import * as actions from "../actions";

const initialState = {
  selectedSolution: null,
};

const solution = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SELECTED_SOLUTION: {
      const selectedSolution = action.payload;
      return { ...state, selectedSolution };
    }
    case actions.CLEAR_SELECTED_SOLUTION: {
      const selectedSolution = null;
      return { ...state, selectedSolution };
    }
    default:
      return state;
  }
};

export default solution;
