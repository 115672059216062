import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MediaCard from "../common/MediaCard";
import solutions from "../../constants/maps/solutions";
import insights from "../../constants/maps/insights";
import Navbar from "../common/Navbar";
import { PageTitle } from "../../styles/common";

const SearchSolutions = ({
  location,
}) => {
  const [list, setSolutionsList] = useState(null);
  const [loading, setLoader] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [searchInput, setInput] = useState(new URLSearchParams(location.search).get('search_query'));

  const handleInput = (e) => {
    const searchTerm = e.target.value;
    setInput(searchTerm);
  };
  const getSearchList = () => {
    if (searchInput) {
      const searchSolutionList = getSolutionsList();
      const insightsSearchList = getInsightsList();
      setSolutionsList([...searchSolutionList, ...insightsSearchList]);
    }
  };
  const removeSpecialsChars = (str) => str.toLowerCase().replace(/[.*+?^${}()|[\]\\\s]/g, '');
  const getSolutionsList = () => solutions.filter((sol) => (removeSpecialsChars(sol.title + sol.searchTerms?.toString()).match(removeSpecialsChars(searchInput)) && sol.id));
  const getInsightsList = () => insights.filter((insight) => (removeSpecialsChars(insight.title + insight.searchTerms?.toString()).match(removeSpecialsChars(searchInput)) && insight.id));
  const delaySearch = () => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getSearchList();
      }, 300);
    };
  };
  const handleKeyUp = delaySearch();
  useEffect(() => {
    getSearchList();
  }, []);

  return (
    <ViewContainer>
      <Navbar hideSearch />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} sm={8} md={5}>
          <StyledSearch
            id="outlined-adornment-search"
            size="large"
            fullWidth
            onChange={handleInput}
            onKeyUp={handleKeyUp}
            placeholder="Search Solutions"
            value={searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            labelWidth={70}
          />
        </Grid>
      </Grid>
      <Title>Search Results</Title>

      <Grid container direction="row" alignItems="stretch" spacing={2}>
        {list?.map((items) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MediaCard
              {...items}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />
          </Grid>
        ))}
      </Grid>
    </ViewContainer>

  );
};

export default SearchSolutions;

export const ViewContainer = styled.div`
  padding: 100px 50px;
  background-color: #f8f8f8;
  
`;
const Title = styled(PageTitle)`
  margin: 25px 0;
`;
const StyledSearch = styled(TextField)`
  &.MuiTextField-root{
    &.MuiSvgIcon-root{
      cursor: pointer;
    }
  }
`;
