import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  FormControl,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// local components
import styled from "styled-components";
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  HighchartContainer,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
// images
import heartAttackAnalysis_sol1 from "../../../assets/images/app/heartAttackAnalysis_sol1.webp";
import heartAttackAnalysis_sol2 from "../../../assets/images/app/heartAttackAnalysis_sol2.webp";

const HeartAttackAnalysis = () => {
  const initialData = {
    age: "",
    sex: "",
    cp: "",
    trtbps: "",
    chol: "",
    fbs: "",
    restecg: "",
    thalachh: "",
    exng: "",
    oldpeak: "",
    slp: "",
    caa: "",
    thall: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "payerProvider",
    "heartattackanalysis",
  );

  const sex = [
    { name: "Female", value: 0 },
    { name: "Male", value: 1 },
  ];
  const cp = [
    { name: "Typical Angina", value: 0 },
    { name: "Atypical Angina", value: 1 },
    { name: "Non-anginal Pain", value: 2 },
    { name: "Asymptomatic", value: 3 },
  ];
  const restecg = [
    { name: "Normal", value: 0 },
    { name: "ST-T wave normality", value: 1 },
    { name: "Left- ventricular hypertrophy", value: 2 },
  ];
  const exng = [
    { name: "False", value: 0 },
    { name: "True", value: 1 },
  ];

  const fbs = [
    { name: "False", value: 0 },
    { name: "True", value: 1 },
  ];

  const showResult = () => {
    const NewDataSet = {
      age: "63",
      sex: "1",
      cp: "3",
      trtbps: "145",
      chol: "233",
      fbs: "1",
      restecg: "0",
      thalachh: "150",
      exng: "0",
      oldpeak: "2.3",
      slp: "0",
      caa: "0",
      thall: "1",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      age: "57",
      sex: "1",
      cp: "0",
      trtbps: "130",
      chol: "131",
      fbs: "0",
      restecg: "1",
      thalachh: "115",
      exng: "1",
      oldpeak: "1.2",
      slp: "1",
      caa: "1",
      thall: "3",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      age,
      sex,
      cp,
      trtbps,
      chol,
      fbs,
      restecg,
      thalachh,
      exng,
      oldpeak,
      slp,
      caa,
      thall,
    } = sampleData;

    const params = `${age},${sex},${cp},${trtbps},${chol},${fbs},${restecg},${thalachh},${exng},${oldpeak},${slp},${caa},${thall}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
          console.log(res);
          const percentages = res.feature_importance;
          const params = [];
          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          console.log(percentages);
          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color:
                      (Highcharts.theme
                        && Highcharts.theme.contrastTextColor)
                      || "black",
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The heart is a muscular organ, which pumps blood through the blood
            vessels of the circulatory system. The pumped blood carries oxygen
            and nutrients to the body, while carrying metabolic waste such as
            carbon dioxide to the lungs. The heart is approximately the size of
            a closed fist and is located between the lungs, in the middle
            compartment of the chest. The heart is divided into four chambers:
          </p>
          <ul>
            <li>Upper left atria </li>
            <li>Upper right atria</li>
            <li>Lower left Ventricles</li>
            <li>Lower right ventricles </li>
          </ul>
          <center>
            <Grid item xs={12}>
              <img width="40%" src={heartAttackAnalysis_sol1} />
            </Grid>
          </center>
          <p>
            The heart is enclosed in a protective sac, the pericardium, which
            also contains a small amount of fluid. The heart beats at a resting
            rate close to 72 beats per minute. Exercise temporarily increases
            the rate, but lowers resting heart rate in the long term, and is
            good for heart health.
          </p>
          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Heart attack
          </ResponsiveTypography>
          <p>
            A heart attack usually occurs when a blood clot blocks blood flow to
            the heart. Without blood, tissue loses oxygen and dies. The blockage
            is most often a build-up of fat, cholesterol and other substances,
            which form a plaque in the arteries that feed the heart. Sometimes,
            a plaque can rupture and form a clot that blocks blood flow. The
            interrupted blood flow can damage or destroy part of the heart
            muscle. A heart attack, also called a myocardial infarction, can be
            fatal, but treatment has improved dramatically over the years.
          </p>
          <center>
            <Grid item xs={12}>
              <img width="40%" src={heartAttackAnalysis_sol2} />
            </Grid>
          </center>
          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Symptoms of Heart Attack
          </ResponsiveTypography>
          <p>Some of the major heart attack symptoms in women and men are, </p>
          <ul>
            <li>Chest pain </li>
            <li>Shortness of breath and nauseous feeling </li>
            <li>Pain or discomfort in the jaw, neck, arm, or, shoulder.</li>
          </ul>
          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Dataset Attack
          </ResponsiveTypography>
          <p>
            The dataset we have is taken from Kaggle and it contains 303 rows
            and 14 columns, which helps us understand and predict the chances of
            heart attack.
          </p>
          <p>
            This Machine Learning Model benefits Hospitals/ Healthcare units by
            predicting the probability of a patient getting heart attack based
            on his/her medical records by saving people life.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/HeartAttack/HeartAttackAnalysis.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/rashikrahmanpritom/heart-attack-analysis-prediction-dataset"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="age"
                  variant="outlined"
                  name="age"
                  label="AGE"
                  value={sampleData.age}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="sex"
                  variant="outlined"
                  name="sex"
                  select
                  label="SEX"
                  value={sampleData.sex}
                  onChange={onChangeFields}
                >
                  {sex.map((option) => (
                    <MenuItem key={option.name} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="cp"
                  variant="outlined"
                  name="cp"
                  select
                  label="CP"
                  value={sampleData.cp}
                  onChange={onChangeFields}
                >
                  {cp.map((option) => (
                    <MenuItem key={option.name} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="trtbps"
                  variant="outlined"
                  name="trtbps"
                  label="TRTBPS"
                  value={sampleData.trtbps}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="chol"
                  variant="outlined"
                  name="chol"
                  label="CHOL"
                  value={sampleData.chol}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="fbs"
                  variant="outlined"
                  name="fbs"
                  select
                  label="FBS"
                  value={sampleData.fbs}
                  onChange={onChangeFields}
                >
                  {fbs.map((option) => (
                    <MenuItem key={option.name} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="restecg"
                  variant="outlined"
                  name="restecg"
                  select
                  label="RESTECG"
                  value={sampleData.restecg}
                  onChange={onChangeFields}
                >
                  {restecg.map((option) => (
                    <MenuItem key={option.name} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="thalachh"
                  variant="outlined"
                  name="thalachh"
                  label="THALACHH"
                  value={sampleData.thalachh}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="exng"
                  variant="outlined"
                  name="exng"
                  select
                  label="EXNG"
                  value={sampleData.exng}
                  onChange={onChangeFields}
                >
                  {exng.map((option) => (
                    <MenuItem key={option.name} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="oldpeak"
                  variant="outlined"
                  name="oldpeak"
                  label="OLDPEAK"
                  value={sampleData.oldpeak}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="slp"
                  variant="outlined"
                  name="slp"
                  label="SLP"
                  value={sampleData.slp}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="caa"
                  variant="outlined"
                  name="caa"
                  label="CAA"
                  value={sampleData.caa}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="thall"
                  variant="outlined"
                  name="thall"
                  label="THALL"
                  value={sampleData.thall}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {resultData}
              </Result>
              <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                  <HighchartContainer id="prediction-chart" display />
                </Grid>
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(HeartAttackAnalysis);

const ResponsiveTypography = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
