import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CohortSenseService from "./CohortSenseService";
import CommonStyles from './commonStyles';

class ModelSelectionSection extends React.Component {
  constructor(props) {
    super(props);
    this.onSelectChanged = this.onSelectChanged.bind(this);
    this.onFeatureSelectChanged = this.onFeatureSelectChanged.bind(this);
    this.onSubmitModelSelection = this.onSubmitModelSelection.bind(this);
    this.onChangeLearnMixtureWeights = this.onChangeLearnMixtureWeights.bind(this);
    this.fillAdaNetValues = this.fillAdaNetValues.bind(this);
    this.onChangeAdanetFields = this.onChangeAdanetFields.bind(this);
    this.onChangeAutoKerasFields = this.onChangeAutoKerasFields.bind(this);
    this.onTraditionalModelFeatureSelection = this.onTraditionalModelFeatureSelection.bind(this);
    this.state = {
      modelSelection: '',
      isDisabled: false,
      isError: false,
      resultAvailable: false,
      result: {},
      adanet: {
        learningRate: '',
        trainingSteps: '',
        batchSize: '',
        learnMixtureWeights: '',
        adaNetLambda: '',
        adaNetIterations: '',
        randomSeed: '',
        trainTestSplit: '',
      },
      autoKeras: {
        trainTestSplit: '',
      },
      traditional: {
        featureSelection: '',
        args: '',
      },
    };

    this.sampleResponse = {
      ModelName: ["logistic_regression", "random_forest", "adaboost"],
      AUC: [0.52, 0.88, 0.88],
      allmetrics: {
        modelaccuracy: [0.95, 0.95, 0.95],
        model_roc_auc_score: [0.52, 0.88, 0.88],
        model_recall_list: [0.0, 0.0, 0.0],
        model_precision_list: [0.0, 0.0, 0.0],
      },
      ConfusionMetr: [[[20, 0], [1, 0]], [[20, 0], [1, 0]], [[20, 0], [1, 0]]],
      filename: "COHORT_SENSE_851563743293.csv",
      picklefn: ["logistic_regression1563743414.pkl", "random_forest1563743414.pkl", "adaboost1563743415.pkl"],
      jfilename: "COHORT_SENSE_851563743293.json",
      rocgraphdata: [["logistic_regression", "random_forest", "adaboost"], [[0.0, 0.05, 0.1, 0.3, 0.35, 0.45, 0.5, 0.6, 0.7, 0.8, 1.0], [0.0, 0.05, 0.1, 0.15, 0.25, 0.4, 1.0], [0.0, 0.1, 0.15, 0.2, 0.3, 0.45, 0.65, 0.9, 1.0]], [[0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0], [0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0], [0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0]], [0.52, 0.88, 0.88]],
    };

    this.argumentPlaceholderText = {
      correlation: "Enter the limit of correlation value",
      correlationPvalue: "Enter the limit of correlation value",
      LGBM: "Enter the Number of Features to be select",
    };

    this.response = {
      Accuracy: "0.53333336",
      AUC: "0.66666675",
      Loss: "0.7108364",
      Precision: "0.16666667",
      Recall: "0.33333334",
      filename: "CKD_SAMPLE1560429515.csv",
    };
  }

  componentDidMount() {
    this.fillAdaNetValues();
  }

  onCloseSnack() {
    this.setState({
      isError: false,
    });
  }

  onChangeAdanetFields(event) {
    const { value } = event.target;
    const { name } = event.target;

    this.setState((prevState) => ({
      adanet: {
        ...prevState.adanet,
        [name]: value,
      },
    }));
  }

  onChangeAutoKerasFields(event) {
    const { value } = event.target;
    const { name } = event.target;

    this.setState((prevState) => ({
      autoKeras: {
        ...prevState.autoKeras,
        [name]: value,
      },
    }));
  }

  onChangeLearnMixtureWeights(event) {
    const modelSelection = event.target.value;

    this.setState((prevState) => ({
      adanet: {
        ...prevState.adanet,
        learnMixtureWeights: modelSelection,
      },
    }));
  }

  onTraditionalModelFeatureSelection(event) {
    const { value } = event.target;

    this.setState((prevState) => ({
      traditional: {
        ...prevState.traditional,
        args: value,
      },
    }));
  }

  // 0.001,800,128,False,0,2,42,0.3
  fillAdaNetValues() {
    this.setState({
      adanet: {
        learningRate: 0.001,
        trainingSteps: 800,
        batchSize: 128,
        learnMixtureWeights: "False",
        adaNetLambda: 0,
        adaNetIterations: 2,
        randomSeed: 42,
        trainTestSplit: 0.3,
      },
    });
  }

  onSelectChanged(event) {
    this.setState({
      resultAvailable: false,
      result: {},
    });
    const modelSelection = event.target.value;
    this.setState(
      {
        modelSelection,
      },
    );
  }

  onSubmitModelSelection() {
    const { modelSelection } = this.state;

    if (modelSelection === 'adanet') {
      const parameters = this.state.adanet;
      let areValuesEmpty = false;
      Object.keys(parameters).forEach((key) => {
        if (parameters[key] === '') {
          areValuesEmpty = true;
        }
      });

      if (areValuesEmpty) {
        this.setState({
          isError: true,
        });
      } else {
        this.setState({
          isLoading: true,
        });
        const filename = window.sessionStorage.getItem('cohortFileName');
        const jsonFileName = window.sessionStorage.getItem('cohortJsonFileName');
        CohortSenseService.generateAdanetModel(this.state.adanet.learningRate, this.state.adanet.trainingSteps,
          this.state.adanet.batchSize, this.state.adanet.learnMixtureWeights, this.state.adanet.adaNetLambda,
          this.state.adanet.adaNetIterations, this.state.adanet.randomSeed, this.state.adanet.trainTestSplit,
          filename, jsonFileName)
          .then((result) => {
            console.log('generateAdanetModel', result);
            this.setState({
              isLoading: false,
              resultAvailable: true,
              result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (modelSelection === 'autoKeras') {
      const parameters = this.state.autoKeras;
      let areValuesEmpty = false;
      Object.keys(parameters).forEach((key) => {
        if (parameters[key] === '') {
          areValuesEmpty = true;
        }
      });

      if (areValuesEmpty) {
        this.setState({
          isError: true,
        });
      } else {
        this.setState({
          isLoading: true,
        });
        const filename = window.sessionStorage.getItem('cohortFileName');
        const jsonFileName = window.sessionStorage.getItem('cohortJsonFileName');
        CohortSenseService.generateKerasModel(this.state.autoKeras.trainTestSplit, filename, jsonFileName)
          .then((result) => {
            console.log('generateKerasModel', result);
            this.setState({
              isLoading: false,
              resultAvailable: true,
              result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const parameters = this.state.traditional;
      let areValuesEmpty = false;
      Object.keys(parameters).forEach((key) => {
        if (parameters[key] === '') {
          areValuesEmpty = true;
        }
      });

      if (areValuesEmpty) {
        this.setState({
          isError: true,
        });
      } else {
        this.setState({
          isLoading: true,
        });
        const filename = window.sessionStorage.getItem('cohortFileName');
        const jsonFileName = window.sessionStorage.getItem('cohortJsonFileName');
        CohortSenseService.retrieveFeatureSelectionPreview(this.state.traditional.featureSelection, filename, jsonFileName,
          this.state.traditional.args)
          .then((result) => {
            const previewData = JSON.parse(result.predata);
            console.log('retrieveFeatureSelectionPreview', result, previewData);

            this.setState({
              isLoading: false,
              resultAvailable: true,
              result: {
                dataRows: previewData.data,
                fields: previewData.schema.fields,
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  onFeatureSelectChanged(event) {
    const optionSelection = event.target.value;
    this.setState(
      {
        traditional: {
          featureSelection: optionSelection,
        },
      },
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <section className="App">
        <div>
          <section className={classes.container}>
            <h2 className={classes.titleClient}>
              Model Selection
            </h2>

            <section>
              <Grid container>
                <Grid item md={6}>
                  <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.width95}>
                      <InputLabel>Model Selection</InputLabel>
                      <Select value={this.state.modelSelection} onChange={this.onSelectChanged}>
                        <MenuItem value="adanet">ADANET</MenuItem>
                        <MenuItem value="autoKeras">AUTOKERAS</MenuItem>
                        <MenuItem value="traditional">TRADITIONAL</MenuItem>
                      </Select>
                      <br />
                    </FormControl>
                  </form>
                </Grid>

                <Grid item md={6}>
                  <div align="left">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onSubmitModelSelection}
                      disabled={!this.state.modelSelection}
                      style={{ "margin-top": 15 }}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <br />

              {
                this.state.modelSelection && this.state.modelSelection === 'adanet'
                && (
                  <Grid container>
                    <Grid item md={4}>
                      <FormControl className={classes.width95}>
                        <TextField
                          label="Learning Rate"
                          name="learningRate"
                          type="number"
                          onChange={this.onChangeAdanetFields}
                          value={this.state.adanet.learningRate}
                          margin="normal"
                        />
                      </FormControl>

                      <FormControl component="fieldset" className={classes.width95}>
                        <FormLabel component="label">Learn Mixture Weights</FormLabel>
                        <RadioGroup
                          style={{ display: 'block' }}
                          row
                          aria-label="Learn Mixture Weights"
                          name="learnMixtureWeights"
                          value={this.state.adanet.learnMixtureWeights}
                          onChange={this.onChangeLearnMixtureWeights}
                        >
                          <FormControlLabel
                            value="False"
                            control={<Radio color="primary" />}
                            label="False"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="True"
                            control={<Radio color="primary" />}
                            label="True"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>

                      <FormControl className={classes.width95}>
                        <TextField
                          label="Random Seed"
                          name="randomSeed"
                          type="number"
                          onChange={this.onChangeAdanetFields}
                          value={this.state.adanet.randomSeed}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={4}>
                      <FormControl className={classes.width95}>
                        <TextField
                          label="Training Steps"
                          name="trainingSteps"
                          type="number"
                          onChange={this.onChangeAdanetFields}
                          value={this.state.adanet.trainingSteps}
                          margin="normal"
                        />
                      </FormControl>

                      <FormControl className={classes.width95}>
                        <TextField
                          label="Adanet Lambda"
                          name="adaNetLambda"
                          type="number"
                          onChange={this.onChangeAdanetFields}
                          value={this.state.adanet.adaNetLambda}
                          margin="normal"
                        />
                      </FormControl>

                      <FormControl className={classes.width95}>
                        <TextField
                          label="Train Test Split"
                          name="trainTestSplit"
                          type="number"
                          InputProps={{ inputProps: { min: 0, max: 1 } }}
                          onChange={this.onChangeAdanetFields}
                          value={this.state.adanet.trainTestSplit}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={4}>
                      <FormControl className={classes.width95}>
                        <TextField
                          label="Batch Size"
                          name="batchSize"
                          type="number"
                          onChange={this.onChangeAdanetFields}
                          value={this.state.adanet.batchSize}
                          margin="normal"
                        />
                      </FormControl>
                      <FormControl className={classes.width95}>
                        <TextField
                          label="Adanet Iterations"
                          name="adaNetIterations"
                          type="number"
                          onChange={this.onChangeAdanetFields}
                          value={this.state.adanet.adaNetIterations}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )
              }

              {
                this.state.modelSelection && this.state.modelSelection === 'autoKeras'
                && (
                  <Grid container>
                    <Grid item md={4}>
                      <FormControl className={classes.width95}>
                        <TextField
                          label="Train Test Split"
                          type="number"
                          min="1"
                          InputProps={{ inputProps: { min: 0, max: 1 } }}
                          max="1"
                          name="trainTestSplit"
                          onChange={this.onChangeAutoKerasFields}
                          value={this.state.autoKeras.trainTestSplit}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )
              }

              {
                this.state.modelSelection && this.state.modelSelection === 'traditional'
                && (
                  <div>
                    <Grid container>
                      <Grid item md={6}>
                        <FormControl className={classes.width95}>
                          <InputLabel>Feature Selection</InputLabel>
                          <Select
                            value={this.state.traditional.featureSelection}
                            onChange={this.onFeatureSelectChanged}
                          >
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="correlation">Correlation</MenuItem>
                            <MenuItem value="LGBM">LightGBM</MenuItem>
                            <MenuItem value="correlationPvalue">P-Value</MenuItem>
                          </Select>
                          <br />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {
                      this.state.traditional.featureSelection && this.state.traditional.featureSelection !== 'none'
                      && (
                        <Grid container>
                          <Grid item md={6}>
                            <FormControl className={classes.width95}>
                              <TextField
                                label={this.argumentPlaceholderText[this.state.traditional.featureSelection]}
                                name="args"
                                type="number"
                                onChange={this.onTraditionalModelFeatureSelection}
                                value={this.state.traditional.args}
                                margin="normal"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      )
                    }

                  </div>
                )
              }
            </section>

            <br />
            <br />
            <section>
              {
                this.state.isLoading && (
                  <LinearProgress />
                )
              }
              <br />
              <h5>Results</h5>
              {
                this.state.resultAvailable && this.state.modelSelection === "traditional"
                && this.state.result && this.state.result.fields && this.state.result.dataRows
                && (
                  <div className={classes.scrollableTable}>
                    <div align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.props.navigateNext();
                        }}
                      >
                        Model Generation
                      </Button>
                    </div>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.result.fields.map((field) => (
                              <TableCell align="right">{field.name}</TableCell>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.result.dataRows.map((row) => (
                            <TableRow key={row.index}>
                              {
                                this.state.result.fields.map((field) => (
                                  <TableCell
                                    align="right"
                                  >
                                    {field.name === 'index' ? row[field.name] + 1 : row[field.name]}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          ))
                        }
                      </TableBody>

                    </Table>
                  </div>
                )
              }

              {
                this.state.resultAvailable && this.state.modelSelection === "autoKeras" && this.state.result && this.state.result.Accuracy
                && (
                  <Grid container>
                    <Grid
                      item
                      md={4}
                    >
                      Accuracy:
                      {' '}
                      {parseFloat(this.state.result.Accuracy).toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      md={4}
                    >
                      Precision:
                      {' '}
                      {parseFloat(this.state.result.Precision).toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      md={4}
                    >
                      Recall:
                      {' '}
                      {parseFloat(this.state.result.Recall).toFixed(2)}
                    </Grid>
                  </Grid>
                )
              }

              {
                this.state.resultAvailable && this.state.modelSelection === "adanet" && this.state.result && this.state.result.Accuracy
                && (
                  <Grid container>
                    <Grid
                      item
                      md={2}
                    >
                      Accuracy:
                      {' '}
                      {parseFloat(this.state.result.Accuracy).toFixed(2)}
                    </Grid>
                    <Grid item md={2}>
                      AUC:
                      {parseFloat(this.state.result.AUC).toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      md={2}
                    >
                      Precision:
                      {' '}
                      {parseFloat(this.state.result.Precision).toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      md={2}
                    >
                      Recall:
                      {' '}
                      {parseFloat(this.state.result.Recall).toFixed(2)}
                    </Grid>
                    <Grid item md={2}>
                      Loss:
                      {parseFloat(this.state.result.Loss).toFixed(2)}
                    </Grid>
                  </Grid>
                )
              }
            </section>
          </section>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.isError}
          variant="error"
          autoHideDuration={2000}
          message={<span>This is an error</span>}
          onClose={this.onCloseSnack}
        >
          <SnackbarContent
            // className={classes.errorSnack}
            aria-describedby="client-snackbar"
            message={(
              <span id="client-snackbar" className={classes.message}>
                {/* <ErrorIcon className={classes.icon + ' ' + classes.iconVariant}/> */}
                Field(s) are empty
              </span>
            )}
          />
        </Snackbar>
      </section>
    );
  }
}

ModelSelectionSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CommonStyles)(ModelSelectionSection);
