import insight1 from "../../assets/images/insights/insight1.webp";
import insight2 from "../../assets/images/insights/insight2.webp";
import insight3 from "../../assets/images/insights/insight3.webp";
import insight4 from "../../assets/images/insights/insight4.webp";
import insight5 from "../../assets/images/insights/insight5.webp";
import insight6 from "../../assets/images/insights/insight6.webp";
import insight7 from "../../assets/images/insights/insight7.webp";
import insight8 from "../../assets/images/insights/insight8.webp";
import insight9 from "../../assets/images/insights/insight9.webp";
import insight32 from "../../assets/images/insights/insight32.webp";
import insight33 from "../../assets/images/insights/insight33.webp";
import insight34 from "../../assets/images/insights/insight34.webp";
import insight35 from "../../assets/images/insights/insight35.webp";
import insight36 from "../../assets/images/insights/insight36.webp";
import insight37 from "../../assets/images/insights/insight37.webp";

import image1 from "../../assets/images/insights/image1.webp";
import image8 from "../../assets/images/insights/image8.webp";
import image9 from "../../assets/images/insights/image9.webp";
import image14 from "../../assets/images/insights/image14.webp";
import image15 from "../../assets/images/insights/image15.webp";
import image17 from "../../assets/images/insights/image17.webp";
import image18 from "../../assets/images/insights/image18.webp";
import image24 from "../../assets/images/insights/image24.webp";
import image26 from "../../assets/images/insights/image26.webp";
import image27 from "../../assets/images/insights/image27.webp";
import image21 from "../../assets/images/insights/image21.webp";

import blog1 from "../../assets/images/insights/blog1.webp";
import blog9 from "../../assets/images/insights/blog9.webp";
import blog19 from "../../assets/images/insights/blog19.webp";

import insightIds from './insightids';

export default [
  {
    id: insightIds.insight1,
    title: "Average Age Of Initial Pathologic Diagnosis Of Breast Cancer",
    type: "external",
    image: insight1,
    searchTerms: ["cancer analysis", "female diseases", "image analytics"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/AverageAgeOfInitialPathologicDiagnosisOfBreastCancer/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight2,
    title: "Geographical Spread of Relative Death rates in US",
    type: "external",
    image: insight2,
    searchTerms: ["US population analysis", "visualizations", "heat maps"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/GeographicalSpreadofRelativeDeathratesinUS/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight3,
    title: "Osteoarthritis of Hip",
    type: "external",
    image: insight7,
    searchTerms: ["visualisations", "Bar Chart", "Osteoarthritis", "Medications"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TopMedicationsTakenforOsteoarthritisoftheHip/Dashboard1",
  },
  {
    id: insightIds.insight4,
    title: "Osteoarthritis of Knee",
    type: "external",
    image: insight9,
    searchTerms: ["visualisations", "Bar Chart", "Osteoarthritis", "Medications"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TopMedicationsTakenforOsteoarthritisoftheKnee/Dashboard1",
  },
  // new
  {
    id: insightIds.insight5,
    title: "Impact of Healthcare Cost in Life Expectancy with Disability",
    type: "external",
    image: image27,
    searchTerms: ["Cost Analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/HealthcarecostimpactonLifeExpectancy/Sheet2?publish=yes",
  },
  {
    id: insightIds.insight6,
    title: "Race Distribution for Inpatient Claims",
    type: "external",
    image: insight9,
    searchTerms: ["Insurance analysis", "Caste", "Visualization"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/RaceDistributionforInpatientClaims/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight7,
    title: "Top Diagnosis Taken by Inpatient Claims",
    type: "external",
    image: blog19,
    searchTerms: ["Insurance analysis", "payer", "visualization"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TopDiagnosisCodetakenbyInpatientClaims/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight8,
    title: "Most pay out for Diagnosis",
    type: "external",
    image: image21,
    searchTerms: ["cost analysis", "diagnosis codes", "payers", "visualization"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/MostPayoutforDiagnosis/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight9,
    title: "US State wise - Provider Network Analysis",
    type: "external",
    image: image14,
    searchTerms: ["visualisations", "Comparision Charts", "data graphs"],
    externalUrl: "https://public.tableau.com/profile/sourabh.agrawal#!/vizhome/Cigna_16087144286740/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight10,
    title: "Claims, Day Supply and Drug Costs Comparision",
    type: "external",
    image: image9,
    searchTerms: ["insurance Analysis", "visualizations", "comparision charts"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Claimscost/Sheet2?publish=yes",
  },
  {
    id: insightIds.insight11,
    title: "Age Trends on Colorectal Cancer",
    type: "external",
    image: image15,
    searchTerms: ["cancer analysis", "visualizations"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/AgeTrendsonColorectalCancer/Dashboard1",
  },
  {
    id: insightIds.insight12,
    title: "Age Distribution in Epilepsy",
    type: "external",
    image: image26,
    searchTerms: ["visualisations", "comparision charts", "US population analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/MedicationTakenbyEpilepsyPatientsBasedontheirAgeGroup/Dashboard1",
  },
  {
    id: insightIds.insight13,
    title: "Opioid Prescriber Analysis in US",
    type: "external",
    image: insight2,
    searchTerms: ["US population analysis", "visualizations"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/OpioidPrescriberAnalysisinUS/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight14,
    title: "Trending Opioid Prescribers by Specialty",
    type: "external",
    image: insight6,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TrendingOpioidPrescribersbySpecialty/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight15,
    title: "Most Frequent Visits to Hospital (Week Days)",
    type: "external",
    image: image9,
    searchTerms: ["patient hospital visit analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/MostFrequentVisitstoHospitalWeekDays/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight16,
    title: "Trends on Total Waiting time for Appointments",
    type: "external",
    image: insight9,
    searchTerms: ["supply chain management", "medical appointments", "visualization", "providers"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TrendsonTotalWaitingtimeforAppointment/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight17,
    title: "Geographical Trends on NoShow Appointments",
    type: "external",
    image: insight3,
    searchTerms: ["heatmaps", "visualizations", "patient record", "providers"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/GeographicalTrendsonNoShowAppointments/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight18,
    title: "TOP Rates of Patients No-Show in Neighborhood",
    type: "external",
    image: insight8,
    searchTerms: ["medical appointment", "supply chain management", "patient record"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TopRatesofPatientsNoShowinNeighbourhood/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight19,
    title: "Ventilator Estimation in US States",
    type: "external",
    image: insight36,
    searchTerms: ["US population analysis", "visualizations", "ICU analysis"],
    externalUrl: "https://apps.vlifevirtusa.com/vLife3tools/COVID-19/ventilator_requirement.html",
  },
  {
    id: insightIds.insight20,
    title: "CKD - Infographics",
    type: "external",
    image: image14,
    searchTerms: ["US population analysis", "kidney diseases", "visualization", "marketscan data"],
    externalUrl: "https://vlife.virtusa.com/static/media/CKD_Infographic_v2.1.1e2bf580.pdf",
  },
  {
    id: insightIds.insight21,
    title: "Simulated US Population",
    type: "external",
    image: image1,
    externalUrl: "https://vlife.virtusa.com/static/media/Synthetic_emr_data.fdcce004.pdf",
  },
  {
    id: insightIds.insight22,
    title: "T2D - Infographics",
    type: "external",
    image: image8,
    searchTerms: ["diabetes", "visualizations", "marketscan data"],
    externalUrl: "https://vlife.virtusa.com/static/media/T2D_Infographics_v5.1.1957a717.pdf",
  },
  {
    id: insightIds.insight24,
    title: "Type 2 Diabetes Diagnosis",
    type: "page",
    image: image24,
    searchTerms: ["visualisations", "data graphs", "marketscan data"],
    internalUrl: `/type2diabetes-diagnosis`,
  },
  {
    id: insightIds.insight25,
    title: "Patient Diagnosis Heirarchy",
    type: "page",
    image: image18,
    searchTerms: ["patient analysis", "visualizations", "patient medical history"],
    internalUrl: `/patient-heirarchy-dashboard`,
  },
  {
    id: insightIds.insight26,
    title: "Market Scan T2D",
    type: "page",
    image: blog1,
    searchTerms: ["Data graphs", "diabetes analysis", "visualization"],
    internalUrl: "/type2-diabetes-dashboard",
  },
  {
    id: insightIds.insight27,
    title: "Geographic Spread of Neonatal Hypothyroidism",
    type: "external",
    image: blog1,
    searchTerms: ["Heatmaps", "visualizations", "thyroid analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/GeographicSpreadofNeonatalHypothyroidism/Dashboard1",
  },
  {
    id: insightIds.insight28,
    title: "Market Scan Musculoskeletal Journey",
    type: "page",
    image: blog9,
    searchTerms: ["Visualisations", "Comparision Charts", "surgery"],
    internalUrl: "/musculoskeletal-dashboard",
  },
  {
    id: insightIds.insight29,
    title: "Prostate Cancer Distribution",
    type: "external",
    image: image17,
    searchTerms: ["Male Cancer", "Cancer Analysis", "Visualization"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/GeographicDistributionofProstateCancerPatients/Dashboard1",
  },
  {
    id: insightIds.insight30,
    title: "Death count analysis for united states",
    type: "external",
    image: insight1,
    searchTerms: ["US population analysis", "visualizations", "heat maps"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/DeathCountAnalysisforUnitedStatesbyyear/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight31,
    title: "Correlation between Frac-ops & Death-per-capita",
    type: "external",
    image: insight2,
    searchTerms: ["visualisations", "comparision analysis", "statistical analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/CorrelationbetweenFrac-opsDeath-per-capita/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight32,
    title: "Medications Prescribed for Appendicitis",
    type: "external",
    image: insight2,
    searchTerms: ["surgery", "medicines for appendix", "operation"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/MedicationsPrescribedforAppendicitis/Dashboard1",
  },
  {
    id: insightIds.insight33,
    title: "Top Speciality with Opiate",
    type: "external",
    image: insight4,
    searchTerms: [""],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TopSpecialtywithOpiate/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight34,
    title: "Trend Analysis of Drug overdose",
    type: "external",
    image: insight1,
    searchTerms: ["adverse effects of drugs", "medication side effects"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TrendAnalysisofDrugoverdose/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight36,
    title: "Medications Manufactured by Manufacturing Sites in terms of Weight",
    type: "external",
    image: insight1,
    searchTerms: ["manufacturing Analysis", "manufacturing amount", "demand analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Medicationbysites/Sheet1",
  },
  {
    id: insightIds.insight37,
    title: "Geographical Map for Medication Shipped",
    type: "external",
    image: insight2,
    searchTerms: ["logistics", "heat maps", "shipment analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/GeographicalMapforMedicationsShipped/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight38,
    title: "Country wise Trends on Packing Price",
    type: "external",
    image: insight2,
    searchTerms: ["logistics", "world price analysis", "packaging cost"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/CountryWisetrendsonPackingTrends/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight39,
    title: "Total Medication Shipments Transit by Air",
    type: "external",
    image: insight32,
    searchTerms: ["logistics", "supply chain", "transportation"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TotalMedicalShipmentsTransitbyAir/Sheet1",
  },
  {
    id: insightIds.insight40,
    title: "Best Manufacturing Sites for Medication Shipped",
    type: "external",
    image: insight4,
    searchTerms: ["logistics", "supply chain", "site analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/BestManufacturingSitesforMedicationsShipped/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight42,
    title: "Medication Cost of Shipments",
    type: "external",
    image: insight4,
    searchTerms: ["cost of transportation", "financial analysis", "ML on cost"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/ShipmentcostofMedications/Sheet1",
  },
  {
    id: insightIds.insight43,
    title: "Various Modes for Shipment of Medications",
    type: "external",
    image: insight1,
    searchTerms: ["transportation", "logistics", "supply chain"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/VariousModesofShipmentsofMedicines/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight44,
    title: "Freight Cost for Drugs across Countries",
    type: "external",
    image: insight2,
    searchTerms: ["visualisations", "freight cost"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/FreightCostforDrugsAcrossCountries/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight45,
    title: "RFM Analysis",
    type: "external",
    image: insight4,
    searchTerms: ["Customer Segmentation", "visualizations", "Marketing Technique", "RFM analysis"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/CustomerSegmentation-RFMAnalysis/Dashboard1",
  },
  {
    id: insightIds.insight46,
    title: "Customer Segmentation - Country wise Sales",
    type: "external",
    image: image26,
    searchTerms: ["Customer Segmentation", "Segmentation Strategy", "Sales Force"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/CustomerSegmentation-Country-wiseSales/Dashboard1",
  },
  {
    id: insightIds.insight47,
    title: "Revenue Analysis",
    type: "external",
    image: insight1,
    searchTerms: ["Customer Segmentation", "Cost Analysis", "Sales & Marketing"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/CustomerSegmentation-RevenueAnalysis/Dashboard1",
  },
  {
    id: insightIds.insight48,
    title: "Proportion of All Prescriptions prescribed by Practitioners",
    type: "external",
    image: insight4,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Prescriptiondistribution/Prescriptiondistribution?publish=yes",
  },
  {
    id: insightIds.insight49,
    title: "Drug Poisoning Deaths in U.S. (States)",
    type: "external",
    image: insight3,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/DrugPoisoningDeathsinU_S_States/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight51,
    title: "Analysis of Age-adjusted Death Rate across US county",
    type: "external",
    image: insight1,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/AnalysisofAge-adjustedDeathRateacrossUScounty/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight52,
    title: "Total Medical Practitioners across Regions ",
    type: "external",
    image: insight8,
    externalUrl: "https://public.tableau.com/app/profile/vlife.virtusa/viz/Medicalpractitioners/Sheet1",
  },
  {
    id: insightIds.insight53,
    title: "Nursing Centres in different States ",
    type: "external",
    image: insight4,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/NursingCentres/Sheet1",
  },
  {
    id: insightIds.insight54,
    title: "Count of ownership of the hospitals by different Groups ",
    type: "external",
    image: insight8,
    searchTerms: ["Pie chart", "Ownership distribution", "Hospital Ownership"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Hospitalownershipdistribution/Hospitalownershipdistribution",
  },
  {
    id: insightIds.insight55,
    title: "Top Drugs in each state ",
    type: "external",
    image: insight3,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TopDrugCount/Dashboard1",
  },
  {
    id: insightIds.insight56,
    title: "Heirarchical Condition Category Score for US States ",
    type: "external",
    image: insight4,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/HCC_16125062077430/HCC?publish=yes",
  },
  {
    id: insightIds.insight57,
    title: "Trends on Race with respect to Population",
    type: "external",
    image: insight4,
    searchTerms: ["Race", "Race Distribution", "Pie Chart", "visualisations"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/RaceandPopulation/Sheet2",
  },
  {
    id: insightIds.insight58,
    title: "Drug Overdose Deaths Analysis ",
    type: "external",
    image: insight5,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/GeographicalSpreadofDeathscausedbyDrugOverdose/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight59,
    title: "Therapeutic procedures for non-small cell lung cancer",
    type: "external",
    image: insight7,
    searchTerms: ["visualisations", "Bar Chart", "Lung cancer", "Therapeutic procedure"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/TherapeuticProcedures/Dashboard1",
  },
  {
    id: insightIds.insight60,
    title: "Non Small Lung Cancer Race distribution",
    type: "external",
    image: insight4,
    searchTerms: ["Race Distribution", "lung cancer", "visualisation"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/RaceTrendsonNon-Small-CellLungCancer/Dashboard1",
  },
  {
    id: insightIds.insight61,
    title: "Breast Cancer Classification Factors",
    type: "external",
    image: insight9,
    searchTerms: ["Breast cancer", "Classification", "Tumor", "Machine Learning"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/DistributionofBreastCancerbyDiagnosisType/Dashboard1",
  },
  {
    id: insightIds.insight62,
    title: "Proteomes - Numeric Proportion of Tumor type",
    type: "external",
    image: insight9,
    searchTerms: ["Cancer analysis", "Tumor", "classification"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Proteomes-ProportionofDifferentTumorTypes/Dashboard2",
  },
  {
    id: insightIds.insight63,
    title: "Interactive ChatBot",
    type: "external",
    image: insight4,
    searchTerms: ["chatbot", "AI"],
    externalUrl: "https://harbingerlearning.com/blog/taking-life-sciences-training-to-the-next-level-with-ai-powered-chatbots/",
  },
  {
    id: insightIds.insight64,
    title: "Healthcare provider inpatient charges across US states",
    type: "external",
    image: insight32,
    searchTerms: ["visualisations", "Inpatient Charge", "Provider", "US States"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/In-PatientChargesAcrossStates/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight65,
    title: "Healthcare provider inpatient charges and average discharges across US Counties",
    type: "external",
    image: insight33,
    searchTerms: ["visualisations", "Inpatient Charges", "Provider", "US Cities"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/InPatientChargesDistributionacrossCities/Sheet1",
  },
  {
    id: insightIds.insight66,
    title: "US Healthcare provider inpatient charges w.r.t. Zip Codes",
    type: "external",
    image: insight34,
    searchTerms: ["visualisations", "Inpatient Charges", "Provider", "Zip code"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/InPatientChargesDistributionacrossUSStates/Sheet1",
  },
  {
    id: insightIds.insight67,
    title: "US Healthcare provider inpatient charges",
    type: "external",
    image: insight35,
    searchTerms: ["visualisations", "Inpatient Charges", "Area Chart"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/ProviderIn-PatientCharges/Sheet1",
  },
  {
    id: insightIds.insight68,
    title: "Lead Causes for Death Mortality Analysis",
    type: "external",
    image: insight32,
    searchTerms: ["visualisations", "Mortality Analysis", "Bubble chart"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/LeadCausesforDeathMortalityAnalysis/Sheet1?publish=yes",
  },
  {
    id: insightIds.insight69,
    title: "Top Specialty Nursing Services",
    type: "external",
    image: insight9,
    searchTerms: ["visualisations", "Nursing", "Specialty Services"],
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Specialityservices/Sheet2",
  },
  {
    id: insightIds.insight70,
    title: "Count of Hospital Ownership",
    type: "external",
    image: insight4,
    externalUrl: "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Hospitalownershipdistribution/Hospitalownershipdistribution",
  },
  {
    id: insightIds.insight71,
    title: "Metastatic Tissue Classification",
    type: "external",
    image: insight32,
    externalUrl: "https://www.kaggle.com/andrewmvd/metastatic-tissue-classification-patchcamelyon",

  },

];
