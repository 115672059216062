import React, { memo, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const MentalHealth = () => {
  const initialData = {
    age: "",
    gender: "",
    history: "",
    benefits: "",
    careOptions: "",
    anonymity: "",
    leaveOptions: "",
    workInterference: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("payerProvider", "mentalHealth");

  const gender = [
    { name: "Female", value: 0 },
    { name: "Male", value: 1 },
    { name: "Trans", value: 2 },
  ];

  const boolean = [
    { name: "No", value: 0 },
    { name: "Yes", value: 1 },
    { name: "Don't Know", value: 2 },
  ];
  const leaveOptions = [
    { name: "Very Easy", value: 0 },
    { name: "Somewhat Easy", value: 1 },
    { name: "Somewhat Difficult", value: 2 },
    { name: "Very Difficult", value: 3 },
    { name: "Don't Know", value: 4 },
  ];
  const workInterference = [
    { name: "Never", value: 0 },
    { name: "Rarely", value: 1 },
    { name: "Sometimes", value: 2 },
    { name: "Often", value: 3 },
    { name: "Don't Know", value: 4 },
  ];

  const formHighChart = (data) => {
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {},
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Feature Importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][0][0],
              y: data[1][0][1],
            },
            {
              name: data[1][1][0],
              y: data[1][1][1],
            },
            {
              name: data[1][2][0],
              y: data[1][2][1],
            },
            {
              name: data[1][3][0],
              y: data[1][3][1],
            },
            {
              name: data[1][4][0],
              y: data[1][4][1],
            },
            {
              name: data[1][5][0],
              y: data[1][5][1],
            },
            {
              name: data[1][6][0],
              y: data[1][6][1],
            },
            {
              name: data[1][7][0],
              y: data[1][7][1],
            },
          ],
        },
      ],
    });
  };

  const showSample1 = () => {
    const NewDataSet = {
      age: "34",
      gender: "1",
      history: "0",
      benefits: "0",
      careOptions: "1",
      anonymity: "0",
      leaveOptions: "0",
      workInterference: "0",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      age: "28",
      gender: "1",
      history: "1",
      benefits: "2",
      careOptions: "1",
      anonymity: "0",
      leaveOptions: "0",
      workInterference: "4",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      age,
      gender,
      history,
      benefits,
      careOptions,
      anonymity,
      leaveOptions,
      workInterference,
    } = sampleData;

    const params = `${age},${gender},${history},${benefits},
    ${careOptions},${anonymity},${leaveOptions},${workInterference}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("sample", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res);
          formHighChart(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The mental health of the employees has become one of the main
            concerns of any employer today. It is one of the required areas that
            must be covered in any organization’s healthcare benefits. A
            well-crafted mental health policy encourages a healthy workplace
            environment and also gives employees and the management a framework
            to encourage proper treatment by eliminating the stigma that mainly
            arises around the issue.
          </p>
          <p>
            This application uses a predictive ML model that analyses user
            responses for various questions based on the current mental health
            plan provided by their employer and predicts how willing they would
            be in availing the treatment provided to them. This will help
            employers understand their employees’ attitudes towards the policies
            and make the necessary changes.
          </p>
          <p>The following are the inputs taken by the application:</p>
          <List>
            <ListItem>
              <strong>
                <i>Age:</i>
              </strong>
              Age of the employee
            </ListItem>
            <ListItem>
              <strong>
                <i>Gender:</i>
              </strong>
              Gender of the employee (female/male/trans)
            </ListItem>
            <ListItem>
              <strong>
                <i>Family history:</i>
              </strong>
              Does the employee have a family history of mental illness?
              (no/yes)
            </ListItem>
            <ListItem>
              <strong>
                <i>Benefits:</i>
              </strong>
              Does their employer provide mental health benefits? (no/ yes/
              don't know)
            </ListItem>
            <ListItem>
              <strong>
                <i>Care options:</i>
              </strong>
              Is the employee aware of the options for the mental health care
              provided by their employer? (no/ yes/ not sure)
            </ListItem>
            <ListItem>
              <strong>
                <i>Anonymity:</i>
              </strong>
              Is the anonymity of the employee protected if they choose to take
              advantage of mental health or substance abuse treatment resources?
              (no/ yes/ don't know)
            </ListItem>
            <ListItem>
              <strong>
                <i>Leave:</i>
              </strong>
              How easy is it for the employee to take medical leave for a mental
              health condition? (Very easy/ Somewhat easy/ Somewhat difficult/
              Very difficult/ Don't know)
            </ListItem>
            <ListItem>
              <strong>
                <i>Work interference:</i>
              </strong>
              If the employee has a mental health condition, do they feel that
              it interferes with their work? (Never/ Rarely/ Sometimes/ Often/
              Don't know)
            </ListItem>
          </List>
          <p>
            <strong>Application :</strong>
            Understanding the employees’ thoughts and perspectives on the
            current mental health plan provided is an effective way for an
            employer to determine the best-suitable health policy for their
            employers and revamp the policy in a way that benefits both the
            business, as well as the employees.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/MentalHealthTreatment/MentalHealthTreatmentPrediction.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/osmi/mental-health-in-tech-survey"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="Age"
                name="age"
                fullWidth
                onChange={handleInputs}
                value={sampleData.age}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="gender"
                label="Gender"
                value={sampleData.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="history"
                label="History"
                value={sampleData.history}
                onChange={handleInputs}
              >
                {boolean.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="benefits"
                label="Benefits"
                value={sampleData.benefits}
                onChange={handleInputs}
              >
                {boolean.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="careOptions"
                label="CareOptions"
                value={sampleData.careOptions}
                onChange={handleInputs}
              >
                {boolean.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="anonymity"
                label="Anonymity"
                value={sampleData.anonymity}
                onChange={handleInputs}
              >
                {boolean.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="leaveOptions"
                label="LeaveOptions"
                value={sampleData.leaveOptions}
                onChange={handleInputs}
              >
                {leaveOptions.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="workInterference"
                label="WorkInterference"
                value={sampleData.workInterference}
                onChange={handleInputs}
              >
                {workInterference.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Result>
              <strong>Result:</strong>
              {`${result[0]}`}
            </Result>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MentalHealth);
