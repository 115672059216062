import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Divider, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

import Sample1_before from "../../../assets/images/app/incision1_before.webp";
import Sample1_after from "../../../assets/images/app/incision1_after.webp";
import Sample2_before from "../../../assets/images/app/incision2_before.webp";
import Sample2_after from "../../../assets/images/app/incision2_after.webp";
import Normal from "../../../assets/images/app/normal.webp";

const IncisionCareMangement = () => {
  const [resultData, setResult] = useState(null);
  const [selectedSample, setSample] = useState("");
  const sampleData = {
    sample1: {
      image1: Sample1_before,
      image2: Sample1_after,
      imgText: "The percentage probability of incision identified is 62%",
      result:
        "The incision identified in the image is less than 30 days of surgery",
    },
    sample2: {
      image1: Sample2_before,
      image2: Sample2_after,
      imgText: "The percentage probability of incision identified is 73%",
      result: "The incision identified in the image is post 30 days of surgery",
    },
    sample3: {
      image1: Normal,
      result: "The image uploaded does not recognize an incision.",
    },
  };

  // set sample value and selected image
  const setSampleData = (e) => {
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    setResult({
      image1: sampleData[selectedSample].image1,
    });
    setSample(selectedSample);
  };

  // show result based on selected sample
  const showResult = () => {
    setResult({
      ...resultData,
      image2: sampleData[selectedSample].image2,
      imgText: sampleData[selectedSample].imgText,
      result: sampleData[selectedSample].result,
    });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          An incision is a cut made through the skin or tissues of the body in
          order to expose the underlying organ, bone, or tissue so that a
          surgical procedure can be performed. It is a common misconception that
          incisions are cuts made just through the skin, when it typically goes
          through tissues, fat, and most often through muscles to allow access
          to the surgical site. Depending on the type of surgical procedure
          performed, the size of the incision varies. Incisions are much deeper
          than they appear from the surface, which is why although the wound may
          appear to have healed on the surface in a week or two, it can take
          months before the underlying muscles and tissues heal completely.
          Proper post-operative care is required to help in effective healing
          and restoration of full muscle strength.
          {' '}
          <br />
          This application allows the user to upload a picture of the incision
          on their knee after a Total Knee Replacement (TKR) surgery and
          predicts how long ago the incision identified was made. This can aid
          in administering proper post-operative care.
        </Paragraph>
        <strong>Application:</strong>
        <Paragraph>
          A count of 100 images of knee incisions obtained after a total knee
          replacement surgery was collected from various internet sources. The
          exact location of the incision in each image was manually labelled and
          saved. This dataset was then used to train a YOLOv3 model to precisely
          identify the location of a knee incision in any given image. This
          model not only identifies the incision location but also primarily
          checks whether the given input image is valid or not.
          <br />
          A separate set of 30 images of knee incisions obtained after a
          total knee replacement surgery were collected from various internet
          sources. These images contained a mixture of incision wounds from TKR
          surgeries performed less than a month ago, and those performed a
          little over a month ago. The images were manually labelled as either
          ‘more than a month post-surgery ’, or ‘less than a month post-surgery
          ’. The small size of the dataset makes it unsuitable for training an
          efficient image classification model. A technique called Synthetic
          Data Generation is used to generate a set of synthetic images from the
          existing dataset to overcome this challenge. This is achieved using a
          tool called SinGAN (
          <a
            href="https://vlife.virtusa.com/sin-gan"
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
          >
            Virtusa | vLife™
          </a>
          ), a variant of GAN (Generative Adversarial Networks) in which the
          network learns from a single natural image to generate images that are
          very realistic. Around 80 – 90 images per class were synthetically
          generated and combined with the original images. This modified dataset
          was then used to train a model that correctly identifies whether the
          given incision image was obtained less than a month ago or not.
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/incision/incision-care.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-around"
            alignItems="center"
            alignContent="center"
          >
            <Grid xs={12} sm={6} md={3}>
              <StyledFigure>
                <Image
                  src={Sample1_before}
                  height="150px"
                  width="130px"
                  alt=""
                />
                <StyledButton
                  variant="contained"
                  color="primary"
                  btnColor="#023e8a"
                  data-sample="sample1"
                  onClick={setSampleData}
                >
                  Sample 1
                </StyledButton>
              </StyledFigure>
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <StyledFigure>
                <Image
                  src={Sample2_before}
                  height="150px"
                  width="120px"
                  alt=""
                />
                <StyledButton
                  variant="contained"
                  color="primary"
                  btnColor="#023e8a"
                  data-sample="sample2"
                  onClick={setSampleData}
                >
                  Sample 2
                </StyledButton>
              </StyledFigure>
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <StyledFigure>
                <Image src={Normal} height="150px" width="140px" alt="" />
                <StyledButton
                  variant="contained"
                  color="primary"
                  btnColor="#023e8a"
                  data-sample="sample3"
                  onClick={setSampleData}
                >
                  Sample 3
                </StyledButton>
              </StyledFigure>
            </Grid>
          </Grid>
          {resultData && (
            <>
              <Divider />
              <Grid container xs={12} direction="row" alignItems="center">
                <Grid item xs={12} md={5}>
                  <StyledFigure>
                    <Image src={resultData.image1} width="300px" alt="" />
                    <Typography>Selected Image</Typography>
                  </StyledFigure>
                </Grid>
                <Grid xs={12} md={2}>
                  <StyledButton
                    variant="contained"
                    btnColor="#023e8a"
                    color="primary"
                    onClick={showResult}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Execute
                  </StyledButton>
                </Grid>

                {resultData.imgText && (
                  <Grid item xs={12} md={4} justify="center">
                    <StyledFigure>
                      <Image src={resultData.image2} width="300px" alt="" />
                      <Typography>{resultData.imgText}</Typography>
                    </StyledFigure>
                  </Grid>
                )}
              </Grid>

              {resultData.result && (
                <Result>
                  <strong>Result:</strong>
                  {resultData.result}
                </Result>
              )}
            </>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(IncisionCareMangement);
const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #274c77;
`;
const Image = styled.img`
  border-radius: 3px;
`;
