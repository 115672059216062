import React, { memo, useState } from "react";
import {
  Grid, TextField, FormControl, MenuItem,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  HighchartContainer,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import timelinessOfCare_sol1 from "../../../assets/images/app/timelinessOfCare_sol1.webp";

const TimelinessOfCare = () => {
  const initialData = {
    emg_services: "",
    meets_criteria: "",
    rating: "",
    mortality: "",
    safety: "",
    re_admission: "",
    patient_exp: "",
    care_effectiveness: "",
    medical_imaging: "",
    hospital_type: "",
    ownership: "",
  };
  const boolenValues = ["True", "False"];
  const commonValues = [
    "Same as the national average",
    "Above the national average",
    "Below the national average",
  ];
  const ownership = [
    "Hospital Ownership_Government – Federal",
    "Hospital Ownership_Government - Hospital District or Authority",
    "Hospital Ownership_Government - Local",
    "Hospital Ownership_Government - State",
    "Hospital Ownership_Physician",
    "Hospital Ownership_Proprietary",
    "Hospital Ownership_Tribal",
    "Hospital Ownership_Voluntary non-profit - Church",
    "Hospital Ownership_Voluntary non-profit - Other",
    "Hospital Ownership_Voluntary non-profit - Private",
  ];

  const sample = {
    emg_services: "True",
    meets_criteria: "True",
    rating: 3.0,
    mortality: "Below the national average",
    safety: "Same as the national average",
    re_admission: "Above the national average",
    patient_exp: "Same as the national average",
    care_effectiveness: "Same as the national average",
    medical_imaging: "Below the national average",
    hospital_type: "Hospital Type_Acute Care Hospitals",
    ownership: "Hospital Ownership_Government – Federal",
  };

  const [parameters, setParameters] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "patientEngagement",
    "timelinessOfCare",
  );

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Eliminating Wait Time from Hospitals",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][0][0],
              y: data[1][0][1],
            },
            {
              name: data[1][1][0],
              y: data[1][1][1],
            },
            {
              name: data[1][2][0],
              y: data[1][2][1],
            },
            {
              name: data[1][3][0],
              y: data[1][3][1],
            },
            {
              name: data[1][4][0],
              y: data[1][4][1],
            },
            {
              name: data[1][5][0],
              y: data[1][5][1],
            },
            {
              name: data[1][6][0],
              y: data[1][6][1],
            },
            {
              name: data[1][7][0],
              y: data[1][7][1],
            },
            {
              name: data[1][8][0],
              y: data[1][8][1],
            },
            {
              name: data[1][9][0],
              y: data[1][9][1],
            },
          ],
        },
      ],
    });
  };

  const loadSampleData = () => {
    setParameters(sample);
  };

  const onChangeFields = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(value, name);
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  const getPrediction = () => {
    const {
      emg_services,
      meets_criteria,
      rating,
      mortality,
      safety,
      re_admission,
      patient_exp,
      care_effectiveness,
      medical_imaging,
      hospital_type,
      ownership,
    } = parameters;
    setResultData("");

    const params = `${emg_services},${meets_criteria},${rating},${mortality},${safety},${re_admission},${patient_exp},${care_effectiveness},${medical_imaging},${hospital_type},${ownership}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          formHighChart(res);

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Inspiration: The Hospitals are usually categorised on the basis of
            services offered, type of ownership, quality of facilities and
            several other parameters. In our research phase, we came across
            various Machine Learning algorithms which predicted Hospital Ratings
            for the above parameters but there was little to no research done to
            check the wait time at hospitals. Hence, This Machine Learning
            algorithm was developed to solve the problem of a patient to check
            how good the timeliness of a care is before booking an appointment.
          </p>
          <p>
            <b>Data :</b>
            {' '}
            The information contains general information about
            all: ; hospitals that have been registered with Medicare, including
            their addresses, type of hospital, and ownership structure. It also
            contains information about the quality of each hospital, in the form
            of an overall rating (1-5, where 5 is the best possible rating & 1
            is the worst), and whether the hospital scored above, same as, or
            below the national average for a variety of measures.
          </p>
          <p>
            This data was updated by CMS on July 25, 2017. CMS overall rating
            includes 60 of the 100 measures for which data is collected &
            reported on
            {" "}
            <a
              href="https://www.medicare.gov/hospitalcompare/search.html"
              target="_blank"
              rel="noreferrer"
            >
              Hospital Compare website.
            </a>
            Each of the measures have different collection/reporting dates, so
            it is impossible to specify exactly which time period this dataset
            covers. For more information about the timeframes for each measure,
            <br />
            see:
            {" "}
            <a
              href="https://www.medicare.gov/hospitalcompare/Data/Data-Updated.html#"
              target="_blank"
              rel="noreferrer"
            >
              https://www.medicare.gov/hospitalcompare/Data/Data-Updated.html#
            </a>
            <br />
            For more information about the data itself, APIs and a variety of
            formats,
            {' '}
            <br />
            see:
            {" "}
            <a
              href="https://data.medicare.gov/Hospital-Compare"
              target="_blank"
              rel="noreferrer"
            >
              https://data.medicare.gov/Hospital-Compare
            </a>
          </p>
          <p>
            <b>Analytics Functions</b>
            <br />
            Supervised Learning Techniques of Predictive Analytics are used to
            predict the timeliness care of hospitals. Algorithm XGBoost couple
            with EDA techniques was used for the prediction function. EDA
            techniques helped to understand the data before using Machine
            Learning techniques on it and to remove the unwanted data points.
          </p>
          <h4>Steps Taken</h4>
          <ul type={1}>
            <li>
              Categorical Variables, specifically ordinal variables were used to
              make bar charts to observe the data behavior
            </li>
            <li>
              The categorical variables (low/medium/high), Boolean Variables
              (Yes/No) were then converted to Dummy variables(1/2/3) using
              Encoding Techniques like Label Encoder, Factorization and One Hot
              Encoding
            </li>
            <li>
              Unnecessary Variables (Hospital Name, Address etc.) were dropped
              to increase the model efficiency
            </li>
            <li>
              Machine Learning Technique was used over this Dataset to predict
              the status of Timeliness of Care
            </li>
            <li>Results were validated against a validation set</li>
            <li>
              The factors affecting the result were recorded and plotted in a
              pie chart (Self Explanatory)
            </li>
          </ul>
          <p> A Feature Importance Graph is shown below</p>
          <center>
            <img width="60%" src={timelinessOfCare_sol1} />
          </center>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Eliminating_wait_time_hospital"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Eliminating_wait_time_hospital/data"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          <Grid container justify="center" alignContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Emergency Services"
                  name="emg_services"
                  onChange={onChangeFields}
                  value={parameters.emg_services}
                >
                  {boolenValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Meets Criteria for meaningful use of EHRs"
                  name="meets_criteria"
                  onChange={onChangeFields}
                  value={parameters.meets_criteria}
                >
                  {boolenValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Hospital Overall Rating"
                  name="rating"
                  onChange={onChangeFields}
                  value={parameters.rating}
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Mortality National Comparison"
                  name="mortality"
                  onChange={onChangeFields}
                  value={parameters.mortality}
                >
                  {commonValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Safety Of Care National Comparison"
                  name="safety"
                  onChange={onChangeFields}
                  value={parameters.safety}
                >
                  {commonValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Readmission national comparison"
                  name="re_admission"
                  onChange={onChangeFields}
                  value={parameters.re_admission}
                >
                  {commonValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Patient Experience National Comparison"
                  name="patient_exp"
                  onChange={onChangeFields}
                  value={parameters.patient_exp}
                >
                  {commonValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Effectiveness of care national comparison"
                  name="care_effectiveness"
                  onChange={onChangeFields}
                  value={parameters.care_effectiveness}
                >
                  {commonValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Efficient Use of Medical Imaging National Comparison"
                  name="medical_imaging"
                  onChange={onChangeFields}
                  value={parameters.medical_imaging}
                >
                  {commonValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Hospital Type"
                  name="hospital_type"
                  onChange={onChangeFields}
                  value={parameters.hospital_type}
                >
                  <MenuItem
                    key={0}
                    value="Hospital Type_Acute Care Hospitals"
                  >
                    Hospital Type_Acute Care Hospitals
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value="Hospital Type_Critical Access Hospitals"
                  >
                    Hospital Type_Critical Access Hospitals
                  </MenuItem>
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Hospital Ownership"
                  name="ownership"
                  onChange={onChangeFields}
                  value={parameters.ownership}
                >
                  {ownership.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
              </FormControl>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={loadSampleData}
              >
                Sample Data
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getPrediction}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <section>
              <Grid item xs={12}>
                {showSpinner && <Spinner text="Loading..." />}

                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`${resultData[0]}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer id="container_1" display />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </section>
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(TimelinessOfCare);
