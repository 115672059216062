import * as React from "react";

import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components";
import Plot from 'react-plotly.js';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import TextFormatIcon from '@material-ui/icons/TextFormat';
export default function DenseTable({ tableData, onClick, linkId, key = 'tableData' }) {
  return (
    <TableContainer>
      {tableData && (
        <StyledTable
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {tableData.ColumnName.map((field) => (
                <HeaderCol key={field} align="left" >{field}</HeaderCol>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.[key]?.map((row) => (
              <TableRow key={row.project_id}>
                {tableData.ColumnName.map((field, index) => (
                  field == linkId ? (
                    <StyledCell align="left" id={row[index]} data-name={row[index + 1]} onClick={onClick}>
                      {row[index]}
                    </StyledCell>
                  ) : (
                    <TableCell align="left">
                      {row[index]}
                    </TableCell>
                  )
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
    </TableContainer>
  );
}
export function VizTable({ tableData, columns, colType }) {

  const colTypeIcons = {
    pkey: <IconButton size="small"><VpnKeyIcon /></IconButton>,
    int: <FontAwesomeIcon icon={faHashtag} />,
    text: <IconButton size="small"><TextFormatIcon /></IconButton>
  }
  return (<TableContainer maxwidth='100%'>
    {tableData && (
      <StyledTable
        size="small"
        aria-label="a dense table"
        maxwidth={true}
      >
        <TableHead>
          <TableRow>
            {columns?.map((field) => (
              <HeaderCol key={field} align="center" bgColor='#4f4b4a'>{colTypeIcons[colType[field]]} &nbsp;{field}</HeaderCol>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>

          <TableRow>
            {tableData?.map((row) => (
              <TableCell align="left" id='vizcell'>
                {row.dataPercentile ?
                  <>
                    {Object.entries(JSON.parse(row.dataPercentile))?.map(data => (
                      <p>{data[0]}: {data[1]}</p>
                    ))}
                  </>
                  :
                  <Plot
                    data={[
                      {
                        x: row.x,
                        y: row.y,
                        type: 'bar',
                        width: 0.9,
                        hovertemplate: '<i>Bins</i>: %{x}<br>' + '<b>Count</b>: %{y}',
                        mode: 'markers',
                        marker: { size: 16 },
                      }
                    ]}
                    layout={{
                      width: 140,
                      height: 140,
                      hovermode: 'closest',
                      yaxis: {
                        visible: false
                      },
                      xaxis: {
                        visible: false,
                        showline: true,
                        linecolor: '#636363',
                        linewidth: 6
                      },
                      hoverlabel: {},
                      showlegend: false,
                      margin: {
                        l: 20,
                        r: 20,
                        b: 20,
                        t: 20,
                        pad: 4
                      }
                    }}
                    config={{
                      displayModeBar: false
                    }}

                  />}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </StyledTable>
    )}
  </TableContainer>
  );
}

export const HeaderCol = styled(TableCell)`
background: ${props => props.bgColor ? props.bgColor : 'rgb(60, 64, 175)'};
border-right: ${props => props.bgColor && '1px solid #ffff'};
color: white;
`;
export const StyledCell = styled(TableCell)`
&.MuiTableCell-root {
  text-decoration: underline;
  color: #575D90;
  cursor: pointer;
  
}


`;
export const StyledTable = styled(Table)`
  max-width: 100%;
  width: ${props => props.maxwidth && 'max-content'};
  #vizcell{
    min-width: 150px;
    border: 1px solid darkgray;
    
  }
  .MuiSvgIcon-root{
    font-size: 0.8rem,
    color: #ffff
  }
  @media only screen and (max-width: 1100px) {
    .MuiTableCell-root {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 600px) {
    .MuiTableCell-root {
      font-size: 12px;
      #vizcell{
        min-width: max-content;
      }
    }
  }
`;
export const TableContainer = styled(Paper)`
&.MuiPaper-root {
  max-height: ${props => props.maxwidth ? 'max-content' : '80%'};
  max-width: 100%;
  overflow: auto;
}
`;
