import React, { memo, useState, useEffect } from "react";
import {
    Grid,
    MenuItem,
    FormControlLabel,
    Checkbox,
    ListItemIcon,
    ListItemText,
    FormControl,
    Select,
    RadioGroup,
    FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import InfoToolTip from "../../components/InfoToolTip";
import { FormControlLabelInfo } from "../../InputForm";
import { validateForm, isObjKeyhasval } from '../../util';
import { StyledInput } from "../../InputForm";
import { CommonCheckbox } from "../ModelBuilding/LogisticRegression";
import { subFeatureData } from "../../constants";

export const RobustScalerForm = memo((props) => {
    const { userInputData } = useSelector((state) => state.vnet);
    const { initialData = null } = subFeatureData.Robust_Scaler;
    const dispatch = useDispatch();
    const [dropdownList, setdropdownList] = useState(null);
    const [selected, setSelected] = useState(userInputData?.target || []);
    const [allVal, setAllVal] = useState([]);
    const [result, setResult] = useState();
    const [error, setError] = useState({});
    const [fieldError, setFieldError] = useState(false);

    const validationData = {
        lower_quantile_range: { maxNum: 100000, onlyNum: true },
        upper_quantile_range: { minNum: -1, onlyInt: true },
    };

    const handleFieldError = (errData) => {
        setFieldError({
            ...fieldError,
            ...errData,
        });
    };

    const { validateOnBlur } = validateForm(validationData, handleFieldError);

    const handleChange = (e) => {
        const { value } = e.target;

        if (value[value.length - 1] === "all") {
            if ([allVal.length] > 1) {
                allVal.length = 0;
            } else {
                const vals = JSON.parse(result);
                allVal.push(...vals);
            }
            setSelected(selected.length === dropdownList.length ? [] : dropdownList);

            if (e.target.name) {
                dispatch(
                    setInputData({
                        ...userInputData,
                        [e.target.name]: allVal,
                    }),
                );
            }
            return;
        }
        if (e.target.name === "target") {
            dispatch(
                setInputData({
                    ...userInputData,
                    [e.target.name]: e.target.value,
                    columns: userInputData?.columns?.filter(
                        (col) => e.target.value?.indexOf(col) == -1,
                    ),
                }),
            );
        }
        if (e.target.name === "columns") {
            dispatch(
                setInputData({
                    ...userInputData,
                    [e.target.name]: e.target.value,
                }),
            );
        }
        setSelected(value);
        error[e.target.name] && setError({ ...error, [e.target.name]: false });
    };

    const getCheckboxVal = (checked) => (checked ? "True" : "False");

    const handleCheckBox = (e) => {
        const { value, name, checked = false } = e.target;
        dispatch(
            setInputData({
                ...userInputData,
                [name]:
                    ["with_centering", "with_scaling", "unit_variance"].indexOf(name) > -1
                        ? getCheckboxVal(checked)
                        : value,
            }),
        );
    };

    const handleOnChange = (e) => {
        console.log(e);
        const { value, name } = e.target;

        dispatch(
            setInputData({
                ...userInputData,
                [e.target.name]: value,
            }),
        );

        if (fieldError[name]) {
            setFieldError({ ...fieldError, [name]: false });
        }
    };

    const isAllSelected = dropdownList?.length > 0 && selected?.length === dropdownList?.length;

    useEffect(async () => {
        const result = await props.getCategories();
        setResult(result);
        console.log(result);
        if (result) {
            setdropdownList(JSON.parse(result));
        }
    }, []);

    const MenuProps = {
        PaperProps: {
            style: {
                width: 250,
            },
        },
    };

    const isFormValid = () => {
        const err = { ...error };

        if (userInputData?.target?.length == 0) {
            err.target = true;
        }
        if (userInputData?.columns?.length == 0) {
            err.columns = true;
        }
        setError(err);
        return isObjKeyhasval(err);
    };

    const handleFormReset = () => {
        dispatch(setInputData({
            ...initialData,
        }));
        setInputDisable("Default");
    };

    return (
        <ScalerContainer>
            <CenteredGrid
                container
                spacing={3}
                alignItems="center"
                alignContent="center"
            >
                <Grid item xs={3}>
                    <b>
                        {" "}
                        <InfoToolTip title="Target" info="Select the target feature(s)">
                            {" "}
                            Target
                        </InfoToolTip>
                    </b>
                </Grid>
                <Grid item xs={9}>
                    <FormControl fullWidth error={error.target}>
                        <Select
                            labelId="mutiple-select-label"
                            label="target"
                            multiple
                            name="target"
                            value={userInputData?.target || []}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            variant="outlined"
                        >
                            {dropdownList?.map((value) => (
                                <MenuItem key={value} value={value}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={userInputData?.target?.indexOf(value) > -1}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={value} />
                                </MenuItem>
                            ))}
                        </Select>
                        {error.target && <FormHelperText>Required Field</FormHelperText>}

                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <b>
                        {" "}
                        <InfoToolTip
                            title="Feature"
                            info="Select features to be scaled"
                        >
                            {" "}
                            Feature
                        </InfoToolTip>
                    </b>
                </Grid>
                <Grid item xs={9}>
                    <FormControl fullWidth error={error.columns}>
                        <Select
                            labelId="mutiple-select-label"
                            label="target"
                            multiple
                            name="columns"
                            value={userInputData?.columns || []}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            variant="outlined"
                        >
                            {dropdownList?.map((value) => (
                                <MenuItem
                                    key={value}
                                    value={value}
                                    disabled={userInputData?.target?.indexOf(value) > -1}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={userInputData?.columns?.indexOf(value) > -1}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={value} />
                                </MenuItem>
                            ))}
                        </Select>
                        {error.columns && <FormHelperText>Required Field</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControlLabelInfo
                        control={(
                            <CommonCheckbox
                                checked={userInputData?.with_centering}
                                name="with_centering"
                                onChange={handleCheckBox}
                            />
                        )}
                        label="With Centering"
                        info="If True, center the data before scaling."
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControlLabelInfo
                        control={(
                            <CommonCheckbox
                                checked={userInputData?.with_scaling}
                                name="with_scaling"
                                onChange={handleCheckBox}
                            />
                        )}
                        label="With Scaling"
                        info="If True, scale the data to interquartile range."
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControlLabelInfo
                        control={(
                            <CommonCheckbox
                                checked={userInputData?.unit_variance}
                                name="unit_variance"
                                onChange={handleCheckBox}
                            />
                        )}
                        label="Unit Variance"
                        info="If True, scale data so that normally distributed features have a variance of 1"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <StyledInput
                        name="lower_quantile_range"
                        label="Lower Quantile Range"
                        variant="outlined"
                        onChange={handleOnChange}
                        fullWidth
                        value={userInputData?.lower_quantile_range}
                        onBlur={validateOnBlur}
                        error={fieldError.lower_quantile_range}
                        helperText={fieldError.lower_quantile_range}
                        info="Lower quantile range used to calculate scale"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <StyledInput
                        name="upper_quantile_range"
                        label="Upper Quantile Range"
                        variant="outlined"
                        onChange={handleOnChange}
                        fullWidth
                        value={userInputData?.upper_quantile_range}
                        onBlur={validateOnBlur}
                        error={fieldError.upper_quantile_range}
                        helperText={fieldError.upper_quantile_range}
                        info="Upper quantile range used to calculate scale"
                    />
                </Grid>
            </CenteredGrid>
            {props.render({ handleFormReset, isFormValid })}
        </ScalerContainer>
    );
});
export const ScalerContainer = styled.section`
  text-align: left;
  min-width: ${props => props.width};
  .MuiTextField-root {
    min-width: 190px;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;

const CenteredGrid = styled(Grid)`
  text-align: center;
`;
