import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

// import local components
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import imagedeIdentification_sol1 from "../../../assets/images/app/ImagedeIdentification-sol.webp";
import imagedeIdentification_sample1 from "../../../assets/images/app/imagedeidentification2.webp";
import imagedeIdentification_sample2 from "../../../assets/images/app/imagedeidentification1.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const { getQueryResult, uploadFile } = CommonService("patientEngagementProduction", "imagedeIdentification");

const ImagedeIdentification = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadresult, setUploadResult] = useState("");
  const [imageResult1, setImageResult1] = useState("");
  const [imageResult2, setImageResult2] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    setUploadResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        setImageResult2(res.result);
        setImageResult1("");
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    setUploadResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setUploadResult(res);
        setResult(res);
        setImageResult1(res.input_data);
        setImageResult2(res.output_data);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Medical images are a foundational tool in modern medicine that enable clinicians to visualize critical information about a patient to help diagnose and treat them. The digitization of medical images has vastly improved our ability to reliably store, share, view, search, and curate these images to assist our medical professionals. The number of modalities for medical images has also increased. From CT scans to MRIs, digital pathology to ultrasounds, there are vast amounts of medical data collected in medical image archives.</p>
          <p>
            These medical images are also useful for medical research. Using machine learning, scientists at global medical research institutions can analyze hundreds of thousands or millions of images to deepen their insight into medical issues. The challenge for health professionals is how to use these images while complying with regulatory obligations like the Health Information Portability and Accountability Act (HIPAA). Often, medical images contain Protected Health Information (PHI) stored as text within the image itself. This has historically presented a challenge because the process of removing PHI, called de-identifying, required the manual review and editing of images. This manual process can easily take many minutes per image and makes de-identifying large datasets time consuming and expensive.
            Here, we are trying to solve this problem where one can de-identify the image within seconds using AWS Comprehend Medical and AWS Rekognition
          </p>
          <center><img width="60%" src={imagedeIdentification_sol1} /></center>
          <p>Amazon Rekognition is used to extract text from the images and Amazon Comprehend Medical is used to identify and detect the PHI.</p>

          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ImageDeIdentification/MedicalImageDeIdentification_.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://wiki.cancerimagingarchive.net/pages/viewpage.action?pageId=80969777"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<CloudDownloadIcon />}
                >
                  <PresignedS3Link
                    href="ImageDeIdentification/Sample/IDI_SAMPLE1.dcm"
                    rel="noreferrer"
                    download
                  >
                    Download Sample .DCM File
                  </PresignedS3Link>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={5} md={5}>
              <Image src={imagedeIdentification_sample1} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1/"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={10} sm={5} md={5}>
              <Image src={imagedeIdentification_sample2} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample2/"
                onClick={getsampleResult}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={9} sm={5} md={3}>
              <TextField
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
                helperText="please upload only jpg/jpeg/png/dcm formats"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container spacing={2} xs={12}>
              <Grid item xs={12} md={12}>
                <Grow in={result} timeout={1500}>
                  <div>
                    <section>
                      <Result>
                        <p>
                          <b>Result : </b>
                          The de-identified image is as below
                        </p>
                      </Result>
                    </section>
                    <Grid container direction="row" justify="center" alignItems="center">
                      {uploadresult && (
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography variant="h6" gutterBottom>Original Image </Typography>
                          <ResultImg
                            width="70%"
                            height="340px"
                            src={imageResult1}
                            alt=""
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="h6" gutterBottom>De-identified Image </Typography>
                        <ResultImg
                          width="70%"
                          height="340px"
                          src={imageResult2}
                          alt=""
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grow>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ImagedeIdentification);

const Image = styled.img`
  width: 300px;
  height: 300px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 220px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
  }
`;

const ResultImg = styled.img`
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 250px;
    height: 200px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    height: 200px;
  }
`;
