import React, { memo, useState, useEffect } from "react";
import {
  Grid,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setInputData } from "../../../../../../store/actions/vnet";
import { StyledButton } from "../../../../../../styles/common";
import { FormControlLabelInfo } from "../../InputForm";
import { InfoText } from "../ModelOptimization/DataShapley";

export const LabelEncoderForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const [dropdownList, setdropdownList] = useState(null);
  const dispatch = useDispatch();
  const [selectedColumn, setChecked] = useState({});
  const [alert, setAlert] = useState(false);

  useEffect(async () => {
    const result = await props.getCategories('From', 'Categorical');
    // let result = "[\"pclass1\", \"gender\", \"sibsp\", \"parch\", \"fare\", \"survived\"]";
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }

    if (userInputData?.columns) {
      const selectAll = {};
      userInputData?.columns.map((key) => {
        selectAll[key] = true;
      });
      setChecked({
        ...selectAll,
      });
    }
  }, []);

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (e.target.name) {
      if (name !== "SelectAll") {
        const latestCheckList = {
          ...selectedColumn,
          [name]: checked,
        };
        if (selectedColumn.all && checked == false) {
          latestCheckList.all = false;
        }
        setChecked({
          ...latestCheckList,
        });

        const updatedList = Object.keys(latestCheckList).filter(
          (key) => latestCheckList[key],
        );
        console.log(updatedList);
        dispatch(
          setInputData({
            columns: updatedList,
          }),
        );
      } else {
        const selectAll = {};
        dropdownList.map((key) => {
          selectAll[key] = checked;
        });
        setChecked({
          ...selectAll,
          all: checked,
        });
        dispatch(
          setInputData({
            columns: checked ? [...dropdownList] : [],
          }),
        );
      }
    }
    alert && setAlert(false);
  };

  const handleFormReset = () => {
    console.log('reset');
  };

  const isFormValid = () => {
    if (!userInputData?.columns || userInputData?.columns?.length == 0) {
      setAlert(true);
      return false;
    }
    return true;
  };
  return (
    <SelectContainer>
      {alert && (
      <InfoText success={false}>
        {' '}
        <FontAwesomeIcon icon={faInfoCircle} />
        {' '}
        Please choose atleast one column
      </InfoText>
      )}

      <FormControlLabelInfo
        control={(
          <Checkbox
            name="SelectAll"
            onChange={handleCheckBox}
            value="SelectAll"
            checked={!!selectedColumn.all}
          />
        )}
        label="Select All"
        info="Categorical features(if any) will be label encoded"
      />
      <br />
      <StyledDiv>
        <CheckboxGrid container spacing={3} alignItems="flex-start">
          {dropdownList?.map((value) => (
            <>
              <Grid item xs={4} sm={4} md={4}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name={value}
                      onChange={handleCheckBox}
                      checked={!!selectedColumn[value]}
                      value={value}
                    />
                  )}
                  label={value}
                />
              </Grid>
            </>
          ))}
        </CheckboxGrid>
      </StyledDiv>
      <br />

      {props.render({ handleFormReset, isFormValid })}

    </SelectContainer>
  );
});

const SelectContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
    min-width: 190px;
  }
`;

const StyledDiv = styled(Grid)`
  border: 1px solid grey;
  border-radius: 4px;
`;

const CheckboxGrid = styled(Grid)`
  margin-left: 10px;
  overflow: auto;
`;

const StyledButtons = styled(StyledButton)`
  text-align: left;
`;
