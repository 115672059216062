const MainViewStyle = (theme) => ({
  patternOverlay: {
    background: "url(http://s14.directupload.net/images/111129/44ga9qid.png)",
    height: '750px',
  },
  dividerWhite: { width: '100%', backgroundColor: 'white' },
  platContent: { fontSize: '14px', textAlign: 'left' },
  borderRight: { borderRight: '3px solid white' },
  contactusBtn: { color: 'white', border: '1px solid white' },
  featureContent: {
    borderRadius: '10px',
    color: '#696a6a',
    maxWidth: '80%',
    marginLeft: '8px',
  },
  justifyCenter: {
    justify: 'center',
  },
  pricingContent: {
    borderRadius: '10px',
    padding: '5px 5px 5px 0',
    color: '#696a6a',
    width: 'auto',
    marginLeft: '8px',
  },
  staticPricingContent: {
    borderRadius: '10px',
    color: '#696a6a',
    minWidth: '15%',
    margiLeft: '8px',
    padding: '5px 5px 5px 0',
    '&:hover': {
      borderRadius: '10px',
      color: '#696a6a',
      transform: "scale(1.04)",
      zIndex: '1',
      cursor: 'pointer',
    },
  },
  selectedContent: {
    borderRadius: '4px',
    padding: '5px 5px 5px 0',
    margiLeft: '8px',
    color: '#696a6a',
    transform: "scale(1.06)",
    zIndex: '1',
    width: '22%',
    cursor: 'pointer',
  },
  pad5: { padding: '5px' },
  greenIcon: {
    color: '#07d054',
  },
  height100: {
    height: '100%',
    borderRadius: '10px',
  },
  redIcon: {
    color: '#e24556',
  },
  pricingCardContainer: { display: 'flex', alignContent: 'center' },
  pricingHeader: {
    height: '130px',
    color: 'white',
    flexDirection: 'column',
    backgroundColor: '#fb8c00',
    justifyContent: 'center',
    padding: '3px',
    borderRadius: '10px',
  },
  silverHeader: {
    height: '130px',
    color: 'white',
    flexDirection: 'column',
    padding: '3px',
    backgroundColor: '#0b6bbf',
    borderRadius: '10px',
    justifyContent: 'center',
    padding: '5px',

  },
  silverBtn: {
    color: 'white',
    backgroundColor: '#1565c0',
    '&:hover': {
      background: '#1565c0',
    },
  },
  goldHeader: {
    height: '130px',
    color: 'white',
    flexDirection: 'column',
    backgroundColor: '#0b6bbf',
    padding: '3px',
    borderRadius: '10px',
    justifyContent: 'center',
    padding: '5px',

  },
  goldBtn: {
    color: 'white',
    backgroundColor: '#0b6bbf',
  },
  premiumHeader: {
    height: '130px',
    color: 'white',
    flexDirection: 'column',
    backgroundColor: '#0b6bbf',
    textAlign: 'center',
    borderRadius: '10px',
    justifyContent: 'center',
    padding: '5px',
  },
  premiumBtn: { color: 'white', backgroundColor: '#0b6bbf' },
  heroContainer: {
    paddingLeft: "100px",
    paddingRight: "100px",
    outline: "none",
  },
  title: {
    display: "inline-block",
    position: "relative",
    fontSize: "50px",
    fontWeight: 700,
    textAlign: "Left",
    marginBottom: "0px",
    color: "white",
  },
  subTitle: {
    display: "inline-block",
    position: "relative",
    fontSize: "30px",
    fontWeight: 400,
    textAlign: "Left",
    color: "white",
    marginBottom: "50px",
  },

  bannerButton: {
    marginRight: "30px",
    minWidth: "130px",
    color: '#ffffff',
    borderWidth: '2px',
    borderColor: '#ffffff',
    '&:hover': {
      background: 'linear-gradient(45deg, #EB3349 30%, #F45C43 90%)',
      color: '#ffffff',
      borderWidth: "2px",
      borderColor: "transparent",
    },
  },
  bannerIconButton: {
    marginRight: "30px",
    minWidth: "130px",
    color: '#ffffff',
    borderWidth: '2px',
    borderColor: '#ffffff',
    paddingLeft: "15px",
    '&:hover': {
      background: 'linear-gradient(45deg, #EB3349 30%, #F45C43 90%)',
      color: '#ffffff',
      borderWidth: "2px",
      borderColor: "transparent",
    },
  },
  bannerButtonIcon: {
    margiLeft: "0px",
    paddingRight: "10px",
  },
  linkButton: {
    textDecoration: 'none',
    color: 'inherit',
  },
  downloadIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '5px',
    textDecoration: 'none',
  },
  modelString: { textAlign: 'justify', margin: '20px' },
  invertImageColor: { filter: 'invert(1)' },
  alert: {
    height: '50',
    width: '100%',
    marginTop: '5px',
    fontSize: '10px',
  },
  h1Heading: {
    fontSize: "65px",
    fontWeight: 600,
    color: "white",
  },
  container: {
    padding: "120px 75px 150px 75px",
    background: "#ffffff",
  },
  hostAppContainer: {
    padding: "60px 30px 15px 50px",
    background: "#ffffff",
    margin: '10%',
  },
  container1: {
    padding: "120px 100px 20px 100px",
    background: "#ffffff",
  },
  CAcontainer: {
    padding: "10px 100px 150px 100px",
    background: "#ffffff",
  },
  mockcontainer: {
    padding: "100px 100px 150px 100px",
  },
  pricingContainer: {
    margin: '75px 40px 20px 40px',
  },
  containerComponentIntro: {
    padding: "0px 100px 50px 100px",
    background: "#ffffff",
  },
  containerData: {
    padding: "120px 100px 100px 100px",
    background: "#ffffff",
  },
  containerData1: {
    padding: "50px 100px 100px 100px",
    background: "#ffffff",
  },
  CAcontainerData: {
    padding: "120px 100px 10px 100px",
    background: "#ffffff",
  },
  imageContainer: {
    padding: "20px 70px 20px 70px",
  },

  titleClient: {
    fontSize: "32px",
    textAlign: "left",
    fontWeight: 300,
    color: "#333333",
  },
  titleClientSmall: {
    fontSize: "24px",
    textAlign: "left",
    fontWeight: 200,
    color: "#333333",
    margin: "20px 0px",
  },
  titleClientData: {
    fontSize: "40px",
    textTransform: "uppercase",
    fontWeight: 700,
    color: "#333333",
    margin: "0px 100px -100px 100px",
  },
  h2Heading: {
    fontSize: "65px",
    fontWeight: 600,
    color: "white",
  },
  h3Heading: {
    fontSize: "40px",
    fontWeight: 700,
    color: "white",
  },
  textCenter: {
    textAlign: "center",
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    color: "gray",
  },
  card: {
    maxWidth: 350,
    margin: "10px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  imageCard: {
    margin: "10px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  media: {
    height: 200,
  },
  cardMS: {
    maxWidth: 600,
    margin: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "250px",
  },
  btn: {
    width: "100%",
    borderWidth: "1px",
    margin: "0px 15px 0px 15px",
    fontSize: "15px",
    '&:hover': {
      background: 'linear-gradient(45deg, #EB3349 30%, #F45C43 90%)',
      color: '#ffffff',
      borderWidth: "1px",
      borderColor: "transparent",
    },
  },
  fasterAiBtn: {
    width: "100%",
    borderWidth: "1px",
    fontSize: "15px",
    '&:hover': {
      background: 'linear-gradient(45deg, #EB3349 30%, #F45C43 90%)',
      color: '#ffffff',
      borderWidth: "1px",
      borderColor: "transparent",
    },

  },
  resultTitle: {
    fontFamily: 'Titillium Web, sans-serif',
    color: '#4a4a4a',
    margin: '0 0 26px',
    fontWeight: '600',
    '&:hover': {
      color: '#ff5325',
    },
  },
  romPaper: {
    width: '100%',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  maskPaper: {
    width: '100%',
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
  },
  ocrImage: {
    width: '100%',
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerStyle1: {
    backgroundColor: "#1e219f",
    '& span': {
      color: 'white',
      fontSize: '18px',
    },
  },
  headerStyle2: {
    height: 40,
    '& span': {
      fontSize: '15px',
    },
  },
  cardRoot: {
  },
  cardGrid: {
    textDecoration: 'none',
  },

  landingPageCardHeader: {
    height: 40,
  },

  landingPageCardMedia: {
    height: 100,
    width: '100%',
  },

  landingPageCardTypography: {
    // height: 100,
    fontSize: '13px',
    marginBottom: 0,
    verticalAlign: 'center',
  },

  landingPageCardActions: {
    justifyContent: "center",
  },

  solutionListGrid: {
    paddingBottom: 30,
  },

  mediaAAS: {
    height: 100,
    width: 100,
    margin: "0px 15px 0px 120px",
  },
  mediaMS: {
    height: 250,
  },
  cardData: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  mediaNA: {
    width: 317,
    height: 186,
  },
  para: {
    color: 'purple',
    fontStyle: 'italic',
    marginTop: 14,
  },
  header: {
    color: '#3C40AF',
    textAlign: 'center !important',
    textDecoration: 'underline',
    marginTop: 35,
  },
  head: {
    marginTop: 32,
    fontSize: 20,
    color: '#3C40AF',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  list1: {
    marginTop: 30,
    textAlign: 'left',
    marginLeft: 100,
  },
  list2: {
    textAlign: 'left',
    marginLeft: 338,
    top: 656,
    position: 'absolute',
  },
  list3: {
    textAlign: 'left',
    marginLeft: 565,
    top: 656,
    position: 'absolute',
  },
  marketscanp: {
    marginTop: 30,
    textAlign: 'left',
    marginLeft: 249,
    color: '#1581bf',
  },
  marketscanp1: {
    marginTop: 30,
    textAlign: 'left',
    color: '#1581bf',
  },
  whiteFont: {
    color: '#fff',
  },
  mediaCA: {
    height: '455px',
  },
  headingCA: {
    color: '#e29b2d',
    fontSize: '20px',
    textAlign: 'left',
    marginTop: '11px',
  },
  bodyCA: {
    textAlign: 'left',
  },
  cardCA: {
    margin: '10px',
    maxWidth: '535px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  headingCA1: {
    color: 'purple',
    textAlign: 'left',
    fontSize: '25px',
  },
  cardCA1: {
    margin: '10px',
    maxWidth: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },
  mediaCA1: {
    height: '645px',
  },
  headingCA2: {
    color: '#e29b2d',
    fontSize: '20px',
    textAlign: 'left',
    marginTop: '50px',
  },
  mediaCA2: {
    height: '580px',
  },
  table: {
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid white',
    width: '1100px',
    float: 'left',
    verticalAlign: 'middle',
    marginBottom: '20px',
    textAlign: 'center',
    borderCollapse: 'collapse',
  },
  table1: {
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid white',
    float: 'left',
    verticalAlign: 'middle',
    marginBottom: '20px',
    textAlign: 'center',
    borderCollapse: 'collapse',
  },

  dropdown: {
    height: '80px',
    width: '250px',
    marginLeft: '100px',
    // textSize:'50px',
    // fontWeight:'bolder',
  },

  button: {
    textAlign: 'left',
    backgroundColor: '#3C40AF !important',
    color: '#FFFFFF',
    top: '200px',
    position: 'center',
    left: '15%',
    // marginTop:'500px',
    fontSize: '18px',
    transition: '.5s ease',

  },

  width100: {
    width: '100%',
  },

  width95: {
    width: '95%',
  },

  width60: {
    width: '60%',
  },
  width70: {
    width: '70%',
  },
  width80: {
    width: '80%',
  },
  width50: {
    width: '50%',
  },
  width30: {
    width: '30%',
  },

  genomicsButton: { // For Genomics
    textAlign: "center",
    marginRight: "30px",
    marginTop: "30px",
    marginLeft: "30px",
    minWidth: "130px",
    color: '#142792',
    borderWidth: '2px',
    borderColor: '#142792',
    paddingLeft: "22px",
    '&:hover': {
      background: 'linear-gradient(45deg, #EB3349 30%, #F45C43 90%)',
      color: '#ffffff',
      borderWidth: "2px",
      borderColor: "transparent",
    },
  },

  check: {
    marginLeft: '200px',
    fontWeight: 'bolder',

  },
  tableHeader: {
    backgroundColor: '#3C40AF !important',
    color: 'white',
    align: 'left',
  },
  tableHeader1: {
    backgroundColor: '#FC7703 !important',
    color: 'white',
    align: 'left',
  },
  tableHeader2: {
    backgroundColor: 'grey !important',
    color: 'white',
    align: 'left',
  },
  tableRow: {
    '&:hover': {
      transform: "scale(1.1)",
      backgroundColor: '#d8dded',
    },
  },
  tableMargin: {
    margin: '8px',
  },
  scrollableTable: {
    overflowX: "scroll",
  },
  radio: {
    marginLeft: '200px',
    fontWeight: 'bolder',
  },

  count: {
    marginLeft: '200px',
    fontWeight: 'bolder',
  },

  chartdropdown: {
    height: '150px',
    width: '250px',
    marginLeft: '0px',
    fontWeight: 'bold',
  },

  th: {
    fontWeight: 'bold',
  },

  bigPicture: {
    width: '100%',
    height: '200px',
  },

  h6: {
    textAlign: "left",
    paddingTop: "20px",
  },

  paddingLeftAlign: {
    textAlign: "left",
    paddingTop: "20px",
    paddingLeft: "20px",
  },

  paddingLeftAlignOl: {
    textAlign: "left",
    paddingLeft: "40px",
    listStyleType: "disc !important",
  },

  padding30Top: {
    padding: "30px 0px 0px 0px",
    textAlign: "left",
  },
  buttonBTS: {
    borderWidth: "1px",
    margin: "0px 15px 0px 15px",
    fontSize: "15px",
    backgroundColor: "#1e219f",
    color: "white",
    '&:hover': {
      background: 'linear-gradient(45deg, #EB3349 30%, #F45C43 90%)',
      color: '#ffffff',
      borderWidth: "1px",
      borderColor: "transparent",
    },
  },
  promptPanel: {
    overflowY: 'scroll',
    height: '180px',
  },
  inputGif: {
    margin: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    width: "170px",
    height: "350px",
  },
  padding30: {
    paddingTop: "30px",
  },
  outputGif: {
    margin: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    width: "240px",
  },
  outputGifGrid: {
    paddingTop: "60px",
  },
  margin12: {
    marginRight: "8px",
  },
  width600: {
    width: "600px",
  },
  bordernormal: {
    border: "solid 1px black",
  },
  margin10: {
    margin: '10px',
  },
  margin2: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  leftText: {
    textAlign: "left",
  },
  justifyText: {
    textAlign: "justify",
  },
  justifyLeftMain: {
    textAlign: "justify",
    marginLeft: "20px",
    '& p,ul': {
      color: '#666',
      lineHeight: 1.5,
    },
    '& h6,strong': {
      color: '#333333',
    },

  },
  rightText: {
    textAlign: "right",
  },
  playButtonContainer: {
    width: "164px",
    height: "164px",
    borderRadius: "100px",
    display: "flex",
    marginLeft: "20px",
    alignItems: "center",
    justifyContent: "center",
    background: "-webkit-linear-gradient(330deg,rgba(255,255,255,0) 0%,rgba(255,255,255,.2) 100%)",
    background: "linear-gradient(120deg,rgba(255,255,255,0) 0%,rgba(255,255,255,.2) 100%)",
    boxShadow: "0 24px 72px 0 rgba(0,0,0,.5)",
    transition: "300ms all cubic-bezier(.4,0,.2,1)",
    '&:hover': {
      cursor: "pointer",
      transform: "scale(.96)",
    },
  },
  playImageContainer: {
    width: "380px",
    height: "380px",
    display: "flex",
    borderRadius: "16px",
    alignItems: "center",
    justifyContent: "center",
    background: "-webkit-linear-gradient(330deg,rgba(255,255,255,0) 0%,rgba(255,255,255,.2) 100%)",
    background: "linear-gradient(120deg,rgba(255,255,255,0) 0%,rgba(255,255,255,.2) 100%)",
    boxShadow: "0 24px 72px 0 rgba(0,0,0,.5)",
    transition: "300ms all cubic-bezier(.4,0,.2,1)",
    '&:hover': {
      cursor: "pointer",
      transform: "scale(.96)",
    },
  },
  playImg: {
    zIndex: "2",
    width: "280px",
    height: "280px",
    borderRadius: "8px",
    background: 'white',
    boxShadow: "0 8px 16px 0 rgba(0,0,0,.3)",
    display: "-webkit-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "300ms all cubic-bezier(.4,0,.2,1)",
    '&:hover': {
      transform: "scale(1.16)",

    },
  },
  playButton: {
    zIndex: "2",
    width: "120px",
    height: "120px",
    borderRadius: "100%",
    background: "#fff",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,.3)",
    display: "-webkit-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "300ms all cubic-bezier(.4,0,.2,1)",
    '&:hover': {
      transform: "scale(1.16)",
    },

  },

  playButtonSvg: {
    width: "34px",
    height: "34px",
  },
  videoPopup: {
    Index: "3",
  },
  a: {
    textDecoration: "none",
    cursor: "pointer",
  },
  mainViewbtn: {
    color: "white",
    boxShadow: "0 24px 72px 0 rgba(0,0,0,.5)",
    transition: "300ms all cubic-bezier(.4,0,.2,1)",
    '&:hover': {
      transform: "scale(1.16)",
    },
  },
  audioWave: {
    borderRadius: "50px",
    padding: "10px",
  },
  audio: {
    padding: "10px",
  },
  audioBtn: {
    margin: "10px",
  },
  slider: {
    margin: '0 20px 0px 20px',
  },
  textColor: {
    color: '#3f51b5',
  },
  dividerColor: {
    backgroundColor: "#fc7703",
    height: "3px",
    margin: "0px 0px 10px 0px",
  },
  singanTable: { height: '350px', overflowY: 'scroll', overflowX: 'hidden' },
});

export default MainViewStyle;
