import React from "react";
import styled from "styled-components";
import { Grid, Divider } from "@material-ui/core";

import logoWhite from "../../../assets/logos/virtusa-logo-white.webp";
import logoLg from "../../../assets/logos/virtusa-logo-lg.webp";
import SocialShare from "../../common/SocialShare";

export default function Footer({
  isInverted = false,
  hideSocialShare = false,
  useCaseTitle,
}) {
  const year = new Date().getFullYear();

  return (
    <FooterContainer isInverted={isInverted}>
      <Grid container justify="flex-start" alignItems="center">
        <Grid item xs={12} md={10}>
          <img src={isInverted ? logoWhite : logoLg} alt="" />
        </Grid>
        {!hideSocialShare && (
          <Grid item xs={12} md={2}>
            <SocialShare changeColor={isInverted} text={useCaseTitle} />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={10}>
          <p className="copyright">
            <strong>Copyright ©{year} Virtusa Corp.</strong> All rights
            reserved.
          </p>
        </Grid>
        <Grid item xs={12} md={2}>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href='https://www.virtusa.com/privacy-statement' target='_blank'>Privacy Policy</a>
        </Grid>
      </Grid>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  padding: ${(props) => (props.isInverted ? "50px 30px" : "30px")};
  margin: 0px;
  background-color: ${(props) => props.isInverted && "#2941b7"};
  border-radius: ${(props) => props.isInverted && "0px"};
  color: ${(props) => (props.isInverted ? "#ffffff" : "#2941b7")};
  img {
    height: 50px;
  }
  p {
    color: ${(props) => (props.isInverted ? "#ffffff" : "#2941b7")};
    padding-left: 18px;
    font-size: 11px;
    font-weight: 200;
  }
  a{
    text-decoration: none;
    color: ${(props) => (props.isInverted ? "#ffffff" : "#2941b7")};
    &:hover{
      text-decoration: underline;
    }
  }
  hr {
    background-color: #fc7703;
    height: 3px;
    margin: 5px 20px;
  }
  @media only screen and (max-width: 960px) {
    padding: 10px;
    img {
      height: 30px;
    }
    hr {
      margin: 3px 8px;
    }
    p{
      padding-left: 10px;
    }
    

`;
