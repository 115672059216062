import React from 'react';
import Highcharts from 'highcharts/highmaps';
import tilemap from 'highcharts/modules/tilemap';
import PropTypes from 'prop-types';
import CircularProgress from "../../common/loading/Spinner";
import CommonService from '../../../utils/services/CommonService';

const { getQueryResult } = CommonService("ibri", "digitalTwins");

window.Highcharts = Highcharts;
tilemap(Highcharts);

class UsState extends React.Component {
  constructor(props) {
    super(props);
    console.log('UsState');
    this.btnClick = this.btnClick.bind(this);
    this.state = {
      mainPage: true,
      map_data: null,
    };
  }

  btnClick(name) {
    console.log(name);
    if (name === "Indiana") {
      console.log(name);
      this.setState({ mainPage: false });
    }
  }

  createGraph() {
    console.log(this.state.map_data);
    const { onSelectState } = this.props;

    Highcharts.setOptions({
      colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
    });
    Highcharts.chart('us_main', {
      chart: {
        type: 'tilemap',
        inverted: true,
        height: '80%',
      },
      title: {
        text: '',
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      colorAxis: {
        visible: false,
      },
      tooltip: {
        headerFormat: '<b>{point.key}<br/>',
        pointFormat: 'The population of <b> {point.name}</b> is <b>{point.value}</b>',
      },
      series: [{
        mapData: this.state.map_data,
        dataLabels: {
          enabled: true,
          format: '{point.hc-a2}',
          color: '#f45c42',
          style: {
            textOutline: false,
          },
        },
        data: this.state.map_data,
      }],
      plotOptions: {
        series: {
          point: {
            events: {
              click() {
                if (this.name === "Indiana") {
                  this.setState({ mainPage: false });
                  onSelectState(this.drilldown);
                }
              },
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

  render() {
    return (
      <div>
        <div id="us_main"><CircularProgress /></div>
      </div>
    );
  }

  componentDidMount() {
    getQueryResult('UsState_url')
      .then((response) => {
        console.log(response);
        this.setState({ map_data: response });
        this.createGraph();
      })
      .catch((error) => {
        console.log(error);
        console.log(this.state.map_data);
      });
  }
}

UsState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default UsState;
