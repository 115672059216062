import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  MenuItem,
  TextField,
  TableRow, TableHead, TableCell, TableBody, Table,
} from "@material-ui/core";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import marketscan from "../../../assets/images/app/marketscan.webp";
import JsonDataTable from "../../common/JsonDataTable";

const IBMMarketScan = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [tableName, settableName] = useState("");
  const [result, setResult] = useState("");
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "z1dApps", "marketscan",
  );

  const tableList = [
    {
      label: 'Enrollment Details',
      value: 'Edetails',
    },
    {
      label: 'Annual Enrollment',
      value: 'enrollmentdetails',
    },
    {
      label: 'Facility Header',
      value: 'facilityheader',
    },
    {
      label: 'Inpatient Admission',
      value: 'inpatientadmission',
    },
    {
      label: 'Inpatient services',
      value: 'inpatientservice',
    },
    {
      label: 'Laboratory Test Results',
      value: 'labtest',
    },
    {
      label: 'Out Patient claims',
      value: 'outpatientclaims',
    },
    {
      label: 'Weights',
      value: 'weights',
    },
  ];
  const getSampleData = (e) => {
    setSpinner(true);
    setResult("");
    settableName(e.target.value);
    getQueryResult("sample", `${e.target.value}/`)
      .then((res) => {
        setSpinner(false);
        const previewData = JSON.parse(res.Data);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setResult({ fields, dataRows });
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <LinkContainer>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item xs={12} sm={6}>
                <img src={marketscan} width="auto" alt="" />
              </Grid>

              <Grid item xs={12} sm={4}>
                IBM® MarketScan® Claims: 186M​ Records
                {' '}
                <br />
                Explorys EMR: 55M​ Records
                {' '}
                <br />
                Claims+EMR linked: 4.3M Records

                <ul>
                  <li>240M unique patients​​</li>
                  <li>20B+ records​​</li>
                  <li>260+ contributing employers​​</li>
                  <li>350 unique carrier representations​​</li>
                  <li>40 contributing health plans</li>
                </ul>
              </Grid>

            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                select
                variant="outlined"
                label="Market Scan"
                name="tableName"
                value={tableName}
                fullWidth
                onChange={getSampleData}
              >
                <MenuItem key="select" value="select">
                  Select Table Name
                </MenuItem>
                {tableList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {result && (
            <Grid item xs={12}>
              <JsonDataTable fields={result.fields} dataRows={result.dataRows} />
            </Grid>
            )}
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}

        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(IBMMarketScan);
