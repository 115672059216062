import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import ErrorIcon from '@material-ui/icons/Error';
import CohortSenseService from "./CohortSenseService";
import CommonStyles from './commonStyles';

class TraditionalModelSection extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeTrainToTest = this.onChangeTrainToTest.bind(this);
    this.onChangeModelTuningFields = this.onChangeModelTuningFields.bind(this);
    this.onSubmitModelTuning = this.onSubmitModelTuning.bind(this);
    this.onCloseSnack = this.onCloseSnack.bind(this);
    this.state = {
      openModelTuningDialog: false,
      modelSelection: '',
      isDisabled: false,
      isError: false,
      resultAvailable: true,
      result: {},
      trainTestSplit: '',
      modelTuning: {
        modelType: '',
        allMetrics: [],
        dataSize: '',
        trainTestSplit: '',
        result: {},

      },
    };
  }

  onChangeModelTuningFields(event) {
    const { value } = event.target;
    const { name } = event.target;

    this.setState((prevState) => ({
      modelTuning: {
        ...prevState.modelTuning,
        [name]: value,
      },
    }));
  }

  onCloseSnack() {
    this.setState({
      isError: false,
    });
  }

  onChangeTrainToTest(event) {
    const { value } = event.target;

    this.setState({
      trainTestSplit: value,
    });
  }

  onSubmit() {
    const { trainTestSplit } = this.state;

    let areValuesEmpty = false;
    if (trainTestSplit === '') {
      areValuesEmpty = true;
    }

    if (areValuesEmpty) {
      this.setState({
        isError: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      const filename = window.sessionStorage.getItem('cohortFileName');
      const jsonFileName = window.sessionStorage.getItem('cohortJsonFileName');

      CohortSenseService.generateTraditionalModel(trainTestSplit, filename, jsonFileName)
        .then((result) => {
          console.log('generateTraditionalModel', result);

          this.setState({
            isLoading: false,
            resultAvailable: true,
            result,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  openModelTuningDialog = (modelType) => {
    const index = this.state.result.ModelName.indexOf(modelType);
    const allMetrics = [
      this.state.result.allmetrics.modelaccuracy[index],
      this.state.result.allmetrics.modelaccuracy[index],
      this.state.result.allmetrics.model_roc_auc_score[index],
      this.state.result.allmetrics.model_recall_list[index],
      this.state.result.allmetrics.model_precision_list[index],
    ];

    this.setState((prevState) => ({
      modelTuning: {
        ...prevState.modelTuning,
        allMetrics,
        modelType,
      },
    }));

    this.setState({
      openModelTuningDialog: true,
    });
  };

  onSubmitModelTuning() {
    const filename = window.sessionStorage.getItem('cohortFileName');
    const jsonFileName = window.sessionStorage.getItem('cohortJsonFileName');
    if (this.state.modelTuning.dataSize === '' || this.state.modelTuning.trainTestSplit === '') {
      this.setState({
        isError: true,
      });
    } else {
      CohortSenseService.performModelTuning(this.state.modelTuning.dataSize, this.state.modelTuning.modelType, JSON.stringify(this.state.result.AUC),
        JSON.stringify(this.state.modelTuning.allMetrics), this.state.modelTuning.trainTestSplit, filename, jsonFileName)
        .then((result) => {
          console.log('performModelTuning', result);

          this.setState((prevState) => ({
            modelTuning: {
              ...prevState.modelTuning,
              result,
            },
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  closeModelTuningDialog = () => {
    this.setState({ openModelTuningDialog: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <section className="App">
        <div>
          <section className={classes.container}>
            <h2 className={classes.titleClient}>
              Traditional Model Generation
            </h2>

            <section>
              <Grid container>
                <Grid item md={6}>
                  <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.width95}>
                      <TextField
                        label="Train Test Split"
                        type="number"
                        min="0"
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                        max="1"
                        name="trainTestSplit"
                        onChange={this.onChangeTrainToTest}
                        value={this.state.trainTestSplit}
                        margin="normal"
                      />
                    </FormControl>
                  </form>
                </Grid>

                <Grid item md={6}>
                  <div align="left" style={{ "margin-top": 15 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onSubmit}
                      disabled={!this.state.trainTestSplit}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </section>

            <br />
            <br />
            <section>
              {
                this.state.isLoading && (
                  <LinearProgress />
                )
              }
              <br />
              <h5>Results</h5>
              {
                this.state.resultAvailable && this.state.result && this.state.result.ModelName
                && (
                  <div>
                    <Grid container>
                      <Grid item md={3}><b>Model Name</b></Grid>
                      {
                        this.state.result.ModelName.map((value) => (
                          <Grid item md={3}>{value}</Grid>
                        ))
                      }
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>AUC</b></Grid>
                      {
                        this.state.result.AUC.map((value) => (
                          <Grid item md={3}>{value}</Grid>
                        ))
                      }
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Model Accuracy</b></Grid>
                      {
                        this.state.result.allmetrics.modelaccuracy.map((value) => (
                          <Grid item md={3}>{value}</Grid>
                        ))
                      }
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Model Recall</b></Grid>
                      {
                        this.state.result.allmetrics.model_recall_list.map((value) => (
                          <Grid item md={3}>{value}</Grid>
                        ))
                      }
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Model Precision</b></Grid>
                      {
                        this.state.result.allmetrics.model_precision_list.map((value) => (
                          <Grid item md={3}>{value}</Grid>
                        ))
                      }
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Export</b></Grid>
                      {
                        this.state.result.picklefn.map((value) => (
                          <Grid item md={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              target="_blank"
                              href={CohortSenseService.getExportModelUrl(value)}
                              className={classes.bannerIconButton}
                            >
                              Export
                            </Button>
                          </Grid>
                        ))
                      }
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Model Tuning</b></Grid>
                      {
                        this.state.result.ModelName.map((value) => (
                          <Grid item md={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.openModelTuningDialog(value)}
                              className={classes.bannerIconButton}
                            >
                              Tune Model
                            </Button>
                          </Grid>
                        ))
                      }
                    </Grid>

                  </div>
                )
              }
            </section>

            <br />
            <section>
              {
                this.state.modelTuning.result && this.state.modelTuning.result.ModelName
                && (
                  <div>
                    <h5>Model Tuning Results</h5>
                    <Grid container>
                      <Grid item md={3}><b>Model Name</b></Grid>
                      <Grid item md={9}>{this.state.modelTuning.result.ModelName}</Grid>
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Model Accuracy</b></Grid>
                      <Grid item md={9}>{this.state.modelTuning.result.ModelAccuracy}</Grid>
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Model Recall</b></Grid>
                      <Grid item md={9}>{this.state.modelTuning.result.ModelRecall}</Grid>
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Model Precision</b></Grid>
                      <Grid item md={9}>{this.state.modelTuning.result.ModelPrecision}</Grid>
                    </Grid>

                    <br />

                    <Grid container>
                      <Grid item md={3}><b>Export</b></Grid>
                      <Grid item md={9}>
                        <Button
                          variant="contained"
                          color="primary"
                          target="_blank"
                          href={CohortSenseService.getExportModelUrl(this.state.modelTuning.result.picmodelname)}
                          className={classes.bannerIconButton}
                        >
                          Export
                        </Button>
                      </Grid>
                    </Grid>

                  </div>
                )
              }
            </section>
          </section>
        </div>
        <Dialog
          fullWidth
          onClose={this.closeModelTuningDialog}
          aria-labelledby="Model-Tuning"
          open={this.state.openModelTuningDialog}
        >
          <DialogTitle id="diabetes-dialog-title" onClose={this.closeModelTuningDialog}>
            Model Tuning
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={this.closeModelTuningDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <h4>
              Model:
              {this.state.modelTuning.modelType}
            </h4>
            <br />
            <section>
              <div>
                <FormControl className={classes.width95}>
                  <TextField
                    label="Data size"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    name="dataSize"
                    onChange={this.onChangeModelTuningFields}
                    value={this.state.modelTuning.dataSize}
                    margin="normal"
                  />
                </FormControl>

                <br />

                <FormControl className={classes.width95}>
                  <TextField
                    label="Train to Test Split"
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 1 } }}
                    name="trainTestSplit"
                    onChange={this.onChangeModelTuningFields}
                    value={this.state.modelTuning.trainTestSplit}
                    margin="normal"
                  />
                </FormControl>
              </div>
            </section>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.onSubmitModelTuning}>
              Tune Model
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.isError}
          variant="error"
          autoHideDuration={2000}
          message={<span>This is an error</span>}
          onClose={this.onCloseSnack}
        >
          <SnackbarContent
            className={classes.errorSnack}
            aria-describedby="client-snackbar"
            message={(
              <span id="client-snackbar" className={classes.message}>
                <ErrorIcon className={`${classes.icon} ${classes.iconVariant}`} />
                Field(s) are empty
              </span>
            )}
          />
        </Snackbar>
      </section>
    );
  }
}

TraditionalModelSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CommonStyles)(TraditionalModelSection);
