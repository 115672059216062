import React, { memo, useState } from "react";
import { Grid, FormControl, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import styled from "styled-components";

import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const PredictingEligibility = () => {
  const initialData = {
    intervention: " ",
    diagnosis: " ",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "clinicalTrials",
    "predictingEligibility",
  );

  const showResult = () => {
    const NewDataSet = {
      intervention: "Bevacizumab",
      diagnosis:
        "Bevacizumab glioblastoma diagnosis and co medication that may interfere with study results immuno suppressive agents other than corticosteroids",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      intervention: "Dexamethasone 21-phosphate",
      diagnosis: "breast cancer diagnosis and signed informed consent",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const { intervention, diagnosis } = sampleData;

    const params = `${intervention}&userValue[]=${diagnosis}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <p>
              Interventional cancer clinical trials are generally too
              restrictive, and some patients are often excluded based on
              comorbidity, past or concomitant treatments, or the fact that they
              are over a certain age. The efficacy and safety of new treatments
              for patients with these characteristics are, therefore, not
              defined. In this work, a model has been built and trained to
              automatically predict whether short clinical statements were
              considered in the inclusion or exclusion criteria
              <br />
              <br />
              The relevant data for this project is derived from the
              intervention, condition, and eligibility fields written in
              unstructured free-text language. The information in the
              eligibility criteria—both exclusion and inclusion criteria—are
              sets of phrases and/or sentences displayed in a free format, such
              as paragraphs, bulleted lists, enumeration lists, etc.
              <br />
              <br />
              Using Deep Neural Network, this tool predicts the eligibility of a
              patient for a cancer clinical trial after going through their
              diagnosis notes.
            </p>
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Patient%20Eligibility%20for%20Cancer%20Trials"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Patient%20Eligibility%20for%20Cancer%20Trials/data"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <h5>Please provide the following data inputs</h5>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
            >
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    rows="4"
                    multiline
                    label="Drug Name"
                    name="intervention"
                    value={sampleData.intervention}
                    onChange={onChangeFields}
                    type="text"
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    rows="4"
                    multiline
                    label="Diagnosis of Patient"
                    name="diagnosis"
                    value={sampleData.diagnosis}
                    onChange={onChangeFields}
                    type="text"
                  />
                  <br />
                </StyledFormControl>
              </Grid>
            </Grid>
            <br />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample Data 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample Data 2
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </section>
          <br />
          <h3>Output</h3>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {resultData}
              </Result>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictingEligibility);

export const StyledFormControl = styled(FormControl)`
  .MuiFormControl-root {
    margin: 0px 50px;
    padding: 0px 10px;

    @media only screen and (max-width: 900px) {
      padding: 0px 10px;
    }
  }
`;
