import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

const MentalHealthPrevalence = () => {
  const data = [
    [
      "Mental Health Cases (%) Across Countries",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Mentalhealth_16165703400550/Sheet1",
    ],
    [
      "Average Depressive Disorder (%) over the Years",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Mentalhealth_16165703400550/Sheet2",
    ],
    [
      "Yearwise Average no. of Cases for Major Depressive Disorder",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Mentalhealth_16165703400550/Sheet5",
    ],
    [
      "Cases by Number and Percentage of Population",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Mentalhealth_16165703400550/Yearlycases",
    ],
  ];
  const data1 = [
    [
      "Country Cluster on Total Cases as Percentage of Population",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/Mentalhealth_16165703400550/Sheet7",
    ],
  ];
  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Mental health data is based on the IHME, GBD 'prevalence' data. The
            IHME denotes prevalence as: "The total number of cases of a given
            disease in a specified population at a designated time. The data
            consists of 11 different Mental health related conditions e.g.
            Eating disorder, Depressive disorder etc. as a percentage of
            population and absolute population number as well for countries
            across the world. The data has been extracted on yearly basis from
            1990 to 2016 for each country.
          </p>
          <p>The various disorders in the data are described below:</p>
          <ul>
            <li>
              <b>Alcohol and Drug use disorders :</b>
              {' '}
              It consist of a strong
              desire to take the substance (Alcohol/Drug), impaired control over
              use, a withdrawal syndrome on ceasing or reducing use, tolerance
              to the effects of the drug, the need for larger doses to achieve
              the desired psychological effect, a disproportionate amount of
              time spent by the user obtaining, using, and recovering from drug
              use, and persistence of drug taking despite the problems that
              occur
            </li>
            <li>
              <b>Anorexia nervosa:</b>
              {' '}
              An eating disorder causing people to
              obsess about weight and what they eat.
            </li>
            <li>
              <b>Anxiety disorder:</b>
              {' '}
              A mental health disorder characterised by
              feelings of worry, anxiety or fear that are strong enough to
              interfere with one's daily activities.
            </li>
            <li>
              <b>Bipolar disorder:</b>
              {' '}
              A disorder associated with episodes of
              mood swings ranging from depressive lows to manic highs.
            </li>
            <li>
              <b>Bulimia nervosa:</b>
              {' '}
              A serious eating disorder marked by
              bingeing, followed by methods to avoid weight gain.
            </li>
            <li>
              <b>Depressive Disorder:</b>
              {' '}
              It refers to the sum of those
              suffering from 'major depressive disorder' and 'persistent
              depressive disorder'.
            </li>
            <li>
              <b>Eating disorder:</b>
              {' '}
              It is the sum of those suffering from
              'anorexia nervosa' and 'bulimia nervosa'.
            </li>
            <li>
              <b>Major depressive disorder:</b>
              {' '}
              A mental health disorder
              characterised by persistently depressed mood or loss of interest
              in activities, causing significant impairment in daily life.
            </li>
            <li>
              <b>Persistent depressive disorder:</b>
              {' '}
              A mild but long-term form
              of depression.
            </li>
            <li>
              <b>Schizophrenia:</b>
              {' '}
              A disorder that affects a person's ability
              to think, feel and behave clearly.
            </li>
          </ul>
          <p>
            <b>Methodology:</b>
            <br />
            {' '}
            The focus of data analysis is to find how the mental health
            cases are distributed across countries over the years. Different
            visualizations are created to understand it better. Further, the
            countries are grouped in three clusters depending on the prevalence
            of mental health cases based on unsupervised learning techniques.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="http://www.healthdata.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <Typography variant="h6" gutterBottom>
          <FontAwesomeIcon icon={faInfoCircle} />
          {' '}
          Visualizations
        </Typography>
        <br />
        <Grid
          container
          xs={12}
          spacing={5}
          direction="row"
        >
          {data.map((data) => (
            <Grid item xs={12} sm={6} md={3}>
              <A target="_blank" href={data[1]}>
                <StyledCard>{data[0]}</StyledCard>
              </A>
            </Grid>
          ))}
        </Grid>
        <br />
        <Typography variant="h6" gutterBottom>
          <br />
          <FontAwesomeIcon icon={faInfoCircle} />
          {' '}
          Cluster
        </Typography>
        <br />
        <Grid container xs={12} spacing={5} alignItems="center" direction="row">
          {data1.map((data) => (
            <Grid item xs={12} sm={6} md={3}>
              <A target="_blank" href={data[1]}>
                <StyledCard>{data[0]}</StyledCard>
              </A>
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MentalHealthPrevalence);
const A = styled.a`
  text-decoration: none;
`;
const StyledCard = styled.div`
  height: 100%;
  text-align: center;
  background-color: #0080ff;
  color: white;
  border-radius: 4px;
  margin-left: 40px;
  padding: 12px;
  flex-grow: 0;
  flex-basis: 25%;
  margin-bottom: 0.35rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075rem;
`;
