import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import inceptionv3_hartebeest from "../../../assets/images/app/inceptionv3_hartebeest.webp";
import inceptionv3_hartebeest_most from "../../../assets/images/app/inceptionv3_hartebeest_most.webp";
import mobilenetv1_zebra from "../../../assets/images/app/mobilenetv1_zebra.webp";

const NeuronShapley = () => {
  const [resultData, setResultData] = useState(false);

  const showResultFun = () => {
    setResultData(true);
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Neuron Shapley is a new framework to quantify the contribution of individual neurons in prediction and performance of deep learning model. By accounting the interaction of neurons across all other neurons, neuron Shapley is more effective in identifying the important filters compared to the common approach based on activation pattern.Interestingly removing just 20 neurons with highest Shapley scores effectively destroys the prediction accuracy of InceptionV3 model on ImageNet dataset as accuracy fall down 100% to 8% for Hartebeest class
          </p>
          <b>Shapley value of neurons:</b>
          <p>
            It is value allotted to each neuron measuring the contribution of that particular neuron in predicting the overall accuracy. Let’s a trained model is evaluated on a specific performance metric V (example Accuracy). Overall performance of model having N elements (Neurons) is V(N). So, the goal is to assign responsibility to each neuron or one would want to partition overall performance V(N) among the model elements.
            <br />
            <br />
            Computing Shapley values is computationally very expensive so instead of finding exact Shapley values here top contributors are found by using Multi-Armed-bandits(Truncated Multi Armed Bandit Shapley ).
            There are many ways to partition the overall performance, but due to having very complex interaction of a neuron with other neurons in network it is hard task. So, to resolve this issue we can use Shapley value properties.
          </p>
          <b>Shapley value properties:</b>
          <ol>
            <li>
              <b>Zero contribution:</b>
              {' '}
              If any neuron does not change the overall performance when it is added to any other subset of neurons in network. One simple example is a neuron with all zero parameters.
            </li>
            <li>
              <b>Symmetric elements:</b>
              {' '}
              If two neurons give the same performance when they are added to any subnetwork. Then they should have the same value.
            </li>
            <li>
              <b>Additivity in performance metric:</b>
              {' '}
              Natural way to measure the overall performance of model is having a linear combination of two or more performance metrics.
            </li>
          </ol>
          <p>
            Shapley algorithm is equivalent to
            <b>corporate game theory</b>
            , in which N players are playing and V(S) is rewarded if N/S players opt out.
          </p>
          <b>Application of Neuron Shapley</b>
          <p>Neuron Shapley discovers that for every class, there exists a small number of filters that are critical. Neuron Shapley is a flexible framework that can be used to identify neurons that are responsible for many types of network behavior beyond the standard prediction accuracy. As model’s behavior largely depends on the presence of critical neurons, one can utilize this sparsity to apply post-training of model to improve model performance without any access to the training data.</p>
          <p>It can help data scientists and data engineers in solving problems as:</p>
          <ol>
            <li>
              To identify filters responsible for biased prediction and repair the machine learning model without much degradation of the overall accuracy.
              <ul>
                <li>
                  <b>Discovering unfair filters</b>
                  {' '}
                  It has been shown that the gender detection models have certain biases towards certain ethnic groups. The filters with the most negative values that decrease fairness and contribute to the disparity are identified. Zeroing out these negative shapley value filters greatly increased the gender classification accuracy. That is the interesting potential of using Neuron Shapley for
                  {' '}
                  <b>rapid model repair.</b>
                  {' '}
                  Zeroing out filters can be much faster and easier than retraining the network, and it also does not require an extensive training set.
                </li>
                <li>
                  <b>Identifying filters vulnerable to adversaries:</b>
                  {' '}
                  An adversary can arbitrarily change the output of a deep network by adding imperceptible perturbations to the input image. Neuron Shapley is applied to identify filters that are most vulnerable to attacks in the model. network filters interact differently on the adversarially perturbed images than on the clean images. After zeroing out the filters with the top N Shapley values (most vulnerable filters), the adversary’s attack success rate drops. Neuron Shapley offers a fast mechanism to repair models without needing to retrain.
                </li>
              </ul>
            </li>
            <li>To simplify model complexity based on model size by removing the least responsible neurons. The updated model is relatively faster to deploy as it is of lesser size.</li>
          </ol>
          <p>Neuron Shapley is a principled framework to quantify the contribution of every neuron to each prediction success and failure of the network. It aims to identify which neurons are responsible for predictions that are biased against a few ethnic groups and neurons that are vulnerable to attacks. Neuron Shapley is thus a step toward making deep learning more accountable and responsible</p>
          <p>Neuron Shapley can be extended to the Healthcare classification problems as well. It can help in</p>
          <ul>
            <li>The wound care by identifying wound segments</li>
            <li>Identifying the damage in chest x-rays by Improving the x-ray image classification</li>
            <li>Eye Care - Cross sections of the retinas in the eye to for retina damage classification</li>
          </ul>
          <p>
            <b>Problem statement:</b>
            {' '}
            Application to implement neuron Shapley with InceptionV3/MobileNetV1 model for image classification and demonstrate performance improvement of model in terms of accuracy and model size after removing least responsible neurons.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/SAIL/NeuronShapley"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://arxiv.org/pdf/2002.09815.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stanford AI : Citations
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            xs={12}
            direction="row"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Grid xs={12}>
              <StyledFigure>
                <StyledButton
                  variant="contained"
                  color="primary"
                  data-sample="sample1"
                  onClick={showResultFun}
                >
                  Show Demo
                </StyledButton>
              </StyledFigure>
            </Grid>
          </Grid>
          {resultData && (
          <section>
            <Paragraph>
              <b>Methodology:</b>
              <p>
                10 different animal classes (Ex. Hartebeest, Zebra, etc.) are collected of having 25 images in each of
                <b>ImageNet dataset.</b>
              </p>
              <p>
                Neuron Shapley algorithm is executed using InceptionV3 model having 17216 neurons on collected dataset. The most responsible neurons are identified for classifying specific class.
                The team also executed Neuron Shapley algorithm using MobileNetV1 model having 6998 neurons on same dataset ImageNet. And got result that for zebra class one can remove 700 least responsible neurons safely with accuracy 92% (accuracy has changed from 88% to 92%). After removal neurons the checkpoints are saved.
              </p>
              <b>Result</b>
            </Paragraph>
            <Grid>
              <Paper>
                <TableDiv>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableHead align="left">Scenario</StyledTableHead>
                        <StyledTableHead align="left">Model</StyledTableHead>
                        <StyledTableHead align="left">Class of Image</StyledTableHead>
                        <StyledTableHead align="left">Accuracy</StyledTableHead>
                        <StyledTableHead align="left">No. Neurons removal</StyledTableHead>
                        <StyledTableHead align="left">Checkpoint Size in M</StyledTableHead>
                      </TableRow>
                    </TableHead>
                    <TableBody align="center">
                      <TableRow>
                        <TableCell>Default</TableCell>
                        <TableCell>InceptionV3</TableCell>
                        <TableCell>Hartebeest</TableCell>
                        <TableCell>100%</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>109</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Modified</TableCell>
                        <TableCell>InceptionV3</TableCell>
                        <TableCell>Hartebeest</TableCell>
                        <TableCell>88%</TableCell>
                        <TableCell>400</TableCell>
                        <TableCell>95.4</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Default</TableCell>
                        <TableCell>MobileNetV1</TableCell>
                        <TableCell>Zebra</TableCell>
                        <TableCell>88%</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>67.9</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Modified</TableCell>
                        <TableCell>MobileNetV1</TableCell>
                        <TableCell>Zebra</TableCell>
                        <TableCell>92%</TableCell>
                        <TableCell>700</TableCell>
                        <TableCell>17</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableDiv>
              </Paper>
            </Grid>
            <Paragraph>
              <b>InceptionV3 Model result for Hartebeest Class:</b>
              <p>Number of removals of most important neurons: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]</p>
              <p>Accuracy :[100.0, 52.0, 40.0, 44.0, 8.0, 4.0, 4.0, 4.0, 4.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]</p>
              <center>
                {' '}
                <Image src={inceptionv3_hartebeest} alt="" />
              </center>
              <b>We can evaluate the impact of neurons with largest Shapley values by zeroing them out. So as by removing just 20 neurons accuracy falls down from 100% to 8% it means that these 20 neurons are very responsible for accuracy. This denotes that these 20 neurons are critical.</b>
              <p>Number of Least Important neurons Removal: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800,1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000]</p>
              <p>Accuracy: [100.0, 96.0, 96.0, 88.0, 88.0, 56.0, 40.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]</p>
              <center>
                {' '}
                <Image src={inceptionv3_hartebeest_most} alt="" />
              </center>
              <b>We can remove 400 least responsible neurons with accuracy of 88% safely to reduce complexity of model.MobileNetV1 Model result for Zebra class:</b>
              <p>Number of removals of Least responsible neurons: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000]</p>
              <p>Accuracy: [88.0, 92.0, 96.0, 96.0, 88.0, 96.0, 92.0, 92.0, 76.0, 16.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]</p>
              <center>
                {' '}
                <Image src={mobilenetv1_zebra} alt="" />
              </center>
              <b>We can remove 700 neurons with accuracy 92% safely to reduce complexity of model.</b>
            </Paragraph>
          </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(NeuronShapley);

const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #274c77;
`;
const Image = styled.img`
  border-radius: 3px;
`;

const Image1 = styled.img`
  width: 75%;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Image2 = styled.img`
  width: -webkit-fill-available;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
const TableDiv = styled.div`
    overflow: auto;
`;
