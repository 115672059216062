import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Label from '@material-ui/icons/Label';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';
import sidebarOptions, { MODELOPTIONS } from './fasterAiConstants';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'black',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },

  },
  content: {
    color: '#fff',
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    overflow: 'hidden',
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  disabledText: {
    color: 'slategray',
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
  },
  dragable: {
    cursor: 'move',
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, isDisable, dragStyle, ...other
  } = props;

  return (
    <TreeItem
      label={(
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={[classes.labelText, isDisable ? classes.disabledText : '', dragStyle ? classes.dragable : '']}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      )}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    height: '100%',
  },
});

export default function GmailTreeView({
  props, options, onDragStart, isModelSetupDone, disableDPOptions,
}) {
  const classes = useStyles();
  const panelOptions = sidebarOptions[options];
  const disableOptions = [];
  function DraggableTreeItem({ labelText, isDisable }) {
    return (
      <StyledTreeItem
        nodeId={labelText}
        labelText={labelText}
        labelIcon={AssignmentIcon}
        isDisable={isDisable}
        color="#1a73e8"
        bgColor="#e8f0fe"
        dragStyle
        draggable={!isDisable}
        onDragStart={onDragStart}
      />
    );
  }
  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['2']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >

      <StyledTreeItem nodeId="2" labelText="Data" labelIcon={FolderIcon}>
        <StyledTreeItem nodeId="upload" labelText="Upload" labelIcon={Label}>
          <DraggableTreeItem labelText="Upload Image Data" isDisable />
          <DraggableTreeItem labelText="Upload Tabular Data" />
        </StyledTreeItem>
        <StyledTreeItem nodeId="sample" labelText="Sample" labelIcon={Label}>
          <DraggableTreeItem labelText="Sample Image" />
          <DraggableTreeItem labelText="Sample Tabular" />
        </StyledTreeItem>
      </StyledTreeItem>

      <StyledTreeItem nodeId="3" labelText="Exploratory Data Analysis" labelIcon={FolderIcon}>
        {panelOptions.eda && panelOptions.eda.map((option) => (
          <DraggableTreeItem labelText={option} />
        ))}
      </StyledTreeItem>
      <StyledTreeItem nodeId="4" labelText="Data Preprocessing" labelIcon={FolderIcon}>
        {panelOptions.data_processing && panelOptions.data_processing.map((option) => (
          <DraggableTreeItem labelText={option} isDisable={disableDPOptions} />
        ))}
      </StyledTreeItem>
      <StyledTreeItem nodeId="5" labelText="Model Training and Evaluation" labelIcon={FolderIcon}>
        {isModelSetupDone ? panelOptions.model_prediction && panelOptions.model_prediction.map((option) => (
          <DraggableTreeItem labelText={option} isDisable={disableOptions.indexOf(option) > -1} />
        )) : panelOptions.model_training && panelOptions.model_training.map((option) => (
          <DraggableTreeItem labelText={option} />
        ))}

      </StyledTreeItem>
      <StyledTreeItem nodeId="6" labelText="Run SAIL Apps" labelIcon={FolderIcon}>
        <DraggableTreeItem labelText="Shapley" isDisable={disableDPOptions} />
        <DraggableTreeItem labelText="Multiaccuracy" isDisable={disableDPOptions} />

      </StyledTreeItem>
    </TreeView>

  );
}
