import React, { memo, useState, useEffect } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setInputData } from '../../../../../../store/actions/vnet';
import Plot from '../../components/Plot';
import { StyledInpuLabel } from '../DataTransformation/ArithmeticOperation';
import { InfoText } from '../ModelOptimization/DataShapley';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const StackedBarChartForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const [xWideData, setXWideData] = useState({ input0: '' });
  const [xLongData, setXLongData] = useState({ input0: '' });
  const [dropdownList, setdropdownList] = useState(null);
  const [fieldError, setError] = useState({});
  const [selected, setSelected] = useState(userInputData?.target || []);
  const [alert, setAlert] = useState("");

  const dispatch = useDispatch();

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;
    dispatch(
      setInputData({
        ...userInputData,
        y: typeof value === 'string' ? value.split(',') : value,
      })
    );
    setSelected(value);
    alert && setAlert(false);
    if (fieldError.features) {
      setError({});
    }
  };

  const handleXWideData = (e) => {
    if (Object.values(xWideData).indexOf(e.target.value) == -1) {
      setXWideData({ ...xWideData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
    {
      dispatch(
        setInputData({
          ...userInputData,
          x: e.target.value,
        })
      );
    }
  };
  const handleXLongData = (e) => {
    if (Object.values(xLongData).indexOf(e.target.value) == -1) {
      setXLongData({ ...xLongData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
    {
      dispatch(
        setInputData({
          ...userInputData,
          x: e.target.value,
        })
      );
    }
  };

  useEffect(async () => {
    // let result = '["pclass1", "gender", "sibsp", "parch", "fare", "survived"]';
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);

  const handleSpecify = (e) => {
    dispatch(
      setInputData({
        wide_data: e.target.value,
        x: '',
        y: [],
      })
    );
    setError({});
  };
  const handleFormReset = () => {
    dispatch(
      setInputData({
        wide_data: 'True',
        y: [],
        x: '',
      })
    );
  };

  const isFormValid = () => {
    if (userInputData?.wide_data == 'True') {
      if (userInputData?.y?.length == 0) {
        setError({ features: true });
        setAlert(true);
        return;
      }
    } else if (userInputData?.wide_data == 'False') {
      if (Object.entries(userInputData?.y)?.length == 0) {
        setError({ input0: true });
        setAlert(true);
        return;
      }
    }
    if (!userInputData?.x || Object.entries(userInputData?.y).length == 0) {
      setAlert(true);
      return false;
    }
    return true;
  };
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };
  return (
    <section>
      {alert && (
        <InfoText success={false}>
          <FontAwesomeIcon icon={faInfoCircle} />
          Please choose both X and Y Axis Values
        </InfoText>
      )}
      <FormLabel component='label'>Specify</FormLabel>
      <RadioGroup
        style={{ display: 'block' }}
        row
        name='wide_data'
        defaultValue='True'
        aria-label='Learn Mixture Weights'
        value={userInputData.wide_data}
        onChange={handleSpecify}
      >
        <FormControlLabel
          value='True'
          control={<Radio color='primary' />}
          label='Wide Data'
          labelPlacement='end'
        />
        <FormControlLabel
          value='False'
          control={<Radio color='primary' />}
          label='Long Data'
          labelPlacement='end'
        />
      </RadioGroup>
      <>
        <Grid container spacing={3} alignItems='flex-start'>
          <>
            <Grid item xs={6}>
              <TextField
                name='x'
                label='X-Axis'
                variant='outlined'
                onChange={handleXWideData}
                select
                fullWidth
                required
                value={userInputData.x||''}
                error={fieldError.x}
                helperText={fieldError.x && 'Required Field'}
              >
                {dropdownList?.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <StyledFormControl fullWidth required>
                <StyledInpuLabel>Y-Axis</StyledInpuLabel>

                <Select
                  multiple
                  id='y'
                  variant='outlined'
                  name='y'
                  required
                  value={userInputData.y || []}
                  onChange={handleMultiple}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  helperText={fieldError.y && 'Required Field'}
                >
                  {dropdownList?.map((value) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox
                        checked={userInputData.y?.indexOf(value) > -1}
                      />
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
          </>
        </Grid>
      </>

      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </section>
  );
});

export const StackedBarChartResult = memo(() => {
  const { resultData = null } = useSelector((state) => state.vnet);
  return (
    resultData && (
      <div>
        <Plot data={resultData.Plot ? JSON.parse(resultData.Plot) : ''} />
      </div>
    )
  );
});

export const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
  }
  &.MuiSelect-root {
    width: 100%;
  }
`;
