import React, { memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  LinkContainer,
} from "../../../styles/common";
import CoronaDashboard from './CoronaDashboard';

const useStyles = makeStyles({
  root: {
    height: "100%",
    textAlign: "left",
    backgroundColor: "#e6b800",
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  textCenter: {
    textAlign: "center",
  },
});

const CovidDashboard = () => (
  <SolutionContainer>
    <Collapse text="Description">
      <Paragraph>
        <p>
          {' '}
          This dashboard represents the snapshot of disease spread in USA.
          The number of cases confirmed, recovered and deceased are shown
          with respect to each state. The stats are last updated on
        </p>
        <p>
          The data source is from &nbsp;
          <a
            href="https://github.com/CSSEGISandData/COVID-19"
            target="_blank"
            rel="noreferrer"
          >
            Johns Hopkins Github repository.
          </a>
        </p>
      </Paragraph>
      <LinkContainer>
        <CoronaDashboard />
      </LinkContainer>
    </Collapse>

  </SolutionContainer>
);

export default memo(CovidDashboard);
