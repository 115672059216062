import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";

const Anomagram = () => {

  return (

    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Anomagram is an interactive visualization tool for exploring how a deep learning model can be applied to the task of anomaly detection (on stationary data). Given an ECG signal sample, an autoencoder model (running live in the browser) can predict if it is normal or abnormal. To try it out, click any of the test ECG signals from the ECG5000 dataset below, or draw a signal to see the model's prediction!
          </p>
          <p>
            Anomagram is designed as part of interactive visualizations that help communicate technical insights on how deep learning models work. It is entirely browser based, implemented in Tensorflow.js, no installations required.
          </p>
          <p>
            An Autoencoder is a type of artificial neural network used to learn efficient (low dimensional) data representations in an unsupervised manner. It is typically comprised of two components - an encoder that learns to map input data to a low dimension representation ( also called a bottleneck, denoted by z ) and a decoder that learns to reconstruct the original signal from the low dimension representation. The training objective for the autoencoder model is to minimize the reconstruction error - the difference between the input data and the reconstructed output.
          </p>
          <p>
            This prototype uses the ECG5000 dataset which contains 5000 examples of ECG signals from a patient. Each data sample (corresponds to an extracted heartbeat containing 140 points) has been labelled as normal or being indicative of heart conditions related to congestive heart failure.
          </p>
          <p>
            The tool is useful for for educators (tool to support guided discussion of the topic), entry level data scientists, and non-ML experts (citizen data scientists, software developers, designers etc).
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://anomagram.fastforwardlabs.com/#/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/victordibia/anomagram/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read more
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text='Demo'>
        <DemoContainer>
          <center>
            <iframe
              src='https://anomagram.fastforwardlabs.com/#/'
              width='100%'
              height='650'
            />
          </center>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>

  );
}

export default memo(Anomagram);
