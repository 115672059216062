import React, { memo, useState, useRef, useEffect } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  RadioGroup,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
// import local components
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import sample1 from "../../../assets/images/app/visualQna1.webp";
import sample2 from "../../../assets/images/app/visualQna2.webp";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import getUserData from "../../../utils";
import styled from "styled-components";
import { ChatView } from "../../common/ChatBot";
const { postQueryResult, uploadFilesWithBody } = CommonService(
  "z1dApps",
  "visualQna"
);
const VisualQnAMedicalImaging = () => {
  const [response, setResponse] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [radiovalue, setRadiovalue] = useState(
    "What is the diabetic macular edema grade for this image?"
  );
  let refToScroll = useRef();

  const queList = {
    q1: "Show me posts by Ram Bhandarkar?",
    q2: "What are the cells that produces Amyloid protein?",
    q3: "Genes or genomes that causes Frontotemporal dementia?",
    q4: "Find me blogs on Amazon Neptune ?",
  };
  const [conversation, setConversation] = useState([
    ["bot", "Hi!, What can I help you with?"],
  ]);
  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const [result, setResult] = useState(false);
  const [selectedImg, setSelectedImg] = useState();
  const [type, setType] = useState();
  const [fileName, setSelectedFileName] = useState("");

  const questions = [
    "What is the diabetic macular edema grade for this image?",
    "Are there hard exudates in this image?",
    "Are there hard exudates in the fovea?",
    "Are there hard exudates in this region?",
    "Are there optic discs in this region?",
  ];

  const staticImg = {
    sample1,
    sample2,
  };

  const setImage = (e) => {
    const file = e.target.files[0];
    if (e.target.files && e.target.files[0]) {
      setUploadedImage(e.target.files[0]);
      setSelectedFileName(fileName);
      setSelectedImg(URL.createObjectURL(file));
      setType("upload");
      setConversation([
        ["bot", `Hi!!,you have choosen custom image`],
        ["bot", "What can I help you with?"],
      ]);
    }
  };
  const getuploadResult = async (radiovalue) => {
    try {
      setSpinner(true);
      let params = {
        Context_param: JSON.stringify({
          Application_name: "vqa",
          UserID: userId,
        }),
        Content_param: JSON.stringify({ Question: radiovalue }),
        img: uploadedImage,
      };
      let result = await uploadFilesWithBody("upload", params);
      setSpinner(false);
      setResponse(result?.Result);
      setConversation([
        ...conversation,
        ["user", radiovalue],
        ["bot", result?.Result],
      ]);
    } catch (err) {
      console.log(err);
      setSpinner(false);
      setNotification({ open: "error", message: err?.message });
      return false;
    }
  };
  const getResult = async (radiovalue) => {
    try {
      setSpinner(true);
      let params = {
        Context_param: {
          Application_name: "vqa",
          UserID: userId,
        },
        Content_param: { Question: radiovalue },
      };
      let result = await postQueryResult(type, params);
      setSpinner(false);
      setNotification({ open: "success", message: result.Console });
      setResponse(result?.Result);
      setConversation([
        ...conversation,
        ["user", radiovalue],
        ["bot", result?.Result],
      ]);
    } catch (err) {
      setSpinner(false);
      setNotification({ open: "error", message: err?.message });
      return false;
    }
  };
  const getSampleRes = async (e) => {
    console.log(e);
    setSelectedImg(staticImg[e.currentTarget.id]);
    setType(e.currentTarget.id);
    setConversation([
      ["bot", `Hi!!,you have choosen ${e.currentTarget.id} image`],
      ["bot", "What can I help you with?"],
    ]);
  };

  const setSearchParams = (parameter) => {
    setConversation([...conversation, ["user", parameter]]);
    setRadiovalue(parameter);
    switch (type) {
      case "sample1":
      case "sample2":
        getResult(parameter);
        return;
      case "upload":
        getuploadResult(parameter);
        return;
    }
  };
  useEffect(() => {
    refToScroll.current &&
      refToScroll.current.scrollTo(0, refToScroll.current.scrollHeight);
  }, [conversation]);


  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Visual Question Answering (VQA) models take an image and a
            natural-language question as input and provides an accurate natural
            language answer. Mirroring real-world scenarios, both the questions
            and answers are open-ended. Visual Questions selectively target
            different areas of an image, including background details and
            underlying context. VQA allows clinicians to probe the model with
            subtle differentiating questions and contributing to build trust in
            predictions.
          </p>
          <p>
            This application evaluates it on the clinically relevant task of
            Diabetic Macular Edema (DME) staging from fundus imaging. By using a
            tailored training procedure and loss function that measures the
            level of inconsistency, this application of DME staging provides
            important improvements in both VQA accuracy and consistency.
          </p>
          <p>
            The data source DME dataset was built from the IDRiD and eOphta
            datasets, which are popular datasets with fundus images. For each
            image, a set of pre-defined questions is generated.
          </p>
          <p>
            Number of images : Train: 433 Val: 112 Test: 134 <br />
            Number of QA pairs: Train: 9779 Val: 2380 Test: 1311
          </p>
          <p>
            This application’s VQA model is built using Pytorch TORCH.NN which
            uses
            <ul>
              <li>
                An ImageNet-pretrained ResNet101 for encoding of fundus images.
              </li>
              <li>
                A single layer Long Short Term Memory(LSTM) network in Pytorch
                for encoding the questions
              </li>
            </ul>
          </p>
          The application by using the computations from these models provides
          the answer for the question asked about the fundus image uploaded.
          <p>
            The Business scope of this application can be extended substantially
            with a larger volume of dataset (images and QA Pairs). If this
            application is to be trained with, say at least 200+ questions, it
            would be of significant help in the medical field. Also, by not just
            limiting it with DME Fundus images, this application can also be
            trained for a wide variety of Medical imaging like X-ray, CT Scan,
            MRI, PET Scan and more.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://zenodo.org/record/6784358"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={3} alignItems="center">
              {/* <img src={sample1} width="200px" height="auto" /> */}
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getSampleRes}
                id="sample1"
                name="sample1"
              >
                Sample1
              </StyledButton>
            </Grid>

            <Grid item xs={12} md={3} alignItems="center">
              {/* <img src={sample2} width="200px" height="auto" /> */}
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getSampleRes}
                id="sample2"
                name="sample2"
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} md={1} alignItems="center">
              <strong>OR</strong>
            </Grid>
            <Grid item xs={12} md={5} alignItems="center">
              <TextField type="file" color="primary" onChange={setImage} />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getuploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs="6">
              <a href="https://material.vlifevirtusa.com/vqa/sample/sample1.jpg" target="_blank">Download Sample1 image</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://material.vlifevirtusa.com/vqa/sample/sample2.jpg" target="_blank">Download Sample2 image</a>
            </Grid>
            <Grid item xs="6">
              <a href="https://www.google.com/search?q=Diabetic+Retinopathy&tbm=isch&ved=2ahUKEwjyiP7iirL7AhW3zaACHWwOBBYQ2-cCegQIABAA&oq=Diabetic+Retinopathy&gs_lcp=CgNpbWcQAzIECAAQQzILCAAQgAQQsQMQgwEyCwgAEIAEELEDEIMBMgsIABCABBCxAxCDATIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoGCAAQBxAeOgYIABAIEB46BggAEAUQHlDSBViBD2DgE2gAcAB4AIABnQOIAZUUkgEHMi0zLjQuMZgBAKABAaoBC2d3cy13aXotaW1nwAEB&sclient=img&ei=moR0Y_LTIrebg8UP7JyQsAE&bih=609&biw=1280&safe=active" target="_blank">Diabetic Retinopathy images from Google Search</a>
            </Grid>
          </Grid>
          <br />
          <br />
          {selectedImg && (
            <Grid
              container
              spacing={2}
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={3}>
                <img src={selectedImg} width="200px" height="auto" />
              </Grid>

              <Grid item xs={5}>
                  <Title><b>VQA Conversational-AI</b></Title>
                <ChatBotContainer ref={refToScroll}>
                  <ChatView conversation={conversation} />
                </ChatBotContainer>
              </Grid>
              <Grid item xs={4}>
                <QuestionList >
                  <p>VQA Conversational-AI Sample Questions</p>
                  {questions.map((item) => (
                    <ListItem
                      key={item}
                      onClick={() => {
                        setSearchParams(item);
                      }}
                    >
                      <StyledListText>{item}</StyledListText>
                      {/* <StyledListItemText primary={item}/> */}
                    </ListItem>
                  ))}
                </QuestionList>
              </Grid>
            </Grid>
          )}
          {showSpinner && <Spinner text="Loading..." />}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(VisualQnAMedicalImaging);

const Title = styled.div`
  padding: 10px;
  height: 20px;
  background-color: #000000;
  color: #ffff;
  margin-bottom: 5px;
  position: sticky;
`;
const ChatBotContainer = styled.div`
  height: 250px;
  max-height: 290px;
  overflow: auto;
  min-width: 100%;
  border-radius: 0.375rem;
  box-shadow: rgb(0 0 0 / 15%) 0.0625rem 0rem 0.25rem 0px;
  box-sizing: border-box;
  padding: 5px;
  max-width: 100%;


  .user {
    margin-left: auto;
    background-color: #3f51b5;
    border-radius: 1.5rem 1.5rem 0px;
    color: white;
    font-size: 12px;

  }
  .bot {
    margin-right: auto;
    background-color: rgb(230, 230, 230);
    border-radius: 1.5rem 1.5rem 1.5rem 0px;
    background-color: #fc7703;
    color: white;
    font-size: 12px;
  }
  .bubble {
    display: table;
    max-width: 100%;
    padding: 0.4em 0.8em;
    overflow-wrap: break-word;
    margin-bottom: 0.625rem;
    //background-color: rgb(230, 230, 230);
  }
`;
const QuestionList = styled(List)`
  height: 290px;
  max-height: 290px;
  min-width: 100%;
  border-radius: 0.375rem;
  box-shadow: rgb(0 0 0 / 15%) 0.0625rem 0rem 0.25rem 0px;
  box-sizing: border-box;
  padding: 1px;
  max-width: 100%;
  
`;
const StyledListItemText = styled(ListItemText)`
  border-radius: 4px;
  background: transparent;
  border: 1px solid teal;
  font-size: 0.5em;
  display: block;

  &:hover {
    color: blue;
    border: 2px solid blue;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.5em;
  }
`;
const StyledListText = styled(Button)`
  display: inline-block;
  color: black;
  font-size: 10px;
  border: 1px solid teal;
  border-radius: 20px;
  display: block;
  text-transform:unset ! important;
  cursor: pointer;
}
`;
