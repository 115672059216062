import React from 'react';
import Highcharts from 'highcharts/highmaps';
import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
import {
  Button, FormControl, InputLabel, Select, MenuItem, Grid, TextField,
} from "@material-ui/core";
import CircularProgress from "../../common/loading/Spinner";
import CommonService from '../../../utils/services/CommonService';

const { getQueryResult } = CommonService("ibri", "digitalTwins");

exporting(Highcharts);
offline(Highcharts);

class State extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMap: true,
      stateName: "",
      value: "",
      Highcharts,
      map: [{}],
      list: [],
      state: '',
      person_name: '',
    };

    this.backBtn = this.backBtn.bind(this);
    this.btnClick = this.btnClick.bind(this);
    this.setMap = this.setMap.bind(this);
  }

  backBtn() {
    this.setState({
      displayMap: true,
      stateName: "",
    });
    this.props.goBack();
  }

  btnClick(name) {
    this.setState({
      displayMap: false,
      stateName: name,
    });
  }

  getMapTemplate(state) {
    switch (state) {
      case 17:
        return require('@highcharts/map-collection/countries/us/us-in-all.geo.json');
      case 61:
        return require('@highcharts/map-collection/countries/us/us-ak-all.geo.json');
      case 55:
        return require('@highcharts/map-collection/countries/us/us-mt-all.geo.json');
      case 26:
        return require('@highcharts/map-collection/countries/us/us-ne-all.geo.json');
      case 11:
        return require('@highcharts/map-collection/countries/us/us-nj-all.geo.json');
      case 57:
        return require('@highcharts/map-collection/countries/us/us-nm-all.geo.json');
      case 56:
        return require('@highcharts/map-collection/countries/us/us-nv-all.geo.json');
      case 12:
        return require('@highcharts/map-collection/countries/us/us-ny-all.geo.json');
      case 19:
        return require('@highcharts/map-collection/countries/us/us-oh-all.geo.json');
      case 48:
        return require('@highcharts/map-collection/countries/us/us-ok-all.geo.json');
      case 64:
        return require('@highcharts/map-collection/countries/us/us-or-all.geo.json');
      case 13:
        return require('@highcharts/map-collection/countries/us/us-pa-all.geo.json');
      case 37:
        return require('@highcharts/map-collection/countries/us/us-sc-all.geo.json');
      case 28:
        return require('@highcharts/map-collection/countries/us/us-sd-all.geo.json');
      case 44:
        return require('@highcharts/map-collection/countries/us/us-tn-all.geo.json');
      case 49:
        return require('@highcharts/map-collection/countries/us/us-tx-all.geo.json');
      case 58:
        return require('@highcharts/map-collection/countries/us/us-ut-all.geo.json');
      case 38:
        return require('@highcharts/map-collection/countries/us/us-va-all.geo.json');
      case 65:
        return require('@highcharts/map-collection/countries/us/us-wa-all.geo.json');
      case 20:
        return require('@highcharts/map-collection/countries/us/us-wi-all.geo.json');
      case 39:
        return require('@highcharts/map-collection/countries/us/us-wv-all.geo.json');
      case 36:
        return require('@highcharts/map-collection/countries/us/us-nc-all.geo.json');
      case 43:
        return require('@highcharts/map-collection/countries/us/us-ms-all.geo.json');
      case 41:
        return require('@highcharts/map-collection/countries/us/us-al-all.geo.json');
      case 25:
        return require('@highcharts/map-collection/countries/us/us-mo-all.geo.json');
      case 46:
        return require('@highcharts/map-collection/countries/us/us-ar-all.geo.json');
      case 52:
        return require('@highcharts/map-collection/countries/us/us-az-all.geo.json');
      case 62:
        return require('@highcharts/map-collection/countries/us/us-ca-all.geo.json');
      case 53:
        return require('@highcharts/map-collection/countries/us/us-co-all.geo.json');
      case 32:
        return require('@highcharts/map-collection/countries/us/us-de-all.geo.json');
      case 33:
        return require('@highcharts/map-collection/countries/us/us-fl-all.geo.json');
      case 34:
        return require('@highcharts/map-collection/countries/us/us-ga-all.geo.json');
      case 63:
        return require('@highcharts/map-collection/countries/us/us-hi-all.geo.json');
      case 22:
        return require('@highcharts/map-collection/countries/us/us-ia-all.geo.json');
      case 54:
        return require('@highcharts/map-collection/countries/us/us-id-all.geo.json');
      case 16:
        return require('@highcharts/map-collection/countries/us/us-il-all.geo.json');
      case 23:
        return require('@highcharts/map-collection/countries/us/us-ks-all.geo.json');
      case 42:
        return require('@highcharts/map-collection/countries/us/us-ky-all.geo.json');
      case 47:
        return require('@highcharts/map-collection/countries/us/us-la-all.geo.json');
      case 35:
        return require('@highcharts/map-collection/countries/us/us-md-all.geo.json');
      case 18:
        return require('@highcharts/map-collection/countries/us/us-mi-all.geo.json');
      case 24:
        return require('@highcharts/map-collection/countries/us/us-mn-all.geo.json');
      case 59:
        return require('@highcharts/map-collection/countries/us/us-wy-all.geo.json');
      default:
        return null;
    }
  }

  setMap(us_state) {
    const { onSelectedCity } = this.props;
    const map_template = this.getMapTemplate(us_state);
    const { person_name } = this.state;
    Highcharts.mapChart('us_state', {
      chart: {
        map: map_template,
        height: '40%',
      },
      title: {
        text: null,
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },
      colorAxis: {
        min: 0,
      },
      credits: {
        enabled: false,
      },
      series: [{
        data: this.state.map,
        name: 'Total no of Patients',
        cursor: 'pointer',
        states: {
          hover: {
            color: '#BADA55',
          },
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}',

        },
      }],
      plotOptions: {
        series: {
          point: {
            events: {
              click() {
                this.setState({ mainPage: false });
                if (onSelectedCity == "17") {
                  onSelectedCity(this.name.split(' ').join('').replace(/[.,\s]/g, '').toLowerCase(), person_name);
                  // hist.push(`/linechart/${this.name.split(' ').join('').replace(/[.,\s]/g, '').toLowerCase()}/${person_name}`);
                }
              },
            },
          },
        },
      },
    });
  }

  render() {
    let button;
    if (this.state.displayMap) {
      button = (
        <div className="btn" id="btn">
          <Button onClick={this.backBtn} variant="contained" color="primary">Back</Button>
        </div>
      );
    }

    return (
      <div>
        <div className="App">

          <Grid container justify="center" spacing={3}>
            <Grid item xs={12}>
              {button}
            </Grid>
            <Grid item xs={12} sm={6} sm={4}>
              <TextField variant="outlined" select fullWidth value={this.state.person_name} label="Select Person" onChange={(e) => this.setState({ person_name: e.target.value })}>
                <MenuItem value="" disabled>Select Person</MenuItem>
                {this.state.list}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <br />
        <div id="us_state"><CircularProgress /></div>
      </div>

    );
  }

  componentDidMount() {
    // Load patient list
    getQueryResult('personname_url')
      .then((response) => {
        this.setState({
          list:
            response.map((pa_name, i) => (
              <MenuItem key={i} value={pa_name}>{pa_name}</MenuItem>
            )),
        });
        this.setMap(this.props.selectedState);
      }).catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.map !== prevState.map) {
      this.setMap(this.props.selectedState);
    }

    if (this.state.person_name !== prevState.person_name) {
      if (this.state.person_name !== "") {
        const params = `${this.state.person_name},${this.props.selectedState}`;
        getQueryResult("person_url", params)
          .then((response) => {
            this.setState({ map: response });
          }).catch((error) => {
            console.log(error);
          });
      }
    }
  }
}

export default State;
