import React, { memo, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  Button,
  IconButton,
  Drawer,
  Avatar,
  TextField,
  InputAdornment,
  useScrollTrigger,
  Menu,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import MaterialLink from "@material-ui/core/Link/Link";

import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CloseIcon from "@material-ui/icons/Close";
import ListIcon from "@material-ui/icons/List";
import SendIcon from "@material-ui/icons/Send";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemText from "@material-ui/core/ListItemText";
import { Dashboard, ExitToApp } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight, faListUl, faSignOutAlt, faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { StyledButton, LogoImage, Paragraph } from "../../styles/common";

import { ListCollapse } from "./Collapse";
import DomainContent from "./DomainContent";
import ResourceContent from "./ResourceContent";
import ContactUsDialog from "./ContactusDialog";
import NavbarConstants from "../../constants/NavbarContent";

import TransformedLogo from "../../assets/logos/vlifeLogo4.webp";
import logo from "../../assets/logos/vlifeLogo1.webp";
import getUserData from "../../utils";
import { USER_MANAGEMENT } from "../../constants";

function Navbar({ changeOnScroll = false, hideSearch, cartData }) {
  const [openDrawer, setDrawer] = useState(false);
  const [domainContent, setDomainContent] = useState(false);
  const [showProfileMenu, setProfileMenu] = useState(null);
  const [showAboutMenu, setAboutMenu] = useState(null);
  const [showResourceMenu, setResourceMenu] = useState(null);
  const [openDialogContact, setDialog] = useState(false);
  const [searchData, setSearchInput] = useState('');
  const [redirectData, setRedirect] = useState('');
  const userData = getUserData();
  const role = userData?.token.privilege;
  const { ADMIN_ROLE, PRIMARY_ROLE } = USER_MANAGEMENT;
  const showDashboard = role === ADMIN_ROLE.toUpperCase() || role === PRIMARY_ROLE.toUpperCase();

  const toggleDrawer = () => {
    setDrawer(!openDrawer);
  };

  const closeDomainContent = (e) => {
    setDomainContent(false);
  };

  const closeDialogContact = () => {
    setDialog(false);
  };

  const openContactusDialog = () => {
    setDialog(true);
  };

  const trigger = useScrollTrigger({
    threshold: 80,
    disableHysteresis: true,
  });
  const handleDomainMenu = () => {
    setDomainContent(!domainContent);
  };
  const logout = () => {
    localStorage.removeItem("sessData");
    setRedirect('/login');
  };

  function domain() {
    return (
      <StyledListitem>
        <NavButton
          color="inherit"
          target="_blank"
          id="showDomainMenu"
          endIcon={<ExpandMoreIcon />}
          onClick={handleDomainMenu}
          className="domain-navbutton"
        >
          Domain
        </NavButton>
      </StyledListitem>
    );
  }
  function resources() {
    const handleResourceMenu = (e) => {
      setResourceMenu(e.currentTarget);
    };
    const handleClose = () => {
      setResourceMenu(null);
    };
    return (
      <StyledListitem onClick={closeDomainContent}>
        <NavButton
          color="inherit"
          target="_blank"
          id="showResourcesMenu"
          endIcon={<ExpandMoreIcon />}
          onClick={handleResourceMenu}
          className="resources-navbutton"
        >
          Resources
        </NavButton>
        <StyledMenu
          anchorEl={showResourceMenu}
          keepMounted
          disableScrollLock
          open={Boolean(showResourceMenu)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          getContentAnchorEl={null}
        >
          <ResourceContent transform={!changeOnScroll || trigger} />
        </StyledMenu>
      </StyledListitem>
    );
  }

  function vNet() {
    return (
      <StyledListitem>
        <Link to="/vNet">
          <NavButton color="inherit" target="_blank" className="vnet-navbutton">
            vNet
          </NavButton>
        </Link>
      </StyledListitem>
    );
  }

  function pricing() {
    return (
      <StyledListitem>
        <Link to="/pricing">
          <NavButton color="inherit" endIcon={<AttachMoneyIcon />} className="pricing-navbutton">
            Pricing
          </NavButton>
        </Link>
      </StyledListitem>
    );
  }

  function about() {
    const handleAboutMenu = (e) => {
      setAboutMenu(e.currentTarget);
    };
    const handleClose = () => {
      setAboutMenu(null);
    };
    return (
      <StyledListitem onClick={closeDomainContent}>
        <NavButton
          id="showAboutMenu"
          color="inherit"
          onClick={handleAboutMenu}
          endIcon={<ExpandMoreIcon />}
          className="about-navbutton"
        >
          About
        </NavButton>
        <StyledMenu
          anchorEl={showAboutMenu}
          keepMounted
          open={Boolean(showAboutMenu)}
          onClose={handleClose}
          disableScrollLock
          variant="menu"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          getContentAnchorEl={null}
        >
          <Link to="/aboutus">
            <MenuItem
              onClick={handleClose}
              component={MaterialLink}
              target="_blank"
            >
              About us
            </MenuItem>
          </Link>
          <Link to="/offerings">
            <MenuItem
              onClick={handleClose}
              component={MaterialLink}
              target="_blank"
            >
              vLife™ Offerings
            </MenuItem>
          </Link>

          <Link to="engage_with_us">
            <MenuItem
              onClick={handleClose}
              component={MaterialLink}
              target="_blank"
            >
              Engage with us
            </MenuItem>
          </Link>

          <Link to="/faq">
            <MenuItem
              onClick={handleClose}
              component={MaterialLink}
              target="FAQ"
            >
              FAQs
            </MenuItem>
          </Link>
          <MenuItem
            onClick={handleClose}
            component={MaterialLink}
            href="https://material.vlifevirtusa.com/asset/vLife_Collateral.zip"
          >
            Download Collateral
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            component={MaterialLink}
            target="_blank"
            href="https://material.vlifevirtusa.com/StanfordAI/Virtusa_SAIL_Collaboration.pdf"
          >
            Virtusa SAIL Collaboration
          </MenuItem>
        </StyledMenu>
      </StyledListitem>
    );
  }

  function contactus() {
    return (
      <StyledListitem onClick={closeDomainContent}>
        <NavButton
          color="inherit"
          endIcon={<SendIcon />}
          onClick={openContactusDialog}
          className="contactus-navbutton"
        >
          Contact Us
        </NavButton>
      </StyledListitem>
    );
  }
  function searchInput(chageInputColor) {
    const handleSearch = (e) => {
      setSearchInput(e.target.value);
    };
    const handleClickSearchIcon = () => {
      searchData && setRedirect(`/search?search_query=${searchData}`);
    };
    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        handleClickSearchIcon();
      }
    };
    return (
      <StyledListitem>
        <StyledSearch
          variant="outlined"
          label="Search"
          chageColor={chageInputColor}
          hide={hideSearch}
          size="small"
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={handleClickSearchIcon}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </StyledListitem>
    );
  }
  function profile() {
    const handleProfileMenu = (e) => {
      setDomainContent(false);
      setProfileMenu(e.currentTarget);
    };
    const handleClose = () => {
      setProfileMenu(null);
    };
    const userData = getUserData();
    const userInfo = userData?.token?.name?.split(' ');
    const firstLetter = userInfo
      ? userInfo[0].slice(0, 1).toUpperCase()
      : "N";
    const SecondLetter = userInfo
      ? userInfo[1].slice(0, 1).toUpperCase()
      : "A";
    const ProfileLetter = firstLetter + SecondLetter;
    return (
      <>
        <Avatar
          onClick={handleProfileMenu}
          style={{
            backgroundColor: "#fc7703",
            color: "white",
            cursor: "pointer",
          }}
          aria-label="recipe"
        >
          {ProfileLetter}
        </Avatar>
        <StyledMenu
          anchorEl={showProfileMenu}
          keepMounted
          open={Boolean(showProfileMenu)}
          onClose={handleClose}
          variant="menu"
          disableScrollLock
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          getContentAnchorEl={null}
        >
          {showDashboard && (
          <ProfileMenuItem onClick={handleClose} component={Link} to="/admin" target="_blank">
            <FontAwesomeIcon icon={faUserCog} />
            Admin Dashboard
          </ProfileMenuItem>
          )}
          <ProfileMenuItem onClick={handleClose} component={Link} to="/orders">
            <FontAwesomeIcon icon={faListUl} />
            Orders
          </ProfileMenuItem>
          <ProfileMenuItem onClick={logout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            {' '}
            Logout
          </ProfileMenuItem>
        </StyledMenu>
      </>
    );
  }
  function cart() {
    return (
      <StyledListitem>
        <CartButton component={Link} to="/checkout" startIcon={<ShoppingCartIcon />}>
          Cart
          {cartData?.items?.length > 0 && `(${cartData.items.length})`}
        </CartButton>
      </StyledListitem>
    );
  }
  function mobileView() {
    const subCategoryList = NavbarConstants.Resources.sections;
    const caseStudies = subCategoryList["case-studies"].category;
    const publications = subCategoryList.publications.category;
    const media = subCategoryList.media.category;
    const handleDomain = () => {
      setDrawer(false);
      setResourceMenu(false);
      handleDomainMenu();
    };
    const handleContactus = () => {
      setDrawer(false);
      openContactusDialog();
    };

    return (
      <MobileList component="nav" aria-labelledby="Menu-Drawer" disablePadding>
        <Subheading onClick={handleDomain}>
          Domain
          {' '}
          <FontAwesomeIcon icon={faAngleRight} />
        </Subheading>
        <Subheading>
          <Link to="/vnet">
            vNet
            {' '}
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </Subheading>
        <Subheading onClick={handleContactus}>
          Contact us
          {' '}
          <FontAwesomeIcon icon={faAngleRight} />
        </Subheading>
        <ListCollapse listText="Resources">
          <List component="div" id="sub-list" disablePadding>
          <ListItem>
              <ListItemText>
                <Link to="/categories/gen-ai">
                  Gen AI
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/categories/stanford-ai">
                  Stanford AI
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/categories/collaborators">
                  Collaborators
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/videos">
                  Videos
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListCollapse listText="Case studies">
              {caseStudies.map((obj) => (
                <List component="div" id="sub-list" disablePadding>
                  <ListItem key={obj.heading}>
                    <ListItemText>
                      <MaterialLink
                        target="_blank"
                        id="externalLink"
                        href={obj.externalLink}
                      >
                        {obj.heading}
                        <FontAwesomeIcon icon={faAngleRight} />
                      </MaterialLink>
                    </ListItemText>
                  </ListItem>
                </List>
              ))}
            </ListCollapse>
            <ListCollapse listText="Publications">
              {publications.map((obj) => (
                <List component="div" id="sub-list" disablePadding>
                  <ListItem key={obj.heading}>
                    <ListItemText>
                      <MaterialLink
                        target="_blank"
                        id="externalLink"
                        href={obj.externalLink}
                      >
                        {obj.heading}
                        <FontAwesomeIcon icon={faAngleRight} />
                      </MaterialLink>
                    </ListItemText>
                  </ListItem>
                </List>
              ))}
            </ListCollapse>
            <ListCollapse listText="Media">
              {media.map((obj) => (
                <List component="div" id="sub-list" disablePadding>
                  <ListItem key={obj.heading}>
                    <ListItemText>
                      <MaterialLink
                        target="_blank"
                        id="externalLink"
                        href={obj.externalLink}
                      >
                        {obj.heading}
                        <FontAwesomeIcon icon={faAngleRight} />
                      </MaterialLink>
                    </ListItemText>
                  </ListItem>
                </List>
              ))}
            </ListCollapse>
          </List>
        </ListCollapse>

        <ListCollapse listText="About">
          <List component="div" id="sub-list" disablePadding>
            <ListItem>
              <ListItemText>
                <Link to="/aboutus">
                  About us
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/offerings">
                  vLife Offerings
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/engage_with_us">
                  Engage With us
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/faq">
                  FAQs
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <MaterialLink href="https://material.vlifevirtusa.com/asset/vLife_Collateral.zip">
                  Download Collateral
                  {' '}
                  <FontAwesomeIcon icon={faAngleRight} />
                </MaterialLink>
              </ListItemText>
            </ListItem>
          </List>
        </ListCollapse>
        <Subheading>
          <Link to="/pricing">
            Pricing
            {' '}
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </Subheading>
        <ListCollapse listText="Profile">
          <List component="div" id="sub-list" disablePadding>
            {showDashboard && (
            <ListItem>
              <ListItemText>
                <Link to="/engage_with_us" target="_blank">
                  Admin dashboard
                </Link>
              </ListItemText>
            </ListItem>
            )}
            <ListItem>
              <ListItemText>
                <Link to="/orders">
                  Orders
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem onClick={logout}>
              <ListItemText primary="Logout" />
              <ExitToApp />
            </ListItem>
          </List>
        </ListCollapse>
      </MobileList>
    );
  }
  return (
    <ClickAwayListener onClickAway={closeDomainContent}>
      <section id="back-to-top-anchor">
        {redirectData && <Redirect to={redirectData} />}
        <NavBarContiner transform={changeOnScroll && !trigger}>
          <MenuContainer id="menu-icon">
            <StyledIcon
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </StyledIcon>
            <MenuDrawer open={openDrawer} anchor="left" id="drawer">
              <StyledIcon
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  toggleDrawer();
                }}
              >
                <CloseIcon id="close-icon" />
              </StyledIcon>
              {mobileView()}
              {searchInput(true)}
            </MenuDrawer>
          </MenuContainer>

          <LogoContainer id="logo" item xs={1}>
            <Link to="/">
              <LogoImage
                id="nav-logo"
                src={changeOnScroll ? (trigger ? logo : TransformedLogo) : logo}
                alt=""
              />
            </Link>
          </LogoContainer>

          <MenuItems container id="menu-items">
            <StyledList>
              {domain()}
              {vNet()}
              {resources()}
              {pricing()}
              {about()}
              {contactus()}
              {searchInput()}
              {cart()}
            </StyledList>
          </MenuItems>

          <ProfileContainer>{profile()}</ProfileContainer>
        </NavBarContiner>

        {domainContent && (
          <DomainContent
            transform={!changeOnScroll || trigger}
            close={closeDomainContent}
          />
        )}
        <ContactUsDialog open={openDialogContact} close={closeDialogContact} />
      </section>
    </ClickAwayListener>
  );
}

const mapStateToProps = (state) => ({
  cartData: state.cart,
});
export default connect(mapStateToProps)(memo(Navbar));

export const StyledList = styled(List)`
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
`;
const MobileList = styled(List)`
  .MuiListItem-root {
    background-color: #204c74;
    margin: 2px 0 2px 0;
    .MuiTypography-body1 {
      font-weight: bold;
    }
    a {
      text-decoration: none;
    }
  }
  #sub-list {
    background: "#204c74";
    .MuiListItem-root {
      background-color: #204c74;
      margin: 0px;
      padding-left: 25px;
      #externalLink {
        padding-left: 20px;
      }
      a {
        text-decoration: none;
        color: #ffff;
      }
    }
  }
`;
export const StyledListitem = styled(ListItem)`
  &.MuiListItem-root {
    color: inherit;
    float: left;
    width: auto;
    padding: 0;
    position: relative;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;
const NavButton = styled(Button)`
  &.MuiButton-root {
    color: inherit;
    margin: auto;
    font-size: 14px;
    list-style: none;
    border-color: #dc1478;
    margin-right: 20px;
    text-transform: none;
    &:hover {
      border-width: 1px;
      border-color: transparent;
      background-color: transparent;
    }
    &::after {
      top: 100%;
      left: 0;
      width: 100%;
      height: 3px;
      content: "";
      position: absolute;
      transform: scaleX(0);
      background: #fc7703;
      transition: transform 0.5s;
      transform-origin: right;
    }
    &:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`;
const StyledSearch = styled(TextField)`
  &.MuiTextField-root{
    min-width: 200px;
    .MuiFormLabel-root, .MuiInputBase-root {
      color: ${(props) => props.chageColor && '#ffff'};
      
    };
    &.MuiOutlinedInput-notchedOutline{
      border-color: ${(props) => props.chageColor && '#ffff'}
    };
    opacity: ${(props) => (props.hide ? 0 : 1)}
  }
`;
export const NavBarContiner = styled.header`

  color:  ${(props) => (props.transform ? "#ffffff" : "#555555")};
  padding: ${(props) => (props.transform ? "10px 30px 0 30px" : "0 20px 0 10px")};
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items:center;
  background-color:${(props) => (props.transform ? "rgb(0, 0, 0, 0.0)" : "#ffffff")};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 30%);
  transition: 0.3s  ease-in;

  #menu-icon, #drawer{
    display:none
  }

  .MuiOutlinedInput-root {
    color: ${(props) => (props.transform ? "#ffffff" : "#2941b7")};
    border-radius: 10px;
    font-size: 14px;
    hover: {
      color: #fc7703;
    };
	  width: 85%;
  }
  .MuiFormLabel-root{
    color: ${(props) => (props.transform ? "#ffffff" : "#2941b7")};
	  border-color: white;

  }
 
  @media only screen and (max-width: 1200px) {
    #menu-items{
      display:none;
    }
    #menu-icon{
      display:block
    }
    padding: 0 15px 0 10px;
  }
`;
const MenuContainer = styled.div``;
const LogoContainer = styled.div``;
const MenuItems = styled.div`
  margin-left: auto;
`;
const ProfileContainer = styled.div``;
const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    margin-top: 15px;
    overflow: hidden;
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 1200px) {
    .MuiPaper-root {
      overflow-y: scroll;
      margin-top: 50px;
    }
  }
  body {
    overflow: auto;
  }
`;
const StyledIcon = styled(IconButton)`
&.MuiIconButton-root{
  position: relative;
  width: 2rem;
  height: 2rem;
  color: inherit;
  margin: 0;
  border: 0;
  cursor: pointer;
  .MuiSvgIcon-root {
    font-size: 2rem;
    #close-icon {
      margin-left: 10%;
    }
  }
  }
`;
const CartButton = styled(StyledButton)`
  &.MuiButton-root {
    color: inherit;
  }
`;
const MenuDrawer = styled(Drawer)`
  .MuiPaper-root {
    background: #053354;
    color: #ffff;
    .MuiSvgIcon-root {
      font-size: 2rem;
      padding: 5px 0px 0 30px;
    }
  }
`;
const Subheading = styled(Paragraph)`
  padding-left: 20px;
  font-weight: bold;
  color: #ffff;
  font-size: 16px;
  a {
    color: #ffff;
    text-decoration: none;
  }
`;
const ProfileMenuItem = styled(MenuItem)`
  font-size: 0.8rem;
  .svg-inline--fa {
    padding-right: 5px;
  }
`;
