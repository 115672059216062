import React, { memo, useState } from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import styled from "styled-components";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";

const ClassfyingRheumotoidArthritisPatients = () => {
  const initialData = {
    erthrocyte: "",
    glucose: "",
    hematocrit: "",
    ureaNitrogen: "",
    hemoglobin: "",
    plateletCount: "",
    mch: "",
    mepiridine: "",
    allopurinol: "",
    naproxen: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "mlModels",
    "retrieveRheumatoidArthritisPrediction",
  );

  const showResult = () => {
    const NewDataSet = {
      erthrocyte: "42.8",
      glucose: "94.7",
      hematocrit: "40.1",
      ureaNitrogen: "7.5",
      hemoglobin: "13.3",
      plateletCount: "329.2",
      mch: "27.2",
      mepiridine: "0",
      allopurinol: "0",
      naproxen: "0",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      erthrocyte: "40.7",
      glucose: "79.6",
      hematocrit: "45.5",
      ureaNitrogen: "10.0",
      hemoglobin: "12.8",
      plateletCount: "157.7",
      mch: "28.1",
      mepiridine: "0",
      allopurinol: "0",
      naproxen: "49",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      erthrocyte,
      glucose,
      hematocrit,
      ureaNitrogen,
      hemoglobin,
      plateletCount,
      mch,
      mepiridine,
      allopurinol,
      naproxen,
    } = sampleData;

    const params = `${erthrocyte},${glucose},${hematocrit},${ureaNitrogen},${hemoglobin},${plateletCount},${mch},${mepiridine},${allopurinol},${naproxen}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          setResultData(res);
          const params = [];
          const percentages = res.percentage[0];
          for (const propertyName in percentages) {
            params.push({
              name: propertyName,
              y: percentages[propertyName],
            });
          }

          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color:
                      (Highcharts.theme
                        && Highcharts.theme.contrastTextColor)
                      || "black",
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <section>
            <p>
              Rheumatoid arthritis is a chronic inflammatory disorder that can
              affect more than just your joints. In some people, the condition
              can damage a wide variety of body systems that include the skin,
              eyes, lungs, heart, and blood vessels. Being an autoimmune
              disorder, rheumatoid arthritis occurs when your immune system
              attacks your own body's tissues by mistake.
            </p>
            <p>
              The signs and symptoms of rheumatoid arthritis vary in severity
              and may even come and go. Periods of increased disease activity,
              called flares, alternate with periods of relative remission — when
              the swelling and pain fade or disappear. Over time, rheumatoid
              arthritis can cause joints to deform and shift out of place.
            </p>
            <p>
              The synthetic dataset of the US population available on vLife™
              platform is leveraged to train a
              {' '}
              <b>Logistic Regression</b>
              {' '}
              model
              that effectively classifies whether a patient, given their medical
              history and other clinical details, is a Rheumatoid Arthritis
              patient or not.
            </p>
            <p>
              The slow progression of this disease makes it extremely difficult
              to bring in features that directly correlate to the classification
              outcome based on EMR data alone. This model employs the
              {" "}
              <b>f-test </b>
              method to identify the most significant factors that can aid in
              identifying the said medical condition.
            </p>
            <p>
              In addition to classifying whether a patient is rheumatoid
              arthritis patient or not, the user can also identify the
              contributing factors for the given test case.
            </p>
          </section>
          <LinkContainer>
            <Grid container spacing={2}>
            <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/rheumatoid-arthritis"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <br />
          <section>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Erthrocyte Distribution width [Entittic Vol]"
                          name="erthrocyte"
                          value={sampleData.erthrocyte}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Glucose [Mass/Vol] in Blood"
                          name="glucose"
                          value={sampleData.glucose}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="uotlined-adornment-weight"
                          variant="outlined"
                          label="Hematocrit [Vol Fraction] of Blood by Automated Count"
                          name="hematocrit"
                          value={sampleData.hematocrit}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Urea Nitrogen [Mass/Vol] in Blood"
                          name="ureaNitrogen"
                          value={sampleData.ureaNitrogen}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Hemaglobin [Mass/Vol] in Blood"
                          name="hemaglobin"
                          value={sampleData.hemoglobin}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Platelets in Blood by Automated Count"
                          name="plateletCount"
                          value={sampleData.plateletCount}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="MCH [Entitic Mass] by Automated Count"
                          name="mch"
                          value={sampleData.mch}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Mepiridine HCI"
                          name="mepiridine"
                          value={sampleData.mepiridine}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Allopurinal"
                          name="allopurinal"
                          value={sampleData.allopurinol}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Naproxen"
                          name="naproxen"
                          value={sampleData.naproxen}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <StyledDiv align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                    </StyledDiv>
                  </BorderGray>
                </section>
                <br />
                <section>
                  <StyledDiv align="right">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </StyledDiv>
                </section>
              </Grid>
              <Grid item xs={12} md={5}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`Rheumatoid Arthritis:  ${resultData.isRA}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer
                          id="prediction-chart"
                          display
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ClassfyingRheumotoidArthritisPatients);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
const StyledDiv = styled.div`
  text-align: right;
  @media only screen and (max-width: 959px) {
    text-align: center;
  }
`;
