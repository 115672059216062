import React, { memo, useState, useEffect } from "react";
import {
  InputAdornment, MenuItem, TextField, Grid, Divider, Button,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from 'highcharts';
// local components

import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";

const PredictMortalityCasesWorldwide = () => {
  const [result, setResult] = useState(null);
  const [showSpinner, setSpinner] = useState(false);
  const [input, setInput] = useState("");
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "z1dApps",
    "mortalityCasesWorldwide",
  );

  const onChangeFields = (e) => {
    setInput(e.target.value);
  };

  const getResult = (e) => {
    if (!input) {
      setNotification({ open: "error", message: "Please Enter a number" });
      return;
    }
    setResult(null);
    setSpinner(true);
    getQueryResult('result', input)
      .then((res) => {
        setResult(res);
        setSpinner(false);
        formHighChart(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  };

  const formHighChart = (data) => {
    Highcharts.chart('container', {

      title: {
        text: 'Predicting Mortality Cases Worldwide',
      },

      yAxis: {
        title: {
          text: 'Patient death counts',
        },
      },

      xAxis: {
        accessibility: {
          rangeDescription: 'time',
        },
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },

      xAxis: {
        categories: data[1],
      },

      series: [{
        name: 'Death cases',
        data: data[2],
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
          },
          chartOptions: {

            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        }],
      },

    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          Health administrators, researchers, and policymakers use prediction models to forecast patient outcomes including morbidity, mortality, and health system utilization. This enables the respective interest groups to identify the seriousness and prompts worldwide leaders to take acute measures. Data for model development is leveraged from
          <a href="https://github.com/CSSEGISandData/COVID-19" target="_blank" rel="noreferrer">Johns Hopkins Github repository.</a>
        </Paragraph>
        <Paragraph>Unlike, traditional estimates machine learning algorithms take several features to arrive at accurate numbers and provide the most realistic situation based on the user’s input</Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/CoronaDeathPrediction/corona_deaths_prediction.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/sudalairajkumar/novel-corona-virus-2019-dataset"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={4} justify="center" alignItems="center" alignContent="center">
            <Grid xs={12} sm={6} md={3}>
              Enter the days since the disease spread:
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="input"
                value={input}
                label="Number"
                required
                errorText="Input is required."
                onChange={onChangeFields}
              />

            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Button variant="contained" color="primary" onClick={getResult}>
                Execute
              </Button>
            </Grid>
          </Grid>
          {showSpinner && <Spinner />}
          <br />
          {result && (
            <Result>
              <strong>Result:</strong>
              {`${result[0]}`}
            </Result>
          )}

          <HighchartContainer id="container" display={result} />

        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictMortalityCasesWorldwide);
