import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import {
  Collapse, Divider, Grid, MenuItem, TextField,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown, faArrowRight, faCheckCircle, faDownload, faEye, faFileDownload, faInfo, faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '../../../../../styles/common';
import { AntTab, AntTabs } from './DataResultTab';
import { TabPanel, a11yProps } from './ResultPanel';
import { CustomTable, PastActivitySortTable } from '../../../../common/JsonDataTable';
import { setResult } from '../../../../../store/actions/vnet';
import { StyledInput } from '../InputForm';
import { InfoText } from '../features/ModelOptimization/DataShapley';
import { CollapseTitle } from './DataVizResult';

function ModelBuildingResult({ fetchModelMetrics, commonAPI }) {
  const { resultData = {}, Context_param = {} } = useSelector((state) => state.vnet);
  const { modelData = {} } = resultData || {};
  const {
    modelOutput = null, modelTesting = null, historyData = null, isModelRunning = false,
  } = modelData || {};

  const [value, setValue] = useState(0);
  const [modelInfo, setModelInfo] = useState({});
  const [sampleTestingHeader, setSampleHeader] = useState({});
  const dispatch = useDispatch();
  const [metricksObj, setMetricksObj] = useState({ showOptimized: false });
  const [isOpen, setCollapse] = useState('');
  const headerData = [
    { label: 'Model Name' },
    { label: 'Response', action: 'download' },
    { label: 'Created Date', sortKey: 'Created_Date', type: 'date' },
  ];
  const automlHeaderData = [
    { label: 'Model' },
    { label: 'Accuracy', sortKey: 'Accuracy' },
    { label: 'Balanced Accuracy' },
    { label: 'F1 Score' },
    { label: 'ROC AUC' },
    { label: 'Time Taken' },
  ];
  const modelInfoDetails = {
    'Model Type': 'modelType',
    Model: 'model',
    'Model Name': 'modelName',
    Description: 'description',
    'Model ID': 'modelID',
    'Model Version': 'modelVersionID',
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCollapse = (e) => {
    if (isOpen === e.target.id) {
      setCollapse('');
    } else {
      setCollapse(e.target.id);
    }
  };

  const getModelMetrics = () => {
    fetchModelMetrics(modelInfo);
  };
  const getSignedurl = async (filePath = modelOutput?.modelInfo?.filePath) => {
    if (filePath) {
      const ContextData = {
        StageID: "Common_API",
        FeatureID: "Common_API",
        FunctionID: "signedURL",
        Actions: "",
      };
      const contentData = {
        filePath,
      };
      const res = await commonAPI(ContextData, contentData);
      if (res?.Result?.signedURL) {
        window.open(res?.Result?.signedURL);
      }
    }
  };
  const downloadModel = () => {
    getSignedurl(modelOutput?.modelInfo?.filePath);
  };
  const getdeepCheckReport = () => {
    getSignedurl(modelOutput?.modelInfo?.reportPath);
  };

  const handleOnChange = async (e) => {
    const { value, name } = e.target;
    const modelDataObj = {};
    switch (name) {
      case "modelType":
        modelDataObj[name] = value;
        modelDataObj.model = null;
        modelDataObj.modelVersionID = null;
        break;
      case "model":
        modelDataObj[name] = value;
        modelDataObj.modelName = null;
        modelDataObj.modelVersionID = null;
        break;
      case "modelName":
        modelDataObj[name] = value;
        modelDataObj.modelVersionID = null;
        break;
      case "modelVersionID":
        modelDataObj[name] = value;
        modelDataObj.filePath = null;

        break;
      case "threshold":
        modelDataObj[name] = value;
        const ContextData = {
          StageID: "Common_API",
          FeatureID: "Common_API",
          FunctionID: "fetchOptimizedMetrics",
          Actions: " ",
          Threshold: value,
          Model_RunID: modelInfo?.modelVersionID,
        };
        const res = await commonAPI(ContextData);
        if (res?.Result) {
          dispatch(setResult({
            ...resultData,
            modelData: {
              ...resultData.modelData,
              modelOutput: {
                ...resultData.modelData.modelOutput,
                optimizedMetrics: { ...res.Result },
              },
            },
          }));
        }
        break;
      default:
        break;
    }

    setModelInfo({
      ...modelInfo,
      ...modelDataObj,
    });
  };

  useEffect(() => {
    setModelInfo(resultData?.modelData?.modelOutput?.modelInfo);
    setMetricksObj({ showOptimized: resultData?.modelData?.modelOutput?.modelInfo?.modelType === 'Classification' })
  }, [modelData]);

  useEffect(() => {
    const colKeys = modelData?.modelTesting?.sampletesting?.[0] && Object.keys(modelData?.modelTesting?.sampletesting?.[0]);
    if (Array.isArray(colKeys)) {
      const headerData = colKeys?.map((col) => (col == 'Created Date' ? { label: col, type: 'date', sortKey: 'Created Date' } : { label: col }));
      setSampleHeader(headerData);
      if (Context_param?.StageID == "Model_Testing") {
        setValue(1);
      }
    }
  }, [modelData?.modelTesting]);
  return (
    <ModelContainer>
      {resultData?.modelData ? (
        <>
          <p>Choose Model</p>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={3} id="modelcontainer">
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="modelType"
                label="Model Type"
                variant="outlined"
                onChange={handleOnChange}
                select
                size="small"
                fullWidth
                value={modelInfo?.modelType}
              >
                <MenuItem key="modelType" value="">
                  Choose ModelType
                </MenuItem>
                {historyData && Object.keys(historyData)?.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}

              </TextField>
            </Grid>
            {modelInfo?.modelType && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="model"
                    label="Model"
                    variant="outlined"
                    onChange={handleOnChange}
                    select
                    size="small"
                    fullWidth
                    value={modelInfo?.model}
                  >
                    {historyData?.[modelInfo?.modelType] && Object.keys(historyData?.[modelInfo?.modelType])?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}

                  </TextField>
                </Grid>
                {modelInfo.model && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name="modelName"
                        label="Model Name"
                        variant="outlined"
                        onChange={handleOnChange}
                        select
                        size="small"
                        fullWidth
                        value={modelInfo?.modelName}
                      >
                        {historyData?.[modelInfo?.modelType]?.[modelInfo?.model] && Object.keys(historyData?.[modelInfo?.modelType]?.[modelInfo?.model])?.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}

                      </TextField>
                    </Grid>
                    {modelInfo?.modelName && (
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="modelVersionID"
                          label="Model Version"
                          variant="outlined"
                          minWidth="100%"
                          onChange={handleOnChange}
                          select
                          size="small"
                          fullWidth
                          value={modelInfo?.modelVersionID}
                        >
                          {historyData?.[modelInfo?.modelType]?.[modelInfo?.model]?.[modelInfo?.modelName]?.map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}

                        </TextField>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>

          {modelInfo?.modelVersionID
            && (
              <center>
                <StyledButton variant="outlined" color="secondary" size="small" onClick={getModelMetrics} endIcon={<FontAwesomeIcon icon={faArrowRight} />}>Execute</StyledButton>
                {modelInfo?.filePath && <StyledButton size="small" variant="outlined" color="secondary" onClick={downloadModel} endIcon={<FontAwesomeIcon icon={faDownload} />}>Download Model  </StyledButton>}
              </center>
            )}
          {!modelData?.inProgress ? (
            <>
              {modelOutput?.modelInfo && (
                <>
                  <SubTitle text="Model Info" />
                  <Grid container spacing={3} className="infoContainer">
                    {Object.entries(modelInfoDetails).map((data) => (
                      <Grid item xs={12} sm={6} md={4}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        {' '}
                        <strong>
                          {' '}
                          {data[0]}
                          :
                        </strong>
                        {' '}
                        {modelOutput?.modelInfo?.[data[1]]}
                      </Grid>
                    ))}
                  </Grid>
                  <Divider />
                </>
              )}

              {modelOutput?.isShapleyRunning
                ? (
                  <InfoText success>
                    <strong>
                      <center>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {' '}
                        Data Shapley for the selected model version is in progress!!
                      </center>
                    </strong>
                  </InfoText>
                ) : (
                  <>
                    {modelOutput?.modelInfo && (
                      <AntTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="ant example"
                      >
                        <AntTab label="Model Metrics" {...a11yProps(0)} />
                        {modelData?.modelTesting && <AntTab label="Model Testing" {...a11yProps(1)} />}
                      </AntTabs>
                    )}

                    <TabPanel value={value} index={0}>
                      {isModelRunning
                        && (
                          <center>
                            <InfoText success>
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Model Building is in progress, check after sometime
                            </InfoText>
                          </center>
                        )}
                      {modelOutput && <Grid container justifyContent="flex-start" spacing={3}>
                        {modelOutput?.defaultMetrics?.performanceMetrics ? <Grid item xs={12} sm={6} md={4}>
                          <SubTitle text="Default Metrics" />

                          <p><strong>Performance Metrics</strong></p>
                          <CustomTable headerData={['Name', 'value']} bodyData={modelOutput?.defaultMetrics?.performanceMetrics} padding="11px" />
                          {modelOutput?.defaultMetrics?.confusionMatrix?.length > 0 && <>

                            <p><strong>Confusion Matrix</strong></p>
                            <MatrixContainer>
                              {modelOutput?.defaultMetrics?.confusionMatrix?.map((row) => (
                                <MatrixRow colLen={row.length}>
                                  {row.map((col) => (
                                    <div id="column">{col}</div>
                                  ))}
                                </MatrixRow>
                              ))}
                            </MatrixContainer>
                          </>}
                        </Grid>
                          :
                          <Grid item xs={12} >
                            {modelOutput?.automlMetrics && (<>
                              <p><strong>Performance metrics comparison </strong></p>
                              <PastActivitySortTable headerData={automlHeaderData} bodyData={JSON.parse(modelOutput?.automlMetrics)} colKeys={Array.isArray(JSON.parse(modelOutput?.automlMetrics)) && JSON.parse(modelOutput?.automlMetrics)?.[0] && Object.keys(JSON.parse(modelOutput?.automlMetrics)[0])} /> :
                            </>
                            )}
                          </Grid>
                        }

                        {metricksObj.showOptimized && <Grid item xs={12} sm={6} md={4}>
                          <SubTitle text="Optimized Metrics" />
                          {modelOutput?.thresholdList?.length ? (
                            <div>

                              <StyledSpan>
                                {modelOutput?.optimizedMetrics && Object.keys(modelOutput.optimizedMetrics).length > 0 && <strong>Performance Metrics</strong>}
                                {' '}
                                {modelOutput?.thresholdList?.length > 0 && (
                                  <StyledInput
                                    name="threshold"
                                    label="Threshold"
                                    variant="outlined"
                                    maxWidth="120px"
                                    minWidth="50px"
                                    onChange={handleOnChange}
                                    select
                                    size="small"
                                    value={modelInfo?.threshold || modelOutput?.currentThreshold}
                                  >
                                    <MenuItem key="selectthreshold" value="">
                                      Choose Threshold
                                    </MenuItem>
                                    {modelOutput?.thresholdList?.map((value) => (
                                      <MenuItem key={value} value={value}>
                                        {value}
                                      </MenuItem>
                                    ))}
                                  </StyledInput>
                                )}
                              </StyledSpan>
                              {modelOutput?.optimizedMetrics && Object.keys(modelOutput.optimizedMetrics).length > 0 && (
                                <>
                                  <CustomTable headerData={['Name', 'value']} bodyData={modelOutput?.optimizedMetrics?.performanceMetrics} padding="11px" />

                                  <p><strong>Confusion Matrix</strong></p>
                                  <MatrixContainer>
                                    {modelOutput?.optimizedMetrics?.confusionMatrix?.map((row) => (
                                      <MatrixRow colLen={row.length}>
                                        {row.map((col) => (
                                          <div id="column">{col}</div>
                                        ))}
                                      </MatrixRow>
                                    ))}
                                  </MatrixContainer>
                                </>
                              )}

                            </div>
                          ) : modelData?.shapleyInProgress ? <InfoText success><strong><center> Data Shapley for the selected model version is in progress!!</center></strong></InfoText> : (
                            <strong>
                              <InfoText success>
                                {' '}
                                To perform datashapley click on Model Optimization
                                {">"}
                                Data Shapley
                              </InfoText>
                            </strong>
                          )}
                        </Grid>}

                        {modelOutput?.modelInfo?.reportPath && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SubTitle text="Use DeepChecks" />
                            <StyledButton variant="outlined" onClick={getdeepCheckReport} endIcon={<FontAwesomeIcon icon={faEye} />}>View Report</StyledButton>
                          </Grid>
                        )}
                      </Grid>}

                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <CollapseTitle onClick={handleCollapse} id="sample">Random Sample</CollapseTitle>
                      <Collapse in={isOpen == 'sample'} timeout="auto" unmountOnExit>
                        {modelTesting?.sampletesting ? <PastActivitySortTable headerData={sampleTestingHeader} bodyData={modelTesting?.sampletesting} colKeys={Array.isArray(modelTesting?.sampletesting) && modelTesting?.sampletesting?.[0] && Object.keys(modelTesting?.sampletesting[0])} /> : <p>Please Perform Model Testing</p>}
                      </Collapse>

                      <CollapseTitle onClick={handleCollapse} id="file">File Upload</CollapseTitle>
                      <Collapse in={isOpen == 'file'} timeout="auto" unmountOnExit>
                        {modelTesting?.filetesting ? <PastActivitySortTable downloadModel={getSignedurl} headerData={headerData} bodyData={modelTesting?.filetesting} colKeys={["Model_Name", "Response", "Created_Date"]} /> : <p>Please Perform Model Testing </p>}
                      </Collapse>
                    </TabPanel>
                  </>
                )}
            </>
          ) : (
            <InfoText success>
              <strong>
                <center>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Model Building for the selected model version is in progress!!
                </center>
              </strong>
            </InfoText>
          )}
        </>
      ) : <p>No Actions Performed</p>}
    </ModelContainer>
  );
}
export default memo(ModelBuildingResult);

const ModelContainer = styled.div`
  #modelcontainer  {
    min-width: 70vw;
    max-width: 100%;
    justify-content: center;
    justify-items: center;
    text-align: center;
  }
  .infoContainer{
    font-size: 0.8rem;
  }
  .svg-inline--fa{
    color: #2a9d8f;
  }
  #matrix{
    display: flex;
    font-size: small;
  }
`;
export const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -5px 0px;
`;
export const MatrixRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.colLen}, 1fr);
  text-align: center;
  #column {
    padding: 10px;
    min-width: 25px;
    border: 1px solid gray;
  }
`;
export const MatrixContainer = styled.section`
  width: max-content;
`;

export function SubTitle({ text }) {
  return (
    <>
      <p>
        <strong>
          <FontAwesomeIcon icon={faInfoCircle} />
          {text}
        </strong>
      </p>
      <Divider />
    </>
  );
}
