import React, { useState, useEffect, memo } from "react";
import { Slide, Snackbar, SnackbarContent } from "@material-ui/core";
import styled from "styled-components";
import { Check, Error } from "@material-ui/icons";
import { SolutionContainer } from "../../styles/common";

function SolutionWrapperWithSnackbar({ snackbar, children }) {
  const [open, setOpen] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(snackbar?.open);
  }, [snackbar]);
  return (
    <SolutionContainer>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        variant={snackbar?.open}
        autoHideDuration={4000}
        TransitionComponent={Slide}
        onClose={handleClose}
      >
        <SnackContent
          message={(
            <div>
              {snackbar?.open == "success" ? <Check /> : <Error />}
&nbsp;
              {snackbar?.message}
            </div>
          )}
          color={snackbar?.open == "success"}
        />
      </Snackbar>
    </SolutionContainer>
  );
}
export default memo(SolutionWrapperWithSnackbar);
const SnackContent = styled(SnackbarContent)`
  background-color: ${(props) => (props.color ? "#4e9a51" : "#d74545")};
  padding: 6px 16px;
  div {
    display: flex;
    align-items: center;
  }
`;
