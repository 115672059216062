import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import Highcharts from "highcharts";

// local components
import * as d3 from 'd3';

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
} from "../../../styles/common";

import uninsured_us_data from '../../../assets/files/un_insured.csv';

const UsUninsured = () => {
  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [result, setSample] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "payerProvider", "usUninsured",
  );

  const parsecsv = (data) => {
    console.log(data);
    setField(data.columns);
    setDataRows(data);
  };

  const getSample = () => {
    setSample('');
    setSpinner(true);

    const legends = {
      POP_COU: 'Population Count',
      Disabled_Medicare: 'Medicare beneficiaries(disabled)',
      Elderly_Medicare: 'Medicare beneficiaries(elderly)',
      POP_URBAN: ' Urban Population',
      Age_19_Under: ' Population under age 19',
      Hispanic: 'Hispanic origin',
      POP_RURAL: 'Rural Population',
      Age_65_84: 'Population age 65-84',
      AREA_URBAN: 'Urban Area',
      White: 'White',
    };

    getQueryResult("getChartDetails")
      .then((res) => {
        setSpinner(false);
        setSample(true);
        const params = [];
        Object.entries(res).map((arr) => {
          params.push({
            name: arr[0],
            y: arr[1],
          });
        });
        Highcharts.chart('prediction-chart', {
          chart: {
            plotShadow: false,
            type: 'pie',
          },
          title: {
            text: 'MAJOR CONTRIBUTING FACTORS',
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.1f}%</b>',
          },
          legend: {
            labelFormatter() {
              console.log(this);
              console.log(this.data);
              console.log(this.name);
              return legends[this.name];
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              cursor: 'pointer',
              showInLegend: true,
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.y:.1f} %',
                style: {
                  color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                },
              },
            },
          },

          series: [{
            colorByPoint: true,
            data: params,
          }],
        });
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };
  useEffect(() => {
    d3.csv(uninsured_us_data)
      .then((data) => {
        parsecsv(data);
      })
      .catch((err) => {
        setNotification(err.message);
      });
  }, []);

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            According to recent census data, approximately 9 percent of the people in the United States do not
            have a health insurance. That's 29.5 million people who may receive substandard medical care or be
            turned away due to their lack of status. Because the United States does not have nationalized
            healthcare, citizens rely on health insurance to cover their medical expenses.
            {' '}
          </p>
          <p>
            There are a wide variety of coverage types offered by both private companies and public healthcare
            systems like Medicare and Medicaid. Even so, the rate of uninsured were at 18% before the Affordable
            Care Act (ACA) mandate in 2013.
          </p>
          <p>
            This solution provides information about features that impact the rate of uninsured in the United States.
            Although there are many factors that contribute directly or indirectly towards the count of the uninsured,
            there are some important factors on which insurance providers can focus.
            {' '}
          </p>
          <p>
            <strong>Data: </strong>
            Datasets are incorporated from different sources which include community health
            indicators, demographic details, median household income, and rural & urban breakdown data.
          </p>
          <p>
            <strong>Method: </strong>
            Random Forest modelling technique is used to determine important features.
            The benefit of using Random Forest is that it requires almost no input preparation. Also, it can handle binary
            features, categorical features, numerical features without any need for scaling and can perform implicit
            feature selection and provide a pretty good indicator of feature importance. Further, the top 10 features in
            order of importance are selected from the model.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.census.gov/topics/income-poverty/income/data/tables.html"
                  target="_blank"
                  rel="noreferrer"
                >
                 Data Source
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Rural_Uninsured/Understanding_the_Rural_Uninsured.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.census.gov/topics/income-poverty/income/data/tables.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source 1
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.census.gov/programs-surveys/geography/guidance/geo-areas/urban-rural/2010-urban-rural.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source 2
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSample}
            >
              Sample
            </StyledButton>
            <br />
            <br />
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <section>
              <Paper>
                <Table>
                  {/* <TableHead>
                                        <TableRow>
                                            {fields.map((field) => (
                                                <TableCell key={field.name} align="left"> <strong>{field.name}</strong></TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataRows.map(row => (
                                            <TableRow key={row.index}>
                                                {fields.map(field => (
                                                    <TableCell align="left">
                                                        {field.name === "index"
                                                            ? row[field.name] + 1
                                                            : row[field.name]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody> */}
                  <TableHead>
                    <TableRow>
                      {fields && fields.map((field) => (
                        <TableCell>{field}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataRows && dataRows.map((row) => (
                      <TableRow>
                        {fields.map((field) => (
                          <TableCell>{row[field]}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <br />
              <br />
              <Grid container xs={12} direction="row" justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <HighchartContainer
                    id="prediction-chart"
                    display={result}
                  />
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(UsUninsured);

const Paper = styled.div`
  overflow: auto;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
