import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  Divider,
  ListItem,
  TextField,
  ListItemText,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
//import { ReactMic } from "@cleandersonlobo/react-mic";

// local

import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";

// images
import lungCancerComorbidities_sol1 from "../../../assets/images/app/lungCancerComorbidities_sol1.webp";
import lungCancerComorbidities_sol2 from "../../../assets/images/app/lungCancerComorbidities_sol2.webp";

const LungCancerComorbidities = () => {
  const [uploadedAudioFile, setUploadedAudioFile] = useState("");
  const [errorType, setErrorType] = useState("");
  const [responseData, setResponseData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [audio, setAudio] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [sample, setSample] = useState("");
  const [mic, setMic] = useState(false);
  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [openSample, setOpenSample] = useState(true);
  const [openRecord, setOpenRecord] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [record, setRecord] = useState(false);
  const [audioBlob, setAudioBlob] = useState(false);
  const [sampleFile, setSampleFile] = useState(false);
  const [sample1, setSample1] = useState(false);
  const [sample2, setSample2] = useState(false);

  const { getQueryResult } = CommonService(
    "patientEngagement",
    "LungCancerComorbidities",
  );

  const { uploadFile } = CommonService(
    "patientEngagement",
    "LungCancerComorbidities",
  );

  const getBlobData = async (blobURL) => {
    const file = await fetch(blobURL)
      .then((r) => r.blob())
      .then(
        (blobFile) => new File([blobFile], "file.wav", { type: "audio/wav" }),
      );
    setRecodedaudio(file);
  };

  const setRecodedaudio = (selectedFile) => {
    setUploadedAudioFile(selectedFile);
  };

  const openUploadClick = () => {
    setResponseData("");
    setOpenUpload(true);
    setOpenRecord(false);
    setOpenSample(false);
    setMic(false);
    setRecord(false);
  };

  const openRecordClick = () => {
    setOpenRecord(true);
    setMic(true);
    setRecord(false);
    setAudioBlob(false);
    setOpenUpload(false);
    setOpenSample(false);
  };

  const openSampleClick = () => {
    setOpenSample(true);
    setOpenRecord(false);
    setOpenUpload(false);
    setMic(true);
    setAudioBlob(false);
    setRecord(false);
  };

  const startRecording = () => {
    setRecord(true);
  };

  const stopRecording = () => {
    setRecord(false);
  };

  const onStop = (recordedBlob) => {
    setAudioBlob(recordedBlob.blobURL);
    setMic(false);
    getBlobData(recordedBlob.blobURL);
  };

  const handleChange = (e) => {
    setAudio(false);
    setSample(false);
    if (e.target.files[0] !== undefined) {
      setFileUrl(URL.createObjectURL(e.target.files[0]));
    }
    setUploadedAudioFile(e.target.files[0]);
    setAudio(true);
    setFileUrl(URL.createObjectURL(e.target.files[0]));
  };

  const setAlert = (errorType, errorMessage) => {
    setErrorType(errorType);
    setErrorMessage(errorMessage);
    setTimeout(() => {
      setErrorType("");
    }, 3000);
  };

  const getSampleAudioRes = (e) => {
    const audioFile = e.currentTarget.getAttribute("data-sample");

    if (audioFile == "sampleaudio_1/") {
      setSampleFile(
        "https://material.vlifevirtusa.com/LungCancer/sampleaudio_1.wav",
      );
      setSample1(true);
      setSample2(false);
    } else {
      setSampleFile(
        "https://material.vlifevirtusa.com/LungCancer/sampleaudio_2.wav",
      );
      setSample1(false);
      setSample2(true);
    }
    setAudio(false);
    setFileUrl("");

    setSpinner(true);
    setResponseData("");
    setAudio(true);
    setSample(true);
    getQueryResult("sampleResult", audioFile)
      .then((res) => {
        setResponseData(res);
        setSpinner(false);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadAudioRes = () => {
    if (!uploadedAudioFile) {
      setAlert("warning", "Please choose the file");
      setResponseData("");
    } else {
      setSpinner(true);
      setResponseData("");
      uploadFile("uploadResult", uploadedAudioFile)
        .then((res) => {
          setSpinner(false);
          setResponseData(res.result);
          console.log(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      {/* <ContainerData>
        <TitleClientData>Lung Cancer Comorbidities Predicter</TitleClientData>
      </ContainerData>
      <br /> <br /> <br /> <br /> <br /> <br /> */}
      <Collapse text="Description">
        <Paragraph>
          <h4>Analytics Functions Used and Approach Followed – </h4>
          <p>
            The model comprises of a
            {' '}
            <b>Convolutional Neural Network (CNN)</b>
            using Keras and a Tensorflow backend. The convolution layers are
            designed for feature detection. It works by sliding a filter window
            over the input and performing a matrix multiplication and storing
            the result in a feature map.
          </p>
          <p>
            The output layer will have 6 nodes (num_labels) which matches the
            number of possible classifications. Activation Function (Softmax)
            makes the output sum up to 1 so the output can be interpreted as
            probabilities. The model then makes its prediction based on which
            option has the highest probability among the six classes
          </p>
          <p>The Patient is hence classified in 6 classes</p>
          <p>
            Bronchiectasis, Bronchiolitis, COPD, Healthy, Pneumonia, URTI, which
            briefly means –
          </p>
          <p>
            <b>Bronchiectasis - </b>
            Bronchiectasis is a disease in which there
            is permanent enlargement of parts of the airways of the lung.
            Symptoms typically include a chronic cough with mucus production.
          </p>
          <p>
            <b>Bronchiolitis - </b>
            Bronchiolitis is a common lung infection in
            young children and infants. It causes inflammation and congestion in
            the small airways (bronchioles) of the lung.
          </p>
          <p>
            <b>COPD - Chronic obstructive pulmonary disease (COPD)</b>
            {' '}
            is a
            common lung disease. Having COPD makes it hard to breathe. There are
            two main forms of COPD: Chronic bronchitis, which involves a
            long-term cough with mucus. Emphysema, which involves damage to the
            lungs over time.
          </p>
          <p>
            <b>Pneumonia</b>
            {' '}
            – Infection that inflames air sacs in one or both
            lungs, which may fill with fluid.
          </p>
          <p>
            <b>URTI- An upper respiratory tract infection (URTI) </b>
            is an
            illness caused by an acute infection, which involves the upper
            respiratory tract, including the nose, sinuses, pharynx, or larynx.
          </p>
          <p>
            For Exploratory Data Analysis and Data understanding, a Mel
            Frequency Cepstral Coefficient (MFCC) graph is drawn to extract
            features (number of wheezes and crackles, measuring similarity
            between audios, analysing voice amplitudes etc.)
          </p>
          <center>
            <img
              width="60%"
              height="250px"
              src={lungCancerComorbidities_sol1}
            />
          </center>
          <p>
            Further to which, the counts of different classes was plotted on a
            simple bar graph for further analysis
          </p>
          <center>
            <img
              width="60%"
              height="250px"
              src={lungCancerComorbidities_sol2}
            />
          </center>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/LungCancerComorbiditiesPredictor/LungCancerComorbiditiesPredictor.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/code/markdenton/cnn-disease-classification-linked-features-95#Parsing-the-Dataset"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <section>
            {errorType && (
              <StyledAlert severity={errorType}>{errorMessage}</StyledAlert>
            )}
            <div>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <div>
                    <ul>
                      <Divider />

                      <ListItem button onClick={openUploadClick}>
                        <ListItemText primary="UPLOAD" href="#simple-list" />
                      </ListItem>

                      <Divider />

                      <ListItem button onClick={openRecordClick}>
                        <ListItemText primary="RECORD A VOICE" />
                      </ListItem>

                      <Divider />

                      <ListItem button onClick={openSampleClick}>
                        <ListItemText primary="SAMPLE CLIPS" />
                      </ListItem>

                      <Divider />
                    </ul>
                  </div>
                </Grid>

                <Grid xs={12} md={6} alignItems="center">
                  {
                    // OpenUpload
                    openUpload && (
                      <StyledDiv>
                        <Typography gutterBottom>
                          Please upload an audio clip of your voice (of file
                          type .wav)
                        </Typography>
                        <TextField
                          type="file"
                          onChange={handleChange}
                          helperText="Please select audio(.wav) file"
                        />
                        <br />
                        {audio && fileUrl && (
                          <div>
                            <audio controls>
                              <source src={fileUrl} type="audio/wav" />
                            </audio>
                          </div>
                        )}
                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={getUploadAudioRes}
                        >
                          Execute
                        </StyledButton>
                      </StyledDiv>
                    )
                  }
                  {
                    // OpenSamples
                    openSample && (
                      <StyledDiv>
                        <Typography gutterBottom>
                          Try one of our sample audio clips
                        </Typography>
                        <br />
                        <StyledButton
                          variant="contained"
                          data-sample="sampleaudio_1/"
                          color="primary"
                          onClick={getSampleAudioRes}
                        >
                          Sample1
                        </StyledButton>
                        <StyledButton
                          variant="contained"
                          data-sample="sampleaudio_2/"
                          color="primary"
                          onClick={getSampleAudioRes}
                        >
                          Sample2
                        </StyledButton>
                        {audio && sample && sample1 && (
                          <div>
                            <br />
                            <audio controls>
                              <source src={sampleFile} type="audio/wav" />
                            </audio>
                          </div>
                        )}
                        {audio && sample && sample2 && (
                          <div>
                            <br />
                            <audio controls>
                              <source src={sampleFile} type="audio/wav" />
                            </audio>
                          </div>
                        )}
                      </StyledDiv>
                    )
                  }
                  {
                    // OpenRecord
                    openRecord && mic ? (
                      <StyledDiv>
                        <Typography gutterBottom>
                          If you do not have an audio clip of your voice, you
                          can record yourself here and upload your clip.
                        </Typography>
                        {/* <StyledReactMic
                          record={record}
                          onStop={onStop}
                          strokeColor="white"
                          backgroundColor="black"
                        /> */}
                        <AudioBtn
                          variant="contained"
                          color="primary"
                          onClick={startRecording}
                          type="StyledButton"
                        >
                          Start
                        </AudioBtn>
                        <AudioBtn
                          variant="contained"
                          color="primary"
                          onClick={stopRecording}
                          type="StyledButton"
                        >
                          Stop
                        </AudioBtn>
                      </StyledDiv>
                    ) : openRecord && audioBlob ? (
                      <StyledDiv>
                        <Typography gutterBottom>
                          If you do not have an audio clip of your voice, you
                          can record yourself here and upload your clip.
                        </Typography>
                        <audio controls>
                          <source src={audioBlob} type="audio/wav" />
                        </audio>
                        <br />
                        <AudioBtn
                          variant="contained"
                          color="primary"
                          onClick={openRecordClick}
                          type="StyledButton"
                        >
                          Record Again
                        </AudioBtn>
                        <br />
                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={getUploadAudioRes}
                        >
                          Execute
                        </StyledButton>
                      </StyledDiv>
                    ) : (
                      ""
                    )
                  }
                </Grid>
                <Grid item xs={12} md={3}>
                  <div>
                    <Typography gutterBottom variant="h6" component="body">
                      Output
                    </Typography>
                    <br />
                    {showSpinner && <Spinner text="Loading..." />}
                    {responseData && (
                      <Grid container XS={12}>
                        <Grid item xs={12} md={12}>
                          <div>
                            <br />
                            <section>
                              <Result>{responseData}</Result>
                            </section>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    <br />
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(LungCancerComorbidities);

const StyledDiv = styled.div`
  margin-top: 16px;
`;
const ContainerData = styled.section`
  padding: 13px 15px 0px 15px;
`;
const StyledDiv1 = styled.div`
  padding: 120px 100px 100px 100px;
  background: #ffffff;
`;
const AudioBtn = styled(StyledButton)`
  margin: 10px;
`;
const TitleClientData = styled.h1`
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
  color: #333333;
  margin: 0px 100px -100px 100px;
  text-align: center;
`;
const StyledAlert = styled(Alert)`
  width: 29%;
  margin-left: 34%;
`;
// const StyledReactMic = styled(ReactMic)`
//   border-radius: 50px;
//   padding: 10px;
// `;
