import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";

// images
import sdoh_sol1 from "../../../assets/images/app/sdoh11.webp";
import sdoh_sol2 from "../../../assets/images/app/sdoh3.webp";

const SDOH = () => {
  const initialData = {
    exercise_wk: '',
    empl_status: '',
    health_drugs: '',
    income: '',
    age: '',
    insurance_type: '',
    social_engagement: '',
    marital_status: '',
    transportation: '',
    food_security: '',
    community_safety: '',
    housing_security: '',
    ethnicity: '',
    housing_utilities: '',
    health_literacy: '',
    empl_hrs: '',
    living_situation: '',
    edu_level: '',
    location: '',
    housing_rent_mortgage: '',
    empl_job_security: '',
    gender: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("payerProvider", "sdoh");

  const insurance_type = [
    { name: 'Medicaid Insurance Plan', value: 0 },
    { name: 'Medicaid and Medicare Insurance Plan', value: 1 },
  ];
  const empl_status = [
    { name: 'Not working (looking for a job)', value: 0 },
    { name: 'Working part time', value: 1 },
    { name: 'Disabled, Not able to work', value: 2 },
    { name: 'Not working (not looking for a job)', value: 3 },
    { name: 'Retired', value: 4 },
    { name: 'Student', value: 5 },
    { name: 'Working full time', value: 6 },

  ];
  const exercise_wk = [
    { name: 'Less than a week', value: 0 },
    { name: 'More than thrice in a week', value: 1 },
    { name: 'Never exercise', value: 2 },
    { name: 'Thrice in a week', value: 3 },
    { name: 'Twice in a week', value: 4 },
    { name: 'Once in a week', value: 5 },
  ];
  const living_situation = [
    { name: 'Live in a household with other people', value: 0 },
    { name: 'Live in a residential facility', value: 1 },
    { name: 'Live alone in my own home', value: 2 },
    { name: 'Live in a facility such as nursing home', value: 3 },
    { name: 'Other', value: 4 },
    { name: 'Temporarily staying in a shelter or homeless', value: 5 },
    { name: 'Temporarily staying with a relative or friend', value: 6 },
  ];
  const housing_utilities = [
    { name: 'Always', value: 0 },
    { name: 'Never', value: 1 },
    { name: 'often', value: 2 },
    { name: 'rarely', value: 3 },
    { name: 'sometimes', value: 4 },
  ];
  const housing_rent_mortgage = [
    { name: 'Always', value: 0 },
    { name: 'Never', value: 1 },
    { name: 'often', value: 2 },
    { name: 'rarely', value: 3 },
    { name: 'sometimes', value: 4 },
  ];
  const empl_hrs = [
    { name: '20 - 30 hours', value: 0 },
    { name: '31 - 40 hours', value: 1 },
    { name: '41 - 50 hours', value: 2 },
    { name: 'Less than 20 hours', value: 3 },
    { name: 'More than 50 hours', value: 4 },
  ];
  const empl_job_security = [
    { name: 'Always', value: 0 },
    { name: 'Never', value: 1 },
    { name: 'often', value: 2 },
    { name: 'rarely', value: 3 },
    { name: 'sometimes', value: 4 },
  ];
  const edu_level = [
    { name: 'Less than high school degree', value: 0 },
    { name: 'Associate degree', value: 1 },
    { name: 'Bachelors degree', value: 2 },
    { name: 'Graduate degree', value: 3 },
    { name: 'High school degree or equivalent (eg. GED)', value: 4 },
    { name: 'Some college but no degree', value: 5 },
  ];
  const health_drugs = [
    { name: 'Monthly', value: 0 },
    { name: 'Never', value: 1 },
    { name: 'Once or Twice', value: 2 },
    { name: 'Daily or Amost Daily', value: 3 },
    { name: 'Weekly', value: 4 },
  ];
  const gender = [
    { name: 'Female', value: 0 },
    { name: 'Male', value: 1 },
    { name: 'Prefer not to answer', value: 2 },
  ];
  const age = [
    { name: '35 - 44 year', value: 0 },
    { name: '25 - 34 year', value: 1 },
    { name: '45 - 54 year', value: 2 },
    { name: '55 - 64 year', value: 3 },
    { name: '65 or older years', value: 4 },
  ];
  const location = [
    { name: 'Urban city area', value: 0 },
    { name: 'Rural area', value: 1 },
    { name: 'Small town far outside a large city', value: 2 },
    { name: 'Suburban area around a larger city', value: 3 },
  ];
  const ethnicity = [
    { name: 'American Indian or Alaska Native', value: 0 },
    { name: 'Hispanic or Latino', value: 1 },
    { name: 'Other', value: 2 },
    { name: 'Asian', value: 3 },
    { name: 'Black or African American', value: 4 },
    { name: 'White', value: 5 },
  ];
  const marital_status = [
    { name: 'In a relationship, living together', value: 0 },
    { name: 'Prefer not to answer', value: 1 },
    { name: 'Single, never married', value: 2 },
    { name: 'Widowed', value: 3 },
    { name: 'Divorce', value: 4 },
    { name: 'Married', value: 5 },
  ];
  const income = [
    { name: '$10,000 - $19,000', value: 0 },
    { name: '$20,000 - $29,999', value: 1 },
    { name: '$30,000 - $39,999', value: 2 },
    { name: '$40,000 - $49,999', value: 3 },
    { name: '$50,000 - $59,999', value: 4 },
    { name: '$60,000 - $69,999', value: 5 },
    { name: '$70,000 - $79,999', value: 6 },
    { name: '$80,000 - $89,999', value: 7 },
    { name: '$90,000 - $99,999', value: 8 },
    { name: 'More than $90,000', value: 9 },
    { name: 'Prefer not to answer', value: 10 },
  ];

  const showSample1 = () => {
    const NewDataSet = {
      insurance_type: '0',
      living_situation: '2',
      housing_utilities: '1',
      housing_rent_mortgage: '0',
      empl_status: '6',
      empl_hrs: '1',
      empl_job_security: '4',
      edu_level: '1',
      health_drugs: '1',
      exercise_wk: '4',
      gender: '0',
      age: '0',
      location: '3',
      ethnicity: '5',
      marital_status: '2',
      income: '3',
      housing_security: '2.00',
      health_literacy: '1.0',
      transportation: '1.00',
      community_safety: '2.2',
      food_security: '3.00',
      social_engagement: '3.00',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      insurance_type: '0',
      living_situation: '0',
      housing_utilities: '3',
      housing_rent_mortgage: '1',
      empl_status: '6',
      empl_hrs: '1',
      empl_job_security: '4',
      edu_level: '4',
      health_drugs: '1',
      exercise_wk: '2',
      gender: '0',
      age: '0',
      location: '1',
      ethnicity: '5',
      marital_status: '2',
      income: '1',
      housing_security: '1.75',
      health_literacy: '2.0',
      transportation: '2.50',
      community_safety: '1.2',
      food_security: '1.75',
      social_engagement: '3.00',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      insurance_type, living_situation, housing_utilities, housing_rent_mortgage, empl_status, empl_hrs,
      empl_job_security, edu_level, health_drugs, exercise_wk, gender, age, location, ethnicity,
      marital_status, income, housing_security, health_literacy, transportation, community_safety,
      food_security, social_engagement,
    } = sampleData;

    const params = `${insurance_type},${living_situation},${housing_utilities},${housing_rent_mortgage},${empl_status},
        ${empl_hrs},${empl_job_security},${edu_level},${health_drugs},${exercise_wk}, ${gender}, ${age},${location},
        ${ethnicity}, ${marital_status},${income},${housing_security},
        ${health_literacy}, ${transportation},${community_safety},${food_security},${social_engagement}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);
      SetResultData('');

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res.result);

          const percentages = res.feature_importances;
          const params = [];

          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          console.log(percentages);
          Highcharts.chart('prediction-chart', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The
            <b>social determinants of health</b>
            {' '}
            are the economic and social conditions that influence individual and group differences in
            health status. Social Determinant of health are conditions in the environments in which people are born, live, learn, work, play, worship, and age that affect a wide range of health, functionality and quality of life outcomes and risks.
            {' '}
          </p>
          <p>If any of the SDOH are missing from one’s life, this may lead to unmet social needs </p>
          <p><strong>Following SDOH factors are been measured here along with how one determines an unmet social need  </strong></p>
          <center>
            <Grid item xs={12}>
              <Image src={sdoh_sol1} alt="" />
            </Grid>
          </center>
          <p>
            <strong>Solution:</strong>
            Using 22 different SDOH, this application is predicting whether a person with given social determinants
            is prone to any chronic condition or not.  It can help healthcare payers to understand and anticipate health conditions of their incoming customers based on their unmet social needs.
          </p>
          <p>The machine learning approach was able to identify the relative value of multiple SDOH categories and determine their role in patient risk which could help providers seeking to improve health at the individual level.</p>
          <p><strong>Unmet social need importance- Respondents with chronic conditions </strong></p>
          <center>
            <Grid item xs={12}>
              <Image src={sdoh_sol2} alt="" />
            </Grid>
          </center>
          <p>Majority of the respondents with chronic condition(s) consistently ranked Housing security or Food security Reliable transportation as their most pressing social needs.</p>
          <p>
            <strong>Application:</strong>
            {' '}
            Insurance payers understands that, there are important factors beyond health care, although only 10% of the National Health Expenditure is spent on tackling these unmet social needs. With a desire to transform the health Industry from one of sick care to well care, US healthcare payers aims to address these unmet social needs affecting people with chronic condition(s) on Medicaid and Medicaid-Medicare Insurance plans.
          </p>
          <p><strong>Conclusion</strong></p>
          <p>This application highlights the significant influence of SDOH on individuals’ health and healthcare use. It is an important advancement in tackling disparities in healthcare because risk can be assessed without gathering information directly from the patient and thus can be incorporated efficiently into workflows.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/SDOH/SDOH.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.cdc.gov/socialdeterminants/data/index.htm"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="insurance_type"
                label="Insurance_Type"
                value={sampleData.insurance_type}
                onChange={handleInputs}
              >
                {insurance_type.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="living_situation"
                label="Living_situation"
                value={sampleData.living_situation}
                onChange={handleInputs}
              >
                {living_situation.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="housing_utilities"
                label="Housing_Utilities"
                value={sampleData.housing_utilities}
                onChange={handleInputs}
              >
                {housing_utilities.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="housing_rent_mortgage"
                label="Housing_Rent_Mortgage"
                value={sampleData.housing_rent_mortgage}
                onChange={handleInputs}
              >
                {housing_rent_mortgage.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="empl_status"
                label="Empl_Status"
                value={sampleData.empl_status}
                onChange={handleInputs}
              >
                {empl_status.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="empl_hrs"
                label="Empl_hrs"
                value={sampleData.empl_hrs}
                onChange={handleInputs}
              >
                {empl_hrs.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="empl_job_security"
                label="Empl_job_security"
                value={sampleData.empl_job_security}
                onChange={handleInputs}
              >
                {empl_job_security.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="edu_level"
                label="Edu_level"
                value={sampleData.edu_level}
                onChange={handleInputs}
              >
                {edu_level.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="health_drugs"
                label="Health_drugs"
                value={sampleData.health_drugs}
                onChange={handleInputs}
              >
                {health_drugs.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="exercise_wk"
                label="Exercise_wk"
                value={sampleData.exercise_wk}
                onChange={handleInputs}
              >
                {exercise_wk.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="gender"
                label="Gender"
                value={sampleData.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="age"
                label="Age"
                value={sampleData.age}
                onChange={handleInputs}
              >
                {age.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="location"
                label="Location"
                value={sampleData.location}
                onChange={handleInputs}
              >
                {location.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="ethnicity"
                label="Ethnicity"
                value={sampleData.ethnicity}
                onChange={handleInputs}
              >
                {ethnicity.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="marital_status"
                label="Marital_status"
                value={sampleData.marital_status}
                onChange={handleInputs}
              >
                {marital_status.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="income"
                label="Income"
                value={sampleData.income}
                onChange={handleInputs}
              >
                {income.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Housing_security"
                name="housing_security"
                fullWidth
                onChange={handleInputs}
                value={sampleData.housing_security}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Health_literacy"
                name="health_literacy"
                fullWidth
                onChange={handleInputs}
                value={sampleData.health_literacy}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Transportation"
                name="transportation"
                fullWidth
                onChange={handleInputs}
                value={sampleData.transportation}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Community_safety"
                name="community_safety"
                fullWidth
                onChange={handleInputs}
                value={sampleData.community_safety}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Food_security"
                name="food_security"
                fullWidth
                onChange={handleInputs}
                value={sampleData.food_security}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Social_Engagement"
                name="social_engagement"
                fullWidth
                onChange={handleInputs}
                value={sampleData.social_engagement}
              />
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}
          <HighchartContainer
            id="prediction-chart"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(SDOH);

const Image = styled.img`
  width: 60%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 400px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 300px;
  }
`;
