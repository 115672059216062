import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Paper, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CommonService from "../../../utils/services/CommonService";
// import local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";

// images
import handGesture_sample1 from "../../../assets/images/app/handGesture_sample1.webp";
import handGesture_sample2 from "../../../assets/images/app/handGesture_sample2.webp";

const HandGesture = () => {
  const { getQueryResult } = CommonService("payerProvider", "handGesture");
  const { uploadFile } = CommonService("payerProvider", "handGesture");

  const [showSpinner, setSpinner] = useState(false);
  const [text, setText] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [imageUrl, setImageUrl] = useState(false);
  const [notification, setNotification] = useState("");

  const onChangeFields = (e) => {
    const value = e.target.files[0];
    console.log(value);
    setSpinner(true);
    setImageUrl("");
    setText("");
    setUploadedFile(URL.createObjectURL(value));

    uploadFile("upload", value)
      .then((result) => {
        console.log(result);
        setImageUrl(result.detected_image_url);
        setText(result.message);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });
  };

  const getImageResult = (sample) => {
    setSpinner(true);
    setText("");
    setImageUrl("");

    getQueryResult(sample)
      .then((result) => {
        console.log(result);
        setText(result.message);
        setImageUrl(result.detected_image_url);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const showUploadOption = () => {
    setUploadedFile("");
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Gesture recognition</b>
            {' '}
            is a type of perceptual computing user
            interface that allows computers to capture and interpret human
            {" "}
            <b>gestures</b>
            {' '}
            as commands. One subset of Gesture Recognition is “
            Hand Gesture Recognition”. It focuses on capturing and identifying
            different hand gestures in either images or videos. This application
            focuses on detecting and recognizing simple hand gestures with high
            accuracy.
          </p>
          <p>The hand-gesture recognition process workflow is as follows:</p>
          <ul>
            <li>
              Preprocessing the input image: The input image is preprocessed to
              separate the active part of the image from the background, which
              usually consists of the hand gesture to be recognized. Contours
              are detected and a boundary box joining those contours is created.
            </li>
            <li>
              The angles between the contours is calculated and the area under
              the identified bounding box is determined. Now that the contours
              are identified and a bounding box is created, the angles between
              each of the contours, along with the area enclosed by the bounding
              box are calculated to approximately determine the hand gesture.
            </li>
            <li>
              Testing the application :A few sample images (images of hand
              gestures) are fed to the application to obtain the detected hand
              gesture.
            </li>
          </ul>
          <p>
            <strong> Application</strong>
          </p>
          <p>
            Hand gesture recognition is a tool to enable sign language using
            computer vision techniques. It can be used at any place where deaf
            and mute people need to communicate ranging from a commercial place
            to a hospital.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/HandGesture/HandGestureRecognition.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={4}>
              <MaskPaper elevation={3}>
                <Image src={handGesture_sample1} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={getImageResult.bind(this, "sample1")}
                >
                  Sample 1
                </MarginButton>
              </MaskPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <MaskPaper elevation={3}>
                <Image src={handGesture_sample2} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={getImageResult.bind(this, "sample2")}
                >
                  Sample 2
                </MarginButton>
              </MaskPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3}>
                <MaskPaper>
                  {uploadedFile ? (
                    <section>
                      <Image src={uploadedFile} alt="" />
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={showUploadOption}
                      >
                        Upload Different Image
                      </StyledButton>
                    </section>
                  ) : (
                    <section>
                      <Typography variant="h6">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {' '}
                        Upload Image
                      </Typography>
                      <HiddenInput
                        name="uploadedFile"
                        onChange={onChangeFields}
                        id="icon-button-file"
                        type="file"
                      />
                      <label
                        variant="contained"
                        color="primary"
                        htmlFor="icon-button-file"
                      >
                        <UploadIcon fontSize="large" />
                      </label>
                    </section>
                  )}
                </MaskPaper>
              </Paper>
            </Grid>
          </Grid>
          <section>{showSpinner && <Spinner text="Loading..." />}</section>
          {imageUrl && (
            <section>
              <Result>{text}</Result>
              <img src={imageUrl} width="60%" alt="" />
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(HandGesture);
const MaskPaper = styled(Paper)`
  justify-content: center;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 230px;
`;
const MarginButton = styled(StyledButton)`
  margin-right: 10px;
  margin-left: 10px;
`;
const Image1 = styled.img`
  @media only screen and (min-width: 900px) {
    width: 800px;
    height: 400px;
  }
`;
const ResponsiveTypography = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
`;
const UploadIcon = styled(CloudUploadIcon)`
  cursor: pointer;
  margin-top: 20px;
`;
const HiddenInput = styled.input`
  display: none;
`;
