import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import tilemap from "highcharts/modules/tilemap";
import Highcharts from "highcharts/highmaps";
import drilldown from "highcharts/modules/drilldown";
import * as d3 from "d3";
import { StyledButton } from "../../styles/common";
import data from "../../assets/files/malefemale.csv";

tilemap(Highcharts);
drilldown(Highcharts);

class MaleAndFemale extends React.Component {
  constructor(props) {
    super(props);

    this.dict = [];
    this.pproc = [];
    this.d1 = [];
    this.d2 = [];
    this.des = [];

    this.parsecsv = this.parsecsv.bind(this);
    this.createMapData = this.createMapData.bind(this);
    this.createMap = this.createMap.bind(this);
  }

  componentDidMount() {
    d3.csv(data).then((data) => {
      this.parsecsv(data);
    });
  }

  parsecsv(data) {
    for (let i = 0; i < data.length; i++) {
      const pproc = data[i][0];
      const d1 = parseInt(data[i][1], 10);
      const d2 = parseInt(data[i][2], 10);
      const des = data[i][3];
      this.dict.push([pproc, d1, d2, des]);
    }
    this.createMapData(this.dict);
  }

  createMapData(data1) {
    for (const i in data1) {
      this.pproc.push(data1[i][0]);
      this.d1.push(data1[i][1]);
      this.d2.push(data1[i][2]);
      this.des.push(data1[i][3]);
    }
    this.createMap(this.dict);
  }

  createMap(data1) {
    Highcharts.chart("container", {
      chart: {
        type: "column",
      },
      title: {
        text: "Male and Female Distribution Based on Top 10 Procedures",
      },

      xAxis: {
        categories: this.pproc,
        crosshair: true,
        title: {
          text: "Procedures Group",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Population",
        },
      },
      tooltip: {
        formatter() {
          for (const i in data1) {
            if (this.x === data1[i][0]) {
              return (
                `Total ${
                  this.series.name
                } Patients under `
                + `<b>${
                  data1[i][3]
                }</b>`
                + ` is `
                + `<b>${
                  this.y
                }</b>`
              );
            }
          }
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Male",
          data: this.d1,
        },
        {
          name: "Female",
          data: this.d2,
        },
      ],
    });
  }

  render() {
    return (
      <>
        <Grid container alignItems="end" justifyContent="flex-end">
          <Grid item xs={12}>
            <StyledButton
              size="large"
              component={Link}
              to="/solutions/population-health-dashboard"
            >
              Previous
            </StyledButton>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={10} md={10}>
            <div id="container" />
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography>
              * This Visualization has Drilldown capabilities based on Diagnosis
              group and Patient Total Pay on Bill Type.
            </Typography>
          </Grid>
        </Grid>
        <Typography />
      </>
    );
  }
}
export default memo(MaleAndFemale);
