import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, RadioGroup, FormControlLabel, Radio, FormLabel,
} from '@material-ui/core';
import { StyledInput } from '../../InputForm';
import { setInputData } from '../../../../../../store/actions/vnet';

export const CTGANForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector((state) => state.vnet);
  const [hideTextField, sethideTextField] = useState(isCanvasClicked);
  const [fieldError, setFieldError] = useState(isCanvasClicked);
  const dispatch = useDispatch();

  const isFormValid = () => {
    if (!hideTextField && !userInputData?.No_of_Rows) {
      setFieldError(true);
    } else {
      return true;
    }
  };
  const handleFormReset = () => {
    console.log('reset');
  };

  const handleOnChange = (e) => {
    dispatch(setInputData({
      ...userInputData,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name == 'No_of_Rows' && fieldError) {
      setFieldError(false);
    }
  };

  return (
    <>
      {hideTextField
        ? (
          <Grid container>
            <Grid item xs={12} sm={8}>
              <FormLabel component="label">Select Dataset</FormLabel>
              <RadioGroup
                row
                name="Merge"
                defaultValue="False"
                aria-label="Learn Mixture Weights"
                onChange={handleOnChange}
              >
                <FormControlLabel
                  value="True"
                  control={<Radio color="primary" />}
                  label="Synthetic + Original Dataset"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="False"
                  control={<Radio color="primary" />}
                  label="Synthetic Dataset"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        )
        : (
          <StyledInput
            type="number"
            label="Total no. of rows"
            required
            onChange={handleOnChange}
            value={userInputData?.No_of_Rows}
            variant="outlined"
            name="No_of_Rows"
            error={fieldError}
            helperText={fieldError && 'Required Field'}
            info="Total no of rows"
          />
        )}

      {props.render({ handleFormReset, isFormValid })}
    </>
  );
});
