import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import solutions from "../../constants/maps/solutions";
import MediaCard from "./MediaCard";

const ThemedOfferings = ({
  solutionIds,
  hideSolInfo,
  gridSize = 4,
}) => {
  const [list, setSolutionsList] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  useEffect(() => {
    if (solutionIds.length > 0) {
      const list = solutions.filter((item) => solutionIds.includes(item.id));
      setSolutionsList(list);
    }
  }, [solutionIds]);

  return (
    <>
      {list?.map((items) => (
        <Grid item xs={12} sm={6} md={gridSize} lg={3}>
          <MediaCard
            type={hideSolInfo && "solution"}
            {...items}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
        </Grid>
      ))}
    </>
  );
};

export default ThemedOfferings;
