import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CohortAnalyzerContentView from './CohortAnalyzerContentView';
import MainViewStyle from '../viz/main-view';
import PreviewDataSection from './PreviewDataSection';
import FeatureScalingSection from './FeatureScalingSection';
import TraditionalModelSection from './TraditionalModelSection';
import ModelSelectionSection from './ModelSelectionSection';
import Navbar from '../../common/Navbar';
import Footer from '../../views/home/Footer';
import MainLayout from "../../common/MainLayout";
import { PageContainer, PageTitle } from "../../../styles/common";

class CohortAnalyzerToolView extends React.Component {
  constructor(props) {
    super(props);
    this.currentComponent = 'cohortAnalyzer';
    this.navigateToPreviewData = this.navigateToPreviewData.bind(this);
    this.navigateToFeatureScaling = this.navigateToFeatureScaling.bind(this);
    this.navigateToTraditionalModelSection = this.navigateToTraditionalModelSection.bind(this);
    this.navigateToModelSelection = this.navigateToModelSelection.bind(this);

    this.state = {
      currentComponent: "cohortAnalyzer",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  navigateToPreviewData() {
    this.setState({
      currentComponent: "previewData",
    });
  }

  navigateToFeatureScaling() {
    this.setState({
      currentComponent: "featureScaling",
    });
  }

  navigateToTraditionalModelSection() {
    this.setState({
      currentComponent: "traditionalModelSection",
    });
  }

  navigateToModelSelection() {
    this.setState({
      currentComponent: "modelSelection",
    });
  }

  getComponent(componentName) {
    const components = {
      cohortAnalyzer: <CohortAnalyzerContentView navigateNext={this.navigateToPreviewData} />,
      previewData: <PreviewDataSection navigateNext={this.navigateToFeatureScaling} />,
      featureScaling: <FeatureScalingSection navigateNext={this.navigateToModelSelection} />,
      modelSelection: <ModelSelectionSection navigateNext={this.navigateToTraditionalModelSection} />,
      traditionalModelSection: <TraditionalModelSection />,
    };

    return components[componentName];
  }

  render() {
    const { classes } = this.props;
    return (
      <MainLayout>
        <Navbar />
        <PageContainer>
          <PageTitle>Cohort Sense</PageTitle>
          <p>
            Integrate and analyze subject and data together using innovative visualization
            and analysis tools
          </p>

          <Grid container>
            <Grid item xs={12} md={6}>
              <b>Why Cohort Sense?</b>

              <ul>
                <li>Every Problem starts with choosing a cohort</li>
                <li>Every cohort has different dimensions and flavor to it</li>
                <li>Creating custom cohorts is tedious and query intensive</li>
              </ul>

              <b>What Cohort Sense offers?</b>

              <ul>
                <li>A drag drop interface to quickly visualize the database</li>
                <li>A plethora of in-built visualizations for custom cohorts</li>
                <li>
                  Zero wait time to have the snapshot of your cohort – can modify in split
                  second
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} md={6}>
              <b>How to Create custom Cohorts?</b>

              <ul>
                <li>Choose a DB (Default - US simulated Population)</li>
                <li>Select a visualization format (Default – Table)</li>
                <li>Expand Demographics/Diagnoses/Medication</li>
                <li>Drag the feature of choice onto the adjacent or top box</li>
                <li>This creates the first cohort, add on extra features as filters</li>
                <li>Filters are applied in hierarchical order</li>
                <li>Toggle the visualization options to find the choice of view</li>
              </ul>

            </Grid>
          </Grid>

          <section>
            <p>
              'Preview Data' works only when Diagnosis is selected as follows:
              <ul>
                <li>Type 2 Diabetes Mellitus</li>
                <li>Chronic Kidney Disease (Ckd)</li>
                <li>Type 2 Diabetes Mellitus - YES and Chronic Kidney Disease (Ckd)</li>
              </ul>
            </p>
            <div>
              An AI driven simulated data set generated based on US statistics. This mirrors US real
              population and
              models diseases in multiple states. This data is PHI compliant and consists of
              363+ Mn records capturing demographics, observations, diagnoses, procedures and
              medications.
            </div>
            <div style={{ overflow: 'auto' }}>
              {
                this.getComponent(this.state.currentComponent)
              }
            </div>
          </section>
        </PageContainer>
      </MainLayout>

    );
  }
}

CohortAnalyzerToolView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(MainViewStyle)(CohortAnalyzerToolView);
