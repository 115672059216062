import { isNumber } from "highcharts";

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const formatRequest = {

  csvParams: () => ({ filepath: "./titanic_classification.csv" }),
  renameCol: (data) => ({ columns: data }),
};

export const isObjKeyhasval = (errData) => Object.values(errData).filter((err) => err && err).length <= 0;

export function validateForm(validationData, errorCallback) {
  const isAlpha = (text) => /^[a-zA-z]+$/.test(text);
  const isNumeric = (text) => /^-?\d+(\.\d+)?$/.test(+text);
  const isAlphaNumeric = (text) => /^[a-zA-z0-9]+$/.test(text);
  const isInteger = (text) => /^(\+|-)?\d+$/.test(text);
  const isFloat = (text) => /^[+-]?([0-9]{1,})[.]([0-9]{1,})$/.test(text);

  const validateOnBlur = (e) => {
    const { name, value } = e.target;
    if (value) {
      const {
        onlyNum = false, onlyChar = false, noSpecialChars = false, onlyInt = false, onlyFloat = false,
      } = validationData[name];
      if (onlyInt && !isInteger(value)) {
        errorCallback({ [name]: "value should be an Integer" });
        return;
      }
      if (onlyFloat && !isFloat(value)) {
        errorCallback({ [name]: "value should be a Float" });
        return;
      }
      if (onlyNum && !isNumeric(value)) {
        errorCallback({ [name]: "value should be a number" });
        return;
      }
      if (onlyChar && !isAlpha(value)) {
        errorCallback({ [name]: "value should contain only characters" });
        return;
      }
      if (noSpecialChars && !isAlphaNumeric(value)) {
        errorCallback({ [name]: "value should be only characters/numbers" });
        return;
      }
      if (value > validationData[name]?.maxNum) {
        errorCallback({ [name]: `Max number should be ${validationData[name]?.maxNum}` });
        return;
      }
      if (value < validationData[name]?.minNum) {
        errorCallback({ [name]: `Min Number should be ${validationData[name]?.minNum}` });
        return;
      }
      errorCallback({ [name]: "" });
    }
  };

  return { validateOnBlur };
}
