import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  Checkbox,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";

export const CorrelationMatrixForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const [dropdownList, setdropdownList] = useState(null);
  const dispatch = useDispatch();
  const [selectedColumn, setChecked] = useState({});

  useEffect(async () => {
    const result = await props.getCategories();
    // let result = "[\"class_1\",\"class_2\",\"class_3\",\"class_4\",\"class_5\",\"class_6\"]"
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (e.target.name) {
      if (name !== "SelectAll") {
        const latestCheckList = {
          ...selectedColumn,
          [name]: checked,
        };
        if (selectedColumn.all && checked == false) {
          latestCheckList.all = false;
        }
        setChecked({
          ...latestCheckList,
        });

        const updatedList = Object.keys(latestCheckList).filter(
          (key) => latestCheckList[key],
        );
        console.log(updatedList);
        dispatch(
          setInputData({
            columns: updatedList,
          }),
        );
      } else {
        const selectAll = {};
        dropdownList.map((key) => {
          selectAll[key] = checked;
        });
        setChecked({
          ...selectAll,
          all: checked,
        });
        dispatch(
          setInputData({
            columns: checked ? [...dropdownList] : [],
          }),
        );
      }
    }
  };
  const handleFormReset = () => {
    console.log('reset');
  };

  const isFormValid = () => true;
  return (
    <MatrixContainer>
      <FormControlLabel
        control={(
          <Checkbox
            name="SelectAll"
            onChange={handleCheckBox}
            value="SelectAll"
            checked={!!selectedColumn.all}
          />
        )}
        label="Select All"
      />
      <br />
      <StyledDiv>
        <CheckboxGrid container spacing={3} alignItems="flex-start">
          {dropdownList?.map((value) => (
            <>
              <Grid item xs={4} sm={4} md={4}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name={value}
                      onChange={handleCheckBox}
                      checked={!!selectedColumn[value]}
                      value={value}
                    />
                  )}
                  label={value}
                />
              </Grid>
            </>
          ))}
        </CheckboxGrid>
      </StyledDiv>
      {props.render({ handleFormReset, isFormValid })}

    </MatrixContainer>
  );
});

const MatrixContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
    min-width: 190px;
  }
`;

const StyledDiv = styled(Grid)`
  border: 1px solid grey;
  border-radius: 4px;
`;

const CheckboxGrid = styled(Grid)`
  height: 200px;
  margin-left: 10px;
  overflow: auto;
`;
