import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import Highcharts from 'highcharts/highmaps';
import * as d3 from 'd3';
import { StyledButton } from "../../styles/common";
import data from '../../assets/files/totalpay.csv';

class PatientTotalPayTop10 extends React.Component {
  constructor(props) {
    super(props);

    this.dict = [];
    this.drg = [];
    this.d15 = [];
    this.d16 = [];
    this.des = [];

    this.parsecsv = this.parsecsv.bind(this);
    this.createMapData = this.createMapData.bind(this);
    this.createMap = this.createMap.bind(this);
  }

  componentDidMount() {
    d3.csv(data)
      .then((data) => {
        console.log(data);
        this.parsecsv(data);
      })
      .catch((err) => {
        throw err;
      });
  }

  parsecsv(data) {
    for (let i = 0; i < data.length; i++) {
      const drg = data[i][0];
      const d1 = parseInt(data[i][1], 10);
      const d2 = parseInt(data[i][2], 10);
      const des = data[i][3];
      this.dict.push([drg, d1, d2, des]);
    }
    this.createMapData(this.dict);
  }

  createMapData(data) {
    console.log(data);
    for (const i in data) {
      console.log(data[i][0]);
      this.drg.push(data[i][0]);
      this.d15.push(data[i][1]);
      this.d16.push(data[i][2]);
      this.des.push(data[i][3]);
    }
    this.createMap(this.dict);
  }

  createMap(data1) {
    Highcharts.chart('container', {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Total Pay of Top 10 Diagnosis',
      },
      xAxis: {
        categories: this.drg,
        title: {
          text: 'Diagnosis Group',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Pay',
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray',
          },
        },
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {

        formatter() {
          for (const i in data1) {
            if (this.x === data1[i][0]) {
              return `${'Total pay of ' + '<b>'}${data1[i][3]}</b>` + ` is ` + `<b>${this.y}</b>` + ` in ` + `<b>${this.series.name}</b>`;
            }
          }
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
            formatter() {
              return ` <strong>${this.series.name}`;
            },
          },
        },
      },
      series:
        [{

          name: '2015',
          data: this.d15,
        }, {
          name: '2016',
          data: this.d16,
        }],
    });
  }

  render() {
    return (
      <>
        <Grid container alignItems="end" justifyContent="flex-end">
          <Grid item xs={12}>
            <StyledButton
              size="large"
              component={Link}
              to="/solutions/population-health-dashboard"
            >
              Previous
            </StyledButton>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={10} md={10}>
            <div id="container" />
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography>
              * This Visualization has Drilldown capabilities based on Diagnosis group and Patient Total Pay on Bill Type.
            </Typography>
          </Grid>
        </Grid>
      </>

    );
  }
}
export default memo(PatientTotalPayTop10);
