import React, { memo, useState } from "react";
import styled from "styled-components";
import { Typography, Grid, Paper } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// local components
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import OcrCertificate_sol1 from "../../../assets/images/app/OCRcertificate.webp";

const OcrCertificate = () => {
  const [result, setResult] = useState(false);
  const [upload, setUpload] = useState(false);
  const [results, SetResultData] = useState(false);
  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [downloadurl, setDownloadUrl] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [startCapture, setStartCapture] = useState(false);
  const [uploadfile, setUploadfile] = useState("");

  const { getQueryResult, uploadFile } = CommonService(
    "patientEngagement", "OCRDeathCertificate",
  );

  const sample = () => {
    SetResultData('');
    setUpload('');
    setResult(true);
  };

  const getupload = () => {
    SetResultData('');
    setResult('');
    setUpload(true);
  };

  const resetCapture = () => {
    setStartCapture(false);
    setUploadfile("");
    setResult("");
  };

  const onChangeFields = (e) => {
    const uploadedFile = e.currentTarget.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(uploadedFile);

    reader.onloadend = function (e) {
      setUploadfile([reader.result]);
      setStartCapture(true);
    }.bind();
    getUploadResult(uploadedFile);
  };

  const getUploadResult = (uploadfile) => {
    setSpinner(true);
    SetResultData("");

    uploadFile("upload", uploadfile)
      .then((res) => {
        console.log(res);
        setSpinner(false);

        const table1 = JSON.parse(res[0]);
        const table2 = JSON.parse(res[1]);

        const dataRows = table1.data;
        const { fields } = table1.schema;
        const tabledata = [table1, table2];

        setTableData(tabledata);
        setField(fields);
        setDataRows(dataRows);
        setDownloadUrl(res[2]);
        SetResultData(res);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const sampleResult = () => {
    setSpinner(true);
    SetResultData("");

    getQueryResult("sample")
      .then((res) => {
        console.log(res);
        setSpinner(false);

        const table1 = JSON.parse(res[0]);
        const table2 = JSON.parse(res[1]);

        const dataRows = table1.data;
        const { fields } = table1.schema;
        const tabledata = [table1, table2];

        setTableData(tabledata);
        setField(fields);
        setDataRows(dataRows);
        setDownloadUrl(res[2]);
        SetResultData(res);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const handleChange = (event, newValue) => {
    const tableData = tabledata[newValue];

    setTabValue(newValue);
    const dataRows = tableData.data;
    const { fields } = tableData.schema;
    setField(fields);
    setDataRows(dataRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const val = event.target.value;
    setRowsPerPage(val);
    setPage("0");
  };

  return (

    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Optical Character Recognition (OCR) is an automated text reading
            process from images that includes machine printed (typographic)
            or handwritten character recognition and numeral recognition.
            Image processing is a process of analysing a digitised image for
            performing operations such as enhancement, compression,
            segmentation, or editing.
          </p>

          <Grid item xs={12} sm={12} md={12}>
            <center><SolImg src={OcrCertificate_sol1} alt="" /></center>
          </Grid>

          <p>
            In this solution OCR techniques are implemented via AWS Textract
            and python packages are used for language translation, allowing
            easy recognition of information from death certificates in
            Key-Value format. The vital information processed includes info
            such as name, SSN, marital status, etc. Translation will then
            occur after the processing of information and will convert and
            output all information into a table format in English.
          </p>
          <p>
            <strong> Sample :</strong>
            {' '}
            In this use case for a sample a death
            certificate is executed to showcase the OCR capability. The life
            claims adjustment process depends on the upload of documents to
            confirm the death. The main document to confirm the coverage and
            validity of the policy is the death certificate. Death
            certificates in Brazil are registered at the notary's offices.
            Although there are instructions on how to complete these
            documents, they are not registered in standard forms. In order
            to solve such problems, this tool is built to perform OCR (or
            text extraction) and then translate information from Portuguese
            to English.
          </p>
        </Paragraph>
        <LinkContainer />
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" spacing={2}>
            <StyledButton variant="contained" color="primary" onClick={sample}>
              Sample
            </StyledButton>
            <StyledButton variant="contained" color="secondary" onClick={getupload}>
              Upload
            </StyledButton>
          </Grid>
          {upload
                        && (
                        <section>
                          <Grid container xs={12} direction="row" justify="center" alignItems="center" alignContent="space-between">
                            <Grid item xs={10} sm={6} md={6}>
                              <Paper elevation={3}>
                                <ImagePaper>
                                  {startCapture ? (
                                    <>
                                      <Image src={uploadfile} alt="" />
                                      <StyledButton
                                        variant="contained"
                                        color="primary"
                                        onClick={resetCapture}
                                      >
                                        Upload Different Image
                                      </StyledButton>
                                    </>
                                  ) : (
                                    <section>
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <Typography variant="h6">
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                        {' '}
                                        Upload Image
                                      </Typography>
                                      <HiddenInput
                                        name="uploadedFile"
                                        onChange={onChangeFields}
                                        id="icon-button-file"
                                        type="file"
                                      />
                                      <label
                                        variant="contained"
                                        color="primary"
                                        htmlFor="icon-button-file"
                                      >
                                        <UploadIcon fontSize="large" />
                                      </label>
                                    </section>
                                  )}
                                </ImagePaper>
                              </Paper>
                            </Grid>
                          </Grid>
                        </section>
                        )}
          {result
                        && (
                        <section>
                          <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center" alignContent="space-between">
                            <Grid item xs={10} sm={10} md={6}>
                              <Paper elevation={3}>
                                <ImagePaper>
                                  <CertificateImage src="https://material.vlifevirtusa.com/OCR-Death/sample.jpg" alt="" />
                                </ImagePaper>
                              </Paper>
                            </Grid>
                            <Grid item xs={10} sm={10} md={4}>
                              <StyledButton variant="contained" color="primary" onClick={sampleResult}>
                                Execute
                              </StyledButton>
                            </Grid>
                          </Grid>
                        </section>
                        )}
          {showSpinner && <Spinner text="Loading..." />}
          {results
                        && (
                        <section>
                          <Grid container xs={12} direction="row" justify="center" alignItems="center" alignContent="space-between">
                            <TableGrid item xs={12} sm={8} md={8}>
                              <AppBars position="static" color="primary">
                                <Tabs
                                  value={tabValue}
                                  onChange={handleChange}
                                  aria-label="simple tabs example"
                                  variant="fullWidth"
                                >
                                  <Tab label="Detected Entities" />
                                  <Tab label="Translated Content" />
                                </Tabs>
                              </AppBars>

                              <TableDiv>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Parameter</TableCell>
                                      <TableCell>Value</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {dataRows && Object.entries(dataRows[0])
                                      .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                      )
                                      .map((row) => (
                                        <TableRow key={row}>
                                          {row.map(
                                            (field) => field[0] !== "index" && (
                                            <TableCell align="left">
                                              {field}
                                            </TableCell>
                                            ),
                                          )}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableDiv>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                component="div"
                                count={Object.entries(`${dataRows[0]}`).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </TableGrid>
                          </Grid>
                          <StyledButton
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<GetAppIcon />}
                          >
                            <a
                              href={downloadurl}
                              download
                            >
                              Download Result
                            </a>
                          </StyledButton>
                        </section>
                        )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(OcrCertificate);

const CertificateImage = styled.img`
           height: 350px;
            width: 80%;
           @media only screen and (max-width: 900px) {
            width: auto;
            max-width: 75%;
       }   @media only screen and (max-width: 450px) {
            width: auto;
            max-width: 65%;
   }
   `;

const SolImg = styled.img`
            width: 90%;
   `;

const TableDiv = styled(TableContainer)`
  cursor: pointer;
  margin-top: 20px;
  z-index: 0;
`;

const TableGrid = styled(Grid)`
  margin-top: 20px;
`;

const AppBars = styled(AppBar)`
z-index: 0;
`;

const ImagePaper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;

const HiddenInput = styled.input`
  display: none;
`;
const UploadIcon = styled(CloudUploadIcon)`
  cursor: pointer;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 250px;
  height: 250px;
  padding: 3% 3% 3% 3%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 240px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
