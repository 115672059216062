import React, { memo, useState } from "react";
import { Grid, FormControl, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MenuBookOutlinedIcons from "@material-ui/icons/MenuBook";

// local components
import styled from "styled-components";

import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const DiagnosisPredictor = () => {
  const initialData = {
    resString: "",
    text: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "payerProvider",
    "diagnosticPrediction",
  );

  const showResult = () => {
    const NewDataSet = {
      text: "2-D M-MODE: , ,1.  Left atrial enlargement with left atrial diameter of 4.7 cm.,2.  Normal size right and left ventricle.,3.  Normal LV systolic function with left ventricular ejection fraction of 51%.,4.  Normal LV diastolic function.,5.  No pericardial effusion.,6.  Normal morphology of aortic valve, mitral valve, tricuspid valve, and pulmonary valve.,7.  PA systolic pressure is 36 mmHg.,DOPPLER: , ,1.  Mild mitral and tricuspid regurgitation.,2.  Trace aortic and pulmonary regurgitation.",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      text: "PREOPERATIVE DIAGNOSIS: , Morbid obesity. ,POSTOPERATIVE DIAGNOSIS: , Morbid obesity. ,PROCEDURE:,  Laparoscopic Roux-en-Y gastric bypass, antecolic, antegastric with 25-mm EEA anastamosis, esophagogastroduodenoscopy. ,ANESTHESIA: , General with endotracheal intubation. ,INDICATIONS FOR PROCEDURE: , This is a 50-year-old male who has been overweight for many years and has tried multiple different weight loss diets and programs.  The patient has now begun to have comorbidities related to the obesity.  The patient has attended our bariatric seminar and met with our dietician and psychologist.  The patient has read through our comprehensive handout and understands the risks and benefits of bypass surgery as evidenced by the signing of our consent form.,PROCEDURE IN DETAIL: , The risks and benefits were explained to the patient.  Consent was obtained.  The patient was taken to the operating room and placed supine on the operating room table.  General anesthesia was administered with endotracheal intubation.  A Foley catheter was placed for bladder decompression.  All pressure points were carefully padded, and sequential compression devices were placed on the legs.  The abdomen was prepped and draped in standard, sterile, surgical fashion.  Marcaine was injected into the umbilicus.",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const { text } = sampleData;

    const params = `${text}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.predicted);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <strong>Abstract</strong>
          <p>
            The diagnosis predictor a project that aims to automatically predict
            the area of medical specialty required for diagnosis based on a
            patient’s anamnesis. An anamnesis is represented by a raw text file
            with doctor's notes about the patient, including their age,
            complaints or concerns described in layman terms, the patient's
            history etc.
          </p>
          <p>
            This solution can help a doctor find the optimal solution for
            diagnosis code order. While a doctor can serve a patient more
            efficiently, saving time wasted on unnecessary consultations, a
            patient can save time as well as money. Moreover, in difficult
            cases, the algorithm may help a doctor find a diagnosis faster,
            which in some cases may be extremely valuable, up to saving lives.
            Theoretically this algorithm can help medical researchers identify
            the best way to treat any medical condition based on their unobvious
            interconnections with some symptoms.
          </p>
          <p>
            This algorithm is built with the help of NLP. The target column of
            the dataset consists of the name of the area of medical specialty
            required for diagnosis.
          </p>
          <p>
            <strong>The Dataset </strong>
          </p>
          <p>
            The data is scrapped from mtsamples.com and comprises of the
            following columns
            {" "}
          </p>
          <ul>
            <li>Description - Short description of transcription </li>
            <li>
              Medical Speciality - Medical specialty classification of
              transcription
            </li>
            <li>Sample_name – Transcription Title </li>
            <li>Transcription – Sample Medical Transcription </li>
            <li>Keywords – Relevant Keywords from the transcription </li>
          </ul>
          <p>
            Since medical data is extremely hard to find due to HIPAA privacy
            regulations. This dataset offers a solution by providing medical
            transcription samples.
          </p>
          <p>The following is how a sample transcription looks like: </p>
          <p>
            The left ventricular cavity size and wall thickness appear normal.
            The wall motion and left ventricular systolic function appears
            hyperdynamic with estimated ejection fraction of 70% to 75%. There
            is near-cavity obliteration seen. There also appears to be increased
            left ventricular outflow tract gradient at the mid cavity level
            consistent with hyperdynamic left ventricular systolic function.
            There is abnormal left ventricular relaxation pattern seen as well
            as elevated left atrial pressures seen by Doppler examination.,2.
            The left atrium appears mildly dilated.,3. The right atrium and
            right ventricle appear normal.,4. The aortic root appears normal.,5.
            The aortic valve appears calcified with mild aortic valve stenosis,
            calculated aortic valve area is 1.3 cm square with a maximum
            instantaneous gradient of 34 and a mean gradient of 19 mm.,6. There
            is mitral annular calcification extending to leaflets and supportive
            structures with thickening of mitral valve leaflets with mild mitral
            regurgitation.,7. The tricuspid valve appears normal with trace
            tricuspid regurgitation with moderate pulmonary artery hypertension.
            Estimated pulmonary artery systolic pressure is 49 mmHg. Estimated
            right atrial pressure of 10 mmHg.,8. The pulmonary valve appears
            normal with trace pulmonary insufficiency.,9. There is no
            pericardial effusion or intracardiac mass seen.,10. There is a color
            Doppler suggestive of a patent foramen ovale with lipomatous
            hypertrophy of the interatrial septum. 11. The study was somewhat
            technically limited and hence subtle abnormalities could be missed
            from the study.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://mtsamples.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/DiagnosticsPrediction/DiagnosticsPrediction.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<MenuBookOutlinedIcons />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/DiagnosticsPrediction/NLP-Readme.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ReadMe
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid container xs={12} direction="row" justify="center">
              <Grid item xs={12}>
                <StyledFormControl fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    rows="8"
                    multiline
                    label="Input"
                    name="text"
                    value={sampleData.text}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
            </Grid>
            <br />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample Data 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample Data 2
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </section>
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {resultData}
              </Result>
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(DiagnosisPredictor);

const StyledFormControl = styled(FormControl)`
  .MuiFormControl-root {
    margin: 0px 50px;
    padding: 0px 10px;

    @media only screen and (max-width: 900px) {
      padding: 0px 10px;
    }
  }
`;
