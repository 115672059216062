import React, { useState } from "react";
import styled from "styled-components";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import {
  DemoContainer,
  Paragraph,
  StyledButton,
  Result,
  LinkContainer,
  CustomTextField,
} from "../../../styles/common";
import CommonService from "../../../utils/services/CommonService";
import getUserData from "../../../utils";
import Notification from "../../common/Notification";
import Spinner from "../../common/loading/Spinner";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  TextField,
} from "@material-ui/core";

export default function PriorAuth() {
  const { postQueryResult } = CommonService(
    "priorAuth","priorAuth"
  );

  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);

  const initialData = {
    request_id: "",
    provider_claim_request: "",
    provider_claim_date: "",
    clinical_nurse:"",
    clinical_nurse_date:"",
    medical_review_coordinator:"",
    medical_review_date:"",
    medical_director:"",
    medical_director_date:"",
    peer_to_peer_mom:"",
    peer_to_peer_mom_date:"",
  };

  const [sampleField, setSampleField] = useState(initialData);
  const [result, setResult] = useState("");
  const [executeBtn, setexecuteBtn] = useState(true);

  const handleOnChange = (e) => {
    setSampleField({
      ...sampleField,
      [e.target.name]: e.target.value,
    });
  };

  const handleSample = (e) => {
    setexecuteBtn(false)
    setResult("")
    setSampleField({
      ...sampleField,
      request_id: "1234",
      provider_claim_request: "This letter is to request approval for the surgery, hospital, and post-surgical care associated with the planned implantation of the Subcutaneous Implantable Defibrillator (the S-ICDTM System) for John Major. This patient is scheduled for surgery on 01/12/2023. I have attached clinical documentation to support a determination of medical necessity for S-ICD implantation. The S-ICD System is clinically appropriate for my patient as they do not have symptomatic bradycardia, incessant ventricular tachycardia, or spontaneous, frequently recurring ventricular tachycardia that is reliably terminated with anti-tachycardia pacing. In addition, your health plan’s coverage policy ABC12345678 states that the S-ICD [may be/ is] considered medically necessary for patients requiring an implantable defibrillator that have the following risk factors associated with implantation of a traditional ICD: High Blood Pressure and Slow Post Surgery Recovery. The enclosed information supports the presence of these/this risk factors in my patient. Therefore, I have determined that S-ICD implantation is justified. Based upon the above criteria and the information enclosed, I request that approval be granted for surgery for John Major and all related services as soon as possible. Please fax your approval to my office at the following number 123-123-1234 or contact me with additional questions. I can be reached conveniently at 987-654-3210.",
      provider_claim_date:"01/09/2023 09:00 am",
      clinical_nurse:"Upon checking the Request letter from the Dr for the Member John Major and reviewing the Supporting documents it is determined that the member does not require a S-ICD implant and a Traditional ICD is sufficient. As per the Medical Procedure Document , Reference number: 12345 and the Clinical Guideline document : CG-12345, the Traditional ICD is sufficient and can yield better clinical outcomes. So, the request for S-ICD is rejected and the Dr is advised to proceed with the Traditional ICD Procedure which is covered by the members plan and it does not require a Prior Authorization.",
      clinical_nurse_date:"01/09/2023 10:00 AM",
      medical_review_coordinator:"Upon checking the Request letter from the Dr for the Member John Major and reviewing the Supporting documents it is determined that the member does not require a S-ICD implant and a Traditional ICD is sufficient. As per the Medical Procedure Document , Reference number: 12345 and the Clinical Guideline document : CG-12345, the Traditional ICD is sufficient and can yield better clinical outcomes. So, the request for S-ICD is rejected and the Dr is advised to proceed with the Traditional ICD Procedure which is covered by the members plan and it does not require a Prior Authorization.",
      medical_review_date:"01/10/2023 10:00 AM",
      medical_director:"The medical necessity for a S-ICD implant is not fully clincally established in the case of member John Major. A Traditional ICD would yeild the expected results and also is the correct procedure considering the age and health condition of the member. So Rejecting the request for S-ICD implant and advising the patent to proceed with the Traditional ICD Implantation.",
      medical_director_date:"01/09/2023 11:00 AM",
      peer_to_peer_mom:"The requesting Provider explained the Current Clinical situation and medical condition of the Member – John Major. The recurrence of the arrhythmia condition for the member John Major and the need for the S-ICD implat at the earliest.The Medical Director provided the counter points on how the Traditional ICD could be an Alternative for the S-ICD and can yeild the same medical benefits. As the member is not experiencing serious Rhythm issues and considering the age a Traditional-ICD can be the correct choice of device to be used. MD presented the associated supporting Guideline documents to the Requesting Provider.End of the call, it is mutually agreed to proceed with the Traditional ICD Implant for the patient John Major as it can yeild the same results as S-ICD. The implant surgical procedure will happen as planned on 01/13/2023.",
      peer_to_peer_mom_date:"01/10/2023 09:00 AM – 09:30 AM",
    });
  };

  const handleOnExecute = () => {
    const params = {
        "request_id": sampleField?.request_id,
        "report": "Request for Denial summary report",
        "provider_claim_request": {
            "date": sampleField?.provider_claim_date,
            "comment":sampleField?.provider_claim_request,
        },
        "clinical_nurse":{
            "date": sampleField?.clinical_nurse_date,
            "comment":sampleField?.clinical_nurse,
        },
        "medical_review_coordinator":{
            "date":sampleField?.medical_review_date,
            "comment":sampleField?.medical_review_coordinator,
        },
        "medical_director":{
            "date":sampleField?.medical_director_date,
            "comment":sampleField?.medical_director,
        },
        "peer_to_peer_mom":{
            "date":sampleField?.peer_to_peer_mom_date,
            "mom":sampleField?.peer_to_peer_mom,
        },
    };
    setSpinner(true);
    postQueryResult("priorauth", params)
        .then((res) => {
            setResult(res?.summary)
            setSpinner(false);
            setNotification({ open: 'success', message: "Successfully Summarised" });
        })
        .catch((err) => {
            setNotification({ open: 'error', message: err.message });
            setSpinner(false);
        });
  };

  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
             <Grid item xs={8} sm={5} md={6}>
              <TextField
                name="request_id"
                label="Request ID"
                variant="outlined"
                value={sampleField?.request_id}
                onChange={handleOnChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
             {" "}
            </Grid>
            <Grid item xs={8} sm={5} md={6}>
              <TextField
                multiline
                name="provider_claim_request"
                label="Provider Claim Request"
                variant="outlined"
                fullWidth
                value={sampleField?.provider_claim_request}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
              <TextField
                name="provider_claim_date"
                label="Provider Claim Date"
                variant="outlined"
                fullWidth
                value={sampleField?.provider_claim_date}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={5} md={6}>
              <TextField
                multiline
                name="clinical_nurse"
                label="Clinical Nurse"
                variant="outlined"
                fullWidth
                value={sampleField?.clinical_nurse}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
              <TextField
                name="clinical_nurse_date"
                label="clinical nurse Date"
                variant="outlined"
                fullWidth
                value={sampleField?.clinical_nurse_date}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={5} md={6}>
              <TextField
                multiline
                name="medical_review_coordinator"
                label="Medical Review Coordinator"
                variant="outlined"
                fullWidth
                value={sampleField?.medical_review_coordinator}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
              <TextField
                name="medical_review_date"
                label="Medical Review Date"
                variant="outlined"
                fullWidth
                value={sampleField?.medical_review_date}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={5} md={6}>
              <TextField
                multiline
                name="medical_director"
                label="Medical Director"
                variant="outlined"
                fullWidth
                value={sampleField?.medical_director}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
              <TextField
                name="medical_director_date"
                label="Medical Director Date"
                variant="outlined"
                fullWidth
                value={sampleField?.medical_director_date}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={5} md={6}>
              <TextField
                multiline
                name="peer_to_peer_mom"
                label="Peer to Peer Mom"
                variant="outlined"
                fullWidth
                value={sampleField?.peer_to_peer_mom}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
              <TextField
                name="peer_to_peer_mom_date"
                label="Peer to Peer Mom Date"
                variant="outlined"
                fullWidth
                value={sampleField?.peer_to_peer_mom_date}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {" "}
            </Grid>
            <Grid item xs={8} sm={4} md={4}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleSample}
              >
                Sample
              </StyledButton>
            </Grid>

            <Grid item xs={8} sm={4} md={4}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleOnExecute}
                disabled={executeBtn}>
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
          </Grid>

          {result && (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12}>
                <Result>
                  <Paragraph>
                    {" "}
                    <strong>Result: </strong> <p>{result} </p>{" "}
                  </Paragraph>
                </Result>
              </Grid>
            </Grid>
          )}
          {showSpinner && <Spinner text="Loading..." />}
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
};