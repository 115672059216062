import React, { memo, useState, useEffect } from "react";
import {
    Grid,
    MenuItem,
    Checkbox,
    ListItemIcon,
    ListItemText,
    FormControl,
    Select,
    InputLabel,
} from "@material-ui/core";
import styled from "styled-components";
import { setInputData } from '../../../../../../store/actions/vnet';
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabelInfo } from "../../InputForm";
import { isObjKeyhasval } from "../../util";
import { subFeatureData } from "../../constants";
import { InfoText } from '../ModelOptimization/DataShapley';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';

export const SortColumnForm = memo((props) => {
    const { userInputData } = useSelector((state) => state.vnet);
    const dispatch = useDispatch();
    const { initialData = null } = subFeatureData.Sort_Column;
    const [dropdownList, setdropdownList] = useState(null);
    const [selected, setSelected] = useState(userInputData?.target || []);
    const [allVal, setAllVal] = useState([]);
    const [result, setResult] = useState();
    const [error, setError] = useState({});
    const [alert, setAlert] = useState('');

    const handleChange = (e) => {
        const { value } = e.target;

        if (value[value.length - 1] === "all") {
            if ([allVal.length] > 1) {
                allVal.length = 0;
            } else {
                const vals = JSON.parse(result);
                allVal.push(...vals);
            }
            setSelected(selected.length === dropdownList.length ? [] : dropdownList);

            if (e.target.name) {
                dispatch(
                    setInputData({
                        ...userInputData,
                        [e.target.name]: allVal,
                    }),
                );
            }
            return;
        }
        if (e.target.name === "column") {
            dispatch(
                setInputData({
                    ...userInputData,
                    [e.target.name]: e.target.value,
                }),
            );
        }
        setSelected(value);
        error[e.target.name] && setError({ ...error, [e.target.name]: false });
    };

    const handleCheckBox = (e) => {
        const { checked } = e.target;
        let val = e.target.value;
        if (checked) {
        } else {
            val = 'False';
        }
        dispatch(
            setInputData({
                ...userInputData,
                [e.target.name]: val,
            }),
        );
    };

    const isAllSelected = dropdownList?.length > 0 && selected?.length === dropdownList?.length;

    useEffect(async () => {
        const result = await props.getCategories();
        setResult(result);
        console.log(result);
        if (result) {
            setdropdownList(JSON.parse(result));
        }
    }, []);

    const MenuProps = {
        PaperProps: {
            style: {
                width: 250,
            },
        },
    };
    const handleFormReset = () => {
        dispatch(setInputData({
            ...initialData,
        }));
        console.log("reset");
    };

    const isFormValid = () => {
        if (!userInputData?.column || Object.entries(userInputData?.column).length == 0) {
            setAlert(true);
            return false;
        }
        return true;
    };

    return (
        <SortColumnContainer>
            {alert && (
                <InfoText success={false}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Please select the columns to sort
                </InfoText>
            )}
            <CenteredGrid
                container
                spacing={3}
                alignItems="center"
                alignContent="center"
            >
                <Grid item xs={12} md={12}>
                    <FormControl fullWidth error={error.target}>
                        <StyledInpuLabel >Column</StyledInpuLabel>
                        <Select
                            multiple
                            labelId="mutiple-select-label"
                            label="Select Columns"
                            name="column"
                            value={userInputData?.column || []}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            variant="outlined"
                        >
                            <MenuItem value="all">
                                <ListItemIcon>
                                    <Checkbox
                                        checked={isAllSelected}
                                        indeterminate={
                                            selected?.length > 0 &&
                                            selected?.length < dropdownList?.length
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {dropdownList?.map((value) => (
                                <MenuItem key={value} value={value}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={userInputData?.column?.indexOf(value) > -1}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabelInfo
                        control={(
                            <Checkbox
                                value="True"
                                name="ascending"
                                onChange={handleCheckBox}
                            />
                        )}
                        label="Ascending"
                    />
                </Grid>
            </CenteredGrid>
            {props.render({
                handleFormReset,
                isFormValid,
            })}
        </SortColumnContainer>
    );
});
const SortColumnContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
  }
`;

const CenteredGrid = styled(Grid)`
  text-align: center;
`;

const StyledInpuLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    margin: -5px 5px;
  }

`;

