import React, { memo, useState, useEffect } from 'react';
import {
  FormControlLabel, Grid, MenuItem, RadioGroup, TextField, Radio,
} from '@material-ui/core';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { setInputData } from '../../../../../../store/actions/vnet';

export const MTClassification = memo((props) => {
  const [modelInfo, setModelInfo] = useState({ modelType: "" });
  const [historyData, setHistoryData] = useState({});
  const [fieldError, setFieldError] = useState(false);
  const [modeloutputData, setmodeloutputData] = useState(null);
  const { userInputData } = useSelector((state) => state.vnet);

  const dispatch = useDispatch();
  const handleOnChange = async (e) => {
    const { value, name } = e.target;
    const modelDataObj = {};
    switch (name) {
      case "modelType":
        modelDataObj[name] = value;
        modelDataObj.model = null;
        break;
      case "model":
        modelDataObj[name] = value;
        modelDataObj.Model_Name = null;
        break;
      case "Model_Name":
        modelDataObj[name] = value;
        modelDataObj.Model_RunID = null;
        break;
      case "Model_RunID":
        modelDataObj.Model_RunID = value;
        const contextData = {
          Actions: "From",
          model: modelInfo.model,
          Model_Name: modelInfo.Model_Name,
          Model_RunID: value,
          modelType: modelInfo?.modelType,
        };
        const result = await props.getCommonAPIData(contextData);

        setmodeloutputData(result.Result.sample);
        dispatch(setInputData({
          ...modelInfo,
          ...modelDataObj,
          parameters: result.Result.sample,
          target: result.Result.target,
          columns: result.Result.columns,
          template: result.Result.template,
        }));
        break;

      default:
        break;
    }

    setModelInfo({
      ...modelInfo,
      ...modelDataObj,
    });
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleFormReset = () => {
    setModelInfo({ modelType: "" });
  };

  const handleUserInput = (e) => {
    const { name, value } = e.target;
    if (name == "mode") {
      dispatch(setInputData({
        ...userInputData,
        [name]: value,
      }));
    } else {
      dispatch(setInputData({
        ...userInputData,
        parameters: {
          ...userInputData.parameters,
          [name]: value,
        },
      }));
    }
  };

  const isFormValid = () => {
    const error = {};
    Object.keys(modelInfo).forEach((key) => {
      if (!modelInfo?.[key]) {
        error[key] = "required field";
      }
    });
    setFieldError(error);

    return Object.keys(error).length == 0;
  };
  useEffect(async () => {
    const contextData = {
      StageID: "Common_API",
      FeatureID: "Common_API",
      FunctionID: "resumeStage",
      Actions: "",
      ProductID: "vNet",
    };
    const res = await props.getCommonAPIData(contextData);
    setHistoryData(res?.Result?.historyData);
  }, []);

  const getSignedurl = async () => {
    if (userInputData?.template) {
      const ContextData = {
        StageID: "Common_API",
        FeatureID: "Common_API",
        FunctionID: "signedURL",
        Actions: "",
      };
      const contentData = {
        filePath: userInputData?.template,
      };
      const res = await props.getCommonAPIData(ContextData, contentData);
      if (res?.Result?.signedURL) {
        window.open(res?.Result?.signedURL);
      }
    }
  };
  return (
    <Container>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={3} id="modelcontainer">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="modelType"
            label="Model Type"
            variant="outlined"
            onChange={handleOnChange}
            select
            size="small"
            fullWidth
            value={modelInfo.modelType}
            error={fieldError.modelType}
            helperText={fieldError.modelType}
          >
            {historyData && Object.keys(historyData).map((value) => (
              <MenuItem key={value} value={value} disabled={value === 'AutoML'}>
                {value}
              </MenuItem>
            ))}

          </TextField>
        </Grid>
        {modelInfo.modelType && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="model"
                label="Model"
                variant="outlined"
                onChange={handleOnChange}
                select
                size="small"
                fullWidth
                value={modelInfo.model}
                error={fieldError.model}
                helperText={fieldError.model}
              >
                {historyData && Object.keys(historyData?.[modelInfo.modelType]).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}

              </TextField>
            </Grid>
            {modelInfo.model && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="Model_Name"
                    label="Model Name"
                    variant="outlined"
                    onChange={handleOnChange}
                    select
                    size="small"
                    fullWidth
                    value={modelInfo?.Model_Name}
                    error={fieldError.Model_Name}
                    helperText={fieldError.Model_Name}
                  >
                    {historyData && Object.keys(historyData?.[modelInfo.modelType]?.[modelInfo.model]).map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}

                  </TextField>
                </Grid>
                {modelInfo?.Model_Name && (
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Model_RunID"
                      label="Model Version"
                      variant="outlined"
                      minWidth="100%"
                      onChange={handleOnChange}
                      select
                      size="small"
                      fullWidth
                      value={modelInfo?.Model_RunID}
                      error={fieldError.Model_RunID}
                      helperText={fieldError.Model_RunID}
                    >
                      {historyData?.[modelInfo.modelType]?.[modelInfo.model]?.[modelInfo.Model_Name]?.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}

                    </TextField>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {modeloutputData && (
        <>
          <RadioGroup
            row
            name="mode"
            defaultValue="False"
            aria-label="Learn Mixture Weights"
            defaultChecked="SampleTesting"
            value={userInputData?.mode}
            onChange={handleUserInput}
          >
            <FormControlLabel
              value="SampleTesting"
              control={<Radio color="primary" />}
              label="Sample Testing"
              labelPlacement="end"
            />
            <FormControlLabel
              value="FileTesting"
              control={<Radio color="primary" />}
              label="File Testing"
              labelPlacement="end"
            />
          </RadioGroup>

          {userInputData?.mode === 'SampleTesting' && (
            <Grid container justifyContent="flex-start" alignItems="center" spacing={3} id="modelcontainer">
              {Object.entries(modeloutputData).map((obj) => (

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name={obj[0]}
                    label={obj[0]}
                    variant="outlined"
                    onChange={handleUserInput}
                    size="small"
                    fullWidth
                    value={userInputData?.parameters?.[obj[0]]}
                  />

                </Grid>
              ))}

            </Grid>
          )}
          {userInputData?.mode == 'FileTesting' && (
            <>
              <p><strong>Testing Template</strong></p>
              <ColNameContainer>
                {Object.entries(modeloutputData).map((obj) => (
                  <li>{obj[0]}</li>
                ))}
              </ColNameContainer>
              {userInputData?.template && (
                <button id="download" onClick={getSignedurl}>
                  <FontAwesomeIcon icon={faFileDownload} />
                  {' '}
                  Download Template
                </button>
              )}
              <p><strong>Upload a file </strong></p>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="file"
                    onChange={props.handleOnChange}
                    size="small"
                    type="file"
                    fullWidth
                    error={userInputData?.error_file}
                    id="csvInput"
                    helperText={userInputData?.error_file && 'Please Upload a csv file'}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      <br />
      {props.render({ handleFormReset, isFormValid, saveCallback: userInputData?.mode == "SampleTesting" ? 'saveInputData' : 'saveFileData' })}

    </Container>
  );
});

const Container = styled.section`
width: 100%;  
margin-bottom: 10px;
#download{
  padding: 10px;
  border-right: 1px solid rgba(224,224,224,1);
  color: #ffff;
  min-width: fit-content;
  background-color: #00AF88;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
}
a {
  text-decoration: none;
  color: #ffff;
}
`;
const ColNameContainer = styled.div`
  max-width: 100%;
  overflow: auto;
  display: flex;
  list-style: none;
  li {
    padding: 10px;
    border-right: 1px solid rgba(224,224,224,1);
    background-color: #00afb9;
    color: #ffff;
    min-width: fit-content;
  }
  #download{
    padding: 10px;
    border-right: 1px solid rgba(224,224,224,1);
    color: #ffff;
    min-width: fit-content;
      border-radius: 5px;
      background-color: #00AF88;
  }
  a {
    text-decoration: none;
    color: #ffff;
  }

`;
const Info = styled.p`
color: ${(props) => (props.success ? '#2a9d8f' : '#f28482')};
`;
