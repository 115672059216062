import {
  AppBar,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableCell,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "../../common/loading/Spinner";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import FileDownloadIcon from "@material-ui/icons/GetApp";
import {
  DemoContainer,
  Paragraph,
  Result,
  StyledButton,
  LinkContainer,
} from "../../../styles/common";
import { StyledTabs } from "./ColbertAnalysis";
// user id
import getUserData from "../../../utils";
import CommonService from "../../../utils/services/CommonService";
import Plot from "react-plotly.js";

export const ResponsibleAIDemo = () => {
  //file upload
  const { uploadFilesWithBody: uploadDataset } = CommonService(
    "pyod1",
    "pyod1"
  );
  // Model Explainability
  const { postQueryResult, uploadFilesWithBody } = CommonService(
    "z1dAppsv1",
    "responsibleAIModelExplainability"
  );

  const list1 = [
    { key: "LightGBM", value: "lightgbm" },
    { key: "XGBoost", value: "XGBoost" },
    { key: "Random Forest Classifier", value: "RandomForestClassifier" },
  ];
  const cascadeList = [
    {
      type: "Local Explainability",
      value: "local",
      subtype: [
        { label: "Lime", value: "lime" },
        { label: "Shap", value: "shap" },
        { label: "Shapash Local", value: "shapash_local" },
        { label: "Shapash Comparison", value: "shapash_comparison" },
        { label: "Anchor", value: "anchor" },
      ],
    },
    {
      type: "Global Explainability",
      value: "global",
      subtype: [
        { label: "Shapash Feature Plot", value: "shapash_feature_plot" },
        {
          label: "Shapash Contribution Plot",
          value: "shapash_contribution_plot",
        },
        { label: "Shap Summary Plot", value: "shap_summary_plot" },
        { label: "Shap Dependence Plot", value: "shap_dependence_plot" },
        { label: "Shap Force Plot", value: "shap_force_plot" },
        { label: "Shap Class Explainer", value: "shap_class_explainer" },
      ],
    },
  ];
  const visualizationList = [
    {
      label: "Saliency Map Visualization",
      value: "saliency map visualization",
    },
    { label: "Shap Value Importance", value: "shapley value importance" },
    {
      label: "Gradient Class Activation Mapping",
      value: "gradient class activation mapping",
    },
  ];

  const initialData = {
    contribution: "",
    targetClasses: "",
    feature: "",
    interactionIndex: "",
  };

  const [globalField, setGlobalField] = useState(initialData);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [btnEnable, setBtnEnable] = useState(true);
  const [selectedFile, setselectedFile] = useState("");
  const [fileName, setselectedFileName] = useState("");
  const [result, setResult] = useState("");
  const [resultTabularData, setResultTabularData] = useState("");
  const [resultImgData, setResultImgData] = useState("");
  const [resultImgSummary, setResultImgSummary] = useState("");
  const [resultGradientData, setResultGradientData] = useState("");
  const [modelLayer, setModelLayer] = useState("");

  const [removeFeature, setRemoveFeature] = useState("");
  const [indexValue, setIndexValue] = useState("");
  const [indexValueTwo, setIndexValueTwo] = useState("");
  const [field2, setField2] = useState("");
  const [{ list2, list3 }, setDropdown] = useState({
    list2: "",
    list3: "",
  });
  const [visualizationField, setVisualizationField] = useState("");
  const [selectedFile2, setSelectedFile2] = useState("");
  const [selectedFile1, setSelectedFile1] = useState("");
  const [fileName2, setSelectedFileName2] = useState("");
  const [fileName1, setSelectedFileName1] = useState("");
  const [tabVal, setTabVal] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabVal(newValue);
  };

  const handleGlobalField = (e) => {
    setResultTabularData("");

    const { name, value } = e.target;
    setGlobalField({
      ...globalField,
      [name]: value,
    });
  };

  //Tab 1
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    setBtnEnable(false);
    if (file) {
      setselectedFile(file);
      setselectedFileName(filename);
    }
  };

  //Tab 1 api integration
  const getUploadResult = () => {
    setResult("");
    setRemoveFeature("");
    setField2("");
    setDropdown("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (!selectedFile) {
      setNotification({ open: "error", message: "please upload a file" });
      return;
    }

    setSpinner(true);
    uploadDataset("upload", {
      Context_param: JSON.stringify({
        Application_name: "model-explainability",
        UserID: userId.toLowerCase(),
        Execution_time: date,
      }),
      Content_param: JSON.stringify({ Dataset_Name: fileName }),
      file: selectedFile,
    })
      .then((res) => {
        setSpinner(false);
        setNotification({ open: "success", message: res.Console });
        const Data = res.Result;
        console.log(Data);
        setResult(Data);
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const handleChange = (e) => {
    setResultTabularData("");
    setIndexValue(e.target.value);
  };

  const handleIndexChange = (e) => {
    setResultTabularData("");
    setIndexValueTwo(e.target.value);
  };

  const handleInputs = (e) => {
    setField2(e.target.value);
  };
  const handleColumnName = (e) => {
    setRemoveFeature(e.target.value);
  };

  const handleMenuChange = (event) => {
    setResultTabularData("");
    setIndexValue("");
    setIndexValueTwo("");
    setGlobalField("");

    setDropdown((data) => ({ list3: "", list2: event.target.value }));
  };
  const handleSubmenuChange = (event) => {
    setResultTabularData("");
    setIndexValue("");
    setIndexValueTwo("");
    setGlobalField("");

    setDropdown((data) => ({ ...data, list3: event.target.value }));
  };

  const handleVisualization = (e) => {
    setVisualizationField(e.target.value);
    setResultImgData("");
    setResultImgSummary("");
    setResultGradientData("");
    setModelLayer("");
  };

  //Tab 1 api tabular data integration
  const handleExecute = () => {
    setResultTabularData("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    console.log("list3", list3);
    setSpinner(true);

    if (
      list3 === "lime" ||
      list3 === "anchor" ||
      list3 === "shap" ||
      list3 === "shapash_local"
    ) {
      postQueryResult("tabularData", {
        Context_param: {
          Application_name: "model-explainability",
          UserID: userId.toLowerCase(),
          Execution_time: date,
        },
        Content_param: {
          s3Path: result.path,
          Dataset_Name: fileName,
          removeFeature: removeFeature,
          model_technique: field2,
          method: list2,
          plot_type: list3,
          id_value: Number(indexValue),
        },
      })
        .then((res) => {
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          const Data = res.Result.path;
          setResultTabularData(Data);
        })
        .catch((err) => {
          setNotification({ open: "error", message: `${err.message}` });
          setSpinner(false);
          console.log(err);
        });
    } else if (list3 === "shapash_comparison") {
      postQueryResult("tabularData", {
        Context_param: {
          Application_name: "model-explainability",
          UserID: userId.toLowerCase(),
          Execution_time: date,
        },
        Content_param: {
          s3Path: result.path,
          Dataset_Name: fileName,
          removeFeature: removeFeature.toUpperCase(),
          model_technique: field2,
          method: list2,
          plot_type: list3,
          indexOne: Number(indexValue),
          indexTwo: Number(indexValueTwo),
        },
      })
        .then((res) => {
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          const Data = res.Result.path;
          setResultTabularData(Data);
        })
        .catch((err) => {
          setNotification({ open: "error", message: `${err.message}` });
          setSpinner(false);
          console.log(err);
        });
    } else if (list3 === "shapash_contribution_plot") {
      postQueryResult("tabularData", {
        Context_param: {
          Application_name: "model-explainability",
          UserID: userId.toLowerCase(),
          Execution_time: date,
        },
        Content_param: {
          s3Path: result.path,
          Dataset_Name: fileName,
          removeFeature: removeFeature,
          model_technique: field2,
          method: list2,
          plot_type: list3,
          contribution: globalField.contribution,
        },
      })
        .then((res) => {
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          const Data = res.Result.path;
          setResultTabularData(Data);
        })
        .catch((err) => {
          setNotification({ open: "error", message: `${err.message}` });
          setSpinner(false);
          console.log(err);
        });
    } else if (list3 === "shap_force_plot") {
      postQueryResult("tabularData", {
        Context_param: {
          Application_name: "model-explainability",
          UserID: userId.toLowerCase(),
          Execution_time: date,
        },
        Content_param: {
          s3Path: result.path,
          Dataset_Name: fileName,
          removeFeature: removeFeature,
          model_technique: field2,
          method: list2,
          plot_type: list3,
          class_num: Number(globalField.targetClasses),
        },
      })
        .then((res) => {
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          const Data = res.Result.path;
          setResultTabularData(Data);
        })
        .catch((err) => {
          setNotification({ open: "error", message: `${err.message}` });
          setSpinner(false);
          console.log(err);
        });
    } else if (list3 === "shap_dependence_plot") {
      postQueryResult("tabularData", {
        Context_param: {
          Application_name: "model-explainability",
          UserID: userId.toLowerCase(),
          Execution_time: date,
        },
        Content_param: {
          s3Path: result.path,
          Dataset_Name: fileName,
          removeFeature: removeFeature,
          model_technique: field2,
          method: list2,
          plot_type: list3,
          feature: globalField.feature,
          interaction_index: globalField.interactionIndex,
        },
      })
        .then((res) => {
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          const Data = res.Result.path;
          setResultTabularData(Data);
        })
        .catch((err) => {
          setNotification({ open: "error", message: `${err.message}` });
          setSpinner(false);
          console.log(err);
        });
    } else {
      postQueryResult("tabularData", {
        Context_param: {
          Application_name: "model-explainability",
          UserID: userId.toLowerCase(),
          Execution_time: date,
        },
        Content_param: {
          s3Path: result.path,
          Dataset_Name: fileName,
          removeFeature: removeFeature,
          model_technique: field2,
          method: list2,
          plot_type: list3,
        },
      })
        .then((res) => {
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          const Data = res.Result.path;
          setResultTabularData(Data);
        })
        .catch((err) => {
          setNotification({ open: "error", message: `${err.message}` });
          setSpinner(false);
          console.log(err);
        });
    }
  };

  const handleSelectedFile = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (e.target.files && e.target.files[0]) {
      setSelectedFile1(file);
      setSelectedFileName1(fileName);
    }
  };

  const handleSelectedImg = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (e.target.files && e.target.files[0]) {
      setSelectedFile2(file);
      setSelectedFileName2(fileName);
    }
  };

  //Tab 2 Image Data Api integration
  const handleImageData = () => {
    setResultImgData("");
    setResultImgSummary("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (!selectedFile1) {
      setNotification({ open: "error", message: "please upload a file" });
      setSpinner(false);
      return;
    }
    if (!selectedFile2) {
      setNotification({ open: "error", message: "please upload a file" });
      setSpinner(false);
      return;
    }

    setSpinner(true);
    uploadFilesWithBody("imageData", {
      Context_param: JSON.stringify({
        Application_name: "model-explainability",
        UserID: userId.toLowerCase(),
        Execution_time: date,
      }),
      Content_param: JSON.stringify({
        File_Name: fileName1,
        Img_Name: fileName2,
        plot_type: visualizationField,
      }),
      file: selectedFile1,
      img: selectedFile2,
    })
      .then((res) => {
        setSpinner(false);
        setNotification({ open: "success", message: res?.Console });
        const Data = res.Result;
        setResultImgData(Data.s3Path);
        console.log("ImgData", Data);
      })
      .catch((err) => {
        setNotification({ open: "error", message: `${err.message}` });
        setSpinner(false);
        console.log(err);
      });
  };

  //Tab2 Image summary api integration
  const handleImageSummary = () => {
    setResultImgData("");
    setResultImgSummary("");
    setModelLayer("");
    setResultGradientData("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (!selectedFile1) {
      setNotification({ open: "error", message: "please upload a file" });
      setSpinner(false);
      return;
    }

    setSpinner(true);
    uploadFilesWithBody("imageSummary", {
      Context_param: JSON.stringify({
        Application_name: "model-explainability",
        UserID: userId.toLowerCase(),
        Execution_time: date,
      }),
      Content_param: JSON.stringify({
        File_Name: fileName1,
        plot_type: visualizationField,
      }),
      file: selectedFile1,
    })
      .then((res) => {
        setSpinner(false);
        setNotification({ open: "success", message: res?.Console });
        const Data = res.Result;
        setResultImgSummary(Data.s3Path);
        console.log("Data", Data);
      })
      .catch((err) => {
        setNotification({ open: "error", message: `${err.message}` });
        setSpinner(false);
        console.log(err);
      });
  };

  //tab2 gradient image data api
  const handleGradientData = () => {
    setResultGradientData("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (!selectedFile1) {
      setNotification({ open: "error", message: "please upload a file" });
      setSpinner(false);
      return;
    }
    if (!selectedFile2) {
      setNotification({ open: "error", message: "please upload a file" });
      setSpinner(false);
      return;
    }

    setSpinner(true);
    uploadFilesWithBody("imageData", {
      Context_param: JSON.stringify({
        Application_name: "model-explainability",
        UserID: userId.toLowerCase(),
        Execution_time: date,
      }),
      Content_param: JSON.stringify({
        File_Name: fileName1,
        Img_Name: fileName2,
        plot_type: visualizationField,
        layer_name: modelLayer,
      }),
      file: selectedFile1,
      img: selectedFile2,
    })
      .then((res) => {
        setSpinner(false);
        setNotification({ open: "success", message: res?.Console });
        const Data = res.Result;
        setResultGradientData(Data.s3Path);
        console.log("Data", Data);
      })
      .catch((err) => {
        setNotification({ open: "error", message: `${err.message}` });
        setSpinner(false);
        console.log(err);
      });
  };

  const handleLayerChange = (e) => {
    setResultGradientData("");
    setModelLayer(e.target.value);
  };

  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <AppBar position="static" color="default">
            <StyledTabs
              value={tabVal}
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="simple tabs example"
            >
              <Tab label="Tabular Data" />
              <Tab label="Image Data" />
            </StyledTabs>
          </AppBar>

          <TabPanel value={tabVal} index={0}>
            <br />
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={8} sm={7} md={5}>
                <TextField
                  type="file"
                  color="primary"
                  name="uploadFile"
                  onChange={handleChangeFile}
                  helperText="*File must be of type .csv"
                />
              </Grid>

              <Grid item xs={4} sm={5} md={3}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getUploadResult}
                  disabled={btnEnable}
                >
                  Upload
                </StyledButton>
              </Grid>
              <Grid item xs={10} sm={10} md={4}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<FileDownloadIcon />}
                >
                  <a href="https://material.vlifevirtusa.com/ModelExplainability/sample.csv" download>
                    Download Sample File
                  </a>
                </StyledButton>
              </Grid>
            </Grid>

            <br />
            {result && (
              <>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <br />
                  <Grid item xs={6}>
                    {" "}
                    <Paragraph> Enter the target column name:</Paragraph>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      onChange={handleColumnName}
                      name="removeFeature"
                      label="Target Column Name"
                      value={removeFeature}
                      fullWidth
                      select
                      InputLabelProps={{ shrink: true }}
                    >
                      {result.columnNames?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    {" "}
                    <Paragraph>
                      {" "}
                      Select the model for the data to be trained on:
                    </Paragraph>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      onChange={handleInputs}
                      select
                      label="Data Model"
                      id="outlined-select-currency"
                      fullWidth
                      value={field2}
                    >
                      {list1.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {option.key}{" "}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <br />
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sx={6} sm={6} md={3}>
                    <Paragraph> Select the explainability technique:</Paragraph>{" "}
                  </Grid>
                  <Grid item sx={6} sm={6} md={3}>
                    <TextField
                      variant="outlined"
                      onChange={handleMenuChange}
                      name="list2"
                      select
                      fullWidth
                      label="Explainability Technique"
                      value={list2}
                    >
                      {cascadeList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item sx={6} sm={6} md={3}>
                    <Paragraph> Select the explainability model:</Paragraph>{" "}
                  </Grid>
                  <Grid item sx={6} sm={6} md={3}>
                    <TextField
                      variant="outlined"
                      name="list3"
                      select
                      fullWidth
                      label="Explainability Model"
                      value={list3}
                      onChange={handleSubmenuChange}
                    >
                      {cascadeList
                        .find((item) => item.value === list2)
                        ?.subtype.map((list3) => (
                          <MenuItem key={list3.value} value={list3.value}>
                            {list3.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </Grid>

                <br />
                <br />

                {list2 === "local" ? (
                  <>
                    {/* Local */}
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {list3 !== "shapash_comparison" && (
                        <>
                          <Grid item>
                            <Paragraph> Index value:</Paragraph>{" "}
                          </Grid>
                          <Grid item>
                            <TextField
                              variant="outlined"
                              name="indexValue"
                              label="Index Value"
                              fullWidth
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              value={indexValue}
                              helperText="Value Must be Integer greather than zero"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {list3 === "shapash_comparison" && (
                        <>
                          <Grid item xs={6} sm={6} md={3}>
                            <Paragraph> Index value 1:</Paragraph>{" "}
                          </Grid>
                          <Grid item xs={6} sm={6} md={3}>
                            <TextField
                              variant="outlined"
                              name="indexValue"
                              label="Index Value One"
                              fullWidth
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              value={indexValue}
                              helperText="Value Must be Integer greather than zero"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={3}>
                            <Paragraph> Index value 2:</Paragraph>{" "}
                          </Grid>
                          <Grid item xs={6} sm={6} md={3}>
                            <TextField
                              variant="outlined"
                              name="indexValueTwo"
                              label="Index Value Two"
                              fullWidth
                              onChange={handleIndexChange}
                              InputLabelProps={{ shrink: true }}
                              value={indexValueTwo}
                              helperText="Value Must be Integer greather than zero"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    {/* Global */}
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {(list3 === "shapash_contribution_plot" ||
                        list3 === "shap_force_plot") && (
                          <>
                            <Grid item>
                              <Paragraph>
                                {" "}
                                {list3 === "shap_force_plot"
                                  ? "Number of Target Classes"
                                  : "Contribution"}{" "}
                              </Paragraph>{" "}
                            </Grid>
                            <Grid item>
                              <TextField
                                name={
                                  list3 === "shap_force_plot"
                                    ? "targetClasses"
                                    : "contribution"
                                }
                                label={
                                  list3 === "shap_force_plot"
                                    ? "Target Classes"
                                    : "Contribution"
                                }
                                onChange={handleGlobalField}
                                value={
                                  list3 === "shap_force_plot"
                                    ? globalField.targetClasses
                                    : globalField.contribution
                                }
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          </>
                        )}
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {list3 === "shap_dependence_plot" && (
                        <>
                          <Grid item xs={6} sm={6} md={3}>
                            Feature:
                          </Grid>
                          <Grid item xs={6} sm={6} md={3}>
                            <TextField
                              name="feature"
                              value={globalField.feature}
                              onChange={handleGlobalField}
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={3}>
                            Interaction Index:
                          </Grid>
                          <Grid item xs={6} sm={6} md={3}>
                            <TextField
                              name="interactionIndex"
                              value={globalField.interactionIndex}
                              onChange={handleGlobalField}
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                <br />
                <br />

                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={handleExecute}
                    >
                      Execute
                    </StyledButton>
                  </Grid>
                </Grid>
              </>
            )}
            <br />

            {showSpinner && <Spinner text="Please Wait, file uploading..." />}

            <br />

            {/* Tab1 Result */}
            {resultTabularData && (
              <>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {resultTabularData && (
                      <>
                        <iframe
                          src={resultTabularData}
                          width="100%"
                          height="500px"
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </TabPanel>

          <TabPanel value={tabVal} index={1}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={6} md={6}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<FileDownloadIcon />}
                >
                  <a href="https://material.vlifevirtusa.com/ModelExplainability/sample.pkl" download>
                    Download Sample File
                  </a>
                </StyledButton>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<FileDownloadIcon />}
                >
                  <a href="https://material.vlifevirtusa.com/ModelExplainability/sample.jpg" download>
                    Download Sample Image
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  type="file"
                  color="primary"
                  name="File_Name"
                  helperText="*Please upload only model file (.pkl/.h5)"
                  onChange={handleSelectedFile}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  onChange={handleSelectedImg}
                  type="file"
                  color="primary"
                  name="Img_Name"
                  helperText="*Please upload only image (jpg/jpeg/png/webp) format"
                />
              </Grid>

              <br />
              <br />
              <Grid item xs={6} sm={4} md={4}>
                <Paragraph> Select an image visualization technique:</Paragraph>{" "}
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  select
                  name="plot_type"
                  label="Visualization Technique"
                  id="outlined-select-currency"
                  fullWidth
                  onChange={handleVisualization}
                  value={visualizationField}
                >
                  {visualizationList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br />
              <br />
              <Grid item xs={12} md={12} sm={12}>
                <br />
                {visualizationField !== "gradient class activation mapping" && (
                  <StyledButton
                    id="btn2"
                    variant="contained"
                    onClick={handleImageData}
                    color="primary"
                  >
                    Execute
                  </StyledButton>
                )}
                {visualizationField === "gradient class activation mapping" && (
                  <StyledButton
                    id="btn2"
                    variant="contained"
                    onClick={handleImageSummary}
                    color="primary"
                  >
                    Model Summary
                  </StyledButton>
                )}
              </Grid>
            </Grid>
            <br />
            {resultImgData && (
              <>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {resultImgData && (
                      <img
                        src={resultImgData}
                        alt={resultImgData}
                        width="600"
                        //height="400"
                        style={{ margin: "2", padding: "2" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            <br />
            {resultImgSummary && (
              <>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {resultImgSummary && (
                      <img
                        src={resultImgSummary}
                        alt={resultImgSummary}
                        width="600"
                        // height="400"
                        style={{ margin: "2", padding: "2" }}
                      />
                    )}
                  </Grid>
                  <br />
                  <Grid item>
                    {" "}
                    <Paragraph> Enter the model layer name:</Paragraph>{" "}
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      name="modelLayer"
                      label="Model layer name"
                      value={modelLayer}
                      fullWidth
                      onChange={handleLayerChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <br />
                  <br />
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={handleGradientData}
                    >
                      Execute
                    </StyledButton>
                  </Grid>
                </Grid>
              </>
            )}
            <br />

            {resultGradientData && (
              <>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {resultGradientData && (
                      <img
                        src={resultGradientData}
                        alt={resultGradientData}
                        width="600"
                        // height="400"
                        style={{ margin: "2", padding: "2" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            <br />
            {showSpinner && <Spinner text="Data Loading.. Please wait for a minute..." />}
          </TabPanel>
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
};

export default function ResponsibleAIDesc() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <strong>Image Model Explainability:</strong>
            <p>
              When it comes to understanding the decisions made by image-based
              deep learning models, several techniques can provide valuable
              insights. Saliency map visualization is one such method that
              highlights the important regions of an image that influenced the
              model's prediction. By visualizing the saliency map, one can
              identify the pixels or areas that contributed most significantly
              to the model's decision-making process. This helps in
              understanding the model's focus and the features it deems
              important for making predictions.{" "}
            </p>
            <p>
              Another powerful technique for image model explainability is the
              use of Shapley value importance. Shapley values provide a way to
              assign importance scores to different input features or pixels
              based on their contribution to the model's predictions. By
              calculating the Shapley values for each pixel in an image, we can
              determine the individual impact of each pixel on the model's
              output. This helps in quantifying the relative importance of
              different regions or features within an image.{" "}
            </p>
            <p>
              Additionally, Gradient Class Activation Mapping (Grad-CAM) is
              another technique that enables the visualization of important
              image regions. Grad-CAM generates a heatmap that highlights the
              areas of an image that had the most influence on the model's
              prediction. This method leverages the gradients of the target
              class with respect to the model's feature maps to determine the
              relevance of each pixel. By visualizing the Grad-CAM heatmap, it
              becomes easier to understand which regions the model focused on
              while making its prediction.{" "}
            </p>
          </p>
          <p>
            <strong> Tabular Model Explainability: </strong>
            <p>
              {" "}
              For tabular data, several techniques can be employed to gain
              insights into the decision-making process of machine learning
              models. SHAP (SHapley Additive exPlanations) is one such method
              that provides a unified framework for feature importance analysis.
              It assigns an importance score to each feature in the dataset,
              quantifying its impact on the model's predictions. SHAP values
              take into account the interactions between features and provide a
              comprehensive understanding of their contributions.{" "}
            </p>
            <p>
              LIME (Local Interpretable Model-Agnostic Explanations) is another
              popular technique for tabular model explainability. It creates
              local surrogate models around individual data instances to
              approximate the behavior of the original model. By analyzing the
              surrogate model, LIME provides interpretability at the instance
              level, highlighting the important features that influenced the
              model's decision for a specific data point.{" "}
            </p>
            <p>
              In addition to SHAP and LIME, Anchor is another method
              specifically designed for tabular data explainability. It aims to
              generate "anchors," which are simple if-then rules that can
              accurately predict the model's output. These anchors help in
              providing comprehensible explanations for individual predictions
              by identifying the key conditions that lead to a particular
              outcome.{" "}
            </p>
            <p>
              Overall, these techniques empower users to understand and
              interpret the decisions made by image and tabular models. By
              visualizing important regions, quantifying feature importance, and
              generating comprehensible rules, these methods contribute to
              enhancing the transparency and trustworthiness of machine learning
              models.{" "}
            </p>
          </p>
          <p>
            <LinkContainer>
              <Grid container spacing={2}>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://material.vlifevirtusa.com/ModelExplainability/model_explainability_tabular.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Notebook for Tabular
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fmaterial.vlifevirtusa.com%2FModelExplainability%2Fmodel_explainability_image.html&data=05%7C01%7Cprathaptummala%40virtusa.com%7Cab31f760071345e6db4a08db7eb05948%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C638243068147885075%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=vlZLfLELC4ob5Fr5KKDP27zBy8mVpJ3P1rWqpvzzFIY%3D&reserved=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Notebook for Image
                    </a>
                  </StyledButton>
                </Grid>
              </Grid>
            </LinkContainer>
          </p>
        </Paragraph>
      </Collapse>
    </SolutionContainerWrapper>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;
const StyledTableContainer = styled(TableContainer)`
  max-width:250,
  margin-left: auto,
  margin-right: auto,
`;
const HeaderCell = styled(TableCell)`
  background-color: #00afb9;
  color: white;
  text-align: left;
`;
const Cell = styled(TableCell)`
  text-align: left;
`;
