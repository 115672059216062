import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// import local components
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import americanSignLanguageDetection_sample1 from "../../../assets/images/app/asl-sample1.webp";
import americanSignLanguageDetection_sample2 from "../../../assets/images/app/asl-sample2.webp";

const { uploadFile } = CommonService("z1dApps", "asl");

const AmericanSignLanguageDetection = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [startCapture, setStartCapture] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsample1 = () => {
    setStartCapture(false);
    setResult("The Hand Gesture for ASL is recognized as letter C");
  };
  const getsample2 = () => {
    setStartCapture(false);
    setResult("The Hand Gesture for ASL is recognized as Space");
  };

  const getUploadResult = (uploadfile) => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const resetCapture = () => {
    setStartCapture(false);
    setUploadfile("");
    setResult("");
  };

  const onChangeFields = (e) => {
    const uploadedFile = e.currentTarget.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(uploadedFile);

    reader.onloadend = function (e) {
      setUploadfile([reader.result]);
      setStartCapture(true);
    }.bind();
    getUploadResult(uploadedFile);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>American Sign Language is a natural language that serves as the predominant sign language of Deaf communities in the United States and most of Anglophone Canada. ASL is a complete and organized visual language that is expressed by facial expression as well as movements and motions with the hands.</p>
          <p>The ASL letter detector has the capability to detect any letter formed by hands. The solution is created by initializing a CNN model which is then trained with a large dataset of images of letters in sign language.</p>
          <p>
            {' '}
            <strong> Application</strong>
          </p>
          <List>
            <ListItem>This can be used to help translate what a person communicating in sign language is trying to relay (only letter)</ListItem>
            <ListItem>Can be used to help teach sign language to beginners.</ListItem>
            <ListItem>Application can be expanded on in the future to be able to detect words (via spelling them out).</ListItem>
          </List>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/grassknoted/asl-alphabet?select=asl_alphabet_test"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ASL/American_Sign_Language_Detection.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={6} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  <Image src={americanSignLanguageDetection_sample1} />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsample1}
                  >
                    Sample1
                  </StyledButton>
                </ImagePaper>
              </Paper>
            </Grid>
            <Grid item xs={10} sm={6} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  <Image src={americanSignLanguageDetection_sample2} />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsample2}
                  >
                    Sample2
                  </StyledButton>
                </ImagePaper>
              </Paper>
            </Grid>
            <Grid item xs={10} sm={10} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  {startCapture ? (
                    <>
                      <Image src={uploadfile} alt="" />
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={resetCapture}
                      >
                        Upload Different Image
                      </StyledButton>
                    </>
                  ) : (
                    <section>
                      <br />
                      <br />
                      <br />
                      <br />
                      <Typography variant="h6">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {' '}
                        Upload Image
                      </Typography>
                      <HiddenInput
                        name="uploadedFile"
                        onChange={onChangeFields}
                        id="icon-button-file"
                        type="file"
                      />
                      <label
                        variant="contained"
                        color="primary"
                        htmlFor="icon-button-file"
                      >
                        <UploadIcon fontSize="large" />
                      </label>
                    </section>
                  )}
                </ImagePaper>
              </Paper>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <Grow in={result} timeout={1500}>
                  <ResultDiv>
                    <Result>
                      <strong>Result : </strong>
                      <h3>{result}</h3>
                    </Result>
                  </ResultDiv>
                </Grow>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(AmericanSignLanguageDetection);

const ImagePaper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;

const HiddenInput = styled.input`
  display: none;
`;
const UploadIcon = styled(CloudUploadIcon)`
  cursor: pointer;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 250px;
  height: 200px;
  padding: 3% 3% 3% 3%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 240px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
const ResultDiv = styled.div`
 margin-top: 5%;
`;
