import React, { Component } from 'react';
import Drawer from './Drawer';
import { withStyles } from '@material-ui/core/styles';
import Styles from './DataopsStyles';
import { Link } from 'react-router-dom';
import Home from './Home';
import {
  Paper,
  Button,
  MenuItem,
  IconButton,
  Grid,
  Typography,
  ListItemIcon,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  ListItemText,
  AppBar,
  Tabs,
  Tab,
  Step,
  Stepper,
  Select,
  StepLabel,
  FormLabel,
} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import DataopsService from './DataopsService';
import { ToastContainer, toast } from 'react-toastify';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { TreeItem, TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import copy from 'copy-to-clipboard';
import APIDATA from '../../../utils/services/api-config';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import TableProjects from './Table';
import TableWF from '../Dataops/TableWF';
import TableJobs from '../Dataops/TableJobs';

import styled from 'styled-components';
import {
  PopupInfoToolTip,
  TextFieldToolTip,
} from '../../views/solutions/vnet/components/InfoToolTip';
import {
  setProject,
  createJobs,
  createWorkflow,
} from '../../../store/actions/datachef';
import { FormControlLabelInfo } from '../../views/solutions/vnet/InputForm';
import {
  WorkspaceContainer,
  WorkSpace,
} from '../../views/solutions/vnet/index';
// images
import svg3 from '../../../assets/images/dataops/3.svg';
import mongodbImg from '../../../assets/images/dbImages/mongodb.webp';
import mysqlImg from '../../../assets/images/dbImages/mysql.webp';
import postgresqlImg from '../../../assets/images/dbImages/postgresql.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faCopy,
  faExternalLinkAlt,
  faFolderOpen,
  faForward,
  faAngleLeft,
  faAngleRight,
  faTable,
  faThumbsDown,
  faThumbsUp,
  faQuestionCircle,
  faArrowRight,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import twitterImg from '../../../assets/images/dataStream/twitter.webp';
import dynamodbImg from '../../../assets/images/dbImages/dynamodb.webp';
import { StyledBackdrop, StyledButton, Result } from '../../../styles/common';
import Spinner from '../../common/loading/Spinner';
import { StyledTabs } from '../../views/solutions/ColbertAnalysis';
import Appbar from './Appbar';
import { VizTable } from './Table';
import Plot from '../../views/solutions/vnet/components/Plot';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`a11y-tabpanel-${index}`}
      aria-labelledby={`a11y-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </div>
  );
}
class Dtops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: 'Home',
      openDialog: true,
      passwordText: 'password',
      clusterName: '',
      clusterUserName: '',
      clusterPwd: '',
      dns: '',
      cleansing: 'no',
      showPreview: true,
      page: 0,
      submitBtnStatus: true,
      rowsPerPage: 10,
      showProjects: true,
      showWorkFlow: true,
      breadCrumbsList: [{ title: 'Project', to: 'showProjects' }],
      activeStep: 0,
      query: 'SELECT * FROM ',
      tabValue: 0,
      showExistingStream: false,
      twitterId: '@VirtusaCorp',
      maxTweets: 20,
      cronMints: 10,
      tweetsFromDate: '',
      workSpaceData: false,
      workSpaceWFData: false,
      workSpaceConnectionData: false,
      workSpaceJobData: false,
      showWorkspace: false,
      projNameState: false,
      projIdState: false,
      WFIdState: false,
      JobIdState: false,
      dpTab: 0,
      sqlTabvalue: 2,
      crawlerTab: 0,
      dataSet: false,
      HostName: '',
      UserName: '',
      PWD: '',
      DBName: '',
      S3URL:
        's3://datachef-v2/Env=Dev/User=GOPINATHMU@VIRTUSA.COM/Project=DFP-HMKC/Workflow=DFW-VX16TI/Job=job-0N7OU5GFMD/BulkDataPreparation/WPMOG28NHPVLJ25YOXN5.csv',
      //dummy
      schemaList: [],
      conversionInfoState: 'json',
      PartitionTypeState: 'None',
      DetectPII_Patterns_State: [],
      TransformationInfo_State: [],
      checkedItems: new Map(),
      Source_dataset_s3: '',
      isReportStarted: false,
      isActive: '',
    };
    this.componentMetaData = {
      Project: {
        back: 'Home',
        next: '',
        info: [
          'Choose a .csv file to be uploaded',
          'Provide a short description of the file',
          'Click on ‘Save’ to validate the file',
        ],
      },
      'Data Transform': {
        back: 'Data Manipulation',
        next: 'Dashboard & Preview',
      },
      'Data Ingestion': {
        back: 'Project',
        next: 'Data Manipulation',
      },
      'Data Manipulation': {
        back: 'Data Ingestion',
        next: 'Data Transform',
      },
      'Dashboard & Preview': {
        back: 'Data Transform',
        next: '',
      },
    };
    this.apiOptions = {
      createNewJob: {
        url: 'dataops/createJobId/',
        LoadingText: 'Creating new Job id',
        successMessage: 'Job Id created Succesfully',
      },
      getJobIds: {
        url: 'dataops/getJobId/',
        LoadingText: 'Loding Jobs for the selected user',
        successMessage: 'Exsting Jobs Loaded Succesfully',
      },
      getSession: {
        url: 'ingest/jdbc/getUserConf/validateConnection/',
        LoadingText: 'Checking if session is already availble',
        successMessage: 'Loaded Sessions',
      },
    };
    this.components = {};
    this.dbImages = {
      mysql: mysqlImg,
      postgres: postgresqlImg,
      mongodb: mongodbImg,
      dynamodb: dynamodbImg,
    };
    this.dbType = {
      sql: [
        {
          name: 'My Sql',
          img: mysqlImg,
          value: 'mysql',
          view: 'sqlView',
          dataSet: 'MySQL',
        },
        {
          name: 'PostgresSql',
          img: postgresqlImg,
          value: 'postgres',
          view: 'sqlView',
          comingsoon: true,
        },
        {
          name: 'Redshift',
          img: dynamodbImg,
          value: 'mysql',
          view: 'sqlView',
          comingsoon: true,
        },
        {
          name: 'Oracle',
          img: dynamodbImg,
          value: 'mysql',
          view: 'sqlView',
          comingsoon: true,
        },
        {
          name: 'MariaDB',
          img: dynamodbImg,
          value: 'mysql',
          view: 'sqlView',
          comingsoon: true,
        },
        {
          name: 'SQL Server',
          img: dynamodbImg,
          value: 'mysql',
          view: 'sqlView',
          comingsoon: true,
        },
      ],
      document: [
        {
          name: 'MongoDB',
          img: mongodbImg,
          value: 's3',
          view: 'cloudView',
          comingsoon: true,
        },
        {
          name: 'DynamoDB',
          value: 'gcp',
          img: dynamodbImg,
          view: 'cloudView',
          comingsoon: true,
        },
      ],
      cloud: [
        {
          name: 'S3',
          img: dynamodbImg,
          value: 's3',
          view: 'cloudView',
          dataSet: 'S3Object',
        },
        {
          name: 'GCP',
          value: 'gcp',
          img: dynamodbImg,
          view: 'cloudView',
          comingsoon: true,
        },
        {
          name: 'Azure',
          value: 'azure',
          img: dynamodbImg,
          view: 'cloudView',
          comingsoon: true,
        },
      ],
      file: [
        { name: 'JSON', img: dynamodbImg, value: 'json', dataSet: 'file' },
        { name: 'CSV', value: 'csv', img: dynamodbImg, dataSet: 'file' },
      ],
      streaming: [
        {
          name: 'IOT Device',
          img: dynamodbImg,
          value: 'json',
          comingsoon: true,
        },
        {
          name: 'Social NEtwork',
          value: 'csv',
          img: dynamodbImg,
          comingsoon: true,
        },
        { name: 'Media', value: 'csv', img: dynamodbImg, comingsoon: true },
        { name: 'Logs', value: 'csv', img: dynamodbImg, comingsoon: true },
      ],
      saas: [
        {
          name: 'SAAS Data',
          img: dynamodbImg,
          value: 'json',
          comingsoon: true,
        },
      ],
    };
    this.dataStreams = {
      kinesis: [
        {
          name: 'Twitter',
          img: twitterImg,
          value: 'twitter',
          comingsoon: true,
        },
      ],
    };
    this.dbDetails = {
      postgres: [],
      mysql: [],
      mongodb: [],
    };
    this.jobs = [
      'User Id',
      'Job Id',
      'DB Config',
      'Dataset',
      'Raw File Path',
      'Raw File',
      'Data Operation',
      'Build Lake',
      'Job Status',
      'TimeStamp',
      'File Destination',
      'Athena Table',
    ];
    this.steps = [
      'Initiate ',
      'Creating Dataset',
      'Creating Analysis',
      'Creating Visualization',
      'Completed',
    ];
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 0 });
  };
  ChangeTabs = (e, newValue) => {
    this.setState({ tabValue: newValue });
  };
  handlesqlTab = (e, newValue) => {
    this.setState({ sqlTabvalue: newValue });
  };
  handleCrawlerTab = (e, newValue) => {
    this.setState({ crawlerTab: newValue });
  };

  setComponet = (componentName) => {
    this.setState({ componentName });
    this.setState({
      showProjects: true,
      showWorkFlow: false,
      JobName: '',
      JobDesc: '',
    });
    this.components[componentName] &&
      this.components[componentName].callBackName();
  };
  radioBtnHandleChange = (e) => {
    const { name } = e.target;
    const value = e.target.value;
    this.setState({ [name]: value, conversionInfoState: value });
  };

  changeCheckBox = (e) => {
    const { name } = e.target;
    const value = e.target.checked;
    if (value) {
      this.setState({ [name]: value, PII_State: [name] });
    } else {
      this.setState({ [name]: value, PII_State: false, DetectPII_Patterns_State: [] });

    }
  };

  handleChooseFeatures = (e) => {
    const { value } = e.target;
    this.setState({ DetectPII_Patterns_State: value })
  }

  changeTransCheckBox = (e) => {
    const { name } = e.target;
    const value = e.target.checked;
    if (value) {
      this.setState({ [name]: value, TransformationInfo_State: [name] });
    }
  };

  getTableData = async (selectedTable) => {
    let { projIdState, WFIdState, JobIdState, dataSet, secretName } =
      this.state;
    this.setState({
      tableData: '',
      tableSchema: '',
      selectedTable,
      tableData: '',
    });
    try {
      this.isLoading(true, 'Fetching Table Data');
      const res = await this.getCommonAPIData('Preview/PreviewDataset', {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
        SecretName: secretName,
        Dataset: dataSet,
        DatasetInfo: {
          SecretName: secretName,
          Query: `Select * from ${selectedTable}`,
        },
      });
      this.isLoading(false);
      if (res) {
        this.setState({
          tableData: res['Result']?.previewData,
          tableDataFields: res['Result']?.ColumnList,
          columnTypes: res['Result']?.ColumnDtype,
          sqlVizData: res['Result']?.viz,
          Source_dataset_s3: res['ContentParam']?.Source_dataset_s3,
          selectedTable: selectedTable,
        });
      }
    } catch (error) {
      this.catchBlock(error);
    }
  };
  getJobInfo = async (jobId) => {
    const { userId } = this.state;
    const params = `?id=${userId}&job id=${jobId}`;
    try {
      this.isLoading(true, 'Fecthing Job Details');
      const res = await DataopsService.getPostResults(params, 'getJobInfo/');
      this.isLoading(false);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> Fetched Job Details
        </div>
      );
      this.setState({ jobDetails: res.result });
    } catch (error) {
      this.catchBlock(error);
    }
  };
  generateReport = async () => {
    const { projIdState, WFIdState, JobIdState, Key, FileType } = this.state;

    this.isLoading(true);
    const resBasic = await this.getCommonAPIData('DataInsight/dataProfiling', {
      ProjectId: projIdState,
      WorkflowId: WFIdState,
      JobId: JobIdState,
      Exec_info: {
        Key: Key,
        FileType: FileType,
      },
    });
    this.isLoading(false);
    if (resBasic.Error) {
      toast.error(
        <div>
          <FontAwesomeIcon /> {resBasic.Console}
        </div>
      );

      return;
    }
    this.setState({ isReportStarted: true, reportUrl: '' });
    toast.success(
      <div>
        <FontAwesomeIcon /> {resBasic.UI_Response}
      </div>
    );
  };
  checkReport = async () => {
    const { projIdState, WFIdState, JobIdState } = this.state;
    this.isLoading(true);
    const resBasic = await this.getCommonAPIData(
      'DataInsight/dataProfilingUrl',
      {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
      }
    );
    this.isLoading(false);

    if (resBasic.Error) {
      toast.error(
        <div>
          <FontAwesomeIcon /> {resBasic.Console}
        </div>
      );
      return;
    }

    if (resBasic?.status) {
      this.setState({ reportUrl: resBasic?.Url });
    }
  };

  getCollectionData = async (collectionName) => {
    const { hash_id, userId } = this.state;
    this.setState({ tableData: '' });
    const params = `?id=${userId}&hash id=${hash_id}&sql=${collectionName}&Record Count=10`;
    try {
      this.isLoading(true, 'Fetching Documents');
      const res = await DataopsService.getPostResults(
        params,
        'ingest/jdbc/retrieveTableRecords/'
      );
      res && this.setState({ jsonDocument: res });
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  isLoading = (loading, text) => {
    this.setState({
      isLoading: loading,
      LoadingText: text,
    });
  };
  closeDialog = () => {
    this.setState({
      openDialog: false,
    });
  };
  openDialog = (heading) => {
    this.setState({
      openDialog: true,
    });
  };
  showPassword = () => {
    this.setState({
      passwordText: this.state.passwordText === 'text' ? 'password' : 'text',
    });
  };
  showCreateUser = () => {
    this.setState({ createNewId: !this.state.createNewId });
  };
  toggleTablePreview = () => {
    this.setState({ showPreview: !this.state.showPreview });
  };
  showBuildLake = () => {
    this.setState({
      chartData: '',
      chartDetails: '',
      activeStep: 0,
      showCreateJobProgress: false,
      created_bucket_name: '',
      newBucket: '',
    });
    this.setComponet('Data Transform');
  };
  componentDidMount() {
    this.getWorkspaceData();
  }
  copyToCliboard = (data) => {
    copy(this.getDataToCopy()[data]);
    toast.success(
      <div>
        <FontAwesomeIcon icon={faThumbsUp} /> Copied
      </div>
    );
  };
  getDataToCopy = () => ({
    apiLink: `{${APIDATA.Z1D}:8505/vLifeDataOps-prod/kinesis/putCustomizeRecords/?id=${this.state.userId}&job%20id=${this.state.createdJobId}&stream%20name=${this.state.kinesisName}&data=<data>`,
    boto3Code: `
            import boto3 \n
            client = boto3.client('firehose','us-east-1') \n
            response = fclient.put_record(DeliveryStreamName='${this.state.kinesisName}',Record={'Data': <Data Json_format>}
        `,
  });
  createNewJob = async () => {
    const { projIdState, WFIdState, JobDesc, JobName } = this.state;
    let data2 = {
      ProjectId: projIdState,
      WorkflowId: WFIdState,
      JobName,
      JobDesc,
    };
    let url = 'UserRegistration/CreateJob';
    if (!JobName || !JobDesc) {
      toast.error(
        <div> Please Enter Data to the all Fields to Create Job</div>
      );
      return;
    }
    try {
      this.props.createJobs(url, data2);
      this.isLoading(true, 'Creating Job');
      const res = await this.getCommonAPIData(url, data2);
      res &&
        this.setState({
          createdJobId: res.hash_id,
          openDialog: false,
          selectDataFrom: '',
          kinesisName: '',
          selectedDb: '',
          ProjectName: '',
          WorkflowName: '',
          JobIdState: res.ContentParam.JobId,
          jobName: '',
        });
      this.isLoading(false);
      this.getJobIds(data2.ProjectId, data2.WorkflowId);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> {res.Result}
        </div>
      );
      this.setComponet('Data Ingestion');
    } catch (error) {
      this.catchBlock(error);
    }
  };
  getCommonAPIData = async (endpoint, contentData = {}, contextData = {}) => {
    const { projIdState, WFIdState, JobIdState, dataSet } = this.state;
    let result;
    const postparams = {
      Context_param: {
        ...this.props?.datachef?.Context_param,
        ...contextData,
      },
      Content_param: {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
        ...contentData,
      },
    };
    try {
      result = await DataopsService.getPostResults(endpoint, postparams);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  getUploadFileCommonAPIData = async (
    endpoint,
    contentData = {},
    contextData = {},
    file
  ) => {
    const { projIdState, WFIdState, JobIdState, dataSet } = this.state;
    let result;
    const postparams = {
      Context_param: {
        ...this.props?.datachef?.Context_param,
        ...contextData,
      },
      Content_param: {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
        Dataset: dataSet,
        ...contentData,
      },
    };
    try {
      result = await DataopsService.uploadFile(file, postparams, endpoint);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  createNewProject = async (e) => {
    const { ProjectName, ProjectDesc, ProjectDomain, ProjectSubDomain } =
      this.state;
    let initial = true;
    let data = {
      ProjectName,
      ProjectDesc,
      ProjectDomain,
      ProjectSubDomain,
    };
    if (!ProjectName || !ProjectDesc || !ProjectDomain || !ProjectSubDomain) {
      toast.error(
        <div> Please Enter Data to the all Fields to Create Project</div>
      );
      return;
    }
    try {
      this.props.setProject(data);
      this.isLoading(true, 'Creating Project');
      const res = await this.getCommonAPIData(
        'UserRegistration/CreateProject',
        data
      );
      res &&
        this.setState({
          openDialog: false,
          ProjectName: '',
          ProjectDesc: '',
          ProjectDomain: '',
          ProjectSubDomain: '',
          WorkflowName: '',
          jobName: '',
          projNameState: ProjectName,
          projIdState: res.ContentParam.ProjectId,
        });
      this.isLoading(false);
      this.getProjectIds(initial);
      this.getWorkFlowIds(ProjectName, initial);
      console.log(this.state.workSpaceData);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> success
        </div>
      );
    } catch (error) {
      this.catchBlock(error);
    }
  };
  createNewWorkFlow = async (e) => {
    let initial = true;
    const { WorkflowDesc, WorkflowName, projNameState, projIdState } =
      this.state;
    let data1 = {
      ProjectId: projIdState,
      WorkflowDesc,
      WorkflowName,
    };
    let data2 = {
      ProjectName: projNameState,
    };
    let url = 'UserRegistration/CreateWorkflow';
    if (!WorkflowName || !WorkflowDesc) {
      toast.error(
        <div> Please Enter Data to the all Fields to Create Workflow</div>
      );
      return;
    }
    try {
      this.props.createWorkflow(data1);
      this.isLoading(true, 'Creating Workflow');
      const res = await this.getCommonAPIData(url, data1);
      res &&
        this.setState({
          ProjectId: '',
          WorkflowName: '',
          WorkflowDesc: '',
          jobName: '',
          WFIdState: res.ContentParam.WorkflowId,
          projIdState: res.ContentParam.ProjectId,
        });
      this.isLoading(false);
      this.getWorkFlowIds(data2.ProjectName, initial);
      let ProjectId = res.ContentParam.ProjectId;
      let WorkflowId = res.ContentParam.WorkflowId;
      this.getJobIds(ProjectId, WorkflowId, initial);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> Created New Workflow
        </div>
      );
    } catch (error) {
      this.catchBlock(error);
    }
  };
  getJobIds = async (data4, initial) => {
    if (initial) {
      this.setState({
        showWorkFlow: false,
        showProjects: false,
        showWorkspace: false,
      });
    } else {
      try {
        this.isLoading(true, 'Fetching Jobs');
        let url = 'UserRegistration/GetJob';
        const res = await this.getCommonAPIData(url, data4);
        if (res) {
          const newbreadCrumbsList = this.state.breadCrumbsList;
          newbreadCrumbsList[2] = { title: 'Jobs', to: 'showJobs' };
          this.setState({
            jobsList: res['job Info'],
            showProjects: false,
            showWorkFlow: false,
            breadCrumbsList: newbreadCrumbsList,
            jobDetails: '',
            workSpaceJobData: res.ContentParam,
          });
          res['job Id'] &&
            toast.success(
              <div>
                <FontAwesomeIcon icon={faThumbsUp} /> {successMessage}
              </div>
            );
        }
        this.isLoading(false);
      } catch (error) {
        this.catchBlock(error);
      }
    }
  };
  getProjectIds = async (initial) => {
    if (initial) {
      this.setState({ showWorkFlow: true, showProjects: false });
    } else {
      try {
        this.isLoading(true, 'Fetching Projects', 'orange');
        let url = 'UserRegistration/GetProject';
        let res = await this.getCommonAPIData(url);
        console.log(res);
        if (res?.Error) {
          toast.error(
            <div>
              <FontAwesomeIcon icon={faThumbsDown} />
              {res.Console}
            </div>
          );
        } else {
          toast.success(
            <div>
              <FontAwesomeIcon icon={faThumbsUp} /> {res.UI_Response}
            </div>
          );
        }
        if (res) {
          const newbreadCrumbsList = [this.state.breadCrumbsList[0]];
          this.setState({
            projectList: res['project name'],
            showProjects: true,
            breadCrumbsList: newbreadCrumbsList,
            workSpaceData: res.ContentParam,
            showWorkspace: true,
          });
          console.log(this.state.workSpaceData);
          res['job Id'] &&
            toast.success(
              <div>
                <FontAwesomeIcon icon={faThumbsUp} /> {res.message}
              </div>
            );
        }
        this.isLoading(false);
      } catch (error) {
        this.catchBlock(error);
      }
    }
  };
  getWorkFlowIds = async (ProjectName, initial) => {
    let data3 = {
      ProjectName,
    };
    if (initial) {
      this.setState({
        showWorkFlow: true,
        showProjects: false,
        showWorkspace: false,
      });
    } else {
      try {
        this.isLoading(true, 'loading workflows');
        let url = 'UserRegistration/GetWorkflow';
        const res = await this.getCommonAPIData(url, data3);
        if (res) {
          const newbreadCrumbsList = this.state.breadCrumbsList;
          newbreadCrumbsList[1] = { title: 'Workflow', to: 'showWorkFlow' };
          this.setState({
            workFlowList: res['workflow name'],
            showWorkFlow: true,
            showWorkspace: true,
            showProjects: false,
            ProjectName: ProjectName,
            breadCrumbsList: newbreadCrumbsList,
            WorkflowName: '',
            workSpaceWFData: res.ContentParam,
          });
          toast.success(
            <div>
              <FontAwesomeIcon icon={faThumbsUp} /> {res.Result}
            </div>
          );
        }
        this.isLoading(false);
      } catch (error) {
        this.catchBlock(error);
      }
    }
  };
  handleWorkspaceCard = (component) => {
    if (component == 'Home') {
      this.getWorkspaceData()
    }
    this.setComponet(component)
  }
  getWorkspaceData = async () => {
    try {
      this.isLoading(true, 'Loading workspace');
      let url = 'UserRegistration/GetWorkspace';
      const res = await this.getCommonAPIData(url);
      if (res) {
        this.setState({
          worksapceData: res['ContentParam'],
        });
        toast.success(
          <div>
            <FontAwesomeIcon icon={faThumbsUp} /> {res.Result}
          </div>
        );
      }
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  catchBlock = (error) => {
    this.isLoading(false);
    toast.error(
      <div>
        <FontAwesomeIcon icon={faThumbsDown} />
        {error.message || 'Error Occured'}
      </div>
    );
  };
  getSession = async (selectedDb) => {
    const { url, successMessage, LoadingText } = this.apiOptions.getSession;
    const params = `?id=${this.state.userId}&db_name=${selectedDb}`;
    this.isLoading(true, LoadingText);
    try {
      const res = await DataopsService.getPostResults(params, url);
      this.setState({
        selectedDb,
        sessions: res,
      });
      res
        ? toast.success(
          <div>
            <FontAwesomeIcon icon={faThumbsUp} />
            {successMessage}
          </div>
        )
        : toast.error(
          <div>
            <FontAwesomeIcon icon={faThumbsDown} /> No Session available
          </div>
        );
      this.isLoading(false);
      this.setDbDetails();
    } catch (error) {
      this.catchBlock(error);
    }
  };
  setDbDetails = () => {
    const dbConf = this.dbDetails[this.state.selectedDb];
    this.setState({
      dns: dbConf[0],
      clusterName: dbConf[1],
      clusterUserName: dbConf[2],
      clusterPwd: dbConf[3],
    });
  };

  getSecretName = async () => {
    console.log(this.state.dataSet);
    const { JobIdState, projIdState, WFIdState, dataSet } = this.state;
    try {
      this.isLoading(true, 'loading Connections');
      const res = await this.getCommonAPIData('SQL/GetSecretName', {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
        Dataset: dataSet,
      });
      if (res) {
        this.setState({
          showWorkspace: true,
          workSpaceConnectionData: res.ContentParam,
        });
        toast.success(
          <div>
            <FontAwesomeIcon icon={faThumbsUp} /> {res.Result}
          </div>
        );
      }
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };

  validateExistingConnection = async () => {
    const { projIdState, WFIdState, JobIdState, dataSet, secretName } = this.state;
    try {
      this.isLoading(true, 'Fetching Table Data');
      const res = await this.getCommonAPIData('SQL/GetDbSchema', {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
        Dataset: dataSet,
        SecretName: secretName,
      });
      this.isLoading(false);
      if (res) {
        this.setComponet('Data Manipulation');
        this.setState({
          schemaList: res['ContentParam']?.DbInfo,
          collectionList: null,
        });
      }
    } catch (err) {
      this.catchBlock(err);
      this.isLoading(false);
    }
  }
  validateConnection = async () => {
    const {
      projIdState,
      WFIdState,
      JobIdState,
      dataSet,
      HostName,
      UserName,
      PWD,
      DBName,
      S3URL,
      projNameState,
      WFNameState,

    } = this.state;
    let url, params;
    if (dataSet == 'MySQL') {
      url = 'SQL/ValidateSQlConn';
      params = {
        DatasetInfo: {
          host: HostName,
          username: UserName,
          password: PWD,
          dbname: DBName,
        },
        Dataset: dataSet,
      };
    } else if (dataSet == 'S3Object') {
      url = 'DataIngestion/validateS3Object';
      params = {
        ProjectName: projNameState,
        WorkflowName: WFNameState,
        DatasetInfo: S3URL,
        Dataset: dataSet,
      };
    }
    try {
      this.isLoading(true, 'Establish the DB Connection');

      const res = await this.getCommonAPIData(url, params);
      console.log(this.state.selectDataFrom, 'value');
      if (res?.Error) {
        toast.error(
          <div>
            <FontAwesomeIcon /> {res.Console}
          </div>
        );
        this.isLoading(false);
        return;
      }
      if (this.state.selectDataFrom === 'sql') {
        const schemaNameRes = await this.getCommonAPIData('SQL/GetSecretName', {
          Dataset: dataSet,
        });
        if (schemaNameRes.Error) {
          toast.error(
            <div>
              <FontAwesomeIcon /> {schemaNameRes.Console}
            </div>
          );
          this.isLoading(false);
          return;
        }
        if (schemaNameRes.ContentParam?.tableData?.length > 0) {
          const schemaRes = await this.getCommonAPIData('SQL/GetDbSchema', {
            ...params,
            SecretName: schemaNameRes.ContentParam?.tableData[0][0],
            Dataset: dataSet,
          });
          if (schemaRes.Error) {
            toast.error(
              <div>
                <FontAwesomeIcon /> {schemaRes.Console}
              </div>
            );
            this.isLoading(false);
            return;
          }
          this.setState({
            schemaList: schemaRes['ContentParam']?.DbInfo,
            collectionList: null,
            secretName: schemaNameRes.ContentParam?.tableData[0][0],
          });
        }
      } else if (dataSet === 'S3Object') {
        const previewRes = await this.getCommonAPIData(
          'Preview/PreviewDataset',
          {
            ProjectId: projIdState,
            WorkflowId: WFIdState,
            JobId: JobIdState,
            Dataset: 'S3Object',
            DatasetInfo: {
              FileKey: res['Source_dataset_s3'],
            },
          }
        );
        if (previewRes.Error) {
          this.isLoading(false);
          toast.error(
            <div>
              <FontAwesomeIcon /> {previewRes.Console}
            </div>
          );
          return;
        }
        this.setState({
          schemaList: null,
          secretName: '',
          selectedTable: '',
          Source_dataset_s3: res['Source_dataset_s3'],
          tableData: previewRes['Result']?.previewData,
          tableDataFields: previewRes['Result']?.ColumnList,
          sqlVizData: previewRes['Result']?.viz,
        });
      }
      this.setComponet('Data Manipulation');
      this.isLoading(false);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> {res['UI_Response']}
        </div>
      );
    } catch (err) {
      this.catchBlock(err);
      this.isLoading(false);
    }
  };
  uploadFile = async (file) => {
    const { projIdState, WFIdState, JobIdState } = this.state;
    let url = 'DataIngestion/fileUploadRequest';
    let params = {
      ProjectId: projIdState,
      WorkflowId: WFIdState,
      JobId: JobIdState,
    };
    try {
      this.isLoading(true, 'Uploading File');
      const res = await this.getUploadFileCommonAPIData(url, params, {}, file);
      res && this.isLoading(false);
      this.setState({
        filePath: res.file_path,
        bucket: res.bucket,
        file_format: res['file format'],
        created_bucket_name: '',
        tableName: '',
        tables: '',
      });
      this.isLoading(false);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> File uploaded Succesfully
        </div>
      );
      // this.setComponet('DataProcessing');
    } catch (err) {
      console.log(err.message);
      this.isLoading(false);
    }
  };
  deleteJobId = async (jobId) => {
    const { userId, jobsList } = this.state;
    const params = `?id=${userId}&job id=${jobId}`;
    this.isLoading(true, 'Deleting Job');
    try {
      const res = await DataopsService.getPostResults(
        params,
        'dataops/deleteJobId/'
      );
      const newJobList = jobsList.filter((job) => job[0] !== jobId);
      this.setState({ jobsList: newJobList });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> Deleted Succesfully
        </div>
      );
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  setDatasetName = (e) => {
    this.setState({ datasetName: false });
    const DatasetType = e.currentTarget.getAttribute('data-view');
    const dataSet = e.currentTarget.getAttribute('data-set');
    {
      DatasetType == 'sqlView'
        ? this.setState({
          datasetName: 'Database',
          dataSet: 'MySQL',
          dbList: false,
          showWorkspace: false,
        })
        : DatasetType == 'cloudView'
          ? this.setState({
            datasetName: 'Cloud',
            dbList: false,
            dataSet: 'S3Object',
          })
          : this.setState({
            datasetName: 'File',
            dbList: false,
            dataSet: 'file',
          });
    }
  };
  deleteProject = async (ProjectName) => {
    const { userId, projectList } = this.state;
    const params = `?id=${userId}&project name=${ProjectName}`;
    this.isLoading(true, 'Deleting Job');
    try {
      const res = await DataopsService.getPostResults(
        params,
        'ingest/deleteProject/'
      );
      const newJobList = projectList.filter((job) => job !== ProjectName);
      this.setState({ projectList: newJobList });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> Deleted Succesfully
        </div>
      );
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  deleteWorkFlow = async (WorkflowName) => {
    const { userId, workFlowList, ProjectName } = this.state;
    const params = `?id=${userId}&project name=${ProjectName}&workflow name=${WorkflowName}`;
    this.isLoading(true, 'Deleting Job');
    try {
      const res = await DataopsService.getPostResults(
        params,
        'ingest/deleteWorkflow/'
      );
      const newJobList = workFlowList.filter((job) => job !== WorkflowName);
      this.setState({ workFlowList: newJobList });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> Deleted Succesfully
        </div>
      );
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  showBreadcrumbsView = (show) => {
    this.setState({ showProjects: false, showWorkFlow: false, [show]: true });
  };
  createStream = async () => {
    const {
      userId,
      stream,
      kinesisBucket,
      createdJobId,
      bufferTime,
      bufferFileSize,
      outputDir,
      customFolderName,
    } = this.state;
    const params = `?id=${userId}&job id=${createdJobId}&stream name=${stream}&bucket name=${kinesisBucket}&buffer time=${bufferTime}&buffer file size=${bufferFileSize}&output dir=${outputDir === 'Custom-Folder' ? customFolderName : outputDir
      }`;
    this.isLoading(true, 'Creating stream');
    this.setState({ kinesisName: '', tweetData: '', kinesisS3Output: '' });
    try {
      const res = await DataopsService.getPostResults(
        params,
        'kinesis/createStream/'
      );
      this.setState({ kinesisName: res['kinesis name'] });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> Created New stream
        </div>
      );
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  putRecords = async () => {
    const {
      userId,
      kinesisName,
      createdJobId,
      twitterId,
      maxTweets,
      tweetsFromDate,
      cronMints,
    } = this.state;
    const params = `?id=${userId}&job id=${createdJobId}&stream name=${kinesisName}&twitter id=${twitterId}&max tweet=${maxTweets}&date=${tweetsFromDate}&cron mins=${cronMints}`;
    this.isLoading(true, 'Loading kinesis s3 path');
    this.setState({ kinesisS3Output: '', tweetData: '' });
    try {
      const res = await DataopsService.getPostResults(
        params,
        'kinesis/putRecords/'
      );
      this.setState({ kinesisS3Output: res.s3_dest });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> s3 Path Loaded
        </div>
      );
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  putcustomizedRecords = async () => {
    const { userId, kinesisName, createdJobId, jsonData } = this.state;
    const params = `?id=${userId}&job id=${createdJobId}&stream name=${kinesisName}&data=${jsonData}`;
    this.isLoading(true, 'Loading kinesis s3 path');
    this.setState({ customDataS3Link: '' });
    try {
      const res = await DataopsService.getPostResults(
        params,
        'kinesis/putCustomizeRecords/'
      );
      this.setState({ customDataS3Link: res.s3_dest });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> s3 Path Loaded
        </div>
      );
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  fetchRecords = async () => {
    const { userId, createdJobId, twitterId, maxTweets } = this.state;
    const params = `?id=${userId}&job id=${createdJobId}&twitter id=${encodeURIComponent(
      twitterId
    )}&max tweet=${maxTweets}`;
    this.isLoading(true, 'Loading tweets');
    this.setState({ kinesisS3Output: '', tweetData: '' });
    try {
      const res = await DataopsService.getPostResults(
        params,
        'data/fetchTweet/'
      );
      this.setState({ tweetData: res.tweet });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> tweets Loaded
        </div>
      );
      this.isLoading(false);
    } catch (error) {
      this.catchBlock(error);
    }
  };
  onFieldChange = (e) => {
    let { value, name } = e.target;
    let id;
    switch (name) {
      case 'uploadedFile':
        value = e.target.files[0];
        this.uploadFile(value);
        break;
      case 'newBucket':
        const regex = new RegExp('^[A-Za-z0-9-]*$');
        if (!regex.test(value)) {
          toast.error(<div> Special characters are not allowed</div>);
          return false;
        }
        break;
      case 'userId':
        let userName;
        this.state.userIdList.filter((user) => {
          if (user[1] == value) {
            userName = user[0];
          }
        });
        this.setState({ openDialog: false, userName });
        break;
      case 'column_action':
        id = e.currentTarget.getAttribute('data-id');
        this.setState({
          dataManipulationObj: {
            ...this.state.dataManipulationObj,
            [id]: { feature: value },
          },
        });
        console.log(this.state.dataManipulationObj);
        break;
      case 'column_val':
        id = e.target.id;
        this.setState({
          dataManipulationObj: {
            ...this.state.dataManipulationObj,
            [id]: { ...this.state.dataManipulationObj[id], value: value },
          },
        });
        break;
      case 'selectDataFrom':
        if (value === 'sql') {
          this.setState({
            dbList: this.dbType[value],
            datasetName: false,
            uploadedFile: '',
            filePath: '',
            dataSource: 'MySQL',
          });
        } else if (value === 'cloud') {
          this.setState({
            dbList: this.dbType[value],
            datasetName: false,
            uploadedFile: '',
            filePath: '',
            dataSource: value,
          });
        } else if (value === 'file') {
          this.setState({
            dbList: this.dbType[value],
            datasetName: false,
            uploadedFile: '',
            filePath: '',
            dataSource: 'Upload',
          });
        } else if (value === 'document') {
          this.setState({
            dbList: this.dbType[value],
            datasetName: false,
            uploadedFile: '',
            filePath: '',
            dataSource: value,
          });
        } else if (value === 'streaming') {
          this.setState({
            dbList: this.dbType[value],
            datasetName: false,
            uploadedFile: '',
            filePath: '',
            dataSource: value,
          });
        } else if (value === 'saas') {
          this.setState({
            dbList: this.dbType[value],
            datasetName: false,
            uploadedFile: '',
            filePath: '',
            dataSource: value,
          });
        } else {
          this.setState({
            uploadedFile: this.dbType[value],
            sessions: '',
            showPreview: false,
            selectedDb: '',
            datasetName: false,
            dataSource: value,
          });
        }
        break;
      case 'selectedTable':
        this.getTableData(value);
        break;
      case 'schemaName':
        this.fetchTables(value);
        break;
      case 'collectionName':
        this.getCollectionData(value);
        break;
      case 'kinesisName':
        this.setState({ tweetData: '', kinesisS3Output: '' });
        break;
      default:
    }
    this.setState({ [name]: value });
  };
  fetchExistingStreams = async () => {
    this.isLoading(true, 'Loading streams');
    try {
      const res = await DataopsService.getPostResults('', 'kinesis/getStream/');
      this.setState({ existingStreams: res });
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> Streams Loaded
        </div>
      );
      this.isLoading(false);
      this.setComponet('Kinesis');
    } catch (error) {
      this.catchBlock(error);
    }
  };
  setSession = async (sessionData) => {
    const { createdJobId, userId } = this.state;
    const params = `?id=${userId}&Job id=${createdJobId}&hash id=${sessionData[7]}`;
    this.isLoading(true, 'Loading Schema');
    try {
      this.setState({
        schemaList: null,
        schemaName: '',
        datasetName: '',
        tableData: '',
        selectedTable: '',
        created_bucket_name: '',
        tables: '',
      });
      const res = await DataopsService.getPostResults(
        params,
        'ingest/jdbc/getUserConf/validateConnection/verify/v1'
      );
      this.isLoading(false);
      if (this.state.selectDataFrom === 'sql') {
        this.setState(
          { hash_id: res.hash_id, schemaList: res.schema, showPreview: true },
          () => {
            // this.setComponet('DataProcessing');
          }
        );
      } else {
        this.setState(
          {
            hash_id: res.hash_id,
            collectionList: res.schema,
            showPreview: true,
          },
          () => {
            // this.setComponet('DataProcessing');
          }
        );
      }
      res
        ? toast.success(
          <div>
            <FontAwesomeIcon icon={faThumbsUp} /> Schema List Loaded
          </div>
        )
        : toast.error(
          <div>
            <FontAwesomeIcon icon={faThumbsDown} /> {res.Console}
          </div>
        );
    } catch (error) {
      this.catchBlock(error);
    }
  };
  FeaturesContainer = ({ selectedCheveron, subFeature }) => {
    const listData =
      selectedCheveron && cheveronData[selectedCheveron].features;
    const [open, setCollapse] = useState('');
    const handleCollapse = (e) => {
      const val = e.currentTarget.getAttribute('data-feature');
      setCollapse(open === val ? '' : val);
    };
  };
  getCurrentStage = (e) => {
    let initial = true;
    const projectId = e.target.id;
    const projName = e.target.getAttribute('data-name');
    this.setState({ projNameState: projName });
    this.setState({ projIdState: projectId });
    this.getWorkFlowIds(projName, initial);
  };
  getCurrentStageWF = (e) => {
    let initial = true;
    const ProjectId = e.target.getAttribute('id');
    const WorkflowId = e.target.getAttribute('wf-id');
    const WFName = e.target.getAttribute('wf-name');
    this.setState({ WFIdState: WorkflowId, WFNameState: WFName });
    this.setState({ projIdState: ProjectId });
    let data1 = {
      ProjectId,
      WorkflowId,
    };
    this.getJobIds(data1.ProjectId, data1.WorkflowId, initial);
  };
  handleCurrentStage = async (e) => {
    const ProjectId = e.target.getAttribute('id');
    const WorkflowId = e.target.getAttribute('wf-id');
    const JobId = e.target.getAttribute('job-id');
    if (ProjectId && WorkflowId && JobId) {
      try {

        this.isLoading(true, 'Fetching Session')
        const currentSession = await this.getCommonAPIData('UIHelper/PageSession', {
          ProjectId: ProjectId,
          WorkflowId: WorkflowId,
          JobId: JobId,
        }, { "Actions": "Route" });
        this.isLoading(false);

        this.setState({
          Source_dataset_s3: currentSession?.data?.latestDF,
          schemaList: currentSession?.data?.DbInfo,
          tableData: currentSession.data?.['Result']?.previewData,
          tableDataFields: currentSession.data?.['Result']?.ColumnList,
          columnTypes: currentSession.data?.['Result']?.ColumnDtype,
          sqlVizData: currentSession.data?.['Result']?.viz,
          dataSet: currentSession?.data?.input_src,
          dataManipulationObj: currentSession?.data?.draftFlag && JSON.parse(currentSession?.data?.draft),
          JobIdState: JobId, projIdState: ProjectId, WFIdState: WorkflowId
        });
        currentSession?.data?.stage && this.setComponet(currentSession?.data?.stage);
      }
      catch (err) {
        this.isLoading(false);
        console.log(err)
      }
    }
  };
  getCurrentStageJob = (e) => {
    const ProjectId = e.target.getAttribute('id');
    const WorkflowId = e.target.getAttribute('wf-id');
    const JobId = e.target.getAttribute('job-id');
    console.log(JobId);
    this.setState({ JobIdState: JobId });
    let data1 = {
      ProjectId,
      WorkflowId,
    };
    this.getJobIds(data1.ProjectId, data1.WorkflowId);
    this.setComponet('Data Ingestion');
  };
  getCurrentStageConnections = (e, id) => {
    const SecretNameId = e.currentTarget.getAttribute('name');
    this.setState({
      secretName: SecretNameId,
      isActive: id
    });
    console.log(this.state.isActive);
  };

  handleSpecify = (e) => {
    const val = e.target.value;
    this.setState({ dataSet: 'MySQL', specify: val });
    if (val == 'True') {
      this.getSecretName();
    }
  };

  handleWorkspace = (e) => {
    const { projNameState, projIdState, WFIdState } = this.state;
    let data = { ProjectId: projIdState, WorkflowId: WFIdState };
    const workspaceName = e.target.getAttribute('name');
    this.setState({ showWorkspace: !this.state.showWorkspace });
    {
      workspaceName == 'myProjects'
        ? !this.state.showWorkspace && this.getProjectIds()
        : workspaceName == 'myWorkflows'
          ? !this.state.showWorkspace && this.getWorkFlowIds(projNameState)
          : !this.state.showWorkspace && this.getJobIds(data);
    }
    console.log(this.state.showWorkspace);
  };
  Jobs = () => {
    const { classes } = this.props;
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumb}>
          {this.state.breadCrumbsList &&
            this.state.breadCrumbsList.map((obj) => (
              <Link
                color='inherit'
                onClick={this.showBreadcrumbsView.bind(this, obj.to)}
              >
                <Typography color='textPrimary' className={classes.link}>
                  <FontAwesomeIcon icon={faFolderOpen} /> &nbsp;
                  {obj.title}
                </Typography>
              </Link>
            ))}
        </Breadcrumbs>
        <section>
          {this.state.showProjects ? (
            <div style={{ width: '-webkit-fill-available' }}>
              <WorkspaceContainer
                style={{ width: 'inherit', zIndex: 2 }}
                display={this.state.showWorkspace}
              >
                <span
                  onClick={this.handleWorkspace}
                  id='workspace-btn'
                  name='myProjects'
                >
                  <FontAwesomeIcon
                    icon={this.state.showWorkspace ? faAngleRight : faAngleLeft}
                  />
                  &nbsp;My Projects
                </span>
                <WorkSpace style={{ width: '85%' }} id='workspace-content'>

                  <TableProjects
                    tableData={this.state.workSpaceData}
                    onClick={this.getCurrentStage}
                    linkId='Project Id'
                  />
                </WorkSpace>
              </WorkspaceContainer>
              <Typography variant='h6' color='textPrimary'>
                Create New Project:
              </Typography>
              <br />
              <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Project Name'
                    name='ProjectName'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.ProjectName}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Project Description'
                    name='ProjectDesc'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.ProjectDesc}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Project Domain'
                    name='ProjectDomain'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.ProjectDomain}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Project Sub Domain'
                    name='ProjectSubDomain'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.ProjectSubDomain}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                xs={12}
                direction='row'
                justify='center'
                alignItems='center'
                alignContent='center'
                style={{ margin: '10px' }}
              >
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    onClick={this.createNewProject}
                  >
                    CREATE
                  </StyledButton>
                </Grid>
              </Grid>
            </div>
          ) : this.state.showWorkFlow ? (
            <div style={{ width: '-webkit-fill-available' }}>
              <WorkspaceContainer
                style={{ width: 'inherit', zIndex: 2 }}
                display={this.state.showWorkspace}
              >
                <span
                  onClick={this.handleWorkspace}
                  id='workspace-btn'
                  name='myWorkflows'
                >
                  <FontAwesomeIcon
                    icon={this.state.showWorkspace ? faAngleRight : faAngleLeft}
                  />
                  &nbsp;My Workflows
                </span>
                <WorkSpace style={{ width: '85%' }} id='workspace-content'>

                  <TableWF
                    tableData={this.state.workSpaceWFData}
                    onClick={this.getCurrentStageWF}
                    linkId='Project Id'
                    data={this.Workflow}
                  />
                </WorkSpace>
              </WorkspaceContainer>
              <Typography variant='h6' color='textPrimary'>
                Create New Workflow
              </Typography>
              <br />
              <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Workflow Name'
                    name='WorkflowName'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.WorkflowName}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Workflow Description'
                    name='WorkflowDesc'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.WorkflowDesc}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    onClick={this.createNewWorkFlow}
                  >
                    CREATE
                  </StyledButton>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div style={{ width: '-webkit-fill-available' }}>
              <WorkspaceContainer
                style={{ width: 'inherit', zIndex: 2 }}
                display={this.state.showWorkspace}
              >
                <span
                  onClick={this.handleWorkspace}
                  id='workspace-btn'
                  name='myJobs'
                >
                  <FontAwesomeIcon
                    icon={this.state.showWorkspace ? faAngleRight : faAngleLeft}
                  />
                  &nbsp;My Jobs
                </span>
                <WorkSpace
                  id='workspace-content'
                  display={this.state.showWorkspace}
                >
                  <TableJobs
                    tableData={this.state.workSpaceJobData}
                    onClick={this.getCurrentStageJob}
                    linkId='Project Id'
                  />
                </WorkSpace>
              </WorkspaceContainer>
              <Typography variant='h6' color='textPrimary'>
                Create New Job
              </Typography>
              <br />
              <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Job Name'
                    name='JobName'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.JobName}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    id='outlined-basic'
                    variant='outlined'
                    label='Job Description'
                    name='JobDesc'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    value={this.state.JobDesc}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    onClick={this.createNewJob}
                  >
                    CREATE
                  </StyledButton>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                xs={12}
                direction='row'
                justify='left'
                style={{ margin: '10px' }}
              ></Grid>
            </div>
          )}
        </section>
      </div>
    );
  };
  dataManipulation = async (e) => {
    let id = e.currentTarget.id;
    this.setState({ tableData: '', tableSchema: '' });
    try {
      this.isLoading(true, 'Fetching Table Data');
      const res = await this.getCommonAPIData('DataPrepare/Bulk', {
        Source_dataset_s3: this.state.Source_dataset_s3,
        DatasetOperation: this.state.dataManipulationObj,
        draft: id ? true : false
      });
      this.isLoading(false);
      if (res) {
        if (!id) {
          this.setState({
            tableData: res['Result']?.previewData,
            tableDataFields: res['Result']?.ColumnList,
            sqlVizData: res['Result']?.viz,
          });
        }
      }
    } catch (error) {
      this.catchBlock(error);
    }
  };
  Dataset = () => {
    const { classes } = this.props;
    return (
      <div>
        <Typography>Configure Source Data from Available Methods</Typography>
        <br />
        <Grid
          container
          spacing={4}
          xs={12}
          direction='row'
          justify='left'
          alignItems='center'
          alignContent='center'
        >
          <Grid item xs={12} sm={4}>
            <TextField
              autoFocus
              id='outlined-basic'
              select
              variant='outlined'
              label='Type of Data Source'
              name='selectDataFrom'
              fullWidth
              autoComplete='off'
              value={this.state.selectDataFrom}
              onChange={this.onFieldChange}
            >
              <MenuItem key={0} value='sql'>
                Relational  DB
              </MenuItem>
              <MenuItem key={1} value='document'>
                Document DB
              </MenuItem>
              <MenuItem key={2} value='cloud'>
                Cloud Storage
              </MenuItem>
              <MenuItem key={3} value='file'>
                Upload File
              </MenuItem>
              <MenuItem key={4} value='streaming'>
                Streaming Data
              </MenuItem>
              <MenuItem key={5} value='saas'>
                SAAS Application
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Typography
          variant='h6'
          display='block'
          color='textPrimary'
          className={classes.textMargin}
        >
          {this.state.datasetName}
        </Typography>
        {this.state.selectDataFrom && (
          <Grid
            container
            spacing={4}
            xs={12}
            direction='row'
            justify='left'
            alignItems='center'
          >
            {this.state.dbList ? (
              this.state.dbList.map(
                ({ img, name, dataSet, view, comingsoon }) => (
                  <Grid item xs={6} sm={4} md={3}>
                    {comingsoon == true ? (
                      <TextFieldToolTip info='Coming soon'>
                        <Paper
                          style={{ opacity: 0.5, pointerEvents: 'none' }}
                          className={classes.dbCard}
                          onClick={this.setDatasetName}
                          data-view={view}
                          data-set={dataSet}
                        >
                          <img
                            src={img}
                            className={classes.dbCardImage}
                            alt=''
                          />
                          <Typography variant='h6' component='h5'>
                            {name}
                          </Typography>
                        </Paper>
                      </TextFieldToolTip>
                    ) : (
                      <Paper
                        className={classes.dbCard}
                        onClick={this.setDatasetName}
                        data-view={view}
                        data-set={dataSet}
                      >
                        <img src={img} className={classes.dbCardImage} alt='' />
                        <Typography variant='h6' component='h5'>
                          {name}
                        </Typography>
                      </Paper>
                    )}
                  </Grid>
                )
              )
            ) : (
              <div></div>
            )}
          </Grid>
        )}
        {this.state.selectDataFrom && (
          <Grid container
            xs={12}
            direction='row'
            justify='left'
            alignItems='center'
          >
            {this.state.datasetName == 'Database' ? (
              <section>
                <section>
                  <br />
                  <RadioGroup
                    style={{ display: 'block' }}
                    row
                    name='specify'
                    aria-label='Learn Mixture Weights'
                    value={this.state.specify}
                    onChange={this.handleSpecify}
                  >
                    <Grid container spacing={2} alignItems='left'>
                      <Grid item xs={12} md={5} style={{ minWidth: 'fit-content', }}>
                        <FormControlLabelInfo
                          value='False'
                          control={<Radio color='primary' />}
                          label='Create new DB connection'
                          labelPlacement='end'
                          info='Add a New DB Connection'
                        />
                      </Grid>
                      <Grid item xs={6} sm={5} md={5} style={{ minWidth: 'fit-content', }}>
                        <FormControlLabelInfo
                          value='True'
                          control={<Radio color='primary' />}
                          label='Existing DB connections'
                          labelPlacement='end'
                          info='See Already Created DB Connections'
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {this.state.specify == 'False' && (
                    <>
                      <Grid container spacing={3} justifyContent='center'>
                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            id='outlined-basic'
                            variant='outlined'
                            label='HostName'
                            name='HostName'
                            fullWidth
                            autoComplete='off'
                            size='small'
                            value={this.state.HostName}
                            onChange={this.onFieldChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            id='outlined-basic'
                            variant='outlined'
                            label='UserName'
                            name='UserName'
                            fullWidth
                            autoComplete='off'
                            size='small'
                            value={this.state.UserName}
                            onChange={this.onFieldChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            id='outlined-basic'
                            variant='outlined'
                            type='password'
                            label='PWD'
                            name='PWD'
                            fullWidth
                            autoComplete='off'
                            size='small'
                            value={this.state.PWD}
                            onChange={this.onFieldChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            id='outlined-basic'
                            variant='outlined'
                            label='DB Name'
                            name='DBName'
                            fullWidth
                            autoComplete='off'
                            size='small'
                            value={this.state.DBName}
                            onChange={this.onFieldChange}
                          ></TextField>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          xs={12}
                          direction='row'
                          justify='center'
                          alignItems='center'
                          alignContent='center'
                          style={{ margin: '10px' }}
                        >
                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <StyledButton
                              variant='contained'
                              color='primary'
                              onClick={this.validateConnection}
                            >
                              Validate &amp; Save
                            </StyledButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {this.state.specify == 'True' && (
                    <>
                      <Typography
                        variant='h6'
                        display='block'
                        color='textPrimary'
                        className={classes.textMargin}
                      >
                        Select Existing Sessions
                      </Typography>
                      <Grid
                        container
                        spacing={2}
                        xs={12}
                        direction='row'
                        justify='left'
                        alignItems='center'
                      >
                        {this.state.workSpaceConnectionData.tableData?.map(
                          (data, id) => (
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ display: 'flex' }}
                            >
                              <div key={id}>
                                <Paper
                                  className={classes.sessionCard}
                                  elevation={2}
                                  onClick={(event) =>
                                    this.getCurrentStageConnections(event, id)
                                  }
                                  name={data[0]}
                                  style={{
                                    backgroundColor:
                                      this.state.isActive === id
                                        ? '#3aba82'
                                        : '',
                                    color:
                                      this.state.isActive === id ? '#ffff' : '',
                                    width: 'initial',
                                  }}
                                >
                                  <Grid
                                    container
                                    xs={12}
                                    direction='row'
                                    justify='left'
                                    style={{ flexDirection: 'column' }}
                                  >
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: '10px' }}>
                                        <strong>Name &nbsp;: &nbsp;</strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                      <Typography
                                        component='p'
                                        style={{ fontSize: '10px' }}
                                      >
                                        {data[0]}
                                      </Typography>
                                    </Grid>
                                    <br />
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: '10px' }}>
                                        <strong> ANS &nbsp;: </strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                      <Typography
                                        component='p'
                                        style={{ fontSize: '10px' }}
                                      >
                                        {data[1]}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </div>
                            </Grid>
                          )
                        )}
                      </Grid>
                      <StyledButton
                        onClick={this.validateExistingConnection}
                        variant='contained'
                        color='primary'
                      >
                        Submit
                      </StyledButton>
                    </>
                  )}
                </section>
              </section>
            ) : this.state.datasetName == 'Cloud' ? (
              <section style={{ width: 'inherit' }}>
                <Grid xs={12} container justifyContent='center'>
                  <Grid item xs={8}>
                    <TextField
                      autoFocus
                      id='outlined-basic'
                      variant='outlined'
                      label='S3 URI'
                      name='S3URL'
                      fullWidth
                      autoComplete='off'
                      size='small'
                      value={this.state.S3URL}
                      onChange={this.onFieldChange}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  xs={12}
                  direction='row'
                  justify='center'
                  alignItems='center'
                  alignContent='center'
                  style={{ margin: '10px' }}
                >
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <StyledButton
                      variant='contained'
                      color='primary'
                      onClick={this.validateConnection}
                    >
                      Validate &amp; Save
                    </StyledButton>
                  </Grid>
                </Grid>
              </section>
            ) : this.state.datasetName == 'File' ? (
              <Grid item xs={6} sm={4}>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  name='uploadedFile'
                  type='file'
                  fullWidth
                  onChange={this.onFieldChange}
                />
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>
        )}
        {this.state.selectedDb && (
          <section>
            {this.state.sessions && (
              <section>
                <Typography
                  variant='h6'
                  display='block'
                  color='textPrimary'
                  className={classes.textMargin}
                >
                  Select Existing Sessions with{this.state.selectedDb}
                </Typography>
                <Grid
                  container
                  spacing={4}
                  xs={12}
                  direction='row'
                  justify='left'
                  alignItems='center'
                >
                  {this.state.sessions.map((session) => (
                    <Grid item xs={6} sm={4}>
                      <Paper
                        className={classes.sessionCard}
                        elevation={2}
                        onClick={this.setSession.bind(this, session)}
                      >
                        <img className={classes.dbCardImage} alt='' />
                        <Grid container xs={12} direction='row' justify='left'>
                          <Grid item xs={4}>
                            <Typography component='h5'>
                              <strong>Connection Name &nbsp;: &nbsp;</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography component='h5'>{session[0]}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography component='h5'>
                              <strong> DNS &nbsp;: </strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography component='h5'>{session[2]}</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </section>
            )}
            <section>
              <Typography
                variant='h6'
                display='block'
                color='textPrimary'
                className={classes.textMargin}
              >
                Db Connection Details
              </Typography>
              <Grid
                container
                spacing={4}
                xs={12}
                direction='row'
                justify='left'
                alignItems='center'
              >
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant='outlined'
                    id='outlined-basic'
                    fullWidth
                    name='dns'
                    label='DNS'
                    autoComplete='off'
                    value={this.state.dns}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant='outlined'
                    id='outlined-basic'
                    fullWidth
                    name='clusterName'
                    label='Cluster Name'
                    autoComplete='off'
                    value={this.state.clusterName}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant='outlined'
                    id='outlined-basic'
                    fullWidth
                    name='clusterUserName'
                    label='Cluster User Name'
                    autoComplete='off'
                    value={this.state.clusterUserName}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='outlined-basic'
                    name='clusterPwd'
                    type={this.state.passwordText}
                    label='Cluster Password'
                    autoComplete='off'
                    value={this.state.clusterPwd}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={this.showPassword}
                          >
                            {this.state.passwordText === 'text' ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant='outlined'
                    id='outlined-basic'
                    fullWidth
                    name='connectionName'
                    label='Connection Name'
                    autoComplete='off'
                    value={this.state.connectionName}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.margin2}
                    onClick={this.validateConnection}
                  >
                    Validate Connection
                  </Button>
                </Grid>
              </Grid>
            </section>
          </section>
        )}
      </div>
    );
  };
  fetchTables = (schemaName) => {
    const { hash_id, userId } = this.state;
    const params = `?id=${userId}&hash id=${hash_id}&Schema Name=${schemaName || this.state.schemaName
      }`;
    this.isLoading(true, 'Fetching Tables');
    DataopsService.getPostResults(params, 'ingest/jdbc/fetchTable/')
      .then((res) => {
        this.isLoading(false);
        this.setState({ tables: res.tablename });
        toast.success(
          <div>
            <FontAwesomeIcon icon={faThumbsUp} /> Tables Loaded
          </div>
        );
      })
      .catch((err) => {
        console.log(err.message);
        this.isLoading(false);
      });
  };
  SQLEditor = () => {
    const { classes } = this.props;
    return (
      <SqlEditorContainer>
        <SQLMainContent>
          {this.state.schemaList && (
            <SQLSchema>
              <strong>&nbsp;Schemas</strong>
              <hr />
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={['public']}
              >
                {this.state.schemaList.map((schemaObj) => (
                  <TreeItem
                    nodeId={schemaObj.schema_name}
                    label={schemaObj.schema_name}
                  >
                    {schemaObj.table_list.map((table) => (
                      <TreeItem
                        nodeId={table}
                        label={table}
                        onClick={this.getTableData.bind(this, table)}
                        icon={<FontAwesomeIcon icon={faTable} />}
                      />
                    ))}
                  </TreeItem>
                ))}
              </TreeView>
            </SQLSchema>
          )}
          <SQLContent>
            <AppBar position='static' color='default'>
              <StyledTabs
                value={this.state.sqlTabvalue}
                onChange={this.handlesqlTab}
                variant='fullWidth'
                aria-label='simple tabs example'
              >
                <Tab label='Visualization' />
                <Tab label='Manipulation' />
                <Tab label='Data Overview' />
              </StyledTabs>
            </AppBar>

            <TabPanel value={this.state.sqlTabvalue} index={0}>
              {/*<TableImg src={tableImg} />*/}
              <Grid
                container
                xs={12}
                direction='row'
                justify='left'
                alignContent={'center'}
              >
                <Grid item xs={12} sm={12}>
                  <VizTable
                    tableData={this.state.sqlVizData}
                    columns={this.state.tableDataFields}
                    colType={this.state.columnTypes}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.sqlTabvalue} index={1}>
              <DataManipulationContainer>
                <Grid container xs={12} spacing={2} alignItems='center' justifyContent='center'>
                  {this.state.tableDataFields?.map((col) => (
                    <>
                      <Grid item xs={12} sm={1}>
                        &nbsp;
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <p style={{ fontSize: "12px" }}>{col}</p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id={col}
                          variant='outlined'
                          label='Operation'
                          name='column_action'
                          fullWidth
                          select
                          size='small'
                          value={this.state.dataManipulationObj?.[col]?.feature}
                          onChange={this.onFieldChange}
                        >
                          {[
                            'Column Rename',
                            'Drop Duplicate',
                            'Column Delete',
                            'Imputation',

                          ].map((col_action) => (
                            <MenuItem
                              key={col_action}
                              value={col_action}
                              data-id={col}
                            >
                              {col_action}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {['Column Rename', 'Imputation'].indexOf(
                          this.state.dataManipulationObj?.[col]?.feature
                        ) > -1 && (
                            <FormControl fullWidth>
                              <TextField
                                id={col}
                                variant='outlined'
                                label='value'
                                name='column_val'
                                data-id={col}
                                size='small'
                                value={this.state.dataManipulationObj?.[col]?.value}
                                onChange={this.onFieldChange}
                              />
                            </FormControl>
                          )}
                      </Grid>
                    </>
                  ))}
                </Grid>

              </DataManipulationContainer>
            </TabPanel>
            <TabPanel value={this.state.sqlTabvalue} index={2}>
              {this.state.tableData && (
                <Grid
                  container
                  xs={12}
                  direction='row'
                  justify='left'
                  alignContent={'center'}
                >
                  <Grid item xs={12} sm={12}>
                    <TableContainer className={classes.scrollableTable}>
                      <Table stickyHeader className={classes.table}>
                        <TableHead>
                          <TableRow>
                            {this.state.tableDataFields.map((field) => (
                              <TableCell
                                className={classes.tableHeader}
                                key={field}
                                align='left'
                              >
                                {field}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.tableData
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                            )
                            .map((row) => (
                              <TableRow key={row.index}>
                                {this.state.tableDataFields.map((field) => (
                                  <TableCell align='left'>
                                    {row[field]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      component='div'
                      count={this.state.tableData.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
              )}
              {/*<TableImg src={tab3} />*/}
            </TabPanel>
            {this.state.sqlTabvalue == 1 && <>

              <StyledButton
                onClick={this.dataManipulation}
                variant='contained'
                color='primary'
                id='draft'
              >
                save
              </StyledButton>
              <StyledButton
                onClick={this.dataManipulation}
                variant='contained'
                color='primary'
              >
                Submit
              </StyledButton>
            </>
            }
          </SQLContent>
        </SQLMainContent>
      </SqlEditorContainer>
    );
  };
  Kinesis = () => {
    const { classes } = this.props;
    return (
      <div>
        <section style={{ height: '500px' }}>
          <Typography
            variant='h6'
            display='block'
            color='textPrimary'
            className={classes.textMargin}
          >
            Data Stream
          </Typography>
          {!this.state.showExistingStream ? (
            <Grid
              container
              spacing={2}
              xs={12}
              direction='row'
              justify='left'
              alignContent={'center'}
            >
              <Grid item xs={12} sm={3}>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  label='Existing Streams *'
                  name='kinesisName'
                  fullWidth
                  select
                  value={this.state.kinesisName}
                  onChange={this.onFieldChange}
                >
                  {this.state.existingStreams &&
                    this.state.existingStreams.map((stream) => (
                      <MenuItem key={stream} value={stream}>
                        {stream}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              xs={12}
              direction='row'
              justify='left'
              alignContent={'center'}
            >
              <Grid item xs={12} sm={3}>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  label='Stream Name *'
                  name='stream'
                  fullWidth
                  value={this.state.stream}
                  onChange={this.onFieldChange}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id='outlined-basic'
                  fullWidth
                  variant='outlined'
                  label='s3 Bucket Name*'
                  name='kinesisBucket'
                  value={this.state.kinesisBucket}
                  onChange={this.onFieldChange}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  label='Buffer Time(seconds)'
                  placeholder='between 60sec to 300sec'
                  name='bufferTime'
                  fullWidth
                  value={this.state.bufferTime}
                  onChange={this.onFieldChange}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  label='Buffer File Size(MB)'
                  placeholder='between 1MB to 500MB'
                  name='bufferFileSize'
                  fullWidth
                  value={this.state.bufferFileSize}
                  onChange={this.onFieldChange}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  label='Output Dir'
                  name='outputDir'
                  fullWidth
                  select
                  value={this.state.outputDir}
                  onChange={this.onFieldChange}
                >
                  <MenuItem
                    key={'dir1'}
                    value={
                      'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}/'
                    }
                  >
                    {
                      'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}/'
                    }
                  </MenuItem>
                  <MenuItem
                    key={'dir1'}
                    value={
                      'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}/day=!{timestamp:dd}/hour=!{timestamp:HH}/'
                    }
                  >
                    {
                      'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}/day=!{timestamp:dd}/hour=!{timestamp:HH}/'
                    }
                  </MenuItem>
                  <MenuItem
                    key={'dir1'}
                    value={
                      'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}/day=!{timestamp:dd}/'
                    }
                  >
                    {
                      'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}/day=!{timestamp:dd}/'
                    }
                  </MenuItem>
                  <MenuItem
                    key={'dir1'}
                    value={
                      'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}'
                    }
                  >
                    {'stream-data/year=!{timestamp:yyyy}/month=!{timestamp:MM}'}
                  </MenuItem>
                  <MenuItem key={'dir1'} value={'stream-data/'}>
                    {'stream-data/'}
                  </MenuItem>
                  <MenuItem key={'dir1'} value={'Custom-Folder'}>
                    {'Custom-Folder'}
                  </MenuItem>
                </TextField>
              </Grid>
              {this.state.outputDir === 'Custom-Folder' && (
                <Grid item xs={12} sm={3}>
                  <TextField
                    id='outlined-basic'
                    variant='outlined'
                    label='Custom Folder Name'
                    name='customFolderName'
                    fullWidth
                    value={this.state.customFolderName}
                    onChange={this.onFieldChange}
                  ></TextField>
                </Grid>
              )}
              <Grid item xs={12} sm={2}>
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.btn}
                  onClick={this.createStream}
                >
                  Create Stream
                </Button>
              </Grid>
            </Grid>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showExistingStream}
                name='showExistingStream'
                onClick={this.changeCheckBox}
              />
            }
            label='Create New Stream'
          />
          {this.state.kinesisName && (
            <section>
              <AppBar position='static'>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.ChangeTabs}
                  aria-label='simple tabs example'
                >
                  <Tab label='TWitter Data' />
                  <Tab label='Load Custom Data' />
                </Tabs>
              </AppBar>
              <TabPanel value={this.state.tabValue} index={0}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  direction='row'
                  justify='center'
                  alignContent='center'
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      className={classes.width100}
                      fullWidth
                      id='outlined-basic'
                      size='small'
                      variant='outlined'
                      label='Twitter Id*'
                      placeholder='starts with @ or #'
                      name='twitterId'
                      value={this.state.twitterId}
                      onChange={this.onFieldChange}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      className={classes.width100}
                      id='outlined-basic'
                      size='small'
                      fullWidth
                      variant='outlined'
                      label='Date'
                      name='tweetsFromDate'
                      type='date'
                      value={this.state.tweetsFromDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.onFieldChange}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      className={classes.width100}
                      id='outlined-basic'
                      size='small'
                      fullWidth
                      variant='outlined'
                      label='Max No. of Tweets'
                      name='maxTweets'
                      value={this.state.maxTweets}
                      onChange={this.onFieldChange}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      label='Job Re-run'
                      fullWidth
                      placeholder='value in mints'
                      name='cronMints'
                      size='small'
                      value={this.state.cronMints}
                      onChange={this.onFieldChange}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      variant='outlined'
                      size='small'
                      color='primary'
                      onClick={this.fetchRecords}
                    >
                      Preview
                    </Button>
                    &nbsp;
                    <Button
                      variant='outlined'
                      size='small'
                      color='primary'
                      onClick={this.putRecords}
                    >
                      submit
                    </Button>
                  </Grid>
                  {this.state.kinesisS3Output && (
                    <Grid item xs={12}>
                      <Button variant='outlined' color='primary'>
                        <a
                          className={classes.linkButton}
                          href={this.state.kinesisS3Output}
                          target='_blank'
                        >
                          verify data on s3 Bucket
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                      </Button>
                    </Grid>
                  )}
                  {this.state.tweetData && (
                    <pre className={classes.fetchRecords}>
                      <code>
                        <p>Tweets</p>
                        {this.state.tweetData}
                      </code>
                    </pre>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1}>
                <Grid container xs={12} direction='row'>
                  <Grid
                    xs={12}
                    sm={6}
                    justify='center'
                    alignContent='center'
                    alignItems='center'
                  >
                    <Grid item xs={12}>
                      <TextField
                        className={classes.width80}
                        id='outlined-basic'
                        fullWidth
                        multiline
                        rows={4}
                        variant='outlined'
                        label='Custom Data *'
                        defaultValue={
                          '{"subject":"a87dc24a6e547c4a5fe85738b4b0903b", "app name":"Cancer Lectins", "api endpoint":"https://patient-engagement.vlifevirtusa.com/PatientEngagement/cancerlectins/MTHR", "api status":"200", "api reason": "Success", "time stamp":"Tue, 16 Mar 17:34:30 IST 2021"}'
                        }
                        name='jsonData'
                        value={this.state.jsonData}
                        onChange={this.onFieldChange}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.btn}
                        onClick={this.putcustomizedRecords}
                      >
                        push Data
                      </Button>
                    </Grid>
                    {this.state.customDataS3Link && (
                      <Grid item xs={12}>
                        <br />
                        <br />
                        <br />
                        <Button variant='outlined' color='primary'>
                          <a
                            className={classes.linkButton}
                            href={this.state.customDataS3Link}
                            target='_blank'
                          >
                            verify data on s3 Bucket
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </a>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <section className={classes.customData}>
                      <p>
                        <strong>Ways that we integrate live stream</strong>
                      </p>
                      <p>
                        <strong> 1. Via pre-built API </strong>
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={this.copyToCliboard.bind(this, 'apiLink')}
                        />
                      </p>
                      <p>
                        {`${APIDATA.Z1D}:8505/vLifeDataOps-prod/kinesis/putCustomizeRecords/?id=${this.state.userId}&job%20id=${this.state.createdJobId}&stream%20name=${this.state.kinesisName}&data=<data>`}
                      </p>
                      <p>
                        <strong> 2. Via boto3 API </strong>
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={this.copyToCliboard.bind(this, 'boto3Code')}
                        />
                      </p>
                      <p>
                        <code>
                          import boto3 <br />
                          client = boto3.client('firehose','us-east-1') <br />
                          {`response = fclient.put_record(DeliveryStreamName='${this.state.kinesisName}',Record={'Data': <Data Json_format>}`}
                        </code>
                      </p>
                    </section>
                  </Grid>
                </Grid>
              </TabPanel>
            </section>
          )}
        </section>
      </div>
    );
  };
  nextComponent = (e) => {
    this.setComponet(e.currentTarget.id);
    this.setState({ specify: 'None' });
  };
  DataProcessing = () => {
    const { classes } = this.props;
    return (
      <div>
        {this.state.showPreview ? (
          <section style={{ height: '600px', margin: '-20px' }}>
            {this.state.schemaList && this.SQLEditor()}
            {this.state.collectionList && (
              <section style={{ width: '100%' }}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  direction='row'
                  justify='left'
                  alignContent='center'
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      className={classes.width80}
                      variant='outlined'
                      id='outlined-basic'
                      select
                      fullWidth
                      name='collectionName'
                      label='Select Collection'
                      autoComplete='off'
                      value={this.state.collectionName}
                      onChange={this.onFieldChange}
                    >
                      {this.state.collectionList.map((collection) => (
                        <MenuItem key={collection} value={collection}>
                          {collection}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {this.state.jsonDocument && (
                    <Grid item xs={12}>
                      <pre>
                        <code className={classes.code}>
                          <p>Documents</p>
                          {this.state.jsonDocument}
                        </code>
                      </pre>
                    </Grid>
                  )}
                </Grid>
              </section>
            )}
          </section>
        ) : (
          <></>
        )}
      </div>
    );
  };
  handleDataProcessingTab = (e, newVal) => {
    this.setState({ dpTab: newVal });
  };
  showInitiate = async (e, newVal) => {
    const {
      projIdState,
      WFIdState,
      JobIdState,
      conversionInfoState,
      PartitionTypeState,
      DetectPII_Patterns_State,
      TransformationInfo_State,
      secretName,
      Source_dataset_s3,
    } = this.state;
    try {
      this.isLoading(true, 'Data Manipulation & Transformation In Progress');
      const res = await this.getCommonAPIData('Glue/Transformation', {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
        Dataset: 'S3',
        TransformationInfo: TransformationInfo_State,
        DetectPII_Patterns: DetectPII_Patterns_State,
        ConversionInfo: conversionInfoState,
        PartitionType: PartitionTypeState,
        SecretName: secretName,
        Source_dataset_s3: Source_dataset_s3,
      });
      if (res.Error) {
        toast.error(
          <div>
            <FontAwesomeIcon /> {res.Console}
          </div>
        );
        this.isLoading(false);
        return;
      }
      const resTransformationStatus = await this.getCommonAPIData(
        'Glue/TransformationStatus',
        {
          ProjectId: projIdState,
          WorkflowId: WFIdState,
          JobId: JobIdState,
          Dataset: 'S3',
          GlueJob_Info: {
            JobRunId: res.ContentParam?.GlueJob_Info.JobRunId,
          },
        }
      );
      if (resTransformationStatus.Error) {
        toast.error(
          <div>
            <FontAwesomeIcon /> {resTransformationStatus.UI_Response}
          </div>
        );
        this.setState({
          submitBtnStatus: true,
          initiateQuicksight: false
        });
        this.isLoading(false);
        return;
      }
      this.setState({
        initiateQuicksight: true,
        Key: resTransformationStatus?.Key,
        FileType: resTransformationStatus?.FileType,
        submitBtnStatus: false,
      });
      this.isLoading(false);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> {resTransformationStatus.Result}
        </div>
      );
    } catch (err) {
      this.catchBlock(err);
      this.isLoading(false);
    }
  };

  callInitiate = async (e, newVal) => {
    const {
      projIdState,
      WFIdState,
      JobIdState,
      projNameState,
      WFNameState,
      jobName,
      Key,
      FileType,
    } = this.state;
    try {
      this.isLoading(true, 'Metadata Extraction & Table Definition Creation In Progress');

      const resCreateCrawler = await this.getCommonAPIData(
        'Athena/CreateCrawler',
        {
          ProjectId: projIdState,
          WorkflowId: WFIdState,
          JobId: JobIdState,
          Exec_info: {
            Key: Key,
            FileType: FileType,
          },
        }
      );
      if (resCreateCrawler.Error) {
        toast.error(
          <div>
            <FontAwesomeIcon /> {resCreateCrawler.Console}
          </div>
        );
        this.isLoading(false);
        return;
      }

      const resCreateTable = await this.getCommonAPIData('Athena/CreateTable', {
        JobId: JobIdState,
        ProjectName: projNameState,
        WorkflowName: WFNameState,
        JobName: jobName,
      });
      if (resCreateTable.Error) {
        toast.error(
          <div>
            <FontAwesomeIcon /> {resCreateTable.Console}
          </div>
        );
        this.isLoading(false);
        return;
      }
      const resBasic = await this.getCommonAPIData('DataInsight/basic', {
        ProjectId: projIdState,
        WorkflowId: WFIdState,
        JobId: JobIdState,
        Exec_info: {
          Key: Key,
          FileType: FileType,
        },
      }
      );
      if (resBasic.Error) {
        toast.error(
          <div>
            <FontAwesomeIcon /> {resBasic.Console}
          </div>
        );
        this.isLoading(false);
        return;
      }
      this.setComponet('Dashboard & Preview');

      this.setState({
        activeStep: 0,
        dashboardViz: "",
        reportUrl: '',
        isReportStarted: false,
      });
      let status, step;
      const timeInterval = setInterval(async () => {
        try {
          this.isLoading(true, 'Analysis & Visualization In Progress');

          status = await this.getCommonAPIData('UIHelper/PageStatus', {
            ProjectId: projIdState,
            WorkflowId: WFIdState,
            JobId: JobIdState,
          }, { Actions: 'DataViz' });
          step = status?.PageStatus?.filter(num => num == '1').length;

          this.setState({ activeStep: step });
          if (step === 6) {
            clearInterval(timeInterval);
            setTimeout(() => {
              this.isLoading(false);
              this.setState({ dashboardViz: resBasic?.Viz, })
            }, 2000)
          }
        }
        catch (err) {
          this.isLoading(false);
        }
      }, 10000);
      toast.success(
        <div>
          <FontAwesomeIcon icon={faThumbsUp} /> {resBasic.Result}
        </div>
      );
    } catch (err) {
      this.catchBlock(err);
      this.isLoading(false);
    }
  };
  Dashboard = () => {
    return (
      <>
        <Grid item xs={12}>
          <Stepper alternativeLabel activeStep={this.state.activeStep}>
            {this.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {this.state.dashboardViz && <>
          <AppBar position='static' color='default'>
            <StyledTabs
              value={this.state.crawlerTab}
              onChange={this.handleCrawlerTab}
              variant='fullWidth'
              aria-label='simple tabs example'
            >
              <Tab label='Visualization' />
              <Tab label='Data Profiling' />
            </StyledTabs>
          </AppBar>
          <TabPanel value={this.state.crawlerTab} index={0}>
            {this.state.dashboardViz?.TextForm &&
              Object.entries(this.state.dashboardViz?.TextForm)?.map(
                (data) => (
                  <p>
                    {' '}
                    <FontAwesomeIcon icon={faArrowRight} /> {data[0]} :{' '}
                    {data[1]}
                  </p>
                )
              )}

            <Grid container>
              <Grid item xs={12} sm={6}>
                <Plot data={{ ...this.state.dashboardViz?.column_distinct }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Plot data={{ ...this.state.dashboardViz?.column_type }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Plot data={{ ...this.state.dashboardViz?.row_duplicate }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Plot data={{ ...this.state.dashboardViz?.column_correlation }} />

              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel
            value={this.state.crawlerTab}
            index={1}
          ><br />
            {this.state.reportUrl ? <iframe src={this.state.reportUrl} width='100%' height='400px' /> :
              <PandasReport>
                {this.state.isReportStarted ? <>
                  <StyledButton onClick={this.checkReport} variant='contained'>Check Again</StyledButton>
                  <p>Report generation is in progress, check after sometime</p>
                </> :
                  <StyledButton onClick={this.generateReport} variant='contained'>Generate Report</StyledButton>}
              </PandasReport>
            }
          </TabPanel>
        </>}
      </>
    );
  };
  BuildLake = () => {
    const { classes } = this.props;
    return (
      <div>
        {
          <section>
            <Typography>
              Perform enriching, cleaning, normalizing and loading output forms
              in data lakes without writing code
            </Typography>
            <br />
            <br />
            <Grid
              container
              spacing={2}
              xs={12}
              direction='row'
              justify='left'
              alignContent={'center'}
            >
              <AppBar position='static' color='default'>
                <StyledTabs
                  value={this.state.dpTab}
                  onChange={this.handleDataProcessingTab}
                  variant='fullWidth'
                  aria-label='simple tabs example'
                >
                  <Tab label='Transformation' />
                  <Tab label='File Conversion' />
                  <Tab label='PII Detection' />
                </StyledTabs>
              </AppBar>
              <TabPanel
                style={{ width: '100%' }}
                value={this.state.dpTab}
                index={0}
              >
                <br />

                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  alignContent='center'
                >
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.dropDuplicates}
                          name='dropDuplicates'
                          onClick={this.changeTransCheckBox}
                        />
                      }
                      label='Drop Null Fileds'
                    />
                    <FormControlLabelInfo
                      control={
                        <Checkbox
                          checked={this.state.dropNull}
                          name='dropNull'
                          disabled={true}
                          onClick={this.changeTransCheckBox}
                        />
                      }
                      label='Join'
                      info='Coming Soon'
                    />
                    <FormControlLabelInfo
                      control={
                        <Checkbox
                          checked={this.state.dropNonAscii}
                          name='dropNonAscii'
                          disabled={true}
                          onClick={this.changeTransCheckBox}
                        />
                      }
                      label='Split Fields'
                      info='Coming Soon'
                    />
                    <FormControlLabelInfo
                      control={
                        <Checkbox
                          checked={this.state.dropNonAscii}
                          name='dropNonAscii'
                          disabled={true}
                          onClick={this.changeTransCheckBox}
                        />
                      }
                      label='Filter'
                      info='Coming Soon'
                    />
                    <FormControlLabelInfo
                      control={
                        <Checkbox
                          checked={this.state.dropNonAscii}
                          name='dropNonAscii'
                          disabled={true}
                          onClick={this.changeTransCheckBox}
                        />
                      }
                      label='Union'
                      info='Coming Soon'
                    />
                    <FormControlLabelInfo
                      control={
                        <Checkbox
                          checked={this.state.dropNonAscii}
                          name='dropNonAscii'
                          disabled={true}
                          onClick={this.changeTransCheckBox}
                        />
                      }
                      label='Aggregate'
                      info='Coming Soon'
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                style={{ width: '100%' }}
                value={this.state.dpTab}
                index={1}
              >
                <br />
                <Grid container spacing={3} alignItems='center' justifyContent='center'>

                  <Grid item xs={12} md={5} >
                    <FormControl>
                      <RadioGroup
                        value={this.value}
                        row
                        onChange={this.radioBtnHandleChange}
                        aria-labelledby='demo-radio-buttons-group-label'
                        defaultValue='json'
                        name='radio-buttons-group'
                      >
                        <FormControlLabel
                          value='json'
                          control={<Radio />}
                          label='JSON'
                        />
                        <FormControlLabel
                          value='csv'
                          control={<Radio />}
                          label='CSV'
                        />
                        <FormControlLabel
                          value='parquet'
                          control={<Radio />}
                          label='Parquet'
                        />
                        <FormControlLabel
                          value='avro'
                          control={<Radio />}
                          label='Avro'
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                style={{ width: '100%' }}
                value={this.state.dpTab}
                index={2}
              >
                <br />

                <Grid container alignItems='center' spacing={3} justifyContent='center'>
                  <Grid item xs={12} md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.folderStructure}
                          name='folderStructure'
                          onClick={this.changeCheckBox}
                        />
                      }
                      label='Detect PII'
                    />
                  </Grid>
                  {this.state.PII_State && (
                    <Grid item xs={12} md={4}>
                      <Select
                        // id={features}
                        multiple
                        variant='outlined'
                        label='Choose Features'
                        name='choose_features'
                        fullWidth
                        select
                        value={this.state.DetectPII_Patterns_State}
                        onChange={this.handleChooseFeatures}
                        renderValue={(DetectPII_Patterns_State) => DetectPII_Patterns_State.join(', ')}
                      >
                        {[
                          'PERSON_NAME',
                          'EMAIL',
                          'CREDIT_CARD',
                          'IP_ADDRESS',
                          'MAC_ADDRESS',
                          'PHONE_NUMBER',
                          'USA_PASSPORT_NUMBER',
                          'USA_SSN',
                          'USA_ITIN',
                          'BANK_ACCOUNT',
                          'USA_DRIVING_LICENSE',
                          'USA_HCPCS_CODE',
                          'USA_NATIONAL_DRUG_CODE',
                          'USA_NATIONAL_PROVIDER_IDENTIFIER',
                          'USA_DEA_NUMBER',
                          'USA_HEALTH_INSURANCE_CLAIM_NUMBER',
                          'USA_MEDICARE_BENEFICIARY_IDENTIFIER',
                        ]?.map((ft_action) => (
                          <MenuItem
                            key={ft_action}
                            value={ft_action}
                          // data-id={ft}
                          >
                            <ListItemIcon>
                              <Checkbox checked={this.state.DetectPII_Patterns_State.indexOf(ft_action) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={ft_action} />{' '}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <Grid item xs={12}>
              <center> <br/><Button
                  variant='contained'
                  color='primary'
                  onClick={this.showInitiate}
                >
                  Submit
                </Button></center>
              </Grid>
              {this.state.initiateQuicksight && (
                <Grid item xs={12}>
                  <center>
                    <br />
                    <Typography>
                      <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Data Transformation has been performed and stored as json file format. Proceed to extract metadata and table definition creation.
                    </Typography>
                    <br />
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={this.callInitiate}
                    >
                      Initiate
                    </Button>
                  </center>
                </Grid>
              )}
            </Grid>
          </section>
        }
      </div>
    );
  };
  getComponent = () => {
    const { componentName, userId, createdJobId, selectDataFrom } = this.state;
    switch (componentName) {
      case 'Home':
        return (
          <Home
            handleClick={this.handleWorkspaceCard}
            data={this.state.worksapceData}
            loadStatus={this.state.isLoading}
            loadText={this.state.LoadingText}
            handleCurrentStage={this.handleCurrentStage}
          />

        );
      case 'Project':
        return this.Jobs();
      case 'Data Ingestion':
        return this.Dataset();
      case 'Data Manipulation':
        return this.SQLEditor();
      case 'Data Transform':
        return this.BuildLake();
      case 'Dashboard & Preview':
        return this.Dashboard();
      default:
        return <Home handleClick={this.setComponet} />;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.body}>
        <Appbar />
        <Container>
          <Drawer handleClick={this.handleWorkspaceCard} list={this.listObj} />
          <section className={classes.mainContainer}>
            {this.state.componentName === 'Home' ? (
              this.getComponent()
            ) : this.state.componentName === 'vLife' ? (
              <Link to='/' />
            ) : (
              <Paper className={classes.paper}>
                <section>
                  <div className={classes.componentHeader}>
                    <div>
                      {this.state.componentName} &nbsp;
                      <PopupInfoToolTip
                        popupInfo={this.componentMetaData[this.state.componentName]?.info}
                        icon={faQuestionCircle}
                      />
                    </div>
                    <div>
                      {this.componentMetaData[this.state.componentName]
                        ?.back && (
                          <Button
                            variant='contained'
                            id={
                              this.componentMetaData[this.state.componentName]
                                ?.back
                            }
                            className={classes.btn}
                            onClick={this.nextComponent}
                          >
                            <FontAwesomeIcon icon={faBackward} />
                          </Button>
                        )}
                      {this.componentMetaData[this.state.componentName]
                        ?.next && (
                          <Button
                            variant='contained'
                            id={
                              this.componentMetaData[this.state.componentName]
                                ?.next
                            }
                            className={classes.btn}
                            onClick={this.nextComponent}
                          >
                            <FontAwesomeIcon icon={faForward} />
                          </Button>
                        )}
                    </div>
                  </div>
                  <div className={classes.componentPadding}>
                    {this.getComponent()}
                  </div>
                </section>

              </Paper>
            )}
            <section style={{ marginLeft: '-50px' }}>
              {/* <Footer /> */}
            </section>
            <StyledBackdrop
              style={{ color: 'white' }}
              open={this.state.isLoading}
            >
              <Spinner text={this.state.LoadingText} />
            </StyledBackdrop>
          </section>
        </Container>
        <ToastContainer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ datachef: state.datachef });
const mapDispatchToProps = (dispatch) => ({
  setProject: (data) => dispatch(setProject(data)),
  createWorkflow: (data) => dispatch(createWorkflow(data)),
  createJobs: (data) => dispatch(createJobs(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Dtops));
const TableImg = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 80%;
`;
const SqlEditorContainer = styled.section`
  margin: -25px;
`;
const SQLSchema = styled.div`
  width: min-content;
  min-width: fit-content;
  max-width: 20%;
  overflow: auto;
`;
const Container = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  display: flex;
  margin-top: 60px;
  .MuiAppBar-root{
    z-index: 0;
  }
  .MuiTableCell-root {
    font-size: 10px;
    padding: 6px;
  }
`;
const SQLContent = styled.div`
  margin-left: 5px;
  overflow: auto;
  border: 1px solid #84878c;
  width: 100%;
  max-width: 100%;
  min-height: 60vh;
`;
const SQLMainContent = styled.div`
  display: flex;
  gap: 5px;
`;
const DataManipulationContainer = styled.div`
  padding: 10px 0;
  max-height: 50vh;
  overflow: auto;
`;
const PandasReport = styled.section`
  padding: 10px;
  text-align: center;
`;
