import React, { memo, useState, useEffect } from "react";
import {
    Grid,
    MenuItem,
    ListItemText,
    FormControl,
    Select,
    FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledInput } from "../../InputForm";
import { isObjKeyhasval } from "../../util";
import { subFeatureData } from "../../constants";
import { InfoText } from '../ModelOptimization/DataShapley';

export const PiePlotForm = memo((props) => {
    const { userInputData } = useSelector((state) => state.vnet);
    const dispatch = useDispatch();
    const { initialData = null } = subFeatureData.Pie_Plot;
    const [dropdownList, setdropdownList] = useState(null);
    const [result, setResult] = useState();
    const [error, setError] = useState({});
    const [alert, setAlert] = useState("");
    const handleChange = (e) => {

        dispatch(
            setInputData({
                ...userInputData,
                [e.target.name]: e.target.value,
            }),
        );

        error[e.target.name] && setError({ ...error, [e.target.name]: false });
    };

    useEffect(async () => {
        const result = await props.getCategories();
        setResult(result);
        console.log(result);
        if (result) {
            setdropdownList(JSON.parse(result));
        }
    }, []);

    const MenuProps = {
        PaperProps: {
            style: {
                width: 250,
            },
        },
    };
    const handleFormReset = () => {
        dispatch(setInputData({
            ...initialData,
        }));
        console.log("reset");
    };

    const isFormValid = () => {
        if (!userInputData?.column || Object.entries(userInputData?.column).length == 0) {
            setAlert(true);
            return false;
        }
        return true;
    };

    return (
        <PiePlotContainer>
            {alert && (
                <InfoText success={false}>
                    {' '}
                    <FontAwesomeIcon icon={faInfoCircle} />
                    {' '}
                    Please select column 
                </InfoText>
            )}
            <CenteredGrid
                container
                spacing={3}
                alignItems="center"
                alignContent="center"
            >
                <Grid item xs={12} md={12}>
                    <FormControl fullWidth error={error.target}>
                        <StyledInput
                            label="Select Column"
                            name="column"
                            select
                            fullWidth
                            value={userInputData?.column || ''}
                            onChange={handleChange}
                            MenuProps={MenuProps}
                            variant="outlined"
                        >
                            {dropdownList?.map((value) => (
                                <MenuItem key={value} value={value}>
                                    <ListItemText primary={value} />
                                </MenuItem>
                            ))}
                        </StyledInput>
                        {error.column && <FormHelperText>Required Field</FormHelperText>}

                    </FormControl>
                </Grid>
            </CenteredGrid>
            {props.render({
                handleFormReset,
                isFormValid,
            })}
        </PiePlotContainer>
    );
});
const PiePlotContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
  }
`;

const CenteredGrid = styled(Grid)`
  text-align: center;
`;
