import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableRow,
  FormControl,
  TableCell,
  MenuItem,
  TableHead,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import styled from "styled-components";

import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
} from "../../../styles/common";

const CustomerSegmentation = () => {
  const [spinner, setSpinner] = useState(false);
  const [sampleFileList, setSampleList] = useState([]);
  const [fields, setFields] = useState(null);
  const [dataRows, setDataRows] = useState(null);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "customerSegmentation",
  );

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Customer Segments",
      },
      tooltip: {},
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Customers count",
          colorByPoint: true,
          data: [
            {
              name: data[0][0],
              y: data[1][0],
            },
            {
              name: data[0][1],
              y: data[1][1],
            },
            {
              name: data[0][2],
              y: data[1][2],
            },
            {
              name: data[0][3],
              y: data[1][3],
            },
            {
              name: data[0][4],
              y: data[1][4],
            },
            {
              name: data[0][5],
              y: data[1][5],
            },
            {
              name: data[0][6],
              y: data[1][6],
            },
            {
              name: data[0][7],
              y: data[1][7],
            },
            {
              name: data[0][8],
              y: data[1][8],
            },
            {
              name: data[0][9],
              y: data[1][9],
            },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    setSpinner(true);
    getQueryResult("sampleFileList")
      .then((res) => {
        setSpinner(false);
        setSampleList(res.SampleFiles);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  }, []);

  const onChangeFields = (e) => {
    const { value } = e.target;
    setSpinner(true);
    getQueryResult("sampleFileData", value)
      .then((res) => {
        setSpinner(false);
        console.log(res);
        const previewData = JSON.parse(res.predata);
        setFields(previewData.schema.fields);
        setDataRows(previewData.data);
        console.log(dataRows);

        formHighChart(res.output);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };
  console.log(dataRows);

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Description: </b>
            RFM analysis is a marketing technique used to
            determine quantitatively which customers are the best ones by
            examining how recently a customer has purchased (recency), how often
            they purchase (frequency), and how much the customer spends
            (monetary). It is also helpful in finding customer lifetime value
            (CLV), which represents the total amount of money a customer is
            expected to spend in business, or on products, during their
            lifetime.
            <br />
            <br />
            <b>Input data: </b>
            Customer dataset is used which captures purchase
            details of customers in retail stores. It contains unique customer
            id , transaction date and purchase amount
            <br />
            <br />
            <b>Method: </b>
            Here, 3 data samples are used, each having close to
            42000 customer data to calculate their RFM score and segregated them
            in 11 strategic heads using those scores. Scores are given on the
            scale of 1 to 5 for three categories (Recency, Frequency and
            Monetary), 1 being lowest and 5 being highest. E.g. a RFM score of
            111 depicts customer is not bringing any value to company whereas
            score of 555 describes most valuable customer.
            <br />
            <br />
          </p>
          <p>
            Further, whole analysis can be summarized into 11 segments to
            understand customers better using following rules on RFM scores:
          </p>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <ScrollableTable>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableHead>CustomerSegment</StyledTableHead>
                        <StyledTableHead>Champions</StyledTableHead>
                        <StyledTableHead StyledTableHead>
                          Loyal Customers
                        </StyledTableHead>
                        <StyledTableHead>Potential Loyalist</StyledTableHead>
                        <StyledTableHead>Recent Customers</StyledTableHead>
                        <StyledTableHead>Promising</StyledTableHead>
                        <StyledTableHead>
                          Customers Needing Attention
                        </StyledTableHead>
                        <StyledTableHead>About to Sleep</StyledTableHead>
                        <StyledTableHead>At Risk</StyledTableHead>
                        <StyledTableHead>Can’t Lose Them</StyledTableHead>
                        <StyledTableHead>Hibernating</StyledTableHead>
                        <StyledTableHead>Lost</StyledTableHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Recency Score Range</TableCell>
                        <TableCell>4-5</TableCell>
                        <TableCell>2-5</TableCell>
                        <TableCell>3-5</TableCell>
                        <TableCell>4-5</TableCell>
                        <TableCell>3-4</TableCell>
                        <TableCell>2-3</TableCell>
                        <TableCell>2-3</TableCell>
                        <TableCell>0-2</TableCell>
                        <TableCell>0-1</TableCell>
                        <TableCell>1-2</TableCell>
                        <TableCell>0-2</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Frequency & Monetary Combined Score Range
                        </TableCell>
                        <TableCell>4-5</TableCell>
                        <TableCell>3-5</TableCell>
                        <TableCell>1-3</TableCell>
                        <TableCell>0-1</TableCell>
                        <TableCell>0-1</TableCell>
                        <TableCell>2-3</TableCell>
                        <TableCell>0-2</TableCell>
                        <TableCell>2-5</TableCell>
                        <TableCell>4-5</TableCell>
                        <TableCell>1-2</TableCell>
                        <TableCell>0-2</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ScrollableTable>
              </div>
            </Grid>
            <Grid item xs={12}>
              <p>
                Based on the table, the recommended marketing action is
                described below:
              </p>
              <ScrollableTable aria-label="simple table">
                <TableHead1>
                  <TableRow>
                    <StyledTableHead>CustomerSegment</StyledTableHead>
                    <StyledTableHead>Activity</StyledTableHead>
                    <StyledTableHead>Actionable Tip</StyledTableHead>
                  </TableRow>
                </TableHead1>
                <TableBody>
                  <TableRow>
                    <TableCell>Champions</TableCell>
                    <TableCell>
                      Bought recently, buy often and spend the most!
                    </TableCell>
                    <TableCell>
                      Reward them. Can be early adopters for new products. Will
                      promote your brand.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Loyal Customers</TableCell>
                    <TableCell>
                      Spend good money with us often. Responsive to promotions.
                    </TableCell>
                    <TableCell>
                      Upsell higher value products. Ask for reviews. Engage
                      them.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Potential Loyalist</TableCell>
                    <TableCell>
                      Recent customers, but spent a good amount and bought more
                      than once.
                    </TableCell>
                    <TableCell>
                      Offer membership / loyalty program, recommend other
                      products.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Recent Customers</TableCell>
                    <TableCell>Bought most recently, but not often.</TableCell>
                    <TableCell>
                      Provide on-boarding support, give them early success,
                      start building relationship.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Promising</TableCell>
                    <TableCell>
                      Recent shoppers, but haven’t spent much.
                    </TableCell>
                    <TableCell>
                      Create brand awareness, offer free trials
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Customers Needing Attention</TableCell>
                    <TableCell>
                      Above average recency, frequency and monetary values. May
                      not have bought very recently though.
                    </TableCell>
                    <TableCell>
                      Make limited time offers, Recommend based on past
                      purchases. Reactivate them.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>About To Sleep</TableCell>
                    <TableCell>
                      Below average recency, frequency and monetary values. Will
                      lose them if not reactivated.
                    </TableCell>
                    <TableCell>
                      Share valuable resources, recommend popular products /
                      renewals at discount, reconnect with them.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>At Risk</TableCell>
                    <TableCell>
                      Spent big money and purchased often. But long time ago.
                      Need to bring them back!
                    </TableCell>
                    <TableCell>
                      Send personalized emails to reconnect, offer renewals,
                      provide helpful resources.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Can’t Lose Them</TableCell>
                    <TableCell>
                      Made biggest purchases, and often. But haven’t returned
                      for a long time.
                    </TableCell>
                    <TableCell>
                      Win them back via renewals or newer products, don’t lose
                      them to competition, talk to them.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hibernating</TableCell>
                    <TableCell>
                      Last purchase was long back, low spenders and low number
                      of orders.
                    </TableCell>
                    <TableCell>
                      Offer other relevant products and special discounts.
                      Recreate brand value.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Lost</TableCell>
                    <TableCell>
                      Lowest recency, frequency and monetary scores.
                    </TableCell>
                    <TableCell>
                      Revive interest with reach out campaign, ignore otherwise.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </ScrollableTable>
            </Grid>
          </Grid>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/customer_segmentation/RFM.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://raw.githubusercontent.com/alifiaharmd/ML-DLPlayground/main/Dataset/Retail_Data_Transactions.csv"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
        <br />
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          {sampleFileList.length > 0 && (
            <section>
              <Grid container direction="row" justify="center">
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      select
                      variant="outlined"
                      transform="translate(14px, 20px) scale(1)"
                      label="Select Segmentation"
                      onChange={onChangeFields}
                    >
                      {sampleFileList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </section>
          )}
          <br />
          <br />
          {fields && dataRows && (
            <div>
              <Grid container xs={12} direction="row" justify="center">
                <Grid xs={12}>
                  <ScrollableTable>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {fields.map((field) => (
                            <StyledTableHead1 key={field.name} align="left">
                              {field.name}
                            </StyledTableHead1>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataRows.map((row) => (
                          <TableRow key={row.index}>
                            {fields.map((field) => (
                              <TableCell align="left">
                                {field.name === "index"
                                  ? row[field.name] + 1
                                  : row[field.name]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ScrollableTable>
                </Grid>
              </Grid>
            </div>
          )}
          {spinner && <Spinner text="Loading..." />}
          <Grid container direction="row" justify="center">
            <Grid item xs={12} sm={6}>
              <HighchartContainer id="container_1" display />
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(CustomerSegmentation);

const ScrollableTable = styled.div`
  overflow: scroll;
  max-height: 500px;
  .MuiTableCell-root {
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
`;
const TableHead1 = styled(TableHead)`
  /* background-color: #3c40af; */
  color: white !important;
  text-align: left;
  font-weight: 500;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
const StyledTableHead1 = styled(TableCell)`
  font-weight: bold;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
