import { CSVForm, CSVResult } from './features/DataInput/CSV';
import { StandardScalerForm } from './features/DataPreparation/StandardScaler';
import { RenameColForm } from './features/DataTransformation/RenameColumn';
import { ReplaceValForm } from './features/DataTransformation/ReplaceValue';
import { DropDuplicatesForm } from './features/DataTransformation/DropDuplicates';
import { SelectColForm } from './features/DataTransformation/SelectColumn';
import { CorrelationMatrixForm } from './features/Dataviz/CorrelationMatrix';
import { CorrelationPlotForm } from './features/Dataviz/CorrelationPlot';
import { ScatterPlotForm } from './features/Dataviz/ScatterPlot';
import { BarPlotForm } from './features/Dataviz/BarPlot';
import { LinePlotForm } from './features/Dataviz/LinePlot';
import { LogisticRegressionForm } from './features/ModelBuilding/LogisticRegression';
import { RandomForestForm } from './features/ModelBuilding/RandomForest';
import { DecisionTreeForm } from './features/ModelBuilding/DecisionTree';
import { DecisionTreeRegressionForm } from './features/ModelBuilding/DecisionTreeRegression';
import { GaussianNaiveBayesForm } from './features/ModelBuilding/GaussianNaiveBayes';
import { DataShapleyForm } from './features/ModelOptimization/DataShapley';
import { MissingValuesForm } from './features/DataTransformation/MissingValues';
import { MTClassification } from './features/ModelTesting/MTClassification';
import { KNNForm } from './features/ModelBuilding/KNN';
import { LabelEncoderForm } from './features/DataPreparation/LabelEncoder';
import { CTGANForm } from './features/DataGen/CTGAN';
import { OneHotEncoderForm } from './features/DataPreparation/OneHotEncoder';
import { ArithmeticOperationForm } from './features/DataTransformation/ArithmeticOperation';
import { LinearRegressionForm } from './features/ModelBuilding/LinearRegression';
import { SortColumnForm } from './features/DataTransformation/SortColumn';
import { ChoroplethMapsForm } from './features/Dataviz/ChoroplethMaps';
import { AutoMLForm } from './features/ModelBuilding/AutoML';
import { PiePlotForm } from './features/Dataviz/PiePlot';
import { MinmaxScalarForm } from './features/DataPreparation/MinMaxScalar';
import { DatatypeConverterForm } from './features/DataTransformation/DatatypeConverter';
import { BinarizerForm } from './features/DataPreparation/Binarizer';
import { ANNForm } from './features/ModelBuilding/ANN';
import { RandomForestRegressionForm } from './features/ModelBuilding/RandomForestRegression';
import { LightGBMClassifierForm } from './features/ModelBuilding/LightGBMClassifier';
import { StackedBarChartForm } from './features/Dataviz/StackedBarChart';
import { XGBoostClassifierForm } from './features/ModelBuilding/XGBoostClassifier';
import { PCAForm } from './features/ModelBuilding/PCA';
import { GradientBoosterForm } from './features/ModelBuilding/GradientBooster';
import { AdaBoosterForm } from './features/ModelBuilding/AdaBooster';
import { SVCForm } from './features/ModelBuilding/SVC';
import { CatBoosterForm } from './features/ModelBuilding/CatBoostClassifier';
import { RobustScalerForm } from './features/DataPreparation/RobustScaler';

const dropDownData = {
  booleanList: [
    { label: 'False', value: 'False' },
    { label: 'True', value: 'True' },
  ],
};

export const subFeatureData = {
  CSV_Input: {
    fields: [
      {
        label: 'file',
        name: 'fileName',
        placeholder: '',
        type: 'file',
        validationKey: 'csvInput',
      },
    ],
    title: 'CSV Input',
    loadingText: '',
    Form: CSVForm,
    requestParams: 'csvParams',
    uploadFileApi: 'DataInput/Upload_CSV',
    popupInfo: [
      'Choose a .csv file to be uploaded',
      'Provide a short description of the file',
      'Click on ‘Save’ to validate the file',
      'Click on ‘Execute’ to upload it',
      'You may ‘Reset’ your selections, but files that have been uploaded will not be deleted',
      'In the Results section, click on the tabs to explore data statistics, data overview, correlation among features, and generate a detailed data report. ',
      "To upload a new file, click on the ‘Data Input – CSV' icon on the canvas, select the new file to be uploaded, and repeat the same steps above",
    ],
  },
  Rename_Column: {
    fields: [],
    title: 'Rename Column',
    loadingText: '',
    shortDesc:
      'Change the name of the column(s) of the dataset to the specified name(s)',
    Form: RenameColForm,
    noValidation: true,
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select the column that is to be renamed, From the ‘From’ dropdown list',
      'Enter the new column name in the ‘To’ textbox',
      'Click on the ‘+’ button to add more columns to be renamed.',
      'Unwanted entries can be deleted by clicking on the ‘delete’ icon ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to rename the columns',
      'You can view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of the Result section ',
    ],
  },
  Replace_Value: {
    fields: [],
    title: 'Replace Value',
    shortDesc:
      'Searches the specified fields for the mentioned value and replaces it with the new value',
    loadingText: '',
    Form: ReplaceValForm,
    noValidation: true,
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select the column whose values you would like to replace, from the `column` dropdown.',
      'Enter the value to be replaced in the ‘From’ textbox.',
      'And the new value in the ‘To’ textbox.',
      'Click on the ‘+’ button to add more values to replace.',
      'Unwanted entries can be deleted by clicking on the ‘delete’ icon',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to replace the values',
      'You can view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of the Result section ',
    ],
  },
  Drop_Duplicates: {
    fields: [],
    title: 'Drop Duplicates',
    shortDesc: 'Removes duplicate records from the dataset',
    loadingText: '',
    Form: DropDuplicatesForm,
    noValidation: true,
    initialData: {
      Keep: 'first',
      Subset: [],
    },
    popupInfo: [
      'From the ‘subset’ dropdown, select the column(s) to be considered for identifying duplicate values ',
      'Select whether to keep the ‘First’ or ‘Last’ occurrence of the duplicated value(s)',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to drop the duplicate entries',
      'You can view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of the Result section',
    ],
  },
  Select_Column: {
    fields: [],
    title: 'Select Column',
    loadingText: '',
    shortDesc:
      'Creates a new dataset based on the selected columns in the specified order',
    Form: SelectColForm,
    noValidation: true,
    initialData: { specify: 'False', columns: [] },
    popupInfo: [
      'Select the names of the required columns. Note: the unselected columns will be dropped from the dataset ',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to drop the duplicate entries',
      'You can view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of the Result section',
    ],
  },
  LabelEncoding: {
    fields: [],
    title: 'Label Encoder',
    shortDesc:
      'Converts the labels of categorical columns into into numeric form to make it machine-readable',
    loadingText: '',
    Form: LabelEncoderForm,
    noValidation: true,
    popupInfo: [
      'From the list of features displayed, select the feature(s) that are to be label encoded ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to encode the selected categorical features',
      'You can view all data transformation/preparation activities that have been performed in the ‘Transformation’ tab of the Result section',
      'You can also view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of the Result section',
    ],
  },
  OneHot_Encoder: {
    fields: [],
    title: 'One Hot Encoder',
    loadingText: '',
    shortDesc: 'Encodes categorical features as one-hot numeric array(s)',
    Form: OneHotEncoderForm,
    popupInfo: [
      'From the list of features displayed, select the feature(s) that are to be one-hot encoded',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries ',
      'Click on ‘Execute’ to encode the selected categorical features',
      'You can view all data transformation/preparation activities that have been performed in the ‘Transformation’ tab of the Result section',
      'You can also view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of the Result section',
    ],
  },
  Binarizer: {
    fields: [],
    title: 'Binarizer',
    loadingText: '',
    shortDesc:
      'Binarize data (set feature values to 0 or 1) according to a threshold',
    Form: BinarizerForm,
    noValidation: true,
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select the column that is to be binarized, from the dropdown list',
      'Enter the threshold value for that column',
      'Click on the ' + ' button to add more columns to be binarized',
      'Unwanted entries can be deleted by clicking on the "delete" icon',
      'Provide a Short Description',
      'Click on "Save" to validate the entries',
      'Click on "Execute" to binarize the selected continuous features',
      'You can also view a sample, a brief overview, and statistics of the modified data in the Data tab of Result section',
    ],
  },
  Gradient_Boosting_Classifier: {
    fields: [],
    title: 'Gradient Boosting Classifier',
    shortDesc:
      'An ensemble learning method for classification that combine many weak learning models together to create a strong predictive model',
    loadingText: '',
    Form: GradientBoosterForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: 0.2, randomState: 0, shuffle: 'False' },
      model_param: {
        random_state: '0',
        learning_rate: '0.75',
        n_estimators: '20',
        max_features: '2',
        max_depth: '2',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model',
      'Click on the Default button to use the default parameter values',
      'Click on "Custom" to customize the parameter values',
      'From the "Train Features" dropdown list, select the column(s) to be used to train the model',
      'Select the target column(s) from the "Target" dropdown list',
      'Provide a short description',
      'Click on "Save" to validate the entries',
      'Click on "Execute" to build a Gradient Boosting Classifier',
      'To retrain the model with a new set of parameters, click on the "Gradient Boosting Classifier" icon from your project canvas, edit the inputs, "Save", then "Execute"',
    ],
  },
  AdaBoost_Classifier: {
    fields: [],
    title: 'Ada Boost Classifier',
    shortDesc:
      'Adaptive Boosting is an iterative ensemble method that combines multiple classifiers to increase the accuracy of classifiers',
    loadingText: '',
    Form: AdaBoosterForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: 0.2, randomState: 0, shuffle: 'False' },
      model_param: {
        random_state: '0',
        learning_rate: '1.0',
        n_estimators: '50',
      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and short description to the model',
      'Click on the Default button to use the default parameter values',
      'Click on Custom to customize the parameter values.',
      'From the Train Features dropdown list, select the column(s) to be used to train the model.',
      'Select the target column from the Target dropdown list.',
      'Provide a short description',
      'Click on Save to validate the entries',
      'Click on Execute to build a AdaBoost Classifier.',
      'To retrain the model with a new set of parameters, click on the "AdaBoost classifier" icon from your project canvas, edit the inputs, "Save", then "Execute"',
    ],
  },
  CatBoost_Classifier: {
    fields: [],
    title: 'Cat Boost Classifier',
    shortDesc:
      'A gradient boosting algorithm on Decision trees that builts decision trees consecutively with reduced loss compared to the previous trees.',
    loadingText: '',
    Form: CatBoosterForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: 0.2, random_state: 0, shuffle: 'False' },
      model_param: {
        learning_rate: '0.03',
        iterations: '1000',
        random_seed: '0',
        max_depth: '6',
        l2_leaf_reg: '3.0',

      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and short description to the model',
      'Click on the Default button to use the default parameter values',
      'Click on Custom to customize the parameter values.',
      'From the Train Features dropdown list, select the column(s) to be used to train the model.',
      'Select the target column from the Target dropdown list.',
      'Provide a short description',
      'Click on Save to validate the entries',
      'Click on Execute to build a CatBoost Classifier.',
      'To retrain the model with a new set of parameters, click on the "Cat Boost classifier" icon from your project canvas, edit the inputs, "Save", then "Execute"',
    ],

  },

  MinMax_Scaler: {
    fields: [],
    title: 'Minmax Scaler',
    shortDesc:
      'Normalizes the input features/variables into the specified range',
    loadingText: '',
    Form: MinmaxScalarForm,
    noValidation: true,
    initialData: {
      columns: [],
      target: '',
    },
    popupFullWidth: 'md',
    popupInfo: [
      'From the "Target" dropdown list, select the feature(s) that will be used to train your model. These features will not be scaled',
      'From the "Features" dropdown list, select the feature(s) to be scaled',
      'Provide a minimum and maximum value for the range',
      'Provide a short description',
      'Click on "Save" to validate the entries',
      'Click on "Execute" to scale the selected continuous features',
      'You can view all data transformation/preparation activities in the "Transformation" tab of Result section',
      'You can also view a sample, a brief overview, and statistics of the modified data in the Data tab of Result section',
    ],
  },
  Missing_Value_Treatment: {
    fields: [],
    title: 'Missing Values Treatment',
    loadingText: '',
    shortDesc:
      'Treating missing values in a column by either dropping the rows or filling up the values with other parameters',
    Form: MissingValuesForm,
    noValidation: true,
    popupInfo: [
      'Select a method from the ‘Method’ dropdown list.',
      'Select the column(s) for which missing value treatment is to be performed ',
      'Click on the ‘+’ button to add more methods and select columns to be treated',
      'Unwanted entries can be deleted by clicking on the ‘delete’ icon ',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to drop the duplicate entries',
      'You can view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of the Result section',
    ],
  },
  'Correlation Matrix': {
    fields: [],
    title: 'Correlation Matrix',
    loadingText: '',
    Form: CorrelationMatrixForm,
    requestParams: 'correlationMatrix',
    noValidation: true,
    popupInfo: [],
  },
  'Correlation Plot': {
    fields: [],
    title: 'Correlation Plot',
    loadingText: '',
    Form: CorrelationPlotForm,
    requestParams: 'correlationPlot',
    noValidation: true,
    initialData: { columns: [] },
    popupInfo: [],
  },
  'Scatter Plot': {
    fields: [],
    title: 'Scatter Plot',
    loadingText: '',
    shortDesc:
      'A plot that shows the extent of correlation, if any, between the values of observed quantities',
    Form: ScatterPlotForm,
    initialData: { specify: 'False', columns: [] },
    noValidation: true,
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select ‘Default’ option to generate plots with all combinations of the selected features ',
      'From the ‘Features’ dropdown list, select the columns(features) to plot the visuals with',
      'Select the ‘Custom’ option, if you’d like to specify the X and Y axes ',
      'Select the X and Y features from the dropdown list ',
      'Click on the ‘+’ button to add more X-Y column pairs ',
      'Unwanted entries can be deleted by clicking on the ‘delete’ icon ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to generate scatter plot(s) ',
      'You can view the generated plot(s) under ‘Scatter Plot’ in the ‘Data Visualization’ tab of the Result ',
    ],
  },
  StackedBarPlot: {
    fields: [],
    title: 'Stacked Bar Chart',
    loadingText: '',
    shortDesc:
      'The stacked bar graph extends the standard bar chart from looking at numeric values across one categorical variable to two.',
    Form: StackedBarChartForm,
    initialData: { wide_data: 'true', y: [], x: '' },
    noValidation: true,
    hidePreviousInputAPI: true,
    popupInfo: [
      'Choose the format of the data provided',
      'Select the columns to be plotted on the X-Axis',
      'Select the columns to be taken on the Y-Axis',
      'Enter a brief description for the plot.',
      'Click on Save to validate the inputs',
      'Click on Execute to Generate the plot',
      'The plot generatedwill be displayed in the result section, under Data Viz tab',
    ],
  },
  'Bar Plot': {
    fields: [],
    title: 'Bar Plot',
    shortDesc:
      'A plot to show how numerical datapoints in a dataset vary from each other',
    loadingText: '',
    Form: BarPlotForm,
    initialData: { specify: 'False', columns: [] },
    noValidation: true,
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select ‘Default’ option to generate plots with all combinations of the selected features ',
      'From the ‘Features’ dropdown list, select the columns(features) to plot the visuals with',
      'Select the ‘Custom’ option, if you’d like to specify the X and Y axes ',
      'Select the X and Y features from the dropdown list ',
      'Click on the ‘+’ button to add more X-Y column pairs ',
      'Unwanted entries can be deleted by clicking on the ‘delete’ icon ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to generate scatter plot(s) ',
      'You can view the generated plot(s) under Bar Plot’ in the ‘Data Visualization’ tab of the Result ',
    ],
  },
  'Line Plot': {
    fields: [],
    title: 'Line Plot',
    shortDesc:
      'A plot to track changes over different periods of time for one or more groups',
    loadingText: '',
    Form: LinePlotForm,
    initialData: { specify: 'False', columns: [] },
    noValidation: true,
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select ‘Default’ option to generate plots with all combinations of the selected features ',
      'From the ‘Features’ dropdown list, select the columns(features) to plot the visuals with',
      'Select the ‘Custom’ option, if you’d like to specify the X and Y axes ',
      'Select the X and Y features from the dropdown list ',
      'Click on the ‘+’ button to add more X-Y column pairs ',
      'Unwanted entries can be deleted by clicking on the ‘delete’ icon ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to generate scatter plot(s) ',
      'You can view the generated plot(s) under Line Plot’ in the ‘Data Visualization’ tab of the Result ',
    ],
  },
  Logistic_Regression: {
    fields: [],
    title: 'Logistic Regression',
    shortDesc:
      'A supervised algorithm that measures the relationship between a discrete dependent variable and one or more independent variables',
    loadingText: '',
    Form: LogisticRegressionForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      ModelDetail: {
        Name: '',
        Description: '',
      },
      split_param: { test_size: 0.2, random_state: 0, shuffle: 'False' },
      model_param: {
        C: 1.0,
        fit_intercept: 'True',
        max_iter: 100,
        multi_class: 'auto',
        penalty: 'l2',
        solver: 'lbfgs',
        tol: '0.001',
        verbose: '0',
        warm_start: 'False',
      },
      target: '',
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model ',
      'Click on the ‘Default’ button to use the default parameter values ',
      'Click on ‘Custom’ to customize the parameter values ',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the model ',
      'Select the target column(s) from the ‘Target’ dropdown list ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to build a Logistic Regression model ',
      'Click on ‘Execute’ to build a Logistic Regression model ',
      'To retrain the model with a new set of parameters, click on the ‘Logistic Regression’ icon from your project canvas, edit the inputs, ‘Save’, then ‘Execute’ ',
    ],
  },
  K_Nearest_Neighbors: {
    fields: [],
    title: 'K Nearest Neighbors',
    shortDesc:
      'A non-parametric supervised learning method that classifies objects based on a plurality vote of its neighbors',
    loadingText: '',
    Form: KNNForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      model_param: {
        n_neighbors: '5',
        weights: 'uniform',
        algorithm: 'auto',
        leaf_size: '30',
        p: '2',
        n_jobs: '1',
      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model ',
      'Click on the ‘Default’ button to use the default parameter values ',
      'Click on ‘Custom’ to customize the parameter values ',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the model ',
      'Select the target column(s) from the ‘Target’ dropdown list ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to build a K-Nearest Neighbors model ',
      'Click on ‘Execute’ to build a K-Nearest Neighbors model ',
      'To retrain the model with a new set of parameters, click on the ‘K-Nearest Neighbors’ icon from your project canvas, edit the inputs, ‘Save’, then ‘Execute’ ',
    ],
  },
  'Data Shapley': {
    title: 'Data Shapley',
    shortDesc:
      'An equitable data valuation technique that scores datapoints in a a dataset with positive/negative Shapley Values, to indicate its worth. Note: Data Shapley can be performed only on classification models',
    loadingText: '',
    Form: DataShapleyForm,
    initialData: null,
    popupFullWidth: 'md',
    trigger: 'Async',
    popupInfo: [
      'From the dropdowns, select the model type, model, model name and model version to be optimized ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to start generating Data Shapley values for your dataset ',
      'Calculation of Shapley values may take some time',
      'To check whether Shapley values have been generated, select the model type, model, model name and model version from the dropdowns ',
      'Once Shapley values have been generated, enter a ‘Threshold Value’. This represents what percent of data having low Shapley values will be removed before re-training the selected model ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Save’ to validate the entries  ',
      'You can view the optimized model metrics, along with the original metrics, for the re-trained model in the ‘Model’ tab of the Result section ',
      'You can try multiple threshold values to identify which one gives the best performance.',
      'Note: Shapley values can be calculated for only one model at a time',
    ],
  },
  Random_Forest: {
    fields: [],
    title: 'Random Forest',
    shortDesc:
      'An ensemble learning method for classification that operates by constructing and combining multiple decision tree',
    loadingText: '',
    Form: RandomForestForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      model_param: {
        min_samples_split: '2',
        min_samples_leaf: '1',
        random_states: 'None',
        n_jobs: 'None',
        n_estimators: '100',
        max_leaf_nodes: 'None',
        bootstrap: 'True',
        warm_start: 'False',
        criterion: 'gini',
      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model ',
      'Click on the ‘Default’ button to use the default parameter values ',
      'Click on ‘Custom’ to customize the parameter values ',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the model ',
      'Select the target column(s) from the ‘Target’ dropdown list ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to build a Random Forest model ',
      'Click on ‘Execute’ to build a Random Forest model ',
      'To retrain the model with a new set of parameters, click on the ‘Random Forest’ icon from your project canvas, edit the inputs, ‘Save’, then ‘Execute’ ',
    ],
  },
  RandomForest_Regression: {
    fields: [],
    title: 'Random Forest Regression',
    shortDesc: '',
    loadingText: '',
    Form: RandomForestRegressionForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      model_param: {
        n_estimators: '100',
        criterion: 'squared_error',
        max_depth: 'None',
        min_samples_split: '2',
        min_samples_leaf: '1',
        max_leaf_nodes: 'None',
        bootstrap: 'True',
        random_states: 'None',
        warm_start: 'False',
      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
      target: '',
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and short description to the model',
      'Click on the Default button to use the default parameter values',
      'Click on Custom to customize the parameter values.',
      'From the Train Features dropdown list, select the column(s) to be used to train the model.',
      'Select the target column from the Target dropdown list.',
      'Provide a short description',
      'Click on Save to validate the entries',
      'Click on Execute to build a Random Forest Regression model.',
      'To retrain the model with a new set of parameters, click on the Random Forest Regression icon from your project canvas, edit the inputs, Save, then Execute',
    ],
  },
  Light_GBM_Classifier: {
    fields: [],
    title: 'Light GBM Classifier',
    shortDesc:
      'Light Gradient Boosting Machine Classifier : LightGBM Classifier is a gradient boosting framework based on decision trees to increases the efficiency of the model and reduces memory usage',
    loadingText: '',
    Form: LightGBMClassifierForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: 0.2, random_state: 0, shuffle: 'False' },
      model_param: {
        num_leaves: '31',
        max_depth: '-1',
        num_iterations: '100',
        max_bin: '255',
        feature_fraction: '1.0',
        bagging_fraction: '1.0',
        min_data_in_bin: '3',
      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
      target: '',
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model.',
      'Click on the Default button to use the default parameter values.',
      'Click on Custom to customize the parameter values',
      'From the Train Features dropdown list, select the column(s) to be used to train the model.',
      'Select the target column(s) from the Target dropdown list.',
      'Provide a short description',
      'Click on Save to validate the entries.',
      'Click on Execute to build a Light Gradient Boosting Machine Classifier.',
      'To retrain the model with a new set of parameters, click on the Light Gradient Boosting Machine Classifier icon from your project canvas, edit the inputs, Save, then Execute.',
    ],
  },
  XGBoost_Classifier: {
    fields: [],
    title: 'XGBoost Classifier',
    shortDesc:
      'Extreme Gradient Boosting classifier is is a scalable, distributed gradient-boosted decision tree (GBDT) machine learning model for classification problem.',
    loadingText: '',
    Form: XGBoostClassifierForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: 0.2, random_state: 0, shuffle: 'False' },
      model_param: {
        booster: 'gbtree',
        gamma: '0',
        max_depth: '6',
        learning_rate: '0.3',
        n_estimators: '100',
        colsample_bytree: '1.0',
        subsample: '1.0',
      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
      target: '',
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model.',
      'Click on the Default button to use the default parameter values.',
      'Click on Custom to customize the parameter values',
      'From the Train Features dropdown list, select the column(s) to be used to train the model.',
      'Select the target column(s) from the Target dropdown list.',
      'Provide a short description',
      'Click on Save to validate the entries.',
      'Click on Execute to build a XGBoost Classifier.',
      'To retrain the model with a new set of parameters, click on the XGBoost classifier icon from your project canvas, edit the inputs, Save, then Execute.',
    ],
  },
  Decision_Tree: {
    fields: [],
    title: 'Decision Tree',
    shortDesc:
      'A supervised algorithm that makes use of classification tree models to perform classification on target variables that can take a discrete set of values',
    loadingText: '',
    Form: DecisionTreeForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      ModelDetail: {
        Name: '',
        Description: '',
      },
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      model_param: {
        max_depth: 'None',
        min_samples_split: '2',
        min_samples_leaf: '1',
        ccp_alpha: '0.0',
        max_leaf_nodes: 'None',
        criterion: 'gini',
        splitter: 'best',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model ',
      'Click on the ‘Default’ button to use the default parameter values ',
      'Click on ‘Custom’ to customize the parameter values ',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the model ',
      'Select the target column(s) from the ‘Target’ dropdown list ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to build a Decision Tree model ',
      'Click on ‘Execute’ to build a Decision Tree model ',
      'To retrain the model with a new set of parameters, click on the ‘Decision Tree’ icon from your project canvas, edit the inputs, ‘Save’, then ‘Execute’ ',
    ],
  },
  Naive_Bayes: {
    fields: [],
    title: 'Gaussian Naive Bayes',
    shortDesc:
      'A probabilistic classifier that makes use of Bayes theorem to produce strong (naive) independence assumptions between the features',
    loadingText: '',
    Form: GaussianNaiveBayesForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      model_detail: {},
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      model_param: {
        var_smoothing: '1e-9',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model ',
      'Click on the ‘Default’ button to use the default parameter values ',
      'Click on ‘Custom’ to customize the parameter values ',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the model ',
      'Select the target column(s) from the ‘Target’ dropdown list ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to build a Gaussian Naïve Bayes model ',
      'Click on ‘Execute’ to build a Gaussian Naïve Bayes model ',
      'To retrain the model with a new set of parameters, click on the ‘Gaussian Naïve Bayes’ icon from your project canvas, edit the inputs, ‘Save’, then ‘Execute’ ',
    ],
  },
  Linear_Regression: {
    fields: [],
    title: 'Linear Regression',
    shortDesc:
      'An algorithm that models the relationship between a scalar dependent variable and one or more or independent variables using linear predictor functions whose unknown model parameters are estimated from the data',
    loadingText: '',
    Form: LinearRegressionForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      model_param: {
        fit_intercept: 'True',
        positive: 'False',
      },
      ModelDetail: {
        Name: '',
        Description: '',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model',
      'Click on the ‘Default’ button to use the default parameter values',
      'Click on ‘Custom’ to customize the parameter values',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the model',
      ' Select the target column(s) from the ‘Target’ dropdown list',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to build a Linear Regression model',
      'You can view the model metrics for the trained model in the ‘Model’ tab of the Result section',
      'To retrain the model with a new set of parameters, click on the ‘Linear Regression’ icon from your project canvas, edit the inputs, ‘Save’, then ‘Execute',
    ],
  },
  DecisionTree_Regression: {
    fields: [],
    title: 'Decision Tree Regression',
    shortDesc:
      'A supervised algorithm that makes use of regression tree models to perform prediction on target variables that are typically continuous values',
    loadingText: '',
    Form: DecisionTreeRegressionForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      ModelDetail: {
        Name: '',
        Description: '',
      },
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      model_param: {
        max_depth: 'None',
        min_samples_split: '2',
        min_samples_leaf: '1',
        ccp_alpha: '0.0',
        max_leaf_nodes: 'None',
        criterion: 'squared_error',
        splitter: 'best',
        random_states: 'None',
      },
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and short description to the model ',
      'Click on the `Default` button to use the default parameter values',
      'Click on `Custom`` to customize the parameter values. ',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the model ',
      'Select the target column from the `Target` dropdown list. ',
      'Provide a short description ',
      'Click on `Save` to validate the entries',
      'Click on `Execute` to build a Decision Tree Regression model.',
      'To retrain the model with a new set of parameters, click on the `Decision Tree Regression` icon from your project canvas, edit the inputs, `Save`, then `Execute`',
    ],
  },
  AutoML_Classifier: {
    fields: [],
    title: 'AutoML',
    shortDesc:
      'Builds and compares the performance of different classification models without any parameter tuning',
    loadingText: '',
    Form: AutoMLForm,
    initialData: {
      columns: [],
      split_param: { test_size: '0.2', random_state: '0', shuffle: 'False' },
      target: '',
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model',
      'Change the split parameters to take custom values.',
      'From the ‘Train Features’ dropdown list, select the column(s) to be used to train the models in AutoML',
      'Select the target column(s) from the ‘Target’ dropdown list',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries ',
      'Click on ‘Execute’ to build a AutoML Classifier model',
      'You can view the model comparison table based on different metrics in the ‘Model’ tab of the Result section',
    ],
  },
  Classification: {
    title: 'Model Testing',
    shortDesc: 'Visualize the prediction/classification results of your models',
    loadingText: '',
    Form: MTClassification,
    initialData: null,
    popupFullWidth: 'md',
    uploadFileApi: 'ModelTesting/mtfilevalidate',
    // initialData: {
    //  mode: 'SampleTesting'
    // }
    popupInfo: [
      'From the dropdowns, select the model type, model, model name and model version to be tested ',
      'Select ‘Sample Testing’ to test on a set of sample values taken randomly from the trained datase',
      'Select ‘File Testing’ to test the model using a custom test file. You’ll see a list of column names that the test file should contain. ',
      'Download the template provided and add necessary test values if you don’t have a test file handy (for File Testing) ',
      'Upload the test file (for File Testing) ',
      'Provide a short description ',
      'Click on ‘Save’ to validate the entries  ',
      'Click on ‘Execute’ to generate a prediction for the samples ',
      'You can view the predicted results for the trained model under the ‘Random Sample’ tab (for Random Testing), and ‘File Upload’ tab (for File testing) in the Model Results section',
    ],
  },
  Standard_Scaler: {
    title: 'Standard Scaler',
    shortDesc: 'Scales each feature/variable to unit variance',
    loadingText: '',
    Form: StandardScalerForm,
    noValidation: true,
    initialData: {
      // copy: "False",
      with_mean: 'False',
      with_std: 'False',
      target: [],
      columns: [],
    },
    popupInfo: [
      'From the ‘Target’ dropdown list, select the feature(s) that will be used to train your model. These features will not be scaled ',
      'From the ‘Features’ dropdown list, select the feature(s) to be scaled ',
      'Choose the appropriate option to scale your data',
      'Your data can either be centered before scaling, or scale it to unit variance ',
      'Provide a short description',
      'Click on ‘Save’ to validate the entries',
      'Click on ‘Execute’ to scale the selected continuous features ',
      'You can view all data transformation/preparation activities in the ‘Transformation’ tab of Result section',
      'You can also view a sample, a brief overview, and statistics of the modified data in the ‘Data’ tab of Result section ',
    ],
  },
  Robust_Scaler: {
    title: 'Robust Scaler',
    shortDesc: 'Scale features using statistics that are robust to outliers.',
    loadingText: '',
    Form: RobustScalerForm,
    noValidation: true,
    initialData: {
      with_centering: 'True',
      with_scaling: 'True',
      unit_variance: 'False',
      lower_quantile_range: '25',
      upper_quantile_range: '75',
      target: [],
      columns: [],
    },
    popupInfo: [
      'From the Target dropdown list, select the feature(s) that will be used to train your model. These features will not be scaled.',
      'From the Features dropdown list, select the feature(s) to be scaled.',
      'Select the checkboxes as per requirement.',
      'Provide the lower and upper quantile range.',
      'Provide a short description.',
      'Click on Save to validate the entries.',
      'Click on Execute to scale the selected continuous features.',
      'You can view all data transformation/preparation activities in the Transformation tab of Result section.',
      'You can also view a sample, a brief overview, and statistics of the modified data in the Data tab of Result section.',
    ],
  },
  ctgan: {
    title: 'CTGAN',
    loadingText: '',
    Form: CTGANForm,
    shortDesc:
      'A GAN-based method to model tabular data distribution and sample rows from the distribution',
    initialData: { Merge: 'False' },
    hidePreviousInputAPI: true,
    popupInfo: [
      'Enter the number of synthetic data rows that you would like to generate. Note: a maximum of 200% of the count of rows of the original dataset can be generated',
      'Provide a short description',
      'Click on ‘Save’ to validate the row count',
      'Click on ‘Execute’ to generate synthetic data',
      'You can view  sample, brief overview, and statistics of the synthetic data in the ‘Data’ tab of the Result section',
      'Click on the ‘CTGAN’ icon on your project canvas and select ‘Synthetic + Original Dataset’ to merge the original data with synthetic data.',
      'If you’d like to proceed with the synthetic data alone, you can do so by selecting ‘Synthetic Dataset’ and save. Then click on ‘Execute’.',
      'To use the original dataset alone, you can proceed without clicking on the icon on your project canvas.',
    ],
  },
  Arithmetic_Operation: {
    title: 'Arithmetic Operation',
    loadingText: '',
    shortDesc:
      'Creates new columns by performing arithmetic operations on the specified columns',
    Form: ArithmeticOperationForm,
    initialData: { method: {} },
    popupFullWidth: 'md',
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select the math operator from ‘method’ drop down list',
      'Select columns to be operated from ‘From’ drop down list',
      'Type the name of the new column in ‘NewName’ textbox',
      'Click on the ‘+’ button to add more operators',
      'Unwanted entries can be deleted by clicking on the delete icon',
      'Provide a short description in the description text box',
      'Click on save to validate the entries',
      'Click on Execute to perform the operations',
      'You can view a sample, a brief overview, and the statistics of the modified data in the data tab of the result section',
    ],
  },
  Sort_Column: {
    fields: [],
    title: 'Sort Column',
    shortDesc:
      'Sorts the dataset based on the specified columns and sort order',
    loadingText: '',
    Form: SortColumnForm,
    noValidation: true,
    initialData: { column: [], ascending: 'False' },
    popupInfo: [
      'Select the columns on which the data needs to be sorted from the Columns dropdown list',
      'Select the checkbox Ascending for the data to be sorted in Ascending order. The data will be sorted in Descending order by default.',
      'Provide a short description',
      'Click on Save to validate the entries',
      'Click on Execute to sort the data based on the selected columns',
      'You can view a sample, a brief overview, and statistics of the modified data in the Data tab of the result section',
    ],
  },
  choroplethMaps: {
    title: 'Choropleth maps',
    shortDesc: 'A plot to visualize data across geospatial areas',
    loadingText: '',
    Form: ChoroplethMapsForm,
    initialData: {
      mapping_feature: '',
      gradient_feature: '',
      hover_name: '',
      hover_data: [],
      title: '',
    },
    popupFullWidth: 'md',
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select a mapping feature that has the names of States in camel case.',
      'From the dropdown select a gradient feature, based on which the plot mask will be shaded.',
      'Select the Hover name, for the title of the tooltips.',
      'From the dropdown select a set of features to be displayed in the tooltip.',
      'Enter a title for the plot',
      'Provide a short description of the plot',
      'Click on "Save" to validate the inputs',
      'click on "Execute" to upload it',
      'You may "Reset" your inputs but the generated plots will not be deleted.',
    ],
  },
  Pie_Plot: {
    title: 'Pie Plot',
    loadingText: '',
    shortDesc: 'A plot to analyse the distribution of the data',
    Form: PiePlotForm,
    initialData: { column: '' },
    hidePreviousInputAPI: true,
    popupInfo: [
      'Select a column to generate pie plot.',
      'Click on save to validate the data.',
      'Click on execute to generate the pie plot for the selected column.',
      'You may reset your selections but the generated plot will not be deleted.',
    ],
  },
  Artificial_Neural_Network: {
    title: 'Artificial Neural Network',
    loadingText: '',
    shortDesc: 'A neural network to classify an observation as belonging to some discrete class as a function of the inputs.',
    Form: ANNForm,
    initialData: {
      columns: [],
      target: '',
      hiddenLayer: {},
      outputLayer: {
        actfunc: '',
      },
      split_param: {
        test_size: '0.2',
        random_state: '0',
        shuffle: 'False',
      },
      compilation: {
        loss: '',
        epochs: '',
        batchSize: '',
        optimizer: '',
      },
    },
    popupInfo: [
      'Provide a name and short description to the model',
      'Provide the split parameter values',
      'From the Train Features dropdown list, select the column(s) to be used to train the model.',
      'Select the target column from the Target dropdown list.',
      'Provide the values for the Hidden Layers and Output Layer.',
      'Click on Define My Model to generate a diagram of the neural network.',
      'Provide the values for Model Compilation.',
      'Provide a short description.',
      'Click on Save to validate the entries.',
      'Click on Execute to build an Artificial Neural Network model.',
      'To retrain the model with a new set of parameters, click on the Artificial Neural Network icon from your project canvas, edit the inputs, Save, then Execute.',
    ],
    popupFullWidth: 'lg',
    trigger: 'Async',
  },
  PCA: {
    title: 'PCA',
    loadingText: '',
    shortDesc: 'This process computes the principal components and uses them to perform a change of basis on the data',
    Form: PCAForm,
    initialData: {
      "cols": [],
      "col_names": [],
      "n": '',
      replace: 'False'
    },
    popupInfo: [
      'select the features to perform pca on',
      'Select an appropriate n value',
      'Click on generate to view the composition of the components', 'Select either to replace the existing dataset with the principal components or to append the components to the existing dataset.',
      'Select either to replace the existing dataset with the principal components or to append the components to the existing dataset.',
      'Once satisfied with the compostions, you can enter the names for columns in the text fields generated below.',
      'Enter an appropriate description for the action',
      'Click on save, then execute to perform the action'
    ],
    popupFullWidth: 'lg',
  },
  svc: {
    fields: [],
    title: 'Support vector classification',
    shortDesc: 'Create the best line or decision boundary that can segregate n-dimensional space into classes so that we can easily put the new data point in the correct category in the future',
    loadingText: '',
    Form: SVCForm,
    trigger: 'Async',
    initialData: {
      columns: [],
      ModelDetail: {
        Name: '',
        Description: ''
      },
      split_param: {
        test_size: 0.2,
        random_state: 0,
        shuffle: 'False'
      },
      model_param: {
        C: 1.0, kernel: 'rbf', degree: '3', probability: 'True', max_iter: '-1', shrinking: 'True', decision_function_shape: 'ovr', break_ties: 'False'
      },
      target: ''
    },
    popupFullWidth: 'md',
    popupInfo: [
      'Provide a name and a short description for the model.',
      'Click on the Default button to use the default parameter values.',
      'Click on Custom to customize the parameter values',
      'From the Train Features dropdown list, select the column(s) to be used to train the model.',
      'Select the target column(s) from the Target dropdown list.',
      'Provide a short description',
      'Click on Save to validate the entries.',
      'Click on Execute to build a Support Vector Classification model.',
      'To retrain the model with a new set of parameters, click on the "Support Vector Classification" icon from your project canvas, edit the inputs, Save, then Execute.',
    ],
  },
  Datatype_Converter: {
    title: 'Datatype Converter',
    shortDesc: 'Converts the column(s) from one datatype to another',
    loadingText: '',
    Form: DatatypeConverterForm,
    initialData: { column: '' },
    // hidePreviousInputAPI: true,
    popupInfo: [
      'Select the column that needs to be converted, From the Column dropdown list',
      'Select the datatype that is to be converted, From the Datatype dropdown list',
      'Click on the + button to add more columns to be converted',
      'Unwanted entries can be deleted by clicking on delete icon',
      'Provide a short description for conversion',
      'Click on Save to validate the entries',
      'Click on Execute to convert the columns',
      'Fill the columns if it contains any NULL values and repeat the steps',
      'You can view a sample, a brief overview, and statistics of the modified data in the Data tab of the Result section.',
    ],
  },
  default: {
    fields: [],
    Form: null,
    Result: null,
    popupInfo: [],
  },
};

export const stageIds = {
  'Data Input': 'Data_Input',
  'Data Transformation': 'Data_Transformation',
  'Data Visualization': 'Data_Visualization',
  'Model Building': 'Model_Building',
  'Model Optimization': 'Model_Optimization',
  'Data Preparation': 'Data_Preparation',
  'Model Testing': 'Model_Testing',
  'Data Generation': 'Data_Generation',
};
export const stageLabels = {
  Data_Input: 'Data Input',
  Data_Transformation: 'Data Transformation',
  Data_Visualization: 'Data Visualization',
  Model_Building: 'Model Building',
  Model_Optimization: 'Model Optimization',
  Data_Preparation: 'Data Preparation',
  Model_Testing: 'Model Testing',
  Data_Generation: 'Data Generation',
};
export const featureIds = {
  'File Upload': 'File_Upload',
  'Column Operators': 'Column_Operators',
  'Cloud Upload': 'Cloud_Upload',
  Correlation: 'Correlation',
  Distribution: 'Distribution',
  'Continuous Features': 'Continuous_Features',
  Regression: 'Regression',
  'Categorical Features': 'Categorical_Features',
  Classification: 'Classification',
  SAIL: 'SAIL',
  'Synthetic Data': 'Synthetic_Data',
  GeoSpatial: 'GeoSpatial',
  AutoML: 'AutoML',
  'Deep Learning': 'Deep_learning',
  'Dimensionality Reduction': 'Dimensionality_Reduction'
};
export const featureLabels = {
  File_Upload: 'File Upload',
  Column_Operators: 'Column Operators',
  Cloud_Upload: 'Cloud Upload',
  Correlation: 'Correlation',
  Continuous_Features: 'Continuous Features',
  Categorical_Features: 'Categorical Features',
  Classification: 'Classification',
  Regression: 'Regression',
  SAIL: 'SAIL',
  Synthetic_Data: 'Synthetic Data',
  GeoSpatial: 'GeoSpatial',
  AutoML: 'AutoML',
  Distribution: 'Distribution',
  Deep_learning: 'Deep Learning',
  Dimensionality_Reduction: "Dimensionality Reduction"
};
export const subFeatureIds = {
  CSV: 'CSV_Input',
  'Rename Column': 'Rename_Column',
  'Drop Duplicates': 'Drop_Duplicates',
  'Correlation Matrix': 'Correlation Matrix',
  'Correlation Plot': 'Correlation Plot',
  'Scatter Plot': 'Scatter Plot',
  'Stacked Bar Chart': 'StackedBarPlot',
  'Bar Plot': 'Bar Plot',
  'Line Plot': 'Line Plot',
  'Logistic Regression': 'Logistic_Regression',
  'Linear Regression': 'Linear_Regression',
  'Decision Tree': 'Decision_Tree',
  'Decision Tree Regression': 'DecisionTree_Regression',
  'Gaussian Naive Bayes': 'Naive_Bayes',
  'Random Forest': 'Random_Forest',
  'Random Forest Regression': 'RandomForest_Regression',
  'Light GBM Classifier': 'Light_GBM_Classifier',
  'XGBoost Classifier': 'XGBoost_Classifier',
  'Standard Scaler': 'Standard_Scaler',
  'Select Column': 'Select_Column',
  'Data Shapley': 'Data Shapley',
  'Missing Values Treatment': 'Missing_Value_Treatment',
  'Model Testing': 'Classification',
  'Label Encoder': 'LabelEncoding',
  'One Hot Encoder': 'OneHot_Encoder',
  'K Nearest Neighbors': 'K_Nearest_Neighbors',
  'Replace Value': 'Replace_Value',
  CTGAN: 'ctgan',
  'Arithmetic Operation': 'Arithmetic_Operation',
  'Sort Column': 'Sort_Column',
  'Choropleth Maps': 'choroplethMaps',
  'AutoML Classifier': 'AutoML_Classifier',
  'Pie Plot': 'Pie_Plot',
  'MinMax Scaler': 'MinMax_Scaler',
  'Datatype Converter': 'Datatype_Converter',
  Binarizer: 'Binarizer',
  'Artificial Neural Network': 'Artificial_Neural_Network',
  'PCA': 'PCA',
  'Gradient Boosting Classifier': 'Gradient_Boosting_Classifier',
  AdaBoost: 'AdaBoost_Classifier',
  'Support vector classification': 'svc',
  'CatBoost': 'CatBoost_Classifier',
  'Robust Scaler': 'Robust_Scaler',
};
export const subFeatureLables = {
  CSV_Input: 'CSV',
  Rename_Column: 'Rename Column',
  Drop_Duplicates: 'Drop Duplicates',
  'Correlation Matrix': 'Correlation Matrix',
  'Correlation Plot': 'Correlation Plot',
  'Scatter Plot': 'Scatter Plot',
  'StackedBarPlot': 'Stacked Bar Chart',
  'Bar Plot': 'Bar Plot',
  'Line Plot': 'Line Plot',
  Logistic_Regression: 'Logistic Regression',
  Linear_Regression: 'Linear Regression',
  Decision_Tree: 'Decision Tree',
  DecisionTree_Regression: 'Decision Tree Regression',
  Naive_Bayes: 'Gaussian Naive Bayes',
  Random_Forest: 'Random Forest',
  RandomForest_Regression: 'Random Forest Regression',
  Light_GBM_Classifier: 'Light GBM Classifier',
  Standard_Scaler: 'Standard Scaler',
  Select_Column: 'Select Column',
  'Data Shapley': 'Data Shapley',
  Missing_Value_Treatment: 'Missing Values Treatment',
  Classification: 'Model Testing',
  LabelEncoding: 'Label Encoder',
  K_Nearest_Neighbors: 'K Nearest Neighbors',
  ctgan: 'CTGAN',
  Replace_Value: 'Replace Value',
  Arithmetic_Operation: 'Arithmetic Operation',
  Sort_Column: 'Sort Column',
  choroplethMaps: 'Choropleth Maps',
  AutoML_Classifier: 'AutoML Classifier',
  Pie_Plot: 'Pie Plot',
  MinMax_Scaler: 'MinMax Scaler',
  Datatype_Converter: 'Datatype Converter',
  OneHot_Encoder: 'One Hot Encoder',
  Binarizer: 'Binarizer',
  Artificial_Neural_Network: 'Artificial Neural Network',
  XGBoost_Classifier: 'XGBoost Classifier',
  'PCA': 'PCA',
  Gradient_Boosting_Classifier: 'Gradient Boosting Classifier',
  AdaBoost_Classifier: 'AdaBoost',
  'svc': 'Support vector classification',
  'CatBoost_Classifier': 'CatBoost',
  'Robust_Scaler': 'Robust Scaler',
};

export const validationData = {
  csvInput: {
    info: 'Please upload only .csv format',
    formats: ['csv'],
  },
};
const cheveronData = {
  'Data Input': {
    key: 'Data_Input',
    features: [
      {
        label: 'File Upload',
        key: '',
        subList: ['CSV', 'Excel *'],
      },
      {
        label: 'Cloud Sources',
        subList: [
          'AWS S3 *',
          'Amazon HealthLake *',
          'Azure DataLake Storage*',
          'Google Storage *',
        ],
      },
      {
        label: 'Other Sources',
        subList: ['MySQL *', 'PostgreSQL *', 'RestAPI *'],
      },
    ],
  },
  'Data Generation': {
    key: 'Data_transformation',
    features: [
      {
        label: 'Synthetic Data',
        key: 'Synthetic_Data',
        subList: [
          'CTGAN',
          'Gaussian Mixture Model*',
          'Kernel Density Estimation *',
        ],
      },
    ],
  },
  'Data Transformation': {
    key: 'Data_transformation',
    features: [
      {
        label: 'Column Operators',
        key: '',
        subList: [
          'Rename Column',
          'Drop Duplicates',
          'Select Column',
          'Missing Values Treatment',
          'Replace Value',
          'Arithmetic Operation',
          'Sort Column',
          'Datatype Converter',
        ],
      },
      {
        label: 'Row Transformations',
        key: '',
        subList: ['Data Filter *', 'Group By *', 'Top N by Group *'],
      },
    ],
  },
  'Data Visualization': {
    key: 'Data_Visualization',
    features: [
      {
        label: 'Correlation',
        key: 'Correlation',
        subList: ['Scatter Plot', 'Bar Plot', 'Line Plot'],
      },
      {
        label: 'GeoSpatial',
        key: '',
        subList: ['Choropleth Maps'],
      },
      {
        label: 'Distribution',
        key: 'Distribution',
        subList: ['Pie Plot', 'Venn Diagram *', 'Stacked Bar Chart'],
      },
      {
        label: 'Miscellaneous',
        key: 'Distribution',
        subList: ['cPCA *', 'Knowledge Graph *', 'AutoViz *'],
      },
    ],
  },
  'Data Preparation': {
    key: 'Data_Preparation',
    features: [
      {
        label: 'Continuous Features',
        key: 'Continuous_Features',
        subList: [
          'Standard Scaler',
          'MinMax Scaler',
          'Robust Scaler',
          'MaxAbs Scaler *',
          'Binarizer',
          'Multi-label Binarizer *',
        ],
      },
      {
        label: 'Categorical Features',
        key: 'Categorical_Features',
        subList: ['Label Encoder', 'One Hot Encoder'],
      },
      {
        label: 'Dimensionality Reduction',
        key: 'Dimensionality_Reduction',
        subList: ['PCA'],
      },
    ],
  },
  'Model Building': {
    key: 'Model_Building',
    features: [
      {
        label: 'Classification',
        key: '',
        subList: [
          'Logistic Regression',
          'Random Forest',
          'Decision Tree',
          'K Nearest Neighbors',
          'Gaussian Naive Bayes',
          'XGBoost Classifier',
          'Light GBM Classifier',
          'Support vector classification',
          'Gradient Boosting Classifier',
          'AdaBoost',
          'CatBoost',
        ],
      },
      {
        label: 'Regression',
        key: '',
        subList: [
          'Linear Regression',
          'Decision Tree Regression',
          'Random Forest Regression',
          'Gradient Boosting Regressor *',
          'AdaBoost Regressor *',
          'KNeighbors Regressor *',
          'Support Vector Regression *',
        ],
      },
      {
        label: 'AutoML',
        key: '',
        subList: ['AutoML Classifier'],
      },
      {
        label: 'Deep Learning',
        key: '',
        subList: ['Artificial Neural Network'],
      },
      {
        label: 'Clustering',
        key: '',
        subList: ['K- means clustering *'],
      },
    ],
  },
  'Model Optimization': {
    key: 'Model_Optimization',
    features: [
      {
        label: 'SAIL',
        key: 'SAIL',
        subList: ['Data Shapley', 'MultiAccuracy *', 'Neuron Shapley *'],
      },
      {
        label: 'Traditional',
        key: 'Traditional',
        subList: ['GridSearchCV *'],
      },
    ],
  },
  'Model Testing': {
    key: 'Model_Testing',
    features: [
      {
        label: 'Classification',
        key: 'Classification',
        subList: ['Model Testing'],
      },
    ],
  },
  // "Save Model": {},
};
export default cheveronData;

export const API = {
  myWorkSpace: {
    requestParams: ['UserID'],
    endpoint: 'UI/myWorkspace',
  },
  createProject: {
    requestParams: ['UserID'],
    endpoint: 'UI/newProject',
  },
};

export const cssValues = {
  fontSize: '12px',
  infoBgcolor: '#053557',
  textColor: '#f4f1de',
  selectedTextColor: '#16F4D0',
  CheveronBg: '#074674',
};
export const vizFeatureIds = {
  'Bar Plot': 'Correlation',
  'Correlation Matrix': 'Correlation',
  'Correlation Plot': 'Correlation',
  'Line Plot': 'Correlation',
  Pie_Plot: 'Distribution',
  'Scatter Plot': 'Correlation',
  'StackedBarPlot': 'Distribution',
  choroplethMaps: 'GeoSpatial',
};

export const formData = {
  createProject: {
    fields: [
      {
        label: 'Project Name',
        name: 'projName',
        type: 'text',
        required: true,
      },
      {
        label: 'Domain',
        name: 'domain',
        type: 'text',
        required: true,
      },
      {
        label: 'Sub Domain',
        name: 'subDomain',
        type: 'select',
        options: [{ label: 'a', value: 'b' }],
        required: true,
      },
      {
        label: 'DataSet Source',
        name: 'datasetSource',
        type: 'text',
        required: true,
      },
      {
        label: 'Description',
        name: 'description',
        type: 'multiline',
        rows: 3,
        required: true,
      },
    ],
    initialData: {
      projName: '',
      domain: '',
      description: '',
      subDomain: '',
      datasetSource: '',
    },
  },
};
