import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import tilemap from 'highcharts/modules/tilemap';
import Highcharts from 'highcharts/highmaps';
import drilldown from 'highcharts/modules/drilldown';
import * as d3 from 'd3';
import { StyledButton } from "../../styles/common";
import data from '../../assets/files/tileData_Thercls.csv';
import data0 from '../../assets/files/drillData_thercls.csv';

tilemap(Highcharts);
drilldown(Highcharts);

class PatientTherapeuticCode extends React.Component {
  constructor(props) {
    super(props);

    this.data1 = [];
    this.rawData0 = [];
    this.m = [];
    this.cx = [];
    this.wq = 0;
    this.datavalue = [];
    this.v = [];
    this.tr = [];

    this.createMapData = this.createMapData.bind(this);
    this.createMap = this.createMap.bind(this);
    this.parsecsvfile = this.parsecsvfile.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    d3.csv(data)
      .then((data) => {
        d3.csv(data0)
          .then((data0) => {
            this.rawData0 = data0;
            this.createMapData(data);
            this.parsecsvfile(data);
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw (err);
      });
  }

  createMapData(data) {
    for (let i = 0; i < data.length; i++) {
      this.data1.push({
        'hc-a2': data[i][0],
        name: data[i][1],
        region: data[i][2],
        x: parseInt(data[i][3], 10),
        y: parseInt(data[i][4], 10),
        value: parseInt(data[i][5], 10),
        drilldown: parseInt(data[i][6], 10),
      });
    }
  }

  parsecsvfile(data) {
    for (const i in data) {
      this.cx.push(data[i][1]);
    }
    for (let k = 0; k < data.length; k++) {
      this.wq = parseInt(data[k][6]);
      this.datavalue = this.drgparse(this.rawData0);
      console.log(this.datavalue);
      this.m.push({
        type: 'column',
        tooltip: {
          headerFormat: '',
          pointFormat: 'The total cost of {point.des} is <b>${point.y}</b> ',
        },
        xAxis: 1,
        yAxis: 1,
        id: this.wq,
        name: this.cx[k],
        data: this.datavalue,
        inverted: true,
      });
    }
    this.createMap();
  }

  drgparse(data) {
    this.v = [];
    for (let c = 0; c < data.length; c++) {
      if (data[c].state_code == this.wq) {
        this.v.push({
          name: data[c].th_code,
          y: parseInt(data[c].count),
          des: data[c].th_description,
        });
      }
    }
    console.log(this.v);
    return this.v;
  }

  createMap() {
    Highcharts.chart('container', {
      chart: {
        type: 'tilemap',
        inverted: true,
        height: '80%',
      },

      title: {
        text: 'Health Care Cost',
      },

      subtitle: {
        text: 'Source: Marketscan-Statewise Patients Population',
      },

      xAxis: [{
        visible: false,

      }, {
        visible: true,
        lineColor: '#111111',
        lineWidth: 0,
        type: 'category',
      }],
      yAxis: [{
        visible: false,
      }, {
        visible: true,
      }],

      colorAxis: {
        dataClasses: [{
          from: 0,
          to: 100000000,
          color: '#6ad3d8',
          name: '< 100M',
        }, {
          from: 100000001,
          to: 500000000,
          color: '#c96830',
          name: '100M - 500M',
        }, {
          from: 500000001,
          to: 1000000000,
          color: '#FFC428',
          name: '500M - 1B',
        }, {
          from: 1000000001,
          to: 2000000000,
          color: '#ac6de0',
          name: '1B - 2B',
        }, {
          from: 2000000001,
          color: '#f44242',
          name: '> 2B',
        }],
      },

      tooltip: {
        headerFormat: '',
        pointFormat: 'The population of <b> {point.name}</b> is <b>{point.value}</b>',
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.hc-a2}',
            color: '#000000',
            style: {
              textOutline: false,
            },
          },
        },
      },

      series: [{
        name: '',
        data: this.data1,
      }],

      drilldown: {
        series: this.m,
      },
    });
  }

  render() {
    return (
      <>
        <Grid container alignItems="end" justifyContent="flex-end">
          <Grid item xs={12}>
            <StyledButton
              size="large"
              component={Link}
              to="/solutions/population-health-dashboard"
            >
              Previous
            </StyledButton>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={10} md={10}>
            <div id="container" />
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography>
              * This Visualization has Drilldown capabilities based on Type - 2 Diabetes along with Patient Totalpay.
            </Typography>
          </Grid>
        </Grid>
      </>

    );
  }
}
export default memo(PatientTherapeuticCode);
