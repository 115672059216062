import * as React from "react";

import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core";
import styled from "styled-components";

export default function DenseTable({ tableData, onClick, linkId, key = 'tableData' }) {
  return (
    <TableContainer>
      {tableData && (
        <StyledTable
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {tableData.ColumnName.map((field) => (
                <HeaderCol key={field} align="left">{field}</HeaderCol>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.[key]?.map((row) => (
              <TableRow key={row.project_id}>
                {tableData.ColumnName.map((field, index) => (
                  field == linkId ? (
                    <StyledCell align="left" id={row[index]} wf-id={row[1]} job-id={row[3]} onClick={onClick}>
                      {row[index]}
                    </StyledCell>
                  ) : (
                    <TableCell align="left">
                      {row[index]}
                    </TableCell>
                  )
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
    </TableContainer>
  );
}

const HeaderCol = styled(TableCell)`
background: rgb(60, 64, 175);
color: white;
`;
const StyledCell = styled(TableCell)`
&.MuiTableCell-root {
  text-decoration: underline;
  color: #575D90;
  cursor: pointer;
}


`;
const StyledTable = styled(Table)`
  @media only screen and (max-width: 1100px) {
    .MuiTableCell-root {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 600px) {
    .MuiTableCell-root {
      font-size: 12px;
    }
  }
`;
const TableContainer = styled(Paper)`
&.MuiPaper-root {
  max-height: 80%;
  max-width: 100%;
  overflow: auto;
}
`;
