import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import { StyledFormControl } from "../Dataviz/ScatterPlot";
import { validateForm, isObjKeyhasval } from "../../util";
import { subFeatureData } from "../../constants";
import InfoToolTip from "../../components/InfoToolTip";
import { StyledInput } from '../../InputForm';
import { CommonCheckbox, LRContainer } from "./LogisticRegression";
import { InfoText } from '../ModelOptimization/DataShapley';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '../../../../../../styles/common';
import ZoomImage from "../../../../../common/ZoomImage";

export const ANNForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector(
    (state) => state.vnet,
  );
  const { initialData = null } = subFeatureData.Logistic_Regression;
  const [disableModelInfo, setDisableModelInfo] = useState(isCanvasClicked);

  const [isEditable, setIsEditable] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  //rename
  const [fromData, setFromdata] = useState({});
  const [dropdownList, setdropdownList] = useState(null);
  const [change, setChange] = useState(true);
  const [fromValid, setFromValid] = useState(false);
  const [toValid, setToValid] = useState(false);
  const [alert, setAlert] = useState("");
  const [error, setError] = useState({ name: " ", value: " " });
  const [modelPlot, setModelPlot] = useState("");
  const [layerIndx, setLayerLen] = useState(1);

  const handleFromData = (e) => {
    setFromdata({ ...fromData, [e.target.name]: e.target.value });

    if (e.target.name) {
      dispatch(
        setInputData({
          hiddenLayer: {
            ...userInputData.hiddenLayer,
            [e.target.name]: {
              ...userInputData.hiddenLayer?.[e.target.name],
              units: e.target.value
            },
          },
        }),
      );
    }
    const fromValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    setFromValid(fromValid);
    setChange(!submitValid);
  };
  const handleDefineModel = async () => {
    let contextData = {
      "Actions": "modelSummary",
    };
    let result = await props.getCommonAPIData(contextData, { ...userInputData }, 'ModelBuilding/ANNSummary');
    setModelPlot(result?.Result)
    console.log(result);
  }
  const handleToData = (e) => {
    if (e.target.name) {
      dispatch(
        setInputData({
          hiddenLayer: {
            ...userInputData.hiddenLayer,
            [e.target.name]: {
              ...userInputData.hiddenLayer?.[e.target.name],
              actFunc: e.target.value
            },
          },
        }),
      );
    }
    const toValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    alert && setAlert(false);
    setToValid(toValid);
    setChange(!submitValid);
  };
  const getInputFieldLen = () => Object.entries(fromData).filter((data) => data[0].startsWith('layer')).length;
  const addField = () => {
    const fromDataLen = getInputFieldLen();
    if (fromDataLen <= 5) {
      setFromdata({ ...fromData, [`layer${Number(layerIndx + 1)}`]: "" });
    }
    setLayerLen(layerIndx + 1)
    setChange(true);
    setToValid(false);
    setFromValid(false);
  };
  const isAlpha = (text) => /\d/.test(text);

  const validateInput = (e) => {
    const { name, value } = e.target;
    const error = {
      ...error,
    };
    if (!value) {
      error[name] = "Please Enter a Value";
      error[2] = true;
      setChange(true);
    } else if (value === name) {
      error[name] = "Cannot be From Value";
      setChange(true);
    } else if (isAlpha(value)) {
      error[name] = "cannot contain numbers";
      setChange(true);
    }
    setError(error);
  };

  const removeField = (e) => {
    const list = { ...fromData };
    const globallist = { ...userInputData.hiddenLayer };
    delete globallist[e];
    delete list[e];
    setFromdata(list);
    dispatch(
      setInputData({
        hiddenLayer: globallist,
      }),
    );
    setChange(false);
  };

  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);
  useEffect(() => {
    if (userInputData?.hiddenLayer) {
      const formData = {};
      let fromDataLen = 0;
      Object.keys(userInputData?.hiddenLayer).map((data, indx) => {
        formData[`layer${indx + 1}`] = data;
        fromDataLen++;
      });
      setLayerLen(fromDataLen);
      setFromdata(formData);
      setChange(false);
    } else {
      setFromdata({ layer1: "" });
    }
  }, []);


  //

  const validationData = {
    test_size: { maxNum: 1, minNum: 0, onlyNum: true },
    random_state: { minNum: 0, onlyNum: true },
    epochs: { minNum: 0, onlyNum: true },
    batchSize: { minNum: 0, onlyNum: true },
  };

  const handleFieldError = (errData) => {
    setFieldError({
      ...fieldError,
      ...errData,
    });
  };

  const { validateOnBlur } = validateForm(validationData, handleFieldError);
  const dispatch = useDispatch();

  const getCheckboxVal = (checked) => (checked ? "True" : "False");

  const handleOnChange = (e) => {
    console.log(e);
    const { value, name, checked = false } = e.target;
    const dataId = e.currentTarget.dataset.id;

    switch (dataId) {
      case "model_detail":
        dispatch(
          setInputData({
            ...userInputData,
            ModelDetail: {
              ...userInputData.ModelDetail,
              [name]: value,
            },
          }),
        );
        break;
      case "isEditable":
        if (checked) {
          if (
            confirm(
              `Editing the existing model details will create a new model run and version. Do you want to continue ?`,
            )
          ) {
            setDisableModelInfo(false);
            setIsEditable(checked);
          }
        } else {
          setDisableModelInfo(true);
          setIsEditable(checked);
        }
        break;
      case "compilation":
        dispatch(
          setInputData({
            ...userInputData,
            compilation: {
              ...userInputData.compilation,
              [name]: value,
            },
          }),
        );
        break;
      case "outputLayer":
        dispatch(
          setInputData({
            ...userInputData,
            outputLayer: {
              ...userInputData?.outputLayer,
              [name]: value,
            },
          }),
        );
        break;
      case "split_param":
        dispatch(
          setInputData({
            ...userInputData,
            split_param: {
              ...userInputData.split_param,
              [name]:
                ["shuffle"].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        break;
      default:
        dispatch(
          setInputData({
            ...userInputData,
            [name]: value,
          }),
        );
        break;
    }
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        columns: typeof value === "string" ? value.split(",") : value,
      }),
    );
    if (fieldError.columns) {
      setFieldError({ ...fieldError, columns: false });
    }
  };

  const handleFormReset = () => {
    dispatch(
      setInputData({
        ...initialData,
      }),
    );
    setFromdata({ layer1: "" });
    setModelPlot("")
  };
  const handleBlur = (e) => {
    const { value, name } = e.target;
    if (isNaN(value)) {
      setError({ ...fieldError, [name]: 'value must be number' });
      return;
    }
    if (value > 30) {
      setError({ ...fieldError, [name]: 'value must be <=30' });
      return
    }
    setError({ ...fieldError, [name]: false });

  };

  const isFormValid = () => {
    const errorObj = {};
    const validateObj = {
      target: userInputData?.target,
      Name: userInputData?.ModelDetail?.Name,
      Description: userInputData?.ModelDetail?.Description,
      ...userInputData.outputLayer,
      ...userInputData.compilation
    };
    Object.keys(validateObj).map((key) => {
      if (!validateObj[key]) {
        errorObj[key] = "Required Field";
      }
    });
    if (userInputData?.columns.length == 0) {
      errorObj.columns = "Required Field";
    }
    if (userInputData?.hiddenLayer && Object.entries(userInputData?.hiddenLayer).length == 0) {
      setAlert(true);
    }
    const errData = { ...fieldError, ...errorObj };
    setFieldError(errData);

    return isObjKeyhasval(errData);
  };

  useEffect(async () => {
    const dropdownList = await props.getCategories();
    if (dropdownList) {
      setdropdownList(JSON.parse(dropdownList));
    }
  }, []);

  return (
    <LRContainer padding="1rem">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={modelPlot ? 8 : 10}>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={5}>
              <StyledInput
                name="Name"
                label="Name"
                variant="outlined"
                onChange={handleOnChange}
                fullWidth
                required
                disabled={disableModelInfo}
                value={userInputData?.ModelDetail?.Name}
                inputProps={{ "data-id": "model_detail" }}
                error={fieldError.Name}
                helperText={fieldError.Name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <StyledInput
                name="Description"
                label="Description"
                variant="outlined"
                onChange={handleOnChange}
                fullWidth
                required
                disabled={disableModelInfo}
                value={userInputData?.ModelDetail?.Description}
                inputProps={{ "data-id": "model_detail" }}
                error={fieldError.Description}
                helperText={fieldError.Description}
              />
            </Grid>
          </Grid>
          {isCanvasClicked && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isEditable}
                  onChange={handleOnChange}
                  inputProps={{ "data-id": "isEditable" }}
                />
              )}
              label="Edit Model Info"
            />
          )}

          <SubTitile>
            <InfoToolTip title="Customize the train test split">
              Split Parameters
            </InfoToolTip>
          </SubTitile>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={5}>
              <StyledInput
                name="test_size"
                label="Test Size"
                variant="outlined"
                onChange={handleOnChange}
                fullWidth
                inputProps={{ "data-id": "split_param" }}
                value={userInputData?.split_param?.test_size}
                onBlur={validateOnBlur}
                error={fieldError.test_size}
                helperText={fieldError.test_size}
                info="Proportion of the dataset to be included in the test split."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <StyledInput
                name="random_state"
                label="Random State"
                variant="outlined"
                onChange={handleOnChange}
                fullWidth
                inputProps={{ "data-id": "split_param" }}
                value={userInputData?.split_param?.random_state}
                onBlur={validateOnBlur}
                error={fieldError.random_state}
                helperText={fieldError.random_state}
                info="Specify a value to set the random state seed value. Using an integer will produce the same results across different calls "
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControlLabel
                control={(
                  <CommonCheckbox
                    checked={userInputData?.split_param?.shuffle}
                    name="shuffle"
                    onChange={handleOnChange}
                    inputProps={{ "data-id": "split_param" }}
                  />
                )}
                label="Shuffle"
                info="Shuffle the data before splitting or not."
              />
            </Grid>
          </Grid>

          <SubTitile>
            <InfoToolTip title="Customize the train test split">
              Input Parameters
            </InfoToolTip>
          </SubTitile>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={5}>
              <StyledFormControl fullWidth error={fieldError.columns}>
                <InputLabel id="model-evaluation">Train Features</InputLabel>
                <Select
                  multiple
                  variant="outlined"
                  name="columns"
                  disabled={disableModelInfo}
                  value={userInputData?.columns || []}
                  onChange={handleMultiple}
                  input={<Input />}
                  fullWidth
                  required
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                // inputProps={{ 'data-id': "columns" }}
                >
                  {dropdownList?.map((value) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox
                        checked={userInputData?.columns?.indexOf(value) > -1}
                        disabled={
                          userInputData?.columns?.indexOf(userInputData?.target) > -1
                        }
                      />
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))}
                </Select>
                {fieldError.columns && <FormHelperText>Required Field</FormHelperText>}
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <StyledInput
                name="target"
                label="Target"
                variant="outlined"
                onChange={handleOnChange}
                select
                required
                disabled={disableModelInfo}
                fullWidth
                value={userInputData?.target}
                error={fieldError.target}
                helperText={fieldError.target}
              >
                {dropdownList?.map((value) => (
                  <MenuItem key={value} value={value} data-id="target">
                    {value}
                  </MenuItem>
                ))}
              </StyledInput>
            </Grid>
          </Grid>


          <SubTitile>
            <InfoToolTip title="Customize the train test split">
              Hidden Layer
            </InfoToolTip>
          </SubTitile>

          {alert && (
            <InfoText success={false}>
              {' '}
              <FontAwesomeIcon icon={faInfoCircle} />
              {' '}
              Please choose atleast one hidden layer
            </InfoText>
          )}
          <Grid container spacing={3} alignItems="center">

            {Object.entries(fromData).map((data) => (
              <>
                <Grid item xs={5}>
                  <StyledInput
                    name={data[0]}
                    label="Number of Neurons"
                    variant="outlined"
                    onChange={handleFromData}
                    onBlur={handleBlur}
                    key={data[0]}
                    fullWidth
                    required
                    type="number"
                    disabled={disableModelInfo}
                    value={userInputData?.hiddenLayer?.[data[0]]?.units}
                    placeholder="ex: 5  (1-30)"
                    info="The number of neurons being passed"
                  >
                  </StyledInput>
                </Grid>
                <Grid item xs={5}>
                  <StyledInput
                    fullWidth
                    name={data[0]}
                    label="Activation Function"
                    variant="outlined"
                    onChange={handleToData}
                    //onBlur={validateInput}
                    select
                    requried
                    value={userInputData?.hiddenLayer?.[data[0]]?.actFunc || ''}
                    error={error[fromData[data[0]]]}
                    helperText={error[fromData[data[0]]]}
                    disabled={disableModelInfo}
                    info="A function that is used for the transformation of the input values of neurons."
                  >
                    {['relu', 'linear', 'tanh', 'sigmoid'].map(value => (
                      <MenuItem
                        key={value}
                        value={value}
                      >
                        {value}
                      </MenuItem>
                    ))}

                  </StyledInput>
                </Grid>
                {!(data[0].startsWith("disabled")) && (
                  <Grid item xs={1}>
                    <FontAwesomeIcon icon={faTrash} onClick={() => removeField(data[0])} size="large" />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
          <StyledButton disabled={change} onClick={addField} variant="outlined">
            +
          </StyledButton>

          <SubTitile>
            <InfoToolTip title="Customize the train test split">
              Output Layer
            </InfoToolTip>
          </SubTitile>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={5}>
              <StyledInput
                fullWidth
                name='actfunc'
                label="Activation Function"
                variant="outlined"
                onChange={handleOnChange}
                requried
                value={userInputData?.outputLayer?.actfunc}
                disabled={disableModelInfo}
                error={fieldError["actfunc"]}
                helperText={fieldError["actfunc"]}
                inputProps={{ "data-id": "outputLayer" }}
                select
                info="A function that is used for the transformation of the input values of neurons."
              >
                {['sigmoid', 'softmax'].map(value => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-id="outputLayer"
                  >
                    {value}
                  </MenuItem>
                ))}
              </StyledInput>
            </Grid>
            <Grid item xs={12} >
              <StyledButton variant="contained" color="primary" onClick={handleDefineModel}>Define my model</StyledButton>
            </Grid>
          </Grid>
        </Grid>
        {modelPlot && <PlotContainer item xs={12} md={4}>
          <ZoomImage src={modelPlot}
            width='auto' />
        </PlotContainer>}
      </Grid>

      <SubTitile>
        <InfoToolTip title="Customize the train test split">
          Model Compilation
        </InfoToolTip>
      </SubTitile>

      <Grid container spacing={3} >
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            name='optimizer'
            label="Optimizer"
            variant="outlined"
            onChange={handleOnChange}
            requried
            value={userInputData?.compilation?.optimizer}
            error={fieldError['optimizer']}
            helperText={fieldError['optimizer']}
            inputProps={{ "data-id": "compilation" }}
            disabled={disableModelInfo}
            select
            info="A function or an algorithm that modifies the attributes of the neural network, such as weights and learning rate"
          >
            {['adam', 'SGD', 'RMSProp', 'ftrl'].map(value => (
              <MenuItem
                key={value}
                value={value}
                data-id="compilation"
              >
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            name='loss'
            label="Loss Function"
            variant="outlined"
            onChange={handleOnChange}
            requried
            value={userInputData?.compilation?.loss}
            error={fieldError['loss']}
            helperText={fieldError['loss']}
            inputProps={{ "data-id": "compilation" }}
            disabled={disableModelInfo}
            select
            info="Quantifies the difference between the expected outcome and the outcome produced by the model"
          >
            {['binary_crossentropy', 'categorical_crossentropy', 'poisson', 'sparse_categorical_crossentropy', 'kullback_leibler_divergence'].map(value => (
              <MenuItem
                key={value}
                value={value}
                data-id="compilation"
              >
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            name='batchSize'
            label="Batch Size"
            variant="outlined"
            onBlur={validateOnBlur}
            onChange={handleOnChange}
            requried
            value={userInputData?.compilation?.batchSize}
            error={fieldError['batchSize']}
            helperText={fieldError['batchSize']}
            inputProps={{ "data-id": "compilation" }}
            disabled={disableModelInfo}
            type='number'
            placeholder="ex: 32"
            info="The number of samples that will be passed through to the network at one time"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            name='epochs'
            label="No. of Epochs"
            variant="outlined"
            onChange={handleOnChange}
            type="number"
            requried
            onBlur={validateOnBlur}
            value={userInputData?.compilation?.epochs}
            error={fieldError['epochs']}
            helperText={fieldError['epochs']}
            inputProps={{ "data-id": "compilation" }}
            disabled={disableModelInfo}
            placeholder="ex: 10"
            info="Training the neural network with all the training data for one cycle."
          />
        </Grid>
      </Grid>
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </LRContainer>
  );
});

const PlotContainer = styled(Grid)`
  img{
    width: auto;
    max-width: 100%;
    height: auto;
  }
  border-left: 1px solid gray;
`;
export const SubTitile = styled.div`
  font-weight: bold;
  margin: 8px 0 5px 0;
`;
