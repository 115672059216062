import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import surgerySkills_sol1 from "../../../assets/images/app/surgerySkills_sol1.webp";
import surgerySkills_sample1 from "../../../assets/images/app/surgerySkills_sample1.webp";

const SurgerySkills = () => {
  const [sample, setSample] = useState(false);
  const [execute, setExecute] = useState(false);

  const sampleResult = () => {
    setSample(true);
  };
  const executeResult = () => {
    setExecute(true);
  };
  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The need for automatic surgical skills assessment is increasing,
            especially because manual feedback from senior surgeons observing
            junior surgeons is prone to subjectivity and time consuming. Thus,
            automating the evaluation of surgical skills is a very important
            step towards improving surgical practice.
          </p>
          <p>
            Conventional surgical skill assessment is currently based on
            checklists that are filled by an expert surgeon observing the
            surgery. In an attempt to evaluate surgical skills without relying
            on an expert’s opinion, Objective Structured Assessment of Technical
            Skills (OSATS) has been proposed and is used in clinical practice.
            Unfortunately, this type of observational evaluation is still prone
            to several external and subjective variables: the checklists’
            development process, the inter-rater reliability, and the evaluator
            bias.
          </p>
          <p>
            Recent advances in surgical robotics such as the da Vinci surgical
            robot (Intuitive Surgical Inc. Sunnyvale, CA) enabled the collection
            of motion and video data from different surgical activities. Hence,
            an alternative for checklists and outcome-based methods is to
            extract, from these motion data, global movement features such as
            the completion time, speed, curvature, motion smoothness, and other
            holistic features of the surgical task.
          </p>
          <Grid container>
            <Grid item xs={5}>
              <p>Surgical Activities that are trained on CNN model are:</p>
              <ul>
                <li>Knot-Tying</li>
                <li>Suturing</li>
                <li>Needle Passing</li>
              </ul>
            </Grid>
            <Grid item xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={5}>
              <p>
                The Skill level of the Surgeons are classified into three
                categories:
              </p>
              <ul>
                <li>Novice</li>
                <li>Intermediate</li>
                <li>Expert</li>
              </ul>
            </Grid>
          </Grid>

          <p>
            <b>Dataset:</b>
          </p>
          <p>
            JIGSAWS dataset is used to train CNN model. The JIGSAWS dataset has
            been collected from eight right-handed subjects with three different
            skill levels (Novice (N), Intermediate (I) and Expert (E))
            performing three different surgical tasks (suturing, needle passing
            and knot tying) using the da Vinci surgical system. Each subject
            performed five trials of each task. For each trial, the kinematic
            and video data were recorded. This application focuses on kinematic
            data which are numeric variables of four manipulators: left and
            right masters (controlled directly by the subject’s hands), and left
            and right slaves (controlled indirectly by the subject via the
            master manipulators). These kinematic variables (76 in total) are
            captured at a frequency equal to 30 frames per second for each
            trial. Each trial is considered as a multivariate time series (MTS)
            and accordingly, designed a one-dimensional CNN dedicated to learn
            the useful features for surgical skill classification automatically.
          </p>
          <p>
            <b>Architecture:</b>
          </p>
          <p>
            This application uses a new architecture of Convolutional Neural
            Networks (CNN) dedicated to surgical skill evaluation. The
            architecture mitigates the need to pre-define sensitive and
            unreliable gesture boundaries using one dimensional filter over the
            kinematic data. The original hierarchical structure of deep learning
            model enables to represent the gestures in latent low-level
            variables (first and second layers), as well as capturing global
            information related to the surgical skill level (third layer). To
            provide interpretable feedback, instead of using a final
            fully-connected layer like most traditional approaches, a Global
            Average Pooling (GAP) layer is used which enables to benefit from
            the Class Activation Map (CAM) to visualize which parts of the
            surgical task contributes the most to the surgical skill
            classification. The input of the CNN is an MTS with variable length
            l and 76 channels. The output layer contains the surgical skill
            level (N, I, E).
          </p>
          <center>
            <img width="70%" src={surgerySkills_sol1} alt="" />
          </center>
          <p>
            <b>Working:</b>
          </p>
          <p>
            This is a Demo version of the Application. Hence, Skill
            Classification of a Surgeon for Suturing Activity is shown below. To
            get the full version of the tool which includes BYOD (Bring your own
            Data) for all the surgical activities, please add this application
            to the cart and checkout. vLife team will get in touch with you for
            more details.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/RoboticSurgery/Surgery_Evaluation.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={sampleResult}
          >
            Sample Video for Suturing
          </StyledButton>
          {sample && (
            <Grid item xs={12}>
              <ResponsiveVideo height="400px" width="400px" controls autoPlay>
                <source
                  src="https://vlife.virtusa.com/RoboticSurgery/sample_video.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </ResponsiveVideo>
              <br />
              <Result>
                The Kinematic data with respect to this video is extracted out
                and is passed to the model. Please click on the Execute button
                to see the skill level of the Surgeon along with an Activation
                map, which shows the movement of the left tool in the video.
              </Result>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={executeResult}
              >
                Execute
              </StyledButton>
              {execute && (
                <Grid item xs={12}>
                  <br />
                  <ResponsiveVideo
                    height="400px"
                    width="400px"
                    controls
                    autoPlay
                  >
                    <source
                      src="https://vlife.virtusa.com/RoboticSurgery/Suturing_Right.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </ResponsiveVideo>
                  <br />
                  <Result>
                    The proposed method uses the Class Activation Map to
                    localize the regions of the surgical task and their
                    corresponding contribution to a certain classification.
                    Below is the reason why the Surgeon has been classified as
                    “Novice”.
                  </Result>
                  <br />
                  <img width="30%" src={surgerySkills_sample1} alt="" />
                </Grid>
              )}
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(SurgerySkills);

const ResponsiveVideo = styled.video`
  @media only screen and (max-width: 500px) {
    width:100%;
    height:100%;
  }
`;
