import React, { memo, useState, useEffect } from 'react';
import {
  Grid, TextField, MenuItem, Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '../../../../../../styles/common';
import { setInputData } from '../../../../../../store/actions/vnet';
import { StyledInput } from '../../InputForm';

import { isObjKeyhasval } from '../../util';
import { InfoText } from '../ModelOptimization/DataShapley';
import { subFeatureData } from "../../constants";

export const DatatypeConverterForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const { initialData = null } = subFeatureData.Datatype_Converter;
  const dispatch = useDispatch();
  const [fromData, setFromdata] = useState({});
  const [dropdownList, setdropdownList] = useState(null);
  const [change, setChange] = useState(true);
  const [fromValid, setFromValid] = useState(false);
  const [toValid, setToValid] = useState(false);
  const [alert, setAlert] = useState("");
  const [error, setError] = useState({ name: " ", value: " " });
  const [fromDataIndex, setfromDataIndex] = useState("1");

  const handleFromData = (e) => {
    if (Object.values(fromData).indexOf(e.target.value) == -1) {
      setFromdata({ ...fromData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
    const fromValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    setFromValid(fromValid);
    setChange(!submitValid);
  };

  const handleToData = (e) => {
    if (e.target.name) {
      dispatch(
        setInputData({
          column: {
            ...userInputData.column,
            [e.target.name]: e.target.value,
          },
        }),
      );
    }
    const toValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    alert && setAlert(false);
    setToValid(toValid);
    setChange(!submitValid);
  };
  const getInputFieldLen = () => Object.entries(fromData).filter((data) => data[0].startsWith('input')).length;
  const addField = () => {
    const fromDataLen = getInputFieldLen();
    if (fromDataLen < dropdownList.length) {
      setFromdata({ ...fromData, [`input${fromDataIndex}`]: "" });
    }
    setfromDataIndex(fromDataIndex + 1);
    setChange(true);
    setToValid(false);
    setFromValid(false);
  };
  const isAlpha = (text) => /\d/.test(text);

  const validateInput = (e) => {
    const { name, value } = e.target;
    const error = {
      ...error,
    };
    if (!value) {
      error[name] = "Please Enter a Value";
      error[2] = true;
      setChange(true);
    } else if (value === name) {
      error[name] = "Cannot be From Value";
      setChange(true);
    } else if (isAlpha(value)) {
      error[name] = "cannot contain numbers";
      setChange(true);
    }
    setError(error);
  };

  const removeField = (e) => {
    const list = { ...fromData };
    const globallist = { ...userInputData.column };
    delete globallist[list[e]];
    delete list[e];
    setFromdata(list);
    dispatch(
      setInputData({
        column: globallist,
      }),
    );
    setChange(false);
  };

  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);
  useEffect(() => {
    if (userInputData?.column) {
      const formData = {};
      let fromDataLen = 0;
      Object.keys(userInputData?.column).map((data, indx) => {
        formData[`disabled${indx}`] = data;
        fromDataLen++;
      });
      setFromdata(formData);
      setfromDataIndex(fromDataLen);
      setChange(false);
    } else {
      setFromdata({ input0: "" });
    }
  }, []);
  useEffect(() => {
    if (getInputFieldLen() == 0) {
      setChange(false);
    }
  }, [fromData]);

  const isFormValid = () => {
    if (!userInputData?.column || Object.entries(userInputData?.column).length == 0) {
      setAlert(true);
      return false;
    }
    return true;
  };

  const handleFormReset = () => {

    setFromdata({ input0: '' });
    dispatch(
      setInputData({
        column: {},
      })
    );
  };

  return (
    <DatatypeConverterContainer>
      {alert && (
        <InfoText success={false}>
          {' '}
          <FontAwesomeIcon icon={faInfoCircle} />
          {' '}
          Please choose atleast one column to change datatype
        </InfoText>
      )}
      <Grid container spacing={3} alignItems="center">

        {Object.entries(fromData).map((data) => (
          <>
            <Grid item xs={12} sm={5}>
              {data[0].startsWith("disabled") ? (
                <StyledInput
                  fullWidth
                  name={data[0]}
                  label="Column"
                  variant="outlined"
                  disabled
                  type="text"
                  required
                  value={data[1]}
                  info="Specify the column to be converted"
                />
              ) : (
                <StyledInput
                  name={data[0]}
                  label="Column"
                  variant="outlined"
                  onChange={handleFromData}
                  select
                  key={data[0]}
                  fullWidth
                  required
                  value={fromData && fromData[data[0]]}
                  info="Specify the column to be converted"
                >
                  {dropdownList?.map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      disabled={Object.values(fromData).indexOf(value) > -1}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </StyledInput>
              )}
            </Grid>
            <Grid item xs={10} sm={5}>
              <StyledInput
                name={fromData[data[0]]}
                label='DataType'
                variant='outlined'
                onChange={handleToData}
                //onBlur={validateInput}
                select
                key={data[0]}
                disabled={data[0].startsWith("disabled")}
                fullWidth
                required
                info="Specify the type for conversion"
                value={
                  fromData[data[0]]
                  && userInputData?.column?.[fromData[data[0]]]
                }
              >
                {['int', 'float', 'string', 'datetime']?.map((value) => (
                  <MenuItem key={value} value={value} disabled={Object.values(fromData).indexOf(value) > -1}>
                    {value}
                  </MenuItem>
                ))}
              </StyledInput>
            </Grid>
            {!(data[0].startsWith("disabled")) && (
              <Grid item xs={2} sm={2}>
                <FontAwesomeIcon icon={faTrash} onClick={() => removeField(data[0])} size="large" />
              </Grid>
            )}
          </>
        ))}
      </Grid>
      <StyledButton disabled={change} onClick={addField} variant="outlined">
        +
      </StyledButton>
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </DatatypeConverterContainer>
  );
});

const DatatypeConverterContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
    min-width: 190px;
  }
`;
