import React, { memo, useState, useEffect } from "react";
import {
  Grid, MenuItem, TextField, FormControl,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import SolutionContainer from "../../common/SolutionContainerWrapper";

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const FrequentPharmacyVisitor = () => {
  const [spinner, setSpinner] = useState(false);
  const [idList, setIDList] = useState([]);
  const [notification, setNotification] = useState("");
  const [resultData, setResultData] = useState(false);

  useEffect(() => {
    setSpinner(true);
    getQueryResult("idList")
      .then((res) => {
        setSpinner(false);
        setIDList(res.enrollid);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  }, []);

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "frequentPharmacyVisitor",
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSpinner(true);
    getQueryResult("frequency", value)
      .then((res) => {
        setSpinner(false);
        console.log(res);
        setResultData(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Problem :</b>
            {' '}
            Pharmacists are an integral part of primary health
            care. Evidence suggests that pharmacists see their patients
            somewhere between 1.5 and 10 times more frequently than they see
            primary care physicians. But, there is no proper mechanism to
            predict who are the patients making frequent visits.
            <br />
            <br />
            Especially for patients suffering from chronic conditions visiting
            frequency is high. This set represents a high valued customer
            segment making a forced visit for refills. Is there a way to
            identify potential patients and cut down the visit frequency? Is
            there a way to predict the next visit and deliver the medicine to
            the patient’s door step?
            <br />
            <br />
            <b>Solution:</b>
            {' '}
            Improved customer experience is a success factor to
            improved long term revenues. In this high potential solution, an EMR
            is combined with claims data to analyze a patient’s purchase pattern
            with respect to treatment pathways. The analyzed results are then
            used in training a machine learning model to predict/identify
            patients who are likely to make frequent visits to Pharmacy.
            <br />
            <br />
            Once the high potential patients are identified, organizations can
            design custom methods to answer individual patient needs.
            <br />
            <br />
            <b>Approach: </b>
            Proportion of Days Covered(PDC) provides percentage
            of days patient is covered with medication in a therapeutic category
            out of the total days in the reporting period. A patient with PDC
            greater than 80% can be usually treated as Frequent Pharmacy
            Visitor.
            <br />
            Formula for calculating PDC Value - PDC = (Number of days in period
            "coverd"/Number of days in period)*100
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Frequent_Pharmacy_visit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Frequent_Pharmacy_visit/data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          {idList.length > 0 && (
            <Grid container direction="row" justify="center">
              <Grid item xs={12} sm={6} md={3}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    labelId="outlined-adornment-weight-label"
                    transform="translate(14px, 20px) scale(1)"
                    id="outlined-adornment-weight"
                    label="Select Enroll ID"
                    variant="outlined"
                    select
                    onChange={handleChange}
                  >
                    {idList.map((option) => (
                      <MenuItem value={option}>{option}</MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {spinner && <Spinner text="Loading..." />}
          {resultData && (
            <Grid container direction="row" justify="center">
              <Grid item xs={12} sm={6}>
                <Result>
                  <strong>Result</strong>
                  {`${resultData}`}
                </Result>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(FrequentPharmacyVisitor);
