import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// local components
import { Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft, faAngleDoubleRight, faChartPie, faDatabase, faBrain, faBox,
} from "@fortawesome/free-solid-svg-icons";
import { vizFeatureIds } from "../constants";
import DataTab from "./DataResultTab";
import DataViz from "./DataVizResult";
import DataTransform from "./DataTrasformResult";
import { setResult, setResultTab } from "../../../../../store/actions/vnet";
import { StyledBackdrop } from "../../../../../styles/common";
import Spinner from "../../../../common/loading/Spinner";
import Notification from "../../../../common/Notification";
import postReq, { getResult } from "../Service";
import ModelBuildingResultTab from "./ModelBuildingResultTab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    minWidth: "max-content",
  },
}));

function ResultPanel() {
  const { Context_param = {}, resultData = null, resultTabVal = 0 } = useSelector((state) => state.vnet);

  const [toggle, setToggle] = useState(true);
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleTabChange = (event, newValue) => {
    dispatch(setResultTab(newValue));
  };
  const toggleTab = (event) => {
    setToggle(!toggle);
  };

  const getDataFrame = (Actions) => {
    const params = {
      Context_param: {
        ...Context_param,
        Actions: "",
        Update_Date: new Date().toLocaleDateString(),
        Created_Date: new Date().toLocaleDateString(),
        StageID: "Common_API",
        FeatureID: "Common_API",
        FunctionID: Actions,
      },
      Content_param: {},
    };
    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          setLoader(false);
          if (res.Error_Flag) {
            setNotification({ open: 'error', message: res.Error_UI });
          } else {
            dispatch(setResult({
              ...resultData,
              data: {
                ...res.Result,
                selectedDataset: Actions,
              },
            }));
            setNotification({ open: 'success', message: res.Console });
            // dispatch(setResultTab(2));
          }
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };
  const getPlotData = (data) => {
    const params = {
      Context_param: {
        ...Context_param,
        Actions: "vizPull",
        Update_Date: new Date().toLocaleDateString(),
        Created_Date: new Date().toLocaleDateString(),
        StageID: "Data_Visualization",
        FeatureID: vizFeatureIds[data.FunctionID],
        FunctionID: data.FunctionID,
        Viz_Info: data.title,
      },
      Content_param: {},
    };
    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          if (res.Error_Flag) {
            setNotification({ open: 'error', message: res.Error_UI });
          } else {
            getResult(res.Result?.Path).then(s3Res => {
              setLoader(false);
              dispatch(setResult({
                ...resultData,
                dataViz: {
                  ...resultData.dataViz,
                  zPlot: s3Res,
                  Current_Plot: data.title,
                  Current_Stage: data.FunctionID,
                },
              }));
            }).catch(err => {
              setLoader(false);
            })
            setNotification({ open: 'success', message: res.Console });
            // dispatch(setResultTab(1));
          }
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };
  const getPandasReport = () => {
    const params = {
      Context_param: {
        ...Context_param,
        Trigger: "Async",
        FeatureID: "Common_API",
        FunctionID: "Pandas_Profile",
        Actions: "Report",
        StageID: "Common_API",
      },
      Content_param: {},
    };
    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          setLoader(false);
          if (res.Error_Flag) {
            setNotification({ open: 'error', message: res.Error_UI });
          } else {
            if (res.Result?.inProgress) {
              dispatch(setResult({
                ...resultData,
                data: {
                  ...resultData.data,
                  inProgress: res.Result.inProgress,
                  pandasReport: "",
                },
              }));
            } else {
              dispatch(setResult({
                ...resultData,
                data: {
                  ...resultData.data,
                  pandasReport: res.Result,
                  inProgress: false,
                },
              }));
            }
            setNotification({ open: 'success', message: res.Console });
            // dispatch(setResultTab(0));
          }
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };
  const fetchModelMetrics = (modelInfo) => {
    const params = {
      Context_param: {
        ...Context_param,
        Trigger: "Async",
        FeatureID: "Common_API",
        FunctionID: "fetchModelMetrics",
        Actions: "",
        Model_RunID: modelInfo?.modelVersionID,
        modelName: modelInfo?.modelName,
        model: modelInfo?.model,
        modelType: modelInfo?.modelType,
        StageID: "Common_API",
      },
      Content_param: {},
    };
    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          setLoader(false);
          if (res.Error_Flag) {
            setNotification({ open: 'error', message: res.Error_UI });
          } else {
            dispatch(setResult({
              ...resultData,
              modelData: {
                ...resultData.modelData,
                ...res.Result,
              },
            }));
            setNotification({ open: 'success', message: res.Console });
            // dispatch(setResultTab(0));
          }
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };
  const getPastActivity = () => {
    const params = {
      Context_param: {
        ...Context_param,
        FeatureID: "Common_API",
        Actions: "",
        StageID: "Common_API",
        FunctionID: "activityList",
      },
      Content_param: {},
    };
    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          setLoader(false);
          if (res.Error_Flag) {
            setNotification({ open: 'error', message: res.Error_UI });
          } else {
            dispatch(setResult({
              ...resultData,
              ...JSON.parse(res.Result),
            }));
            setNotification({ open: 'success', message: res.Console });
            // dispatch(setResultTab(2));
          }
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };
  const getAllViz = () => {
    const params = {
      Context_param: {
        ...Context_param,
        FeatureID: "Common_API",
        Actions: "",
        StageID: "Common_API",
        FunctionID: "vizList",
      },
      Content_param: {},
    };
    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          setLoader(false);
          if (res.Error_Flag) {
            setNotification({ open: 'error', message: res.Error_UI });
          } else {
            dispatch(setResult({
              ...resultData,
              dataViz: res.Result,
            }));
            setNotification({ open: 'success', message: res.Console });
            // dispatch(setResultTab(2));
          }
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };
  async function getCommonAPIData(contextData = {}, contentData = {}) {
    let result;
    const postparams = {
      Context_param: {
        ...Context_param,
        ...contextData,
      },
      Content_param: {
        ...contentData,
      },
    };
    try {
      setLoader(true);
      result = await postReq(postparams);
      setLoader(false);

      if (result.Error_Flag) {
        setNotification({ open: "error", message: result.Error_UI });
      } else {
        setNotification({ open: "success", message: result.Console });
        return result;
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      return false;
    }
  }

  return (
    <ResultContainer>
      <Container className={classes.root} toggle={toggle}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={resultTabVal}
          onChange={handleTabChange}
          aria-label="Vertical StyledTabs example"
        >
          <Tab
            label={(
              <div className="tabLabel">
                <FontAwesomeIcon icon={faDatabase} />
                
                <span className="tabText">Data</span>
              </div>
            )}
            {...a11yProps(0)}
          />
          <Tab
            label={(
              <div className="tabLabel">
                <FontAwesomeIcon icon={faChartPie} />
                
                <span className="tabText">Data Viz</span>
              </div>
            )}
            {...a11yProps(1)}
          />
          <Tab
            label={(
              <div className="tabLabel">
                
                <FontAwesomeIcon icon={faBrain} />
                
                <span className="tabText">Transformation</span>
              </div>
            )}
            {...a11yProps(2)}
          />
          <Tab
            label={(
              <div className="tabLabel">
                <FontAwesomeIcon icon={faBox} />
                
                <span className="tabText">Model</span>
              </div>
            )}
            {...a11yProps(3)}
          />

        </Tabs>
        <div onClick={toggleTab} className="slider">
          
          <FontAwesomeIcon icon={toggle ? faAngleDoubleLeft : faAngleDoubleRight} />
        </div>
        <TabPanel value={resultTabVal} index={0}>
          <DataTab getDataFrame={getDataFrame} getPandasReport={getPandasReport} commonAPI={getCommonAPIData} />
        </TabPanel>
        <TabPanel value={resultTabVal} index={1}>
          <DataViz getPlotData={getPlotData} getAllViz={getAllViz} commonAPI={getCommonAPIData} />
        </TabPanel>
        <TabPanel value={resultTabVal} index={2}>
          <DataTransform getPastActivity={getPastActivity} />
        </TabPanel>
        <TabPanel value={resultTabVal} index={3}>
          <ModelBuildingResultTab fetchModelMetrics={fetchModelMetrics} commonAPI={getCommonAPIData} />
        </TabPanel>
      </Container>
      <StyledBackdrop open={isLoading}>
        <Spinner />
      </StyledBackdrop>
      <Notification snackbar={notification} />
    </ResultContainer>
  );
}
export default memo(ResultPanel);

const ResultContainer = styled(Paper)`
&.MuiPaper-root {
  box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  min-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 15px;
  padding-top: 0;
  hr {
    margin: 10px 0;
  }
}

`;
export const Toggle = styled.section`
    color: #ffff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
    #content {
      background: #053354;
      height: 100%;
      min-width: 200px;
      display: ${(props) => (props.in ? 'block' : "none")}  
    }
    #icon {
      background: #053354;
      color: #ffff;
      min-width: 1rem;
      padding: 5px;
      border-radius: 0 50% 50% 0;
      position: sticky;
      top: 40px;
    }
  }`;
const Container = styled.div`
  margin-top: 5px;
  transition: 1s all ease-out;
  .slider{
    padding: 8px;
    background: #1d3557;
    border-radius: 0 5px 5px 0;
    max-width: fit-content;
    color: #ffff;
    cursor:pointer;
  }
  .Mui-selected {
    align-items: left;
    color: white;
    background: #053354;
    background: transparent linear-gradient(180deg,#053354 0,#006aa5 100%) 0 0 no-repeat padding-box;
  }
  .PrivateTabIndicator-colorSecondary-10{
    background: #f07167;
    width: 6px;
  }
  .MuiTabs-vertical{
    min-width: max-content;
    .tabText{
      text-transform: none;
      display: ${(props) => (props.toggle ? 'inline-block' : 'none')};
    }
  }
  .tabLabel{
    width: fit-content;
  }
  .MuiTab-root{
    min-width: fit-content;
  }
  `;
export function TabPanel(props) {
  const {
    children, value, index, width = "", ...other
  } = props;

  return (
    <StyledBox
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </StyledBox>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledBox = styled.div`
  padding: 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  .MuiTypography-body1, .MuiTableCell-root {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 600px) {
    overflow: auto
  }
`;
