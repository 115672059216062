import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, TextField, MenuItem, Button, Slide } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LazyHero from 'react-lazy-hero';
import GetAppIcon from '@material-ui/icons/GetApp';

// local components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/MainLayout';
import Navbar from '../../common/Navbar';
import Collapse from '../../common/Collapse';
import Footer from '../../views/home/Footer';
import CommonService from '../../../utils/services/CommonService';
import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';

import {
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
  HighchartContainer,
  Result,
} from '../../../styles/common';

// images
import HLSDashboard_sol1 from '../../../assets/images/app/revenue.webp';
import HLSDashboard_sol2 from '../../../assets/images/app/landing-page-3.webp';
import HLSDashboard_sol3 from '../../../assets/images/app/hls1.webp';
import HLSDashboard_sol4 from '../../../assets/images/app/hls2.webp';
import HLSDashboard_sol5 from '../../../assets/images/app/hls3.webp';
import HLSDashboard_sol6 from '../../../assets/images/app/hls4.webp';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const HLSDashboard = () => {
  const { uploadFilesWithBody } = CommonService('z1dApps', 'hls');
  const [selectedOption, setSelectedOption] = useState('');
  const [uploadfile, setUploadfile] = useState('');
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState('');
  const [result, SetResultData] = useState(false);
  const [exploreCharts, setExploreCharts] = useState(false);

  const multivariateFeatures = [
    { label: 'HEALTH CARE', value: 'HC' },
    { label: 'LIFE SCIENCES', value: 'LS' },
    { label: 'IBM', value: 'IBM' },
    { label: 'HLS Combined', value: 'HLS Combined' },
  ];

  const handleInputs = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  const getResult = () => {
    if (!selectedOption) {
      setNotification({ open: 'error', message: 'Fields are empty' });
    } else {
      setSpinner(true);
      setExploreCharts(false);
      SetResultData(false);

      uploadFilesWithBody('output', { file: uploadfile }, selectedOption)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res);
          if (selectedOption === 'HC') {
            formChart(res.quarter_sum, 'Healthcare Revenue Prediction');
          } else if (selectedOption === 'IBM') {
            formChart(res.quarter_sum, 'IBM Revenue Prediction');
          } else if (selectedOption === 'LS') {
            formChart(res.quarter_sum, 'Life Science Revenue Prediction');
          } else if (selectedOption === 'HLS Combined') {
            formChart(res.quarter_sum, 'HLS Combined');
            SetResultData(res.quarter_sum);
          }
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };

  const formChart = (data, title, divID) => {
    Highcharts.chart(divID || 'container', {
      chart: {
        type: 'line',
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: ['FY21-Q4', 'FY22-Q1', 'FY22-Q2', 'FY22-Q3'],
      },
      yAxis: {
        title: {
          text: 'Revenue ($)',
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: false,
        },
      },
      series: [
        {
          name: 'Quarter',
          data,
        },
      ],
    });
  };
  const showMoreCharts = () => {
    const moreCharts = Object.entries(result.segment_quarter);
    setExploreCharts(moreCharts);
  };

  useEffect(() => {
    exploreCharts &&
      exploreCharts.forEach((quarterData, index) => {
        formChart(quarterData[1], quarterData[0], `explore_${index}`);
      });
  }, [exploreCharts]);
  return (
    <MainLayout>
      <SolutionContainer snackbar={notification}>
        <Navbar />
        <LazyHero
          imageSrc={HLSDashboard_sol1}
          parallaxOffset='150'
          minHeight='75vh'
          opacity='0.8'
          color='#283caa'
        >
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={7} md={7}>
              <Slide direction='right' timeout={600} in>
                <SlideTitle>
                  Virtusa HLS Revenue Forecasting Platform
                </SlideTitle>
              </Slide>
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <SlideImage width='90%' src={HLSDashboard_sol2} alt='' />
            </Grid>
          </Grid>
        </LazyHero>
        <Container>
          <Title>About</Title>
          <Grid item xs={12}>
            <p>
              Virtusa HLS Database consists of financial data from FY 2012 and
              the same has been used to build the Multivariate Model. The
              Dataset is collected with respect to different segments of SBU
              (Healthcare, Life science and IBM) and these segments have been
              combined to corresponding SBUs for SBU level forecasting. The
              Model predicts revenue at SBU level and provides an option to see
              the split up of the predicted revenue at segment level.
            </p>
          </Grid>
          <Grid item xs={12}>
            <img width='100%' src={HLSDashboard_sol3} />
          </Grid>
          <br />
          <br />
          <Grid
            container
            direction='row'
            alignItems='center'
            spacing={3}
            justify='center'
          >
            <Grid item xs={12} sm={12} md={6}>
              <img width='95%' src={HLSDashboard_sol4} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Title>SDFC</Title>
              <p>
                Since the dataset is based on different segments, the number of
                data points used for training the model is less. Thus, it is
                important to update the Database with actual values after every
                quarter so that the training dataset increases which will help
                in increasing the performance and the accuracy of the model.
                Also, for the SFDC stages, below distribution has been
                considered for each quarter for every segment in SBU.
              </p>
            </Grid>
          </Grid>
          <center>
            {' '}
            <Title>Multivariate Model</Title>
          </center>
          <Grid
            container
            direction='row'
            alignItems='center'
            spacing={3}
            justify='center'
          >
            <Grid item xs={12} sm={12} md={6}>
              <Title>Description</Title>
              <p>
                Multivariate data analysis is a set of statistical models that
                examine patterns in multidimensional data by considering, at
                once, several data variables. For Predicting Revenue, we have
                only considered Internal factors to build the model.
              </p>
              <p>Internal variables used are: </p>
              <Grid container direction='row'>
                <Grid item xs={12} md={5}>
                  <List>
                    <ListItem> Injection </ListItem>
                    <ListItem> Opportunity</ListItem>
                    <ListItem> Qualification</ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} md={5}>
                  <List>
                    <ListItem> Pursuit </ListItem>
                    <ListItem> Proposal</ListItem>
                    <ListItem> Closure</ListItem>
                    <ListItem> Verbal</ListItem>
                    <ListItem> Contract Award</ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img width='90%' src={HLSDashboard_sol5} />
            </Grid>
          </Grid>

          <center>
            {' '}
            <Title>Analysis</Title>
          </center>
          <Grid
            container
            direction='row'
            alignItems='center'
            spacing={3}
            justify='center'
          >
            <p>Data Format to be uploaded</p>
            <Grid item xs={12}>
              <img width='100%' src={HLSDashboard_sol6} />
            </Grid>
            <Grid item xs={12}>
              <center>
                <p>
                  A sample file is provided below which can be downloaded. The
                  sample file consists of data for Quarter 3- FY2021.
                </p>
                <StyledButton
                  variant='outlined'
                  color='primary'
                  size='large'
                  startIcon={<GetAppIcon />}
                >
                  <a
                    href='https://vlife.virtusa.com/HLS/Sample_hls.csv'
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    Sample Data
                  </a>
                </StyledButton>
              </center>
            </Grid>
          </Grid>

          <Collapse text='Demo'>
            <DemoContainer>
              <Grid container direction='row' justify='center' spacing={3}>
                <Grid item xs={12} sm={5} md={4}>
                  <TextField
                    variant='outlined'
                    type='file'
                    label='*Supports only csv formats'
                    name='uploadedData'
                    onChange={onChangeFile}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                  <TextField
                    variant='outlined'
                    select
                    fullWidth
                    name='multivariateFeatures'
                    label='MultivariateFeatures'
                    value={selectedOption}
                    onChange={handleInputs}
                  >
                    {multivariateFeatures.map((data) => (
                      <MenuItem value={data.value}>{data.label}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    onClick={getResult}
                  >
                    Forecast
                  </StyledButton>
                </Grid>
              </Grid>
              {showSpinner && <Spinner text='Loading...' />}
              {result && (
                <Result>
                  <strong>Result:</strong>
                  The database for Virtusa HLS revenue is updated till FY21 -Q3.
                  The forecasted revenue is for next 4 quarters
                </Result>
              )}
              <HighchartContainer id='container' display={result} />
              {result && result.segment_quarter && (
                <Button
                  color='primary'
                  variant='contained'
                  onClick={showMoreCharts}
                >
                  {' '}
                  Explore &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faAngleDoubleRight} />{' '}
                </Button>
              )}
              <br />
              <br />
              <Grid container xs={12} spacing={3} justify='center'>
                {exploreCharts &&
                  exploreCharts.map((option, index) => (
                    <Grid item xs={6}>
                      <div
                        style={{ display: exploreCharts ? 'block' : 'none' }}
                        id={`explore_${index}`}
                      />
                    </Grid>
                  ))}
              </Grid>
            </DemoContainer>
          </Collapse>
        </Container>
      </SolutionContainer>
    </MainLayout>
  );
};

export default memo(HLSDashboard);

const Container = styled.div`
  padding: 5% 5% 5% 5%;
`;

const SlideImage = styled.img`
  margin-top: 1%;
  margin-bottom: 2%;
  height: 95%;
  width: 95%;
`;

const SlideTitle = styled.p`
  color: white;
  font-size: 50px;
  @media only screen and (max-width: 900px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 30px;
  }
`;

const Title = styled.p`
  font-size: 30px;
  @media only screen and (max-width: 900px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 20px;
  }
`;
