import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Paper } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components
import AddToCartButton from "../../common/AddToCartButton";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";

// images
import polypSegmentation_sol1 from "../../../assets/images/app/polypSegmentation_sol1.webp";
import polypSegmentation_sol2 from "../../../assets/images/app/polypSegmentation_sol2.webp";
import polypSegmentation_sample1 from "../../../assets/images/app/polypSegmentation_sample1.webp";
import polypSegmentation_sample2 from "../../../assets/images/app/polypSegmentation_sample2.webp";
import polypSegmentation_sample3 from "../../../assets/images/app/polypSegmentation_sample3.webp";
import polypSegmentation_sample4 from "../../../assets/images/app/polypSegmentation_sample4.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const PolypSegmentation = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [selectedImage1, setSelectedImage1] = useState(false);
  const [selectedImage2, setSelectedImage2] = useState(false);
  const [notification] = useState("");

  const selectedImageFunc1 = () => {
    setSpinner(true);
    setSelectedImage2(false);
    setSelectedImage1(true);
    setSpinner(false);
  };
  const selectedImageFunc2 = () => {
    setSpinner(true);
    setSelectedImage2(true);
    setSelectedImage1(false);
    setSpinner(false);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Colorectal cancer, also known as bowel cancer, colon cancer, or
            rectal cancer, is any cancer that affects the colon and rectum.
            Colorectal cancer (CRC) is the second-highest cause of
            cancer-related deaths in the United States with over 50,000
            estimated deaths in 2014. The advanced stage of colorectal cancer
            has a poor five-year survival rate of 10%, whereas the diagnosis in
            early stages of development has showed a more favourable five-year
            survival rate of 90%. Early diagnosis of colorectal cancer is
            achievable if colorectal polyps, a possible precursor to cancer, are
            detected and removed before developing into malignancy. The gold
            standard screening method for polyp detection and removal is optical
            colonoscopy. Colonoscopy has been a successful preventative
            procedure and has contributed to a significant decline of 30% in
            colorectal cancer.
          </p>
          <p>
            A colonoscopy procedure takes about 25 minutes and consists of the
            following two phases:
          </p>
          <ul>
            <li>
              Insertion phase during which a flexible endoscope (a flexible tube
              with a tiny video camera at the tip) is advanced via the anus and
              then gradually all the way to the end of the colon.
            </li>
            <li>
              Withdrawal phase that begins immediately after the insertion
              phase, during which the endoscope is gradually withdrawn while
              colonoscopists meticulously examine the colon wall to find and
              remove polyps.
            </li>
          </ul>
          <p>
            Colonoscopy is an operator dependent procedure wherein human factors
            such as fatigue and insufficient attentiveness can lead to
            the miss-detection of polyps during long and back-to-back
            procedures. The average polyp miss-rate is estimated around 4-12%.
            Patients with missed polyps may be diagnosed with a late stage
            colorectal cancer with the survival rate of less than 10%.
          </p>

          <Grid container>
            <Grid item xs={7}>
              <p>
                <b> Dataset and Input Details</b>
              </p>
              <p>
                CVC-ClinicDB is a database of frames extracted from colonoscopy
                videos. These frames contain several examples of polyps. In
                addition to the frames, it provides the ground truth for the
                polyps which consists of a mask corresponding to the region
                covered by the polyp in the image.
              </p>
              <p>
                CVC-ClinicDB database consists of two different types of images:
              </p>
              <ul>
                <li>Original images: original </li>
                <li>Polyp mask: ground truth</li>
              </ul>
              <p>
                612 still images from 29 different sequences. Each image has its
                associated manually annotated ground truth covering the polyp.
              </p>
            </Grid>
            <Grid item xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              <img
                width="100%"
                src={polypSegmentation_sol1}
                alt=""
              />
            </Grid>
          </Grid>
          <p>

            <b>Polyp Segmentation using UNET in TensorFlow</b>
          </p>
          <p>
            In this application, Polyp segmentation has been performed using
            deep learning, UNet architecture, OpenCV, and other libraries.
            CVC-ClinicDB dataset has been used to implement semantic
            segmentation.
          </p>
          <p>

            <b>What is Semantic Segmentation?</b>
          </p>
          <p>
            Semantic segmentation is one of the challenges in computer vision
            research. It can be thought of as a classification problem, but at
            the pixel level because you need to predict the label or class of
            every pixel in an image. Semantic segmentation is a task that steps
            toward the complete scene understanding by learning to predict what
            is in the image. The importance of scene understanding lies in its
            applications to the field of computer vision, such as self-driving
            vehicles, human-computer interaction, autonomous robots, augmented
            reality, facial- recognition systems, etc.
          </p>
          <p>
            <b>What is UNet?</b>
          </p>
          <p>
            The UNet is a fully convolutional neural network that was developed
            by Olaf Ronneberger at the Computer Science Department of the
            University of Freiburg, Germany. It was especially developed for
            biomedical image segmentation.
          </p>
          <center>
            <img width="50%" src={polypSegmentation_sol2} />
          </center>
          <p>
            <b>Application</b>
          </p>
          <p>
            Computer-aided polyp detection may assist colonoscopists with the
            task of polyp detection and may encourage more attentiveness during
            procedures. This polyp detection application can help us save many
            lives by detecting polyps more accurately. It is a polyp detection
            system which handle polyps’ variability in terms of shape, size,
            colour, and texture, which can potentially save lives!
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/balraj98/cvcclinicdb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <PresignedS3Link
                    href="PolypDetetction/polypDetection.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </PresignedS3Link>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={4}>
              <MaskPaper elevation={3}>
                <Image src={polypSegmentation_sample1} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={selectedImageFunc1}
                >
                  Sample 1
                </MarginButton>
              </MaskPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <MaskPaper>
                <Image src={polypSegmentation_sample2} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={selectedImageFunc2}
                >
                  Sample 2
                </MarginButton>
              </MaskPaper>
            </Grid>
          </Grid>

          <section>
            <div>
              <br />
              {showSpinner && <Spinner text="Loading..." />}
            </div>
          </section>
          {selectedImage1 && (
            <section>
              <section>
                <Result>
                  Detected polyps for the given sample is marked in Red
                </Result>
                <br />
                <br />
                <img src={polypSegmentation_sample3} alt="" />
              </section>
            </section>
          )}
          {selectedImage2 && (
            <section>
              <br />

              <section>
                <Result variant="outlined" color="primary">
                  Detected polyps for the given sample is marked in Red
                </Result>
                <br />
                <br />
                <img src={polypSegmentation_sample4} alt="" />
              </section>
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(PolypSegmentation);
const MaskPaper = styled(Paper)`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 230px;
`;
const MarginButton = styled(StyledButton)`
  margin-right: 10px;
  margin-left: 10px;
`;
