import React, { memo, useRef, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    InputAdornment,
    Input,
    Paper,
    Button,
    AppBar,
    Tab,
    Tabs,
    MenuItem
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import JoditEditor from "jodit-react";
import { jsPDF } from "jspdf";

// local components
import styled from 'styled-components';
import Collapse from '../../common/Collapse';
import CollapseMedtech from '../../common/CollapseMedtech';
import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import CommonService from "../../../utils/services/CommonService";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    List,
    ListItem,
} from '../../../styles/common';

// images
import pageCover from '../../../assets/images/app/pdf_cover1.png';
import pageCover2 from '../../../assets/images/app/pdf_cover2.png';

// user id
import getUserData from "../../../utils";
// import pdfDownload from '../../../utils/pdfgenerator';

const ColbertAnalysis = () => {

    const { postQueryResult, uploadFile } = CommonService(
        "medtech", "medtech",
    );

    const [showSpinner, setSpinner] = useState(false);
    const [showSpinner1, setSpinner1] = useState(false);
    const [showSpinner2, setSpinner2] = useState(false);
    const [showSpinner3, setSpinner3] = useState(false);

    const [showSpinnerPara, setSpinnerPara] = useState(false);
    const [notification, setNotification] = useState("");
    const [result, setResult] = useState('');
    const [inputText, setInputText] = useState("ICD for patients with age >18 years");
    const [selectedFile, setselectedFile] = useState('');
    const [dropDownText, setDropDownText] = useState('');
    const [btnEnable, setBtnEnable] = useState(true);
    const [s3path, setS3path] = useState('');
    const [filename, setFileName] = useState('');
    const [rteValue, setrteValue] = useState("");
    const [promtValue, setPromtValue] = useState("");
    const [finalResult, setFinalResult] = useState("");
    const [promptPara, setPromptPara] = useState("");
    const [editorrteValue, setEditorrteValue] = useState("");
    const [fields, setFields] = React.useState({});
    const [sentimentResult, setSentimentResult] = useState("");
    const [summaryResult, setSummaryResult] = useState("");
    const [studyResult, setStudyResult] = useState("");
    const [keyResult, setKeyResult] = useState("");
    const [editorfinalResult, setEditorFinalResult] = useState("");
    const [editorSentimentResult, setEditorSentimentResult] = useState("");

    const [userId, setUserId] = useState(getUserData()?.token?.emailid);

    // tab value
    const [value, setValue] = useState(0);

    // for tab values
    const tabhandleChange = (event, newValue) => {
        setValue(newValue);
    };

    // radio button
    const [radiovalue, setRadioValue] = useState("");
    const [radioName, setRadioName] = useState("");
    const [radiovalueText, setRadioValueText] = useState("box");
    const [radioNameText, setRadioNameText] = useState("box");
    const [radiovalueText2, setRadioValueText2] = useState("box");
    const [radioNameText2, setRadioNameText2] = useState("box");
    const [radiovalueText3, setRadioValueText3] = useState("box");
    const [radioNameText3, setRadioNameText3] = useState("box");

    const handleRadioChange = (event) => {
        setResult('');
        setDropDownText('');
        setSummaryResult("");
        setStudyResult("");
        setKeyResult("");
        setRadioValue(event.target.value);
        setRadioName(event.target.name);
        if (event.target.value == "input")
            setInputText("ICD for patients with age >18 years")
    };

    const handleRadioChangeText = (event) => {
        setRadioValueText(event.target.value);
        setRadioNameText(event.target.name);
    };

    const handleRadioChangeText2 = (event) => {
        setRadioValueText2(event.target.value);
        setRadioNameText2(event.target.name);
    };

    const handleRadioChangeText3 = (event) => {
        setRadioValueText3(event.target.value);
        setRadioNameText3(event.target.name);
    };

    const handleInputs = (e) => {
        const { value } = e.target;
        setInputText(value);
    };

    const handleInputDrop = (e) => {
        const { value } = e.target;
        setInputText(value);
    };

    const handleChangePromt = (e) => {
        const { value } = e.target;
        setPromtValue(value);
    };

    const onChangeFile = (e) => {
        setS3path("");
        setFinalResult("");
        setrteValue("");
        setResult("");
        setSentimentResult("");

        const file = e.target.files[0];
        if (file) {
            setselectedFile(file);
            setBtnEnable(false);
        } else {
            setBtnEnable(true);
            setselectedFile("");
        }

    };


    const getRiskResult = () => {

        setSpinner(true);
        postQueryResult("riskanalyis", { path: s3path, filename: filename })
            .then((res) => {
                setSpinner(false);
                setrteValue(res);

                let markupStr1 = `<p><b>Description of Observations</b></p><br />`

                let str = res.result_dict.map((value, index) => {
                    let string;
                    let strr = index != '0' ?
                        `<p>${value.content}</p>` : ""
                    // if (index != '0' && index % 2 == '0') {
                    //     strr = strr + `<p><strong>Risk Analysis Result : </strong>${value["Risk Analysis Result"]} </p>`
                    // }

                    if (index != '0' && index % 2 == '0') {

                        if (index == '2') {
                            string = `
                            <table><tr><td><b>Observation ID : </b> 1123121</td><td><b>GxP : </b> GCP</td></tr>
                        <tr><td><b>Finding Affiliation : </b> Internal</td><td><b>Sector : </b> Pharma</td></tr>
                        <tr><td><b>Compliance Topic </b></td><td>Site Study – Section Oversight</td></tr>
                        <tr><td><b>Compliance Subtopic </b></td><td>Study Management – SD/PI Oversight</td></tr>
                        </table>
                        <br /><p><b>Observation</b></p>
                        <p>${value.content}</p><p><strong>Risk Analysis Result : </strong>${value["Risk Analysis Result"]} </p><br /><br />`
                        }
                        if (index == '4') {
                            string = `
                            <table><tr><td><b>Observation ID : </b> 1123121</td><td><b>GxP : </b> GCP</td></tr>
                        <tr><td><b>Finding Affiliation : </b> Internal</td><td><b>Sector : </b> Pharma</td></tr>
                        <tr><td><b>Compliance Topic </b></td><td>Records Management</td></tr>
                        <tr><td><b>Compliance Subtopic </b></td><td>Source Documentation</td></tr>
                        </table>
                        <br /><p><b>Observation</b></p>
                        <p>${value.content}</p><p><strong>Risk Analysis Result : </strong>${value["Risk Analysis Result"]} </p><br /><br />`
                        }
                        if (index == '6') {
                            string = `
                            <table><tr><td><b>Observation ID : </b> 1123121</td><td><b>GxP : </b> GCP</td></tr>
                        <tr><td><b>Finding Affiliation : </b> Internal</td><td><b>Sector : </b> Pharma</td></tr>
                        <tr><td><b>Compliance Topic </b></td><td>Records Management</td></tr>
                        <tr><td><b>Compliance Subtopic </b></td><td>Source Documentation</td></tr>
                        </table>
                        <br /><p><b>Observation</b></p>
                        <p>${value.content}</p><p><strong>Risk Analysis Result : </strong>${value["Risk Analysis Result"]} </p><br /><br />`
                        }
                        if (index == '8') {
                            string = `
                            <table><tr><td><b>Observation ID : </b> 1123121</td><td><b>GxP : </b> GCP</td></tr>
                        <tr><td><b>Finding Affiliation : </b> Internal</td><td><b>Sector : </b> Pharma</td></tr>
                        <tr><td><b>Compliance Topic </b></td><td>Records Management</td></tr>
                        <tr><td><b>Compliance Subtopic </b></td><td>Source Documentation</td></tr>
                        </table>
                        <br /><p><b>Observation</b></p>
                        <p>${value.content}</p><p><strong>Risk Analysis Result : </strong>${value["Risk Analysis Result"]} </p><br /><br />`
                        }
                    }
                    return string;
                })
                let finalString = `<div style="text-align:justify; font-size: 20px;" >${markupStr1 + str.join("")}<br /><br /><br /></div>`
                setEditorrteValue(finalString);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
                setNotification({ open: "error", message: err.message });
            });

    };

    const getSummarizeResult = () => {

        setSpinner(true);
        postQueryResult("summarize", { filename: filename, path: s3path, })
            .then((res) => {
                setSpinner(false);
                setFinalResult(res);

                let str1 = `<p><strong> Executive Summary</strong></p><br />`
                let str2 = res.page_summary.map((value, index) => (
                    `<p>${value}</p>`
                ))
                let finalString = `<div style="text-align:justify;  font-size: 20px;" >${str1 + str2.join("")}
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                </div>`
                setEditorFinalResult(finalString + editorrteValue);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
                setNotification({ open: "error", message: err.message });
            });
    };

    const getGenerateParagraph = () => {

        setSpinnerPara(true);
        postQueryResult("paragraphgen", { prompttext: promtValue })
            .then((res) => {
                setSpinnerPara(false);
                if (sentimentResult == "") {
                    setPromptPara(res);
                    // setSentimentResult(finalResult.executive_summary + rteValue.result_string + res);
                    setSentimentResult(res);

                    //paragraph
                    let str = `<div style="text-align:justify;  font-size: 20px; " ><p><strong>Generated Paragraph</strong></p>${res}
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    </div>`

                    //executive summary
                    let str1 = `<p><strong> Executive Summary</strong></p> <br />`
                    let str2 = finalResult.page_summary.map((value, index) => (
                        `<p>${value}</p>`
                    ))
                    let finalString = `<div style="text-align:justify;  font-size: 20px;" >${str1 + str2.join("")}
                    <br /><br />
                    </div>`

                    setEditorSentimentResult(finalString + str + editorrteValue);
                } else {
                    setSentimentResult(sentimentResult + res);
                    setPromptPara(promptPara + res);
                    let str = `<div style="text-align:justify;  font-size: 20px; " ><br />${res}</div>`

                    setEditorSentimentResult(editorSentimentResult + str);
                }
            })
            .catch((err) => {
                console.log(err);
                setSpinnerPara(false);
                setNotification({ open: "error", message: err.message });
            });
    };


    // const getOutput = () => {

    //     setResult("");

    //     if (!inputText) {
    //         setNotification({ open: "error", message: "Fields are empty" });
    //     } else {
    //         setSpinner(true);
    //         postQueryResult("cbs", { userquery: inputText })
    //             .then((res) => {
    //                 setSpinner(false);
    //                 setResult(res);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //                 setSpinner(false);
    //                 setNotification({ open: "error", message: err.message });
    //             });
    //     }
    // };

    const getOutput = () => {

        setResult("");

        if (!inputText) {
            setNotification({ open: "error", message: "Fields are empty" });
        } else {
            setSpinner1(true);
            setSpinner2(true);
            setSpinner3(true);
            setSummaryResult("");
            setStudyResult("");
            setKeyResult("");
            /////
            postQueryResult("cbsstudy", { username: userId, userquery: inputText })
                .then((res) => {
                    setSpinner2(false);
                    setStudyResult(res);
                })
                .catch((err) => {
                    console.log(err);
                    setSpinner2(false);
                    setNotification({ open: "error", message: err.message });
                });
            ///////
            postQueryResult("cbssummary", { username: userId, userquery: inputText })
                .then((res) => {
                    setSpinner1(false);
                    setSummaryResult(res);
                })
                .catch((err) => {
                    console.log(err);
                    setSpinner1(false);
                    setNotification({ open: "error", message: err.message });
                });
            /////
            postQueryResult("cbskey", { username: userId, userquery: inputText })
                .then((res) => {
                    setSpinner3(false);
                    setKeyResult(res);
                })
                .catch((err) => {
                    console.log(err);
                    setSpinner3(false);
                    setNotification({ open: "error", message: err.message });
                });
        }
    };

    const getUploadResult = () => {

        setSpinner(true);
        uploadFile("fileupload", selectedFile)
            .then((res) => {
                setSpinner(false);
                setS3path(res.Result.path);
                setFileName(res.Result.fileName);
                setNotification({ open: 'success', message: "Uploaded Successfully" });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };


    //test jodit
    const editorRef = useRef(null);
    const finalReportEditorRef = useRef(null);

    const config = {
        style: {
            maxHeight: "400px",
            color: "#2F4F4F",
        },
    };

    const docDownload = () => {
        let el = document.getElementsByClassName("jodit-wysiwyg");
        el[2].setAttribute("id", "pdf-download");
        let preHtml =
            "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        let postHtml = "</body></html>";
        let html =
            preHtml + document.getElementById("pdf-download").innerHTML + postHtml;
        let blob = new Blob(["\ufeff", html], { type: "application/msword" }); // Specify link url
        let url =
            "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html); // Specify file name
        let filename = "document.doc"; // Create download link element
        let downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = url; // Setting the file name
            downloadLink.download = filename; //triggering the function
            downloadLink.click();
        }
        document.body.removeChild(downloadLink);
    };

    const pdfDownloadSummary = (e) => {
        e.preventDefault();
        let el = document.getElementsByClassName("jodit-wysiwyg");
        el[0].setAttribute("id", "pdf-download");
        let doc = new jsPDF("p", "pt", "A4");
        doc.html(document.getElementById("pdf-download"), {
            html2canvas: { scale: 0.48 },
            callback: (pdf) => {

                doc.setFontSize(8);
                const pageCount = doc.internal.getNumberOfPages();
                console.log(pageCount);

                // For each page, print the page number and the total pages
                for (let i = 1; i <= pageCount; i++) {
                    // Go to page i
                    doc.setPage(i);
                    //Print Page 1 of 4 for example
                    console.log(doc.internal.pageSize.height);
                    doc.setFontSize(13);

                    //page number
                    doc.setFontSize(8);
                    doc.text(
                        String(i) + "/" + String(pageCount),
                        doc.internal.pageSize.width - 20,
                        doc.internal.pageSize.height - 10,
                        null,
                        null,
                        "right"
                    );
                }


                //cover page
                doc.addPage();
                doc.setFontSize(14);
                doc.setTextColor(105, 105, 105);

                {
                    doc.addImage(
                        pageCover,
                        "PNG",
                        doc.internal.pageSize.width / 2 - 200,
                        doc.internal.pageSize.height / 4 - 150,
                        430,
                        470
                    );
                }
                doc.rect(
                    20,
                    20,
                    doc.internal.pageSize.width - 40,
                    doc.internal.pageSize.height - 40
                );
                doc.setFillColor(247, 139, 139);
                doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");

                doc.setLineWidth(1);
                doc.setDrawColor(237, 139, 139);
                doc.setFillColor(237, 139, 139);
                doc.triangle(
                    0,
                    doc.internal.pageSize.height,
                    0,
                    doc.internal.pageSize.height - 250,
                    doc.internal.pageSize.width + 40,
                    doc.internal.pageSize.height,
                    "FD"
                );
                doc.movePage(pageCount + 1, 1);
                //////

                console.log(doc.output('blob'));
                doc.save(`Report_${new Date().toLocaleString()}`);
            },

            margin: [40, 30, 40, 40],
        });
    };

    const pdfDownload = (e) => {
        e.preventDefault();
        let el = document.getElementsByClassName("jodit-wysiwyg");
        el[1].setAttribute("id", "pdf-download");
        let doc = new jsPDF("p", "pt", "A4");
        doc.html(document.getElementById("pdf-download"), {
            html2canvas: { scale: 0.48 },
            callback: (pdf) => {

                doc.setFontSize(8);
                const pageCount = doc.internal.getNumberOfPages();
                console.log(pageCount);

                // For each page, print the page number and the total pages
                for (let i = 1; i <= pageCount; i++) {
                    // Go to page i
                    doc.setPage(i);
                    //Print Page 1 of 4 for example
                    console.log(doc.internal.pageSize.height);
                    doc.setFontSize(13);

                    //page number
                    doc.setFontSize(8);
                    doc.text(
                        String(i) + "/" + String(pageCount),
                        doc.internal.pageSize.width - 20,
                        doc.internal.pageSize.height - 10,
                        null,
                        null,
                        "right"
                    );
                }


                //cover page
                doc.addPage();
                doc.setFontSize(14);
                doc.setTextColor(105, 105, 105);

                {
                    doc.addImage(
                        pageCover,
                        "PNG",
                        doc.internal.pageSize.width / 2 - 200,
                        doc.internal.pageSize.height / 4 - 150,
                        430,
                        470
                    );
                }
                doc.rect(
                    20,
                    20,
                    doc.internal.pageSize.width - 40,
                    doc.internal.pageSize.height - 40
                );
                doc.setFillColor(247, 139, 139);
                doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");

                doc.setLineWidth(1);
                doc.setDrawColor(237, 139, 139);
                doc.setFillColor(237, 139, 139);
                doc.triangle(
                    0,
                    doc.internal.pageSize.height,
                    0,
                    doc.internal.pageSize.height - 250,
                    doc.internal.pageSize.width + 40,
                    doc.internal.pageSize.height,
                    "FD"
                );
                doc.movePage(pageCount + 1, 1);
                //////

                // //cover page 2
                // doc.addPage();
                // doc.setFontSize(14);
                // // doc.setTextColor(105, 105, 105);

                // {
                //     doc.addImage(
                //         pageCover2,
                //         "PNG",
                //         doc.internal.pageSize.width / 2 - 250,
                //         doc.internal.pageSize.height / 4 - 150,
                //         450,
                //         150
                //     );
                // }
                // doc.rect(
                //     20,
                //     20,
                //     doc.internal.pageSize.width - 40,
                //     doc.internal.pageSize.height - 40
                // );

                // doc.movePage(pageCount + 2, 2);
                //////

                console.log(doc.output('blob'));
                doc.save(`Report_${new Date().toLocaleString()}`);
            },

            margin: [40, 30, 40, 40],
        });
    };

    return (
        <SolutionContainer snackbar={notification}>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        Clinical Trials are operated by a largely manual process through a large number of spreadsheets & documents by large MedTech firms and their CROs.  This adds significant burden in terms of effort and cost across trial planning, trial monitoring & management, and document summarization & reporting. As a result, Clinical Trials can experience a loss of visibility, delays and poor quality.
                    </p>
                    <p>
                        Virtusa MedTech prides itself on its AI-First approach and has been on the forefront of the Gen AI and NLP based ML model developments for Clinical Trials orchestration. Virtusa’s site monitoring, auto report generation solutions have brought savings of ~4000 hours/year of auditing effort , 70% reduction in time to create various reports (Audit, Inspector, CSR, AE, 510(k), FDA responses) at the leading life sciences company in the world.
                    </p>
                </Paragraph>
                <DemoContainer>
                    <Grid container xs={12} spacing={2} direction="row">
                        <Grid item xs={12}>
                            <AppBar position="static" color="default">
                                <StyledTabs
                                    value={value}
                                    onChange={tabhandleChange}
                                    variant="fullWidth"
                                    aria-label="simple tabs example"
                                >
                                    <Tab label="Gen AI-Based Contextual Search" />
                                    <Tab label="Clinical Trial Site Monitoring" />
                                    <Tab label="Gen AI-Driven Audit Assist and Risk Stratification" />
                                </StyledTabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                    <TabPanel value={value} index={0}>
                        <div>
                            <Paragraph>Swiftly access relevant insights from trial documents tailored to user roles and history.</Paragraph>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://material.vlifevirtusa.com/ContextBasedSearch/ContextualBasedSearching.html"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Notebook
                                </a>
                            </StyledButton>
                            <br /><br />
                            <Grid container
                                spacing={3}
                                direction='column'>
                                <Grid item>
                                    <TextField
                                        name="input"
                                        label="Role"
                                        variant="outlined"
                                        onChange={handleInputs}
                                        value="Principal Investigator"
                                    >
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                        name="input"
                                        label="Device Name"
                                        variant="outlined"
                                        onChange={handleInputs}
                                        value="Cardiac Implants"
                                    >
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                        name="input"
                                        label="Document Type"
                                        variant="outlined"
                                        onChange={handleInputs}
                                        value="FDA Submissions"
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                direction='row'
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <br />
                                    <StyledRadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name={radioName}
                                        value={radiovalue}
                                        onChange={handleRadioChange}
                                    >
                                        <FormControlLabel
                                            value="input"
                                            name="input"
                                            control={<Radio />}
                                            label="Custom Search"
                                        />
                                        <FormControlLabel
                                            value="select"
                                            name="select"
                                            control={<Radio />}
                                            label="Sample Search"
                                        />
                                    </StyledRadioGroup>
                                </Grid>
                                {radiovalue == "input" && (
                                    <>
                                        <Grid item xs={9}>
                                            <TextField
                                                name="input"
                                                label="Enter Text"
                                                variant="outlined"
                                                onChange={handleInputs}
                                                fullWidth
                                                value={inputText}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <StyledButton
                                                style={{ marginTop: '10px' }}
                                                variant='contained'
                                                color='primary'
                                                onClick={getOutput}

                                            >
                                                Search
                                            </StyledButton>
                                        </Grid>
                                    </>
                                )}
                                {radiovalue == "select" && (
                                    <>
                                        <Grid item xs={12} sm={9} md={9}>
                                            <TextField
                                                label="Select Query"
                                                variant="outlined"
                                                onChange={handleInputDrop}
                                                select
                                                fullWidth
                                                value={inputText}
                                                name="category"
                                            >
                                                {["Look for safety & efficacy of ICD implants for elderly patents > 70 years", "Real World data for patients with ICD after 2 years"]
                                                    .map((values, index) => (
                                                        <MenuItem
                                                            value={values}
                                                        >
                                                            {values}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <StyledButton
                                                style={{ marginTop: '10px' }}
                                                variant='contained'
                                                color='primary'
                                                onClick={getOutput}
                                            >
                                                Search
                                            </StyledButton>
                                        </Grid>
                                    </>
                                )}
                                {result && (
                                    <Grid item xs={12} >
                                        <HeaderContainer>
                                            <StyledHeading></StyledHeading>
                                            <strong>
                                                <FontAwesomeIcon icon={faInfoCircle} /> Result
                                            </strong>
                                            <StyledHeading></StyledHeading>
                                        </HeaderContainer>
                                    </Grid>
                                )}


                                <Grid item xs={12} sm={6} md={6}>
                                    {showSpinner1 && <Spinner text="Summary Loading..." />}
                                    {summaryResult && (
                                        <DivBox>
                                            <>
                                                <Paragraph><strong>Summary</strong></Paragraph>
                                                <Paragraph>{summaryResult.Query_Summary}</Paragraph>
                                            </>
                                        </DivBox>
                                    )}

                                </Grid>
                                {/* <Paragraph>
                                            {result.Query_Summary.split('*').map((values, index) => (
                                                <Paragraph> {index == '0' && <p>{values}</p>}
                                                    {index != '0' && <ul><li>{values} </li></ul>}
                                                </Paragraph>
                                            ))}
                                        </Paragraph> */}
                                <Grid item xs={12} sm={6} md={6}>
                                    {showSpinner2 && <Spinner text="ClinicalStudies Loading..." />}
                                    {studyResult && (
                                        <>
                                            <CollapseDiv>
                                                {studyResult.ClinicalStudies.map((value, index) => (
                                                    <>
                                                        <CollapseMedtech number={value.NCTId} text={value.BriefTitle} link={value.href}>
                                                            {showSpinner3 && <Spinner text="Keypoints Loading..." />}
                                                            {keyResult && (
                                                                <>
                                                                    {keyResult?.map((values, index) => (
                                                                        <>
                                                                            {value.NCTId == values.NCTId && <>
                                                                                {values.keypoints.split('\n').map((valuem, index) => (
                                                                                    <>
                                                                                        <Paragraph>{valuem} </Paragraph>
                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </CollapseMedtech>
                                                    </>
                                                ))}
                                            </CollapseDiv>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Paragraph>
                            Use site-monitoring to create and review documents during site-visit
                        </Paragraph>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://v1-apps.vlifevirtusa.com/site-monitoring/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    SiteMonitoring
                                </a>
                            </StyledButton>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Paragraph> Simplify report creation with intuitive prompts, reducing time and effort. Review risk-based stratification for audit reports created during the site-visit.</Paragraph>
                        <StyledButton
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<OpenInNewIcon />}
                        >
                            <a
                                href="https://material.vlifevirtusa.com/GenAI/GenAI%20Audit%20Assist.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Notebook
                            </a>
                        </StyledButton>
                        <br />
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={8} sm={5} md={4}>
                                <Input
                                    type="file"
                                    color="primary"
                                    name="uploadedFile"
                                    onChange={onChangeFile}
                                />
                                {/* <center> <HyperText > *supports {fileformat} file formats only</HyperText></center> */}
                            </Grid>
                            <Grid item xs={4} sm={2} md={2}>
                                <StyledButton
                                    id="btn2"
                                    variant="contained"
                                    color="primary"
                                    helperText="Please select a txt file only"
                                    disabled={btnEnable}
                                    onClick={getUploadResult}
                                >
                                    Upload
                                </StyledButton>
                            </Grid>
                            {s3path && (
                                <>
                                    <Grid item xs={12}>
                                        <br /><br />
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            helperText="Please select a txt file only"
                                            onClick={getRiskResult}
                                        >
                                            Predict Risk
                                        </StyledButton>
                                    </Grid>

                                    {rteValue && (
                                        <>
                                            <Grid item xs={12} >
                                                <br />
                                                <HeaderContainer>
                                                    <StyledHeading></StyledHeading>
                                                    <strong>
                                                        <FontAwesomeIcon icon={faInfoCircle} /> Observations
                                                    </strong>
                                                    <StyledHeading></StyledHeading>
                                                </HeaderContainer>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={12} md={4}>
                                                <br />
                                                <StyledRadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name={radioNameText}
                                                    value={radiovalueText}
                                                    onChange={handleRadioChangeText}
                                                >
                                                    <FormControlLabel
                                                        value="box"
                                                        name="box"
                                                        control={<Radio />}
                                                        label="Review"
                                                    />
                                                    <FormControlLabel
                                                        value="editor"
                                                        name="editor"
                                                        control={<Radio />}
                                                        label="Editor"
                                                    />
                                                </StyledRadioGroup>
                                            </Grid> */}
                                            {/* {radiovalueText == "box" && (
                                                <> */}
                                            {/* <GridHidden>
                                                        <FlexContainer>
                                                            <StyledEditor
                                                                ref={editorRef}
                                                                value={rteValue.result_string}
                                                                config={config}
                                                                tabIndex={1} // tabIndex of textarea
                                                                onBlur={(newContent) => setrteValue(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                onChange={(newContent) => { }}
                                                            />
                                                        </FlexContainer>
                                                    </GridHidden> */}
                                            <GridBox item xs={12} sm={12} md={12}>
                                                <Paragraph><strong>Observations</strong></Paragraph>
                                                {rteValue.result_dict.map((value, index) => (
                                                    <>
                                                        {index != '0' && <Paragraph>{value.content}</Paragraph>}
                                                        {index != '0' && index % 2 == '0' && <>
                                                            <Paragraph><strong>Risk Analysis Result : </strong>{value["Risk Analysis Result"]} </Paragraph>
                                                        </>
                                                        }
                                                    </>
                                                ))}
                                                <br />
                                            </GridBox>
                                            {/* </>
                                            )} */}
                                            {/* {radiovalueText == "editor" && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <br /><br />
                                                        <FlexContainer>
                                                            <StyledEditor
                                                                ref={editorRef}
                                                                value={editorrteValue}
                                                                config={config}
                                                                tabIndex={1} // tabIndex of textarea
                                                                onBlur={(newContent) => setrteValue(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                onChange={(newContent) => { }}
                                                            />
                                                        </FlexContainer>
                                                        <br />
                                                    </Grid>
                                                </>
                                            )} */}
                                            <Grid item xs={12}>
                                                <br />
                                                <StyledButton
                                                    id="btn2"
                                                    variant="contained"
                                                    color="primary"
                                                    helperText="Please select a txt file only"
                                                    onClick={getSummarizeResult}
                                                >
                                                    Summarize
                                                </StyledButton>
                                            </Grid>

                                            {finalResult && (
                                                <>
                                                    <Grid item xs={12} >
                                                        <br />
                                                        <HeaderContainer>
                                                            <StyledHeading></StyledHeading>
                                                            <strong>
                                                                <FontAwesomeIcon icon={faInfoCircle} /> Executive Summary
                                                            </strong>
                                                            <StyledHeading></StyledHeading>
                                                        </HeaderContainer>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <br />
                                                        <StyledRadioGroup
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name={radioNameText2}
                                                            value={radiovalueText2}
                                                            onChange={handleRadioChangeText2}
                                                        >
                                                            <FormControlLabel
                                                                value="box"
                                                                name="box"
                                                                control={<Radio />}
                                                                label="Review"
                                                            />
                                                            <FormControlLabel
                                                                value="editor"
                                                                name="editor"
                                                                control={<Radio />}
                                                                label="Editor"
                                                            />
                                                        </StyledRadioGroup>
                                                    </Grid>
                                                    {radiovalueText2 == "box" && (
                                                        <>
                                                            <GridHidden>
                                                                <FlexContainer>
                                                                    <StyledEditor
                                                                        ref={editorRef}
                                                                        value={finalResult.executive_summary}
                                                                        config={config}
                                                                        tabIndex={1} // tabIndex of textarea
                                                                        onBlur={(newContent) => setFinalResult(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                        onChange={(newContent) => { }}
                                                                    />
                                                                </FlexContainer>
                                                            </GridHidden>
                                                            <GridBox item xs={12} sm={12} md={12}>
                                                                <Paragraph><strong>Executive Summary</strong></Paragraph>
                                                                {finalResult.page_summary.map((value, index) => (
                                                                    <>
                                                                        <Paragraph>{value}</Paragraph>
                                                                    </>
                                                                ))}
                                                                <br />
                                                            </GridBox>
                                                            <br />
                                                        </>
                                                    )}
                                                    {radiovalueText2 == "editor" && (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <br /><br />
                                                                <FlexContainer>
                                                                    <StyledEditor
                                                                        ref={editorRef}
                                                                        // value={finalResult.executive_summary}
                                                                        value={editorfinalResult}
                                                                        config={config}
                                                                        tabIndex={1} // tabIndex of textarea
                                                                        onBlur={(newContent) => setFinalResult(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                        onChange={(newContent) => { }}
                                                                    />
                                                                </FlexContainer>
                                                                <br /><br />
                                                            </Grid>
                                                            <StyledButton
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={pdfDownloadSummary}
                                                            >
                                                                Download as pdf &nbsp;{" "}
                                                                <FontAwesomeIcon icon={faFilePdf} />
                                                            </StyledButton>
                                                        </>
                                                    )}
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <br /><br />
                                                    </Grid>
                                                    <Grid item xs={7} md={7}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            required
                                                            label="Enter your prompt"
                                                            name="promt"
                                                            value={promtValue}
                                                            onChange={handleChangePromt}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <StyledButton
                                                            id="btn2"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={getGenerateParagraph}
                                                        >
                                                            Generate Paragraph
                                                        </StyledButton>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        {showSpinnerPara && <Spinner text="Loading..." />}
                                                    </Grid>
                                                    {sentimentResult && (
                                                        <>
                                                            <Grid item xs={12} >
                                                                <br />
                                                                <HeaderContainer>
                                                                    <StyledHeading></StyledHeading>
                                                                    <strong>
                                                                        <FontAwesomeIcon icon={faInfoCircle} /> Final Report
                                                                    </strong>
                                                                    <StyledHeading></StyledHeading>
                                                                </HeaderContainer>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <br />
                                                                <StyledRadioGroup
                                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                                    name={radioNameText3}
                                                                    value={radiovalueText3}
                                                                    onChange={handleRadioChangeText3}
                                                                >
                                                                    <FormControlLabel
                                                                        value="box"
                                                                        name="box"
                                                                        control={<Radio />}
                                                                        label="Review"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="editor"
                                                                        name="editor"
                                                                        control={<Radio />}
                                                                        label="Editor"
                                                                    />
                                                                </StyledRadioGroup>
                                                            </Grid>
                                                            {radiovalueText3 == "box" && (
                                                                <>
                                                                    <GridHidden>
                                                                        <FlexContainer>
                                                                            <StyledEditor
                                                                                ref={editorRef}
                                                                                value={sentimentResult}
                                                                                config={config}
                                                                                tabIndex={2} // tabIndex of textarea
                                                                                onBlur={(newContent) => setSentimentResult(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                                onChange={(newContent) => { }}
                                                                            />
                                                                        </FlexContainer>
                                                                    </GridHidden>
                                                                    <GridBox item xs={12} sm={12} md={12}>
                                                                        {/* <Paragraph><strong>Observations</strong></Paragraph>
                                                                        {rteValue.result_dict.map((value, index) => (
                                                                            <>
                                                                                {index != '0' && <Paragraph>{value.content}</Paragraph>}
                                                                                {index != '0' && index % 2 == '0' && <>
                                                                                    <Paragraph><strong>Risk Analysis Result : </strong>{value["Risk Analysis Result"]} </Paragraph>
                                                                                </>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                        <Paragraph><strong>Summary</strong></Paragraph>
                                                                        {finalResult.page_summary.map((value, index) => (
                                                                            <>
                                                                                <Paragraph>{value}</Paragraph>
                                                                            </>
                                                                        ))} */}
                                                                        <Paragraph><strong> Generated Paragraph</strong></Paragraph>
                                                                        <Paragraph>{promptPara}</Paragraph>
                                                                        <br />
                                                                    </GridBox>
                                                                </>
                                                            )}
                                                            {radiovalueText3 == "editor" && (
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <br /><br />
                                                                        <FlexContainer>
                                                                            <StyledEditor
                                                                                ref={editorRef}
                                                                                value={editorSentimentResult}
                                                                                // value={sentimentResult}
                                                                                config={config}
                                                                                tabIndex={2} // tabIndex of textarea
                                                                                onBlur={(newContent) => setSentimentResult(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                                onChange={(newContent) => { }}
                                                                            />
                                                                        </FlexContainer>
                                                                        <br /><br />
                                                                    </Grid>
                                                                    <br />
                                                                    <StyledButton
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={pdfDownload}
                                                                    >
                                                                        Download as pdf &nbsp;{" "}
                                                                        <FontAwesomeIcon icon={faFilePdf} />
                                                                    </StyledButton>
                                                                    {/* <StyledButton
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={docDownload}
                                                                    >
                                                                        Download as Doc &nbsp;{" "}
                                                                        <FontAwesomeIcon icon={faFileWord} />
                                                                    </StyledButton> */}
                                                                </>
                                                            )}

                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                        </Grid>
                    </TabPanel>
                </DemoContainer>
            </Collapse>

            {showSpinner && <Spinner text="Loading..." />}

        </SolutionContainer >
    );
};

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tablepanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <PanelContainer>{children}</PanelContainer>}
        </div>
    );
}

export default memo(ColbertAnalysis);


const Label = styled.div`
  font-size: 12px;
  padding: 4px;
`;

const FlexContainer = styled.div`
  display: flex;
  position: relative;
`;

const GridBox = styled(Grid)`
padding: 10px;
border: 1px solid grey;
max-height: 400px;
overflow: auto;
`;

const DivBox = styled.div`
padding: 10px;
border: 1px solid grey;
max-height: 400px;
overflow: auto;
`;

const GridHidden = styled(Grid)`
display:none;
`;

const StyledEditor = styled(JoditEditor)`
  .jodit-workplace {
    max-height: 400px;
  }
`;


const CollapseDiv = styled.div`
margin-top: -5px;
height: 420px;
overflow: auto;
`;

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

export const StyledTabs = styled(Tabs)`
.MuiTab-root{
    text-transform: none;
}

.Mui-selected{
    background-color: #ff0055;
    color: white;
    font-weight: bold;
    text-transform: none;
  }

@media only screen and (max-width: 950px) {
  .MuiTabs-flexContainer{
    flex-wrap: wrap;
    .PrivateTabIndicator-colorPrimary-7{
      display: none;
    }
    .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
  }
}
@media only screen and (max-width: 650px) {
    background-color: #ffff;
    font-size: 12px;
    .MuiTabs-flexContainer{
      flex-direction: column;
      .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
    }
`;

const HeaderContainer = styled(Paragraph)`
  display: flex;
  align-items: center;
  justify-content: center;
  strong {
    color: #2941b7;
  }
`;

const StyledHeading = styled.div`
  width: 35%;
  margin: 0px 1rem;
  height: 3px;
  background: #fc7703;
  color: #2941b7;
  @media only screen and (max-width: 450px) {
    width: 20%;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;
