import React, { memo, useState } from "react";
import {
    Grid, Typography, Slide, Divider,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";

// import local components

import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import Collapse from "../../common/Collapse";
import {
    StyledButton,
    SolutionContainer,
    LinkContainer,
    Paragraph,
} from "../../../styles/common";

// images
import SimilarityDoc from "../../../assets/images/app/similarityDoc_soln.webp";
import SimilarityDoc2 from "../../../assets/images/app/doc_img1.jpg";
import SimilarityDoc3 from "../../../assets/images/app/doc_img2.jpg";
import HospitalCard from '../../common/HospitalCard';
import ZoomImage from "../../common/ZoomImage";


export const DocSimilarityReportDemo = () => {


    const [notification, setNotification] = useState("");

    const data = [
        [
            "Document Similarity Dashboard",
            "",
            "",
            "",
            "",
            "https://apps.vlifevirtusa.com:8503/vLifePortalSSO/embedUrl?id=8fccfb76-6301-4ae2-a5df-a755c1a2471f",
            "Click Here",
        ],

    ];

    return (
        <SolutionContainerWrapper snackbar={notification}>
            <Collapse text="Demo">
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://material.vlifevirtusa.com/Spyglass/Spyglass%20usecase.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    More Details
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
                <Grid container justifyContent="center">
                    {data.map((data) => (
                        <Grid item xs={12} sm={4} md={3}>
                            <HospitalCard
                                name={data[0]}
                                utility={data[1]}
                                link={data[2]}
                                userName={data[3]}
                                password1={data[4]}
                                s3Link={data[5]}
                                s3LinkButtonName={data[6]}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>

        </SolutionContainerWrapper>
    );
};

export default function DocSimilarityReportDesc() {

    return (
        <SolutionContainerWrapper>
            <section>
                <div>
                    <Collapse text="Description">
                        <Grid container xs={12} direction="row">

                            <Paragraph>
                                <p>This application streamlines the way clinical trial documents are compared and analyzed. Our innovative solution enables users to compare the main US clinical directory with all the local registers, identifying any loss or addition of information. This process is highly efficient, as it focuses human effort only on those studies that need modification. Clinical trials are a crucial component of medical research, providing critical data that is used to develop new treatments and therapies. However, comparing clinical trial documents can be a complex and time-consuming process, requiring significant human effort and resources. This application addresses this challenge by providing a detailed understanding of the text, enabling users to pinpoint any loss or addition of information. By identifying these differences, users can focus their human effort on those studies that need modification, thereby streamlining the process and improving efficiency.</p>
                                <p>The comparisons are done at two levels: paragraph and sentence. This approach enables us to pinpoint the sentence or paragraph that is an outlier with respect to the parent document, thereby providing valuable insights to clinicians and researchers.</p>
                                <br />
                                <center>
                                    <Grid item xs={12} md={12}>
                                        <center> <ZoomImage src={SimilarityDoc} /></center>
                                    </Grid>
                                    <strong>Architecture diagram</strong>
                                </center>
                                <br />
                                <p>This application uses a powerful transformer model that creates sentence embeddings, taking into consideration the context of the sentence and generating similarity scores. This approach is superior to traditional bag-of-words methods, as it provides a more nuanced understanding of the text. This is particularly important when dealing with translated documents, which may not be an exact match for the parent document.</p>
                                <br />
                                <p><h3>Sample clinical trial documents:</h3></p>
                                <br />
                                <Grid container xs={12} direction="row" alignItems="center">
                                    <Grid item xs={12} sm={6} md={6}>
                                        <center><ZoomImage src={SimilarityDoc2} width="90%" height /><br />
                                            <strong>Document 1</strong>
                                        </center>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <center>  <ZoomImage src={SimilarityDoc3} width="90%" /><br />
                                            <strong>Document 2</strong>
                                        </center>
                                    </Grid>
                                </Grid>
                                <br />
                                <p>The results of this comparison process are presented in an intuitive and easy-to-use dashboard. The dashboard provides users with a clear understanding of the differences between clinical trial documents, highlighting any areas where modification is necessary. The use of a user-friendly dashboard enables clinicians and researchers to quickly and efficiently compare clinical trials, providing a valuable tool for the medical community.</p>
                            </Paragraph>
                        </Grid>

                        <LinkContainer>
                            <Grid container>
                                <Grid item>
                                    <StyledButton
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        startIcon={<OpenInNewIcon />}
                                    >
                                        <a
                                            href="https://clinicaltrialsdatabase.be/en"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Belgium-ClinicalTrials
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid item>
                                    <StyledButton
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        startIcon={<OpenInNewIcon />}
                                    >
                                        <a
                                            href="https://www.toetsingonline.nl/to/ccmo_search.nsf/Searchform?OpenForm"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Netherland-ClinicalTrials
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid item>
                                    <StyledButton
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        startIcon={<OpenInNewIcon />}
                                    >
                                        <a
                                            href="https://reec.aemps.es/reec/public/web.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Spain-ClinicalTrials
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid item>
                                    <StyledButton
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        startIcon={<OpenInNewIcon />}
                                    >
                                        <a
                                            href="https://my.health.gov.il/CliniTrials/Pages/Home.aspx"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Israel-ClinicalTrials
                                        </a>
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </LinkContainer>
                    </Collapse>

                </div>
            </section>
        </SolutionContainerWrapper>
    );
};

const SubSectiontitle = styled.div`
  border-bottom: 3px solid #3175eb;
  text-align: left;
  margin-bottom: 20px;
  padding: 20px 0 10px 0;
`;
