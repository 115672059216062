import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid, TextField, MenuItem, Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import surgicalSiteInfectionPrediction_sol1 from "../../../assets/images/app/ssi-img1.webp";
import surgicalSiteInfectionPrediction_sol2 from "../../../assets/images/app/ssi-img2.webp";
import surgicalSiteInfectionPrediction_sol3 from "../../../assets/images/app/ssi-img3.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const SurgicalSiteInfectionPrediction = () => {
  const initialData = {
    operativeprocedure: "",
    hospitalcategory: "",
    hospitaltype: "",
    procedurecount: "",
    observedinfection: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("commercialAnalytics", "SurgicalSite");

  const operativeprocedure = [
    { name: "Colon Surgery", value: 0 },
    { name: "Gallbladder Surgery", value: 1 },
    { name: "Hip Prosthesis", value: 2 },
    { name: "Knee Prosthesis", value: 3 },
    { name: "Thoracic Surgery", value: 4 },
  ];

  const hospitalcategory = [
    { name: "Acute Care Hospital", value: 0 },
    { name: "Critical Access Hospital", value: 1 },
  ];

  const hospitaltype = [
    { name: "Community (125-250 Beds)", value: 0 },
    { name: "Community (<125 Beds)", value: 1 },
    { name: "Community (>250 Beds)", value: 2 },
    { name: "Critical Access", value: 3 },
    { name: "Major Teaching", value: 4 },
    { name: "Paediatric", value: 5 },
  ];

  const showSample1 = () => {
    const NewDataSet = {
      operativeprocedure: "3",
      hospitalcategory: "1",
      hospitaltype: "0",
      procedurecount: "200",
      observedinfection: "98",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      operativeprocedure: "4",
      hospitalcategory: "0",
      hospitaltype: "5",
      procedurecount: "125",
      observedinfection: "57",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      operativeprocedure, hospitalcategory, hospitaltype, procedurecount, observedinfection,
    } = sampleData;

    const params = `${operativeprocedure},${hospitalcategory},${hospitaltype},${procedurecount},${observedinfection}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Surgical site infection is an infection that occurs after surgery in the part of the body where the surgery took place. SSIs are the third most frequently reported nosocomial infection and are associated with substantial morbidity that can endanger a patient’s life, increase the number of days in the hospital, and increase healthcare costs. Patients with SSI are twice as likely to die, 60% more likely to be admitted to the intensive care unit, and more than five times more likely to be readmitted to the hospital after discharge. </p>
          <p>
            <strong>SIR (Standardized Infection Ratio):</strong>
            {' '}
            The standardized infection ratio (SIR) is a summary measure used to track HAIs (Healthcare Associated Infections) at a national, state, or local level over time. The SIR adjusts for various facility and/or patient-level factors that contribute to HAI risk within each facility. The SIR allows for a comparison to the national benchmark from a baseline time period (2006 – 2008), and can be used to measure progress from a single point in time. In other words, the SIR permits comparisons between the number of infections experienced by a facility, group, or state to the number of infections that were predicted to have occurred based on national data (i.e., baseline data).
          </p>
          <p><strong>How is the SIR (Standardized Infection Ratio) Calculated?</strong></p>
          <Grid container xs={12} direction="row" justify="center" alignItems="center">
            <Grid item xs={8} sm={4} md={5}>
              <Image src={surgicalSiteInfectionPrediction_sol1} alt="" />
            </Grid>
            <Grid item xs={10} sm={7} md={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHead>SIR </StyledTableHead>
                    <StyledTableHead>REPRESENTATION </StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      {' '}
                      SIR
                      {">"}
                      {' '}
                      1
                      {' '}
                    </TableCell>
                    <TableCell align="left"> Observed Infections are greater than predicted infections</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      {' '}
                      SIR
                      {"<"}
                      {' '}
                      1
                      {' '}
                    </TableCell>
                    <TableCell align="left"> Less observed infection than predicted infection</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      {' '}
                      SIR
                      {"="}
                      {' '}
                      1
                      {' '}
                    </TableCell>
                    <TableCell align="left"> Observed infection is same as Predicted infection </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <p>
            <strong>About dataset:</strong>
            {' '}
            The dataset is taken from Kaggle. This dataset shows the surgical site infections (SSIs) reported by a hospital to the Centre for Disease Control and Prevention National Healthcare Safety Network (NHSN). All procedure and infection data for surgical procedures performed were downloaded from NHSN (National Healthcare Safety Network) for each year’s data release. The hospital submitted procedure counts and infection counts are given in the dataset. The dataset we have is for the year 2017.The dataset contains 6841 rows and 19 columns.
          </p>
          <p><strong>Here, only 5 operative procedures (Colon surgery, Gallbladder surgery, Hip prosthesis, Thoracic surgery, Knee prosthesis) are considered for SSI prediction. These 5 procedures are selected based on their count in dataset. So, the dataset contains 1877 rows and 10 columns.</strong></p>
          <p>Here, this Machine Learning model designed to predicts the number of infections based on the past SSIs experiences of the facility and using the predicted infection values, then the SIR is calculated.</p>
          <center>
            <Image src={surgicalSiteInfectionPrediction_sol2} alt="" />
            <br />
            <Image src={surgicalSiteInfectionPrediction_sol3} alt="" />
          </center>
          <p>
            <strong>Application: </strong>
            {' '}
            The use case can help Medical Researchers to understand the factors which affect SSI and minimize it.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <PresignedS3Link
                  href="SSI/SSI.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </PresignedS3Link>
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={10} sm={6} md={5}>
              <Cards>
                <CardActionArea>
                  <CardContent>
                    <Link
                      href="https://public.tableau.com/app/profile/vlife.virtusa/viz/SurgicalSiteInfectionbyOperativeprocedure/OperativeprocedurewithHighSurgicalSiteInfection"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <center>
                        <Title gutterBottom variant="h6" component="h2">
                          <strong>Surgical Site Infection by Operative Procedure</strong>
                        </Title>
                      </center>
                    </Link>
                  </CardContent>
                </CardActionArea>
              </Cards>
            </Grid>
            <Grid item xs={10} sm={6} md={5}>
              <Cards>
                <CardActionArea>
                  <CardContent>
                    <Link
                      href="https://public.tableau.com/app/profile/vlife.virtusa/viz/County-wiseSurgicalSiteInfectioncountforparticularOperativeprocedure/Country-wiseSurgicalSiteInfectioncountforparticularOperativeprocedure"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <center>
                        <Title gutterBottom variant="h6" component="h2">
                          <strong>County wise Surgical Site Infection Count</strong>
                        </Title>
                      </center>
                    </Link>
                  </CardContent>
                </CardActionArea>
              </Cards>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="operativeprocedure"
                label="Operative Procedure"
                value={sampleData.operativeprocedure}
                onChange={handleInputs}
              >
                {operativeprocedure.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="hospitalcategory"
                label="Hospital Category"
                value={sampleData.hospitalcategory}
                onChange={handleInputs}
              >
                {hospitalcategory.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="hospitaltype"
                label="Hospital Type"
                value={sampleData.hospitaltype}
                onChange={handleInputs}
              >
                {hospitaltype.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Procedure Count"
                name="procedurecount"
                fullWidth
                onChange={handleInputs}
                value={sampleData.procedurecount}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Observed Infection"
                name="observedinfection"
                fullWidth
                onChange={handleInputs}
                value={sampleData.observedinfection}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Result>
              <strong>Result:</strong>
              {`${result}`}
            </Result>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(SurgicalSiteInfectionPrediction);

const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;

const Image = styled.img`
  width: 60%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 400px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 300px;
  }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
`;

const Cards = styled(Card)`
                width: 380px;
                margin: 1% 1% 1% 1%;
                background-color: #0080ff;
                color: white;
                @media only screen and (max-width: 900px) {
                    height: 100%;
                    width: 250px;
                  }
                  @media only screen and (max-width: 450px) {
                    height: 100%;
                    width: 250px;
                  }
                `;

const Title = styled(Typography)`
               font-size: 20px;
               @media only screen and (max-width: 900px) {
                font-size: 18px;
              }
              @media only screen and (max-width: 450px) {
                font-size: 16px;
              }
                   `;
