import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import {
  FormControlLabel,
  Grid,
  TextField,
  Radio,
  Hidden,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Paper from '@material-ui/core/Paper';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import TablePagination from "@material-ui/core/TablePagination";

// import local components
import Highcharts from 'highcharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import GCP_Data_Flow from "../../../assets/images/app/GCP_Data_Flow.webp";
import { StyledRadioGroup } from "./DcGan";

const SentimentAnalysis = () => {
  const { getQueryResult, uploadFile } = CommonService("z1dApps", "sentimentAnalysis");

  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadedfile, setUploadfile] = useState("");
  const [sentimentData, setSentimentData] = useState("");
  const [sentimentDetails, setSentimentDetails] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const getsampleResult = () => {
    setSpinner(true);
    setResult("");
    getQueryResult("sample")
      .then((res) => {
        setSpinner(false);
        setResult(res);
        setSentimentData({ sentiment: 'AllSectionDetails', details: res.AllSectionDetails });
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadedfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        setSentimentData({ sentiment: 'AllSectionDetails', details: res.AllSectionDetails });
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    if (['jpg', 'jpeg', 'pdf', 'png'].indexOf(file.name.split('.')[1]) == -1) {
      setNotification({ open: 'error', message: "please upload only jpg/jpeg/png/pdf format" });
      return false;
    }
    setUploadfile(file);
  };

  const handleChangeRowsPerPage = (event) => {
    const val = event.target.value;
    setRowsPerPage(val);
    setPage("0");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleSentimentData = (e) => {
    const sentiment = e.target.value;
    setSentimentData({ sentiment, details: result[sentiment] });
    setSentimentDetails(null);
  };
  const handleSentimentDetails = (e) => {
    const sentimentSection = e.currentTarget.id;

    setSentimentDetails({ details: result[sentimentData.sentiment][sentimentSection].Details, sentimentSection });
  };
  const stackedBarChart = () => {
    Highcharts.chart('stackedBar', {
      chart: {
        type: 'bar',
      },
      colors: ['rgb(144, 237, 125)', 'rgb(244, 91, 91)'],
      title: {
        text: 'Sentiment By Topic',
      },
      xAxis: {
        categories: result?.StackedBarChart[0],
      },
      yAxis: {
        min: 0,
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
      series: [{
        name: 'Positive',
        data: result?.StackedBarChart[1],
      }, {
        name: 'Negative',
        data: result?.StackedBarChart[2],
      }],
    });
  };
  const donutChart = () => {
    Highcharts.chart('donut', {
      chart: {
        renderTo: 'donut',
        type: 'pie',
      },
      colors: ['rgb(144, 237, 125)', 'rgb(244, 91, 91'],
      title: {
        text: 'Sentiment',
      },
      yAxis: {
        title: {
          text: 'Total percent market share',
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
        },
      },
      tooltip: {
        formatter() {
          return `<b>${this.point.name}</b>: ${this.y} %`;
        },
      },
      series: [{
        name: 'Browsers',
        colorByPoint: true,
        data: result?.DonutChartData,
        size: '50%',
        innerSize: '50%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      }],
    });
  };
  useEffect(() => {
    if (result) {
      stackedBarChart();
      donutChart();
    }
  }, [result]);

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>The Monitoring Visit Report for Interventional Studies is an important source of information for identification of compliance risk as part of GCP audits. Although there are efforts to structure the report information through predefined templates, a large portion of the report information is entered in free text. The free text format is a major obstacle for rapid extraction and subsequent use of information by auditors. As a result, Monitoring Visit Report have not used for data mining to extract insights.</p>
          <p>In this application, we perform advanced analytics and extract the semantics of the monitoring visit report and combines natural language processing (NLP) and computer-assisted review to rapidly detect potential risk or issues to enable proactive audits.</p>
          <center><img width="60%" src={GCP_Data_Flow} /></center>
          <p>A Monitoring visit report template is created having sections with questions pertaining to an clinical trial audit. Each question has an answer and optional comment with it. The entire report is passed through AWS Textract.</p>
          <p>Amazon Textract is a machine learning (ML) service that automatically extracts text, handwriting, and data from scanned documents. It goes beyond simple optical character recognition (OCR) to identify, understand, and extract data from forms and tables. Amazon Textract generates output in different formats including raw JSON, JSON for each page in the document, text, text in reading order, key/values exported as CSV, tables exported as CSV.</p>
          <p>
            The sentiment of whole report is then predicted based on sentiment analysis of each section of the report. For the sentimental analysis,
            <strong>siebert/sentiment-roberta-large-english</strong>
            {' '}
            model is used from huggingface library. This model is a fine-tuned checkpoint of RoBERTa-large (Liu et al. 2019). It enables reliable binary sentiment analysis for various types of English-language text. The model was fine-tuned and evaluated on 15 data sets from diverse text sources to enhance generalization across different types of texts (reviews, tweets and etc.,). Consequently, it outperforms models trained on only one type of text.
          </p>
          <p>
            A customized logic is created as per the template for analyzing the section and question level sentiment as predicting by the siebert model.
            Insights are generated and plotted based on the sentiment analysis.
          </p>
          <p>A sample blank template is also available for download for the user to fill and upload to test the application.</p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/MonitoringVisitReport/GCPAuditReportSentimentAnalysis.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Please click sample to explore sample report analysis or upload the report in pdf format as per the blank template
          </p>
          <br />
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={5} md={3}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getsampleResult}
              >
                Sample
              </StyledButton>
              <br />
              <a
                href="https://material.vlifevirtusa.com/ImageDeIdentification/Sample.pdf"
                target="_blank"
                rel="noreferrer"
              >
                View Sample Report
              </a>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={9} sm={5} md={3}>
              <TextField
                type="file"
                onChange={onChangeFile}
                helperText="Download template, fill it and upload as pdf"
              />
              <br />
              <a
                href="https://material.vlifevirtusa.com/MonitoringVisitReport/vLife_template_monitoring_visit_report_blank_.docx"
                rel="noreferrer"
                download
              >
                Sample template- Blank
              </a>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result
            && (
            <section>
              <Grid container xs={12} direction="row" justify="center" alignItems="center" alignContent="space-between" spacing={3}>
                <Grid item xs={12} md={7}>
                  <Papers>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHead colSpan={2} align="center">
                            <strong>Basic Report Details</strong>
                          </StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {result?.BasicInfo && Object.entries(result?.BasicInfo)
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                          .map((value) => (
                            <TableRow>
                              <TableCell>{value[0]}</TableCell>
                              <TableCell>{value[1]}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Papers>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    component="div"
                    count={19}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} direction="row" justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={7}>
                  <p><strong>Section wise Report detailed information</strong></p>
                  <Paper>
                    <Hidden xsDown>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableHead align="left">Total Sections</StyledTableHead>
                            <StyledTableHead align="left">Positive Sentiments</StyledTableHead>
                            <StyledTableHead align="left">Negative Sentiments</StyledTableHead>
                            <StyledTableHead align="left">Total Responses</StyledTableHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="0">
                            <BorderedCell align="center"><strong>{result?.TotalSectionsCount}</strong></BorderedCell>
                            <BorderedCell align="center"><strong>{result?.PositiveSectionCount}</strong></BorderedCell>
                            <BorderedCell align="center"><strong>{result?.NegativeSectionCount}</strong></BorderedCell>
                            <BorderedCell align="center"><strong>{result?.TotalResponses}</strong></BorderedCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Hidden>
                    <Hidden smUp>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <StyledTableHead align="left">Total Sections</StyledTableHead>
                            <BorderedCell align="center"><strong>{result?.TotalSectionsCount}</strong></BorderedCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableHead align="left">Positive Sentiments</StyledTableHead>
                            <BorderedCell align="center"><strong>{result?.PositiveSectionCount}</strong></BorderedCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableHead align="left">Negative Sentiments</StyledTableHead>
                            <BorderedCell align="center"><strong>{result?.NegativeSectionCount}</strong></BorderedCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableHead align="left">Total Responses</StyledTableHead>
                            <BorderedCell align="center"><strong>{result?.TotalResponses}</strong></BorderedCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Hidden>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <HighchartContainer id="donut" display />
                </Grid>
                <Grid item xs={12} md={6}>
                  <HighchartContainer id="stackedBar" display />
                </Grid>
                <Grid item xs={12}>
                  <center>
                    <Button variant="outlined" color="primary">
                      The overall sentiment of the report is predicted to be
                      {result?.ReportSentiment}
                    </Button>
                  </center>
                  <StyledRadioGroup
                    row
                    justify="center"
                    aria-label="Learn Mixture Weights"
                    name="learnMixtureWeights"
                    value={sentimentData?.sentiment}
                    onChange={handleSentimentData}
                    defaultValue="AllSectionDetails"
                  >
                    <FormControlLabel
                      control={<Radio value="AllSectionDetails" name="all" />}
                      label="All Sections"
                    />
                    <FormControlLabel
                      control={
                        <Radio value="PositiveSectionDetails" name="positive" />
                      }
                      label="Positive Sentiment Sections"
                    />
                    <FormControlLabel
                      control={<Radio value="NegativeSectionDetails" name="negative" />}
                      label="Negative Sentiment Section"
                    />
                  </StyledRadioGroup>
                </Grid>
              </Grid>
              <br />
              {sentimentData && (
              <Grid container xs={12} direction="row" justify="center" alignItems="center" alignContent="space-between" spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHead align="left">
                            Section
                          </StyledTableHead>
                          <StyledTableHead align="left">
                            Sentiment
                          </StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sentimentData?.details && Object.entries(sentimentData.details)
                          .map((value) => (
                            <StyledRow onClick={handleSentimentDetails} id={value[0]} isSelected={value[0] == sentimentDetails?.sentimentSection}>
                              <TableCell>{value[0]}</TableCell>
                              <TableCell>{sentimentData.details[value[0]].SectionSentiment}</TableCell>
                            </StyledRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <br />
                {sentimentDetails && (
                <Grid item xs={12} md={8}>
                  <Table>
                    <TableHead>
                      <SectionRow>
                        <StyledTableHead align="left" />
                        <StyledTableHead align="left">
                          Question
                        </StyledTableHead>
                        <StyledTableHead align="left">
                          Answer
                        </StyledTableHead>
                        <StyledTableHead align="left">
                          Comment
                        </StyledTableHead>
                      </SectionRow>
                    </TableHead>
                    <TableBody>
                      {sentimentDetails.details.map((value, index) => (
                        <>
                          <SectionRow>
                            <StyledTableHead>{index + 1}</StyledTableHead>
                            <TableCell>{value.Question}</TableCell>
                            <TableCell>{value.Answer}</TableCell>
                            <TableCell>{value.Comment}</TableCell>
                          </SectionRow>
                          <SectionRow>
                            <StyledTableHead>Sentiment</StyledTableHead>
                            <TableCell colSpan={2}>{value.QuestionAnswerSentiment}</TableCell>
                            <TableCell>{value.CommentSentiment}</TableCell>
                          </SectionRow>
                          <SectionRow>
                            <HighlightedCell colSpan={2}>Overall Sentiment</HighlightedCell>
                            <HighlightedCell colSpan={2} align="left">{value.OverallSentiment}</HighlightedCell>
                          </SectionRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                )}
              </Grid>
              )}
            </section>
            )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(SentimentAnalysis);

const Papers = styled(Paper)`
  overflow: auto;
  height: 400px;
  margin-top: 5%;
  cursor: pointer;
`;

const StyledTableHead = styled(TableCell)`
&.MuiTableCell-root {
  background-color: #3c40af;
  color: white;
  font-weight: bold;
  font-size: smaller;
  @media only screen and (max-width: 450px) {
    font-size: x-small;
  }
}
`;
const BorderedCell = styled(TableCell)`
&.MuiTableCell-root {
  border-right: 1px light grey;
  @media only screen and (max-width: 450px) {
    font-size: x-small;
  }
}
`;
const HighlightedCell = styled(TableCell)`
&.MuiTableCell-root {
  background-color: #ffeedd;
  @media only screen and (max-width: 450px) {
    font-size: x-small;
  }
}
`;
const StyledRow = styled(TableRow)`
  &.MuiTableRow-root {
    background-color: ${(props) => props.isSelected && '#ffe8d6'};
    &:hover {
      cursor:pointer;
      background-color: #ffe8d6;
    }
  }
`;
const SectionRow = styled(TableRow)`
&.MuiTableRow-root {
    .MuiTableCell-root {
    @media only screen and (max-width: 600px) {
        padding: 8px;
        font-size: smaller;
    }
    @media only screen and (max-width: 600px) {
        padding: 5px;
        font-size: x-small;
    }
  }
  }
`;
