import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

import { LogoImage, Paragraph, StyledButton } from "../../styles/common";
import CommonService from "../../utils/services/CommonService";

import logo from "../../assets/logos/vlifeLogo1.webp";
import { validateEmail } from "../../utils";

export default function ContactusDialog({ open, close }) {
  const initialState = {
    fName: '',
    lName: '',
    jobTitle: '',
    org: "",
    email: "",
    phone: "",
    comments: "",
  };
  const { getQueryResult } = CommonService(
    "payerProvider",
    "contactUs",
  );

  const [fields, setFields] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [showSuccessMessage, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const handleOnChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: false,
    });
  };
  const postContactDetails = () => {
    const errorObj = {};

    // check if fields are empty
    Object.keys(fields).map((key) => {
      if (key !== 'comments' && !fields[key]) {
        errorObj[key] = 'Required Field';
      }
    });

    if (Object.keys(errorObj).length > 0) {
      setErrors(errorObj);
    }
    else {
      if (!validateEmail(fields.email)) {
        setErrors({
          ...errors,
          email: 'Invalid Email',
        });
        return;
      }

      setSuccessText(false);
      setErrorText(false);
      const {
        fName, lName, jobTitle, org, email, phone, comments,
      } = fields;
      const params = `${fName}^${lName}^${jobTitle}^${org}^${email}^${phone}^${comments}`;
      getQueryResult('sendMail', encodeURIComponent(params))
        .then((res) => {
          setSuccessText(true);
        })
        .catch((err) => {
          setErrorText(err.message);
        });
    }
  };

  return (
    <section>
      <StyledDialog
        onClose={close}
        aria-labelledby="diabetes-dialog-title"
        open={open}
        fullScreen
      >
        <DialogContent>
          <Grid container direction="row">
            <Grid item xs={12} md={2}>
              <Link to="/" onClick={close}>
                <LogoImage
                  id="nav-logo"
                  src={logo}
                  alt=""
                  width="auto"
                  height="auto"
                />
              </Link>
              <CloseButton aria-label="Close" onClick={close}>
                <CloseIcon />
              </CloseButton>
            </Grid>
            <GridContent item xs={12} md={10}>
              <Heading>Find out what vLife™ can do for you.</Heading>
              <SubTitle>
                Contact us to speak to an expert, request an assessment, or demo
                our solutions.
              </SubTitle>
              <Grid container spacing={4} rowSpacing={{ xs: 3 }}>
                <Grid item xs={12} sm={5}>
                  <StyledTextField label="First Name" name="fName" value={fields.fName} onChange={handleOnChange} helperText={errors.fName} error={errors.fName} required />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <StyledTextField label="Last Name" name="lName" value={fields.lName} onChange={handleOnChange} helperText={errors.lName} error={errors.lName} required />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <StyledTextField label="JobTitle" name="jobTitle" value={fields.jobTitle} onChange={handleOnChange} helperText={errors.jobTitle} error={errors.jobTitle} required />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <StyledTextField label="Organisation" name="org" value={fields.org} onChange={handleOnChange} helperText={errors.org} error={errors.org} required />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <StyledTextField label="Email" name="email" value={fields.email} onChange={handleOnChange} helperText={errors.email} error={errors.email} required />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <StyledTextField label="Phone" name="phone" value={fields.phone} onChange={handleOnChange} helperText={errors.phone} error={errors.phone} required />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <StyledTextField width="80%" label="Comments" name="comments" value={fields.comments} onChange={handleOnChange} />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <br />
                  <StyledButton variant="contained" endIcon={<SendIcon />} onClick={postContactDetails}>
                    Contact us
                  </StyledButton>
                </Grid>
                {showSuccessMessage
                  && (
                    <Grid item xs={12}>
                      <StyledTypography variant="h6" gutterBottom success> !!! We have received your mail, our sales team will reach out to you</StyledTypography>
                    </Grid>
                  )}
                {errorText
                  && (
                    <Grid item xs={12}>
                      <StyledTypography variant="h6" gutterBottom>{errorText}</StyledTypography>
                    </Grid>
                  )}
              </Grid>
            </GridContent>

          </Grid>
        </DialogContent>
      </StyledDialog>
    </section>
  );
}
const Heading = styled.strong`
  font-size: 55px;
  font-weight: 200;
`;
const GridContent = styled(Grid)`
  margin-left: -35px;
  width: 100%
`;
const StyledTextField = styled(TextField)`
&.MuiTextField-root{
  width: ${(props) => (props.width ? props.width : "90%")};
  @media only screen and (max-width: 450px) {
    max-width: 80%;
    margin-top: 10px;
    .MuiInputBase-root{
       margin-top: 15px;
    }
  }
}
`;
const SubTitle = styled(Paragraph)`
  color: grey;
  font-weight: 350;
  font-size: 18px;
  margin-bottom: 50px;
  @media only screen and (max-width: 450px) {
    margin-bottom: 20px;
    width: 80%;
  }
`;
const CloseButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  color: gray;
`;
const StyledDialog = styled(Dialog)`
  @media only screen and (max-width: 1000px) {
    strong {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
    .MuiGrid-root {
      margin-left: 0px;
      .MuiGrid-spacing-xs-4 > .MuiGrid-item {
        padding: 0px;
      }
    }
    label + .MuiInput-formControl {
      margin-top: 25px;
    }
  }

  @media only screen and (max-width: 450px) {
    strong {
      font-size: 20px;
    }
    img{
      height: 25px;
    }
    .MuiGrid-root {
      margin-left: 5px;
      .MuiGrid-spacing-xs-4 > .MuiGrid-item {
        padding: 0px;
      }
    }
    p {
      font-size: 14px;
    }
    .MuiDialogContent-root {
      padding: 0;
    }
  }
`;

const StyledTypography = styled(Paragraph)`
    color:  ${(props) => (props.success ? '#52A451' : '#B9593C')};
    @media only screen and (max-width: 450px) {
      width: 80%
    }
`;
