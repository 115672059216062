import React, { memo, useState } from "react";
import {
  Grid, TextField, FormControl, MenuItem,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local imports

import Highcharts from "highcharts";
import styled from "styled-components";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  HighchartContainer,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const BehaviouralAnalysis = () => {
  const initialData = {
    mh: "",
    age: "",
    gender: "",
    el: "",
    emp: "",
    ahi: "",
    race: "",
    aih: "",
    vet: "",
    ms: "",
    happiness: "",
    pre: "",
    asleep: "",
    dep: "",
    pad: "",
    ftd: "",
    concentration: "",
    dd: "",
    dof: "",
  };
  const ages = [
    {
      value: 1,
      label: "18 to 24",
    },
    {
      value: 2,
      label: "25 to 29",
    },
    {
      value: 3,
      label: "30 to 34",
    },
    {
      value: 4,
      label: "35 to 39",
    },
    {
      value: 5,
      label: "40 to 44",
    },
    {
      value: 6,
      label: "45 to 49",
    },
    {
      value: 7,
      label: "50 to 54",
    },
    {
      value: 8,
      label: "55 to 59",
    },
    {
      value: 9,
      label: "60 to 64",
    },
    {
      value: 10,
      label: "65 to 69",
    },
    {
      value: 11,
      label: "70 to 74",
    },
    {
      value: 12,
      label: "75 to 79",
    },
    {
      value: 13,
      label: "80 or older",
    },
  ];

  const genders = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 2,
      label: "Female",
    },
  ];

  const pres = [
    {
      value: 2,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const vets = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 2,
      label: "No",
    },
  ];

  const els = [
    {
      value: 1,
      label: "Never attended school ",
    },
    {
      value: 2,
      label: "Elementary",
    },
    {
      value: 3,
      label: "Some high school",
    },
    {
      value: 4,
      label: "High school graduate",
    },
    {
      value: 5,
      label: "Some college or technical school",
    },
    {
      value: 6,
      label: "College graduate",
    },
  ];

  const emps = [
    {
      value: 1,
      label: "Employed for wages",
    },
    {
      value: 2,
      label: "Self-employed",
    },
    {
      value: 3,
      label: "Out of work for 1 year or more",
    },
    {
      value: 4,
      label: "Out of work for less than 1 year",
    },
    {
      value: 5,
      label: "homemaker",
    },
    {
      value: 6,
      label: "student",
    },
    {
      value: 7,
      label: "Retired",
    },
    {
      value: 8,
      label: "Unable to work",
    },
  ];

  const ahis = [
    {
      value: 1,
      label: "Less than $10,000",
    },
    {
      value: 2,
      label: "Less than $15,000",
    },
    {
      value: 3,
      label: "Less than $20,000",
    },
    {
      value: 4,
      label: "Less than $25,000",
    },
    {
      value: 5,
      label: "Less than $35,000",
    },
    {
      value: 6,
      label: "Less than $50,000",
    },
    {
      value: 7,
      label: "Less than $75,000",
    },
    {
      value: 8,
      label: "$75,000 or more",
    },
  ];

  const races = [
    {
      value: 1,
      label: "  White only, non-Hispanic ",
    },
    {
      value: 2,
      label: "Black only, non-Hispanic ",
    },
    {
      value: 3,
      label: "American Indian or Alaskan Native only, Non-Hispanic",
    },
    {
      value: 4,
      label: "Asian only, non-Hispanic",
    },
    {
      value: 5,
      label: "Native Hawaiian or other Pacific Islander only, Non-Hispanic",
    },
    {
      value: 6,
      label: "Other race only, non-Hispanic",
    },
    {
      value: 7,
      label: "Multiracial, non-Hispanic",
    },
    {
      value: 8,
      label: "Hispanic",
    },
  ];

  const mss = [
    {
      value: 1,
      label: "Married",
    },
    {
      value: 2,
      label: "Divorced",
    },
    {
      value: 3,
      label: "Widowed",
    },
    {
      value: 4,
      label: "Separated",
    },
    {
      value: 5,
      label: "Never married",
    },
    {
      value: 6,
      label: "A member of an unmarried couple",
    },
  ];

  const [parameters, setParameters] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "patientEngagement",
    "behaviouralAnalysis",
  );

  const formHighChart = (data1) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "MAJOR CONTRIBUTING FACTORS",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: "mental health",
              y: data1["mental health"],
            },
            {
              name: "age 5 years",
              y: data1["age 5 years"],
            },
            {
              name: "gender",
              y: data1.gender,
            },
            {
              name: "education",
              y: data1["mental health"],
            },
            {
              name: "employment",
              y: data1.employment,
            },
            {
              name: "income",
              y: data1.income,
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      mh: "0",
      age: "1",
      gender: "1",
      el: "1",
      emp: "1",
      ahi: "1",
      race: "1",
      aih: "1",
      vet: "1",
      ms: "1",
      happiness: "2",
      pre: "2",
      asleep: "0",
      dep: "0",
      pad: "0",
      ftd: "0",
      concentration: "0",
      dd: "0",
      dof: "0",
    };
    setParameters(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      mh,
      age,
      gender,
      el,
      emp,
      ahi,
      race,
      aih,
      vet,
      ms,
      happiness,
      pre,
      asleep,
      dep,
      pad,
      ftd,
      concentration,
      dd,
      dof,
    } = parameters;

    const params = `${mh},${age},${gender},${el},${emp},${ahi},${race},${aih},${vet},${ms},${happiness},${pre},${asleep},${dep},${pad},${ftd},${concentration},${dd},${dof}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.isHealth);
          console.log(res);
          formHighChart(res.pro_col["0"]);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <p>
              Patient behavior plays a crucial role in adherence and the success
              of the treatment. Healthcare Professionals (HCPs) who understand
              patient behavior and behavioral models and frameworks can design
              more successful patient support programs that are aware of the
              needs of their patients. Pharmaceutical companies can also help
              patients improve their medication adherence and self-management,
              leading to better results and reduced burden on the healthcare
              system.
            </p>
            <b>Problem:</b>
            {' '}
            The objective of the solution is to predict an
            individual's health by deeper analysis of Demographic and
            behavioural analysis.
            <br />
            <br />
            <b>Solution:</b>
            {' '}
            Solution to build a predictive model in order to
            assess patient health based on its behavioural assessment. Advanced
            Level Machine Learning Model is trained to predict individual's
            health. The dataset is an open source dataset provided by the center
            for Disease Control and prevention. Patient data for several years
            is recorded and projected into Prediction model.
            <br />
            <br />
            <b>Predicted Outcome</b>
            <ol>
              <li>Patient Health status - Good / Bad</li>
              <li>Features contributing to Prediction are mentioned below</li>
            </ol>
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/cdc/behavioral-risk-factor-surveillance-system"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Behavioural_Assesment/Assessment%20of%20Patient%20Health.ipynb"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <StyledDemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <Grid container spacing={2}>
            <Grid item sm={1} />
            <Grid item xs={12} sm={4}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Poor Mental Health(Days)*"
                  name="mh"
                  value={parameters.mh}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Gender*"
                  name="gender"
                  value={parameters.gender}
                  onChange={onChangeFields}
                >
                  {genders.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Employment Status*"
                  name="emp"
                  value={parameters.emp}
                  onChange={onChangeFields}
                >
                  {emps.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Race*"
                  name="race"
                  value={parameters.race}
                  onChange={onChangeFields}
                >
                  {races.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Martial Status*"
                  name="ms"
                  value={parameters.ms}
                  onChange={onChangeFields}
                >
                  {mss.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Pregnant*"
                  name="pre"
                  value={parameters.pre}
                  onChange={onChangeFields}
                >
                  {pres.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Number of Days of Depression*"
                  name="dep"
                  value={parameters.dep}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Felt tired Days*"
                  name="ftd"
                  value={parameters.ftd}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Depression Days*"
                  name="dd"
                  value={parameters.dd}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Days of Fidgety*"
                  name="dof"
                  value={parameters.dof}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} />

            <Grid item xs={12} sm={4}>
              <FormControl minWidth="80" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Age*"
                  name="age"
                  value={parameters.age}
                  onChange={onChangeFields}
                >
                  {ages.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Education Level"
                  name="el"
                  value={parameters.el}
                  onChange={onChangeFields}
                >
                  {els.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  select
                  label="Annual Household Income*"
                  name="ahi"
                  value={parameters.ahi}
                  onChange={onChangeFields}
                >
                  {ahis.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Number of Adults in Household*"
                  name="aih"
                  value={parameters.aih}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Veteran*"
                  name="vet"
                  value={parameters.vet}
                  onChange={onChangeFields}
                >
                  {vets.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Number of Days of Happiness*"
                  name="happiness"
                  value={parameters.happiness}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Number of Days of trouble falling asleep*"
                  name="asleep"
                  value={parameters.asleep}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Number of Days of Poor appetites*"
                  name="pad"
                  value={parameters.pad}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Number of Days of Tiredness*"
                  name="concentration"
                  value={parameters.concentration}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} />
          </Grid>
          <center>
            <Grid item xs={12}>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                SAMPLE DATA
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                EXECUTE
              </StyledButton>
            </Grid>
          </center>
          <Grid item xs={12}>
            <section>
              <Grid item xs={12}>
                {showSpinner && <Spinner text="Loading..." />}

                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`The patient is predicted to have a ${resultData} based on the Demographic and behavioural analysis provided<`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer id="container_1" display />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </section>
          </Grid>
        </StyledDemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(BehaviouralAnalysis);
const StyledDemoContainer = styled(DemoContainer)`
  text-align: left;
`;
