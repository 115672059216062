import getUserData from '../../utils';
import { CURRENT_ENV } from '../../utils/services/api-config';
import * as actions from '../actions';

const userId = getUserData()?.token?.emailid;
console.log(userId)
const initialState = {
  Context_param: {
      ProductSuite: 'Datachefv2',
      Environment: CURRENT_ENV,
      Actions: '',
      DependsOn: '',
      UserID: userId,
    },
};
console.log(initialState)
const dataChefProjectData = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PROJECT: {
      const projectData = action.payload;
      return { ...state, projectData };
    }
    case actions.SET_FEATURE_DATA_CHEF: {
      const Context_param = { ...state.Context_param, ...action.payload };
      return { ...state, Context_param };
    }
    case actions.CREATE_WORKFLOW: {
      const projectData = action.payload;
      return { ...state, projectData };
    }
    case actions.CREATE_JOBS: {
      const projectData = action.payload;
      return { ...state, projectData };
    }
    case actions.SET_DATACHEF_INPUT_DATA: {
      const userInputData = { ...state.userInputData, ...action.payload };
      return { ...state, userInputData };
    }
    default:
      return state;
  }
};
export default dataChefProjectData;
