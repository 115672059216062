import React, { memo, useState } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import styled from "styled-components";

function Upload({ helperText, handler }) {
  const [fileName, setFileName] = useState("");

  const onFileUpload = (e) => {
    const file = e.target.files[0];
    handler(file);
    setFileName(file && file.name);
  };
  return (
    <Container>
      <Input
        name="uploadedFile"
        onChange={onFileUpload}
        id="icon-button-file"
        type="file"
      />
      <Label variant="contained" color="primary" htmlFor="icon-button-file">
        <CloudUploadIcon fontSize="large" />
        <span>Upload</span>
      </Label>
      {fileName && (
      <SelectedText>
        {' '}
        {fileName}
      </SelectedText>
      )}
      {/* {helperText && <SelectedText> {helperText}</SelectedText>} */}
    </Container>
  );
}

export default memo(Upload);

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  color: #3f51b5;
  border: 1px solid rgba(63, 81, 181, 0.5);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 0.9375rem;
`;
const SelectedText = styled.span`
  font-size: 0.7375rem;
  color: #3f51b5;
`;
const Input = styled.input`
  display: none;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
