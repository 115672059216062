import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Grid, TextField, MenuItem, Typography,
} from "@material-ui/core";
import Highcharts from "highcharts";

// local components

import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  ListItem,
  HighchartContainer,
} from "../../../styles/common";

const ShapleyEMR = () => {
  const initialData = {
    modal: "",

  };

  const [sampleData, setSampleData] = useState(initialData);
  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [result, setResult] = useState(false);
  const [baseModal, setBaseModal] = useState(false);
  const [shapleyModel, setShapleyModel] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);
  const [showSpinner2, setSpinner2] = useState(false);
  const [showSpinner3, setSpinner3] = useState(false);
  const [notification, setNotification] = useState("");

  const { postQueryResult, getQueryResult } = CommonService(
    "sailApps", "shapleyEMR",
  );

  const formHighChart = (data1) => {
    Highcharts.chart('container',
      {
        chart:
        {
          type: 'column',
        },
        title:
        {
          text: data1[0],
        },
        subtitle:
        {

        },
        xAxis: {
          categories: ["Accuracy", "AUC_ROC", "Precision", "Recall"],
          title: {
            text: null,
          },
        },
        yAxis:
        {
          min: 0,
          title:
          {
            text: data1[2],

          },
          labels:
          {
            overflow: 'justify',
          },
        },
        tooltip:
        {
          valueSuffix: '',
          headerFormat: '',
        },
        legend:
        {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
        },
        series: data1[3],
      });

    /*  second */
    Highcharts.chart('container_1', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Shapley Value Split',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [{
        name: 'Values',
        colorByPoint: true,
        data: [{
          name: 'Positive',
          y: data1[4],
          color: '#bdef9e',

        }, {
          name: 'Negative',
          y: data1[5],
          color: '#ef6e6e',

        }],
      }],
    });
  };

  const getSampleResult = () => {
    setSpinner1(true);
    setShapleyModel('');
    setBaseModal('');
    setField([]);
    setDataRows([]);
    setResult('');

    getQueryResult("sampleData")
      .then((res) => {
        setSpinner1(false);
        const previewData = JSON.parse(res.Data);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setField(fields);
        setDataRows(dataRows);
        setResult(res);
      })
      .catch((err) => {
        console.log(err);
        setSpinner1(false);
        setNotification({ open: "error", message: err.message });
      });
    formHighChart('');
  };

  const getBaseModal = () => {
    const {
      modal,
    } = sampleData;

    const params = `${modal}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner2(true);
      setShapleyModel('');
      setBaseModal('');

      postQueryResult("shapleyModel", { params })
        .then((res1) => {
          setSpinner2(false);
          setBaseModal(res1["Before Shapley Base Model Metrics "]);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
      formHighChart('');
    }
  };

  const getShapleyModel = () => {
    const {
      modal,
    } = sampleData;

    const params = `${modal}`;
    setSpinner3(true);
    setShapleyModel('');

    postQueryResult("retrainModel", { params })
      .then((res1) => {
        setSpinner3(false);
        console.log(res1);
        setShapleyModel(res1[3][1].data);
        formHighChart(res1);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const handleInputs = (e) => {
    setShapleyModel('');
    setBaseModal('');
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            As data becomes the fuel driving technological and economic growth, a fundamental challenge
            is how to quantify the value of data in algorithmic predictions and decisions
          </p>
          <p>
            In this work, a principled framework to address data valuation in the context of supervised
            machine learning is showcased. Monte Carlo and gradient-based methods are implemented to efficiently
            estimate data Shapley values
          </p>
          <strong>Benefits Include:</strong>
          <ListItem>
            It is more powerful than the popular leave-one-out or leverage score algorithms
          </ListItem>
          <ListItem>
            Low Shapley value data effectively capture outliers and corruptions
          </ListItem>
          <ListItem>
            High Shapley value data inform what type of new data to acquire to improve the predictor
          </ListItem>
          <p>
            Given an EMR dataset centered on Type II Diabetes, Shapley can be applied to identify the noise in
            the dataset and systematically eliminate it. A balanced T2D dataset is presented below for Shapley demo.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/SAIL/Shapley-EMR/t2d_shapley_documented.ipynb"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://arxiv.org/abs/1904.02868"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>

            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSampleResult}
            >
              Sample Data
            </StyledButton>
          </Grid>
          {showSpinner1 && <Spinner text="Loading..." />}
          {result && (
            <div>
              <TablePaper>
                <Table>
                  <TableHead>
                    <TableRow>
                      {fields.map((field) => (
                        <TableCell key={field.name} align="left"><strong>{field.name}</strong></TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataRows.map((row) => (
                      <TableRow key={row.index}>
                        {fields.map((field) => (
                          <TableCell align="left">
                            {field.name === "index"
                              ? row[field.name] + 1
                              : row[field.name]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TablePaper>
              <section>
                <Grid container spacing={2} direction="row" justify="center" alignContent="center" alignItems="center">
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography>Choose machine learning model</Typography>
                  </Grid>
                  <Grid item xs={10} sm={6} md={4}>
                    <TextField
                      variant="outlined"
                      select
                      fullWidth
                      name="modal"
                      label="Machine Learning Model"
                      value={sampleData.modal}
                      onChange={handleInputs}
                    >
                      <MenuItem key="0" value="logistic">Logistic</MenuItem>
                      <MenuItem key="1" value="RandomForest">Random Forest</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getBaseModal}
                    >
                      Submit
                    </StyledButton>
                  </Grid>
                </Grid>
              </section>
              {showSpinner2 && <Spinner text="Loading..." />}
              {baseModal
                && (
                <section>
                  <Grid container direction="row" spacing={3} justifyContent="center" alignContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={6}>
                      <p><strong>Base Model Metrics</strong></p>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableHead align="left">Metric</StyledTableHead>
                            <StyledTableHead align="left">Value</StyledTableHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="0">
                            <TableCell align="left">Accuracy</TableCell>
                            <TableCell align="left">{baseModal.model_accuracy}</TableCell>
                          </TableRow>
                          <TableRow key="1">
                            <TableCell align="left">AUC</TableCell>
                            <TableCell align="left">{baseModal.roc_auc_score_model}</TableCell>
                          </TableRow>
                          <TableRow key="2">
                            <TableCell align="left">Precision</TableCell>
                            <TableCell align="left">{baseModal.model_precision}</TableCell>
                          </TableRow>
                          <TableRow key="3">
                            <TableCell align="left">Recall</TableCell>
                            <TableCell align="left">{baseModal.model_recall}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={getShapleyModel}
                      >
                        Retrain with shapley
                      </StyledButton>
                    </Grid>
                  </Grid>
                  {showSpinner3 && <Spinner text="Loading..." />}
                  {shapleyModel
                    && (
                    <section>
                      {shapleyModel && sampleData.modal == "logistic"
                        && (
                        <section>
                          <Grid container spacing={2} xs={12} direction="row" justify="center">
                            <Grid item xs={12} sm={8} md={6}>
                              <strong><p>Shapley Model Metrics</p></strong>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableHead align="left">Metric</StyledTableHead>
                                    <StyledTableHead align="left">Value</StyledTableHead>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow key="0">
                                    <TableCell align="left">Accuracy</TableCell>
                                    <TableCell align="left">{shapleyModel[0]}</TableCell>
                                  </TableRow>
                                  <TableRow key="1">
                                    <TableCell align="left">AUC</TableCell>
                                    <TableCell align="left">{shapleyModel[1]}</TableCell>
                                  </TableRow>
                                  <TableRow key="2">
                                    <TableCell align="left">Precision</TableCell>
                                    <TableCell align="left">{shapleyModel[2]}</TableCell>
                                  </TableRow>
                                  <TableRow key="3">
                                    <TableCell align="left">Recall</TableCell>
                                    <TableCell align="left">{shapleyModel[3]}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Grid>
                          </Grid>
                        </section>
                        )}
                      <Grid container xs={12} spacing={2} direction="row" justify="center">
                        <Grid item xs={12} sm={8} md={4}>
                          <HighchartContainer
                            id="container_1"
                            display={shapleyModel}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={1} />
                        <Grid item xs={12} sm={8} md={6}>
                          <HighchartContainer
                            id="container"
                            display={shapleyModel}
                          />
                        </Grid>
                      </Grid>
                    </section>
                    )}
                </section>
                )}
            </div>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(ShapleyEMR);

const TablePaper = styled.div`
  overflow: auto;
  margin-bottom: 5%;
`;
export const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
