const WSClientWrapper = {
  wrappedFetch(url, config) {
    return fetch(url, config)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw Error(response.status);
      })
      .then((json) => json)
      .catch((error) => {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message,
        );
        throw Error(error);
      });
  },
};

export { WSClientWrapper };
