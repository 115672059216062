import React from "react";
import styled from "styled-components";

const Error = ({ code = "500" }) => (
  <>
    <FourZeroFour>{code}</FourZeroFour>
    <Oops>Oops! Something went wrong...</Oops>
    <SubText>The page you're looking for no longer exists.</SubText>
  </>
);

export default Error;

export const FourZeroFour = styled.p`
  color: #FC7703;
  font-size: 60px;
  text-align: left;
  text-transform: none;
  font-weight: 700;
  margin: 30vh 0px 0px 0px;
`;

export const Oops = styled.p`
  color: #2941b7;
  font-size: 48px;
  text-align: left;
  text-transform: none;
  font-weight: 400;
  margin: 10px 0px 0px 0px;
`;

export const SubText = styled.p`
  color: #5b5b5b;
  font-size: 18px;
  text-align: left;
  text-transform: none;
  font-weight: 500;
  margin: 20px 0px 0px 0px;
`;
