import { Grid, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import ZoomImage from "../../../common/ZoomImage";

export default function DataSetDetails(props) {
  return (
    <div>
      <ul>
        {Object.entries(props?.data?.texts)?.map((ele) => (
          <>
            <li>
              <b>{ele[0]}: </b>
              {ele[1]}
            </li>
          </>
        ))}
      </ul>
        <div>
          {props.data?.text?.map((item)=>(
          <Typography>{item.data} </Typography>
        )
        )}
        </div>
      <Grid container>
        {props.data?.images?.map((ele) => (
          <Grid item xs={12} md={ele.grid}>
            <figure>
              {ele.title && <figcaption><h3>{ele.title}</h3></figcaption>}
              {ele.src && <ZoomImage src={ele.src} width='100%'/>}
            </figure>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
const StyledImg = styled.img`
  width: -webkit-fill-available;
`;
const OrderedList = styled.ol`
  font-weight: bold;
`;
