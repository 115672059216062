import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import styled from "styled-components";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";

import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";

const ChronicKidneyDisease = () => {
  const initialData = {
    age: "",
    gender: "",
    race: "",
    carbonDioxide: "",
    chloride: "",
    glucose: "",
    triglyrecide: "",
    bodyWeight: "",
    glomerularFilterationRate: "",
    creatinine: "",
    hematocrit: "",
    plateletCount: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "mlModels",
    "chronicKidneyDisease",
  );

  const gender = [
    { name: "Female", value: 0 },
    { name: "Male", value: 1 },
  ];

  const race = [
    {
      value: 0,
      name: "Asian",
    },
    {
      value: 1,
      name: "Black",
    },
    {
      value: 2,
      name: "Hispanic",
    },
    {
      value: 3,
      name: "Native",
    },
    {
      value: 4,
      name: "Other",
    },
    {
      value: 5,
      name: "White",
    },
  ];

  const showSample1 = () => {
    const NewDataSet = {
      age: "41",
      gender: "1",
      race: "5",
      carbonDioxide: "24.36",
      chloride: "109.93",
      glucose: "91.43",
      triglyrecide: "109.2",
      bodyWeight: "89.9",
      glomerularFilterationRate: "101.53",
      creatinine: "1.3",
      hematocrit: "46.6",
      plateletCount: "402.16",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const showSample2 = () => {
    const NewDataSet = {
      age: "60",
      gender: "1",
      race: "5",
      carbonDioxide: "23.26",
      chloride: "107.2",
      glucose: "78.2",
      triglyrecide: "127.01",
      bodyWeight: "74.66",
      glomerularFilterationRate: "76.9",
      creatinine: "1.63",
      hematocrit: "39.66",
      plateletCount: "250.66",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      age,
      gender,
      race,
      carbonDioxide,
      chloride,
      glucose,
      triglyrecide,
      bodyWeight,
      glomerularFilterationRate,
      creatinine,
      hematocrit,
      plateletCount,
    } = sampleData;

    const params = `${age},${gender},${race},${carbonDioxide},${chloride},${glucose},
        ${triglyrecide},${bodyWeight},${glomerularFilterationRate},${creatinine},${hematocrit},
        ${plateletCount}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          setResultData(res.isCKD);

          const params = [];
          const percentages = res.percentage[0];
          for (const propertyName in percentages) {
            params.push({
              name: propertyName,
              y: percentages[propertyName],
            });
          }

          Highcharts.chart('prediction-chart', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Demo">
        <Paragraph>
          <p>
            Chronic kidney disease (CKD) is a slow and progressive loss of kidney function over a period of several years.
            Eventually, a person will develop permanent kidney failure. Also known as chronic renal failure, chronic renal
            disease, or chronic kidney failure, is much more widespread than people realize.
          </p>
          <p>
            Type 2 Diabetes is a major pre cursor for the chronic kidney disease. There is no cure for chronic kidney
            disease, which means treatment is focused on reducing symptoms.
          </p>
          <p>
            In this POC, the model identifies the subjects susceptible to chronic kidney disease within 3 years of onset
            of Type II diabetes. The model is trained on real world data from state of Indiana in collaboration with Indiana
            Bio Research Institute
          </p>
          <p>
            The data considered has more 1000+ significant features and required deep statistical and domain expertise to select
            final features that made it to model building. The identification of a CKD patient is the priority for the model so it
            weighs False Negatives more intensely than False Positives leaving nothing to chance.
          </p>
        </Paragraph>
        <DemoContainer>
          <h4>Please provide the following data inputs</h4>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Age"
                      name="age"
                      value={sampleData.age}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={10} md={6}>
                  <TextField
                    variant="outlined"
                    select
                    fullWidth
                    name="gender"
                    label="Gender"
                    value={sampleData.gender}
                    onChange={onChangeFields}
                  >
                    {gender.map((data) => (
                      <MenuItem value={data.value}>{data.name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={10} md={6}>
                  <TextField
                    variant="outlined"
                    select
                    fullWidth
                    name="race"
                    label="Race"
                    value={sampleData.race}
                    onChange={onChangeFields}
                  >
                    {race.map((data) => (
                      <MenuItem value={data.value}>{data.name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <br />

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Carbondioxide, total in Blood"
                      name="carbonDioxide"
                      value={sampleData.carbonDioxide}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Chloride in Blood"
                      name="chloride"
                      value={sampleData.chloride}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Glucose in Blood"
                      name="glucose"
                      value={sampleData.glucose}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Triglyceride in Serum or Plasma"
                      name="triglyrecide"
                      value={sampleData.triglyrecide}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Body Weight"
                      name="bodyWeight"
                      value={sampleData.bodyWeight}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Glomerular filteration rate"
                      name="glomerularFilterationRate"
                      value={sampleData.glomerularFilterationRate}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Creatinine in Blood"
                      name="creatinine"
                      value={sampleData.creatinine}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Hematocrit of Blood Count"
                      name="hematocrit"
                      value={sampleData.hematocrit}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Platelets in Blood Count"
                      name="plateletCount"
                      value={sampleData.plateletCount}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <BorderGray>
                <Grid container xs={12} sm={12} md={12} spacing={2} justify="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <center>
                      Not sure about valid data inputs? Try any of the following
                      sample data...
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={showSample1}
                    >
                      Sample 1
                    </StyledButton>

                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={showSample2}
                    >
                      Sample 2
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </Grid>
                </Grid>
              </BorderGray>
              <br />
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Output</h3>
              {showSpinner && <Spinner text="Loading..." />}
              {resultData && (
              <section>
                <Result>
                  <strong>Result:</strong>
                  Chronic Kidney Disease:
                  {' '}
                  {`${resultData}`}
                </Result>
                <Grid container direction="row" justify="center">
                  <Grid item xs={12}>
                    <HighchartContainer id="prediction-chart" display />
                  </Grid>
                </Grid>
              </section>
              )}
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ChronicKidneyDisease);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 5px;
  margin-top: 3%;
   @media only screen and (max-width: 750px) {
    text-align: center;
  }
`;
