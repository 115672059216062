import React, { memo, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { subFeatureData } from '../../constants';
import { StyledInput } from '../../InputForm';
import { StyledButton } from '../../../../../../styles/common';
import {
  clearInputData,
  setInputData,
} from '../../../../../../store/actions/vnet';
import { InfoText } from '../ModelOptimization/DataShapley';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const CSVForm = memo((props) => {
  const { userInputData, Context_param, isCanvasClicked } = useSelector(
    (state) => state.vnet,
  );
  const fieldList = Context_param?.FunctionID
    && subFeatureData[Context_param?.FunctionID].fields;
  const dispatch = useDispatch();
  const inputRef = useRef();

  const handleChangeFile = () => {
    if (userInputData.fileName) {
      if (
        confirm(
          'If u wish to change file, all previous actions will be erased!. Click ok to continue',
        )
      ) {
        dispatch(clearInputData());
      }
    }
  };
  const handleFormReset = () => {
    inputRef.current.value = '';
    dispatch(clearInputData());
  };

  const uploadSampleDataset = async () => {
    inputRef.current.value = '';
    dispatch(setInputData({
      isSampleData: true,
      isCustomFile: false,
      error_file: false
    }))
  }

  const isFormValid = () => true;


  return (
    fieldList && (
      <center>
        {userInputData.fileName ? (
          <UploadedContainer>
            <p>
              <strong>Uploaded File:</strong>
              {' '}
              {userInputData.fileName}
            </p>
            <Tooltip
              arrow
              placement="top"
              title="This action will replace the current file!"
            >
              <StyledButton
                variant="outlined"
                color="primary"
                onClick={handleChangeFile}
              >
                Change File
              </StyledButton>
            </Tooltip>
          </UploadedContainer>
        )
          : <>
            <StyledInput
              type="file"
              onChange={props.handleOnChange}
              variant="outlined"
              disabled={userInputData.fileName}
              inputRef={inputRef}
              id="csvInput"
              error={userInputData?.error_file}
              helperText={userInputData?.error_file && 'Please Upload a csv file'}
            />
            <center><p>OR</p></center>
            <StyledButton
              variant={userInputData?.isSampleData ? "contained" : "outlined"}
              color="primary"
              onClick={uploadSampleDataset}
            >
              Sample Dataset
            </StyledButton>
            {userInputData?.isSampleData && <InfoText success={true}><FontAwesomeIcon icon={faCheckCircle} /> You have chosen Sample Data</InfoText>}
            {userInputData?.isCustomFile && <InfoText success={true}><FontAwesomeIcon icon={faCheckCircle} /> Custom file uploaded</InfoText>}
          </>}

        {props.render({
          handleFormReset,
          isFormValid,
          saveCallback: 'saveFileData',
          hideResetIfCanva: isCanvasClicked,
          isSaveDisabled: userInputData.fileName,
        })}
      </center>
    )
  );
});

const UploadedContainer = styled.section`
  text-align: center;
`;
