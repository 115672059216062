import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
// local components
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";
import TxtGenStepper from "./TxtGenStepper";
import { Link } from "react-router-dom";

const TextGenerationDesc = () => {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Natural Language Processing (NLP) is an Artificial Intelligence
            technology concerned with the interactions between computers and
            human language. This technology enables computers to process human
            language in the form of text or voice data and to ‘understand’ its
            full meaning, complete with the speaker or writer’s intent and
            sentiment.
          </p>
          <p>
            The application has Transformer Models from OpenAI and also GPT-2
            models that are trained with custom corpuses. The main motive of
            this application is to be useful in the HealthCare Industry, hence
            the custom trained models are trained on medical corpuses.
          </p>
          <p>
            This medical corpus trained models can generate texts and also
            answer questions that Pharmaceutical personnels require. For
            example, the trained model can list out the entire procedure for any
            surgery. It can also list out drugs / medicines that are needed for
            a medical treatment. Based on the corpus it is trained with, the
            scope of this application can be widely increased.
          </p>
          <p>
            This application also allows the user to train the model with their
            own corpus and generates a series of statements based on the prompt
            sentence they provide. The user’s input can be any generic
            statement. The output series of generated statements can also be
            customized by the number and length of statements to be generated.
          </p>
          <p>
            This application has Generative Pretrained models from OpenAI
            namely:
          </p>
          <ul>
            <li>
              GPT-3: both text-davinci-003 and text-davinci-002 – the auto
              regressive language model that produces human-like text.
            </li>
            <li>
              GPT-2 : transformer-based model to produce next words in a
              sentence.
            </li>
            <li>
              BYOD GPT-2: Custom trained models on various corpuses.
              <ul>
                <li>
                  MS Marco GPT-2: This version of GPT-2 has been pre trained
                  with Microsoft Machine Reading Comprehension (MS Marco)
                  corpus.
                </li>
                <li>
                  MIMIC –III GPT-2: This version of GPT-2 has been pre trained
                  with Medical Information Mart for Intensive Care III (MIMIC
                  III) clinical notes corpus.
                </li>
                <li>
                  PubMed GPT-2: This version of GPT-2 has been pre trained with
                  PubMed corpus which has citations of biomedical literature
                  from MEDLINE.
                </li>
              </ul>
            </li>
          </ul>
        </Paragraph>
      </Collapse>
    </SolutionContainerWrapper>
  );
};
export function TextGenerationDemo() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Demo">
        <DemoContainer>
          <TxtGenStepper />
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
}

export default memo(TextGenerationDesc);
