import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
} from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";

// images
import geneticVariantClassification_sol1 from "../../../assets/images/app/geneticVariantClassification_sol1.webp";

const GeneticVariantClassification = () => {
  const initialData = {
    CHROM: "",
    REF: "",
    ALT: "",
    AF_ESP: "",
    AF_EXAC: "",
    AF_TGP: "",
    CLNVC: "",
    ORIGIN: "",
    Allele: "",
    IMPACT: "",
    EXON: "",
    STRAND: "",
    LoFtool: "",
    CADD_PHRED: "",
    CADD_RAW: "",
  };

  const allel_origin = [
    {
      value: 0,
      label: "Unknown",
    },
    {
      value: 1,
      label: "Gemline",
    },
    {
      value: 2,
      label: "Somantic",
    },
    {
      value: 4,
      label: "Inherited",
    },
    {
      value: 8,
      label: "Paternal",
    },
    {
      value: 16,
      label: "Maternal",
    },
    {
      value: 32,
      label: "De-novo",
    },
    {
      value: 64,
      label: "Biparental",
    },
    {
      value: 128,
      label: "Uniparental",
    },
    {
      value: 256,
      label: "Not Tested",
    },
    {
      value: 512,
      label: "Tested Inconclusive",
    },
    {
      value: 1073741824,
      label: "Other",
    },
  ];

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "bioInformatics",
    "geneticVariantClassifier",
  );

  const formHighChart = (data) => {
    const params = [];
    data.map((arr, index) => params.push({
      name: arr[0],
      y: arr[1],
    }));
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: params,
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      CHROM: "0",
      REF: "2",
      ALT: "1",
      AF_ESP: "0",
      AF_EXAC: "0",
      AF_TGP: "0",
      CLNVC: "3",
      ORIGIN: "1",
      Allele: "1",
      IMPACT: "2",
      EXON: "39",
      STRAND: "1",
      LoFtool: "771",
      CADD_PHRED: "8316",
      CADD_RAW: "25346",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const showResult2 = () => {
    const NewDataSet = {
      CHROM: "22",
      REF: "1",
      ALT: "4",
      AF_ESP: "0",
      AF_EXAC: "0",
      AF_TGP: "0",
      CLNVC: "3",
      ORIGIN: "1",
      Allele: "4",
      IMPACT: "2",
      EXON: "568",
      STRAND: "0",
      LoFtool: "10",
      CADD_PHRED: "9230",
      CADD_RAW: "50538",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      CHROM,
      REF,
      ALT,
      AF_ESP,
      AF_EXAC,
      AF_TGP,
      CLNVC,
      ORIGIN,
      Allele,
      IMPACT,
      EXON,
      STRAND,
      LoFtool,
      CADD_PHRED,
      CADD_RAW,
    } = sampleData;

    const params = `${CHROM},${REF},${ALT},${AF_ESP},${AF_EXAC},${AF_TGP},${CLNVC},${ORIGIN},${Allele},${IMPACT},${EXON},${STRAND},${LoFtool},${CADD_PHRED},${CADD_RAW}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res.result);
          setSpinner(false);
          setResultData(res.result);
          formHighChart(res.importance);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            ClinVar is a freely accessible, public archive of reports of the
            relationships among human variations and phenotypes. ClinVar
            processes submissions reporting variants found in patient samples,
            assertions made regarding their clinical significance, information
            about the submitter, and other supporting data.
          </p>
          <p>
            These variants are (usually manually) classified by clinical
            laboratories on a categorical spectrum ranging from benign, likely
            benign, uncertain significance, likely pathogenic, and pathogenic.
            Variants that have conflicting classifications (from laboratory to
            laboratory) can cause confusion when clinicians or researchers try
            to interpret whether the variant has an impact on the disease of a
            given patient.
          </p>
          <p>
            This application predicts whether ClinVar variant will have
            conflicting classifications. This is presented as a binary
            classification problem, where each record in the dataset is a
            genetic variant.
          </p>
          <p>
            Conflicting classifications are when two of any of the following
            three categories are present for one variant, two submissions of one
            category are not considered conflicting.
          </p>
          <ul type="disc">
            <li>Likely Benign or Benign </li>
            <li>VUS </li>
            <li>Likely Pathogenic or Pathogenic </li>
          </ul>
          <br />
          <center>
            <img src={geneticVariantClassification_sol1} alt="" />
          </center>

          <section>
            <p>
              Conflicting classifications are when two of any of the following
              three categories are present for one variant, two submissions of
              one category are not considered conflicting.
            </p>
            <ul>
              <li>Likely Benign or Benign</li>
              <li>VUS</li>
              <li>Likely Pathogenic or Pathogenic</li>
            </ul>
          </section>
          <Grid>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <h3>Name</h3>
                    </TableCell>
                    <TableCell>
                      <h3>Description</h3>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>CHROM</TableCell>
                    <TableCell>Chromosome the variant is located on</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>REF</TableCell>
                    <TableCell>Reference Allele</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ALT</TableCell>
                    <TableCell>Alternate Allele</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>AF_ESP</TableCell>
                    <TableCell>
                      Allele frequencies from GO-ESP(Exon Sequencing Project)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>AF_EXAC</TableCell>
                    <TableCell>
                      Allele frequencies from ExAC (Exome Aggregation
                      Consortium)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>AF_TGP</TableCell>
                    <TableCell>
                      Allele frequencies from the 1000 genomes project
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CLNVC</TableCell>
                    <TableCell>Variant Type</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ORIGIN</TableCell>
                    <TableCell>
                      Allele origin. One or more of the following values may be
                      added: 0 - unknown; 1 - germline; 2 - somatic; 4 -
                      inherited; 8 - paternal; 16 - maternal; 32 - de-novo; 64 -
                      biparental; 128 - uniparental; 256 - not-tested; 512 -
                      tested-inconclusive; 1073741824 – other
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ALLELE</TableCell>
                    <TableCell>
                      The variant allele used to calculate the consequence
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IMPACT</TableCell>
                    <TableCell>
                      The impact modifier for the consequence type
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>EXON</TableCell>
                    <TableCell>The exon number (out of total number)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>STRAND</TableCell>
                    <TableCell>
                      Defined as + (forward) or - (reverse).
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>LoFtool</TableCell>
                    <TableCell>
                      Loss of Function tolerance score for loss of function
                      variants
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CADD_PHRED</TableCell>
                    <TableCell>Phred-scaled CADD score</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CADD_RAW</TableCell>
                    <TableCell>
                      Score of the deleteriousness of variants
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Genetic_variant_classification/notebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Genetic_variant_classification/data"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <br />
          <section>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="CHROM"
                          name="CHROM"
                          value={sampleData.CHROM}
                          onChange={onChangeFields}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="REF"
                          name="REF"
                          value={sampleData.REF}
                          onChange={onChangeFields}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="ALT"
                          name="ALT"
                          value={sampleData.ALT}
                          onChange={onChangeFields}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="AF_ESP"
                          name="AF_ESP"
                          value={sampleData.AF_ESP}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="AF_EXAC"
                          name="AF_EXAC"
                          value={sampleData.AF_EXAC}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="AF_TGP"
                          name="AF_TGP"
                          value={sampleData.AF_TGP}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="CLNVC"
                          name="CLNVC"
                          value={sampleData.CLNVC}
                          onChange={onChangeFields}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          select
                          variant="outlined"
                          label="ORIGIN"
                          name="ORIGIN"
                          value={sampleData.ORIGIN}
                          onChange={onChangeFields}
                        >
                          {allel_origin.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="ALLELE"
                          name="Allele"
                          value={sampleData.Allele}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="IMPACT"
                          name="IMPACT"
                          value={sampleData.IMPACT}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="EXON"
                          name="EXON"
                          value={sampleData.EXON}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="STRAND"
                          name="STRAND"
                          value={sampleData.STRAND}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="LoFtool"
                          name="LoFtool"
                          value={sampleData.LoFtool}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="CADD_PHRED"
                          name="CADD_PHRED"
                          value={sampleData.CADD_PHRED}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="CADD_RAW"
                          name="CADD_RAW"
                          value={sampleData.CADD_RAW}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <StyledDiv align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={getResult}
                      >
                        Submit
                      </StyledButton>
                    </StyledDiv>
                  </BorderGray>
                </section>
                <br />
              </Grid>
              <Grid item xs={12} md={6}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`${resultData}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer id="container" display />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(GeneticVariantClassification);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
const StyledDiv = styled.div`
  text-align: right;
  @media only screen and (max-width: 959px) {
    text-align: center;
  }
`;
