import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent';
import * as Highcharts from 'highcharts/highmaps';
import moment from 'moment';
import highchartsMap from "highcharts/modules/map";
import styled from 'styled-components';
import APIDATA from '../../../utils/services/api-config';

highchartsMap(Highcharts);

const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

class CoronaDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: 0,
      alive: 0,
      death: 0,
      recovery: 0,
      stateData: null,
    };

    const dateTwoBefore = moment().subtract(2, 'days').format("MM-DD-YYYY");
    const dateOneBefore = moment().subtract(1, 'days').format("MM-DD-YYYY");

    fetch(`${APIDATA.Z1D}:4996/globalCount?currentDate=${dateOneBefore}.csv`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            confirmed: result.Confirmed,
            alive: result.Alive,
            death: result.Death,
            recovery: result.Recovery,
          });
        },
        (error) => {
          fetch(`${APIDATA.Z1D}:4996/globalCount?currentDate=${dateTwoBefore}.csv`)
            .then((res) => res.json())
            .then(
              (result) => {
                this.setState({
                  confirmed: result.Confirmed,
                  alive: result.Alive,
                  death: result.Death,
                  recovery: result.Recovery,
                });
              },
              (error) => {
                this.setState({
                  confirmed: 0,
                  alive: 0,
                  death: 0,
                  recovery: 0,
                });
              },
            );
        },
      );

    // .then( res => {
    fetch(`${APIDATA.Z1D}:4996/stateCount?currentDate=${dateOneBefore}.csv`)
      .then((res) => res.json())
      .then(
        (result) => {
          for (let i = 0; i < states.length; i++) {
            if (result[states[i].name]) {
              states[i].value = result[states[i].name].Confirmed;
              states[i].alive = result[states[i].name].Alive;
              states[i].recovered = result[states[i].name].Recovered;
              states[i].deaths = result[states[i].name].Deaths;
            }
          }
        },
        (error) => {
          fetch(`${APIDATA.Z1D}:4996/stateCount?currentDate=${dateTwoBefore}.csv`)
            .then((res) => res.json())
            .then(
              (result) => {
                for (let i = 0; i < states.length; i++) {
                  if (result[states[i].name]) {
                    states[i].value = result[states[i].name].Confirmed;
                    states[i].alive = result[states[i].name].Alive;
                    states[i].recovered = result[states[i].name].Deaths;
                    states[i].deaths = result[states[i].name].Recovered;
                  }
                }
              },
              (error) => {
                console.log("Error getting States Data");
              },
            );
        },
      )
      // })
      .then((res) => {
        this.setState({
          stateData: states,
        });
      });
  }

  createCoronaUsaMap(data) {
    // Highcharts.mapChart('us-map-corona', {
    //     chart: {
    //         map: 'countries/us/us-all',
    //     },

    //     series: [
    //         {
    //             mapData: mapDataIE,
    //             data: data,
    //             joinBy: ['name', 'name'],
    //             dataLabels: {
    //                 enabled: true,
    //                 format: "{point.abbreviation}"
    //             },

    //         }
    //     ],

    //     tooltip: {
    //         headerFormat:'{point.name}',
    //         pointFormat:' <b> {point.name} </b> <br/> <i>Confirmed</i>: {point.value}  <br/> <i>Active</i>: {point.alive} <br/>\
    //             <i>Recovered</i>: {point.recovered} <br/> <i>Deceased</i>: {point.deaths}'
    //     },

    //     title: {
    //         text: null
    //     },

    //     exporting: {
    //         sourceWidth: 1000,
    //         sourceHeight: 700
    //     },
    //     legend: {
    //         layout: 'horizontal',
    //         borderWidth: 0,
    //         backgroundColor: 'rgba(255,255,255,0.85)',
    //         floating: true,
    //         verticalAlign: 'top',
    //         y: 25,
    //         enabled: false
    //     },

    //     mapNavigation: {
    //         enabled: true,
    //         enableMouseWheelZoom: false
    //     },

    //     colorAxis: {
    //         min: 1,
    //         type: 'logarithmic',
    //         minColor: '#EEEEFF',
    //         maxColor: '#8a1900',
    //         stops: [
    //             [0, '#e1de00'],
    //             [0.33, '#e1bc00'],
    //             [0.66, '#e12900'],
    //             [1, '#8a1900']
    //         ],
    //         showInLegend: false
    //     }
    // })
  }

  render() {
    const classes = {};
    return (
      <div>
        <span>
          <StyledCard style={{ backgroundColor: "lightgray" }}>
            <Header>
              Confirmed
            </Header>
            <Count>
              {this.state.confirmed}
            </Count>
          </StyledCard>

          <StyledCard style={{ backgroundColor: "#F1C40F" }}>
            <Header>
              Active
            </Header>
            <Count>
              {this.state.alive}
            </Count>
          </StyledCard>

          <StyledCard style={{ backgroundColor: "limegreen" }}>
            <Header>
              Recovered
            </Header>
            <Count>
              {this.state.recovery}
            </Count>
          </StyledCard>

          <StyledCard style={{ backgroundColor: "red" }}>
            <Header>
              Deceased
            </Header>
            <Count>
              {this.state.death}
            </Count>
          </StyledCard>
        </span>
        <Card>
          <div id="us-map-corona" />
        </Card>
      </div>
    );
  }

  componentDidMount() {
    const data = [
      {
        value: 438,
        code: "NJ",
        name: "New Jersey",
        alive: 543,
        recovered: 23,
        deaths: 3,
      },
      {
        value: 387.35,
        code: "ri",
      },
      {
        value: 312.68,
        code: "ma",
      },
      {
        value: 271.4,
        code: "ct",
      },
      {
        value: 209.23,
        code: "md",
      },
      {
        value: 195.18,
        code: "ny",
        name: "New York",
      },
      {
        value: 154.87,
        code: "de",
      },
      {
        value: 114.43,
        code: "fl",
        name: "Florida",
      },
      {
        value: 107.05,
        code: "oh",
        name: "Ohio",
      },
      {
        value: 105.8,
        code: "pa",
      },
      {
        value: 86.27,
        code: "il",
      },
      {
        value: 838,
        code: "CA",
        name: "California",
        alive: 543,
        recovered: 23,
        deaths: 3,
      },
      {
        value: 72.83,
        code: "hi",
      },
      {
        value: 69.03,
        code: "va",
      },
      {
        value: 67.55,
        code: "mi",
      },
      {
        value: 65.46,
        code: "in",
      },
      {
        value: 63.8,
        code: "nc",
      },
      {
        value: 54.59,
        code: "ga",
      },
      {
        value: 53.29,
        code: "tn",
      },
      {
        value: 53.2,
        code: "nh",
      },
      {
        value: 51.45,
        code: "sc",
      },
      {
        value: 39.61,
        code: "la",
      },
      {
        value: 39.28,
        code: "ky",
      },
      {
        value: 38.13,
        code: "wi",
      },
      {
        value: 34.2,
        code: "wa",
      },
      {
        value: 33.84,
        code: "al",
      },
      {
        value: 31.36,
        code: "mo",
      },
      {
        value: 30.75,
        code: "tx",
      },
      {
        value: 29,
        code: "wv",
      },
      {
        value: 25.41,
        code: "vt",
      },
      {
        value: 23.86,
        code: "mn",
      },
      {
        value: 23.42,
        code: "ms",
      },
      {
        value: 20.22,
        code: "ia",
      },
      {
        value: 19.82,
        code: "ar",
      },
      {
        value: 19.4,
        code: "ok",
      },
      {
        value: 17.43,
        code: "az",
      },
      {
        value: 16.01,
        code: "co",
      },
      {
        value: 15.95,
        code: "me",
      },
      {
        value: 13.76,
        code: "or",
      },
      {
        value: 12.69,
        code: "ks",
      },
      {
        value: 10.5,
        code: "ut",
      },
      {
        value: 8.6,
        code: "ne",
      },
      {
        value: 7.03,
        code: "nv",
      },
      {
        value: 6.04,
        code: "id",
      },
      {
        value: 5.79,
        code: "nm",
      },
      {
        value: 3.84,
        code: "sd",
      },
      {
        value: 3.59,
        code: "nd",
      },
      {
        value: 2.39,
        code: "mt",
      },
      {
        value: 1.96,
        code: "wy",
      },
      {
        value: 0.42,
        code: "ak",
      },
    ];
    this.createCoronaUsaMap(this.state.stateData);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.stateData != prevState.stateData) {
      this.createCoronaUsaMap(this.state.stateData);
    }
  }
}

CoronaDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CoronaDashboard;
const StyledCard = styled(Card)`
  &.MuiCard-root{
    width:210px;
    height:130px;
    margin:30px;
    display:inline-block;
    text-align:center;
  }
`;
const Header = styled(CardContent)`
&.MuiCardContent-root{
  font-size: 25px;
  font-weight: bolder;
  color: white;
}
`;
const Count = styled(CardContent)`
&.MuiCardContent-root{
  font-size: 20px;
  font-weight: bold;
}
`;
