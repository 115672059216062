const hostname = window && window.location && window.location.hostname;
const HOST_NAMES = {
  dev: "localhost",
  staging: "staging-portal.vlife.virtusa.com",
  prod: "vlife.virtusa.com",
  integ: "integ.vlife.virtusa.com",
  integtest: "dgns4khwj03ov.cloudfront.net",
  devUI: "dev.vlife.virtusa.com",
};
const ENV_ENDPOINTS = {
  dev: {
    PORTAL_API: "https://integ-portal-api.vlifevirtusa.com/",
    COMMERCIAL_ANALYTICS:
      "https://v1-integ-apps.vlifevirtusa.com/CommercialAnalytics/",
    BIO_INFORMATICS:
      "https://v1-integ-apps.vlifevirtusa.com/BioInformatics/",
    PATIENT_ENGAGEMENT:
      "https://v1-integ-apps.vlifevirtusa.com/PatientEngagement/",
    PATIENT_ENGAGEMENT_VENV:
      "https://v1-integ-apps.vlifevirtusa.com/PatientEngagementVenv/",
    PAYER_PROVIDER: "https://v1-integ-apps.vlifevirtusa.com/",
    CLINICAL_TRIALS:
      "https://v1-integ-apps.vlifevirtusa.com/Clinical_Trials/",
    SAIL: "https://v1-integ-apps.vlifevirtusa.com/SAILApplications/",
    Z1D: "https://integ-apps.vlifevirtusa.com",
    Z1DV1: "https://v1-integ-apps.vlifevirtusa.com/",
    IBRI: "https://v1-integ-apps.vlifevirtusa.com/IBRI/",
    COVID_DASHBOARD: "https://v1-integ-apps.vlifevirtusa.com/covidDashboard/",
    CLIENT_USE_CASE: "https://v1-integ-apps.vlifevirtusa.com/use-case-api/",
    COMPARE_TOOL: "https://v1-integ-apps.vlifevirtusa.com/api/",
    COVID19_APPS: "https://v1-integ-apps.vlifevirtusa.com/Corona/",
    PYOD1: "https://integ-apps.vlifevirtusa.com:8510/",
    PYOD2: "https://integ-apps.vlifevirtusa.com:8508/",
    TEST_PERIDONTAL_CHART: "https://integ-apps.vlifevirtusa.com:8016/",
    TEST_MEDICAL_REPORT: "https://integ-apps.vlifevirtusa.com:8511/",
    TEST_CCDL: "https://integ-apps.vlifevirtusa.com:8513/",
    vnet: {
      stepFunCall:
        "https://pnpfja09kb.execute-api.us-east-1.amazonaws.com/vnet/gatekeeper",
      stepFunResponse: "https:/integ-vnet.vlifevirtusa.com/",
      flask: "https://integ-vnet.vlifevirtusa.com:5040/",
    },
    SDLC:"https://v1-integ-apps.vlifevirtusa.com/SDLC",
  },
  devUI: {
    PORTAL_API: "https://integ-portal-api.vlifevirtusa.com/",
    COMMERCIAL_ANALYTICS:
      "https://v1-integ-apps.vlifevirtusa.com/CommercialAnalytics/",
    BIO_INFORMATICS:
      "https://v1-integ-apps.vlifevirtusa.com/BioInformatics/",
    PATIENT_ENGAGEMENT:
      "https://v1-integ-apps.vlifevirtusa.com/PatientEngagement/",
    PATIENT_ENGAGEMENT_VENV:
      "https://v1-integ-apps.vlifevirtusa.com/PatientEngagementVenv/",
    PAYER_PROVIDER: "https://v1-integ-apps.vlifevirtusa.com/",
    CLINICAL_TRIALS:
      "https://v1-integ-apps.vlifevirtusa.com/Clinical_Trials/",
    SAIL: "https://v1-integ-apps.vlifevirtusa.com/SAILApplications/",
    Z1D: "https://integ-apps.vlifevirtusa.com",
    Z1DV1: "https://v1-integ-apps.vlifevirtusa.com/",
    IBRI: "https://v1-integ-apps.vlifevirtusa.com/IBRI/",
    COVID_DASHBOARD: "https://v1-integ-apps.vlifevirtusa.com/covidDashboard/",
    CLIENT_USE_CASE: "https://v1-integ-apps.vlifevirtusa.com/use-case-api/",
    COMPARE_TOOL: "https://v1-integ-apps.vlifevirtusa.com/api/",
    COVID19_APPS: "https://v1-integ-apps.vlifevirtusa.com/Corona/",
    PYOD1: "https://integ-apps.vlifevirtusa.com:8510/",
    PYOD2: "https://integ-apps.vlifevirtusa.com:8508/",
    TEST_PERIDONTAL_CHART: "https://integ-apps.vlifevirtusa.com:8016/",
    TEST_MEDICAL_REPORT: "https://integ-apps.vlifevirtusa.com:8511/",
    TEST_CCDL: "https://integ-apps.vlifevirtusa.com:8513/",
    vnet: {
      stepFunCall:
        "https://pnpfja09kb.execute-api.us-east-1.amazonaws.com/vnet/gatekeeper",
        stepFunResponse: "https:/integ-vnet.vlifevirtusa.com/",
        flask: "https://integ-vnet.vlifevirtusa.com:5040/",
    },
    SDLC:"https://v1-integ-apps.vlifevirtusa.com/SDLC",
  },
  staging: {
    PORTAL_API: "https://staging-portal-api.vlifevirtusa.com/",
    COMMERCIAL_ANALYTICS:
      "https://v1-staging-apps.vlifevirtusa.com/CommercialAnalytics/",
    BIO_INFORMATICS:
      "https://v1-staging-apps.vlifevirtusa.com/BioInformatics/",
    PATIENT_ENGAGEMENT:
      "https://v1-staging-apps.vlifevirtusa.com/PatientEngagement/",
    PATIENT_ENGAGEMENT_VENV:
      "https://v1-staging-apps.vlifevirtusa.com/PatientEngagementVenv/",
    PAYER_PROVIDER: "https://v1-staging-apps.vlifevirtusa.com/",
    CLINICAL_TRIALS:
      "https://v1-staging-apps.vlifevirtusa.com/Clinical_Trails/",
    SAIL: "https://v1-staging-apps.vlifevirtusa.com/SAILApplications/",
    Z1D: "https://staging-apps.vlifevirtusa.com",
    Z1DV1: "https://v1-staging-apps.vlifevirtusa.com/",
    IBRI: "https://v1-staging-apps.vlifevirtusa.com/IBRI/",
    COVID_DASHBOARD: "https://v1-staging-apps.vlifevirtusa.com/covidDashboard/",
    CLIENT_USE_CASE: "https://v1-staging-apps.vlifevirtusa.com/use-case-api/",
    COMPARE_TOOL: "https://v1-staging-apps.vlifevirtusa.com/",
    COVID19_APPS: "https://v1-staging-apps.vlifevirtusa.com/Corona/",
    PYOD1: "https://staging-apps.vlifevirtusa.com:8510/",
    PYOD2: "https://staging-apps.vlifevirtusa.com:8508/",
    NLPTOOLKIT2:"https://staging-apps.vlifevirtusa.com:8510/",
    TEST_PERIDONTAL_CHART: "https://staging-apps.vlifevirtusa.com:8016/",
    TEST_MEDICAL_REPORT: "https://staging-apps.vlifevirtusa.com:8511/",
    TEST_CCDL: "https://staging-apps.vlifevirtusa.com:8513/",
    vnet: {
      stepFunCall:
        "https://pnpfja09kb.execute-api.us-east-1.amazonaws.com/vnet/staging-gatekeeper",
      stepFunResponse: "https://staging-vnet.vlifevirtusa.com/",
      flask: "https://staging-vnet.vlifevirtusa.com:5040/",
    },
    SDLC:"https://v1-integ-apps.vlifevirtusa.com/SDLC",
  },
  integtest: {
    PORTAL_API: "https://portal-api.vlife.virtusa.com/",
    COMMERCIAL_ANALYTICS:
      "https://commercial-analytics.vlifevirtusa.com/CommercialAnalytics/",
    BIO_INFORMATICS: "https://bio-informatics.vlifevirtusa.com/BioInformatics/",
    PATIENT_ENGAGEMENT_VENV:
      "https://patient-engagement-prod.vlifevirtusa.com/",
    PATIENT_ENGAGEMENT:
      "https://patient-engagement.vlifevirtusa.com/PatientEngagement/",
    PAYER_PROVIDER: "https://payer-provider.vlifevirtusa.com/",
    CLINICAL_TRIALS:
      "https://clinicaltrails-ml.vlifevirtusa.com/Clinical_Trials/",
    SAIL: "https://sailapps.vlifevirtusa.com/SAILApplications/",
    Z1D: "https://apps.vlifevirtusa.com",
    Z1DV1: "https://v1-integ-apps.vlifevirtusa.com",
    IBRI: "https://dashboard-api.vlifevirtusa.com/",
    COVID_DASHBOARD: "https://covid-19.vlifevirtusa.com/",
    COMPARE_TOOL: "https://prod-comparetool.vlifevirtusa.com/",
    COVID19_APPS: "https://vlife-covid-19-apps.vlifevirtusa.com/Corona/",
    vnet: {
      stepFunCall:
        "https://pnpfja09kb.execute-api.us-east-1.amazonaws.com/vnet/integ-gatekeeper",
      stepFunResponse: "https://integ-vnet.vlifevirtusa.com/",
      flask: "https://integ-vnet.vlifevirtusa.com:5040/",
    },
    SDLC:"https://v1-integ-apps.vlifevirtusa.com/SDLC",
  },
  integ: {
    PORTAL_API: "https://integ-portal-api.vlifevirtusa.com/",
    COMMERCIAL_ANALYTICS:
      "https://v1-integ-apps.vlifevirtusa.com/CommercialAnalytics/",
    BIO_INFORMATICS:
      "https://v1-integ-apps.vlifevirtusa.com/BioInformatics/",
    PATIENT_ENGAGEMENT:
      "https://v1-integ-apps.vlifevirtusa.com/PatientEngagement/",
    PATIENT_ENGAGEMENT_VENV:
      "https://v1-integ-apps.vlifevirtusa.com/PatientEngagementVenv/",
    PAYER_PROVIDER: "https://v1-integ-apps.vlifevirtusa.com/",
    CLINICAL_TRIALS:
      "https://v1-integ-apps.vlifevirtusa.com/Clinical_Trials/",
    SAIL: "https://v1-integ-apps.vlifevirtusa.com/SAILApplications/",
    Z1D: "https://integ-apps.vlifevirtusa.com",
    Z1DV1: "https://v1-integ-apps.vlifevirtusa.com/",
    IBRI: "https://v1-integ-apps.vlifevirtusa.com/IBRI/",
    COVID_DASHBOARD: "https://v1-integ-apps.vlifevirtusa.com/covidDashboard/",
    CLIENT_USE_CASE: "https://v1-integ-apps.vlifevirtusa.com/use-case-api/",
    COMPARE_TOOL: "https://v1-integ-apps.vlifevirtusa.com/api/",
    COVID19_APPS: "https://v1-integ-apps.vlifevirtusa.com/Corona/",
    PYOD1: "https://integ-apps.vlifevirtusa.com:8510/",
    PYOD2: "https://integ-apps.vlifevirtusa.com:8508/",
    TEST_PERIDONTAL_CHART: "https://integ-apps.vlifevirtusa.com:8016/",
    TEST_MEDICAL_REPORT: "https://integ-apps.vlifevirtusa.com:8511/",
    TEST_CCDL: "https://integ-apps.vlifevirtusa.com:8513/",
    vnet: {
      stepFunCall:
        "https://pnpfja09kb.execute-api.us-east-1.amazonaws.com/vnet/gatekeeper",
      stepFunResponse: "https:/integ-vnet.vlifevirtusa.com/",
      flask: "https://integ-vnet.vlifevirtusa.com:5040/",
    },
    SDLC:"https://v1-integ-apps.vlifevirtusa.com/SDLC",
  },
  prod: {
    PORTAL_API: "https://portal-api.vlife.virtusa.com/",
    COMMERCIAL_ANALYTICS:
      "https://v1-apps.vlifevirtusa.com/CommercialAnalytics/",
    BIO_INFORMATICS:
      "https://v1-apps.vlifevirtusa.com/BioInformatics/",
    PATIENT_ENGAGEMENT:
      "https://v1-apps.vlifevirtusa.com/PatientEngagement/",
    PATIENT_ENGAGEMENT_VENV:
      "https://v1-apps.vlifevirtusa.com/PatientEngagementVenv/",
    PAYER_PROVIDER: "https://v1-apps.vlifevirtusa.com/",
    CLINICAL_TRIALS:
      "https://v1-apps.vlifevirtusa.com/Clinical_Trials/",
    SAIL: "https://v1-apps.vlifevirtusa.com/SAILApplications/",
    Z1D: "https://apps.vlifevirtusa.com",
    Z1DV1: "https://v1-apps.vlifevirtusa.com/",
    IBRI: "https://v1-apps.vlifevirtusa.com/IBRI/",
    COVID_DASHBOARD: "https://v1-apps.vlifevirtusa.com/covidDashboard/",
    CLIENT_USE_CASE: "https://v1-apps.vlifevirtusa.com/use-case-api/",
    COMPARE_TOOL: "https://v1-apps.vlifevirtusa.com/api/",
    COVID19_APPS: "https://v1-apps.vlifevirtusa.com/Corona/",
    PYOD1: "https://apps.vlifevirtusa.com:6003/",
    PYOD2: "https://apps.vlifevirtusa.com:6004/",
    NLPTOOLKIT2:"https://apps.vlifevirtusa.com:6003/",
    TEST_PERIDONTAL_CHART: "https://apps.vlifevirtusa.com:8016/",
    TEST_CCDL: "https://apps.vlifevirtusa.com:8507/",
    TEST_MEDICAL_REPORT: "https://apps.vlifevirtusa.com:8508/",
    vnet: {
      stepFunCall:
        "https://pnpfja09kb.execute-api.us-east-1.amazonaws.com/vnet/prod-gatekeeper",
      stepFunResponse: "https://prod-vnet.vlifevirtusa.com/",
      flask: "https://prod-vnet.vlifevirtusa.com:5040/",
    },
    SDLC:"https://v1-integ-apps.vlifevirtusa.com/SDLC",
  },
};
let CURRENT_ENV_ENDPOINTS; let
  ENV;
switch (hostname) {
  // for Staging
  case HOST_NAMES.staging:
    CURRENT_ENV_ENDPOINTS = ENV_ENDPOINTS.staging;
    ENV = "Staging";
    break;
  // for Integ
  case HOST_NAMES.integ:
    CURRENT_ENV_ENDPOINTS = ENV_ENDPOINTS.integ;
    ENV = "Integ";
    break;
  // for Integtest
  case HOST_NAMES.integtest:
    CURRENT_ENV_ENDPOINTS = ENV_ENDPOINTS.integtest;
    ENV = "Integ";
    break;
  // for Prod
  case HOST_NAMES.prod:
    CURRENT_ENV_ENDPOINTS = ENV_ENDPOINTS.prod;
    ENV = "Prod";
    break;
  case HOST_NAMES.devUI:
    CURRENT_ENV_ENDPOINTS = ENV_ENDPOINTS.devUI;
    ENV = "AWS";
    break;
  // dev/local env
  default:
    CURRENT_ENV_ENDPOINTS = ENV_ENDPOINTS.dev;
    ENV = "AWS";
    break;
}
export default CURRENT_ENV_ENDPOINTS;
export const CURRENT_ENV = ENV;
