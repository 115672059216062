import React, { memo, useState } from "react";
import {
  Grid,
} from "@material-ui/core";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  LinkContainer,
} from "../../../styles/common";
import StyledCard from '../../common/HospitalCard';

const Dataiku = () => {
  const data = [
    ['Dashboard ', '', 'http://dataiku.vlifevirtusa.com/projects/SUPPLYCHAIN/dashboards/uaFwOAH_supply-chains-default-dashboard/view/SR4QkN2', 'Please Contact vLife™ Team for Credentials'],
    ['Visual Analysis', '', 'http://dataiku.vlifevirtusa.com/projects/SUPPLYCHAIN/analysis/C5L0yjYQ/ml/p/tFkUdBE4/list/results#learning.sessions', 'Please Contact vLife™ Team for Credentials'],
    ['Prediction Demo', '', 'http://dataiku.vlifevirtusa.com/projects/SUPPLYCHAIN/webapps/xs8KgqI_supply-chain-index/view', 'Please Contact vLife™ Team for Credentials'],
    ['Flow Diagram', '', 'http://dataiku.vlifevirtusa.com/projects/SUPPLYCHAIN/flow/', 'Please Contact vLife™ Team for Credentials'],
    ['Architecture', '', `../../../assets/images/app/SupplyChain-Arch.webp`, '', ''],
  ];

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Empower analysts and data scientists to deploy models into production in a few clicks. Data cleaning, enriching, preprocessing, as well as models, are bundled together for simplified scoring pipelines.
            Handle large quantities of real-time predictions with queuing, parallelism, and load balancing. Run multiple scoring nodes for full high availability. Automatic elastic scaling to handle unexpected traffic surges.
          </p>
          <p>
            Deploy as an API: visual models, custom Python or R models, custom Python or R functions or SQL queries. Access history of logs queries and predictions at any time to check that model performance is not drifting with time.
            Automatic feature engineering, generation, and selection to use any kind of data in your models.
            Compare dozens of algorithms from Dataiku interface, both for supervised and unsupervised tasks.
            Support for Keras, Tensorflow backend, integrate with Tensorboard, and scale model training using GPU.
            Manage your model lifecycle: deploy new versions, retrain previous versions and rollback to any secure version in just one click
          </p>
          <p>
            Create automatic reports on your datasets and point potential data quality issues.
            Generate univariate and multivariate statistics to produce detailed datasets audit report.
            Filter and search your data as easily as you would in Excel
            Dataiku lets you package a whole workflow, optionally including data and models, as a single deployable and reproducible package. Empower analysts and data scientists to deploy models into production in a few clicks.
            Secure your data with fine-grained access rights.
          </p>
          <h3>Card Items:</h3>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={3} direction="row">
            {data.map((data) => (
              <Grid item xs={6} sm={4} md={3}>
                <StyledCard
                  name={data[0]}
                  utility={data[1]}
                  link={data[2]}
                  userName={data[3]}
                  password1={data[4]}
                  s3Link={data[5]}
                />
              </Grid>
            ))}
          </Grid>
        </LinkContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(Dataiku);
