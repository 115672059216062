import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import cervicalCancerScreening_sol1 from "../../../assets/images/app/cervicalDescription1.webp";
import cervicalCancerScreening_sol2 from "../../../assets/images/app/cervicalDescription2.webp";
import cervicalCancerScreening_sol3 from "../../../assets/images/app/cervicalDescription3.webp";
import cervicalCancerScreening_sample1 from "../../../assets/images/app/cervicalCancer1.webp";
import cervicalCancerScreening_sample2 from "../../../assets/images/app/cervicalCancer2.webp";
import cervicalCancerScreening_sample3 from "../../../assets/images/app/cervicalCancer3.webp";

const { getQueryResult, uploadFile } = CommonService("z1dApps", "cervicalCancer");

const CervicalCancerScreening = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(res.result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res.result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <List>
            <ListItem>Cervical cancer is easy to prevent if caught in its pre-cancerous stage. Today, women worldwide in low-resource settings are benefiting from programs where cancer is identified and treated in a single visit. One of the greatest challenges of cervical cancer screen is determining the appropriate method of treatment which can vary depending on patients’ physiological differences</ListItem>
            <ListItem>Many women at high risk for cervical cancer are receiving treatment that will not work for them due to the position of their cervix. Health providers are able to identify high risk patients, but may not have the skills to reliably discern which treatment will prevent cancer in these women. A treatment which works effectively for one woman may obscure future cancerous growth in another woman, greatly increasing health risks.</ListItem>
            <ListItem>The solution aims to make real-time determinations about patients’ treatment eligibility based on cervix type. This supports remote supervision which helps healthcare providers make better treatment decisions in rural settings.</ListItem>
            <ListItem>Here, an algorithm is developed which accurately identifies a woman’s cervix type based on images.</ListItem>
          </List>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <h3>Cervix Basic Anatomy : </h3>
              <Paragraph>There are different types of cervix in data set and all are considered normal (not cancerous), but since the transformation zones aren't always visible, some of the patients require further testing while some don't. This decision is very important for the healthcare provider and critical for the patient. Identifying the transformation zones is not an easy task for the healthcare providers, therefore, an algorithm-aided decision will significantly improve the quality and efficiency of cervical cancer screening for these patients. </Paragraph>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <center><ImageSol src={cervicalCancerScreening_sol1} alt="" /></center>
            </Grid>
          </Grid>
          <Paragraph>
            Two important areas of the cervix
            <List>
              <ListItem>Squamo-columnar junction (SCJ) –2 different types of epithelial cells meet</ListItem>
              <ListItem>Transformation Zone (TZ) -area between the original SCJ and the new SCJ</ListItem>
            </List>
          </Paragraph>
          <h4>Cervix Types </h4>
          <Paragraph>Different Transformation Zone Locations  = Different cervix type</Paragraph>
          <center>
            <Grid item xs={8} sm={8} md={9}>
              <img width="100%" src={cervicalCancerScreening_sol2} alt="" />
            </Grid>
          </center>
          <h4>Why is Cervix Types Important ?</h4>
          <List>
            <ListItem>Most cervical cancers begin in the cells in the transformation zone</ListItem>
            <ListItem>Cervix types 2 and 3 might include hidden lesions and require different treatment</ListItem>
          </List>
          <center>
            <Grid item xs={6} sm={6} md={6}>
              <img width="50%" src={cervicalCancerScreening_sol3} alt="" />
            </Grid>
          </center>
          <h4>Application : </h4>
          <Paragraph>Classification into cervix types will prevent ineffectual treatments and allow healthcare providers to give proper referral for cases that require more advanced treatment.</Paragraph>

          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/intel-mobileodt-cervical-cancer-screening/data"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/CervicalCancerScreening/CervicalCancerScreening.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={8} sm={4} md={2}>
              <Image src={cervicalCancerScreening_sample1} />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample/"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={8} sm={4} md={2}>
              <Image src={cervicalCancerScreening_sample2} />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1/"
                onClick={getsampleResult}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={8} sm={4} md={2}>
              <Image src={cervicalCancerScreening_sample3} />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample2/"
                onClick={getsampleResult}
              >
                Sample3
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={6} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <Grow in={result} timeout={1500}>
                  <ResultDiv>
                    <Result>
                      <strong>Result : </strong>
                      <h3>{result}</h3>
                    </Result>
                  </ResultDiv>
                </Grow>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(CervicalCancerScreening);

const Image = styled.img`
  width: 100%;
  height: 150px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 240px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
const ResultDiv = styled.div`
 margin-top: 5%;
`;
const ImageSol = styled.img`
  width: 80%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 350px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 300px;
  }
`;
