import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

const { getQueryResult, uploadFile } = CommonService("patientEngagement", "rom");

const Rom = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [imageResult1, setImageResult1] = useState("");
  const [imageResult2, setImageResult2] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    getQueryResult("sample")
      .then((res) => {
        setSpinner(false);
        setResult(res[2]);
        setImageResult1(res[0]);
        setImageResult2(res[1]);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = (e) => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res[2]);
        setImageResult1(res[0]);
        setImageResult2(res[1]);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Knee replacement, or arthroplasty, is a surgical procedure that can help alleviate pain and restore
            partial to total function in severely diseased knee joints. It can be performed as a partial or a
            total knee replacement (TKR). The procedure mostly involves cutting away damaged bone and cartilage
            from one’s thighbone, shinbone and kneecap and replacing it with an artificial joint (prosthesis)
            made of metal alloys, high-grade plastics and polymers.
          </p>
          <p>
            Knee replacement surgeries typically involve a considerable amount of postoperative pain, and includes
            vigorous physical rehabilitation that may take 12 weeks or longer. In determining whether the
            rehabilitation is on track, an orthopedic surgeon assesses the knee's range of motion, stability and
            strength. X-rays help determine the extent of recovery. Range of motion refers to the full movement of
            a joint (in this case, a knee). A completely straight knee joint will measure 0° and a fully bent knee will
            have a flexion of at least 135° degrees.
          </p>
          <p>
            This application ingests a video of a patient stretching their leg and analyses the motion to calculate the
            degree of movement, which can help in predicting the recovery rate, as well as effective post-op care.
          </p>
          <p>
            High level python libraries including CV2, labrose, and matplotlib have been used to develop this
            application. The input video is converted to images first. A video analytics algorithm based on
            advanced mathematical concepts identifies the joints in the images, over which a simple line graph
            is drawn. The angle formed by these lines determine the range of motion of the knee joints. Since
            ROM for post-op care in TKR is a major concern, all the remaining joints are neglected and only the
            knee joint is taken into consideration. A further extension to this algorithm might involve
            analyzing the other joints of the body or clubbing the EMR data to predict further parameters like
            the recovery rate.
          </p>
          <List>
            <ListItem>
              <h4>What does ROM offers?</h4>
              ROM offers range of motion of patient’s knee based on the video of his/her knee movement.
            </ListItem>
            <ListItem>
              <h4>Working with ROM</h4>
              <List>
                <ListItem>  User uploads the video of himself stretching his/her leg while comfortably sitting on a chair (check sample video for reference)</ListItem>
                <ListItem>  ROM is then calculated over the video.It is self-explanatory and easy to use </ListItem>
              </List>
            </ListItem>
          </List>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/spmallick/learnopencv/tree/master/OpenPose-Multi-Person"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ROM/Range_of_Motion.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={3}>
              <Grid container direction="column" spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12}>
                  <video height="200px" width="200px" controls>
                    <source
                      src="https://material.vlifevirtusa.com/ROM/uploaded_video1.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample_image1"
                    onClick={getsampleResult}
                  >
                    Sample1
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grow in={result} timeout={1500}>
              <div>
                <section>
                  <Result>
                    <strong>Result : </strong>
                    {' '}
                    The angle between the initial and final position of the knee is
                    {' '}
                    {result}
                    {' '}
                    degrees
                  </Result>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={10} sm={8} md={6}>
                      <ResultImg
                        width="50%"
                        height="250px"
                        src={imageResult1}
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={10} sm={8} md={6}>
                      <ResultImg
                        width="50%"
                        height="250px"
                        src={imageResult2}
                        alt=""
                      />
                    </Grid>

                  </Grid>
                </section>
              </div>
            </Grow>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(Rom);

const ResultImg = styled.img`
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 300px;
    height: 200px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    height: 200px;
  }
`;
