import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  AppBar,
  Tab,
  Card,
  CardActionArea,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Input,
  ImageList,
  ImageListItem,
} from '@material-ui/core';

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  Heading4,
} from '../../../styles/common';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import styled from 'styled-components';

// import local components
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import CommonService from '../../../utils/services/CommonService';
import { HeaderCell } from '../../common/JsonDataTable';
import { StyledTabs } from './IOTRecipesView';

// images
import sample1_1 from '../../../assets/images/app/sample_result_01/DAPI1.png';
import sample1_2 from '../../../assets/images/app/sample_result_01/Hema1.png';
import sample1_3 from '../../../assets/images/app/sample_result_01/Lap21.png';
import sample1_4 from '../../../assets/images/app/sample_result_01/Marker1.png';
import sample1_5 from '../../../assets/images/app/sample_result_01/Original1.png';
import sample1_6 from '../../../assets/images/app/sample_result_01/SegRefined1.png';

import sample2_1 from '../../../assets/images/app/sample_result_02/DAPI2.png';
import sample2_2 from '../../../assets/images/app/sample_result_02/Hema2.png';
import sample2_3 from '../../../assets/images/app/sample_result_02/Lap22.png';
import sample2_4 from '../../../assets/images/app/sample_result_02/Marker2.png';
import sample2_5 from '../../../assets/images/app/sample_result_02/Original2.png';
import sample2_6 from '../../../assets/images/app/sample_result_02/SegRefined2.png';

import sample3_1 from '../../../assets/images/app/sample_result_03/DAPI3.png';
import sample3_2 from '../../../assets/images/app/sample_result_03/Hema3.png';
import sample3_3 from '../../../assets/images/app/sample_result_03/Lap23.png';
import sample3_4 from '../../../assets/images/app/sample_result_03/Marker3.png';
import sample3_5 from '../../../assets/images/app/sample_result_03/Original3.png';
import sample3_6 from '../../../assets/images/app/sample_result_03/SegRefined3.png';

import sample4_1 from '../../../assets/images/app/sample_result_04/DAPI4.png';
import sample4_2 from '../../../assets/images/app/sample_result_04/Hema4.png';
import sample4_3 from '../../../assets/images/app/sample_result_04/Lap24.png';
import sample4_4 from '../../../assets/images/app/sample_result_04/Marker4.png';
import sample4_5 from '../../../assets/images/app/sample_result_04/Original4.png';
import sample4_6 from '../../../assets/images/app/sample_result_04/SegRefined4.png';

import sample1 from '../../../assets/images/app/cellsample1.webp';
import sample2 from '../../../assets/images/app/cellsample2.webp';
import sample3 from '../../../assets/images/app/cellsample3.webp';
import sample4 from '../../../assets/images/app/cellsample4.webp';
import downloadsample from '../../../assets/images/app/cellsampledownload.jpg';

import soln1 from '../../../assets/images/app/celldescimage1.webp';
import soln2 from '../../../assets/images/app/celldescimage2.webp';
import soln3 from '../../../assets/images/app/celldescimage3.webp';

const useStyles = makeStyles({
  root: {
    height: '100%',
    textAlign: 'left',
    backgroundColor: '#0080FF',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  textCenter: {
    textAlign: 'center',
  },
});

// user id
import getUserData from '../../../utils';


const CancerousCellDetection = () => {
  const [notification, setNotification] = useState('');
  const [showSpinner, setSpinner] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedSample, setSelectedSample] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [fileName, setSelectedFileName] = useState('');
  const [image, setImage] = useState(null);
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [result, setResult] = useState(false);

  const { uploadFilesWithBody } = CommonService(
    'cancerCellDetection',
    'cancerCellDetection'
  );
  //tab1 images
  const imageData = {
    sample1: [sample1_1, sample1_2, sample1_3, sample1_4, sample1_5, sample1_6],
    sample2: [sample2_1, sample2_2, sample2_3, sample2_4, sample2_5, sample2_6],
    sample3: [sample3_1, sample3_2, sample3_3, sample3_4, sample3_5, sample3_6],
    sample4: [sample4_1, sample4_2, sample4_3, sample4_4, sample4_5, sample4_6],
  };

  //tab1 table
  function createData(title, count) {
    return { title, count };
  }

  const rows = {
    sample1: [
      createData('Number of total nuclei', 129),
      createData('Number of IHC+ cells', 67),
      createData('Percentage of IHC+ cells', 51.9),
    ],
    sample2: [
      createData('Number of total nuclei', 250),
      createData('Number of IHC+ cells', 31),
      createData('Percentage of IHC+ cells', 12.4),
    ],
    sample3: [
      createData('Number of total nuclei', 193),
      createData('Number of IHC+ cells', 100),
      createData('Percentage of IHC+ cells', 51.8),
    ],
    sample4: [
      createData('Number of total nuclei', 134),
      createData('Number of IHC+ cells', 55),
      createData('Percentage of IHC+ cells', 41.0),
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const setSampleImages = (e) => {
    setSpinner(true);
    let id = e.currentTarget.id;
    setTimeout(function () {
      setSpinner(false);
      setSelectedSample(id);
    }, 3000);
  };



  const fileSelectedHandler = (e) => {
    //const file = setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(file);
      setSelectedFileName(fileName);
    }
  };

  const uploadFileHandler = () => {

    ///api integration
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (!selectedFile) {
      setNotification({ open: 'error', message: 'please upload a file' });
      return;
    }

    setSpinner(true);
    uploadFilesWithBody('upload', {
      img: selectedFile,
      Context_param: JSON.stringify({
        Application_name: 'cancer-detection-dl',
        UserID: userId,
        Execution_time: date,
      }),
      Content_param: JSON.stringify({}),
    })
      .then((res) => {
        setSpinner(false);
        setNotification({ open: 'success', message: res.Console });
        setResult(res.Result);

        console.log(Data)
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The assessment of protein expression using
            Immunohistochemical (IHC) staining of tissue sections on
            glass slides is very critical to diagnose and take
            clinical decision scenarios, including cancer
            classification residual disease detection, and even
            mutation detection. With the technology advancement,
            scientists developed multiplex immunofluorescence (mpIF)
            staining which has a higher diagnostic prediction accuracy
            than IHC scoring, tumor mutational burden, or gene
            expression profiling. Pathologists used these IHC and
            multiplex immunofluorescence (mpIF) staining to predict
            the result of the tissue sample. But the only major
            concern was that mpIF assays are expensive and not widely
            available.
          </p>
          <p>
            Pathologists used tissue samples to diagnose various
            pathological issues and to detect they have applied
            different techniques like visual markers to enhance the
            abnormal activity which will be highlighted by the stained
            tissue sample. That is how pathologists generate the
            above-mentioned images like IHC and Hematoxylin by using
            biomarking or visual marking dyes to enhance the abnormal
            cells within the tissue. To generate IHC images,
            pathologists use common markers including fluorescent dye,
            enzymes, colloidal gold, and radioactive elements. This is
            where multiplex immunofluorescence (mpIF) comes as the
            best alternative and also an accurate way to calibrate
            tissue samples. In mpIF, they used florescent imaging to
            increase the visual of cells by targeting specific colors
            by using multiplex immunofluorescence imaging to enhance
            cells within the tissue. As you can see from the mpIF
            imaging of mpIF DAPI, mpIF Lap2, and mpIF Ki67, they have
            used different biomarking chemicals and applied
            fluorescent imaging to target specific cells to get the
            accurate staining of the tissue sample.
          </p>
          <p>
            Therefore, a deep learning framework was created to take
            only input as IHS to translate low-cost and prevalent IHC
            slides to more expensive-yet-informative mpIF images.
            Also, while segmenting relevant cells, and quantifying
            protein expressions to generate noisier and
            artifact-ridden images to identify the cell
            classification. For example, considering cancer cells,
            pathologists will be able to use the framework to identify
            the cancerous cells within the tissue.
          </p>
          <p>This application generates 5 images. (Hematoxylin, mpIF DAPI, mpIF Lap2, mpIF Ki67, and Segmentation).</p>
          <center>
            <Grid item xs={10} sm={10} md={10}>
              <img src={soln1} width="80%" />
            </Grid>
          </center>
          <p><strong>Hematoxylin</strong> is a chemical or dye that is used to color part of cells which also ultimately gives cell nuclei a pleasing blue coloration of varying hue and intensity, depending on the type of hematoxylin used.</p>
          <p><strong>Multiplex immunofluorescence (mpIF)</strong> is a powerful tool for the simultaneous detection of tissue-based biomarkers, revolutionizing traditional immunohistochemistry. Standard immuno-detection techniques, such as immunohistochemistry (IHC), only allow for 1-5 markers but mpIF can analyze 2 - 50 markers at one time, expressed on a single cell level with high precision. </p>
          <ul>
            <li>
              <strong>mpIF DAPI</strong> is nuclear counterstain or visual marker which can be detected by blue fluorescent for targeted and specific cell parts.
            </li>
            <li>
              <strong>mpIF Ki67</strong> is targeting specific proteins within the tissue sample. Further information as Ki-67 is a protein found in the nucleus of cancer cells. Knowing how much Ki-67 is produced by your tumor can help your healthcare team estimate how likely the cancer is to grow and spread. Taking the fluorescent image of Ki67 might give pathologists the chance to identify the positive cancerous cells within the tissue sample.
            </li>
            <li>
              <strong>mpIF LAP2Beta</strong> is a protein that in humans is encoded by the TMPO gene. Also is an inner nuclear membrane protein and by using this nuclear envelop stain with greater then 95% cell coverage to better separate touching/overlapping cells channels.
            </li>
          </ul>
          <p><strong>Segmented images</strong> are generated using this application by considering IHC, Hematoxylin and mpIF to produce a clearer output for the viewer about the positive and negative cells of cancerous tissue samples. This also allows us to calculate total cells/nuclei, and take the percentage of positive cancer cells.  </p>
          <p>For example: </p>
          <ul>
            <li>
              Number of total nuclei: 141
            </li>
            <li>
              Number of IHC+ cells: 73
            </li>
            <li>
              Percentage of IHC+ cells: 51.8 %
            </li>
          </ul>
          <p>Two segmented images are generated from the model  </p>
          <ul>
            <li>
              <strong>Segmented overlaid </strong><br></br>
              Considering the IHC and segmented images both were overlaid to get more contrast information of the tissue sample.
            </li>
            <center>
              <Grid item xs={8} sm={6} md={4}>
                <img src={soln2} height="30%" width="60%" />
              </Grid>
            </center>
            <li>
              <strong>Segmented Refined </strong><br></br>
              This image was produced by refining the segmented image in order to generate a clearer image of segmented image to detect the positive and negative cells.
            </li>
            <center>
              <Grid item xs={8} sm={6} md={4}>
                <img src={soln3} height="30%" width="60%" />
              </Grid>
            </center>
          </ul>
          <p>
            <b>Dataset</b>
            <br />
            Data Distribution:
            <ul>
              <li>
                575 sets for training, 91 sets for validation, and 598
                sets for testing.
              </li>
              <li>
                Randomly selected and manually segmented 41 images of
                size 640x640 from the recently released BCDataset.
              </li>
            </ul>
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/CancerCellDetection-DL/CancerCellDetection.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://apc01.safelinks.protection.outlook.com/GetUrlReputation"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://deepliif.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  More information
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBar position="static" color="default">
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Sample" />
                  <Tab label="BYOD" />
                </StyledTabs>
              </AppBar>
            </Grid>
          </Grid>
          <TabPanel value={value} index={0}>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6} sm={3} md={3}>
                <img src={sample1} height="100" width="100" />
                <br />
                <div style={{ textAlign: 'center' }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample1"
                    onClick={setSampleImages}
                    value={selectedSample}
                    id="sample1"
                    name={selectedSample}
                  >
                    Sample 1
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <img src={sample2} height="100" width="100" />
                <br />
                <div style={{ textAlign: 'center' }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample2"
                    onClick={setSampleImages}
                    value={selectedSample}
                    id="sample2"
                    name={selectedSample}
                  >
                    Sample 2
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <img src={sample3} height="100" width="100" />
                <br />
                <div style={{ textAlign: 'center' }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample3"
                    onClick={setSampleImages}
                    value={selectedSample}
                    id="sample3"
                    name={selectedSample}
                  >
                    Sample 3
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <img src={sample4} height="100" width="100" />
                <br />
                <div style={{ textAlign: 'center' }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample4"
                    onClick={setSampleImages}
                    value={selectedSample}
                    id="sample4"
                    name={selectedSample}
                  >
                    Sample 4
                  </StyledButton>
                </div>
              </Grid>
            </Grid>
            <br /> <br /><br />
            {selectedSample && (
              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justify="space-evenly"
                alignItems="center"
              >
                {' '}
                {/* <Grid item xs={6} sm={3} md={3} style={{ gap: 8 }}>
                          {imageData[selectedSample].map((img) => (
                            <img src={img} width="100" height="100" />
                          ))}
                        </Grid> */}

                <Grid item xs={6} sm={3} md={3}>
                  <ImageList cols={3} gap={8} rowHeight={108}>
                    {imageData[selectedSample].map((img) => (
                      <ImageListItem key={img}>
                        <img src={img} loading="lazy" />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>

                <Grid item xs={6} sm={3} md={3}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {rows[selectedSample].map((row) => (
                          <TableRow key={row.title}>
                            <TableCell component="th" scope="row">
                              {row.title}
                            </TableCell>
                            <TableCell align="right">
                              {row.count}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>{' '}
                </Grid>
              </Grid>
            )}
          </TabPanel>

          {/* tab2 */}
          <TabPanel TabPanel value={value} index={1}>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={10} sm={10} md={10}>
                <strong>Please upload your own image or download a sample image from the given link and upload</strong>
              </Grid>
              <Grid item xs={10} sm={3} md={3}>
                <StyledButton
                  variant='outlined'
                  color='primary'>
                  <a
                    href={downloadsample}
                    download
                    target="blank"
                  >
                    Download Sample
                  </a>
                </StyledButton>
              </Grid>
              <Grid item xs={8} sm={5} md={3}>
                <Input
                  type="file"
                  color="primary"
                  name="uploadedFile"
                  onChange={fileSelectedHandler}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                {image && (
                  <img
                    src={image}
                    alt={image}
                    width="100"
                    height="100"
                  />
                )}
              </Grid>
              <Grid item xs={4} sm={3} md={3}>
                <StyledButton
                  id="btn2"
                  variant="contained"
                  color="primary"
                  onClick={uploadFileHandler}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
            {/* output */}
            {result && (
              <>
                <br /><br />
                <Grid
                  container
                  spacing={5}
                  direction='row'
                  justify="center"
                  alignItems='center'
                >
                  <Grid item xs={6} sm={4} md={4}>
                    <ImageList cols={3} gap={8} rowHeight={108}>
                      {result?.path_list?.map((img) => (
                        <ImageListItem key={img}>
                          <img src={img} loading="lazy" />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Grid>
                  {/* <Grid item xs={2} sm={2} md={2}>
                  </Grid> */}
                  <Grid item xs={10} sm={5} md={4}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>

                            <TableCell align="right">
                              Number of total nuclei
                            </TableCell>

                            <TableCell align="right">
                              {result?.scoring?.num_total}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right">
                              Number of IHC+ cells
                            </TableCell>
                            <TableCell align="right">
                              {result?.scoring?.num_pos}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right">
                              Percentage of IHC+ cells
                            </TableCell>
                            <TableCell align="right">
                              {result?.scoring?.percent_pos}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </>
            )}
          </TabPanel>
          {showSpinner && <Spinner text="Loading..." />}

        </DemoContainer>
      </Collapse>
    </SolutionContainer >
  );
};

export default memo(CancerousCellDetection);

const ListContainer = styled.ul`
          padding-left: 0;
          `;

const H4 = styled.h4`
          text-align: left;
          border-bottom: 3px solid #3175eb;
          font-weight: 400;
          margin: 10px 0;
          padding: 10px 0;
          width: 100%;

          @media only screen and (max-width: 960px) {
            font - size: 1.6rem;
  }
          @media only screen and (max-width: 450px) {
            font - size: 1.2rem;
  }
          `;
const StyledTitle = styled(Heading4)`
          color: #f5a76c;
          `;
const PanelContainer = styled(Paper)`
          &.MuiPaper-root {
            padding: 15px;
  }
          `;
const ListItem = styled.li`
          text-align: justify;
          `;
function StyledCard({ name, utility, cardAsLink }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.link}
        component={cardAsLink && 'a'}
        href={cardAsLink}
        target="_blank"
      >
        <CardContent>
          {name && (
            <Typography
              className={classes.textCenter}
              gutterBottom
              variant="h6"
              component="h5"
            >
              {name}
            </Typography>
          )}
          {utility && (
            <Typography
              gutterBottom
              variant="h6subtitle1"
              className={classes.textCenter}
              component="p"
            >
              {utility}
              <br />
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

export function List({ children, icon = faCheck }) {
  return (
    <ListItem>
      <FontAwesomeIcon icon={icon} color="#e6aa6a" />
      &nbsp;&nbsp;
      {children}
    </ListItem>
  );
}

function H3({ children }) {
  return <StyledTitle>{children}</StyledTitle>;
}
