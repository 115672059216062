import React, { useState, useCallback } from "react";
import {
  Typography,
  Grid,
  Paper,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";

// import local components
import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import Spinner from "../common/loading/Spinner";
import MainLayout from "../common/MainLayout";
import Footer from "../views/home/Footer";
import PasswordValidator from "../common/PasswordValidator";
import {
  validateEmail,
  isPasswordValid,
  validatePhoneNumber,
  allowedDomains,
} from "../../utils";
import NotificationWidget from "../common/Notification";
import { StyledBackdrop } from "../../styles/common";
// images
import vLifeLogin from "../../assets/images/app/vLifeLogin.webp";
import TransformedLogo from "../../assets/logos/vlifeLogo4.webp";
import commonServices from "../../utils/services/CommonService";
import { USER_MANAGEMENT } from "../../constants";

const TermsandConditions = (props) => (
  <MainLayout>

    <StyledLazyHero
      imageSrc={vLifeLogin}
      parallaxOffset="110"
      minHeight="220vh"
      opacity="0.8"
      color="#283caa"
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={11}>
          <LoginContainer>
            <br />
            <LogoContainer id="logo" item xs={1}>
              <Link to="/">
                <LogoImage id="nav-logo" src={TransformedLogo} alt="" />
              </Link>
            </LogoContainer>

            <LoginTitle>Terms and Conditions</LoginTitle>
            <SectionTitleDivider />
            <br />
            <form>
              <LoginFormContainer
                container
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <LoginMessageContainer>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    align="justify"
                  >
                    These terms and conditions (“Terms”) govern your use of the machine learning models, code snippets, tools, accelerators and other materials (“vLife Materials”) made available to you and your users (“You” and “Your”) by Virtusa through the vLife marketplace.
                    <br />
                    <br />
                    OWNERSHIP. Virtusa and its licensors own all right, title, and interest in and to the vLife Materials and all related technology and intellectual property rights.
                    <br />
                    <br />
                    LICENSE. Subject to Your compliance with these Terms, any applicable cloud platform agreement, and any other agreement governing Your use the vLife Materials, Virtusa grants You a limited, revocable, non-exclusive, non-sublicensable, non-transferable license to access and use the vLife Materials solely for your internal business use, which use must be in compliance with all applicable laws, rules and regulations and must not infringe or violate any third party rights.
                    <br />
                    <br />
                    RESERVATION OF RIGHTS. Except as expressly provided in the License section, You obtain no license or rights from Virtusa or its licensors to the vLife Materials or the intellectual property rights therein, and all such rights are expressly reserved.  Virtusa reserves the right at any time to modify, suspend, or discontinue providing the vLife Materials in whole or in part in its sole discretion with or without notice. Virtusa reserves the right at any time to modify these Terms in its sole discretion and the amended Terms will be effective upon subsequent use of the vLife Materials.
                    <br />
                    <br />
                    RESTRICTIONS. You may not use the vLife Materials in any manner other than as expressly permitted by these Terms.  You will not and will not attempt to (a) resell, sublicense, lease, rent, loan, transfer, or distribute the vLife Materials to third parties; (b) reverse engineer, disassemble, or decompile the vLife Materials (except to the extent permitted by applicable law; or (c) remove, obscure, or alter Virtusa’s or any third party’s trademarks or copyright or other proprietary rights notices affixed to or contained within or accessed in conjunction with or through vLife Materials.
                    <br />
                    <br />
                    INDEMNITY. You agree to indemnify, defend, and hold harmless Virtusa and its licensors from any and all loss, cost, liability, and expense arising from or related to your data, your use of the vLife Materials, or your violation of these terms.
                    <br />
                    <br />
                    DISCLAIMER OF WARRANTY. THE VLIFE MATERIALS AND YOUR USE OF THEM ARE PROVIDED "AS IS."  VIRTUSA AND ITS LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE VLIFE MATERIALS, INCLUDING ANY WARRANTY THAT THE VLIFE MATERIALS WILL BE ERROR FREE OR FREE OF HARMFUL COMPONENTS, OR THAT ANY CONTENT IN YOUR CLOUD PLATFORM ACCOUNT, INCLUDING YOUR CONTENT AND DATA, WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED.  EXCEPT TO THE EXTENT PROHIBITED BY LAW, VIRTUSA AND ITS LICENSORS DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE. VIRTUSA IS NOT RESPONSIBLE FOR ANY PROBLEMS OR OTHER DAMAGE RESULTING FROM SUCH LIMITATIONS OR ISSUES.
                    <br />
                    <br />
                    LIMITATIONS OF LIABILITY. IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY LOSS OR UNAVAILABILITY OF OR DAMAGE TO DATA, LOST REVENUE, LOST PROFITS, FAILURE TO REALIZE EXPECTED SAVINGS, DAMAGE TO REPUTATION, BUSINESS INTERRUPTION, DOWNTIME COSTS OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, EXEMPLARY OR ANY SIMILAR TYPE OF DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS OR THE USE OR THE INABILITY TO USE THE VLIFE MATERIALS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ASSUME ALL RESPONSIBILITY FOR THE SELECTION OF THE VLIFE MATERIALS TO ACHIEVE YOUR INTENDED RESULTS.  IN ANY CASE, VIRTUSA AND ITS LICENSORS' AGGREGATE LIABILITY UNDER THIS AGREEMENT WILL BE LIMITED TO THE AMOUNT YOU ACTUALLY PAY VIRTUSA DURING THE YEAR PRECEDING THE CLAIM FOR THE VLIFE MATERIALS.  THIS SECTION SHALL APPLY TO THE FULL EXTENT PERMITTED BY APPLICABLE LAW REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, PRODUCT LIABILITY, STRICT LIABILITY, STATUTE, LAW, EQUITY OR OTHERWISE.
                    <br />
                    <br />
                    TERM AND TERMINATION. These Terms and Your access to the vLife Materials will become effective as of Your agreement to these Terms and shall continue in effect unless You fail to fully comply with these Terms or are otherwise terminated. Virtusa may suspend or terminate Your license to use the vLife Materials at any time upon written notice for any reason in its sole discretion. In addition, the suspension or termination of Your cloud platform account may suspend or terminate Your access to and use of the vLife Materials. You will cease Your use of the vLife Materials, remove them from Your cloud platform account, and destroy any copies of the vLife Materials in Your control upon receiving such a notice, or upon other termination of Your right to use the vLife Materials. In no event will Virtusa provide, or be liable for, any refund of fees paid by You for access to Your cloud platform. You may cease Your use of the vLife Materials at any time without notice.
                    <br />
                    <br />
                    MISCELLANEOUS. All of these Terms which by their nature are intended to survive termination shall survive the termination of this Agreement.  These Terms shall be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts, except to the extent that they apply the laws of another jurisdiction to conflicts arising hereunder.  These Terms will be subject to the exclusive jurisdiction of the state and federal courts serving the Commonwealth of Massachusetts, and the parties agree and submit to the personal and exclusive jurisdiction and venue of these courts, except that nothing will prohibit Virtusa from instituting an action in any court of competent jurisdiction to obtain injunctive relief or protect or enforce its intellectual property rights.
                  <br/>
                  <br/>
                  Yes, I want Virtusa to keep me up-to-date with recent industry developments including insights, upcoming events, and innovative solution capabilities according to the <a href='https://www.virtusa.com/privacy-statement' target='_blank' style={{color: 'white'}}>Privacy Policy</a>
                  </Typography>
                </LoginMessageContainer>

              </LoginFormContainer>
              <br />

            </form>
          </LoginContainer>
        </Grid>
      </Grid>

    </StyledLazyHero>

    <StyledFooter showCart={false} />
  </MainLayout>
);
export default TermsandConditions;

export const StyledLazyHero = styled(LazyHero)`
@media only screen and (max-width: 1000px) {
  .QrGtg{
    position: relative
  }
}
`;
export const BannerTitle = styled(Typography)`
color: #ffffff;
  font-size: 46px;
  font-weight: 300;
  text-align: left;
  padding-top: 25px;
  padding-right: 20px;
  @media only screen and (max-width: 950px) {
    font-size: 30px;
    padding-left: 10px;
    text-align: center;
  }
  @media only screen and (max-width: 450px) {
    font-size: 24px;
  }
`;
const LoginContainer = styled(Paper)`
  &.MuiPaper-root {
    color: #ffffff;
    margin: 20px;
    padding: 10px;
    background-color: rgb(0, 0, 0, 0.1);
    max-height: auto;

    @media only screen and (max-width: 900px) {
      font-size: 20px;
      margin-top: 0%;
      text-align: center;
      padding: 0px 60px;
    }
    @media only screen and (max-width: 600px) {
      margin-top: 1%;
      padding: 8%
    }
  }
`;
const LoginTitle = styled(Typography)`
  &.MuiTypography-root {
    color: #ffffff;
    padding: 10px 8px;
    font-size: 28px;
    text-align: left;
    font-weight: 200;
    text-decoration: none;
    @media only screen and (max-width: 900px) {
      margin-left: 2px;
      padding: 00px 0px;
      font-size: 25px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 20px;
    }
  }
`;
const LoginFormContainer = styled(Grid)`
  &.MuiGrid-root {
    width: 100%;
    padding: 10px;
    color: #ffffff;

    @media only screen and (max-width: 900px) {
      font-size: 17px;
      text-align: center;
      padding: 0%;
    }
  }
`;
const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    @media only screen and (max-width: 900px) {
      padding: 1%;
    }
  }
`;
const LoginFormButtonContainer = styled(Grid)`
  padding: 0px 20px 10px 20px;
  @media only screen and (max-width: 900px) {
    margin: -23px;
  }
`;
const LoginPrimaryButton = styled(Button)`
&.MuiButton-root{
  color: #ffffff;
  width: 100%;
  background-color: #fc7703;

  @media only screen and (max-width: 900px) {
    width: 50%;
    text-size: 12px;
  }
  @media only screen and (max-width: 450px) {
    margin: -5px;
  }
}
`;
const LinkButton = styled(Link)`
  width: 100%;
  color: inherit;
  text-decoration: none;
  text-align: center;
  font-size: 11.5px;
  @media only screen and (max-width: 450px) {
    padding: 5px;
  }
`;
const StyledAlert = styled(Alert)`
&.MuiAlert-root{
  fontStyle: italic;
  color: black;
  background-color: white;
  padding: 3px 3px 3px 6px;
  min-height: 30px;
  width: 100%;
}
`;
const SectionTitleDivider = styled.hr`
  width: 27%;
  height: 3px;
  margin: 0px auto;
  max-width: 400px;
  background-color: #fc7703;
  text-align: left;
  margin-left: 11px;
  border: 0;
  @media only screen and (max-width: 900px) {
    margin-left: 2px;
  }
`;
const CustomTextField = styled(TextField)`
  &.MuiTextField-root {
    &.label.MuiInputLabel-root {
      color: #ffffff;
    }
    ,
    .MuiInput-underline:before {
      border-bottom-color: #ffffff;
    }
    ,
    .MuiInputBase-input.MuiInput-input {
      color: ${(props) => (!props.changeText ? "#ffff" : "#f5ee1d;")};
    }
    ,
    .MuiInputLabel-animated {
      color: #ffff;
    }
    ,
    &.label.Mui-focused {
      color: #fc7703;
    }
    ,
    .MuiInput-underline:after {
      border-bottom-color: #fc7703;
    }
    ,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom-color: #fc7703;
    }
  }
`;
const LoginSecondaryButton = styled(Button)`
&.MuiButton-root{

  color: #ffffff;
  width: 100%;
  border-color: #ffffff;
  hover: {
    color: #fc7703;
    border-color: #fc7703;
    transform: scale(1.1);
  }

  @media only screen and (max-width: 900px) {
    width: 50%;
    text-size: 12px;
  }
  @media only screen and (max-width: 900px) {
    margin: -5px;
  }
}
`;
const LoginMessageContainer = styled.div`
  padding: 5px;
  width: 100%;
`;
const StyledFooter = styled(Footer)`
  padding: 0px;
  @media only screen and (max-width: 900px) {
    padding: 0px;
  }
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    color: #ffffff;
    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
    }
  }
`;
const LogoContainer = styled.div``;
const LogoImage = styled.img`
  height: 30px;
  @media only screen and (max-width: 900px) {
    height: 25px;
  }
  @media only screen and (max-width: 500px) {
    height: 20px;
  }
`;
