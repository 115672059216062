import { faCircleQuestion, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export default function Chatbot({ title, conversation, handleSearch, loader,queList, style={} }) {
  const [chatInput, setInput] = useState("");
  const [showList, setQuestionList] = useState(false);
 
  let refToScroll = useRef();

  const handleSend = () => {
    setInput("");
    handleSearch(chatInput);
    setQuestionList(false);
  };
  const handleChatInput = (e) => {
      setInput(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSend()
    }
  }
  useEffect(() => {
    refToScroll.current && refToScroll.current.scrollTo(0, refToScroll.current.scrollHeight);
  }, [conversation])
  
  const handleQuestionist=()=>{
    setQuestionList(true);
  }
  const setQuestion=(e)=>{
    setInput(queList[e.target.id]);
  }

  return (
    <Container height={style.height}>
      <Header>
        <h3>{title} <FontAwesomeIcon icon={faCircleQuestion}/></h3>
       
      </Header>
      <Body ref={refToScroll} height={style.bodyHeight}>
        <ChatView conversation={conversation} />
        {loader ? (
          <div className="bubble bot">
            <Loader className="loader">
              <div className="bubble-loader"></div>
              <div className="bubble-loader"></div>
              <div className="bubble-loader"></div>
            </Loader>
          </div>
        ):
        <QuestionsList>
               
          <span className="bubble bot" onClick={handleQuestionist} > Click for predefined questions!</span><br/>
          {showList && 
           Object.entries(queList).map(ques=>(
            <span id={ques[0]} onClick={setQuestion}>{ques[1]}</span>
          )) }
        </QuestionsList>}
        <span id='scrollto'> </span>
      </Body>
      <Footer>
        <Input
          onChange={handleChatInput}
          value={chatInput}
          placeholder="Enter Your Query"
          onKeyDown={handleKeyDown}
        />
        <Button onClick={handleSend}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      </Footer>
    </Container>
  );
}

export const ChatView = ({ conversation }) => {
  return (
    <>
      {conversation?.map((chatData, ind) => (
        <div
          className={`bubble ${chatData[0]}`}
          id={`${chatData[0]}${ind}`}
          key={`${chatData[0]}${ind}`}
        >
          {chatData[1]}
        </div>
      ))}
    </>
  );
};
const QuestionsList=styled.div`
  span {
    text-decoration: none;
    padding: 5px;
    background-color: rgb(230, 230, 230);
    border-radius: 5px;
    font-size: 10px;
    margin: 3px;
    display: inline-block;
    cursor:pointer;
    &:hover{
      text-decoration: underline;
    }
  }
`;
const Container = styled.div`
  height: ${props=>props.height ? props.height : '97vh'};
  max-width: 100%;
  .user {
    margin-left: auto;
    background-color: #ffe6e6;
    border-radius: 1.5rem 1.5rem 0px;
  }
  .bot {
    margin-right: auto;
    background-color: rgb(230, 230, 230);
    color: var(--bot-text-color);
    border-radius: 1.5rem 1.5rem 1.5rem 0px;
  }
  .bubble {
    max-width: 100%;
    padding: 0.8em 1.4em;
    text-align: left;
    overflow-wrap: break-word;
    margin-bottom: 0.625rem;
  }
`;
const Body = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 0.375rem;
  box-shadow: rgb(0 0 0 / 15%) 0.0625rem 0rem 0.25rem 0px;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  height: ${props=>props.height ? props.height-50: '80vh'};
  max-width: 28.75rem;
  overflow: auto;
  #scrollto{
    display: block;
    margin-bottom: 10px;
  }
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.625rem;
  min-height: 3.125rem;
  min-width: 100%;
  max-width: 100%;
  box-shadow: rgb(0 0 0 / 15%) 0.0625rem 0rem 0.25rem 0px;
  box-sizing: border-box;
`;
const Header = styled.header`
  min-width: 100%;
  text-align: center;
  display: block;
  max-width: 100%;
  border-right:1px light rgb(0 0 0 / 15%);
`;
const Input = styled.input`
  padding: 5px;
  width: 100%;
  outline: none;
  border: none;
`;
const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;
const Loader = styled.div`



.bubble-loader {
  animation: expand .75s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 10px;
  height: 10px;
}

.bubble-loader:nth-child(1) {
  background: #5d605c;
}

.bubble-loader:nth-child(2) {
  animation-delay: 180ms;
  background: #5d605c;
}

.bubble-loader:nth-child(3) {
  animation-delay: 360ms;
  background: #5d605c;
}


@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.55);
  }
}

`;
