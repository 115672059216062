import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
} from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";

import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";

const MiceProteinClassification = () => {
  const initialData = {
    DYRK1A_N: "",
    pCAMKII_N: "",
    pERK_N: "",
    BRAF_N: "",
    APP_N: "",
    SOD1_N: "",
    pPKCG_N: "",
    ARC_N: "",
    Ubiquitin_N: "",
    pS6_N: "",
    H3AcK18_N: "",
    CaNA_N: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "bioInformatics",
    "miceProteinClassification",
  );

  const formHighChart = (data) => {
    const params = [];
    data.map((arr, index) => params.push({
      name: arr[0],
      y: arr[1],
    }));
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: params,
        },
      ],
    });
  };

  const showSample1 = () => {
    const NewDataSet = {
      DYRK1A_N: "0.35513649",
      pCAMKII_N: "3.326519847",
      pERK_N: "0.333245417",
      BRAF_N: "0.262297244",
      APP_N: "0.451272583",
      SOD1_N: "1.138072003",
      pPKCG_N: "2.346156703",
      ARC_N: "0.124025251",
      Ubiquitin_N: "1.237059042",
      pS6_N: "0.124025251",
      H3AcK18_N: "0.29067954",
      CaNA_N: "1.335982176",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const showSample2 = () => {
    const NewDataSet = {
      DYRK1A_N: "0.387995542",
      pCAMKII_N: "2.866263334",
      pERK_N: "0.744785862",
      BRAF_N: "0.327575227",
      APP_N: "0.417529056",
      SOD1_N: "0.332351536",
      pPKCG_N: "1.265494792",
      ARC_N: "0.115538194",
      Ubiquitin_N: "1.035763889",
      pS6_N: "0.115538194",
      H3AcK18_N: "0.101692708",
      CaNA_N: "1.689105903",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      DYRK1A_N,
      pCAMKII_N,
      pERK_N,
      BRAF_N,
      APP_N,
      SOD1_N,
      pPKCG_N,
      ARC_N,
      Ubiquitin_N,
      pS6_N,
      H3AcK18_N,
      CaNA_N,
    } = sampleData;

    const params = `${DYRK1A_N},${pCAMKII_N},${pERK_N},${BRAF_N},${APP_N},${SOD1_N},${pPKCG_N},${ARC_N},
    ${Ubiquitin_N},${pS6_N},${H3AcK18_N},${CaNA_N}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          setResultData(res[0]);
          formHighChart(res[1]);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Down Syndrome(DS) is a common genetic disorder, caused by an extra copy of chromosome 21. It results in over-expression of genes and hindering learning efficiency in species. Hence, understanding protein expression levels and their interaction aid in diagnosing DS. </p>
          <p>Protein expression samples of control and trisomic(DS) mice are considered assuming the same treatment in human beings. The research study identified 77 proteins play roles in brain structure and functioning. Hence, both control and trisomic mice are exposed to context fear conditioning(CFC) injected with and without memantine drugs which are analyzed using ML techniques. </p>
          <p>About 0.1% of live births are affected by Down Syndrome(DS) worldwide which is known for brain inefficiency in memorising and learning. Assuming memantine drug as its treatment, it is injected in control and trisomic(DS) mice while exposing to context fear conditioning to study their behavior.A multiclass classification problem to determine eight classes of mice based on expression levels of 77 proteins,getting trained in CFC with and without injecting memantine. Those classes are to identify a mice group and behavior towards its learning environment.</p>
          <strong>Classes:</strong>
          <List>
            <ListItem>c-CS-s: control mice, stimulated to learn, injected with saline </ListItem>
            <ListItem>c-CS-m: control mice, stimulated to learn, injected with memantine</ListItem>
            <ListItem>c-SC-s: control mice, not stimulated to learn, injected with saline</ListItem>
            <ListItem>c-SC-m: control mice, not stimulated to learn, injected with memantine </ListItem>
            <ListItem>t-CS-s: trisomy mice, stimulated to learn, injected with saline</ListItem>
            <ListItem>t-CS-m: trisomy mice, stimulated to learn, injected with memantine</ListItem>
            <ListItem>t-SC-s: trisomy mice, not stimulated to learn, injected with saline</ListItem>
            <ListItem>t-SC-m: trisomy mice, not stimulated to learn, injected with memantine</ListItem>
          </List>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/MiceProtein/classify_mice_protein.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>

              <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/code/dkleefisch/classification-of-mice-by-protein-expression/notebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
 

            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <h4>Please provide the following data inputs</h4>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="DYRK1A_N"
                      name="DYRK1A_N"
                      value={sampleData.DYRK1A_N}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="pCAMKII_N"
                      name="pCAMKII_N"
                      value={sampleData.pCAMKII_N}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="pERK_N"
                      name="pERK_N"
                      value={sampleData.pERK_N}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="BRAF_N"
                      name="BRAF_N"
                      value={sampleData.BRAF_N}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="APP_N"
                      name="APP_N"
                      value={sampleData.APP_N}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="SOD1_N"
                      name="SOD1_N"
                      value={sampleData.SOD1_N}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="pPKCG_N"
                      name="pPKCG_N"
                      value={sampleData.pPKCG_N}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="ARC_N"
                      name="ARC_N"
                      value={sampleData.ARC_N}
                      onChange={onChangeFields}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="Ubiquitin_N"
                      name="Ubiquitin_N"
                      value={sampleData.Ubiquitin_N}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="pS6_N"
                      name="pS6_N"
                      value={sampleData.pS6_N}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="H3AcK18_N"
                      name="H3AcK18_N"
                      value={sampleData.H3AcK18_N}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <FormControl minWidth="120" varient="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-weight"
                      variant="outlined"
                      label="CaNA_N"
                      name="CaNA_N"
                      value={sampleData.CaNA_N}
                      onChange={onChangeFields}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <BorderGray>
                <Grid container xs={12} sm={12} md={12} spacing={2} justify="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <center>
                      Not sure about valid data inputs? Try any of the following
                      sample data...
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={showSample1}
                    >
                      Sample 1
                    </StyledButton>

                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={showSample2}
                    >
                      Sample 2
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </Grid>
                </Grid>
              </BorderGray>
              <br />
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Output</h3>
              {showSpinner && <Spinner text="Loading..." />}
              {resultData && (
                <section>
                  <Result>
                    <strong>Result:</strong>
                    {`${resultData}`}
                  </Result>
                  <Grid container direction="row" justify="center">
                    <Grid item xs={12}>
                      <HighchartContainer id="container" display />
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MiceProteinClassification);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 5px;
  margin-top: 3%;
   @media only screen and (max-width: 750px) {
    text-align: center;
  }
`;
