import React, { memo, useState, useEffect } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../../../../styles/common';
import { setInputData } from '../../../../../../store/actions/vnet';
import { StyledInput, FormControlLabelInfo } from '../../InputForm';
import { StyledFormControl } from "./ScatterPlot";

export const LinePlotForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);

  const [fromData, setFromdata] = useState({ input0: '' });
  const [dropdownList, setdropdownList] = useState(null);
  const [fieldError, setError] = useState({});

  const dispatch = useDispatch();
  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        columns: typeof value === 'string' ? value.split(',') : value,
      }),
    );
    if (fieldError.features) {
      setError({});
    }
  };

  const handleFromData = (e) => {
    if (Object.values(fromData).indexOf(e.target.value) == -1) {
      setFromdata({ ...fromData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
  };
  const handleToData = (e) => {
    if (e.target.name) {
      dispatch(
        setInputData({
          ...userInputData,
          columns: {
            ...userInputData?.columns,
            [e.target.name]: e.target.value,
          },
        }),
      );
      if (fieldError.input0) {
        setError({});
      }
    }
  };
  const addField = () => {
    const fromDataLen = Object.entries(fromData).length;
    if (fromDataLen < 4) {
      setFromdata({ ...fromData, [`input${fromDataLen + 1}`]: '' });
    }
  };
  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
    if (userInputData.specify == 'True' && userInputData?.columns) {
      const formData = {};
      Object.keys(userInputData?.columns).map((data, indx) => {
        formData[`input${indx}`] = data;
      });
      setFromdata(formData);
    }
  }, []);

  const handleSpecify = (e) => {
    const columns = e.target.value == 'True' ? {} : [];
    dispatch(
      setInputData({
        specify: e.target.value,
        columns,
      }),
    );
    setError({});
  };
  const handleFormReset = () => {
    dispatch(
      setInputData({
        specify: 'False',
        columns: [],
      }),
    );
  };

  const isFormValid = () => {
    if (userInputData?.specify == 'False') {
      if (userInputData?.columns?.length == 0) {
        setError({ features: true });
        return;
      }
    } else if (userInputData?.specify == 'True') {
      if (Object.entries(userInputData?.columns)?.length == 0) {
        setError({ input0: true });
        return;
      }
    }
    return true;
  };
  return (
    <section>
      <FormLabel component="label">Specify</FormLabel>
      <RadioGroup
        style={{ display: 'block' }}
        row
        name="specify"
        defaultValue="False"
        aria-label="Learn Mixture Weights"
        value={userInputData.specify}
        onChange={handleSpecify}
      >
        <FormControlLabelInfo
          value="False"
          control={<Radio color="primary" />}
          label="Default"
          labelPlacement="end"
          info="View all plots between the selected features"
        />
        <FormControlLabelInfo
          value="True"
          control={<Radio color="primary" />}
          label="Custom"
          labelPlacement="end"
          info="Choose the axes for the plots"
        />
      </RadioGroup>

      {userInputData.specify == 'True' && (
        <>
          <Grid container spacing={3} alignItems="center">
            {Object.entries(fromData).map((data) => (
              <>
                <Grid item xs={6}>
                  <StyledInput
                    name={data[0]}
                    label="X-label"
                    variant="outlined"
                    onChange={handleFromData}
                    select
                    required
                    fullWidth
                    value={fromData[data[0]]}
                    error={fieldError[data[0]]}
                    helperText={fieldError[data[0]] && 'Required Field'}

                  >
                    {dropdownList?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </Grid>
                <Grid item xs={6}>
                  <StyledInput
                    name={fromData[data[0]]}
                    label="Y-label"
                    variant="outlined"
                    onChange={handleToData}
                    select
                    required
                    fullWidth
                    value={
                      fromData[data[0]]
                      && userInputData.columns?.[fromData[data[0]]]
                    }
                    error={fieldError[data[0]]}
                    helperText={fieldError[data[0]] && 'Required Field'}
                  >
                    {dropdownList?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </Grid>
              </>
            ))}
          </Grid>
          <StyledButton onClick={addField} variant="outlined">
            +
          </StyledButton>
        </>
      )}
      {userInputData.specify == 'False' && (
        <StyledFormControl error={fieldError.features}>
          <InputLabel id="model-evaluation">Features</InputLabel>
          <Select
            multiple
            variant="outlined"
            name="features"
            value={userInputData.columns}
            onChange={handleMultiple}
            input={<Input />}
            fullWidth
            required
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                },
              },
            }}
            renderValue={(selected) => selected.join(', ')}
          >
            {dropdownList?.map((value) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={userInputData.columns.indexOf(value) > -1} />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </Select>
          {fieldError.features && <FormHelperText>Required Field</FormHelperText>}
        </StyledFormControl>
      )}
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </section>
  );
});
