import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  Button,
  Paper,
  Backdrop,
  CircularProgress,
  DialogTitle, Dialog, DialogContent,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faAngleRight,
  faAngleLeft,
  faFolderPlus,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
// local components
import Navbar from '../../../common/Navbar';
import {
  Paragraph,
  StyledButton,
  StyledBackdrop,
} from '../../../../styles/common';
import InputForm from './InputForm';
import { formData, API } from './constants';
import Table from './Table';
import postReq from './Service';
import NotificationWidget from '../../../common/Notification';

import {
  setInputData,
  setCanvaList,
  setFeatureData,
  setResult,
} from '../../../../store/actions/vnet';

// images
import fasterAi from '../../../../assets/images/solutions/fasterAi.webp';
import iaas from '../../../../assets/images/solutions/iaas.webp';
import computerVision from '../../../../assets/images/solutions/computerVision.webp';
import syntheticGen from '../../../../assets/images/solutions/syntheticGen.webp';
import platformAi from '../../../../assets/images/solutions/platformAi.webp';
import { StyledDialog, StyledDialogContent } from './FasterAI';
import getUserData from '../../../../utils';
import FormGenerator from './components/FormGenerator';
import { CURRENT_ENV } from '../../../../utils/services/api-config';

function FasterAI({ vnet }) {
  const menuData = {
    data: [
      {
        title: 'Synthetic Data Generator',
        img: syntheticGen,
        link: '/synthetic-data',
        text: 'vNet - Synthetic Data Generator is a platform to create artificial data by using different algorithms that mirror the statistical properties of the original data but does not reveal any information regarding real people. Generating synthetic data using this platform enables business users to maintain privacy, successfully carry out product testing and train machine learning algorithms. The platform can be used when there is a business trade-off between data privacy and data utility while selecting a privacy-enhancing technology.',
      },
    ],
    ai: [
      {
        title: 'Faster AI',
        img: fasterAi,
        ProductSuite: 'faster-ai',
        ProductId: 'TD',
        showProjectBtn: true,
        link: '/solutions/faster-ai',
        text: 'vNet - Faster AI is an intuitive codeless AI platform to build, train, deploy and optimize ML models. It leverages applications developed with Stanford University to create noise-free and unbiased datasets.',
      },
      {
        title: 'Computer Vision',
        img: computerVision,
        link: '/categories/offerings/computer-vision',
        text: 'vNet - Computer Vision  is a No Code/Low Code Image analytics platform to boost Industry solutions and optimize performance, cost and efficiency.',
      },
    ],
    business: [
      {
        title: 'Platform AI',
        img: platformAi,
        link: '/themes/platform-ai',
        text: 'vNet - Platform AI is a business model designer to piece together pre-trained AI-models from the vLife Marketplace. It would enable Business Analysts to put together end-to-end solutions (In-progress)',
      },
      {
        title: 'IAAS',
        img: iaas,
        link: '/innovation-as-service',
        text: 'vNet – Innovation as a Service is an AI platform to intuitively select 3rd party applications and software across MLOps, Transfer Learning and Federated Learning (In-progress)',
      },
    ],
  };
  const [dropdowndata, setDropdowndata] = useState([]);
  const [inputType, setInputType] = useState('select');

  const [showWorkspace, setWorkspace] = useState(false);
  const [selctedMenu, setMenu] = useState('ai');
  const [openPopup, setPopup] = useState(false);
  const [fields, setFields] = useState(formData.createProject.initialData);
  const [workSpaceData, setWorkSpace] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [projectDetails, setProjectDetails] = useState(false);
  const [notification, setNotification] = useState('');
  const [userName, setUser] = useState('');
  const dispatch = useDispatch();

  const Context_param = {
    UserID: vnet.Context_param.UserID,
    ProductSuite: 'faster-ai',
    Environment: CURRENT_ENV,
    StageID: 'Common_API',
    FeatureID: 'Common_API',
    FunctionID: 'myWorkspace',
    Actions: '',
    ProductID: 'vNet',
  };

  const handleFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    const Healthcarelist = [
      { label: 'Patient Engagement', value: 'Patient Engagement' },
      { label: 'Care Management', value: 'Care Management' },
      { label: 'Provider Journey', value: 'Provider Journey' },
      { label: 'Claims Processing', value: 'Claims Processing' },
    ];
    const LifeScienceslist = [
      { label: 'Clinical Trials', value: 'Clinical Trials' },
      { label: 'Commercial Analytics', value: 'Commercial Analytics' },
      { label: 'Patient Engagement', value: 'Patient Engagement' },
      {
        label: 'Provider and Payer Engagement',
        value: 'Provider and Payer Engagement',
      },
    ];

    if (value == 'Healthcare') {
      setInputType('select');
      setDropdowndata([...Healthcarelist]);
    } else if (value == 'Life Sciences') {
      setInputType('select');
      setDropdowndata([...LifeScienceslist]);
    } else if (value == 'Others') {
      setInputType('text');
    }

    setFields({
      ...fields,
      [name]: value,
      [`error_${name}`]: false,
    });
  };
  const createProject = () => {
    const fieldErr = {};
    let isValid = true;
    Object.keys(fields).map((key) => {
      if (!fields[key]) {
        fieldErr[`error_${key}`] = true;
        isValid = false;
      }
    });
    setFields({
      ...fields,
      ...fieldErr,
    });
  };
  const handleMenu = (e) => {
    setMenu(e.currentTarget.id);
  };

  const handlePopupClose = (e) => {
    setPopup(false);
  };

  const handlePopup = (e) => {
    const productId = e.currentTarget.getAttribute('id');
    const productSuite = e.currentTarget.getAttribute('suite');
    setPopup(true);
    setProductDetails({ productSuite, productId });
  };

  const handleWorkspace = () => {
    setWorkspace(!showWorkspace);
    !showWorkspace && getWorkspaceData();
  };

  async function getCurrentStage(e) {
    const val = e.target.id;
    let result;
    try {
      const postparams = {
        Context_param: {
          ...Context_param,
          FunctionID: 'resumeStage',
          ProjectID: val,
        },
        Content_param: {},
      };

      setLoading(true);
      result = await postReq(postparams);
      if (result.Error_Flag) {
        setNotification({ open: 'error', message: result.Error_UI });
      } else {
        setNotification({
          open: 'success',
          message: result.Console || 'Project fetched Succesfully',
        });
        dispatch(
          setFeatureData({
            ...result?.Result?.currentStage,
          }),
        );
        dispatch(setCanvaList(result?.Result?.canvas));
        dispatch(
          setResult({
            modelData:
              result?.Result?.historyData
                && Object.keys(result?.Result?.historyData).length > 0
                ? {
                  historyData: result?.Result?.historyData,
                }
                : null,
          }),
        );
        setProjectDetails(result?.Result?.currentStage);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  async function getWorkspaceData() {
    let result;
    try {
      setLoading(true);
      const postparams = {
        Context_param: {
          ...Context_param,
          FunctionID: 'myWorkspace',
        },
        Content_param: {},
      };
      result = await postReq(postparams);
      if (result?.Error_Flag) {
        setNotification({ open: 'error', message: result.Error_UI });
      } else {
        setNotification({ open: 'success', message: 'Workspace loaded' });
        setWorkSpace(result?.Result);
      }
    } catch (error) {
      setNotification({ open: 'error', message: result?.Error_UI });
    }
    setLoading(false);
  }
  async function createNewProject(e) {
    let result;

    try {
      const postparams = {
        Context_param: {
          ...Context_param,
          FunctionID: 'newProject',
          ProjectName: fields.projName,
          ProductID: productDetails.productId,
          ProductSuite: productDetails.productSuite,
          Domain_Name: fields.domain,
          SubDomain: fields.subDomain,
          Project_Description: fields.description,
          Dataset_Source: fields.datasetSource,
          Updated_By: vnet.Context_param.UserID,
          Update_Date: new Date().toLocaleString(),
          Created_By: vnet.Context_param.UserID,
          Created_Date: new Date().toLocaleString(),
        },
        Content_param: {},
      };

      if (
        fields.domain
        && fields.subDomain
        && fields.datasetSource
        && fields.description
        && fields.projName != ' '
      ) {
        setLoading(true);
        handlePopupClose();

        result = await postReq(postparams);

        if (!result.Error_Flag) {
          dispatch(
            setFeatureData({
              ProjectName: result?.Result?.Project_Name,
              ProductID: productDetails.productId,
              UserID: vnet.Context_param.UserID,
              ProjectID: result?.Result?.Project_ID,
              ProductSuite: productDetails.productSuite,
              FeatureID: '',
              FunctionID: '',
              StageID: 'Data_Input',
            }),
          );
          dispatch(setResult(null));
          dispatch(setCanvaList([]));
          setNotification({ open: 'success', message: 'Created New Project' });
          setTimeout(() => {
            setProjectDetails(result.Result);
          }, 1000);
        } else {
          setNotification({ open: 'error', message: result.Error_UI });
        }
        setLoading(false);
      } else {
        setNotification({ open: 'error', message: 'Please enter all fields' });
      }
    } catch (error) {
      console.log(error);
      setNotification({ open: 'error', message: error.message });
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch(
      setFeatureData({
        ...vnet?.Context_param,
        Environment: CURRENT_ENV,
      }),
    );
    const name = getUserData()?.token?.name;
    const formatedName = name
      .toLowerCase()
      .split(' ')
      ?.map((data) => data?.slice(0, 1)?.toUpperCase() + data?.slice(1))
      ?.join(' ');
    setUser(formatedName);
  }, []);

  return (
    <VnetContainer>
      {projectDetails && (
        <Redirect
          to={{ pathname: '/faster-ai', state: { projectDetails } }}
        />
      )}
      <Navbar />
      <Content>
        <Grid item xs={12} md={3}>
          <VNetHeading1>
            vLife™
            {' '}
            <font color="#fc7703">|</font>
            {' '}
            vNet
          </VNetHeading1>
        </Grid>
        <h1>An AI Enterprise for Building Models</h1>
        <h2>SMARTER | FASTER | BETTER</h2>
        <NavContainer>
          <MenuGrid
            id="data"
            isSelected={selctedMenu === 'data'}
            item
            xs={12}
            sm={4}
            md={2}
            onClick={handleMenu}
          >
            <h3>Data</h3>
          </MenuGrid>
          <MenuGrid
            id="ai"
            isSelected={selctedMenu === 'ai'}
            item
            xs={12}
            sm={4}
            md={2}
            onClick={handleMenu}
          >
            <h3>Artificial Intelligence</h3>
          </MenuGrid>
          <MenuGrid
            id="business"
            isSelected={selctedMenu === 'business'}
            item
            xs={12}
            sm={4}
            md={2}
            onClick={handleMenu}
          >
            <h3>Business</h3>
          </MenuGrid>
        </NavContainer>

        {selctedMenu && (
          <DataContainer container justify="center" spacing={3}>
            {menuData[selctedMenu].map((data) => (
              <ContentGrid item xs={12} sm={12 / menuData[selctedMenu].length}>
                <h2>{data.title}</h2>
                <DataContent>
                  {data.showProjectBtn ? (
                    <GridFasterAI container>
                      <Grid item>
                        <StyledButton
                          variant="outlined"
                          display={selctedMenu}
                          endIcon={<FontAwesomeIcon icon={faFolderPlus} />}
                          onClick={handlePopup}
                          id={data.ProductId}
                          suite={data.ProductSuite}
                        >
                          Create Project
                        </StyledButton>
                        <Paragraph color="#f8edeb">{data.text}</Paragraph>
                      </Grid>
                    </GridFasterAI>
                  ) : (
                    <GridContent container>
                      <StyledButton
                        variant="outlined"
                        display={selctedMenu}
                        endIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                        id={data.ProductId}
                        suite={data.ProductSuite}
                        component={Link}
                        to={data.link}
                      >
                        Explore
                      </StyledButton>
                      <Paragraph color="#f8edeb">{data.text}</Paragraph>
                    </GridContent>
                  )}

                  <img src={data.img} alt="" />
                </DataContent>
              </ContentGrid>
            ))}
          </DataContainer>
        )}
        <StyledBackdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      </Content>

      <WorkspaceContainer display={showWorkspace}>
        <span onClick={handleWorkspace} id="workspace-btn">
          <FontAwesomeIcon icon={showWorkspace ? faAngleRight : faAngleLeft} />
          &nbsp;My workspace
        </span>
        <WorkSpace id="workspace-content" display={showWorkspace}>
          <h1>
            Welcome back, {userName}
          </h1>
          <Table
            tableData={workSpaceData}
            onClick={getCurrentStage}
            for="ProjectID"
          />
        </WorkSpace>
      </WorkspaceContainer>

      <StyledDialog open={openPopup} onClose={handlePopupClose}>
        <DialogTitle>Create Project</DialogTitle>
        <StyledDialogContent>
          <FormGenerator
            fieldList={[
              {
                label: 'Project Name',
                name: 'projName',
                type: 'text',
                required: true,
              },
              {
                label: 'Domain',
                name: 'domain',
                type: 'select',
                dropdownList: [
                  { label: 'Healthcare', value: 'Healthcare' },
                  { label: 'Life Sciences', value: 'Life Sciences' },
                  { label: 'Others', value: 'Others' },
                ],
                required: true,
              },
              {
                label: 'Sub Domain',
                name: 'subDomain',
                type: inputType,
                dropdownList: dropdowndata,
                required: true,
              },
              {
                label: 'DataSet Source',
                name: 'datasetSource',
                type: 'text',
                required: true,
              },
              {
                label: 'Description',
                name: 'description',
                type: 'multiline',
                rows: 3,
                required: true,
              },
            ]}
            handleChange={handleFields}
          />
          <CreateBtn variant="outlined" color="primary">
            <Link onClick={createNewProject}>Create</Link>
          </CreateBtn>
        </StyledDialogContent>
      </StyledDialog>

      <NotificationWidget snackbar={notification} />
    </VnetContainer>
  );
}
const mapStateToProps = (state) => ({ vnet: state.vnet });

export default connect(mapStateToProps)(FasterAI);
const CreateBtn = styled(StyledButton)`
  &.MuiButton-root {
    border-radius: 5px;
    background: #053557;
    a {
      text-decoration: none;
      color: #ffff;
    }
  }
`;
const MenuGrid = styled(Grid)`
  background-color: ${(props) => (props.isSelected ? '#f4f1de' : '#000000')};
  color: ${(props) => (props.isSelected ? '#000000' : 'inherit')};
  cursor: pointer;
  transition: 0.5s ease-in-out;
  &:hover {
    background-color: #f4f1de;
    color: #000000;
  }
  @media only screen and (max-width: 600px) {
    margin: 5px;
  }
`;
const DataContent = styled(Paper)`
  background: rgba(144, 160, 171, 0.4);
  width: auto;
  text-align: center;
  height: 100%;
  padding: 20px;
  transition: height 0.8s linear;
  .MuiTypography-root {
    margin: 8px;
    text-align: justify;
  }
  .MuiButton-root {
    color: #ffff;
    background: black;
    border: 1px solid #ffff;
  }
  img {
    border-radius: 5px;
    max-width: 70%;
    height: 40%;
    background-size: cover;
  }
  transition: opacity 0.4s linear;
`;
const ContentGrid = styled(Grid)`
  h2 {
    color: #c1cad6;
  }
`;
const DataContainer = styled(Grid)`
  padding: 10px;
  margin: 0 20px;
  width: auto;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 6px;

  h2 {
    color: White;
  }
  overflow: hidden;
`;
const NavContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
  #data {
    border-radius: 10px 0 0 10px;
    border-right: 3px solid #f8edeb;
    @media only screen and (max-width: 600px) {
      border-radius: 5px;
      border-left: 2px solid #f8edeb;
      border-right: 2px solid #f8edeb;
    }
  }
  #ai {
    @media only screen and (max-width: 600px) {
      border-radius: 5px;
      border-left: 2px solid #f8edeb;
      border-right: 2px solid #f8edeb;
    }
  }
  #business {
    border-radius: 0 10px 10px 0;
    border-left: 3px solid #f8edeb;
    @media only screen and (max-width: 600px) {
      border-radius: 5px;
      border-left: 2px solid #f8edeb;
      border-right: 2px solid #f8edeb;
    }
  }
`;
const VnetContainer = styled.div`
  background: #e5e5e5;
  width: 100%;
  padding-top: 50px;
  h1 {
    font-size: 35px;
  }
  @media only screen and (max-width: 900px) {
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 450px) {
    h1 {
      font-size: 20px;
      margin: 10px 0;
    }
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 15px;
    }
  }
`;
const Content = styled.section`
  padding-top: 50px;
  height: auto;
  min-height: 85vh;
  width: 100%;
  background: linear-gradient(to bottom, #053557, #01629a);
  text-align: center;
  color: #ffff;
`;
export const WorkSpace = styled(Grid)`
  padding: 15px;
  text-align: center;
  color: white;
  @media only screen and (max-width: 1000px) {
    padding: 10px;
  }
  @media only screen and (max-width: 600px) {
    padding: 5px;
  }
`;
export const WorkspaceContainer = styled.div`
  position: fixed;
  top: 100px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  animation: ${(props) => (props.display ? 'slide' : 'slideout')} 0.4s ease-out;

  #workspace-content {
    width: 85%;
    background: #2c3e50;
    height: 90vh;
    display: ${(props) => (props.display ? 'block' : 'none')};
    transition: 0.4s linear;
    border-radius: 0px 0 0 5px;
  }
  #workspace-btn {
    padding: 4px;
    background: #264653;
    border-radius: 3px 0 0 3px;
    color: #ffff;
    height: 20px;
    cursor: pointer;
  }
  @media only screen and (max-width: 1100px) {
    font-size: 14px;
    flex-wrap: wrap;
    height: 30px;

    top: 70px;
    #workspace-btn {
      float: right;
    }
    #workspace-content {
      width: 100%;
    }
  }
  @keyframes slide {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes slideout {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }
`;
const VNetHeading1 = styled.h2`
  font-size: 30px;
  font-weight: bolder;
  color: white;
  margin-top: -10px;
  @media only screen and (max-width: 959px) {
    padding: 0% 0%;
    margin: 8% 0% 0% 0%;
  }
  @media only screen and (max-width: 600px) {
    font-size: 22px;
    margin: 6% 0% 0% 0%;
  }
  @media only screen and (max-width: 450px) {
    font-size: 18px;
  }
`;
const GridFasterAI = styled(Grid)`
justify-content: left;
  }
`;
const GridContent = styled(Grid)`
justify-content: center;
  }
`;

const StyledLink = styled(Link)`
color: #FFF;
@media only screen and (max-width: 600px) {
  font-size: 14px;
}
@media only screen and (max-width: 600px) {
  font-size: 12px;
}
@media only screen and (max-width: 450px) {
  font-size: 11px;
}}
`;
