import React, { memo, useState } from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  Typography,
  Stepper,
  StepLabel,
  StepConnector,
  Step,
  Grid,
  TextField,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import Collapse from '../../common/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faLocationArrow,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faMoneyCheck,
  faRedoAlt,
  faTasks,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from '../../../styles/common';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import stages from '../../../assets/images/app/stages.webp';
import CommonService from '../../../utils/services/CommonService';
import Spinner from '../../common/loading/Spinner';

const OCRCheckValidation = () => {
  const [stepperData, setStepperData] = useState({
    activeStep: 0,
    activeIndex: 'Upload Image',
  });
  const steps = [
    'Upload Image',
    'OCR - Extraction',
    'Level 1 Approval',
    'Level 2 Approval',
  ];
  const [loader, setloader] = useState(false);
  const [tableData, settableData] = useState(null);
  const [status, setshowStatus] = useState(false);
  const [resData, setResponseData] = useState(false);
  const [uploadedFile, setuploadedFile] = useState(null);
  const [notification, setNotification] = useState('');

  const { getQueryResult, uploadFile } = CommonService(
    'z1dApps',
    'ocrCheckvalidation'
  );

  const getResult = (e) => {
    const param = e.currentTarget.getAttribute('data-id');
    settableData('');
    setshowStatus(false);
    setloader(true);
    getQueryResult('sample', param)
      .then((res) => {
        const result = JSON.parse(res.result);

        setloader(false);
        settableData(result.data[0]);
        setResponseData({
          ...resData,
          status: res.status,
          validity: res.validity_status,
          duplicate: res.check_number_status,
          reason: res.reason_list,
          money_status: res.money_status,
          second_lvl_duplicate: res.Payee_Name_Money_duplicate_status,
        });
        nextStep();
      })
      .catch((err) => {
        setloader(false);
      });
  };
  const onChangeFields = (e) => {
    setuploadedFile(e.target.files[0]);
  };

  const getuploadResult = () => {
    if (!uploadedFile) {
      setNotification({ open: 'error', message: 'please select a file' });
      return;
    }
    settableData('');
    setshowStatus(false);
    setloader(true);
    uploadFile('upload', uploadedFile)
      .then((res) => {
        const result = JSON.parse(res.result);

        setloader(false);
        settableData(result.data[0]);
        setResponseData({
          ...resData,
          status: res.status,
          validity: res.validity_status,
          duplicate: res.check_number_status,
          reason: res.reason_list,
          money_status: res.money_status,
          second_lvl_duplicate: res.Payee_Name_Money_duplicate_status,
        });
        nextStep();
      })
      .catch((err) => {
        setloader(false);
      });
  };
  const previousStep = () => {
    setStepperData({
      activeStep: stepperData.activeStep - 1,
      activeIndex: steps[stepperData.activeStep - 1],
    });
  };
  const nextStep = () => {
    setStepperData({
      activeStep: stepperData.activeStep + 1,
      activeIndex: steps[stepperData.activeStep + 1],
    });
  };
  const firstStep = () => {
    setStepperData({
      activeStep: 0,
      activeIndex: 'Upload Image',
    });
  };
  const refreshCheck = () => {
    settableData('');
    setshowStatus(false);
    setloader(true);
    getQueryResult('refresh')
      .then((res) => {
        const result = JSON.parse(res.result);

        setloader(false);
        firstStep();
      })
      .catch((err) => {
        setloader(false);
      });
  };
  const getContent = () => {
    switch (stepperData.activeIndex) {
      case 'Upload Image':
        return (
          <div>
            <Grid
              container
              xs={12}
              direction='row'
              justify='center'
              alignItems='center'
              alignContent='space-between'
            >
              <Grid item xs={12} sm={4} md={2}>
                <StyledButton
                  variant='contained'
                  color='primary'
                  data-id='sample/'
                  onClick={getResult}
                >
                  Sample1
                </StyledButton>
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <StyledButton
                  variant='contained'
                  color='primary'
                  data-id='sample1/'
                  onClick={getResult}
                >
                  Sample2
                </StyledButton>
              </Grid>
              <Grid item xs={12} md={1}>
                <h3>OR</h3>
              </Grid>
              <Grid item xs={12} md={6} alignItems='center'>
                <TextField
                  id='fileUpload'
                  type='file'
                  onChange={onChangeFields}
                  helperText='please upload a pdf/image file'
                />
                <StyledButton
                  variant='contained'
                  color='primary'
                  onClick={getuploadResult}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
          </div>
        );
      case 'OCR - Extraction':
        return (
          tableData && (
            <section>
              <Title>OCR - Extraction</Title>

              <Grid
                container
                xs={12}
                spacing={2}
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={12} sm={7}>
                  <TableContainer>
                    <Table aria-label='sticky table'>
                      <TableHead>
                        <TableRow>
                          <HeaderCell key='condition' align='left'>
                            Parameters
                          </HeaderCell>
                          <HeaderCell key='statement' align='left'>
                            Value
                          </HeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData &&
                          Object.entries(tableData).map(
                            (row) =>
                              row[0] !== 'index' && (
                                <TableRow key={row[0]}>
                                  {row.map((field) => (
                                    <StyledCell align='left'>
                                      {field}
                                    </StyledCell>
                                  ))}
                                </TableRow>
                              )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    onClick={previousStep}
                  >
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                  </StyledButton>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    onClick={nextStep}
                  >
                    Validate
                  </StyledButton>
                </Grid>
              </Grid>
            </section>
          )
        );
      case 'Level 1 Approval':
        return (
          <section>
            <Title>Approval Checklist</Title>
            {tableData && (
              <Grid
                container
                xs={12}
                spacing={2}
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={12} sm={7}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <HeaderCell key='condition' align='left'>
                          <Typography variant='h6' component='h6'>
                            Parameters
                          </Typography>
                        </HeaderCell>
                        <HeaderCell key='statement' align='left'>
                          <Typography variant='h6' component='h6'>
                            Status
                          </Typography>
                        </HeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <List isValid={resData.validity === 'Approved'}>
                        Check Date
                      </List>
                      <List isValid={resData.duplicate === 'notduplicated'}>
                        Exact Duplicate
                      </List>
                      <List
                        isValid={resData.money_status === 'Approved'}
                        pending={resData.money_status !== 'Approved'}
                      >
                        Check Amount
                      </List>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <section>
                    {resData.status === 'Approved' ||
                    resData.status === 'Pending' ? (
                      <Alert severity='success'>
                        <AlertTitle>Approved</AlertTitle>
                      </Alert>
                    ) : (
                      resData.status === 'Declined' && (
                        <Alert severity={'error'} style={{ textAlign: 'left' }}>
                          <AlertTitle>{status}</AlertTitle>
                          <ul type='none'>
                            {resData.reason.map((reason) => (
                              <li>{reason}</li>
                            ))}
                          </ul>
                        </Alert>
                      )
                    )}
                  </section>
                </Grid>
              </Grid>
            )}
            <br />
            <br />
            <StyledButton
              variant='contained'
              color='primary'
              onClick={previousStep}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
            </StyledButton>
            {(resData.status === 'Approved' ||
              resData.status === 'Pending') && (
              <StyledButton
                variant='contained'
                color='primary'
                onClick={nextStep}
              >
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </StyledButton>
            )}
            <StyledButton
              variant='contained'
              color='primary'
              onClick={firstStep}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </StyledButton>
            {resData.duplicate === 'Declined' && (
              <StyledButton
                variant='contained'
                color='primary'
                onClick={refreshCheck}
              >
                Refresh
              </StyledButton>
            )}
          </section>
        );

      case 'Level 2 Approval':
        return (
          <section>
            <Title>Level 2 Approval</Title>
            <Grid
              container
              xs={12}
              spacing={2}
              direction='row'
              justify='center'
              alignItems='center'
              alignContent='left'
            >
              <Grid item xs={12} sm={7}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <HeaderCell key='condition' align='left'>
                        <Typography variant='h6' component='h6'>
                          Parameters
                        </Typography>
                      </HeaderCell>
                      <HeaderCell key='statement' align='left'>
                        <Typography variant='h6' component='h6'>
                          Status
                        </Typography>
                      </HeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <List
                      isValid={resData.money_status === 'Approved'}
                      pending={resData.money_status !== 'Approved'}
                    >
                      Check Amount
                    </List>
                    <List
                      isValid={resData.second_lvl_duplicate === 'notduplicated'}
                    >
                      Duplicate{' '}
                    </List>
                    <List isValid={resData.duplicate === 'notduplicated'}>
                      Exact Duplicate
                    </List>
                    <List isValid={resData.validity === 'Approved'}>
                      Check Date
                    </List>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} sm={7}>
                <section>
                  {resData.status === 'Approved' ? (
                    <Alert severity='success'>
                      <AlertTitle>{resData.status}</AlertTitle>
                    </Alert>
                  ) : (
                    <Alert
                      severity={
                        resData.status === 'Pending' ? 'warning' : 'error'
                      }
                      style={{ textAlign: 'left' }}
                    >
                      <AlertTitle>{resData.status}</AlertTitle>
                      <ul type='none'>
                        {resData.reason.map((reason) => (
                          <li>{reason}</li>
                        ))}
                      </ul>
                    </Alert>
                  )}
                  <br />
                  <br />
                  <StyledButton
                    variant='contained'
                    color='primary'
                    onClick={firstStep}
                  >
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                  </StyledButton>
                </section>
              </Grid>
            </Grid>
          </section>
        );

      default:
        return null;
    }
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text='Description'>
        <Paragraph>
          <p>
            Optical Character Recognition technique in healthcare has the
            capability to recognize alphanumeric characters present in documents
            and store the information in an electronic file. This algorithm
            helps to reduce the use of paper files or microfiche and make the
            document electronically exchangeable.
          </p>
          <p>
            An Automated Algorithm comprising of Name Entity Recognition and
            Optical Character Recognition techniques. It is used on checks to
            read Payer’s and Payee’s Names, Check Amount, Date and Check Number.
            NLTK package of Natural Language Processing in Python is used for
            data wrangling, removing stop words and garbage values and to clean
            the data.
          </p>
          <p>
            Once the data is extracted from the image, it is then stored in an
            electronically shareable file making it easier to understand and
            manipulate. OCR takes up scanned image of the check leaf in jpeg/png
            format and returns the information written on it in text format.
          </p>
          <center>
            <img width='70%' src={stages} />
          </center>
          <p>
            User can upload a clear scanned copy of the check to test the
            application. Here a insurance check approval workflow is also
            depicted, showcasing various steps, conditions which need to be
            fulfilled before a check can be approved for amount reimbursement.
            It could be date check, or check amount limit. Explore the below
            examples to understand the different scenarios.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/OCR/sample1.PNG"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant='outlined'
                color='primary'
                size='large'
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href='http://material.vlifevirtusa.com/OCR/OCR.html'
                  target='_blank'
                  rel='noreferrer'
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant='outlined'
                color='primary'
                size='large'
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href='https://vlife.virtusa.com/static/media/OCR_Check_Validation.2ca593d7.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  Read More
                </a>
              </StyledButton>
              <StyledButton
                variant='outlined'
                color='primary'
                size='large'
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href='https://material.vlifevirtusa.com/OCR/sample1.PNG'
                  target='_blank'
                  rel='noreferrer'
                >
                  View Sample Data 1
                </a>
              </StyledButton>
              <StyledButton
                variant='outlined'
                color='primary'
                size='large'
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href='https://material.vlifevirtusa.com/OCR/sample.png'
                  target='_blank'
                  rel='noreferrer'
                >
                  View Sample Data 2
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text='Demo'>
        <DemoContainer>
          <Hidden only={['md', 'lg']}>
            <StepperContainer
              activeStep={stepperData.activeStep}
              connector={<StyledConnect />}
              orientation='vertical'
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </StepperContainer>
          </Hidden>
          <Hidden only={['xs', 'sm']}>
            <StepperContainer
              activeStep={stepperData.activeStep}
              connector={<StyledConnect />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </StepperContainer>
          </Hidden>
          {stepperData.activeIndex && getContent()}
          {loader && <Spinner />}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(OCRCheckValidation);

function ColorlibStepIcon(props) {
  const { active, completed } = props;
  const icons = {
    1: <CloudUploadIcon />,
    2: <FontAwesomeIcon icon={faMoneyCheck} />,
    3: <FontAwesomeIcon icon={faTasks} />,
    4: <ThumbUpIcon />,
  };

  return (
    <div className={`stepicon-root ${(active || completed) && 'active'}`}>
      {icons[String(props.icon)]}
    </div>
  );
}
function List({ children, isValid, pending }) {
  return (
    <TableRow>
      <StyledCell align='left'>
        <Typography variant='h6' component='h6'>
          <FontAwesomeIcon icon={faLocationArrow} /> {children}{' '}
        </Typography>
      </StyledCell>
      <StyledCell align='left'>
        <Typography variant='h6'>
          {isValid ? (
            <FontAwesomeIcon icon={faCheckCircle} color='#4caf50' />
          ) : pending ? (
            <FontAwesomeIcon icon={faExclamationCircle} />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} color='#d13f48' />
          )}
        </Typography>
      </StyledCell>
    </TableRow>
  );
}
const TableContainer = styled(Paper)`
  &.MuiPaper-root {
    overflow: auto;
  }
`;
const StepperContainer = styled(Stepper)`
  &.MuiStepper-root {
    .MuiStep-root {
      .MuiStepLabel-root {
        .MuiStepLabel-iconContainer {
          .stepicon-root {
            background-color: #ccc;
            z-ondex: 1;
            color: #fff;
            width: 50px;
            height: 50px;
            display: flex;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
          }
          .active {
            background-image: linear-gradient(
              136deg,
              rgb(242, 113, 33) 0%,
              rgb(233, 64, 87) 50%,
              rgb(138, 35, 135) 100%
            );
          }
        }
      }
    }
  }
`;
const StyledConnect = styled(StepConnector)`
  &.MuiStepConnector-horizontal {
    background-color: #eaeaf0;
    border: 0;
    height: 3px;
    border-radius: 4px;
  }
  &.MuiStepConnector-active,
  &.MuiStepConnector-completed {
    background-image: linear-gradient(
      95deg,
      rgb(242, 113, 33) 0%,
      rgb(233, 64, 87) 50%,
      rgb(138, 35, 135) 100%
    );
  }
  @media only screen and (max-width: 960px) {
    &.MuiStepConnector-active,
    &.MuiStepConnector-completed {
      width: 0;
    }
  }
`;
const Title = styled.h2`
  color: #486fab;
`;
const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: #3c40af;
    color: #ffff;
  }
`;
const StyledCell = styled(TableCell)`
  @media only screen and (max-width: 450px) {
    &.MuiTableCell-root {
      font-size: 0.685rem;
    }
  }
`;
