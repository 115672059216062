import React from "react";
import Grid from "@material-ui/core/Grid";
import PivotTableUI from "react-pivottable/PivotTableUI";
import TableRenderers from "react-pivottable/TableRenderers";
import * as pivotUtils from "react-pivottable/Utilities";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import StringSimilarity from "string-similarity";
import { Button, MenuItem } from "@material-ui/core";

import { ToastContainer, toast } from 'react-toastify';
import MatrixService from "./MatrixService";
//
import "./CohortAnalyzerContentView.css";
import "react-pivottable/pivottable.css";
import getUserData from "../../../utils";

const Helpers = {
  arraysEqual(_arr1, _arr2) {
    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) { return false; }

    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) { return false; }
    }

    return true;
  },
  isSubsetOf(set, subset) {
    if (!Array.isArray(set) || !Array.isArray(subset) || set.length === 0 || subset.length === 0) { return false; }
    return Array.from(new Set([...set, ...subset])).length === set.length;
  },
};
export const cohortFilters = {
  "Type 2 Diabetes Mellitus": {
    payloadKey: "Type2Diabetes",
  },
  "Chronic Kidney Disease (Ckd)": {
    payloadKey: "ChronicKidneyDisease",
  },
};
// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

class PivotTableUISmartWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pivotState: props,
      aggregateTotals: this.props.getTotalAggregates(props),
      cohortName: '',
      selectedCohort: '',
      savedCohorts: [],
      menuAnchorEl: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      pivotState: nextProps,
      aggregateTotals: this.props.getTotalAggregates(nextProps),
    });
  }

  componentDidMount() {

  }

  onSearchChange(s) {
    this.props.onSearchChange(s);
  }

  onNameChange(e) {
    this.setState({ cohortName: e.target.value });
  }

  onClickOpenButton(e) {
    this.setState({ menuAnchorEl: e.currentTarget });
  }

  handleCohortMenuClose() {
    this.setState({ menuAnchorEl: null });
  }

  handleCohortSelect(e) {
    const cohortId = e.currentTarget.value;
    if (!cohortId) {
      this.setState({ menuAnchorEl: null });
    } else {
      const cohort = this.state.savedCohorts.filter((cohort) => cohort.id === cohortId)[0];
      const filterDetails = JSON.parse(cohort.filter_details);
      const tempPivotState = { ...this.state.pivotState };
      tempPivotState.cols = filterDetails.fields_x;
      tempPivotState.rows = filterDetails.fields_y;
      tempPivotState.valueFilter = filterDetails.valueFilter;
      tempPivotState.rendererName = filterDetails.renderer_name
        ? filterDetails.renderer_name : tempPivotState.rendererName;
      this.setState({ menuAnchorEl: null, cohortName: cohort.cohort_name });
      this.props.onChange(tempPivotState);
    }
  }

  render() {
    let cohortMenuItems = [];
    if (this.state.savedCohorts.length === 0) {
      cohortMenuItems.push(<MenuItem
        className="font-resize cohortSelectMenuItem"
        value=""
        onClick={this.handleCohortSelect.bind(this)}
      >
        No saved cohorts
      </MenuItem>);
    } else {
      cohortMenuItems = this.state.savedCohorts
        .map((cohort) => (
          <MenuItem
            className="font-resize cohortSelectMenuItem"
            value={cohort.id}
            onClick={this.handleCohortSelect.bind(this)}
          >
            {cohort.cohort_name}
          </MenuItem>
        ));
    }
    return (
      <div>
        <Grid>
          <Grid container>

            <Grid item md={9} className="text-center">
              <h3>Cohort Analysis (Patient Population)</h3>
            </Grid>

            <Grid item md={3}>
              <p className="text-center pvtAxisContainer pvtHorizList pvtCols">
                <div>Cohort Patient Population</div>
                <div>{this.state.aggregateTotals.grandTotal.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
              </p>
            </Grid>

          </Grid>
        </Grid>
        <PivotTableUI
          renderers={({ ...TableRenderers, ...PlotlyRenderers })}
          {...this.state.pivotState}
          onSearchChange={(s) => {
            this.onSearchChange(s);
          }}
          unusedOrientationCutoff={0}
        />
      </div>
    );
  }
}

class CohortAnalyzerContentView extends React.Component {
  componentDidMount() {
    if (this.state.mode === "thinking") {
      this.toggleLoadingOverlay(true);
    } else {
      this.toggleLoadingOverlay(false);
    }
  }

  onClickPreviewButton(e) {
    const userId = getUserData()?.token.emailid;
    const cohorts = JSON.parse(localStorage.getItem('filterState'));
    const cohortCol = JSON.parse(cohorts.cols);
    const cohortRow = JSON.parse(cohorts.rows);
    const cohortFilter = JSON.parse(cohorts.valueFilter);

    console.log('onClickPreviewButton', cohorts, cohortCol, cohortRow);

    const cohortMatches = ['Type 2 Diabetes Mellitus', 'Chronic Kidney Disease (Ckd)'];

    let selection = "";
    let filters = "";

    cohortCol.forEach((col) => {
      if (cohortMatches.indexOf(col) !== -1) {
        selection += `${cohortFilters[col].payloadKey},`;
        if (cohortFilter[col]) {
          const filterCount = Object.keys(cohortFilter[col]).length;

          console.log(col, filterCount);
          if (filterCount === 1) {
            const key = Object.keys(cohortFilter[col])[0];
            if (key === 'no') {
              filters += "1,";
            } else {
              filters += "0,";
            }
          } else {
            filters += "3,";
          }
        } else {
          filters += "4,";
        }
      }
    });

    cohortRow.forEach((row) => {
      if (cohortMatches.indexOf(row) !== -1) {
        selection += `${cohortFilters[row].payloadKey},`;
        if (cohortFilter[row]) {
          const filterCount = Object.keys(cohortFilter[row]).length;

          console.log(row, filterCount);
          if (filterCount === 1) {
            const key = Object.keys(cohortFilter[row])[0];
            if (key === 'no') {
              filters += "1,";
            } else {
              filters += "0,";
            }
          } else {
            filters += "3,";
          }
        } else {
          filters += "4,";
        }
      }
    });

    // selection = selection.substring(0,selection.length-1);
    filters = filters.substring(0, filters.length - 1);

    const requestParam = `${102},${selection}${filters}`;
    console.log('requestParam', requestParam);
    const allowedParams = ['102,ChronicKidneyDisease,4', "102,Type2Diabetes,3", '102,Type2Diabetes,4', '102,Type2Diabetes,ChronicKidneyDisease,1,4'];
    if (allowedParams.indexOf(requestParam) == -1) {
      toast('Please select only ChronicKidneyDisease,Type2Diabetes options from Diagnosis', {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
      return false;
    }
    this.toggleLoadingOverlay(true);
    MatrixService.initiateDataPreparation(requestParam)
      .then((result) => {
        this.toggleLoadingOverlay(false);
        this.props.navigateNext();
      })
      .catch((err) => {
        console.log(err);
        toast(err, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
        this.toggleLoadingOverlay(false);
      });
  }

  processFilters(data) {
    const columnsToDisplay = {};
    const displayToColumns = {};
    const unitCostMapping = {};
    data.forEach((val) => {
      columnsToDisplay[val.column_name] = val.display;
      displayToColumns[val.display] = val.column_name;
      unitCostMapping[val.display] = val.unit_cost;
    });
    data.sort((a, b) => {
      if (a.category < b.category) {
        return -1;
      }
      if (a.category > b.category) {
        return 1;
      }
      return 0;
    });
    const headersArray = data.map((val) => val.display);
    const dataArray = [];
    const categorizedHeadersArray = [];
    const categoryToAttrMapping = {};

    const hiddenAttributes = data.map((val) => {
      if (!val.active) return val.display; return "";
    });
    const categoryArray = [...new Set(data.map((val) => val.category))];
    const hiddenAggregators = headersArray.filter((display) => display.toLowerCase() !== "population");

    for (const i in categoryArray) {
      const tmpArr = (data.map((val) => {
        if (val.category === categoryArray[i]) return val.display;
      }))
        .filter((item) => item !== undefined);
      categoryToAttrMapping[categoryArray[i]] = [];
      tmpArr.forEach((header) => {
        categorizedHeadersArray.push(header);
        categoryToAttrMapping[categoryArray[i]].push(header);
      });
    }

    dataArray[0] = categorizedHeadersArray;
    if (dataArray.length <= 1) {
      dataArray[1] = Array(dataArray[0].length).fill(0);
    }

    return {
      data: dataArray,
      hiddenAttributes,
      hiddenAggregators,
      columnsToDisplay,
      displayToColumns,
      unitCostMapping,
      categoryToAttrMapping,
    };
  }

  componentWillMount() {
    this.setState(
      {
        mode: "thinking",
        filename: "Cohort Analysis (Patient Population)",
        textarea: "",
        pivotState: { data: [], prevCols: [], prevRows: [] },
        searchValue: "",
      },
      () => {
        MatrixService.retrieveMatrixAttributes()
          .then((result) => {
            window.sessionStorage.setItem(
              "attributes",
              JSON.stringify(result.attributes),
            );

            this.toggleLoadingOverlay(false);
            const processed = this.processFilters(result.attributes);
            const filterState = localStorage.getItem('filterState') ? JSON.parse(localStorage.getItem('filterState')) : null;
            this.setState({
              mode: "file",
              filename: "Cohort Analysis (Patient Population)",
              searchValue: "",
              pivotState: {
                data: processed.data,
                aggregators: { Sum: pivotUtils.aggregators.Sum },
                aggregatorName: "Sum",
                hiddenFromDragDrop: ["Population"],
                hiddenFromAggregators: processed.hiddenAggregators,
                hiddenAttributes: processed.hiddenAttributes,
                vals: ["Population"],
                rendererName: filterState && filterState.rendererName ? JSON.parse(filterState.rendererName) : null,
                cols: filterState && filterState.cols ? JSON.parse(filterState.cols) : [],
                rows: filterState && filterState.rows ? JSON.parse(filterState.rows) : [],
                valueFilter: filterState && filterState.valueFilter ? JSON.parse(filterState.valueFilter) : {},
                // added column to display mapping
                columnsToDisplay: processed.columnsToDisplay,
                displayToColumns: processed.displayToColumns,
                categoryToAttrMapping: processed.categoryToAttrMapping,
                searchFoundAttrs: [],
                searchFoundCats: [],
                prevCols: [],
                prevRows: [],
              },
              unitCostMapping: processed.unitCostMapping,
            });

            this.doOnChange(this.state.pivotState);
          })
          .catch((err) => {
            console.log(err);
            toast(err, {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
            });
            this.toggleLoadingOverlay(false);
          });
      },
    );
  }

  clearFilters() {
    const { pivotState } = this.state;
    pivotState.cols = [];
    pivotState.rows = [];
    this.setState({ pivotState, aggregateTotals: this.getTotalAggregates(pivotState) });
    localStorage.removeItem('filterState');
  }

  saveFilters(stateForFilters) {
    const filterData = {
      cols: JSON.stringify(stateForFilters.cols),
      rows: JSON.stringify(stateForFilters.rows),
      valueFilter: JSON.stringify(stateForFilters.valueFilter),
      rendererName: JSON.stringify(stateForFilters.rendererName),
    };
    localStorage.setItem('filterState', JSON.stringify(filterData));
  }

  doOnChange(s) {
    const { displayToColumns } = this.state.pivotState;
    const { columnsToDisplay } = this.state.pivotState;
    const currentData = [];
    currentData[0] = this.state.pivotState.data[0];

    const selectedAttrs = s.cols.concat(s.rows);
    const prevSelectedAttrs = s.prevCols.concat(s.prevRows);

    if ((s.cols.length === 0 && s.rows.length === 0)) {
      this.setState({
        pivotState: s,
        aggregateTotals: this.getTotalAggregates(s),
      }, () => {
        this.filterBySearch(this.state.searchValue);
      });
      this.saveFilters(s);
    } else if (Helpers.arraysEqual(selectedAttrs, prevSelectedAttrs)) {
      this.setState({
        pivotState: s,
        aggregateTotals: this.getTotalAggregates(s),
      }, () => {
        this.filterBySearch(this.state.searchValue);
      });
      this.saveFilters(s);
    } else if (Helpers.isSubsetOf(prevSelectedAttrs, selectedAttrs)) {
      s.prevCols = s.cols;
      s.prevRows = s.rows;
      this.setState({
        pivotState: s,
        aggregateTotals: this.getTotalAggregates(s),
      }, () => {
        this.filterBySearch(this.state.searchValue);
      });
      this.saveFilters(s);
    } else {
      this.setState({
        pivotState: s,
        aggregateTotals: this.getTotalAggregates(s),
      });
      const colAttributes = s.cols.length !== 0 ? s.cols.map((val) => displayToColumns[val]) : [];
      const rowAttributes = s.rows.length !== 0 ? s.rows.map((val) => displayToColumns[val]) : [];
      const attr = colAttributes.concat(rowAttributes);
      this.toggleLoadingOverlay(true);

      MatrixService.retrieveMatrixAttributeValues(attr)
        .then((result) => {
          this.toggleLoadingOverlay(false);
          const { values } = result;
          for (let i = 1; i <= values.length; i++) {
            currentData[i] = Array(currentData[0].length).fill(0);
            const currentObj = values[i - 1];
            for (const attr in currentObj) {
              if (attr === 'population') {
                currentData[i][currentData[0].indexOf(columnsToDisplay[attr])] = parseInt(currentObj[attr], 10);
              } else {
                currentData[i][currentData[0].indexOf(columnsToDisplay[attr])] = currentObj[attr] === 0 || currentObj[attr] === 1 || currentObj[attr] === 'true' || currentObj[attr] === 'false'
                  ? currentObj[attr] === 1 || currentObj[attr] === 'true' ? 'yes' : 'no' : currentObj[attr];
              }
            }
          }

          s.data = currentData;

          s.prevCols = s.cols;
          s.prevRows = s.rows;

          this.setState({
            pivotState: s,
            aggregateTotals: this.getTotalAggregates(s),
          }, () => {
            this.filterBySearch(this.state.searchValue);
          });
          this.saveFilters(s);
        })
        .catch((error) => {
          console.log("Unable to update matrix attribute values: ", error);
          toast(`Unable to update matrix attribute values: ${error}`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });
          this.toggleLoadingOverlay(false);
        });
    }
  }

  toggleLoadingOverlay(show) {
    const overlay = document.querySelector(".overlay");
    if (show) {
      overlay.classList.add("overlay-visible");
    } else {
      overlay.classList.remove("overlay-visible");
    }
  }

  getTotalAggregates(data) {
    const pivotData = new pivotUtils.PivotData(data);

    const rowKeys = pivotData.getRowKeys();
    const colKeys = pivotData.getColKeys();

    const colTotals = [];
    const rowTotals = [];

    const grandTotalAggregate = data.aggregatorName === "Sum"
      ? pivotData.getAggregator([], []).sum
      : pivotData.getAggregator([], []).count;

    for (const x in colKeys) {
      const totalColAggregator = pivotData.getAggregator([], colKeys[x]);
      colTotals.push(totalColAggregator.format(totalColAggregator.value()));
    }

    for (const x in rowKeys) {
      const totalRowAggregator = pivotData.getAggregator(rowKeys[x], []);
      rowTotals.push(totalRowAggregator.format(totalRowAggregator.value()));
    }
    return {
      colAttr: colKeys,
      colTotals,
      rowAttr: rowKeys,
      rowTotals,
      grandTotal: grandTotalAggregate,
    };
  }

  isNotSimilar(a, b) {
    return a.toLowerCase().search(b.toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) === -1
      && StringSimilarity.compareTwoStrings(b.toLowerCase().trim(), a) < 0.5;
  }

  filterBySearch(s) {
    this.setState({
      searchValue: s,
    }, () => {
      const listToHide = ["Population"];
      const matchList = [];
      const catMatchList = [];
      const { categoryToAttrMapping } = this.state.pivotState;
      const attrList = this.state.pivotState.data[0];
      const selectedFilters = this.state.pivotState.cols.concat(this.state.pivotState.rows);

      for (const x in attrList) {
        // if no matches were found .search() returns -1
        if (this.isNotSimilar(attrList[x], this.state.searchValue)
          && !selectedFilters.includes(attrList[x])) {
          listToHide.push(attrList[x]);
        } else if (this.state.searchValue.length > 0 && !this.isNotSimilar(attrList[x], this.state.searchValue)) {
          matchList.push(attrList[x]);
        }
      }
      for (const category in categoryToAttrMapping) {
        if (matchList
          .filter((val) => categoryToAttrMapping[category].includes(val)).length > 0) {
          catMatchList.push(category);
        }
      }

      const tempState = this.state.pivotState;
      tempState.hiddenFromDragDrop = listToHide;
      tempState.searchFoundAttrs = matchList;
      tempState.searchFoundCats = catMatchList;
      const searchEl = document.querySelector('.pvtSearch');
      if (listToHide.length === (attrList.length + 1 - selectedFilters.length)) {
        searchEl.classList.add('pvtSearchNotFound');
      } else if (document.querySelector('.pvtSearchNotFound')) {
        searchEl.classList.remove('pvtSearchNotFound');
      }

      this.setState({
        pivotState: tempState,
      });
    });
  }

  render() {
    return (
      <div>

        <Grid className="main">
          <section className="content">
            <Grid>
              <Grid md={12} className="pivot-table-col">
                <div className="overlay">
                  <h3 className="overlay-text">
                    {/* <Glyphicon className="loader" glyph="refresh"/><br/> */}
                    Loading...
                  </h3>
                </div>
                <div align="right">
                  <Button variant="contained" color="primary" onClick={() => { this.onClickPreviewButton(); }}>
                    Preview
                    Data
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.clearFilters();
                    }}
                  >
                    Clear Selection
                  </Button>
                </div>
                <br />
                <PivotTableUISmartWrapper
                  {...this.state.pivotState}
                  onChange={(s) => {
                    this.doOnChange(s);
                  }}
                  onSearchChange={(s) => {
                    this.filterBySearch(s);
                  }}
                  getTotalAggregates={this.getTotalAggregates}
                />
              </Grid>
            </Grid>

          </section>

        </Grid>
        <ToastContainer />
      </div>

    );
  }
}

export default CohortAnalyzerContentView;
