export default {
  insight1: "ins-1",
  insight2: "ins-2",
  insight3: "ins-3",
  insight4: "ins-4",
  insight5: "ins-5",
  insight6: "ins-6",
  insight7: "ins-7",
  insight8: "ins-8",
  insight9: "ins-9",
  insight10: "ins-10",
  insight11: "ins-11",
  insight12: "ins-12",
  insight13: "ins-13",
  insight14: "ins-14",
  insight15: "ins-15",
  insight16: "ins-16",
  insight17: "ins-17",
  insight18: "ins-18",
  insight19: "ins-19",
  insight20: "ins-20",
  insight21: "ins-21",
  insight22: "ins-22",
  insight23: "ins-23",
  insight24: "ins-24",
  insight25: "ins-25",
  insight26: "ins-26",
  insight27: "ins-27",
  insight28: "ins-28",
  insight29: "ins-29",
  insight30: "ins-30",
  insight31: "ins-31",
  insight32: "ins-32",
  insight33: "ins-33",
  insight34: "ins-34",
  insight35: "ins-35",
  insight36: "ins-36",
  insight37: "ins-37",
  insight38: "ins-38",
  insight39: "ins-39",
  insight40: "ins-40",
  insight41: "ins-41",
  insight42: "ins-42",
  insight43: "ins-43",
  insight44: "ins-44",
  insight45: "ins-45",
  insight46: "ins-46",
  insight47: "ins-47",
  insight48: "ins-48",
  insight49: "ins-49",
  insight50: "ins-50",
  insight51: "ins-51",
  insight52: "ins-52",
  insight53: "ins-53",
  insight54: "ins-54",
  insight55: "ins-55",
  insight56: "ins-56",
  insight57: "ins-57",
  insight58: "ins-58",
  insight59: "ins-59",
  insight60: "ins-60",
  insight61: "ins-61",
  insight62: "ins-62",
  insight63: "ins-63",
  insight64: "ins-64",
  insight65: "ins-65",
  insight66: "ins-66",
  insight67: "ins-67",
  insight68: "ins-68",
  insight69: "ins-69",
  insight70: "ins-70",
  insight71: "ins-71",

};
