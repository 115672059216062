import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Grid, TextField, CircularProgress, FormControl, MenuItem, Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Autocomplete from '@material-ui/lab/Autocomplete';

// import local components
import CommonService from "../../../utils/services/CommonService";
import HospitalCard from '../../common/HospitalCard';
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

const MedicareLocator = () => {
  const { getQueryResult } = CommonService("z1dApps", "medicareLocator");

  const [showSpinner, setSpinner] = useState(false);
  const [parameters, setParameters] = useState({ zipCode: "", services: "" });
  const [selectedState, setStates] = useState("");
  const [city, setCity] = useState("");
  const [zipCodeList, setZipCodeList] = useState("");
  const [cityList, setCityList] = useState("");
  const [text, setText] = useState("");
  const [tableData, settableData] = useState("");

  const [notification, setNotification] = useState("");
  const facilities = ['Hospital', 'Nursing Home', 'Hospice', 'Home Health Agency'];
  const states = ['Arkansas', 'California', 'Illinois', 'Louisiana',
    'Massachusetts', 'Michigan', 'Minnesota', 'New York',
    'Oklahoma', 'Pennsylvania', 'Texas', 'Vermont', 'Virginia', 'Maryland', 'Florida', 'Alabama', 'New Jersey', 'Iowa', 'Montana', 'Mississippi', 'Oregon',
    'South Carolina', 'Indiana', 'Delaware', 'Kentucky', 'Kansas', 'Wisconsin', 'Puerto Rico', 'West Virginia',
    'Missouri', 'Arizona', 'Nebraska', 'Hawaii', 'Georgia', 'North Carolina',
    'Colorado', 'Alaska', 'New Hampshire', 'Maine', 'South Dakota', 'Ohio',
    'Idaho', 'Tennessee', 'North Dakota', 'Connecticut', 'Washington', 'Nevada',
    'Wyoming', 'Utah', 'New Mexico', 'Guam', 'District Of Columbia', 'Rhode Island',
    'American Samoa'];

  const getZipCodeList = (cityValue) => {
    const { services } = parameters;
    const parameterString = `${services},${cityValue}`;
    setSpinner(true);
    settableData('');
    getQueryResult("getQuery", `zip_finder/${encodeURIComponent(parameterString)}`)
      .then((res) => {
        setZipCodeList(res.zip_codes && res.zip_codes.length > 1 ? res.zip_codes : '');
        settableData(res);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getAddress = (zipCode) => {
    const { services } = parameters;
    const parameterString = `${services},${zipCode}`;
    setSpinner(true);
    settableData("");
    getQueryResult("getQuery", `innetwork_finder/${encodeURIComponent(parameterString)}`)
      .then((res) => {
        settableData(res);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getCityList = (stateValue) => {
    const { services } = parameters;
    const parameterString = `${services},${stateValue}`;
    setSpinner(true);
    settableData("");
    setCityList('');
    setZipCodeList('');
    getQueryResult("getQuery", `city_finder/${encodeURIComponent(parameterString)}`)
      .then((res) => {
        setCityList(res.cities.length ? res.cities : '');
        settableData(res);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const setStateValue = (name, newValue) => {
    setStates(newValue);
    getCityList(newValue);
  };

  const setCityValue = (event, newValue) => {
    setCity(newValue);
    getZipCodeList(newValue);
  };

  const onChangeFields = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setParameters({ ...parameters, [name]: value });
    if (name == 'zipCode') {
    }
  };
  useEffect(() => {
    parameters?.zipCode && getAddress(parameters.zipCode);
  }, [parameters.zipCode]);

  const formCardList = () => {
    const { data, result } = tableData;
    return data.length
      ? (
        <Grid container spacing={2} direction="row">
          {data.map((col) => (
            <Grid item xs={12} sm={6} md={3}>
              <HospitalCard
                name={col['HOSPITAL NAME'] || col['HOSPICE HOME'] || col['NURSING HOME'] || col['HOME HEALTH AGENCY NAME']}
                address={col.ADDRESS}
                city={col.CITY}
                number={col['PHONE NUMBER']}
                zipcode={col['ZIP CODE']}
                rating={col.RATING}
              />
            </Grid>
          ))}
        </Grid>
      )
      : <Typography>{result}</Typography>;
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Medicare Locator Application helps in locating health care providers that accept Medicare. It will help one find them in an area based on the selected location like state, city, zip codes. The tool generated the list of all the listed Medicare Facilities along with the ratings and Contact Details. The following providers can be searched by the tool.</p>
          <p>
            <b>Hospitals:</b>
            {' '}
            Find and compare around 7000 hospitals which provide services like medical, surgical, and psychiatric care to Medicare Patients.
          </p>
          <p>
            <b>Nursing homes:</b>
            {' '}
            Get detailed information about every Medicare-certified nursing home in the country. A nursing home is a place for people who can’t be cared for at home and need 24-hour nursing care. Find nursing homes based on a specific location.
          </p>
          <p>
            <b>Home health services:</b>
            {' '}
            Home health care services are a valuable Medicare benefit that provides skilled nursing care, therapy and other aid to people who are largely or entirely confined to their homes.
          </p>
          <p>
            <b>Hospice care:</b>
            Medicare-certified hospice care is usually given in your home or other facility where you live, whether it’s at home, an assisted living facility, or a nursing home.
          </p>

        </Paragraph>

        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://data.medicare.gov/resource/xubh-q36u.json"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/MedicareLocator/Medicare_locator.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="region"
                variant="outlined"
                name="services"
                select
                label="Facility"
                fullWidth
                value={parameters.services}
                placeholder="Choose a Facility"
                onChange={onChangeFields}
              >
                {facilities.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                value={selectedState}
                onChange={setStateValue}
                disableClearable
                fullWidth
                id="controllable-states-demo"
                options={states.map((option) => option)}
                renderInput={(params) => <TextField {...params} label="State" variant="outlined" />}
              />
            </Grid>
            {cityList && (
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                value={city}
                onChange={setCityValue}
                disableClearable
                fullWidth
                id="controllable-states-demo"
                options={cityList.map((option) => option)}
                renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
              />
            </Grid>
            )}
            {zipCodeList && (
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="region"
                variant="outlined"
                name="zipCode"
                select
                fullWidth
                label="ZipCode"
                value={parameters.zipCode}
                onChange={onChangeFields}
              >
                {zipCodeList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            )}
          </Grid>
          <h3>{text}</h3>
          {showSpinner && <Spinner text="Loading..." />}
          {tableData && formCardList()}

        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MedicareLocator);
