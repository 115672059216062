import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, MenuItem, TableCell, Table, TableBody, TableHead, TableRow, TextField, Radio } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";


// local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import { StyledTableHead } from "./ShapleyEMR";
import bern1 from '../../../assets/images/app/bern_desc_1.webp';
import bern2 from '../../../assets/images/app/bern_desc_2.webp';
import ZoomImage from "../../common/ZoomImage";
import getUserData from '../../../utils';
import SolutionContainer from "../../common/SolutionContainerWrapper";
import RadioTable from "../../common/RadioTable";

const { postQueryResult } = CommonService(
  "z1dApps",
  "bern",
);
const { postQueryResult: getResult, getQueryResult } = CommonService(
  "z1dApps",
  "bioMed",
);

const Bern = () => {
  const [result, setResult] = useState('');
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [pmcIdList, setPMCList] = useState(null);
  const [selectedPmId, setPMId] = useState('');
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const [pubmedData, setPubmedData] = useState({ id: '' });

  const handleChange = (e) => {

    setPubmedData({ ...pubmedData, [e.target.name]: e.target.value });

  }
  const handlePMId = (e) => {

    setPubmedData({ ...pubmedData, pmcId: e.currentTarget.id, id: '' });

  }

  const getBernRes = async () => {
    try {
      setSpinner(true);
      let params = {
        Context_param: {
          Application_name: 'Bern',
          UserID: userId,
        },
        Content_param:
          { text: pubmedData.id },
      }
      let result = await postQueryResult("sample", params);
      result = result?.Result;
      setSpinner(false);
      setNotification({ open: "success", message: "Loaded Entities" });
      if (result) {
        let keys = result?.output && Object.keys(result?.output);
        const symbols = {
          '<': ' ',
          '>': ' '
        }
        let txt = result.text;
        Object.entries(symbols).forEach(char => {
          txt = txt.replace(char[0], char[1])
        });
        console.log(txt);

        keys?.forEach(element => {
          txt = txt.replace(new RegExp(`(${element})`, 'g'), `<span class="tooltip" data-id='${result?.output[element]}'>${element} </span>`)
        });
        setResult({ txt, txt, jsonData: JSON.stringify(result.jsonData), filePath: result.filePath });

      };
    }
    catch (err) {
      setSpinner(false);
      setNotification({ open: "error", message: err?.message });
      return false;
    }
  }
  const getPMCData = async () => {
    try {
      setSpinner(true);
      setPMCList(null);
      setResult(null);
      setPubmedData({ ...pubmedData, pmcId: '', id: '' });
      let params = {
        Context_param: {},
        Content_param:
          { query: pubmedData.term, article_count: 5 },
      }
      let result = await getResult("getPMCIds", params);
      setSpinner(false);
      setNotification({ open: "success", message: "Article List Loaded" });
      if (result) {
        setPMCList(result);
      };
    }
    catch (err) {
      setSpinner(false);
      setNotification({ open: "error", message: err?.message });
      return false;
    }
  }
  const getArticleData = async () => {
    try {
      setSpinner(true);
      setResult(null);
      let result = await getQueryResult("getArticle", encodeURIComponent(pubmedData.pmcId));
      setSpinner(false);
      setNotification({ open: "success", message: "Article Loaded" });
      if (result) {
        setPubmedData({ ...pubmedData, id: result });
      };
    }
    catch (err) {
      setSpinner(false);
      setNotification({ open: "error", message: err?.message });
      return false;
    }
  }


  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>In biomedical natural language processing, named entity recognition (NER) and named entity normalization (NEN) are key tasks that enable the automatic extraction of biomedical entities (e.g., diseases and drugs) from the ever-growing biomedical literature. BERN2 (Advanced Biomedical Entity Recognition and Normalization), a tool that improves the previous neural network-based NER tool by employing a multi-task NER model and neural network-based NEN models to achieve much faster and more accurate inference. The tool can help annotate large-scale biomedical texts for various tasks such as biomedical knowledge graph construction.</p>

          <p>The data provided by BERN2 is post-processed and may differ from the most current/accurate data available from U.S. National Library of Medicine (NLM).</p>
          <p>BERN can be used to create structured data from unstructured data sources (biomedical literatures) which can be then used to train ML (Machine Learning) models and create a directory which will help researchers to create a Biomedical Knowledge Graph for a holistic view of entities. This in return will help users to create a better business model/research findings backed by data from unstructured data sources.</p>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs="7">
            <strong>BERN can detect 7 entities from any given medical research paper namely: -</strong>
          <ol typeof="1">
            <li>Genes/Proteins</li>
            <li>Diseases</li>
            <li>Pathways</li>
            <li>miRNAs</li>
            <li>Mutations</li>
            <li>Species</li>
            <li>Drugs/Chemicals</li>
          </ol>
          <strong>Features of BERN2</strong>
          <ul>
            <li>Recognition of 9 biomedical entity types along with normalized IDs (Gene, Disease, Chemical, Species, Mutation, Cell Line, Cell Type, DNA, and RNA)</li>
            <li>PMID as an input and annotated results in real time</li>
            <li>Bio registry for CUIs: Bio registry is used to standardize prefixes for normalized entity identifiers</li>
            <li>The upgraded gene/protein normalizer: The gene/protein type normalizer has been improved by using BioSyn</li>
          </ul>
          <p>
            <strong>Use case’s</strong>
            <ol>
              <li>Finding the most pertinent documents to the questions made by medical practitioners.</li>
              <li>Clinical research -- Life sciences and research organizations can optimize the matching process for creating effective and safe drugs.</li>
              <li>Utilizing Graph Database’s to show/create relations within entities to create a corpus of data for understanding similarities within treatment.</li>
            </ol>
          </p>
            </Grid>
            <Grid item xs="5">
            <center><ZoomImage src={bern1} width="90%" /></center>
              <center><b>Decision rule for NER</b></center>
            </Grid>
          </Grid>
          <br/>
            <Grid item xs={12} >
              <center><ZoomImage src={bern2} width="90%" /></center>
              <center><b>BERN flow</b></center>
            </Grid>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://arxiv.org/abs/2201.02080"
                  target="_blank"
                  rel="noreferrer"
                >
                  Paper link
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <BernContainer>
            <Paragraph>Please mention a medical term to be looked at PubMed Central Repository. Clicking on "Get Articles" will fetch 5 random articles with respect to the specified medical term. </Paragraph>
            <Grid
              container
              xs={12}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid xs={12} sm={4} >
                <TextField
                  id="term"
                  name="term"
                  variant="outlined"
                  label="Medical term"
                  value={pubmedData.term}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} sm={2}>
                <StyledButton onClick={getPMCData} variant='contained' color="primary" size="small"><FontAwesomeIcon icon={faUpload} /> &nbsp; Get Articles</StyledButton>
              </Grid>
              <Grid xs={12}  >
                <br />
                {pmcIdList?.body && <>

                  <Paragraph><b>Please find below the Articles fetched from PubMed along with PMC ID for the desired medical term. Please select any article and click on "Get Content" to extract the content of the article.</b></Paragraph>
                
                  <RadioTable header={pmcIdList?.header} body={pmcIdList?.body} onclick={handlePMId} id={pubmedData?.pmcId}/>

                </>}
              </Grid>
            </Grid>
            {pmcIdList && pubmedData.pmcId && <>
              <Paragraph>Please click on "Get Content" button to see the content of <strong>{pmcIdList?.body[pubmedData?.pmcId]}</strong> Article </Paragraph>

              <p> <StyledButton onClick={getArticleData} variant='contained' color="primary" size="small"><FontAwesomeIcon icon={faUpload} /> &nbsp; Get Content</StyledButton></p>
              {pubmedData.id &&
                <>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid xs={12} > <h3>{pmcIdList[pubmedData.pmcId]}</h3></Grid>
                    <Grid xs={12} sm={11} >
                      <PubmedTextArea
                        rows='15'
                        name='id'
                        value={pubmedData.id}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={12}>
                      <Paragraph>Please click on "Extract Entities" to extract out the entities from the content of the article pulled from PubMed.</Paragraph>
                      <p> <StyledButton onClick={getBernRes} variant='contained' color="primary" size="small"><FontAwesomeIcon icon={faUpload} /> &nbsp; Extract Entities</StyledButton></p>
                    </Grid>
                  </Grid>
                  <br />

                  {result && (
                    <>
                      <Paragraph>Please find below the content of <strong>{pmcIdList?.body[pubmedData?.pmcId]}</strong></Paragraph>
                      <Grid container spacing={3} >
                        <Grid xs={12}>
                          <CategoryContainer>
                            <Category>
                              <ColorIndicator color="rgba(152,78,163, 0.3)"></ColorIndicator> &nbsp;Gene/Protein
                            </Category>
                            <Category>
                              <ColorIndicator color="rgba(255,255,51, 0.3)"></ColorIndicator> &nbsp;DNA
                            </Category>
                            <Category>
                              <ColorIndicator color="rgba(77,175,74, 0.3)"></ColorIndicator> &nbsp;Drug/Chemical
                            </Category>
                            <Category>
                              <ColorIndicator color="rgba(228,26,28, 0.3)"></ColorIndicator> &nbsp;Disease
                            </Category>
                            <Category>
                              <ColorIndicator color="rgba(153,153,153, 0.3)"></ColorIndicator> &nbsp;Cell type
                            </Category>
                            <Category>
                              <ColorIndicator color="rgba(247,129,191, 0.3)"></ColorIndicator> &nbsp;Cell line
                            </Category>
                            <Category>
                              <ColorIndicator color="rgba(255,127,0, 0.3)"></ColorIndicator> &nbsp;Species
                            </Category>
                          </CategoryContainer>
                        </Grid>
                      </Grid>
                      <br/>
                      <HighlightFont>
                        {ReactHtmlParser(result?.txt)}
                      </HighlightFont>
                      {result?.filePath && <> <Paragraph>To Download BERN response in JSON format, click on Download button</Paragraph>
                      <StyledButton
                        variant="outlined"
                        color="primary"
                        size="large"
                        startIcon={<OpenInNewIcon />}
                      >
                        <a
                          href={result.filePath}
                          download
                          target='_blank'
                          rel='noreferrer'
                        >
                          Download JSon
                        </a>
                      </StyledButton></>}
                    </>
                  )}
                </>}
            </>}


            {showSpinner && <Spinner text="Loading..." />}
          </BernContainer>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(Bern);
const StyledRow = styled(TableRow)`
  .MuiTableCell-root{
    padding: 6px;
    cursor: pointer;
    &.hover{
        background-color: lightgray; 
    }
  }
`;
const JsonData = styled.pre`
  margin: 0 0 1rem;
  padding: 0.7rem;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid grey;
  border-radius: 4px;
  border-left: 4px solid #4e73df;
  height: 10vh;
  width: 98%;
  overflow-x: auto;
`;
const BernContainer = styled.div`
`;
const HighlightFont = styled.div`
    padding: 12px;
    margin: 10px;
    text-align: justify;
    max-height: 400px;
    overflow: auto;
    line-height: 1.5;
    [data-id=disease]{
      background-color: rgba(228,26,28, 0.3)
    }
    [data-id=gene]{
      background-color: rgba(152,78,163, 0.3)
    }
    [data-id=drug]{
      background-color: rgba(77,175,74, 0.3)
    }
    [data-id=DNA]{
      background-color: rgba(255,255,51, 0.3)
    }
    [data-id=cell_line]{
      background-color: rgba(247,129,191, 0.3)
    }
    [data-id=cell_type]{
      background-color: rgba(153,153,153, 0.3)
    }
    [data-id=species]{
      background-color: rgba(255,127,0, 0.3)
    }
    .tooltip {
      position: relative;
      display: inline-block;
      text-decoration: none;
    }

  
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 150px;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      font-size: smaller;
      text-decoration: none;
      
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      top: 120%;
      left: 0;
      .title{
        font-weight: bold;

      }
    }
    
    .tooltip:hover {
      text-decoration: underline;

       .tooltiptext {
          visibility: visible;
       }
      
    }
    `


const ColorIndicator = styled.span`
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${props => props.color};
  `
const PubmedTextArea = styled.textarea`
   width: 100%;
   padding: 8px;

  `
const CategoryContainer = styled.div`
   display: flex;
   justify-content: space-between;
  `
const Category = styled.div`
   display: flex;
   justify-content: space-between;
  `