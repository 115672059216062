const CommonStyles = (theme) => ({
  textCenter: {
    textAlign: "center",
  },
  textLeft: {
    textAlign: "left",
  },
  invalid: {
    color: '#f44336',
  },
  valid: {
    color: '#00897b',
  },
  fullWidth: {
    width: "100%",
  },
  width95: {
    width: "95%",
  },
  width90: {
    width: "90%",
  },
  scrollableTable: {
    overflowX: "scroll",
  },
  buttonLayoutArea: {
    marginTop: 15,
    paddingLeft: 20,
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    color: "gray",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 1,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  errorSnack: {
    backgroundColor: theme.palette.error.dark,
  },
  successMessage: {
    color: "#3c763d",
    fontWeight: "bolder",
  },
  successMessageArea: {
    backgroundColor: "#dff0d8",
    padding: 10,
  },
  errorMessage: {
    color: "#a94442",
    fontWeight: "bolder",
  },
  errorMessageArea: {
    backgroundColor: "#f2dede",
    padding: 10,
  },
  formPadding20: {
    padding: 30,
  },
  leftAlign: {
    textAlign: "left",
  },
  padding30: {
    padding: "0px 0px 30px",
  },
  blueText: {
    color: '#6154dd',
  },
  imgBorder: {
    borderRadius: '5px',
  },
  titleText: {
    backgroundColor: '#2941b7',
    padding: '20px',
    color: 'white',
  },
  btn: {
    color: "#ffffff",
    backgroundColor: "#fc7703",
    width: "100%",
    '&:hover': {
      backgroundColor: "#fc7703",
      transform: "scale(1.1)",
    },
  },
  zoomImg: {
    zIndex: '5',
    position: 'absolute',
    background: 'black',
    width: '50%',
    height: '50%',
    backgroundColor: 'rgb(0,0,0)',
    backgroundColor: 'rgba(0,0,0, 0.9)',
    padding: '40px',
    marginLeft: '30px',
    transition: '200ms all cubic-bezier(.4,0,.2,1)',
  },
});

export default CommonStyles;
