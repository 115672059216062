import { WSClientWrapper } from '../Dataops/WSClientWrapper';
import APIDATA from "../../../utils/services/api-config";

const url = `${APIDATA.Z1D}:5001`;

const CohortSenseService = {

  initiateDataPreparation(params) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    return WSClientWrapper.wrappedFetch(`${url}/datapreparation/${params}`, getRequestConfig);
  },

  retrievePreviewData(fileName) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    if (fileName) {
      return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/previewdata/${fileName}`, getRequestConfig);
    }
    console.error('File empty');
    return Error("filename empty");
  },
  retrieveFeatureScalingPreview(scalingOption, fileName, jsonFileName) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    if (fileName) {
      return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/featurescaling/${scalingOption},${fileName},${jsonFileName}`, getRequestConfig);
    }
    console.error('File empty');
    return Error("filename empty");
  },

  generateAdanetModel(learningRate, trainingSteps, batchSize, learnMixtureWeights, adaNetLambda, adaNetIterations,
    randomSeed, testToTrainRatio, fileName, jsonFileName) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    if (fileName) {
      return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/adanet/${learningRate},${trainingSteps},${batchSize},${learnMixtureWeights},${adaNetLambda},${adaNetIterations},${randomSeed},${testToTrainRatio},${fileName},${jsonFileName}`, getRequestConfig);
    }
    console.error('File empty');
    return Error("filename empty");
  },

  generateKerasModel(testToTrainRatio, fileName, jsonFileName) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    if (fileName) {
      return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/autokeras/${testToTrainRatio},${fileName},${jsonFileName}`, getRequestConfig);
    }
    console.error('File empty');
    return Error("filename empty");
  },

  retrieveFeatureSelectionPreview(feature, fileName, jsonFileName, args) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    if (fileName) {
      if (feature !== 'none') {
        return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/featureselection/${feature},${args},${fileName},${jsonFileName}`, getRequestConfig);
      }
      return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/featureselection/${feature},${fileName},${jsonFileName}`, getRequestConfig);
    }
    return Error("filename empty");
  },

  generateTraditionalModel(trainTestSplit, fileName, jsonFileName) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    if (fileName) {
      return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/tm/${trainTestSplit},${fileName},${jsonFileName}`, getRequestConfig);
    }
    return Error("filename empty");
  },

  getExportModelUrl(filename) {
    return `${url}/cohortanalyzer/exportModel/${filename}`;
  },

  performModelTuning(dataSize, modelName, aucMetrics, allMetrics, trainTestSplit, fileName, jsonFileName) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    if (fileName) {
      return WSClientWrapper.wrappedFetch(`${url}/cohortanalyzer/mt/${dataSize},${modelName},"${aucMetrics}","${allMetrics}",${trainTestSplit},${fileName},${jsonFileName}`, getRequestConfig);
    }
    return Error("filename empty");
  },

};

export default CohortSenseService;
