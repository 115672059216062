import React, { memo, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const HealthInsuranceLeadPrediction = () => {
  const initialData = {
    citycode: "",
    accomodationtype: "",
    recoinsurancetype: "",
    isspouse: "",
    healthindicator: "",
    holdingpolicyduration: "",
    holdingpolicytype: "",
    recopolicycat: "",
    recopolicypremium: "",
    longtermcustomer: "",
    age: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("payerProvider", "heathcare_insurance");

  const accomodationtype = [
    { name: 'Own', value: 0 },
    { name: 'Rent', value: 1 },

  ];

  const recoinsurancetype = [
    { name: 'Joint', value: 0 },
    { name: 'Individual', value: 1 },

  ];

  const isspouse = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 },

  ];

  const showSample1 = () => {
    const NewDataSet = {
      citycode: '30',
      accomodationtype: '1',
      recoinsurancetype: '1',
      isspouse: '0',
      healthindicator: '1',
      holdingpolicyduration: '0',
      holdingpolicytype: '0',
      recopolicycat: '14',
      recopolicypremium: '7068',
      longtermcustomer: '0',
      age: '25',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      citycode: '0',
      accomodationtype: '1',
      recoinsurancetype: '1',
      isspouse: '0',
      healthindicator: '0',
      holdingpolicyduration: '1',
      holdingpolicytype: '3',
      recopolicycat: '7',
      recopolicypremium: '17862',
      longtermcustomer: '0',
      age: '52',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      citycode, accomodationtype, recoinsurancetype, isspouse, healthindicator, holdingpolicyduration, holdingpolicytype, recopolicycat, recopolicypremium, longtermcustomer, age,
    } = sampleData;

    const params = `${citycode},${accomodationtype},${recoinsurancetype},${isspouse},${healthindicator},${holdingpolicyduration},${holdingpolicytype},${recopolicycat},${recopolicypremium},${longtermcustomer},${age}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);
      SetResultData('');

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res.result);

          const percentages = res.feature_importances;
          const params = [];

          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          console.log(percentages);
          Highcharts.chart('prediction-chart', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>A financial services company provides various financial services like loan, investment funds, insurance etc. to its customers and wishes to cross-sell health insurance to the existing customers who may or may not hold insurance policies with the company. The company recommend health insurance to its customers based on their profile once these customers land on the website. Customers might browse the recommended health insurance policy and consequently fill up a form to apply. When these customers fill-up the form, their Response towards the policy is considered positive and they are classified as a lead.</p>
          <p>Once these leads are acquired, the sales advisors approach them to convert and thus the company can sell proposed health insurance to these leads in a more efficient manner.</p>
          <p>Here, a model is developed to predict whether the person will be interested in the proposed Health plan/policy or not given the information about:</p>

          <List>
            <ListItem>Demographics (city, age, region etc.)</ListItem>
            <ListItem>Information regarding holding policies of the customer</ListItem>
            <ListItem>Recommended Policy Information</ListItem>
          </List>
          <p><strong>DATA Information:  </strong></p>
          <List>
            <ListItem>
              <strong>Visit:</strong>
              {' '}
              Code for the City of the customers
            </ListItem>
            <ListItem>
              <strong>Accomodation_Type:</strong>
              {' '}
              Customer Owns or Rents the house
            </ListItem>
            <ListItem>
              <strong>RecoInsuranceType:</strong>
              {' '}
              Joint or Individual type for the recommended insurance
            </ListItem>
            <ListItem>
              <strong>Is_Spouse:</strong>
              {' '}
              If the customers are married to each other
            </ListItem>
            <ListItem>
              <strong>Health_Indicator:</strong>
              {' '}
              Encoded values for health of the customer
            </ListItem>
            <ListItem>
              <strong>HoldingPolicyDuration:</strong>
              {' '}
              Duration (in years) of holding policy (a policy that customer has already subscribed to with the company)
            </ListItem>
            <ListItem>
              <strong>HoldingPolicyType:</strong>
              {' '}
              Type of holding policy
            </ListItem>
            <ListItem>
              <strong>RecoPolicyCat:</strong>
              {' '}
              Encoded value for recommended health insurance
            </ListItem>
            <ListItem>
              <strong>RecoPolicyPremium:</strong>
              {' '}
              Annual Premium (INR) for the recommended health insurance
            </ListItem>
            <ListItem>
              <strong>Long_Term_Customer:</strong>
              {' '}
              If holding policy duration is more than 14 years
            </ListItem>
            <ListItem>
              <strong>Age:</strong>
              {' '}
              Age of the customer
            </ListItem>
          </List>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/HealthInsuranceLead/Health_Insurance_Lead_Prediction.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/sureshmecad/health-insurance-lead-prediction"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="City_Code"
                name="citycode"
                fullWidth
                onChange={handleInputs}
                value={sampleData.citycode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="accomodationtype"
                label="Accomodation_Type"
                value={sampleData.accomodationtype}
                onChange={handleInputs}
              >
                {accomodationtype.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="recoinsurancetype"
                label="RecoInsuranceType"
                value={sampleData.recoinsurancetype}
                onChange={handleInputs}
              >
                {recoinsurancetype.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="isspouse"
                label="Is_Spouse"
                value={sampleData.isspouse}
                onChange={handleInputs}
              >
                {isspouse.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Health_Indicator"
                name="healthindicator"
                fullWidth
                onChange={handleInputs}
                value={sampleData.healthindicator}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="HoldingPolicyDuration"
                name="holdingpolicyduration"
                fullWidth
                onChange={handleInputs}
                value={sampleData.holdingpolicyduration}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="HoldingPolicyType"
                name="holdingpolicytype"
                fullWidth
                onChange={handleInputs}
                value={sampleData.holdingpolicytype}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="RecoPolicyCat"
                name="recopolicycat"
                fullWidth
                onChange={handleInputs}
                value={sampleData.recopolicycat}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="RecoPolicyPremium"
                name="recopolicypremium"
                fullWidth
                onChange={handleInputs}
                value={sampleData.recopolicypremium}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Long_Term_Customer"
                name="longtermcustomer"
                fullWidth
                onChange={handleInputs}
                value={sampleData.longtermcustomer}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Age"
                name="age"
                fullWidth
                onChange={handleInputs}
                name="age"
                value={sampleData.age}
              />
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}
          <HighchartContainer
            id="prediction-chart"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(HealthInsuranceLeadPrediction);
