import * as React from "react";

import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core";
import styled from "styled-components";

export default function DenseTable({ tableData, onClick }) {
  return (
    <TableContainer>
      {tableData && (
      <StyledTable
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            {tableData.schema.fields.map((field) => (
              <HeaderCol key={field.name} align="left">{field.name}</HeaderCol>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.data.map((row) => (
            <TableRow key={row.index}>
              {tableData.schema.fields.map((field) => (
                field.name == "ProjectID" ? (
                  <StyledCell align="left" id={row[field.name]} onClick={onClick}>
                    {row[field.name]}
                  </StyledCell>
                ) : (
                  <TableCell align="left">
                    {row[field.name]}
                  </TableCell>
                )
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      )}
    </TableContainer>
  );
}

const HeaderCol = styled(TableCell)`
  background: rgba(144, 160, 171, 0.4);
  color: #000000;
`;
const StyledCell = styled(TableCell)`
&.MuiTableCell-root {
  text-decoration: underline;
  color: #575D90;
  cursor: pointer;
}


`;
const StyledTable = styled(Table)`
  @media only screen and (max-width: 1100px) {
    .MuiTableCell-root {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 600px) {
    .MuiTableCell-root {
      font-size: 12px;
    }
  }
`;
const TableContainer = styled(Paper)`
&.MuiPaper-root {
  max-height: 80%;
  max-width: 100%;
  overflow: auto;
}
`;
