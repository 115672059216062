import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  MenuItem,
  TextField,
  FormControl,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";

const ClinicalEventsPatient = () => {
  const [values, setValues] = useState(false);
  const [values1, setValues1] = useState(false);
  const [predictionData, setPredictionData] = useState(false);
  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);

  const { getQueryResult, postQueryResult } = CommonService(
    "z1dApps",
    "clinicaleventspatient",
  );

  const patientId = [
    { name: "Patient 1", value: "Patient_1" },
    { name: "Patient 2", value: "Patient_2" },
    { name: "Patient 3", value: "Patient_3" },
    { name: "Patient 4", value: "Patient_4" },
    { name: "Patient 5", value: "Patient_5" },
    { name: "Patient 6", value: "Patient_6" },
    { name: "Patient 7", value: "Patient_7" },
    { name: "Patient 8", value: "Patient_8" },
  ];

  const handleChange = (e) => {
    const { value } = e.target;
    setValues1(false);
    setValues(false);
    setSpinner(true);
    getQueryResult("result", value)
      .then((res) => {
        setSpinner(false);
        setPredictionData(res);
        const data = Object.keys(res.PastVisits);
        const sortedData = data.sort(customSort);
        const dataSet = sortedData.map((key) => ({ ...res.PastVisits[key], pastVisit: key }));
        setValues(dataSet);
        console.log(values);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const customSort = function (a, b) {
    return Number(a.match(/(\d+)/g)[0]) - Number(b.match(/(\d+)/g)[0]);
  };

  const postPredict = () => {
    setSpinner1(true);
    postQueryResult("predictData", predictionData)
      .then((res1) => {
        setSpinner1(false);
        console.log(res1);
        const data = Object.keys(res1.UpcomingVisits);
        const sortedData = data.sort(customSort);
        const dataSet1 = sortedData.map((key) => ({ ...res1.UpcomingVisits[key], upcomingVisit: key }));
        setValues1(dataSet1);
        console.log(values1);
      })
      .catch((err) => {
        console.log(err);
        setSpinner1(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            A common challenge in healthcare today is that physicians have
            access to massive amounts of data on patients, but little time nor
            tools. Intelligent clinical decision support anticipates the
            information at the point of care that is specific to the patient and
            provider needs. Electronic health records (EHR), represent the
            longitudinal experience of both patients and doctors
          </p>
          <p>
            This application implements a generic predictive model that covers
            observed medical conditions and medication uses. It is a temporal
            model using recurrent neural networks (RNN) and was developed and
            applied to longitudinal time stamped EHR data from 260K patients
            over 8 years. Encounter records (e.g. diagnosis codes, medication
            codes or procedure codes) were input to RNN to predict (all) the
            diagnosis and medication categories for a subsequent visit. The
            model assesses the history of patients to make multilabel
            predictions.
          </p>
          <b>Application :</b>
          <p>
            It used historical EHR data to predict future physician diagnoses
            and medication orders. It can be used in anticipating the patient
            status at the time of visit and presenting data a physician would
            want to see at the moment.
            <br />
            <br />
            Application also predicted the time to the patients next visit.
            Predicting the visit time facilitates guidance of whether a patient
            may be delayed in seeking care. It is also studied and observed
            empirically that knowledge learned from one hospital could be
            adapted to another hospital.
          </p>
          <b>Dataset :</b>
          <p>
            The application is built on MIMIC-III dataset. The MIMIC-III dataset
            is processed and a suitable training dataset is generated. Training
            dataset is a Python Pickled list of list of list. Each list
            corresponds to patients, visits, and medical codes (e.g. diagnosis
            codes, medication codes, procedure codes, etc.) First, medical codes
            need to be converted to an integer. Then a single visit can be seen
            as a list of integers. Then a patient can be seen as a list of
            visits.
            {" "}
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ClinicalEventsForPatient/ClinicalEventsForPatient.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://arxiv.org/pdf/1511.05942.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Paragraph>
            <StyledText>
              Select a patient to see the list of Hospital visits, date and
              corresponding ICD Codes
            </StyledText>
          </Paragraph>
          <Grid container direction="row" justify="center">
            <Grid item xs={12} sm={6} md={3}>
              <MarginedTextBox minWidth="120" varient="outlined" fullWidth>
                <TextField
                  label="Select Patient"
                  variant="outlined"
                  name="patient_id"
                  select
                  onChange={handleChange}
                >
                  {patientId.map((data) => (
                    <MenuItem value={data.value}>{data.name}</MenuItem>
                  ))}
                </TextField>
              </MarginedTextBox>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {values && (
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TablePaper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableHead align="center" minWidth="70px">
                          Past Visits
                        </StyledTableHead>
                        <StyledTableHead align="center" minWidth="70px">
                          Admission Date
                        </StyledTableHead>
                        <StyledTableHead align="left">
                          ICD Codes
                        </StyledTableHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{data.pastVisit}</TableCell>
                          <TableCell align="center">
                            {data.AdmissionDate}
                          </TableCell>
                          <TableCell align="left">
                            {data.ICD9_Code.join(",")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TablePaper>
              </Grid>
              <Paragraph>
                <StyledText>
                  Click Predict to view the list of next hospital visits,
                  probable duration by when the hospital visit is predicted and
                  the list medical (ICD) Codes for each of the predicted visit
                </StyledText>
              </Paragraph>
              <Grid item xs={12} sm={6} md={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={postPredict}
                >
                  Predict
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                {showSpinner1 && <Spinner text="Loading..." />}
              </Grid>
              {values1 && (
                <Grid item xs={12}>
                  <TablePaper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHead align="left">
                            Upcoming Visits
                          </StyledTableHead>
                          <StyledTableHead align="left">
                            Predicted Admission Date
                          </StyledTableHead>
                          <StyledTableHead align="left">
                            Predicted ICD9 Codes
                          </StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values1.map((data1, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">
                              {data1.upcomingVisit}
                            </TableCell>
                            <TableCell align="center">
                              {data1.Predicted_Admission_Date}
                            </TableCell>
                            <TableCell align="left">
                              {data1.Predicted_ICD9_Codes.join(",")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TablePaper>
                </Grid>
              )}
            </Grid>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ClinicalEventsPatient);

const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
  white-space: nowrap;
  min-width: ${(props) => props.minWidth};
  @media only screen and (max-width: 900px) {
    font-size: 14px;
    min-width: auto;
  }
`;

const TablePaper = styled.div`
  overflow: scroll;
  max-height: 500px;
  .MuiTableCell-root {
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
`;

const MarginedTextBox = styled(FormControl)`
  margin: 10px;
`;

const StyledText = styled.strong`
  font-size: 14px;
  text-align: justify;
`;
