import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { TabPanel, a11yProps } from './ResultPanel';
import { AntTab, AntTabs } from './DataResultTab';
import { StyledButton } from '../../../../../styles/common';
import { PastActivitySortTable } from '../../../../common/JsonDataTable';

function DataTransformationResult({ getPastActivity }) {
  const { resultData = {} } = useSelector((state) => state.vnet);
  const [value, setValue] = useState(0);
  const [tableBody, setTableBodyData] = useState(null);
  const currentHeaderData = [
    { label: 'Execution Time', sortKey: 'ExecutionTime', type: 'date' },
    { label: 'User' },
    { label: 'Operation' },
    { label: 'Action Performed' },
    { label: 'Comments' },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTableBodyData(resultData?.dataTransform?.Past_Activity);
  }, [resultData?.dataTransform?.Past_Activity]);

  return (
    <div>
      <p>
        To view all the activities related to data transformation, click on Execute
        <StyledButton variant="contained" color="primary" onClick={getPastActivity}>Execute</StyledButton>
      </p>
      {tableBody && (
      <>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Activities" {...a11yProps(0)} />
        </AntTabs>

        <TabPanel value={value} index={0}>
          <PastActivitySortTable headerData={currentHeaderData} bodyData={tableBody} colKeys={["ExecutionTime", "UserID", "FunctionID", "Console", "Comments"]} />
        </TabPanel>
      </>
      )}
    </div>
  );
}
export default memo(DataTransformationResult);
