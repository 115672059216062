import React, { memo, useState } from "react";
import {
    Grid, TextField, AppBar, Tab, MenuItem, Paper, Tabs
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import local components
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
    StyledButton,
    SolutionContainer,
    LinkContainer,
    Paragraph,
} from "../../../styles/common";
import CommonService from "../../../utils/services/CommonService";
import { StyledTabs } from "./ColbertAnalysis";
import Spinner from "../../common/loading/Spinner";
import RadioTable from "../../common/RadioTable";

// images
import kg_sol1 from "../../../assets/images/app/BioMedKG_Architecture.webp";

const { postQueryResult, getQueryResult } = CommonService(
    "z1dApps",
    "knowledgegraph",
);

const KnowledgeGraph = () => {

    const [notification, setNotification] = useState('');
    const [showSpinner, setSpinner] = useState(false);
    const [showSpinner1, setSpinner1] = useState(false);
    const [result1, setResult] = useState('');
    const [nodedata, setNodeData] = useState('');
    const [tabVal, setTabVal] = useState(0);
    const [subtabVal, setSubTabVal] = useState(0);
    const [dropdown, setDropDown] = useState('');
    const [iframelink, setIframeLink] = useState('');
    const [firstTabVal, setFirstTabVal] = useState(null);
    const [secondtabVal, setsecondTabVal] = useState(null);
    const [thirdtabVal, setThirdTabVal] = useState(null);
    const [columndata, setColumnData] = useState({ article: "", query: "" });
    const [pubmedData, setPubmedData] = useState({ id: '' });
    const [custom, setcustom] = useState('');
    const [alzeimer, setalzeimer] = useState('');
    const [querytext, setQueryText] = useState('');
    const [querybutton, setQuerybutton] = useState(false);
    // radio button
    const [radiovalue, setRadioValue] = useState("");
    const [radioName, setRadioName] = useState("");

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
        setRadioName(event.target.name);
        if (event.target.name === "custom") {
            setcustom(true);
            setalzeimer(false);
            setQuerybutton(false);
            setQueryText(true);
        }
        else {
            setalzeimer(true);
            setcustom(false);
            setQuerybutton(true);
            setQueryText(false);
        }
        setColumnData({});
        setFirstTabVal(false);
        setsecondTabVal(false);
        setThirdTabVal(false);
    };

    const handleSubTabChange = (event, newValue) => {
        setSubTabVal(newValue);
    };

    const handlePMId = (e) => {
        setPubmedData({ ...pubmedData, pmcId: e.currentTarget.id, id: '' });
    }

    const getQuery = () => {

        setSubTabVal(3);
        setThirdTabVal("Knowledge-Graph Generation");

    };

    const getArticleData = () => {

        setSpinner1(true);
        setSubTabVal(2);
        setsecondTabVal("Extract Semantic Triples");
        setNodeData("");

        const params = pubmedData.pmcId;

        getQueryResult('articleResult', params)
            .then((res) => {
                setNotification({ open: 'success', message: res.Console });
                setNodeData(res);
                setSpinner1(false);
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner1(false);
            });
    };

    const getQueryResults = () => {

        setResult("");
        setPubmedData({ ...pubmedData, pmcId: '', id: '' });
        let areValuesEmpty = false;
        if (columndata.article === "" || columndata.query === "") {
            areValuesEmpty = true;
        }
        if (areValuesEmpty) {
            setNotification({ open: "error", message: "Fields are empty" });
        } else {
            setFirstTabVal("Retrieve Articles");
            setSubTabVal(1);
            setSpinner(true);
            postQueryResult('queryResult', {
                Content_param: {
                    query: columndata.query,
                    article_count: columndata.article,
                },
            })
                .then((res) => {
                    setNotification({ open: 'success', message: res.Console });
                    setResult(res);
                    setSpinner(false);
                })
                .catch((err) => {
                    setNotification({ open: 'error', message: err.message });
                    setSpinner(false);
                });
        }
    };

    const handleInputs = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setColumnData({
            ...columndata,
            [name]: value,
        });
        if (value === "Alzheimer")
            setDropDown(Alzheimer);
        else if (value === "Lung_Cancer")
            setDropDown(Lung_Cancer);
        else if (value === "Asthma")
            setDropDown(Asthma);
        else if (value === "Parkinson_Disease")
            setDropDown(Parkinson_Disease);
        else if (value === "Coronary_Arteriosclerosis")
            setDropDown(Coronary_Arteriosclerosis);
    };

    const handleDropdown = (e) => {
        setSpinner(true);
        setTimeout(() => {
            setSpinner(false);
            const { name } = e.target;
            const { value } = e.target;
            setIframeLink(value);
        }, 2000);
    };

    // const handleTabChange = (event, newValue) => {
    //     setTabVal(newValue);
    //     setColumnData({});
    //     setResult("");
    //     setNodeData("");
    //     setSubTabVal(0);
    //     setsecondTabVal(null);
    //     setThirdTabVal(null);
    // };

    const Alzheimer = [
        {
            label:
                'What are the diseases which co-exist with disease called Temporary Amnesia? What are the procedures to diagnose the disease called Epilepsy? What are the diseases that are diagnosed by the procedure called Electroencephalography?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/TemporaryAmnesia_Epilepsy_Electroencephalography.html',
        },
        {
            label:
                'What are the cell functions which are affected by the organism function called Catabolism? What are the amino acids, peptides, or proteins which augment the cell function called Autophagy? What are the cells which produce the amino acid, peptide, or protein called Nitric Oxide Synthase?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Catabolism_Autophagy_NitricOxideSynthase.html',
        },
        {
            label:
                'What are the body part, organ, or organ components which are part of the Brain region?What are the tissues which are part of body part, organ, or organ component called Visual Cortex?What are the cells which are part of the tissue called Gray Matter?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Brain_VisualCortex_GrayMatter.html',
        },
        {
            label:
                'What are the gene or genomes which are associated with the mental or behavioral dysfunction called Depressive disorder?What are the body part, organ, or organ components which are the location of gene or genome called VEGFB?What is the body region of the body part, organ, or organ component called Carotid Arteries?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Depressive_VEGFB_CarotidArteries.html',
        },
        {
            label:
                'What are the diagnostic procedures which are similar to the intellectual product called Clock Test?What are the mental or behavioral dysfunctions which are diagnosed by the diagnostic procedure called Mini-mental state examination?What are the other mental or behavioral dysfunctions which are affected by mental or behavioral dysfunction called Memory impairment?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/ClockTest_MMST_MemoryImpairment.html',
        },
        {
            label:
                'What are the amino acids, peptides, or proteins which treat the syndrome called Adult Fanconi syndrome?What are the gene or genomes which convert to amino acid, peptide, or protein called aldehyde dehydrogenases?What are the neoplastic processes which produce gene or genome called ALDH3A1?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/AdultFanconi_aldehydeDehydrogenases_ALDH3A1.html',
        },
        {
            label:
                'What are the molecular functions affected by the protein called Apolipoprotein E?What are the gene or genomes which augment the molecular function called Proteolysis?What are the body part, organ, or organ components which are the location of IRS1 gene?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Apolipoprotein_Proteolysis_IRS1.html',
        },
    ];
    const Lung_Cancer = [
        {
            label:
                'What are the medical devices which are used by the diagnostic procedure called Chest CT?What are the other medical devices that use the device called Computed Tomography Scanning Systems?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/ChestCT_ComputedTomographyScanningSystems.html',
        },
        {
            label:
                'What are the pathologic functions which are caused by the bacterium called Helicobacter pylori?What are the organic chemicals which treat the pathologic function called Chronic inflammation?What are the amino acids, peptides, or proteins which stimulate the organic chemical called doxycycline?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Helicobacter_ChronicInflammation_doxycycline.html',
        },
        {
            label:
                'What are the diseases or syndromes which are prevented by the organic chemical called omega-3 fatty acids?What are the injuries or poisoning which coexist with cardiovascular diseases?What are the therapeutic or preventive procedures which treat the Poisoning called Cardiotoxicity?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/omega3_CVD_Cardiotoxicity.html',
        },
        {
            label:
                'What are the amino acids, peptides, or proteins which treat the syndrome called Adult Fanconi syndrome?What are the gene or genomes which convert to amino acid, peptide, or protein called aldehyde dehydrogenases?What are the neoplastic processes which produce ALDH3A1 gene?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/AdultFanconi_aldehydeDehydrogenases_ALDH3A1.html',
        },
        {
            label:
                'What are the cell functions which are disrupted by the organic chemical called doxorubicin?What are the pharmacologic substances which disrupt the cell function called Cell Growth?What are the neoplastic processes which are treated by the pharmacologic substance called MTOR Inhibitors?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/doxorubicin_CellGrowth_MTOR.html',
        },
        {
            label:
                'What are the amino acids, peptides, or proteins which affect the genetic function called DNA Repair?What are the nucleic acids, nucleosides, or nucleotides which interact with RNA-Binding Proteins?What are the pathologic functions which are caused by the nucleic acid, nucleoside, or nucleotide called MicroRNAs?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/DNARepair_RNA_MicroRNAs.html',
        },
        {
            label:
                'What are the organic chemicals which treat the neoplastic process called Carcinoma of lung?What are the therapeutic or preventive procedures which use the organic chemical called carboplatin?What are the medical devices used in the therapeutic or preventive procedure called Radiosurgery?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/CarcinomaLung_Carboplatin_Radiosurgery.html',
        },
    ];
    const Asthma = [
        {
            label:
                'What are the body part, organ, or organ components which are the location of the disease called Asthma?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/AsthmaDisease.html',
        },
        {

            label:
                'What are the medical devices which are used by the diagnostic procedure called Chest CT?What are the other medical devices that use the device called Computed Tomography Scanning Systems?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/ChestCT_ComputedTomographyScanningSystems.html',
        },
        {

            label:
                'What are the gene or genomes which are associated with the mental or behavioral dysfunction called Depressive disorder?What are the body part, organ, or organ components which are the location of VEGFB gene?What is the body region of the body part, organ, or organ component called Carotid Arteries?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Depressive_VEGFB_CarotidArteries.html',
        },
        {
            label:
                'What are the molecular functions affected by the protein called Apolipoprotein E?What are the gene or genomes which augment the molecular function called Proteolysis?What are the body part, organ, or organ components which are the location of IRS1 gene?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Apolipoprotein_Proteolysis_IRS1.html',
        },
        {
            label:
                'What are the amino acids, peptides, or proteins which affect the genetic function called DNA Repair?What are the nucleic acids, nucleosides, or nucleotides which interact with RNA-Binding Proteins?What are the pathologic functions which are caused by MicroRNAs?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/DNARepair_RNA_MicroRNAs.html',
        },
        {
            label:
                'What are the cell functions which are disrupted by the organic chemical called acetylcholine?What are the pharmacologic substances which disrupt the cell function called Cytokinesis?What are the neoplastic processes which are treated by the pharmacologic substance called Caspase Inhibitors?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Acetylcholine_Cytokinesis_CaspaseInhibitors.html',
        },
        {
            label:
                'What are the organic chemicals which treat the disease called Asthma?What are the therapeutic or preventive procedures that use the organic chemical called tiotropium?What are the other therapeutic or preventive procedures which are similar to Inhalation Therapy?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Asthma_Tiotropium_InhalationTherapy.html',
        },
    ];
    const Parkinson_Disease = [
        {
            label:
                'What are the organic chemicals which treat Parkinson Disease?What are the therapeutic or preventive procedures that use the organic chemical called resveratrol?What are the organism functions which are stimulated by Adoptive Immunotherapy?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Parkinson_resveratrol_AdoptiveImmunotherapy.html',
        },
        {
            label:
                'What are the body part, organ, or organ components which are part of the Brain region?What are the tissues which are part of body part, organ, or organ component called Visual Cortex?What are the cells which are part of the tissue called Gray Matter?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Brain_VisualCortex_GrayMatter.html',
        },
        {
            label:
                'What are the gene or genomes which are associated with the mental or behavioral dysfunction called Depressive disorder?What are the body part, organ, or organ components which are the location of gene or genome called VEGFB?What is the body region of the body part, organ, or organ component called Carotid Arteries?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Depressive_VEGFB_CarotidArteries.html',
        },
        {
            label:
                'What are the diagnostic procedures which are similar to the intellectual product called Clock Test?What are the mental or behavioral dysfunctions which are diagnosed by the diagnostic procedure called Mini-mental state examination?What are the other mental or behavioral dysfunctions which are affected by mental or behavioral dysfunction called Memory impairment?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/ClockTest_MMST_MemoryImpairment.html',
        },
        {
            label:
                'What are the therapeutic or preventive procedures which treat the congenital abnormality called Agenesis of corpus callosum?What are the diseases or syndromes which are treated by the therapeutic or preventive procedure called Metastasectomy ?What are the organic chemicals associated with Obesity?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/AgenesisCorpusCallosum_Metastasectomy_Obesity.html',
        },
        {
            label:
                'What are the amino acids, peptides, or proteins which treat Adult Fanconi syndrome?What are the gene or genomes which convert to amino acid, peptide, or protein called aldehyde dehydrogenases? What are the neoplastic processes which produce ALDH3A1 gene?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/AdultFanconi_aldehydeDehydrogenases_ALDH3A1.html',
        },
        {
            label:
                'What are the signs or symptoms which co-exist with the sign or symptom called Sleeplessness? What are the body part, organ, or organ components which are the location of the sign or symptom called Dyspnea?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Sleeplessness_Dyspnea.html',
        },
    ];
    const Coronary_Arteriosclerosis = [
        {
            label:
                'What are the medical devices which are used by the diagnostic procedure called Chest CT? What are the other medical devices that use the device called Computed Tomography Scanning Systems?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/ChestCT_ComputedTomographyScanningSystems.html',
        },
        {
            label:
                'What are the pathologic functions which are caused by the bacterium called Helicobacter pylori? What are the organic chemicals which treat the pathologic function called Chronic inflammation? What are the amino acids, peptides, or proteins which stimulate the organic chemical called doxycycline?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Helicobacter_ChronicInflammation_doxycycline.html',
        },
        {
            label:
                'What are the diseases or syndromes which are prevented by the organic chemical called omega-3 fatty acids? What are the injuries or poisoning which coexist with cardiovascular diseases? What are the therapeutic or preventive procedures which treat the Poisoning called Cardiotoxicity?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/omega3_CVD_Cardiotoxicity.html',
        },
        {
            label:
                'What are the signs or symptoms which co-exist with the sign or symptom called Sleeplessness? What are the body part, organ, or organ components which are the location of the sign or symptom called Dyspnea?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Sleeplessness_Dyspnea.html',
        },
        {
            label:
                'What are the amino acids, peptides, or proteins which affect the genetic function called DNA Repair? What are the nucleic acids, nucleosides, or nucleotides which interact with RNA-Binding Proteins? What are the pathologic functions which are caused by MicroRNAs?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/DNARepair_RNA_MicroRNAs.html',
        },
        {
            label:
                'What are the organic chemicals which treat the disease called Coronary Arteriosclerosis? What are the therapeutic or preventive procedures that use the organic chemical called tamoxifen? What are the neoplastic processes which are prevented by Antiestrogen Therapy?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/CoronaryArteriosclerosis_Tamoxifen_AntiestrogenTherapy.html',
        },
        {
            label:
                'What are the cell functions which are affected by the organism function called Catabolism? What are the amino acids, peptides, or proteins which augment the cell function called Autophagy? What are the cells which produce the amino acid, peptide, or protein called Nitric Oxide Synthase?',
            value: 'https://material.vlifevirtusa.com/Knowledge_graph_from_BioMedical_Literatures/BioMedKG/Catabolism_Autophagy_NitricOxideSynthase.html',
        },
    ];
    const dieseases = [
        { name: "Alzheimer", value: "Alzheimer" },
        { name: "Lung Cancer", value: "Lung_Cancer" },
        { name: "Asthma", value: "Asthma" },
        { name: "Parkinson Disease", value: "Parkinson_Disease" },
        { name: "Coronary Arteriosclerosis", value: "Coronary_Arteriosclerosis" },
    ];
    return (
        <SolutionContainer snackbar={notification}>
            <section>
                <div>
                    <Collapse text="Description">
                        <Grid container xs={12} direction="row">
                            <Grid item xs={12} md={12}>
                                <Paragraph>
                                    <p>
                                        A significant proportion of the world’s data is in unstructured format like social media, medical records, and business documents. According to some insights, between 80 to 90 percent of the data generated every day can be defined as unstructured data. Unsurprisingly, such phenomena are also observed in health care such as electronic health records, research papers, etc. Research papers contain a vast amount of information which we can use to represent the domain knowledge in structured graphs. To employ this, we could leverage Knowledge graphs. Knowledge graphs are widely used to model prior knowledge in the form of nodes and edges to represent semantically connected knowledge entities.
                                    </p>
                                    <center>
                                        <Grid item xs={12} sm={10} md={10}>
                                            <img width="100%" src={kg_sol1} alt="" />
                                        </Grid>
                                    </center>
                                    <p>
                                        In order to retrieve medical articles, PubMed Central or PubMed resources were used which support search and retrieval of biomedical and life sciences literature. PMC is a full-text archive of biomedical and life sciences journal literature. Whereas, PubMed contains abstracts of biomedical literature.
                                    </p>
                                    <p>Next, we leverage the power of SemRep, a rule-based natural language processing (NLP) system, based on (1) medical concepts, (2) concept types (e.g., drugs, diseases), and (3) semantic relationships between concepts (e.g., drugs-TREAT-diseases) in Unified Medical Language System (UMLS), to identify and extract semantic triples (called semantic predications) from biomedical text. Semantic triples are a set of three entities in the form of subject–predicate–object (or head–relation–tail) expression which are basic building blocks of a knowledge graph.</p>
                                    <p>After extracting the semantic triples, the properties, such as concept type and concept name, were mapped with the extracted nodes in CUI (Concept Unique Identifier – a unique id assigned to a medical concept). For this, UMLS APIs were used to fetch the concept name and concept category for each CUI. UMLS facilitates and bring together many health and biomedical vocabularies and standards to enable interoperability between computer systems.</p>
                                    <p>After enriching the nodes with properties, the data is stored in s3 from where we load it into a Neptune Cluster. AWS Neptune is employed to store the triples data since Neptune is a graph database service engine optimized for storing billions of relationships and querying the graph with milliseconds latency. Using this Neptune cluster, Knowledge graphs can be extracted based on different queries in an interactive html format.</p>
                                </Paragraph>
                            </Grid>
                        </Grid>
                        <LinkContainer>
                            <Grid container spacing={2}>

                            </Grid>
                        </LinkContainer>
                    </Collapse>
                    <Collapse text="Demo">
                        <br />
                        {/* <AppBar position='static' color='default'>
                            <StyledTabs
                                value={tabVal}
                                onChange={handleTabChange}
                                variant='fullWidth'
                                aria-label='simple tabs example'
                            >
                                <Tab label='Alzheimer / Lung Cancer' />
                                <Tab label='General Medical Articles' />
                            </StyledTabs>
                        </AppBar> */}

                        <AppBar position='static'>
                            <StyledTabss
                                value={subtabVal}
                                onChange={handleSubTabChange}
                                aria-label='simple tabs example'
                            >
                                <Tab
                                    label={
                                        <div
                                            style={{
                                                marginRight: 'auto',
                                                maxWidth: 'max-content'
                                            }}
                                        >
                                            <span>Select/Type Medical Keywords&nbsp;&nbsp;</span>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                                        </div>
                                    }
                                />
                                {firstTabVal && (
                                    <Tab
                                        label={
                                            <div
                                                style={{
                                                    marginRight: 'auto',
                                                    maxWidth: 'max-content'
                                                }}
                                            >
                                                <span>{firstTabVal}&nbsp;&nbsp;</span>
                                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                                            </div>
                                        }
                                    />
                                )}
                                {secondtabVal && (
                                    <Tab
                                        label={
                                            <div
                                                style={{
                                                    marginRight: 'auto',
                                                    maxWidth: 'max-content'
                                                }}
                                            >
                                                <span>{secondtabVal}&nbsp;&nbsp;</span>
                                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                                            </div>
                                        }
                                    />
                                )}
                                {thirdtabVal && (
                                    <Tab
                                        label={
                                            <div
                                                style={{
                                                    marginRight: 'auto',
                                                    maxWidth: 'max-content'
                                                }}
                                            >
                                                <span>{thirdtabVal}&nbsp;&nbsp;</span>
                                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                                            </div>
                                        }
                                    />
                                )}
                                {/* <Tab label={firstTabVal} /> */}
                            </StyledTabss>
                        </AppBar>
                        <TabPanel value={subtabVal} index={0}>
                            <Grid
                                container
                                spacing={2}
                                direction='row'
                                justify='center'
                                alignItems='center'
                            >
                                <Grid item xs={12}>
                                    <Paragraph><b>Please select an option to proceed</b></Paragraph>
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                    <StyledRadioGroup
                                        aria-labelledby='demo-controlled-radio-buttons-group'
                                        name={radioName}
                                        value={radiovalue}
                                        onChange={handleRadioChange}
                                    >
                                        <FormControlLabel
                                            name="alzeimer"
                                            value="alzeimer"
                                            control={<Radio />}
                                            label='Sample Medical keywords'
                                        />
                                        <FormControlLabel
                                            name="custom"
                                            value="custom"
                                            control={<Radio />}
                                            label='Custom Medical keywords'
                                        />
                                    </StyledRadioGroup>
                                </Grid>
                            </Grid>
                            {alzeimer && (
                                <section>
                                    <p>Please select an option from “Search Term”, select the number of articles and click “Execute” to retrieve the articles relevant to the selected medical key word.</p>
                                    <Grid container xs={12} spacing={2} direction='row' justify='center'>
                                        <Grid item xs={6} sm={5} md={4}>
                                            <TextField
                                                name="query"
                                                label="Search Term"
                                                variant="outlined"
                                                onChange={handleInputs}
                                                fullWidth
                                                select
                                                value={columndata.query}
                                            >
                                                {dieseases.map((value) => (
                                                    <MenuItem value={value.value}>
                                                        {value.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={4}>
                                            <TextField
                                                name="article"
                                                label="No of Articles"
                                                variant="outlined"
                                                onChange={handleInputs}
                                                fullWidth
                                                select
                                                value={columndata.article}
                                            >
                                                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((value) => (
                                                    <MenuItem value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid xs={12} md={12}>
                                        <center>  <StyledButton
                                            variant='contained'
                                            color='primary'
                                            onClick={getQueryResults}
                                        >
                                            Execute
                                        </StyledButton></center>
                                    </Grid>
                                </section>
                            )}
                            {custom && (
                                <section>
                                    <p>Please type a keyword or two and also select the number of articles to retrieve the articles relevant to your typed medical key word(s).</p>
                                    <Grid container xs={12} spacing={2} direction='row' justify='center' alignItems='center'>
                                        <Grid item xs={6} sm={5} md={4}>
                                            <TextField
                                                name="query"
                                                label="Search Term"
                                                variant="outlined"
                                                onChange={handleInputs}
                                                fullWidth
                                                value={columndata.query}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={4}>
                                            <TextField
                                                name="article"
                                                label="No of Articles"
                                                variant="outlined"
                                                onChange={handleInputs}
                                                fullWidth
                                                select
                                                value={columndata.article}
                                            >
                                                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((value) => (
                                                    <MenuItem value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid item xs={12} md={12}>
                                        <center> <StyledButton
                                            variant='contained'
                                            color='primary'
                                            onClick={getQueryResults}
                                        >
                                            Execute
                                        </StyledButton></center>
                                    </Grid>
                                </section>
                            )}<br />

                        </TabPanel>
                        <TabPanel value={subtabVal} index={1}>
                            {result1 && (
                                <section>
                                    <Paragraph>Please find below the PubMed (PM) or PubMed Central (PMC) IDs of the articles and their respective titles.</Paragraph>
                                    <Paragraph>Also, please select an article to extract its semantic triples, a set of three entities in the form of subject–predicate–object (or head–relation–tail) expression. The triples data is used to store the data in any Graph Database.</Paragraph>
                                    <Grid container xs={12} spacing={2} direction='row' justify='center' alignItems='center'>
                                        <Grid item xs={10} sm={10} md={12}>

                                            <RadioTable header={result1?.header} body={result1?.body} onclick={handlePMId} id={pubmedData?.pmcId} />

                                        </Grid>
                                    </Grid>
                                    {result1 && pubmedData.pmcId && <>
                                        <Paragraph>Please click on “Execute” button to proceed.</Paragraph>
                                        <Grid container xs={12} spacing={5} direction='row' justify='center' alignItems='center'>
                                            <Grid item xs={5} sm={4} md={2}>
                                                <StyledButton onClick={getArticleData} variant='contained' color="primary" >Execute</StyledButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                    }
                                </section>
                            )}
                        </TabPanel>
                        <TabPanel value={subtabVal} index={2}>
                            {showSpinner1 && <Spinner text="Extracting nodes and its edges..." />}
                            {nodedata && (
                                <section>
                                    <Paragraph>Please find below the triples data used to store in graph databases.</Paragraph>
                                    <Grid container xs={12} spacing={5} direction='row' justify='center' alignItems='center'>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <b>Vertex/Node Data contains</b>
                                            <ul>
                                                <li>CUI – unique id assigned to a UMLS concept</li>
                                                <li>Class – concept semantic type or category</li>
                                                <li>Name - concept name of the node</li>
                                            </ul>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <b>Edge Data contains</b>
                                            <ul>
                                                <li>Head - The CUI of the ‘head’ node</li>
                                                <li>Relation - label between nodes</li>
                                                <li>Tail - The CUI of the ‘tail’ node</li>
                                            </ul>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <center><strong>Vertex/Node Data</strong></center><br />
                                            <TablePaper>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableHead>CUI</StyledTableHead>
                                                            <StyledTableHead>CLASS</StyledTableHead>
                                                            <StyledTableHead>NAME</StyledTableHead>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {nodedata["0"]?.map((value) => (
                                                            <TableRow>
                                                                <TableCell>{`${value[0]}`}</TableCell>
                                                                <TableCell>{`${value[1]}`}</TableCell>
                                                                <TableCell>{`${value[2]}`}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TablePaper>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <center><strong>Edge Data/Relations between nodes</strong></center><br />
                                            <TablePaper>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableHead>Head</StyledTableHead>
                                                            <StyledTableHead>Relation</StyledTableHead>
                                                            <StyledTableHead>Tail</StyledTableHead>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {nodedata["1"]?.map((values) => (
                                                            <TableRow>
                                                                <TableCell>{`${values[0]}`}</TableCell>
                                                                <TableCell>{`${values[1]}`}</TableCell>
                                                                <TableCell>{`${values[2]}`}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TablePaper>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                            {querybutton && (
                                                <section>
                                                    <StyledButton
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={getQuery}
                                                    >
                                                        Get Query
                                                    </StyledButton>

                                                </section>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {querytext && (
                                        <Paragraph>We are unable to generate knowledge graphs with this information at the moment. To view knowledge graphs from pre-loaded data, please select a sample keyword from the “Select/Type Medical Keywords” tab.</Paragraph>
                                    )}
                                </section>
                            )}
                        </TabPanel>
                        <TabPanel value={subtabVal} index={3}>
                            {thirdtabVal && (
                                <section><br />
                                    <Paragraph>Please select a query to generate the Knowledge graph which answers that query.</Paragraph>
                                    <Grid container xs={12} spacing={5} direction='row' justify='center' alignItems='center'>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                name="article"
                                                label="Select a Query"
                                                variant="outlined"
                                                onChange={handleDropdown}
                                                fullWidth
                                                select
                                            >
                                                {dropdown.map((option) => (
                                                    <MenuItem key={option.label} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid><br /><br />
                                    {iframelink && (
                                        <Grid container xs={12} spacing={2} direction='row' justify='center' alignItems='center'>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <iframe
                                                    id="iFrame"
                                                    name="myFrame"
                                                    src={iframelink}
                                                    width="100%"
                                                    height="600"
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </section>
                            )}
                        </TabPanel>

                    </Collapse>
                    {showSpinner && <Spinner text="Loading..." />}

                </div>
            </section>
        </SolutionContainer >
    );
};
function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tablepanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <PanelContainer>{children}</PanelContainer>}
        </div>
    );
}
export default memo(KnowledgeGraph);

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

const StyledTableHead = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: #3c40af;
    color: white;
    font-weight: bold;
    font-size: smaller;
    @media only screen and (max-width: 450px) {
      font-size: x-small;
    }
  }
`;

const TabPanels = styled(TabPanel)`
::-webkit-scrollbar { 
    display: none; 
}
`;

const TablePaper = styled.div`
width: 100%;
height: 450px;
overflow: auto;
`;

export const StyledTabss = styled(StyledTabs)`

    color: black;
    background-color: white;

.Mui-selected{
    background-color: white;
    color: black;
    font-weight: bold;
    text-transform: none;
  }

`;

export const StyledRadioGroup = styled(RadioGroup)`
                display: block;
                text-align: left;
                font-size: 28px;
                `;