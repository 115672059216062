import React, { memo, useState } from "react";
import {
  Tab,
  Grid,
  AppBar,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import styled from "styled-components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faTimes,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import ZoomImage from "../../common/ZoomImage";
import Button from "@material-ui/core/Button";

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import { StyledTabs } from "./QuestionGeneration";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
} from "../../../styles/common";

// image components
import clinicalnotespreprocessor_solution1 from "../../../assets/images/app/clinicalnotespreprocessor.webp";
import clinicalnotespreprocessor_sample1 from "../../../assets/images/app/periodontal-chart.png";
import clinicalnotespreprocessor_sample2 from "../../../assets/images/app/periodontal-chart2.png";
import clinicalnotespreprocessor_sample3 from "../../../assets/images/app/periodontal-chart3.png";
import clinicalnotespreprocessor_sample4 from "../../../assets/images/app/periodontal-chart4.png";
import perioDesimage from "../../../assets/images/app/perioDesimage1.webp";
import perioDesimage2 from "../../../assets/images/app/perioDesimage2.webp";
import InfoToolTip from './vnet/components/InfoToolTip';
import ContactUsDialog from '../../common/ContactusDialog';
// user id
import getUserData from "../../../utils";

export const PeriodontalChartDemo = memo(() => {
  const { uploadFilesWithBody } = CommonService("z1dApps", "periodontalChar");

  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const [notification, setNotification] = useState("");
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [showSpinner, setSpinner] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [contactus, setContactus] = useState(false);
  const [imagename, setImageName] = useState(false);
  const [link, setLink] = useState(false);

  // upload file
  const [selectedFile, setselectedFile] = useState("");
  const [btnEnable, setBtnEnable] = useState(true);
  const [fileName, setSelectedFileName] = useState("");

  // for contact us
  const [dialogContact, setOpenDialogContact] = useState(false);

  const openDialogContact = () => {
    setOpenDialogContact(true);
  };
  const closeDialogContact = () => {
    setOpenDialogContact(false);
  };

  const [metaData, setMetaData] = useState({
    isDataValid: false,
    isSample: false,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setData({});
    setLink(false);
    setContactus(false);
  };

  const fileSelectedHandler = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;

    if (e.target.files && e.target.files[0]) {
      setselectedFile(file);
      setBtnEnable(false);
      setSelectedFileName(fileName);
      setImageName(fileName);
    }
    if (['jpg', 'jpeg', 'png', 'webp', 'pdf'].indexOf(file?.name?.split('.')[1]?.toLowerCase()) == -1) {
      setNotification({ open: 'error', message: "please upload only jpg/jpeg/png/pdf/webp format" });
      setselectedFile("");
      setSelectedFileName("");
      setImageName("");
      setBtnEnable(true)
      return false;
    }
  };

  const setSampleData = (e) => {
    setSpinner(true);
    setData({});
    const name = e.currentTarget.getAttribute("data-sample");
    const id = e.currentTarget.id;
    setImageName(name);
    const downloadLink = name == "sample1"
      ? "https://material.vlifevirtusa.com/PerioExtractor/Samples/sample1_output.json"
      : name == "sample2"
        ? "https://material.vlifevirtusa.com/PerioExtractor/Samples/sample2_output.json"
        : name == "sample3"
          ? "https://material.vlifevirtusa.com/PerioExtractor/Samples/sample3_output.json"
          : name == "sample4"
            ? "https://material.vlifevirtusa.com/PerioExtractor/Samples/sample4_output.json"
            : "";
    setDownloadLink(downloadLink);

    setTimeout(function () {
      setSpinner(false);
      getData(id);
    }, 3000);
  };

  const getData = (data) => {
    setLink(true);
    fetch(data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setData({
          ...data,
          result: myJson,
        });
      });
  };

  const getUploadResult = () => {
    setData({});
    if (!selectedFile) {
      setNotification({ open: "error", message: "please upload a image" });
      return;
    }
    setSpinner(true);
    uploadFilesWithBody("upload", {
      file: selectedFile,
      Context_param: JSON.stringify({
        UserID: userId,
      }),
    })
      .then((res) => {
        setSpinner(false);
        setData({
          ...data,
          result: res?.FinalData,
        });
        setDownloadLink(res?.Json_Path_signedURL);
        setContactus(true);
        if(res.Success_msg)
        setNotification({ open: "success", message: res.Success_msg });
        if(res.Error_msg)
        setNotification({ open: "error", message: res.Error_msg });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBar position="static" color="default">
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  variant='fullWidth'
                  aria-label="simple tabs example"
                >
                  <Tab label="Sample" />
                  <Tab label="BYOD" />
                </StyledTabs>
              </AppBar>
            </Grid>
          </Grid>
          <TabPanel value={value} index={0}>
            <Paragraph>
              Please select a sample periodontal chart to generate a table
              containing information regarding each tooth from the uploaded
              chart with their respective Probing Depth values for both Buccal
              and Lingual section
            </Paragraph>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={3} md={3}>
                <center>
                  <ZoomImage
                    width="52%"
                    src={clinicalnotespreprocessor_sample1}
                    alt=""
                  />
                </center>
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample1"
                    onClick={setSampleData}
                    value="sample1"
                    id="../../../assets/files/sample1_output.json"
                    name="sample1"
                  >
                    Sample 1
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <center>
                  <ZoomImage
                    width="100%"
                    src={clinicalnotespreprocessor_sample2}
                    alt=""
                  />
                </center>
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample2"
                    onClick={setSampleData}
                    value="sample2"
                    id="../../../assets/files/sample2_output.json"
                    name="sample2"
                  >
                    Sample 2
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <center>
                  <ZoomImage
                    width="72%"
                    src={clinicalnotespreprocessor_sample3}
                    alt=""
                  />
                </center>
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample3"
                    onClick={setSampleData}
                    value="sample3"
                    name="sample3"
                    id="../../../assets/files/sample3_output.json"
                  >
                    Sample 3
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <center>
                  <ZoomImage
                    width="62%"
                    src={clinicalnotespreprocessor_sample4}
                    alt=""
                  />
                </center>
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample4"
                    onClick={setSampleData}
                    value="sample4"
                    name="sample4"
                    id="../../../assets/files/sample4_output.json"
                  >
                    Sample 4
                  </StyledButton>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={1} md={1}>
                <strong>
                  <InfoToolTip
                    title="Basic understanding of the chart"
                    info="•	Probing/Pocket Depth is the space measured (in mm) between a tooth and the gum tissue surrounding it.
                    •	Buccal refers to the side of the teeth facing the cheek, and Lingual refers to the side facing the tongue.
                    •	The upper half of the periodontal chart focuses on the teeth lying in the upper jaw, while the bottom half focuses on the teeth lying in the lower jaw.
                    •	Six measurements of probing depth are taken per tooth; three in buccal side and three in lingual side. 
                    •	Normal range of probing depth lies from 1 to 3 mm. A depth greater than 3 mm is a possible cause for concern.
                    •	Missing fields in probing depth indicates that the patient does not have a tooth at that position.
                     "
                  />
                </strong>
              </Grid>
              <Grid item xs={12} sm={10} md={11}>
                <Paragraph>
                  Please upload your own image or download a sample image from
                  the given link and upload
                </Paragraph>
              </Grid>
              <Grid item xs={10} sm={3} md={3}>
                <StyledButton variant="outlined" color="primary">
                  <a
                    href={clinicalnotespreprocessor_sample1}
                    download
                  >
                    Download Sample
                  </a>
                </StyledButton>
              </Grid>
              <Grid item xs={8} sm={5} md={3}>
                <Input
                  type="file"
                  color="primary"
                  name="uploadedFile"
                  onChange={fileSelectedHandler}
                />
              </Grid>
              {selectedFile && (
                <>
                  <Grid container>
                    <Grid item xs={4}>
                      <center>
                        <ZoomImage
                          width="60%"
                          src={URL.createObjectURL(selectedFile)}
                          alt=""
                        />
                      </center>
                    </Grid>
                    <Grid item xs={8}>
                      <Paragraph>
                        Please click on 'Execute' to generate a table containing
                        information regarding each tooth from the uploaded chart
                        with their respective Probing Depth values for both
                        Buccal and Lingual section
                      </Paragraph>
                      <Grid item xs={12} md={12}>
                        <StyledButton
                          id="btn2"
                          variant="contained"
                          color="primary"
                          helperText="Please select a csv file only"
                          onClick={getUploadResult}
                          disabled={btnEnable}
                        >
                          Execute
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </TabPanel>
          <br />
          <br />
          {showSpinner && (
            <Spinner text="Loading.. Please wait for a minute..." />
          )}
          {data?.result && (
            <>
              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="left"
              >
                <Grid item xs={12} >
                  <Paragraph>Please find below the probing depth details extracted from the periodontal chart in {imagename}</Paragraph>
                </Grid>
                <Grid item xs={12} sm={8} md={11}>
                  <TableContainers>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <HeaderCell rowspan={2} align="center">
                            <strong>Tooth Number</strong>
                          </HeaderCell>
                          <HeaderCell
                            rowspan={2}
                            align="center"
                            width={"300px"}
                          >
                            <strong>Tooth Name</strong>
                          </HeaderCell>
                          <HeaderCell
                            rowspan={2}
                            align="center"
                            width={"200px"}
                          >
                            <strong>Tooth Present</strong>
                          </HeaderCell>
                          <HeaderCell
                            colSpan={2}
                            align="center"
                            width={"200px"}
                          >
                            <strong>Probing Depth</strong>
                          </HeaderCell>
                          {/* <HeaderCell
                            rowspan={2}
                            align="center"
                            width={"70px"}
                          >
                            <LinkButton href={downloadLink} target="_blank" download>
                              <b> <FontAwesomeIcon icon={faDownload} />
                                &nbsp; Json</b>
                            </LinkButton>
                          </HeaderCell> */}
                        </TableRow>
                        <TableRow>
                          <HeaderCell align="center">
                            <strong>Buccal</strong>
                          </HeaderCell>
                          <HeaderCell align="center">
                            <strong>Lingual</strong>
                          </HeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(data?.result).map((key) => (
                          <TableRow>
                            <TableCell align="Center">{key}</TableCell>
                            <TableCell align="left">
                              {data?.result[key]?.Tooth_Name}
                            </TableCell>
                            <TableCell align="center">
                              <FontAwesomeIcon
                                color={
                                  data?.result[key]?.Tooth_Missing
                                    ? "#d13f48"
                                    : "#4caf50"
                                }
                                icon={
                                  data?.result[key]?.Tooth_Missing
                                    ? faTimes
                                    : faCheck
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              {data?.result[key]?.Buccal?.PD == null
                                ? "-"
                                : data?.result[key]?.Buccal?.PD}
                            </TableCell>
                            <TableCell align="center">
                              {data?.result[key]?.Lingual?.PD == null
                                ? "-"
                                : data?.result[key]?.Lingual?.PD}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainers>
                </Grid>

                {link && (
                  <>
                    <Grid item xs={12} sm={1} md={1}>
                      <LinkButton href={downloadLink} download>
                        <StyledButton color="primary" variant="contained">
                          <FontAwesomeIcon icon={faDownload} />Json
                        </StyledButton>
                      </LinkButton>
                    </Grid>
                  </>
                )}
                <br />
                {contactus && (
                  <>
                    <Grid item xs={12} sm={1} md={1}>
                      <LinkButton href={downloadLink} download>
                        <StyledButton color="primary" variant="contained">
                          <FontAwesomeIcon icon={faDownload} />Json
                        </StyledButton>
                      </LinkButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <h4>
                        To leverage this solution & extract data from your organisation specific periodontal charts
                        <StyledButton
                          variant="contained"
                          color="secondary"
                          onClick={openDialogContact}
                        >
                          Contact Us
                        </StyledButton>
                      </h4>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </DemoContainer>
      </Collapse>
      {dialogContact && (
        <ContactUsDialog open={openDialogContact} close={closeDialogContact} />
      )}
    </SolutionContainer >
  );
});

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

export default function PeriodontalChartDesc() {
  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Delays in claim resolution is among the top problems of claim
            processes. And many regions have enacted laws requiring dental
            insurance carriers to pay claims within a timely period (ranging
            generally from 15 to 60 days). So, to fulfil this requirement
            without affecting the efficiency is a challenge.
          </p>
          <p>
            Let’s look into the case of dental claims where periodontal disease
            is involved. Gum (or periodontal) disease is one of the most common
            and leading threats to dental health that often go under the radar.
            Almost half, 47.2%, of all adults aged 30 years and above have some
            form of this disease. It increases with age. 70.1% of adults aged 65
            years and older have periodontal disease. It is more common in men
            than women (56.4% vs 38.4%).
          </p>
          <p>
            Gum disease or periodontitis occurs when the gums, the tissues that
            hold your teeth in place, suffers from a serious infection due to
            the accumulation of plaque and bacteria. Advanced cases of the
            disease involve severe damage to the jaw bone and eventually lead to
            tooth loss.
          </p>
          <p>
            Periodontitis can be painless, so it may go undetected without
            preventative dental care but it does not happen right away. There
            are 4 stages involved in the development of the disease:
          </p>
          <List>
            <ListItem>
              <strong>Stage 1: Gingivitis –</strong> This is the first stage of
              the disease and is still reversible. It’s characterized by
              bleeding or swollen gums.
            </ListItem>
            <ListItem>
              <strong> Stage 2: Early Periodontitis –</strong> When gingivitis
              is left untreated, it advances into this stage. The infection has
              now reached the bones and is starting the deterioration process.
              While considered irreversible, it can still be managed with
              treatments.
            </ListItem>
            <ListItem>
              <strong>Stage 3: Moderate Periodontitis –</strong> At stage 3, the
              infection has reached the point where not only your bones are
              affected, but so are the bloodstream and immune system.
            </ListItem>
            <ListItem>
              <strong>Stage 4: Advanced Periodontitis –</strong> The final stage
              of the disease. This is characterized by rapid bone loss and teeth
              falling out of the gumline.
            </ListItem>
          </List>
          <center>
            <ZoomImage width="55%" src={perioDesimage} alt="" />
          </center>
          <p>
            <strong>Periodontal charting</strong> is a critical step in the
            early detection of gum disease. It is the process of measuring
            pockets – the space between a tooth and the gum tissue surrounding
            it. A dentist or dental hygienist uses an instrument known as a
            probe and carefully inserts it into this space. The probe has
            markings like a tape measure that indicates how deep it can reach
            into this space to check the health of your gums. Generally, the
            dentist takes six measurements per tooth to ensure that all areas of
            your mouth are reviewed. Alongside these measurements, they also
            check for bleeding of the gum tissue and areas of gum recession.
          </p>
          <p>
            Healthy gum tissue typically has pockets measuring 1-3 millimetres
            and fits snugly around the tooth. Measurements of 4 millimetres and
            deeper are concerning since it could mean that the bone that
            supports your tooth is being degraded by periodontal disease.
            Bleeding is also a sign of gingivitis and gum disease, as healthy
            gums do not tend to bleed.
          </p>
          <p>
            Pocket depths indicate how healthy your gums are, and the typical
            range of measurements include:
          </p>
          <List>
            <ListItem>
              <strong>0-3mm without bleeding:</strong> It means that there are
              no dental problems, and the person is doing great with his/her
              overall oral health!
            </ListItem>
            <ListItem>
              <strong>1-3mm with bleeding:</strong> Early signs of gingivitis.
              Improved at-home oral care as well as further professional
              cleaning is required.
            </ListItem>
            <ListItem>
              <strong>3-5mm with no bleeding:</strong> This is an indication
              that there is a potential for gum disease. Routine cleaning cannot
              go below 3mm, so further in-depth visits to the dentist will be
              needed.
            </ListItem>
            <ListItem>
              <strong>3-5mm with bleeding:</strong> This is an early stage of
              gum disease or the beginning of periodontitis. This may require
              additional treatment, better home care, and three to four visits
              to the dentist per year.
            </ListItem>
            <ListItem>
              <strong>5-7mm with bleeding:</strong> This is a sign of soft and
              hard tissue damage coupled with bone loss. Definitive treatment is
              required over multiple dental visits. Also need to improve home
              care and schedule many more hygiene visits to prevent tooth loss.
            </ListItem>
            <ListItem>
              <strong>7mm and above with bleeding:</strong> This is the advanced
              stage of periodontal disease and will require aggressive
              treatment. Surgery will probably be needed to repair the bone
              loss. Periodontal maintenance is definitely required very
              frequently.
            </ListItem>
          </List>
          <p>
            For dental claims, checking the periodontal charts manually to find
            the Pocket Depths takes a huge toll on the accuracy as well as the
            time taken to resolve the claim. So, we have provided the automation
            of this process of data extraction from periodontal charts for
            faster claims processes and better accuracy. Based on the content of
            the Periodontal chart extracted and the range of measurements where
            it falls, one can identify if any treatment, surgery is required or
            not. On that basis, the claims can be paid fully, partially or
            denied entirely.{" "}
          </p>
          <b>Basic understanding of the chart -</b>
          <ul>
            <li>
              <b>Probing/Pocket Depth</b> is the space measured (in mm) between
              a tooth and the gum tissue surrounding it.
            </li>
            <li>
              <b>Buccal</b> refers to the side of the teeth facing the cheek,
              and Lingual refers to the side facing the tongue.
            </li>
            <li>
              The upper half of the periodontal chart focuses on the teeth lying
              in the upper jaw, while the bottom half focuses on the teeth lying
              in the lower jaw.
            </li>
            <li>
              <b>Six</b> measurements of probing depth are taken per tooth;
              three in buccal side and three in lingual side.{" "}
            </li>
            <li>
              <b>Normal range of probing depth</b> lies from 1 to 3 mm. A depth
              greater than 3 mm is a possible cause for concern.
            </li>
            <li>
              Missing fields in probing depth indicates that the patient does
              not have a tooth at that position.
            </li>
          </ul>
          <center>
            <ZoomImage width="70%" src={perioDesimage2} alt="" />
          </center>
          <b>Conditions required for the provided input chart -</b>
          <ul>
            <li>
              Periodontal chart needs to be in an electronic format with printed
              digits for probing depth.
            </li>
            <li>
              The periodontal charts that are being passed to the application
              should have good image quality.
            </li>
            <li>
              The periodontal chart must always have the two halves as marked in
              the fig.
            </li>
            <li>
              The sequence supported in upper half must be of format Buccal
              –Lingual, and the sequence supported in lower half must be of
              format Lingual—Buccal
            </li>
            <li>
              It is necessary that the periodontal charts have the teeth
              numbering for each tooth. The sequence of the numbering of the
              teeth can start from any order.
            </li>
          </ul>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}></Grid>
        </LinkContainer>
      </Collapse>
    </SolutionContainer>
  );
}

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: #00afb9;
    padding: 2px;
    color: #ffff;
  }
`;
const TableContainers = styled.div`
  width: 100%;
  height: 450px;
  overflow: auto;
  //
`;

const LinkButton = styled.a`
  text-decoration: none;
  color: inherit;
`;
