import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// local components

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  Result,
  LinkContainer,
  HighchartContainer,
} from "../../../styles/common";

// images
import cancerLectin_sample1 from "../../../assets/images/app/cancerLectin_sample1.webp";

const CancerLectins = () => {
  const initialData = {
    input: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "patientEngagement",
    "cancerLectins",
  );

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: "The chance of given protien sequence has Cancer",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Probability",
          colorByPoint: true,
          data: [
            {
              name: "Yes",
              sliced: true,
              selected: true,
              y: data[1],
              color: "#bdef9e",
            },
            {
              name: "No",
              y: data[2],
              color: "#FF9999",
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      input: "MTHRRTAQGRRPRWLLSIISALLSAVLQTRAATGSASQVHLDLTVLIGVPLPSSVSFTTGYGGFPAYSFGPGANVGRPARTLIPPTFFRDFAIGVAVKPNSAQGGVLFAITDAFQKVIYLGLRLSSVEDGRQRVILYYTEPGSHVSREAAVFSVPVMTNRWNRFAVTVQGEEVALFMDCEEQSQVRFQRSSWPLTFEPSAGIFVGNAGAMGLERFTGSIQQLTIYSDPRTPEELCEAQESSASGEASGFQEMDEVAEIMEAVTYTQAPPKESHVDPISVPPTSSSPAEDSELSGEPVPEGTPETNLSIIGHSSPEQGSGEILNDTLEVHAMDGDPGTDDGSGDGALLNVTDGQGLSATATGEASVPVTTVLEAENGSMPTGSPTLAMFTQSIREVDTPDPENLTTTASGDGEVPTSTDGDTEADSSPTGGPTLKPREEATLGSHGEEWLTPAVSKMPLKAFEEEEASGTAIDSLDVIFTPTVVLEQVSRRPTDIQATFTPTVVLEETSGAPTDTQDALTPTVAPEQMFTAEPTDGGDLVASTEEAEEEGSGSMPPSGPPLPTPTVTPKRQVTLVGVEAEGSGPVGGLDEGSGSGDIVGNEDLLRGPPGPPGPPGSPGIPGKPGTDVFMGPPGSPGEDGAPGEPGPQGPEGQPGLDGASGQQGMKGEKGARGPNGSAGEKGDPGNRGLPGPPGKNGEVGTPGVMGPPGPPGPPGPPGPGCTTELGFEIEGSGDVRLLSKPTISGPTSPSGPKGEKGEQGAKGERGADGTSTMGPPGPRGPPGHVEVLSSSLINITNGSMNFSDIPELMGPPGPDGVPGLPGFPGPRGPKGDTGVPGFPGLKGEQGEKGEPGAILTGDVPLEMMKGRKGEPGIHGAPGPMGPKGPPGHKGEFGLPGRPGRPGLNGLKGAKGDRGVTLPGPPGLPGPPGPPGPPGAVVNIKGAVFPIPARPHCKTPVGTAHPGDPELVTFHGVKGEKGSWGLPGSKGEKGDQGAQGPPGPPVDPAYLRHFLNSLKGENEDASFRGESSNNLFVSGPPGLPGYPGLVGQKGEAVVGPQGPPGIPGLPGPPGFGRPGVPGPPGPPGPPGPPAILGAAVALPGPPGPPGQPGLPGSRNLVTALSDMGDMLQKAHLVIEGTFIYLRDSGEFFIRVRDGWKKLQLGELIPIPADSPPPPALSSNPYQPQPPLNPILSANYERPVLHLVALNTPVAGDIRADFQCFQQARAAGLLSTFRAFLSSHLQDLSTVVRKAERFGLPIVNLKGQVLFNNWDSIFSGDGGQFNTHIPIYSFDGRDVMTDPSWPQKVVWHGSNPHGVRLVDKYCEAWRTTDMAVTGFASPLSTGKILDQKAYSCANRLIVLCIENSFMTDTRK​",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      input: "MGRFIFVSFGLLVLFLSLSGTGAGLHCPSDWYYYDQHCYRIFNEEMNWEDAEWFCTKQAKGAHLVSIKSAKEADFVAWMVTQNIEESFSHVSIGLRVQNKEKQCSTKWSDGSSVSYDNLLDLYITKCSLLKKETGFRKWFVASCIGKIPFVCKFPPQC",
    };
    setSampleData(NewDataSet);
  };
  const getResult = () => {
    setResultData("");

    const {
      input,
    } = sampleData;

    const params = `${input}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          console.log(res);
          formHighChart(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Analytics Functions Used and approach followed
            <ul>
              <li>
                The unprocessed dataset it cleaned by using techniques like
                converting ordinal variables to categorical variables, removing
                of duplicate and null records
              </li>
              <li>
                Since the protein sequence is textual data, it has been
                tokenized and converted to a multidimensional array
              </li>
              <li>
                Deep Learning Neural Net LSTM model is used with Relu Activation
                function to predict the output
              </li>
              <li>
                The predicted values are scaled to follow normal distribution
              </li>
              <li>
                The probability distribution of each class is represented by a
                pie chart
              </li>
            </ul>
          </p>
          <p>
            The graph of cancerous cells vs non cancerous cells is shown below
          </p>
        </Paragraph>
        <center>
          <img height="250px" src={cancerLectin_sample1} alt="" />
        </center>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/umerrana/cancerlectins"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Cancer_lectin/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          <Grid xs={12} container direction="column" alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <TextFiels600
                id="outlined-adornment-weight"
                variant="outlined"
                name="input"
                value={sampleData.input}
                type="text"
                label="Input"
                required
                errorText="Input is required."
                fullWidth
              />
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
          <section>
            <Result>
              <strong>Result:</strong>
              {`${resultData[0]}`}
            </Result>
            <Grid container direction="row" justify="center">
              <Grid item xs={12}>
                <HighchartContainer id="container_1" display />
              </Grid>
            </Grid>
          </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(CancerLectins);

const TextFiels600 = styled(TextField)`
@media only screen and (min-width: 900px){
  width: 600px;
}
`;
