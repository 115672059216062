import React, { memo, useState } from "react";
import { Grid, FormControl, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import styled from "styled-components";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import predictOligomericState_sol1 from "../../../assets/images/app/predictOligomericState_sol1.webp";
import predictOligomericState_sol2 from "../../../assets/images/app/predictOligomericState_sol2.webp";

const PredictOligomericState = () => {
  const initialData = {
    acidSequence: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "bioInformatics",
    "predictOligomericState",
  );

  const showResult = () => {
    const NewDataSet = {
      acidSequence:
        "MRGSHHHHHHGSMSELITENMHMKLYMEGTVNNHHFKCTSEGEGKPYEGTQTMRIKVVEGGPLPFAFDILATSFMYGSYTFINHTQGIPDFFKQSFPEGFTWERVTTYEDGGVLTATQDTSLQDGCLIYNVKIRGVNFTSNGPVMQKKTLGWEAGTEMLYPADGGLEGRSDDALKLVGGGHLICNLKSTYRSKKPAKNLKVPGVYYVDRRLERIKEADKETYVEQHEVAVARYCDLPSKLGHKLN",
    };
    setSampleData(NewDataSet);
    getResult("OligomericState/");
  };
  const showResult2 = () => {
    const NewDataSet = {
      acidSequence:
        "MRGSHHHHHHGSRSSKNVIKEFMRFKVRMEGTVNGHEFEIEGEGEGRPYEGHNTVKLKVTKGGPLPFAWDILSPQFQYGSKVYVKHPADIPDYKKLSFPEGFKWERVMNFEDGGVVTVTQDSSLQDGCFIYKVKFIGVNFPSDGPVMQKKTMGWEASTERLYPRDGVLKGEIHKALKLKDGGHYLVEFKSIYMAKKPVQLPGYYYVDSKLDITSHNEDYTIVEQYERTEGRHHLF",
    };
    setSampleData(NewDataSet);
    getResult("OligomericState1/");
  };

  const getResult = (sample) => {
    setResultData("");
    const areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", sample)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <strong>Introduction</strong>
            : An oligomer usually is a
            macromolecular complex formed by non-covalent bonding of a few
            macromolecules like proteins or nucleic acids. In this sense, a
            homo-oligomer would be formed of a few identical molecules and by
            contrast, a hetero-oligomer would be made of more than one,
            different, macromolecules. Dimers, trimers, and tetramers are
            oligomers composed of two, three, and four monomers, respectively.
          </p>
          <p>
            Monomeric fluorescent protein (FPs) are ideal tools for fluorescent
            tagging in biomedical imaging whereas oligomeric FPs hinder their
            usage as tagging labels because of their tendencies to aggregate.
            The prediction of oligomeric states is helpful for enhancing live
            biomedical imaging. Computational prediction of FP oligomeric states
            can accelerate the effort of protein engineering efforts of creating
            monomeric FPs
          </p>
          <p>
            <strong>Dataset</strong>
            : A data set consisting of 409 FPs along
            with their oligomeric states were compiled. The size of the dataset
            is limited by the currently available experimental data on FP
            oligomerization. The oligomeric state has 3 classes:
            <ul>
              <li>Monomers</li>
              <li>Dimers</li>
              <li>Tetramers</li>
            </ul>
          </p>
          <p>
            Various Machine learning methods were used to construct predictive
            models for predicting the protein oligomeric state. The models used
            were:
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={2} alignItems="center">
                <ul>
                  <li>1D CNN</li>
                  <li>ROBERTa</li>
                  <li>BERT</li>
                  <li>XLM</li>
                  <li>DistilBERT</li>
                </ul>
              </Grid>
              <Grid xs={8}>
                <Image
                  width="50%"
                  src={predictOligomericState_sol1}
                />
              </Grid>
            </Grid>
            <br />
          </p>
          <p>Model with best Accuracy was considered to be the best model.</p>
          <center>
            <img width="40%" height="300px" src={predictOligomericState_sol2} />
          </center>
          <p>
            Based on the above bar graph, 1D CNN was considered to be final
            model.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/chaninnantasenamat/fluorescent-protein-oligomeric-state"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Oligomeric/Oligomeric.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebooks
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <p>
            Please input below the Amino Acid Sequence for which the Oligomeric
            State has to be predicted:
          </p>
          <PaddedGrid
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid xs={12} alignItems="center">
              <Width50>
                <LeftText
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Amino Acid Sequence"
                  value={sampleData.acidSequence}
                  onChange={onChangeFields}
                />
              </Width50>
              <br />
              <br />
            </Grid>
          </PaddedGrid>
          <section>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample 2
            </StyledButton>
          </section>
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${resultData}`}
              </Result>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictOligomericState);

const Width50 = styled(FormControl)`
  width: 50%;
`;
const LeftText = styled(TextField)`
  text-align: left;
`;

const Image = styled.img`
  @media only screen and (max-width: 700px) {
    padding-left: 20%;
  }
  margin-top: 20px;
`;
const PaddedGrid = styled(Grid)`
  @media only screen and (max-width: 400px) {
    padding-right: 10%;
  }
`;
