import getUserData from '../../../../utils';
import CURRENT_ENV_ENDPOINTS from '../../../../utils/services/api-config';
import { wrappedAxios } from '../../../../utils/services/CommonService';

const { stepFunCall } = CURRENT_ENV_ENDPOINTS.vnet;
const flaskUrl = CURRENT_ENV_ENDPOINTS.vnet.flask;
export const postFlaskReq = (params, endpoint) => {
  let token = getUserData().token?.id_token;
  const config = {
    method: "POST",
    url: `${flaskUrl}${endpoint}`,
    data: params,
    // headers: {'auth-token': token}
    headers: {'x-auth-origin': 'vlife'}
  };

  return wrappedAxios(config);
};
export const getResult = (url) => {
  let token = getUserData().token?.id_token;
  const config = {
    method: "GET",
    url: url,
    Accept: "application/json",
    "Content-Type": "application/json",
    // headers: {'auth-token': token}
  };

  return wrappedAxios(config);
};
const postReq = async (params) => {
  let token = getUserData().token?.id_token;
  const config = {
    method: "POST",
    url: stepFunCall,
    data: params,
    // headers: {'auth-token': token}
    headers: {'x-auth-origin': 'vlife'}
  };
  try {
    const lamdaRes = await wrappedAxios(config);
    const albConfig = {
      method: "POST",
      url: CURRENT_ENV_ENDPOINTS.vnet.stepFunResponse,
      data: lamdaRes,
      // headers: {'auth-token': token}
      headers: {'x-auth-origin': 'vlife'}
    };
    return wrappedAxios(albConfig);
  } catch (err) {
    throw Error(err);
  }
};
export default postReq;