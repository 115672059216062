const styles = (theme) => ({
  body: {
    backgroundColor: '#f2f2f2',
    width: '100%',
    height: '100%',
    fontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
    color: '#333',
  },
  width80: {
    width: '80%',
  },
  mainContainer: {
    padding: '5px 15px',
    flex: '1',
    overflow: 'auto',
  },
  paper: {
    width: '100%',
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    fontweight: '20px',
    marginBottom: '10px',
  },
  subHeader: {
    margin: '10px 0px 10px',
  },
  subSection: {
    padding: '10px',
  },
  componentHeader: {
    height: '40px',
    padding: '6px',
    backgroundColor: '#5e64ba',
    fontSize: '16px',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  linkButton: {
    textDecoration: 'none',
    color: 'inherit',
  },
  componentPadding: {
    padding: '25px',
  },
  loading: {
    height: '50px',
    borderLeft: '4px solid #2ed18a',
    color: '#5f615c',
    paddingLeft: '5px',
    marginBottom: '5px',
  },
  loadingContent: {
    margin: '20px auto',
  },
  textCenter: {
    textAlign: 'center',
  },
  btn: {
    margin: '5px',
    textDecoration: 'none',
  },
  addIcon: {
    cursor: 'pointer',
  },
  width50: {
    width: '50%',
  },
  jobsList: {
    padding: '10px',
    borderLeft: '3px solid #3aba82',
    cursor: 'pointer',
    '&:active': {
      transform: 'translateY(4px)',
    },
  },
  dbCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'Pointer',
    flexWrap: 'wrap',
    borderLeft: '3px solid #3aba82',
  },
  dbCardImage: {
    width: '50px',
    height: '50px',
    margin: 10,
    borderRadius: '5px',
  },
  textMargin: {
    margin: '10px 0 10px 0',
  },
  sessionCard: {
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'center',
    borderLeft: '3px solid #3aba82',
    padding: '10px',
    cursor: 'pointer',
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
  },
  root: {
    color: 'white',
  },
  table: {
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid white',
    width: '100%',
    float: 'left',
    verticalAlign: 'middle',
    textAlign: 'center',
    borderCollapse: 'collapse',
  },
  scrollableTable: {
    overflowX: 'scroll',
    maxHeight: '500px',
  },
  tableHeader: {
    backgroundColor: '#4f4b4a',
    color: 'white',
    align: 'left',
  },
  homeIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '6px',
  },
  link: {
    color: '#2f3542',
    '&:hover': {
      color: ' #3aba82',
    },
  },
  breadcrumb: {
    margin: '10px 20px 20px 10px',
  },
  icon: {
    fontSize: '19px',
    color: '#2f3542',
  },
  code: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    color: '#d3dded',
    background: '#282c34',
    height: '400px',
  },
  fetchRecords: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    color: '#d3dded',
    background: '#282c34',
    height: '35vh',
    width: '100%',
  },
  sqlEditor: {
    padding: '0.5em',
    color: '#d3dded',
    background: '#282c34',
    width: '100%',
  },
  text: {
    fontSize: '16px',
  },
  customData: {
    width: '100%',
    backgroundColor: '#dae0eb',
    padding: '10px',
    wordWrap: 'break-word',
  },
});
export default styles;
