import React, { memo, useState } from "react";
import { Grid, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

// import local components

import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images

const NeptuneBotGraph = () => {
  const [radiovalue, setRadiovalue] = useState();
  const [title, setTitle] = useState('');
  const description={
    DrugDataSearch : <p>Predicting probable drug-drug interactions (DDIs) based on a literature-KG graph.
    Our model is based on extracting the semantic paths that connect two drug nodes in a KG.
    The KG is disease-specific and is created by harvesting and analyzing biomedical literature and structured databases</p>,
    LungCancer: <p>Dataset are created by harvesting related publications and the aforementioned structured DBs.
    Here, we show how Cell Functions which are disrupted by Organic Chemical components and Pharmacologic Substances which disrupts Cell Function via KG Models.</p>,
    AwsBlogSearch: <p>we will be using Python and Beautiful Soup to scrape the AWS Database Blog site to generate and store semi-structured data which will be stored in our knowledge graph which is powered by Amazon Neptune. After we ingest this data we will enhance and curate our semi-structured data by using Python to call Amazon Comprehend to extract named entities from the blog post text. We will then connect these extracted entities within our knowledge graph to provide more contextually relevant connections within our blog data.</p>
  }
  const handleRadioChange = (e) => {
    setRadiovalue(e.target.value);
    let title='';
    switch(e.target.value){
      case "DrugDataSearch":
        title='Drug Data Search Knowledgebase';
        break;
      case "LungCancer":
        title='Lung Cancer Knowledgebase';
        break;
      case "AwsBlogSearch":
        title='AWS Blog Knowledgebase';
        break;
    }
    setTitle(title)
  };
  return (
    <SolutionContainer>
      <Collapse text="Description">
          <Paragraph>
            <p>
              Knowledge graph applications are one of the most popular graph use
              cases being built on Amazon Neptune today. knowledge graph backed
              application with NLP and Natural Language search integration using
              Amazon Neptune, Amazon Comprehend, and Amazon Lex. Knowledge
              graphs consolidate and integrate an organization’s information
              into a single location by relating data stored from structured
              systems (e.g., e-commerce, sales records, CRM systems) and
              unstructured systems (e.g., text documents, email, news articles)
              together in a way that makes it readily available to users and
              applications.
            </p>
            <p>
              In reality, data rarely exists in a format that allows us to
              easily extract and connect relevant elements. Finally, we will
              wrap up our architecture by creating a React-based smart
              application, powered by Amazon Lex and web-based chatbot interface
              which will exploit the connected nature of the data within our
              knowledge graph to provide contextually relevant answers to
              questions asked
            </p>
          </Paragraph>
        <LinkContainer></LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <Grid item xs={12} md={12}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            value={radiovalue}
            onChange={handleRadioChange}
            row
          >
            <FormControlLabel
              value="DrugDataSearch"
              control={<Radio />}
              label="Drug Data Search"
            />
            <FormControlLabel
              value="LungCancer"
              control={<Radio />}
              label="Lung Cancer"
            />
            <FormControlLabel
              value="AwsBlogSearch"
              control={<Radio />}
              label="AWS Blog"
            />
          </RadioGroup>
        </Grid>
        {radiovalue && (<>
          {description[radiovalue]}
          <Grid item xs={12} md={12}>
            <center>
              <StyledButton
                color="primary"
                variant="contained"
                onClick={() => {
                  window.open(
                    `https://apps.vlifevirtusa.com:7000/session?tag=${radiovalue}&wrapper=https://apps.vlifevirtusa.com:8010/Lex/chatbot&title=${title}`,
                    "_blank"
                  );
                }}
              >
                Launch ChatBot
              </StyledButton>
            </center>
          </Grid>
        </>
        )}
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(NeptuneBotGraph);
