import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, List, Tab, Paper, Tabs, ListItemIcon, ListItem, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {
  faExternalLinkAlt,
  faHome,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import getUserData from '../../../utils';

const drawerWidth = 220;
const userData = getUserData();
const role = userData?.token.privilege;

const useStyles = makeStyles((theme) => ({
  root1: {
    display: 'flex',
    backgroundColor: '#1a237e',
    zIndex: -1
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    justifyContent: 'space-between',
    zIndex: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icon: {
    fontSize: '19px',
    color: '#2f3542',
  },
  explore: {
    margin: 'auto',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 10,
    width: '100%'
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  text: {
    color: '#002699',
  },
  btn: {
    margin: '10px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    minWidth: 'max-content',
    minHeight: '100vh'
  },
}));

export function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export function TabPanel(props) {
  const { children, value, index, width = '', ...other } = props;

  return (
    <StyledBox
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </StyledBox>
  );
}

export default function ClippedDrawer(
  { handleClick, selectedComponent },
  props
) {
  const [toggle, setToggle] = useState(false);
  const [tabVal, setTabVal] = useState(0);

  const classes = useStyles();


  const toggleTab = (event) => {
    setToggle(!toggle);
  };

  const handleTabChange = (event, newValue) => {
    setTabVal(newValue);
    handleClick(event.currentTarget.id);
  };

  return (
    <DrawerContainer >
      <List id='list'>
        <ResultContainer toggle={toggle}>
          <Container className={classes.root} toggle={toggle}>
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={tabVal}
              onChange={handleTabChange}
              aria-label='Vertical StyledTabs example'
            >
              <Tab
                label={
                  <div
                    className='tabLabel'
                    style={{
                      marginRight: 'auto',
                      maxWidth: 'max-content'
                    }}
                  >
                    <FontAwesomeIcon icon={faHome} />
                    <span className='tabText'>Home</span>
                  </div>
                }
                id='Home'
              />
              <Tab
                label={
                  <div
                    className='tabLabel'
                    style={{
                      marginRight: 'auto',
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <span className='tabText'>Project</span>
                  </div>
                }
                id='Project'
              />
              <div className={classes.explore}>
                <Button
                  component={Link}
                  to='/'
                  className='tabLabel'
                  variant='outlined'
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                  <span className='tabText'>Explore vLife </span>
                </Button>
                <Button
                  component={Link}
                  to='/vNet'
                  target='_blank'
                  variant='outlined'
                  className='tabLabel'
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                  <span className='tabText'>Explore vNet</span>
                </Button>
              </div>
            </Tabs>

            <div onClick={toggleTab} className='slider'>
              <FontAwesomeIcon
                icon={toggle ? faAngleDoubleRight : faAngleDoubleLeft}
              />
            </div>
          </Container>

        </ResultContainer>
      </List>

      {/* </div> */}
    </DrawerContainer>
  );
}
const DrawerContainer = styled.div`
  #list{
    height: 100%;
    padding-bottom: 0;
  }
`;
const ResultContainer = styled(Paper)`
&.MuiPaper-root {
  box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  min-height: 88vh;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 0;
  width: max-content;
  height: inherit;
  hr {
    margin: 10px 0;
  }
}

`;
export const Toggle = styled.section`
    color: #ffff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
    #content {
      background: #053354;
      height: 100%;
      min-width: 200px;
      display: ${(props) => (props.in ? 'block' : 'none')}  
    }
    #icon {
      background: #053354;
      color: #ffff;
      min-width: 1rem;
      padding: 5px;
      border-radius: 0 50% 50% 0;
      position: sticky;
      top: 40px;
    }
  }`;
const Container = styled.div`
  transition: 1s all ease-out;
  .tabText {
    text-transform: none;
    //display: ${(props) => (props.toggle ? 'inline-block' : 'none')};
    margin-left: 15px;
  }
  &.MuiListItem-root{
    padding: 10px;
    .MuiListItemIcon-root{
      padding: 5px;
    }
  }
  
  .slider {
    padding: 8px;
    background: #1d3557;
    border-radius: 0 5px 5px 0;
    color: #ffff;
    cursor: pointer;
  }
  .Mui-selected {
    align-items: left;
    color: white;
    background: #053354;
    background: transparent linear-gradient(180deg, #053354 0, #006aa5 100%) 0 0
      no-repeat padding-box;
  }
  .PrivateTabIndicator-colorSecondary-10 {
    background: #f07167;
    width: 6px;
  }
  .MuiTabs-vertical {
    min-height: 88vh;
    overflow-x: ${(props) => (props.toggle ? 'hidden' : '')};
    width: ${(props) => (props.toggle ? '40px' : '170px')};
    .tabText {
      text-transform: none;
    }
  }
  .tabLabel {
    width: 100%;
    text-align: left;
    .MuiButton-label{
      justify-content: left;
    }
  }
  .MuiTab-root {
    //min-width: max-content;
    //@media only screen and (min-width: 600px) {
    //  max-width: max-content;
    //}
  }
`;

const StyledBox = styled.div`
  padding: 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  .MuiTypography-body1,
  .MuiTableCell-root {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 600px) {
    overflow: auto;
  }
`;
