import React from "react";
import { Collapse, ListItem, ListItemText } from "@material-ui/core";
import styled from "styled-components";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Paragraph, StyledButton } from "../../styles/common";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

export default function SolutionCollapse({ children, number, text, link }) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <StyledH3 onClick={handleClick}>
                <strong>{number} - </strong>{text}
                <StyleButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                >
                    <Link
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Record
                    </Link>
                </StyleButton>
            </StyledH3>
            <Collapse in={!open} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </div>
    );
}

export function ListCollapse({ listText, children }) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem onClick={handleClick}>
                <ListItemText primary={listText} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </>
    );
}

export const StyledH3 = styled(Paragraph)`
font-size: 16px;
  background: rgb(224, 224, 224);
  padding: 0.388889rem 0.555556rem 0.277778rem 0.777778rem;
  border-left: 0.222222rem solid #2941b7;
  border-radius: 0.166667rem;
  cursor: pointer;
  margin: 0 0 2px 0;
`;

const Link = styled.a`
backgroundColor: #2941b7;
color: #2941b7;
`;

const StyleButton = styled(StyledButton)`
padding: 5px;
`;
