import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Grid, TextField, MenuItem, Button } from '@material-ui/core';
import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import {
  Paragraph,
  DemoContainer,
  LinkContainer,
} from '../../../styles/common';
import SolutionContainer from '../../common/SolutionContainerWrapper';

// images
import videometadata from '../../../assets/images/app/videometadata.png';

const VideoMetadataExtraction = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  //const [shown, setShown] = useState(false);

  const handleChange = (e) => {
    setSelectedItem(e.target.value);
  };

  const openIframe = (url) => {
    //setShown(true);
    window.open(url, '_blank', 'noopener,noreferrer');
   
  };


  const iframeData = {
    'Diabetes and COVID-19':
      'https://material.vlifevirtusa.com/video-metadata/key_words_diabetes_graph.html',
    'Pregnancy and COVID-19':
      'https://material.vlifevirtusa.com/video-metadata/key_words_pregnancy_graph.html',
    'COVID-19 and Polio':
      'https://material.vlifevirtusa.com/video-metadata/categories_key_terms_polio_graph.html',
    'Covid and Air Pollution':
      'https://material.vlifevirtusa.com/video-metadata/labels_air_pollution_graph.html',
    'Tuberculosis and COVID-19':
      'https://material.vlifevirtusa.com/video-metadata/key_words_tuberculosis_graph.html',
    relationships_polio_antibiotics:
      'https://material.vlifevirtusa.com/video-metadata/relationships_polio_antibiotics_graph.html',
    topic_term_video:
      'https://material.vlifevirtusa.com/video-metadata/topic_term_video_graph.html',
    key_words_labels:
      'https://material.vlifevirtusa.com/video-metadata/key_words_labels_graph.html',
    categories_key_terms:
      'https://material.vlifevirtusa.com/video-metadata/categories_key_terms_graph.html',
    common_two_topics:
      'https://material.vlifevirtusa.com/video-metadata/common_two_topics_graph.html',
    treatment_term:
      'https://material.vlifevirtusa.com/video-metadata/treatment_term_graph.html',
    entities_topics_covid_tuberculosis:
      'https://material.vlifevirtusa.com/video-metadata/entities_topics_covid_tuberculosis_graph.html',
    word_segment_tuberculosis:
      'https://material.vlifevirtusa.com/video-metadata/word_segment_tuberculosis_graph.html',
    sars: 'https://material.vlifevirtusa.com/video-metadata/sars_graph.html',
    knowledge_graph:
      'https://material.vlifevirtusa.com/video-metadata/knowledge_graph.html',
  };
  const dropdownList = [
    {
      label:
        'Key words that relate to a topic discussed during a 60 second scene of the Diabetes and COVID-19 Video',
      value: 'Diabetes and COVID-19',
    },
    {
      label:
        'Key word or phrase and their respective labels mentioned in each 60 second scene of the Pregnancy and COVID-19 Video',
      value: 'Pregnancy and COVID-19',
    },
    {
      label:
        'Main categories discussed and what are the key terms mentioned in each category of the COVID-19 and Polio Video',
      value: 'COVID-19 and Polio',
    },
    {
      label: 'Labels associated with the Covid and Air Pollution Video',
      value: 'Covid and Air Pollution',
    },
    {
      label:
        'Key words or phrases present in the video with their respective labels during a 60 second scene in the Tuberculosis and COVID-19 Video',
      value: 'Tuberculosis and COVID-19',
    },
    {
      label:
        'Relationships between various Entities and Topics present in "Covid and polio" and "Covid and Antibiotics" Videos',
      value: 'relationships_polio_antibiotics',
    },
    {
      label:
        'Which videos share the same topic and what are the terms associated with that topic',
      value: 'topic_term_video',
    },
    {
      label:
        'Key words and their respective labels which exist in certain segments of Multiple Videos',
      value: 'key_words_labels',
    },
    {
      label:
        'Categories that exist among multiple videos and the key terms within each of those categories',
      value: 'categories_key_terms',
    },
    {
      label:
        'Two topics that multiple video segments share and the key terms related to those topics',
      value: 'common_two_topics',
    },
    {
      label:
        'Topics that are related to the word ‘treatment’ and what segments of each video mention those topics',
      value: 'treatment_term',
    },
    {
      label:
        'Entities and topics that are discussed during a 60 second scene of the Tuberculosis and COVID –19 Video',
      value: 'entities_topics_covid_tuberculosis',
    },
    {
      label:
        'Key Words, Video Segments, and Topics that are Related to the word Tuberculosis',
      value: 'word_segment_tuberculosis',
    },
    {
      label:
        'Key Words, Video Segments, and Topics that are Related to the SARS Virus',
      value: 'sars',
    },
    {
      label:
        'Knowledge Graph Portraying all Nodes and Relationships between 11 Videos',
      value: 'knowledge_graph',
    },
  ];

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <LinkContainer>
            <section>
              <p>
                This solution demonstrates AWS AI Services like Amazon
                Rekognition, Amazon Transcribe and Amazon Comprehend can help
                href extract valuable metadata from video assets and store that
                information in Amazon Neptune Graph database for maximum query
                performance and flexibility. In this solution we will be able
                href search for a specific label or entity and return a list of
                1min video segments related across the videos.
              </p>
              <p>
                To extract metadata from a video, we'll use the following AWS AI
                services
              </p>
              <ul>
                <li>
                  Amazon Rekognition href cut the video in scenes and detect
                  label from the video itself
                </li>
                <li>Amazon Transcribe href convert audio into text</li>
                <li>
                  Amazon Comprehend href extract entities and topics from the
                  transcribed text via Topic Modelling and Named Entity
                  recognition
                </li>
              </ul>
              <p>
                The metadata related href the video, segments, scenes, entities,
                labels will be stored in Amazon Neptune. Amazon Neptune is a
                fully managed low latency graph database service that will allow
                us href store metadata as nodes (aka vertices) and branches (aka
                edges) href represent relationships between the nodes.
              </p>

              <center>
                {' '}
                <img src={videometadata} width="100%" alt="" />
              </center>
            </section>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Videos that are used for metadata extraction">
        <br />
        <DemoContainer>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={4}
          >
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DR3Q5ETOm33M%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D20&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=lY%2F5esURw06RZ3s3pf7w27%2BYkZZRetSc58N6sO8YnF8%3D&reserved=0"
            >
              {' '}
              Covid & Antibiotics{' '}
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dt6OYU8WH9Ww%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D59&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=dag%2BVf1urcn6qitfGYcXhSOph9EUlaQJKD9Z2dW7lcw%3D&reserved=0"
            >
              Children & Covid{' '}
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DfB6n1bsd8Og%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D19&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=TqqNnkfztdrGdpmcoMNtWGsODEu6lpEBa0SIWJAeKg4%3D&reserved=0"
            >
              Covid & Polio{' '}
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DceBgCLG-QbY%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D22&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=a8jqQ3nSWSwFsFy3yeNg6c8aMnGfPfFEDy1Jtjc5TUc%3D&reserved=0"
            >
              Covid & Flu
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWxrNDLbFGUA%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D23&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=CmrFzWX4Pgv4y02BQRVSlFN0L8WCwG7UIbdZ5H89Gdg%3D&reserved=0"
            >
              Covid & TB
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DwZXx4CCL0nY%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D29&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=WruP31UA9Lk1jH7TGELWUJzN4002LGQKqADOOebO8AE%3D&reserved=0"
            >
              Covid & Tobacco
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DIRuKkTOH6Zo%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D25&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=Xve2PIDymrRVGGoUHWyHQdoRpro8AsPQSuSzt17TDvk%3D&reserved=0"
            >
              Covid & Air Pollution
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dw9sxA1rJoFY%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D30&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=evCWW%2BAX7fej5toBYfr%2Bss5dPGCnprdR1VIK7HqK%2B%2F4%3D&reserved=0"
            >
              Breast Feeding & Covid
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DtMRuU1lWnRI%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D33&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=GlB8W3BKFZYyvOmqCAF%2FvLsCCaK34lLeh3%2F6BSt4vps%3D&reserved=0"
            >
              HIV & Covid
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DdwxfRDSOgIs%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D35&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=mQE0Qixyk5c4v86%2B3twlbuv91b5qhEc2OWROUM5P97s%3D&reserved=0"
            >
              Diabetes & Covid
            </a>
            <a
              target="_blank"
              href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqA6-wL2lcUw%26list%3DPL9S6xGsoqIBXRQzSDOfFb13iPrbL8fgy1%26index%3D38&data=05%7C01%7Cmgopalakrishnan%40virtusa.com%7C6ebb48a005b0410c886808da7b104e50%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C637957607231765381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=6s9eRld1nWh4Ox5i5NFzd1o6aeVej828C7YvUImvcJY%3D&reserved=0"
            >
              Pregnancy & Covid
            </a>
          </Grid>
        </DemoContainer>
      </Collapse>
      <Collapse text="Insights">
        <br />
        <DemoContainer>
          <Grid container alignContent="center" spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={10}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Select Insight"
                fullWidth
                select
                value={selectedItem}
                onChange={handleChange}
              >
                {dropdownList.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={1} md={2}>
              <Button variant="contained" color="primary" onClick={() => openIframe(iframeData?.[selectedItem])}>
                View Insight
              </Button>
            </Grid>
          </Grid>

          <br />
          {/* {shown && (
            <Grid item xs={12}>
              <center>
                <iframe
                  id="iFrame"
                  name="myFrame"
                  src={iframeData?.[selectedItem]}
                  width="100%"
                  height="650"
                />
              </center>
            </Grid>
          )} */}

          {showSpinner && <Spinner text="Loading..." />}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(VideoMetadataExtraction);
