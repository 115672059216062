import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";

const ACLTearPrediction = () => {
  const [response, setResponse] = useState(false);
  const [image, setImage] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("z1dApps", "aclTear");

  const getSampleImgResult = (sample) => {
    setResponse(false);
    setSpinner(true);

    getQueryResult("output", sample)
      .then((res) => {
        setSpinner(false);
        setResponse(res[0]);
        setImage(res[1]);
        console.log(res[0]);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The Anterior Cruciate Ligament (ACL) is one of the key ligaments
            that help stabilize the knee joints. The ACL connects the thighbone
            (femur) to the shinbone (tibia). It's most commonly torn during
            sports that involve sudden stops and changes in direction — such as
            basketball, soccer, tennis and volleyball.
            <br />
            <br />
            This Application is primarily focused on Anterior Cruciate Ligament
            (ACL) tears which are the most common knee injuries among top
            athletes in soccer or basketball. ACL tears happen when the anterior
            cruciate ligament is either stretched, partially torn, or completely
            torn. The most common injury is a complete tear. Symptoms include
            pain, a popping sound during injury, instability of the knee, and
            joint swelling. There are around 200,000 ACL tears each year in the
            United States, with over 100,000 ACL reconstruction surgeries per
            year.
            <br />
            <br />
            This Application first identifies the location of ACL in Knee MRI
            Images and plots the ROI(Region Of Interest). Later, the model
            predicts if there is a ACL tear present or not in the MRI Image.
            CNN(Convolutional Neural Network) model has been trained on huge
            dataset of Knee MRI Images with ACL tear.
          </p>
          <p>
            <LinkContainer>
              <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/sohaibanwaar1203/kneemridataset"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://material.vlifevirtusa.com/ACL-MRI/KneeMRI.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Notebook
                    </a>
                  </StyledButton>
                </Grid>

              </Grid>
            </LinkContainer>
          </p>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={12} sm={2} alignItems="center">
                <Image
                  src="https://vlife.virtusa.com/ACL_MRI/Non_Rup.png"
                  width="150px"
                />
              </Grid>
              <Grid xs={12} sm={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getSampleImgResult.bind(this, "sample1/")}
                >
                  Sample 1
                </StyledButton>
              </Grid>
              <Grid xs={12} sm={1}>
                <h3>OR</h3>
              </Grid>
              <Grid xs={12} sm={2} alignItems="center">
                <Image
                  src="https://vlife.virtusa.com/ACL_MRI/sample_2.png"
                  width="150px"
                />
              </Grid>
              <Grid xs={12} sm={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getSampleImgResult.bind(this, "sample2/")}
                >
                  Sample 2
                </StyledButton>
              </Grid>
            </Grid>
          </section>
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {response && (
            <section>
              <Result>
                <strong>Result:</strong>
                {response}
              </Result>
              <Image src={image} width="300px" alt="" />
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ACLTearPrediction);

const Image = styled.img`
  @media only screen and (max-width: 1100px) {
    max-width: 130px;
    max-height: 130px;
  }
  `;
