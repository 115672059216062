import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import { StyledFormControl } from "../Dataviz/ScatterPlot";
import { List, ListItem } from "../../../../../../styles/common";
import { validateForm, isObjKeyhasval } from "../../util";
import { subFeatureData } from "../../constants";
import InfoToolTip from "../../components/InfoToolTip";
import { StyledInput, FormControlLabelInfo } from '../../InputForm';
import { CommonCheckbox, LRContainer } from "./LogisticRegression";

export const AutoMLForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector(
    (state) => state.vnet,
  );
  const { initialData = null } = subFeatureData.Logistic_Regression;

  const [isDisabled, setInputDisable] = useState("Default");
  const [dropdownList, setdropdownList] = useState(null);
  const [disableModelInfo, setDisableModelInfo] = useState(isCanvasClicked);

  const [isEditable, setIsEditable] = useState(false);
  const [fieldError, setFieldError] = useState(false);


  const validationData = {
    test_size: { maxNum: 1, minNum: 0, onlyNum: true },
    random_state: { minNum: 0, onlyNum: true },
  };

  const handleFieldError = (errData) => {
    setFieldError({
      ...fieldError,
      ...errData,
    });
  };

  const { validateOnBlur } = validateForm(validationData, handleFieldError);
  const dispatch = useDispatch();

  const getCheckboxVal = (checked) => (checked ? "True" : "False");

  const handleOnChange = (e) => {
    console.log(e);
    const { value, name, checked = false } = e.target;
    const dataId = e.currentTarget.dataset.id;

    switch (dataId) {
      case "model_detail":
        dispatch(
          setInputData({
            ...userInputData,
            ModelDetail: {
              ...userInputData.ModelDetail,
              [name]: value,
            },
          }),
        );
        break;
      case "target":
        dispatch(
          setInputData({
            ...userInputData,
            target: value,
          }),
        );
        break;
      case "split_param":
        dispatch(
          setInputData({
            ...userInputData,
            split_param: {
              ...userInputData.split_param,
              [name]:
                ["shuffle"].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        break;
      case "isEditable":
        if (checked) {
          if (
            confirm(
              `Editing the existing model details will create a new model run and version. Do you want to continue ?`,
            )
          ) {
            setDisableModelInfo(false);
            setIsEditable(checked);
          }
        } else {
          setDisableModelInfo(true);
          setIsEditable(checked);
        }
        break;
      default:
        break;
    }
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        columns: typeof value === "string" ? value.split(",") : value,
      }),
    );
    if (fieldError.columns) {
      setFieldError({ ...fieldError, columns: false });
    }
  };

  const handleFormReset = () => {
    dispatch(
      setInputData({
        ...initialData,
      }),
    );
  };

  const isFormValid = () => {
    const errorObj = {};
    const validateObj = {
      target: userInputData?.target,
      Name: userInputData?.ModelDetail?.Name,
      Description: userInputData?.ModelDetail?.Description,
    };
    Object.keys(validateObj).map((key) => {
      if (!validateObj[key]) {
        errorObj[key] = "Required Field";
      }
    });
    if (userInputData?.columns.length == 0) {
      errorObj.columns = "Required Field";
    }
    const errData = { ...fieldError, ...errorObj };
    setFieldError(errData);

    return isObjKeyhasval(errData);
  };

  useEffect(async () => {
    const dropdownList = await props.getCategories();
    if (dropdownList) {
      setdropdownList(JSON.parse(dropdownList));
    }
  }, []);

  return (
    <LRContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Name"
            label="Name"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Name}
            inputProps={{ "data-id": "model_detail" }}
            error={fieldError.Name}
            helperText={fieldError.Name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Description"
            label="Description"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Description}
            inputProps={{ "data-id": "model_detail" }}
            error={fieldError.Description}
            helperText={fieldError.Description}
          />
        </Grid>
      </Grid>
      {isCanvasClicked && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={isEditable}
              onChange={handleOnChange}
              inputProps={{ "data-id": "isEditable" }}
            />
          )}
          label="Edit Model Info"
        />
      )}
      <br />
      <strong>
        <InfoToolTip title="Customize the train test split">
          Split Parameters
        </InfoToolTip>
      </strong>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="test_size"
            label="Test Size"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "split_param" }}
            value={userInputData?.split_param?.test_size}
            onBlur={validateOnBlur}
            error={fieldError.test_size}
            helperText={fieldError.test_size}
            info="Proportion of the dataset to be included in the test split."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="random_state"
            label="Random State"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "split_param" }}
            value={userInputData?.split_param?.random_state}
            onBlur={validateOnBlur}
            error={fieldError.random_state}
            helperText={fieldError.random_state}
            info="Specify a value to set the random state seed value. Using an integer will produce the same results across different calls "
         />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            control={(
              <CommonCheckbox
                disabled={isDisabled == "Default"}
                checked={userInputData?.split_param?.shuffle}
                name="shuffle"
                onChange={handleOnChange}
                inputProps={{ "data-id": "split_param" }}
              />
            )}
            label="Shuffle"
            info="Shuffle the data before splitting or not."
          />
        </Grid>
      </Grid>


      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <StyledFormControl error={fieldError.columns}>
            <InputLabel id="model-evaluation">Train Features</InputLabel>
            <Select
              multiple
              variant="outlined"
              name="features"
              disabled={disableModelInfo}
              value={userInputData?.columns || []}
              onChange={handleMultiple}
              input={<Input />}
              fullWidth
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              renderValue={(selected) => selected.join(", ")}
            // inputProps={{ 'data-id': "columns" }}
            >
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={userInputData?.columns?.indexOf(value) > -1}
                    disabled={
                      userInputData?.columns?.indexOf(userInputData?.target) > -1
                    }
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {fieldError.columns && <FormHelperText>Required Field</FormHelperText>}
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledInput
            name="target"
            label="Target"
            variant="outlined"
            onChange={handleOnChange}
            select
            required
            disabled={disableModelInfo}
            fullWidth
            value={userInputData?.target}
            error={fieldError.target}
            helperText={fieldError.target}
          >
            {dropdownList?.map((value) => (
              <MenuItem key={value} value={value} data-id="target">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
      </Grid>
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </LRContainer>
  );
});

