import React, { memo, useState } from "react";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  DemoContainer,
  StyledButton,
  Result,
} from "../../../styles/common";
import styled from "styled-components";
import {
  Grid,
  Typography,
  AppBar,
  Tab,
  Input,
  TextField,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableBody,
} from "@material-ui/core";
import { StyledTabs } from "./ColbertAnalysis";
import { TabPanel } from "./JakInhibitors";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import InfoToolTip from "./vnet/components/InfoToolTip";
import { validateForm, isObjKeyhasval } from "../solutions/vnet/util";
// user id
import getUserData from "../../../utils";

const NameMatcherDesc = () => {
  return (
    <>
      <SolutionContainerWrapper>
        <Collapse text="Description">
          <Paragraph>
            <p>
              Fuzzy name matching is a technique used to match names that are
              similar, but not exactly the same. This is often used when working
              with data from different sources, where names may be spelled
              differently or may be in different formats. It can also be used to
              correct errors in data entry, such as typos or misspellings.{" "}
            </p>
            <p>
              The process of fuzzy name matching typically involves using
              algorithms to compare and score the similarity between names based
              on various criteria. Commonly used criteria include phonetic
              similarity, edit distance, and common prefix or suffix. For
              example, the Soundex algorithm is a phonetic algorithm that
              assigns a code to a name based on its pronunciation, while the
              Levenstein distance algorithm measures the number of edits
              (insertions, deletions, and substitutions) required to change one
              name into another. The scores generated by these algorithms can
              then be used to determine the best match or to identify potential
              matches which can be passed to a model. In some cases, a threshold
              can be set to automatically accept or reject matches based on the
              scores, while in other cases, matches are reviewed manually to
              ensure accuracy.{" "}
            </p>
            <p>
              Fuzzy name matching can be used in a variety of applications, such
              as data integration, data de-duplication, and record linkage. For
              example, in data integration, fuzzy name matching can be used to
              match records from different databases that contain information
              about the same individuals. In data de-duplication, it can be used
              to identify and remove duplicate records. And in record linkage,
              it can be used to link records from different datasets that refer
              to the same individual.{" "}
            </p>
            <p>
              It is important to note that fuzzy name matching is not always
              perfect, and it may not be able to match all similar names
              correctly. Additionally, it can be sensitive to certain types of
              errors, such as transpositions, and may not be able to correct
              them.{" "}
            </p>
            <p>
              This application makes use of a stacked ensemble model to provide
              a match score. This is to ensure that the model can make accurate
              predictions even on some out of sample data. This model solves the
              above-mentioned problems as well. It is powered by a fuzzy name
              matching model that can handle most of the challenges faced in
              large scale name matching like the ones mentioned in issues being
              tackled. The model gives out a decimal value as the match score
              output for a particular name/string pair with 1 being a perfect
              match and 0 being no match.
            </p>
          </Paragraph>
        </Collapse>
      </SolutionContainerWrapper>
    </>
  );
};

export function NameMatcherDemo() {
  const { postQueryResult } = CommonService("z1dApps", "nameMatcher");
  // const { postQueryResult } = CommonService("nameMatcher", "nameMatcher");

  const [fieldError, setFieldError] = useState(false);

  const validationData = {
    thresHold: { maxNum: 100, minNum: 0, onlyNum: true },
  };

  const handleFieldError = (errData) => {
    setFieldError({
      ...fieldError,
      ...errData,
    });
  };

  const { validateOnBlur } = validateForm(validationData, handleFieldError);

  const initialData = {
    name_a: "",
    name_b: "",
  };

  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [selectedSample, setSelectedSample] = useState("");

  const [sampleData, setSampleData] = useState(initialData);
  const [rawText, setRawText] = useState("");
  const [thresHold, setThesHold] = useState("");
  const [result, setResult] = useState("");
  const [result2, setResult2] = useState("");
  const [result3, setResult3] = useState("");
  const [result4, setResult4] = useState("");
  const [tableradiovalue, setTableRadioValue] = useState(0);
  const [fields, setFields] = useState(null);
  const [dataRow, setDataRow] = useState(null);
  const [fields4, setFields4] = useState(null);
  const [dataRow4, setDataRow4] = useState(null);
  const [btnEnable, setBtnEnable] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  function createData(name) {
    return { name };
  }

  const rows = {
    sample1: [
      createData("ABOU MOSSAAB, Abdelwadoud"),
      createData("ABOU MOSSAAH, Abdelouadoud"),
      createData("ABOU MOSSAB, Abdelouadoud"),
      createData("ABRINI, Mohamed"),
      createData("ABRINI, Mohammad"),
      createData("ABRINI, Mohammed"),
      createData("Jacob RABALSKY"),
      createData("Jacques BROUGERE"),
      createData("Jacques BROUGERE"),
      createData("Jacques Pasche"),
      createData("Obac William Olawo"),
      createData("Obaid Altaf Khanani"),
      createData("Obaj William Olau"),
      createData("Victor Emelianenko"),
      createData("Victor Emilio CAZARES GASTELLUM"),
      createData("Victor Emilio CAZARES GASTELUM"),
      createData("Victor Emilio CAZARES SALAZAR"),
      createData("Victor Emilio CAZAREZ SALAZAR"),
      createData("Victor Francisco BELTRAN GARCIA"),
      createData("Victor Hugo CASTRO GARZON"),
    ],
    sample2: [
      createData("PUCHKOV Andrei"),
      createData("PUCHKOV Andrei Sergeevich"),
      createData("PUCHKOV Andrey"),
      createData("PUCHKOV Andrey Sergeyevich"),
      createData("PUERTA PARRA Gabriel"),
      createData("PUGACHEV, Aleksei Vladimirovich"),
      createData("PUGACHEV, Alexei Pavlovich"),
      createData("PUGACHEV, Alexey"),
      createData("PUGACHYOV, Oleg Ivanovich"),
      createData("PUHACHOV, Oleh Ivanovych"),
      createData("PULIDO ORTIZ, Bayardo de Jesus"),
      createData("PULIDO VALDIVIA, Francisco"),
      createData("PULIDO VALDIVIA, Javier"),
      createData("PULIDO VARGAS, Alvaro"),
      createData("PULIDO VARGAS, Alvaro Enrique"),
      createData("PURGIN, Andrei"),
      createData("PURGIN, Andrej"),
      createData("PURGIN, Andrey Yevgenyevich"),
      createData("PURGIN, Andriy"),
      createData("PURGYN, Andriy"),
    ],
    sample3: [
      createData("QAFISHEH Hisham Younis Yahia"),
      createData("QAFISHEH Hisham Younis Yahya"),
      createData("QAFISHEH Hisham Yunis lchiyeh"),
      createData("QAFISHIH Hisham Yunis Yahya"),
      createData("QAHIYE Mohamed Ahmed"),
      createData("QALAF Fuad Mohamed"),
      createData("QALAI Nader"),
      createData("QALEI Nader"),
      createData("QALEM Musa"),
      createData("QALHARBE DE LEON Oscar"),
      createData("QALIM Musa"),
      createData("QAMAR Naved"),
      createData("QAMAR Naveed"),
      createData("QAMAR Navid"),
      createData("QAMAR Nuwayd"),
      createData("QAN'AN Muhammad Salih Muhammad"),
      createData("QANAN Radwan Muhammad Husayn Ali"),
      createData("QANBAR Khalid Zaki"),
      createData("QANI Esmail"),
      createData("QANNADI Mohammad"),
    ],
    sample4: [
      createData("Arkadii Romanovich"),
      createData("Arkadii Romanovych"),
      createData("Arkadiy Romanovych"),
      createData("Arkady Romanovich"),
      createData("Arkady Romanovych"),
      createData("Boris Borisovich"),
      createData("Boris Romanovich"),
      createData("Borys Romanovych"),
      createData("Igor Arkadevich"),
      createData("Igor Arkadievich"),
      createData("Igor Arkadiiovych"),
      createData("Igor Arkadiyevich"),
      createData("Igor Arkadyevich"),
      createData("Ihor Arkadiiovych"),
      createData("ROTENBERG JUNIOR Boris"),
      createData("Karina Iurevna"),
      createData("Karina Iurievna"),
      createData("Karina Iuryevna"),
      createData("Karina Yurevna"),
      createData("Karina Yurievna"),
    ],
  };

  const setSampleNames = (e) => {
    setSpinner(true);
    setSelectedSample("");
    setResult2("");
    setResult4("");
    setRawText("");
    setThesHold("");
    setIsEmpty(false)
    let id = e.currentTarget.id;
    setTimeout(function () {
      setSpinner(false);
      setSelectedSample(id);
      setThesHold(50);
    }, 3000);
  };

  //tabs
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //radio button
  const handleTableRadioChange = (event) => {
    setTableRadioValue(event.target.value);
  };

  //textfields
  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
    setResult("");
  };

  const handleChange2 = (e) => {
    console.log(e.target);
    setRawText(e.target.value);
  };
  const handleChange3 = (e) => {
    console.log(e.target);
    setThesHold(e.target.value);
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  //buttons
  const showSample1 = (e) => {
    const NewDataSet = {
      name_a: "James Smith",
      name_b: "James Smcith",
    };
    setSampleData(NewDataSet);
    setResult("");
    setResult3("");
    console.log(dataRow);
  };
  const showSample2 = (e) => {
    const NewDataSet = {
      name_a: "José Müller ",
      name_b: "Jose Muller",
    };
    setSampleData(NewDataSet);
    setResult("");
    setResult3("");
    console.log(dataRow);
  };
  const showSample3 = (e) => {
    const NewDataSet = {
      name_a: "Gennadiy Nikolayevich",
      name_b: "Gennady Nikolayevich",
    };
    setSampleData(NewDataSet);
    setResult("");
    setResult3("");
    console.log(dataRow);
  };
  const showSample4 = (e) => {
    const NewDataSet = {
      name_a: "Cory-Henderson",
      name_b: "Cory Henderson",
    };
    setSampleData(NewDataSet);
    setResult("");
    setResult3("");
    console.log(dataRow);
  };

  //api integration
  const calculateScore = () => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    setSpinner(true);
    setResult3("");
    setResult("");
    const { name_a, name_b } = sampleData;

    const name1 = `${name_a}`;
    const name2 = `${name_b}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setSpinner(false);
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      postQueryResult("getResult", {
        Context_param: {
          Application_name: "name-matching",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          name_a: name1,
          name_b: name2,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          setResult(res.Result);
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
      setResult("");
    }
  };

  const calculateScore2 = () => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (thresHold < 0 || thresHold > 100) {
      setNotification({
        open: "error",
        message: "Value should be between 0 and 100",
      });
    }
    else if (!rawText) {
      setNotification({
        open: "error",
        message: "Name to be matched field should not be empty",
      });
    }
    else {
      setSpinner(true);
      setResult4("");
      setResult2("");
      setIsEmpty(false)

      postQueryResult("output", {
        Context_param: {
          Application_name: "name-matching",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          name_a: rawText,
          name_b: `${selectedSample}.json`,
          threshold: thresHold,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          setSpinner(false);
          const Data = res.Result.output;
          console.log("result2", Data);
          if (Object.keys(Data).length === 0) {
            setResult2("");
            setIsEmpty(true);
            setNotification({ open: "error", message: res.Console });
          } else {
            setResult2(Data);
            setIsEmpty(false);
          }
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
      setResult2("");
      setIsEmpty(false)
    }
  };

  const explainability = (e) => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    let id = e.currentTarget.id;
    console.log(id);
    const { name_a, name_b } = sampleData;

    const name1 = `${name_a}`;
    const name2 = `${name_b}`;
    setSpinner(true);
    setDataRow(null);
    setResult3("");

    postQueryResult("explainability", {
      Context_param: {
        Application_name: "name-matching",
        Execution_time: date,
        UserID: userId,
      },

      Content_param: {
        name_a: name1,
        name_b: name2,
      },
    })
      .then((res) => {
        setNotification({ open: "success", message: res.Console });
        setSpinner(false);
        const Data = JSON.parse(res.Result.output);
        setResult3(Data);
        setFields(Data?.schema?.fields);
        setDataRow(Data?.data);
        console.log("Data", Data);
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });

    // setResult4("");
  };

  const explainability2 = () => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    setSpinner(true);
    console.log(result4);
    setDataRow4(null);
    setResult4("");

    postQueryResult("explainability", {
      Context_param: {
        Application_name: "name-matching",
        Execution_time: date,
        UserID: userId,
      },

      Content_param: result2[tableradiovalue][1],
    })
      .then((res) => {
        setNotification({ open: "success", message: res.Console });
        setSpinner(false);
        const Data = JSON.parse(res.Result.output);
        setResult4(Data);
        setFields4(Data?.schema?.fields);
        setDataRow4(Data?.data);
        console.log("Data", Data);
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });

    // setResult3("");
  };

  return (
    <SolutionContainerWrapper snackbar={notification}>
      <AppBar position="static" color="default">
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Name vs Name" />
          <Tab label="Name vs  a list of Names" />
        </StyledTabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <DemoContainer>
          <Grid
            container
            spacing={2}
            xs={12}
            direction="row"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Paragraph>
                Please enter two names in the Name 1 and Name 2 fields
                respectively or select any of the samples for pre-defined names.
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                rows="1"
                multiline
                fullWidth
                variant="outlined"
                name="name_a"
                label="Name 1"
                onChange={handleInputs}
                value={sampleData.name_a}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                rows="1"
                multiline
                fullWidth
                variant="outlined"
                name="name_b"
                label="Name 2"
                onChange={handleInputs}
                value={sampleData.name_b}
              />{" "}
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} direction="row" justifyContent="center">
            <Grid item xs={12}>
              <StyledButton
                id="sample1"
                variant="contained"
                color="primary"
                data-sample="sample1"
                onClick={showSample1}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                id="sample2"
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={showSample2}
              >
                Sample 2
              </StyledButton>
              <StyledButton
                id="sample3"
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={showSample3}
              >
                Sample 3
              </StyledButton>
              <StyledButton
                id="sample4"
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={showSample4}
              >
                Sample 4
              </StyledButton>
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={calculateScore}
              >
                Calculate score
              </StyledButton>
            </Grid>
          </Grid>

          {result && (
            <>
              <Result>
                <strong>Result</strong>
                <Typography>
                  {" "}
                  The similarity score for {sampleData.name_a} and{" "}
                  {sampleData.name_b} is {result.match_score}%
                </Typography>
              </Result>

              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Paragraph>
                    {" "}
                    Please click on the Compute Explainability button to get
                    match scores on a word to word basis.
                  </Paragraph>
                </Grid>
                <br />

                <Grid item>
                  <StyledButton
                    id="explainability"
                    variant="contained"
                    color="secondary"
                    onClick={explainability}
                  >
                    Compute Explainability
                  </StyledButton>
                </Grid>
              </Grid>
            </>
          )}
          {showSpinner && (
            <Spinner text="Loading.. Please wait for a minute..." />
          )}
          {result3 && (
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {result3?.schema?.fields?.map((item) => (
                          <HeaderCol align="start">{item.name} </HeaderCol>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataRow?.map((row) => (
                        <TableRow>
                          {fields?.map((field) => (
                            <Cell align="center" key={row.index}>
                              {row[field.name]}
                            </Cell>
                          ))}
                          <br />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <DemoContainer>
          <Grid container spacing={2} direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={12}>
              <Paragraph>
                This method can be used in scenarios where the user has to match
                the names from a list of names from a database. The four samples
                are taken from a database of names.
              </Paragraph>
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1"
                onClick={setSampleNames}
                value={selectedSample}
                id="sample1"
                name={selectedSample}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={setSampleNames}
                value={selectedSample}
                id="sample2"
                name={selectedSample}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={setSampleNames}
                value={selectedSample}
                id="sample3"
                name={selectedSample}
              >
                Sample 3
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={setSampleNames}
                value={selectedSample}
                id="sample4"
                name={selectedSample}
              >
                Sample 4
              </StyledButton>
            </Grid>
          </Grid>

          <br />

          {selectedSample && (
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12}>
                <Paragraph>
                  {" "}
                  Please enter a name to be searched from the list of names
                </Paragraph>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ScrollableTable component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <HeaderCol>List of Names</HeaderCol>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows[selectedSample].map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{row.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </ScrollableTable>{" "}
              </Grid>
              <Grid item xs={10} sm={6} md={4}>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  align="start"
                >
                  <Grid item xs={11} sm={11} md={11}>
                    <TextField
                      fullWidth
                      id="rawText"
                      variant="outlined"
                      name="rawText"
                      label="Name to be matched"
                      onChange={handleChange2}
                      value={rawText}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}></Grid>
                  <Grid item xs={11} sm={11} md={11}>
                    <TextField
                      fullWidth
                      id="thresHold"
                      type="number"
                      variant="outlined"
                      name="thresHold"
                      label="Threshold"
                      onChange={handleChange3}
                      value={thresHold}
                      onBlur={validateOnBlur}
                      error={fieldError.thresHold}
                      helperText={fieldError.thresHold}
                    />
                    <center>Value should be between 0 and 100</center>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <strong>
                      <InfoToolTip
                        title="This value is used to filter out the names based on a combination of phonetic and string distance algorithms. Higher threshold implies that more names with lower scores are filtered out, but some names might go unnoticed and vice versa. Value should be between 0 and 100"
                        info=" "
                      />
                    </strong>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <StyledButton
                  variant="contained"
                  color="secondary"
                  onClick={calculateScore2}
                >
                  Calculate score
                </StyledButton>
              </Grid>
            </Grid>
          )}
          <br />
          <br />
          {isEmpty && (
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Result>
                  <p>
                    <b>Result : </b>
                    No Successful Match Found
                  </p>
                </Result>
              </Grid>
            </Grid>
          )}

          {result2 && (
            <>
              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Result>
                    <strong>Result :</strong>
                  </Result>
                </Grid>
              </Grid>
              <br />

              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <ScrollableTable component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <HeaderCol align="start">Select Names</HeaderCol>
                          <HeaderCol align="start">Index</HeaderCol>
                          <HeaderCol align="start">Target Name</HeaderCol>
                          <HeaderCol align="start">Reference Name</HeaderCol>
                          <HeaderCol align="start">Match score(%)</HeaderCol>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {result2.map((item, index) => (
                          <TableRow>
                            <Cell align="center">
                              {" "}
                              <Radio
                                value={index}
                                onChange={handleTableRadioChange}
                                checked={index == tableradiovalue}
                              />
                            </Cell>
                            <Cell align="center">{item[0]}</Cell>
                            <Cell>{item[1].name_a}</Cell>
                            <Cell>{item[1].name_b}</Cell>
                            <Cell align="center">{item[1].match_score}</Cell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ScrollableTable>{" "}
                </Grid>
              </Grid>

              <br />

              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Paragraph>
                    {" "}
                    Please click on the Compute Explainability button to get
                    match scores on a word to word basis.
                  </Paragraph>
                </Grid>
                <br />

                <Grid item>
                  <StyledButton
                    id="explainability2"
                    variant="contained"
                    color="secondary"
                    onClick={explainability2}
                  >
                    Compute Explainability
                  </StyledButton>
                </Grid>
              </Grid>
            </>
          )}

          {showSpinner && (
            <Spinner text="Loading.. Please wait for a minute..." />
          )}

          <br />
          <br />
          {result4 && (
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {result4?.schema?.fields?.map((item) => (
                          <HeaderCol align="start">{item.name} </HeaderCol>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataRow4?.map((row) => (
                        <TableRow>
                          {fields4?.map((field) => (
                            <Cell align="center" key={row.index}>
                              {row[field.name]}
                            </Cell>
                          ))}
                          <br />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </TabPanel>
    </SolutionContainerWrapper>
  );
}

export default memo(NameMatcherDesc);

const ScrollableTable = styled(TableContainer)`
  height: 400px;
`;
const HeaderCol = styled(TableCell)`
  background-color: #00afb9;
  color: white;
  min-width: 100px;
  text-align: center;
  height: 5px;
`;
const Cell = styled(TableCell)`
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: block;
  text-align: left;
`;
