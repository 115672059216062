import React, { useState } from "react";
import {
  Grid, TextField, Typography, FormControl,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

// import local components
import MainLayout from "../../common/MainLayout";
import { PageTitle, StyledButton } from "../../../styles/common";
import commonServices from "../../../utils/services/CommonService";
import Navbar from "../../common/Navbar";
import Spinner from "../../common/loading/Spinner";

// images
import askForAModel_sol1 from "../../../assets/images/app/askForAModel_sol1.webp";
import Notification from "../../common/Notification";
import { validateEmail } from "../../../utils";

const AskForAModel = () => {
  const initialData = {
    problem_stmt: "",
    uploadedData: "",
    s3Link: "",
    customer: "",
    businessUseCase: "",
    dataDetails: "",
    literature: "",
    samplecode: "",
    info: "",
    contact_mail: "",
    title: "",
  };

  const { uploadFilesWithBody } = commonServices(
    "z1dApps",
    "askForModel",
  );
  const [fields, setFields] = useState(initialData);
  const [isLoading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');

  const handleOnChange = (e) => {
    const value = (e.target.files && e.target.files[0]) || e.target.value;
    setFields({
      ...fields,
      [e.target.name]: value,
    });
  };

  const postFormDetails = () => {
    const {
      title, samplecode, s3Link, customer, businessUseCase, literature, info, contact_mail, problem_stmt, dataDetails, uploadedData, uploadedPresentation,
    } = fields;
    const params = {
      args: title,
      urls: `${problem_stmt}${s3Link ? `^${s3Link}` : '^'}^${dataDetails}^${businessUseCase}^${customer}^${literature}^${samplecode}^${info}^${contact_mail}`,
    };
    const postParams = `${encodeURIComponent(params.args)}?urls=${encodeURIComponent(params.urls)}`;
    const files = {
      file1: uploadedPresentation,
    };
    if (!s3Link) {
      files.file = uploadedData;
    }

    let areValuesEmpty = false;
    Object.keys(fields).forEach((key) => {
      if (fields[key] === '') {
        if (['s3Link', 'uploadedData', 'samplecode', 'literature', 'info'].indexOf(key) > -1) {
          return;
        }
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: 'Please fill required fields' });
      return;
    }
    setLoading(true);
    uploadFilesWithBody('sendMail', files, postParams)
      .then((res) => {
        setLoading(false);
        setNotification({ open: "success", message: 'Mail Sent Successfully!!!' });
      })
      .catch((err) => {
        setLoading(false);
        setNotification({ open: "error", message: 'Sorry, Mail not sent due to technical issues please try after sometime' });
      });
  };
  const resetForm = () => {
    setFields(initialData);
  };
  const checkForValidEmail = () => {
    if (fields.contact_mail && !validateEmail(fields.contact_mail)) {
      setNotification({ open: "error", message: 'Invalid Email Format' });
      setFields({
        ...fields,
        contact_mail: "",
      });
    }
  };

  return (
    <MainLayout>
      <div>
        <Navbar />
        <BgImage imageSrc={askForAModel_sol1} opacity="0.1">
          <HostAppContainer>
            <PageTitle>Ask for a Model</PageTitle>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              alignContent="center"
            >
              <Grid item xs={12} sm={5} alignItems="center">
                <StyledLabel>1) Solution Title* </StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="title"
                    onChange={handleOnChange}
                    value={fields.title}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5} alignItems="center">
                <StyledLabel>2) Problem Statement* </StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="problem_stmt"
                    onChange={handleOnChange}
                    value={fields.problem_stmt}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5} alignItems="center">
                <StyledLabel>3) Data Source *</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Data Source Link"
                    name="s3Link"
                    onChange={handleOnChange}
                    value={fields.s3Link}
                  />
                  <br />
                </Width100>
              </Grid>
              <ORContainer item xs={12} sm={2}>
                <h3>OR</h3>
              </ORContainer>
              <UploadContainer item xs={12} sm={2}>
                <Width100>
                  <HiddenInput
                    name="uploadedData"
                    onChange={handleOnChange}
                    id="icon-button-file"
                    type="file"
                  />
                  <label
                    htmlFor="icon-button-file"
                  >
                    <UploadIcon fontSize="large" />
                    <br />
                    {fields?.uploadedData?.name}
                  </label>
                </Width100>
              </UploadContainer>

              <Grid item xs={12} sm={5}>
                <StyledLabel>4) Data Details*</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="dataDetails"
                    onChange={handleOnChange}
                    value={fields.dataDetails}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5}>
                <StyledLabel>5) Business Use Case *</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="businessUseCase"
                    onChange={handleOnChange}
                    value={fields.businessUseCase}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5}>
                <StyledLabel>6) Customer/Prospect *</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="customer"
                    onChange={handleOnChange}
                    value={fields.customer}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5}>
                <StyledLabel>7) Upload Presentation/Document *</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    type="file"
                    color="primary"
                    variant="outlined"
                    name="uploadedPresentation"
                    onChange={handleOnChange}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5}>
                <StyledLabel>8) Literature URL</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="literature"
                    onChange={handleOnChange}
                    value={fields.literature}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5}>
                <StyledLabel>9) Sample Code URL</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="samplecode"
                    onChange={handleOnChange}
                    value={fields.samplecode}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5}>
                <StyledLabel>10) Any Other Information</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="info"
                    onChange={handleOnChange}
                    value={fields.info}
                  />
                  <br />
                </Width100>
              </Grid>
              <Grid item xs={12} sm={5}>
                <StyledLabel>11) Contact Email*</StyledLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Width100>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    name="contact_mail"
                    onBlur={checkForValidEmail}
                    onChange={handleOnChange}
                    value={fields.contact_mail}
                  />
                  <br />
                </Width100>
              </Grid>
            </Grid>
            <ButtonContainer container justify="center">
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={postFormDetails}
                >
                  Send Email
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  endIcon={<RotateLeftIcon />}
                  onClick={resetForm}
                >
                  Reset
                </StyledButton>
              </Grid>
            </ButtonContainer>
            {isLoading && (
              <section>
                <Spinner />
              </section>
            )}
          </HostAppContainer>
        </BgImage>
        <Notification snackbar={notification} />
      </div>
    </MainLayout>
  );
};
export default AskForAModel;

export const BgImage = styled(LazyHero)`
  height: 1250px;
  @media only screen and (max-width: 959px) {
    height: 1200px;
  }
  @media only screen and (max-width: 600px) {
    height: 1600px;
  }
`;

const HostAppContainer = styled.section`
  padding: 0px 30px 15px 30px;
  background: #ffffff;
  margin: 10%;
  text-align: left;
`;

const Width100 = styled(FormControl)`
  width: 100%;
`;
const ButtonContainer = styled(Grid)`
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;
const StyledLabel = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 13px;
  }
`;
const HiddenInput = styled.input`
  display: none;
  text-align: center;
`;
const UploadIcon = styled(CloudUploadIcon)`
  cursor: pointer;
  margin-top: 20px;
`;

const UploadContainer = styled(Grid)`
  text-align: center !important;
  @media only screen and (min-width: 600px) {
    margin-bottom: 20px !important;
  }
`;
const ORContainer = styled(Grid)`
  text-align: center !important;
  @media only screen and (min-width: 600px) {
    font-size: 15px;
  }
`;
