import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  FormGroup,
  FormControl,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local imports

import Highcharts from "highcharts";
import styled from "styled-components";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  HighchartContainer,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import insuranceClaimPrediction_sol1 from "../../../assets/images/app/insuranceClaimPrediction_sol1.webp";

const InsuranceClaimPrediction = () => {
  const initialData = {
    claimdrugcode: "",
    insuredamount: "",
    coinsuranceamount: "",
    inpatientdeductibleAmount: "",
    blooddeductibleamount: "",
    gender: "",
    cancer: "",
    depression: "",
    primarydiagnosis: "",
    diagnosiscode: "",
    countrycode: "",
    statecode: "",
    race: "",
    diabetes: "",
    osteoporosis: "",
    stroke: "",
    pulmonaryDisease: "",
    rheumatoidAthritis: "",
    cancerValue: "",
    depressionValue: "",
    osteoporosisValue: "",
    strokeValue: "",
    pulmonaryDiseaseValue: "",
    diabetesValue: "",
    rheumatoidAthritisValue: "",
  };

  const races = [
    {
      value: 0,
      label: "White",
    },
    {
      value: 1,
      label: "Black",
    },
    {
      value: 2,
      label: "Hispanic",
    },
    {
      value: 3,
      label: "Others",
    },
  ];

  const genders = [
    {
      value: 0,
      label: "Female",
    },
    {
      value: 1,
      label: "Male",
    },
  ];

  const primarydiagnosises = [
    {
      value: 0,
      label: "V",
    },
    {
      value: 1,
      label: "H",
    },
    {
      value: 2,
      label: "E",
    },
    {
      value: 3,
      label: "Not Specified",
    },
  ];

  const [parameters, setParameters] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [num, setNum] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "insuranceClaimPrediction",
  );

  const showResult = () => {
    const NewDataSet = {
      claimdrugcode: "23",
      insuredamount: "120000",
      coinsuranceamount: "100000",
      inpatientdeductibleAmount: "20000",
      blooddeductibleamount: "200",
      gender: "1",
      primarydiagnosis: "0",
      diagnosiscode: "232",
      countrycode: "12",
      statecode: "21",
      race: "2",
      cancer: "true",
      pulmonaryDisease: "false",
      depression: "true",
      diabetes: "true",
      osteoporosis: "false",
      rheumatoidAthritis: "false",
      stroke: "false",
      cancerValue: "1",
      depressionValue: "2",
      osteoporosisValue: "1",
      strokeValue: "1",
      pulmonaryDiseaseValue: "2",
      diabetesValue: "2",
      rheumatoidAthritisValue: "2",
    };
    setParameters(NewDataSet);
  };

  const getResult = () => {
    setResultData("");
    setNum("");

    const {
      claimdrugcode,
      insuredamount,
      coinsuranceamount,
      inpatientdeductibleAmount,
      blooddeductibleamount,
      gender,
      primarydiagnosis,
      diagnosiscode,
      countrycode,
      statecode,
      race,
    } = parameters;

    const benefAmount = parseInt(coinsuranceamount, 10)
      + parseInt(blooddeductibleamount, 10)
      + parseInt(inpatientdeductibleAmount, 10);
    const primaryDiaCodeId = parameters.primarydiagnosis + diagnosiscode;
    const cancerValue = parameters.cancerValue ? parameters.cancerValue : 1;
    const pulmonaryDiseaseValue = parameters.pulmonaryDiseaseValue
      ? parameters.pulmonaryDiseaseValue
      : 1;
    const depressionValue = parameters.depressionValue
      ? parameters.depressionValue
      : 1;
    const diabetesValue = parameters.diabetesValue ? parameters.diabetesValue : 1;
    const osteoporosisValue = parameters.osteoporosisValue
      ? parameters.osteoporosisValue
      : 1;
    const rheumatoidAthritisValue = parameters.rheumatoidAthritisValue
      ? parameters.rheumatoidAthritisValue
      : 1;
    const strokeValue = parameters.strokeValue ? parameters.strokeValue : 1;

    const params = `${claimdrugcode},${primaryDiaCodeId},${insuredamount},${benefAmount},${gender},${statecode},${countrycode},${race},${cancerValue},${pulmonaryDiseaseValue},${depressionValue},${diabetesValue},${osteoporosisValue},${rheumatoidAthritisValue},${strokeValue}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          setNum(res[0]);
          console.log(res);
          const params = [];
          const percentages = res[1];
          console.log(num);
          console.log(percentages);
          for (const propertyName in percentages) {
            params.push({
              y: parseInt(propertyName, 10),
              name: percentages[propertyName],
            });
            Highcharts.chart("prediction-chart", {
              chart: {
                plotShadow: false,
                type: "pie",
              },
              title: {
                text: "MAJOR CONTRIBUTING FACTORS",
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                    style: {
                      color:
                        (Highcharts.theme
                          && Highcharts.theme.contrastTextColor)
                        || "black",
                    },
                  },
                },
              },
              credits: {
                enabled: false,
              },
              series: [
                {
                  colorByPoint: true,
                  data: params,
                },
              ],
            });
          }
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  const handleCheckBox = (e) => {
    const { name } = e.target;
    const value = e.target.checked;
    const valueName = `${e.target.name}Value`;
    let val;
    if (value === "true") {
      val = 1;
    } else {
      val = 2;
    }
    console.log(name, value);
    setParameters({
      ...parameters,
      [name]: value,
      [valueName]: val,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Given the paucity of knowledge that people possess about how
            insurance works, most individuals end up overestimating or
            underestimating the amount covered by their insurance. Having a
            system that does this calculation for them can aid people in giving
            them a better understanding of their expenditure on health care.
          </p>
          <p>
            This application aims to provide an easy-to-use interface in which
            an algorithm predicts the estimated insurance claims amount based on
            the details provided by the user. The
            {" "}
            <b> CMS SynPUF US Gov Research Data </b>
            {' '}
            was used to build this
            application as it contains the insurance claims, along with the
            demographic information of patients across the United States. The
            model considers various comorbidities such as cancer, depression,
            osteoporosis, COPD etc., as additional aspects while accounting for
            the prediction.
          </p>
          <h2>Approach and Outcomes</h2>
          <center>
            <Image1 src={insuranceClaimPrediction_sol1} />
          </center>
          <h3>The main variables considered are:-</h3>
          <ul>
            <li>Claim Amount​</li>
            <li>Payable Amount</li>
            <li>Amount Ratio</li>
            <li>Gender</li>
            <li>Race</li>
            <li>Drug Code</li>
          </ul>
          <h3>
            This solution is beneficial for both, hospitals and insurance
            providers, alike:
          </h3>
          <ul>
            <li>
              <b>For Hospitals :</b>
              {' '}
              A patient who just made a visit to the
              hospital and finds out that they might have to undergo treatment
              for disease can get a better understanding of the amount he is
              expected to pay, instead of worrying about the medical bills, or
              overestimating the amount covered by their insurance.
              <br />
            </li>
            <li>
              <b>For Insurance Provider :</b>
              {' '}
              As an insurance provider, to
              maximize profit, the company must effectively price their
              insurance premium and assess the risk involved for a particular
              individual.
            </li>
          </ul>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Insurance_claims_prediction/inpatientbene.csv"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Claims_prediction/Claim_Prediction-v2.ipynb"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <StyledDemoContainer>
          <center>
            <h5>Please provide only numerical values for the inputs</h5>
          </center>
          <Grid container spacing={2}>
            <Grid item sm={1} />
            <Grid item xs={12} sm={4}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  name="claimdrugcode"
                  id="standard-required"
                  label="CLM Drug Code"
                  value={parameters.claimdrugcode}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  id="standard-required"
                  label="Insured Amount(MedCare)"
                  name="insuredamount"
                  value={parameters.insuredamount}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  id="standard-required"
                  label="Beneficiary Coinsurance Liability Amount"
                  name="coinsuranceamount"
                  value={parameters.coinsuranceamount}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  id="standard-required"
                  label="Beneficiary Inpatient Deductible Amount"
                  name="inpatientdeductibleAmount"
                  value={parameters.inpatientdeductibleAmount}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  id="standard-required"
                  label="Beneficiary Blood Deductible Amount"
                  name="blooddeductibleamount"
                  value={parameters.blooddeductibleamount}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Gender"
                  name="gender"
                  value={parameters.gender}
                  onChange={onChangeFields}
                >
                  {genders.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={parameters.cancer}
                        name="cancer"
                        onChange={handleCheckBox}
                      />
                    )}
                    label="Cancer"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={parameters.depression}
                        name="depression"
                        onChange={handleCheckBox}
                      />
                    )}
                    label="Depression"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={parameters.osteoporosis}
                        name="osteoporosis"
                        onChange={handleCheckBox}
                      />
                    )}
                    label="Osteoporosis"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={parameters.stroke}
                        name="stroke"
                        onChange={handleCheckBox}
                      />
                    )}
                    label="Stroke/transient Ischemic Attack"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item sm={1} />

            <Grid item xs={12} sm={4}>
              <FormControl minWidth="80" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Primary Diagnosis"
                  name="primarydiagnosis"
                  value={parameters.primarydiagnosis}
                  onChange={onChangeFields}
                >
                  {primarydiagnosises.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  id="standard-required"
                  label="Enter Diagnosis Code"
                  name="diagnosiscode"
                  value={parameters.diagnosiscode}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  id="standard-required"
                  label="Country Code"
                  name="countrycode"
                  value={parameters.countrycode}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  type="number"
                  id="standard-required"
                  label="State Code"
                  name="statecode"
                  value={parameters.statecode}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Race"
                  name="race"
                  value={parameters.race}
                  onChange={onChangeFields}
                >
                  {races.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={parameters.pulmonaryDisease}
                        name="pulmonaryDisease"
                        onChange={handleCheckBox}
                      />
                    )}
                    label="Chronic Obstructive Pulmonary Disease"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={parameters.diabetes}
                        name="diabetes"
                        onChange={handleCheckBox}
                      />
                    )}
                    label="Diabetes"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={parameters.rheumatoidAthritis}
                        name="rheumatoidAthritis"
                        onChange={handleCheckBox}
                      />
                    )}
                    label="Rheumatoid Arthritis"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item sm={1} />
          </Grid>
          <center>
            <Grid item xs={12}>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                SAMPLE DATA
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                EXECUTE
              </StyledButton>
            </Grid>
          </center>
          <Grid item xs={12}>
            <section>
              <Grid item xs={12}>
                {showSpinner && <Spinner text="Loading..." />}

                {num && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`The predicted Insurance Claim amount for the details provided is: ${num} `}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer
                          id="prediction-chart"
                          display
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </section>
          </Grid>
        </StyledDemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(InsuranceClaimPrediction);
const StyledDemoContainer = styled(DemoContainer)`
  text-align: left;
`;

const Image1 = styled.img`
  @media only screen and (min-width: 900px) {
    width: 80%;
  }
`;
