import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Grid, Typography, AppBar, Tab, Input } from '@material-ui/core';
import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from '../../../styles/common';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import { StyledTabs } from './IOTRecipesView';
import { TabPanel } from './JakInhibitors';
import CommonService from '../../../utils/services/CommonService';

// images
import medicalReport from '../../../assets/images/app/medicalReport.webp';
import sample1_1 from '../../../assets/images/app/sample1_1.webp';
import sample1_2 from '../../../assets/images/app/sample1_2.webp';
import sample2_1 from '../../../assets/images/app/sample2_1.webp';
import sample2_2 from '../../../assets/images/app/sample2_2.webp';
import sample3_1 from '../../../assets/images/app/sample3_1.webp';
import sample3_2 from '../../../assets/images/app/sample3_2.webp';

// user id
import getUserData from '../../../utils';



const MedicalReport = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState('');
  //Tab1
  const [result, SetResult] = useState(false);

  //tab value
  const [value, setValue] = useState(0);
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  //upload file
  const [selectedFile2, setSelectedFile2] = useState('');
  const [fileName2, setSelectedFileName2] = useState('');
  const [selectedFile1, setSelectedFile1] = useState('');
  const [fileName1, setSelectedFileName1] = useState('');
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  //output
  const [reports, setReports] = useState(false);

  const { uploadFilesWithBody } = CommonService(
    'medicalReport',
    'medicalReport'
  );

  //tab1
  const itemData2 = [
    {
      title: 'Sample 1',
      img1: sample1_1,
      img2: sample1_2,
      report:
        'the lungs appear clear. there are focal airspace opacities suggest pneumonia. the pleural spaces are clear. the heart and pulmonary are normal. mediastinal contours are normal. there pneumothorax.',
    },
    {
      title: 'Sample 2',
      img1: sample2_1,
      img2: sample2_2,
      report:
        'cardiac and mediastinal contours are within normal limits. the lungs are clear. bony structures are intact.',
    },

    {
      title: 'Sample 3',
      img1: sample3_1,
      img2: sample3_2,
      report:
        'the heart pulmonary and mediastinum are within normal limits. there pleural effusion pneumothorax. there focal air space opacity suggest pneumonia. there calcified granuloma the left lung base.',
    },
  ];

  const generateReport = () => {
    SetResult(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //tab2
  // const fileSelectedHandler = (e) => {
  //   console.log(e);
  //   //setSelectedFile(e.target.files[0]);
  // };

  const firstFileSelectedHandler = (e) => {
    //setSelectedFile(e.target.files[0]);

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (e.target.files && e.target.files[0]) {
      setImage1(URL.createObjectURL(e.target.files[0]));
      setSelectedFile1(file);
      setSelectedFileName1(fileName);
    }
  };
  const secondFileSelectedHandler = (e) => {
    // setSelectedFile(e.target.files[0]);

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (e.target.files && e.target.files[0]) {
      setImage2(URL.createObjectURL(e.target.files[0]));
      setSelectedFile2(file);
      setSelectedFileName2(fileName);
    }
  };

  //file upload
  const uploadFileHandler = () => {
    //setReports(true);

    ///api integration
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (!selectedFile1) {
      setNotification({ open: 'error', message: 'please upload a file' });
      return;
    }
    if (!selectedFile2) {
      setNotification({ open: 'error', message: 'please upload a file' });
      return;
    }

    setSpinner(true);
    uploadFilesWithBody('upload', {
      file1: selectedFile1,
      file2: selectedFile2,
      Context_param: JSON.stringify({
        Application_name: 'medical-report-generator',
        Execution_time: date,
        UserID: userId,
      }),
      Content_param: JSON.stringify({}),
    })
      .then((res) => {
        setSpinner(false);
        setNotification({ open: 'success', message: res.Console });
        const Data = JSON.parse(res.Result);
        setReports(Data);
        console.log(res);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);

        console.log(err);
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The automated generation of radiology reports provides X-rays and
            has tremendous potential to enhance the clinical diagnosis of
            diseases in patients.Chest diseases are fatal to human life. Common
            chest diseases such as pneumonia, pneumothorax, and effusion are
            diagnosed with the help of medical images, such as chest X-rays
            (CXR) and CT scans. These images provide subsequent evidence of
            chest abnormalities captured through a proper pathological process.
            A radiologist conducts an analytical examination for the presence of
            even a minor abnormality on an X-ray image, followed by a detailed
            diagnostic textual report of a patient. This manually created report
            describes the condition of the chest in general, detailed findings,
            and diseases, if they are projected on the X-ray image. Writing
            medical reports is a laborious task. In developing countries with a
            large population with poor health conditions, such as Pakistan,
            radiologists may have to capture hundreds of X-ray images of
            different patients every day. Generating hundreds of reports on
            pathological conditions of lungs against CXR is time-consuming and
            tedious. The process of describing X-rays in terms of text is not
            efficient, even for specialist doctors in their respective fields.
            Moreover, this task is error-prone due to inexperienced
            radiologists, faulty reasoning by radiologists, staff shortage in
            hospitals, or additional workload in the hospitals that cause errors
            in the reports.
          </p>
          <p>
            Image Captioning is a challenging artificial intelligence problem
            which refers to the process of generating textual description from
            an image based on the image contents.They write textual reports to
            narrate the findings regarding each area of the body examined in the
            imaging study, specifically whether each area was found to be
            normal, abnormal or potentially abnormal.
          </p>
          <p>
            For radiologists and pathologists, especially those working in the
            rural areas where the quality of healthcare is relatively low,
            writing medical-imaging reports is demanding or on the other hand
            for experienced radiologists and pathologists.So, to address all
            these matters this application provides a better solution .
          </p>
          <ul>
            <li>
              {' '}
              <b> Prerequisites :</b>
            </li>{' '}
            <p>
              This work assumes some deep learning familiarity with topics like
              CNNs, RNNs, Transfer Learning, Python programming and Keras
              library. The two below mentioned models will be used for our
              problem:
            </p>
            <ul>
              <li>Encoder-Decoder Model</li>
              <li>Attention-Mechanism</li>
            </ul>
            <p>
              The encoder(CNN) processes each item in the input sequence, it
              compiles the information it captures into a vector called the
              context. After processing the entire input sequence, the encoder
              sends the context over to the decoder(RNN), which begins producing
              the output sequence item by item. Attention Mechanism highly
              improves the quality of machine translation systems.It was
              proposed as an improvement to the encoder-decoder models. The
              context vector turned out to be a bottleneck for these types of
              models. It made it challenging for them to deal with long
              sentences. Attention allows the model to focus on the relevant
              parts of the input sequence as needed.
            </p>
            <li>
              {' '}
              <b>About Dataset:</b>
            </li>
            <p>
              Radiology reports for the chest x-ray images from the Indiana
              University hospital network.The image dataset contains multiple
              chest x-rays of a single person. For instance: side-view of the
              x-ray, multiple frontal views etc. Just as a radiologist uses all
              these images to write the findings, the models will also use all
              these images together to generate the corresponding findings.
              There are 3955 reports present in the dataset, with each report
              having one or more images associated with it.
            </p>
          </ul>
        </Paragraph>
        <br />
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/MedicalReportGeneration/MedicalReportGeneration.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant='outlined'
                color='primary'
                size='large'
                startIcon={<OpenInNewIcon />}
              >
                <a href='https://academictorrents.com/details/5a3a439df24931f410fac269b87b050203d9467d' target='_blank' rel='noreferrer'>
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://academictorrents.com/details/5a3a439df24931f410fac269b87b050203d9467d"
                  target="_blank"
                  rel="noreferrer"
                >
                  Images : Contains all the chest X-rays
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://academictorrents.com/details/66450ba52ba3f83fbf82ef9c91f2bde0e845aba9"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reports : Contains the corresponding reports for the above
                  images
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <br />
      <Grid item xs={12}>
        <AppBar position="static" color="default">
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Sample" />
            <Tab label="BYOD" />
          </StyledTabs>
        </AppBar>
      </Grid>
      <br />
      <TabPanel value={value} index={0}>
        <DemoContainer>
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} sm={12}>
              <StyledButton
                id="btn1"
                variant="contained"
                color="primary"
                data-sample="sample1"
                onClick={generateReport}
              >
                Generate Reports
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
          >
            <Grid item xs={6} sm={6}>
              {itemData2.map((item) => {
                return (
                  <>
                    <img
                      src={item.img1}
                      alt={item.title}
                      width="100"
                      height="100"
                    />{' '}
                    <img
                      src={item.img2}
                      alt={item.title}
                      width="100"
                      height="100"
                    />
                    <br />
                    <Paragraph style={{ textAlign: 'center' }}>
                      {' '}
                      <b>{item.title}</b>
                    </Paragraph>
                    {/* <span >
                      {' '}
                      <b>{item.title}</b>
                    </span> */}
                    <br />
                    <br />
                    <br />
                  </>
                );
              })}
            </Grid>

            {result && (
              <Grid item xs={6} sm={6}>
                {itemData2.map((item) => {
                  return (
                    <>
                      <img
                        src={item.img1}
                        alt={item.title}
                        width="100"
                        height="100"
                      />{' '}
                      <img
                        src={item.img2}
                        alt={item.title}
                        width="100"
                        height="100"
                      />{' '}
                      <br />
                      <Paragraph>
                        {' '}
                        <b>{item.title} Reports:</b> {item.report}{' '}
                      </Paragraph>
                      <br />
                    </>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </DemoContainer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <DemoContainer>
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={10} sm={10} md={10}>
              <strong>Please upload your own image or download a sample image from the given link and re-upload</strong>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={firstFileSelectedHandler}
              />
              <br />
              <Typography>Please upload a front-view image</Typography>
              <br /> <br />
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={secondFileSelectedHandler}
              />{' '}
              <br />
              <Typography>Please upload a side-view image</Typography>
            </Grid>

            <Grid item xs={6} sm={6}>
              {image1 && (
                <img
                  src={image1}
                  alt={image1}
                  width="100"
                  height="100"
                  style={{ margin: '2', padding: '2' }}
                />
              )}{' '}
              {image2 && (
                <img
                  src={image2}
                  alt={image2}
                  width="100"
                  height="100"
                  style={{ margin: '2', padding: '2' }}
                />
              )}{' '}
              <br />
              <a
                href="https://material.vlifevirtusa.com/MedicalReportGeneration/samples/samples.zip"
                download
                rel="noreferrer"
              >
                Download a Sample file
              </a>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            {' '}
            <Grid item xs={12} sm={12}>
              {' '}
              <StyledButton
                id="btn2"
                variant="contained"
                color="primary"
                onClick={uploadFileHandler}
              >
                Generate Reports
              </StyledButton>
            </Grid>{' '}
          </Grid>
          <br />
          <br />
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
          >
            <Grid item xs={12} sm={12}>
              {showSpinner && <Spinner text="Loading..." />}{' '}
            </Grid>

            {reports && (
              <Grid item xs={12} sm={12}>
                {' '}
                <Typography>
                  <b>Report : </b>
                  {reports}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DemoContainer>
      </TabPanel>
    </SolutionContainer>
  );
};
export default memo(MedicalReport);
