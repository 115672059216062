import * as actions from '.';

export const addToCart = (item) => ({
  type: actions.ADD_TO_CART,
  payload: item,
});

export const removeFromCart = (id) => ({
  type: actions.REMOVE_FROM_CART,
  payload: id,
});

export const clearCart = () => ({
  type: actions.CLEAR_CART,
});

export const requestCartData = (data, userId) => ({
  type: actions.REQUEST_CART_DETAILS,
  payload: { data, userId },
});

export const receiveCartData = (data) => ({
  type: actions.RECEIVE_CART_DETAILS,
  payload: { status: 'SUCCESS', data },
});

export const errorCartData = (error) => ({
  type: actions.RECEIVE_CART_DETAILS,
  payload: { status: 'ERROR', error },
});
