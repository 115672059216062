import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function Panel({
  minHeight, headerTitle, children, height, noOverflow, icon,
}) {
  const useStyles = makeStyles({
    panelHeight: {
      minHeight,
    },
    panelHeader: {
      background: '#0074D9',
      color: '#FFFFFF',
      border: '1px solid #ffff',
      borderRadius: '5px',
      textAlign: 'left',
      paddingLeft: '10px',
    },
    child: !noOverflow ? {
      overflowY: 'scroll',
      height: minHeight,
      color: '#FFFFFF',
    } : '',
    panel: {
      margin: '10px 0 10px 0',
      background: 'rgba(0, 0, 0, 0.6)',
    },
  });
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.panel}>
      <div className={classes.panelHeight}>
        <div className={classes.panelHeader}>
          <Typography variant="h6" gutterBottom>
            {icon}
&nbsp;
            {' '}
            {headerTitle}
          </Typography>
        </div>
        <div className={classes.child}>
          {children}
        </div>
      </div>
    </Paper>
  );
}

export default Panel;
