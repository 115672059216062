import getUserData from "../../utils";
import { CURRENT_ENV } from "../../utils/services/api-config";
import * as actions from "../actions";

const userId = getUserData()?.token?.emailid;
const initialState = {
  Context_param: {
    ProjectName: '',
    ProductID: '',
    UserID: userId,
    ProjectID: '',
    ProductSuite: '',
    StageID: "DATA_INPUT",
    FeatureID: "",
    FunctionID: "",
    Environment: CURRENT_ENV,
  },
  userInputData: {},
  resultData: null,
  consoleList: [],
  isCanvasClicked: false,
  canvasList: [],
  resultTabVal: 0,
};

const vnetUserData = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PROJECT_DETAILS: {
      const projectDetails = action.payload;
      return { ...state, projectDetails };
    }
    case actions.SET_FEATURE_DATA: {
      const Context_param = { ...state.Context_param, ...action.payload };
      return { ...state, Context_param };
    }
    case actions.SET_INPUT_DATA: {
      const userInputData = { ...state.userInputData, ...action.payload };
      return { ...state, userInputData };
    }
    case actions.CLEAR_INPUT_DATA: {
      const userInputData = {};
      return { ...state, userInputData };
    }
    case actions.SET_RESULT: {
      const resultData = action.payload; 1;
      return { ...state, resultData };
    }
    case actions.SET_CONSOLE: {
      const consoleList = state.consoleList ? [...state.consoleList, action.payload] : [action.payload];
      return { ...state, consoleList };
    }
    case actions.SET_CANVA_LIST: {
      const canvasList = action.payload;
      return { ...state, canvasList };
    }
    case actions.CLEAR_RESULT: {
      return { ...state, resultData: null };
    }
    case actions.IS_CANVA_CLICK: {
      return { ...state, isCanvasClicked: action.payload };
    }
    case actions.SET_RESULT_TABVAL: {
      return { ...state, resultTabVal: action.payload };
    }
    default:
      return state;
  }
};

export default vnetUserData;
