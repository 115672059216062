import React, { memo, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faBookReader } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { withStyles } from "@material-ui/core/styles";

function InfoToolTip({
  title = "", info = "", placement = 'bottom', children,
}) {
  return (
    <Tooltip
      title={(
        <>
          {title && <p><strong>{title}</strong></p>}
          {info && <span>{info}</span>}
        </>
    )}
      arrow
      placement={placement}
    >
      <span>
        {' '}
        <FontAwesomeIcon icon={faInfoCircle} />
        {' '}
        {children}
      </span>
    </Tooltip>
  );
}
export function TextFieldToolTip({
  title = "", info = "", placement = 'top', children,
}) {
  return (
    <Tooltip
      title={(
        <>
          {info && <span>{info}</span>}
        </>
    )}
      arrow
      placement={placement}
    >
      <span>
        {' '}
        {children}
      </span>
    </Tooltip>
  );
}
export function PopupInfoToolTip({ popupInfo = [], icon }) {
  const [open, setOpen] = useState(true);
  const handleToolTip = () => {
    setOpen(!open);
  };
  return (
    <GreyTooltip
      title={(
        <>
          <strong>User Guide</strong>
          <List type="1">
            {popupInfo?.map((data) => (
              <li>{data}</li>
            ))}
          </List>
        </>
    )}
      arrow
      placement="top"
    >
      <IconContainer>
        {' '}
        <FontAwesomeIcon icon={icon || faBookReader} />
      </IconContainer>

    </GreyTooltip>
  );
}

export default memo(InfoToolTip);

export const GreyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#666666",
    color: "#ffff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: "#666666",
  },
}))(Tooltip);

const IconContainer = styled.span`
  cursor: pointer;
`;

const List = styled.ol`
  padding-left: 8px;
`;
