import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import { StyledButton } from "../../styles/common";
import { addToCart, removeFromCart } from "../../store/actions/cart";
import SocialShare from "./SocialShare";

const AddToCartButton = ({ title }) => {
  const { solutionId } = useParams();
  const dispatch = useDispatch();

  const [isInCart, setisInCart] = useState(false);

  const cartItems = useSelector((state) => state.cart.items);

  useEffect(() => {
    console.log("cartItems", cartItems);
    if (cartItems.some((item) => item === solutionId)) setisInCart(true);
    else setisInCart(false);
  }, [cartItems]);

  const onAddToCartClicked = () => {
    if (isInCart) {
      dispatch(removeFromCart(solutionId));
      setisInCart(false);
    } else {
      dispatch(addToCart(solutionId));
      setisInCart(true);
    }
  };

  return (
    <Container>
      <StyledButton
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<AddShoppingCartIcon />}
        onClick={onAddToCartClicked}
      >
        {isInCart ? "Remove from Cart" : "Add to Cart"}
      </StyledButton>
      <SocialShare text={title} />
    </Container>
  );
};

export default AddToCartButton;
const Container = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
