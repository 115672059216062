import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

export const CorrelationPlotForm = memo(({ getCategories }) => {
  const dispatch = useDispatch();

  useEffect(async () => {
    const result = await getCategories();
  }, []);

  return (
    <PlotsContainer>
      To create correlation plot on your dataset, please click on save and execute
    </PlotsContainer>
  );
});
const PlotsContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
    min-width: 190px;
  }
`;
