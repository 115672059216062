import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

import roboticSegmentation_sol1 from "../../../assets/images/app/roboticSegmentation.webp";

const RoboticSegmentation = () => {
  const [result, setResult] = useState(false);

  const showImage = () => {
    setResult({
      video: "",
      text: "The left and right instruments are segmented from the image and are highlighted as red and blue respectively",
      image:
        "https://vlife.virtusa.com/instrument_segmentation/sample_image_output/output.jpg",
    });
  };
  const showVideo = () => {
    setResult({
      text: "The left and right instruments are segmented from the video and are highlighted as red and blue respectively  ",
      video:
        "https://vlife.virtusa.com/instrument_segmentation/sample_video_output/output.mp4",
      image: "",
    });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Detection, tracking, and pose estimation of surgical instruments are
            crucial tasks for computer assistance during minimally invasive
            robotic surgery. In many cases, the first step is the automatic
            segmentation of surgical tools.
          </p>
          <p>
            Robot-assisted Minimally Invasive Surgery (RMIS) overcomes many of
            the limitations of traditional laparoscopic Minimally Invasive
            Surgery (MIS), providing the surgeon with improved control over the
            anatomy with articulated instruments and dexterous master
            manipulators.
          </p>
          <p>
            The solution aims to track the left and right instruments by
            color-coding them as they move while performing surgery.
          </p>
          <p>
            State of the art CNN UNet model is used for training. The steps in
            preparing the data, training and testing the model are as follows:
            <ul>
              <li>
                The video data is made into multiple frames for preparing
                training data.
              </li>
              <li>
                The data is prepared by labelling images using the annotation
                tool, LabelMe, by drawing polygons around the robotic
                instruments and storing the coordinates into a JSON file.
              </li>
              <li>
                The images, along with the annotations, are passed on to the
                UNet model for training.
              </li>
              <li>
                The model’s performance is tested by measuring the accuracy
                using the dice loss metric.
              </li>
            </ul>
          </p>
          <p>
            The Dice coefficient is a popular loss function for image
            segmentation tasks, which is essentially a measure of overlap
            between two samples. This measure ranges from 0 to 1, where a dice
            coefficient of 1 denotes perfect and complete overlap. The dice
            coefficient was originally developed for binary data.
          </p>
          <p>
            The model is trained on 100 images and can successfully track the
            left and right robotic instruments.
          </p>
          <strong> Application: </strong>
          <p>
            The tool can be used for simple 2D applications such as overlay
            masking or 2D tracking, but also for more complex 3D tasks such as
            pose estimation.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/InstrumentSegmentation/roboticsegmentation.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="http://opencas.webarchiv.kit.edu/?q=node/30s"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-around"
            alignItems="center"
            alignContent="center"
          >
            <Grid xs={12} sm={6} md={3}>
              <StyledFigure>
                <video height="200px" width="200px" controls>
                  <source
                    src="https://vlife.virtusa.com/instrument_segmentation/sample_video_input/Video.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showVideo}
                >
                  Sample Video
                </StyledButton>
              </StyledFigure>
            </Grid>
            <Grid xs={10} sm={6} md={3}>
              <StyledFigure>
                <Image
                  src={roboticSegmentation_sol1}
                  alt=""
                />
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showImage}
                >
                  Sample Image
                </StyledButton>
              </StyledFigure>
            </Grid>
          </Grid>
          {result && (
            <section>
              <Result>
                <strong>Result:</strong>
                <Paragraph>{result.text}</Paragraph>
              </Result>
              <br />
              {result.image && (
                <Grid item xs={12}>
                  <ResultImage
                    src={result.image}
                    alt=""
                  />
                </Grid>
              )}
              {result.video && (
                <Grid item xs={12}>
                  <Video height="350px" width="350px" controls>
                    <source src={result.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </Video>
                </Grid>
              )}
            </section>
          )}
          <br />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(RoboticSegmentation);
const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Image = styled.img`
  border-radius: 3px;
  height: 200px;
  width: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 200px;
    min-width: 200px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    min-width: 200px;
    float:center;
  }
`;
const Video = styled.video`
  height: 300px;
  width: 400px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 250px;
    max-height:300px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    max-height: 200px;
  }
`;
const ResultImage = styled.img`
  height: 300px;
  width: 450px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 350px;
    max-height:300px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
    max-height: 200px;
  }
`;
