import React, { memo, useState } from "react";
import {
  Grid, TextField, FormControl, MenuItem,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  HighchartContainer,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const FreightCostPrediction = () => {
  const initialData = {
    country: "",
    fullfilled: "",
    shipment: "",
    subclassification: "",
    brand: "",
    unitmeasure: "",
    itemquality: "",
    packprice: "",
    unitprice: "",
    manufacturingsite: "",
    firstline: "",
    lineitem: "",
    weight: "",
    waiting: "",
    delivery: "",
    recordeddate: "",
  };
  const countrys = [
    {
      value: 0,
      label: "Afghanistan",
    },
    {
      value: 1,
      label: "Angola",
    },
    {
      value: 2,
      label: "Belize",
    },
    {
      value: 3,
      label: "Benin",
    },
    {
      value: 4,
      label: "Adult Botswana",
    },
    {
      value: 5,
      label: "Burkina Faso",
    },
    {
      value: 6,
      label: "Burundi",
    },
    {
      value: 7,
      label: "Cameroon",
    },
    {
      value: 8,
      label: "Congo",
    },
    {
      value: 9,
      label: "Côte d Ivoire",
    },
    {
      value: 10,
      label: "Dominican Republic",
    },
    {
      value: 11,
      label: "Ethiopia",
    },
    {
      value: 12,
      label: "Ghana",
    },
    {
      value: 13,
      label: "Guatemala",
    },
    {
      value: 14,
      label: "Guinea",
    },
    {
      value: 15,
      label: "Guyana",
    },
    {
      value: 16,
      label: "Haiti",
    },
    {
      value: 17,
      label: "Kazakhstan",
    },
    {
      value: 18,
      label: "Kenya",
    },
    {
      value: 19,
      label: "Kyrgyzstan",
    },
    {
      value: 20,
      label: "Lebanon",
    },
    {
      value: 21,
      label: "Lesotho",
    },
    {
      value: 22,
      label: "Liberia",
    },
    {
      value: 23,
      label: "Libya",
    },
    {
      value: 24,
      label: "Malawi",
    },
    {
      value: 25,
      label: "Mali",
    },
    {
      value: 26,
      label: "Mozambique",
    },
    {
      value: 27,
      label: "Namibia",
    },
    {
      value: 28,
      label: "Nigeria",
    },
    {
      value: 29,
      label: "Pakistan",
    },
    {
      value: 30,
      label: "Rwanda",
    },
    {
      value: 31,
      label: "Senegal",
    },
    {
      value: 32,
      label: "Sierra",
    },
    {
      value: 33,
      label: "South Africa",
    },
    {
      value: 34,
      label: "South Sudan",
    },
    {
      value: 35,
      label: "Sudan",
    },
    {
      value: 36,
      label: "Swaziland",
    },
    {
      value: 37,
      label: "Tanzania",
    },
    {
      value: 38,
      label: "Togo",
    },
    {
      value: 39,
      label: "Uganda",
    },
    {
      value: 40,
      label: "Vietnam",
    },
    {
      value: 41,
      label: "Zambia",
    },
    {
      value: 42,
      label: "Zimbabwe",
    },
  ];

  const fullfilleds = [
    {
      value: 0,
      label: "Direct Drop",
    },
    {
      value: 1,
      label: "From RDC",
    },
  ];

  const shipments = [
    {
      value: 0,
      label: "Air",
    },
    {
      value: 1,
      label: "Air Charter",
    },
    {
      value: 2,
      label: "Ocean",
    },
    {
      value: 3,
      label: "Truck",
    },
  ];
  const subclassifications = [
    {
      value: 0,
      label: "ACT",
    },
    {
      value: 1,
      label: "Adult",
    },
    {
      value: 2,
      label: "HIV test",
    },
    {
      value: 3,
      label: "HIV test - Ancillary",
    },
    {
      value: 4,
      label: "Malaria",
    },
    {
      value: 5,
      label: "Pediatric",
    },
  ];
  const brands = [
    {
      value: 0,
      label: "ABBOTT LABORATORIES (PUERTO RICO)",
    },
    {
      value: 1,
      label: "ABBOTT LOGISTICS B.V",
    },
    {
      value: 2,
      label: "ABBVIE LOGISTICS (FORMERLY ABBOTT LOGISTICS BV)",
    },
    {
      value: 3,
      label: "ABBVIE, SRL (FORMALLY ABBOTT LABORATORIES INTERNATIONAL CO.)",
    },
    {
      value: 4,
      label: "ACCESS BIO, INC.",
    },
    {
      value: 5,
      label: "ACCOUN NIGERIA LIMITED",
    },
    {
      value: 6,
      label: "ACOUNS NIGERIA LTD",
    },
    {
      value: 7,
      label: "ACTION MEDEOR E.V.",
    },
    {
      value: 8,
      label: "AHN (PTY) LTD (AKA UCB (S.A.)",
    },
    {
      value: 9,
      label: "AMSTELFARMA B.V",
    },
    {
      value: 10,
      label: "ASPEN PHARMACARE",
    },
    {
      value: 11,
      label: "AUROBINDO PHARAM (SOUTH AFRICA)",
    },
    {
      value: 12,
      label: "Abbott GmbH & Co. KG",
    },
    {
      value: 13,
      label: "Aurobindo Pharma Limited",
    },
    {
      value: 14,
      label: "B&C GROUP S.A.",
    },
    {
      value: 15,
      label: "BIO-RAD LABORATORIES (FRANCE)",
    },
    {
      value: 16,
      label: "BIO-RAD LABORATORIES PTY LTD. (SOUTH AFRICA)",
    },
    {
      value: 17,
      label: "BIOLYTICAL LABORATORIES INC",
    },
    {
      value: 18,
      label: "BRISTOL-MYERS SQUIBB",
    },
    {
      value: 19,
      label: "BUNDI INTERNATIONAL DIAGNOSTICS LTD",
    },
    {
      value: 20,
      label: "CENTRAL PHARMACEUTICAL COMPANY NO. 1",
    },
    {
      value: 21,
      label: "CHEMBIO DIAGNOSTIC SYSTEMS, INC.",
    },
    {
      value: 22,
      label: "CIPLA LIMITED",
    },
    {
      value: 23,
      label: "EMCURE PHARMACEUTICALS LTD",
    },
    {
      value: 24,
      label: "ETHNOR DEL ISTMO S.A.",
    },
    {
      value: 25,
      label: "EY Laboratories",
    },
    {
      value: 26,
      label: "GILEAD SCIENCES IRELAND, INC",
    },
    {
      value: 27,
      label: "GLAXOSMITHKLINE EXPORT LIMITED",
    },
    {
      value: 28,
      label: "HETERO LABS LIMITED",
    },
    {
      value: 29,
      label: "HUMAN GMBH",
    },
    {
      value: 30,
      label: "Hoffmann-La Roche ltd Basel",
    },
    {
      value: 31,
      label: "IDA FOUNDATION",
    },
    {
      value: 32,
      label: "IDIS LIMITED",
    },
    {
      value: 33,
      label: "IMRES B.V",
    },
    {
      value: 34,
      label: "INTERNATIONAL HEALTHCARE DISTRIBUTORS",
    },
    {
      value: 35,
      label: "INVERNESS MEDICAL INNOVATIONS HONG KONG LTD",
    },
    {
      value: 36,
      label: "INVERNESS MEDICAL INNOVATIONS SOUTH AFRICA (PTY) LTD",
    },
    {
      value: 37,
      label: "JANSSEN SCIENCES IRELAND UC (FORMERLY JANSSEN R&D IRELAND)",
    },
    {
      value: 38,
      label: "JSI R&T INSTITUTE, INC.",
    },
    {
      value: 39,
      label: "KAS MEDICS LIMITED",
    },
    {
      value: 40,
      label: "LAWRENCE LABORATORIES (SUBSIDIARY OF BRISTOL MYERS SQUIBB)",
    },
    {
      value: 41,
      label: "MEDMIRA EAST AFRICA LTD",
    },
    {
      value: 42,
      label:
        "MERCK SHARP & DOHME IDEA GMBH (FORMALLY MERCK SHARP & DOHME B.V.)",
    },
    {
      value: 43,
      label: "MICRO LABS LIMITED",
    },
    {
      value: 44,
      label: "MISSIONPHARMA A/S",
    },
    {
      value: 45,
      label: "MSD LATIN AMERICA SERVICES, S. DE R.L. DE C.V",
    },
    {
      value: 46,
      label: "MYLAN LABORATORIES LTD (FORMERLY MATRIX LABORATORIES)",
    },
    {
      value: 47,
      label: "NOVARTIS PHARMA SERVICES AG",
    },
    {
      value: 48,
      label: "OMEGA DIAGNOSTICS LTD",
    },
    {
      value: 49,
      label: "Orasure Technologies Inc.",
    },
    {
      value: 50,
      label: "Orgenics, Ltd",
    },
    {
      value: 51,
      label: "PHARMACY DIRECT",
    },
    {
      value: 52,
      label: "PLURIPHARM S.A",
    },
    {
      value: 53,
      label: "PUETRO RICO PHARMACEUTICAL, INC",
    },
    {
      value: 54,
      label: "Premier Medical Corporation Ltd.",
    },
    {
      value: 55,
      label: "RAININ INSTRUMENT, LLC",
    },
    {
      value: 56,
      label: "RANBAXY Fine Chemicals LTD",
    },
    {
      value: 57,
      label: "REINBOLD EXPORT IMPORT",
    },
    {
      value: 58,
      label: "S.BUYS WHOLESALER",
    },
    {
      value: 59,
      label: "SCMS from RDC",
    },
    {
      value: 60,
      label: "SETEMA LIMITED PLC",
    },
    {
      value: 61,
      label: "SHANGHAI KEHUA BIOENGINEERING CO.,LTD.(KHB)",
    },
    {
      value: 62,
      label: "STRIDES ARCOLAB LIMITED",
    },
    {
      value: 63,
      label: "SUB-SAHARAN BIOMEDICAL P.L.C.",
    },
    {
      value: 64,
      label: "Standard Diagnostics, Inc.",
    },
    {
      value: 65,
      label: "SWORDS LABORATORIES",
    },
    {
      value: 66,
      label: "SYSMEX AMERICA INC",
    },
    {
      value: 67,
      label: "Standard Diagnostics, Inc",
    },
    {
      value: 68,
      label: "THE MEDICAL EXPORT GROUP BV",
    },
    {
      value: 69,
      label: "TURE PHARMACEUTICALS & MEDICAL SUPPLIES P.L.C.",
    },
    {
      value: 70,
      label: "Trinity Biotech, Plc.",
    },
    {
      value: 71,
      label: "WAGENIA.",
    },
    {
      value: 72,
      label: "ZEPHYR BIOMEDICALS",
    },
  ];

  const manufacturingsites = [
    {
      value: 0,
      label: "ABBSP",
    },
    {
      value: 1,
      label: "ABBVIE (Abbott) France",
    },
    {
      value: 2,
      label: "ABBVIE (Abbott) Japan Co. Ltd.",
    },
    {
      value: 3,
      label: "ABBVIE (Abbott) Logis. UK",
    },
    {
      value: 4,
      label: "ABBVIE (Abbott) St. P burg USA",
    },
    {
      value: 5,
      label: "ABBVIE GmbH & Co.KG Wiesbaden",
    },
    {
      value: 6,
      label: "ABBVIE Labs North Chicago US",
    },
    {
      value: 7,
      label: "ABBVIE Ludwigshafen Germany",
    },
    {
      value: 8,
      label: "Access BIO, L.C.",
    },
    {
      value: 9,
      label: "Alere Medical Co., Ltd",
    },
    {
      value: 10,
      label: "Aspen-OSD, Port Elizabeth, SA",
    },
    {
      value: 11,
      label: "Aurobindo Unit III, India",
    },
    {
      value: 12,
      label: "Aurobindo Unit VII, IN",
    },
    {
      value: 13,
      label: "BI, Ingelheim, Germany",
    },
    {
      value: 14,
      label: "BMS Evansville, US",
    },
    {
      value: 15,
      label: "BMS Meymac, France",
    },
    {
      value: 16,
      label: "BUNDI INTERNATIONAL DIAGNOSTICS LTD",
    },
    {
      value: 17,
      label: "Bio-Rad Laboratories",
    },
    {
      value: 18,
      label: "Boehringer Ing., Koropi, GR",
    },
    {
      value: 19,
      label: "Boehringer Ingelheim Roxane US",
    },
    {
      value: 20,
      label: "Bristol-Myers Squibb Anagni IT",
    },
    {
      value: 21,
      label: "Chembio Diagnostics Sys. Inc.",
    },
    {
      value: 22,
      label: "Cipla Ltd A-42 MIDC Mahar. IN",
    },
    {
      value: 23,
      label: "Cipla, Goa, India",
    },
    {
      value: 24,
      label: "Cipla, Kurkumbh, India",
    },
    {
      value: 25,
      label: "Cipla, Patalganga, India",
    },
    {
      value: 26,
      label: "EY Laboratories, USA",
    },
    {
      value: 27,
      label:
        "Emcure Plot No.P-2, I.T-B.T. Park, Phase II, MIDC, Hinjwadi, Pune, India",
    },
    {
      value: 28,
      label: "GSK Aranda",
    },
    {
      value: 29,
      label: "GSK Barnard Castle UK",
    },
    {
      value: 30,
      label: "GSK Cape Town Factory (South Africa)",
    },
    {
      value: 31,
      label: "GSK Crawley",
    },
    {
      value: 32,
      label: "GSK Mississauga (Canada)",
    },
    {
      value: 33,
      label: "GSK Ware (UK)",
    },
    {
      value: 34,
      label: "GSK, U1, Poznan, Poland",
    },
    {
      value: 35,
      label: "Gilead(Nycomed) Oranienburg DE",
    },
    {
      value: 36,
      label: "INVERNESS MEDICAL INNOVATIONS SOUTH AFRICA (PTY) LTD",
    },
    {
      value: 37,
      label: "Gland Pharma, Hyderabad, IN",
    },
    {
      value: 38,
      label: "Guilin OSD site, No 17, China.",
    },
    {
      value: 39,
      label: "Hetero Unit III Hyderabad IN",
    },
    {
      value: 40,
      label: "Hetero, Jadcherla, unit 5, IN",
    },
    {
      value: 41,
      label: "Human Diagnostic",
    },
    {
      value: 42,
      label: "INVERNESS ANY",
    },
    {
      value: 43,
      label: "INVERNESS ORGENICS LINE",
    },
    {
      value: 44,
      label: "Inverness Japan",
    },
    {
      value: 45,
      label: "Inverness USA",
    },
    {
      value: 46,
      label: "Ipca Dadra/Nagar Haveli IN",
    },
    {
      value: 47,
      label: "Janssen Ortho LLC, Puerto Rico",
    },
    {
      value: 48,
      label: "Janssen-Cilag, Latina, IT",
    },
    {
      value: 49,
      label: "KHB Test Kit Facility, Shanghai China",
    },
    {
      value: 50,
      label: "MSD Elkton , Ltd",
    },
    {
      value: 51,
      label: "MSD Manati, Puerto Rico DIRECT",
    },
    {
      value: 52,
      label: "SD Midrand, J burg, SA",
    },
    {
      value: 53,
      label: "PMSD Patheon, Canada",
    },
    {
      value: 54,
      label: "MSD South Granville.",
    },
    {
      value: 55,
      label: "MSD, Haarlem, NL",
    },
    {
      value: 56,
      label: "Macleods Daman Plant INDIA",
    },
    {
      value: 57,
      label: "MedMira Inc",
    },
    {
      value: 58,
      label: "Meditab (for Cipla) Daman IN",
    },
    {
      value: 59,
      label: "Medochemie Factory A, CY",
    },
    {
      value: 60,
      label: "Medopharm Malur Factory, INDIA LIMITED PLC",
    },
    {
      value: 61,
      label: "Mepro Pharm Wadhwan Unit II,LTD",
    },
    {
      value: 62,
      label: "Micro Labs Ltd LIMITED",
    },
    {
      value: 63,
      label: "Micro Labs, Hosur, P.L.C.",
    },
    {
      value: 64,
      label: "Micro labs, Verna, Goa, India.",
    },
    {
      value: 65,
      label: "Mylan (formerly Matrix) Nashik",
    },
    {
      value: 66,
      label: "Mylan,  H-12 & H-13, India",
    },
    {
      value: 67,
      label: "NA",
    },
    {
      value: 68,
      label: "Novartis Pharma AG, Switzerland",
    },
    {
      value: 69,
      label: "Novartis Pharma Suffern, USA",
    },
    {
      value: 70,
      label: "OMEGA Diagnostics, UK",
    },
    {
      value: 71,
      label: "Orasure Technologies.",
    },
    {
      value: 72,
      label: "Orasure Technologies, Inc US",
    },
    {
      value: 73,
      label: "Pacific Biotech",
    },
    {
      value: 74,
      label: "Premier Med. Corp Ltd.",
    },
    {
      value: 75,
      label: "Premier Medical Corporation",
    },
    {
      value: 76,
      label: "Ranbaxy Fine Chemicals LTD",
    },
    {
      value: 77,
      label: "Ranbaxy per Shasun Pharma",
    },
    {
      value: 78,
      label: "Ranbaxy per Shasun Pharma Ltd",
    },
    {
      value: 79,
      label: "Ranbaxy, Paonta Shahib, India",
    },
    {
      value: 80,
      label: "Remedica",
    },
    {
      value: 81,
      label: "Roche Basel",
    },
    {
      value: 82,
      label: "Roche Madrid",
    },
    {
      value: 83,
      label: "Standard Diagnostics, Korea",
    },
    {
      value: 84,
      label: "Strides, Bangalore, India",
    },
    {
      value: 85,
      label: "Trinity Biotech",
    },
    {
      value: 86,
      label: "Weifa A.S., Hausmanng",
    },
    {
      value: 87,
      label: "bioLytical Laboratories",
    },
  ];
  const firstlines = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const [parameters, setParameters] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "freightcostprediction",
  );

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Prediction of Freight Cost of Medications",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][0],
              y: data[1][1],
            },
            {
              name: data[2][0],
              y: data[2][1],
            },
            {
              name: data[3][0],
              y: data[3][1],
            },
            {
              name: data[4][0],
              y: data[4][1],
            },
            {
              name: data[5][0],
              y: data[5][1],
            },
            {
              name: data[6][0],
              y: data[6][1],
            },
            {
              name: data[7][0],
              y: data[7][1],
            },
            {
              name: data[8][0],
              y: data[8][1],
            },
            {
              name: data[9][0],
              y: data[9][1],
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      country: "0",
      fullfilled: "0",
      shipment: "0",
      subclassification: "0",
      brand: "0",
      unitmeasure: "3",
      itemquality: "22",
      packprice: "112",
      unitprice: "22",
      manufacturingsite: "0",
      firstline: "0",
      lineitem: "400",
      weight: "34",
      waiting: "0",
      delivery: "2020-01-09",
      recordeddate: "2020-01-09",
    };
    setParameters(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      country,
      fullfilled,
      shipment,
      subclassification,
      brand,
      unitmeasure,
      itemquality,
      packprice,
      unitprice,
      manufacturingsite,
      firstline,
      lineitem,
      weight,
      waiting,
      delivery,
      recordeddate,
    } = parameters;

    const params = `${country},${fullfilled},${shipment},${subclassification},${brand},${unitmeasure},${itemquality},${packprice},${unitprice},${manufacturingsite},${firstline},${lineitem},${weight},${waiting},${delivery},${recordeddate}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res[0]);
          console.log(res);
          formHighChart(res[1]);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Problem :</b>
            {' '}
            Pharma supply chain is a complex and expensive
            network to dwindle with. As competition with generic products rises,
            soaring logistics costs will be the factor that can seriously hurt
            pharmaceutical in the long term.
            <br />
            <br />
            <b>Solution:</b>
            {' '}
            Informed decision making is all it takes to bring
            down costs by a huge margin. 2 main factors –
            <b>Price</b>
            {' '}
            and
            <b>Priority</b>
            {' '}
            drive the decision of choosing a route. The solution
            at hand is built with price in focus and impact of other factors on
            final cost of shipment.
            <br />
            <br />
            <b>Approach:</b>
            {' '}
            The solution is trained on 10K+ records of open
            source supply chain health commodity shipment and pricing data. A
            machine learning model is robustly trained to accept 15+ variables
            pertaining to 15+ countries and predict the cost incurred to deliver
            the shipment.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_Freight_Cost/Data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_Freight_Cost/Notebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <StyledDemoContainer>
          <Grid container spacing={2}>
            <Grid item sm={1} />
            <Grid item xs={12} sm={4}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Country*"
                  name="country"
                  value={parameters.country}
                  onChange={onChangeFields}
                >
                  {countrys.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Shipment Mode*"
                  name="shipment"
                  value={parameters.shipment}
                  onChange={onChangeFields}
                >
                  {shipments.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Brand*"
                  name="brand"
                  value={parameters.brand}
                  onChange={onChangeFields}
                >
                  {brands.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Item Quantity*"
                  name="itemquality"
                  value={parameters.itemquality}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Unit Price*"
                  name="unitprice"
                  value={parameters.unitprice}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="First Line Designation*"
                  name="firstline"
                  value={parameters.firstline}
                  onChange={onChangeFields}
                >
                  {firstlines.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Weight Kilograms*"
                  name="weight"
                  value={parameters.weight}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Delivery Date*"
                  name="delivery"
                  value={parameters.delivery}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} />

            <Grid item xs={12} sm={4}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="FullFilled Via*"
                  name="fullfilled"
                  value={parameters.fullfilled}
                  onChange={onChangeFields}
                >
                  {fullfilleds.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Sub Classification"
                  name="subclassification"
                  value={parameters.subclassification}
                  onChange={onChangeFields}
                >
                  {subclassifications.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Unit measure per pack*"
                  name="unitmeasure"
                  value={parameters.unitmeasure}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Pack Price*"
                  name="packprice"
                  value={parameters.packprice}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Manufacturing Site*"
                  name="manufacturingsite"
                  value={parameters.manufacturingsite}
                  onChange={onChangeFields}
                >
                  {manufacturingsites.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="line item insurance*"
                  name="lineitem"
                  value={parameters.lineitem}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Waiting Days*"
                  name="waiting"
                  value={parameters.waiting}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Delivery Recorded Date*"
                  name="recordeddate"
                  value={parameters.recordeddate}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} />
          </Grid>
          <center>
            <Grid item xs={12}>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                SAMPLE DATA
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                EXECUTE
              </StyledButton>
            </Grid>
          </center>

          <Grid item xs={12}>
            <section>
              <Grid item xs={12}>
                {showSpinner && <Spinner text="Loading..." />}

                {resultData && (
                  <section>
                    <h3>Output</h3>
                    <Result>
                      <strong>Result:</strong>
                      {`THE ESTIMATED COST OF SHIPMENT IS ${resultData}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer id="container_1" display />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </section>
          </Grid>
        </StyledDemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(FreightCostPrediction);

const StyledDemoContainer = styled(DemoContainer)`
    text-align: left ;
`;
