import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  RadioGroup,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import { isObjKeyhasval } from "../../util";
import { ScalerContainer } from './StandardScaler';
import { subFeatureData } from '../../constants';
import { StyledInput } from "../../InputForm";
import { StyledInpuLabel } from "../DataTransformation/ArithmeticOperation";
import { TextFieldToolTip } from "../../components/InfoToolTip";

export const MinmaxScalarForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const dispatch = useDispatch();
  const [dropdownList, setdropdownList] = useState(null);
  const [error, setError] = useState({});

  const checkValidMax=(e)=>{ 
    if(( userInputData?.max && userInputData?.min) && Number(userInputData?.max) < Number(userInputData?.min) ){
     setError({...error, 'max': 'max value should be greater than min'});
     return
    }
    error['max'] &&   setError({...error, 'max': false});
  }


  const handleChange = (e) => {
    let val = e.target.value;

    dispatch(
      setInputData({
        ...userInputData,
        [e.target.name]: val,
      }),
    );
    error[e.target.name] && setError({ ...error, [e.target.name]: false});
  };

  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 300,
      },
    },
  };
  const handleFormReset = () => {
    dispatch(
      setInputData(
        subFeatureData['MinMax_Scaler']?.initialData
      )
    )
  };

  const isFormValid = () => {
    const errorObj = {};
    const validateObj = {
      target: userInputData?.target,
      min: userInputData?.min,
      max: userInputData?.max
    };
    Object.keys(validateObj).map((key) => {
      if (!validateObj[key]) {
        errorObj[key] = "Required Field";
      }
    });
    if (userInputData?.columns.length == 0) {
      errorObj.columns = "Required Field";
    }
    const errData = { ...error, ...errorObj };
    setError(errData);
    return isObjKeyhasval(errData);
  };
  return (
    <ScalerContainer width="100%">
      <Grid
        container
        spacing={3}
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} md={3}>
          <FormControl fullWidth error={error.target}>
            <StyledInpuLabel>Target</StyledInpuLabel>
            <Select
              labelId="mutiple-select-label"
              label="target"
              name="target"
              value={userInputData?.target || []}
              onChange={handleChange}
              MenuProps={MenuProps}
              variant="outlined"
            >
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {error.target && <FormHelperText>Required Field</FormHelperText>}

          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldToolTip info="column(s) to be scaled">

            <FormControl fullWidth error={error.columns}>
              <StyledInpuLabel>Feature</StyledInpuLabel>
              <Select
                labelId="mutiple-select-label"
                label="target"
                multiple
                name="columns"
                value={userInputData?.columns || []}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                variant="outlined"
              >
                {dropdownList?.map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    disabled={userInputData?.target?.indexOf(value) > -1}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={userInputData?.columns?.indexOf(value) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>
              {error.columns && <FormHelperText>Required Field</FormHelperText>}
            </FormControl>
          </TextFieldToolTip>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInput
            name="min"
            label="Min"
            variant="outlined"
            onChange={handleChange}
            onBlur={checkValidMax}
            fullWidth
            type="number"
            value={userInputData?.min}
            error={error.min}
            helperText={error.min}
            info="minimum of feature range"
          
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInput
            name="max"
            label="Max"
            variant="outlined"
            onChange={handleChange}
            fullWidth
            type="number"
            value={userInputData?.max}
            error={error.max}
            helperText={error.max}
            info="maximum of feature range"
            onBlur={checkValidMax}
          />
        </Grid>
      </Grid>
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </ScalerContainer>
  );
});

