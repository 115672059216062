const NavbarContent = {
  // new list
  Resources: {
    sections: {
      "case-studies": {
        heading: "Case Studies",
        category: [
          {
            heading: "IBRI",
            externalLink:
              "https://www.virtusa.com/success-stories/how-machine-learning-simulated-data-aws-and-virtusa-brought-innovation-to-diabetes-research-at-the-ibri",
          },
          {
            heading: "Astra Zeneca",
            externalLink:
              "https://vlife.virtusa.com/About/Virtusa_AZ_casestudy.mp4",
          },
          {
            heading: "Regeneron Hackathon",
            externalLink:
              "https://material.vlifevirtusa.com/regeneron/Renegenron_Commercial_Hackathon_Virtusa.pdf",
          },
        ],
      },
      publications: {
        heading: "Publications",
        category: [
          {
            heading: "IBRI Thesis",
            externalLink:
              "https://vlife.virtusa.com/IBRI/IBRI_Virtusa_Thesis.pdf",
          },
          {
            heading: "Bias Removal",
            externalLink:
              "https://material.vlifevirtusa.com/BiasRemoval/WSJ_white_Paper.pdf",
          },
        ],
      },
      media: {
        heading: "Media",
        category: [
          {
            heading: "Indiana Biosciences Research Institute Case study",
            externalLink:
              "https://www.virtusa.com/success-stories/how-machine-learning-simulated-data-aws-and-virtusa-brought-innovation-to-diabetes-research-at-the-ibri",
          },
          {
            heading: "Eliminating Bias in Machine Learning",
            externalLink:
              "https://www.virtusa.com/news-room/events/year_2021/july/eliminating-bias-in-machine-learning",
          },
          {
            heading: "AWS Re:Invent 2019 vLife Showcase video",
            externalLink: "https://www.youtube.com/watch?v=KgxTClsHSr4",
          },
          {
            heading: "AWS Data Exchange Provider",
            externalLink: "https://www.virtusa.com/partners/aws/data-analytics",
          },
          {
            heading: "AWS Marketplace Artifacts",
            externalLink:
              "https://aws.amazon.com/marketplace/seller-profile?id=4ffff9af-2a58-49ff-abdc-6c2d0e3ba850",
          },
          {
            heading: "Stanford University Affiliation",
            externalLink:
              "https://www.virtusa.com/news-room/press-releases/year_2019/September/11540051884",
          },
          {
            heading: "University of Texas Affiliation, AWS & Cardinal Health",
            externalLink:
              "https://www.virtusa.com/news-room/press-releases/year_2019/October/30102019",
          },
          {
            heading: "AWS Life Sciences Competency",
            externalLink:
              "https://www.virtusa.com/news-room/press-releases/year_2019/July/11540051801",
          },
          {
            heading: "HITRUST CSF® Certification",
            externalLink:
              "https://www.virtusa.com/news-room/press-releases/year_2019/november/12112019",
          },
          {
            heading: "Everest Group Recognition",
            externalLink:
              "https://www.virtusa.com/news-room/in_the_media/year_2020/july/everest-group-recognizes-virtusa-as-major-contender-medical-devices-digital-services-peak-matrix-2020",
          },
          {
            heading: "Stevie Awards",
            externalLink:
              "https://www.globenewswire.com/news-release/2019/09/04/1910850/0/en/Virtusa-Wins-Three-Gold-Stevie-Awards-in-2019-International-Business-Awards.html",
          },
        ],
      },
      "gen-ai": {
        heading: "Gen AI",
        headingLink: "/gen-ai",
      },
      "stanford-ai": {
        heading: "Stanford AI",
        headingLink: "/stanford-ai",
      },
      collaborators: {
        heading: "Collaborators",
        headingLink: "/developers-collaborators",
      },
      videos: {
        heading: "Videos",
        headingLink: "/videos",
      },
    },
  },
  Domain: {
    "bio-pharma": {
      heading: "Bio-Pharma",
      sections: {
        "commercial-analytics": {
          heading: "Commercial Analytics",
          category: [
            {
              heading: "Research & Development",
              path: "/categories/bio-pharma/commercial-analytics/research-and-development",
            },
            {
              heading: "Sales & Marketing",
              path: "/categories/bio-pharma/commercial-analytics/sales-and-marketing-ca",
            },
            {
              heading: "Product Manufacturing",
              path: "/categories/bio-pharma/commercial-analytics/product-manufacturing",
            },
            {
              heading: "Ingredient Sourcing",
              path: "/categories/bio-pharma/commercial-analytics/ingredient-sourcing",
            },
            {
              heading: "Warehousing",
              path: "/categories/bio-pharma/commercial-analytics/warehousing",
            },
            // {
            //     heading: "Drug Approval and Registration",
            // },
            {
              heading: "Customer Management",
              path: "/categories/bio-pharma/commercial-analytics/customer-management-CA",
            },
          ],
        },
        clinical_trials: {
          heading: "Clinical Trials",
          category: [
            {
              heading: "Research & Development",
              path: "/categories/bio-pharma/clinical-trials/research&development-CT/",
            },
            {
              heading: "Implementation",
              path: "/categories/bio-pharma/clinical-trials/implementations",
            },
            {
              heading: "Drug Approval & Registration",
              path: "/categories/bio-pharma/clinical-trials/drug-approval",
            },
            // {
            //     heading: "Drug Approval & Registration",
            //     imgUrl: 'ca/icon-6.jpg'
            // },
            {
              heading: "Customer Management",
              path: "/categories/bio-pharma/clinical-trials/customer-management-CT",
            },
          ],
        },
        "patient-engagement": {
          heading: "Patient Engagement",
          category: [
            {
              heading: "Patient Education",
              path: "/categories/bio-pharma/patient-engagement-biopharma/patient-education",
            },
            {
              heading: "Feedback Analysis",
              path: "/categories/bio-pharma/patient-engagement-biopharma/feedback-analysis",
            },
            {
              heading: "Remote Patient Monitoring",
              path: "/categories/bio-pharma/patient-engagement-biopharma/rpm",
            },
            {
              heading: "Engaging For Improvement",
              path: "/categories/bio-pharma/patient-engagement-biopharma/improvement-engaging",
            },
          ],
        },
        baas: {
          heading: "Bioinformatics As a Service",
          category: [
            {
              heading: "Signal Analysis",
              path: "/categories/bio-pharma/bioinformatics-as-a-service/signal-analysis",
            },
            {
              heading: "Variant Analysis",
              path: "/categories/bio-pharma/bioinformatics-as-a-service/variant-analysis",
            },
            {
              heading: "Regulatory & Quality",
              path: "/categories/bio-pharma/bioinformatics-as-a-service/regulatory&quality",
            },
            {
              heading: "Clinical Interpretation",
              path: "/categories/bio-pharma/bioinformatics-as-a-service/clinical-interpretation",
            },
            {
              heading: "Digital Pathology",
              path: "/categories/bio-pharma/bioinformatics-as-a-service/digital-pathology",
            },
          ],
        },
      },
    },
    "med-tech": {
      heading: "MedTech",
      sections: {
        "commercial-solutions": {
          heading: "Commercial Solutions",
          category: [
            {
              heading: "Commercial IT",
              path: "/categories/medtech/commercial-solutions/commercial-it",
            },
            {
              heading: "Sales & Marketing",
              path: "/categories/medtech/commercial-solutions/sales-marketing-cs",
            },
            {
              heading: "Manufacturing & Supply Chain",
              path: "/categories/medtech/commercial-solutions/supply-chain",
            },
            {
              heading: "Customer Service",
              path: "/categories/medtech/commercial-solutions/customer-service",
            },
            {
              heading: "Regulatory & Quality",
              path: "/categories/medtech/commercial-solutions/regulatory-quality",
            },
            // {
            //     heading: "HEOR - Reimbursement",
            // },
            {
              heading: "Shared Services- Finance",
              path: "/categories/medtech/commercial-solutions/shared-service",
            },
          ],
        },
        "connected-solutions": {
          heading: "Connected Solutions",
          category: [
            {
              heading: "Testing and Validation",
              path: "/categories/medtech/connected-solutions/testing-validation",
            },
            // {
            //     heading: "Connectivity Solution",
            // },
            {
              heading: "Legacy Modernization",
              path: "/categories/medtech/connected-solutions/legacy-modernization",
            },
            // {
            //     heading: "Privacy and Security",
            // },
          ],
        },
        "patient-engagement": {
          heading: "Patient Engagement",
          category: [
            {
              heading: "Patient Education and Support",
              path: "/categories/medtech/patient-engagement-medtech/patient-education&support",
            },
            {
              heading: "Remote Patient Monitoring",
              path: "/categories/medtech/patient-engagement-medtech/rpm-medtech",
            },
          ],
        },
        "provider-payer": {
          heading: "Provider and Payer Engagement",
          category: [
            {
              heading: "Provider Education and Training",
              path: "/categories/medtech/provider-and-payerengagement/provider-education",
            },
            {
              heading: "EMR Integration and FHIR Interoperability",
              path: "/categories/medtech/provider-and-payerengagement/emr-integration",
            },
            {
              heading: "Decision Support",
              path: "/categories/medtech/provider-and-payerengagement/decision-support",
            },
            {
              heading: "Procedure/ Treatment Support",
              path: "/categories/medtech/provider-and-payerengagement/procedure&treatment-support",
            },
            // {
            //     heading: "Contracting Models",
            // },
          ],
        },
      },
    },
    payer: {
      heading: "Payers",
      sections: {
        "member-journey": {
          heading: "Member Journey",
          category: [
            // {
            //     heading: "Enrollment and Interoperability",
            // },
            {
              heading: "Digitize Appeals and Grievances",
              path: "/categories/payers/member-journey/appeals-grievances",
            },
            {
              heading: "Member Customer Service",
              path: "/categories/payers/member-journey/member-customer-service",
            },
            {
              heading: "Member Outreach - Campaign",
              path: "/categories/payers/member-journey/member-outreach",
            },
          ],
        },
        "claims-processing": {
          heading: "Claims Processing ",
          category: [
            // {
            //     heading: "Benefit Configuration",
            //     // route: "/navigation/claims-processing/benefit-configuration",
            // },
            {
              heading: "Claims Modernization",
              path: "/categories/payers/claims-processing/claims-modernization",
            },
            {
              heading: "Identify Fraud, Waste and Abuse",
              path: "/categories/payers/claims-processing/identify-frauds",
            },
            {
              heading: "Billing and Payment",
              path: "/categories/payers/claims-processing/billing-payments",
            },
            {
              heading: "Analytics-Driven Personalization ",
              path: "/categories/payers/claims-processing/analytics-driven",
            },
          ],
        },
        "care-management": {
          heading: "Care Management",
          category: [
            {
              heading: "Population Health Management",
              path: "/categories/payers/care-management/population-health-management",
            },
            {
              heading: "Digitize Prior Authorization",
              path: "/categories/payers/care-management/digitize-authorization",
            },
            {
              heading: "Clinical Data-Interoperability",
              path: "/categories/payers/care-management/interoperability",
            },
            // {
            //     heading: "IoT Wearable Devices",
            // },
            // {
            //     heading: "Member Incentive Program",
            // },
          ],
        },
        "provider-journey": {
          heading: "Provider Journey",
          category: [
            {
              heading: "Provider Lifecycle Management",
              path: "/categories/payers/provider-journey/provider-lifecycle-mgmt",
            },
            {
              heading: "Provider Customer Service",
              path: "/categories/payers/provider-journey/provider-customer-service",
            },
            {
              heading: "Provider Performance Management",
              path: "/categories/payers/provider-journey/provider-performance",
            },
          ],
        },
      },
    },
    providers: {
      heading: "Providers",
      sections: {
        "population-health-mgmt": {
          heading: "Population Health Management",
          category: [
            {
              heading: "Risk Stratification",
              path: "/categories/providers/population-health-mgmt/risk-stratification",
            },
            {
              heading: "Care route Enablement ",
              path: "/categories/providers/population-health-mgmt/care-route",
            },
            {
              heading: "Disease Registries",
              path: "/categories/providers/population-health-mgmt/disease-registries",
            },
            {
              heading: "Gaps in Care Analysis",
              path: "/categories/providers/population-health-mgmt/care-analysis",
            },
          ],
        },
        "patient-engagement": {
          heading: "Patient Engagement",
          category: [
            {
              heading: "Patient Portal",
              path: "/categories/providers/patient-engagement-providers/patient-portal",
            },
            // {
            //     heading: "Longitudinal Record",
            // },
            {
              heading: "Kiosks",
              path: "/categories/providers/patient-engagement-providers/kiosks",
            },
            {
              heading: "Online Scheduling",
              path: "/categories/providers/patient-engagement-providers/online-scheduling",
            },
          ],
        },
        ancillaries: {
          heading: "Ancillaries",
          category: [
            {
              heading: "Revenue Cycle Management",
              path: "/categories/providers/ancillaries/revenue-cycle-mgmt",
            },
            {
              heading: "Laboratory Information Systems",
              path: "/categories/providers/ancillaries/laboratory-info",
            },
            // {
            //     heading: "Radiology Information Systems",
            // },
          ],
        },

        "advanced-analytics": {
          heading: "Advanced Analytics",
          category: [
            // {
            //     heading: "Precision Medicine and Genomics",
            // },
            {
              heading: "Advanced Clinical Decision Support",
              path: "/categories/providers/advanced-analytics/advanced-clinical-support",
            },
            {
              heading: "Artificial Intelligence & Machine Learning",
              path: "/categories/providers/advanced-analytics/ai-ml",
            },
          ],
        },
        "virtual-care": {
          heading: "Virtual Care",
          category: [
            // {
            //     heading: "Telehealth",
            // },
            {
              heading: "Remote Patient Monitoring",
              path: "/categories/providers/virtual-care-providers/rpm-vc",
            },
          ],
        },
      },
    },
  },
};
export default NavbarContent;
