const modelOptions = ['Model Selection', 'Compare All Model Metrics', 'Blend Models', 'Select Ensemble Models', 'Ensemble Models', 'Stack Models', 'Hyper Parameter Tune Model'];
const constants = {
  'Tabular Data': {
    data_processing: ["Missing Value Detection", "Drop Missing", "Fill Missing", "Feature Scaling", "Outliers Check", "Feature Extraction", "Log Transform"],
    eda: ["Pandas Profiling", "Sweet Viz", "Show All Datatypes", 'Generate EDA Charts'],
    model_training: ["Initiailize ML Pipeline"],
    model_prediction: modelOptions,
  },
  'Image Data': {
    data_processing: ["Missing Value Detection", "Drop Missing", "Fill Missing", "Feature Scaling", "Outliers Check", "Feature Extraction", "Image Data Preprocessing", "Log Transform"],
    eda: ["Display Sample Image"],
    model_training: ["Model Training with Transfer learning(sample)"],
  },
  'Upload Image Data': {
    data_processing: ["Missing Value Detection", "Drop Missing", "Fill Missing", "Feature Scaling", "Outliers Check", "Feature Extraction", "Image Data Preprocessing", "Log Transform"],
    eda: ["Show Uploaded Training Data Image"],
    model_training: ["Model Training with Transfer learning"],
    model_prediction: ['Prediction on Trained Transfer Learning Model'],
  },
  'After Image Prediction': {
    data_processing: null,
    eda: null,
    model_training: null,
    model_prediction: null,
  },
  'All Data': {
    data_processing: ["Missing Value Detection", "Drop Missing", "Fill Missing", "Feature Scaling", "Outliers Check", "Feature Extraction", "Image Data Preprocessing", "Log Transform"],
    eda: ["Pandas Profiling", "Sweet Viz", "Show All Datatypes", "Show Sample Images", "Generate EDA Charts", "Display Sample Image", "Show Uploaded Training Data Image"],
    model_training: ["Initiailize ML Pipeline", "Model Training with Transfer learning(sample)", "Model Training with Transfer learning", 'Prediction on Trained Transfer Learning Model'],
    model_prediction: modelOptions,
  },
};
const content = {
  Loading: {
    pandasProfiling: 'Running Pandas Profiling',
    sweetViz: 'Running Sweet Vizualization',
    showFetaures: 'Running Show All Datatypes',
    sampleData: 'Loading Sample Data',
    ageBinning: 'Loading Feature Extraction',
    dropMissing: 'Loading Drop Missing',
    fillMissing: 'Loading Fill Missing',
    getMissing: 'Getting Missing Values',
    logTransform: ' Running Log Transform',
    outliersCheck: 'Running Outliers Check',
    featureExtraction: 'Running Feature Extraction',
    featureScaling: 'Running Feature Scaling',
    setupModel: 'Initializing ML Pipeline',
    ensembleModels: 'Running Ensemble Models',
    selectModels: 'Running Selected Model',
    runAll: 'Running all ML Models',
    selectEnsemble: 'Running Select Ensemble',
    blendModel: 'Running Blend Models',
    stackModels: 'Running Stack Models',
    hyperTune: ' Running Hyper Parameter Tuning',
    genAllPlots: 'Generating All Plots',
    shapley: "Running Shapley",
    multiaccuracy: "Running multiaccuracy",
    uploadData: 'Uploading File',
    sampleImage: 'Loading Sample Image',
    imageModelTraining: 'Running model on image Data',
    uploadImagePred: 'upload image Data prediction',
  },
};
const endpoints = {
  pandasProfiling: 'EDA/PandasEda/',
  sweetViz: 'EDA/SweetEda/',
  showFetaures: 'EDA/showfeatures/',
  sampleImage: 'DataIngestion/sampledataimg/',
  uploadImage: 'DataIngestion/uploadfileimg/',
  showUploadedImage: 'ImageAI/getimagedata/',
  trainUploadedImage: 'ImageAI/trainmodelimg/',
  uploadedImagePred: 'ImageAI/getcustomimgpred/',
  ageBinning: 'DataPreprocessing/AgeBining/',
  dropMissing: 'DataPreprocessing/dropmissing/',
  fillMissing: 'DataPreprocessing/fillmissing/',
  getMissing: 'DataPreprocessing/getmissing/',
  logTransform: 'DataPreprocessing/logtransform/',
  outliersCheck: 'DataPreprocessing/outlierscheck/',
  featureExtraction: 'DataPreprocessing/AgeBining/',
  reg: {
    sampleData: 'DataIngestion/sampledataII/reg',
    featureScaling: 'Machinelearning/setupreg/?&userValue[]=',
    setupModel: 'Machinelearning/setupreg/',
    ensembleModels: 'Machinelearning/ensemblemodelsreg/',
    selectModels: 'Machinelearning/selectmodelsreg/',
    runAll: 'Machinelearning/runallreg/',
    selectEnsemble: 'Machinelearning/SelectEnsemblereg/',
    blendModel: 'Machinelearning/blendmodelsreg/',
    stackModels: 'Machinelearning/stackmodelsreg/',
    hyperTune: 'Machinelearning/tunemodelreg/',
  },
  cls: {
    sampleData: 'DataIngestion/sampledata/',
    featureScaling: 'Machinelearning/setupcls/?&userValue[]=',
    setupModel: 'Machinelearning/setupcls/',
    ensembleModels: 'Machinelearning/ensemblemodelscls/',
    selectModels: 'Machinelearning/selectmodelscls/',
    runAll: 'Machinelearning/runallcls/',
    selectEnsemble: 'Machinelearning/SelectEnsemblecls/',
    blendModel: 'Machinelearning/blendmodelscls/',
    stackModels: 'Machinelearning/stackmodelscls/',
    hyperTune: 'Machinelearning/tunemodelcls/',
  },
  genAllPlots: 'EDA/genallplots/',
  shapley: 'SAIL/RunShapley/',
  multiaccuracy: 'SAIL/RunMultiaccuracy/',
  uploadData: 'DataIngestion/uploadfile/',
  imageModelTraining: 'ImageAI/sampleimgpred/',
};
const noramalizeMethods = ['zscore', 'minmax', 'maxabs', 'robust', 'No Normalize'];
const noramalizeRegressionMethods = ['MAE', 'MS', 'RMSE', 'R2', 'RMSLE', 'MAPE'];
const modelsList = [
  { label: 'Logistic Regression', value: 'lr' },
  { label: 'K Nearest Neighbour', value: 'knn' },
  { label: 'Naives Bayes', value: 'nb' },
  { label: 'Decision Tree Classifier', value: 'dt' },
  { label: 'SVM – Linear Kernel', value: 'svm' },
  { label: 'SVM – Radial Kernel', value: 'rbfsvm' },
  { label: 'Gaussian Process Classifier', value: 'gpc' },
  { label: 'Multi Level Perceptron', value: ' mlp' },
  { label: 'Ridge Classifier', value: ' ridge' },
  { label: 'Random Forest Classifier', value: 'rf' },
  { label: 'Quadratic Discriminant Analysis', value: 'qda' },
  { label: 'Ada Boost Classifier', value: 'ada' },
  { label: 'Gradient Boosting Classifier', value: 'gbc' },
  { label: 'Extra Trees Classifier', value: 'et' },
  { label: 'Extreme Gradient Boosting', value: 'xgboost' },
  { label: 'Light Gradient Boosting', value: 'lightgbm' },
  { label: 'CatBoost Classifier', value: 'catboost' },
];
const regModelsList = [
  { label: 'Linear Regression', value: 'lr' },
  { label: 'Ridge Regression', value: 'ridge' },
  { label: 'Lasso Regression', value: 'lasso' },
  { label: 'Support Vector Regression', value: 'svm' },
  { label: 'Random Forest Regressor', value: 'rf' },
  { label: 'K Neighbors Regressor', value: 'knn' },
  { label: 'Decision Tree Regressor', value: 'dt' },
  { label: 'Gradient Boosting Regressor', value: 'gbr' },
  { label: 'Light Gradient Boosting', value: 'lightgbm' },
  { label: 'Extreme Gradient Boosting', value: 'xgboost' },

];
const selectEnsembleModelList = [
  { label: 'Random Forest Classifier', value: 'rf' },
  { label: 'Ada Boost Classifier', value: 'ada' },
  { label: 'Gradient Boosting Classifier', value: 'gbc' },
  { label: 'Extra Trees Classifier', value: 'et' },
  { label: 'Extreme Gradient Boosting', value: 'xgboost' },
  { label: 'Light Gradient Boosting', value: 'lightgbm' },
  { label: 'CatBoost Classifier', value: 'catboost' },
];
const tuneModelList = [
  { label: 'Logistic Regression', value: 'lr' },
  { label: 'K Nearest Neighbour', value: 'knn' },
  { label: 'Naives Bayes', value: 'nb' },
  { label: 'Decision Tree Classifier', value: 'dt' },
];
const imageDataModelOptions = [
  { label: 'Resnet50', value: 'Resnet' }, { label: 'InceptionV3', value: 'Inception' }, { label: 'SqueezeNet', value: 'SqueezeNet' }, { label: 'Densenet121', value: 'Densenet' }];
const optionsMapping = {
  'Pandas Profiling': {
    callBack: 'getEDAResult',
    args: 'pandasProfiling',
  },
  'Sweet Viz': {
    callBack: 'getEDAResult',
    args: 'sweetViz',
    fields: [{
      label: 'Target', name: 'sweetvizparams', setOptions: 'targetOptions', options: [],
    }],
  },
  "Generate EDA Charts": {
    callBack: 'getEDAResult',
    args: 'genAllPlots',
    fields: [{
      label: 'Target', name: 'genAllParams', setOptions: 'targetOptions', options: [],
    }],
  },
  'Show All Datatypes': {
    callBack: 'getShowFeaturesResults',
    args: 'showfeatures',
    fields: [{
      label: 'Features',
      name: 'showfeaturesParams',
      options: [
        { label: 'Show Numerical features', value: '0' },
        { label: 'Show Categorical  features', value: '1' },
        { label: 'Show unique count', value: '2' },
      ],
    }],
  },
  'Feature Scaling': {
    callBack: 'getFeatureScalingResult',
    args: 'featureScaling',
    fields: [
      {
        label: 'Target', name: 'sweetvizparams', setOptions: 'targetOptions', options: [],
      },
      { label: 'Normalized Method', name: 'featureScalingMethod', options: noramalizeMethods },
    ],
    resultText: 'Preprocessed Data',

  },
  'Sample Image': {
    callBack: 'getImageDataResult',
    args: 'sampleImage',
    resultText: 'Sample Data',
    fields: [
      { label: 'Select Sample', name: 'sampleImageOption', options: [{ label: 'Normal', value: 'sample1' }, { label: 'Pneumonia', value: 'sample2' }] },
    ],
  },
  'Sample Tabular': {
    callBack: 'getSampleData',
    args: 'sampleTabular',
    resultText: 'Sample Data',
    fields: [
      { label: 'Sample Data for', name: 'mlType', options: [{ label: 'Classification', value: 'cls' }, { label: 'Regression', value: 'reg' }] },
    ],
  },

  'Upload Tabular Data': {
    callBack: 'getUploadFileData',
    resultText: 'Upload File',
    fields: [
      { label: '', name: 'fileUpload', type: 'file' },
      { label: 'Task to be Performed', name: 'mlType', options: [{ label: 'Classification', value: 'cls' }, { label: 'Regression', value: 'reg' }] },
    ],
    args: 'uploadData',
  },
  'Upload Image Data': {
    callBack: 'getUploadImageData',
    resultText: 'Upload File',
    fields: [
      {
        label: '', name: 'fileUpload', type: 'file', helperText: 'Please upload .zip file only',
      },
    ],
    args: 'uploadData',
    popupHtml: `<strong><p>Points to Remember for Custom Image Training and Upload<p>
                    <p>1.Uploaded file should be in format of .zip.</p>
                    <p>2.Your image dataset must contain at least 2 different classes/types of images (e.g cat and dog) and you must collect at least 500 images for each of the classes to achieve maximum accuracy.</p>
                    <p>3.Folder structure of your uploaded dataset should be-</p>
                    <ul>   
                        <li>pets//train//dog//dog-train-images</li>
                        <li>pets//train//cat//cat-train-images</li>
                        <li>pets//train//squirrel//squirrel-train-images</li>
                        <li>pets//train//snake//snake-train-images</li>
                        </br>
                        <li> pets//test//dog//dog-test-images</li>
                        <li> pets//test//cat//cat-test-images</li>
                        <li>pets//test//squirrel//squirrel-test-images</li>
                        <li>pets//test//snake//snake-test-images</li>
                    </ul>
                    <p>4.Because model training is a compute intensive tasks, We advise to be patience while traning your model on large custom image datasets.</p><strong>`,
  },
  "Missing Value Detection": {
    callBack: 'getDataProcessingResult',
    args: 'getMissing',
    resultText: 'Preprocessed Data',

  },
  "Drop Missing": {
    callBack: 'getDataProcessingResult',
    args: 'dropMissing',
    fields: [
      { label: 'Drop Missing', name: 'dropMissingParams', placeholder: 'Example: 50' },
    ],
    flag: 1,
    resultText: 'Preprocessed Data',

  },
  "Fill Missing": {
    callBack: 'getDataProcessingResult',
    args: 'fillMissing',
    fields: [
      {
        label: 'Fill Missing',
        name: 'fillmissingParams',
        options: [
          { label: 'Fill Categorical  features', value: 'cat' },
          { label: 'Fill Numerical features', value: 'num' },
        ],
      },
    ],
    flag: 2,
    resultText: 'Preprocessed Data',

  },
  'Log Transform': {
    callBack: 'getDataProcessingResult',
    args: 'logTransform',
    fields: [
      { label: 'Log Transform', name: 'logTransformParams', isMultiSelect: true },
    ],
    flag: 3,
    isMultipleParams: true,
    resultText: 'Preprocessed Data',

  },
  "Feature Extraction": {
    callBack: 'getDataProcessingResult',
    args: 'featureExtraction',
    fields: [
      { label: 'Bins', name: 'ageBinningParams', placeholder: 'Example: 2' },
    ],
    flag: 4,
    isMultipleParams: true,
    resultText: 'Preprocessed Data',

  },
  "Outliers Check": {
    callBack: 'getDataProcessingResult',
    args: 'outliersCheck',
    fields: [
      { label: 'Outliers Check', name: 'outliersCheckParams', placeholder: 'Example: -2' },
    ],
    resultText: 'Preprocessed Data',

  },
  "Initiailize ML Pipeline": {
    callBack: 'setupModelBuiliding',
    args: 'setupModel',
    fields: [
      {
        label: 'Target', name: 'sweetvizparams', setOptions: 'targetOptions', options: [],
      },
      { label: 'Ignore Features(optional)', name: 'ignoreFeatures', optional: true },
      { label: 'Normalize Method', name: 'normalizedMethod', options: noramalizeMethods },
      { label: 'Train Size', name: 'trainSize', placeholder: 'Example: 0.7' },
    ],
    resultText: 'ML Pipeline Configuration',
  },
  "Select Ensemble Models": {
    callBack: 'getModelEvaluationResult',
    fields: [
      { label: 'Folds', name: 'fold', placeholder: 'Example: 2' },
      {
        label: 'Model',
        name: 'selectedEnsembleModel',
        options: selectEnsembleModelList,
        regOptions: [
          { label: 'Random Forest Regressor', value: 'rf' },
          { label: 'Gradient Boosting Regressor', value: 'gbr' },
          { label: 'Light Gradient Boosting', value: 'lightgbm' }],
        isMultiSelect: true,
      },
    ],
    args: 'selectEnsemble',
    flag: '0',
    resultText: 'Score Grid',

  },
  "Ensemble Models": {
    callBack: 'getModelEvaluationResult',
    args: 'ensembleModels',
    fields: [

      { label: 'Folds', name: 'fold', placeholder: 'Example: 2' },
      { label: 'Method', name: 'selectedmethod', options: ['Bagging', 'Boosting'] },
      {
        label: 'Optimize', name: 'selectedOptimize', options: ['Accuracy', 'AUC', 'Recall', 'Precision', 'F1', 'Kappa'], regOptions: noramalizeRegressionMethods,
      },
      { label: 'Estimator', name: 'estimator', placeholder: 'Example: 10' },
      {
        label: 'Model',
        name: 'ensembleModel',
        options: [
          { label: 'Random Forest Classifier', value: 'rf' },
          { label: 'Ada Boost Classifier', value: 'ada' },
          { value: 'gbc', label: 'Gradient Boosting Classifier' },
          { value: 'et', label: 'Extra Trees Classifier' },
        ],
        regOptions: [],
      },
    ],
    resultText: 'Score Grid For Ensemble Model',
  },
  "Blend Models": {
    callBack: 'getModelEvaluationResult',
    args: 'blendModel',
    fields: [
      {
        label: 'Optimize', name: 'selectedOptimize', options: ['Accuracy', 'AUC', 'Recall', 'Precision', 'F1', 'Kappa'], regOptions: noramalizeRegressionMethods,
      },
      { label: 'Folds', name: 'fold', placeholder: 'Example: 2' },
      { label: 'Save Model', name: 'saveModel', options: [{ label: 'yes', value: 'True' }, { label: 'No', value: 'False' }] },
      {
        label: 'Model',
        name: 'selectedBlendModel',
        options: selectEnsembleModelList,
        regOptions: [{ label: 'Random Forest Regressor', value: 'rf' },
          { label: 'Gradient Boosting Regressor', value: 'gbr' },
          { label: 'Light Gradient Boosting', value: 'lightgbm' },
          { label: 'Extreme Gradient Boosting', value: 'xgboost' }],
        isMultiSelect: true,
      },

    ],
    flag: '0',
    resultText: 'Score Grid',

  },
  "Compare All Model Metrics": {
    callBack: 'getModelEvaluationResult',
    fields: [
      { label: 'No. of Top model selected', name: 'noOfmodels', placeholder: 'Example: 1' },
      { label: 'Folds', name: 'fold', placeholder: 'Example: 2' },
    ],
    args: 'runAll',
    resultText: 'Score Grid for ML Models',
  },
  "Model Selection": {
    callBack: 'getModelEvaluationResult',
    fields: [
      {
        label: 'models', name: 'selectedModel', options: modelsList, regOptions: regModelsList,
      },
      { label: 'Folds', name: 'fold', placeholder: 'Example: 2' },
    ],
    args: 'selectModels',
    resultText: 'Score Grid',
  },
  "Hyper Parameter Tune Model": {
    callBack: 'getModelEvaluationResult',
    fields: [
      {
        label: 'models', name: 'selectedTuneModel', options: tuneModelList, regOptions: regModelsList,
      },
      {
        label: 'Optimize', name: 'selectedOptimize', options: ['Accuracy', 'AUC', 'Recall', 'Precision', 'F1'], regOptions: noramalizeRegressionMethods,
      },
      { label: 'No. of Iteration', name: 'noOfIteration', placeholder: 'Example: 10' },
      { label: 'Folds', name: 'fold', placeholder: 'Example: 2' },
      { label: 'Save Model', name: 'saveModel', options: [{ label: 'yes', value: 'True' }, { label: 'No', value: 'False' }] },
    ],
    args: 'hyperTune',
    flag: '0',
    resultText: 'Score Grid For Hyper Tuned Model',
  },
  "Stack Models": {
    callBack: 'getModelEvaluationResult',
    fields: [
      {
        label: 'Optimize', name: 'selectedOptimize', options: ['Accuracy', 'AUC', 'Recall', 'Precision', 'F1', 'Kappa'], regOptions: noramalizeRegressionMethods,
      },
      { label: 'Folds', name: 'fold', placeholder: 'Example: 2' },
      { label: 'Save Model', name: 'saveModel', options: [{ label: 'yes', value: 'True' }, { label: 'No', value: 'False' }] },
      {
        label: 'Model',
        name: 'selectedStackModel',
        options: selectEnsembleModelList,
        regOptions: [{ label: 'Random Forest Regressor', value: 'rf' },
          { label: 'Gradient Boosting Regressor', value: 'gbr' },
          { label: 'Light Gradient Boosting', value: 'lightgbm' },
          { label: 'Extreme Gradient Boosting', value: 'xgboost' }],
        isMultiSelect: true,
      },

    ],
    args: 'stackModels',
    flag: '0',
    resultText: 'Score Grid',
  },
  Shapley: {
    callBack: 'runShapeley',
    fields: [
      {
        label: 'Target', name: 'sweetvizparams', setOptions: 'targetOptions', options: [],
      },
      { label: 'Model Name', name: 'modelName', options: [{ value: 'logistic', label: 'Logistic Regression' }, { value: 'NN', label: 'Neural Networks' }] },
      { label: 'No. of Data Points for Iterations', name: 'dataPointsCount', placeholder: 'Example: 2 or 100' },
    ],
    args: 'shapley',
  },
  Multiaccuracy: {
    callBack: 'runShapeley',
    fields: [
      {
        label: 'Target', name: 'sweetvizparams', setOptions: 'targetOptions', options: [],
      },
      {
        label: 'categorical feature', name: 'multiaccuracyCategoricalFeature', setOptions: 'categoricalFeatures', options: [],
      },
    ],
    args: 'multiaccuracy',
  },
  "Model Training with Transfer learning(sample)": {
    callBack: 'imageModelTraining',
    fields: [
      { label: 'Model', name: 'imageModelTraining', options: imageDataModelOptions },
    ],
    args: '',
    resultText: 'Prediction Score Grid',
  },
  "Model Training with Transfer learning": {
    callBack: 'uploadimageModelTraining',
    fields: [
      { label: 'Model', name: 'imageModelTraining', options: imageDataModelOptions },
      { label: 'No. of Classes', name: 'classCount', placeholder: 'EX: 2' },
      { label: 'No. of Epochs', name: 'epochCount', placeholder: 'EX: 10' },
      { label: 'Augmentation', name: 'augmentation', options: [{ label: 'True', value: 'True' }, { label: 'False', value: 'False' }] },
      { label: 'Batch Size', name: 'batchSize', placeholder: 'EX: 32' },

    ],
    args: '',
  },
  "Display Sample Image": {
    callBack: 'showImageData',
    resultText: 'Sample Image',
  },
  "Show Uploaded Training Data Image": {
    callBack: 'showUploadedImageData',
    fields: [
      { label: 'Class Name', name: 'uploadImageClass', placeholder: 'EX: Normal/Pneumonia' },
    ],
    resultText: 'Uploaded Trained Data Image',
  },
  'Prediction on Trained Transfer Learning Model': {
    callBack: 'getUploadImgPrediction',
    fields: [
      { label: 'No. of Images', name: 'imagesCount', placeholder: 'EX: 10' },
      { label: 'Test Set Lable', name: 'testSetLable', placeholder: 'EX: Normal' },
    ],
    resultText: 'Sample Image',
  },

};
export default constants;
export const CONTENT = content;
export const API = endpoints;
export const MODELOPTIONS = modelOptions;
export const TUNE_MODEL_OPTIONS = tuneModelList;
export const SELECT_ENSEMBLE_OPTIONS = selectEnsembleModelList;
export const NORMALIZED_OPTIONS = noramalizeMethods;
export const ALL_MODEL_OPTIONS = modelsList;
export const OPTIONS_MAPPING = optionsMapping;
