import React, { useState, useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import { Button } from "@material-ui/core";

const MediaCard = ({
  id,
  title,
  image,
  summary,
  description,
  demoView,
  externalUrl,
  internalUrl,
  selectedCard,
  setSelectedCard,
  type,
}) => {
  const history = useHistory();
  const location = useLocation();
  const refToScroll = useRef(null);

  const onCardClicked = (cardType, cardId) => {
    switch (cardType) {
      case "theme":
        history.push(`/themes/${cardId}`);
        break;
      case "page":
        history.push(internalUrl);
        break;
      case "primary-category":
        history.push(`/categories/${cardId}`);
        break;
      case "secondary-category":
        history.push(`${location.pathname}/${cardId}`);
        break;
      case "tertiary-category":
        history.push(`${location.pathname}/${cardId}`);
        break;
      case "solution":
      case "external":
        externalUrl ? (Array.isArray(externalUrl) ? window.open(externalUrl[0][1], '_blank') : window.open(externalUrl, '_blank')) : (demoView && history.push(`/solutions/${cardId}`));
        break;
      case "no-route":
        return false;
      default:
        setSelectedCard(cardId);
        refToScroll.current.scrollIntoView({
          behavior: "smooth",
          inline: "end",
        });
        break;
    }
  };

  const closeMoreInfo = () => {
    setSelectedCard(null);
    refToScroll.current.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "end",
    });
  };

  return (
    <StyledCardWrapper ref={refToScroll}>
      <StyledCard
        onClick={() => onCardClicked(type, id)}
        isExpanded={selectedCard === id}
        isExternal={externalUrl}
      >
        <CardImage src={image} alt={id} />
        <CardContent>
          <CardTitleContainer>
            <Tooltip title={title} arrow disableInteractive>
              <CardTitle>{title}</CardTitle>
            </Tooltip>
          </CardTitleContainer>
          <CardDivider />
          {summary && (
            <CardSummary>{summary}</CardSummary>
          )}
        </CardContent>
      </StyledCard>
      <StyledExpansion isExpanded={selectedCard === id}>
        <CardTitle>{`More on ${title}`}</CardTitle>
        <CardDivider />
        <StyledCloseIcon onClick={closeMoreInfo} />
        <Grid container direction="column" justify="space-between">
          <Grid item xs={12} sm={12}>
            <CardDescription>{ReactHtmlParser(description)}</CardDescription>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {demoView && (
              <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                <CardButton component={Link} to={`/solutions/${id}`}>
                  <strong>Try Demo</strong>
                </CardButton>
              </Grid>
            )}
            {externalUrl && (
              <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                {Array.isArray(externalUrl) ? (externalUrl.length > 0 && (
                  externalUrl?.map((extData) => (
                    <CardLink>
                      <a href={extData[1]} target="_blank" rel="noreferrer">{extData[0]}</a>
                    </CardLink>
                  )))
                ) : (
                  <CardGrid>
                  <CardLink>
                    <a href={externalUrl} target="_blank" rel="noreferrer"><strong>Try Demo</strong></a>
                  </CardLink>
                  {(title == "Site Monitoring") && (
                  <CardLink>
                    <a href={"https://material.vlifevirtusa.com/sitemonitoring/SiteMonitoring_Wireframe_Guide.pdf"} target="_blank" rel="noreferrer"><strong>Wireframe Guide</strong></a>
                  </CardLink>
                  )}
                  </CardGrid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </StyledExpansion>
      <StyledExpansionPad isExpanded={selectedCard === id} />
    </StyledCardWrapper>
  );
};

export default MediaCard;

export const CardGrid = styled.div`
  display: flex;
`;
export const CardImage = styled.img`
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 150px;
  object-fit: cover;
  color: #2941b7;
  font-size: 18px;
  text-align: left;
  text-transform: none;
  transition: transform 1s;

`;

export const CardContent = styled.div`
  margin: 10px 20px 0px 20px;
`;

export const CardTitleContainer = styled.div`
  height: 42px;
  display: table;
`;

export const CardTitle = styled.h4`
  color: #2941b7;
  font-size: 18px;
  text-align: left;
  text-transform: none;
  font-weight: 400;
  margin: 0px 0px 0px 0px;
  vertical-align: middle;
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CardDivider = styled.div`
  background-color: #fc7703;
  height: 1px;
  width: 100%;
  margin: 10px 0px;
`;

export const CardSummary = styled.p`
  color: #29293a;
  font-size: 15px;
  text-align: left;
  font-weight: 300;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  min-height: 90px;
`;

export const CardDescription = styled.p`
  color: #29293a;
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  text-transform: none;
  margin: 10px 0px 0px 0px;
`;

export const CardButton = styled(Button)`
&.MuiButtonBase-root {
  color: #ffffff;
  background-color: #dc1478;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  text-transform: none;
  padding: 10px 10px;
  box-shadow: 0 8px 10px -12px rgba(0, 0, 0, 0.5);
  &:hover {
    box-shadow: 0 10px 20px -12.125px rgba(0, 0, 0, 0.3);
    border: 2px solid #dd1166;
  }
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid #df1681;
  margin-top: 20px;
  min-width: 100%;
  text-align: center;
  display: block;
  a {
    text-decoration: none !important;
    color: #ffffff !important;
  }
}
`;

export const CardLink = styled.button`
  color: #ffffff;
  background-color: #dc1478;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  margin-left: 10px;
  text-transform: none;
  padding: 10px 5px;
  box-shadow: 0 8px 10px -12px rgba(0, 0, 0, 0.5);
  &:hover {
    box-shadow: 0 10px 20px -12.125px rgba(0, 0, 0, 0.3);
    border: 2px solid #dd1166;
  }
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid #df1681;
  margin-top: 20px;
  min-width: 100%;
  text-align: center;
  text-decoration: none;
  display: block;

  a {
    text-decoration: none !important;
    color: #ffffff !important;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 5px;
  }
`;

export const StyledCardWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`;

export const StyledCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 8px 20px -12px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 10px 30px -12.125px rgba(0, 0, 0, 0.1);
  }
  cursor: pointer;
  border-radius: 8px;
  height: 100%;
  border-bottom: ${(props) => props.isExternal && "2px solid #597688"};
  overflow:hidden;
  &:after {
    content: "";
    width: 0;
    height: 0;
    margin: calc(50% - 25px);
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #fff;
    visibility: ${(props) => (props.isExpanded ? "visible" : "hidden")};
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    img{
      transform: scale(1.15)
    }
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #2941b7;
  &:hover {
    color: #dc1478;
  }
`;

export const StyledExpansion = styled.div`
  background-color: #ffffff;
  box-shadow: 0 8px 20px -12px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 10px 30px -12.125px rgba(0, 0, 0, 0.1);
  }
  padding: 20px;
  height: ${(props) => (props.isExpanded ? "300px" : "0px")};
  width: calc(100vw - 140px);
  transition: all 0.3s ease-in-out;
  margin: 25px 0px;
  left: 50px;
  position: absolute;
  visibility: ${(props) => (props.isExpanded ? "visible" : "hidden")};
  border-radius: 8px;
  overflow-y: auto;

  @media only screen and (min-width: 1600px) {
    width: 1560px;
    margin: 25px calc(50vw - 850px);
  }

  @media only screen and (max-width: 600px) {
    width: calc(100vw - 90px);
    left: 25px;
  }
`;

export const StyledExpansionPad = styled.div`
  margin-bottom: ${(props) => (props.isExpanded ? "375px" : "0px")};
  transition: all 0.1s linear;
`;
