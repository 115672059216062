import solutionIds from "./solutionIds";
import etiology from "../../assets/images/solutions/etiology.webp";
import metastasis from "../../assets/images/solutions/metastasis.webp";
import tnm from "../../assets/images/solutions/tnm.webp";
import metastasisprocess from "../../assets/images/solutions/metastasis-process.webp";
import typesimaging from "../../assets/images/solutions/types-imaging.webp";
import imagingclinical from "../../assets/images/solutions/imaging-clinical.webp";
import oligometastasis from "../../assets/images/solutions/oligometastasis.webp";
import cancerincidence from "../../assets/images/solutions/cancer-incidence.webp";
import commoncancer from "../../assets/images/solutions/common-cancer.webp";
import genetics from "../../assets/images/solutions/genetics.webp";
import geneticmutation from "../../assets/images/solutions/genetic-mutation.webp";
import twohit from "../../assets/images/solutions/two-hit.webp";
import typestreatment from "../../assets/images/solutions/types-treatment.webp";
import individualcases from "../../assets/images/solutions/individual-cases.webp";
import clinicaltrails from "../../assets/images/solutions/clinical-trails.webp";
import insightids from "./insightids";

export default [
  {
    id: "rpm",
    title: "Remote Patient Monitoring",
    type: "tertiary-category",
    solutions: [
      solutionIds.incisioncare,
      solutionIds.caloriePredictor,
      solutionIds.ioTContinuous,
    ],
  },
  {
    id: "improvement-engaging",
    title: "Engaging for Improvement",
    type: "tertiary-category",
    solutions: [
      solutionIds.classfyingRheumotoidArthritisPatients,
      solutionIds.predictingType2Diabetes,
      solutionIds.brainTumorSegmentation,
      solutionIds.predictingCerebralInfarction,
      solutionIds.lungCancerComorbidities,
      solutionIds.liverDiseasePrediction,
      solutionIds.deepScap,
      solutionIds.heartAttackAnalysis,
    ],
  },
  {
    id: "patient-education",
    title: "Patient Education and Support",
    type: "tertiary-category",
    solutions: [
      solutionIds.predictingHeartDisease,
      solutionIds.timelinessOfCare,
      solutionIds.strokePrediction,
      solutionIds.lungCancerComorbidities,
      solutionIds.predictingParkinson,
      solutionIds.diseasePrognosis,
    ],
    insights: [insightids.insight20],
  },
  {
    id: "implementations",
    title: "Implementation",
    type: "tertiary-category",
    solutions: [
      solutionIds.drugCascading,
      solutionIds.predictingEligibility,
      solutionIds.sideEffects,
      solutionIds.brainTumorSegmentation,
      solutionIds.clinicalnotesPreprocessor,
      solutionIds.diseasePrognosis,
      solutionIds.nlpToolkit
    ],
  },
  {
    id: "drug-approval",
    title: "Drug Approval & Registration",
    type: "tertiary-category",
    solutions: [
      solutionIds.feasibilityAnalysis,
      solutionIds.mortalityEffect,
      solutionIds.sentimentAnalysis,
    ],
  },
  {
    id: "customer-management-CT",
    title: "Customer Management",
    type: "tertiary-category",
    solutions: [
      solutionIds.bioLemmatizer,
      solutionIds.networkGraphAnalyzer,
      solutionIds.selfService,
      solutionIds.strokePrediction,
      solutionIds.cohortSense,
    ],
    insights: [
      insightids.insight45,
      insightids.insight34,
      insightids.insight46,
    ],
  },
  {
    id: "customer-management-CA",
    title: "Customer Management",
    type: "tertiary-category",
    solutions: [solutionIds.customerSegmentation],
    insights: [
      insightids.insight45,
      insightids.insight34,
      insightids.insight46,
    ],
  },
  {
    id: "warehousing",
    title: "Warehousing",
    type: "tertiary-category",
    solutions: [],
    insights: [insightids.insight37],
  },
  {
    id: "ai-ml",
    title: "Artificial Intelligence & Machine Learning",
    type: "tertiary-category",
    solutions: [
      solutionIds.roboticSegmentation,
      solutionIds.retinaDamageClassification,
      solutionIds.arrhythmiaClassification,
      solutionIds.rom,
      solutionIds.diabeticRetinopathy,
      solutionIds.imageColorization,
      solutionIds.skinCancerAnalysis,
      solutionIds.bloodCellInception,
      solutionIds.pneumoniaDiagnosis,
      solutionIds.insuranceClaimPrediction,
      solutionIds.differentialPrivacy,
      solutionIds.encryptedTextData,
      solutionIds.differentialPrivacyText,
      solutionIds.responsibleAIModelExplainability,
      solutionIds.nlpToolkit,
      solutionIds.insuranceFraudDetection,
      solutionIds.responsibleAI,
    ],
  },
  {
    id: "patient-education&support",
    title: "Patient Education and Support",
    type: "tertiary-category",
    solutions: [
      solutionIds.mentalHealth,
      solutionIds.retweetCountPredictor,
      solutionIds.suicideIdeation,
      solutionIds.mentalHealthPrevalence,
      solutionIds.dementiaPrediction,
    ],
  },
  {
    id: "rpm-medtech",
    title: "Remote Patient Monitoring",
    type: "tertiary-category",
    solutions: [
      solutionIds.heartSoundsClassification,
      solutionIds.dataInteroperablePlatform,
      solutionIds.faceEmotionRecognizer,
      solutionIds.stressDetectionHRV,
      solutionIds.footWoundImageSegmentation,
    ],
  },
  {
    id: "research&development-CT",
    title: "Research & Development",
    type: "tertiary-category",
    solutions: [
      solutionIds.bioLemmatizer,
      solutionIds.predictingADR,
      solutionIds.geneticVariantClassification,
      solutionIds.antibioticResistancePrediction,
      solutionIds.classfyingRheumotoidArthritisPatients,
      solutionIds.predictingHeartDisease,
      solutionIds.predictingType2Diabetes,
      solutionIds.timePeriod2CompleteClinicalTrial,
      solutionIds.geneVariations,
      solutionIds.networkGraphAnalyzer,
      solutionIds.selfService,
      solutionIds.strokePrediction,
      solutionIds.breastCancerProteomes,
      solutionIds.predictingParkinson,
      solutionIds.cohortSense,
      solutionIds.chronicKidneyDisease,
      solutionIds.toxPrediction,
      solutionIds.proteinDeepLearning,
      solutionIds.aqueousSolubility,
      solutionIds.bern,
    ],
    insights: [insightids.insight59, insightids.insight68],
  },
  {
    id: "procedure&treatment-support",
    title: "Procedure/ Treatment Support",
    type: "tertiary-category",
    solutions: [
      solutionIds.roboticSegmentation,
      solutionIds.eyesStatePrediction,
      solutionIds.activationMetastasis,
      solutionIds.osteoarthritisSeverityPrediction,
      solutionIds.eEGClassification,
      solutionIds.eEGOnSensoryTask,
      solutionIds.shoulderImplant,
      solutionIds.pulmonaryEmbolism,
      solutionIds.polypSegmentation,
      solutionIds.bowelpreparation,
    ],
  },
  {
    id: "signal-analysis",
    title: "Signal Analysis",
    type: "tertiary-category",
    solutions: [solutionIds.sequenceGenerator],
  },
  {
    id: "regulatory&quality",
    title: "Regulatory & Quality",
    type: "tertiary-category",
    solutions: [
      solutionIds.primerClassifier,
      solutionIds.geneSimilarity,
      solutionIds.toxPrediction,
      solutionIds.proteinDeepLearning,
      solutionIds.aqueousSolubility,
    ],
  },
  {
    id: "testing-validation",
    title: "Testing and Validation",
    type: "tertiary-category",
    solutions: [
      solutionIds.chestXRaysCovid19,
      solutionIds.gastrointestinalCancer,
      solutionIds.cervicalCancerScreening,
      solutionIds.prostateCancerDetection,
      solutionIds.colonPathology,
      solutionIds.aclTear,
      solutionIds.surgicalSiteInfectionPrediction,
    ],
  },
  {
    id: "shared-service",
    title: "Shared Services- Finance",
    type: "tertiary-category",
    solutions: [solutionIds.freightCostPrediction, solutionIds.hls],
    insights: [insightids.insight47],
  },
  {
    id: "supply-chain",
    title: "Manufacturing & Supply Chain",
    type: "tertiary-category",
    solutions: [],
    insights: [
      insightids.insight42,
      insightids.insight43,
      insightids.insight39,
      insightids.insight38,
    ],
  },
  {
    id: "variant-analysis",
    title: "Variant Analysis",
    type: "tertiary-category",
    solutions: [
      solutionIds.geneticVariantClassification,
      solutionIds.humanproteinImageclassification,
      solutionIds.miceProteinClassification,
      solutionIds.genomePhenotype,
      solutionIds.genomeFrequencyAnalysis,
      solutionIds.genomicDataPreprocessor,
    ],
  },
  {
    id: "clinical-interpretation",
    title: "Clinical Interpretation",
    type: "tertiary-category",
    solutions: [
      solutionIds.geneticVariantClassification,
      solutionIds.antibioticResistancePrediction,
      solutionIds.deepNuclie,
      solutionIds.geneExpressionAnalysis,
      solutionIds.predictBioMechanical,
      solutionIds.breastCancerClassification,
      solutionIds.breastCancerProteomes,
      solutionIds.cancerLectins,
      solutionIds.predictOligomericState,
      solutionIds.multimodalSingleCellIntegration,
    ],
  },
  {
    id: "research-and-development",
    title: "Research and Development",
    type: "tertiary-category",
    solutions: [
      solutionIds.competitorAnalyzer,
      solutionIds.drugDiscovery,
      solutionIds.drugMOAPrediction,
      solutionIds.toxPrediction,
      solutionIds.proteinDeepLearning,
      solutionIds.aqueousSolubility,
    ],
    insights: [
      insightids.insight1,
      insightids.insight2,
      insightids.insight3,
      insightids.insight4,
    ],
  },
  {
    id: "sales-and-marketing-ca",
    title: "Sales & Marketing",
    type: "tertiary-category",
    solutions: [
      solutionIds.competitorAnalyzer,
      solutionIds.drugRecommendation,
      solutionIds.predictingAdClick,
      solutionIds.predictingEligibility,
    ],
  },
  {
    id: "commercial-it",
    title: "Commercial IT",
    type: "tertiary-category",
    solutions: [
      solutionIds.forecastingExcellence,
      solutionIds.differentialPrivacy,
      solutionIds.encryptedTextData,
      solutionIds.differentialPrivacyText,
    ],
    insights: [insightids.insight31],
  },
  {
    id: "sales-marketing-cs",
    title: "Sales & Marketing",
    type: "tertiary-category",
    solutions: [
      solutionIds.frequentPharmacyVisitor,
      solutionIds.providerDashboards,
      solutionIds.annotatedCSV
    ],
  },
  {
    id: "product-manufacturing",
    title: "Product Manufacturing",
    type: "tertiary-category",
    solutions: [solutionIds.forecastingExcellence],
    insights: [
      insightids.insight40,
      insightids.insight44,
      insightids.insight36,
      insightids.insight32,
    ],
  },
  {
    id: "customer-service",
    title: "Customer Service",
    type: "tertiary-category",
    solutions: [solutionIds.behaviouralAnalysis, solutionIds.annotatedCSV],
  },
  {
    id: "provider-customer-service",
    title: "Provider Customer Service",
    type: "tertiary-category",
    solutions: [solutionIds.medicareLocator],
  },
  {
    id: "ingredient-sourcing",
    title: "Ingredient Sourcing",
    type: "tertiary-category",
    solutions: [solutionIds.forecastingExcellence, solutionIds.jakInhibitors],
  },
  {
    id: "provider-education",
    title: "Provider Education and Training",
    type: "tertiary-category",
    solutions: [
      solutionIds.drugRecommendation,
      solutionIds.surgerySkills,
      solutionIds.cryoTherapyAnalysis,
      solutionIds.melanomaClassification,
      solutionIds.periodontalChart
    ],
  },
  {
    id: "decision-support",
    title: "Decision Support",
    type: "tertiary-category",
    solutions: [
      solutionIds.cervicalCancerRisk,
      solutionIds.intracranialHemorrhage,
      solutionIds.breastCancerMammography,
      solutionIds.sinGan,
    ],
  },
  {
    id: "emr-integration",
    title: "EMR Integration and FHIR Interoperability",
    type: "tertiary-category",
    solutions: [
      solutionIds.dataInteroperablePlatform,
      solutionIds.patternAnalyzer,
    ],
  },
  {
    id: "interoperability",
    title: "Clinical Data - Interoperability",
    type: "tertiary-category",
    solutions: [
      solutionIds.dataInteroperablePlatform,
      solutionIds.tabularDataSynthesizer,
      solutionIds.mirthHL7,
      solutionIds.federatedLearningMedicalData,
      solutionIds.imagedeIdentification,
      solutionIds.colbertAnalysis,
      solutionIds.textgenerationNLP,
    ],
  },
  {
    id: "revenue-cycle-mgmt",
    title: "Revenue Cycle Management",
    type: "tertiary-category",
    solutions: [
      solutionIds.plmLifeCycle,
      solutionIds.plmInsights,
      solutionIds.providerDashboards,
      solutionIds.docSimilarityReport,
    ],
  },
  {
    id: "laboratory-info",
    title: "Laboratory Information Systems",
    type: "tertiary-category",
    solutions: [
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.nameMatcher,
      solutionIds.periodontalChart,
      solutionIds.imageSim,
    ],
  },
  {
    id: "risk-stratification",
    title: "Risk Stratification",
    type: "tertiary-category",
    solutions: [
      solutionIds.maskDetection,
      solutionIds.mentalHealthPrevalence,
      solutionIds.socialDistance,
      solutionIds.predictMortalityKorean,
      solutionIds.fetalHealthClassification,
      solutionIds.uHGStrokePrediction,
      solutionIds.whoSuicideStatistics,
    ],
    insights: [
      insightids.insight22,
      insightids.insight23,
      insightids.insight21,
      insightids.insight27,
    ],
  },
  {
    id: "care-route",
    title: "Care Route Enablement",
    type: "tertiary-category",
    solutions: [],
    insights: [insightids.insight33, insightids.insight13],
  },
  {
    id: "care-analysis",
    title: "Gaps in Care Analysis",
    type: "tertiary-category",
    solutions: [],
    insights: [insightids.insight19],
  },
  {
    id: "disease-registries",
    title: "Disease Registries",
    type: "tertiary-category",
    solutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.globalDashboard,
      solutionIds.womensHealthWellBeing,
      solutionIds.mortalityEffect,
      solutionIds.videoMetadataExtraction,
      solutionIds.knowledgeGraph,
    ],
    insights: [
      insightids.insight15,
      insightids.insight22,
      insightids.insight23,
      insightids.insight29,
      insightids.insight21,
    ],
  },
  {
    id: "appeals-grievances",
    title: "Digitize Appeals and Grievances",
    type: "tertiary-category",
    solutions: [solutionIds.appealsGrievances, solutionIds.questionGeneration],
  },
  {
    id: "digitize-authorization",
    title: "Digitize Prior Authorization",
    type: "tertiary-category",
    solutions: [solutionIds.lifeInsurance, solutionIds.priorAuthorization],
  },
  {
    id: "member-customer-service",
    title: "Member Customer Service",
    type: "tertiary-category",
    solutions: [solutionIds.insuranceComplaintsResolution],
  },
  {
    id: "member-outreach",
    title: "Member Outreach - Campaign",
    type: "tertiary-category",
    solutions: [
      solutionIds.insuranceChurn,
      solutionIds.healthInsuranceLeadPrediction,
      solutionIds.healthInsuranceCoverageRatePrediction,
      solutionIds.textGeneration,
    ],
  },
  {
    id: "identify-frauds",
    title: "Identify Fraud, Waste and Abuse",
    type: "tertiary-category",
    solutions: [
      solutionIds.ocrHandwritten,
      solutionIds.ocrCheckValidation,
      solutionIds.fraudClaimsDetection,
      solutionIds.questionGeneration,
      solutionIds.insuranceFraudDetection,
    ],
  },
  {
    id: "emr-integration",
    title: "EMR Integration and FHIR Interoperability",
    type: "tertiary-category",
    solutions: [solutionIds.mirthHL7],
  },
  {
    id: "disease-registries",
    title: "Disease Registries",
    type: "tertiary-category",
    solutions: [solutionIds.mortalityEffect],
  },
  {
    id: "drug-approval",
    title: "Drug Approval & Registration",
    type: "tertiary-category",
    solutions: [solutionIds.mortalityEffect, solutionIds.feasibilityAnalysis],
  },
  {
    id: "digital-pathology",
    title: "Digital Pathology",
    type: "tertiary-category",
    solutions: [solutionIds.cancerousCellDetection],
  },
  {
    id: "feedback-analysis",
    title: "Feedback Analysis",
    type: "tertiary-category",
    solutions: [
      solutionIds.textSummarizer,
      solutionIds.drugReviewAnalyzer,
      solutionIds.adverseFoodEvents,
      solutionIds.zeroShotClassification,
      solutionIds.textEmotionClassification,
      solutionIds.textgenerationNLP,
      solutionIds.annotatedCSV
    ],
  },
  {
    id: "regulatory-quality",
    title: "Regulatory & Quality",
    type: "tertiary-category",
    solutions: [
      solutionIds.drugReviewAnalyzer,
      solutionIds.medicalAppointmentNoShow,
      solutionIds.neptuneGaphBot,
      solutionIds.differentialPrivacy,
      solutionIds.encryptedTextData,
      solutionIds.differentialPrivacyText,
    ],
    insights: [insightids.insight33],
  },
  {
    id: "patient-portal",
    title: "Patient Portal",
    type: "tertiary-category",
    solutions: [
      solutionIds.caloriePredictor,
      solutionIds.medicalAppointmentNoShow,
      solutionIds.faceEmotionRecognizer,
      solutionIds.americanSignLanguageDetection,
      solutionIds.nameMatcher,
      solutionIds.priorAuthorization,
    ],
  },
  {
    id: "kiosks",
    title: "Kiosks",
    type: "tertiary-category",
    solutions: [solutionIds.neptuneGaphBot],
    insights: [insightids.insight17, insightids.insight18],
  },
  {
    id: "rpm-vc",
    title: "Remote Patient Monitoring",
    type: "tertiary-category",
    solutions: [
      solutionIds.stressDetectionHRV,
      solutionIds.portraitShadowRemoval,
      solutionIds.anomalyDetector,
      solutionIds.medicalImagingVisualQnA,
    ],
    insights: [],
  },
  {
    id: "online-scheduling",
    title: "Online Scheduling",
    type: "tertiary-category",
    solutions: [
      solutionIds.hospitalRecommendation,
      solutionIds.clinicalEventsPatient,
      solutionIds.nameMatcher,
      solutionIds.priorAuthorization,
    ],
    insights: [insightids.insight17, insightids.insight18],
  },
  {
    id: "legacy-modernization",
    title: "Legacy Modernization",
    type: "tertiary-category",
    solutions: [solutionIds.geneticAlgorithm],
  },
  {
    id: "analytics-driven",
    title: "Analytics-Driven Personalization",
    type: "tertiary-category",
    solutions: [
      solutionIds.healthCareCostEstimation,
      solutionIds.legalDocParser,
      solutionIds.textgenerationNLP,
      solutionIds.docSimilarityReport,
    ],
  },
  {
    id: "population-health-management",
    title: "Population Health Management",
    type: "tertiary-category",
    solutions: [
      solutionIds.usUninsured,
      solutionIds.sdoh,
      solutionIds.populationHealthDashboard,
    ],
    insights: [
      insightids.insight11,
      insightids.insight5,
      insightids.insight12,
      insightids.insight6,
      insightids.insight60,
      insightids.insight64,
      insightids.insight66,
      insightids.insight65,
      insightids.insight67,
    ],
  },
  {
    id: "digitize-authorization",
    title: "Digitize Prior Authorization",
    type: "tertiary-category",
    solutions: [solutionIds.lifeInsurance],
  },
  {
    id: "provider-lifecycle-mgmt",
    title: "Provider Lifecycle Management",
    type: "tertiary-category",
    solutions: [],
    insights: [insightids.insight70],
  },
  {
    id: "provider-performance",
    title: "provider-performance",
    type: "tertiary-category",
    solutions: [
      solutionIds.lengthStayHospital,
      solutionIds.providerNetworkAnalysis,
      solutionIds.questionGeneration
    ],
    insights: [insightids.insight8, insightids.insight69],
  },
  {
    id: "billing-payments",
    title: "Billing and Payment",
    type: "tertiary-category",
    solutions: [solutionIds.medicalExpensePrediction, solutionIds.priorAuthorization],
    insights: [insightids.insight10],
  },
  {
    id: "claims-modernization",
    title: "Claims Modernization",
    type: "tertiary-category",
    solutions: [],
    insights: [insightids.insight7],
  },
  {
    id: "advanced-clinical-support",
    title: "Advanced Clinical Decision Support",
    type: "tertiary-category",
    solutions: [
      solutionIds.eEGClassification,
      solutionIds.pulmonaryEmbolism,
      solutionIds.diagnosisPredictor,
      solutionIds.videoMetadataExtraction,
      solutionIds.medicalReportGeneration,
      solutionIds.knowledgeGraph,
      solutionIds.medicalImagingVisualQnA,
      solutionIds.periodontalChart,
      solutionIds.imageSim,
    ],
    insights: [
      insightids.insight25,
      insightids.insight20,
      insightids.insight28,
      insightids.insight24,
    ],
  },

  // for oncology
  {
    id: "etiology-of-cancer",
    title: "Etiology of Cancer",
    type: "tertiary-category",
    info: "Cancer means uncontrolled growth. It's a disease caused by an uncontrolled division of abnormal cells in the part of the body. Also referred to as a tumor. A tumor is defined as a swelling of a part of the body, generally without inflammation, caused by an abnormal growth of tissue whether benign of malignant. Cancer is also sometimes referred to as a neoplasm, a new and abnormal growth of tissue in some part of the body. It is a genetic disease",
    image: etiology,
    isHideSubTitle: true,
    solutions: [
      solutionIds.breastCancerProteomes,
      solutionIds.prostateCancerDetection,
      solutionIds.cervicalCancerRisk,
      solutionIds.pneumoniaDiagnosis,
    ],
  },
  {
    id: "cancer-incidence",
    title: "Cancer Incidence",
    type: "tertiary-category",
    info: "Cancer is a genetic disease. It is caused by an accumulation of detrimental variations in the genome over the course of a lifetime. Cancers are classified according to the tissue where they originate",
    image: cancerincidence,
    isHideSubTitle: true,
    solutions: [
      solutionIds.covid19onCancerPatients,
      solutionIds.cancerIncidentsUSCountry,
      solutionIds.cancerIncidentsUSState,
      solutionIds.nomograms,
    ],
  },
  {
    id: "common-cancers",
    title: "Common Cancer",
    type: "tertiary-category",
    info: "The most common cancers worldwide are lung cancer, accounting for 13% of all cancers diagnosed or 1.8 million people.",
    image: commoncancer,
    isHideSubTitle: true,
    solutions: [
      solutionIds.lungCancerComorbidities,
      solutionIds.colonPathology,
      solutionIds.prostateCancerDetection,
      solutionIds.colonCancerGeneAnalysis,
      solutionIds.skinCancerAnalysis,
      solutionIds.liverDiseasePrediction,
      solutionIds.proteinKinases,
      solutionIds.breastAssessmentTool,
    ],
  },
  {
    id: "genetics",
    title: "Genetics",
    type: "tertiary-category",
    info: "Changes in genes underlined all cancers and the difference between oncogenes and the tumor suppressor gene. Understanding the same is important to think of prediction models on how they contribute to the overall goals of cancer treatment and prevention",
    image: genetics,
    isHideSubTitle: true,
    solutions: [
      solutionIds.predictOligomericState,
      solutionIds.geneticVariantClassification,
      solutionIds.geneVariations,
      solutionIds.sequenceGenerator,
    ],
  },
  {
    id: "genetics-variation",
    title: "Genetic variation and mutation",
    type: "tertiary-category",
    info: "Cancer is a genetic disease and it's caused by an accumulation of detrimental variation to the genome. In molecular biology, we describe gene as a region of DNA that encodes for a functional product. What we mean by a functional product is RNA or protein. DNA provides instructions for all processes of the body, and is made up of bases that bind to each other to form a twisted ladder, which is known as the double helix. Pyrimidine, the T, is binding to Adenine, A, and the C, Cytosine, is binding to Guanine, G. A mutation is one of the few detrimental genetic variation that increases risks to developing a disease or in rare diseases actually causes the disease itself. There are two different types of so called cancer genes, oncogenes and tumor suppressors. Oncogenes promote cancer, so in a normal healthy cell expression of oncogenes is low, on a cancer cell expression of oncogenes is high. This is in contrast to tumor suppressors, which protect against cancer. In normal cells, you'd have high expression of tumor suppressors, and in cancer cells, one would have low expression of tumor suppressor",
    image: geneticmutation,
    isHideSubTitle: true,
    solutions: [
      solutionIds.antibioticResistancePrediction,
      solutionIds.geneticAlgorithm,
      solutionIds.geneticVariantClassification,
      solutionIds.geneVariations,
    ],
  },
  {
    id: "genomic-instability",
    title: "Two-hit hypothesis and genomic instability",
    type: "tertiary-category",
    info: "The Knudson hypothesis, also known as the two-hit hypothesis, is the hypothesis that most tumor suppressor genes require both alleles to be inactivated, either through mutations or through epigenetic silencing, to cause a phenotypic change. It was first formulated by Alfred G. Knudson in 1971 and led indirectly to the identification of cancer-related genes. Knudson won the 1998 Albert Lasker Clinical Medical Research Award for this work.Knudson performed a statistical analysis on cases of retinoblastoma, a tumor of the retina that occurs both as an inherited disease and sporadically. He noted that inherited retinoblastoma occurs at a younger age than the sporadic disease. In addition, the children with inherited retinoblastoma often developed the tumor in both eyes, suggesting an underlying predisposition.Knudson suggested that two hits to DNA were necessary to cause the cancer. In the children with inherited retinoblastoma, the first mutation in what later came to be identified as the RB1 gene, was inherited, the second one acquired. In non-inherited retinoblastoma, instead two mutations, or hits, had to take place before a tumor could develop, explaining the later onset",
    image: twohit,
    isHideSubTitle: true,
    solutions: [
      solutionIds.genomePhenotype,
      solutionIds.miceProteinClassification,
      solutionIds.geneExpressionAnalysis,
      solutionIds.covid19Genetics,
      solutionIds.sARSCoVAssociation,
    ],
  },
  {
    id: "metastasis",
    title: "Metastasis",
    type: "tertiary-category",
    info: "Metastasis is the process which tumor cells go from a primary tumor such as a prostate tumor to a different organ such as bone. Prostate cancer growing in the prostate is an example of a primary tumor. Whereas prostate cancer cells that have gone to the bone and begun growing there is an example of a metastatic prostate tumor. Metastatic is an adjective describing a cancer that metastasizes. Metastases refers to multiple metastatic tumors",
    image: metastasis,
    isHideSubTitle: true,
    solutions: [
      solutionIds.cancerLectins,
      solutionIds.antibioticResistancePrediction,
      solutionIds.humanproteinImageclassification,
      solutionIds.metastatiClassification,
    ],
  },
  {
    id: "tnm-staging",
    title: "TNM (Tumor, Node, Metastasis) Staging",
    type: "tertiary-category",
    info: "The TNM staging system in cancer is by far the most commonly used staging system in cancer diagnosis and is used to determine a patient's prognosis and the course of treatment to be used for a certain patient. Most cancers use this system, except blood cancers and some nervous system cancers. TNM system is typically binary when it comes to the end of TNM",
    image: tnm,
    isHideSubTitle: true,
    solutions: [
      solutionIds.breastCancerClassification,
      solutionIds.brainTumorSegmentation,
      solutionIds.cervicalCancerScreening,
      solutionIds.melanomaClassification,
      solutionIds.geneVariations,
    ],
  },
  {
    id: "metastatic-process",
    title: "The Metastatic Process",
    type: "tertiary-category",
    info: "The metastatic process can be segmented into eight major steps or prediction models, which is: (A) Primary tumor growth, (B) angiogenesis, (C) epithelial-to-mesenchymal transition or EMT, (D) Invasion, (E) Intravasation, (F) Survival in circulation, (G) Extravasation and (H) Dormancy and (I) Secondary tumor growth. Cancer cells can grow into a tumor, cause new blood vessels to grow, which is angiogenesis, undergo physical changes, that's just EMT, and invade through the ECM. Once they get into the bloodstream, it is intravasation. CTCs must survive circulation until they extravasate into a secondary site. Once there as a DTC, they can either grow into a metastatic tumor or go dormant.",
    image: metastasisprocess,
    isHideSubTitle: true,
    solutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.geneVariations,
      solutionIds.bioLemmatizer,
      solutionIds.patientSimilarities,
    ],
  },
  {
    id: "types-ofimaging",
    title: "Types of Imaging",
    type: "tertiary-category",
    info: "X-rays - Plain films, CT (computerized axial tomography), Magnetic field - MRI (magnetic resonance imaging), Sound waves - Ultrasound, Radioisotopes - Nuclear medicine",
    image: typesimaging,
    isHideSubTitle: true,
    solutions: [
      solutionIds.chestXRaysCovid19,
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.brainTumorSegmentation,
      solutionIds.breastCancerClassification,
      solutionIds.pneumoniaDiagnosis,
    ],
  },
  {
    id: "clinical-oncology",
    title: "Imaging in Clinical Oncology",
    type: "tertiary-category",
    info: "Cancer prevention, Cancer Screening, Cancer Diagnostics, Cancer Staging, Access Treatment Response, Monitoring",
    image: imagingclinical,
    isHideSubTitle: true,
    solutions: [
      solutionIds.retinaDamageClassification,
      solutionIds.bloodCellInception,
      solutionIds.humanproteinImageclassification,
    ],
  },
  {
    id: "theory-oligometastasis",
    title: "Theory of Oligometastasis",
    type: "tertiary-category",
    info: "Oligometastases is a disease concept that is defined by a state of limited systemic metastatic tumors for which local ablative therapy could be curative. By definition, the purpose of local treatment for oligometastases is cure, and the primary outcome to be analyzed should be disease-free survival. The concept that patients with only a few metastases from a malignant tumor can potentially be cured was developed in 1995 and termed “Oligo” (few) metastasis, describing an intermediate stage between localized and metastasized cancer. This concept is based on the hypothesis that there is a distinct stage of limited metastatic capability of the primary tumor i.e. that metastatic spread that behaves hierarchical in time and number opens the therapeutic window for a cure at an early, an “oligometastatic” stage",
    image: oligometastasis,
    isHideSubTitle: true,
    solutions: [
      solutionIds.shapleyEMR,
      solutionIds.shapleyImages,
      solutionIds.multiAccuracy,
      solutionIds.primerClassifier,
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.gan,
    ],
  },
  {
    id: "types-of-treatment",
    title: "Types of Treatment",
    type: "tertiary-category",
    info: "Surgery: Removes the tumor, Radiation: Given as external beam radiation or by implanting radioactive seeds into the tumor, Hormonal Therapy: Hormones can cause growth of cancer (Anti-estrogen in females and Anti-testosterone in males), Chemotherapy: Designed to keep cells from dividing, Targeted Therapy: inhibits proteins that are mutated or overexpressed and helping the cancer to grow—this makes the therapy more “cancer specific”, Immunotherapy: use of medicines to stimulate a patient’s own immune system to recognize and destroy cancer cells more effectively)",
    image: typestreatment,
    isHideSubTitle: true,
    solutions: [
      solutionIds.brainTumorSegmentation,
      solutionIds.geneVariations,
      solutionIds.cryoTherapyAnalysis,
      solutionIds.gastrointestinalCancer,
      solutionIds.proteinKinases,
      solutionIds.malariaDetection,
    ],
  },
  {
    id: "individual-cancers",
    title: "Types of treatment for Individual Cancers",
    type: "tertiary-category",
    info: "Lung Cancer, Breast Cancer, Prostrate Cancer, Colon Cancer, Liver Cancer, Stomach Cancer",
    image: individualcases,
    isHideSubTitle: true,
    solutions: [
      solutionIds.predictingADR,
      solutionIds.sideEffects,
      solutionIds.drugRecommendation,
      solutionIds.drugReviewAnalyzer,
      solutionIds.cervicalCancerClassification,
    ],
  },
  {
    id: "clinical-trails",
    title: "Clinical Trials",
    type: "tertiary-category",
    info: "Any therapy that has been improved to treat cancer by the FDA is considered a standard of care. A phase 1 clinical trial takes a new drug that is just being used in people for the first time and is given in increasing dosage, trying to find where it is tolerable. It is not specific to cancer type, and a phase 1 trial is usually considered to be dose finding for toxicity, not for how effective it is in any cancer. Once the safe dose for a drug is found, it is moved on to phase 2 testing. And this is where a specific cancer is targeted at a specific dose to determine efficacy or how well the drug is going to work in that cancer. If the drug is found the have activity at that specific dose in that specific cancer, it is moved on to a phase 3 setting where the drug is compared to the standard of care drugs to see if it is better",
    image: clinicaltrails,
    isHideSubTitle: true,
    solutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.timePeriod2CompleteClinicalTrial,
      solutionIds.geneVariations,
      solutionIds.drugDiscovery,
      solutionIds.cohortSense,
    ],
  },
];
