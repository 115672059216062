import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { Divider, Typography } from "@material-ui/core";
import styled from "styled-components";
import MainLayout from "../common/MainLayout";
import MediaCard from "../common/MediaCard";
import Navbar from "../common/Navbar";
import Error from "../common/Error";

import primaryCategories from "../../constants/maps/primaryCategories";
import secondaryCategories from "../../constants/maps/secondaryCategories";
import solutions from "../../constants/maps/solutions";
import insights from "../../constants/maps/insights";

import { PageContainer, PageTitle, Paragraph } from "../../styles/common";
import tertiaryCategories from "../../constants/maps/tertiaryCategories";
import Footer from "../views/home/Footer";

const Categories = () => {
  const { primaryCategoryId, secondaryCategoryId, tertiaryCategoryId } = useParams();

  const [categoryDetails, setCategoryDetails] = useState(null);
  const [solutionCards, setSolutionCards] = useState(null);
  const [insightCards, setInsightCards] = useState(null);
  const [subCategoryCards, setSubCategoryCards] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    // clear card lists when router path changes
    setSolutionCards(null);
    setInsightCards(null);
    setSubCategoryCards(null);
    if (primaryCategoryId && secondaryCategoryId && tertiaryCategoryId) {
      // get primary category details
      const primaryCatValidation = primaryCategories.filter(
        (item) => item.id === primaryCategoryId,
      )[0];
      // if secondary category is not available in primary categories as a sub-category, then return
      if (
        !primaryCatValidation
        || !primaryCatValidation.subCategories.includes(secondaryCategoryId)
      ) { return; }
      // get secondary category details
      const secondaryCatValidation = secondaryCategories.filter(
        (item) => item.id === secondaryCategoryId,
      )[0];
      // if tertiary category is not available in secondary categories as a sub-category, then return
      if (
        !secondaryCatValidation
        || !secondaryCatValidation.subCategories.includes(tertiaryCategoryId)
      ) { return; }
      // else store tertiary category details in state
      setCategoryDetails(
        tertiaryCategories.filter((item) => item.id === tertiaryCategoryId)[0],
      );
    } else if (primaryCategoryId && secondaryCategoryId) {
      // get primary category details
      const primaryCatValidation = primaryCategories.filter(
        (item) => item.id === primaryCategoryId,
      )[0];
      // if secondary category is not available in primary categories as a sub-category, then return
      if (
        !primaryCatValidation
        || !primaryCatValidation.subCategories.includes(secondaryCategoryId)
      ) { return; }
      // else store secondary category details in state
      setCategoryDetails(
        secondaryCategories.filter((item) => item.id === secondaryCategoryId)[0],
      );
    } else if (primaryCategoryId) {
      setCategoryDetails(
        primaryCategories.filter((item) => item.id === primaryCategoryId)[0],
      );
    }
  }, [primaryCategoryId, secondaryCategoryId, tertiaryCategoryId]);

  useEffect(() => {
    // load solution cards from solution list using solutionId
    if (
      categoryDetails
      && categoryDetails.solutions
      && categoryDetails.solutions.length > 0
    ) {
      const list = solutions.filter((item) => categoryDetails.solutions.includes(item.id));
      setSolutionCards(list);
    }
    if (categoryDetails?.insights?.length > 0) {
      const list = insights.filter((item) => categoryDetails.insights.includes(item.id));
      setInsightCards(list);
    }
    if (!categoryDetails || !categoryDetails.subCategories) return;

    // load sub-category cards from category-list using categoryId
    if (categoryDetails.type === "primary-category") {
      const list = secondaryCategories.filter((item) => categoryDetails.subCategories.includes(item.id));
      setSubCategoryCards(list);
    } else if (categoryDetails.type === "secondary-category") {
      const list = tertiaryCategories.filter((item) => categoryDetails.subCategories.includes(item.id));
      setSubCategoryCards(list);
    }
  }, [categoryDetails]);

  return (
    <MainLayout>
      <Navbar />
      <PageContainer>
        {categoryDetails && (
          <>
            <PageTitle>{categoryDetails.title}</PageTitle>
            {categoryDetails.info && <Paragraph>{categoryDetails.info}</Paragraph>}
            {solutionCards
              && (
              <>
                {!categoryDetails.isHideSubTitle && (
                <SectionTitle
                  title="Solutions"
                  description="Accelerated, Scalable and Customized solutions leveraging niche and advanced algorithms to deliver reliable and accurate results on the go."
                />
                )}
                <Grid container direction="row" alignItems="stretch" spacing={3}>
                  {solutionCards.map((items) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <MediaCard
                        {...items}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
              )}
            {subCategoryCards
              && (
              <Grid container direction="row" alignItems="stretch" spacing={3}>
                {subCategoryCards.map((items) => (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MediaCard
                      {...items}
                      selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                    />
                  </Grid>
                ))}
              </Grid>
              )}
            {insightCards
              && (
              <>
                {!categoryDetails.isHideSubTitle && (
                <SectionTitle
                  title="Insights"
                  description="Discover Insights beyond your imagination. Explore and experience from a plethora of custom built problem specific insights to embrace the latest trends."
                />
                )}

                <Grid container direction="row" alignItems="stretch" spacing={3}>
                  {insightCards.map((items) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <MediaCard
                        {...items}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
              )}
          </>
        )}
        {!categoryDetails && <Error code="404" />}
      </PageContainer>
      <Footer hideSocialShare />
    </MainLayout>
  );
};

export default Categories;

const SubTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 24px;
    text-align: left;
    font-weight: 100;
    padding-bottom: 5px;
    @media only screen and (max-width: 950px) {
      font-size: 16px;
    }
  }
`;
const SubDesc = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 1;
    text-align: left;
    width: 100%;
    color: #29293a;
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }

  }
`;
const StyledDivider = styled(Divider)`
  color: #2941b7;
  margin: 5px 0;
`;
const Container = styled.section`
  color: #2941b7;
  padding: 30px 0;
`;

export function SectionTitle({ title, description }) {
  return (
    <Container>
      <SubTitle>{title}</SubTitle>
      <SubDesc>{description}</SubDesc>
      <StyledDivider />
    </Container>
  );
}
