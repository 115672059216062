import React, { memo, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const DementiaPrediction = () => {
  const initialData = {
    visit: '',
    mr_delay: '',
    gender: '',
    age: '',
    educ: '',
    ses: '',
    mmse: '',
    etiv: '',
    nwbv: '',
    asf: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("payerProvider", "dementia");

  const gender = [
    { name: "Female", value: 0 },
    { name: "Male", value: 1 },
  ];

  const showSample1 = () => {
    const NewDataSet = {
      visit: '2',
      mr_delay: '733',
      gender: '0',
      age: '83',
      educ: '15',
      ses: '2.0',
      mmse: '29.0',
      etiv: '1482',
      nwbv: '0.751',
      asf: '1.184',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      visit: '2',
      mr_delay: '558',
      gender: '0',
      age: '78',
      educ: '12',
      ses: '3.0',
      mmse: '20.0',
      etiv: '1339',
      nwbv: '0.689',
      asf: '1.311',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      visit, mr_delay, gender, age, educ, ses, mmse, etiv, nwbv, asf,
    } = sampleData;

    const params = `${visit},${mr_delay},${gender},${age},${educ},${ses},${mmse},${etiv},${nwbv},${asf}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res[0]);

          const percentages = Object.entries(res[1]);
          const params = [];
          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          console.log(percentages);
          Highcharts.chart('container', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Dementia cannot be determined by performing one single test. Doctors diagnose Alzheimer’s disease and other types of dementia based on an analysis of medical history, physical examination, laboratory tests, careful observation of changes in a person's character, thinking, day-to-day activities, and matching them with the behavioral characteristics associated with each type. Doctors can determine whether a person is demented with a high level of certainty, but it is difficult to determine the exact type of dementia as the symptoms and changes in the brain of the different types tend to be very similar. In some cases, a doctor may diagnose “dementia” and not specify a type. If this occurs, it would be advisable to consult a specialist such as a Neurologist or a Geropsychologist.</p>
          <p>
            <strong>Data</strong>
            {' '}
          </p>
          <p>The dataset used for this application consists of a longitudinal collection of 150 subjects aged 60 to 96. Each subject was scanned on two or more visits, separated by at least one year for a total of 373 imaging sessions. For each subject, 3 or 4 individual T1-weighted MRI scans obtained in single scan sessions are included. The subjects are all right-handed and include both men and women. 72 of the subjects were characterized as Nondemented throughout the study. 64 of the included subjects were characterized as Demented at the time of their initial visits and remained so for subsequent scans, including 51 individuals with mild to moderate Alzheimer’s disease. Another 14 subjects were characterized as Nondemented at the time of their initial visit and were subsequently characterized as Demented at a later visit.</p>
          <p>
            The following are the inputs taken by the application:
            <List>
              <ListItem>
                <strong>Visit:</strong>
                {' '}
                Number of visits for scanning
              </ListItem>
              <ListItem>
                <strong>MR Delay:</strong>
                {' '}
                Number of days between two medical visits
              </ListItem>
              <ListItem>
                <strong>M/F:</strong>
                {' '}
                Male/Female (Male: 1, Female: 0)
              </ListItem>
              <ListItem>
                <strong>Age:</strong>
                {' '}
                in years
              </ListItem>
              <ListItem>
                <strong>EDUC:</strong>
                {' '}
                Years of education
              </ListItem>
              <ListItem>
                <strong>SES:</strong>
                {' '}
                Socioeconomic status as assessed by the Hollingshead Index of Social Position and classified into categories from 1 (highest status) to 5 (lowest status)
              </ListItem>
              <ListItem>
                <strong>MMSE:</strong>
                {' '}
                Mini-Mental State Examination score (range is from 0 = worst to 30 = best)
              </ListItem>
              <ListItem>
                <strong>eTIV:</strong>
                {' '}
                Estimated total intracranial volume (mm3) - refers to the estimated volume of the cranial cavity as outlined by the supratentorial dura matter or cerebral contour when dura is not clearly detectable
              </ListItem>
              <ListItem>
                <strong>nWBV:</strong>
                {' '}
                Normalized whole-brain volume, expressed as a percent of all voxels in the atlas-masked image that are labeled as gray or white matter by the automated tissue segmentation process
              </ListItem>
              <ListItem>
                <strong>ASF:</strong>
                {' '}
                Atlas scaling factor (unitless). Computed scaling factor that transforms native-space brain and skull to the atlas target (i.e., the determinant of the transform matrix)
              </ListItem>
            </List>
          </p>
          <p><strong>Method</strong></p>
          <p>This application returns a Clinical Dementia Rating or CDR, which is a metric used to quantify the severity of dementia (0 = no dementia, 0.5 = mild, 1 = moderate , 2 = severe). As the number of data points with a CDR of value "2" are considerably less, those rows were dropped from the training set. The model also makes use of the class-weight technique to balance the initially imbalanced dataset. A number of ML models like Random Forest, LightGBM , XGBoost etc. were used to train the data, and Random Forest model was found to be having the highest performance value.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Dementia/Dementia_Prediction.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/jboysen/mri-and-alzheimers"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Visit"
                name="visit"
                fullWidth
                onChange={handleInputs}
                value={sampleData.visit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="MR Delay"
                name="mr_delay"
                fullWidth
                onChange={handleInputs}
                value={sampleData.mr_delay}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="gender"
                label="Gender"
                value={sampleData.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Age"
                name="age"
                fullWidth
                onChange={handleInputs}
                value={sampleData.age}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="EDUC"
                name="educ"
                fullWidth
                onChange={handleInputs}
                value={sampleData.educ}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="SES"
                name="ses"
                fullWidth
                onChange={handleInputs}
                value={sampleData.ses}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="MMSE"
                name="mmse"
                fullWidth
                onChange={handleInputs}
                value={sampleData.mmse}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="eTIV"
                name="etiv"
                fullWidth
                onChange={handleInputs}
                value={sampleData.etiv}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="nWBV"
                name="nwbv"
                fullWidth
                onChange={handleInputs}
                value={sampleData.nwbv}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="ASF"
                name="asf"
                fullWidth
                onChange={handleInputs}
                value={sampleData.asf}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} />
            <Grid item xs={12} sm={6} md={4} />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(DementiaPrediction);
