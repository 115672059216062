import React, { memo, useState, useEffect } from 'react';
import {
  Grid, TextField, MenuItem, Select, Checkbox, ListItemText, ListItemIcon, FormControl,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { setInputData } from '../../../../../../store/actions/vnet';
import { StyledButton } from '../../../../../../styles/common';
import { StyledInput } from "../../InputForm";
import { isObjKeyhasval } from '../../util';
import { InfoText } from '../ModelOptimization/DataShapley';

export const MissingValuesForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const dispatch = useDispatch();
  const [fromData, setFromdata] = useState({});
  const [change, setChange] = useState(true);
  const [fromValid, setFromValid] = useState(false);
  const [toValid, setToValid] = useState(false);
  const [fromDataIndex, setfromDataIndex] = useState("1");
  const [toDropdown, setToDropdown] = useState([]);
  const [inputParams, setInputParams] = useState({ methods: {} });
  const [alert, setAlert] = useState("");

  const handleTodata = (e) => {
    const { value } = e.target;

    dispatch(
      setInputData({
        method: {
          ...userInputData.method,
          [e.target.name]: value,
        },
      }),
    );

    const toValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    setToValid(toValid);
    setChange(!submitValid);
  };

  const handleFromData = (e) => {
    if (Object.values(fromData).indexOf(e.target.value) == -1) {
      setFromdata({ ...fromData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
    const fromvalue = e.target.value;
    const value = (inputParams?.methods[fromvalue]);
    console.log(value);
    if (value == "All") {
      const list = [...inputParams.all];
      if (list) {
        setToDropdown([...list]);
      }
    } else {
      const list = [...inputParams.numeric];
      if (list) {
        setToDropdown([...list]);
      }
    }

    const fromValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    setFromValid(fromValid);
    setChange(!submitValid);
  };

  const getInputFieldLen = () => Object.entries(fromData).filter((data) => data[0].startsWith('input')).length;

  const addField = () => {
    const fromDataLen = getInputFieldLen();
    const dropdownlen = Object.entries(inputParams.methods).length;
    if (fromDataLen < dropdownlen) {
      setFromdata({ ...fromData, [`input${fromDataIndex}`]: "" });
    }
    setfromDataIndex(fromDataIndex + 1);
    setChange(true);
    setToValid(false);
    setFromValid(false);
  };

  const removeField = (e) => {
    const list = { ...fromData };
    const globallist = { ...userInputData.method };
    delete globallist[list[e]];
    delete list[e];
    setFromdata(list);
    dispatch(
      setInputData({
        method: globallist,
      }),
    );
    setChange(false);
  };

  useEffect(async () => {
    try {
      const result = await props.getCategories();
      console.log(result);

      if (result) {
        setInputParams(result);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(async () => {
    if (userInputData?.method) {
      const formData = {};
      let fromDataLen = 0;
      Object.keys(userInputData?.method).map((data, indx) => {
        formData[`disabled${indx}`] = data;
        fromDataLen++;
      });
      setFromdata(formData);
      setfromDataIndex(fromDataLen);
      setChange(false);
    } else {
      setFromdata({ input0: "" });
    }
  }, []);
  useEffect(() => {
    if (getInputFieldLen() == 0) {
      setChange(false);
    }
  }, [fromData]);

  const handleFormReset = () => {
    console.log('reset');
  };

  const isFormValid = () => {
    if (!userInputData?.method || Object.entries(userInputData?.method).length == 0) {
      setAlert(true);
      return false;
    }
    return true;
  };

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };

  return (
    <MissingValueContainer>
      {alert && (
        <InfoText success={false}>
          {' '}
          <FontAwesomeIcon icon={faInfoCircle} />
          {' '}
          Please choose atleast one method
        </InfoText>
      )}
      <Grid container spacing={2} alignItems='center'>
        {Object.entries(fromData).map((data) => (
          <>
            <Grid item xs={5}>
              {data[0].startsWith("disabled") ? (
                <TextField
                  fullWidth
                  name={data[0]}
                  label="From"
                  variant="outlined"
                  disabled
                  type="text"
                  value={data[1]}
                  info="Select a method for Missing value Treatment"
                />
              ) : (
                <TextField
                  name={data[0]}
                  label="method"
                  variant="outlined"
                  onChange={handleFromData}
                  select
                  key={data[0]}
                  fullWidth
                  value={fromData && fromData[data[0]]}
                  info="Select a method for Missing value Treatment"
                >
                  {Object.keys(inputParams?.methods).map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      disabled={Object.values(fromData).indexOf(value) > -1}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={6}>
              <Select
                multiple
                name={fromData[data[0]]}
                value={fromData[data[0]] && Array.isArray(userInputData?.method?.[fromData[data[0]]]) ? userInputData?.method?.[fromData[data[0]]] : []}
                onChange={handleTodata}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                variant="outlined"
                disabled={fromData[data[0]] > -1 || data[0].startsWith("disabled")}
              >

                {toDropdown?.map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    disabled={value == 'Null'}
                  >
                    <ListItemIcon>
                      <Checkbox checked={userInputData?.method?.[fromData[data[0]]]?.indexOf(value) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {!(data[0].startsWith("disabled")) && (
              <Grid item xs={1}>
                <FontAwesomeIcon icon={faTrash} onClick={() => removeField(data[0])} size="large" />
              </Grid>
            )}
          </>
        ))}
      </Grid>
      <StyledButton disabled={change} onClick={addField} variant="outlined">
        +
      </StyledButton>

      {props.render({ handleFormReset, isFormValid })}

    </MissingValueContainer>
  );
});

const MissingValueContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
    min-width: 160px;
  }
  .MuiSelect-root {
    min-width: 160px;
  }
  .MuiInputBase-root{
    width: 100%;
  }
`;
