import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';

const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, reducers);

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') return composeWithDevTools(applyMiddleware(...middleware));
  return applyMiddleware(...middleware);
};

const store = createStore(persistedReducer, bindMiddleware([thunkMiddleware]));

export const persistor = persistStore(store);

export default store;
