import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import { StyledFormControl } from "../Dataviz/ScatterPlot";
import { validateForm, isObjKeyhasval } from "../../util";
import { subFeatureData } from "../../constants";
import { StyledInput } from '../../InputForm';
import { LRContainer } from "./LogisticRegression";

import { StyledButton } from '../../../../../../styles/common';
import ZoomImage from "../../../../../common/ZoomImage";
import { StyledRadioGroup } from "../DataTransformation/DropDuplicates";
import { PCATable } from "../../../../../common/JsonDataTable";
import { SubTitle } from "../../components/ModelBuildingResultTab";
import InfoToolTip from "../../components/InfoToolTip";
import { SubTitile } from "./ANN";

export const PCAForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector(
    (state) => state.vnet,
  );
  const { initialData = null } = subFeatureData.Logistic_Regression;

  const [fieldError, setFieldError] = useState(false);
  const [dropdownList, setdropdownList] = useState(null);
  const [dynamicInputs, setDynamicInputs] = useState([]);
  const [tableData, setTableData] = useState(null);


  const handleDefineModel = async () => {
    if (userInputData?.n) {
      let contextData = {
        "Actions": "Components",
      };
      let result = await props.getCommonAPIData(contextData, { ...userInputData });
      result = JSON.parse(result?.Result?.components);
      let rowData = Object.keys(result[Object.keys(result)[0]])
      setTableData({ bodyData: result, rowData: rowData });
    }
    else {
      setFieldError({ ...fieldError, n: 'requried field' })
    }
    fieldError?.n && setFieldError({ ...fieldError, n: false })
  }

  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);

  const dispatch = useDispatch();


  const handleOnChange = (e) => {
    console.log(e);
    const { value, name, checked = false } = e.target;
    if (name == 'n' && value > 5) {
      setFieldError({ ...fieldError, n: 'value must be <=5' });
      dispatch(
        setInputData({
          ...userInputData,
          col_names: [],
        }),
      );
      return;
    }
    if (name == 'col_names') {
      let list = userInputData?.col_names;
      list[e.target.id] = value;
      dispatch(
        setInputData({
          ...userInputData,
          col_names: list,
        }),
      );
    } else {

      dispatch(
        setInputData({
          ...userInputData,
          [name]: value,
        }),
      );
    }
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        cols: typeof value === "string" ? value.split(",") : value,
      }),
    );
    if (fieldError.cols) {
      setFieldError({ ...fieldError, cols: false });
    }
  };

  const handleFormReset = () => {
    dispatch(
      setInputData({
        ...initialData,
      }),
    );
    setTableData(null);
    setDynamicInputs([])

  };

  const isFormValid = () => {
    const errorObj = {};


    if (userInputData?.cols.length == 0) {
      errorObj.cols = "Required Field";
    }

    const errData = { ...fieldError, ...errorObj };
    setFieldError(errData);

    return isObjKeyhasval(errData);
  };

  useEffect(async () => {
    const dropdownList = await props.getCategories();
    if (dropdownList) {
      setdropdownList(JSON.parse(dropdownList));
    }
  }, []);
  useEffect(async () => {
    if (userInputData?.n < 6) {
      let list = Array(Number(userInputData?.n || 0)).fill(1).map((val, indx) => `pc${indx + 1}`)
      setDynamicInputs(list);
      dispatch(
        setInputData({
          ...userInputData,
          col_names: Array(Number(userInputData?.n || 0)).fill(''),
        }),
      );
    }
  }, [userInputData?.n]);

  return (
    <LRContainer padding="1.1rem">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={tableData ? 6 : 10}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={5}>
              <StyledFormControl fullWidth error={fieldError.cols}>
                <InputLabel id="model-evaluation">Features</InputLabel>
                <Select
                  multiple
                  variant="outlined"
                  name="cols"
                  value={userInputData?.cols || []}
                  onChange={handleMultiple}
                  input={<Input />}
                  fullWidth
                  required
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {dropdownList?.map((value) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox
                        checked={userInputData?.cols?.indexOf(value) > -1}
                        disabled={
                          userInputData?.cols?.indexOf(userInputData?.target) > -1
                        }
                      />
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))}
                </Select>
                {fieldError.cols && <FormHelperText>Required Field</FormHelperText>}
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <StyledInput
                name="n"
                label="n"
                variant="outlined"
                onChange={handleOnChange}
                type='number'
                required
                fullWidth
                value={userInputData?.n}
                error={fieldError.n}
                helperText={fieldError.n}
              >
              </StyledInput>
            </Grid>
            <Grid item xs={12} >
              <Tooltip
                arrow
                placement="top"
                title="view the composition of the components wrt the selected columns."
              >
                <StyledButton variant="contained" color="primary" onClick={handleDefineModel}>Generate </StyledButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} >
              <SubTitile>
                <InfoToolTip title=" Select either to replace the existing dataset with the principal components or to append the components to the existing dataset.">
                  Dataset Parameter
                </InfoToolTip>
              </SubTitile>
              <StyledRadioGroup
                row
                justify="center"
                aria-label="Learn Mixture Weights"
                name="replace"
                defaultValue="False"
                value={userInputData?.replace}
                onChange={handleOnChange}
              >
                <FormControlLabel
                  control={<Radio value="True" name="replace" />}
                  label="Replace"
                />
                <FormControlLabel
                  control={<Radio value="False" name="append" />}
                  label="Append"
                />
              </StyledRadioGroup>
            </Grid>
          </Grid>
        </Grid>
        {tableData && <Grid item xs={12} md={6}>
          <PCATable headerData={Object.keys(tableData.bodyData)} rowData={tableData.rowData} bodyData={tableData.bodyData} />
        </Grid>}
      </Grid>
      {tableData &&
        <>
          <SubTitile>
            <InfoToolTip title="">
              Rename Columns created after PCA
            </InfoToolTip>
          </SubTitile>
          <Grid container spacing={3}>
            {dynamicInputs.map((val, indx) => (

              <Grid item xs={12} sm={6} md={4}>
                <StyledInput
                  name={'col_names'}
                  label={val}
                  variant="outlined"
                  id={indx}
                  onChange={handleOnChange}
                  required
                  fullWidth
                  value={userInputData?.[indx]}
                  error={fieldError?.[val]}
                  helperText={fieldError?.[val]}
                >
                </StyledInput>
              </Grid>
            ))}
          </Grid>
        </>}


      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </LRContainer>
  );
});


