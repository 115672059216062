import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
} from "../../../styles/common";

const ConVnet = () => {

    return (
        <SolutionContainer>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        Convnet Playground is a tool for the interactive exploration of Convolutional Neural Networks (Convnets or CNNs)and allows the user to perform semantic image search using CNNs. When one selects an image (by clicking it), a neural network looks at the content of all images in the dataset and shows the top 15 most similar images to the selected image.
                    </p>
                    <p><strong>Convolutional Neural Netwoks </strong></p>
                    <p>
                        CNNs learn hierarchical representations useful for image analysis. Early layers in a CNN learn low level features (e.g. lines, edges, shapes, colours) while later layers learn high level concepts (e.g eyes, legs, faces, doors etc) depending on the dataset used for training.
                    </p>
                    <p><strong>Models and Layers  </strong></p>
                    <p>
                        In this tool the results are provided from 9 models (vgg16, vgg19, mobilenet, efficientnetb0, efficientnetb5, xception, resnet50, inceptionv3, densenet121)  and a selection intermediate models using 8 layers from each model. Only 8 layers are used mainly to reduce the viewer's cognitive burden and enable easy visual comparisons.
                    </p>
                    <p><strong>Distance Metric  </strong></p>
                    <p>
                        Results are provided from the use of 4 different distance metrics in measuring the similarity between features extracted from all images in each dataset. These include cosine, euclidean, squared euclidean and minkowsi distances.
                    </p>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://convnetplayground.fastforwardlabs.com/#/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Source
                                </a>
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://blog.fastforwardlabs.com/2019/07/22/new-research-deep-learning-for-image-analysis.html"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Read more
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>
            <Collapse text="Demo">
                <DemoContainer>
                    <center>
                        <iframe src="https://convnetplayground.fastforwardlabs.com/#/" width="100%" height="650" />
                    </center>

                </DemoContainer>
            </Collapse>
        </SolutionContainer>
    );
}

export default memo(ConVnet);
