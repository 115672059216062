import React, { memo, useState } from "react";
import {
  Grid, TextField, FormControl, MenuItem,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  Heading3,
  List,
  ListItem,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const AntibioticResistancePrediction = () => {
  const initialData = {
    select1: "",
    select2: "",
    select3: "",
    select4: "",
    select5: "",
    select6: "",
    select7: "",
    select8: "",
    select9: "",
    select10: "",
    select11: "",
    select12: "",
    select13: "",
    select14: "",
    select15: "",
    select16: "",
  };

  const segments = [
    "AGAGTAAACGTACTCAACCAGGTACAAGCAGGGGTCAGCCAGATGTTCGGTC",
    "AGGCAACCTGTTTGCCAACTGGACGGGCGCGCTGCTCATC",
    "CCGCGAAGCCAACCTGCACGATTTGTTACGCCGC",
    "TCAAACCGCCCTCTTTCATCCCCGCGCCGAGC",
    "AATCCCCTCGCAGCACGCCCCCGAAGACTACCCCGACATCCAAAGCGCG",
    "TTTGAAGACGCGCTGCTGATGGGTTGCGGCGAACG",
    "GGGATTGTAAAAATAAATGGTGTAGTCGATGCCGGAG",
    "CAGCCTGACCCATTGCCATGCTGCTTGCCGC",
    "ACCAATTACGCCGGCAAACGGGTCGAATCGGA",
    "ATGACCGAACAACACTTTACCGAACAAATCAAATCCCTAATCGACAGCTTAAAAACCAT",
    "TAACATACGGAAACCTTTTATTCATTGAGTGAGGTCGATTATGCT",
    "ACAATGCGTTATTGGCGGAACACTTCACTAAA",
    "CGATTACGACAACGCCGACAACGTGGTGTACCAAACCC",
    "GCCGCGTATAAAAACCCATCAGGAAAGATGCAA",
    "GTTAATTTTTGTTAATCATCCCTTCTTAGGGACGCAAT",
    "GTAGCGGTCGGCAGAACCTTGGCTCATCGCGCCCAA",
  ];
  const sample = {
    select1: "0",
    select2: "1",
    select3: "1",
    select4: "1",
    select5: "1",
    select6: "1",
    select7: "1",
    select8: "1",
    select9: "1",
    select10: "1",
    select11: "0",
    select12: "1",
    select13: "0",
    select14: "0",
    select15: "0",
    select16: "0",
  };

  const Selects = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 0,
      label: "No",
    },
  ];

  const [parameters, setParameters] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "bioInformatics",
    "antibioticResistancePrediction",
  );

  const loadSampleData = () => {
    setParameters(sample);
  };

  const onChangeFields = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(value, name);
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  const getPrediction = () => {
    const {
      select1,
      select2,
      select3,
      select4,
      select5,
      select6,
      select7,
      select8,
      select9,
      select10,
      select11,
      select12,
      select13,
      select14,
      select15,
      select16,
    } = parameters;
    setResultData("");

    const params = `${select1},${select2},${select3},${select4},${select5},${select6},${select7},${select8},${select9},${select10},${select11},${select12},${select13},${select14},${select15},${select16}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          Gonorrhoea is the second most common sexually transmitted infection
          (STI) in Europe. Rates of gonorrhoea infection are on the rise. Many
          people who are infected (especially women) experience no symptoms,
          aiding its spread. However, if the infection is left untreated, it can
          lead to infertility in women, and can occasionally spread to other
          parts of the body such as your joints, heart valves, brain or spinal
          cord. Resistance of these bacteria to antibiotics is rising over time,
          making infections hard to treat.
        </Paragraph>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Heading3>Why Antibiotic Resistance Prediction?</Heading3>
            <List>
              <ListItem>
                In the past, patients were treated with an antibiotic called
                ciprofloxacin. Doctors had to stop using this antibiotic because
                resistance to the drug became too common, causing treatments if
                infections to fail.
              </ListItem>
              <ListItem>
                There’s growing interest in testing for antibiotic resistance
                before a patient begins treatment.
              </ListItem>
              <ListItem>
                Whole genome sequencing making it a more practical approach for
                detecting antibiotic resistance over laboratory testing.
                Sequencing can give you results in a matter of hours.
              </ListItem>
              <ListItem>
                The mechanisms that drive resistance in these bacteria are coded
                in their DNA, meaning that a single test could tell us about
                resistance to a panel of antibiotics, and also give us other
                useful information, like whether the strain you’re infected with
                is on the rise
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <Heading3>What does the Tool offer? </Heading3>
            <List>
              <ListItem>
                "unitigs" are segments of DNA shared by a subset of the strains
                in the DNA sequence collection. The dataset used for this
                application contains unitigs that are statistically associated
                with ciprofloxacin resistance.
              </ListItem>
              <ListItem>
                The input is a binary option for 16 unitig gene sequences to
                represent its presence in the sample or not.
              </ListItem>
              <ListItem>
                The application, as a result, determines whether the sample is
                resistant to the antibiotic Ciprofloxacin, which is used for
                treatment of gonorrhoea.
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Anitbiotic_Resistance"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/nwheeler443/gono-unitigs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <StyledContainer>
          <h4>
            Mark the presence or absence of the following unitig data sequences
            in the sample to be tested for resistance to the antibiotic
            Ciprofloxacin
          </h4>

          {segments.map((option, index) => (
            <Grid container spacing={2} direction="row" justify="flex-start">
              <Grid item xs={12} md={8}>
                <Paragraph>
                  <p>{`${index + 1}) ${option}`}</p>
                </Paragraph>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id={option}
                    select
                    variant="outlined"
                    label="Select Option"
                    name={`select${index + 1}`}
                    value={parameters[`select${index + 1}`]}
                    onChange={onChangeFields}
                  >
                    {Selects.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          ))}
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={loadSampleData}
              >
                Sample Data
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getPrediction}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <section>
              <h3>Output</h3>
              <Grid item xs={12}>
                {showSpinner && <Spinner text="Loading..." />}

                {resultData && (
                  <Result>
                    <strong>Result</strong>
                    {`${resultData}`}
                  </Result>
                )}
              </Grid>
            </section>
          </Grid>
        </StyledContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(AntibioticResistancePrediction);
const StyledContainer = styled(DemoContainer)`
  word-break: break-word;
`;
