import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  Icon,
  Typography,
  Slider,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import LazyHero from "react-lazy-hero";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more"; // init module

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
} from "../../../styles/common";

// images
import covid19Simulation_sol1 from "../../../assets/images/app/covid19Simulation_sol1.webp";

HighchartsMore(Highcharts);

const Covid19Simulation = () => {
  const initialData = {
    country: "",
    uncertainity_error: 0.1,
    timing_intervention: 0,
    strength_intervention: 0,
    diagnosing_rate: 0,
    showReset: false,
    isDisabled: false,
  };

  const [country, setCountry] = useState("");
  const [uncertainity_error, setUncertainity_error] = useState(0.1);
  const [timing_intervention, setTiming_intervention] = useState(0);
  const [strength_intervention, setStrength_intervention] = useState(0);
  const [diagnosing_rate, setDiagnosing_rate] = useState(0);
  const [showReset, setShowReset] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChart, setIsChart] = useState(false);
  const [result, setResult] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [parameters, setParameters] = useState(initialData);
  const [notification, setNotification] = useState("");
  const [slideparameters, setslideParameters] = useState(initialData);

  const { getQueryResult } = CommonService("corona", "covid19Simulation");

  const formHighChart = (data1) => {
    Highcharts.chart("container_1", {
      plotOptions: {
        series: {
          events: {
            checkboxClick(event) {
              // docs
              if (this.visible) {
                this.hide();
              } else {
                this.show();
              }
            },
            legendItemClick(e) {
              return false;
            },
          },
        },
      },
      legend: {
        align: "right",
        layout: "vertical",
        verticalAlign: "top",
        y: 50,
        itemMarginTop: 5,
        itemMarginBottom: 5,
        symbolPadding: 20,
        itemDistance: 0,
      },
      title: {
        text: "",
      },
      xAxis: {
        series: data1[0],
        title: {
          text: "Number of Days",
        },
      },
      series: [
        {
          name: "Exposed",
          data: data1[1][0],
          showCheckbox: true,
          selected: true,
        },
        {
          name: "Model value for Exposed",
          type: "errorbar",
          data: data1[3][0],
          showCheckbox: true,
          selected: true,
        },
        {
          name: "Hospitalized",
          data: data1[1][4],
          showCheckbox: true,
          visible: false,
        },
        {
          name: "Model value for Hospitalized",
          type: "errorbar",
          data: data1[3][4],
          showCheckbox: true,
          selected: true,
        },
        {
          name: "Fatality",
          data: data1[1][5],
          showCheckbox: true,
          visible: false,
        },
        {
          name: "Model value for Fatality",
          type: "errorbar",
          data: data1[3][5],
          showCheckbox: true,
          selected: true,
        },
        {
          name: "Total number of diagnosed cases",
          data: data1[1][8],
          showCheckbox: true,
          visible: false,
        },
        {
          name: "Model value for diagnosed cases",
          type: "errorbar",
          data: data1[3][8],
          showCheckbox: true,
          selected: true,
        },
      ],
    });
  };

  const getResponse = (e, value, name) => {
    setResult(false);
    const {
      country,
      diagnosing_rate,
      strength_intervention,
      uncertainity_error,
      timing_intervention,
    } = parameters;

    const params = `${country},${uncertainity_error},${timing_intervention},${strength_intervention},${diagnosing_rate}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Please select country" });
    } else {
      value
        && setParameters({
          ...parameters,
          [name]: value,
        });
      setShowReset(true);
      setIsDisabled(true);
      setSpinner(true);
      setIsChart(false);

      getQueryResult("result", params)
        .then((res) => {
          setResult(true);
          formHighChart(res);
          setSpinner(false);
        })
        .catch((err) => {
          console.log(err);
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    }
  };
  const resetSlider = () => {
    setParameters();
    setResult(false);
    setShowReset(false);
  };

  const onSliderChange = (e, value, name) => {
    setslideParameters({
      ...slideparameters,
      [name]: value,
    });
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <LazyHero
        imageSrc={covid19Simulation_sol1}
        minHeight="50vh"
        opacity="0.2"
      >
        <p style={{ color: "rgba(255,255,255,1)", fontSize: "50px" }}>
          COVID-19 Simulation
        </p>
      </LazyHero>
      <br />
      <Collapse text="Demo">
        <DemoContainer>
          <div>
            <Grid container direction="row" justify="center">
              <Grid item xs={12} sm={6} md={3}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    transform="translate(14px, 20px) scale(1)"
                    select
                    fullWidth
                    label="Select Country"
                    name="country"
                    value={parameters.country}
                    onChange={onChangeFields}
                  >
                    <MenuItem key={0} value="United Kingdom">
                      United Kingdom
                    </MenuItem>
                    <MenuItem key={1} value="Spain">
                      Spain
                    </MenuItem>
                    <MenuItem key={2} value="Italy">
                      Italy
                    </MenuItem>
                    <MenuItem key={3} value="France">
                      France
                    </MenuItem>
                    <MenuItem key={4} value="Belgium">
                      Belgium
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <br />
            <section>
              <GridJustifiedText
                container
                direction="row"
                justify="center"
                spacing={6}
              >
                <PaddedGrid item xs={12} sm={12} md={6}>
                  <Typography id="label">Uncertainity Error</Typography>
                  <b>{parameters.uncertainity_error}</b>
                  <Slider
                    value={parameters.uncertainity_error.toFixed(3)}
                    aria-labelledby="label"
                    valueLabelDisplay="auto"
                    name="uncertainity_error"
                    min={0}
                    max={1}
                    marks={[
                      { value: 0, label: 0 },
                      { value: 1, label: 1 },
                    ]}
                    step={0.1}
                    onChange={(e, val) => onSliderChange(e, val, "uncertainity_error")}
                    onChangeCommitted={(e, val) => getResponse(e, val, "uncertainity_error")}
                  />
                </PaddedGrid>
                <PaddedGrid item xs={12} sm={12} md={6}>
                  <Typography id="label">Timing Intervention</Typography>
                  <b>{parameters.timing_intervention}</b>
                  <Slider
                    value={parameters.timing_intervention}
                    aria-labelledby="label"
                    valueLabelDisplay="auto"
                    name="timing_intervention"
                    min={-20}
                    max={20}
                    marks={[
                      { value: -20, label: "-20 Days" },
                      { value: 20, label: "20 Days" },
                    ]}
                    onChange={(e, val) => onSliderChange(e, val, "timing_intervention")}
                    onChangeCommitted={(e, val) => getResponse(e, val, "timing_intervention")}
                  />
                </PaddedGrid>
                <PaddedGrid item xs={12} sm={12} md={6}>
                  <Typography id="label">Strength Intervention</Typography>
                  <b>{parameters.strength_intervention}</b>
                  <Slider
                    value={parameters.strength_intervention}
                    aria-labelledby="label"
                    valueLabelDisplay="auto"
                    label="slider 3"
                    name="strength_intervention"
                    min={-10}
                    max={10}
                    marks={[
                      { value: -10, label: -10 },
                      { value: 10, label: 10 },
                    ]}
                    onChange={(e, val) => onSliderChange(e, val, "strength_intervention")}
                    onChangeCommitted={(e, val) => getResponse(e, val, "strength_intervention")}
                  />
                </PaddedGrid>
                <PaddedGrid item xs={12} sm={12} md={6}>
                  <>
                    <Typography id="label">Diagnosing Rate</Typography>
                    <b>{parameters.diagnosing_rate}</b>
                    <Slider
                      value={parameters.diagnosing_rate}
                      aria-labelledby="label"
                      valueLabelDisplay="auto"
                      name="diagnosing_rate"
                      min={-10}
                      max={10}
                      marks={[
                        { value: -10, label: -10 },
                        { value: 10, label: 10 },
                      ]}
                      onChange={(e, val) => onSliderChange(e, val, "diagnosing_rate")}
                      onChangeCommitted={(e, val) => getResponse(e, val, "diagnosing_rate")}
                    />
                  </>
                </PaddedGrid>
              </GridJustifiedText>
              <br />
              <StyledButton
                variant="contained"
                disabled={parameters.isDisabled}
                color="primary"
                onClick={getResponse}
              >
                Execute
              </StyledButton>
              {showReset && (
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={resetSlider}
                >
                  Reset
                </StyledButton>
              )}
              <br />
              {' '}
              <br />
            </section>
            {showSpinner && <Spinner text="Loading..." />}
            {result && (
              <center>
                <Grid container direction="row" justify="center">
                  <Grid item xs={12}>
                    <HighchartContainer id="container_1" display />
                  </Grid>
                </Grid>
              </center>
            )}

            <section>
              <br />
              <JustifyColorText>
                Need more Information about COVID-19 Simulation ? Check out-
              </JustifyColorText>
              <LinkContainer>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      size="large"
                      href="https://sites.google.com/view/data-science-covid-19/homepage"
                      target="_blank"
                    >
                      For more info
                      <FontAwesomeIcon
                        icon={faAngleDoubleRight}
                      />
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      size="large"
                      href="https://sites.google.com/site/jamesyzou/"
                      target="_blank"
                    >
                      Dr. James Zou’s lab
                      <FontAwesomeIcon
                        icon={faAngleDoubleRight}
                      />
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      size="large"
                      href="https://web.stanford.edu/~roquero/"
                      target="_blank"
                    >
                      Jaime’s Website
                      <FontAwesomeIcon
                        icon={faAngleDoubleRight}
                      />
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      size="large"
                      href="https://www.virtusa.com/vlife/"
                      target="_blank"
                    >
                      Vlife Portal
                      <FontAwesomeIcon
                        icon={faAngleDoubleRight}
                      />
                    </StyledButton>
                  </Grid>
                </Grid>
              </LinkContainer>
            </section>
          </div>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(Covid19Simulation);

const FormControlWidth30 = styled(FormControl)`
  width: 30%;
`;

const Width80 = styled.div`
  width: 80%;
`;

const SliderWidth80 = styled.div`
  width: 80%;
  margin: 0 20px 0px 20px;
`;

const JustifyColorText = styled.h3`
  color: #3f51b5;
  ext-align: justify;
`;

const LinkButton = styled.a`
  textdecoration: none;
  color: inherit;
`;

const GridJustifiedText = styled(Grid)`
  text-align: justify;
`;

const PaddedGrid = styled(Grid)`
  padding: 10px 40px !important;
`;

const TextColorIcon = styled(Icon)`
  color: #3f51b5;
`;
