import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ToastContainer, toast } from 'react-toastify';
import CohortSenseService from "./CohortSenseService";
import CommonStyles from './commonStyles';

class PreviewDataSection extends React.Component {
  fields = null;

  constructor(props) {
    super(props);
    this.fields = null;
    this.dataRows = null;
    this.onSelectChanged = this.onSelectChanged.bind(this);
    this.onClickFeatureScaling = this.onClickFeatureScaling.bind(this);

    this.state = {
      dataFile: '',
      isLoading: false,
    };
  }

  onSelectChanged(event) {
    const userId = '102';
    this.fields = null;
    this.dataRows = null;
    const dataFile = event.target.value;
    this.setState(
      {
        dataFile,
        isLoading: true,
      },
    );
    // window.sessionStorage.setItem('cohortFileName','CKD_SAMPLE1560429515.csv');

    if (dataFile) {
      let fileName = dataFile;
      if (fileName === 'COHORT_SENSE') {
        fileName += `_${userId}`;
      }

      CohortSenseService.retrievePreviewData(fileName)
        .then((result) => {
          const previewData = JSON.parse(result.predata);

          this.fields = previewData.schema.fields;
          this.dataRows = previewData.data;

          window.sessionStorage.setItem('cohortFileName', result.filename);
          window.sessionStorage.setItem('cohortJsonFileName', result.jfilename);

          this.setState({ isLoading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          toast.error(err);
        });
    }
  }

  onClickFeatureScaling() {
    console.log('onClickFeatureScaling');
    window.sessionStorage.setItem('cohortSelectedFile', this.state.dataFile);
    this.props.navigateNext();
  }

  render() {
    const { classes } = this.props;
    return (
      <section className="App">
        <div>
          <section className={classes.container}>
            <h2 className={classes.titleClient}>
              Preview Data
            </h2>

            <section>
              <form className={classes.root} autoComplete="off">
                <Grid container>
                  <Grid item md={6}>
                    <FormControl className={classes.fullWidth} disabled={this.state.isLoading}>
                      <InputLabel>Cohort Analysis</InputLabel>
                      <Select value={this.state.dataFile} onChange={this.onSelectChanged}>
                        <MenuItem value="COHORT_SENSE">Cohort Sense</MenuItem>
                        <MenuItem value="SAMPLE">Sample</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={6}>
                    {/* <div align="left" className={classes.buttonLayoutArea}> */}
                    {/*    <Button variant="contained" color="primary">Back</Button> */}
                    {/* </div> */}
                  </Grid>

                </Grid>

              </form>
            </section>

            <br />
            <section>
              <Grid container>
                <Grid item md={12}>
                  {
                    this.state.isLoading && (
                      <LinearProgress />
                    )
                  }

                  {
                    this.fields && (
                      <div>
                        <div align="right">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.onClickFeatureScaling}
                            disabled={!this.state.dataFile && !this.state.isLoading}
                          >
                            Feature Scaling
                          </Button>
                        </div>
                        <div className={classes.scrollableTable}>

                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                {
                                  this.fields.map((field) => (
                                    <TableCell align="right">{field.name}</TableCell>
                                  ))
                                }
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                this.dataRows.map((row) => (
                                  <TableRow key={row.index}>
                                    {
                                      this.fields.map((field) => (
                                        <TableCell
                                          align="right"
                                        >
                                          {field.name === 'index' ? row[field.name] + 1 : row[field.name]}
                                        </TableCell>
                                      ))
                                    }
                                  </TableRow>
                                ))
                              }
                            </TableBody>

                          </Table>
                        </div>
                      </div>

                    )
                  }
                </Grid>
              </Grid>
            </section>
            <ToastContainer />
          </section>
        </div>
      </section>
    );
  }
}

PreviewDataSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CommonStyles)(PreviewDataSection);
