//Adult Dataset
import Adult_Nomitigation_1 from "../../../../assets/images/app/responsibleAI/Adult_Nomitigation_1.webp";
import Adult_Nomitigation_2 from "../../../../assets/images/app/responsibleAI/Adult_Nomitigation_2.webp";
import Adult_Nomitigation_3 from "../../../../assets/images/app/responsibleAI/Adult_Nomitigation_3.webp";
import Adult_Reweighing_1 from "../../../../assets/images/app/responsibleAI/Adult_Reweighing_1.webp";
import Adult_Reweighing_2 from "../../../../assets/images/app/responsibleAI/Adult_Reweighing_2.webp";
import Adult_Reweighing_3 from "../../../../assets/images/app/responsibleAI/Adult_Reweighing_3.webp";
import Adult_Reweighing_4 from "../../../../assets/images/app/responsibleAI/Adult_Reweighing_4.webp";
import Adult_Reweighing_5 from "../../../../assets/images/app/responsibleAI/Adult_Reweighing_5.webp";
import Adult_CorrelationRemover_1 from "../../../../assets/images/app/responsibleAI/Adult_CorrelationRemover_1.webp";
import Adult_CorrelationRemover_2 from "../../../../assets/images/app/responsibleAI/Adult_CorrelationRemover_2.webp";
import Adult_CorrelationRemover_3 from "../../../../assets/images/app/responsibleAI/Adult_CorrelationRemover_3.webp";
import Adult_CorrelationRemover_4 from "../../../../assets/images/app/responsibleAI/Adult_CorrelationRemover_4.webp";
import Adult_CorrelationRemover_5 from "../../../../assets/images/app/responsibleAI/Adult_CorrelationRemover_5.webp";
import Adult_ExponentialGradient_1 from "../../../../assets/images/app/responsibleAI/Adult_ExponentialGradient_1.webp";
import Adult_ExponentialGradient_2 from "../../../../assets/images/app/responsibleAI/Adult_ExponentialGradient_2.webp";
import Adult_ExponentialGradient_3 from "../../../../assets/images/app/responsibleAI/Adult_ExponentialGradient_3.webp";
import Adult_ExponentialGradient_4 from "../../../../assets/images/app/responsibleAI/Adult_ExponentialGradient_4.webp";
import Adult_ExponentialGradient_5 from "../../../../assets/images/app/responsibleAI/Adult_ExponentialGradient_5.webp";
import Adult_GridSearch_1 from "../../../../assets/images/app/responsibleAI/Adult_GridSearch_1.webp";
import Adult_GridSearch_2 from "../../../../assets/images/app/responsibleAI/Adult_GridSearch_2.webp";
import Adult_GridSearch_3 from "../../../../assets/images/app/responsibleAI/Adult_GridSearch_3.webp";
import Adult_GridSearch_4 from "../../../../assets/images/app/responsibleAI/Adult_GridSearch_4.webp";
import Adult_GridSearch_5 from "../../../../assets/images/app/responsibleAI/Adult_GridSearch_5.webp";
import Adult_Adversarial_1 from "../../../../assets/images/app/responsibleAI/Adult_Adversarial_1.webp";
import Adult_Adversarial_2 from "../../../../assets/images/app/responsibleAI/Adult_Adversarial_2.webp";
import Adult_Adversarial_3 from "../../../../assets/images/app/responsibleAI/Adult_Adversarial_3.webp";
import Adult_Adversarial_4 from "../../../../assets/images/app/responsibleAI/Adult_Adversarial_4.webp";
import Adult_Adversarial_5 from "../../../../assets/images/app/responsibleAI/Adult_Adversarial_5.webp";
import Adult_ThresholdOptimizer_1 from "../../../../assets/images/app/responsibleAI/Adult_ThresholdOptimizer_1.webp";
import Adult_ThresholdOptimizer_2 from "../../../../assets/images/app/responsibleAI/Adult_ThresholdOptimizer_2.webp";
import Adult_ThresholdOptimizer_3 from "../../../../assets/images/app/responsibleAI/Adult_ThresholdOptimizer_3.webp";
import Adult_ThresholdOptimizer_4 from "../../../../assets/images/app/responsibleAI/Adult_ThresholdOptimizer_4.webp";
import Adult_ThresholdOptimizer_5 from "../../../../assets/images/app/responsibleAI/Adult_ThresholdOptimizer_5.webp";

//Creditcard Dataset
import CreditCard_Nomitigation_1 from "../../../../assets/images/app/responsibleAI/CreditCard_Nomitigation_1.webp";
import CreditCard_Nomitigation_2 from "../../../../assets/images/app/responsibleAI/CreditCard_Nomitigation_2.webp";
import CreditCard_Reweighing_1 from "../../../../assets/images/app/responsibleAI/CreditCard_Reweighing_1.webp";
import CreditCard_Reweighing_2 from "../../../../assets/images/app/responsibleAI/CreditCard_Reweighing_2.webp";
import CreditCard_CorrelationRemover_1 from "../../../../assets/images/app/responsibleAI/CreditCard_CorrelationRemover_1.webp";
import CreditCard_CorrelationRemover_2 from "../../../../assets/images/app/responsibleAI/CreditCard_CorrelationRemover_2.webp";
import CreditCard_ExponentialGradient_1 from "../../../../assets/images/app/responsibleAI/CreditCard_ExponentialGradient_1.webp";
import CreditCard_ExponentialGradient_2 from "../../../../assets/images/app/responsibleAI/CreditCard_ExponentialGradient_2.webp";
import CreditCard_GridSearch_1 from "../../../../assets/images/app/responsibleAI/CreditCard_GridSearch_1.webp";
import CreditCard_GridSearch_2 from "../../../../assets/images/app/responsibleAI/CreditCard_GridSearch_2.webp";
import CreditCard_Adversarial_1 from "../../../../assets/images/app/responsibleAI/CreditCard_Adversarial_1.webp";
import CreditCard_ThresholdOptimizer_1 from "../../../../assets/images/app/responsibleAI/CreditCard_ThresholdOptimizer_1.webp";
import CreditCard_ThresholdOptimizer_2 from "../../../../assets/images/app/responsibleAI/CreditCard_ThresholdOptimizer_2.webp";
//dibetic dataset
import Diabetic_Nomitigation_1 from "../../../../assets/images/app/responsibleAI/Diabetic_Nomitigation_1.webp";
import Diabetic_Nomitigation_2 from "../../../../assets/images/app/responsibleAI/Diabetic_Nomitigation_2.webp";
import Diabetic_Nomitigation_3 from "../../../../assets/images/app/responsibleAI/Diabetic_Nomitigation_3.webp";
import Diabetic_Reweighing_1 from "../../../../assets/images/app/responsibleAI/Diabetic_Reweighing_1.webp";
import Diabetic_Reweighing_2 from "../../../../assets/images/app/responsibleAI/Diabetic_Reweighing_2.webp";
import Diabetic_Reweighing_3 from "../../../../assets/images/app/responsibleAI/Diabetic_Reweighing_3.webp";
import Diabetic_Reweighing_4 from "../../../../assets/images/app/responsibleAI/Diabetic_Reweighing_4.webp";
import Diabetic_Reweighing_5 from "../../../../assets/images/app/responsibleAI/Diabetic_Reweighing_5.webp";
import Diabetic_CorrelationRemover_1 from "../../../../assets/images/app/responsibleAI/Diabetic_CorrelationRemover_1.webp";
import Diabetic_CorrelationRemover_2 from "../../../../assets/images/app/responsibleAI/Diabetic_CorrelationRemover_2.webp";
import Diabetic_CorrelationRemover_3 from "../../../../assets/images/app/responsibleAI/Diabetic_CorrelationRemover_3.webp";
import Diabetic_CorrelationRemover_4 from "../../../../assets/images/app/responsibleAI/Diabetic_CorrelationRemover_4.webp";
import Diabetic_CorrelationRemover_5 from "../../../../assets/images/app/responsibleAI/Diabetic_CorrelationRemover_5.webp";
import Diabetic_ExponentialGradient_1 from "../../../../assets/images/app/responsibleAI/Diabetic_ExponentialGradient_1.webp";
import Diabetic_ExponentialGradient_2 from "../../../../assets/images/app/responsibleAI/Diabetic_ExponentialGradient_2.webp";
import Diabetic_ExponentialGradient_3 from "../../../../assets/images/app/responsibleAI/Diabetic_ExponentialGradient_3.webp";
import Diabetic_ExponentialGradient_4 from "../../../../assets/images/app/responsibleAI/Diabetic_ExponentialGradient_4.webp";
import Diabetic_ExponentialGradient_5 from "../../../../assets/images/app/responsibleAI/Diabetic_ExponentialGradient_5.webp";
import Diabetic_GridSearch_1 from "../../../../assets/images/app/responsibleAI/Diabetic_GridSearch_1.webp";
import Diabetic_GridSearch_2 from "../../../../assets/images/app/responsibleAI/Diabetic_GridSearch_2.webp";
import Diabetic_GridSearch_3 from "../../../../assets/images/app/responsibleAI/Diabetic_GridSearch_3.webp";
import Diabetic_GridSearch_4 from "../../../../assets/images/app/responsibleAI/Diabetic_GridSearch_4.webp";
import Diabetic_GridSearch_5 from "../../../../assets/images/app/responsibleAI/Diabetic_GridSearch_5.webp";
import Diabetic_Adversarial_1 from "../../../../assets/images/app/responsibleAI/Diabetic_Adversarial_1.webp";
import Diabetic_Adversarial_2 from "../../../../assets/images/app/responsibleAI/Diabetic_Adversarial_2.webp";
import Diabetic_Adversarial_3 from "../../../../assets/images/app/responsibleAI/Diabetic_Adversarial_3.webp";
import Diabetic_Adversarial_4 from "../../../../assets/images/app/responsibleAI/Diabetic_Adversarial_4.webp";
import Diabetic_Adversarial_5 from "../../../../assets/images/app/responsibleAI/Diabetic_Adversarial_5.webp";
import Diabetic_ThresholdOptimizer_1 from "../../../../assets/images/app/responsibleAI/Diabetic_ThresholdOptimizer_1.webp";
import Diabetic_ThresholdOptimizer_2 from "../../../../assets/images/app/responsibleAI/Diabetic_ThresholdOptimizer_2.webp";
import Diabetic_ThresholdOptimizer_3 from "../../../../assets/images/app/responsibleAI/Diabetic_ThresholdOptimizer_3.webp";
import Diabetic_ThresholdOptimizer_4 from "../../../../assets/images/app/responsibleAI/Diabetic_ThresholdOptimizer_4.webp";
import Diabetic_ThresholdOptimizer_5 from "../../../../assets/images/app/responsibleAI/Diabetic_ThresholdOptimizer_5.webp";

//indian liver patient
import IndianLiverPatient_NoMitigation_1 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_NoMitigation_1.webp";
import IndianLiverPatient_NoMitigation_2 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_NoMitigation_2.webp";
import IndianLiverPatient_Reweighing_1 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_Reweighing_1.webp";
import IndianLiverPatient_Reweighing_2 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_Reweighing_2.webp";
import IndianLiverPatient_CorrelationRemover_1 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_CorrelationRemover_1.webp";
import IndianLiverPatient_CorrelationRemover_2 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_CorrelationRemover_2.webp";
import IndianLiverPatient_ExponentialGradient_1 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_ExponentialGradient_1.webp";
import IndianLiverPatient_ExponentialGradient_2 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_ExponentialGradient_2.webp";
import IndianLiverPatient_GridSearch_1 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_GridSearch_1.webp";
import IndianLiverPatient_GridSearch_2 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_GridSearch_2.webp";
import IndianLiverPatient_Adversarial_1 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_Adversarial_1.webp";
import IndianLiverPatient_ThresholdOptimizer_1 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_ThresholdOptimizer_1.webp";
import IndianLiverPatient_ThresholdOptimizer_2 from "../../../../assets/images/app/responsibleAI/IndianLiverPatient_ThresholdOptimizer_2.webp";

const Obj = {
  adultDataset: {
    texts: {
      Dataset: "Adult",
      Mitigation: "None",
      "Protected Attribute": "Ethnic_group​",
      "Accuracy with no mitigation applied": "78%",
      // "With default thresholds, bias against unprivileged group detected in":
      //   "4 out of 4 metrics",
    },
    text:[
      {data:  "With default thresholds, bias against unprivileged group detected in 4 out of 4 metrics", }
    ],
    images: [
      { src: Adult_Nomitigation_1, grid: 12 },
      { src: Adult_Nomitigation_2, grid: 12 },
      { src: Adult_Nomitigation_3, grid: 12 },
    ],
    mitigation: {
      reweighing: {
        texts: {
          Dataset: "Adult",
          Mitigation: "Reweighing algorithm applied",
          "Protected Attribute": "Ethnic group",
          "Privileged Group": "White",
          "Unprivileged Group": "Other",
          "Accuracy after mitigation": "100%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​":
          //  "",
        },
        text:[{
          data: "Bias against unprivileged group was reduced to acceptable levels for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​"
        }],
        images: [
          { src: Adult_Reweighing_1, grid: 12 },
          { src: Adult_Reweighing_2, grid: 12 },
          { src: Adult_Reweighing_3, grid: 12 },
          { src: Adult_Reweighing_4, grid: 12 },
          { src: Adult_Nomitigation_3, grid: 12 , title: 'Original Bias Metrics:'},
          { src: Adult_Reweighing_5, grid: 12 , title: 'Mitigated Bias Metrics:'},
        ],
      },
      correlationRemover: {
        texts: {
          Dataset: "Adult",
          Mitigation: "Correlation Remover algorithm applied",
          "Protected Attribute": "Ethnic group",
          "Privileged Group": "Asian-Pac-Islander",
          "Unprivileged Group": "Other",
          "Accuracy after mitigation": "78%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 0 of 4 previously biased metrics (4 of 4 metrics still indicate bias for unprivileged group)​)​":
          //   "",
        },
        text:[{
          data: "Bias against unprivileged group was reduced to acceptable levels for 0 of 4 previously biased metrics (4 of 4 metrics still indicate bias for unprivileged group)​)​"
        }],
        images: [
          { src: Adult_CorrelationRemover_1, grid: 12 },
          { src: Adult_CorrelationRemover_2, grid: 12 },
          { src: Adult_CorrelationRemover_3, grid: 12 },
          { src: Adult_CorrelationRemover_4, grid: 12 },
          { src: Adult_Nomitigation_3, grid: 12 , title: 'Original Bias Metrics:'},
          { src: Adult_CorrelationRemover_5, grid: 12 , title: 'Mitigated Bias Metrics:'},
        ],
      },
      exponentialGradient: {
        texts: {
          Dataset: "Adult",
          Mitigation: "Exponential Gradient algorithm applied​​",
          "Protected Attribute": "Ethnic group",
          "Privileged Group": "Asian-Pac-Islander",
          "Unprivileged Group": "Other",
          "Accuracy after mitigation": "80.94%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​)​"
      }],
        images: [
          { src: Adult_ExponentialGradient_1, grid: 12 },
          { src: Adult_ExponentialGradient_2, grid: 12 },
          { src: Adult_ExponentialGradient_3, grid: 12 },
          { src: Adult_ExponentialGradient_4, grid: 12 },
          { src: Adult_Nomitigation_3, grid: 12 , title: 'Original Bias Metrics:'},
          { src: Adult_ExponentialGradient_5, grid: 12 , title: 'Mitigated Bias Metrics:'},
        ],
      },
      gridSearch: {
        texts: {
          Dataset: "Adult",
          Mitigation: "Grid Search algorithm applied​",
          "Protected Attribute": "Ethnic group",
          "Privileged Group": "Asian-Pac-Islander",
          "Unprivileged Group": "Other ",
          "Accuracy after mitigation": " 85.02%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​)​"
        }],
        images: [
          { src: Adult_GridSearch_1, grid: 12 },
          { src: Adult_GridSearch_2, grid: 12 },
          { src: Adult_GridSearch_3, grid: 12 },
          { src: Adult_GridSearch_4, grid: 12 },
          { src: Adult_Nomitigation_3, grid: 12 , title: 'Original Bias Metrics:'},
          { src: Adult_GridSearch_5, grid: 12 , title: 'Mitigated Bias Metrics:'},
        ],
      },
      adversarialFairnessClassifier: {
        texts: {
          Dataset: "Adult",
          Mitigation: "Adversarial Fairness Classifier algorithm applied​",
          "Protected Attribute": "Ethnic group",
          "Privileged Group": "Asian-Pac-Islander",
          "Unprivileged Group": "Other",
          "Accuracy after mitigation": "84.04%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 0 of 4 previously biased metrics (4 of 4 metrics still indicate bias for unprivileged group))​":
          //   "",
        },
        text:[{
          data: "Bias against unprivileged group was reduced to acceptable levels for 0 of 4 previously biased metrics (4 of 4 metrics still indicate bias for unprivileged group))​"
        }],
        images: [
          { src: Adult_Adversarial_1, grid: 12 },
          { src: Adult_Adversarial_2, grid: 12 },
          { src: Adult_Adversarial_3, grid: 12 },
          { src: Adult_Adversarial_4, grid: 12 },
          { src: Adult_Nomitigation_3, grid: 12 , title: 'Original Bias Metrics:'},
          { src: Adult_Adversarial_5, grid: 12 , title: 'Mitigated Bias Metrics:'},
        ],
      },
      thresholdOptimizer: {
        texts: {
          Dataset: "Adult",
          Mitigation: "Threshold Optimizer algorithm applied",
          "Protected Attribute": "Ethnic group",
          "Privileged Group": "Asian-Pac-Islander",
          "Unprivileged Group": "Other",
          "Accuracy after mitigation": "82.71%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: Adult_ThresholdOptimizer_1, grid: 12 },
          { src: Adult_ThresholdOptimizer_2, grid: 12 },
          { src: Adult_ThresholdOptimizer_3, grid: 12 },
          { src: Adult_ThresholdOptimizer_4, grid: 12 },
          { src: Adult_Nomitigation_3, grid: 12 , title: 'Original Bias Metrics:'},
          { src: Adult_ThresholdOptimizer_5, grid: 12 , title: 'Mitigated Bias Metrics:'},
        ],
      },
    },
  },

  creditCard: {
    texts: {
      Dataset: "Credit Card",
      Mitigation: "None",
      "Protected Attribute": "Sex​",
      "Accuracy with no mitigation applied": "81.43%",
      //"With default thresholds, bias against unprivileged group detected in 3 out of 4 metrics",
    },
    text:[{data: "With default thresholds, bias against unprivileged group detected in 3 out of 4 metrics",}],

    images: [
      { src: CreditCard_Nomitigation_1, grid: 12 },
      { src: CreditCard_Nomitigation_2, grid: 12 },
    ],
    mitigation: {
      reweighing: {
        texts: {
          Dataset: "Credit Card",
          Mitigation: "Reweighing algorithm applied",
          "Protected Attribute": "SEX",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "82.08%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​)​"}],
        images: [
          { src: CreditCard_Reweighing_1, grid: 12 },
          { src: CreditCard_Nomitigation_2, grid: 12 , title: 'Original Bias Metrics:'},
          { src: CreditCard_Reweighing_2, grid: 12, title: 'Mitigated Bias Metrics:' },
        ],
      },
      correlationRemover: {
        texts: {
          Dataset: "Credit Card",
          Mitigation: "Correlation Remover algorithm applied",
          "Protected Attribute": "SEX",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "82.08%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)":
          //   "",
        },
         text:[{data: "Bias against unprivileged group was reduced to acceptable levels for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)"}],
        images: [
          { src: CreditCard_CorrelationRemover_1, grid: 12 },
          { src: CreditCard_Nomitigation_2, grid: 12 , title: 'Original Bias Metrics:'},
          { src: CreditCard_CorrelationRemover_2, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      exponentialGradient: {
        texts: {
          Dataset: "Credit Card",
          Mitigation: "Exponential Gradient algorithm applied​​",
          "Protected Attribute": "SEX",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "80.94%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​)​":
          //   "",
        },
         text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​)​"
        
        }],
        images: [
          { src: CreditCard_ExponentialGradient_1, grid: 12 },
          { src: CreditCard_Nomitigation_2, grid: 12 , title: 'Original Bias Metrics:'},
          { src: CreditCard_ExponentialGradient_2, grid: 12,  title: 'Mitigated Bias Metrics:' },
        ],
      },
      gridSearch: {
        texts: {
          Dataset: "Credit Card",
          Mitigation: "Grid Search algorithm applied​",
          "Protected Attribute": "Sex",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "82.39%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 4 of 4 previously biased metrics (0 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data: "Bias against unprivileged group was reduced to acceptable levels for 4 of 4 previously biased metrics (0 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: CreditCard_GridSearch_1, grid: 12 },
          { src: CreditCard_Nomitigation_2, grid: 12 , title: 'Original Bias Metrics:'},
          { src: CreditCard_GridSearch_2, grid: 12 , title: 'Mitigated Bias Metrics:'},
        ],
      },
      adversarialFairnessClassifier: {
        texts: {
          Dataset: "Credit Card",
          Mitigation: "Adversarial Fairness Classifier algorithm applied​",
          "Protected Attribute": "SEX",
          "Privileged Group": "Female",
          "Unprivileged Group": "Male",
          "Accuracy after mitigation": "81.5%",
          // "Bias against unprivileged group was reduced to Acceptable levels* for  1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        }, 
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for  1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​"}],

        images: [
          { src: CreditCard_Nomitigation_2, grid: 12 , title: 'Original Bias Metrics:'},
        { src: CreditCard_Adversarial_1, grid: 12,title: 'Mitigated Bias Metrics:' }
      ],
      },
      thresholdOptimizer: {
        texts: {
          Dataset: "Credit Card",
          Mitigation: "Threshold Optimizer algorithm applied",
          "Protected Attribute": "Sex",
          "Privileged Group": "Female",
          "Unprivileged Group": "Male",
          "Accuracy after mitigation": "78.54%​",
          // "Bias against unprivileged group was reduced to Acceptable levels* for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​)​"}],
        images: [
          { src: CreditCard_ThresholdOptimizer_1, grid: 12 },
          { src: CreditCard_Nomitigation_2, grid: 12 , title: 'Original Bias Metrics:'},
          { src: CreditCard_ThresholdOptimizer_2, grid: 12, title: 'Mitigated Bias Metrics:' },
        ],
      },
    },
  },

  diabetic: {
    texts: {
      Dataset: "Diabetic",
      Mitigation: "None",
      "Protected Attribute": "Race​",
      "Accuracy with no mitigation applied": "85.33%",
      //"With default thresholds, bias against unprivileged group detected in 3 out of 4 metrics",
    },
    text:[{data:"With default thresholds, bias against unprivileged group detected in 3 out of 4 metrics",}],
    images: [
      { src: Diabetic_Nomitigation_1, grid: 12 },
      { src: Diabetic_Nomitigation_2, grid: 12 },
      { src: Diabetic_Nomitigation_3, grid: 12 },
    ],

    mitigation: {
      reweighing: {
        texts: {
          Dataset: "Diabetic​",
          Mitigation: "Reweighing algorithm applied​",
          "Protected Attribute": "Race",
          "Privileged Group": "Hispanic",
          "Unprivileged Group": "Asian​",
          "Accuracy after mitigation": "100%​",
          // "Bias against unprivileged group was reduced to acceptable levels* for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data: "Bias against unprivileged group was reduced to acceptable levels for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: Diabetic_Reweighing_1, grid: 12 },
          { src: Diabetic_Reweighing_2, grid: 12 },
          { src: Diabetic_Reweighing_3, grid: 12 },
          { src: Diabetic_Reweighing_4, grid: 12 },
          { src: Diabetic_Nomitigation_3, grid: 12, title: 'Original Bias Metrics:' },
          { src: Diabetic_Reweighing_5, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      correlationRemover: {
        texts: {
          Dataset: "Diabetic​",
          Mitigation: "Correlation Remover algorithm applied​​",
          "Protected Attribute": "Race​",
          "Privileged Group": "Other",
          "Unprivileged Group": "Asian​",
          "Accuracy after mitigation": "85.33%​",
          // "Bias against unprivileged group was reduced to acceptable levels* for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: Diabetic_CorrelationRemover_1, grid: 12 },
          { src: Diabetic_CorrelationRemover_2, grid: 12 },
          { src: Diabetic_CorrelationRemover_3, grid: 12 },
          { src: Diabetic_CorrelationRemover_4, grid: 12 },
          { src: Diabetic_Nomitigation_3, grid: 12, title: 'Original Bias Metrics:' },
          { src: Diabetic_CorrelationRemover_5, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      exponentialGradient: {
        texts: {
          Dataset: "Diabetic​",
          Mitigation: "Exponential Gradient algorithm applied​",
          "Protected Attribute": "Race​",
          "Privileged Group": "Hispanic",
          "Unprivileged Group": "Asian​",
          "Accuracy after mitigation": "80.56%​",
          // "Bias against unprivileged group was reduced to acceptable levels* for 4 of 4 previously biased metrics (0 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 4 of 4 previously biased metrics (0 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: Diabetic_ExponentialGradient_1, grid: 12 },
          { src: Diabetic_ExponentialGradient_2, grid: 12 },
          { src: Diabetic_ExponentialGradient_3, grid: 12 },
          { src: Diabetic_ExponentialGradient_4, grid: 12 },
          { src: Diabetic_Nomitigation_3, grid: 12, title: 'Original Bias Metrics:' },
          { src: Diabetic_ExponentialGradient_5, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      gridSearch: {
        texts: {
          Dataset: "Diabetic​",
          Mitigation: "Grid Search algorithm applied​",
          "Protected Attribute": "Race​",
          "Privileged Group": "AfricanAmerican",
          "Unprivileged Group": " Caucasian​",
          "Accuracy after mitigation": "79.65%​",
          // "Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: Diabetic_GridSearch_1, grid: 12 },
          { src: Diabetic_GridSearch_2, grid: 12 },
          { src: Diabetic_GridSearch_3, grid: 12 },
          { src: Diabetic_GridSearch_4, grid: 12 },
          { src: Diabetic_Nomitigation_3, grid: 12, title: 'Original Bias Metrics:' },
          { src: Diabetic_GridSearch_5, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      adversarialFairnessClassifier: {
        texts: {
          Dataset: "Diabetic​",
          Mitigation: "AdversarialFairnessClassifier algorithm applied​​",
          "Protected Attribute": "Race​",
          "Privileged Group": "Hispanic",
          "Unprivileged Group": " Caucasian​",
          "Accuracy after mitigation": "55.00%​",
          // "Bias against unprivileged group was reduced to acceptable levels for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data: "Bias against unprivileged group was reduced to acceptable levels for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: Diabetic_Adversarial_1, grid: 12 },
          { src: Diabetic_Adversarial_2, grid: 12 },
          { src: Diabetic_Adversarial_3, grid: 12 },
          { src: Diabetic_Adversarial_4, grid: 12 },
          { src: Diabetic_Nomitigation_3, grid: 12, title: 'Original Bias Metrics:' },
          { src: Diabetic_Adversarial_5, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      thresholdOptimizer: {
        texts: {
          Dataset: "Diabetic​",
          Mitigation: "Threshold Optimizer algorithm applied​​​",
          "Protected Attribute": "Race​",
          "Privileged Group": "Hispanic",
          "Unprivileged Group": " Asian",
          "Accuracy after mitigation": "80.40%​",
          // "Bias against unprivileged group was reduced to acceptable levels* for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)":
          //   "",
        },
        text:[{data: "Bias against unprivileged group was reduced to acceptable levels for 1 of 4 previously biased metrics (3 of 4 metrics still indicate bias for unprivileged group)"}],
        images: [
          { src: Diabetic_ThresholdOptimizer_1, grid: 12 },
          { src: Diabetic_ThresholdOptimizer_2, grid: 12 },
          { src: Diabetic_ThresholdOptimizer_3, grid: 12 },
          { src: Diabetic_ThresholdOptimizer_4, grid: 12 },
          { src: Diabetic_Nomitigation_3, grid: 12, title: 'Original Bias Metrics:' },
          { src: Diabetic_ThresholdOptimizer_5, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
    },
  },
  indianLiverPatientDataset: {
    texts: {
      Dataset: "Indian Liver Patient Dataset",
      Mitigation: "None",
      "Protected Attribute": "Gender",
      "Accuracy with no mitigation applied": "76.16%",
      // "With default thresholds, bias against unprivileged group detected in":
      //   "3 out of 4 metrics",
    },
    text:[{data:  "With default thresholds, bias against unprivileged group detected in3 out of 4 metrics",}],
    images: [
      { src: IndianLiverPatient_NoMitigation_1, grid: 12 },
      { src: IndianLiverPatient_NoMitigation_2, grid: 12 },
    ],

    mitigation: {
      reweighing: {
        texts: {
          Dataset: "IndianLiverPatient​",
          Mitigation: "Reweighing algorithm applied​",
          "Protected Attribute": "gender",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "100%​",
          // "Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: IndianLiverPatient_Reweighing_1, grid: 12 },
          { src: IndianLiverPatient_NoMitigation_2, grid: 12,title: 'Original Bias Metrics:' },
          { src: IndianLiverPatient_Reweighing_2, grid: 12, title: 'Mitigated Bias Metrics:' },
        ],
      },
      correlationRemover: {
        texts: {
          Dataset: "IndianLiverPatient​",
          Mitigation: "Correlation Remover algorithm applied​​",
          "Protected Attribute": "gender",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "74.81%​",
          // "Bias against unprivileged group was reduced to acceptable levels* for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data: "Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: IndianLiverPatient_CorrelationRemover_1, grid: 12 },
          { src: IndianLiverPatient_NoMitigation_2, grid: 12,title: 'Original Bias Metrics:' },

          { src: IndianLiverPatient_CorrelationRemover_2, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      exponentialGradient: {
        texts: {
          Dataset: "IndianLiverPatient​",
          Mitigation: "Exponential Gradient algorithm applied​​",
          "Protected Attribute": "Gender",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "94.86%​",
          // "Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: IndianLiverPatient_ExponentialGradient_1, grid: 12 },
          { src: IndianLiverPatient_NoMitigation_2, grid: 12,title: 'Original Bias Metrics:' },

          { src: IndianLiverPatient_ExponentialGradient_2, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      gridSearch: {
        texts: {
          Dataset: "IndianLiverPatient​",
          Mitigation: "Grid Search algorithm applied​​",
          "Protected Attribute": "gender",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "94.74%​",
          // "Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data: "Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [
          { src: IndianLiverPatient_GridSearch_1, grid: 12 },
          { src: IndianLiverPatient_NoMitigation_2, grid: 12,title: 'Original Bias Metrics:' },

          { src: IndianLiverPatient_GridSearch_2, grid: 12,title: 'Mitigated Bias Metrics:' },
        ],
      },
      adversarialFairnessClassifier: {
        texts: {
          Dataset: "IndianLiverPatient​",
          Mitigation: "AdversarialFairnessClassifier algorithm applied​​",
          "Protected Attribute": "gender",
          "Privileged Group": "Female",
          "Unprivileged Group": "Male",
          "Accuracy after mitigation": "67.97%​",
          // "Bias against unprivileged group was reduced to acceptable levels for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 2 of 4 previously biased metrics (2 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [          
          { src: IndianLiverPatient_NoMitigation_2, grid: 12,title: 'Original Bias Metrics:' },
        { src: IndianLiverPatient_Adversarial_1, grid: 12 ,title: 'Mitigated Bias Metrics:'}],
      },
      thresholdOptimizer: {
        texts: {
          Dataset: "IndianLiverPatient​",
          Mitigation: "Threshold Optimizer algorithm applied​​",
          "Protected Attribute": "gender",
          "Privileged Group": "Male",
          "Unprivileged Group": "Female",
          "Accuracy after mitigation": "94.74%​",
          // "Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​":
          //   "",
        },
        text:[{data:"Bias against unprivileged group was reduced to acceptable levels for 3 of 4 previously biased metrics (1 of 4 metrics still indicate bias for unprivileged group)​"}],
        images: [ 
          { src: IndianLiverPatient_ThresholdOptimizer_1, grid: 12, },
          { src: IndianLiverPatient_NoMitigation_2, grid: 12,title: 'Original Bias Metrics:' },
        { src: IndianLiverPatient_ThresholdOptimizer_2, grid: 12,title: 'Mitigated Bias Metrics:' }
      ],
      },
    },
  },
};
export default Obj;
