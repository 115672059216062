import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";

// local components
import SolutionContainer from "../../common/SolutionContainerWrapper";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  LinkContainer,
  DemoContainer,
  ListItem,
} from "../../../styles/common";

// images
import ProviderNetworkAnalysis_sol1 from "../../../assets/images/app/ProviderNetworkAnalysis.webp";

const ZeroShotClassification = () => {
  const [notification, setNotification] = useState("");

  return (

    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
      <Paragraph>
        <Grid container xs={12} direction="row" justify="center">
          <Grid item xs={12} >
            <p>Zero-shot topic classification is a technique that allows us to associate an appropriate label with a piece of text. This association is irrespective of the text domain and the aspect. For example, it can be a topic, emotion, or event described by the label.</p>
            <p>In this application, you can define your own labels and then run a classifier to assign a probability to each label. There is an option to do multi-class classification too, in this case, the scores will be independent, each will fall between 0 and 1. With the default option, the pipeline assumes that only one of the candidate labels is true, returning a list of scores for each label which adds up to 1.</p>
            <p>To perform zero-shot topic classification, we need a zero-shot topic classification model, that allows us to classify data that has not been previously used to build the model. In simple terms, it uses a model built by other people, against your data. Hugging Face allows us to leverage a pre-trained BERT model to implement zero-shot topic classification on text using Python.</p>
          </Grid>
        </Grid>
      </Paragraph>
      </Collapse>
      <LinkContainer>
        <Grid container spacing={2}>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<OpenInNewIcon />}
            >
              <a
                href="https://material.vlifevirtusa.com/Zero-ShotClassification/Zero_shot_classification.html"
                target="_blank"
                rel="noreferrer"
              >
                Notebook
              </a>
            </StyledButton>
          </Grid>


          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://huggingface.co/joeddav/xlm-roberta-large-xnli"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
        </Grid>
      </LinkContainer>
      <Collapse text='Demo'>
        <DemoContainer>
          <center>
            <StyledButton
              variant="contained"
              color="primary"
              size="large"
              textColor="white"
            >
              <a
                href="https://integ-apps.vlifevirtusa.com:5052/"
                target="_blank"
                rel="noreferrer"
              >
                Try Demo
              </a>
            </StyledButton>
          </center>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(ZeroShotClassification);
