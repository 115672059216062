import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import local components
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import AraaliNetworks_sol1 from "../../../assets/images/app/araali_sol1.webp";
import AraaliNetworks_sol2 from "../../../assets/images/app/araali_sol2.webp";
import AraaliNetworks_sol3 from "../../../assets/images/app/araali_sol3.webp";
import AraaliNetworks_sol4 from "../../../assets/images/app/araali_sol4.webp";
import AraaliNetworks_sol5 from "../../../assets/images/app/araali_sol5.webp";
import AraaliNetworks_sol6 from "../../../assets/images/app/araali_sol6.webp";

const AraaliNetworks = () => {
  const [notification, setNotification] = useState("");

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <a href="https://www.araalinetworks.com/" target="_blank" rel="noreferrer">Araali Networks</a>
            {' '}
            transparently secures the network connections between services deployed on VMs, containers, and container management platforms like Kubernetes. It creates an identity-based, passwordless system that performs authentication, authorization, and audit for any inter-service communication out of the box. It is a 3rd Gen Cloud Workload Protection. It works on the principle of Least Privilege for Apps on any Cloud, or any Resource.
          </p>
          <p>Araali is to be used for Modern Cyber Risk Management. Digital Transformation and the cloud are significantly changing risk around customer data. The figures below show the Gaps in the cloud controls and how Araali secures the risks. </p>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="100%" src={AraaliNetworks_sol1} alt="" />
            </Grid>
          </center>
          <p><strong>Araali: Least Privilege for Apps – any Cloud, any Resource</strong></p>
          <p>It works on below 3 principles:</p>
          <Grid>
            <Paper>
              <TableDiv>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableHead align="left">PREPARE</StyledTableHead>
                      <StyledTableHead align="left">PRIORITISE</StyledTableHead>
                      <StyledTableHead align="left">PROTECT</StyledTableHead>
                    </TableRow>
                  </TableHead>
                  <TableBody align="center">
                    <TableRow>
                      <TableCell>A Network diagram of the application running in production</TableCell>
                      <TableCell>Identifies the critical applications and services by ‘Auto detecting the protect surface’</TableCell>
                      <TableCell>Protect applications inside out- only allow identity-based access to critical applications and services</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Clearly shows the hierarchical structure of the pods, containers, and applications</TableCell>
                      <TableCell>Focus on the protect surface first. </TableCell>
                      <TableCell>Monitor egress – breaches happen as there is no good way to control what leaves the prod environment</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Displays the communications between the production and any open source and third-party resources</TableCell>
                      <TableCell />
                      <TableCell>Assume Compromises – the production as a overall is not pristine</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {' '}
                        <center>
                          <img width="100%" src={AraaliNetworks_sol2} alt="" />
                        </center>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <center>
                          <img width="100%" src={AraaliNetworks_sol3} alt="" />
                        </center>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <center>
                          <img width="100%" src={AraaliNetworks_sol4} alt="" />
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableDiv>
            </Paper>
          </Grid>
          <p><strong>Araali on vLife™</strong></p>
          <p>Araali works on Zero Trust Policy and considers all the communication to be a threat and unsecure. Araali Captures the information about the network connections happening both at the entry and the exit ports of the applications. The network connections allow and deny policies are customizable, scalable and platform independent and helps the developer restrict the traffic. For the vLife’s demo application, Araali.yaml file is deployed in the cluster, which collects the information about the network connections happening in the Pod. This Session is Authorized on Araali Console, and the application connection are immediately reflected on the console.</p>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="100%" src={AraaliNetworks_sol5} alt="" />
            </Grid>
          </center>
          <p><strong>vLife & Araali Networks Collaboration: </strong></p>
          <p>A Flask API which communicates to AWS Service is deployed in a EKS cluster and Araali Assessment is triggered on this cluster. The network connections, threats, alerts, and other details are then captured on Araali Console.</p>
          <List>
            <ListItem>An EKS Cluster is setup on AWS with a set of 2 Worker nodes which hosts all the required Kubernetes files & user application.</ListItem>
            <ListItem>Flask application is deployed in an EKS cluster, with a two way interaction enabled between the internet and the ECS cluster</ListItem>
            <ListItem>The Control pane is accessible via Instance private IP, where we used to deploy & manage application using kubectl commands</ListItem>
            <ListItem>The Docker image of the application is deployed in the nodes of this Kubernetes cluster, and the control pane’s security groups are enabled for port 443 and 80 </ListItem>
          </List>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="100%" src={AraaliNetworks_sol6} alt="" />
            </Grid>
          </center>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={10} sm={10} md={10}>
              <StyledButton
                variant="contained"
                color="primary"
                textColor="white"
                size="large"
              >
                <a
                  href="https://console.araalinetworks.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Launch Araali Console
                </a>
              </StyledButton>
            </Grid>
            <Grid item xs={10} sm={10} md={10}>
              <Typography>
               Please Contact vLife™ Team for Credentials 
              </Typography>
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(AraaliNetworks);

const TableDiv = styled.div`
                overflow: auto;

                `;

const Image = styled.img`
  width: 250px;
  height: 200px;
  padding: 3% 3% 3% 3%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 240px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;

const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
