import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const FraudClaimsDetection = () => {
  const initialData = {
    id: '',
    inpatient: '',
    operating: '',
    attending: '',
    diagnosis: '',
    admit: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    provider: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "payerProvider", "fraudClaims",
  );

  const showSample1 = () => {
    const NewDataSet = {
      id: 'PRV51001',
      inpatient: '440150',
      operating: '276640.0',
      attending: '449164.29',
      diagnosis: '90020.12',
      admit: '225033.65',
      code1: '181898.23',
      code2: '101383.07',
      code3: '128155.14',
      code4: '78522.12',
      provider: '625',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      id: 'PRV52059',
      inpatient: '7366940',
      operating: '1347522.0',
      attending: '7337767.89',
      diagnosis: '236688.75',
      admit: '2034983.12',
      code1: '6211703.35',
      code2: '4514233.22',
      code3: '3262526.68',
      code4: '2193229.95',
      provider: '1656369',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      id, inpatient, operating, attending, diagnosis, admit, code1, code2, code3, code4, provider,
    } = sampleData;

    const params = `${id},${inpatient},${operating},${attending},${diagnosis},${admit},${code1},${code2},${code3},${code4},${provider}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      SetResultData("");
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          setSpinner(false);
          SetResultData(res.result);

          const percentages = res.importance;
          const params = [];

          percentages.map((arr) => (
            params.push({
              name: arr[0],
              y: arr[1],
            })
          ));

          Highcharts.chart('container', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Provider Fraud is one of the biggest problems facing Medicare. According to the US government, the total Medicare spending increased exponentially due to frauds in Medicare claims. Healthcare fraud is an organized crime which involves peers of providers, physicians, beneficiaries acting together to make fraud claims.</p>
          <p>Rigorous analysis of Medicare data has yielded many physicians who indulge in fraud. They adopt ways in which an ambiguous diagnosis code is used to adopt costliest procedures and drugs. Insurance companies are the most vulnerable institutions impacted due to these bad practices. Due to this reason, insurance companies increased their insurance premiums and as result healthcare is becoming costly matter day by day.</p>
          <p>So in the following use case, various costs reimbursed by the provider are considered along with claim count and fraudulent providers are detected.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/rohitrox/healthcare-provider-fraud-detection-analysis"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Fraud_detection/fraud_detection.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo for Sample">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="id"
                label="Provider ID"
                value={sampleData.id}
                fullWidth
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="inpatient"
                label="IP Annual Reimbursement Amt"
                value={sampleData.inpatient}
                fullWidth
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="operating"
                label="Operating physician avg Reimbursement amt"
                fullWidth
                value={sampleData.operating}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="attending"
                label="Attending physician avg Reimbursement amt"
                fullWidth
                value={sampleData.attending}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="diagnosis"
                label="Diagnosis group code Reimbursement amt"
                fullWidth
                value={sampleData.diagnosis}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="admit"
                label="Claim admit diagnosis Reimbursement amt"
                fullWidth
                value={sampleData.admit}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="code1"
                label="Claim diagnosis code 1 Reimbursement amt"
                fullWidth
                value={sampleData.code1}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="code2"
                label="Claim diagnosis code 2 Reimbursement amt"
                fullWidth
                value={sampleData.code2}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="code3"
                label="Claim diagnosis code 3 Reimbursement amt"
                fullWidth
                value={sampleData.code3}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="code4"
                label="Claim diagnosis code 4 Reimbursement amt"
                fullWidth
                value={sampleData.code4}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                name="provider"
                label="Provider claim count"
                fullWidth
                value={sampleData.provider}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <section>
            <Result>
              <strong>Result:</strong>
              {`${result}`}
            </Result>
          </section>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(FraudClaimsDetection);
