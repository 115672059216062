import React, { memo, useState, useEffect } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setInputData } from '../../../../../../store/actions/vnet';
import InfoToolTip from '../../components/InfoToolTip';

export const DropDuplicatesForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const dispatch = useDispatch();
  const [dropdownList, setdropdownList] = useState(null);
  const [selected, setSelected] = useState(userInputData?.Subset || []);
  const [allVal, setAllVal] = useState([]);
  const [result, setResult] = useState();
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;

    if (value[value.length - 1] === 'all') {
      if ([allVal.length] > 1) {
        allVal.length = 0;
      } else {
        const vals = JSON.parse(result);
        allVal.push(...vals);
      }
      setSelected(selected.length === dropdownList.length ? [] : dropdownList);

      if (e.target.name) {
        dispatch(
          setInputData({
            ...userInputData,
            [e.target.name]: allVal,
          }),
        );
      }
      return;
    }
    if (e.target.name) {
      dispatch(
        setInputData({
          ...userInputData,
          [e.target.name]: e.target.value,
        }),
      );
    }
    setSelected(value);

    error && setError(false);
  };

  const onChecked = (e) => {
    const { value } = e.target;
    if (e.target.name) {
      dispatch(
        setInputData({
          ...userInputData,
          [e.target.name]: e.target.value,
        }),
      );
    }
  };

  const isAllSelected = dropdownList?.length > 0 && selected?.length === dropdownList?.length;

  useEffect(async () => {
    const result = await props.getCategories();
    setResult(result);
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);
  const handleFormReset = () => {
    console.log('reset');
  };

  const isFormValid = () => {
    if (!userInputData?.Subset || userInputData?.Subset?.length == 0) {
      setError(true);
      return;
    }
    return true;
  };
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };

  return (
    <DuplicatesContainer>
      <CenteredGrid
        container
        alignItems="center"
        direction="row"
        alignContent="center"
      >
        <Grid item xs={12} sm={4}>
          <strong>
            <InfoToolTip
              title="Subset:"
              info="Columns to be considered for identifying duplicates"
            >
              Subset
            </InfoToolTip>
          </strong>
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormControl fullWidth error={error}>
            <Select
              labelId="mutiple-select-label"
              multiple
              name="Subset"
              value={selected}
              onChange={handleChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              required
              variant="outlined"
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selected?.length > 0
                      && selected?.length < dropdownList?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <ListItemIcon>
                    <Checkbox checked={selected.indexOf(value) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>Required Field</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <br />
          <strong>
            <InfoToolTip
              title="keep: {‘first’, ‘last’, False}, default ‘first’"
              info="Determines which duplicates (if any) to keep. - first : Drop duplicates except for the first occurrence. - last : Drop duplicates except for the last occurrence. - False : Drop all duplicates."
            >
              {' '}
              Select Keep
            </InfoToolTip>
          </strong>
        </Grid>
        <Grid item xs={12} sm={5}>
          <br />
          <StyledRadioGroup
            row
            justify="center"
            aria-label="Learn Mixture Weights"
            name="learnMixtureWeights"
            defaultValue="first"
            value={userInputData.Keep}
            onChange={onChecked}
          >
            <FormControlLabel
              control={<Radio value="first" name="Keep" />}
              label="First"
            />
            <FormControlLabel
              control={<Radio value="last" name="Keep" />}
              label="Last"
            />
          </StyledRadioGroup>
        </Grid>
        <Grid item xs={2}>
          &nbsp;
        </Grid>
        {/* <Grid item xs={3}>
          <strong><InfoToolTip title='inplace: bool, default False' info='Whether to drop duplicates in place' /> Select Inplace</strong>
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                value={'True'}
                name={'Inplace'}
                onChange={handleCheckBox}
              />
            }
          />
        </Grid> */}
      </CenteredGrid>
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </DuplicatesContainer>
  );
});
const DuplicatesContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
    min-width: 190px;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;

const CenteredGrid = styled(Grid)`
  text-align: center;
`;
