import React, { memo, useState } from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Grid, Button } from '@material-ui/core';

// local components
import Collapse from '../../common/Collapse';

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
} from '../../../styles/common';

// images
import humanproteinImageclassification_sol1 from '../../../assets/images/app/humanproteinImageclassification_sol1.webp';
import humanproteinImageclassification_sol2 from '../../../assets/images/app/humanproteinImageclassification_sol2.webp';
import humanproteinImageclassification_sample_red from '../../../assets/images/app/humanproteinImageclassification_sample_red.webp';
import humanproteinImageclassification_sample_blue from '../../../assets/images/app/humanproteinImageclassification_sample_blue.webp';
import humanproteinImageclassification_sample_green from '../../../assets/images/app/humanproteinImageclassification_sample_green.webp';
import humanproteinImageclassification_sample_yellow from '../../../assets/images/app/humanproteinImageclassification_sample_yellow.webp';
import humanproteinImageclassification_sample1 from '../../../assets/images/app/humanproteinImageclassification_sample2.webp';
import humanproteinImageclassification_sample2 from '../../../assets/images/app/humanproteinImageclassification_sample1.webp';

const HumanproteinImageclassification = () => {
  const [sample, setSample] = useState(false);
  const [result, setResult] = useState(false);

  const getsample = () => {
    setResult('');
    setSample(true);
  };
  const getresult = () => {
    setResult(true);
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Protein image classification is an automated method to classify
            human organelle based on protein patterns and characteristics of
            different organelles. The aim is to predict protein organelle
            localization labels for each sample. There are in total 28 different
            labels present in the dataset. The dataset is acquired in a highly
            standardized way using one imaging modality (confocal microscopy).
          </p>
          <center>
            <Grid item xs={12}>
              <img
                width="60%"
                src={humanproteinImageclassification_sol1}
                alt=""
              />
            </Grid>
            <Grid item xs={12}>
              <img
                width="60%"
                src={humanproteinImageclassification_sol2}
                alt=""
              />
            </Grid>
          </center>
          <p>
            All image samples are represented by four filters (stored as
            individual files), the protein of interest (green) plus three
            cellular landmarks: nucleus (blue), microtubules (red), endoplasmic
            reticulum (yellow). The green filter should hence be used to predict
            the label, and the other filters are used as references.
          </p>
          <p>
            Images visualizing proteins in cells are commonly used for
            biomedical research, and these cells could hold the key for the next
            breakthrough in medicine. Proteins are “the doers” in the human
            cell, executing many functions that together enable life. in order
            to fully understand the complexity of the human cell, models must
            classify mixed patterns across a range of different human cells.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Protein_image_classification"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/c/human-protein-atlas-image-classification/data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          {sample == false && (
            <section>
              <Grid
                container
                direction="row"
                spacing={2}
                justify="center"
                alignItems="center"
              >
                <Grid xs={2}>
                  <Button variant="contained">Blue Filter</Button>
                </Grid>
                <Grid xs={2}>
                  <Button variant="contained">Green Filter</Button>
                </Grid>
                <Grid xs={2}>
                  <Button variant="contained">Red Filter</Button>
                </Grid>
                <Grid xs={2}>
                  <Button variant="contained">Yellow Filter</Button>
                </Grid>
                <StyledFigure>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsample}
                  >
                    Sample
                  </StyledButton>
                </StyledFigure>
              </Grid>
            </section>
          )}
          {sample && (
            <section>
              <Grid container spacing={2} direction="row" justify="center">
                <Grid item xs={6} sm={6} md={2}>
                  <img
                    src={humanproteinImageclassification_sample_blue}
                    width="100px"
                    alt=""
                  />
                  <p>Blue Filter</p>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <img
                    src={humanproteinImageclassification_sample_green}
                    width="100px"
                    alt=""
                  />
                  <p>Green Filter</p>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <img
                    src={humanproteinImageclassification_sample_red}
                    width="100px"
                    alt=""
                  />
                  <p>Red Filter</p>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <img
                    src={humanproteinImageclassification_sample_yellow}
                    width="100px"
                    alt=""
                  />
                  <p>Yellow Filter</p>
                </Grid>
                <StyledFigure>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getresult}
                  >
                    Execute
                  </StyledButton>
                </StyledFigure>
              </Grid>
              {result && (
                <section>
                  <center>
                    <Grid item xs={12} sm={12} md={4}>
                      <h3>Predicted protein localization labels</h3>
                    </Grid>
                  </center>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    direction="row"
                    justify="center"
                  >
                    <Grid item xs={12} sm={12} md={5}>
                      <Paragraph>
                        <b>Nucleoli</b>
                        <p>
                          The nucleolus is a non-membrane bound organelle inside
                          the nucleus and it is responsible for the synthesis,
                          processing, and assembly of ribosomes. It is also
                          involved in several other cellular processes, such as
                          mitosis, stress response, and cell cycle regulation.
                        </p>
                        <img
                          src={humanproteinImageclassification_sample1}
                          width="200px"
                          alt=""
                        />
                      </Paragraph>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Paragraph>
                        <b>Rods and Rings (R&R)</b>
                        <p>
                          The R&R are a poorly characterized cytoplasmic
                          structure that is present at low levels in human cell
                          lines. The function of R&R remains to be revealed.
                        </p>
                        <br />
                        <br />
                        <img
                          src={humanproteinImageclassification_sample2}
                          width="180px"
                          alt=""
                        />
                      </Paragraph>
                    </Grid>
                  </Grid>
                </section>
              )}
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(HumanproteinImageclassification);
const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
