import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import tilemap from 'highcharts/modules/tilemap';
import Highcharts from 'highcharts/highmaps';
import drilldown from 'highcharts/modules/drilldown';
import * as d3 from 'd3';
import { StyledButton } from "../../styles/common";
import data from '../../assets/files/totalpay.csv';

class PatientPopulationTop10 extends React.Component {
  constructor(props) {
    super(props);

    this.dict = [];

    this.parsecsv = this.parsecsv.bind(this);
    this.createMap = this.createMap.bind(this);
  }

  componentDidMount() {
    d3.csv(data)
      .then((data) => {
        this.parsecsv(data);
      })
      .catch((err) => {
        throw err;
      });
  }

  parsecsv(data) {
    for (let i = 0; i < data.length; i++) {
      const drg = data[i][0];
      const cnt = parseInt(data[i][1], 10);
      const dec = data[i][3];
      this.dict.push({
        name: drg,
        y: cnt,
        desc: dec,
      });
    }
    this.createMap(this.dict);
  }

  createMap(data1) {
    Highcharts.chart('container', {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
        },
      },
      title: {
        text: 'Patients Count Based on Top 10 Diagnosis Group',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          innerSize: 100,
          depth: 45,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            color: '#000000',
            connectorColor: '#000000',
            formatter() {
              return `Patients under <strong>${this.point.name}</strong> : ${this.y}`;
            },
          },
        },
      },

      tooltip: {
        headerFormat: '',
        pointFormat: 'The patients under<b> {point.desc}</b> is <b>{point.y}</b>',

      },
      series: [{
        data: data1,

      }],
    });
  }

  render() {
    return (
      <>
        <Grid container alignItems="end" justifyContent="flex-end">
          <Grid item xs={12}>
            <StyledButton
              size="large"
              component={Link}
              to="/solutions/population-health-dashboard"
            >
              Previous
            </StyledButton>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={10} md={10}>
            <div id="container" />
          </Grid>
        </Grid>
      </>

    );
  }
}
export default memo(PatientPopulationTop10);
