import React, { memo, useState } from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const PredictBioMechanical = () => {
  // This is the initial state of the DOM
  const initialData = {
    pi: "",
    pt: "",
    la: "",
    ss: "",
    pr: "",
    ds: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "patientEngagementProduction",
    "predictBioMechanical",
  );

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "DISTRIBUTION OF CLASSES",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: "Hernia",
              y: data[1][0][0],
            },
            {
              name: "Normal",
              y: data[1][0][1],
            },
            {
              name: "Spondylolisthesis",
              y: data[1][0][2],
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      pi: "63.027",
      pt: "22.55",
      la: "39.60",
      ss: "40.47",
      pr: "98.67",
      ds: "-0.25",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      pi: "94.17482232",
      pt: "15.38076983",
      la: "67.70572132",
      ss: "78.79405249",
      pr: "114.8901128",
      ds: "53.25522004",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      pi, pt, la, ss, pr, ds,
    } = sampleData;

    const params = `${pi},${pt},${la},${ss},${pr},${ds}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          console.log(res);
          formHighChart(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <b>What is Hernia? </b>
          <p>
            Hernias are caused by a combination of pressure and an opening or
            weakness of muscle or fascia; the pressure pushes an organ or tissue
            through the opening or weak spot. Sometimes muscle weakness is
            present at birth; more often, it occurs later in life.
          </p>
          <p>
            Hernias are caused by a combination of pressure and an opening or
            weakness of muscle or fascia; the pressure pushes an organ or tissue
            through the opening or weak spot. Sometimes muscle weakness is
            present at birth; more often, it occurs later in life.
          </p>
          <b>What is Spondylolisthesis? </b>
          <p>
            Spondylolisthesis is a spinal condition that causes lower back pain.
            It occurs when one of your vertebrae, the bones of your spine, slips
            out of place onto the vertebra below it. Most of the time,
            nonsurgical treatment can relieve your symptoms.
            {" "}
          </p>
          <b>Data: </b>
          <p>
            The algorithm is trained on data from the dataset of UCI ML
            Repository
            {" "}
            <a href="http://archive.ics.uci.edu/ml" target="_blank" rel="noreferrer">
              http://archive.ics.uci.edu/ml
            </a>
          </p>
          <p>
            Each patient is represented in the data set by six biomechanical
            attributes derived from the shape and orientation of the pelvis and
            lumbar spine (each one is a column):
          </p>
          <b />
          <ul>
            <li>Pelvic incidence </li>
            <li>Pelvic tilt </li>
            <li>Lumbar lordosis angle </li>
            <li>Sacral slope </li>
            <li>Sacral slope </li>
            <li>Grade of spondylolisthesis </li>
          </ul>
          <p>
            This application aims to classify whether the patient is Normal,
            Disk Hernia, or Spondylolisthesis by analyzing the Biomechanical
            features (above 6 Labels) using Advanced Machine Learning
            Algorithms.
          </p>
          <b>The patients are classified in 3 classes – </b>
          <ul>
            <li>
              <i>Normal</i>
              {' '}
              – the patient is fit and does not have any issues
            </li>
            <li>
              <i>Disk Hernia </i>
              – The patient is suffering from Hernia. A
              condition which refers to a problem with a rubbery disc between
              the spinal bones. This condition occurs when the soft center of a
              spinal disc pushes through a crack in the tougher exterior casing.
            </li>
            <li>
              <i>Spondylolisthesis </i>
              - It is a spinal condition that affects
              the lower vertebrae (spinal bones). This disease causes one of the
              lower vertebrae to slip forward onto the bone directly beneath
            </li>
          </ul>
          <p>Features affecting the results (Input variables )</p>
          <p>
            <b>Pelvic Incidence - </b>
            Pelvic incidence (PI), or pelvisacral
            angle, is defined as the angle between a line perpendicular to the
            sacral plate at its midpoint and a line connecting the same point to
            the centre of the bicoxofemoral axis.
          </p>

          <p>
            <b>Pelvic Tilt -</b>
            {' '}
            Pelvic tilt is the orientation of the pelvis in
            respect to the thighbones and the rest of the body. The pelvis can
            tilt towards the front, back, or either side of the body.
          </p>
          <p>
            <b>Lumbar lordosis angle </b>
            - LLA is an ideal parameter for the
            evaluation of lumbar lordosis. The normal value of LLA can be
            defined as 20-45 degrees with a range of 1 SD
          </p>
          <p>
            <b>Pelvic radius- </b>
            The Pelvic Radius Angle (PRA) was measured
            from the PR line (as defined in the literature) to the horizontal
          </p>
          <p>
            <b>Grade of spondylolisthesis-</b>
            {' '}
            Spondylolisthesis occurs in
            different degrees based on the amount of slippage of one vertebra on
            another. These degrees of slippage are medically termed grades.
            Accordingly, grade I is 0%-25%, grade II is 25%-50%, grade III is
            50%-75%, grade IV is 75%-100%, and grade V is &gt;100%
          </p>
          <p>
            The Pie chart shows the probable distribution graph of classes for
            the given data
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="http://archive.ics.uci.edu/ml/index.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/BioMechanical_Patient_Classification/Notebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid container direction="row" justify="center" spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Pelvic_Incidence*"
                    name="pi"
                    value={sampleData.pi}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Lumbar_lordosis_angle"
                    name="la"
                    value={sampleData.la}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Pelvic_Radius*"
                    name="pr"
                    value={sampleData.pr}
                    onChange={onChangeFields}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Pelvic_Tilt*"
                    name="pt"
                    value={sampleData.pt}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Sacral_Slope*"
                    name="ss"
                    value={sampleData.ss}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Degree_Spondylolisthesis"
                    name="ds"
                    value={sampleData.ds}
                    onChange={onChangeFields}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showResult}
                >
                  Sample 1
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showResult2}
                >
                  Sample 2
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getResult}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
          </section>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`Based on the bio-mechanical attributes, the patient is predicted to have ${resultData[0]}`}
              </Result>
              <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                  <HighchartContainer id="container_1" display />
                </Grid>
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictBioMechanical);
