import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Grid, TextField, MenuItem, Typography, Slider,
} from "@material-ui/core";

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from "@material-ui/core/Input";

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const Cpca = () => {
  const initialData = {
    alphaValue: 2,
    alphaType: 'auto',
  };

  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [result, setSample] = useState(false);
  const [imageUrl, setImage] = useState(false);
  const [imageResult, setResult] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);
  const [notification, setNotification] = useState("");
  const [slideparameters, setslideParameters] = useState(initialData);
  const [checked, setChecked] = useState({
    0: false, 1: false, 2: true, 3: true,
  });

  const { getQueryResult } = CommonService(
    "sailApps", "cpca",
  );

  const getSample = () => {
    setSpinner(true);

    getQueryResult("sampleData")
      .then((res) => {
        setSpinner(false);
        const previewData = JSON.parse(res.predata);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setSample(res);
        setField(fields);
        setDataRows(dataRows);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getResult = () => {
    setSpinner1(true);

    const check = []; const nonchecked = []; let
      checkedparams = [];
    Object.entries(checked).map((arr) => {
      arr[1] ? check.push(arr[0]) : nonchecked.push(arr[0]);
    });
    checkedparams = [...check, ...nonchecked];

    const isAlphaValue = slideparameters.alphaType === 'manual' ? `,${slideparameters.alphaValue}` : '';

    const params = `${checkedparams},${slideparameters.alphaType}${isAlphaValue}`;

    getQueryResult("withParams", params)
      .then((res) => {
        setSpinner1(false);
        setResult(true);
        const imgUrl = res.image_url;
        setImage(imgUrl);
      })
      .catch((err) => {
        console.log(err);
        setSpinner1(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const handleCheckBox = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const isChecked = e.target.checked;
    const valueName = `${e.target.name}Value`;

    setChecked({
      ...checked,
      [name]: isChecked,
      // [valueName]: value,
    });
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setslideParameters({
      ...slideparameters,
      [name]: value,
    });
  };

  const onSliderChange = (e, value, name) => {
    setslideParameters({
      ...slideparameters,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Contrastive principal component analysis (cPCA), identifies low-dimensional structures that are enriched
            in a dataset relative to comparison data. cPCA with a background dataset enables us to visualize
            dataset-specific patterns missed by PCA and other standard methods.
          </p>
          <p><strong>What is PCA?</strong></p>
          <p>
            PCA projects the data onto a low-dimensional space and is especially powerful as an approach to
            visualize patterns, such as clusters, clines, and outliers in a dataset.
          </p>
          <p><strong>Why cPCA?</strong></p>
          <p>
            PCA is designed to explore one dataset at a time. But when multiple datasets or multiple conditions
            in one dataset are to be compared then the current state-of practice is to perform PCA on each
            dataset separately, and then manually compare the various projections.
          </p>
          <p>
            Contrastive PCA (cPCA) is designed to fill in this gap in data exploration and visualization by
            automatically identifying the projections that exhibit the most interesting differences across
            datasets. The main advantages of cPCA are its generality and ease of use
          </p>
          <p>
            <strong>Scenario:</strong>
            {' '}
            In training a classifier to predict the presence of a disease, the patient
            profiles might carry other comorbidities which will introduce a bias in prediction. These comorbidities
            are a background noise to the dataset and might blur the training process. To visualize and train the
            classifier better, cPCA can be applied to segregate data of interest.
          </p>
          <p>
            <strong>Dataset:</strong>
            {' '}
            An EMR dataset of T2D patients with other comorbidities such as retinopathy
            as a background.
          </p>
          <p>
            <strong>The class of Interest:</strong>
            {' '}
            Patients with Type 2 Diabetes
          </p>
          <p>
            <strong>Background Dataset:</strong>
            {' '}
            Should have the structure that we would like to remove from the target data
          </p>
          <p>
            <strong>Class 3,Class 4:</strong>
            Class 3 and Class 4 are the background classes. These classes are
            considered as noise in the dataset.  Because of the differentiating between target and background model
            gives the directions correspond to features carried uniquely by the target dataset, and are hence are
            more likely to lead to meaningful discovery of the additional structure of the target data compared to
            the background.
          </p>
          <p>
            <strong>Alpha:</strong>
            {' '}
            Alpha is a hyperparameter in CPCA. Each value of α yields a direction with a
            different trade-off between target and background variance. By altering the alpha value, the principal
            components can be clustered to the user’s need.
          </p>
          <p>
            <strong>Auto Mode:</strong>
            {' '}
            Instead of choosing a single value of α, in practice, model
            automatically select a few distinct values in such a way that the subspaces corresponding to each value
            of α lie far apart from one another, as characterized by the principal angle between the subspaces.
            This allows model  to present the user with a few scatterplots, one for each selected value of α
            (that the user can quickly scan), which represent the behavior of cPCA for a wide range of α values,
            making the overall algorithm effectively hyperparameter-free
          </p>
          <p>Manual mode: -  Each selected value of α reveals different structure within the target dataset.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
            <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://ieeexplore.ieee.org/document/5206848"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/cPCA_images/CPCA_IMAGES.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.nature.com/articles/s41467-018-04608-8"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container xs={12} direction="row" justify="center" alignItems="center">
            <Grid xs={12} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getSample}
              >
                Sample
              </StyledButton>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={2}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
              <Input type="file" color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
              >
                Execute
              </StyledButton>
            </Grid> */}
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <section>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      {fields.map((field) => (
                        <StyledTableHead key={field.name} align="left">
                          {' '}
                          <strong>{field.name}</strong>
                        </StyledTableHead>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataRows.map((row) => (
                      <TableRow key={row.index}>
                        {fields.map((field) => (
                          <TableCell align="left">
                            {field.name === "index"
                              ? row[field.name] + 1
                              : row[field.name]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center" justifyContent="center">
                <Grid xs={12} md={3}>
                  <h4> Select Target Classes</h4>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <center>
                    <Grid container xs={12} direction="row" justify="center" alignItems="center" justifyContent="center">
                      <FormGroup row>
                        <Grid item xs={4} sm={3} md={3}>
                          <FormControlLabel
                            control={<Checkbox checked={checked['0']} name="0" onChange={handleCheckBox} value={checked.class1} />}
                            label="class1"
                          />
                        </Grid>
                        <Grid item xs={4} sm={3} md={3}>
                          <FormControlLabel
                            control={<Checkbox checked={checked['1']} name="1" onChange={handleCheckBox} value={checked.class2} />}
                            label="class2"
                          />
                        </Grid>
                        <Grid item xs={4} sm={3} md={3}>
                          <FormControlLabel
                            control={<Checkbox checked={checked['2']} name="2" onChange={handleCheckBox} value={checked.class3} />}
                            label="class3"
                          />
                        </Grid>
                        <Grid item xs={4} sm={3} md={3}>
                          <FormControlLabel
                            control={<Checkbox checked={checked['3']} name="3" onChange={handleCheckBox} value={checked.class4} />}
                            label="class4"
                          />
                        </Grid>
                      </FormGroup>
                    </Grid>
                  </center>
                </Grid>
                <Grid item xs={10} sm={6} md={3}>
                  <TextField
                    id="outlined-basic"
                    select
                    fullWidth
                    variant="outlined"
                    label="Alpha selection Type"
                    name="alphaType"
                    onChange={handleInputs}
                    value={slideparameters.alphaType}
                  >
                    <MenuItem key="0" value="auto">Auto</MenuItem>
                    <MenuItem key="1" value="manual">Manual</MenuItem>
                  </TextField>
                </Grid>
                {(slideparameters.alphaType === 'manual') && (
                  <Grid item xs={12} sm={3}>
                    <div>
                      <Typography id="label">
                        Alpha Value:
                        <b>{slideparameters.alphaValue}</b>
                      </Typography>
                      <Slider
                        value={slideparameters.alphaValue}
                        aria-labelledby="label"
                        valueLabelDisplay="auto"
                        name="alphaValue"
                        min={0}
                        max={1000}
                        marks={[{ value: 0, label: 0 }, { value: 1000, label: 1000 }]}
                        step={1}
                        onChange={(e, val) => onSliderChange(e, val, 'alphaValue')}
                      />
                    </div>
                  </Grid>
                )}
                <Grid xs={12} md={3}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getResult}
                  >
                    Submit
                  </StyledButton>
                </Grid>
              </Grid>
              {showSpinner1 && <Spinner text="Loading..." />}
              {imageResult
                && (
                <section>
                  <Result><strong>Result : </strong></Result>
                  <Grid item xs={12} sm={12} md={12}>
                    <img src={imageUrl} width="75%" height="250px" />
                  </Grid>
                </section>
                )}
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(Cpca);

const Paper = styled.div`
                overflow: auto;
                margin-top:2%;
                margin-bottom:2%;
                `;
const StyledTableHead = styled(TableCell)`
                background-color: #3c40af;
                color: white;
                font-weight: bold;
                `;
