import React, { memo, useState, useEffect } from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '../../../../../../styles/common';
import { setInputData } from '../../../../../../store/actions/vnet';

import { InfoText } from '../ModelOptimization/DataShapley';

export const ReplaceValForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const dispatch = useDispatch();
  const [columnData, setColumnData] = useState({});
  const [fromData, setFromData] = useState({ input0: '' });
  const [dropdownList, setdropdownList] = useState(null);
  const [change, setChange] = useState(true);
  const [columnValid, setColumnValid] = useState(false);
  const [toValid, setToValid] = useState(false);
  const [fromValid, setFromValid] = useState(false);
  const [alert, setAlert] = useState('');
  const [error, setError] = useState({ name: ' ', value: ' ' });
  const [columnDataIndex, setColumnDataIndex] = useState('1');
  const [allData, setAllData] = useState({});

  const handleColumnData = (e) => {
    setColumnData({ ...columnData, [e.target.name]: e.target.value });
    const columnValid = e.target.value;
    const submitValid = toValid && columnValid && fromValid;
    setColumnValid(columnValid);
    setChange(!submitValid);
  };

  const handleToData = (e) => {
    console.log(e.target.name);
    const { value, name, id } = e.target;
    if (name) {
      dispatch(
        setInputData({
          column: {
            ...userInputData.column,
            [name]: {
              ...userInputData?.column?.[name],
              [id]: value,
            },
          },
        })
      );
      let colObj = userInputData?.column;
      if (colObj) {
        console.log(colObj);
        colObj[name] = { [id]: value };
        let allData = {
          ...colObj,
        };
        setAllData({ ...allData, ...colObj });
        console.log(allData);
      }
    }
    const toValid = e.target.value;
    const submitValid = toValid && columnValid && fromValid;
    alert && setAlert(false);
    setToValid(toValid);
    setChange(!submitValid);
  };

  const handleFromData = (e) => {
    if (e.target.name) {
      setFromData({ ...fromData, [e.target.id]: e.target.value });
    }
    const fromValid = e.target.value;
    const submitValid = fromValid && columnValid && toValid;
    alert && setAlert(false);
    setFromValid(fromValid);
    setChange(!submitValid);
  };

  const getInputFieldLen = () =>
    Object.entries(columnData).filter((data) => data[0].startsWith('input'))
      .length;
  const addField = () => {
    const columnDataLen = getInputFieldLen();
    if (columnDataLen < dropdownList.length) {
      setColumnData({ ...columnData, [`input${columnDataIndex}`]: '' });
    }
    setColumnDataIndex(columnDataIndex + 1);
    setChange(true);
    setToValid(false);
    setColumnValid(false);
  };

  const validateInput = (e) => {
    const { name, value } = e.target;
    const error = {
      ...error,
    };
    if (!value) {
      error[name] = 'To or From cannot be Empty';
      error[2] = true;
      setChange(true);
    }
    setError(error);
  };

  const removeField = (e, key, key1, key2) => {
    const list = { ...fromData };
    delete list[key1];
    const columnList = { ...columnData };
    const globallist = { ...userInputData.column };
    if (key2) {
      const value = globallist[key];
      let arr = Object.keys(value);

      if (arr.length > 1) {
        delete globallist[key][e];
      } else {
        delete globallist[key];
      }
    }

    delete columnList[key1];
    setColumnData(columnList);
    dispatch(
      setInputData({
        column: globallist,
      })
    );
    setChange(false);
  };

  useEffect(async () => {
    const result = await props.getCategories();
    // let result = '["pclass1", "gender", "sibsp", "parch", "fare", "survived"]';
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);
  useEffect(() => {
    if (userInputData?.column) {
      const formData = {};
      let columnDataLen = 0;
      Object.keys(userInputData?.column).map((data, indx) => {
        formData[`disabled${indx}`] = data;
        columnDataLen++;
      });
      setColumnData(formData);
      setColumnDataIndex(columnDataLen);
      setChange(false);
    } else {
      setColumnData({ input0: '' });
    }
  }, []);
  useEffect(() => {
    if (getInputFieldLen() == 0) {
    }
  }, [columnData]);

  const handleFormReset = () => {
    setColumnData({ input0: '' });
    dispatch(
      setInputData({
        column: {},
      })
    );
  };

  const isFormValid = () => {
    if (
      !userInputData?.column ||
      Object.entries(userInputData?.column).length == 0
    ) {
      setAlert(true);
      return false;
    }
    return true;
  };

  return (
    <ReplaceContainer>
      {alert && (
        <InfoText success={false}>
          <FontAwesomeIcon icon={faInfoCircle} />
          Please choose atleast one column to replace
        </InfoText>
      )}
      <JustifiedGrid container spacing={1} alignItems='flex-start'>
        {Object.entries(columnData).map((data) => (
          <>
            <Grid item xs={3}>
              {data[0].startsWith('disabled') ? (
                <TextField
                  fullWidth
                  name={data[0]}
                  label='Column'
                  variant='outlined'
                  disabled
                  type='text'
                  required
                  value={data[1]}
                />
              ) : (
                <TextField
                  name={data[0]}
                  label='Column'
                  variant='outlined'
                  onChange={handleColumnData}
                  select
                  key={data[0]}
                  fullWidth
                  required
                  value={columnData && columnData[data[0]]}
                >
                  {dropdownList?.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id={data[0]}
                requried
                name={columnData[data[0]]}
                label='From'
                variant='outlined'
                onChange={handleFromData}
                // onBlur={validateInput}
                type='text'
                value={columnData[data[0]] && fromData[data[0]]}
                error={error[columnData[data[0]]]}
                helperText={error[columnData[data[0]]]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name={columnData[data[0]]}
                id={fromData[data[0]]}
                label='To'
                variant='outlined'
                onChange={handleToData}
                // onBlur={validateInput}
                type='text'
                requried
                disabled={data[0].startsWith('disabled')}
                value={
                  columnData[data[0]] &&
                  userInputData?.column?.[columnData[data[0]]]?.[
                  fromData[data[0]]
                  ]
                }
                error={error[columnData[data[0]]]}
                helperText={error[columnData[data[0]]]}
              />
            </Grid>
            {!data[0].startsWith('disabled') && (
              <DeleteGrid item xs={1}>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() =>
                    removeField(
                      fromData[data[0]],
                      columnData[data[0]],
                      data[0],
                      columnData[data[0]] &&
                      userInputData?.column?.[columnData[data[0]]]?.[
                      fromData[data[0]]
                      ]
                    )
                  }
                  size='large'
                />
              </DeleteGrid>
            )}
          </>
        ))}
        <Grid container>
          <Grid item>
            <StyledButton
              disabled={change}
              onClick={addField}
              variant='outlined'
            >
              +
            </StyledButton>
          </Grid>
        </Grid>
        {/* <StyledButton disabled={change} onClick={addField} variant='outlined'>
          +
        </StyledButton> */}
      </JustifiedGrid>
      {props.render({ handleFormReset, isFormValid })}
    </ReplaceContainer>
  );
});

const ReplaceContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
  }
`;

const JustifiedGrid = styled(Grid)`
  justify-content: center;
`;
const DeleteGrid = styled(Grid)`
  align-self: center;
`;
