import React, { memo, useState } from "react";
import {
  Grid, Card, CardMedia, CardActions,
} from "@material-ui/core";

// local components
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  PageContainer,
  PageTitle,
  StyledButton,

} from "../../../styles/common";

// images
import populationHealthDashboard_sol1 from "../../../assets/images/app/populationHealthDashboard_sol1.webp";
import populationHealthDashboard_sol2 from "../../../assets/images/app/populationHealthDashboard_sol2.webp";
import populationHealthDashboard_sol3 from "../../../assets/images/app/populationHealthDashboard_sol3.webp";
import populationHealthDashboard_sol4 from "../../../assets/images/app/populationHealthDashboard_sol4.webp";
import populationHealthDashboard_sol5 from "../../../assets/images/app/populationHealthDashboard_sol5.webp";
import MainLayout from "../../common/MainLayout";
import Navbar from "../../common/Navbar";

const PopulationHealthDashboard = () => (
  <MainLayout>
    <Navbar />
    <PageContainer>
      <Grid container justifyContent="center" spacing={3} alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol1} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/PatientPopulation"
              >
                Patient Population
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol1} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/PopulationTotalPay"
              >
                Patient Total Pay - Diagnosis Group
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol1} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/PatientTherapeuticCode"
              >
                Patient Total Pay - Therapeutic code
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol1} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/basedOnBill"
              >
                Patient Total Pay - Bill Type
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol2} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/ProviderTypes"
              >
                Patient Population - Provider Type
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol3} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/PatientTotalPayTop10"
              >
                Patient Total Pay - Top 10 Diagnosis Group
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol4} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/PatientPopulationTop10"
              >
                Patient Population - Top 10 Diagnosis Group
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CardContainer>
            <Media image={populationHealthDashboard_sol5} />
            <CardActions>
              <StyledButton
                size="large"
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                to="/viz/MaleAndFemale"
              >
                Male & Female Distribution - Top 10 Procedures Group
              </StyledButton>
            </CardActions>
          </CardContainer>
        </Grid>
      </Grid>
    </PageContainer>
  </MainLayout>
);

export default memo(PopulationHealthDashboard);

const CardContainer = styled(Card)`
&.MuiCard-root{
  max-width: 350px;
  height: 100%;
  text-align: center;
  .MuiCardActions-root {
    justify-content: center;
  }
}
`;

const Media = styled(CardMedia)`
  &.MuiCardMedia-root {
    height: 200px;
    @media only screen and (max-width: 350px) {
      height: 100px
    }
  }
`;
