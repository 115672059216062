import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  AppBar,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import FileDownloadIcon from "@material-ui/icons/GetApp";

// import local components
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import genomicDataPreprocessor_sol1 from "../../../assets/images/app/genomicDataPreprocessor_sol1.webp";
import genomicDataPreprocessor_sol2 from "../../../assets/images/app/genomicDataPreprocessor_sol2.webp";
import genomicDataPreprocessor_gif1 from "../../../assets/images/app/genomicDataPreprocessor_gif1.gif";

const { getQueryResult, uploadFile } = CommonService(
  "z1dApps",
  "genomicDataPreprocessor",
);

const GenomicDataPreprocessor = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [isDownloading, setIsDownloading] = useState("");
  const [result, setResult] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [convertedFile, setConvertedFile] = useState("");
  const [timeTakenToConvert, setTimeTakenToConvert] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [showOptions, setShowOptions] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [callApiWith, setCallApiWith] = useState("");
  const [notification, setNotification] = useState("");

  const getBAMSampleResult = (sample) => {
    setSpinner(true);
    setConvertedFile("");
    getQueryResult("fastqSample")
      .then((res) => {
        console.log(res);
        setSpinner(false);
        setConvertedFile(res.result);
        setTimeTakenToConvert(res.ranTime);
        setFileSize(res.fileSize);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getBAMuploadResult = (e) => {
    setSpinner(true);
    setConvertedFile("");
    uploadFile("fastqUpload", e.target.files[0])
      .then((res) => {
        setSpinner(false);
        setConvertedFile(res.result);
        setTimeTakenToConvert(res.ranTime);
        setFileSize(res.fileSize);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getuploadResult = (e) => {
    setSpinner(true);
    setShowOptions(false);
    uploadFile("adamUpload", e.target.files[0])
      .then((res) => {
        setSpinner(false);
        setShowOptions("upload/");
        setNotification({ open: "success", message: "Upload success" });
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getTransformResults = (transforType) => {
    setSpinner(true);
    const params = transforType + callApiWith;
    setConvertedFile("");
    getQueryResult("withParams", params)
      .then((res) => {
        console.log(res);
        setConvertedFile(res.result);
        setTimeTakenToConvert(res.ranTime);
        setFileSize(res.fileSize);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const showMoreOptions = (option) => {
    setShowOptions(true);
    setCallApiWith(option);
  };

  const handleChange = (e, newValue) => {
    setTabValue(newValue);
    setConvertedFile(false);
  };

  const showDownloadAnimation = () => {
    setIsDownloading(true);
    setTimeout(() => {
      setIsDownloading(false);
    }, 4000);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>ADAM:</b>
            {' '}
            ADAM is a library and command line tool that enables
            the use of Apache Spark to parallelize genomic data analysis across
            cluster/cloud computing environments. ADAM uses a set of schemas to
            describe genomic sequences, reads, variants/genotypes, and features,
            and can be used with data in legacy genomic file formats such as
            SAM/BAM/CRAM, BED/GFF3/GTF, and VCF, as well as data stored in the
            columnar Apache Parquet format.
          </p>
          <center>
            <img width="80%" src={genomicDataPreprocessor_sol1} alt="" />
            <br />
            <br />
            <img width="80%" src={genomicDataPreprocessor_sol2} alt="" />
            <br />
            <br />
          </center>
          <b>CONVERSION OPERATION:</b>
          <p>
            <b>adam2fastq:</b>
            {' '}
            Convert BAM to FASTQ files It converts data
            between a legacy genomic file format and using ADAM’s schemas to
            store data in Parquet.
          </p>
          <b>ADAM ACTIONS:</b>
          <ul>
            <li>
              <b>transformAlignments:</b>
              {' '}
              Convert SAM/BAM to ADAM format and
              optionally perform read pre-processing transformations It is the
              entrypoint to ADAM’s read preprocessing tools
            </li>
            <li>
              <b>transformSequences:</b>
              {' '}
              Convert a FASTA file as sequences into
              corresponding ADAM format and vice versa.
            </li>
            <li>
              <b>transformSlices:</b>
              {' '}
              Convert a FASTA file as slices into
              corresponding ADAM format and vice versa
            </li>
          </ul>
          <p>
            <b>Application :</b>
            {' '}
            We can process genomic data files e.g. BAM,
            fasta, fastq using ADAM. In addition to this, adam makes the files
            processing a lot faster.
          </p>
          <p>
            <i>
              By using Adam, we are able to process 1.5 GB bam file in 4:30 min.
            </i>
          </p>
          <p>
            DNA gene sequencing data file is taken as input and it can be
            processed into different file formats. There are some options for
            data preprocessing as well.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/adam/GenomicDataPreprocessor_.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/adam/sample1.bam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sample bam
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/adam/cov2.fasta"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sample fasta
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid item xs={12}>
            <AppBar position="static" color="primary">
              <StyledTabs
                value={tabValue}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="BAM to FASTQ Conversion" />
                <Tab label="ADAM Actions" />
              </StyledTabs>
            </AppBar>
          </Grid>
          <br />
          <TabPanel value={tabValue} index={0}>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={12} md={4} alignItems="center">
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getBAMSampleResult}
                >
                  Sample
                </StyledButton>
              </Grid>
              <Grid item xs={12} md={1}>
                <h3>OR</h3>
              </Grid>
              <Grid item xs={12} md={5} alignItems="center">
                <TextField
                  type="file"
                  color="primary"
                  onChange={getBAMuploadResult}
                  // helperText={fileNames}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={12} md={4} alignItems="center">
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showMoreOptions.bind(this, "sample/")}
                >
                  Sample
                </StyledButton>
              </Grid>
              <Grid item xs={12} md={1}>
                <h3>OR</h3>
              </Grid>
              <Grid item xs={12} md={5} alignItems="center">
                <TextField
                  type="file"
                  color="primary"
                  onChange={getuploadResult}
                  // helperText={fileNames}
                />
              </Grid>
            </Grid>
            <br />
            {showOptions && (
              <section>
                <Typography variant="h6" gutterBottom>
                  Transform Options
                </Typography>
                <br />
                <Grid
                  container
                  xs={12}
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid xs={12} md={3} alignItems="center">
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      onClick={getTransformResults.bind(
                        this,
                        "/transformAlignments/",
                      )}
                    >
                      Transform Alignments
                    </StyledButton>
                  </Grid>
                  <Grid xs={12} md={3} alignItems="center">
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      onClick={getTransformResults.bind(
                        this,
                        "/transformSequences/",
                      )}
                    >
                      Transform Sequences
                    </StyledButton>
                  </Grid>
                  <Grid xs={12} md={3} alignItems="center">
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      onClick={getTransformResults.bind(
                        this,
                        "/transformSlices/",
                      )}
                    >
                      Transform Slices
                    </StyledButton>
                  </Grid>
                </Grid>
              </section>
            )}
          </TabPanel>

          <section>{showSpinner && <Spinner text="Loading..." />}</section>
          {convertedFile && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`Conversion process completed in ${timeTakenToConvert} and output file is available in .zip format`}
              </Result>
              <br />
              <section>
                <center>
                  {isDownloading ? (
                    <Image src={genomicDataPreprocessor_gif1} alt="" />
                  ) : (
                    <LinkButton
                      onClick={showDownloadAnimation}
                      href={convertedFile}
                      download
                    >
                      <StyledButton
                        variant="outlined"
                        color="primary"
                        size="large"
                        startIcon={<FileDownloadIcon />}
                      />
                      <br />
                      file size:
                      {' '}
                      {fileSize}
                    </LinkButton>
                  )}
                </center>
              </section>
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(GenomicDataPreprocessor);

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index}>
      {children}
    </div>
  );
}

const Image = styled.img`
  width: 200px;
  height: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 220px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
  }
`;
const LinkButton = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledTabs = styled(Tabs)`
@media only screen and (max-width: 950px) {
  .MuiTabs-flexContainer{
    flex-wrap: wrap;
    .PrivateTabIndicator-colorPrimary-7{
      display: none;
    }
  }
}
@media only screen and (max-width: 650px) {
  .MuiTabs-flexContainer{
    flex-direction: column;
    .MuiTab-textColorPrimary.Mui-selected{
      border: 1px solid #3f51b5;
    }
  }
`;
