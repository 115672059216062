import { WSClientWrapper } from "./WSClientWrapper";
import APIDATA from '../../../utils/services/api-config';
import { wrappedAxios } from "../../../utils/services/CommonService";

const uploadUrl = `https://apps.vlifevirtusa.com:8800/`;

const DeepScapService = {
  getUserIds() {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
      },
    };

    return WSClientWrapper.wrappedFetch(`${uploadUrl}ingest/getIdData/`, getRequestConfig);
  },

  getPostResults(endpoint, params) {
    const config = {
      method: "POST",
      url: `${uploadUrl}${endpoint}`,
      data: JSON.stringify(params),

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache",
      },
    };

    return wrappedAxios(config);
  },

  uploadFile(file, params, endpoint) {
    const fd = new FormData();
    fd.append('file', file);
    const getRequestConfig = {
      method: "POST",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
      },
      url: `${uploadUrl}${endpoint}`,
      data: JSON.stringify(params),
      body: fd,
    };

    return wrappedAxios(getRequestConfig);
  },


  // getPostResults(params, url) {
  //   const getRequestConfig = {
  //     method: "POST",
  //     cache: "no-cache",
  //     headers: {
  //       Accept: "application/json",
  //     },
  //   };

  //   return WSClientWrapper.wrappedFetch(`${uploadUrl}${url}${params}`, getRequestConfig);
  // },


};

export default DeepScapService;
