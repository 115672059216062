import React, {
  Fragment, memo, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faTrash } from "@fortawesome/free-solid-svg-icons";

import { useSelector, useDispatch } from 'react-redux';
import { TabPanel, a11yProps } from './ResultPanel';
import { AntTab, AntTabs } from './DataResultTab';
import Plot from './Plot';
import { StyledButton } from '../../../../../styles/common';
import { setResult } from "../../../../../store/actions/vnet";

function DataViz({ getPlotData, getAllViz, commonAPI }) {
  const { resultData = {} } = useSelector((state) => state.vnet);
  const { dataViz = {} } = resultData || {};
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [plotKey, setPlotKey] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCollapse = (e) => {
    const title = e.target.getAttribute('data-key');
    const FunctionID = e.target.getAttribute('data-functionId');
    if (title == plotKey) {
      setPlotKey(false);
    } else {
      getPlotData({ title, FunctionID });
    }
  };
  const handleDelete = async (e) => {
    e.stopPropagation();
    const title = e.currentTarget.getAttribute('data-key');
    const FunctionID = e.currentTarget.getAttribute('data-functionId');
    const ContextData = {
      StageID: "Common_API",
      FeatureID: "Common_API",
      FunctionID: "deleteViz",
      Actions: "",
    };
    const contentData = {
      FunctionID,
      Viz_Info: title,
    };
    const res = await commonAPI(ContextData, contentData);
    if (!res.Error_Flag) {
      const filteredData = resultData?.dataViz?.Stages[FunctionID].filter((key) => key !== title);
      let stages = { ...resultData?.dataViz?.Stages };
      let Current_Stage = resultData?.dataViz?.Current_Stage;

      // if tab is empty after deletion
      if (filteredData.length == 0) {
        delete stages[FunctionID];
        // if its a one and only  tab
        if (Object.keys(stages).length == 0) {
          stages = null;
        } else {
          Current_Stage = Object.keys(stages)[0];
        }
      } else {
        stages[FunctionID] = filteredData;
        Current_Stage = FunctionID;
      }
      dispatch(setResult({
        ...resultData,
        dataViz: {
          ...resultData.dataViz,
          Stages: stages,
          Current_Stage,
        },
      }));
    }
  };

  useEffect(() => {
    const current_stage_indx = dataViz?.Stages ? Object.keys(dataViz?.Stages).indexOf(dataViz.Current_Stage) : 0;
    setPlotKey(dataViz?.Current_Plot);
    setValue(current_stage_indx);
  }, [dataViz]);

  return (
    <DataVizContainer>
      <p>
        To view all the vizualizations, click on
        <StyledButton variant="contained" color="primary" onClick={getAllViz}>Execute</StyledButton>
      </p>
      {resultData?.dataViz && (
        <>
          {dataViz?.Stages && (
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              {Object.keys(dataViz.Stages).map((key, indx) => (
                <AntTab label={key} {...a11yProps(indx)} />
              ))}
            </AntTabs>
          )}

          {dataViz?.Stages && Object.keys(dataViz.Stages).map((key, indx) => (
            <TabPanel class="viz" value={value} index={indx}>
              {dataViz.Stages[key].map((title) => (
                title && (
                  <VizCollpase title={title} functionId={key} isOpen={plotKey == title} onClick={handleCollapse} handleDelete={handleDelete}>
                    <Plot data={dataViz.zPlot} />
                  </VizCollpase>
                )
              ))}
            </TabPanel>
          ))}
        </>
      )}
    </DataVizContainer>
  );
}
export default memo(DataViz);
const DataVizContainer = styled.div`
  box-sizing: border-box;
  .viz {
    .MuiBox-root {
      padding: 5px;
    }
  }
`;

export const CollapseTitle = styled.p`
  background: #264653;
  color: #ffff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  min-width: 50rem;
  max-width: 95%;
  cursor: pointer;
  position: relative;
  .deleteIcon{
    position: absolute;
    right: 1%;
    margin:0 10px;
  }
`;

const VizCollpase = memo(({
  title, children, isOpen, onClick, functionId, handleDelete,
}) => (
  <>
    <CollapseTitle>
      <span onClick={onClick} data-key={title} data-functionId={functionId}>
        {title}
        {' '}
        &nbsp;
        <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
      </span>
      {' '}
      <FontAwesomeIcon icon={faTrash} className="deleteIcon" onClick={handleDelete} data-key={title} data-functionId={functionId} />
    </CollapseTitle>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      {children}
    </Collapse>
  </>
));
