import React from 'react'
import { wrappedAxios } from '../../utils/services/CommonService';
import APIDATA from "../../utils/services/api-config";

const url = `${APIDATA.Z1D}:5001`; export default function PresignedS3Link({ children, href }) {
  const handleClick = async () => {
    const config = {
      method: "POST",
      url: `${APIDATA.PORTAL_API}auth/getPresignedS3`,
      data: JSON.stringify({
        'objectKey': href
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache",
      },
    };

    let result = await wrappedAxios(config);
    if (result) {
      window.open(result?.URL);
    }
  }

  return (
    <a onClick={handleClick} rel="noreferrer">{children}</a>
  )
}
