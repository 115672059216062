import React, { memo, useState } from "react";
import styled from "styled-components";
import { TextField, Grid, Divider } from "@material-ui/core";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

import primerClassifier_sol1 from "../../../assets/images/app/primerclassifier1.webp";
import primerClassifier_sol2 from "../../../assets/images/app/primerclassifier2.webp";

const PrimerClassifier = () => {
  const [selectedSample, setSample] = useState("");
  const [resultData, setResult] = useState({ value: "", result: "" });
  const sampleData = {
    sample1: {
      value: "GGCGACCGAACTCCAACTAGAACCTGCATAACTGGCCTGGGAGATATGGT",
      result: "Yes, Protein binding sites are present in the sequence",
    },
    sample2: {
      value: "AGGCAGGTGGTCGTACAATGTTTTCGAAGAGATAGGGGGCCAGAGGCCTC",
      result: "No, There is no protein binding site in the sequence",
    },
  };

  const setSampleData = (e) => {
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    setResult({ value: sampleData[selectedSample].value });
    setSample(selectedSample);
  };

  const showResult = () => {
    setResult({
      ...resultData,
      result: sampleData[selectedSample].result,
    });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          DNA binding sites are sites in DNA where other molecules may bind.
          Deep learning models that are trained to discover the binding sites
          for transcription factors in DNA are used for primer identification.
          DNA interactions occur when a protein binds a molecule of DNA, often
          to regulate its biological function.
        </Paragraph>
        <Paragraph>
          This application features a Neural Network classifier that can
          discover binding motifs in DNA based on the results that determine
          whether a longer DNA sequence binds to the protein or not. This can
          aid in identifying those sequences that can be used further to study
          the biological functions of DNA.
        </Paragraph>
        <Paragraph>
          A simulated data consisting of DNA sequences of length 50 base is
          labelled with 0 or 1 depending on the result of the assay, is used to
          train the model. Here, the longer DNA sequences are the independent
          variables (or predictors), while the dependent variable (or response)
          is the positive or negative response of the assay.
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Genomics_primer_identification"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/abidlabs/deep-learning-genomics-primer"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center">
            <Image src={primerClassifier_sol1} alt="" />
            <Image src={primerClassifier_sol2} alt="" />
            <Grid item xs={12}>
              <strong>Sample FASTA gene sequence:</strong>
            </Grid>
            <Grid item xs={12} sm={10} md={8}>
              <TextField
                rows="4"
                multiline
                variant="outlined"
                name="gene"
                fullWidth
                label="Sample Text"
                value={resultData.value}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample1"
                onClick={setSampleData}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample2"
                onClick={setSampleData}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                btnColor="#023e8a"
                color="primary"
                onClick={showResult}
                endIcon={<ArrowForwardIcon />}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {resultData.result && (
            <>
              <Divider />
              <Result>
                <strong>Result:</strong>
                {resultData.result}
              </Result>
            </>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PrimerClassifier);

const Image = styled.img`
  border-radius: 3px;
  height: 350px; 
  width: 350px;
  @media only screen and (max-width: 900px) {
    width: auto;
    height: auto;
    max-width: 250px;
    max-height: 300px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 250px;
  }
`;
