import React, { memo, useState } from "react";
import styled from "styled-components";
import { LinkContainer } from "../../../styles/common";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DownloadIcon from "@material-ui/icons/OpenInNew";
import {
  Grid,
  TextField,
  MenuItem,
  AppBar,
  Input,
  Tab,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  RadioGroup,
} from "@material-ui/core";
import { OverViewList } from "./vnet/components/DataResultTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTable,
  faExclamationCircle,
  faAngleDoubleRight,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

// local components
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import { StyledTabs } from "./IOTRecipesView";

// import { TabPanel } from "./vnet/components/ResultPanel";
import JsonDataTable from "../../common/JsonDataTable";
import commonServices from "../../../utils/services/CommonService";
import getUserData from "../../../utils";
import ZoomImage from '../../common/ZoomImage';

//images
import EncryptedTextData1 from "../../../assets/images/app/encrypted_text_data1.webp";
import EncryptedTextData2 from "../../../assets/images/app/encrypted_text_data2.webp";
import EncryptedTextData3 from "../../../assets/images/app/encrypted_text_data3.webp";
import EncryptedTextData4 from "../../../assets/images/app/encrypted_text_data4_step1.webp";
import EncryptedTextData5 from "../../../assets/images/app/encrypted_text_data4_step2.webp";
import EncryptedTextData6 from "../../../assets/images/app/encrypted_text_data4_step3.webp";
import EncryptedTextData7 from "../../../assets/images/app/encrypted_text_data4_step4.webp";
import EncryptedTextData8 from "../../../assets/images/app/encrypted_text_data4_step5.webp";
import EncryptedTextData9 from "../../../assets/images/app/encrypted_text_data5.webp";
import EncryptedTextData10 from "../../../assets/images/app/encrypted_text_data6.webp";

const { postQueryResult: postQueryRes, uploadFilesWithBody } = commonServices(
  "pyod1",
  "pyod1"
);
const { postQueryResult } = commonServices("z1dApps", "encryptedTextData");

const EncryptedTextData = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [value, setValue] = useState(0);
  const [data, setData] = useState(null);
  const [encryptData, setEncryptData] = useState({});
  const [isDataTraining, setIsDataTraining] = useState(false);
  const [isDataPredicting, setIsDataPredicting] = useState(false);
  const [isDataDecrypting, setIsDataDecrypting] = useState(false);
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [predictedData, setPredictedData] = useState(null);
  const [decryptData, setDecryptData] = useState(null);
  const [colData, setColData] = useState(null);
  const [open, setOpen] = useState("sample 1");
  const [subtabVal, setSubTabVal] = useState(0);
  const [firstTabVal, setFirstTabVal] = useState(null);
  const [secondtabVal, setsecondTabVal] = useState(null);
  const [thirdtabVal, setThirdTabVal] = useState(null);
  const [fourthtabVal, setFourthTabVal] = useState(null);
  const [metaData, setMetaData] = useState({
    isDataValid: false,
    isValidateResValid: false,
    isEncryptResValid: false,
    validInfo: false,
  });
  const [fileData, setFileData] = useState({ file: "", name: "" });
  const [sampleData, setSampleData] = useState({});
  const [downloadLink, getDownloadLink] = useState({
    secretFilePath: false,
    publicFilePath: false,
    modelFilePath: false,
    encryptedPath: false,
  });
  const [finalLink, setDownloadLink] = useState({
    secretLnk: false,
    publicLnk: false,
    modelLnk: false,
    encryptedLnk: false,
  });
  const [fields, setFields] = useState({
    poly_mod_degree: "",
    global_scale: "",
    target: "",
    epochs: "",
    Test_Train_split: "",
    colVals: [],
  });
  const epochs = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];
  const encryptedData = {
    kstest_score: {
      label: "Encryption of the Training set took(seconds)",
      desc: "This metric uses the two-sample Kolmogorov–Smirnov test to compare the distributions of continuous columns using the empirical Cumulative Distribution Function. .K-S score should be a high value (Max =1.0) when the fit is good and a low value (Min = 0.0) when the fit is not good. When the K-S value goes below 0.05, you will be informed that the Lack of fit is significant.",
    },
    gmloglikelihood_score: {
      label: "Encryption of the Test set took(seconds)",
      desc: "The metrics compare the tables by fitting the real data to a probabilistic model and afterwards compute the likelihood of the synthetic data belonging to the learned distribution. The higher the value of the log-likelihood, the better a model fits a dataset. The log-likelihood value for a given model can range from negative infinity to positive infinity.",
    },
    logdetection_score: {
      label: "Accuracy of the Model",
      desc: "The metric evaluate how hard it is to distinguish the synthetic data from the real data by using a Machine Learning model. The metrics will shuffle the real data and synthetic data together with flags indicating whether the data is real or synthetic, and then cross validate a Machine Learning model that tries to predict this flag.",
    },
    multiclassdecisiontreeclassifier_score: {
      label: "Time per Epoch(seconds)",
      desc: "This metrics will evaluate whether it is possible to replace the real data with synthetic data in order to solve a Machine Learning Problem by learning a Machine Learning model on the synthetic data and then evaluating the score which it obtains when evaluated on the real data.",
    },
  };

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserInput = (e) => {
    const { name, value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabVal(newValue);
  };

  const getSampleResult = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    setSpinner(true);
    setIsDataPredicting(false);
    setIsDataDecrypting(false);
    setData(null);
    setMetaData(null);
    postQueryRes("sampleupload", {
      Context_param: {
        Application_name: "encrypted-data",
        Execution_time: date,
        UserID: userId,
      },
      Content_param: {
        Dataset_Name: "sample.csv",
        s3Path: "encrypted-data/sample/sample.csv",
      },
    })
      .then((res) => {
        setFirstTabVal("DATASET VALIDATION");
        setSubTabVal(1);
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res.message });
          return;
        }
        setNotification({ open: "success", message: res.Console });
        setData({ ...res?.Result, Application_name: "encrypted-data" });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };
  const onChangeFile = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    setFileData({ file: file, name: filename });
  };

  const getUploadResult = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    if (!fileData.file) {
      setNotification({ open: "error", message: "please upload a file" });
      return;
    }
    setIsDataPredicting(false);
    setIsDataDecrypting(false);
    setData(null);
    setSpinner(true);
    setMetaData(null);

    uploadFilesWithBody("upload", {
      Context_param: JSON.stringify({
        Application_name: "encrypted-data",
        UserID: userId,
        Execution_time: date,
      }),
      Content_param: JSON.stringify({ Dataset_Name: fileData.name }),
      file: fileData.file,
    })
      .then((res) => {
        setFirstTabVal("Validate Data");
        setSubTabVal(1);
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res.message });
          return;
        }
        setNotification({ open: "success", message: res.Console });
        setData({ ...res?.Result, Application_name: "encrypted-data" });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };
  const validateData = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    setEncryptData({});
    setMetaData({
      ...metaData,
      isValidateResValid: false,
      validInfo: false,
    });
    setSpinner(true);
    setIsDataPredicting(false);
    setIsDataDecrypting(false);
    let params = {
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
        Execution_time: date,
      },
      Content_param: { s3Path: data.path, target_column_name: fields.target },
    };
    postQueryResult("validate", params)
      .then((res) => {
        setsecondTabVal("ENCRYPTION & MODEL BUILDING");
        setSubTabVal(2);
        setNotification({ open: "success", message: res.Console });
        setSpinner(false);
        setMetaData({
          ...metaData,
          isValidateResValid: !res.Error_Flag,
          validInfo: res?.Result,
          isDataValid: !res.Error_Flag,
        });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const encryptDataFunc = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    setMetaData({
      ...metaData,
      isEncryptResValid: false,
    });
    setIsDataTraining(true);
    setIsDataPredicting(false);
    setIsDataDecrypting(false);
    setEncryptData({});
    setSpinner(true);
    setOpen("Sample 1");
    let params = {
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
        Execution_time: date,
      },
      Content_param: {
        s3Path: data.path,
        target: fields.target,
        global_scale: fields.global_scale,
        poly_mod_degree: fields.poly_mod_degree,
        split: fields.Test_Train_split,
        target: fields.target,
        epochs: fields.epochs,
      },
    };
    postQueryResult("encrypt", params)
      .then((res) => {
        setThirdTabVal("SAMPLE PREDICTION");
        setSubTabVal(3);
        setIsDataTraining(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res.Error_UI });
          setSpinner(false);
          return;
        } else {
          setSpinner(false);
          setNotification({ open: "success", message: res.Console });

          setEncryptData({
            ...res?.Result,
            Application_name: "encrypted-data",
          });
          getDownloadLink({
            ...downloadLink,
            secretFilePath: res?.Result.secret_path,
            publicFilePath: res?.Result.public_path,
            modelFilePath: res?.Result.model_path,
          });
          setSampleData(res?.Result.sample1);
          setColData(res.Result.col_names);
          setMetaData({
            ...metaData,
            isDataValid: !res.Error_Flag,
            isEncryptResValid: !res.Error_Flag,
          });
        }
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const getDwnldLink = (e) => {
    setSpinner(true);
    const dwnldLinkName = e.currentTarget.getAttribute("data-link");
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    if (dwnldLinkName == "secret") {
      postQueryRes("signedurl", {
        Context_param: {
          Application_name: "encrypted-data",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          filePath: downloadLink.secretFilePath,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          setDownloadLink({
            ...finalLink,
            secretLnk: res.Result,
          });
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    } else if (dwnldLinkName == "public") {
      postQueryRes("signedurl", {
        Context_param: {
          Application_name: "encrypted-data",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          filePath: downloadLink.publicFilePath,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          setDownloadLink({
            ...finalLink,
            publicLnk: res.Result,
          });
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    } else if (dwnldLinkName == "model") {
      postQueryRes("signedurl", {
        Context_param: {
          Application_name: "encrypted-data",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          filePath: downloadLink.modelFilePath,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          setDownloadLink({
            ...finalLink,
            modelLnk: res.Result,
          });
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    } else if (dwnldLinkName == "encrypt") {
      postQueryRes("signedurl", {
        Context_param: {
          Application_name: "encrypted-data",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          filePath: downloadLink.encryptedPath,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          setDownloadLink({
            ...finalLink,
            encryptedLnk: res.Result,
          });
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    }
  };

  const predictData = () => {
    setIsDataPredicting(false);
    setIsDataDecrypting(false);
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    setSpinner(true);
    setIsDataPredicting(true);
    let params = {
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
        Execution_time: date,
      },
      Content_param: {
        s3Path: data.path,
        secret_path: encryptData.secret_path,
        public_path: encryptData.public_path,
        model_path: encryptData.model_path,
        mean: encryptData.mean,
        std: encryptData.std,
        input: sampleData,
      },
    };
    postQueryResult("predict", params)
      .then((res) => {
        setIsDataPredicting(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res.Error_UI });
          setSpinner(false);
          return;
        } else {
          setSpinner(false);
          setNotification({ open: "success", message: res.Console });
          setPredictedData(res.Result);
          getDownloadLink({
            ...downloadLink,
            encryptedPath: res?.Result.encryptedOutPath,
          });
          setFourthTabVal("DECRYPT THE PREDICTED VALUE");
          setSubTabVal(4);
        }
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.Error_UI });
        setSpinner(false);
      });
  };

  const decryptVals = () => {
    setIsDataPredicting(false);
    setIsDataDecrypting(false);
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    setSpinner(true);
    setIsDataDecrypting(true);
    let params = {
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
        Execution_time: date,
      },
      Content_param: {
        secret_path: encryptData.secret_path,
        public_path: encryptData.public_path,
        encryptedOutPath: downloadLink.encryptedPath,
      },
    };
    postQueryResult("DecryptData", params)
      .then((res) => {
        setIsDataDecrypting(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res.Error_UI });
          setSpinner(false);
          return;
        } else {
          setSpinner(false);
          setNotification({ open: "success", message: res.Console });
          setDecryptData(res.Result);
        }
      })
      .catch((err) => {
        setIsDataDecrypting(false);
        setNotification({ open: "error", message: err.Error_UI });
        setSpinner(false);
      });
  };

  const setSampleDataFunc = (e) => {
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    if (selectedSample == "sample1") {
      setSampleData(encryptData?.sample1);
    } else {
      setSampleData(encryptData?.sample2);
    }
    if (setSampleDataFunc !== e.target.textContent) {
      setOpen(e.target.textContent);
    } else {
      setOpen(false);
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <strong>What is Homomorphic Encryption?</strong>
          <p>
            Homomorphic encryption is the conversion of data into ciphertext
            that can be analyzed and worked with as if it were still in its
            original form. Homomorphic encryption enables complex mathematical
            operations to be performed on encrypted data without compromising
            the encryption. Homomorphic encryption differs from typical
            encryption methods because it enables mathematical computations to
            be performed directly on the encrypted data, which can make the
            handling of user data by third parties safer. Homomorphic encryption
            is designed to create an encryption algorithm that enables an
            infinite number of arithmetic operations to encrypted data.
          </p>
          <strong>Why Homomorphic Encryption?</strong>
          <p>
            In the field of medicine and pharmaceuticals, the patient data or
            any medical data is expected to be confidential. Most business level
            machine learning models are deployed on the cloud because it’s very
            difficult to share models across users and the computational
            requirements are also very high. Also, many organizations are
            reluctant to use these services because of the prospect of having to
            expose the data to the cloud providers which defeats the whole point
            of confidentiality. There is also a lack of trust in some cases.
            Homomorphic encryption comes into play here by allowing computations
            to be performed directly on encrypted data because of the removal of
            the need for decrypting the data before you use it since arithmetic
            operations can be performed on data encrypted using homomorphic
            encryption. In other words, data integrity and privacy are protected
            while you process data.
          </p>
          <p>
            Most organizations are afraid of providing their data to their
            rivals. With possibility of working with encrypted data it’s
            possible for organizations to share their data and create larger
            datasets thus further helping with medical studies.
          </p>
          <Grid container xs={12} alignItems="center" alignContent="center" justifyContent="center">
            <Grid item xs={6}>
           <center> <ZoomImage src={EncryptedTextData1} /></center>
            </Grid>
            <Grid item xs={6}>
            <center><ZoomImage src={EncryptedTextData2} /></center>
            </Grid>
          </Grid>
          <ol>
            <li>
              The .csv data which is provided by the user is first preprocessed.
              The null values are dropped and data is standardized. This data is
              now converted into ordinary tensors and split into testing and
              training data.
            </li>
            <li>
              The ordinary tensors are now encrypted using CKKS scheme which is
              a homomorphic encryption scheme for additions and multiplications
              and their inverses on encrypted real or complex numbers, CKKS is
              used to yield only approximate results which is sufficient for
              various machine learning models.
            </li>
          </ol>
          <center>
            <Image src={EncryptedTextData3} />
          </center>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/encrypted-data/sample/EncryptedTabularData.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          <AppBar position="static">
            <StyledTabss
              value={subtabVal}
              onChange={handleSubTabChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={
                  <div
                    style={{
                      marginRight: "auto",
                      maxWidth: "max-content",
                    }}
                  >
                    <span>Dataset Selection &nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </div>
                }
              />
              {firstTabVal && (
                <Tab
                  label={
                    <div
                      style={{
                        marginRight: "auto",
                        maxWidth: "max-content",
                      }}
                    >
                      <span>{firstTabVal}&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </div>
                  }
                />
              )}
              {secondtabVal && (
                <Tab
                  label={
                    <div
                      style={{
                        marginRight: "auto",
                        maxWidth: "max-content",
                      }}
                    >
                      <span>{secondtabVal}&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </div>
                  }
                />
              )}
              {thirdtabVal && (
                <Tab
                  label={
                    <div
                      style={{
                        marginRight: "auto",
                        maxWidth: "max-content",
                      }}
                    >
                      <span>{thirdtabVal}&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </div>
                  }
                />
              )}
              {fourthtabVal && (
                <Tab
                  label={
                    <div
                      style={{
                        marginRight: "auto",
                        maxWidth: "max-content",
                      }}
                    >
                      <span>{fourthtabVal}&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </div>
                  }
                />
              )}
            </StyledTabss>
          </AppBar>
          <TabPanel value={subtabVal} index={0}>
            <Grid container spacing={1}>
              <LeftGrid item xs={12}>
                <h4>
                  <FontAwesomeIcon icon={faTable} /> &nbsp; Choose Dataset
                </h4>
                <p>
                  Please Upload your own dataset or Click on the "Select Sample
                  File" to continue
                </p>
                <Grid container xs={12} sm={9}>
                  <Grid item xs={10} sm={6} md={3}>
                    <Input
                      type="file"
                      color="primary"
                      name="uploadedFile"
                      onChange={onChangeFile}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getUploadResult}
                    >
                      Upload
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={1}>
                    <h3>OR</h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getSampleResult}
                    >
                      Select Sample File
                    </StyledButton>
                  </Grid>
                </Grid>
              </LeftGrid>
            </Grid>
            <hr />
            <br />
          </TabPanel>
          <TabPanel value={subtabVal} index={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                {data?.dfHead && (
                  <Grid container xs={12} spacing={2} direction="row">
                    <Grid item xs={12}>
                      <AppBar position="static" color="default">
                        <StyledTabs
                          value={value}
                          onChange={tabhandleChange}
                          aria-label="simple tabs example"
                        >
                          <Tab label="Data Sample" />
                          <Tab label="Data Overview" />
                          <Tab label="Data Statistics" />
                        </StyledTabs>
                      </AppBar>
                      <TabPanel value={value} index={0}>
                        <JsonDataTable
                          fields={data?.dfHead?.schema?.fields}
                          dataRows={data?.dfHead?.data}
                        />
                      </TabPanel>

                      <TabPanel value={value} index={1}>
                        {data?.dfDescribe && (
                          <JsonDataTable
                            fields={data?.dfDescribe?.schema?.fields}
                            dataRows={data?.dfDescribe?.data}
                          />
                        )}
                      </TabPanel>

                      <TabPanel value={value} index={2}>
                        {data?.dfOverview && (
                          <OverViewList tyee="none">
                            {Object.entries(data.dfOverview).map((data) => (
                              <ListItem>
                                <FontAwesomeIcon icon={faCheckCircle} />
                                {`${data[0]} : ${data[1]}`}
                              </ListItem>
                            ))}
                          </OverViewList>
                        )}
                      </TabPanel>
                    </Grid>
                  </Grid>
                )}
                <br />
                {data?.columnNames && (
                  <>
                    <hr />
                    <LeftH4>
                      Please select an intended target column for model
                      prediction and click on "Validate the Data"
                    </LeftH4>
                    <LeftGrid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="target"
                          label="Choose target"
                          variant="outlined"
                          onChange={handleOnChange}
                          select
                          size="small"
                          fullWidth
                          value={fields?.target}
                        >
                          <MenuItem key="modelType" value="">
                            Choose Target
                          </MenuItem>
                          {data?.columnNames?.map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <br />
                      <Grid item xs={12}>
                        <small>
                          <i>
                            For sample dataset please choose TenYearCHD as
                            target variable*
                          </i>
                        </small>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <StyledButton
                          variant="contained"
                          color="secondary"
                          onClick={validateData}
                        >
                          Validate the Data
                        </StyledButton>
                      </Grid>
                    </LeftGrid>
                  </>
                )}
                <Grid />
              </Grid>
              <Grid item xs={12} md={3}>
                <Image src={EncryptedTextData4}></Image>
              </Grid>
            </Grid>
            <hr />
            <br />
          </TabPanel>
          <TabPanel value={subtabVal} index={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <ValidInfoContainer>
                  {metaData?.validInfo &&
                    Object.keys(metaData?.validInfo).map((key) => (
                      <span>
                        <FontAwesomeIcon
                          color={
                            metaData?.validInfo[key]?.error
                              ? "#d13f48"
                              : "#4caf50"
                          }
                          icon={
                            metaData?.validInfo[key]?.error
                              ? faExclamationCircle
                              : faCheckCircle
                          }
                        />
                        &nbsp;&nbsp;{metaData?.validInfo[key]?.message}
                      </span>
                    ))}
                </ValidInfoContainer>
                <>
                  <LeftH3>
                    Create a TenSEAL context for CKKS encryption and Encrypt the
                    Data
                  </LeftH3>
                  <LinkContainer>
                    <Grid container spacing={2}>
                      <Grid item>
                        <StyledButton
                          variant="outlined"
                          color="primary"
                          size="large"
                          startIcon={<OpenInNewIcon />}
                        >
                          <a
                            href="https://material.vlifevirtusa.com/encrypted-data/EncryptDataAndModel.docx"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more about CKKS, TenSEAL context and model
                            encryption
                          </a>
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </LinkContainer>
                  <LeftH4>
                    Please choose the parameters for model prediction and click
                    on "Encrypt the Data and Model"
                  </LeftH4>
                  <Grid container xs={12} spacing={1} direction="row">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="poly_mod_degree"
                        label="Choose Poly Mod Degree "
                        variant="outlined"
                        onChange={handleOnChange}
                        select
                        size="small"
                        fullWidth
                        value={fields.poly_mod_degree}
                      >
                        <MenuItem key="0" value={8192}>
                          8192
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="global_scale"
                        label="Choose Global Scale"
                        variant="outlined"
                        onChange={handleOnChange}
                        select
                        size="small"
                        fullWidth
                        value={fields.global_scale}
                      >
                        <MenuItem key="1" value="21">
                          21
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="Test_Train_split"
                        label="Choose Test-Train split"
                        variant="outlined"
                        onChange={handleOnChange}
                        select
                        size="small"
                        fullWidth
                        value={fields.Test_Train_split}
                      >
                        <MenuItem key="0" value="0.2">
                          0.2
                        </MenuItem>
                        <MenuItem key="1" value="0.25">
                          0.25
                        </MenuItem>
                        <MenuItem key="2" value="0.3">
                          0.3
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="epochs"
                        label="Choose Number of Epochs"
                        variant="outlined"
                        onChange={handleOnChange}
                        select
                        size="small"
                        fullWidth
                        value={fields.epochs}
                      >
                        {epochs.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12}>
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={encryptDataFunc}
                      >
                        Encrypt the Data and Train the Model
                      </StyledButton>
                    </Grid>
                  </Grid>
                </>
                {isDataTraining && (
                  <>
                    <Result>
                      <strong>
                        Please wait... The following actions are being
                        performed:
                      </strong>
                      <ul>
                        <li>
                          Data is being split into test and training set...
                        </li>
                        <li>Data is getting encrypted using public key...</li>
                        <li>
                          Model is getting encrypted using public key and
                          training is taking place on encrypted model...
                        </li>
                        <li>Please wait for around 2 minutes per epoch...</li>
                      </ul>
                    </Result>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Image src={EncryptedTextData5}></Image>
              </Grid>
            </Grid>
            <hr />
            <br />
          </TabPanel>
          <TabPanel value={subtabVal} index={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <LeftH3>Output:</LeftH3>

                {metaData?.isEncryptResValid && (
                  <>
                    <hr></hr>
                    {encryptData && (
                      <>
                        <Grid container xs={12} spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <EncryptedResults elevation={3}>
                              <strong id="title">
                                <FontAwesomeIcon
                                  icon={faLightbulb}
                                  color="#4caf50"
                                />
                                Time taken for encrypting the training set
                              </strong>
                              <p id="label">
                                The public key generated from the CKKS context
                                is used to encrypt the training dataset. The
                                time taken increases almost linearly with the
                                number of elements present in the dataset. This
                                is being displayed because homomorphic
                                encryption is computation heavy process.
                              </p>
                              <strong id="label">
                                Encryption of the Training set took(seconds):{" "}
                                {encryptData.train_time_taken}
                              </strong>
                            </EncryptedResults>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <EncryptedResults elevation={3}>
                              <strong id="title">
                                <FontAwesomeIcon
                                  icon={faLightbulb}
                                  color="#4caf50"
                                />
                                Time taken for encrypting the test set
                              </strong>
                              <p id="label">
                                The public key generated from the CKKS context
                                is used to encrypt the test dataset. Considering
                                that training set is much larger than test set
                                in most scenarios the time taken will be lower.
                              </p>
                              <strong id="label">
                                Encryption of the test set took(seconds):
                                {encryptData.test_time_taken}
                              </strong>
                            </EncryptedResults>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <EncryptedResults elevation={3}>
                              <strong id="title">
                                <FontAwesomeIcon
                                  icon={faLightbulb}
                                  color="#4caf50"
                                />
                                Accuracy
                              </strong>
                              <p id="label">
                                Accuracy is one of the basic metrics for
                                evaluating classification models. Accuracy can
                                be defined as the fraction of predictions the
                                model got right.
                              </p>
                              <strong id="label">
                                Accuracy of the Model: {encryptData.accuracy}
                              </strong>
                            </EncryptedResults>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <EncryptedResults elevation={3}>
                              <strong id="title">
                                <FontAwesomeIcon
                                  icon={faLightbulb}
                                  color="#4caf50"
                                />
                                Time taken per epoch
                              </strong>
                              <p id="label">
                                The weights and bias of the model are encrypted
                                using the same CKKS context as the dataset.
                                Training the encrypted logistic regression model
                                on the encrypted dataset will take up a large
                                amount of time. With this parameter the user can
                                know the average time it takes per epoch
                              </p>
                              <strong id="label">
                                Time per Epoch(seconds):{" "}
                                {encryptData.time_per_epoch}
                              </strong>
                            </EncryptedResults>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <br />
                    <LinkContainer>
                      <LeftGrid container spacing={2}>
                        {finalLink?.secretLnk ? (
                          <>
                            <Grid item xs={12}>
                              <StyledButton variant="outlined" color="primary">
                                <a
                                  href={finalLink.secretLnk}
                                  download
                                  rel="noreferrer"
                                >
                                  Download Secret Key
                                </a>
                              </StyledButton>
                            </Grid>
                            <Grid item xs={12}>
                              <small>
                                <i>
                                  Secret key is used by the receiver to decrypt
                                  the encrypted values to read the message*
                                </i>
                              </small>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                onClick={getDwnldLink}
                                data-link="secret"
                              >
                                Get Secret Key
                              </StyledButton>
                            </Grid>
                            <Grid item xs={12}>
                              <small>
                                <i>
                                  Secret key is used by the receiver to decrypt
                                  the encrypted values to read the message*
                                </i>
                              </small>
                            </Grid>
                          </>
                        )}
                        {finalLink?.publicLnk ? (
                          <>
                            <Grid item xs={12}>
                              <StyledButton variant="outlined" color="primary">
                                <a
                                  href={finalLink.publicLnk}
                                  download
                                  rel="noreferrer"
                                >
                                  Download Public Key
                                </a>
                              </StyledButton>
                            </Grid>
                            <Grid item xs={12}>
                              <small>
                                <i>
                                  Public key is used to encrypt the plain
                                  dataset to convert it into encrypted dataset*
                                </i>
                              </small>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                onClick={getDwnldLink}
                                data-link="public"
                              >
                                Get Public Key
                              </StyledButton>
                            </Grid>
                            <Grid item xs={12}>
                              <small>
                                <i>
                                  Public key is used to encrypt the plain
                                  dataset to convert it into encrypted dataset*
                                </i>
                              </small>
                            </Grid>
                          </>
                        )}
                        {finalLink?.modelLnk ? (
                          <>
                            <Grid item xs={12}>
                              <StyledButton variant="outlined" color="primary">
                                <a
                                  href={finalLink.modelLnk}
                                  download
                                  rel="noreferrer"
                                >
                                  Download Model
                                </a>
                              </StyledButton>
                            </Grid>
                            <Grid item xs={12}>
                              <small>
                                <i>
                                  The model is a binary classifier (Logistic
                                  regression) which calculates the probability
                                  of a discrete outcome given an input variable*
                                </i>
                              </small>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                onClick={getDwnldLink}
                                data-link="model"
                              >
                                Get Model
                              </StyledButton>
                            </Grid>
                            <Grid item xs={12}>
                              <small>
                                <i>
                                  The model is a binary classifier (Logistic
                                  regression) which calculates the probability
                                  of a discrete outcome given an input variable*
                                </i>
                              </small>
                            </Grid>
                          </>
                        )}
                      </LeftGrid>
                    </LinkContainer>
                    <br />
                    <LeftPara>
                      Encrypted data will be in the following form:
                    </LeftPara>
                    <br />
                    <br />
                    <center>
                      <img src={EncryptedTextData9} />
                    </center>
                    <br />
                    {colData && (
                      <>
                        <LeftPara>
                          <strong>Enter the values for model prediction</strong>
                        </LeftPara>
                        <br />
                        <br />
                        <br />
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={3}
                          id="colcontainer"
                        >
                          {colData.map((arr) => (
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                name={arr}
                                label={arr}
                                variant="outlined"
                                onChange={handleUserInput}
                                size="small"
                                fullWidth
                                value={sampleData?.[arr]}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          ))}
                          <Grid />
                          <br />
                          <Grid
                            container
                            xs={12}
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                          >
                            <Grid item xs={12} sm={6}>
                              <FullWidthStyledBtn
                                variant="contained"
                                color="primary"
                                data-sample="sample1"
                                onClick={setSampleDataFunc}
                                isActive={open === "Sample 1"}
                              >
                                Sample 1
                              </FullWidthStyledBtn>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FullWidthStyledBtn
                                variant="contained"
                                color="primary"
                                data-sample="sample2"
                                onClick={setSampleDataFunc}
                                isActive={open === "Sample 2"}
                              >
                                Sample 2
                              </FullWidthStyledBtn>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <StyledButton
                                variant="contained"
                                color="secondary"
                                onClick={predictData}
                              >
                                Predict
                              </StyledButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <br />

                    {isDataPredicting && (
                      <>
                        <Result>
                          <strong>
                            Please wait... The following actions are being
                            performed:
                          </strong>
                          <ul>
                            <li>
                              The user provided inputs are converted into a
                              tensor and then encrypted using the public key....
                            </li>
                            <li>
                              This encrypted data is passed to the saved
                              encrypted model and predictions are made...
                            </li>
                          </ul>
                        </Result>
                      </>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Image src={EncryptedTextData6}></Image>
              </Grid>
            </Grid>
            <hr />
            <br />
          </TabPanel>
          <TabPanel value={subtabVal} index={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                {
                  <>
                    <br />
                    {predictedData && (
                      <>
                        <LinkContainer>
                          <LeftGrid container spacing={2}>
                            {finalLink?.encryptedLnk ? (
                              <>
                                <Grid item xs={12}>
                                  <StyledButton
                                    variant="outlined"
                                    color="primary"
                                  >
                                    <a
                                      href={finalLink.encryptedLnk}
                                      download
                                      rel="noreferrer"
                                    >
                                      Download Encrypted Output
                                    </a>
                                  </StyledButton>
                                </Grid>
                                <Grid item xs={12}>
                                  <small>
                                    <i>
                                      The predicted probability is base64
                                      encoded, serialized and encrypted. The
                                      encrytion has been done using the public
                                      key generated during dataset and model
                                      encryption
                                    </i>
                                  </small>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={12}>
                                  <StyledButton
                                    variant="outlined"
                                    color="primary"
                                    onClick={getDwnldLink}
                                    data-link="encrypt"
                                  >
                                    Get Encrypted Data
                                  </StyledButton>
                                </Grid>

                                <Grid item xs={12}>
                                  <small>
                                    <i>
                                      The predicted probability is base64
                                      encoded, serialized and encrypted. The
                                      encrytion has been done using the public
                                      key generated during dataset and model
                                      encryption
                                    </i>
                                  </small>
                                </Grid>
                              </>
                            )}
                          </LeftGrid>
                          <br />
                          <LeftPara>
                            The serialized, encrypted output looks like:
                          </LeftPara>
                          <br />
                          <center>
                            <Image src={EncryptedTextData10} />
                          </center>
                          <br />
                          <LeftPara>
                            The decryption of the predicted encrypted
                            probability is done using the secret key generated
                            during dataset and model encryption. The decrypted
                            probability will be shown in the UI after the user
                            clicks on "decrypt the predicted value using the
                            secret key"
                          </LeftPara>
                        </LinkContainer>
                        <Grid
                          container
                          xs={12}
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          textAlign="center"
                        >
                          <Grid item xs={12} sm={6}>
                            <FullWidthStyledBtn
                              variant="contained"
                              color="primary"
                              onClick={decryptVals}
                            >
                              Decrypt the predicted value using the secret key
                            </FullWidthStyledBtn>
                          </Grid>
                        </Grid>
                        <br />
                        <br />
                        {isDataDecrypting && (
                          <>
                            <Result>
                              <strong>
                                Please wait... The following actions are being
                                performed:
                              </strong>
                              <ul>
                                <li>
                                  The encrypted prediction then gets decrypted
                                  using the secret key and is sent to the UI...
                                </li>
                                <li>
                                  The prediction will contain the probability of
                                  occurance of both 0 and 1...
                                </li>
                              </ul>
                            </Result>
                          </>
                        )}
                        {decryptData && (
                          <StyledTable>
                            <TableHead>
                              <TableRow>
                                <StyledTableHead>
                                  <h3>Outcome</h3>
                                </StyledTableHead>
                                <StyledTableHead>
                                  <h3>Probability (%)</h3>
                                </StyledTableHead>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(decryptData).map((value) => (
                                <TableRow>
                                  <TableCell>{value[0]}</TableCell>
                                  <TableCell>{value[1]}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </StyledTable>
                        )}
                      </>
                    )}
                  </>
                }
              </Grid>
              {decryptData ? (
                <Grid item xs={12} md={3}>
                  <Image src={EncryptedTextData8}></Image>
                </Grid>
              ) : (
                <Grid item xs={12} md={3}>
                  <Image src={EncryptedTextData7}></Image>
                </Grid>
              )}
            </Grid>

            <hr />
            <br />
          </TabPanel>
          {showSpinner && (
            <Spinner text="Loading.. Please wait for a minute..." />
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(EncryptedTextData);

const ValidInfoContainer = styled.div`
  text-align: left;
  padding-bottom: 15px;
  span {
    display: block;
    padding: 5px;
  }
`;
const DemoHeader = styled.div`
  text-align: left;
  h4 {
    border-bottom: 1px solid;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
const LeftGrid = styled(Grid)`
  text-align: left;
`;
const StyledTable = styled(Table)`
  width: 50%;
`;
const FullWidthStyledBtn = styled(StyledButton)`
  width: -webkit-fill-available;
  background-color: ${(props) => (props.isActive ? "#fc7703" : "#2941b7")};
`;
function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

const LeftH3 = styled.h3`
  text-align: left;
`;

const LeftH4 = styled.h4`
  text-align: left;
`;

const Image = styled.img`
  width: -webkit-fill-available;
`;

const LeftPara = styled.p`
  float: left;
  text-align: left;
`;

export const StyledTabss = styled(StyledTabs)`
  color: black;
  background-color: white;

  .Mui-selected {
    background-color: white;
    color: black;
    font-weight: bold;
    text-transform: none;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: block;
  text-align: left;
  font-size: 28px;
`;

const EncryptedResults = styled(Paper)`
  &.MuiPaper-root {
    padding: 10px;
    text-align: justify;
    height: 100%;
    #title {
      font-size: medium;
    }
    #label {
      font-size: small;
    }
  }
`;
