import * as React from "react";
import { TextField, FormControlLabel } from "@material-ui/core";
import styled from "styled-components";
import { formData } from './constants';
import { TextFieldToolTip } from './components/InfoToolTip';

export default function InputForm({ form, fields, action }) {
  const formDataObj = formData[form];
  const fieldsList = formDataObj.fields;
  return (
    <FormContainer>
      {formDataObj
        && fieldsList.map((inputObj) => (inputObj.type === 'text' ? (
          <StyledInput
            name={inputObj.name}
            label={inputObj.label}
            required={inputObj.required}
            type={inputObj.type}
            variant="outlined"
            onChange={action}
            value={fields[inputObj.name]}
            error={fields[`error_${inputObj.name}`]}
            helperText={fields[`error_${inputObj.name}`] && 'Required'}
          />
        ) : (
          inputObj.type === 'multiline' && (
          <StyledInput
            name={inputObj.name}
            label={inputObj.label}
            type={inputObj.type}
            variant="outlined"
            onChange={action}
            required={inputObj.required}
            multiline
            rows={inputObj.rows}
            value={fields[inputObj.name]}
            error={fields[`error_${inputObj.name}`]}
            helperText={
                  fields[`error_${inputObj.name}`] && 'Data is Required'
                }
          />
          )
        )))}
    </FormContainer>
  );
}

const FormContainer = styled.section`
  padding: 10px;
  margin: 10px;
`;

const InputStyled = styled(TextField)`
  margin-top: 10px;
  width: 100%;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "250px")};
  max-width: ${(props) => props.maxWidth};
  @media only screen and (max-width: 500px) {
    min-width:10%;
    max-width: 100%;
  }
`;

const InputStyledControl = styled(FormControlLabel)`
  margin-top: 10px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '150px')};
  max-width: ${(props) => props.maxWidth};
`;

export const StyledInput = (props) => (
  <>
    {props.info ? (
      <TextFieldToolTip info={props.info}>
        <InputStyled {...props} />
      </TextFieldToolTip>
    ) : (
      <InputStyled {...props} />
    )}
  </>
);
export const FormControlLabelInfo = (props) => (
  <>
    {props.info ? (
      <TextFieldToolTip info={props.info}>
        <InputStyledControl {...props} />
      </TextFieldToolTip>
    ) : (
      <InputStyledControl {...props} />
    )}
  </>
);
