/* actions for shopping cart */

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";

/* actions for solution selections */

export const SET_SELECTED_SOLUTION = "SET_SELECTED_SOLUTION";
export const CLEAR_SELECTED_SOLUTION = "CLEAR_SELECTED_SOLUTION";

export const REQUEST_CART_DETAILS = "REQUEST_CART_DETAILS";
export const RECEIVE_CART_DETAILS = "RECEIVE_CART_DETAILS";

/* ations for vnet */
export const SET_FEATURE_DATA = "SET_FEATURE_DATA";
export const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS";
export const SET_INPUT_DATA = "SET_INPUT_DATA";
export const CLEAR_INPUT_DATA = "CLEAR_INPUT_DATA";
export const SET_RESULT = "SET_RESULT";
export const CLEAR_RESULT = "CLEAR_RESULT";
export const SET_CONSOLE = "SET_CONSOLE";
export const IS_CANVA_CLICK = "IS_CANVA_CLICK";
export const SET_CANVA_LIST = "SET_CANVA_LIST";
export const SET_RESULT_TABVAL = "SET_RESULT_TABVAL";

/* ations for datachef */

export const SET_PROJECT = "SET_PROJECT";
export const SET_FEATURE_DATA_CHEF = "SET_FEATURE_DATA_CHEF";
export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const CREATE_JOBS = "CREATE_JOBS";
export const SET_DATACHEF_INPUT_DATA = "SET_DATACHEF_INPUT_DATA";



