import React, { memo, useState } from "react";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// local components
import Graph from "../../pages/doppleganger/Graph";
import State from "../../pages/doppleganger/State";
import UsState from "../../pages/doppleganger/UsState";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  ListItem,
  List,
  DemoContainer,
} from "../../../styles/common";

const DopplegangerView = () => {
  const [notification, setNotification] = useState("");
  const [currentComponent, SetCurrentComponent] = useState("UsStates");
  const [selectedState, SetSelectedState] = useState("UsStates");
  const [selectedCity, SetSelectedCity] = useState("");
  const [selectedPerson, SetSelectedPerson] = useState("");

  const goBackToUsStates = () => {
    SetCurrentComponent("UsStates");
  };

  const goBackToState = () => {
    SetCurrentComponent("State");
  };

  const showStateComponent = (drillDown) => {
    console.log('showStateComponent', drillDown);
    SetSelectedState(drillDown);
    SetCurrentComponent("State");
  };

  const showGraphComponent = (city, person) => {
    console.log('showGraphComponent', city, person);
    SetSelectedCity(city);
    SetSelectedPerson(person);
    SetCurrentComponent("Graph");
  };

  const getComponent = (componentName) => {
    const components = {
      UsStates: <UsState onSelectState={showStateComponent} />,
      State: <State goBack={goBackToUsStates} onSelectedCity={showGraphComponent} selectedState={selectedState} />,
      Graph: <Graph goBack={goBackToState} selectedCity={selectedCity} selectedPerson={selectedPerson} />,
    };

    return components[componentName];
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>The digital twin concept is close to finding a doppelganger in the real world. For every individual has certain traits and patterns, finding subjects who are close by and emulate similar traits will help the user understand and analyze commonalities and differences between the individuals​</p>
          <List>
            <ListItem>The application is trained on entire US population and is capable to work in a large scale. The identical patients can be drilled down from a geographic perspective.​</ListItem>
            <ListItem>Choosing a geographic location narrows down the search to a limited set of individuals from that particular state​​</ListItem>
            <ListItem>Every state populates the name of all the individuals available from which the user can select one​</ListItem>
            <ListItem>The selected user is then matched with the individuals (if any) by a complex algorithm ​</ListItem>
            <ListItem>The output will be the digital twin names which are highlighted on the map of the selected state​</ListItem>
          </List>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          {getComponent(currentComponent)}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(DopplegangerView);
