import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import PageLoading from "./components/common/loading/PageLoading";
import ScrollTop from "./components/common/ScrollToTop";
import PageNotFound from "./components/pages/PageNotFound";
import Register from "./components/pages/Register";
import TermsandConditions from "./components/pages/TermsandConditions";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import PrivateRoute from "./components/common/AuthProvider";
import SearchSolutions from "./components/pages/SearchSolution";
import vNetHome from "./components/pages/vNetHome";
import PopulationHealthDashboard from "./components/views/solutions/PopulationHealthDashboard";
import PatientHeirarchyDashboard from "./components/pages/PatientHeirarchyDashboard";
import Type2diabetesDiagnosis from "./components/pages/Type2diabetesDiagnosis";
import MusculoSketalDashboard from "./components/pages/viz/MusculoskeletalDashboard";
import PatientJourneyForServices from "./components/pages/viz/PatientJourneyForServices";
import CohortAnalyzerTool from "./components/pages/cohortAnalyzer/CohortAnalyzerTool";
import HLSDashboard from "./components/pages/HLS/HLSDashboard";
import Type2diabetesDashboard from "./components/pages/viz/Type2diabetesDashboard";
import PatientJourneyAdmissionT2D from "./components/pages/viz/PatientJourneyAdmissionT2D";
import PatientJourneyAdmission from "./components/pages/viz/PatientJourneyAdmission";
import CommonViz from "./components/marketscan/CommonViz";

const Home = lazy(() => import("./components/pages/Home"));
const Login = lazy(() => import("./components/pages/Login"));
const VerifyToken = lazy(() => import("./components/pages/VerifyToken"));
const Themes = lazy(() => import("./components/pages/Themes"));
const Categories = lazy(() => import("./components/pages/Categories"));
const Solution = lazy(() => import("./components/pages/Solution"));
// const Insight = lazy(() => import("./components/pages/Insight"));
const Pricing = lazy(() => import("./components/pages/Pricing"));
const SubscriptionPlan = lazy(() => import("./components/pages/SubscriptionPlan"));
const About = lazy(() => import("./components/pages/About"));
const Offerings = lazy(() => import("./components/pages/Offerings"));
const EngageWithUs = lazy(() => import("./components/pages/EngageWithUs"));
const FAQ = lazy(() => import("./components/pages/FAQ"));
const AdminDashboard = lazy(() => import("./components/pages/AdminDashboard"));
const HostAppForm = lazy(() => import("./components/pages/HostAppForm"));

const VideosView = lazy(() => import("./components/pages/VideosView"));
const Checkout = lazy(() => import("./components/pages/Checkout"));
const SolutionOrders = lazy(() => import("./components/pages/Orders"));

const FasterAI = lazy(() => import("./components/views/solutions/vnet/FasterAI.js"));
const Vnet = lazy(() => import("./components/views/solutions/vnet"));
const SyntheticData = lazy(() => import("./components/pages/SyntheticData.js"));
const InnovationAsService = lazy(() => import("./components/pages/InnovationAsService.js"));
const Epidemiology = lazy(() => import("./components/pages/Epidemology.js"));
const SiteStartup = lazy(() => import("./components/pages/Startup.js"));
// const DataTranformation = lazy(() =>
//   import("./components/views/solutions/vnet/DataTranformation.js")
// );
const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<PageLoading />}>
        <Router>
          <Switch>
            <Route exact path="/register" component={Register} />
            <Route exact path="/terms-conditions" component={TermsandConditions} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/verify-token" component={VerifyToken} />
            <Route exact path="/subscribe" component={SubscriptionPlan} />

            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/pricing" component={Pricing} />
            <PrivateRoute exact path="/aboutus" component={About} />
            <PrivateRoute exact path="/offerings" component={Offerings} />
            <PrivateRoute exact path="/engage_with_us" component={EngageWithUs} />
            <PrivateRoute exact path="/faq" component={FAQ} />
            <PrivateRoute exact path="/splash" component={SiteStartup} />
            <Route exact path="/host-your-app" component={HostAppForm} />
            <PrivateRoute exact path="/population-health-dashboard" component={PopulationHealthDashboard} />
            <PrivateRoute exact path="/themes/:themeId" component={Themes} />
            <PrivateRoute
              exact
              path="/categories/:primaryCategoryId"
              component={Categories}
            />
            <PrivateRoute
              exact
              path="/search"
              component={SearchSolutions}
            />
            <PrivateRoute
              exact
              path="/categories/:primaryCategoryId/:secondaryCategoryId"
              component={Categories}
            />
            <PrivateRoute
              exact
              path="/categories/:primaryCategoryId/:secondaryCategoryId/:tertiaryCategoryId"
              component={Categories}
            />
            <PrivateRoute exact path="/solutions/:solutionId" component={Solution} />
            <PrivateRoute exact path="/solutions/:solutionId/:type" component={Solution} />
            <PrivateRoute exact path="/synthetic-data" component={SyntheticData} />
            <PrivateRoute exact path="/innovation-as-service" component={InnovationAsService} />
            <PrivateRoute exact path="/epidemiology" component={Epidemiology} />
            <PrivateRoute exact path="/cohort-sense" component={CohortAnalyzerTool} />
            <PrivateRoute exact path="/hls" component={HLSDashboard} />

            {/* viz */}
            <PrivateRoute exact path="/patient-heirarchy-dashboard" component={PatientHeirarchyDashboard} />
            <PrivateRoute exact path="/type2diabetes-diagnosis" component={Type2diabetesDiagnosis} />
            <PrivateRoute exact path="/musculoskeletal-dashboard" component={MusculoSketalDashboard} />
            <PrivateRoute exact path="/patient-journey-servcices" component={PatientJourneyForServices} />
            <PrivateRoute exact path="/type2-diabetes-dashboard" component={Type2diabetesDashboard} />
            <PrivateRoute exact path="/patient-journey-admission-t2d" component={PatientJourneyAdmissionT2D} />
            <PrivateRoute exact path="/patient-journey-admission" component={PatientJourneyAdmission} />
            <PrivateRoute exact path="/viz/:vizid" component={CommonViz} />

            <PrivateRoute exact path="/faster-ai" component={FasterAI} />
            <Route exact path="/admin" component={AdminDashboard} />
            <PrivateRoute exact path="/videos" component={VideosView} />
            <PrivateRoute exact path="/checkout" component={Checkout} />
            <PrivateRoute exact path="/vnet" component={Vnet} />
            {/* <PrivateRoute exact path="/vnet-release-v1" component={Vnet} /> */}
            <PrivateRoute exact path="/orders" component={SolutionOrders} />
            {/* <PrivateRoute exact path="/data-transformation" component={DataTranformation} /> */}

            {/* <Route exact path="/insights/:insightId" component={Insight} /> */}
            <Route component={PageNotFound} />
          </Switch>
          <ScrollTop />
        </Router>
      </Suspense>
    </PersistGate>
  </Provider>
);

export default App;
