import React from 'react';
import Highcharts from 'highcharts/highmaps';

import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from "../../common/loading/Spinner";
import CommonService from '../../../utils/services/CommonService';
import { StyledButton } from '../../../styles/common';

const { getQueryResult } = CommonService("ibri", "digitalTwins");

class Graph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMap: true,
      stateName: "",
      value: "",
      Highcharts,
      map: [{}],
      chartData: [{}],
    };

    this.backBtn = this.backBtn.bind(this);
    this.btnClick = this.btnClick.bind(this);
    this.setChart = this.setChart.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  backBtn() {
    this.setState({
      displayMap: true,
      stateName: "",
    });
    this.props.goBack();
  }

  btnClick(name) {
    this.setState({
      displayMap: false,
      stateName: name,
    });
  }

  setChart() {
    Highcharts.chart('container_line', {
      chart: {
        type: 'line',

      },
      title: {
        text: `County - ${this.props.selectedCity}`,

      },
      subtitle: {
        text: `For better viz, Synthetic persons were limited | dx sequence : ${this.state.chartData[2].category}`,
        verticalAlign: 'bottom',
        align: 'left',
      },

      xAxis: {
        categories: this.state.chartData[3].category,
      },
      yAxis: {
        title: {
          text: 'onset age',
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
            crop: false,
            overflow: 'none',
            align: 'left',
            verticalAlign: 'middle',
            click() {
              this.setState({ mainPage: false });

              return `<span style="color:${this.series.color}">${this.series.name}</span>`;
            },
          },
        },
      },
      series: this.state.chartData,
    });
  }

  render() {
    let button;
    if (this.state.displayMap) {
      button = (
        <StyledButton onClick={this.backBtn} variant="contained" color="primary" endIcon={<FontAwesomeIcon icon={faBackward} />}>Back</StyledButton>
      );
    }

    return (
      <div>
        <div className="App">
          {button}
        </div>
        <br />
        <div id="container_line"><CircularProgress /></div>
      </div>
    );
  }

  componentDidMount() {
    const params = `${this.props.selectedCity},${this.props.selectedPerson}`;
    getQueryResult('getData', params)
      .then((response) => {
        console.log(response.data);
        this.setState({ chartData: JSON.parse(JSON.stringify(response)) });
      }).catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.chartData !== prevState.chartData) {
      console.log(this.state.chartData);
      this.setChart();
    }
  }
}

export default Graph;
