import React from "react";
import { Link } from "react-router-dom";
import { Grid, Divider } from "@material-ui/core";
import MaterialLink from "@material-ui/core/Link/Link";
import styled from "styled-components";
import NavbarConstants from "../../constants/NavbarContent";

export default function ResourceContent({ transform }) {
  const subCategoryList = NavbarConstants.Resources.sections;
  const caseStudies = subCategoryList["case-studies"].category;
  const publications = subCategoryList.publications.category;
  const media = subCategoryList.media.category;

  return (
    <section>
      {subCategoryList && (
        <GridContainer
          container
          spacing={5}
          alignItems="flex-start"
          transform={transform}
        >
          <Grid item xs={12} sm={6} md={3}>
            <h3>Case Studies</h3>
            {caseStudies.map((obj) => (
              <MaterialLink target="_blank" href={obj.externalLink}>
                {obj.heading}
              </MaterialLink>
            ))}
            <br />
            <Divider />
            <h3>Publications</h3>
            {publications.map((obj) => (
              <MaterialLink target="_blank" href={obj.externalLink}>
                {obj.heading}
              </MaterialLink>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <h3>
              <Link to="/categories/gen-ai">Gen AI</Link>
            </h3>
            <Divider />
            <h3>
              <Link to="/categories/stanford-ai">Stanford AI</Link>
            </h3>
            <Divider />
            <h3>
              <Link to="/categories/collaborators">Collaborators</Link>
            </h3>
            <Divider />
            <h3>
              <Link to="/videos">Videos</Link>
            </h3>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <h3>Media</h3>
            {media.map((obj) => (
              <MaterialLink target="_blank" href={obj.externalLink}>
                {obj.heading}
              </MaterialLink>
            ))}
          </Grid>
        </GridContainer>
      )}
    </section>
  );
}

const GridContainer = styled(Grid)`
  width: auto;
  height: auto;
  padding: 20px;
  background: ${(props) => (props.transform ? "#05334E" : "white")};
  border-radius: 5px;
  color: #5d5b60;
  overflow-y: scroll;
  a {
    color: ${(props) => (props.transform ? "#ffff" : "#5d5b60")};
    font-size: 14px;
    textdecoration: none;
    textalign: left;
    font-size: 14px;
    padding: 5px;
    text-decoration: none;
    display: block;
    width: max-content;
    &:hover {
      color: #fc7703;
      textdecoration: none;
    }
  }

  h3 {
    color: ${(props) => (props.transform ? "#ffff" : "#fc7703")};
    a {
      color: ${(props) => (props.transform ? "#ffff" : "#fc7703")};
      font-size: inherit;
    }
  }

  hr {
    background-color: ${(props) => (props.transform ? "#ffff" : "#fc7703")};
  }
`;
