import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const CompetitorAnalyzer = () => {
  // This is the initial state of the DOM
  const initialData = {
    StoreType: "",
    Assortment: "",
    CompetitionDistance: "",
    OpenSinceMonth: "",
    OpenSinceYear: "",
    Promo2: "",
    Customers: "",
    Promo: "",
    Day: "",
    DayOfWeek: "",
    Month: "",
    Year: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  /**
   * function name CommonService
   * @params - "serviceLine" & "solution"
   * @return 4 methods
   */
  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "competitorAnalyzer",
  );

  const formHighChart = (data) => {
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {},
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Feature Importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][0][0],
              y: data[1][0][1],
            },
            {
              name: data[1][1][0],
              y: data[1][1][1],
            },
            {
              name: data[1][2][0],
              y: data[1][2][1],
            },
            {
              name: data[1][3][0],
              y: data[1][3][1],
            },
            {
              name: data[1][4][0],
              y: data[1][4][1],
            },
            {
              name: data[1][5][0],
              y: data[1][5][1],
            },
            {
              name: data[1][6][0],
              y: data[1][6][1],
            },
            {
              name: data[1][7][0],
              y: data[1][7][1],
            },
            {
              name: data[1][8][0],
              y: data[1][8][1],
            },
            {
              name: data[1][9][0],
              y: data[1][9][1],
            },
          ],
        },
      ],
    });
  };

  // This is to populate the data
  const showResult = () => {
    const NewDataSet = {
      StoreType: "3",
      Assortment: "1",
      CompetitionDistance: "1270",
      OpenSinceMonth: "9",
      OpenSinceYear: "2008",
      Promo2: "0",
      Customers: "555",
      Promo: "1",
      Day: "31",
      DayOfWeek: "4",
      Month: "7",
      Year: "2015",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      Month,
      Year,
      StoreType,
      Assortment,
      CompetitionDistance,
      OpenSinceMonth,
      OpenSinceYear,
      Promo2,
      Customers,
      Promo,
      Day,
      DayOfWeek,
    } = sampleData;

    const params = `${StoreType},${Assortment},${CompetitionDistance},${OpenSinceMonth},${OpenSinceYear},${Promo2},${Customers},${Promo},${Day},${DayOfWeek},${Month},${Year}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    /**
     * getQueryResult accepts 2 arguments
     * 1) API endpoint key ex: sample (for key refer APIList.js > commercialAnalytics > solutions > competitorAnalyzer )
     * 2)params( input field values separated by "," )
     */
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Field(s) are empty" });
    } else {
      setSpinner(true);

      getQueryResult("sample", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          formHighChart(res);
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Competitor Analyzer application intends to explore how the store
            sales get affected when a similar store opens nearby. These insights
            can be useful to perform sales forecasting for the pharmaceutical
            store. They may also prove to be helpful business insights for the
            retailer to decide on locations to set up a new store, and formulate
            a strategic plan to deal with situations when competition arises.
            <br />
            <br />
            The competitor analyzer is a tool to find competitors for
            pharmaceutical stores by analyzing the sales data, size of the
            store, and distance between the stores.
          </p>
          <p>
            <strong>The features of the sample used are listed below: </strong>
          </p>
          <List>
            <ListItem>
              <b> StoreType </b>
              {' '}
              - differentiates between 4 different store
              models: 1, 2, 3, 4
            </ListItem>
            <ListItem>
              <b>Customers</b>
              {' '}
              - the number of customers on a given day
            </ListItem>
            <ListItem>
              <b>Assortment</b>
              {' '}
              -describes an assortment level:
              <b>a</b>
              {' '}
              = basic,
              <b>b</b>
              {' '}
              = extra,
              <b>c</b>
              {' '}
              = extended
            </ListItem>
            <ListItem>
              <b>CompetitionDistance</b>
              {' '}
              - the distance in meters to the nearest
              competitor store
            </ListItem>
            <ListItem>
              <b>CompetitionOpenSince</b>
              [Month/Year] - gives the approximate year and month of the time
              the nearest competitor was opened
            </ListItem>
            <ListItem>
              <b>Promo</b>
              {' '}
              - indicates whether a store is running a promo on
              that day
            </ListItem>
            <ListItem>
              <b>Promo2</b>
              {' '}
              - a continuous and consecutive promotion for some
              stores:
              <b>0</b>
              {' '}
              = store is not participating,
              <b>1</b>
              {' '}
              = store is participating
            </ListItem>
          </List>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Competitor_Analyzer"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Competitor_Analyzer/data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Store Type"
                fullWidth
                onChange={handleInputs}
                name="StoreType"
                value={sampleData.StoreType}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Assortment"
                fullWidth
                onChange={handleInputs}
                name="Assortment"
                value={sampleData.Assortment}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Competition Distance"
                fullWidth
                onChange={handleInputs}
                name="CompetitionDistance"
                value={sampleData.CompetitionDistance}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Promo2"
                fullWidth
                onChange={handleInputs}
                name="Promo2"
                value={sampleData.Promo2}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Customers"
                fullWidth
                onChange={handleInputs}
                name="Customers"
                value={sampleData.Customers}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Year"
                fullWidth
                onChange={handleInputs}
                name="Year"
                value={sampleData.Year}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Competition Open Since Month"
                fullWidth
                onChange={handleInputs}
                name="OpenSinceMonth"
                value={sampleData.OpenSinceMonth}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="DayOfWeek"
                fullWidth
                onChange={handleInputs}
                name="Day Of Week"
                value={sampleData.DayOfWeek}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Day"
                fullWidth
                onChange={handleInputs}
                name="Day"
                value={sampleData.Day}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Promo"
                fullWidth
                onChange={handleInputs}
                name="Promo"
                value={sampleData.Promo}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Competition Open Since Year"
                fullWidth
                onChange={handleInputs}
                name="OpenSinceYear"
                value={sampleData.OpenSinceYear}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Month"
                fullWidth
                onChange={handleInputs}
                name="Month"
                value={sampleData.Month}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                Sample Data
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {`Based on current selection the store is estimated to make a revenue of $${resultData[0]}`}
            </Result>
          )}
          <Grid item xs={12}>
            <HighchartContainer id="container" display />
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(CompetitorAnalyzer);
