import React, { memo, useState } from "react";
import {
  Grid, TextField, InputAdornment, FormControl,
} from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";

const PredictingType2Diabetes = () => {
  const initialData = {
    albuminUrine: "",
    urineCreatinine: "",
    albuminCreatinineRatio: "",
    creatinine: "",
    glucoseRefrigeratedSerum: "",
    ironRefrigeratedSerum: "",
    lactateDehydrogenase: "",
    osmolality: "",
    redCellDistributionWidth: "",
    plateletCount: "",
    glycohemoglobin: "",
    volumeUrineCollection: "",
    urineFlowRate: "",
    vitaminB12: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("mlModels", "retrieveT2DPrediction");

  const showResult = () => {
    const NewDataSet = {
      albuminUrine: "4.3",
      urineCreatinine: "39.0",
      albuminCreatinineRatio: "11.03",
      creatinine: "1.210000",
      glucoseRefrigeratedSerum: "554.000000",
      ironRefrigeratedSerum: "58.000000",
      lactateDehydrogenase: "118.00000",
      osmolality: "296.000000",
      redCellDistributionWidth: "14.0",
      plateletCount: "204.0",
      glycohemoglobin: "13.900000",
      volumeUrineCollection: "87.0",
      urineFlowRate: "0.821000",
      vitaminB12: "524.000000",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      albuminUrine: "16.0",
      urineCreatinine: "76.0",
      albuminCreatinineRatio: "21.05",
      creatinine: "0.880172",
      glucoseRefrigeratedSerum: "102.290859",
      ironRefrigeratedSerum: "83.445534",
      lactateDehydrogenase: "126.80171",
      osmolality: "279.273615",
      redCellDistributionWidth: "13.7",
      plateletCount: "240.0",
      glycohemoglobin: "5.642556",
      volumeUrineCollection: "61.0",
      urineFlowRate: "0.575000",
      vitaminB12: "640.100263",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      albuminUrine,
      urineCreatinine,
      albuminCreatinineRatio,
      creatinine,
      glucoseRefrigeratedSerum,
      ironRefrigeratedSerum,
      lactateDehydrogenase,
      osmolality,
      redCellDistributionWidth,
      plateletCount,
      glycohemoglobin,
      volumeUrineCollection,
      urineFlowRate,
      vitaminB12,
    } = sampleData;

    const params = `${albuminUrine},${urineCreatinine},${albuminCreatinineRatio},${creatinine},${glucoseRefrigeratedSerum},${ironRefrigeratedSerum},${lactateDehydrogenase},${osmolality},${redCellDistributionWidth},${plateletCount},${glycohemoglobin},${volumeUrineCollection},${urineFlowRate},${vitaminB12},1`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          setResultData(res);
          const params = [];
          const percentages = res.percentage[0];
          for (const propertyName in percentages) {
            params.push({
              name: propertyName,
              y: percentages[propertyName],
            });
          }

          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color:
                      (Highcharts.theme
                        && Highcharts.theme.contrastTextColor)
                      || "black",
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <section>
            <p>
              Type 2 Diabetes is a chronic disease that is a major health
              concern across the globe. The number of people with diabetes has
              risen drastically in the last few decades. This disease is not
              only a huge financial burden but also a major cause of blindness,
              kidney failure, heart attacks, stroke, and lower limb amputation.
              The WHO projects that diabetes will be the seventh leading cause
              of death in the year 2030.
            </p>

            <p>
              This application has been built with an aim to predict the
              presence of Type 2 Diabetes by analyzing the laboratory results
              captured for a particular patient. Medication and laboratory data
              from the National Health and Nutrition Examination Survey (NHANES)
              dataset available on the vLife™ platform has been used to develop
              this application. The medications data contains diagnosis details
              for a set of patients, out of which those patients who are
              diabetic are filtered out. The labs data includes laboratory
              measures, which will act as the predictors. This data is then used
              to train an AdaBoost model to predict whether a patient has Type 2
              Diabetes or not, as well as rank the most predictive features.
            </p>
          </section>
          <br />
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Predicting%20T2D%20patients/Type2Diabetes.ipynb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting%20T2D%20patients/Data"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>

            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <br />
          <section>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Albumin, urine"
                          name="albuminUrine"
                          value={sampleData.albuminUrine}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                ug/mL
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Urinary creatinine"
                          name="urineCreatinine"
                          value={sampleData.urineCreatinine}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                mg/dL
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Albumin creatinine ratio"
                          name="albuminCreatinineRatio"
                          value={sampleData.albuminCreatinineRatio}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                mg/g
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Creatinine"
                          name="creatinine"
                          value={sampleData.creatinine}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                mg/dL
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Glucose, refrigerated serum"
                          name="glucoseRefrigeratedSerum"
                          value={sampleData.glucoseRefrigeratedSerum}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                mg/dL
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Iron, refrigerated serum"
                          name="ironRefrigeratedSerum"
                          value={sampleData.ironRefrigeratedSerum}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                ug/dL
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Lactate dehydrogenase"
                          name="lactateDehydrogenase"
                          value={sampleData.lactateDehydrogenase}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                U/L
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Osmolality"
                          name="osmolality"
                          value={sampleData.osmolality}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                mmol/Kg
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Red cell distribution width"
                          name="redCellDistributionWidth"
                          value={sampleData.redCellDistributionWidth}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Platelet count"
                          name="plateletCount"
                          value={sampleData.plateletCount}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                1000 cells/uL
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Glycohemoglobin"
                          name="glycohemoglobin"
                          value={sampleData.glycohemoglobin}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Volume of urine collection #1"
                          name="volumeUrineCollection"
                          value={sampleData.volumeUrineCollection}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mL</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Urine #1 Flow Rate"
                          name="urineFlowRate"
                          value={sampleData.urineFlowRate}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                mL/min
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Vitamin B12"
                          name="vitaminB12"
                          value={sampleData.vitaminB12}
                          onChange={onChangeFields}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                pg/mL
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />

                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <StyledDiv align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                    </StyledDiv>
                  </BorderGray>
                </section>
                <br />
                <section>
                  <StyledDiv align="right">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </StyledDiv>
                </section>
              </Grid>
              <Grid item xs={12} md={6}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`Type2 Diabetic Patient:  ${resultData.isDiabetic}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer
                          id="prediction-chart"
                          display
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictingType2Diabetes);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
  `;
const StyledDiv = styled.div`
  text-align: right;
  @media only screen and (max-width: 959px) {
    text-align: center;
  }
`;
