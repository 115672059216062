import styled from "styled-components";
import { Button, TextField, Backdrop } from "@material-ui/core";

/* Styled Elements */

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => (props.color ? props.color : "#37474f")};
  text-align: justify;

  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

export const Heading2 = styled.h2`
  font-size: 30px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2941b7;

  @media only screen and (max-width: 900px) {
    font-size: 22px;
    margin: 30px 0px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 16px;
    margin: 20px 0px;
  }
`;

export const Heading3 = styled.h3`
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2941b7;

  @media only screen and (max-width: 900px) {
    font-size: 22px;
    margin: 30px 0px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 16px;
    margin: 20px 0px;
  }
`;

export const Heading4 = styled.h4`
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2941b7;

  @media only screen and (max-width: 900px) {
    font-size: 22px;
    margin: 30px 0px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 16px;
    margin: 20px 0px;
  }
`;

export const List = styled.ul`
  text-align: justify;
  @media only screen and (max-width: 450px) {
    margin-left: -16px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: -20px;
  }
`;
export const HighchartContainer = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;
export const ListItem = styled.li`
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #37474f;
  padding: 4px;
  text-align: justify;

  @media only screen and (max-width: 900px) {
    font-size: 14px;
    line-height: 1.3;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
    padding: 8px;
    line-height: 1.2;
  }
`;

/* Styled Containers */

export const Layout = styled.div`
  overflow: auto;
  min-height: 100vh;
  background-color: #f8f8f8;
  overflow-y: hidden;
`;

export const PageContainer = styled.div`
  margin: ${(props) => !props.hideMargin && "25px 50px"};

  @media only screen and (min-width: 1600px) {
    width: 1600px;
    margin: ${(props) => !props.hideMargin && "auto"};
  }

  @media only screen and (max-width: 600px) {
    margin: ${(props) => !props.hideMargin && "25px"};
  }
`;

export const PageTitle = styled.h1`
  color: ${(props) => (props.color ? props.color : "#2941b7")};
  font-size: 36px;
  font-weight: normal;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  margin: 60px 0 20px 0;
  &:after {
    content: "";
    height: 3px;
    width: -webkit-fill-available;
    bottom: -10px;
    left: 0px;
    background-color: #fc7703;
    position: absolute;
  }
  @media only screen and (max-width: 900px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 18px;
  }
`;
export const DemoContainer = styled.div`
  margin: 15px 0px;
  padding: 20px;
  background-color: #ffffff;
  height: auto;
  text-align: center;
  box-shadow: 0 8px 20px -12px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 10px 30px -12.125px rgba(0, 0, 0, 0.1);
  }
  border-radius: 4px;
`;
export const StyledButton = styled(Button)`
  a {
    color: #2941b7;
    text-decoration: none;
    color: ${(props) => (props.textColor ? props.textColor : props.color)};
  }
  &.MuiButton-root {
    margin: ${(props) => (props.margin ? props.margin : "5px")};
    background-color: ${(props) => (props.btnColor ? props.btnColor : props.color)};
    color: ${(props) => (props.txtColor ? props.txtColor : props.color)};
      color: ${(props) => (props.textColor ? props.textColor : props.color)};
    @media only screen and (max-width: 900px) {
      margin: 3px;
      padding: 8px 18px;
      font-size: 12px;
    }
    @media only screen and (max-width: 450px) {
      margin: 3px;
      padding: 6px 15px;
      font-size: 10px;
    }
  }
`;
export const SolutionContainer = styled.div`
  margin: 25px 0px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    img {
      border-radius: 4px;
    }
  @media only screen and (max-width: 900px) {
    img {
      width: 100%;
    }
  }
 
`;

export const LinkContainer = styled.div`
  margin: 20px 0px;
`;
export const LogoImage = styled.img`
  height: 30px;
  padding: 15px 0px;
`;
export const AnchorLink = styled.a`
  padding: 10px 12px 10px 16px;
  border-radius: 4px;
  border: solid 2px #fff;
  font-family: MuseoSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000;
  width: 150px;
  margin: 150px auto;
  text-decoration: none;
  display: block;
  text-align: center;
`;
export const Result = styled.div`
  background: rgb(224, 224, 224);
  padding: 0.388889rem 0.555556rem 0.277778rem 0.777778rem;
  border-left: 0.222222rem solid #2941b7;
  border-radius: 0.166667rem;
  text-align: left;
  margin: 10px 0px;
  strong {
    margin-bottom: 10px;
    display: block;
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
export const CustomTextField = styled(TextField)`
color: #ffffff;
width: 100%;
border: 0;
/* margin: 0px 85px 11px 10px; */
padding: 0;
position: relative;
min-width: 0;
flex-direction: column;
vertical-align: top;
text-align: left;

& label.MuiInputLabel-root {
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 10px;
  }
}
& .MuiInput-underline:before {
  border-bottom-color: #ffffff;
}
& .MuiInputBase-input.MuiInput-input {
  color: #fc7703;
  @media only screen and (max-width: 900px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 15px;
  }
}
.label.Mui-focused {
  color: #fc7703;
}
& .MuiInput-underline:after {
  border-bottom-color: #fc7703;
}
& .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: #fc7703;
}
@media only screen and (max-width: 960px) {
  width: 50%;
}
@media only screen and (max-width: 550px) {
  width: 90%;
}
`;
export const StyledBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
`;
