import React, { useCallback } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import styled from "styled-components";

const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};
export default function FormGenerator({
  fieldList, fields = {}, handleChange, apiDropDownData = null,
}) {
  const debounceHandler = useCallback(debounce(handleChange), []);
  const getInputComponent = (inputObj) => {
    switch (inputObj.type) {
      case "text":
        return (
          <StyledInput
            name={inputObj.name}
            label={inputObj.label}
            required={inputObj.required}
            type={inputObj.type}
            variant="outlined"
            onChange={handleChange}
            value={fields[inputObj.name]}
            error={fields[`error_${inputObj.name}`]}
            helperText={fields[`error_${inputObj.name}`]}
          />
        );
      case "select":
        return (
          <StyledInput
            name={inputObj.name}
            required={inputObj.required}
            label={inputObj.label}
            type={inputObj.type}
            variant="outlined"
            onChange={handleChange}
            select
            error={fields[`error_${inputObj.name}`]}
            value={fields[inputObj.name]}
            id={inputObj.validationKey}
            helperText={fields[`error_${inputObj.name}`] && "Required"}
          >
            {inputObj?.dropdownList?.map((dropdown) => (
              <MenuItem key={dropdown.value} value={dropdown.value}>
                {dropdown.label}
              </MenuItem>
            ))}

          </StyledInput>
        );

      case "api-select":
        return (
          <StyledInput
            name={inputObj.name}
            required={inputObj.required}
            label={inputObj.label}
            type={inputObj.type}
            variant="outlined"
            onChange={handleChange}
            select
            error={fields[`error_${inputObj.name}`]}
            value={fields[inputObj.name]}
            id={inputObj.validationKey}
            helperText={fields[`error_${inputObj.name}`] && "Required"}
          >
            {apiDropDownData?.[inputObj.apiDropDownKey]?.map((dropdown) => (
              <MenuItem key={dropdown.value} value={dropdown.value}>
                {dropdown.label}
              </MenuItem>
            ))}

          </StyledInput>
        );
      case "file":
        return (
          <StyledInput
            name={inputObj.name}
            required={inputObj.required}
            type={inputObj.type}
            variant="outlined"
            onChange={handleChange}
            error={fields[`error_${inputObj.name}`]}
            id={inputObj.validationKey}
            helperText={fields[`error_${inputObj.name}`] && "Required"}
          />
        );
      case "multiline":
        return (
          <StyledInput
            name={inputObj.name}
            label={inputObj.label}
            type={inputObj.type}
            variant="outlined"
            onChange={handleChange}
            required={inputObj.required}
            multiline
            rows={inputObj.rows}
            value={fields[inputObj.name]}
            error={fields[`error_${inputObj.name}`]}
            helperText={
            fields[`error_${inputObj.name}`] && "Data is Required"
          }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <FormContainer>
      {fieldList
        && fieldList.map((inputObj) => (
          getInputComponent(inputObj)
        ))}
    </FormContainer>
  );
}

const FormContainer = styled.section`
  padding: 10px;
  margin: 10px;
`;
const StyledInput = styled(TextField)`
  &.MuiTextField-root{
    margin-top: 10px;
    width: 100%;
  }
`;
