import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";

const ActiveLearner = () => {
  return (

    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Active learning makes it possible to build applications using a small set of labeled data, and enables enterprises to leverage their large pools of unlabeled data.
          </p>
          <p>
            Active learning takes advantage of the collaboration between humans and machines to smartly select a small subset of datapoints for which to obtain labels. It is an iterative process, and ideally access is available to some initial labels to start. These initial labels allow a human to build a baseline machine learning model, and use it to predict outputs for all the unlabeled datapoints. The model then looks through all its predictions, flags the one with which it has the most difficulty, and requests a label for it. A human steps in to provide the label, and the newly labeled data is combined with the initial labeled data to improve the model. Model performance is recorded, and the process repeats.
          </p>
          <p>
            The prototype on Learning with Limited Labeled Data is called Active Learner. It is a tool that sheds light on and provides intuition for how and why active learning works. The prototype allows one to visualize the process of active learning over different types of datasets and selection strategies.
          </p>
          <p><strong>Application : </strong></p>
          <p>Active Learning offers one way for enterprises to leverage their large pools of unlabeled data for building new products. It can be used in many healthcare problems where having labeled data is a challenge and many a times require considerable human effort.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://activelearner.fastforwardlabs.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://blog.fastforwardlabs.com/2019/04/02/a-guide-to-learning-with-limited-labeled-data.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read more
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text='Demo'>
        <DemoContainer>
          <center>
            <iframe
              src='https://activelearner.fastforwardlabs.com/'
              width='100%'
              height='650'
            />
          </center>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
}

export default memo(ActiveLearner);
