import React, { useState, memo } from "react";
import { Grid, TextField } from "@material-ui/core";
import styled from "styled-components";

// import local components
import { StyledButton } from "../../styles/common";
import CommonService from "../../utils/services/CommonService";
import Spinner from "./loading/Spinner";
import Upload from "./Upload";
import SolutionContainer from "./SolutionContainerWrapper";

const ImageAnalysisInput = (props) => {
  const [Img1] = useState(props.img1);
  const [Img2] = useState(props.img2);
  const [Img3] = useState(props.img3);
  const [IsStatic] = useState(props.isStatic);
  const [Sample1url] = useState(props.sample1url);
  const [Sample2url] = useState(props.sample2url);
  const [Video] = useState(props.video);
  const [helperTextForUpload] = useState(props.helperTextForUpload);
  const [, setResult] = useState(props.result);
  const [spinner, setSpinner] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const [notification, setNotification] = useState("");

  const { getQueryResult, uploadFile } = CommonService(...props.url);

  const singleSample = () => {
    getResult("");
  };

  const sample1 = () => {
    const sample = Sample1url || "sample_image1";
    getResult(sample);
  };

  const sample2 = () => {
    const sample = Sample2url || "sample_image2";
    getResult(sample);
  };

  const sample3 = () => {
    const sample = Sampleurl || "sample_image3";
    getResult(sample);
  };
  const getResult = (sample) => {
    props.result("");
    setSpinner(true);
    if (IsStatic) {
      setTimeout(() => {
        setSpinner(false);
        setResult(true, true);
      }, 5000);
      return;
    }
    getQueryResult("output", sample)
      .then((res) => {
        console.log(res);
        setSpinner(false);
        props.result(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);

        setNotification({ open: "error", message: err.message });
      });
  };

  const getuploadResult = () => {
    props.result("");
    setSpinner(true);
    uploadFile("uploadResult", uploadedImage)
      .then((res) => {
        setSpinner(false);
        props.result(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const setImage = (e) => {
    e.preventDefault();
    setUploadedImage(e.target.files[0]);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <section>
        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={Img2 ? 2 : 4} alignItems="center">
            {Video ? (
              <video height="280px" width="400px" controls>
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              Img1 && (
                <Image
                  src={`../../assets/images/app/${Img1}`}
                  width="150x"
                  height="150px"
                  alt=""
                />
              )
            )}
            <br />
            <StyledMarginButton
              variant="contained"
              color="primary"
              onClick={Img2 ? sample1 : singleSample}
            >
              {Img2 ? "Sample1" : "Sample"}
            </StyledMarginButton>
          </Grid>
          {Img2 && (
            <Grid item xs={12} md={2} alignItems="center">
              <Image
                src={`../../assets/images/app/${Img2}`}
                width="150px"
                height="150px"
                alt=""
              />
              <br />
              <StyledMarginButton
                variant="contained"
                color="primary"
                onClick={sample2}
              >
                Sample2
              </StyledMarginButton>
            </Grid>
          )}
          {Img3 && (
            <Grid item xs={12} md={2} alignItems="center">
              <Image src={Img3} width="150px" height="150px" alt="" />
              <br />
              <StyledMarginButton
                variant="contained"
                color="primary"
                onClick={sample3}
              >
                Sample3
              </StyledMarginButton>
            </Grid>
          )}
          <Grid item xs={12} md={1}>
            <h3>OR</h3>
          </Grid>
          <Grid item xs={12} md={5} alignItems="center">
            <TextField
              type="file"
              color="primary"
              onChange={setImage}
              helperText={helperTextForUpload && helperTextForUpload}
            />
            <StyledMarginButton
              variant="contained"
              color="primary"
              onClick={getuploadResult}
            >
              Execute
            </StyledMarginButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {spinner && <Spinner text="Loading..." />}
        </Grid>
      </section>
    </SolutionContainer>
  );
};
export default memo(ImageAnalysisInput);

const StyledMarginButton = styled(StyledButton)`
  margin-left: 10ox;
  margin-right: 10px;
`;
const Image = styled.img`
  @media only screen and (max-width: 1100px) {
    max-width: 130px;
    max-height: 130px;
  }
`;
