import React, { memo, useState } from "react";
import {
  Grid,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";

// import local components
import Collapse from "../../common/Collapse";
import ImageAnalysisInput from "../../common/ImageAnalysisInput";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import bowelPreparation_sol1 from "../../../assets/images/app/bowelPreparation_sol1.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const BowelPreparation = () => {
  const getResponse = (response) => {
    const res = response;
    setResponse(res);
  };

  const [textResponse, setResponse] = useState(false);

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <Grid container xs={12} direction="row" alignItems="center">
            <p>
              The large bowel, also named colon and large intestine, is the
              lower part of the human gastrointestinal tract. It may be affected
              by severe diseases including cancer and chronic inflammations.
              Bowel cancer (colorectal cancer) is currently the third most
              common cancer worldwide, accounting for nearly 1.4 million new
              cases and 700 000 cancer deaths in 2012. The current gold standard
              for diagnostic and screening investigations of the large bowel is
              colonoscopy. This is a real-time video examination of the inside
              of the large bowel by use of a digital high definition endoscope.
              Such endoscopic examinations are resource demanding and require
              both expensive technical equipment and trained personnel.
              Furthermore, the efficiency of colonoscopy depends on sufficient
              bowel cleansing to visualize the gastric mucosa (a membrane that
              lines the GI tract), achieved by use of oral laxatives (substances
              that loosen stools and increase bowel movements) administrated
              prior to the procedure.
            </p>
            <Grid item xs={12} md={9}>
              <p>
                The quality of bowel preparation has shown to influence both the
                colonoscopy completion rate and detection of possible precursors
                of cancer (e.g., adenomas, which are the benign tumor of
                epithelial tissue). Adenoma detection rate (ADR), that is
                inversely associated with a patient's risk of developing
                colorectal cancer, has been proven to be dependent on quality of
                bowel preparation. Therefore, the degree of bowel preparation is
                considered to be a reliable quality measure for colonoscopy.
                Quality of bowel preparation may also influence decisions on
                screening and follow-up intervals, since low-quality bowel
                preparation requires repeated colonoscopy. An accurate
                description of the bowel cleanliness is therefore needed.
                Despite the use of reliable and validated bowel preparation
                scales, the grading may vary from one doctor to another.
              </p>
              <p>
                Traditionally, the bowel preparation quality has been
                categorized as poor, adequate or good. Such classification of
                bowel cleanliness often lacks clear definitions, and the
                judgement on quality tends to be subjective. This may result in
                significant inter-observer variation. To minimize the
                inter-endoscopist variation, new score-based methods of
                assessing bowel cleanliness have been introduced - the Boston
                bowel preparation scale (BBPS). The Boston bowel preparation
                scale (BBPS) uses only a four-point scoring system. The
                segmental scores range from 0 to 3, where 0 is worse and 3 is
                the best quality of the bowel preparation. In this, application
                annotated and verified frames of images from videos are trained
                to predict the BBPS quality score of the bowel for endoscopy.
              </p>
            </Grid>
            <Grid item xs={12} md={3}>
              <Image2 width="100%" src={bowelPreparation_sol1} />
            </Grid>
            <Grid container>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHead>Score</StyledTableHead>
                    <StyledTableHead>Description</StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">0</TableCell>
                    <TableCell align="left">
                      Unprepared colon segment with mucosa is not seen because
                      of solid stool that cannot be cleared
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">1</TableCell>
                    <TableCell align="left">
                      Portion of mucosa of the colon segment seen, but other
                      areas of the colon segment are not well seen because of
                      staining, residual stool, and/or opaque liquid
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">2</TableCell>
                    <TableCell align="left">
                      Minor amount of residual staining, small fragments of
                      stool, and/or opaque liquid but mucosa of the colon
                      segment is not seen well
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">3</TableCell>
                    <TableCell align="left">
                      Entire mucosa of the colon segment seen well, with no
                      residual staining, small fragments of stool, and/or opaque
                      liquid
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <p>
              In this application , Nerthus, a dataset containing videos from
              inside the gastrointestinal (GI) tract, showing different degrees
              of bowel cleansing, is used. The videos are annotated by one or
              more medical experts.
            </p>
            <p>
              <b>Application :</b>
              {' '}
              This application may help researchers to
              develop systems that improve the health-care system in the context
              of the GI tract endoscopic diagnosis. Adequate bowel preparation
              (cleansing) is required to achieve high quality colonoscopy
              examinations. It may help making systems that automatically and
              consistently evaluate the quality of bowel cleansing.
            </p>
          </Grid>

          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://datasets.simula.no/nerthus/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <PresignedS3Link
                    href="bowelquality/Nerthus_Colonoscopy-Bowel_Preparation_Prediction.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </PresignedS3Link>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            img1="bowelPreparation_sample1.webp"
            img2="bowelPreparation_sample2.webp"
            url={["z1dApps", "bowelPreparation"]}
            sample1url="sample1"
            sample2url="sample2"
            result={getResponse}
          />
          {textResponse && (
            <Result>
              <strong>Result:</strong>
              {textResponse}
            </Result>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(BowelPreparation);
const Image1 = styled.img`
  @media only screen and (min-width: 900px) {
    width: 80%;
    height: 250px;
  }
`;

const Image2 = styled.img`
  @media only screen and (min-width: 960px) {
    padding 10px;
  }
`;

export const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
