import React, { memo, useState } from "react";
import {
  Grid, TextField, MenuItem, FormControl,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import styled from "styled-components";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";

// images
import deepScap_sol1 from "../../../assets/images/app/deepScap_sol1.webp";
import deepScap_sol2 from "../../../assets/images/app/deepScap_sol2.webp";

const DeepScap = () => {
  // This is the initial state of the DOM
  const initialData = {
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
    seventh: "",
    eight: "",
    nineth: "",
    tenth: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("patientEngagement", "deepScap");

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Prediction of Anatomical features of the scapulae from the projections on the 10 first principal",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][0][0],
              y: data[1][0][1],
            },
            {
              name: data[1][1][0],
              y: data[1][1][1],
            },
            {
              name: data[1][2][0],
              y: data[1][2][1],
            },
            {
              name: data[1][3][0],
              y: data[1][3][1],
            },
            {
              name: data[1][4][0],
              y: data[1][4][1],
            },
            {
              name: data[1][5][0],
              y: data[1][5][1],
            },
            {
              name: data[1][6][0],
              y: data[1][6][1],
            },
            {
              name: data[1][7][0],
              y: data[1][7][1],
            },
            {
              name: data[1][8][0],
              y: data[1][8][1],
            },
            {
              name: data[1][9][0],
              y: data[1][9][1],
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      first: "-2.123134",
      second: "1.221635",
      third: "0.711593",
      fourth: "0.250792",
      fifth: "0.317490",
      sixth: "1.235764",
      seventh: "-0.572702",
      eight: "0.796309",
      nineth: "-0.576095",
      tenth: "-0.873190",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      first,
      second,
      third,
      fourth,
      fifth,
      sixth,
      seventh,
      eight,
      nineth,
      tenth,
    } = sampleData;

    const params = `${first},${second},${third},${fourth},${fifth},${sixth},${seventh},${eight},${nineth},${tenth}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          console.log(res);
          formHighChart(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The POC predicts the anatomical features on the Scapulae bone by
            taking projections on the principal components of the Statistical
            Shape Model
          </p>
          <h4>Factors affecting the predictions (Input variables explained)</h4>
          <p>
            <b>Projected Principal Component Analysis (Projected-PCA)</b>
            ,
            employs principal component analysis to the projected (smoothed)
            data matrix onto a given linear space spanned by covariates. When it
            applies to high-dimensional factor analysis, the projection removes
            noise components. We show that the unobserved latent factors can be
            more accurately estimated than the conventional PCA if the
            projection is genuine, or more precisely, when the factor loading
            matrices are related to the projected linear space.
          </p>
          <p>
            For more information on Statistical Shape models, how to calculate
            projections and principal components, refer the below link
          </p>
          <p>
            <a
              href="https://graphics.stanford.edu/courses/cs164-09-spring/Handouts/paper_shape_spaces_imm403.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://graphics.stanford.edu/courses/cs164-09-spring/Handouts/paper_shape_spaces_imm403.pdf
            </a>
          </p>
          <p>
            The Algorithm also shows the probability distribution of all the
            input variables over the predictions in a pie chart
          </p>
          <p>
            <b>POC details</b>
            {' '}
            <br />
            In anatomy, the scapula (plural scapulae or scapulas), also known as
            the shoulder bone, shoulder blade, wing bone or blade bone, is the
            bone that connects the humerus (upper arm bone) with the clavicle
            (collar bone).
          </p>
          <p>A scapulae bone looks like this</p>
          <img width="40%" height="200px" src={deepScap_sol2} />
          <p>The Analtomical Features of the scapula bone are</p>
          <p>
            CSA - The critical shoulder angle (CSA) is the angle created between
            the superior and inferior bone margins of the glenoid and the most
            inferolateral border of the acromion.
          </p>
          <p>
            Tilt - Scapular tilting occurs anytime that we raise or lower our
            arm in front of us. The forward tilt is called anterior tilting of
            the shoulder blade and the backward tilt is called posterior tilting
            of the shoulder blade
          </p>
          <p>
            Glen Width and Length - Glenoid cavity or fossa (Cavitas
            glen-oidalis) is a slight concavity at the lateral angle. It forms a
            shallow socket for the articulation of the head of the humerus.
            {" "}
          </p>
          <h5>
            The frequency distribution of these features in the usecase are
            given below
          </h5>
          <img width="80%" height="350px" src={deepScap_sol1} />
          <br />
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/DeepScap"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/iham97/deepscapulassm"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid container direction="row" justify="center" spacing={1}>
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="First Projection"
                    name="first"
                    value={sampleData.first}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Fifth Projection"
                    name="fifth"
                    value={sampleData.fifth}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Nineth Projection"
                    name="nineth"
                    value={sampleData.nineth}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Second Projection"
                    name="second"
                    value={sampleData.second}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Sixth Projection"
                    name="sixth"
                    value={sampleData.sixth}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Tenth Projection"
                    name="tenth"
                    value={sampleData.tenth}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Third Projection"
                    name="third"
                    value={sampleData.third}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Seventh Projection"
                    name="seventh"
                    value={sampleData.seventh}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Fourth Projection"
                    name="fourth"
                    value={sampleData.fourth}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Eight Projection"
                    name="eight"
                    value={sampleData.eight}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12}>
                <br />
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showResult}
                >
                  Sample Data
                </StyledButton>

                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getResult}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
          </section>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${resultData[0]}`}
              </Result>
              <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                  <HighchartContainer id="container_1" display />
                </Grid>
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(DeepScap);
const StyledFormControl = styled(FormControl)`
  @media only screen and (min-width: 900px) {
    width: 75%;
  }
`;
