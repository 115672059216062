import React, { memo, useState } from "react";
import { TextField, Grid } from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// image components
import feasibilityanalysis_sol1 from "../../../assets/images/app/feasibilityanalysis_sol1.webp";

const FeasibilityAnalysis = () => {
  const initialData = {
    title: "",
    description: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);

  const { getQueryResult } = CommonService(
    "clinicalTrials", "feasibilityAnalysis",
  );

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  const showSample1 = () => {
    SetResultData('');
    const NewDataSet = {
      title: "Relaxation and guided imagery program in patients with breast cancer undergoing radiotherapy is not associated with neuroimmunomodulatory effects.",
      description: "OBJECTIVE: Treatment of breast cancer is usually associated with significant psychological stress. In this study, we examined the effects of relaxation and visualization therapy (RVT) on psychological distress, cortisol levels, and immunological parameters of breast cancer patients undergoing radiotherapy. METHODS: Participants were randomly assigned to either the experimental (n=20) who underwent group RVT for 24 consecutive days or control group (n=14) who were on radiotherapy only. Psychological scores (stress, anxiety, and depression) were measured by structured clinical interviews. Salivary cortisol was assessed along the day. Lymphocytes were isolated and cultured to measure T-cell proliferation and sensitivity to glucocorticoids (GCs). RESULTS: RVT was effective to reduce stress, anxiety, and depression scores (all P<.05). However, cortisol levels as well as proliferation remained unchanged following RVT. Although T cells of experimental group were more sensitive to GCs than cells of controls at baseline, no changes were noted following RVT. Cortisol levels were positively correlated to anxiety and depression scores and inversely correlated to T-cell proliferation and sensitivity to GCs. CONCLUSION: We conclude that the psychological intervention was capable to attenuate the emotional distress presented during radiotherapy treatment. A longer RVT or worse psychological morbidity at baseline may be necessary to translate psychological into biological changes.",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    SetResultData('');
    const NewDataSet = {
      title: "Effect of age and radiation dose on local control after breast conserving treatment: EORTC trial 22881-10882.",
      description: "PURPOSE: To determine whether the effect of an additional boost radiation after breast conservative therapy (BCT) on local control depends on age and evaluate the impact of a treatment policy with a threshold for age. PATIENTS AND METHODS: We used data from EORTC 22881-10882 trial, with median follow-up of 77.4 months. Patients receiving BCT and 50Gy whole breast irradiation were randomized to no boost and 16Gy boost (N=5318). RESULTS: In univariate analysis, a boost reduced local failure by a factor of 2 (P<0.0001). Multivariate analysis showed local control increased with age (P=0.0003). There was no evidence that the relative effect of a boost on local control depends on age (P=0.97) However in younger patients the 5-year local failure was higher, therefore the absolute reduction was greater. If the threshold-age for boost treatment were set at 40 years, 8.4% of the study population would receive a boost, resulting in a 5-year local failure of 6.1% in the study population. Changing the threshold-age to 60 years, 67% of the study population would receive a boost and the 5-year local failure would be reduced to 4.4%. CONCLUSIONS: In younger patients a boost dose resulted in a greater absolute reduction of local failure. The relative risk reduction was however similar for all ages. Applying a treatment policy with a threshold-age of 60 would result in 0.6% increase in local failure in the total study population, while sparing the boost to 1/3 of the patients.",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      title,
      description,
    } = sampleData;

    const params = `${title},"&userValue[]=",${description}`;

    SetResultData('');
    setSpinner(true);

    getQueryResult("sample", params)
      .then((res) => {
        setSpinner(false);
        SetResultData(res.result);
      })
      .catch((err) => {
        setSpinner(false);
      });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Clinical trials testing new treatments are divided into different stages, called phases. The earliest
            phase trials may look at whether a drug is safe or the side effects it causes. Later phase trials aim
            to test whether a new treatment is better than existing treatments.
            {' '}
          </p>
          <p>
            There are 3 main phases of clinical trials – phases 1 to 3. Phase 1 trials are the earliest phase
            trials and phase 3 are later phase trials. Some trials have an earlier stage called phase 0, and
            there are some phase 4 trials done after a drug has been licensed.
            {' '}
          </p>
          <center>
            <Grid item xs={8} sm={8} md={8}>
              <img src={feasibilityanalysis_sol1} />
            </Grid>
          </center>
          <p>
            Phase 1 trials are usually the earliest trials of drugs in people. These studies aim to find out if a
            drug behaves in the way researchers expect it to from their laboratory studies.
            {' '}
          </p>
          <p>
            Phase 0 studies usually only involve a small number of people, and they only have a very small
            dose of a drug. The dose of the drug is too small to treat cancer, but the person is also less
            likely to have any side effects.
          </p>
          <p>In general terms, clinical trial feasibility is a process of evaluating the possibility of conducting a particular clinical program / trial in a particular geographical region with the overall objective of optimum project completion in terms of timelines, targets and cost. Conducting clinical trial feasibility is one of the first steps in clinical trial conduct. This process includes assessing internal and environmental capacity, alignment of the clinical trial in terms of study design, dose of investigational product, comparator, patient type, with the local environment and assessing potential of conducting clinical trial in a specific country. A robust feasibility also ensures a realistic assessment and capability to conduct the clinical trial. For local affiliates of pharmaceutical organizations, and contract research organizations, this is a precursor to study placement and influences the decision of study placement. </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Clinical%20Trial%20feasibility%20analyzer/data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Clinical%20Trial%20feasibility%20analyzer"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3146075/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Research paper
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12}>
              <br />
              <strong>Please provide the following data inputs</strong>
              <br />
              {' '}
              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                rows="8"
                multiline
                variant="outlined"
                name="title"
                fullWidth
                onChange={handleInputs}
                label="Title of Clinical Trial"
                value={sampleData.title}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                rows="8"
                multiline
                variant="outlined"
                name="description"
                onChange={handleInputs}
                fullWidth
                label="Description of Clinical trial"
                value={sampleData.description}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample1"
                onClick={showSample1}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample2"
                onClick={showSample2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                btnColor="#023e8a"
                color="primary"
                onClick={getResult}
                endIcon={<ArrowForwardIcon />}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}

        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(FeasibilityAnalysis);
