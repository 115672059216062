import React, { memo, useState, useEffect } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setInputData } from '../../../../../../store/actions/vnet';
import { StyledInput } from '../../InputForm';
import { StyledFormControl } from '../Dataviz/ScatterPlot';
import { List, ListItem } from '../../../../../../styles/common';
import { validateForm, isObjKeyhasval } from '../../util';
import { subFeatureData } from '../../constants';
import InfoToolTip from '../../components/InfoToolTip';

export const KNNForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector(
    (state) => state.vnet,
  );
  const [disableModelInfo, setDisableModelInfo] = useState(isCanvasClicked);
  const [dropdownList, setdropdownList] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [isDisabled, setInputDisable] = useState('Default');
  const { initialData = null } = subFeatureData.K_Nearest_Neighbors;

  const validationData = {
    n_neighbors: { minNum: 0, onlyNum: true },
    leaf_size: { minNum: 0, onlyNum: true },
    p: { minNum: 0, onlyNum: true },
    n_jobs: { minNum: 0, onlyNum: true },
  };

  const solverListData = {
    weights: ['uniform', 'distance'],
    algorithm: ['auto', 'ball_tree', 'kd_tree', 'brute'],
  };

  const handleFieldError = (errData) => {
    setFieldError({
      ...fieldError,
      ...errData,
    });
  };

  const { validateOnBlur } = validateForm(validationData, handleFieldError);
  const dispatch = useDispatch();

  const getCheckboxVal = (checked) => (checked ? 'True' : 'False');

  const handleOnChange = (e) => {
    console.log(e);
    const { value, name, checked = false } = e.target;
    const dataId = e.currentTarget.dataset.id;

    switch (dataId) {
      case 'target':
        dispatch(
          setInputData({
            ...userInputData,
            target: value,
          }),
        );
        break;
      case 'model_detail':
        dispatch(
          setInputData({
            ...userInputData,
            ModelDetail: {
              ...userInputData.ModelDetail,
              [name]: value,
            },
          }),
        );
        break;
      case 'model_param':
        dispatch(
          setInputData({
            ...userInputData,
            model_param: {
              ...userInputData.model_param,
              [name]:
                ['warm_start', 'fit_intercept'].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        if (name == 'penalty') {
          setSolverList(solverListData[value]);
        }
        break;
      case 'split_param':
        dispatch(
          setInputData({
            ...userInputData,
            split_param: {
              ...userInputData.split_param,
              [name]:
                ['shuffle'].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        break;
      case 'isDisabled':
        setInputDisable(value);
        if (value == 'Default') {
          dispatch(
            setInputData({
              ...userInputData,
              ...defaultParams,
            }),
          );
        }
        break;
      case 'isEditable':
        if (checked) {
          if (
            confirm(
              `Editing the existing model details will create a new model run and version. Do you want to continue ?`,
            )
          ) {
            setDisableModelInfo(false);
            setIsEditable(checked);
          }
        } else {
          setDisableModelInfo(true);
          setIsEditable(checked);
        }
        break;
      default:
        break;
    }
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        columns: typeof value === 'string' ? value.split(',') : value,
      }),
    );
    if (fieldError.columns) {
      setFieldError({ ...fieldError, columns: false });
    }
  };

  useEffect(async () => {
    const dropdownList = await props.getCategories();
    if (dropdownList) {
      setdropdownList(JSON.parse(dropdownList));
    }
  }, []);

  const handleFormReset = () => {
    dispatch(
      setInputData({
        ...initialData,
      }),
    );
    setInputDisable('Default');
  };

  const isFormValid = () => {
    const errorObj = {};
    const validateObj = {
      target: userInputData?.target,
      Name: userInputData?.ModelDetail?.Name,
      Description: userInputData?.ModelDetail?.Description,
    };
    Object.keys(validateObj).map((key) => {
      if (!validateObj[key]) {
        errorObj[key] = 'Required Field';
      }
    });
    if (userInputData?.columns.length == 0) {
      errorObj.columns = "Required Field";
    }
    const errData = { ...fieldError, ...errorObj };
    setFieldError(errData);

    return isObjKeyhasval(errData);
  };

  return (
    <LRContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Name"
            label="Name"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Name}
            inputProps={{ 'data-id': "model_detail" }}
            error={fieldError.Name}
            helperText={fieldError.Name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Description"
            label="Description"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Description}
            inputProps={{ 'data-id': "model_detail" }}
            error={fieldError.Description}
            helperText={fieldError.Description}
          />
        </Grid>
      </Grid>
      {isCanvasClicked && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={isEditable}
              onChange={handleOnChange}
              inputProps={{ 'data-id': "isEditable" }}
            />
          )}
          label="Edit Model Info"
        />
      )}
      <RadioGroup
        row
        name="isDisabled"
        defaultValue="False"
        aria-label="Learn Mixture Weights"
        // name="learnMixtureWeights"
        value={isDisabled}
        onChange={handleOnChange}
      >
        <FormControlLabel
          value="Default"
          control={
            <Radio color="primary" inputProps={{ 'data-id': "isDisabled" }} />
          }
          label="Default"
          labelPlacement="end"
        />
        <FormControlLabel
          value="Custom"
          control={
            <Radio color="primary" inputProps={{ 'data-id': "isDisabled" }} />
          }
          label="Custom"
          labelPlacement="end"
        />
      </RadioGroup>

      <strong>
        <InfoToolTip title="Customize the train test split">
          Split Parameters
        </InfoToolTip>
      </strong>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="test_size"
            label="Test Size"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': "split_param" }}
            value={userInputData?.split_param?.test_size}
            onBlur={validateOnBlur}
            error={fieldError.test_size}
            helperText={fieldError.test_size}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="random_state"
            label="Random State"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': "split_param" }}
            value={userInputData?.split_param?.random_state}
            error={fieldError.random_state}
            helperText={fieldError.random_state}
            onBlur={validateOnBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            control={(
              <CommonCheckbox
                disabled={isDisabled == "Default"}
                checked={userInputData?.split_param?.shuffle}
                name="shuffle"
                onChange={handleOnChange}
                inputProps={{ 'data-id': "split_param" }}
              />
            )}
            label="Shuffle"
          />
        </Grid>
      </Grid>
      <strong>
        <InfoToolTip title="Customize the model">Function Parameters</InfoToolTip>
        {' '}
      </strong>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="n_neighbors"
            label="Number of Neighbors"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': "model_param" }}
            value={userInputData?.model_param?.n_neighbors}
            onBlur={validateOnBlur}
            error={fieldError.n_neighbors}
            helperText={fieldError.n_neighbors}
            info="Number of neighbors to use by default for kneighbors queries"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="weights"
            label="Weights"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            select
            defaultValue="uniform"
            value={userInputData?.model_param?.weights}
            info="Weight function to be used in prediction"
          >
            {["uniform", "distance"]?.map((value) => (
              <MenuItem key={value} value={value} data-id="model_param">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="algorithm"
            label="Algorithm"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            select
            defaultValue="auto"
            value={userInputData?.model_param?.algorithm}
            info="Algorithm used to compute the nearest neighbors"
          >
            {["auto", "ball_tree", "kd_tree", "brute"]?.map((value) => (
              <MenuItem key={value} value={value} data-id="model_param">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="leaf_size"
            label="Leaf Size"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': "model_param" }}
            value={userInputData?.model_param?.leaf_size}
            onBlur={validateOnBlur}
            error={fieldError.leaf_size}
            helperText={fieldError.leaf_size}
            info="Leaf size passed to BallTree or KDTree"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="p"
            label="Power"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': "model_param" }}
            value={userInputData?.model_param?.p}
            onBlur={validateOnBlur}
            error={fieldError.p}
            helperText={fieldError.p}
            info="Power parameter for the Minkowski metric"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="n_jobs"
            label="Number of Jobs"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': "model_param" }}
            value={userInputData?.model_param?.n_jobs}
            onBlur={validateOnBlur}
            error={fieldError.n_jobs}
            helperText={fieldError.n_jobs}
            info="The number of parallel jobs to run for neighbors search"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <StyledFormControl error={fieldError.columns}>
            <InputLabel id="model-evaluation">Train Features</InputLabel>
            <Select
              multiple
              variant="outlined"
              name="features"
              disabled={disableModelInfo}
              value={userInputData?.columns || []}
              onChange={handleMultiple}
              input={<Input />}
              fullWidth
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              renderValue={(selected) => selected.join(', ')}
            // inputProps={{ 'data-id': "columns" }}
            >
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={userInputData.columns.indexOf(value) > -1}
                    disabled={
                      userInputData.columns.indexOf(userInputData.target) > -1
                    }
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {fieldError.columns && <FormHelperText>Required Field</FormHelperText>}

          </StyledFormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledInput
            name="target"
            label="Target"
            variant="outlined"
            onChange={handleOnChange}
            select
            required
            disabled={disableModelInfo}
            fullWidth
            inputProps={{ 'data-id': "target" }}
            value={userInputData?.target}
            error={fieldError.target}
            helperText={fieldError.target}
          >
            {dropdownList?.map((value) => (
              <MenuItem key={value} value={value} data-id="target">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
      </Grid>
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </LRContainer>
  );
});

export const LogisticRegressionResult = memo(() => {
  const { resultData = null } = useSelector((state) => state.vnet);
  return (
    resultData && (
      <div>
        <p>
          <strong>{resultData.modelRunID}</strong>
        </p>
        <List>
          {Object.entries(resultData.modelMetrics).map((data) => (Array.isArray(data[1]) ? (
            <ListItem>
              {data[0]}
              <List>
                {data[1].map((val) => (
                  <ListItem>{val}</ListItem>
                ))}
              </List>
            </ListItem>
          ) : (
            <ListItem>{`${data[0]} : ${data[1]}`}</ListItem>
          )))}
        </List>
      </div>
    )
  );
});

const LRContainer = styled.div``;

const CommonCheckbox = ({
  checked, name, onChange, inputProps,
}) => (
  <Checkbox
    checked={checked == 'True'}
    name={name}
    onChange={onChange}
    inputProps={inputProps}
  />
);
