import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { NavigateBeforeSharp } from "@material-ui/icons";
import Collapse from "../../common/Collapse";

// import local components

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import drugDiscovery_sol1 from "../../../assets/images/app/drugDiscovery_sol1.webp";
import drugDiscovery_sol2 from "../../../assets/images/app/drugDiscovery_sol2.webp";
import drugDiscovery_sol3 from "../../../assets/images/app/drugDiscovery_sol3.webp";
import drugDiscovery_sol4 from "../../../assets/images/app/drugDiscovery_sol4.webp";
import drugDiscovery_sample1 from "../../../assets/images/app/drugDiscovery_sample1.webp";

const DrugDiscovery = () => {
  const [showDataset, setShowDataset] = useState(false);
  const [showArchitecture, setShowArchitecture] = useState(false);
  const [showMetrics, setShowMetrics] = useState(false);
  const [imageCode, setImageCode] = useState("");

  const clickShowDataset = () => {
    setShowDataset(!showDataset);
    setShowArchitecture(false);
    setShowMetrics(false);
  };
  const clickShowArchitecture = () => {
    setShowArchitecture(!showArchitecture);
    setShowDataset(false);
    setShowMetrics(false);
  };
  const clickShowMetrics = () => {
    setShowMetrics(!showMetrics);
    setShowArchitecture(false);
    setShowDataset(false);
  };
  const change3DImage = (e) => {
    setImageCode(e.target.innerText);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/3Dmol/1.7.1/3Dmol-min.js";
    document.body.appendChild(script);
  }, []);

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Compound repositories of pharmaceutical companies contain up to a
            few million compounds. Even accounting for growth over time, these
            readily screenable libraries cover only a minuscule fraction of the
            synthetically accessible, drug-like chemical space, which is
            estimated to contain &gt; 1030 molecules. Because chemical space is
            too large to be screened in its entirety for drugs active against a
            particular target, automated design and screening of selected
            compounds with desired properties and the likelihood of activity
            present itself as a complementary approach.
          </p>
          <p>
            Here a new approach using RNN deep learning methodology is used. An
            LSTM‐based RNN model is trained to generate libraries of valid
            SMILES strings with high accuracy. Several research groups have
            recently demonstrated that RNNs can be employed to generate
            canonical SMILES strings and can be fine‐tuned by transfer learning.
            In transfer learning, the machine learning model tries to keep
            information from a previously learned task to solve a different but
            related, yet unseen task. A generative model is applied to
            fragment-based drug discovery by growing a library of leads starting
            from a known active fragment.
          </p>
          <center>
            <img src={drugDiscovery_sol1} alt="image" />
            <br />
            <strong>
              Schematic diagram of model training (left) and compound design by
              sampling (right)
            </strong>
          </center>
          <p>
            Generative model is applied to fragment-based drug discovery by
            growing a library of leads starting from a known active fragment.
          </p>
          <br />
          <h3>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            To know more, check out the
            following!
            <br />
          </h3>

          <Grid container xs={12} direction="row">
            <Grid item xs={12} sm={4} md={3}>
              <MoreBtn onClick={clickShowDataset}>
                Dataset &nbsp;
                {' '}
                <DynamicFonts showMore={showDataset} />
              </MoreBtn>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <MoreBtn onClick={clickShowArchitecture}>
                Model Architecture &nbsp;
                <DynamicFonts showMore={showArchitecture} />
              </MoreBtn>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <MoreBtn onClick={clickShowMetrics}>
                Evaluation Metrics &nbsp;
                <DynamicFonts showMore={showMetrics} />
              </MoreBtn>
            </Grid>
          </Grid>

          {showDataset && (
            <p>
              <strong>Dataset:</strong>
              <br />
              For training the RNN model, a dataset of 19,36,962 SMILES strings
              with annotated nanomolar activities was compiled from Moses
              (https://github.com/molecularsets/moses). The dataset was then
              pre‐processed to remove duplicates, salts and stereochemical
              information. In addition, pre‐processing filtered out nucleic
              acids and long peptides which lay outside of the chemical space
              from which we sought to sample. The RNN was ultimately trained on
              17,75,620 SMILES strings, with lengths from 34 to 128 SMILES
              characters.
            </p>
          )}

          {showArchitecture && (
            <section>
              <p>
                <strong>Model Architecture:</strong>
                <br />
                It consists of two LSTM layers, each with a hidden state vector
                of size 256, regularized with dropout. These two layers are
                followed by a dense output layer and a neuron unit with a
                softmax activation function. The input to the LSTM is a
                one‐hot‐encoded sequence of a molecule's SMILES string, where
                each string is split up into tokens. Each SMILES string is given
                a ‘G’ token (for “go”) at the beginning, and an ‘E’ is added to
                denote the end of the SMILES string. During training, the model
                predicts the next token for each input token in the sequence.
                The loss L is calculated at each position as the categorical
                cross‐entropy between the predicted and actual next token.
              </p>
              <center>
                <img src={drugDiscovery_sol2} alt="image" />
              </center>
            </section>
          )}

          {showMetrics && (
            <section>
              <p>
                <strong>Evaluation:</strong>
                <br />
                Around 5,000 Smiles were generated after training the model.
                <ul>
                  <li>
                    <strong>Validity:</strong>
                    {' '}
                    of the total number of generated
                    smiles, percentage that are actually valid smiles for
                    molecules
                  </li>
                  <li>
                    <strong>Uniqueness:</strong>
                    {' '}
                    of the total valid number of
                    generated smiles, percentage that are not duplicate
                  </li>
                  <li>
                    <strong>Originality:</strong>
                    {' '}
                    of the total number of valid
                    generated smiles, percentage that are brand new creations
                    that do not appear in the training data
                  </li>
                </ul>
              </p>
              <p>
                Results for the above model were:
                <ul>
                  <li>Validity – 93%</li>
                  <li>Uniqueness – 100 %</li>
                  <li>Originality – 100%</li>
                </ul>
              </p>
            </section>
          )}
          <p>
            <strong>Result:</strong>
            <br />
            5,000 Smiles were generated after training the model. The
            visualization of one of them is given below:
          </p>
          <Grid
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={6}>
              <img src={drugDiscovery_sol3} alt="image" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <p>
                <strong>Molecule Details:</strong>
                <ul>
                  <li>Formula: C25H28N4O6</li>
                  <li>Weight: 480.51302</li>
                  <li>Number of Atoms: 35</li>
                </ul>
                <strong>
                  *Similar details are available for all the generated molecule
                </strong>
              </p>
            </Grid>
          </Grid>
          <p>Some more Generated molecules:</p>
          <center>
            <img src={drugDiscovery_sol4} alt="" />
          </center>
          <p>
            The structure of drug target is taken from Protein Data Bank. The
            Protein Data Bank (PDB) archive is the single worldwide repository
            of information about the 3D structures of large biological
            molecules, including proteins and nucleic acids. These are the
            molecules of life that are found in all organisms including
            bacteria, yeast, plants, flies, other animals, and humans.
          </p>
          <p>3 drug targets are considered here :</p>
          <ul>
            <li>
              <strong>Remdesivir (7BV2):</strong>
              {' '}
              It is a broad-spectrum
              antiviral medication developed by the biopharmaceutical company
              Gilead Sciences. As of 2020, remdesivir is being tested as a
              specific treatment for COVID-19.
            </li>
            <li>
              <strong>Bevacizumab (6BF2):</strong>
              {' '}
              It is being studied in the
              treatment of cancer like Cervical cancer, Colorectal Cancer and
              Glioblastoma.
            </li>
            <li>
              <strong>Cetuximab (5i76):</strong>
              {' '}
              It is approved for treatment of
              Colorectal cancer that has metastasized (spread to other parts of
              the body). It is used in patients whose disease has the EGFR
              protein and the wild-type KRAS gene
            </li>
          </ul>
        </Paragraph>

        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/molecularsets/moses"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>


      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                onClick={change3DImage}
                color="primary"
              >
                6BF2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                onClick={change3DImage}
                color="primary"
              >
                7BV2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                onClick={change3DImage}
                color="primary"
              >
                5I76
              </StyledButton>
            </Grid>
          </Grid>

          <center>
            <MoleculeImage
              display={imageCode === "6BF2"}
              className="viewer_3Dmoljs"
              data-pdb="6BF2"
              data-backgroundcolor="0xffffff"
              data-style="stick"
            />
            <MoleculeImage
              display={imageCode === "7BV2"}
              className="viewer_3Dmoljs"
              data-pdb="7BV2"
              data-backgroundcolor="0xffffff"
              data-style="stick"
            />
            <MoleculeImage
              className="viewer_3Dmoljs"
              data-pdb="5i76"
              data-backgroundcolor="0xffffff"
              display={imageCode === "5I76"}
              data-style="stick"
            />
          </center>
          <br />
          {imageCode && (
            <section>
              <Paragraph>
                <p>
                  Binding scores of the newly generated Ligands with the above
                  selected targets are analysed using Vina Wizard in PyRx and
                  the scores are generated in a csv file. Sample CSV:
                </p>
                <center>
                  <img src={drugDiscovery_sample1} alt="" />
                </center>
                <p>
                  The more negative the score is, the better is the binding of
                  the newly generated molecule with the specified target.
                </p>
                <p>
                  <ul>
                    <li>
                      The best binding score among the newly generated molecule
                      with the drug Remdesivir is
                      {' '}
                      <strong>-12</strong>
                      .
                    </li>
                    <li>
                      The best binding score among the newly generated molecule
                      with the drug Bevacizumab is
                      {' '}
                      <strong>-14</strong>
                      .
                    </li>
                    <li>
                      The best binding score among the newly generated molecule
                      with the drug Cetuximab is
                      {' '}
                      <strong>-12</strong>
                      .
                    </li>
                  </ul>
                </p>
              </Paragraph>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(DrugDiscovery);
const MoleculeImage = styled.div`
  margin-top: 10px;
  height: 400px;
  width: 60%;
  display: ${(props) => (props.display ? "block" : "none")};
`;
const MoreBtn = styled(StyledButton)`
  color: #2941b7;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: none;
`;

const DynamicFonts = ({ showMore }) => (
  <FontAwesomeIcon icon={showMore ? faAngleDoubleUp : faAngleDoubleDown} />
);
