import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    List,
    ListItem,
} from "../../../styles/common";

//images
import SelfOrganizing_gif from "../../../assets/images/app/uruguay.gif";

const SelfOrganizing = () => {
    return (

        <SolutionContainer>
            <Collapse text="Description">
                <Paragraph>
                    <p>This application is the implementation of a Self Organizing Map that can be used to find sub-optimal solutions for the Traveling Salesman Problem.  It consists of finding the shortest route possible that traverses all cities in a given map only once.  The difficulty to solve the problem increases rapidly with the number of cities. a self-organizing map is described as an (usually two-dimensional) grid of nodes, inspired in a neural network. Closely related to the map, is the idea of the model, that is, the real world observation the map is trying to represent. The purpose of the technique is to represent the model with a lower number of dimensions, while maintaining the relations of similarity of the nodes contained in it. </p>
                    <p>To capture this similarity, the nodes in the map are spatially organized to be closer the more similar they are with each other. For that reason, SOM are a great way for pattern visualization and organization of data.   </p>
                    <p>The numpy package was used for the computations, which enables vectorization of the computations and higher performance in the execution, as well as more expressive and concise code. pandas is used for loading the .tsp files to memory easily, and matplotlib is used to plot the graphical representation.   </p>
                    <p>The evaluation strategy consists in running several instances of the problem and study some metrics: </p>
                    <List>
                        <ListItem>
                            <strong>Execution time</strong> invested by the technique to find a solution.
                        </ListItem>
                        <ListItem>
                            <strong>Quality</strong> of the solution, measured in function of the optimal route: a route that we say is “10% longer that the optimal route” is exactly 1.1 times the length of the optimal one.
                        </ListItem>
                    </List>
                    <p><strong>Application : </strong></p>
                    <p>The above demonstration can be used in Healthcare as well. </p>
                    <p>Certain examples are  </p>
                    <List>
                        <ListItem>
                            In problem solving of drug supply optimization,
                        </ListItem>
                        <ListItem>
                            Routing problem in healthcare staff in a home health care system, Given a list of patients needing several cares, the problem is to assign cares to care workers. Some skills constraints and time windows have to be satisfied.
                        </ListItem>
                    </List>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://github.com/diego-vicente/som-tsp"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Source
                                </a>
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://diego.codes/post/som-tsp/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Read more
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>

            <Collapse text='Demo'>
                <DemoContainer>
                    <center>
                        <img
                            src={SelfOrganizing_gif}
                            width='70%'
                            height='550'
                        />
                    </center>
                </DemoContainer>
            </Collapse>
        </SolutionContainer>
    );
}

export default memo(SelfOrganizing);
