import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components';
import iconImg from '../../../assets/images/dataops/icon.webp';
import getUserData from '../../../utils';


const userData = getUserData();
const ProfileName = userData?.token?.name;

export default function () {
  return (
    <StyledAppBar position='fixed' >
      <Toolbar >
        <img width='190px' height='100%' src={iconImg} alt='' />
        <Typography
          aria-label='recipe'
        >
          {<FontAwesomeIcon icon={faUser} />} &nbsp;
          {ProfileName}
        </Typography>
      </Toolbar>
    </StyledAppBar>
  )
}
const StyledAppBar = styled(AppBar)`
&.MuiAppBar-root{
  z-index: 2000;
  background-color: #1a237e;
  .MuiToolbar-root{
    display:flex;
    justify-content: space-between;
    &.MuiTypography-root{
      cursor: pointer;
      color: #ffff;
    }
  }
}
`
