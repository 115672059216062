import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { withStyles } from "@material-ui/core/styles";
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { passwordValidation } from "../../utils";

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

export default function PasswordField(props) {
  const [pwdValidation, setPwdValidation] = useState({
    pwdLength: false,
    oneLower: false,
    oneUpper: false,
    oneNumber: false,
    oneSpecialChar: false,
  });
  useEffect(() => {
    setPwdValidation(passwordValidation(props.value));
  }, [props.value]);
  return (

    <LightTooltip
      title={(
        <>
          <PwdText isValid={pwdValidation.pwdLength}>
            <FontAwesomeIcon
              icon={pwdValidation.pwdLength ? faCheck : faTimes}
            />
            Min 8 Characters, Max 32 characters
          </PwdText>
          <PwdText isValid={pwdValidation.oneLower}>
            <FontAwesomeIcon
              icon={pwdValidation.oneLower ? faCheck : faTimes}
            />
            Atleast 1 Lowercase
          </PwdText>
          <PwdText isValid={pwdValidation.oneUpper}>
            <FontAwesomeIcon
              icon={pwdValidation.oneUpper ? faCheck : faTimes}
            />
            Atleast 1 Uppercase
          </PwdText>
          <PwdText isValid={pwdValidation.oneNumber}>
            <FontAwesomeIcon
              icon={pwdValidation.oneNumber ? faCheck : faTimes}
            />
            Atleast 1 Number
          </PwdText>
          <PwdText isValid={pwdValidation.oneSpecialChar}>
            <FontAwesomeIcon
              icon={pwdValidation.oneSpecialChar ? faCheck : faTimes}
            />
            Atleast 1 special character
          </PwdText>
        </>
      )}
      arrow
      placement="left-start"
    >
      {props.children}

    </LightTooltip>

  );
}

const PwdText = styled.p`
  color: ${(props) => (props.isValid ? "#00897b" : "#f44336")};
`;
