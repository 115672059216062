import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const MortalityEffect = () => {
  const initialData = {
    id: "",
    weight: "",
    drug: "",
    purpose: "",
    gender: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("clinicalTrials", "mortalityEffect");

  const gender = [
    { name: "Female", value: 2 },
    { name: "Male", value: 1 },
  ];

  const formHighChart = (data1) => {
    Highcharts.chart('container', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Major Contributing factors',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
          name: data1[0][0],
          y: data1[0][1],
          color: '#bdef9e',

        }, {
          name: data1[1][0],
          y: data1[1][1],
          color: '#ef6e6e',

        }, {
          name: data1[2][0],
          y: data1[2][1],
          color: '#af6d6e',

        }, {
          name: data1[3][0],
          y: data1[3][1],
          color: '#cd000',

        },
        ],
      }],
    });
  };

  const showSample1 = () => {
    const NewDataSet = {
      id: "Patient 1736",
      weight: "74",
      drug: "PREDNISONE",
      purpose: "LOWER RESPIRATORY TRACT INFECTION",
      gender: "1",
    };

    setSampleData(NewDataSet);

    SetResultData('');
    formHighChart('');
  };

  const showSample2 = () => {
    const NewDataSet = {
      id: "Patient 1736",
      weight: "74",
      drug: "OMALIZUMAB",
      purpose: "ASTHMA",
      gender: "1",
    };

    setSampleData(NewDataSet);

    SetResultData('');
    formHighChart('');
  };

  const getResult = () => {
    const {
      id,
      drug,
      purpose,
      gender,
      weight,
    } = sampleData;

    const params = `${id},${drug},${purpose},${gender},
    ${weight}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("sample", params)
        .then((res) => {
          setSpinner(false);
          SetResultData(res.result);
          formHighChart(res.importance);
        })
        .catch((err) => {
          setSpinner(false);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Even though the lives of millions have been saved in the past decades, the mortality rate in patients
            with drug-resistant is still high. Different factors are associated with this mortality. However,
            there is no comprehensive global report addressing these risk factors.
          </p>
          <p>
            Drug-related deaths are deaths that happen after consumption of one or more drugs prescribed to patients.
            It may be directly related to the consumption or may often happen when such drugs are taken in combination
            with other drugs
          </p>
          <p>
            With the details of the patient, the drug consumed and the purpose for consuming the drug as input, this tool
            will predict probability of mortality effect of a drug used by the patient. Classification techniques
            ( OnevsRest Classifiers) in ML has been used to prepare this tool.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting%20mortality%20effect%20of%20a%20drug"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting%20mortality%20effect%20of%20a%20drug/Data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container xs={12} spacing={2} direction="row" justify="center">
            <Grid container xs={12} sm={10} md={6} spacing={2} direction="row" justify="center">
              <Grid item xs={12} sm={10} md={10}>
                <strong>Patient Details</strong>
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                <TextField
                  variant="outlined"
                  label="Patient ID"
                  name="id"
                  fullWidth
                  onChange={handleInputs}
                  value={sampleData.id}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                <TextField
                  variant="outlined"
                  select
                  fullWidth
                  name="gender"
                  label="Gender"
                  value={sampleData.gender}
                  onChange={handleInputs}
                >
                  {gender.map((data) => (
                    <MenuItem value={data.value}>{data.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="weight"
                  label="Weight"
                  value={sampleData.weight}
                  onChange={handleInputs}
                />
              </Grid>
            </Grid>
            <Grid container xs={12} sm={10} md={6} spacing={2} direction="row" justify="center">
              <Grid item xs={12} sm={10} md={10}>
                <strong>Drug Details</strong>
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="drug"
                  label="Name of Drug"
                  value={sampleData.drug}
                  onChange={handleInputs}
                />
              </Grid>
              <InputGrid item xs={12} sm={10} md={10}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Purpose of using Drug"
                  name="purpose"
                  value={sampleData.purpose}
                  onChange={handleInputs}
                />
              </InputGrid>
            </Grid>
          </Grid>
          <ButtonGrid container xs={12} spacing={2} direction="row" justify="center">
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </ButtonGrid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Result>
              <strong>Result:</strong>
              {`${result}`}
            </Result>
          )}
          <Grid xs={12} sm={12} md={12}>
            <HighchartContainer
              id="container"
              display={result}
            />
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MortalityEffect);
const ButtonGrid = styled(Grid)`
    margin-top: 2%;
`;
const InputGrid = styled(Grid)`
margin-bottom: 60px;
@media only screen and (max-width: 900px) {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 450px) {
    margin-bottom: 0px;
  }
`;
