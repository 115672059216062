import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import { StyledInput } from "../../InputForm";
import { StyledFormControl } from "../Dataviz/ScatterPlot";
import {
  LRContainer,
  CommonCheckbox,
} from "./LogisticRegression";
import { validateForm, isObjKeyhasval } from '../../util';
import { subFeatureData } from "../../constants";
import InfoToolTip from "../../components/InfoToolTip";

export const RandomForestForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector(
    (state) => state.vnet,
  );
  const { initialData = null } = subFeatureData.Random_Forest;

  const [isDisabled, setInputDisable] = useState("Default");
  const [dropdownList, setdropdownList] = useState(null);
  const [defaultParams, setdefaultParams] = useState(userInputData);
  const [disableModelInfo, setDisableModelInfo] = useState(isCanvasClicked);
  const solverListData = {
    criterion: ["gini", "entropy"],
  };
  const [isEditable, setIsEditable] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const validationData = {
    random_state: { maxNum: 100000, onlyNum: true },
    n_jobs: { minNum: -1, onlyInt: true },
    random_states: { minNum: 1, onlyNum: true, onlyInt: true },
    n_estimators: { minNum: 1, onlyNum: true, onlyInt: true },
    max_leaf_nodes: { minNum: 1, onlyNum: true, onlyInt: true },
    min_samples_split: { minNum: 1, onlyNum: true },
    min_samples_leaf: { minNum: 1, onlyNum: true },
  };

  const handleFieldError = (errData) => {
    setFieldError({
      ...fieldError,
      ...errData,
    });
  };

  const handleFormReset = () => {
    dispatch(setInputData({
      ...initialData,
    }));
    setInputDisable("Default");
  };

  const isFormValid = () => {
    const errorObj = {};
    const validateObj = {
      target: userInputData?.target,
      Name: userInputData?.ModelDetail?.Name,
      Description: userInputData?.ModelDetail?.Description,
    };
    Object.keys(validateObj).map((key) => {
      if (!validateObj[key]) {
        errorObj[key] = 'Required Field';
      }
    });
    if (userInputData?.columns.length == 0) {
      errorObj.columns = "Required Field";
    }
    const errData = { ...fieldError, ...errorObj };
    setFieldError(errData);

    return isObjKeyhasval(errData);
  };
  const { validateOnBlur } = validateForm(validationData, handleFieldError);
  const dispatch = useDispatch();

  const getCheckboxVal = (checked) => (checked ? "True" : "False");

  const handleOnChange = (e) => {
    console.log(e);
    const { value, name, checked = false } = e.target;
    const dataId = e.currentTarget.dataset.id;

    switch (dataId) {
      case "target":
        dispatch(
          setInputData({
            ...userInputData,
            target: value,
          }),
        );
        break;
      case "model_detail":
        dispatch(
          setInputData({
            ...userInputData,
            ModelDetail: {
              ...userInputData.ModelDetail,
              [name]: value,
            },
          }),
        );
        break;
      case "model_param":
        dispatch(
          setInputData({
            ...userInputData,
            model_param: {
              ...userInputData.model_param,
              [name]:
                ["warm_start", "bootstrap"].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        if (name == "penalty") {
          setSolverList(solverListData[value]);
        }
        break;
      case "split_param":
        dispatch(
          setInputData({
            ...userInputData,
            split_param: {
              ...userInputData.split_param,
              [name]:
                ["shuffle"].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        break;
      case "isDisabled":
        setInputDisable(value);
        if (value == "Default") {
          dispatch(
            setInputData({
              ...userInputData,
              ...defaultParams,
            }),
          );
        }
        break;
      case "isEditable":
        if (checked) {
          if (confirm(`Editing the existing model details will create a new model run and version. Do you want to continue ?`)) {
            setDisableModelInfo(false);
            setIsEditable(checked);
          }
        } else {
          setDisableModelInfo(true);
          setIsEditable(checked);
        }
        break;
      default:
        break;
    }
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        columns: typeof value === "string" ? value.split(",") : value,
      }),
    );
    if (fieldError.columns) {
      setFieldError({ ...fieldError, columns: false });
    }
  };

  useEffect(async () => {
    const dropdownList = await props.getCategories();
    if (dropdownList) {
      setdropdownList(JSON.parse(dropdownList));
    }
  }, []);

  return (
    <LRContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Name"
            label="Name"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Name}
            inputProps={{ "data-id": "model_detail" }}
            error={fieldError.Name}
            helperText={fieldError.Name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Description"
            label="Description"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Description}
            inputProps={{ "data-id": "model_detail" }}
            error={fieldError.Description}
            helperText={fieldError.Description}
          />
        </Grid>
      </Grid>
      {isCanvasClicked && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={isEditable}
              onChange={handleOnChange}
              inputProps={{ "data-id": "isEditable" }}
            />
          )}
          label="Edit Model Info"
        />
      )}
      <RadioGroup
        row
        name="isDisabled"
        defaultValue="False"
        aria-label="Learn Mixture Weights"
        value={isDisabled}
        onChange={handleOnChange}
      >
        <FormControlLabel
          value="Default"
          control={
            <Radio color="primary" inputProps={{ "data-id": "isDisabled" }} />
          }
          label="Default"
          labelPlacement="end"
        />
        <FormControlLabel
          value="Custom"
          control={
            <Radio color="primary" inputProps={{ "data-id": "isDisabled" }} />
          }
          label="Custom"
          labelPlacement="end"
        />
      </RadioGroup>
      <h3><InfoToolTip info="Customize the train test split"> Split Parameters</InfoToolTip></h3>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="test_size"
            label="Test Size"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "split_param" }}
            value={userInputData?.split_param?.test_size}
            onBlur={validateOnBlur}
            error={fieldError.test_size}
            helperText={fieldError.test_size}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="random_state"
            label="Random State"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "split_param" }}
            value={userInputData?.split_param?.random_state}
            onBlur={validateOnBlur}
            error={fieldError.random_state}
            helperText={fieldError.random_state}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            control={(
              <CommonCheckbox
                checked={userInputData?.split_param?.shuffle}
                name="shuffle"
                onChange={handleOnChange}
                inputProps={{ "data-id": "split_param" }}
              />
            )}
            label="Shuffle"
          />
        </Grid>
      </Grid>
      <h3><InfoToolTip info="Customize the model"> Function Parameters</InfoToolTip></h3>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="n_estimators"
            label="n_estimators"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.n_estimators}
            onBlur={validateOnBlur}
            error={fieldError.n_estimators}
            helperText={fieldError.n_estimators}
            info="The number of trees in the forest"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="criterion"
            label="Criterion"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            select
            defaultValue="gini"
            value={userInputData?.model_param?.criterion}
            info="The function to measure the quality of a split"
          >
            {["gini", "entropy"]?.map((value) => (
              <MenuItem key={value} value={value} data-id="model_param">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="min_samples_split"
            label="Minimum Sample Split"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.min_samples_split}
            onBlur={validateOnBlur}
            error={fieldError.min_samples_split}
            helperText={fieldError.min_samples_split}
            info="The minimum number of samples required to split an internal node"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="min_samples_leaf"
            label="Minimum Leaf Samples"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.min_samples_leaf}
            onBlur={validateOnBlur}
            error={fieldError.min_samples_leaf}
            helperText={fieldError.min_samples_leaf}
            info="The minimum number of samples required to be at a leaf node"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="max_leaf_nodes"
            label="Maximum Leaf Nodes"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.max_leaf_nodes}
            onBlur={validateOnBlur}
            error={fieldError.max_leaf_nodes}
            helperText={fieldError.max_leaf_nodes}
            info="Grow trees with in best-first fashion"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="random_states"
            label="Random State"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.random_states}
            onBlur={validateOnBlur}
            error={fieldError.random_states}
            helperText={fieldError.random_states}
            info="Controls both the randomness of the bootstrapping of the samples used when building trees"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="n_jobs"
            label="Number of Jobs"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.n_jobs}
            onBlur={validateOnBlur}
            error={fieldError.n_jobs}
            helperText={fieldError.n_jobs}
            info="The number of jobs to run in parallel"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <FormControlLabel
            control={(
              <CommonCheckbox
                checked={userInputData?.model_param?.bootstrap}
                name="bootstrap"
                onChange={handleOnChange}
                inputProps={{ "data-id": "model_param" }}
              />
            )}
            label="Bootstrap"
          />
          <FormControlLabel
            control={(
              <CommonCheckbox
                checked={userInputData?.model_param?.warm_start}
                name="warm_start"
                onChange={handleOnChange}
                inputProps={{ "data-id": "model_param" }}
              />
            )}
            label="Warm Start"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <StyledFormControl error={fieldError.columns}>
            <InputLabel id="model-evaluation">Train Features</InputLabel>
            <Select
              multiple
              variant="outlined"
              name="features"
              disabled={disableModelInfo}
              value={userInputData?.columns || []}
              onChange={handleMultiple}
              input={<Input />}
              fullWidth
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              renderValue={(selected) => selected.join(", ")}
            >
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={userInputData.columns.indexOf(value) > -1}
                    disabled={
                      userInputData.columns.indexOf(userInputData.target) > -1
                    }
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {fieldError.columns && <FormHelperText>Required Field</FormHelperText>}
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledInput
            name="target"
            label="Target"
            variant="outlined"
            onChange={handleOnChange}
            select
            required
            disabled={disableModelInfo}
            fullWidth
            inputProps={{ "data-id": "target" }}
            value={userInputData?.target}
            error={fieldError.target}
            helperText={fieldError.target}

          >
            {dropdownList?.map((value) => (
              <MenuItem key={value} value={value} data-id="target">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
      </Grid>
      {props.render({ handleFormReset, isFormValid })}
    </LRContainer>
  );
});
