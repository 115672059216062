import React, { memo, useState, useEffect } from 'react';
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { setInputData } from '../../../../../../store/actions/vnet';
import { StyledInput } from '../../InputForm';
import { StyledFormControl } from "./ScatterPlot";
import { isObjKeyhasval } from '../../util';
import { subFeatureData } from '../../constants';
import { TextFieldToolTip } from '../../components/InfoToolTip';

export const ChoroplethMapsForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);

  const [dropdownList, setdropdownList] = useState(null);
  const [fieldError, setError] = useState({});

  const dispatch = useDispatch();
  const handleMultiple = (e) => {
    const {
      target: { value, name },
    } = e;

    dispatch(
      setInputData({
        hover_data: typeof value === 'string' ? value.split(',') : value,
      }),
    );
    if (fieldError[name]) {
      setError({ ...fieldError, [name]: false });
    }
  };
  const handleOnchange = (e) => {
    const {
      target: { value, name },
    } = e;

    dispatch(
      setInputData({
        [name]: value
      }),
    );
    if (fieldError[name]) {
      setError({ ...fieldError, [name]: false });
    }
  };
  const handleFormReset = () => {
    dispatch(
      setInputData(subFeatureData['choroplethMaps']?.initialData),
    );
  };
  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(result);
    }
  }, []);
  const isFormValid = () => {
    let error = { ...fieldError };
    Object.keys(userInputData)?.forEach(ele => {
      if (Array.isArray(userInputData[ele])) {
        if (userInputData[ele].length == 0) error[ele] = true;
      }
      else if (!userInputData[ele]) {
        error[ele] = true;
      }
    });
    setError(error);
    return true;
  };
  return (
    <section>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={4}>
          <StyledInput
            name="mapping_feature"
            label="Mapping Feature"
            variant="outlined"
            onChange={handleOnchange}
            select
            required
            fullWidth
            value={userInputData.mapping_feature}
            error={fieldError['mapping_feature']}
            helperText={fieldError['mapping_feature'] && 'Required Field'}
            info="Column containing state names (In camel case)"
          >
            {dropdownList?.categorical?.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledInput
            name="gradient_feature"
            label="Gradient Feature"
            variant="outlined"
            onChange={handleOnchange}
            select
            required
            fullWidth
            value={userInputData?.gradient_feature}
            error={fieldError['gradient_feature']}
            helperText={fieldError['gradient_feature'] && 'Required Field'}
            info='Column based on which the gradient is set'
          >

            {dropdownList?.numeric?.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledInput
            id="outlined-adornment-weight"
            name="hover_name"
            label="Hover Name"
            variant="outlined"
            onChange={handleOnchange}
            select
            required
            fullWidth
            value={userInputData?.['hover_name']}
            error={fieldError['hover_name']}
            helperText={fieldError['hover_name'] && 'Required Field'}
            info='Data to be displayed as headings for tool tips.'
          >
            {dropdownList?.all?.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldToolTip info="Data to be displayed inside the tool tips.">
            <StyledFormControl error={fieldError.hover_data}>
              <InputLabel id="model-evaluation">Hover Data</InputLabel>
              <Select
                multiple
                variant="Hover Data"
                name="hover_data"
                onChange={handleMultiple}
                input={<Input />}
                fullWidth
                value={userInputData?.['hover_data'] || []}
                required

                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
                renderValue={(selected) => selected.join(', ')}
              >
                {dropdownList?.all?.map((value) => (
                  <MenuItem key={value} value={value}>
                    <Checkbox checked={userInputData?.['hover_data']?.indexOf(value) > -1} />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>
              {fieldError.hover_data && <FormHelperText>Required Field</FormHelperText>}
            </StyledFormControl>
          </TextFieldToolTip>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledInput
            name="title"
            label="Plot Title"
            variant="outlined"
            onChange={handleOnchange}
            required
            fullWidth
            value={userInputData?.['title']}
            error={fieldError['title']}
            helperText={fieldError['title'] && 'Required Field'}
          >
          </StyledInput>
        </Grid>
      </Grid>
      {props.render({
        handleFormReset,
        isFormValid,
      })
      }
    </section >
  );
});
