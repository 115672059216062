import React, { memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
  HighchartContainer,
} from "../../../styles/common";

const useStyles = makeStyles({
  root: {
    height: "100%",
    textAlign: "left",
    backgroundColor: "#0080FF",
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  textCenter: {
    textAlign: "center",
  },
});

function StyledCard({ name, cardAsLink }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.link}
        component={cardAsLink && "a"}
        href={cardAsLink}
        target="_blank"
      >
        <CardContent>
          {name && (
            <Typography
              className={classes.textCenter}
              gutterBottom
              variant="h6"
              component="h5"
            >
              {name}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const FetalHealthClassification = () => {
  const initialData = {
    baseline: "",
    accelerations: "",
    uterine: "",
    prolonged: "",
    abnormal_short: "",
    mean_value_short: "",
    abnormal_long: "",
    mean_value_long: "",
    histogram_minimum: "",
    histogram_maximum: "",
    histogram_mode: "",
    histogram_variance: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("commercialAnalytics", "FetalHealth");

  const data = [
    ["Fetal Health Status By Number of Accelerations per second From Cardiotocography(CTG) Exam", "https://public.tableau.com/app/profile/vlife.virtusa/viz/FetalHealthStatusByNumberofAccelationspersecondFromCardiotocographyCTGExam/Numberofacc_persecond#1"],
    ["Fetal Health Status By Number of Fetal Movement per second From Cardiotocography(CTG) Observation", "https://public.tableau.com/app/profile/vlife.virtusa/viz/FetalHealthStatusByNumberofFetalMovementpersecondFromCardiotocographyCTGObservation/No_ofFetalMovementSecond#1"],
    ["Fetal Health Status By Number of Uterine contractions per second From Cardiotocography(CTG) Observation", "https://public.tableau.com/app/profile/vlife.virtusa/viz/FetalHealthStatusByNumberofUterinecontractionspersecondFromCardiotocographyCTGObservation/No_ofUterineContractionsSecond#1"],
    ["Fetal Health Status By Baseline Fetal Health Rate From Cardiotocography(CTG) Observation ", "https://public.tableau.com/app/profile/vlife.virtusa/viz/FetalHealthStatusByBaselineFetalHealthRateFromCardiotocographyCTGObservation/BaselineFetalHealthRate#1"],
  ];

  const showSample1 = () => {
    const NewDataSet = {
      baseline: "147.0",
      accelerations: "0.002",
      uterine: "0.006",
      prolonged: "0.000",
      abnormal_short: "46.0",
      mean_value_short: "0.6",
      abnormal_long: "49.0",
      mean_value_long: "4.8",
      histogram_minimum: "143.0",
      histogram_maximum: "167.0",
      histogram_mode: "160.0",
      histogram_variance: "1.0",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      baseline: "144.0",
      accelerations: "0.000",
      uterine: "0.000",
      prolonged: "0.000",
      abnormal_short: "61.0",
      mean_value_short: "0.5",
      abnormal_long: "7.0",
      mean_value_long: "9.5",
      histogram_minimum: "125.0",
      histogram_maximum: "164.0",
      histogram_mode: "144.0",
      histogram_variance: "1.0",
    };

    setSampleData(NewDataSet);
  };

  const showSample3 = () => {
    const NewDataSet = {
      baseline: "128.0",
      accelerations: "0.000",
      uterine: "0.003",
      prolonged: "0.003",
      abnormal_short: "30.0",
      mean_value_short: "2.1",
      abnormal_long: "0.0",
      mean_value_long: "8.6",
      histogram_minimum: "54.0",
      histogram_maximum: "182.0",
      histogram_mode: "129.0",
      histogram_variance: "134.0",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      baseline, accelerations, uterine, prolonged, abnormal_short, mean_value_short, abnormal_long, mean_value_long, histogram_minimum, histogram_maximum, histogram_mode, histogram_variance,
    } = sampleData;

    const params = `${baseline},${accelerations},${uterine},${prolonged},${abnormal_short},${mean_value_short},${abnormal_long},${mean_value_long},${histogram_minimum},${histogram_maximum},${histogram_mode},${histogram_variance}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res.result);

          const percentages = res.feature_importance;
          const params = [];
          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          console.log(percentages);
          Highcharts.chart('container', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Reduction of child mortality is reflected in several of the United Nation’s Sustainable Development Goals and is a key indicator of human progress. The UN expects that by 2030, countries end preventable deaths of new born and children under 5 years of age, with all countries aiming to reduce under-5 mortality to atleast as low as 25 per 1000 live births.</p>
          <p>Cardiotocogram (CTGs) are a simple and cost accessible option to assess fetal health, allowing healthcare professionals to take action in order to prevent child and maternal mortality. It is to monitor fetal well-being and allows early detection of fetal distress. The equipment itself works by sending ultrasound pulses and reading its response, thus shedding light on fetal heart rate (FHR), fetal movements, uterine contractions and more. CTG interpretation helps in determining if the pregnancy is high or low risk. An abnormal CTG may indicate the need for further investigations and potential intervention.</p>
          <p>The aim of this Machine Learning model is to classify the outcome of Cardiotocogram test to ensure the well-being of the fetus and detect abnormalities in early stage of pregnancy by reducing child and maternal mortality.</p>
          <p><b>Significance:</b></p>
          <p>The application will be used to assist in the use and interpretation of intrapartum cadiotocogrpahy (CTG), as well as in the clinical management of specific CTG patterns. Several factors, including gestational age and medication administered to the mother, can affect FHR features, so CTG analysis needs to be integrated with other clinical information for a comprehensive interpretation and adequate management.</p>
          <p><b>About dataset:</b></p>
          <p>The dataset is a tabular data and it contains 2126 records of features extracted from Cardiotocogram exams, which were then classified into 3 different classes:</p>
          <List>
            <ListItem>1-Normal</ListItem>
            <ListItem>2-Suspect</ListItem>
            <ListItem>3-Pathological</ListItem>
          </List>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/FetalHealth/FetalHealth.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/andrewmvd/fetal-health-classification"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dataset
                </a>
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="row">
            {data.map((data) => (
              <Grid item xs={12} sm={12} md={6}>
                <StyledCard
                  name={data[0]}
                  cardAsLink={data[1]}
                />
              </Grid>
            ))}
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Baseline Value"
                name="baseline"
                fullWidth
                onChange={handleInputs}
                value={sampleData.baseline}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Accelerations"
                name="accelerations"
                fullWidth
                onChange={handleInputs}
                value={sampleData.accelerations}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Uterine Contractions"
                name="uterine"
                fullWidth
                onChange={handleInputs}
                value={sampleData.uterine}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Prolonged Decelerations"
                name="prolonged"
                fullWidth
                onChange={handleInputs}
                value={sampleData.prolonged}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Abnormal short-term variability"
                name="abnormal_short"
                fullWidth
                onChange={handleInputs}
                value={sampleData.abnormal_short}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Mean value of short-term variability"
                name="mean_value_short"
                fullWidth
                onChange={handleInputs}
                value={sampleData.mean_value_short}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Abnormal long-term variability"
                name="abnormal_long"
                fullWidth
                onChange={handleInputs}
                value={sampleData.abnormal_long}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Mean value of long-term variability"
                name="mean_value_long"
                fullWidth
                onChange={handleInputs}
                value={sampleData.mean_value_long}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Histogram Minimum"
                name="histogram_minimum"
                fullWidth
                onChange={handleInputs}
                value={sampleData.histogram_minimum}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Histogram Maximum"
                name="histogram_maximum"
                fullWidth
                onChange={handleInputs}
                value={sampleData.histogram_maximum}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Histogram Mode"
                name="histogram_mode"
                fullWidth
                onChange={handleInputs}
                value={sampleData.histogram_mode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Histogram Variance"
                name="histogram_variance"
                fullWidth
                onChange={handleInputs}
                value={sampleData.histogram_variance}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample3}
              >
                Sample Data3
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(FetalHealthClassification);
