import React, { memo, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// local components

import MainLayout from "../common/MainLayout";
import Navbar from "../common/Navbar";
import Footer from "../views/home/Footer";
import BasedOnBill from "./BasedOnBill";
import PatientPopulation from "./PatientPopulation";
import MaleAndFemale from "./MaleAndFemale";
import PatientPopulationTop10 from "./PatientPopulationTop10";
import PatientTherapeuticCode from "./PatientTherapeuticCode";
import PatientTotalPayTop10 from "./PatientTotalPayTop10";
import ProviderTypes from "./ProviderTypes";
import PopulationTotalPay from "./PopulationTotalPay";
import Error from "../common/Error";
import { PageContainer, PageTitle } from "../../styles/common";

const PopulationHealthDashboard = () => {
  const { vizid } = useParams();
  const [componentData, setcomponentData] = useState(null);
  const vizualizationData = {
    basedOnBill: { component: <BasedOnBill />, title: "Patient Total Pay based on Bill Type" },
    PopulationTotalPay: { component: <PopulationTotalPay />, title: "Patient Total Pay" },
    ProviderTypes: { component: <ProviderTypes />, title: "Provider Types" },
    PatientTotalPayTop10: { component: <PatientTotalPayTop10 />, title: "Top 10 Diagnosis Group" },
    PatientTherapeuticCode: { component: <PatientTherapeuticCode />, title: "Patient Total Pay based on Therapeutic code" },
    PatientPopulationTop10: { component: <PatientPopulationTop10 />, title: "Top 10 Diagnosis Group" },
    MaleAndFemale: { component: <MaleAndFemale />, title: "Top 10 Procedures" },
    PatientPopulation: { component: <PatientPopulation />, title: "Patient Population" },
  };

  useEffect(() => {
    setcomponentData(vizualizationData[vizid]);
  }, [vizid]);

  return (
    <MainLayout>
      <Navbar />
      {componentData && (
      <PageContainer>
        <PageTitle>
          {componentData.title}
        </PageTitle>
        {componentData.component}
      </PageContainer>
      )}
      {!componentData && <Error code="404" />}
      <Footer />
    </MainLayout>
  );
};

export default memo(PopulationHealthDashboard);
