import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const PageLoading = () => (
  <SpinnerContainer>
    <Spinner text="Loading..." />
  </SpinnerContainer>
);

const SpinnerContainer = styled.div`
  height: 100vh;
  margin-top: calc(50vh - 100px);
`;

export default PageLoading;
