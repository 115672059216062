import React, { memo } from "react";
import styled from "styled-components";
import { Grid, Card, Typography } from "@material-ui/core";

// import local components

import {
  Paragraph,
  StyledButton,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import networkGraphAnalyzer_sol1 from "../../../assets/images/app/networkGraphAnalyzer_sol1.webp";
import networkGraphAnalyzer_sol2 from "../../../assets/images/app/networkGraphAnalyzer_sol2.webp";
import networkGraphAnalyzer_sol3 from "../../../assets/images/app/networkGraphAnalyzer_sol3.webp";
import networkGraphAnalyzer_sol4 from "../../../assets/images/app/networkGraphAnalyzer_sol4.webp";
import networkGraphAnalyzer_sol5 from "../../../assets/images/app/networkGraphAnalyzer_sol4.webp";

// Comorbidity Analyzer
const NetworkGraphAnalyzer = () => (
  <SolutionContainer>
    <Paragraph>
      <section>
        <Grid container>
          <Grid item xs={12} md={6}>
            <CardCA>
              <FullWidthImage src={networkGraphAnalyzer_sol1} />
            </CardCA>
          </Grid>
          <Grid item xs={12} md={6}>
            <HeadingCA>Background</HeadingCA>
            <BodyCA2>
              Growth in use of electronic health records (EHR) in health
              care delivery is opening unprecedented opportunities to
              predict patient risk, optimal treatment pathways, and
              personalized clinical decision-making.
              <br />
              While higher order clinical features (e.g., disease
              phenotypes) are intuitively more meaningful and can reduce
              data volume, they may fail to capture meaningful information
              inherent to patient data.
            </BodyCA2>
            <br />
            <br />
            <HeadingCA>Approach</HeadingCA>
            <BodyCA2>
              The
              {' '}
              <b>temporal occurrence</b>
              {' '}
              of EHR data yields features
              that capture pathophysiologic relations known as
              <b>Medical Embeddings</b>
              {' '}
              . These embeddings are vectorized
              medical codes. The codes when passed onto a neural network
              creates a network where in the trained elements act as nodes
              and the strength between each element is captured by the
              neural network.
            </BodyCA2>
            <br />
            <BodyCA2>
              The similarity score connects each and every element
              (Condition or Medication or Procedure) as a nodal
              representation in 2-Dimensional graph. One can now have a
              holistic view of the EHR data as a
              {' '}
              <b>network graph</b>
              representing the power centers and the many-to-many relations
              between the trained elements
            </BodyCA2>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />

        <Grid container>
          <Grid item xs={12} md={6}>
            <CardCA>
              <FullWidthImage src={networkGraphAnalyzer_sol2} />
            </CardCA>
          </Grid>

          <Grid item xs={12} md={6}>
            <HeadingCA>Real World Applications</HeadingCA>
            <br />
            <BodyCA2>
              <b>Comorbidity Analyzer</b>
              {' '}
              EMR/EHR is a large system
              comprising disease conditions at a population level. When the
              embeddings from the graph network are sorted on page rank and
              centrality concepts, the associated comorbidities give way to
              the root cause of connected conditions
            </BodyCA2>
            <br />
            <BodyCA2>
              <b>Drug Price Estimator</b>
              {' '}
              The network flow when included
              with medication embeddings will pave way to the treatment
              pathways at a population level. Apart from capturing
              individual level data the graph not only reveals the most
              probable treatment pathways taken but also can predict the
              cost associated for condition
            </BodyCA2>
            <br />
            <BodyCA2>
              <b>Length of Stay</b>
              {' '}
              The most complicated problem to retrieve
              from a EMR. The regular ML models work at patient level
              information and try to fit in the comorbidity impact only on
              the occurrence into patient’s profile. But the network graph
              analyzer can predict the chance of a comorbidity occurrence
              and include it in the prediction of length of stay
            </BodyCA2>
            <center>
              <StyledButton
                color="primary"
                onClick={() => {
                  window.open(
                    "https://apps.vlifevirtusa.com/vlife-components/NetworkGraphAnalyzer/",
                    "_blank",
                  );
                }}
              >
                Try Demo
              </StyledButton>
            </center>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <Grid container>
          <Grid item xs={12}>
            <HeadingCA1>Solution Architecture</HeadingCA1>
            <CardCA>
              <FullWidthImage src={networkGraphAnalyzer_sol3} />
            </CardCA>
          </Grid>
        </Grid>

        <br />
        <br />
        <br />

        <Grid container>
          <Grid item xs={12} md={6}>
            <HeadingCA1>Comorbidity Analyzer using Med2Vec</HeadingCA1>
            <CardCA>
              <FullWidthImage src={networkGraphAnalyzer_sol1} />
            </CardCA>
          </Grid>

          <Grid item xs={12} md={6}>
            <HeadingCA2>Understanding the Output Network </HeadingCA2>
            <BodyCA2>
              <ul>
                <li>
                  The Network graph displays various conditions as Nodes
                </li>
                <li>
                  Each Node is associated with other diseases based through
                  connected edges
                </li>
                <li>
                  Conditions with higher comorbidities are associated with
                  several other nodes and can be identified using PageRank
                  as a filter.
                </li>
                <li>
                  The nodes in blue are conditions having higher linkages
                  across patient populations
                </li>
                <li>
                  We can also identify common conditions at a multi fold
                  level
                </li>
                <li>
                  For Example, N30.80 is a diagnosis code for cystitis
                  hematuria (inflammation of the bladder ). It has edges
                  from E11.9 (Type 2 Diabetes) and N18.6 (Renal Disease)
                  stating that these conditions are related via bladder
                  inflammation
                </li>
              </ul>
            </BodyCA2>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />

        <Grid container>
          <Grid item xs={12}>
            <HeadingCA1>Tensor Board – Initial Stage</HeadingCA1>
            <CardCA>
              <FullWidthImage src={networkGraphAnalyzer_sol4} />
            </CardCA>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />

        <Grid container>
          <Grid item xs={12}>
            <HeadingCA1>
              Tensor Board – Trained on Diagnosis code
            </HeadingCA1>
            <Card>
              <FullWidthImage src={networkGraphAnalyzer_sol5} />
            </Card>
          </Grid>
        </Grid>
      </section>
    </Paragraph>
  </SolutionContainer>
);

export default memo(NetworkGraphAnalyzer);

const FullWidthImage = styled.img`
  width: 100%;
`;
const CardCA = styled(Card)`
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const HeadingCA = styled(Typography)`
  color: #e29b2d;
  font-size: 20px;
  text-align: left;
  margin-top: 11px;
`;
const HeadingCA1 = styled(Typography)`
  color: purple;
  font-size: 25px;
  text-align: left;
`;
const HeadingCA2 = styled(Typography)`
  color: #e29b2d;
  font-size: 20px;
  text-align: left;
  margin-top: 50px;
`;
const BodyCA2 = styled(Typography)`
  text-align: left;
`;
