import * as actions from "../actions";

const initialState = {
  items: [],
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_TO_CART: {
      const { items } = state;
      items.push(action.payload);
      return { ...state, items };
    }
    case actions.REMOVE_FROM_CART: {
      const items = state.items.filter((item) => item !== action.payload);
      return { ...state, items };
    }
    case actions.CLEAR_CART: {
      const items = [];
      return { ...state, items };
    }
    default:
      return state;
  }
};

export default cart;
