import React, { memo, useState } from "react";
import {
  Grid, TextField, MenuItem, FormControl,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";

const CryoTherapyAnalysis = () => {
  // This is the initial state of the DOM
  const initialData = {
    gender: "",
    age: "",
    time: "",
    warts: "",
    type: "",
    area: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "bioInformatics",
    "cryoTherapyAnalysis",
  );

  const formChart = (data) => {
    const params = [];
    data.map((arr, index) => params.push({
      name: arr[0],
      y: arr[1],
    }));
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: params,
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      gender: "1",
      age: "20",
      time: "0.5",
      warts: "2",
      type: "1",
      area: "75",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      gender: "1",
      age: "23",
      time: "10.25",
      warts: "7",
      type: "3",
      area: "72",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      gender, age, time, warts, type, area,
    } = sampleData;

    const params = `${gender},${age},${time},${warts},${type},${area}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
          console.log(res);
          formChart(res.importance);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Cryotherapy, also known as cryosurgery, is a commonly used in-office
            procedure for the treatment of a variety of benign and malignant
            lesions.
          </p>
          <p>
            In this process usually a part of the body or whole body is frozen.
            After the completion of treatment, the external features like Age,
            treatment time, number of warts etc are considered by doctor to find
            the tumor is benign or malignant.
          </p>
          <p>
            An analytical exploration is performed to understand the importance
            of the treatment.
          </p>
          <p>
            This dataset contains information about wart treatment results of 90
            patients using cryotherapy. It provides information related to
            patient's characteristics whether after the result of treatment the
            level of cancer is malignant or benign.
          </p>
          <p>
            Following factors are taken as input to determine the severity of
            the cancer
          </p>
          <ul>
            <li>Gender of the patient (1: Male and 2 : Female )</li>
            <li>Age of the patient (in years)</li>
            <li>Time elapsed before treatment (month)</li>
            <li>Number of the warts present in the patient's body</li>
            <li>Types of wart 1– Common ,2 - Plantar , 3– Both</li>
            <li>Area : Surface area of the warts (mm2)</li>
          </ul>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://archive.ics.uci.edu/ml/datasets/Cryotherapy+Dataset+#"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/cryotherapy/cryotherapy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Gender"
                  name="gender"
                  value={sampleData.gender}
                  onChange={onChangeFields}
                  select
                >
                  <MenuItem key="1" value="1">
                    Male
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    Female
                  </MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Age"
                  name="age"
                  value={sampleData.age}
                  onChange={onChangeFields}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Time"
                  name="time"
                  value={sampleData.time}
                  onChange={onChangeFields}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Number of warts "
                  name="warts"
                  value={sampleData.warts}
                  onChange={onChangeFields}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Type"
                  name="type"
                  value={sampleData.type}
                  onChange={onChangeFields}
                  select
                >
                  <MenuItem key="1" value="1">
                    Common
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    Plantar
                  </MenuItem>
                  <MenuItem key="3" value="3">
                    Both
                  </MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Area"
                  name="area"
                  value={sampleData.area}
                  onChange={onChangeFields}
                  type="number"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${resultData}`}
              </Result>
              <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                  <HighchartContainer id="container" display />
                </Grid>
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(CryoTherapyAnalysis);
