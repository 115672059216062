import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";
import { SectionTitle } from "../../pages/Categories";
import { List } from './JakInhibitors';

// image components
import northAmericaMap from '../../../assets/images/app/northamericasimulated.webp';

const NorthAmericaSimulated = () => (
  <SolutionContainer>
    <Collapse text="Description">
      <Grid container direction="row" justify="center" spacing={3}>
        <Grid item xs={12} sm={4}>
          <img src={northAmericaMap} alt="" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paragraph>North American Simulated population represents digital clones of patients, encounters, observations, conditions and care choices</Paragraph>
          <Paragraph>
            <ul>
              <li>364 Million Virtual Lives​</li>
              <li>815 Billion Records​</li>
              <li>96TB Total Size​</li>
              <li>34 Therapeutic Areas​​</li>
              <li>Clean, ready-to-use EMR Data​​​</li>
            </ul>
          </Paragraph>
        </Grid>
      </Grid>
      <LinkContainer>
        <Grid container spacing={2}>

          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<OpenInNewIcon />}
            >
              <a
                href="https://material.vlifevirtusa.com/SyntheticData/Synthetic_emr_data.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Simulated Data Infographics
              </a>
            </StyledButton>
          </Grid>
        </Grid>
      </LinkContainer>
      <SectionTitle title="THERAPEUTIC AREAS" />
      <Grid container direction="row">
        <Grid item xs={6} sm={3}>
          <ul type="none">
            <List>Allergic Rhinitis</List>
            <List>Allergies​​</List>
            <List>Asthma​​</List>
            <List>Atopy​​</List>
            <List>Attention Deficit Disorder</List>
            <List>Bronchitis​​</List>
            <List>Colorectal Heart Failure</List>
            <List>COPD​​</List>
            <List>Cystic Fibrosis</List>
            <List>Dementia​​</List>
            <List>Dermatitis​​</List>
            <List>Dialysis​​</List>
            <List>Epilepsy​​</List>
          </ul>
        </Grid>
        <Grid item xs={6} sm={3}>
          <ul type="none">
            <List>Fibromyalgia​​</List>
            <List>Food Allergies</List>
            <List>Gallstones​​</List>
            <List>Gout​​</List>
            <List>Homelessness​​</List>
            <List>Hypothyroidism​​</List>
            <List>Immunotherapy​​</List>
            <List>Injuries​​</List>
            <List>Lung Cancer</List>
            <List>Lupus​​</List>
            <List>MetaboListc Syndrome Disease​​</List>
            <List>Opioid Addiction</List>
            <List>Osteoarthritis​​</List>
            <List>Pregnancy</List>
          </ul>
        </Grid>
        <Grid item xs={6} sm={4}>
          <ul type="none">
            <List>Primary Atrophic Hypothyroidism​​</List>
            <List>Rheumatoid Arthritis​​</List>
            <List>Self Harm</List>
            <List>Sore Throat</List>
            <List>Total Joint Replacement</List>
            <List>Urinary Tract Infections</List>
          </ul>
        </Grid>
      </Grid>
    </Collapse>

  </SolutionContainer>
);

export default memo(NorthAmericaSimulated);
