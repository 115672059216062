import React, { memo, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    AppBar,
    Tab,
    Tabs,
    MenuItem,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    TableContainer,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Drawer, List, ListItemButton, ListItemIcon, InboxIcon, MailIcon, ListItemText,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RadioGroup from "@material-ui/core/RadioGroup";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from "@material-ui/icons/Search";
import JoditEditor from "jodit-react";

// local components
import styled from 'styled-components';
import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import CommonService from "../../../utils/services/CommonService";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    ListItem,
    LinkContainer
} from '../../../styles/common';
import getUserData from "../../../utils";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

const BusinessRequirement = () => {

    const { getQueryResult, postQueryResult } = CommonService(
        "baSdlcReq", "baSdlcReq",
    );

    const [showSpinner, setSpinner] = useState(false);
    const [notification, setNotification] = useState("");
    const [userId, setUserId] = useState(getUserData()?.token?.emailid);
    const [value, setValue] = useState(0);
    const [chooseDomain, setchooseDomain] = React.useState('');
    const [chooseModule, setchooseModule] = React.useState('');
    const [applicationMgmt, setApplicationMgmt] = useState([]);
    const [readOneAppDetails, setreadOneAppDetails] = useState([]);
    const [useCaseResponse, setUseCaseResponse] = useState([]);
    const [chooseUsecaseTitle, setChooseUsecaseTitle] = useState('');
    const [newUseCase, setnewUseCase] = useState('');
    const [useCaseDescription, setUseCaseDescription] = useState('');
    const [functionalTitle, setfunctionalTitle] = useState([]);
    const [functionalValue, setfunctionalValue] = useState(true);
    const [funcReqReadAll, setfuncReqReadAll] = useState([]);
    const [testcaseGenReadAll, setTestcaseGenReadAll] = useState([]);
    const [chooseFunctionalTitle, setChooseFunctionalTitle] = useState('');
    const [addGenUseCaseBtn, setaddGenUseCaseBtn] = useState(true);
    const [createApp, setCreateApp] = useState(false);
    const [open, setOpen] = useState(false);
    const [codeResult, setCodeResult] = useState("");
    const [trelloValue, setTrelloValue] = useState("");
    const [trelloValue2, setTrelloValue2] = useState("");
    const [deployLink, setDeployLink] = useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateAppOpen = () => {
        setreadOneAppDetails([])
        setCreateApp(true);
    };
    const handleEditAppOpen = () => {
        setCreateApp(true);
    }
    const handleCreateAppClose = () => {
        setCreateApp(false);
    };

    // for tab values
    const tabhandleChange = (event, newValue) => {
        console.log("Tab value--->", newValue)
        if (newValue == 0) {
            getApplicationMgmt()
        }
        setValue(newValue);
        setchooseDomain('')
        setchooseModule('')
        setChooseUsecaseTitle('')
        setfunctionalTitle('')
        setfunctionalValue(true)
        setfuncReqReadAll('')
        setTestcaseGenReadAll('')
        setaddGenUseCaseBtn(true)
    };

    const handleDomainChange = (event) => {
        setchooseDomain(event.target.value);
    }

    const handleModule = (event) => {
        setchooseModule(event.target.value);
        getUseCaseTitleList(event.target.value)

        {
            applicationMgmt?.map((value) => {
                if (value.application_name == chooseDomain.application_name)
                    setTrelloValue(value.trello_id);

            })
        }
    }

    const handleUseCaseTitle = (event) => {
        setChooseUsecaseTitle(event.target.value);
        const values = event.target.value;
    }

    const handleFunctionalTitle = (event) => {
        setChooseFunctionalTitle(event.target.value);
    }

    const handleUseCaseFuncReq = (event) => {
        setChooseUsecaseTitle(event.target.value);
        const params = {
            "usecase_id": event.target.value.usecase_id,
        };
        setSpinner(true);
        postQueryResult("funcReqUsecaseReadAll", params)
            .then((res) => {
                setSpinner(false);
                let response = JSON.parse(res?.Result);
                console.log("func req response--->", response)
                setfuncReqReadAll(response)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err?.message });
                setSpinner(false);
            });
    }

    const getUseCaseTitleList = (module) => {
        const params = {
            "application_id": chooseDomain.application_id,
            "module": module,
        };
        setSpinner(true);
        postQueryResult("funcReqReadAll", params)
            .then((res) => {
                setSpinner(false);
                if (res.Result) {
                    let response = JSON.parse(res.Result);
                    setUseCaseResponse(response);
                }
                else
                    setNotification({ open: 'error', message: res?.Console });
                if (res.Error_Flag) {
                    setNotification({ open: 'error', message: res.message });
                    return;
                } else {
                    setNotification({ open: 'success', message: res?.Console });
                }
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err?.message });
                setSpinner(false);
            });
    }

    const addNewUseCase = (event) => {
        setnewUseCase(event.target.value);
    }

    const addUseCaseDesc = (event) => {
        setUseCaseDescription(event.target.value)
    }

    const createNewUseCase = () => {

        const params = {
            "application_id": chooseDomain.application_id,
            "module": chooseModule,
            "usecase_title": newUseCase,
            "created_by": userId,
            "trello_id": trelloValue,
        };
        setSpinner(true);
        postQueryResult("funcReqCreate", params)
            .then((res) => {
                setSpinner(false);
                getUseCaseTitleList(chooseModule)
                setNotification({ open: 'success', message: res?.Console });
                setnewUseCase('')
            })
            .catch((err) => {
                setSpinner(false);
                setNotification({ open: 'error', message: err?.message });
            });
    }

    let application_context = null;
    const generateFuncRequirement = () => {

        {
            applicationMgmt?.map((value) => {
                if (value.application_name == chooseDomain.application_name)
                    return (
                        application_context = value.application_context
                    )
            })
        }

        {
            useCaseResponse?.map((value) => {
                if (value.usecase_title == chooseUsecaseTitle.usecase_title) {
                    setTrelloValue2(value.trello_id);
                    console.log("trello value 2", value.trello_id);
                }
            })
        }

        const params = {
            "application_name": chooseDomain.application_name,
            "application_context": application_context,
            "module": chooseModule,
            "usecase_context": useCaseDescription,
        };
        setSpinner(true);
        postQueryResult("funcReqGenerate", params)
            .then((res) => {
                console.log("Requirement****", res)
                setSpinner(false);
                setfunctionalValue(false)
                setfunctionalTitle(res?.Result);
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((res) => {
                setNotification({ open: 'error', message: res?.Console });
                setSpinner(false);
            });
    }

    const AddFuncRequirement = () => {
        const params = {
            "application_id": chooseDomain.application_id,
            "usecase_id": chooseUsecaseTitle.usecase_id,
            "created_by": userId,
            "req_key": functionalTitle.map((item) => item.heading),
            "req_value": functionalTitle.map((item) => item.value),
            "trello_id": trelloValue2,
        };
        setSpinner(true);
        postQueryResult("funcReqUsecaseCreate", params)
            .then((res) => {
                setSpinner(false);
                setfunctionalTitle('')
                setfunctionalValue(true)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err?.message });
                setSpinner(false);
            });
    }

    const initialData = {
        application_name: "",
        technology: "",
        application_context: "",
        architecture_pattern: "",
        module: [],
    };
    const module_list = ["MVP1", "MVP2", "Development", "Testing"];
    const [sampleField, setSampleField] = useState(initialData);

    const handleOnChange = (e) => {
        setSampleField({
            ...sampleField,
            [e.target.name]: e.target.value,
        });
    };

    const handleModuleOnChange = (value) => {
        setSampleField({
            ...sampleField,
            ["module"]: value,
        });
    };

    const createAppMgmt = () => {
        const params = {
            "application_name": sampleField?.application_name,
            "application_context": sampleField?.application_context,
            "technology": sampleField?.technology,
            "architecture_pattern": sampleField?.architecture_pattern,
            "module": sampleField?.module,
            "created_by": userId,
        };
        setSpinner(true);
        postQueryResult("createAppMgmt", params)
            .then((res) => {
                handleCreateAppClose()
                setSpinner(false);
                setNotification({ open: 'success', message: res?.Console });
                getApplicationMgmt()
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    }

    const updateAppMgmt = (item) => {
        const params = {
            "application_id": item.application_id,
            "application_name": sampleField?.application_name || item?.application_name,
            "application_context": sampleField?.application_context || item?.application_context,
            "technology": sampleField?.technology || item?.technology,
            "architecture_pattern": sampleField?.architecture_pattern || item?.architecture_pattern,
            "module": sampleField?.module || item?.module,
            "created_by": userId,
        };
        setSpinner(true);
        postQueryResult("updateAppMgmt", params)
            .then((res) => {
                handleCreateAppClose()
                setSpinner(false);
                setNotification({ open: 'success', message: res?.Console });
                getApplicationMgmt()
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    }

    let application_context_val = null;
    const generateTestCase = () => {
        {
            applicationMgmt?.map((value) => {
                if (value.application_name == chooseDomain.application_name)
                    return (
                        application_context_val = value.application_context
                    )
            })
        }

        const params = {
            "application_name": chooseDomain.application_name,
            "application_context": application_context_val,
            "module": chooseModule,
            "functional_requirement": chooseFunctionalTitle?.heading,
            "description": chooseFunctionalTitle?.value,
        };
        setSpinner(true);
        postQueryResult("generateTestCase", params)
            .then((res) => {
                setSpinner(false);
                setaddGenUseCaseBtn(false);
                setTestcaseGenReadAll(res?.Result)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((res) => {
                setNotification({ open: 'error', message: res?.Console });
                setSpinner(false);
            });
    }

    const generateCode = () => {

        {
            applicationMgmt?.map((value) => {
                if (value.application_name == chooseDomain.application_name)
                    return (
                        application_context_val = value.application_context
                    )
            })
        }

        const params = {
            "application_name": chooseDomain.application_name,
            "application_context": application_context_val,
            "module": chooseModule,
            "functional_requirement": chooseFunctionalTitle?.heading,
        };

        setSpinner(true);
        setCodeResult("");

        postQueryResult("generateCode", params)
            .then((res) => {
                setSpinner(false);
                setCodeResult(res?.Result)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((res) => {
                setNotification({ open: 'error', message: res?.Console });
                setSpinner(false);
            });
    }

    const deployCode = () => {

        const params = {

        };
        setSpinner(true);
        postQueryResult("codeDeploy", params)
            .then((res) => {
                setSpinner(false);
                setDeployLink(res?.Result);
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((res) => {
                setNotification({ open: 'error', message: res?.Console });
                setSpinner(false);
            });
    }

    const addGenerateTestCase = () => {
        const params = {
            "application_id": chooseDomain.application_id,
            "usecase_id": chooseUsecaseTitle.usecase_id,
            "created_by": userId,
            "requirement_id": chooseFunctionalTitle.requirement_id,
            "testcase_key": testcaseGenReadAll.map((item) => item.heading),
            "testcase_value": testcaseGenReadAll.map((item) => item.value)
        };
        setSpinner(true);
        postQueryResult("addGenerateTestcase", params)
            .then((res) => {
                setSpinner(false);
                setTestcaseGenReadAll('')
                setaddGenUseCaseBtn(true)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((res) => {
                setNotification({ open: 'error', message: res?.Console });
                setSpinner(false);
            });
    }

    const getApplicationMgmt = (sample) => {
        setSpinner(true);

        getQueryResult("readAll", sample)
            .then((res) => {
                setSpinner(false);
                let response = JSON.parse(res.Result);
                setApplicationMgmt(response)
                if (res.Error_Flag) {
                    setNotification({ open: 'error', message: res.message });
                    return;
                }
                setNotification({ open: "success", message: res?.Console });
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
                setNotification({ open: "error", message: err.message });
            });
    };

    useEffect(() => {
        getApplicationMgmt()
    }, [userId])

    const handlDelete = (i) => {
        const newArray = functionalTitle.filter((item, index) => index !== i);
        setfunctionalValue(false)
        setfunctionalTitle(newArray);
    }

    const handlTestCaseGenDelete = (i) => {
        const newArray = testcaseGenReadAll.filter((item, index) => index !== i);
        setaddGenUseCaseBtn(false)
        setTestcaseGenReadAll(newArray);
    }

    const handleEditList = (item) => {
        const params = {
            "application_id": item.application_id,
        };
        setSpinner(true);
        postQueryResult("readOne", params)
            .then((res) => {
                setSpinner(false);
                let response = JSON.parse(res.Result);
                setreadOneAppDetails(response)
                handleEditAppOpen()
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    }

    return (
        <SolutionContainer snackbar={notification}>
            {/* <Collapse text="Description"> */}
            <DemoContainer>
                <Grid container style={{ width: "100%", display: "flex" }}>
                    <Grid item xs={12} style={{ float: "right", marginLeft: "82%" }}>
                        <LinkContainer>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                style={{ marginTop: "-40px" }}
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://trello.com/b/hViVm6OK"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    User Story
                                </a>
                            </StyledButton>
                        </LinkContainer>
                    </Grid>
                    <Grid item style={{ width: "20%", marginTop: "-20px" }}>
                        <AppBar position="static" color="default">
                            <StyledTabs
                                value={value}
                                onChange={tabhandleChange}
                                orientation='vertical'
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}>
                                <Tab label="Application Managment" />
                                <Tab label="Functional Requirement" />
                                <Tab label="View Functional Requirement" />
                                <Tab label="Test Case Generation" />
                                <Tab label="Code Generation" />
                            </StyledTabs>
                        </AppBar>
                    </Grid>
                    <Grid item style={{ width: "80%", marginTop: "-20px" }}>
                        <TabPanel value={value} index={0}>
                            <Grid item style={{ float: "right" }}>
                                <StyledButton
                                    id="btn2"
                                    variant="contained"
                                    color="primary"
                                    helperText="Please select a txt file only"
                                    onClick={handleCreateAppOpen}>
                                    Create New App Item
                                </StyledButton>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                alignItems="center">

                                <div style={{ width: "100%" }}>
                                    <Grid
                                        container
                                        xs={12}
                                        spacing={0}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <HeaderCol align="start">App ID</HeaderCol>
                                                            <HeaderCol align="start">App Name</HeaderCol>
                                                            <HeaderCol align="start">Date</HeaderCol>
                                                            <HeaderCol align="start">Created By</HeaderCol>
                                                            <HeaderCol align="start">Action</HeaderCol>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {applicationMgmt.map((item, index) => (
                                                            <TableRow>
                                                                <TableCell>
                                                                    {item.application_id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.application_name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.created_date}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.created_by}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faEdit} color="blue" onClick={() => handleEditList(item)} />&nbsp;&nbsp;
                                                                    {/* <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faTrash} color="red" onClick={() => handlDelete(item)}/> */}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center">
                                <div style={{ width: "100%" }}>
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Application</InputLabel>
                                            <Select
                                                value={chooseDomain}
                                                onChange={handleDomainChange}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {applicationMgmt?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>
                                                        {value.application_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Module</InputLabel>
                                            <Select
                                                value={chooseModule}
                                                onChange={handleModule}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {applicationMgmt?.map((value) => {
                                                    if (value.application_name == chooseDomain.application_name)
                                                        return (value.module?.map((module, i) => (
                                                            <MenuItem key={i} value={module}>{module}</MenuItem>
                                                        )))
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose UseCaseTitle</InputLabel>
                                            <Select
                                                value={chooseUsecaseTitle}
                                                onChange={handleUseCaseTitle}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {useCaseResponse?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>{value.usecase_title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                label="Add New UsecaseTitle"
                                                variant="outlined"
                                                style={{ marginTop: "5px" }}
                                                value={newUseCase}
                                                onChange={addNewUseCase}
                                                fullWidth
                                            />
                                        </FormControl>
                                        <StyledButton
                                            id="btn2"
                                            style={{ margin: "15px 10px" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={createNewUseCase}>Add UseCase</StyledButton>
                                    </Grid>
                                    <TextField
                                        multiline
                                        label="Enter the Usecase Description"
                                        variant="outlined"
                                        style={{ marginTop: "5px" }}
                                        onChange={addUseCaseDesc}
                                        fullWidth
                                    />
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            onClick={generateFuncRequirement}>
                                            Generate Functional Requirement
                                        </StyledButton>
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            disabled={functionalValue}
                                            onClick={AddFuncRequirement}>
                                            Add Functional Requirement
                                        </StyledButton>
                                    </Grid>
                                    <Grid item style={{ marginTop: "20px" }}>
                                        {functionalTitle && functionalTitle.length > 0 && functionalTitle?.map((val, i) => (
                                            <>
                                                <h4 style={{ float: "left", margin: "0px 5px" }}>{val.heading}</h4>
                                                <TextField
                                                    name="functional_requirement"
                                                    multiline
                                                    variant="outlined"
                                                    fullWidth
                                                    value={val.value}
                                                    style={{ width: "95%", marginTop: "5px" }}
                                                />
                                                <FontAwesomeIcon style={{ cursor: 'pointer', margin: "30px 0px 0px 20px" }} icon={faTimesCircle} color="red" onClick={() => handlDelete(i)} />
                                            </>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center">
                                <div style={{ width: "100%" }}>
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Application</InputLabel>
                                            <Select
                                                value={chooseDomain}
                                                onChange={handleDomainChange}
                                                style={{ width: "250px", margin: "10px" }}>
                                                {applicationMgmt?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>
                                                        {value.application_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Module</InputLabel>
                                            <Select
                                                value={chooseModule}
                                                onChange={handleModule}
                                                style={{ width: "250px", margin: "10px" }}>
                                                {applicationMgmt?.map((value) => {
                                                    if (value.application_name == chooseDomain.application_name)
                                                        return (value.module?.map((module, i) => (
                                                            <MenuItem key={i} value={module}>{module}</MenuItem>
                                                        )))
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose UseCaseTitle</InputLabel>
                                            <Select
                                                value={chooseUsecaseTitle}
                                                onChange={handleUseCaseFuncReq}
                                                style={{ width: "250px", margin: "10px" }}>
                                                {useCaseResponse?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>{value.usecase_title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginTop: "20px" }}>
                                        {funcReqReadAll && funcReqReadAll.length > 0 && funcReqReadAll.map((val, i) => (
                                            <>
                                                <h4 style={{ float: "left", margin: "0px 5px" }}>{val.heading}</h4>
                                                <TextField
                                                    name="functional_requirement"
                                                    multiline
                                                    variant="outlined"
                                                    fullWidth
                                                    value={val.value}
                                                    style={{ width: "95%", marginTop: "5px" }}
                                                />
                                            </>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center">
                                <div style={{ width: "100%" }}>
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Application</InputLabel>
                                            <Select
                                                value={chooseDomain}
                                                onChange={handleDomainChange}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {applicationMgmt?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>
                                                        {value.application_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Module</InputLabel>
                                            <Select
                                                value={chooseModule}
                                                onChange={handleModule}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {applicationMgmt?.map((value) => {
                                                    if (value.application_name == chooseDomain.application_name)
                                                        return (value.module?.map((module, i) => (
                                                            <MenuItem key={i} value={module}>{module}</MenuItem>
                                                        )))
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose UseCaseTitle</InputLabel>
                                            <Select
                                                value={chooseUsecaseTitle}
                                                onChange={handleUseCaseFuncReq}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {useCaseResponse?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>{value.usecase_title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose FunctionalTitle</InputLabel>
                                            <Select
                                                value={chooseFunctionalTitle}
                                                onChange={handleFunctionalTitle}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {funcReqReadAll && funcReqReadAll.length > 0 && funcReqReadAll.map((value, i) => (
                                                    <MenuItem key={i} value={value}>{value.heading}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <TextField
                                        id='outlined-read-only-input'
                                        multiline
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginTop: "5px" }}
                                        fullWidth
                                        value={chooseFunctionalTitle?.value}
                                    />

                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            onClick={generateTestCase}>
                                            Generate TestCase
                                        </StyledButton>
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            disabled={addGenUseCaseBtn}
                                            onClick={addGenerateTestCase}>
                                            Add Generated TestCase
                                        </StyledButton>
                                    </Grid>
                                    <Grid item style={{ marginTop: "20px" }}>
                                        {testcaseGenReadAll && testcaseGenReadAll.length > 0 && testcaseGenReadAll?.map((val, i) => (
                                            <>
                                                <h4 style={{ float: "left", margin: "0px 5px" }}>{val.heading}</h4>
                                                <TextField
                                                    name="functional_requirement"
                                                    multiline
                                                    variant="outlined"
                                                    fullWidth
                                                    value={val.value}
                                                    style={{ width: "95%", marginTop: "5px" }}
                                                />
                                                <FontAwesomeIcon style={{ cursor: 'pointer', margin: "30px 0px 0px 20px" }} icon={faTimesCircle} color="red" onClick={() => handlTestCaseGenDelete(i)} />
                                            </>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center">
                                <div style={{ width: "100%" }}>
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Application</InputLabel>
                                            <Select
                                                value={chooseDomain}
                                                onChange={handleDomainChange}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {applicationMgmt?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>
                                                        {value.application_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Module</InputLabel>
                                            <Select
                                                value={chooseModule}
                                                onChange={handleModule}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {applicationMgmt?.map((value) => {
                                                    if (value.application_name == chooseDomain.application_name)
                                                        return (value.module?.map((module, i) => (
                                                            <MenuItem key={i} value={module}>{module}</MenuItem>
                                                        )))
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose UseCaseTitle</InputLabel>
                                            <Select
                                                value={chooseUsecaseTitle}
                                                onChange={handleUseCaseFuncReq}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {useCaseResponse?.map((value, i) => (
                                                    <MenuItem key={i} value={value}>{value.usecase_title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose FunctionalTitle</InputLabel>
                                            <Select
                                                value={chooseFunctionalTitle}
                                                onChange={handleFunctionalTitle}
                                                style={{ width: "420px", margin: "10px" }}>
                                                {funcReqReadAll && funcReqReadAll.length > 0 && funcReqReadAll.map((value, i) => (
                                                    <MenuItem key={i} value={value}>{value.heading}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginTop: "10px", float: "left" }}>
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            onClick={generateCode}
                                        >
                                            Generate Code
                                        </StyledButton>
                                    </Grid>

                                    {codeResult && (<>
                                        <Grid item style={{ marginTop: "10px", float: "right" }}>
                                            <StyledButton
                                                id="btn2"
                                                variant="contained"
                                                color="primary"
                                                onClick={generateCode}
                                            >
                                                Reset Code
                                            </StyledButton>
                                        </Grid>
                                    </>)
                                    }

                                    {codeResult && (<>
                                        <Grid item style={{ marginTop: "10px", float: "left" }}>
                                            <BlackContainer>
                                                <ul style={{ textAlign: "left" }}> {codeResult.split('\n').map((valuem, index) => (
                                                    <>
                                                        {valuem}<br />
                                                    </>
                                                ))}
                                                </ul>
                                            </BlackContainer>
                                        </Grid><br />
                                        <Grid item style={{ marginTop: "10px", float: "center" }}>
                                            <StyledButton
                                                id="btn2"
                                                variant="contained"
                                                color="primary"
                                                onClick={deployCode}
                                            >
                                                Deploy
                                            </StyledButton>
                                        </Grid>
                                        {deployLink && (<>
                                            <Grid item style={{ marginTop: "10px", float: "center" }}>
                                                <StyledButton
                                                    variant="outlined"
                                                    color="primary"
                                                    size="large"
                                                >
                                                    <a
                                                        href={deployLink}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                       Visit Application
                                                    </a>
                                                </StyledButton>
                                            </Grid>
                                        </>)}
                                    </>)}
                                </div>
                            </Grid>
                        </TabPanel>
                    </Grid>
                </Grid>
            </DemoContainer>
            {/* </Collapse> */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid item>

                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ color: "red" }}>Deny</Button>
                    <Button style={{ color: "green" }}>Approve</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={createApp}
                onClose={handleCreateAppClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                {readOneAppDetails && readOneAppDetails.length > 0 ? readOneAppDetails.map((item, index) => (
                    <>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Grid item>
                                    <TextField
                                        multiline
                                        name="application_name"
                                        label="Application Name"
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={item?.application_name}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <TextField
                                        multiline
                                        name="technology"
                                        label="Technology"
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={item?.technology}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <TextField
                                        multiline
                                        name="application_context"
                                        label="Application Context"
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={item?.application_context}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <TextField
                                        multiline
                                        name="architecture_pattern"
                                        label="Architecture Pattern"
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={item?.architecture_pattern}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <Autocomplete
                                        style={{ marginTop: "10px" }}
                                        multiple
                                        name="module"
                                        label="Module"
                                        id="tags-filled"
                                        options={module_list.map((option) => option)}
                                        defaultValue={item?.module.map((option) => option)}
                                        freeSolo
                                        onChange={(event, value) => handleModuleOnChange(value)}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                />
                                            ))}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                multiline
                                                name="module"
                                                label="Module"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined" />
                                        )}
                                    />
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <StyledButton style={{ color: "green" }} onClick={() => updateAppMgmt(item)}>Submit</StyledButton>
                        </DialogActions>
                    </>
                ))
                    :
                    <>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Grid item>
                                    <TextField
                                        multiline
                                        name="application_name"
                                        label="Application Name"
                                        variant="outlined"
                                        fullWidth
                                        value={sampleField?.application_name}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <TextField
                                        multiline
                                        name="technology"
                                        label="Technology"
                                        variant="outlined"
                                        fullWidth
                                        value={sampleField?.technology}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <TextField
                                        multiline
                                        name="application_context"
                                        label="Application Context"
                                        variant="outlined"
                                        fullWidth
                                        value={sampleField?.application_context}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <TextField
                                        multiline
                                        name="architecture_pattern"
                                        label="Architecture Pattern"
                                        variant="outlined"
                                        fullWidth
                                        value={sampleField?.architecture_pattern}
                                        onChange={handleOnChange}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ marginTop: "10px" }}
                                    />
                                    <Autocomplete
                                        style={{ marginTop: "10px" }}
                                        multiple
                                        name="module"
                                        label="Module"
                                        id="tags-filled"
                                        options={module_list.map((option) => option)}
                                        freeSolo
                                        onChange={(event, value) => handleModuleOnChange(value)}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                multiline
                                                name="module"
                                                label="Module"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined" />
                                        )}
                                    />
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <StyledButton style={{ color: "green" }} onClick={createAppMgmt}>Submit</StyledButton>
                        </DialogActions>
                    </>
                }
            </Dialog>
            {showSpinner && <Spinner text="Loading..." />}

        </SolutionContainer>
    );
};

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tablepanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <PanelContainer>{children}</PanelContainer>}
        </div>
    );
}

export default memo(BusinessRequirement);


export const StyledListitem = styled(ListItem)`
  &.MuiListItem-root {
    color: inherit;
    float: left;
    width: auto;
    padding: 0;
    position: relative;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledSearch = styled(TextField)`
  &.MuiTextField-root{
    min-width: 50%;
    .MuiFormLabel-root, .MuiInputBase-root {
      color: ${(props) => props.chageColor && '#ffff'};
      
    };
    &.MuiOutlinedInput-notchedOutline{
      border-color: ${(props) => props.chageColor && '#ffff'}
    };
    opacity: ${(props) => (props.hide ? 0 : 1)}
  }
`;

const StyledTextField = styled(TextField)`
&.MuiTextField-root{
  width: ${(props) => (props.width ? props.width : "90%")};
  @media only screen and (max-width: 450px) {
    max-width: 80%;
    margin-top: 10px;
    .MuiInputBase-root{
       margin-top: 15px;
    }
  }
};
label + .MuiInput-formControl{
    margin-top: 10px;
}
`;

const Label = styled.div`
  font-size: 12px;
  padding: 4px;
`;

const BlackContainer = styled.div`

border: 1px solid black;
max-height: 350px;
overflow: auto;
width: 840px;
`;

const FlexContainer = styled.div`
  display: flex;
  position: relative;
`;

const GridBox = styled(Grid)`
padding: 10px;
border: 1px solid grey;
max-height: 400px;
overflow: auto;
`;

const DivBox = styled.div`
padding: 10px;
border: 1px solid grey;
max-height: 400px;
overflow: auto;
`;

const GridHidden = styled(Grid)`
display:none;
`;

const StyledEditor = styled(JoditEditor)`
  .jodit-workplace {
    max-height: 400px;
  }
`;


const CollapseDiv = styled.div`
margin-top: -5px;
height: 420px;
overflow: auto;
`;

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

export const StyledTabs = styled(Tabs)`
.MuiTab-root{
    text-transform: none;
    font-size: 13px;
}

.Mui-selected{
    background-color: #ff0055;
    color: white;
    font-weight: bold;
    text-transform: none;
  }

@media only screen and (max-width: 950px) {
  .MuiTabs-flexContainer{
    flex-wrap: wrap;
    .PrivateTabIndicator-colorPrimary-7{
      display: none;
    }
    .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
  }
}
@media only screen and (max-width: 650px) {
    background-color: #ffff;
    font-size: 12px;
    .MuiTabs-flexContainer{
      flex-direction: column;
      .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
    }
`;

const HeaderContainer = styled(Paragraph)`
  display: flex;
  align-items: center;
  justify-content: center;
  strong {
    color: #2941b7;
  }
`;

const StyledHeading = styled.div`
  width: 35%;
  margin: 0px 1rem;
  height: 3px;
  background: #fc7703;
  color: #2941b7;
  @media only screen and (max-width: 450px) {
    width: 20%;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;

export const HeaderCol = styled(TableCell)`
background: ${props => props.bgColor ? props.bgColor : 'rgb(60, 64, 175)'};
border-right: ${props => props.bgColor && '1px solid #ffff'};
color: white;
`;