import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

const WomensHealthWellBeing = () => {
  const data = [
    ["Women's Reproductive Health in the United States (Timeline)", 'https://public.tableau.com/profile/vlife.virtusa#!/vizhome/WomensReproductiveHealthTimeline/WomensReproductiveHealthTimeline '],
    ["Women's Health and Well-Being (U.S.)", 'https://public.tableau.com/profile/vlife.virtusa#!/vizhome/WomensHealthandWellBeing/WomensHealthandWellBeing '],
  ];

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Men and women have various health concerns, but a number of them
            affect only women - birth control, pregnancy, menstrual cycles,
            menopause, and many more. Although there are common health concerns
            among men and women, the symptoms faced by either may differ greatly
            and may require different treatments. In the following
            visualizations, we exclusively analyze the reproductive health and
            well-being of women residing in the United States.
          </p>
          <p>
            <b>The first set of visualizations</b>
            {' '}
            span over a timeline from the
            year 1970-2020, focusing on the number of women falling under the
            reproductive age (15-49), as well as the change in the fertility
            rate of women throughout the years. The fertility rate indicates the
            average number of children that a woman is likely to have at the end
            of their reproductive age. On careful analysis of the graph, we can
            see that over the years, the fertility rate of women has decreased
            from a very high count. We also observe, from the second graph, that
            the count of women (in thousands) that fall under the reproductive
            age category (15-49) has been steadily increasing over the years. In
            addition to this, although usage of contraceptives was very sparse
            in the earlier years, as time progressed, the number of women who
            were made aware of, and started making use of modern contraceptive
            methods (oral contraceptive pills, intrauterine device (IDU), female
            and male condoms, female and male sterilization, implants, etc.)
            along with their partners have steadily increased. The data used has
            been derived from the Global Health Repository by the World Health
            Organization.
          </p>
          <p>
            <b>The second set of visualizations</b>
            {' '}
            focus on preventive care.
            Preventive care plays a vital role in the upkeeping of women's
            reproductive health, early diagnosis, and better patient care.
            Taking frequent health tests for Cholesterol levels and HIV can help
            a woman be aware of their own health and take care of themselves
            better. In addition to that, regular mammograms and pap smear tests
            can help in screening and early diagnosis of breast cancer and
            cervical cancer. The visualizations show the percentage of women
            (out of the count of women in the reproductive age in that
            particular State) that had taken the tests over a span of two years.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.who.int/data/maternal-newborn-child-adolescent-ageing/indicator-explorer-new/mca/total-fertility-rate-(live-births-per-woman)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source1
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.who.int/data/maternal-newborn-child-adolescent-ageing/indicator-explorer-new/mca/contraceptive-prevalence---any-modern-method-(among-women-aged-15-49-years-)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source2
                  </a>
                </StyledButton>
              </Grid>
              &nbsp;&nbsp;&nbsp;
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.who.int/data/maternal-newborn-child-adolescent-ageing/indicator-explorer-new/mca/women-of-reproductive-age-(15-49-years)-population-(thousands)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source3
                  </a>
                </StyledButton>
              </Grid>
              &nbsp;&nbsp;
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://statusofwomendata.org/explore-the-data/methodology/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source4
                  </a>
                </StyledButton>
              </Grid>

            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <Typography variant="h6" gutterBottom>
          <FontAwesomeIcon icon={faInfoCircle} />
          {' '}
          Visualizations
        </Typography>
        <br />
        <Grid container xs={12} spacing={5} direction="row">
          {data.map((data) => (
            <Grid item xs={12} sm={6} md={3}>
              <A target="_blank" href={data[1]}>
                <StyledCard>{data[0]}</StyledCard>
              </A>
            </Grid>
          ))}
        </Grid>
        <br />
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(WomensHealthWellBeing);
const A = styled.a`
  text-decoration: none;
`;
const StyledCard = styled.div`
  height: 100%;
  text-align: center;
  background-color: #0080ff;
  color: white;
  border-radius: 4px;
  margin-left: 40px;
  padding: 12px;
  flex-grow: 0;
  flex-basis: 25%;
  margin-bottom: 0.35rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075rem;
`;
