import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import Highcharts from "highcharts";

// local components

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";

const MultiAccuracy = () => {
  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [result, setSample] = useState(false);
  const [modelData, setModelData] = useState(false);
  const [multiAccuracy, setMultiAccuracy] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "z1dApps", "multiAccuracy",
  );

  const formHighChart = (data) => {
    Highcharts.chart('container',
      {
        chart:
                {
                  type: 'column',

                },
        title:
                {
                  text: "Accuracy on Race Level",
                },
        subtitle:
                {

                },
        xAxis: {
          categories: ["All races", "race_asian", "race_black", "race_hispanic", "race_native", "race_other", "race_white"],
          title: {
            text: null,
          },
        },
        yAxis:
                {
                  min: 0,
                  title:
                    {
                      text: "Percentage",

                    },
                  labels:
                    {
                      overflow: 'justify',
                    },
                  max: 100,
                },
        tooltip:
                {
                  valueSuffix: '',
                  headerFormat: '',
                },
        legend:
                {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle',
                },
        series: [{
          name: 'Before Multi Accuracy',
          data: data[2],
          color: '#b679e5',
        }, {
          name: 'After Multi Accuracy',
          data: data[3],
          color: '#bdef9e',
        },
        ],
      });
    Highcharts.chart('container1',
      {
        chart:
                {
                  type: 'column',
                },
        title:
                {
                  text: "Metrics Comparison",
                },
        subtitle:
                {

                },
        xAxis: {
          categories: ["Accuracy", "Recall", "Precision", "AUC ROC Score"],
          title: {
            text: null,
          },
        },
        yAxis:
                {
                  min: 0,
                  title:
                    {
                      text: "Percentage",

                    },
                  labels:
                    {
                      overflow: 'justify',
                    },
                  max: 100,
                },
        tooltip:
                {
                  valueSuffix: '',
                  headerFormat: '',
                },
        legend:
                {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle',
                },
        series: data[4],
      });
  };

  const getSample = () => {
    setSample('');
    setModelData('');
    setSpinner(true);

    getQueryResult("sampleData")
      .then((res) => {
        setSpinner(false);
        setSample(true);
        const previewData = JSON.parse(res.Data);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setField(fields);
        setDataRows(dataRows);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getModelData = () => {
    setMultiAccuracy('');
    setModelData(true);
  };

  const getMultiAccuracy = () => {
    setMultiAccuracy('');
    setSpinner1(true);

    getQueryResult("ModelBuilding")
      .then((res) => {
        setSpinner1(false);
        setMultiAccuracy(true);
        formHighChart(res);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Even when the overall accuracy is high, these systems may exhibit systematic biases that harm
            specific subpopulations; such biases may arise inadvertently due to underrepresentation in the
            data used to train a machine-learning model, or as the result of intentional malicious
            discrimination.
          </p>
          <p>
            Introducing *multiaccuracy* auditing and post-processing to ensure accurate predictions across
            *identifiable subgroups*. MULTIACCURACY-BOOST, works in any setting where we have black-box access
            to a predictor and a relatively small set of labeled data for auditing;
          </p>
          <p>
            If the initial model is accurate on an identifiable subgroup, then the post-processed model will
            be also. We experimentally demonstrate the effectiveness of the approach to improve the accuracy
            among minority subgroups in a Type II Diabetes dataset.
          </p>
          <p>
            {' '}
            <strong>Scenario: </strong>
            In an EMR dataset of US population it is bound to have populations from
            various races and majority of them being White. Crucial examination of classifier accuracies on other
            races reveal a drop compared to overall accuracy.
          </p>
          <p>
            {' '}
            <strong>Challenge: </strong>
            To improve the sub-group accuracies of minority races in an EMR dataset
            without impacting the base classifier accuracy.
          </p>
          <p>
            {' '}
            <strong>Dataset: </strong>
            A well balanced dataset of Type II Diabetes population comprising majority
            of white population.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/SAIL/Multiaccuracy/T2D_ma.ipynb"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting%20T2D%20patients/Data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://arxiv.org/abs/1805.12317"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>

            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSample}
            >
              Sample Data
            </StyledButton>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <section>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    {fields.map((field) => (
                      <TableCell key={field.name} align="left">
                        {' '}
                        <strong>{field.name}</strong>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataRows.map((row) => (
                    <TableRow key={row.index}>
                      {fields.map((field) => (
                        <TableCell align="left">
                          {field.name === "index"
                            ? row[field.name] + 1
                            : row[field.name]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <Grid container xs={12} spacing={2} direction="row" justify="center">
              <Grid item xs={10} sm={8} md={6}>
                <div>
                  <TableDiv>
                    <TableHead>
                      <TableRow>
                        <StyledTableHead colSpan={2} align="center"><strong>Details About Dataset</strong></StyledTableHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key="0">
                        <TableCell align="left">Total Number of Records</TableCell>
                        <TableCell align="center">8000</TableCell>
                      </TableRow>
                      <TableRow key="1">
                        <TableCell align="left">Target Split Level</TableCell>
                        <TableCell align="center">
                          <span>0: 4000 </span>
                          {' '}
                          <span style={{ marginLeft: '70px' }}>1:4000</span>
                        </TableCell>
                      </TableRow>
                      <TableRow key="2">
                        <TableCell colSpan={2} align="center"><strong>Race Level Split</strong></TableCell>
                      </TableRow>
                      <TableRow key="3">
                        <TableCell align="left">Asian</TableCell>
                        <TableCell align="center">189</TableCell>
                      </TableRow>
                      <TableRow key="3">
                        <TableCell align="left">Black</TableCell>
                        <TableCell align="center">706</TableCell>
                      </TableRow>
                      <TableRow key="3">
                        <TableCell align="left">Hispanic</TableCell>
                        <TableCell align="center">660</TableCell>
                      </TableRow>
                      <TableRow key="3">
                        <TableCell align="left">Native</TableCell>
                        <TableCell align="center">51</TableCell>
                      </TableRow>
                      <TableRow key="3">
                        <TableCell align="left">Other</TableCell>
                        <TableCell align="center">9</TableCell>
                      </TableRow>
                      <TableRow key="3">
                        <TableCell align="left">White</TableCell>
                        <TableCell align="center">6385</TableCell>
                      </TableRow>
                    </TableBody>
                  </TableDiv>
                </div>
              </Grid>
            </Grid>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getModelData}
            >
              Build Model
            </StyledButton>

            {modelData
                                && (
                                <section>
                                  <Grid container xs={12} spacing={5} direction="row" justify="center" align="center" alignItems="center">
                                    <Grid item xs={10} sm={5}>
                                      <center>
                                        <p><strong>Base Model Metrics</strong></p>
                                        <TableDiv>
                                          <TableHead>
                                            <TableRow>
                                              <StyledTableHead align="left">Metric</StyledTableHead>
                                              <StyledTableHead align="left">Value</StyledTableHead>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow key="0">
                                              <TableCell align="left">Accuracy</TableCell>
                                              <TableCell align="left">93</TableCell>
                                            </TableRow>
                                            <TableRow key="1">
                                              <TableCell align="left">AUC</TableCell>
                                              <TableCell align="left">97</TableCell>
                                            </TableRow>
                                            <TableRow key="2">
                                              <TableCell align="left">Precision</TableCell>
                                              <TableCell align="left">90</TableCell>
                                            </TableRow>
                                            <TableRow key="3">
                                              <TableCell align="left">Recall</TableCell>
                                              <TableCell align="left">93</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </TableDiv>
                                      </center>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                      <ButtonDiv>
                                        <StyledButton
                                          variant="contained"
                                          color="primary"
                                          onClick={getMultiAccuracy}
                                        >
                                          Retrain with MultiAccuracy
                                        </StyledButton>
                                      </ButtonDiv>
                                    </Grid>
                                  </Grid>
                                  {showSpinner1 && <Spinner text="Loading..." />}
                                  {multiAccuracy
                                        && (
                                        <section>
                                          <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                                            <Grid item xs={12} sm={10} md={5}>
                                              <p><strong>Accuracy on Race Level</strong></p>
                                              <Paper>
                                                <TableDiv>
                                                  <TableHead>
                                                    <TableRow>
                                                      <StyledTableHead />
                                                      <StyledTableHead>Before Multi Accuracy</StyledTableHead>
                                                      <StyledTableHead>After Multi Accuracy</StyledTableHead>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    <TableRow key="0">
                                                      <TableCell>All Races</TableCell>
                                                      <TableCell align="center">93.2</TableCell>
                                                      <TableCell align="center">94.9</TableCell>
                                                    </TableRow>
                                                    <TableRow key="1">
                                                      <TableCell>Asian</TableCell>
                                                      <TableCell align="center">86.5</TableCell>
                                                      <TableCell align="center">91.9</TableCell>
                                                    </TableRow>
                                                    <TableRow key="2">
                                                      <TableCell>Black</TableCell>
                                                      <TableCell align="center">94.5</TableCell>
                                                      <TableCell align="center">96.9</TableCell>
                                                    </TableRow>
                                                    <TableRow key="3">
                                                      <TableCell>Hispanic</TableCell>
                                                      <TableCell align="center">93</TableCell>
                                                      <TableCell align="center">95.6</TableCell>
                                                    </TableRow>
                                                    <TableRow key="4">
                                                      <TableCell>Native</TableCell>
                                                      <TableCell align="center">58.3</TableCell>
                                                      <TableCell align="center">66.7</TableCell>
                                                    </TableRow>
                                                    <TableRow key="5">
                                                      <TableCell>Others</TableCell>
                                                      <TableCell align="center">100</TableCell>
                                                      <TableCell align="center">100</TableCell>
                                                    </TableRow>
                                                    <TableRow key="6">
                                                      <TableCell>White</TableCell>
                                                      <TableCell align="center">93.7</TableCell>
                                                      <TableCell align="center">95</TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </TableDiv>
                                              </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                              <div id="container" />
                                            </Grid>
                                          </Grid>
                                          <Grid container xs={12} spacing={5} direction="row" justify="center">
                                            <Grid item xs={12} sm={10} md={5}>
                                              <p><strong>Model Metrics After Multi Accuracy</strong></p>
                                              <TableDiv>
                                                <TableHead>
                                                  <TableRow>
                                                    <StyledTableHead align="left">Metric</StyledTableHead>
                                                    <StyledTableHead align="left">Value</StyledTableHead>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow key="0">
                                                    <TableCell align="left">Accuracy</TableCell>
                                                    <TableCell align="left">95</TableCell>
                                                  </TableRow>
                                                  <TableRow key="1">
                                                    <TableCell align="left">AUC</TableCell>
                                                    <TableCell align="left">94</TableCell>
                                                  </TableRow>
                                                  <TableRow key="2">
                                                    <TableCell align="left">Precision</TableCell>
                                                    <TableCell align="left">96</TableCell>
                                                  </TableRow>
                                                  <TableRow key="3">
                                                    <TableCell align="left">Recall</TableCell>
                                                    <TableCell align="left">95</TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </TableDiv>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                              <div id="container1" />
                                            </Grid>
                                          </Grid>
                                        </section>
                                        )}
                                </section>
                                )}

          </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(MultiAccuracy);

const Paper = styled.div`
  overflow: auto;
  margin-bottom: 3%;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
const TableDiv = styled(Table)`
  @media only screen and (max-width: 900px) {
    margin-left: 3%;
  }
  @media only screen and (max-width: 450px) {
    margin-left: 5%;
  }
`;
const ButtonDiv = styled.div`
  @media only screen and (max-width: 450px) {
    margin-left: 7%;
  }
`;
