import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailIcon from '@material-ui/icons/Mail';

export default function SocialShare({ changeColor, text }) {
  const staticPathList = ['/forgot-password', '/login', '/register', '/'];
  const body = `
    Hello~ %0d%0d
    Super pleased to invite you to self-register and try out vLife™ Healthcare and Life Sciences Marketplace.%0d%0d
    Virtusa’s vLife™ Healthcare and Life Sciences Marketplace 1000+ AI-based technologies to help you address both immediate and long term needs to navigate crisis and emerge stronger. Focused on 34 therapeutic areas including oncology, Respiratory, type 2 diabetes, orthopaedics, renal and epidemiology, the platform is equipped with vNet that offers synthetic data generation tools, computer vision, platform AI, faster AI and Innovation as a Service.%0d%0d
    We would request you to self-register on the platform (at no cost) at https://vlife.virtusa.com/register and try it out yourself. Please feel free to share it with your teams as well.%0d%0d
    Key capabilities include computer vision, synthetic data generation tools, platform AI, and faster AI algorithms to gain high-level understanding from data, digital images and videos.%0d%0d
    A detailed two minute video and information about vLife™ is available on our website https://virtusa.com/vlife as well. %0d%0d
    Please let us know your availability to setup a 30 minutes call to demonstrate the value of vLife™ accelerators platform and use cases engaged with top healthcare and life sciences companies. %0d%0d
    Thank you and best regards%0d%0d
    Team vLife™%0d%0d
    Virtusa%0d%0d
    Marketing@virtusa.com
  `;

  const openPopup = (e) => {
    const data = e.currentTarget.id;
    let url;
    const isStaticText = staticPathList.indexOf(window.location.pathname) > -1;

    switch (data) {
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=https://vlife.virtusa.com${window.location.pathname
        }&text=${isStaticText
          ? "Explore vLife™ to unlock the potential of AI/ML-powered solutions"
          : `Hi, Please checkout the solution on vLife™, ${text}`
        }`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=https://vlife.virtusa.com${window.location.pathname
        }&text=${isStaticText
          ? "Explore vLife™ to unlock the potential of AI/ML-powered solutions"
          : `Hi, Please checkout the solution on vLife™, ${text}`
        }`;
        break;
      default:
        break;
    }
    window.open(url, '_blank', 'width=450,height=450,left=0,top=0`');
  };
  return (
    <>
      <FooterIcons changeColor={changeColor}>
        <span
          onClick={openPopup}
          id="twitter"
          referrerPolicy="no-referrer"
        >
          <TwitterIcon />
        </span>
        <span
          id="linkedin"
          onClick={openPopup}
        >
          <LinkedInIcon />
        </span>
        <a href={`mailto:?subject=RE: Explore vLife to unlock the potential of AI/ML-powered solutions&body=${body}`} title="vlife">
          <MailIcon />
        </a>
      </FooterIcons>
    </>
  );
}

const FooterIcons = styled.section`
text-align: left;
margin-left: 10px;
  .MuiSvgIcon-root{
    font-size: xx-large;
    color: ${(props) => (props.changeColor ? "#ffff" : "rgb(29, 155, 240)")};
    margin: 5px;
    &:hover {
      transform: scale(1.1);
    }
  `;
