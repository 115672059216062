import React, { memo, useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, Button } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '../../../../../../styles/common';
import JsonDataTable from '../../../../../common/JsonDataTable';
import { setInputData } from '../../../../../../store/actions/vnet';
import { RenameContainer } from '../DataTransformation/RenameColumn';
import { isObjKeyhasval } from '../../util';
import { InfoText } from '../ModelOptimization/DataShapley';
import { StyledInput } from '../../InputForm';

export const BinarizerForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const dispatch = useDispatch();
  const [fromData, setFromdata] = useState({});
  const [dropdownList, setdropdownList] = useState(null);
  const [change, setChange] = useState(true);
  const [fromValid, setFromValid] = useState(false);
  const [toValid, setToValid] = useState(false);
  const [alert, setAlert] = useState('');
  const [error, setError] = useState({ name: ' ', value: ' ' });
  const [fromDataIndex, setfromDataIndex] = useState('1');

  const handleFromData = (e) => {
    if (Object.values(fromData).indexOf(e.target.value) == -1) {
      setFromdata({ ...fromData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
    const fromValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    setFromValid(fromValid);
    setChange(!submitValid);
  };

  const handleToData = (e) => {
    if (e.target.name) {
      dispatch(
        setInputData({
          columns: {
            ...userInputData.columns,
            [e.target.name]: Number(e.target.value),
          },
        })
      );
    }
    const toValid = !!e.target.value;
    const submitValid = toValid && fromValid;
    alert && setAlert(false);
    setToValid(toValid);
    setChange(!submitValid);
  };
  const getInputFieldLen = () =>
    Object.entries(fromData).filter((data) => data[0].startsWith('input'))
      .length;
  const addField = () => {
    const fromDataLen = getInputFieldLen();
    if (fromDataLen < dropdownList.length) {
      setFromdata({ ...fromData, [`input${fromDataIndex}`]: '' });
    }
    setfromDataIndex(fromDataIndex + 1);
    setChange(true);
    setToValid(false);
    setFromValid(false);
  };
  const isAlpha = (text) => /\d/.test(text);

  const validateInput = (e) => {
    const { name, value } = e.target;
    const error = {
      ...error,
    };

    if (!value) {
      error[name] = 'Please Enter a Value';
      error[2] = true;
      setChange(true);
    }   
    setError(error);
  };

  const removeField = (e) => {
    const list = { ...fromData };
    const globallist = { ...userInputData.columns };
    delete globallist[list[e]];
    delete list[e];
    setFromdata(list);
    dispatch(
      setInputData({
        columns: globallist,
      })
    );
    setChange(false);
  };

  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);
  useEffect(() => {
    if (userInputData?.columns) {
      const formData = {};
      let fromDataLen = 0;
      Object.keys(userInputData?.columns).map((data, indx) => {
        formData[`disabled${indx}`] = data;
        fromDataLen++;
      });
      setFromdata(formData);
      setfromDataIndex(fromDataLen);
      setChange(false);
    } else {
      setFromdata({ input0: '' });
    }
  }, []);
  useEffect(() => {
    if (getInputFieldLen() == 0) {
      setChange(false);
    }
  }, [fromData]);

  const handleFormReset = () => {
    setFromdata({ input0: '' });
    dispatch(
      setInputData({
        columns: {},
      })
    );
  };

  const isFormValid = () => {
    if (
      !userInputData?.columns ||
      Object.entries(userInputData?.columns).length == 0
    ) {
      setAlert(true);
      return false;
    }
    return true;
  };

  return (
    <RenameContainer>
      {alert && (
        <InfoText success={false}>
          {' '}
          <FontAwesomeIcon icon={faInfoCircle} /> Please choose atleast one
          column
        </InfoText>
      )}
      <Grid container spacing={3} alignItems="center">
        {Object.entries(fromData).map((data) => (
          <>
            <Grid item xs={5}>
              {data[0].startsWith('disabled') ? (
                <StyledInput
                  fullWidth
                  name={data[0]}
                  label="Column"
                  variant="outlined"
                  disabled
                  type="text"
                  required
                  value={data[1]}
                  info="Column to be binarized"
                />
              ) : (
                <StyledInput
                  name={data[0]}
                  label="Column"
                  variant="outlined"
                  onChange={handleFromData}
                  select
                  key={data[0]}
                  fullWidth
                  required
                  value={fromData && fromData[data[0]]}
                  info="Column to be binarized"
                >
                  {dropdownList?.map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      disabled={Object.values(fromData).indexOf(value) > -1}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </StyledInput>
              )}
            </Grid>
            <Grid item xs={5}>
              <StyledInput
                fullWidth
                name={fromData[data[0]]}
                label="Threshold"
                variant="outlined"
                onChange={handleToData}
                //onBlur={validateInput}
                type="number"
                requried
                disabled={data[0].startsWith('disabled')}
                info="values below or equal to this are replaced by 0, above it by 1"
                value={
                  fromData[data[0]] &&
                  userInputData?.columns?.[fromData[data[0]]]
                }
                error={error[fromData[data[0]]]}
                helperText={error[fromData[data[0]]]}
              />
            </Grid>
            {!data[0].startsWith('disabled') && (
              <Grid item xs={1}>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => removeField(data[0])}
                  size="large"
                />
              </Grid>
            )}
          </>
        ))}
      </Grid>
      <StyledButton disabled={change} onClick={addField} variant="outlined">
        +
      </StyledButton>
      {props.render({ handleFormReset, isFormValid })}
    </RenameContainer>
  );
});

