import React, { memo, useState, useEffect } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setInputData } from '../../../../../../store/actions/vnet';
import { StyledInput } from '../../InputForm';
import { StyledFormControl } from '../Dataviz/ScatterPlot';
import { LRContainer, CommonCheckbox } from './LogisticRegression';
import { validateForm, isObjKeyhasval } from '../../util';
import { subFeatureData } from '../../constants';
import InfoToolTip from '../../components/InfoToolTip';

export const DecisionTreeRegressionForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector(
    (state) => state.vnet
  );

  const [isDisabled, setInputDisable] = useState('Default');
  const [dropdownList, setdropdownList] = useState(null);
  const [defaultParams, setdefaultParams] = useState(userInputData);
  const [disableModelInfo, setDisableModelInfo] = useState(isCanvasClicked);
  const { initialData = null } = subFeatureData.Decision_Tree;
  const solverListData = {
    criterion: ['squared_error', 'friedman_mse', 'absolute_error', 'poisson'],
    splitter: ['best', 'random'],
  };
  const [isEditable, setIsEditable] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const validationData = {
    random_state: { maxNum: 100000, onlyNum: true },
    ccp_alpha: { minNum: 0, onlyFloat: true },
    random_states: { minNum: 1, onlyInt: true },
    n_estimators: { minNum: 0, onlyFloat: true },
    max_leaf_nodes: { onlyInt: true, minNum: 1 },
    min_samples_split: { onlyNum: true, minNum:1 },
    max_depth: { onlyInt: true, minNum: 1 },
    min_samples_leaf: { onlyNum: true, minNum:1, },
  };

  const handleFieldError = (errData) => {
    setFieldError({
      ...fieldError,
      ...errData,
    });
  };

  const { validateOnBlur } = validateForm(validationData, handleFieldError);
  const dispatch = useDispatch();

  const getCheckboxVal = (checked) => (checked ? 'True' : 'False');

  const handleOnChange = (e) => {
    console.log(e);
    const { value, name, checked = false } = e.target;
    const dataId = e.currentTarget.dataset.id;

    switch (dataId) {
      case 'target':
        dispatch(
          setInputData({
            ...userInputData,
            target: value,
          })
        );
        break;
      case 'model_detail':
        dispatch(
          setInputData({
            ...userInputData,
            ModelDetail: {
              ...userInputData.ModelDetail,
              [name]: value,
            },
          })
        );
        break;
      case 'model_param':
        dispatch(
          setInputData({
            ...userInputData,
            model_param: {
              ...userInputData.model_param,
              [name]:
                ['warm_start', 'bootstrap'].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          })
        );
        if (name == 'penalty') {
          setSolverList(solverListData[value]);
        }
        break;
      case 'split_param':
        dispatch(
          setInputData({
            ...userInputData,
            split_param: {
              ...userInputData.split_param,
              [name]:
                ['shuffle'].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          })
        );
        break;
      case 'isDisabled':
        setInputDisable(value);
        if (value == 'Default') {
          dispatch(
            setInputData({
              ...userInputData,
              ...defaultParams,
            })
          );
        }
        break;
      case 'isEditable':
        if (checked) {
          if (
            confirm(
              `Editing the existing model details will create a new model run and version. Do you want to continue ?`
            )
          ) {
            setDisableModelInfo(false);
            setIsEditable(checked);
          }
        } else {
          setDisableModelInfo(true);
          setIsEditable(checked);
        }
        break;
      default:
        break;
    }
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        columns: typeof value === 'string' ? value.split(',') : value,
      })
    );
    if (fieldError.columns) {
      setFieldError({ ...fieldError, columns: false });
    }
  };

  const handleFormReset = () => {
    dispatch(
      setInputData({
        ...initialData,
      })
    );
    setInputDisable('Default');
  };

  const isFormValid = () => {
    const errorObj = {};
    const validateObj = {
      target: userInputData?.target,
      Name: userInputData?.ModelDetail?.Name,
      Description: userInputData?.ModelDetail?.Description,
    };
    Object.keys(validateObj).map((key) => {
      if (!validateObj[key]) {
        errorObj[key] = 'Required Field';
      }
    });
    if (userInputData?.columns.length == 0) {
      errorObj.columns = 'Required Field';
    }
    const errData = { ...fieldError, ...errorObj };
    setFieldError(errData);

    return isObjKeyhasval(errData);
  };
  useEffect(async () => {
    // let dropdownList =
    //   '["class_1","class_2","class_3","class_4","class_5","class_6"]';
    const dropdownList = await props.getCategories();
    if (dropdownList) {
      setdropdownList(JSON.parse(dropdownList));
    }
  }, []);

  return (
    <LRContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name='Name'
            label='Name'
            variant='outlined'
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Name}
            inputProps={{ 'data-id': 'model_detail' }}
            error={fieldError.Name}
            helperText={fieldError.Name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name='Description'
            label='Description'
            variant='outlined'
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Description}
            inputProps={{ 'data-id': 'model_detail' }}
            error={fieldError.Description}
            helperText={fieldError.Description}
          />
        </Grid>
      </Grid>
      {isCanvasClicked && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isEditable}
              onChange={handleOnChange}
              inputProps={{ 'data-id': 'isEditable' }}
            />
          }
          label='Edit Model Info'
        />
      )}
      <RadioGroup
        row
        name='isDisabled'
        defaultValue='False'
        aria-label='Learn Mixture Weights'
        value={isDisabled}
        onChange={handleOnChange}
      >
        <FormControlLabel
          value='Default'
          control={
            <Radio color='primary' inputProps={{ 'data-id': 'isDisabled' }} />
          }
          label='Default'
          labelPlacement='end'
        />
        <FormControlLabel
          value='Custom'
          control={
            <Radio color='primary' inputProps={{ 'data-id': 'isDisabled' }} />
          }
          label='Custom'
          labelPlacement='end'
        />
      </RadioGroup>
      <InfoToolTip title='Customize the train test split'>
        Split Parameters
      </InfoToolTip>
      <Grid container spacing={2} alignItems='flex-start'>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='test_size'
            label='Test Size'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'split_param' }}
            value={userInputData?.split_param?.test_size}
            onBlur={validateOnBlur}
            error={fieldError.test_size}
            helperText={fieldError.test_size}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='random_state'
            label='Random State'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'split_param' }}
            value={userInputData?.split_param?.random_state}
            onBlur={validateOnBlur}
            error={fieldError.random_state}
            helperText={fieldError.random_state}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            control={
              <CommonCheckbox
                checked={userInputData?.split_param?.shuffle}
                name='shuffle'
                onChange={handleOnChange}
                inputProps={{ 'data-id': 'split_param' }}
              />
            }
            label='Shuffle'
          />
        </Grid>
      </Grid>
      <InfoToolTip title='Customize the model'>Function Parameters</InfoToolTip>
      <Grid container spacing={2} alignItems='flex-start'>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='criterion'
            label='Criterion'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            select
            defaultValue='squared_error'
            value={userInputData?.model_param?.criterion}
            info='The function used to measure the quality of a split'
          >
            {[
              'squared_error',
              'friedman_mse',
              'absolute_error',
              'poisson',
            ]?.map((value) => (
              <MenuItem key={value} value={value} data-id='model_param'>
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='splitter'
            label='Splitter'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            select
            defaultValue='best'
            value={userInputData?.model_param?.splitter}
            info='The strategy used to choose the split at each node'
          >
            {['best', 'random']?.map((value) => (
              <MenuItem key={value} value={value} data-id='model_param'>
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='max_depth'
            label='Maximum Depth'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'model_param' }}
            value={userInputData?.model_param?.max_depth}
            onBlur={validateOnBlur}
            error={fieldError.max_depth}
            helperText={fieldError.max_depth}
            info='The maximum depth of the tree'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='min_samples_split'
            label='Minimum Sample Split'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'model_param' }}
            value={userInputData?.model_param?.min_samples_split}
            onBlur={validateOnBlur}
            error={fieldError.min_samples_split}
            helperText={fieldError.min_samples_split}
            info='The minimum number of samples required to split an internal node'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='min_samples_leaf'
            label='Minimum Leaf Samples'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'model_param' }}
            value={userInputData?.model_param?.min_samples_leaf}
            onBlur={validateOnBlur}
            error={fieldError.min_samples_leaf}
            helperText={fieldError.min_samples_leaf}
            info='The minimum number of samples required to be at a leaf node'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='max_leaf_nodes'
            label='Maximum Leaf Nodes'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'model_param' }}
            value={userInputData?.model_param?.max_leaf_nodes}
            onBlur={validateOnBlur}
            error={fieldError.max_leaf_nodes}
            helperText={fieldError.max_leaf_nodes}
            info='Grow a tree with max_leaf_nodes in best-first fashion'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='random_states'
            label='Random State'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'model_param' }}
            value={userInputData?.model_param?.random_states}
            onBlur={validateOnBlur}
            error={fieldError.random_states}
            helperText={fieldError.random_states}
            info='Controls the randomness of the estimator'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name='ccp_alpha'
            label='CCP Alpha'
            variant='outlined'
            disabled={isDisabled == 'Default'}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ 'data-id': 'model_param' }}
            value={userInputData?.model_param?.ccp_alpha}
            onBlur={validateOnBlur}
            error={fieldError.ccp_alpha}
            helperText={fieldError.ccp_alpha}
            info='Complexity parameter used for Minimal Cost-Complexity Pruning'
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={12} md={6}>
          <StyledFormControl error={fieldError.columns}>
            <InputLabel id='model-evaluation'>Train Features</InputLabel>
            <Select
              multiple
              variant='outlined'
              name='features'
              disabled={disableModelInfo}
              value={userInputData?.columns || []}
              onChange={handleMultiple}
              input={<Input />}
              fullWidth
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              renderValue={(selected) => selected.join(', ')}
            >
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={userInputData.columns.indexOf(value) > -1}
                    disabled={
                      userInputData.columns.indexOf(userInputData.target) > -1
                    }
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {fieldError.columns && (
              <FormHelperText>Required Field</FormHelperText>
            )}
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledInput
            name='target'
            label='Target'
            variant='outlined'
            onChange={handleOnChange}
            select
            required
            disabled={disableModelInfo}
            fullWidth
            inputProps={{ 'data-id': 'target' }}
            value={userInputData?.target}
            error={fieldError.target}
            helperText={fieldError.target}
          >
            {dropdownList?.map((value) => (
              <MenuItem key={value} value={value} data-id='target'>
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
      </Grid>
      {props.render({ handleFormReset, isFormValid })}
    </LRContainer>
  );
});
