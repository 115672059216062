import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from "prop-types";

import getUserData from '../../utils/index.js';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isRouteAllowed = getUserData();

  return isRouteAllowed ? <Route {...rest} render={(props) => <Component {...props} />} />
    : <Redirect to={`/login?redirect=${rest.location.pathname + rest.location.search}`} />;
};

export default PrivateRoute;
PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]).isRequired,
};

/* MainLayout.defaultProps = {
  children: null,
}; */
