import * as actions from ".";

export const setProjectDetails = (solution) => ({
  type: actions.SET_PROJECT_DETAILS,
  payload: solution,
});
export const setFeatureData = (solution) => ({
  type: actions.SET_FEATURE_DATA,
  payload: solution,
});
export const setInputData = (data) => ({
  type: actions.SET_INPUT_DATA,
  payload: data,
});
export const clearInputData = () => ({
  type: actions.CLEAR_INPUT_DATA,
});
export const setResult = (data) => ({
  type: actions.SET_RESULT,
  payload: data,
});
export const clearResult = (data) => ({
  type: actions.CLEAR_RESULT,
  payload: data,
});
export const setConsole = (data) => ({
  type: actions.SET_CONSOLE,
  payload: data,
});
export const setCanvasClickData = (data) => ({
  type: actions.IS_CANVA_CLICK,
  payload: data,
});
export const setCanvaList = (data) => ({
  type: actions.SET_CANVA_LIST,
  payload: data,
});
export const setResultTab = (val) => ({
  type: actions.SET_RESULT_TABVAL,
  payload: val,
});
