import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StyledCard from './CardElement';
import Styles from './DataopsStyles';
import demo2Img from '../../../assets/images/dataops/demo2.webp';
import workspaceImg from '../../../assets/images/dataops/workspace.webp';
import newProjectImg from '../../../assets/images/dataops/newProject.webp';
import { StyledBackdrop } from '../../../styles/common';
import Spinner from '../../common/loading/Spinner';

import styled from 'styled-components';
import TableWF from './TableWF';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlighter: 'My Workspace',
    };
  }


  render() {
    const { classes } = this.props;
    const cardsList = [
      {
        title: 'My Workspace',
        desc: 'Perform Transformation, Cleansing and Data Compression',
        icon: workspaceImg,
        component: 'Home',
      },
      {
        title: 'Create/Modify Project',
        desc: 'Build Dataops Job, Workflow, View Jobs, Run Jobs and Get Info',
        icon: newProjectImg,
        component: 'Project',
      },
    ];

    return (
      <div>
        <div className={classes.header}>
          <Grid container xs={12}>
            <Grid item xs={12} sm={12}>
              <h1>
                What is DataChef in vLife?
              </h1>

              <p className={classes.text}>
                DataChef can ingest data from any data source no matter if it is
                structured or unstructured, automates the end-to-end data
                workflows, Compresses and partitions the processed files for
                improved performance, Automates the metadata catalog build
                process and designs organized data marts within the data lake in
                a click of a button.
              </p>
            </Grid>
            {/* <Grid item xs={12} sm={5}>
              <img width="100%" height="270px" src={demo2Img} alt="" />
            </Grid> */}
          </Grid>
        </div>
        <section>
          <h2>
            Process
          </h2>
          <Grid container xs={12} spacing={2} direction="row">
            {cardsList.map(({ title, desc, component, icon }) => (
              <Grid item xs={12} sm={6}>
                <StyledCard
                  title={title}
                  desc={desc}
                  icon={icon}
                  handleClick={(e) => { this?.props?.handleClick(component) }}
                  highlight={this.state.highlighter == title}
                />
              </Grid>
            ))}
          </Grid>
        </section>
        <br />
        
        <div>
          <TableWF
            tableData={this.props.data}
            onClick={this.props.handleCurrentStage}
            key='WorkspaceData'
            maxHeight='200px'
            linkId='Job Id'
            isWorkspace={true}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(Styles)(Home);
