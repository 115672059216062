import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Typography,
} from "@material-ui/core";

// import local components
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  Result,
} from "../../../styles/common";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";

// images
import dcGan_sol1 from "../../../assets/images/app/dcGan_sol1.webp";
import dcGan_sol2 from "../../../assets/images/app/dcGan_sol2.webp";
import dcGan_sol3 from "../../../assets/images/app/dcGan_sol3.webp";
import dcGan_sol4 from "../../../assets/images/app/dcGan_sol4.webp";
import dcGan_sample1 from "../../../assets/images/app/dcGan_sample1.webp";
import dcGan_sample2 from "../../../assets/images/app/dcGan_sample2.webp";
import dcGan_sample3 from "../../../assets/images/app/dcGan_sample3.webp";
import dcGan_sample4 from "../../../assets/images/app/dcGan_sample4.webp";
import MailSent from "../../../assets/images/app/mailSent.svg";
import ZoomImage from "../../common/ZoomImage";

const DcGan = () => {
  const initialData = {
    epochCount: 100,
    imageCount: 100,
    isError: false,
  };

  const [showUpload, setShowUpload] = useState(false);
  const [sampleImage, setSampleImage] = useState(false);
  const [sampleOutput, setSampleOutput] = useState(false);
  const [parameters, setParameters] = useState(initialData);
  const [disableBtn, setDisableBtn] = useState(false);
  const [mailSent, setMailSent] = useState("");
  const [uploadMetaData, setUploadMetaData] = useState("");
  const [uploadImgUrl, setUploadImgUrl] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("z1dApps", "dcGan");

  const uploadImage = () => {
    const {
      dataLink, epochCount, imageCount, tag, userMailId,
    } = parameters;
    if (!userMailId) {
      setNotification({ open: "error", message: "Please Enter Mail ID" });
      return;
    }
    if (!dataLink) {
      setNotification({ open: "error", message: "DataLink is required" });
      return;
    }
    const params = `${userMailId}|${dataLink}|${tag}|${epochCount}|${imageCount}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      let isError = false;
      setSpinner(true);
      setUploadMetaData("");
      setUploadImgUrl("");
      setMailSent("");
      setDisableBtn(true);

      getQueryResult("upload", params)
        .then((res) => {
          if (res.status === 400) {
            isError = true;
            setNotification({ open: "error", message: res.message });
          }
        })
        .catch((err) => {
          setSpinner(false);
          isError = true;
          console.log(err);
          setNotification({ open: "error", message: err.message || "Data not supported" });
        });
      setTimeout(() => {
        setSpinner(false);
        !isError && setMailSent('We will send you a mail within 5 hours');
      }, 5000);
    }
  };

  const loadSampleData = () => {
    setShowUpload(false);
    setSampleImage(true);
  };

  const loadUploadData = () => {
    setShowUpload(true);
    setSampleImage(false);
    setSampleOutput(true);
  };

  const showOutput = () => {
    setSampleOutput(true);
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const value = (e.target.files && e.target.files[0]) || e.target.value;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  const onChecked = (event) => {
    const val = event.target.value;
    setParameters({
      ...parameters,
      tag: val,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Grid item xs={12} md={12}>
          <Paragraph>
            <p>
              Learning reusable feature representations from large unlabelled
              datasets has been an area of active research. In the context of
              computer vision, one can leverage the practically unlimited amount
              of unlabelled images and videos to learn good intermediate
              representations, which can then be used on a variety of supervised
              learning tasks such as image classification. One way to build good
              image representations is by training Generative Adversarial
              Networks (GANs) and later reusing parts of the generator and
              discriminator networks as feature extractors for supervised tasks.
              GANs provide an attractive alternative to maximum likelihood
              techniques.
            </p>
            <p>
              <b>DCGANs</b>
 utilize some of the basic principles of
              <b>CNNs</b>
               and have thus become one of the most widely used architectures in
              practice, due to their fast convergence and also due to the fact
              that they can be very easily adapted into more complex variants
              (using labels as conditions, applying residual blocks and so on).
              DCGANs is created so that it basically solves a supervised image
              classification task and the filters learned by the DCGANs can be
              utilized to draw specific objects in the generated image.
            </p>
            <p>
              <b>Architecture</b>

            </p>
            <p>
              Deep Convolutional Generative Adversarial Networks (DCGANs) are a
              class of CNNs and have algorithms like unsupervised learning. The
              role of the discriminator here is to determine that the image
              comes from either a real dataset or generator. The discriminator
              is just like a simple convolution neural network which performs
              image classification task.
            </p>
            <center>
              <img width="60%" src={dcGan_sol1} alt="" />
            </center>
            <p>
              To know more about the Architecture and details of DC-GANs, please
              refer this &nbsp;
              <a
                href="https://arxiv.org/abs/1511.06434v2"
                target="_blank"
                rel="noopener noreferrer"
              >
                paper
              </a>
            </p>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <p> Below are the steps involved :</p>
                <ZoomImage width="100%" src={dcGan_sol2} alt="" />
              </Grid>
              <Grid item xs={12} md={6}>
                <p>
                  The Metadata that can be extracted from the Exif tool in vLife
                  are listed below:
                </p>
                <ZoomImage width="100%" src={dcGan_sol3} alt="" />
              </Grid>
            </Grid>
            <p>
              The tool provides BYOD (Bring Your Own Data) feature to the users
              where the user can provide the CloudFront link of the data that
              needs to be synthetically generated. The runtime for DCGAN is
              minimum 3 hrs. Hence, the Synthetically Generated Images will be
              sent to the User in his Email id within 24 hrs.
            </p>
            <p>
              Few Sample images from Chest X-ray dataset have been provided for
              reference for which the Synthetic Images are also generated. Click
              on Sample Button and Execute DCGAN to see the Synthetic Generated
              Images along with their Metadata that has been extracted out using
              our Exif Tool.
            </p>
            <center>
              <img width="auto" src={dcGan_sol4} alt="" />
            </center>
            <p>
              The work on DCGANs for coloured images is in progress and will be
              released soon.
            </p>
          </Paragraph>
        </Grid>
      </Collapse>
      <Collapse text="Demo">
        <section>
          <div>
            <center>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={loadSampleData}
              >
                Sample
              </StyledButton>

              <StyledButton
                variant="contained"
                color="secondary"
                onClick={loadUploadData}
              >
                Upload
              </StyledButton>
            </center>
          </div>
        </section>
        {showUpload && (
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={5}>
                <center>
                  <img width="100%" src={dcGan_sample4} alt="" />
                </center>
              </Grid>
              <AlignedGrid item xs={12} md={7}>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <FormControl minWidth="80" varient="outlined" fullWidth>
                      <TextField
                        label="Mail Id"
                        fullwidth
                        required
                        autoComplete="off"
                        type="text"
                        variant="outlined"
                        name="userMailId"
                        value={parameters.userMailId}
                        onChange={onChangeFields}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl minWidth="80" varient="outlined" fullWidth>
                      <TextField
                        variant="outlined"
                        fullwidth
                        required
                        autoComplete="off"
                        label="Cloudfront Link"
                        value={parameters.dataLink}
                        name="dataLink"
                        onChange={onChangeFields}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl minWidth="80" varient="outlined" fullWidth>
                      <TextField
                        label="Epoch Count(optional)"
                        fullwidth
                        autoComplete="off"
                        type="text"
                        variant="outlined"
                        helperText="Default No. of Epoch is 100"
                        name="epochCount"
                        value={parameters.epochCount}
                        onChange={onChangeFields}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl minWidth="80" varient="outlined" fullWidth>
                      <TextField
                        label="O/p Images Count(optional)"
                        fullwidth
                        autoComplete="off"
                        type="text"
                        variant="outlined"
                        name="imageCount"
                        helperText="Default No. of o/p Images is 150"
                        value={parameters.imageCount}
                        onChange={onChangeFields}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledRadioGroup
                      row
                      justify="center"
                      aria-label="Learn Mixture Weights"
                      name="learnMixtureWeights"
                      value={parameters.tag}
                      onChange={onChecked}
                    >
                      <FormControlLabel
                        control={<Radio value="KneeData" name="knee" />}
                        label="Knee"
                      />
                      <FormControlLabel
                        control={<Radio value="ChestData" name="chest" />}
                        label="Chest"
                      />
                      <FormControlLabel
                        control={<Radio value="BrainData" name="brain" />}
                        label="Brain"
                      />
                      <FormControlLabel
                        control={
                          <Radio value="ShouldersData" name="shoulders" />
                        }
                        label="Shoulders"
                      />
                      <FormControlLabel
                        control={<Radio value="Others" name="others" />}
                        label="Others"
                      />
                    </StyledRadioGroup>
                  </Grid>
                </Grid>
              </AlignedGrid>

              <Grid item xs={12}>
                <center>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={uploadImage}
                    disabled={disableBtn}
                  >
                    Execute
                  </StyledButton>
                </center>
              </Grid>
            </Grid>
            <section>

              {showSpinner && <Spinner text="Loading..." />}
            </section>
            {mailSent && (
              <center>
                <img width="200px" height="200px" src={MailSent} alt="" />
                <Typography variant="h5" display="block">
                  Thank you for your Interest
                </Typography>
                <Typography variant="h6" display="block" color="primary">
                  {mailSent}
                </Typography>
              </center>
            )}

          </section>
        )}
        {sampleImage && (
          <section>

            <Result>
              The images displayed below are sample images from Chest X-ray
              dataset. The dataset has a total of 7000 images.
            </Result>
            <center>
              <ImageBorder src={dcGan_sample1} alt="" />
            </center>

            <center>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showOutput}
              >
                Execute DCGAN
              </StyledButton>
            </center>

            {sampleOutput && (
              <Grid container justify="center">
                <Grid item xs={12}>
                  <center>
                    <ImageBorder src={dcGan_sample2} alt="" />
                  </center>
                </Grid>
                <Grid item={8}>
                  <Result>
                    The above images are sample images of Synthetically
                    Generated images using Chest X-Ray dataset. The number of
                    synthetically generated images were 500 and the number of
                    epochs were 250.
                  </Result>
                </Grid>
                <Grid item xs={12}>

                  <img width="100%" src={dcGan_sample3} alt="" />
                </Grid>
                <Grid item={8}>

                  <Result>
                    Metadata of all the synthetically generated images are also
                    extracted out using our Exif tool. Sample of metadata file
                    is displayed above
                  </Result>
                </Grid>
              </Grid>
            )}
          </section>
        )}
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(DcGan);

const ImageBorder = styled.img`
border-radius : 5px;  
@media only screen and (min-width: 900px) {
    width: 80%;
  }
`;

const AlignedGrid = styled(Grid)`
  @media only screen and (min-width: 960px) {
    text-align: -webkit-right;
  }
  @media only screen and (max-width: 959px) {
    text-align: -webkit-right;
  }
  @media only screen and (max-width: 959px) {
    text-align: -webkit-center;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
display: block;
`;
