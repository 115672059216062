import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function PageNotFound() {
  return (
    <Container>
      <h1>404</h1>
      <h2>Page Not Found!!!</h2>
      <Link to="/">Back to home page</Link>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  position: relative;
  height: 90vh;
  background: #5e5768;
  font-style: italic;
  color: #ffff;
  a {
    text-decoration: none;
    color: #ffff;
    border: 1px solid white;
    border-radius: 4px;
    padding: 8px;
  }
  h1 {
    font-size: 150px;
    margin-bottom: 0px;
  }
`;
