export const USER_MANAGEMENT = {
  // register
  CHECK_IFPRIMARY: "getPrimaryUser",
  REGISTER: "signUp",
  PRIMARY_STATUS: "true",
  SIGNUP_RES_STATUS: "200",
  PRIMARY_ROLE: "Primary",
  SECONDARY_ROLE: "Secondary",
  BUSSINESSREP_INFO: "Your company has a business representative assigned for primary account holder role",
  SERVER_ISSUE: "vLife is experiencing a temporary network glitch, please try Register after sometime",
  REQ_FIELD: "Field is Required",
  PWD_MATCH_ERR: "Password and Confirm Password do not match.",
  INV_EMAIL: "Invalid email format",
  ONLY_CORP_EMAIL: "Please enter valid corporate mail ID",
  PWD_POLICY_ERR: "Password doesn't match with policy pattern",
  INV_MOBILE: "Invalid Mobile number",
  AGREE_TERMS: "Please select terms and conditions.",
  // login
  CONFIRMED: "CONFIRMED",
  UNCONFIRMED: "UNCONFIRMED",
  PRIMARY_ROLE: "Primary",
  SUBSCRIPTION_ROUTE: '/subscribe',
  PENDING_ROLE: "PENDING",
  SECONDARY_ROLE: "Secondary",
  ADMIN_ROLE: "Admin",
  USER_PENDING_INFO: "User is pending for Admin approval",
  USER_DISABLED_INFO: "Account has been Disabled by Admin",
  FORCE_RESET_PWD: "Admin has requested to Reset your password",
  VERIFY_TOKEN_ROUTE: "/verify-token",
  REGISTER_ROUTE: "/register",
  API_ISSUE: 'Vlife is down, please try after sometime',
  ROUTE_TO_RESET: "Redirecting to Reset-Password page",
  // verifytoken
  PRIMARY_USER_VERIFY: "Verified Token! You will be redirected to the Subscription page shortly",
  SEC_USER_VERIFY: "Verified Token! Admin approval is pending, you will receive mail once approved",
  RESEND_SUCCESS: "Token sent to",
  // forgotpassword
  EMAIL_REQ: "Email is required",
  GUEST: "guestuser@vlife.com",
  GUEST_RESET_ERR: "Guest User can't be Reset",
  FORGOT_PWD_SUCCESS: "Instructions to change your password has been sent to your mail. Redirecting to Reset-Password page",
  RESET_ROUTE: "/reset-password",
  UNVERIFIED_USER: "User is not verified!",
  UN_REGISTERD: "If you are a registered user, you will get reset password notification.",
  // reset
  RESET_SUCESS: "Password updated! You will be redirected to the login page shortly.",
  RESET_PWD_RESEND_TOKEN: "A code has been sent to your mail. Please check the inbox.",
};
