import React, { memo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useScrollTrigger, Zoom, Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    threshold: 150,
    disableHysteresis: true,
  });

  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        <StyledFabBtn color="#fc7703" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </StyledFabBtn>
      </div>
    </Zoom>
  );
}
export default memo(ScrollTop);

const StyledFabBtn = styled(Fab)`
  &.MuiFab-root {
    background-color: rgb(252, 119, 3);
    color: #ffff;
  }
`;
