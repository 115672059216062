import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  RadioGroup,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import InfoToolTip from "../../components/InfoToolTip";
import { FormControlLabelInfo } from "../../InputForm";
import { isObjKeyhasval } from "../../util";

export const StandardScalerForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const dispatch = useDispatch();
  const [dropdownList, setdropdownList] = useState(null);
  const [selected, setSelected] = useState(userInputData?.target || []);
  const [allVal, setAllVal] = useState([]);
  const [result, setResult] = useState();
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { value } = e.target;

    if (value[value.length - 1] === "all") {
      if ([allVal.length] > 1) {
        allVal.length = 0;
      } else {
        const vals = JSON.parse(result);
        allVal.push(...vals);
      }
      setSelected(selected.length === dropdownList.length ? [] : dropdownList);

      if (e.target.name) {
        dispatch(
          setInputData({
            ...userInputData,
            [e.target.name]: allVal,
          }),
        );
      }
      return;
    }
    if (e.target.name === "target") {
      dispatch(
        setInputData({
          ...userInputData,
          [e.target.name]: e.target.value,
          columns: userInputData?.columns?.filter(
            (col) => e.target.value?.indexOf(col) == -1,
          ),
        }),
      );
    }
    if (e.target.name === "columns") {
      dispatch(
        setInputData({
          ...userInputData,
          [e.target.name]: e.target.value,
        }),
      );
    }
    setSelected(value);
    error[e.target.name] && setError({ ...error, [e.target.name]: false });
  };

  const handleCheckBox = (e) => {
    const { checked } = e.target;
    let val = e.target.value;
    if (checked) {
    } else {
      val = "False";
    }
    dispatch(
      setInputData({
        ...userInputData,
        [e.target.name]: val,
      }),
    );
  };

  const isAllSelected = dropdownList?.length > 0 && selected?.length === dropdownList?.length;

  useEffect(async () => {
    const result = await props.getCategories();
    setResult(result);
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };
  const handleFormReset = () => {
    console.log("reset");
  };

  const isFormValid = () => {
    const err = { ...error };

    if (userInputData?.target?.length == 0) {
      err.target = true;
    }
    if (userInputData?.columns?.length == 0) {
      err.columns = true;
    }
    setError(err);
    return isObjKeyhasval(err);
  };
  return (
    <ScalerContainer>
      <CenteredGrid
        container
        spacing={3}
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={3}>
          <b>
            {" "}
            <InfoToolTip title="Target" info="Select the target feature(s)">
              {" "}
              Target
            </InfoToolTip>
          </b>
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth error={error.target}>
            <Select
              labelId="mutiple-select-label"
              label="target"
              multiple
              name="target"
              value={userInputData?.target || []}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              variant="outlined"
            >
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <ListItemIcon>
                    <Checkbox
                      checked={userInputData?.target?.indexOf(value) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {error.target && <FormHelperText>Required Field</FormHelperText>}

          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <b>
            {" "}
            <InfoToolTip
              title="Feature"
              info="Select features to be scaled"
            >
              {" "}
              Feature
            </InfoToolTip>
          </b>
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth error={error.columns}>
            <Select
              labelId="mutiple-select-label"
              label="target"
              multiple
              name="columns"
              value={userInputData?.columns || []}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              variant="outlined"
            >
              {dropdownList?.map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  disabled={userInputData?.target?.indexOf(value) > -1}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={userInputData?.columns?.indexOf(value) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {error.columns && <FormHelperText>Required Field</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControlLabelInfo
            control={(
              <Checkbox
                value="True"
                name="with_mean"
                onChange={handleCheckBox}
              />
            )}
            label="With Mean"
            info="Center the data before scaling"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabelInfo
            control={(
              <Checkbox
                value="True"
                name="with_std"
                onChange={handleCheckBox}
              />
            )}
            label="With Standard Deviation"
            info="Scale the data to unit variance) change name from std to standard deviation"
          />
        </Grid>
      </CenteredGrid>
      {props.render({
        handleFormReset,
        isFormValid,
      })}
    </ScalerContainer>
  );
});
export const ScalerContainer = styled.section`
  text-align: left;
  min-width: ${props => props.width};
  .MuiTextField-root {
    min-width: 190px;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;

const CenteredGrid = styled(Grid)`
  text-align: center;
`;
