import axios from "axios";
import getUserData from "..";
import API from "./APIList";

export function wrappedAxios(config) {
  let token = getUserData()?.token?.id_token;

  // old code //

  // if (config.headers)
  //  {config.headers['Authorization'] = `Bearer ${token}`;config.headers['auth-token'] = token};
  //   config.headers['x-auth-origin']= 'vlife';

  // new code //

  if (config.headers)
    config.headers['Authorization'] = `Bearer ${token}`
    config.headers['x-auth-origin']= 'vlife';
  //

  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        if (response?.data?.status == 'Failed') {
          return {
            Error_Flag: true,
            Error_UI: response.data.Result
          }
        }
        if (response?.data?.Error_Flag === true) {
          throw Error(response?.data?.Error_UI);
        }
        else {
          return response.data;
        }
      }
      throw Error(response.status);
    })
    .catch((err) => {
      if (err.response?.data?.status == 403) {
        throw new Error(err?.response?.status)
      } else {
        throw Error(err);
      }
    });
}

function commonServices(domainName, solutionName) {
  const domain = API[domainName];
  const domainURL = domain.url;
  const solnEndpoints = domain.solutions[solutionName];

  // get request with query params
  const getQueryResult = (endpoint, params, method) => {
    const solnUrl = params
      ? `${solnEndpoints[endpoint]}${params}`
      : solnEndpoints[endpoint];
    const config = {
      method: method || "GET",
      url: `${domainURL}${solnUrl}`,
      headers: {
        Accept: "application/json",
      },
    };

    return wrappedAxios(config);
  };

  // post request with uploaded file as body
  const uploadFile = (endpoint, file) => {
    const solnUrl = solnEndpoints[endpoint];

    const fd = new FormData();
    fd.append("file", file);
    const config = {
      method: "POST",
      url: `${domainURL}${solnUrl}`,
      data: fd,
      headers: {
        Accept: "application/json",
      },
    };

    return wrappedAxios(config);
  };

  // post request with multiple uploaded file as body
  const multipleUploadFile = (endpoint, file) => {
    const solnUrl = solnEndpoints[endpoint];
    const fd = new FormData();
    Object.entries(file)?.map((val) => {
      fd.append("files", val[1]);
    });
    const config = {
      method: "POST",
      url: `${domainURL}${solnUrl}`,
      data: fd,
      headers: {
        Accept: "application/json",
      },
    };
    return wrappedAxios(config);
  };

  // post request with body
  const postQueryResult = (endpoint, params) => {
    const solnUrl = solnEndpoints[endpoint];
    const config = {
      method: "POST",
      url: `${domainURL}${solnUrl}`,
      data: JSON.stringify(params),
      "async": true,
      "crossDomain": true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache",
      },
    };

    return wrappedAxios(config);
  };
  const postFormDataQueryResult = (endpoint, params) => {
    const solnUrl = solnEndpoints[endpoint];
    const config = {
      method: "POST",
      url: `${domainURL}${solnUrl}`,
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache",
      },
    };

    return wrappedAxios(config);
  };

  // post request with body for colbert
  const postQueryResultColbert = (endpoint, params) => {
    const solnUrl = solnEndpoints[endpoint];

    const fd = new FormData();

    Object.entries(params)?.map((val) => {
      fd.append(`${val[0]}`, val[1]);
    });
    const config = {
      method: "POST",
      url: `${domainURL}${solnUrl}`,
      data: fd,
      "async": true,
      "crossDomain": true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache",
      },
    };

    return wrappedAxios(config);
  };

  // multiple uploaded files and body params
  const uploadFilesWithBody = (endpoint, files, params = "") => {
    const solnUrl = solnEndpoints[endpoint] + params;
    const fd = new FormData();

    Object.entries(files)?.map((val) => {
      fd.append(`${val[0]}`, val[1]);
    });

    const config = {
      method: "POST",
      url: `${domainURL}${solnUrl}`,
      data: fd,
      headers: {
        Accept: "application/json",
      },
    };
    return wrappedAxios(config);
  };

  return {
    uploadFile,
    multipleUploadFile,
    uploadFilesWithBody,
    getQueryResult,
    postQueryResult,
    postQueryResultColbert,
    postFormDataQueryResult
  };
}
export default commonServices;
