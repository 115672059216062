import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Paper, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import local components
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import ace_sol2 from "../../../assets/images/app/ace_sol2.webp";
import ace_sol1 from "../../../assets/images/app/ace_sol1.webp";
import ace_sample1 from "../../../assets/images/app/ace_sample1.webp";
import ace_sample2 from "../../../assets/images/app/ace_sample2.webp";
import ace_sample3 from "../../../assets/images/app/ace_sample3.webp";
import ace_sample4 from "../../../assets/images/app/ace_sample4.webp";
import ace_sample5 from "../../../assets/images/app/ace_sample5.webp";
import ace_sample6 from "../../../assets/images/app/ace_sample6.webp";
import ace_sample7 from "../../../assets/images/app/ace_sample7.webp";
import ace_sample8 from "../../../assets/images/app/ace_sample8.webp";
import ace_sample9 from "../../../assets/images/app/ace_sample9.webp";
import ace_sample10 from "../../../assets/images/app/ace_sample10.webp";
import ace_sample11 from "../../../assets/images/app/ace_sample11.webp";
import ace_sample12 from "../../../assets/images/app/ace_sample12.webp";
import ace_sample13 from "../../../assets/images/app/ace_sample13.webp";
import ace_sample14 from "../../../assets/images/app/ace_sample14.webp";
import ace_sample15 from "../../../assets/images/app/ace_sample15.webp";
import ace_sample16 from "../../../assets/images/app/ace_sample16.webp";
import gan_image from "../../../assets/images/app/vision-aided-gan.gif";

const VisionAidedGAN = () => {
  const [resultData, setResultData] = useState(false);

  const showResultFun = () => {
    setResultData(true);
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <center><Image src={gan_image} width="80%" alt="" /></center>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(VisionAidedGAN);

const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #274c77;
`;
const Image = styled.img`
  border-radius: 3px;
`;

const Image1 = styled.img`
  width: 75%;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Image2 = styled.img`
  width: -webkit-fill-available;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
const TableDiv = styled.div`
    overflow: auto;
`;
