export const CONCLUSION = {
  "Cross Reference Only": 0,
  "Furnished Information": 1,
  Justified: 2,
  "No Action Necessary": 3,
  "No Authority": 4,
  Questionable: 5,
  Unjustified: 6,
  "Voluntary Reconsider": 7,
};
export const COMPANY = {
  "21st Century Insurance Company": 0,
  "21st Century North America Insurance Company": 1,
  "21st Century Premier Insurance Company": 2,
  "5 Star Life Insurance Company": 3,
  "AAA Life Insurance Company": 4,
  "ACE American Insurance Company": 5,
  "AGCS Marine Insurance Company": 6,
  "AIG Property Casualty Company": 7,
  "AIG Specialty Insurance Company": 8,
  "AIU Insurance Company": 9,
  "AIX Specialty Insurance Company": 10,
  "AXA Equitable Life Insurance Company": 11,
  "AXA Insurance Company": 12,
  "AXIS Insurance Company": 13,
  "Acadia Insurance Company": 14,
  "Acceptance Indemnity Insurance Company": 15,
  "Acceptance Insurance Company": 16,
  "Accordia Life and Annuity Company": 17,
  "Admiral Insurance Company": 18,
  "Aetna Health Inc": 19,
  "Aetna Health and Life Insurance Company": 20,
  "Aetna Insurance Company of Connecticut": 21,
  "Aetna Life Insurance Company": 22,
  "Alliance of Nonprofits for Insurance Risk Retention Group": 23,
  "Allianz Global Risks US Insurance Company": 24,
  "Allianz Life Insurance Company of North America": 25,
  "Allied Insurance Company of America": 26,
  "Allied World Insurance Company": 27,
  "Allied World Specialty Insurance Company": 28,
  "Allied World Surplus Lines Insurance Company": 29,
  "Allmerica Financial Benefit Insurance Company": 30,
  "Allstate Fire and Casualty Insurance Company": 31,
  "Allstate Indemnity Company": 32,
  "Allstate Insurance Company": 33,
  "Allstate Life Insurance Company": 34,
  "Allstate Property and Casualty Insurance Company": 35,
  "AmGUARD Insurance Company": 36,
  "AmTrust Insurance Company of Kansas, Inc.": 37,
  "AmTrust International Underwriters Limited": 38,
  "Amalgamated Life Insurance Company": 39,
  "American Alternative Insurance Corporation": 40,
  "American Automobile Insurance Company": 41,
  "American Bankers Insurance Company of Florida": 42,
  "American Commerce Insurance Company": 43,
  "American Economy Insurance Company": 44,
  "American Empire Insurance Company": 45,
  "American Empire Surplus Lines Insurance Company": 46,
  "American Equity Investment Life Insurance Company": 47,
  "American Family Home Insurance Company": 48,
  "American Family Life Assurance Company of Columbus": 49,
  "American Family Life Assurance Company of New York": 50,
  "American Fire and Casualty Company": 51,
  "American General Life Insurance Company": 52,
  "American General Life Insurance Company of Delaware": 53,
  "American Guarantee and Liability Insurance Company": 54,
  "American Hallmark Insurance Company of Texas": 55,
  "American Heritage Life Insurance Company": 56,
  "American Income Life Insurance Company": 57,
  "American Modern Home Insurance Company": 58,
  "American Modern Property and Casualty Insurance Company": 59,
  "American Modern Select Insurance Company": 60,
  "American National Insurance Company": 61,
  "American Network Insurance Company": 62,
  "American Pet Insurance Company": 63,
  "American Progressive Life & Health Insurance Company of New York": 64,
  "American Security Insurance Company": 65,
  "American Southern Home Insurance Company": 66,
  "American States Insurance Company": 67,
  "American Strategic Insurance Corp.": 68,
  "American United Life Insurance Company": 69,
  "American Zurich Insurance Company": 70,
  "Ameritas Life Insurance Corp.": 71,
  "Amex Assurance Company": 72,
  "Amica Life Insurance Company": 73,
  "Amica Mutual Insurance Company": 74,
  "Amica Property and Casualty Insurance Company": 75,
  "Anthem Health Plans, Inc": 76,
  "Anthem Life Insurance Company": 77,
  "Arbella Indemnity Insurance Company": 78,
  "Arbella Protection Insurance Company Inc.": 79,
  "Arch Insurance Company": 80,
  "Arch Specialty Insurance Company": 81,
  "Argonaut Insurance Company": 82,
  "Aspen American Insurance Company": 83,
  "Atain Insurance Company": 84,
  "Atain Specialty Insurance Company": 85,
  "Athene Annuity & Life Assurance Company": 86,
  "Athene Annuity & Life Assurance Company of New York": 87,
  "Athene Annuity and Life Company": 88,
  "Athene Life Insurance Company of New York": 89,
  "Atlantic Casualty Insurance Company": 90,
  "Atlantic Mutual Insurance Company": 91,
  "Atlantic Specialty Insurance Company": 92,
  "Auto-Owners Insurance Company": 93,
  "Automobile Insurance Company of Hartford, Connecticut (The)": 94,
  "BCS Insurance Company": 95,
  "Baltimore Life Insurance Company": 96,
  "Bankers Life and Casualty Company": 97,
  "Bankers Standard Insurance Company": 98,
  "Banner Life Insurance Company": 99,
  "Bay State Insurance Company": 100,
  "Berkshire Hathaway Homestate Insurance Company": 101,
  "Berkshire Hathaway Specialty Insurance Company": 102,
  "Blue Shield of California Life & Health Insurance Company": 103,
  "Boston Mutual Life Insurance Company": 104,
  "Brighthouse Life Insurance Company": 105,
  "Bristol West Insurance Company": 106,
  "Brotherhood Mutual Insurance Company": 107,
  "Bunker Hill Insurance Company": 108,
  "C.M. Life Insurance Company": 109,
  "CMFG Life Insurance Company": 110,
  "CSAA Affinity Insurance Company": 111,
  "CSAA Fire & Casualty Insurance Company": 112,
  "CSAA General Insurance Company": 113,
  "CUNA Mutual Life Insurance Company": 114,
  "California Casualty Indemnity Exchange": 115,
  "California Insurance Company": 116,
  "Cambridge Mutual Fire Insurance Company": 117,
  "Canal Insurance Company": 118,
  "Canopius US Insurance, Inc.": 119,
  "Carolina Casualty Insurance Company": 120,
  "Casco Indemnity Company": 121,
  "Catlin Insurance Company, Inc.": 122,
  "Central Mutual Insurance Company": 123,
  "Central States Indemnity Company of Omaha": 124,
  "Century-National Insurance Company": 125,
  "Charter Oak Fire Insurance Company (The)": 126,
  "Chesapeake Life Insurance Company (The)": 127,
  "Chicago Title Insurance Company": 128,
  "Chubb Indemnity Insurance Company": 129,
  "Chubb National Insurance Company": 130,
  "Cigna Health and Life Insurance Company": 131,
  "Cigna HealthCare of Connecticut, Inc": 132,
  "Cincinnati Insurance Company (The)": 133,
  "Citizens Insurance Company of America": 134,
  "Clear Blue Insurance Company": 135,
  "Colonial Life & Accident Insurance Company": 136,
  "Colonial Penn Life Insurance Company": 137,
  "Colonial Surety Company": 138,
  "Colony Insurance Company": 139,
  "Colony Specialty Insurance Company": 140,
  "Columbian Life Insurance Company": 141,
  "Columbian Mutual Life Insurance Company": 142,
  "Combined Insurance Company of America": 143,
  "Commerce Insurance Company (The)": 144,
  "Commonwealth Annuity and Life Insurance Company": 145,
  "Commonwealth Land Title Insurance Company": 146,
  "Companion Life Insurance Company": 147,
  "ConnectiCare Benefits, Inc.": 148,
  "ConnectiCare Inc": 149,
  "ConnectiCare Insurance Company, Inc": 150,
  "Connecticut Attorneys Title Insurance Company": 151,
  "Connecticut FAIR Plan": 152,
  "Connecticut General Life Insurance Company": 153,
  "Connecticut Interlocal Risk Management Agency (CIRMA)": 154,
  "Connecticut Medical Insurance Company": 155,
  "Conseco Insurance Company": 156,
  "Constitution Life Insurance Company": 157,
  "Continental American Insurance Company": 158,
  "Continental Casualty Company": 159,
  "Continental General Insurance Company": 160,
  "Continental Insurance Company (The)": 161,
  "Continental Life Insurance Company of Brentwood, Tennessee": 162,
  "Continental Western Insurance Company": 163,
  "Country Mutual Insurance Company": 164,
  "County Hall Insurance Company, Inc., A Risk Retention Group": 165,
  "Courtesy Insurance Company": 166,
  "Covenant Insurance Company": 167,
  "Crum & Forster Specialty Insurance Company": 168,
  "Dairyland Insurance Company": 169,
  "Dealers Assurance Company": 170,
  "Dearborn Life Insurance Company": 171,
  "Delaware Life Insurance Company": 172,
  "Delta Dental Insurance Company": 173,
  "Delta Dental of Connecticut, Inc.": 174,
  "Dentegra Insurance Company": 175,
  "Depositors Insurance Company": 176,
  "Economy Fire & Casualty Company": 177,
  "Economy Premier Assurance Company": 178,
  "Electric Insurance Company": 179,
  "Elite Transportation Risk Retention Group, Inc.": 180,
  "Empire Fire and Marine Insurance Company": 181,
  "Employers Mutual Casualty Company": 182,
  "Encompass Indemnity Company": 183,
  "Encompass Insurance Company of America": 184,
  "Equitrust Life Insurance Company": 185,
  "Essentia Insurance Company": 186,
  "Esurance Insurance Company": 187,
  "Evanston Insurance Company": 188,
  "Everest National Insurance Company": 189,
  "Everest Reinsurance Company": 190,
  "Executive Risk Specialty Insurance Company": 191,
  "Farm Family Casualty Insurance Company": 192,
  "Farmers Insurance Exchange": 193,
  "Farmers Mutual Hail Insurance Company of Iowa": 194,
  "Federal Insurance Company": 195,
  "Federal Life Insurance Company": 196,
  "Federated Mutual Insurance Company": 197,
  "Federated Service Insurance Company": 198,
  "Fidelity & Guaranty Life Insurance Company": 199,
  "Fidelity Life Association, A Legal Reserve Life Insurance Company": 200,
  "Fidelity National Title Insurance Company": 201,
  "Fidelity Security Life Insurance Company": 202,
  "Firemens Insurance Company of Washington, D.C.": 203,
  "First American Title Insurance Company": 204,
  "First Financial Insurance Company": 205,
  "First Health Life & Health Insurance Company": 206,
  "First Liberty Insurance Corporation (The)": 207,
  "First Mercury Insurance Company": 208,
  "First Specialty Insurance Corporation": 209,
  "FirstComp Insurance Company": 210,
  "Florists Mutual Insurance Company": 211,
  "Foremost Insurance Company Grand Rapids, Michigan": 212,
  "Foremost Property and Casualty Insurance Company": 213,
  "Foremost Signature Insurance Company": 214,
  "Forethought Life Insurance Company": 215,
  "GEICO Casualty Company": 216,
  "GEICO General Insurance Company": 217,
  "GEICO Indemnity Company": 218,
  "GEICO Marine Insurance Company": 219,
  "Garrison Property and Casualty Insurance Company": 220,
  "Gemini Insurance Company": 221,
  "General American Life Insurance Company": 222,
  "General Casualty Company of Wisconsin": 223,
  "General Insurance Company of America": 224,
  "General Star Indemnity Company": 225,
  "Generali - U.S. Branch": 226,
  "Genworth Life Insurance Company": 227,
  "Genworth Life Insurance Company of New York": 228,
  "Genworth Life and Annuity Insurance Company": 229,
  "Gerber Life Insurance Company": 230,
  "Global Aerospace Underwriters": 231,
  "Global Liberty Insurance Company of New York": 232,
  "Globe Life and Accident Insurance Company": 233,
  "Golden Rule Insurance Company": 234,
  "Government Employees Insurance Company": 235,
  "Granite State Insurance Company": 236,
  "Graphic Arts Mutual Insurance Company": 237,
  "Great American Assurance Company": 238,
  "Great American E & S Insurance Company": 239,
  "Great American Insurance Company": 240,
  "Great American Insurance Company of New York": 241,
  "Great American Life Insurance Company": 242,
  "Great Divide Insurance Company": 243,
  "Great Lakes Insurance SE": 244,
  "Great Northern Insurance Company": 245,
  "Great Southern Life Insurance Company": 246,
  "Great West Casualty Company": 247,
  "Great-West Life & Annuity Insurance Company": 248,
  "Greater New York Mutual Insurance Company": 249,
  "Greenwich Insurance Company": 250,
  "Guarantee Trust Life Insurance Company": 251,
  "Guardian Insurance & Annuity Company, Inc.": 252,
  "Guardian Life Insurance Company of America": 253,
  "GuideOne Mutual Insurance Company": 254,
  "GuideOne National Insurance Company": 255,
  "HCC Life Insurance Company": 256,
  "HDI Global Insurance Company": 257,
  "HPHC Insurance Company, Inc.": 258,
  "Hanover American Insurance Company": 259,
  "Hanover Insurance Company (The)": 260,
  "Harco National Insurance Company": 261,
  "Harleysville Insurance Company": 262,
  "Harleysville Life Insurance Company": 263,
  "Harleysville Mutual Insurance Company": 264,
  "Harleysville Preferred Insurance Company": 265,
  "Harleysville Worcester Insurance Company": 266,
  "Hartford Accident and Indemnity Company": 267,
  "Hartford Casualty Insurance Company": 268,
  "Hartford Fire Insurance Company": 269,
  "Hartford Insurance Company of Illinois": 270,
  "Hartford Insurance Company of the Midwest": 271,
  "Hartford Insurance Company of the Southeast": 272,
  "Hartford Life and Accident Insurance Company": 273,
  "Hartford Underwriters Insurance Company": 274,
  "Harvard Pilgrim Health Care of Connecticut, Inc.": 275,
  "HealthyCT, Inc.": 276,
  "Hiscox Insurance Company Inc.": 277,
  "Homesite Insurance Company": 278,
  "Horace Mann Life Insurance Company": 279,
  "Horace Mann Property & Casualty Insurance Company": 280,
  "Houston Specialty Insurance Company": 281,
  "Hudson Specialty Insurance Company": 282,
  "Humana Insurance Company": 283,
  "HumanaDental Insurance Company": 284,
  "IDS Property Casualty Insurance Company": 285,
  "Illinois National Insurance Company": 286,
  "Illinois Union Insurance Company": 287,
  "Indemnity Insurance Company of North America": 288,
  "Independence American Insurance Company": 289,
  "Independent Order of Foresters": 290,
  "Indian Harbor Insurance Company": 291,
  "Infinity Insurance Company": 292,
  "Infinity Standard Insurance Company": 293,
  "Insurance Company of Greater New York": 294,
  "Integon National Insurance Company": 295,
  "Integrity Life Insurance Company": 296,
  "Investors Life Insurance Company of North America": 297,
  "Jackson National Life Insurance Company": 298,
  "James River Insurance Company": 299,
  "Jefferson Insurance Company": 300,
  "Jewelers Mutual Insurance Company": 301,
  "John Alden Life Insurance Company": 302,
  "John Hancock Life & Health Insurance Company": 303,
  "John Hancock Life Insurance Company": 304,
  "John Hancock Life Insurance Company (USA)": 305,
  "Kanawha Insurance Company": 306,
  "Kemper Independence Insurance Company": 307,
  "Kinsale Insurance Company": 308,
  "Knights of Columbus": 309,
  "LM General Insurance Company": 310,
  "LM Insurance Corporation": 311,
  "Lafayette Life Insurance Company": 312,
  "Lancer Insurance Company": 313,
  "Lexington Insurance Company": 314,
  "Liberty Insurance Corporation": 315,
  "Liberty Insurance Underwriters Inc.": 316,
  "Liberty Life Assurance Company of Boston": 317,
  "Liberty Mutual Fire Insurance Company": 318,
  "Liberty Mutual Insurance Company": 319,
  "Liberty Surplus Insurance Corporation": 320,
  "Life Insurance Company of North America": 321,
  "Life Insurance Company of the Southwest": 322,
  "Lincoln Benefit Life Company": 323,
  "Lincoln Heritage Life Insurance Company": 324,
  "Lincoln Life & Annuity Company of New York": 325,
  "Lincoln National Life Insurance Company (The)": 326,
  "Loyal American Life Insurance Company": 327,
  "Lumico Life Insurance Company": 328,
  "Lyndon Southern Insurance Company": 329,
  "Madison National Life Insurance Company, Inc.": 330,
  "Main Street America Assurance Company": 331,
  "Manhattan Life Insurance Company": 332,
  "ManhattanLife Assurance Company of America": 333,
  "Mapfre Insurance Company": 334,
  "Markel Insurance Company": 335,
  "Massachusetts Bay Insurance Company": 336,
  "Massachusetts Homeland Insurance Company": 337,
  "Massachusetts Mutual Life Insurance Company": 338,
  "MedAmerica Insurance Company": 339,
  "Mercer Financial Services Professional Risk Purchasing Group": 340,
  "Meridian Security Insurance Company": 341,
  "Merrimack Mutual Fire Insurance Company": 342,
  "Mesa Underwriters Specialty Insurance Company": 343,
  "Metropolitan Casualty Insurance Company": 344,
  "Metropolitan Direct Property and Casualty Insurance Company": 345,
  "Metropolitan Group Property and Casualty Insurance Company": 346,
  "Metropolitan Life Insurance Company": 347,
  "Metropolitan Property and Casualty Insurance Company": 348,
  "Metropolitan Tower Life Insurance Company": 349,
  "Mid-Century Insurance Company": 350,
  "Mid-West National Life Insurance Company of Tennessee": 351,
  "Middlesex Insurance Company": 352,
  "Middlesex Mutual Assurance Company": 353,
  "Midland National Life Insurance Company": 354,
  "Midvale Indemnity Company": 355,
  "Midwest Employers Casualty Company": 356,
  "Minnesota Life Insurance Company": 357,
  "Montpelier Reinsurance Ltd (U.S. Reinsurance Trust)": 358,
  "Mony Life Insurance Company of America": 359,
  "Mount Vernon Fire Insurance Company": 360,
  "Mutual of America Life Insurance Company": 361,
  "Mutual of Omaha Insurance Company": 362,
  "NGM Insurance Company": 363,
  "NOVA Casualty Company": 364,
  "Narragansett Bay Insurance Company": 365,
  "Nassau Life Insurance Company": 366,
  "National Casualty Company": 367,
  "National Continental Insurance Company": 368,
  "National Fire Insurance Company of Hartford": 369,
  "National General Assurance Company": 370,
  "National General Insurance Company": 371,
  "National General Insurance Online, Inc.": 372,
  "National Guardian Life Insurance Company": 373,
  "National Health Insurance Company": 374,
  "National Indemnity Company": 375,
  "National Interstate Insurance Company": 376,
  "National Liability & Fire Insurance Company": 377,
  "National Life Insurance Company": 378,
  "National Specialty Insurance Company": 379,
  "National Union Fire Insurance Company of Pittsburgh, PA": 380,
  "Nationwide Agribusiness Insurance Company": 381,
  "Nationwide General Insurance Company": 382,
  "Nationwide Insurance Company of America": 383,
  "Nationwide Life Insurance Company": 384,
  "Nationwide Life and Annuity Insurance Company": 385,
  "Nationwide Mutual Fire Insurance Company": 386,
  "Nationwide Mutual Insurance Company": 387,
  "Nationwide Property & Casualty Insurance Company": 388,
  "Nautilus Insurance Company": 389,
  "Navigators Insurance Company": 390,
  "Netherlands Insurance Company (The)": 391,
  "New England Life Insurance Company": 392,
  "New England Mutual Insurance Company": 393,
  "New Hampshire Insurance Company": 394,
  "New London County Mutual Insurance Company": 395,
  "New South Insurance Company": 396,
  "New York Life Insurance Company": 397,
  "New York Life Insurance and Annuity Corporation": 398,
  "New York Marine and General Insurance Company": 399,
  "NorGUARD Insurance Company": 400,
  "Norfolk and Dedham Mutual Fire Insurance Company": 401,
  "North American Capacity Insurance Company": 402,
  "North American Company for Life & Health Insurance": 403,
  "North Light Specialty Insurance Company": 404,
  "North River Insurance Company": 405,
  "Northland Insurance Company": 406,
  "Northwestern Long Term Care Insurance Company": 407,
  "Northwestern Mutual Life Insurance Company": 408,
  "OOIDA Risk Retention Group, Inc.": 409,
  "Occidental Fire and Casualty Company of North Carolina": 410,
  "Ohio Mutual Insurance Company": 411,
  "Ohio National Life Insurance Company": 412,
  "Ohio Security Insurance Company": 413,
  "Old American Insurance Company": 414,
  "Old Dominion Insurance Company": 415,
  "Old Republic Insurance Company": 416,
  "Old Republic Life Insurance Company": 417,
  "Old Republic National Title Insurance Company": 418,
  "Omni Indemnity Company": 419,
  "Omni Insurance Company": 420,
  "Oxford Health Insurance, Incorporated": 421,
  "Oxford Health Plans (CT), Inc": 422,
  "PHL Variable Insurance Company": 423,
  "Pacific Indemnity Company": 424,
  "Pacific Insurance Company, Limited": 425,
  "Pacific Life Insurance Company": 426,
  "Pacific Specialty Insurance Company": 427,
  "Paratransit Insurance Company, A Mutual Risk Retention Group": 428,
  "Patrons Mutual Insurance Company of Connecticut": 429,
  "Paul Revere Life Insurance Company": 430,
  "Pavonia Life Insurance Company of Michigan": 431,
  "Peerless Indemnity Insurance Company": 432,
  "Peerless Insurance Company": 433,
  "Penn Mutual Life Insurance Company": 434,
  "Penn-America Insurance Company": 435,
  "Pennsylvania Lumbermens Mutual Insurance Company": 436,
  "Permanent General Assurance Corporation": 437,
  "Philadelphia Indemnity Insurance Company": 438,
  "Phoenix Insurance Company (The)": 439,
  "Physicians Life Insurance Company": 440,
  "Physicians Mutual Insurance Company": 441,
  "Pilgrim Insurance Company": 442,
  "Pioneer Mutual Life Insurance Company": 443,
  "Plateau Casualty Insurance Company": 444,
  "Plaza Insurance Company": 445,
  "Plymouth Rock Assurance Corporation": 446,
  "Praetorian Insurance Company": 447,
  "Preferred Contractors Insurance Company Risk Retention Group, LLC": 448,
  "Prime Insurance Company": 449,
  "Primerica Life Insurance Company": 450,
  "Principal Life Insurance Company": 451,
  "Principal National Life Insurance Company": 452,
  "Privilege Underwriters Reciprocal Exchange": 453,
  "Progressive Casualty Insurance Company": 454,
  "Progressive Direct Insurance Company": 455,
  "Progressive Max Insurance Company": 456,
  "Progressive Northern Insurance Company": 457,
  "Progressive Northwestern Insurance Company": 458,
  "Progressive Specialty Insurance Company": 459,
  "Property and Casualty Insurance Company of Hartford": 460,
  "Protective Insurance Company": 461,
  "Protective Life Insurance Company": 462,
  "Providence Mutual Fire Insurance Company": 463,
  "Provident Life and Accident Insurance Company": 464,
  "Pruco Life Insurance Company": 465,
  "Prudential Annuities Life Assurance Corporation": 466,
  "Prudential Insurance Company of America": 467,
  "QBE Insurance Corporation": 468,
  "QBE Specialty Insurance Company": 469,
  "Quincy Mutual Fire Insurance Company": 470,
  "RLI Insurance Company": 471,
  "Regent Insurance Company": 472,
  "ReliaStar Life Insurance Company": 473,
  "ReliaStar Life Insurance Company of New York": 474,
  "Reliance Standard Life Insurance Company": 475,
  "RepWest Insurance Company": 476,
  "Republic-Franklin Insurance Company": 477,
  "RiverSource Life Insurance Company": 478,
  "Riverport Insurance Company": 479,
  "SAFECO Insurance Company of America": 480,
  "SAFECO Insurance Company of Illinois": 481,
  "SAFECO Insurance Company of Indiana": 482,
  "SCOR Global Life Americas Reinsurance Company": 483,
  "SPARTA Insurance Company": 484,
  "Safety National Casualty Corporation": 485,
  "Scottsdale Insurance Company": 486,
  "Securian Life Insurance Company": 487,
  "Security Benefit Life Insurance Company": 488,
  "Security Life Insurance Company of America": 489,
  "Security Life of Denver Insurance Company": 490,
  "Security Mutual Life Insurance Company of New York": 491,
  "Security National Insurance Company": 492,
  "Selective Insurance Company of America": 493,
  "Selective Insurance Company of South Carolina": 494,
  "Selective Insurance Company of the Southeast": 495,
  "Seneca Insurance Company, Inc.": 496,
  "Seneca Specialty Insurance Company": 497,
  "Sentinel Insurance Company, Ltd": 498,
  "Sentry Insurance A Mutual Company": 499,
  "Sentry Life Insurance Company": 500,
  "Sentry Select Insurance Company": 501,
  "Sirius America Insurance Company": 502,
  "Sirius International Insurance Corporation": 503,
  "Spirit Commercial Auto Risk Retention Group Inc.": 504,
  "St. Paul Fire and Marine Insurance Company": 505,
  "St. Paul Mercury Insurance Company": 506,
  "Standard Fire Insurance Company (The)": 507,
  "Standard Insurance Company": 508,
  "Star Insurance Company": 509,
  "StarNet Insurance Company": 510,
  "Starr Indemnity & Liability Company": 511,
  "State Auto Property & Casualty Insurance Company": 512,
  "State Automobile Mutual Insurance Company": 513,
  "State Farm Fire & Casualty Company": 514,
  "State Farm Life Insurance Company": 515,
  "State Farm Mutual Automobile Insurance Company": 516,
  "State Life Insurance Company (The)": 517,
  "Steadfast Insurance Company": 518,
  "Stewart Title Guaranty Company": 519,
  "Stillwater Insurance Company": 520,
  "Stillwater Property and Casualty Insurance Company": 521,
  "Stonebridge Life Insurance Company": 522,
  "Stratford Insurance Company": 523,
  "Strathmore Insurance Company": 524,
  "Sun Life Assurance Company of Canada": 525,
  "Sun Life and Health Insurance Company (U.S.)": 526,
  "Surety Life Insurance Company": 527,
  "Symetra Life Insurance Company": 528,
  "T.H.E. Insurance Company": 529,
  "TIAA-CREF Life Insurance Company": 530,
  "Talcott Resolution Life Insurance Company": 531,
  "Talcott Resolution Life and Annuity Insurance Company": 532,
  "Teachers Insurance Company": 533,
  "Technology Insurance Company, Inc.": 534,
  "Time Insurance Company II": 535,
  "Title Resources Guaranty Company": 536,
  "Tokio Marine Specialty Insurance Company": 537,
  "Tower Insurance Company of New York": 538,
  "Tower National Insurance Company": 539,
  "Transamerica Advisors Life Insurance Company": 540,
  "Transamerica Casualty Insurance Company": 541,
  "Transamerica Life Insurance Company": 542,
  "Transamerica Premier LIfe Insurance Company": 543,
  "Transportation Insurance Company": 544,
  "TravCo Insurance Company": 545,
  "Travelers Casualty Company (The)": 546,
  "Travelers Casualty Company of Connecticut": 547,
  "Travelers Casualty Insurance Company of America": 548,
  "Travelers Casualty and Surety Company": 549,
  "Travelers Casualty and Surety Company of America": 550,
  "Travelers Constitution State Insurance Company": 551,
  "Travelers Home and Marine Insurance Company (The)": 552,
  "Travelers Indemnity Company (The)": 553,
  "Travelers Indemnity Company of America (The)": 554,
  "Travelers Indemnity Company of Connecticut (The)": 555,
  "Travelers Personal Insurance Company": 556,
  "Travelers Personal Security Insurance Company": 557,
  "Travelers Property Casualty Company of America": 558,
  "Travelers Property Casualty Insurance Company": 559,
  "Trenwick America Reinsurance Corporation": 560,
  "Truck Insurance Exchange": 561,
  "Trumbull Insurance Company": 562,
  "Twin City Fire Insurance Company": 563,
  "U.S. Financial Life Insurance Company": 564,
  "UNUM Life Insurance Company of America": 565,
  "USAA Casualty Insurance Company": 566,
  "USAA General Indemnity Company": 567,
  "USAA Life Insurance Company": 568,
  "Underwriters at Lloyds London": 569,
  "Unified Life Insurance Company": 570,
  "Union Central Life Insurance Company (The)": 571,
  "Union Mutual Fire Insurance Company": 572,
  "Union Security Insurance Company": 573,
  "United American Insurance Company": 574,
  "United Concordia Insurance Company": 575,
  "United Financial Casualty Company": 576,
  "United Ohio Insurance Company": 577,
  "United Property & Casualty Insurance Company": 578,
  "United Services Automobile Association": 579,
  "United Specialty Insurance Company": 580,
  "United States Fire Insurance Company": 581,
  "United States Liability Insurance Company": 582,
  "United States Life Insurance Company in the City of New York": 583,
  "United of Omaha Life Insurance Company": 584,
  "UnitedHealthcare Insurance Company": 585,
  "UnitedHealthcare Life Insurance Company": 586,
  "Unitrin Direct Insurance Company": 587,
  "Unitrin Preferred Insurance Company": 588,
  "Universal North America Insurance Company": 589,
  "Universal Underwriters Insurance Company": 590,
  "Unum Insurance Company": 591,
  "Utica First Insurance Company": 592,
  "Utica Mutual Insurance Company": 593,
  "Utica National Assurance Company": 594,
  "Utica National Insurance Company of Ohio": 595,
  "Utica Specialty Risk Insurance Company": 596,
  "Valley Forge Insurance Company": 597,
  "Vanliner Insurance Company": 598,
  "Vantis Life Insurance Company": 599,
  "Vermont Mutual Insurance Company": 600,
  "Victoria Fire & Casualty Company": 601,
  "Vigilant Insurance Company": 602,
  "Viking Insurance Company of Wisconsin": 603,
  "Virginia Surety Company Inc.": 604,
  "Voya Insurance and Annuity Company": 605,
  "Voya Retirement Insurance and Annuity Company": 606,
  "Voyager Indemnity Insurance Company": 607,
  "Washington National Insurance Company": 608,
  "WellCare of Connecticut, Inc.": 609,
  "Wesco Insurance Company": 610,
  "West American Insurance Company": 611,
  "West Coast Life Insurance Company": 612,
  "Westchester Fire Insurance Company": 613,
  "Western Surety Company": 614,
  "Western World Insurance Company": 615,
  "Westfield Insurance Company": 616,
  "Wilcac Life Insurance Company": 617,
  "Wilco Life Insurance Company": 618,
  "Wright National Flood Insurance Company": 619,
  "XL Specialty Insurance Company": 620,
  "Zurich American Insurance Company": 621,
  "Zurich American Insurance Company of Illinois": 622,
};
export const COVERAGE = {
  "A & H": 0,
  Auto: 1,
  "Auto Liability": 2,
  "Auto Medical Payment": 3,
  "Auto Physical Damage": 4,
  "Auto Uninsured Motorist": 5,
  "Auto Warranty": 6,
  Aviation: 7,
  "Commercial Auto": 8,
  "Commercial Fire": 9,
  "Commercial Multi-Peril": 10,
  "Commercial Umbrella": 11,
  "Condo Association": 12,
  "Condo Unit Owner": 13,
  "Credit A & H": 14,
  "Credit Life": 15,
  "Credit/Property": 16,
  Dental: 17,
  "Dental/Group Assoc Trust": 18,
  "Dwelling Fire": 19,
  "Exchange-Dental": 20,
  "Exchange-Group Health": 21,
  "Exchange-Individual Hlth": 22,
  "Extended Warranty(Other)": 23,
  Farmowners: 24,
  "Federal Flood": 25,
  "Fidelity and Surety": 26,
  "Fire, Allied": 27,
  "General Liability": 28,
  "Group Annuity": 29,
  "Group Disability Income": 30,
  "Group Health": 31,
  "Group Life": 32,
  "Group Long Term Care": 33,
  "Group Med Supplemental": 34,
  "Group/Association Trust": 35,
  "Home Warranty": 36,
  Homeowners: 37,
  "Ind Disability Income": 38,
  "Ind Medicare Supplemental": 39,
  "Individual Annuity": 40,
  "Individual Health": 41,
  "Individual Life": 42,
  "Individual Long Term Care": 43,
  "Inland Marine": 44,
  "Life and Annuity": 45,
  "Limited Benefit Health": 46,
  "Medical Malpractice": 47,
  Medicare: 48,
  "Medicare Advantage": 49,
  "Medicare Part D Rx": 50,
  "Mobile Home": 51,
  Motorcycle: 52,
  "Ocean Marine": 53,
  "Other [Enter Coverage]": 54,
  "Personal Umbrella": 55,
  "Portable Electronics Ins": 56,
  Prescription: 57,
  "Professional Liability": 58,
  "Short Term Medical": 59,
  "Spec Disease-Individual": 60,
  "Specified Disease - Group": 61,
  "Tenant Homeowners": 62,
  "Title Insurance": 63,
  "Travel Insurance": 64,
  Unknown: 65,
  Vision: 66,
  Watercraft: 67,
  "Worker's Compensation": 68,
};
export const REASON = {
  "Employer Handling": 0,
  "FOI Inquiry": 1,
  "Marketing/Sales": 2,
  "No Jurisdiction": 3,
  Other: 4,
  "Premium and Rating": 5,
  "Retro Review": 6,
  "Statute Violation": 7,
  Underwriting: 8,
  "Unfair Claims Practice": 9,
  Unknown: 10,
  "Utilization Review": 11,
};
export const SUBREASON = {
  "Actual Cash Value Dispute": 0,
  "After Mrkt Prts/Unsat Set": 1,
  Audit: 2,
  "Benefit Extension": 3,
  "CPT Code Issue": 4,
  "CT Continuation 38a-512a": 5,
  Cancellation: 6,
  "Carrier Never Rec'd Appl": 7,
  "Carrier Never Rec'd Claim": 8,
  "Case Management": 9,
  "Claim Delays": 10,
  "Claim Procedure": 11,
  Classification: 12,
  "Comparative Negligence": 13,
  "Contract Provision": 14,
  "Coordination of Benefit": 15,
  Delays: 16,
  "Denial of Claim": 17,
  "Diminished Value": 18,
  "Discontinuation & Replmnt": 19,
  "Duplicate Coverage": 20,
  "Eligibility of Provider": 21,
  "Excessive Charges": 22,
  "Failed to Remit Premium": 23,
  "Labor Rate": 24,
  "Loss of Use": 25,
  "Mandated Benefit": 26,
  "Medical Necessity": 27,
  "Mis-Quote": 28,
  "Misleading Advertising": 29,
  Misrepresentation: 30,
  "Network Adequacy": 31,
  "No Coverage/Premium Paid": 32,
  "No Response": 33,
  "No Subreason": 34,
  "Non-Renewal": 35,
  "Other CT state agency": 36,
  "Other Fees": 37,
  "Other [Enter Sub-Reason]": 38,
  "Other state DOI": 39,
  "Policy Issue Delay": 40,
  "Policy Service": 41,
  "Policy Service Delay": 42,
  "Pre-Existing Condition": 43,
  "Premium Refund Delay": 44,
  "Premium/Notice": 45,
  "Premium/Rate Increase": 46,
  "Producer Handling": 47,
  "Provider Contract Issue": 48,
  Rebate: 49,
  "Refusal to Insure": 50,
  Replacement: 51,
  Rescission: 52,
  "Service Fees": 53,
  Steering: 54,
  "Storage Fees": 55,
  Subrogation: 56,
  "Surprise Billing": 57,
  "Time Delay": 58,
  "UR Procedure": 59,
  "Unapproved Form": 60,
  "Underwriting Delays": 61,
  "Underwrtng/Credit History": 62,
  "Underwrtng/Waivers/Rated": 63,
  "Unfair Discrimination": 64,
  "Unprofessional Conduct": 65,
  "Unsatisfactory Offer": 66,
  "Unsatisfactory Settlement": 67,
  "Usual and Customary Fees": 68,
};
export const DISPOSITION = {
  "Accident in Another State": 0,
  "Additional Money Received": 1,
  "Cancellation Upheld": 2,
  "Cancellation Withdrawn": 3,
  "Claim Paid With Interest": 4,
  "Claim Reopened": 5,
  "Claim Settled": 6,
  "Class Revised": 7,
  "Company Position Upheld": 8,
  "Complaint Withdrawn": 9,
  "Contract Provision": 10,
  "Contract Violation": 11,
  "Corrective Action": 12,
  "Coverage Denied": 13,
  "Coverage Extended": 14,
  "Coverage Granted": 15,
  "Deductible Recovered": 16,
  "Enter Arbitration": 17,
  "External Review Info Sent": 18,
  "Extl Rev Info Sent/SF": 19,
  Federal: 20,
  "Fees Returned": 21,
  "Filed Errors&Omission Clm": 22,
  "Furnished Information": 23,
  "Insufficient Information": 24,
  "Insured Retained Attorney": 25,
  "Inter-Company Arbitration": 26,
  "Med Jurisdiction Explained": 27,
  Mediation: 28,
  "No Cause For Action": 29,
  "Non-Renewal Rescinded": 30,
  "Non-Renewal Upheld": 31,
  "Not Applicable": 32,
  "Not Insurance Related": 33,
  "Other [Enter Disposition]": 34,
  "Pol Restored/Reinstated": 35,
  "Policy Issued": 36,
  "Policy Not In Force": 37,
  "Policy Offered": 38,
  "Policy not written in CT": 39,
  "Premium Refund": 40,
  "Provider Issue": 41,
  "Rate Increase Explained": 42,
  "Rate Problem Solved": 43,
  "Record Only": 44,
  "Refer To Agency": 45,
  "Refer To Appraisal": 46,
  "Refer-Judicial/Attorney": 47,
  "Satisfactory Explanation": 48,
  Satisfied: 49,
  "Underwriting Discretion": 50,
  "Underwriting Guidelines": 51,
  "Usual & Custom Explained": 52,
};
