import React, { memo, useRef, useEffect, useState } from "react";
import {
    Grid,
    TextField,
    InputAdornment,
    Paper,
    AppBar,
    Tab,
    Tabs,
    MenuItem,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    TableContainer,
    TableSortLabel,
    TablePagination,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Avatar
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faThumbsUp, faThumbsDown, faTimes, faRetweet, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RadioGroup from "@material-ui/core/RadioGroup";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from "@material-ui/icons/Search";
import JoditEditor from "jodit-react";
import Radio from '@material-ui/core/Radio';
import SendIcon from "@material-ui/icons/Send";
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

import { forwardRef } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


// local components
import styled from 'styled-components';
import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import CommonService from "../../../utils/services/CommonService";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    ListItem,
} from '../../../styles/common';
import getUserData from "../../../utils";


// images
import DocSearch_sol1 from "../../../assets/images/app/KnowledgeSearchPlatform.jpg";
import ChatBot from "../../../assets/images/app/chatBot.jpg";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ColbertAnalysis = () => {

    const { postQueryResult, multipleUploadFile } = CommonService(
        "docSearch", "docSearch",
    );


    const [showSpinner, setSpinner] = useState(false);
    const [notification, setNotification] = useState("");
    const [selectedFile, setselectedFile] = useState([]);
    const [btnEnable, setBtnEnable] = useState(true);
    const [domainBtnEnable, setdomainBtnEnable] = useState(true);
    const [SdomainBtnEnable, setSdomainBtnEnable] = useState(true);
    const [filename, setFileName] = useState('')
    const [userId, setUserId] = useState(getUserData()?.token?.emailid);
    const [value, setValue] = useState(0);
    const [enableSearch, setEnableSearch] = useState(true);
    const [chooseModel, setchooseModel] = React.useState('');
    const [chooseRole, setchooseRole] = React.useState('');
    const [modelBtnEnable, setModelBtnEnable] = useState(true);
    const [roleBtnEnable, setRoleBtnEnable] = useState(true);
    const [chooseDomain, setchooseDomain] = React.useState('');
    const [chooseSubDomain, setchooseSubDomain] = React.useState('');
    const [searchData, setSearchInput] = useState('');
    const [llmAnswer, setllmAnswer] = useState('');
    const [resAnswer, setresAnswer] = useState('');
    const [diffAnswer, setDiffAnswer] = useState('');
    const [resAnswer1, setresAnswer1] = useState('');
    const [resCitation1, setresCitation1] = useState('');
    const [resPage1, setresPage1] = useState('');
    const [resAnswer2, setresAnswer2] = useState('');
    const [resCitation2, setresCitation2] = useState('');
    const [resPage2, setresPage2] = useState('');
    const [addFile, setAddFile] = useState([]);

    const [orderBy, setOrderBy] = useState(""); // State for sorting
    const [order, setOrder] = useState("asc"); // State for sorting order
    const [page, setPage] = useState(0); // State for current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // State for rows per page

    // State to store filter values for each column
    const [filters, setFilters] = useState({
        id: "",
        file_name: "",
        domain: "",
        sub_domain: "",
    });

    // Function to handle changes in filter values
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(columnId);
    };

    const filteredData = addFile.filter((row) => {
        return Object.keys(filters).every((key) => {
            if (!filters[key]) return true;
            return String(row[key])
                .toLowerCase()
                .includes(filters[key].toLowerCase());
        });
    });

    const sortedData = orderBy
        ? filteredData.slice().sort((a, b) => {
            const aValue = a[orderBy];
            const bValue = b[orderBy];
            if (typeof aValue === "number" && typeof bValue === "number") {
                return order === "asc" ? aValue - bValue : bValue - aValue;
            } else {
                return order === "asc"
                    ? String(aValue).localeCompare(String(bValue))
                    : String(bValue).localeCompare(String(aValue));
            }
        })
        : filteredData;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // for tab values
    const tabhandleChange = (event, newValue) => {
        setValue(newValue);
        setchooseDomain('')
        setchooseSubDomain('')
        setchooseModel('')
        setchooseRole('')
        setdomainBtnEnable(true)
        setSdomainBtnEnable(true)
        setModelBtnEnable(true)
        setRoleBtnEnable(true)
        setEnableSearch(true)

    };

    const onChangeFile = (e) => {
        const file = e.target.files;
        if (file) {
            setselectedFile([file]);
            setBtnEnable(false);
        } else {
            setBtnEnable(true);
            setselectedFile([""]);
        }

    };

    const getUploadResult = () => {
        setSpinner(true);
        multipleUploadFile("docfileupload", selectedFile[0])
            .then((res) => {
                setSpinner(false);
                setEnableSearch(false)
                // setS3path(res.Result.path);
                setFileName(res.Result);
                handleAddFile(res.Result)
                setNotification({ open: 'success', message: "Uploaded Successfully" });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const businessCorporate = ["Marketing", "Sales", "Human Resources", "Finance", "Operations"];
    // const educational = ["Mathematics", "Science", "Literature", "History", "Technology"];
    const technology = ["Software Development", "Networking", "Cybersecurity", "Artificial Intelligence", "Cloud Computing"];
    const healthcare = ["Bio-Pharma", "MedTech", "Payers", "Providers"];
    const finance = ["Corporate Finance", "Investment Management", "Financial Markets", "Corporate Banking", "Retail Banking"];
    // const banking = [];
    const insurance = ["Life Insurance", "Medical Insurance", "Vehicle Insurance", "Property Insurance"];
    const products = ["Drugs", "Devices"];

    let type = null;

    const handleDomainChange = (event) => {
        setchooseDomain(event.target.value);
        if (event.target.value) {
            setdomainBtnEnable(false)
            setSdomainBtnEnable(true)
        } else {
            setdomainBtnEnable(true)
        }
        setllmAnswer("")
        setresAnswer("")
        setDiffAnswer("")
        setresAnswer1("")
        setresCitation1("")
        setresPage1("")
        setresAnswer2("")
        setresCitation2("")
        setresPage2("")
    }

    const handleSubDomainChange = (event) => {
        setchooseSubDomain(event.target.value);
        if (event.target.value) {
            setSdomainBtnEnable(false)
        } else {
            setSdomainBtnEnable(true)
        }
        setllmAnswer("")
        setresAnswer("")
        setDiffAnswer("")
        setresAnswer1("")
        setresCitation1("")
        setresPage1("")
        setresAnswer2("")
        setresCitation2("")
        setresPage2("")
    }

    if (chooseDomain === "Business/Corporate") {
        type = businessCorporate;
    } else if (chooseDomain === "Technology") {
        type = technology;
    } else if (chooseDomain === "Lifescience/Healthcare") {
        type = healthcare;
    } else if (chooseDomain === "Finance/Banking") {
        type = finance;
    } else if (chooseDomain === "Insurance") {
        type = insurance;
    } else if (chooseDomain === "Products") {
        type = products;
    }

    const handleChange = (event) => {
        setchooseModel(event.target.value);
        if (event.target.value) {
            setModelBtnEnable(false)
        } else {
            setModelBtnEnable(true)
        }
        setllmAnswer("")
        setresAnswer("")
        setDiffAnswer("")
        setresAnswer1("")
        setresCitation1("")
        setresPage1("")
        setresAnswer2("")
        setresCitation2("")
        setresPage2("")

    };

    const handleRoleChange = (e) => {
        setchooseRole(e.target.value);
        if (e.target.value) {
            setRoleBtnEnable(false)
        } else {
            setRoleBtnEnable(true)
        }
        setllmAnswer("")
        setresAnswer("")
        setDiffAnswer("")
        setresAnswer1("")
        setresCitation1("")
        setresPage1("")
        setresAnswer2("")
        setresCitation2("")
        setresPage2("")
    };

    const handleSearch = (e) => {
        if (e.target.value) {
            setSearchInput(e.target.value);
        } else {
            setllmAnswer("")
            setresAnswer("")
            setDiffAnswer("")
            setresAnswer1("")
            setresCitation1("")
            setresPage1("")
            setresAnswer2("")
            setresCitation2("")
            setresPage2("")
        }
    };

    const readPrevFileForUser = () => {
        const params = {
            "userid": userId
        };
        setSpinner(true);
        postQueryResult("userPrevFile", params)
            .then((res) => {
                setSpinner(false);
                let response = JSON.parse(res.Result);
                setAddFile(response)
                console.log("USERPREV--->", response)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    useEffect(() => {
        readPrevFileForUser()
    }, [userId])

    const updateKnowledgeBase = () => {
        const params = {
            "user_id": userId,
            "files": filename,
            "domain": chooseDomain,
            "sub_domain": chooseSubDomain || "",
        };
        setSpinner(true);
        postQueryResult("knowledgeBaseUpdate", params)
            .then((res) => {
                setSpinner(false);
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    }

    const handleAddFile = (filename) => {
        const params = {
            "userid": userId,
            "file_path": filename,
            "domain": chooseDomain,
            "sub_domain": chooseSubDomain || "",
        };

        setSpinner(true);
        postQueryResult("addUserFile", params)
            .then((res) => {
                setSpinner(false);
                readPrevFileForUser()
                console.log("USERaddUserFile--->", res.Result)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    }

    const handleClickSearchIcon = () => {
        setllmAnswer("")
        setresAnswer("")
        setDiffAnswer("")
        setresAnswer1("")
        setresCitation1("")
        setresPage1("")
        setresAnswer2("")
        setresCitation2("")
        setresPage2("")
        const params = {
            "question": searchData,
            "model_name": chooseModel,
            "user_role": chooseRole,
            "user_id": userId,
            "files": filename,
            "domain": chooseDomain,
            "sub_domain": chooseSubDomain || "",
        };
        setSpinner(true);
        postQueryResult("docsearch", params)
            .then((res) => {
                setSpinner(false);
                setllmAnswer(res.Result?.llm_result)
                setresAnswer(res.Result?.response);
                setDiffAnswer(res.Result?.diff_result);
                setresAnswer1(res.Result?.searchresult1);
                setresCitation1(res.Result?.source_doc1)
                setresPage1(res.Result?.page_number1)
                setresAnswer2(res.Result?.searchresult2);
                setresCitation2(res.Result?.source_doc2)
                setresPage2(res.Result?.page_number2)
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchIcon();
        }
    };

    const handlDelete = (item) => {
        const params = {
            "userid": userId,
            "id": item.id,
        };
        setSpinner(true);
        postQueryResult("deleteFile", params)
            .then((res) => {
                setSpinner(false);
                readPrevFileForUser()
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    }


    // for conversation AI  

    const [chatBot, setChatBot] = useState(false);
    const [chatBotTab, setChatBotTab] = useState(false);
    const [chatbotList, setChatList] = useState([]);
    const [chatbotQuery, setChatbotquery] = useState('');
    const [chatbotQuery2, setChatbotquery2] = useState('');
    const [chatbotQueryValue, setChatbotqueryValue] = useState('');
    const [chatbotQueryValue2, setChatbotqueryValue2] = useState('');
    const [view2Query, setView2Query] = useState(false);
    const [userRole, setUserRole] = useState("");
    const [response1, setResponse1] = useState("");
    const [response2, setResponse2] = useState("");
    const [response1View, setResponse1View] = useState(false);
    const [response2View, setResponse2View] = useState(false);
    const [response2ViewFirst, setResponse2ViewFirst] = useState(false);
    const [query2Response1, setQuery2Response1] = useState("");
    const [query2Response2, setQuery2Response2] = useState("");
    const [query2Response1View, setQuery2Response1View] = useState(false);
    const [query2Response2View, setQuery2Response2View] = useState(false);
    const [query2Response2ViewFirst, setQuery2Response2ViewFirst] = useState(false);
    const [disLike2Query1, setDisLike2Query1] = useState(false);
    const [disLike2Query2, setDisLike2Query2] = useState(false);
    const [roleStatus, setRoleStatus] = useState(false);
    const [domainvisible, setDomainvisible] = useState(false);
    const [roleVisible, setRoleVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    const [domain2, setDomain2] = useState(false);
    const [domain3, setDomain3] = useState(false);
    const refToScroll = useRef(null);
    const [dislike2, setDislike2] = useState(false);
    const [dislike1, setDislike1] = useState(false);
    const [open, setOpen] = useState(false);
    const [disable, setDisable] = useState(true);
    const [domainDisable, setDomainDisable] = useState(false);
    const [roleDisable, setRoleDisable] = useState(false);


    // const enterQuery = () => {

    //     if (userRole === "") {

    //     }
    //     else {
    //         if (roleStatus === false) {

    //             setRoleVisible(true);
    //             setLoader(true);
    //             setChatbotquery('');

    //             setTimeout(function () {
    //                 setRoleStatus(true);
    //                 setLoader(false);
    //             }, 1000);
    //         }
    //         else {
    //             setChatbotqueryValue(chatbotQuery);
    //             getQueryresult(chatbotQuery);
    //             setChatbotquery('');
    //         }
    //     }
    // };


    const enterQuery = () => {

        setDomainDisable(true);
        setRoleDisable(true);
        if (chatbotQueryValue === "") {
            setChatbotqueryValue(chatbotQuery);
            getQueryresult(chatbotQuery);
            setChatbotquery('');
        }
        else {
            setChatbotqueryValue2(chatbotQuery2);
            getQueryresult(chatbotQuery2);
            setChatbotquery('');
        }

    };

    const setAnswer = (data) => {
        setChatList((prevState) => [...prevState, { data, type: 'answer' }]);
    };

    const handleChatbotQuery = (e) => {
        // if (roleStatus === false) {
        //     setUserRole(e.target.value);
        //     setChatbotquery(e.target.value);
        // }
        // else {
        //     setChatbotquery(e.target.value);
        // }
        if (chatbotQueryValue === "") {
            setChatbotquery(e.target.value);
        }
        else {
            setChatbotquery(e.target.value);
            setChatbotquery2(e.target.value)
        }
    };

    const setQuestion = (data) => {
        if (data) {
            setChatList((prevState) => [...prevState, { data, type: 'question' }]);
        }
    };

    const getQueryresult = (text) => {

        setQuestion(text);
        const params = {
            "question": text,
            "model_name": "Cohere",
            "user_role": userRole,
            "user_id": userId,
            "domain": domain,
            "sub_domain": ""

        };
        setLoader(true);
        postQueryResult("docsearch", params)
            .then((res) => {
                setLoader(false);
                // setAnswer(res.Result.response1);
                if (chatbotQuery2 === "") {
                    setResponse1(res.Result.response1);
                    setResponse1View(true);
                    setResponse2(res.Result.response2);
                    setDisable(true);
                }
                else {
                    setQuery2Response1(res.Result.response1);
                    setQuery2Response1View(true);
                    setQuery2Response2(res.Result.response2);
                    setDisable(true);
                }
                setNotification({ open: 'success', message: res?.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setLoader(false);
            });
    };



    const getOpen = () => {
        setOpen(true);

    };

    const handle2Query = () => {
        setView2Query(true);
        setDisable(false);

    };

    const handleDislike = () => {

        setDisLike2Query1(true);

        // if (response2 === "") {
        //     setLoader(true);
        //     setTimeout(function () {
        //         setResponse2View(true);
        //         setLoader(false);
        //     }, 2000);
        // }
        // else {
        //     setResponse2ViewFirst(true);
        //     setLoader(true);

        //     setTimeout(function () {
        //         setResponse2View(true);
        //         setLoader(false);
        //     }, 2000);
        // }

    };

    const handleSecondResponse1 = () => {

        setLoader(true);
        setTimeout(function () {
            setResponse2View(true);
            setLoader(false);
        }, 2000);

    };

    const handleSecondResponse2 = () => {

        setLoader(true);
        setTimeout(function () {
            setQuery2Response2View(true);
            setLoader(false);
        }, 2000);

    };

    const handleDislike1 = () => {

        // setDisLike2Query1(true);
        setDislike1(true);

    }

    const handleDislike2 = () => {


        setDislike2(true);
        // setQuery2Response2ViewFirst(true);
        // setLoader(true);

        // setTimeout(function () {
        //     setQuery2Response2View(true);
        //     setLoader(false);
        // }, 2000);

    };

    const handleSecondResponse = () => {

        setQuery2Response2ViewFirst(true);
        setLoader(true);

        setTimeout(function () {
            setQuery2Response2View(true);
            setLoader(false);
        }, 2000);

    };

    const handleDislikeLast = () => {

        setDisLike2Query2(true);

    }

    const handleLike = () => {

        setDomain2(true);

    };

    const handleLike2 = () => {

        setDomain3(true);

    };

    useEffect(() => {
        refToScroll.current &&
            refToScroll.current.scrollTo(0, refToScroll.current.scrollHeight + 100);
    }, [chatbotList]);


    const DomianNames = [
        "Business/Corporate", "Technology", "Lifescience/Healthcare", "Finance/Banking", "Insurance", "Products"
    ]

    let list = null;

    const businessCorporateList = ["General", "T2", "T3"];
    const technologyList = ["General", "Developer", "Designer"];
    const healthcareList = ["General", "Pharmacist", "Doctor"];
    const financeList = ["General", "Advisor", "Analyst"];
    const insuranceList = ["General", "Insurance Broker/Agent", "Policy Processing Clerk"];
    const productsList = ["General", "R&D Scientist", "Manager"];

    const [domain, setDomain] = useState("");
    const [domainValue, setDomainValue] = useState("");


    const handleDomain = (e) => {

        setRoleVisible(false);
        setRoleStatus(false);
        setDisable(true);
        const id = e.currentTarget.id;
        setchooseDomain(id);
        setLoader(true);
        setDomainvisible(true);
        setDomainValue(id);

        setTimeout(function () {
            setDomain(id);
            setLoader(false);
        }, 1000);

    };

    if (chooseDomain === "Business/Corporate") {
        list = businessCorporateList;
    } else if (chooseDomain === "Technology") {
        list = technologyList;
    } else if (chooseDomain === "Lifescience/Healthcare") {
        list = healthcareList;
    } else if (chooseDomain === "Finance/Banking") {
        list = financeList;
    } else if (chooseDomain === "Insurance") {
        list = insuranceList;
    } else if (chooseDomain === "Products") {
        list = productsList;
    }

    const handleRole = (e) => {

        const id = e.currentTarget.id;
        setRoleVisible(true);
        setLoader(true);
        setUserRole(id);


        setTimeout(function () {
            setRoleStatus(true);
            setDisable(false);
            setLoader(false);
        }, 1000);

    };

    // reset
    const handleReset = () => {

        setDomainDisable(false);
        setRoleDisable(false);
        setChatList([]);
        setChatbotquery("");
        setChatbotqueryValue("");
        setUserRole("");
        setResponse1("");
        setResponse2("");
        setResponse1View("");
        setResponse2View("");
        setResponse2ViewFirst("");
        setRoleStatus("");
        setDomainvisible("");
        setRoleVisible("");
        setDomain2("");
        setDomainValue("");
        setDomain("");
        setDomain3("");
        setDisLike2Query1(false);
        setDisLike2Query2(false);
        setDisable(true);
        setDislike1(false);
        setDislike2(false);

        setChatbotquery2("");
        setView2Query("");
        setChatbotqueryValue2("");
        setQuery2Response1("");
        setQuery2Response2("");
        setQuery2Response1View("");
        setQuery2Response2View("");
        setQuery2Response2ViewFirst("");

    };

    const handleSameDomain = () => {

        setDomainDisable(false);
        setRoleDisable(false);
        setChatList([]);
        setChatbotquery("");
        setChatbotqueryValue("");
        setResponse1("");
        setResponse2("");
        setResponse1View("");
        setResponse2View("");
        setResponse2ViewFirst("");
        setDomain2("");
        setDisLike2Query1(false);
        setDisLike2Query2(false);
        setDisable(false);
        setDislike1(false);
        setDislike2(false);

        setChatbotquery2("");
        setView2Query("");
        setChatbotqueryValue2("");
        setQuery2Response1("");
        setQuery2Response2("");
        setQuery2Response1View("");
        setQuery2Response2View("");
        setQuery2Response2ViewFirst("");
        setDomain3("");

        // setUserRole("");
        // setRoleStatus("");
        // setDomainvisible("");
        // setRoleVisible("");
        // setDomainValue("");
        // setDomain("");

    };


    useEffect(() => {
        refToScroll.current &&
            refToScroll.current.scrollTo({
                top: refToScroll.current.scrollHeight + 100,
                behavior: 'smooth'
            });
    }, [domain, domainValue, roleVisible, response2View, response1View, roleStatus, domain2, response2ViewFirst, view2Query, query2Response1View, query2Response1, query2Response2ViewFirst, query2Response2View, domain3, disLike2Query1, disLike2Query2, dislike2, dislike1]);


    const handleCloseChat = () => {
        handleReset();
        setChatBotTab(false);
    };


    //pop up 

    const [opened, setOpened] = React.useState(false);

    const handleClickOpen = () => {
        setChatBotTab(true);
    };

    const handleClose = () => {
        setOpened(false);
    };

    return (
        <SolutionContainer snackbar={notification}>
            <Collapse text="Description">
                <Paragraph>
                    <p style={{ fontWeight: "600" }}>Unveiling Knowledge from Documents: A Search and Retrieval Application </p>
                    <p style={{ fontWeight: "600" }}>How it is different from normal search? </p>
                    <p>Researchers and medical professionals can upload medical research papers, clinical trial reports, or other relevant documents and ask specific questions about treatments, diseases, or drug interactions from the document. The application can retrieve relevant sections and answer their questions in a concise and informative way, saving them valuable time and effort in their research endeavors. While other document search and retrieval applications exist, this application's focus on <strong>natural language interaction, comprehensive answers with citations, and the potential for integration with healthcare data</strong> makes it uniquely valuable for the healthcare domain. </p>
                    <p><strong>Conversational Search: </strong>Unlike traditional search engines, you can ask open-ended questions like, "What are the potential side effects of drug X?" or "How does this research compare treatment A and treatment B for disease Y?" from the given pdfs. It addresses the challenge of efficiently searching and extracting information from large document sets. It uses techniques like Information Retrieval (IR) to enable users to ask open ended questions about the document content and receive informative answers with citations. </p>
                    <p>The application accepts a user query along with user role and user_id as input. It then performs the following steps: </p>
                    <p><strong>1. Document Processing: </strong>The model loads and splits the user-provided PDF documents into smaller chunks. </p>
                    <p>The process commences with users providing paths to relevant PDF documents and user_id. </p>
                    <p>The Pdf loader to load the document content. Subsequently, a Character Splitter divides each document into manageable chunks. This chunking process, set to around some n characters with some overlap, optimizes the efficiency of subsequent steps. </p>
                    <p><strong>2. Embedding Generation: </strong>Each text chunk is converted into a numerical representation using a pre-trained language model. This task is handled by HuggingFace embeddings specifically designed for this purpose. The generated embeddings capture the semantic meaning and relationships within the text, allowing for efficient comparisons between the user's query and the document content. </p>
                    <p><strong>3. Document Storage and Retrieval:</strong></p>
                    <p>The application utilizes a persistent vector database to store the generated embeddings. Chroma vector store is used to persist the embeddings. This vector store acts as a knowledge repository, enabling efficient retrieval of relevant document sections based on future user queries. When a new query arises, the system can quickly identify the document chunks containing the most semantically similar information to the user's question. </p>
                    <p>It also estimates the prompt length for which the LLM will be answering the question. </p>
                    <p>Based on the estimated prompt length: </p>
                    <p><li>If the prompt is under 4000 tokens, it uses stuff_retrieval. This function creates a prompt for the LLM that includes user role, search results (up to 3 most relevant document passages), conversation history, and the user's question. It then uses a RetrievalQA chain with the Chroma vector database for efficient retrieval and the LLM for answering.</li></p>
                    <p><li>If the prompt is longer, it uses map_reduce_retrieval. This function creates a similar prompt but leverages a different RetrievalQA chain designed to handle longer prompts by splitting the task into smaller parts.</li> </p>
                    <p><strong>4. User Interaction and Answer Generation:</strong> </p>
                    <p>Users can give open-ended questions about the document content along with their user id, user role. The application retrieves the most relevant document chunks based on the query's meaning
                        Based on the chosen model, the appropriate LLM<strong>(Cohere, Claude, Jurassic)</strong> process these chunks and formulate an informative answer, incorporating citations for transparency.  </p>
                    <p>The Memory component helps the application to maintain a separate Chroma vector database with respective user id history to store the user's past interactions (questions and LLM responses) which leverages the LLM to consider the context of previous questions and answers when generating a response. This leads to a more coherent and informative conversation flow. Users wouldn't need to repeatedly provide context or background information for related questions. This streamlines the interaction and saves time, particularly when dealing with complex medical topics. </p>
                    <p><b>Solution Architecture</b></p>
                    <center>
                        <Grid item xs={12} sm={10} md={10}>
                            <img width="100%" src={DocSearch_sol1} alt="" />
                        </Grid>
                    </center>
                </Paragraph>
                <DemoContainer>
                    <Grid item style={{ width: "100%", padding: "0px 10px 10px 10px" }}>
                        <StyledButton
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<OpenInNewIcon />}>
                            <a
                                href="https://material.vlifevirtusa.com/ContextBasedSearch/contextual_knowledge_Search_Platform.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Notebook
                            </a>
                        </StyledButton>
                    </Grid>
                    <Grid container xs={12} spacing={2} direction="row">
                        <Grid item xs={12}>
                            <AppBar position="static" color="default">
                                <StyledTabs
                                    value={value}
                                    onChange={tabhandleChange}
                                    variant="fullWidth"
                                    aria-label="simple tabs example">
                                    <Tab label="Knowledge Base" />
                                    <Tab label="Knowledge Search" />
                                    {/* <Tab label="ChatBot" /> */}
                                </StyledTabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                    <TabPanel value={value} index={0}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center">

                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <Grid item style={{ marginTop: "4px", border: "1px solid lightgray", borderRadius: "5px", padding: "5px", margin: "15px", marginLeft: "25px" }}>
                                        <input
                                            type="file"
                                            color="primary"
                                            name="uploadedFile"
                                            onChange={onChangeFile}
                                            style={{ float: "left" }}
                                            multiple
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose Domain</InputLabel>
                                            <Select
                                                value={chooseDomain}
                                                onChange={handleDomainChange}
                                                style={{ width: "250px", margin: "10px" }}>
                                                <MenuItem value="Business/Corporate">Business/Corporate</MenuItem>
                                                <MenuItem value="Technology">Technology</MenuItem>
                                                <MenuItem value="Lifescience/Healthcare">Lifescience/Healthcare</MenuItem>
                                                <MenuItem value="Finance/Banking">Finance/Banking</MenuItem>
                                                <MenuItem value="Insurance">Insurance</MenuItem>
                                                <MenuItem value="Products">Products</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel style={{ marginLeft: "10px" }}>Choose SubDomain</InputLabel>
                                            <Select
                                                value={chooseSubDomain}
                                                onChange={handleSubDomainChange}
                                                style={{ width: "250px", margin: "10px" }}>
                                                {type?.map((value) => (
                                                    <MenuItem key={value} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginRight: "20px" }}>
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            helperText="Please select a txt file only"
                                            // disabled={btnEnable || domainBtnEnable || SdomainBtnEnable}
                                            disabled={btnEnable || domainBtnEnable}
                                            onClick={getUploadResult}>
                                            Upload
                                        </StyledButton>
                                    </Grid>
                                </div>
                                <Grid
                                    container
                                    xs={12}
                                    spacing={0}
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={12} md={11}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ backgroundColor: '#2941b7' }}>
                                                    <TableRow>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                style={{ color: "white" }}
                                                                active={orderBy === "id"}
                                                                direction={orderBy === "id" ? order : "asc"}
                                                                onClick={() => handleSort("id")}
                                                            >
                                                                ID
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                style={{ color: "white" }}
                                                                active={orderBy === "filename"}
                                                                direction={orderBy === "filename" ? order : "asc"}
                                                                onClick={() => handleSort("filename")}
                                                            >
                                                                File Name
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                style={{ color: "white" }}
                                                                active={orderBy === "domain_name"}
                                                                direction={orderBy === "domain_name" ? order : "asc"}
                                                                onClick={() => handleSort("domain_name")}
                                                            >
                                                                Domain Name
                                                            </TableSortLabel>
                                                        </TableCell>

                                                        <TableCell>
                                                            <TableSortLabel
                                                                style={{ color: "white" }}
                                                                active={orderBy === "subdomain_name"}
                                                                direction={orderBy === "subdomain_name" ? order : "asc"}
                                                                onClick={() => handleSort("subdomain_name")}
                                                            >
                                                                Sub Domain Name
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell style={{ color: "white" }}>Action</TableCell>


                                                        {/* <HeaderCol align="start">ID</HeaderCol>
                                                    <HeaderCol align="start">File Name</HeaderCol>
                                                    <HeaderCol align="start">Domain Name</HeaderCol>
                                                    <HeaderCol align="start">Sub Domain Name</HeaderCol>
                                                    <HeaderCol align="start">Action</HeaderCol> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {/* {addFile.map((item, index) => ( */}
                                                    <TableRow>
                                                        <TableCell>
                                                            <CustomTextField
                                                                label="ID"
                                                                name="id"
                                                                value={filters.id}
                                                                onChange={handleFilterChange}
                                                            />
                                                            {/* {index+1} */}
                                                        </TableCell>
                                                        <TableCell>
                                                            <CustomTextField
                                                                label="File Name"
                                                                name="file_name"
                                                                value={filters.file_name}
                                                                onChange={handleFilterChange}
                                                            />
                                                            {/* {item.file_name} */}
                                                        </TableCell>
                                                        <TableCell>
                                                            <CustomTextField
                                                                label="Domain Name"
                                                                name="domain"
                                                                value={filters.domain}
                                                                onChange={handleFilterChange}
                                                            />
                                                            {/* {item.domain} */}
                                                        </TableCell>
                                                        <TableCell>
                                                            <CustomTextField
                                                                label="Sub Domain Name"
                                                                name="sub_domain"
                                                                value={filters.sub_domain}
                                                                onChange={handleFilterChange}
                                                            />
                                                            {/* {item.sub_domain} */}
                                                        </TableCell>
                                                        <TableCell>{" "}
                                                            {/* <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faTrash} color="red" onClick={() => handlDelete(item)}/> */}
                                                        </TableCell>
                                                    </TableRow>
                                                    {/* ))} */}
                                                    {sortedData
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        )
                                                        ?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{row.id}</TableCell>
                                                                <TableCell>{row.file_name}</TableCell>
                                                                <TableCell>{row.domain}</TableCell>
                                                                <TableCell>{row.sub_domain}</TableCell>
                                                                <TableCell>
                                                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faTrash} color="red" onClick={() => handlDelete(row)} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={sortedData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item style={{ float: "right", marginRight: "40px", marginTop: "10px" }}>
                                    <StyledButton
                                        id="btn2"
                                        variant="contained"
                                        color="primary"
                                        helperText="Please select a txt file only"
                                        disabled={enableSearch}
                                        onClick={updateKnowledgeBase}>
                                        Update knowledge Base
                                    </StyledButton>
                                </Grid>
                            </div>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center">
                            <div style={{ width: "100%" }}>
                                <Grid item style={{ marginTop: "10px", display: "flex" }}>
                                    <FormControl>
                                        <InputLabel style={{ marginLeft: "10px" }}>Choose Domain</InputLabel>
                                        <Select
                                            value={chooseDomain}
                                            onChange={handleDomainChange}
                                            style={{ width: "250px", margin: "10px" }}>
                                            <MenuItem value="Business/Corporate">Business/Corporate</MenuItem>
                                            <MenuItem value="Technology">Technology</MenuItem>
                                            <MenuItem value="Lifescience/Healthcare">Lifescience/Healthcare</MenuItem>
                                            <MenuItem value="Finance/Banking">Finance/Banking</MenuItem>
                                            <MenuItem value="Insurance">Insurance</MenuItem>
                                            <MenuItem value="Products">Products</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel style={{ marginLeft: "10px" }}>Choose SubDomain</InputLabel>
                                        <Select
                                            value={chooseSubDomain}
                                            onChange={handleSubDomainChange}
                                            style={{ width: "250px", margin: "10px" }}>
                                            {type?.map((value) => (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel style={{ marginLeft: "10px" }}>Choose Model</InputLabel>
                                        <Select
                                            value={chooseModel}
                                            onChange={handleChange}
                                            style={{ width: "250px", margin: "10px" }}>
                                            <MenuItem value="Cohere">Cohere</MenuItem>
                                            <MenuItem value="Claude">Claude</MenuItem>
                                            <MenuItem value="Jurassic">Jurassic</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <StyledTextField label="Role" name="role"
                                            value={chooseRole}
                                            onChange={handleRoleChange} />
                                        {/* <InputLabel style={{marginLeft:"10px"}}>Choose Role</InputLabel>
                                    <Select
                                        value={chooseRole}
                                        onChange={handleRoleChange}
                                        style={{width:"250px", margin:"10px"}}>
                                        <MenuItem value="Pharmacist">Pharmacist</MenuItem>
                                        <MenuItem value="Surgeon">Surgeon</MenuItem>
                                        <MenuItem value="Paramedic">Paramedic</MenuItem>
                                        <MenuItem value="Physician">Physician</MenuItem>
                                        <MenuItem value="None">None</MenuItem>
                                    </Select> */}
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ listStyle: "none" }}>
                                    <StyledListitem>
                                        <StyledSearch
                                            variant="outlined"
                                            label="Search"
                                            size="small"
                                            onChange={handleSearch}
                                            onKeyDown={handleKeyDown}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" onClick={handleClickSearchIcon}>
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <StyledButton
                                            id="btn2"
                                            variant="contained"
                                            color="primary"
                                            helperText="Please select a txt file only"
                                            // disabled={domainBtnEnable || SdomainBtnEnable || roleBtnEnable || modelBtnEnable}
                                            disabled={domainBtnEnable || modelBtnEnable}
                                            style={{ margin: "1px 5px 5px 5px" }}
                                            onClick={handleClickSearchIcon}>
                                            Search
                                        </StyledButton>
                                    </StyledListitem>
                                </Grid>
                                {resAnswer && <Grid item style={{ marginTop: "20px" }}>
                                    <h4>Augmented Results</h4>
                                    <div style={{ borderRadius: "15px", border: "1px solid lightgray", textAlign: 'left', padding: '5px', marginTop: "5px" }}>
                                        <p style={{ margin: "5px 0px" }}><strong>Result Summary: </strong> {resAnswer}</p>
                                    </div>
                                    <div style={{ borderRadius: "15px", border: "1px solid lightgray", textAlign: 'left', padding: '5px', marginTop: "5px" }}>
                                        <p style={{ margin: "5px 0px" }}><strong>Top Result 1: </strong> {resAnswer1}</p>
                                        <p style={{ margin: "5px 0px" }}><strong>Citations: </strong><a href={resCitation1} target="_blank" rel="noreferrer">{resCitation1}</a>{" , "}<strong>Page:</strong> {resPage1}</p>
                                    </div>
                                    <div style={{ borderRadius: "15px", border: "1px solid lightgray", textAlign: 'left', padding: '5px', marginTop: "5px" }}>
                                        <p style={{ margin: "5px 0px" }}><strong>Top Result 2: </strong> {resAnswer2}</p>
                                        <p style={{ margin: "5px 0px" }}><strong>Citations: </strong><a href={resCitation2} target="_blank" rel="noreferrer">{resCitation2}</a>{" , "}<strong>Page:</strong> {resPage2}</p>
                                    </div>
                                    <div style={{ borderRadius: "15px", border: "1px solid lightgray", textAlign: 'left', padding: '5px', marginTop: "5px" }}>
                                        <p style={{ margin: "5px 0px" }}><strong>Comparision Result: </strong> {diffAnswer}</p>
                                    </div>
                                    <h4>Foundation Model Result</h4> 
                                    <div style={{ borderRadius: "15px", border: "1px solid lightgray", textAlign: 'left', padding: '5px', marginTop: "5px", backgroundColor: "#E4E5E8" }}>
                                        <p style={{ margin: "5px 0px" }}>{llmAnswer}</p>
                                    </div>
                                </Grid>}
                            </div>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Paragraph>
                            This chatbot facilitates access to information within medical documents through a user-friendly interface. Users upload healthcare related PDFs and ask open-ended questions in natural language. The chatbot leverages NLP and Information Retrieval techniques, the chatbot identifies relevant sections within documents. It harnesses a robust language model to generate answers, complete with citations, facilitating knowledge extraction from the related documents.
                        </Paragraph>
                        <p>

                        </p>
                        <center>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpen}
                                size="large">
                                <FontAwesomeIcon
                                    icon={faCommentDots}
                                    size="2x"
                                />   &nbsp;&nbsp;
                                Open ChatBot
                            </StyledButton></center>
                        <Grid
                            container
                            xs={12}
                            spacing={4}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12} sm={12} md={9}></Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                {/* Chatbot code */}
                                {chatBotTab && (<>
                                    <ChatBots>
                                        <ChatHeader>

                                            <Avatar alt="Remy Sharp" src={ChatBot} />
                                            <b style={{ marginLeft: '8px' }}>Knowledge Search Bot</b>

                                            <b style={{ marginLeft: '85px' , cursor: 'pointer'}}>
                                                <FontAwesomeIcon
                                                    icon={faRetweet}
                                                    size="2xl"
                                                    color="white"
                                                    onClick={handleReset}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    style={{ marginLeft: '15px' }}
                                                    size="2xl"
                                                    color="white"
                                                    onClick={handleCloseChat}
                                                />
                                            </b>
                                        </ChatHeader>

                                        <ChatBotContainer ref={refToScroll}>

                                            {/*-------------- welcome message --------------------*/}

                                            <p id={"answer"} style={{ marginLeft: "23px" }}>Hello, {getUserData()?.token?.name}. How can I help you today?</p>

                                            <Chats>

                                                <Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                <p id={"answer"}>Please Select your Domain</p>

                                            </Chats>
                                            <p >
                                                <Grid
                                                    container
                                                    xs={12}
                                                    spacing={4}
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12} sm={12} md={8}>
                                                        <StyledButton
                                                            variant="outlined"
                                                            color="primary"
                                                            id="Business/Corporate"
                                                            onClick={handleDomain}
                                                            style={{ height: "40px" }}
                                                            disabled={domainDisable}
                                                            size="small">
                                                            Business/Corporate
                                                        </StyledButton>
                                                        <StyledButton
                                                            variant="outlined"
                                                            color="primary"
                                                            id="Technology"
                                                            onClick={handleDomain}
                                                            style={{ height: "40px" }}
                                                            disabled={domainDisable}
                                                            size="small">
                                                            Technology
                                                        </StyledButton>
                                                        <StyledButton
                                                            variant="outlined"
                                                            color="primary"
                                                            id="Lifescience/Healthcare"
                                                            onClick={handleDomain}
                                                            disabled={domainDisable}
                                                            style={{ height: "40px" }}
                                                            size="small">
                                                            Lifescience/Healthcare
                                                        </StyledButton>
                                                        <StyledButton
                                                            variant="outlined"
                                                            color="primary"
                                                            id="Finance/Banking"
                                                            onClick={handleDomain}
                                                            disabled={domainDisable}
                                                            style={{ height: "40px" }}
                                                            size="small">
                                                            Finance/Banking
                                                        </StyledButton>
                                                        <StyledButton
                                                            variant="outlined"
                                                            color="primary"
                                                            id="Insurance"
                                                            onClick={handleDomain}
                                                            disabled={domainDisable}
                                                            style={{ height: "40px" }}
                                                            size="small">
                                                            Insurance
                                                        </StyledButton>
                                                        <StyledButton
                                                            variant="outlined"
                                                            color="primary"
                                                            id="Products"
                                                            onClick={handleDomain}
                                                            disabled={domainDisable}
                                                            style={{ height: "40px" }}
                                                            size="small">
                                                            Products
                                                        </StyledButton>
                                                    </Grid>
                                                </Grid>
                                            </p>

                                            {/*-------------- After selecting Domain --------------------*/}

                                            {domainvisible && (

                                                <p id={"question"}>{domainValue}</p>

                                            )}

                                            {domain && (
                                                <>
                                                    <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                        <p id={"answer"}>Please select your role under <b>{domainValue}</b> domain</p>
                                                    </Chats>
                                                    <p >
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            spacing={4}
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <Grid item xs={12} sm={12} md={8}>

                                                                {list?.map((value) => (
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id={value}
                                                                        onClick={handleRole}
                                                                        disabled={roleDisable}
                                                                        style={{ height: "40px" }}
                                                                        size="small">
                                                                        {value}
                                                                    </StyledButton>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    </p>
                                                </>
                                            )}

                                            {/*-------------- After Selecting Role --------------------*/}

                                            {roleVisible && (<>
                                                <p id={"question"}>{userRole}</p>
                                            </>)}

                                            {roleStatus && (<>
                                                <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                    <p id={"answer"}>Please enter your <b>{domainValue}</b> domain query</p>
                                                </Chats>
                                            </>)}

                                            {chatbotQueryValue && (
                                                <p id={"question"} style={{ textAlign: 'justify' }}>{chatbotQueryValue}</p>
                                            )}

                                            {/*----------------- First Query First Response---------------------- */}

                                            {response1View && (<>

                                                {response1 ? (<>
                                                    <p id={"answer"} style={{ marginLeft: "23px", textAlign: 'justify' }}>
                                                        <ul> {response1.split('\n').map((valuem, index) => (
                                                            <>
                                                                <li style={{ marginLeft: "-20px" }}>{valuem} </li><br />
                                                            </>
                                                        ))}
                                                        </ul>
                                                    </p>
                                                    <ChatIcon style={{ fontSize: "14px", marginLeft: "23px", maxWidth: "90%" }}>
                                                        Is this response relavent to your query? <b style={{ marginLeft: '10px' }}>
                                                            <FontAwesomeIcon
                                                                icon={faThumbsUp}
                                                                size="l"
                                                                color="#1E22AA"
                                                                onClick={handleLike}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faThumbsDown}
                                                                style={{ marginLeft: '10px' }}
                                                                size="l"
                                                                color="#1E22AA"
                                                                onClick={handleDislike1}
                                                            />
                                                        </b></ChatIcon>
                                                </>
                                                ) : (
                                                    <>
                                                        <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                            <p id={"answer"}> There is no relevant information for your query.</p>
                                                        </Chats>
                                                        <p >
                                                            <Grid
                                                                container
                                                                xs={12}
                                                                spacing={4}
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Grid item xs={12} sm={12} md={10}>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleSameDomain}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Continue with same Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleReset}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Try New Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T2"
                                                                        onClick={handleCloseChat}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Close ChatBot
                                                                    </StyledButton>
                                                                </Grid>
                                                            </Grid>
                                                        </p>
                                                    </>
                                                )}

                                            </>)}

                                            {dislike1 && (<>

                                                <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                    <p id={"answer"}>Do you want second response?</p>
                                                </Chats>

                                                <p >
                                                    <Grid
                                                        container
                                                        xs={12}
                                                        spacing={4}
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Grid item xs={12} sm={12} md={10}>
                                                            <StyledButton
                                                                variant="outlined"
                                                                color="primary"
                                                                id="T3"
                                                                onClick={handleSecondResponse1}
                                                                style={{ height: "40px" }}
                                                                size="small">
                                                                Yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                variant="outlined"
                                                                color="primary"
                                                                id="T3"
                                                                onClick={handleLike}
                                                                style={{ height: "40px" }}
                                                                size="small">
                                                                No
                                                            </StyledButton>
                                                        </Grid>
                                                    </Grid>
                                                </p>

                                            </>)}

                                            {/* --------------- First Query Second Response --------------------*/}

                                            {response2View && (<>

                                                {response2 ? (<>
                                                    <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                        <p id={"answer"}>Here is the best Second Response for you.</p>
                                                    </Chats>
                                                    <p id={"answer"} style={{ marginLeft: "23px", textAlign: 'justify' }}>
                                                        <ul> {response2.split('\n').map((valuem, index) => (
                                                            <>
                                                                <li style={{ marginLeft: "-20px" }}>{valuem} </li><br />
                                                            </>
                                                        ))}
                                                        </ul>
                                                    </p>
                                                    <p >
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            spacing={4}
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <Grid item xs={12} sm={12} md={10}>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handle2Query}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Continue with same Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleReset}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Try New Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T2"
                                                                    onClick={handleCloseChat}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Close ChatBot
                                                                </StyledButton>
                                                            </Grid>
                                                        </Grid>
                                                    </p>

                                                    {/* <ChatIcon style={{ fontSize: "14px", marginLeft: "23px", maxWidth: "90%" }} >
                                                        Is this response relavent to your query?
                                                        <b style={{ marginLeft: '10px' }}>
                                                            <FontAwesomeIcon
                                                                icon={faThumbsUp}
                                                                size="l"
                                                                color="#1E22AA"
                                                                onClick={handleLike}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faThumbsDown}
                                                                style={{ marginLeft: '10px' }}
                                                                size="l"
                                                                color="#1E22AA"
                                                                onClick={handleDislike}
                                                            />
                                                        </b>
                                                    </ChatIcon> */}
                                                </>
                                                ) : (
                                                    <>
                                                        <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                            <p id={"answer"}> There is no more relevant information for your query.</p>
                                                        </Chats>
                                                        <p >
                                                            <Grid
                                                                container
                                                                xs={12}
                                                                spacing={4}
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Grid item xs={12} sm={12} md={10}>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleSameDomain}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Continue with same Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleReset}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Try New Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T2"
                                                                        onClick={handleCloseChat}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Close ChatBot
                                                                    </StyledButton>
                                                                </Grid>
                                                            </Grid>
                                                        </p>
                                                    </>
                                                )}

                                            </>)}

                                            {/* ----------------- no more relavant info for second dislike-------------------*/}

                                            {/* {disLike2Query1 && (
                                                <>
                                                    <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                        <p id={"answer"}> There is no more relevant information for your query.</p>
                                                    </Chats>
                                                    <p >
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            spacing={4}
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <Grid item xs={12} sm={12} md={10}>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleSameDomain}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Continue with same Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleReset}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Try New Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T2"
                                                                    onClick={handleCloseChat}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Close ChatBot
                                                                </StyledButton>
                                                            </Grid>
                                                        </Grid>
                                                    </p>
                                                </>)} */}

                                            {/* --------------- First Query final options ------------ */}

                                            {domain2 && (
                                                <>
                                                    <Chats>
                                                        <Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                        <p id={"answer"}>Thanks for your Feedback.</p>
                                                    </Chats>
                                                    <p >
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            spacing={4}
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <Grid item xs={12} sm={12} md={10}>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handle2Query}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Continue with same Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleReset}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Try New Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T2"
                                                                    onClick={handleCloseChat}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Close ChatBot
                                                                </StyledButton>
                                                            </Grid>
                                                        </Grid>
                                                    </p>
                                                </>
                                            )}


                                            {/* ------------------- Second Query Starts ------------------*/}

                                            {view2Query && (
                                                <>
                                                    <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                        <p id={"answer"}>Please enter your <b>{domainValue}</b> domain query</p>
                                                    </Chats>
                                                    {chatbotQueryValue2 && (
                                                        <p id={"question"}>{chatbotQueryValue2}</p>
                                                    )}
                                                </>
                                            )}

                                            {/* --------------- Second Query FIrst Response ------------------*/}

                                            {query2Response1View && (<>
                                                {query2Response1 ? (
                                                    <>
                                                        <p id={"answer"} style={{ marginLeft: "23px", textAlign: 'justify' }}>
                                                            <ul> {query2Response1.split('\n').map((valuem, index) => (
                                                                <>
                                                                    <li style={{ marginLeft: "-20px" }}>{valuem} </li><br />
                                                                </>
                                                            ))}
                                                            </ul>
                                                        </p>
                                                        <ChatIcon style={{ fontSize: "14px", marginLeft: "23px", maxWidth: "90%" }}>
                                                            Is this response relavent to your query? <b style={{ marginLeft: '10px' }}>
                                                                <FontAwesomeIcon
                                                                    icon={faThumbsUp}
                                                                    size="l"
                                                                    color="#1E22AA"
                                                                    onClick={handleLike2}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faThumbsDown}
                                                                    style={{ marginLeft: '10px' }}
                                                                    size="l"
                                                                    color="#1E22AA"
                                                                    onClick={handleDislike2}
                                                                />
                                                            </b></ChatIcon>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                            <p id={"answer"}> There is no relevant information for your query.</p>
                                                        </Chats>
                                                        <p >
                                                            <Grid
                                                                container
                                                                xs={12}
                                                                spacing={4}
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Grid item xs={12} sm={12} md={10}>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleSameDomain}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Continue with same Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleReset}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Try New Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T2"
                                                                        onClick={handleCloseChat}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Close ChatBot
                                                                    </StyledButton>
                                                                </Grid>
                                                            </Grid>
                                                        </p>
                                                    </>
                                                )}
                                            </>)}

                                            {/* ---------- Second Query FIrst Dislike ------------------*/}

                                            {dislike2 && (<>

                                                <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                    <p id={"answer"}>Do you want a second response?</p>
                                                </Chats>

                                                <p >
                                                    <Grid
                                                        container
                                                        xs={12}
                                                        spacing={4}
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Grid item xs={12} sm={12} md={10}>
                                                            <StyledButton
                                                                variant="outlined"
                                                                color="primary"
                                                                id="T3"
                                                                onClick={handleSecondResponse2}
                                                                style={{ height: "40px" }}
                                                                size="small">
                                                                Yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                variant="outlined"
                                                                color="primary"
                                                                id="T3"
                                                                onClick={handleLike}
                                                                style={{ height: "40px" }}
                                                                size="small">
                                                                No
                                                            </StyledButton>
                                                        </Grid>
                                                    </Grid>
                                                </p>

                                            </>)}

                                            {/* ---------- Second Query Second Response ------------------*/}

                                            {query2Response2ViewFirst && (<>

                                                <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                    <p id={"answer"}>Here is the best Second Response for you.</p>
                                                </Chats>
                                            </>)}

                                            {query2Response2View && (<>

                                                {query2Response2 ? (
                                                    <>
                                                        <p id={"answer"} style={{ marginLeft: "23px", textAlign: 'justify' }}>
                                                            <ul> {query2Response2.split('\n').map((valuem, index) => (
                                                                <>
                                                                    <li style={{ marginLeft: "-20px" }}>{valuem} </li><br />
                                                                </>
                                                            ))}
                                                            </ul>
                                                        </p>

                                                        <p >
                                                            <Grid
                                                                container
                                                                xs={12}
                                                                spacing={4}
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Grid item xs={12} sm={12} md={10}>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleSameDomain}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Continue with same Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleReset}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Try New Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T2"
                                                                        onClick={handleCloseChat}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Close ChatBot
                                                                    </StyledButton>
                                                                </Grid>
                                                            </Grid>
                                                        </p>
                                                        {/* <ChatIcon style={{ fontSize: "14px", marginLeft: "23px", maxWidth: "90%" }}>
                                                            Is this response relavent to your query? <b style={{ marginLeft: '10px' }}>
                                                                <FontAwesomeIcon
                                                                    icon={faThumbsUp}
                                                                    size="l"
                                                                    color="#1E22AA"
                                                                    onClick={handleLike2}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faThumbsDown}
                                                                    style={{ marginLeft: '10px' }}
                                                                    size="l"
                                                                    color="#1E22AA"
                                                                    onClick={handleDislikeLast}
                                                                />
                                                            </b>
                                                        </ChatIcon> */}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                            <p id={"answer"}> There is no relevant information for your query.</p>
                                                        </Chats>
                                                        <p >
                                                            <Grid
                                                                container
                                                                xs={12}
                                                                spacing={4}
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Grid item xs={12} sm={12} md={10}>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleSameDomain}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Continue with same Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T3"
                                                                        onClick={handleReset}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Try New Domain
                                                                    </StyledButton>
                                                                    <StyledButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        id="T2"
                                                                        onClick={handleCloseChat}
                                                                        style={{ height: "50px" }}
                                                                        size="small">
                                                                        Close ChatBot
                                                                    </StyledButton>
                                                                </Grid>
                                                            </Grid>
                                                        </p>
                                                    </>
                                                )}

                                            </>)}

                                            {/* {disLike2Query2 && (
                                                <>
                                                    <Chats><Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                        <p id={"answer"}> There is no more relevant information for your query.</p>
                                                    </Chats>
                                                    <p >
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            spacing={4}
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <Grid item xs={12} sm={12} md={10}>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleSameDomain}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Continue with same Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleReset}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Try New Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T2"
                                                                    onClick={handleCloseChat}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Close ChatBot
                                                                </StyledButton>
                                                            </Grid>
                                                        </Grid>
                                                    </p>
                                                </>)} */}

                                            {domain3 && (
                                                <>
                                                    <Chats>
                                                        <Avatar alt="Remy Sharp" src={ChatBot} style={{ height: "20px", width: "20px", marginTop: "0px" }} />
                                                        <p id={"answer"}>Thanks for your Feedback.</p>
                                                    </Chats>
                                                    <p >
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            spacing={4}
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <Grid item xs={12} sm={12} md={10}>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleSameDomain}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Continue with same Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T3"
                                                                    onClick={handleReset}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Try New Domain
                                                                </StyledButton>
                                                                <StyledButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    id="T2"
                                                                    onClick={handleCloseChat}
                                                                    style={{ height: "50px" }}
                                                                    size="small">
                                                                    Close ChatBot
                                                                </StyledButton>
                                                            </Grid>
                                                        </Grid>
                                                    </p>
                                                </>
                                            )}


                                            {/* {
                                                chatbotList.map((chatData) => (
                                                    <>
                                                        <p id={chatData.type}>{chatData.data}</p>
                                                        <p id={"answer"}>Did you like the Response? </p>
                                                    </>
                                                ))
                                            } */}
                                        </ChatBotContainer>
                                        <InputContainer>
                                            {loader && (<>
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgress />
                                                </Box>
                                            </>)}
                                            <TextField
                                                fullWidth
                                                placeholder="Type here... "
                                                variant="outlined"
                                                disabled={disable}
                                                value={chatbotQuery}
                                                onChange={handleChatbotQuery}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <StyledButton
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={enterQuery}
                                                                endIcon={<SendIcon />}
                                                                btnColor={"#006080"}
                                                            >
                                                                Send
                                                            </StyledButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </InputContainer>
                                    </ChatBots>
                                </>)}
                                {/* Chatbot code end */}
                            </Grid>
                        </Grid>
                    </TabPanel>


                </DemoContainer>
            </Collapse>

            {showSpinner && <Spinner text="Loading..." />}

        </SolutionContainer >
    );
};

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tablepanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <PanelContainer>{children}</PanelContainer>}
        </div>
    );
}

export default memo(ColbertAnalysis);



const ChatBots = styled(Paper)`
  &.MuiPaper-root {
    height: 500px;
    width: 400px;
    overflow: hidden;
    margin-top: 10%;
    border-radius: 10px;
    box-shadow: -1px -1px 17px -3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px -1px 17px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px -1px 17px -3px rgba(0, 0, 0, 0.75);
    position: fixed; /* Make it fixed position */
    top: -5%; /* Adjust the top position as needed */
    right: 5%; /* Adjust the right position as needed */
  }
`;
const ChatBotContainer = styled.section`
 
  display: flex;
  flex-direction: column;
  height: 370px;
  overflow-y: auto;
  padding-left: 3px;
  #answer {
    padding: 10px;
    margin-right: 5px;
    color: black;
    background-color: #f2f2f2;
    text-align: left;
    border-radius: 0 10px 10px 0;
    max-width: 80%;
  }
  #option {
    padding: 10px;
    color: black;
    background-color:  #f2f2f2;
    text-align: left;
    border-radius: 0 10px 10px 0;
    margin-right: auto;
    max-width: 90%;
  }
  #question {
    padding: 10px;
    color: #ffff;
    background-color: #0086b3;
    text-align: right;
    border-radius: 10px 0 0 10px;
    margin-left: auto;
    max-width: 90%;
  }
  p {
    padding: 5px;
    margin: 5px 0;
    max-width: 100%;
    position: relative;
  }
  span {
    height: 50px;
  }
`;
const InputContainer = styled.div`
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #ffff;
  margin-top: 5%;
`;

const ChatIcon = styled.p`
width: 100%;
display: flex;
align-items: center; /* Center items vertically */
justify-content: flex-start; /* Align items to the start horizontally */
`;

const Chats = styled.p`
position: fixed;
bottom: 0;
left: 0;
display: flex;
alignItems: flex-end;
`;

const ChatHeader = styled.div`
padding: 10px;
width: 100%;
display: flex;
align-items: center; /* Center items vertically */
justify-content: flex-start; /* Align items to the start horizontally */
border: 1px solid gray;
background: #006080;
color: #fff; /* Fixed color code */
`;




export const CustomTextField = styled(TextField)`
&.MuiTextField-root{
  margin-top: ${props => !props?.noMargin && '10px 0'};
}
.MuiOutlinedInput-input{
  // border: 1px solid lightblue;
  font-size: 14px;
}
.MuiInputBase-root{
  
  padding: ${props => props.rows && '0px !important'};
}
  width: 100%;
  border: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  text-align: left;
  .MuiInputBase-input {
    padding:5px;
    background: #fff9;
    border-radius: 5px;
  }

.MuiInputLabel-root {
    top: ${props => props.topPad ? props.topPad : '-8px'};
    font-size: 0.95rem;
    line-height: initial;
    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  &.MuiInputBase-input.MuiInput-input {
    color: #fc7703;
    background: #fff9;
    border-radius: 5px;
    @media only screen and (max-width: 900px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 450px) {
      font-size: 0.7rem;
    }
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: #fc7703;
  }
  // @media only screen and (max-width: 960px) {
  //   // width: 50%;
  // }
  // @media only screen and (max-width: 550px) {
  //   width: 90%;
  // }
  input[type="number"]::-webkit-inner-spin-button {
    display: none;
 }
 input:placeholder-shown {
  text-overflow: ellipsis;
}

 /* Fallback for other browsers */
 input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
 }
`;
export const StyledListitem = styled(ListItem)`
  &.MuiListItem-root {
    color: inherit;
    float: left;
    width: auto;
    padding: 0;
    position: relative;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledSearch = styled(TextField)`
  &.MuiTextField-root{
    min-width: 90%;
    .MuiFormLabel-root, .MuiInputBase-root {
      color: ${(props) => props.chageColor && '#ffff'};
      
    };
    &.MuiOutlinedInput-notchedOutline{
      border-color: ${(props) => props.chageColor && '#ffff'}
    };
    opacity: ${(props) => (props.hide ? 0 : 1)}
  }
`;

const StyledTextField = styled(TextField)`
&.MuiTextField-root{
  width: ${(props) => (props.width ? props.width : "90%")};
  @media only screen and (max-width: 450px) {
    max-width: 80%;
    margin-top: 10px;
    .MuiInputBase-root{
       margin-top: 15px;
    }
  }
};
label + .MuiInput-formControl{
    margin-top: 10px;
}
`;

const Label = styled.div`
  font-size: 12px;
  padding: 4px;
`;

const FlexContainer = styled.div`
  display: flex;
  position: relative;
`;

const GridBox = styled(Grid)`
padding: 10px;
border: 1px solid grey;
max-height: 400px;
overflow: auto;
`;

const DivBox = styled.div`
padding: 10px;
border: 1px solid grey;
max-height: 400px;
overflow: auto;
`;

const GridHidden = styled(Grid)`
display:none;
`;

const StyledEditor = styled(JoditEditor)`
  .jodit-workplace {
    max-height: 400px;
  }
`;


const CollapseDiv = styled.div`
margin-top: -5px;
height: 420px;
overflow: auto;
`;

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

export const StyledTabs = styled(Tabs)`
.MuiTab-root{
    text-transform: none;
}

.Mui-selected{
    background-color: #ff0055;
    color: white;
    font-weight: bold;
    text-transform: none;
  }

@media only screen and (max-width: 950px) {
  .MuiTabs-flexContainer{
    flex-wrap: wrap;
    .PrivateTabIndicator-colorPrimary-7{
      display: none;
    }
    .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
  }
}
@media only screen and (max-width: 650px) {
    background-color: #ffff;
    font-size: 12px;
    .MuiTabs-flexContainer{
      flex-direction: column;
      .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
    }
`;

const HeaderContainer = styled(Paragraph)`
  display: flex;
  align-items: center;
  justify-content: center;
  strong {
    color: #2941b7;
  }
`;

const StyledHeading = styled.div`
  width: 35%;
  margin: 0px 1rem;
  height: 3px;
  background: #fc7703;
  color: #2941b7;
  @media only screen and (max-width: 450px) {
    width: 20%;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;

export const HeaderCol = styled(TableCell)`
background: ${props => props.bgColor ? props.bgColor : 'rgb(60, 64, 175)'};
border-right: ${props => props.bgColor && '1px solid #ffff'};
color: white;
`;