import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core";

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";

// import local components
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import { StyledFormControl } from "./PredictingEligibility";

// user id
import getUserData from "../../../utils";

const ToxPrediction = () => {
  const initialData = {
    Molecular_weight: "",
    Smile: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState([{}]);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const { postQueryResult } = CommonService("z1dApps", "toxPrediction");

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  const showResult = () => {
    const NewDataSet = {
      Molecular_weight: "351.7095",
      Smile: "Cc1ccc([N+](=O)[O-])c2c1O[Hg]2",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      Molecular_weight: "223.4837",
      Smile: "O=C(CCl)c1ccc(Cl)cc1Cl",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    setSpinner(true);

    const { Molecular_weight, Smile } = sampleData;

    const MolecularWeight = `${Molecular_weight}`;
    const Smile2 = `${Smile}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setSpinner(false);
      setNotification({ open: "error", message: "Fields are empty" });
    } 
    else {
      postQueryResult("getResult", {
        Context_param: {
          Application_name: "toxicity-prediction",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          Molecular_weight: MolecularWeight,
          Smile: Smile2,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          setResultData(res.Result.data);
          console.log(resultData);
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Toxicity prediction is very important to public health. Among its
            many applications,toxicity prediction is essential to reduce the
            cost and labor of a drug’s preclinical and clinical trials,because a
            lot of drug evaluations (cellular, animal, and clinical) can be
            spared due to the predicted toxicity. In the era of Big Data and
            artificial intelligence, toxicity prediction can benefit from
            machine learning,which has been widely used in many fields such as
            natural language processing, speech recognition, image recognition,
            computational chemistry, and bioinformatics, with excellent
            performance.
          </p>
          <p>
            Exposure to chemicals in our day-to-day life is inevitable, with the
            majority of sources being food additives, cosmetic products,
            medicines, and cleaning products – and some of them might be
            harmful. However, testing the toxicity of all existing compounds by
            performing biological experiments is neither financially nor
            logistically feasible. Toxicity is also a central issue in the field
            of drug discovery, with more than 30% of drug candidates failing in
            clinical trials due to undetected toxic effects. The Tox21 program
            established by the NIH, EPA, and FDA in 2008 focuses on developing
            better toxicity methods, as current methods are unlikely to scale
            with the increased demand for toxicity testing. State of the art
            toxicity predictions built upon specifically designed chemical
            descriptors have been developed over the decades for 12 stress
            response and nuclear receptor signaling pathways toxicity assays.
            This application makes use of machine learning techniques to predict
            the toxicity of any given chemical compound by analysisng compound
            activity outcome (active or inactive) in one or more of the 12
            pathway assays based on the chemical structure information for the
            following assays.
          </p>
          <p>
            Machine learning methods that have been applied to toxicity
            prediction, including deep learning,random forests, k-nearest
            neighbors, and support vector machines.The input parameter to the
            machine learning algorithm, especially its shift from chemical
            structural description only to that combined with human
            transcriptome data analysis, which can greatly enhance prediction
            accuracy.
          </p>
          <p>
            <strong> Nuclear Receptor Signaling Pathways:</strong>
          </p>
          <ul>
            <li>
              <strong> Androgen Receptor (AR): </strong>Androgen Receptor is a
              type of nuclear receptor that is activated by binding any of the
              androgenic hormones, including testosterone and
              dihydrotestosterone in the cytoplasm and then translocating into
              the nucleus.
            </li>
            <li>
              <strong> Aryl hydrocarbon Receptor (AhR): </strong>
              Aryl hydrocarbon receptor signaling is an integral part of the
              induction of cytochrome P450 (CYP) isoforms by ozone. EGF
              repressed the transcriptional activity of aryl hydrocarbon
              receptor. Results provide insight into the mechanism and function
              of the AhR pathway and its impact on gastric cancer progression.
            </li>
            <li>
              <strong>Androgen Receptor LBD (AR-LBD): </strong>
              Androgen receptor (AR) ligand-binding domain (LBD) mutations occur
              in ~20% of all castration-resistant prostate cancer (CRPC)
              patients. These mutations confer ligand promiscuity, but the
              affinity for many steroid hormone pathway intermediates is
              unknown.
            </li>
            <li>
              <strong>Estrogen Receptor (ER): </strong>
              Estrogen receptor alpha ( ERα ), also known as NR3A1 (nuclear
              receptor subfamily 3, group A, member 1), is one of two main types
              of estrogen receptor, a nuclear receptor that is activated by the
              sex hormone estrogen. In humans, ERα is encoded by the gene ESR1.
            </li>
            <li>
              <strong>Estrogen Receptor LBD (ER-LBD): </strong>
              This Estrogen Receptor (ER) Alpha Ligand Binding Domain (LBD) is a
              62.80 kDa recombinant human protein (amino acids 282-595)
              expressed as a GST fusion protein in baculovirus-infected insect
              cells. It was expressed and purified in the absence of exogenous
              ligands.
            </li>
            <li>
              <strong>Aromatase: </strong>
              Aromatase (EC 1.14.14.14), also called estrogen synthetase or
              estrogen synthase, is an enzyme responsible for a key step in the
              biosynthesis of estrogens.
            </li>
            <li>
              <strong>
                Peroxisome Proliferator-Activated Receptor gamma (PPAR-gamma):{" "}
              </strong>
              Peroxisome proliferator-activated receptors (PPARs) are
              ligand-activated transcription factors of nuclear hormone receptor
              superfamily comprising of the three subtypes: PPARα, PPARγ, and
              PPARβ/δ. Activation of PPAR-α reduces triglyceride level and is
              involved in regulation of energy homeostasis.
            </li>
          </ul>
          <p>
            <strong>Stress Response Pathways:</strong>
          </p>{" "}
          <ul>
            <li>
              <strong>Antioxidant Responsive Element (ARE): </strong>The
              antioxidant response element (ARE) is a cis-acting regulatory
              enhancer element found in the 5 ﬂanking region of many phase II
              detoxiﬁcation enzymes.
            </li>
            <li>
              <strong>ATAD5:</strong>
              ATAD5 suppresses centrosome over-duplication by regulating UAF1
              and ID1. ATAD5 restricts R-loop formation through PCNA unloading
              and RNA helicase maintenance at the replication fork. ATAD5, a
              PCNA unloader, plays multiple functions at stalled forks including
              promoting its restart.
            </li>
            <li>
              <strong>Heat Shock factor response Element (HSE): </strong>
              Heat shock factors (HSF) are transcriptional activators of heat
              shock genes. These activators bind specifically to Heat Shock
              sequence Elements (HSE) throughout the genome whose
              consensus-sequence is a tandem array of three oppositely oriented
              "AGAAN" motifs or a degenerate version thereof. Under non-stressed
              conditions, Drosophila HSF is a nuclear-localized unbound monomer,
              whereas heat shock activation results in trimerization and binding
              to the HSE. The Heat Shock sequence Element is highly conserved
              from yeast to humans.
            </li>
            <li>
              <strong>Mitochondrial Membrane Potential (MMP): </strong>
              The mitochondrial membrane potential (ΔΨm) generated by proton
              pumps (Complexes I, III and IV) is an essential component in the
              process of energy storage during oxidative phosphorylation.
              Together with the proton gradient (ΔpH), ΔΨm forms the
              transmembrane potential of hydrogen ions which is harnessed to
              make ATP.
            </li>
            <li>
              <strong>P53: </strong>
              The p53 pathway responds to stresses that can disrupt the fidelity
              of DNA replication and cell division. A stress signal is
              transmitted to the p53 protein by post-translational
              modifications. This results in the activation of the p53 protein
              as a transcription factor that initiates a program of cell cycle
              arrest, cellular senescence or apoptosis.
            </li>
          </ul>
          <p>
            {" "}
            The input to this application is a chemical compound structure in
            SMILES (Simplified Molecular Input Line Entry System) format, a
            machine-readable chemical notation, along with its molecular weight.
            The model then predicts its response to each of the above-mentioned
            assays, using which the toxicity of the compound can be inferred.
          </p>
          <p>
            The Tox21 invited participants to build computational models to
            predict the toxicity of compounds for 12 toxic eﬀects. These toxic
            eﬀects comprised stress response eﬀects (SR), such as the heat shock
            response eﬀect (SR-HSE) and nuclear receptor eﬀects (NR), such as
            activation of the estrogen receptor(NR-ER). Both SR and NR eﬀects
            are highly relevant to human health, since activation of nuclear
            receptorscan disrupt endocrine system function, and activation of
            stress response pathwayscan lead to liver injury or cancer.
          </p>
          <Paragraph>
            <p>
              <strong>Business Outcome:</strong>
              <p>
                The implementation of toxicogenomics in toxicology and eventual
                drug development depends on several factors. The Application of
                functional genomics methodologies to toxicology should optimize
                the prediction of drug responses. Such a global analysis will
                lead to a better understanding of biological mechanisms that
                cause toxic responses. The global approaches will provide a
                better insight into human toxicology than current developments
                and have the potential to identify a toxicant earlier and faster
                in drug development.
              </p>
            </p>{" "}
            <p>
              Further down the development pipeline, toxicogenomics could also
              help to make clinical trials safer and more efficient by
              identifying either poor responders or those who are at particular
              risk of adverse side effects. One of the main functions of
              clinical research is to assess possible deleterious properties and
              side effects in humans of the drug under investigation. A central
              role in how humans react to a drug is played by the
              drug-metabolizing cytochrome P450 (CYP) enzymes in the liver.
              Patients with non-functional CYP alleles are at particular risk
              for adverse side effects, whereas those with additional copies
              respond poorly or not at all. The variability of CYP genes thus
              underlies the variable intensity of drug effects, adverse side
              effects, toxicity and duration of the toxic response for identical
              drug doses.{" "}
            </p>
            <p>
              <strong>How To Use ChEMBL - </strong>
            </p>
            <p>
              ChEMBL is a manually curated database of bioactive molecules with
              drug-like properties. Using The ChEMBL link you can go through the
              Compound ID which also consist molecular weight and structure of
              compound and SMILES data of each compound.{" "}
            </p>
          </Paragraph>
          <p>
            {" "}
            <p>
              {" "}
              <strong> How To Use ChEMBL:</strong>{" "}
            </p>
            ChEMBL is a manually curated database of bioactive molecules with
            drug-like properties. Using The ChEMBL link you can go through the
            Compound ID which also consist molecular weight and structure of
            compound and SMILES data of each compound.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ToxicityPrediction/Toxicity_Prediction_Notebook.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://tripod.nih.gov/tox21/challenge/data.jsp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dataset
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.ebi.ac.uk/chembl/g/#search_results/all"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sample Inputs
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid container xs={12} direction="row" justify="left">
              {" "}
              <h5>
                Please provide your compound structure in smiles format along
                with it's molecular weight
              </h5>
            </Grid>
            <br />
            <Grid container xs={12} direction="row" justify="center">
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    rows="1"
                    multiline
                    label="Molecular_weight"
                    name="Molecular_weight"
                    value={sampleData.Molecular_weight}
                    onChange={onChangeFields}
                    type="text"
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    rows="1"
                    multiline
                    label="Smile"
                    name="Smile"
                    value={sampleData.Smile}
                    onChange={onChangeFields}
                    type="text"
                  />
                  <br />
                </StyledFormControl>
              </Grid>
            </Grid>

            <br />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample Data 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample Data 2
            </StyledButton>
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </section>
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {/* {resultData && (
            <table>
              <tr>
                {Object.keys(resultData[0]).map((key) => (
                  <th>{key}</th>
                ))}
              </tr>
              {resultData.map((item) => (
                <tr>
                  {Object.values(item).map((val) => (
                    <td>{val}</td>
                  ))}
                </tr>
              ))}
            </table>
          )} */}

          <ScrollableTable component={Paper}>
            {resultData && (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {Object.keys(resultData[0]).map((key) => (
                      <HeaderCol>{key}</HeaderCol>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultData.map((item) => (
                    <TableRow>
                      {Object.values(item).map((val) => (
                        <StyledCell>{val}</StyledCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </ScrollableTable>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ToxPrediction);

const HeaderCol = styled(TableCell)`
  background-color: #00afb9;
  color: white;
  min-width: 116px;
  text-align: center;
`;

const StyledCell = styled(TableCell)`
  color: #575d90;
  cursor: pointer;
  text-align: center;
`;
const ScrollableTable = styled(TableContainer)`
  overflow-x: auto;
  width: 100%;
`;
