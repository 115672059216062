import React, { memo, useState } from "react";
import {
    Grid, Typography, Slide, Divider,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components

import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import Collapse from "../../common/Collapse";
import {
    StyledButton,
    SolutionContainer,
    LinkContainer,
    Paragraph,
} from "../../../styles/common";

// images
import ioTArchitecture from "../../../assets/images/app/ioTArchitecture.webp";
import ioTArchitecture2 from "../../../assets/images/app/ioTArchitecture-2.webp";
import HospitalCard from '../../common/HospitalCard';
import ZoomImage from "../../common/ZoomImage";


const IoTContinuous = () => {

    return (
        <SolutionContainer>
            <section>
                <div>
                    <Collapse text="Description">
                        <Grid container xs={12} direction="row">
                            <Grid item xs={12} md={12}>
                                <Paragraph>
                                    In the healthcare IoT space, continuous patient monitoring is one of the key enablers of reducing the risk of readmissions, managing chronic diseases more effectively, and improving patient outcomes. Continuous patient monitoring can be split into two major categories:
                                    <ol>
                                        <li><b>In-patient monitoring:</b> Using medical wearables and other devices in the hospital, care teams can monitor patient vital signs and medical conditions without having to send a nurse to check up on a patient multiple times a day. Care teams can understand the moment that a patient needs critical attention through notifications and prioritizes their time effectively.</li>
                                        <li><b>Remote patient monitoring:</b> By using medical wearables and patient reported outcomes (PROs) to monitor patients outside of the hospital, the risk of readmission can be lowered. Data from chronic disease patients and rehabilitation patients can be collected to ensure that patients are adhering to care plans and that alerts of patient deterioration can be surfaced to care teams before they become critical.</li>
                                    </ol>
                                </Paragraph>
                                <br />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography align="center" variant="subtitle1">
                                    Continuous Patient Monitoring – Architecture
                                </Typography>
                                <center> <ZoomImage width="100%" src={ioTArchitecture} /></center>
                                <Paragraph>This application enables you to:</Paragraph>
                                <ul>
                                    <li>Seamlessly connect different kinds of medical devices to an IoT Central instance.</li>
                                    <li>Monitor and manage the devices to ensure they remain healthy.</li>
                                    <li>Create custom rules around device data to trigger appropriate alerts.</li>
                                    <li>Configure an IoT Central application to export telemetry to an event hub.</li>
                                    <li>Use Logic Apps to send data from an event hub to a Power BI streaming dataset.</li>
                                    <li>Create a Power BI dashboard to visualize data in the streaming dataset</li>
                                </ul>
                                <Typography align="center" variant="subtitle1">
                                    Export data from IoT Central and visualize insights in Power BI
                                </Typography>
                                <center> <ZoomImage width="100%" src={ioTArchitecture2} /></center>
                            </Grid>
                        </Grid>
                        <LinkContainer>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <StyledButton
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        startIcon={<OpenInNewIcon />}
                                    >
                                        <a
                                            href="../../../assets/files/Intersystems_POC_HIMMS2020_v02.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Read More
                                        </a>
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </LinkContainer>
                    </Collapse>
                    <Collapse text="Demo">
                        <br/><br/>
                        <Grid container spacing={3} direction="row">
                                <Grid item xs={12} sm={4} md={3}>
                                    <HospitalCard
                                        name="Dasboard"
                                        link="https://app.powerbi.com/groups/me/dashboards/939fd58b-3524-4fd2-990f-19b37ddf3cfe?ctid=0d85160c-5899-44ca-acc8-db1501b993b6&pbi_source=linkShare"
                                        userName="Please Contact vLife™ Team for Credentials"
                                    />
                                </Grid>
                        </Grid>
                    </Collapse>
                </div>
            </section>
        </SolutionContainer>
    );
};

export default memo(IoTContinuous);
const SubSectiontitle = styled.div`
  border-bottom: 3px solid #3175eb;
  text-align: left;
  margin-bottom: 20px;
  padding: 20px 0 10px 0;
`;
