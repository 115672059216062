import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import osteoarthritisSeverityPrediction_sol1 from "../../../assets/images/app/KneeOsteoarthritis.webp";
import osteoarthritisSeverityPrediction_sample1 from "../../../assets/images/app/KneeOsteoarthritis-sample1.webp";
import osteoarthritisSeverityPrediction_sample2 from "../../../assets/images/app/KneeOsteoarthritis-sample2.webp";

const { getQueryResult, uploadFile } = CommonService("z1dApps", "KneeOsteoarthritis");

const OsteoarthritisSeverityPrediction = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <strong>Knee osteoarthritis</strong>
            {' '}
            (Knee OA) is one of the major causes of knee pain, physical disability, and limitation of physical activity in older adults. It is a degenerative condition where the knee's articular cartilage, i.e. the knee joint cartilage, wears away and results in inflammation and bones of the joints rubbing against each other. Symptoms include pain that increases during physical movement of the joints, swelling, stiffness in the knee, and a decrease in mobility.
          </p>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <p>
                The current methods of detection are x-ray imaging and MRI scans, following symptom analysis performed by a doctor. The severity of OA can be classified by using the
                <strong>Kellgren and Lawrence (KL) system</strong>
                . KL grading uses five grades ranging from 0 to 4 to determine OA severity. The Grade descriptions are as follows:
              </p>
              <List>
                <ListItem>
                  <strong>Grade 0:</strong>
                  {' '}
                  No osteoarthritis detected. Healthy knee image.
                </ListItem>
                <ListItem>
                  <strong>Grade 1 (Doubtful):</strong>
                  {' '}
                  Doubtful joint narrowing with possible osteophytic lipping
                </ListItem>
                <ListItem>
                  <strong>Grade 2 (Minimal):</strong>
                  {' '}
                  Definite presence of osteophytes and possible joint space narrowing
                </ListItem>
                <ListItem>
                  <strong>Grade 3 (Moderate):</strong>
                  {' '}
                  Multiple osteophytes, definite joint space narrowing, with mild sclerosis and definite deformity of the bone ends
                </ListItem>
                <ListItem>
                  <strong>Grade 4 (Severe):</strong>
                  {' '}
                  Large osteophytes, significant joint narrowing, severe sclerosis, and definite deformity of the bone ends
                </ListItem>
              </List>
              {' '}

            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <center><ImageSol src={osteoarthritisSeverityPrediction_sol1} alt="" /></center>
            </Grid>
          </Grid>
          <p>
            The application Osteoarthritis Severity Prediction is built using a specially curated dataset consisting of knee x-ray images that have been classified and grouped according to their respective KL gradings
            With the help of a deep convolutional neural network (CNN),  the knee OA severity can be automatically measured, as assessed by the Kellgren-Lawrence (KL) grading system. The input images must be x-ray images in png format. These images can be read and processed using the OpenCV Python library.
          </p>
          <p>
            <strong>Application :</strong>
            {' '}
            The application can be used for early detection and timely intervention Osteoarthritis cases, This can help in slowing down the development of knee osteoarthritis and help patients start taking countermeasures.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://data.mendeley.com/datasets/56rmx5bjcr/1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/KneeOA/KneeOsteoarthritisSeverity.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={8} sm={4} md={2}>
              <Image src={osteoarthritisSeverityPrediction_sample1} />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={8} sm={4} md={2}>
              <Image src={osteoarthritisSeverityPrediction_sample2} />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={getsampleResult}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={6} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grid container XS={12}>
            <Grid item xs={12} md={12}>
              <Grow in={result} timeout={1500}>
                <ResultDiv>
                  <Result>
                    <strong>Result : </strong>
                    <h3>{result}</h3>
                  </Result>
                </ResultDiv>
              </Grow>
            </Grid>
          </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(OsteoarthritisSeverityPrediction);

const Image = styled.img`
  width: 100%;
  height: 150px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 240px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
const ResultDiv = styled.div`
 margin-top: 5%;
`;
const ImageSol = styled.img`
  width: 80%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 350px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 300px;
  }
`;
