export default {
  cat1: "cat1",
  cat2: "cat2",
  cat3: "cat3",
  cat4: "cat4",
  cat5: "cat5",
  cat6: "cat6",
  commercialAnalytics: "commercial-analytics",
  researchAndDevelopment: "research-and-development",
  biopharma: "bio-pharma",
  providers: "providers",
  medtech: "medtech",
  payers: "payers",
  biopharmaThemes: "bio-pharma-themes",
  payerThemes: "payer-themes",
  MedTechThemes: "med-tech-themes",
  ProviderThemes: "provider-themes",
  offerings: "offerings",
  cancer: "cancer",
  Provider: "provider", // Not providers: "providers"
  genAI: "gen-ai",
  stanfordAI: "stanford-ai",
  type2Diabetes: "type2diabetes",
  genomics: "genomics",
  orthopedic: "orthopedic",
  renal: "renal",
  geneExpressionAnalysis: "gene-expression-analysis",
  oncology: "oncology",
  covid19: "covid19",
  platformaiThemes: "platform-ai",
  collaborators: "collaborators",
  syntheticDataGen: 'synthetic-data-generator',
  mareana: "mareana",
  iotReciepe: "iot-receipes",
  interactiveTools: "interactive-tools",
};
