import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'flex-start',
    height: '100%',
  },
  details: {
    display: 'flex',
  },
  cover: {
    width: 151,
  },
  icon: {
    margin: '0 0px 0px 10px',
    width: '40px',
    height: '40px',
    borderRadius: '6px',
  },
  cardTitle: {
    color: '#374957',
  },
  content: {
    color: '#content',
  },
}));

export default function MediaControlCard({
  title, desc, icon, handleClick, highlight
}) {
  const classes = useStyles();

  return (
    <StyledCard highlight={highlight}>
      <CardActionArea onClick={handleClick} className={classes.root}>
        <div className={classes.icon}>
          <img width="40px" height="40px" src={icon} alt="" />
        </div>
        <CardContent className={classes.content}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
          <Typography variant="subtitle2">
            {desc}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
display: flex;
align-items: center;
text-decoration: none;
justify-content: flex-start;
height: 100%;
background: ${(props) => props.highlight && '#fe7f2d'};
color: ${props => props.highlight && '#ffff'};
.MuiTypography-h6{
  color: ${props => props.highlight && '#ffff'};
}
`