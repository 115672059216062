import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    List,
    ListItem,
} from "../../../styles/common";

const TextSummarization = () => {

    return (
        <SolutionContainer>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                    <b>Luhns Heuristic Method</b> for text summarization is one of the earliest approaches of text summarization. Luhn proposed that the significance of each word in a document signifies how important it is. The idea is that any sentence with maximum occurances of the highest frequency words(Stopwords) and least occurances are not important to the meaning of document than others.
                    </p>
                    <p>
                    Luhn’s algorithm is an approach based on TF-IDF. It selects only the words of higher importance as per their frequency. Higher weights are assigned to the words present at the begining of the document.   
                    </p>
                    <p>
                    The Luhns method is most significant when:
                    <ol>
                        <li>Too low frequent words are not significant </li>
                        <li>Too high frequent words are also not significant (e.g. “is”, “and”) </li>
                        <li>Removing low frequent words is easy 
                            <ul>
                                <li>set a minimum frequency-threshold </li>
                            </ul>
                        </li>
                        <li>Removing common (high frequent) words:
                            <ul>
                                <li>Setting a maximum frequency threshold (statistically obtained) </li>
                                <li>Comparing to a common-word list </li>
                            </ul>
                        </li>
                        <li>Used for summarizing technical documents. </li>
                    </ol>
                    </p>
                    <p>
                        <b>Application : </b> This can be used in summarizing technical research papers, documents and clinical notes. 
                    </p>
                   
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://fastforwardlabs.github.io/luhn/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Source
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>
            <Collapse text="Demo">
                <DemoContainer>
                    <center>
                        <iframe src="https://fastforwardlabs.github.io/luhn/" width="100%" height="650" />
                    </center>

                </DemoContainer>
            </Collapse>
        </SolutionContainer>
    );
}

export default memo(TextSummarization);
