import React, { memo, useState } from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";

// images
import predictingHeartDisease_sol1 from "../../../assets/images/app/predictingHeartDisease_sol1.webp";
import predictingHeartDisease_sol2 from "../../../assets/images/app/predictingHeartDisease_sol2.webp";

const PredictingHeartDisease = () => {
  const initialData = {
    age: "",
    socialFunctioningScore: "",
    symptomsScore: "",
    dailyLivingScore: "",
    sportRecreationScore: "",
    qualityOfLifeScore: "",
    physicalFunctioningScore: "",
    rolephysicalScore: "",
    bodilyPainScore: "",
    generalHealthScore: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "mlModels",
    "retrieveHeartDiseasePrediction",
  );

  const showResult = () => {
    const NewDataSet = {
      age: "71",
      socialFunctioningScore: "2",
      symptomsScore: "0",
      dailyLivingScore: "0",
      sportRecreationScore: "0",
      qualityOfLifeScore: "0",
      physicalFunctioningScore: "3.63",
      rolephysicalScore: "7.14",
      bodilyPainScore: "2.58",
      generalHealthScore: "3.11",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      age: "67",
      socialFunctioningScore: "2.65",
      symptomsScore: "58.8",
      dailyLivingScore: "7.9",
      sportRecreationScore: "31.65",
      qualityOfLifeScore: "47.4",
      physicalFunctioningScore: "3.51",
      rolephysicalScore: "6.25",
      bodilyPainScore: "2.18",
      generalHealthScore: "3.7",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      age,
      socialFunctioningScore,
      symptomsScore,
      dailyLivingScore,
      sportRecreationScore,
      qualityOfLifeScore,
      physicalFunctioningScore,
      rolephysicalScore,
      bodilyPainScore,
      generalHealthScore,
    } = sampleData;

    const params = `${age},${socialFunctioningScore},${symptomsScore},${dailyLivingScore},${sportRecreationScore},${qualityOfLifeScore},${physicalFunctioningScore},${rolephysicalScore},${bodilyPainScore},${generalHealthScore}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          setResultData(res);
          const params = [];
          const percentages = res.percentage[0];
          for (const propertyName in percentages) {
            params.push({
              name: propertyName,
              y: percentages[propertyName],
            });
          }

          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color:
                      (Highcharts.theme
                        && Highcharts.theme.contrastTextColor)
                      || "black",
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <section>
            <p>
              Diseases pertaining to the heart have always been the most
              dangerous of them all and play a significant role in a person’s
              health status but predicting the onset of any diseases related to
              the heart is a challenge. This machine-learning algorithm is
              developed solely with the purpose of making it easy for a doctor
              to analyze the factors affecting heart diseases and identifying if
              a patient is at risk.
              <br />
              Although various researches suggest that many forms of heart
              diseases can be prevented or treated with healthy lifestyle
              choices, clinical measures have a strong story to tell as well.
              This application identifies patients at risk by considering the
              risk factors and clinical measures of the given subject.
            </p>
            <h4>Analytics Functions Used </h4>
            <ul>
              <li>Normalization techniques (Clipping, Log Scaling) </li>
              <li>Backward Propagation to minimize gradient descent </li>
              <li>
                Machine Learning Techniques (Logistic Regression, KNN, Naïve
                Bayes, Decision Trees)
              </li>
              <li>Confusion Matrix to choose the best ML technique </li>
              <li>K-fold Validation to validate Results </li>
            </ul>
            <h4>
              Factors Affecting the Result (input variables to the Algorithm)
            </h4>
            <ul type="1">
              <li>
                <b>Age</b>
                {' '}
                of the Patient
                {" "}
              </li>
              <li>
                <b>VR-12 Scores – </b>
                A The Veterans RAND 12 Item Health Survey
                (VR-12) is a patient-reported global health measure that is used
                to assess a patient’s overall perspective of their health.
              </li>
              <li>
                <b>HOOS Scores -</b>
                {' '}
                Hip Disability and Osteoarthritis Outcome
                score is used to assess patient-relevant outcomes in five
                separate subscales (pain, symptoms, activity of daily living,
                sport and recreation function and hip related quality of life.
              </li>
              <li>
                <b>Quality of Life Score-</b>
                {' '}
                This score measures 5 different
                domains of the quality of life – material and physical
                well-being, relationships with other people, social, community
                and civic activities, personal development and fulfilment, and
                recreation. It typically ranges from 15 to 105.
              </li>
            </ul>
            <p>
              The data on which the model is trained is a variant of the
              simulated US population. The large size of data availability makes
              the trained model very robust and bias-free.
            </p>
            <p>
              Comparison charts of heart diseases across demographic parameters
              like age and sex, along with clinical factors like blood sugar
              levels have helped in understanding the behaviour and pattern of
              the data before using implementing machine learning techniques
              over it.
            </p>
            <Grid container xs={12}>
              <Grid item xs={12} md={6}>
                <center>
                  <img width="100%" src={predictingHeartDisease_sol1} alt="" />
                </center>
              </Grid>
              <Grid item xs={12} md={6}>
                <center>
                  <img width="100%" src={predictingHeartDisease_sol2} alt="" />
                </center>
              </Grid>
            </Grid>
          </section>
          <br />
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/HeartDisease/Notebook/Predict_Heart_Disease.ipynb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/code/ronitf/predicting-heart-disease/notebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <br />
          <section>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Age"
                          name="age"
                          value={sampleData.age}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Social functioning (SF) Score - Oblique Method"
                          name="socialFunctioningScore"
                          value={sampleData.socialFunctioningScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Symptoms score [HOOS]"
                          name="symptomsScore"
                          value={sampleData.symptomsScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Activities of Daily Living Score [HOOS]"
                          name="hemaglobin"
                          value={sampleData.dailyLivingScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Sport-Recreation Score [HOOS]"
                          name="sportRecreationScore"
                          value={sampleData.sportRecreationScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Quality of life score"
                          name="qualityOfLifeScore"
                          value={sampleData.qualityOfLifeScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Physical Functioning (PF) Score - Oblique Method"
                          name="physicalFunctioningScore"
                          value={sampleData.physicalFunctioningScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Role physical (RP) score - oblique method"
                          name="rolephysicalScore"
                          value={sampleData.rolephysicalScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Bodily Pain (BP) Score - Oblique Method"
                          name="bodilyPainScore"
                          value={sampleData.bodilyPainScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 General health (GH) score - oblique method"
                          name="generalHealthScore"
                          value={sampleData.generalHealthScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <div align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                    </div>
                  </BorderGray>
                </section>
                <br />
                <section>
                  <div align="right">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </div>
                </section>
              </Grid>
              <Grid item xs={12} md={6}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`Heart Diseases:  ${resultData.isHD}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer
                          id="prediction-chart"
                          display
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictingHeartDisease);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
// const Image = styled.img`
//   margin-top: 300px;
// `
