import React, { memo, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import {
  RadioGroup, FormControlLabel, Radio, Grid,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { color } from 'highcharts';
import { List, ListItem, StyledButton } from '../../../../../styles/common';
import JsonDataTable from '../../../../common/JsonDataTable';
import { TabPanel, a11yProps } from './ResultPanel';
import Plot from './Plot';

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    minWidth: 'fit-content',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function DataTab({ getDataFrame, getPandasReport, commonAPI }) {
  const classes = useStyles();
  const { resultData = {} } = useSelector((state) => state.vnet) || {};
  const { data = {} } = resultData || {};

  const [value, setValue] = useState(0);
  const [correlationTab, setCorrelationTab] = useState(0);
  const [dataframe, setdataframe] = useState(data?.selectedDataset || "Latest_DF");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCorrelationTab = (event, newValue) => {
    setCorrelationTab(newValue);
  };
  const handleDataframe = (event, newValue) => {
    setdataframe(newValue);
  };
  const getData = () => {
    getDataFrame(dataframe);
  };
  const getSignedurl = async () => {
    if (data?.pandasReport) {
      const ContextData = {
        StageID: "Common_API",
        FeatureID: "Common_API",
        FunctionID: "signedURL",
        Actions: "",
      };
      const contentData = {
        filePath: data?.pandasReport,
      };
      const res = await commonAPI(ContextData, contentData);
      if (res?.Result?.signedURL) {
        window.open(res?.Result?.signedURL);
      }
    }
  };

  useEffect(() => {
    setdataframe(data?.selectedDataset);
  }, [data?.selectedDataset]);

  return (
    <div className={classes.root1}>
      <div className={classes.tabs1}>
        <div>
          {' '}
          <h5>This section gives the view of the data sample, Data Overview and Data Statistics for the latest dataset. If you wish to view the original dataset uploaded  please choose original dataset and execute </h5>
        </div>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <RadioGroup
              row
              name="specify"
              defaultValue="False"
              aria-label="Learn Mixture Weights"
              value={dataframe}
              onChange={handleDataframe}
            >
              <FormControlLabel
                value="Latest_DF"
                control={<Radio color="primary" />}
                label="Latest Dataset"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Original_DF"
                control={<Radio color="primary" />}
                label="Original Dataset"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Synthetic_DF"
                control={<Radio color="primary" />}
                label="Synthetic Dataset"
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledButton variant="contained" color="primary" onClick={getData}>Execute</StyledButton>
          </Grid>
        </Grid>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example" style={{ background: "black", color: 'white', fontWeight: 'bold' }}>
          <AntTab label="Data Sample" {...a11yProps(0)} />
          <AntTab label="Data Statistics" {...a11yProps(1)} />
          <AntTab label="Data Overview" {...a11yProps(2)} />
          <AntTab label="Profiling Report" {...a11yProps(3)} />
          <AntTab label="Correlation" {...a11yProps(4)} />
        </AntTabs>

        <TabPanel value={value} index={0}>
          {data.dataHead
            ? <JsonDataTable fields={data?.dataHead?.schema?.fields} dataRows={data?.dataHead?.data} />
            : <p>Data Unavailable</p>}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {data.dataDescribe
            ? <JsonDataTable fields={data?.dataDescribe?.schema?.fields} dataRows={data?.dataDescribe?.data} />
            : <p>Data Unavailable</p>}

        </TabPanel>

        <TabPanel value={value} index={2}>
          {data?.dataOverview ? (
            <OverViewList tyee="none">
              {Object.entries(data.dataOverview).map((data) => (
                <ListItem>
                  {' '}
                  <FontAwesomeIcon icon={faCheckCircle} />
                  {`${data[0]} : ${data[1]}`}
                </ListItem>
              ))}
            </OverViewList>
          )
            : <p>Data Unavailable</p>}
        </TabPanel>

        <TabPanel value={value} index={3} width="auto">
          <center>
            {data?.pandasReport
              ? <LinkBtn onClick={getSignedurl}>View Report</LinkBtn>
              : data.inProgress
                ? (
                  <>
                    <p>Report generation is in progress, check after sometime</p>
                    <StyledButton variant="outlined" onClick={getPandasReport}>Check Again</StyledButton>
                  </>
                )
                : (
                  <>
                    <p>To generate profiling report</p>
                    <StyledButton variant="outlined" onClick={getPandasReport}>Click here</StyledButton>
                  </>
                )}
          </center>
        </TabPanel>
        <TabPanel value={value} index={4} width="auto">
          {data?.correlationHeatmap
            ? (
              <>
                <AntTabs value={correlationTab} onChange={handleCorrelationTab} aria-label="ant example">
                  <AntTab label="Correlation Plot" {...a11yProps(0)} />
                  <AntTab label="Correlation Matrix" {...a11yProps(1)} />
                </AntTabs>
                <TabPanel value={correlationTab} index={0} width="auto">
                  <Plot data={data?.correlationHeatmap} />
                </TabPanel>
                <TabPanel value={correlationTab} index={1} width="auto">
                  <JsonDataTable fields={data?.correlationMatrix?.schema?.fields} dataRows={data?.correlationMatrix?.data} />
                </TabPanel>
              </>
            ) : <p>Data Unavailable</p>}
        </TabPanel>
      </div>

    </div>
  );
}

export default memo(DataTab);

export const OverViewList = styled(List)`
  list-style: none;
  .svg-inline--fa {
    margin-right: 3px;
    color: #2a9d8f;
  }
`;
const LinkBtn = styled.a`
  text-decoration: none;
  padding: 10px;
  color: #fff;
  background-color: #f50057;
  border-radius: 5%;
`;
