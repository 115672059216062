import React, { useEffect, useState } from "react";
import {
  Grid, AppBar, Tab, Tabs,
} from "@material-ui/core";
import styled from 'styled-components';

import MainLayout from "../../common/MainLayout";
import Navbar from "../../common/Navbar";
import { SolutionContainer } from "../../../styles/common";
import SolutionCards from "../../common/SolutionCards";

const IOTRecipesView = () => {
  const query = new URLSearchParams(window.location.search).get('tab');
  const [tabValue, setTab] = useState(query ? Number(query) : 1);
  const [cardsList, setCardsList] = useState([]);

  const tabObj = {
    0: ['hwReceipes1', 'hwReceipes2', 'hwReceipes3', 'hwReceipes4', 'hwReceipes5'],
    1: ['swReceipes1', 'swReceipes2', 'swReceipes3', 'swReceipes4', 'swReceipes5'],
    2: ['arvr1', 'arvr2', 'arvr3'],
    3: ['tempaltes3d1'],
    4: ['otherTuts1', 'otherTuts2'],
  };
  const handleTabChange = (event, value) => {
    setTab(value);
  };

  useEffect(() => {
    setCardsList(tabObj[tabValue]);
  }, [tabValue]);
  return (
    <SolutionContainer>
      <Navbar />
      <AppBar position="static" color="default">
        <StyledTabs variant="fullWidth" indicatorColor="primary" textColor="primary" value={tabValue} onChange={handleTabChange}>
          <Tab label="Hardware" />
          <Tab label="Software" />
          <Tab label="AR/VR" />
          <Tab label="3D Templates" />
          <Tab label="Other Tutorials" />
        </StyledTabs>
      </AppBar>
      <br />
      <StyledGrid container direction="row" alignItems="stretch" spacing={2}>
        <SolutionCards solutionIds={cardsList} />
      </StyledGrid>
    </SolutionContainer>

  );
};

export default IOTRecipesView;
const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    overflow: hidden;
  }
`;
export const StyledTabs = styled(Tabs)`
@media only screen and (max-width: 950px) {
  .MuiTabs-flexContainer{
    flex-wrap: wrap;
    .PrivateTabIndicator-colorPrimary-7{
      display: none;
    }
  }
}
@media only screen and (max-width: 650px) {
  background-color: #ffff;
  .MuiTabs-flexContainer{
    flex-direction: column;
    .MuiTab-textColorPrimary.Mui-selected{
      border: 1px solid #3f51b5;
    }
  }
`;
