import * as actions from '.';

export const setProject = (data) => ({
  type: actions.SET_PROJECT,
  payload: data,
});

export const setFeatureDataChef = (data) => ({
  type: actions.SET_FEATURE_DATA_CHEF,
  payload: data,
});

export const createWorkflow = (data) => ({
  type: actions.CREATE_WORKFLOW,
  payload: data,
});
export const createJobs = (data) => ({
  type: actions.CREATE_JOBS,
  payload: data,
});
export const setDatachefInputData = (data) => ({
  type: actions.SET_DATACHEF_INPUT_DATA,
  payload: data,
});
