import React from "react";
import styled from "styled-components";

import {
    Radio
} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const RadioTable = ({ header, body, onclick, id }) => (
    <Table aria-label="sticky table">
        <TableHead>
            <TableRow>
                <StyledTableHead align="left" > </StyledTableHead>
                {header?.map((cellData) => (
                    <StyledTableHead align="left" >{cellData}</StyledTableHead>
                ))}
            </TableRow>
        </TableHead>
        <TableBody disablePadding>
            {body && Object.entries(body)?.map((row, ind) => (
                ind < 11 && <StyledRow tabIndex={-1} onClick={onclick} id={row[0]} selected={row[0] == id}>
                    <TableCell align="left" >
                        <Radio value={row[0]} checked={row[0] == id} />
                    </TableCell>
                    {row.map((col) => (
                        <TableCell align="left" >
                            {col}
                        </TableCell>
                    ))}
                </StyledRow>
            ))}
        </TableBody>
    </Table>
);


const StyledRow = styled(TableRow)`
  .MuiTableCell-root{
    padding: 6px;
    cursor: pointer;
    &.hover{
        background-color: lightgray; 
    }
  }
`;

const StyledTableHead = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: #3c40af;
    color: white;
    font-weight: bold;
    font-size: smaller;
    @media only screen and (max-width: 450px) {
      font-size: x-small;
    }
  }
`;



export default RadioTable;
