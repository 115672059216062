import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import CohortSenseService from "./CohortSenseService";
import CommonStyles from './commonStyles';

class FeatureScalingSection extends React.Component {
  constructor(props) {
    super(props);
    this.onSelectChanged = this.onSelectChanged.bind(this);
    this.onClickFeatureSelection = this.onClickFeatureSelection.bind(this);
    this.onSubmitPreviewFeatureScaling = this.onSubmitPreviewFeatureScaling.bind(this);
    this.state = {
      featureScaling: '',
      isLoading: false,
    };
  }

  onSelectChanged(event) {
    const featureScaling = event.target.value;
    this.setState(
      {
        featureScaling,
      },
    );
  }

  onSubmitPreviewFeatureScaling() {
    console.log('this.state.featureScaling', this.state.featureScaling);
    if (this.state.featureScaling) {
      this.setState(
        {
          isLoading: true,
        },
      );

      const fileName = window.sessionStorage.getItem('cohortFileName');
      const jsonFileName = window.sessionStorage.getItem('cohortJsonFileName');

      CohortSenseService.retrieveFeatureScalingPreview(this.state.featureScaling, fileName, jsonFileName)
        .then((result) => {
          console.log('retrieveFeatureScalingPreview', result);
          const previewData = JSON.parse(result.predata);

          this.fields = previewData.schema.fields;
          this.dataRows = previewData.data;

          this.setState(
            {
              isLoading: false,
            },
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onClickFeatureSelection() {
    console.log('onClickFeatureSelection');
    window.sessionStorage.setItem('cohortSelectedFeatureScaling', this.state.featureScaling);
    this.props.navigateNext();
  }

  render() {
    const { classes } = this.props;
    return (
      <section className="App">
        <div>
          <section className={classes.container}>
            <h2 className={classes.titleClient}>
              Feature Scaling
            </h2>

            <section>

              <Grid container>
                <Grid item md={6}>
                  <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.fullWidth}>
                      <InputLabel>Feature Scaling</InputLabel>
                      <Select value={this.state.featureScaling} onChange={this.onSelectChanged}>
                        <MenuItem value="none">None</MenuItem>
                        <MenuItem value="standardScaler">Standard Scaler</MenuItem>
                        <MenuItem value="minMaxScaler">Min/Max Scaler</MenuItem>
                        <MenuItem value="maxAbsScaler">Max/Abs Scaler</MenuItem>
                        <MenuItem value="normalizer">Normalizer</MenuItem>
                        <MenuItem value="robustScaler">Robust Scaler</MenuItem>
                      </Select>
                      <br />
                    </FormControl>
                  </form>

                </Grid>

                <Grid item md={6}>
                  <div align="left" className={classes.buttonLayoutArea}>
                    <Button
                      onClick={this.onSubmitPreviewFeatureScaling}
                      variant="contained"
                      color="primary"
                      disabled={!this.state.featureScaling && !this.state.isLoading}
                    >
                      Submit
                    </Button>
                                        &nbsp;
                    {/* <Button variant="contained" color="primary">Back</Button> */}
                  </div>
                </Grid>

              </Grid>

            </section>

            <br />
            <section>
              <Grid container>
                <Grid item md={12}>
                  {
                                        this.state.isLoading && (
                                        <LinearProgress />
                                        )
                                    }
                  {
                                        this.fields && (
                                        <div className={classes.scrollableTable}>
                                          <div align="right">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={this.onClickFeatureSelection}
                                              disabled={!this.state.featureScaling && !this.state.isLoading}
                                            >
                                              Model Selection
                                            </Button>
                                          </div>
                                          <Table className={classes.table}>
                                            <TableHead>
                                              <TableRow>
                                                {
                                                                this.fields.map((field) => (
                                                                  <TableCell align="right">{field.name}</TableCell>
                                                                ))
                                                            }
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {
                                                            this.dataRows.map((row) => (
                                                              <TableRow key={row.index}>
                                                                {
                                                                        this.fields.map((field) => (
                                                                          <TableCell
                                                                            align="right"
                                                                          >
                                                                            {field.name === 'index' ? row[field.name] + 1 : row[field.name]}
                                                                          </TableCell>
                                                                        ))
                                                                    }
                                                              </TableRow>
                                                            ))
                                                        }
                                            </TableBody>

                                          </Table>
                                        </div>
                                        )
                                    }

                </Grid>
              </Grid>
            </section>
          </section>
        </div>
      </section>
    );
  }
}

FeatureScalingSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CommonStyles)(FeatureScalingSection);
