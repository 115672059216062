import React, { useEffect, useState } from "react";
import {
  Grid, AppBar, Tab, Tabs,
} from "@material-ui/core";

import * as d3 from 'd3';
import sunburst from 'highcharts/modules/sunburst';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import MainLayout from "../common/MainLayout";
import Navbar from "../common/Navbar";

import {
  HighchartContainer, PageContainer, PageTitle, Paragraph,
} from "../../styles/common";
import Footer from "../views/home/Footer";
import processData from '../../assets/files/procdata.csv';
import diagnosis from '../../assets/files/diagnosis.csv';

sunburst(Highcharts);
exporting(Highcharts);

const PatientHeirarchyDashboard = () => {
  const [tabValue, setTab] = useState(0);

  const [heirarchyData, setHeirarchyData] = useState(null);
  const [procedureData, setProcedureData] = useState(null);
  useEffect(() => {
    d3.csv(diagnosis)
      .then((data) => {
        setHeirarchyData(data);
        parsecsv(data, 'Patient Diagnosis Hierarchy');
      });
    d3.csv(processData)
      .then((data) => {
        setProcedureData(data);
      });
  }, []);

  const parsecsv = (data, text) => {
    const dict = [];
    for (let k = 0; k < data.length; k++) {
      if (data[k].value.length === 0) {
        dict.push({
          id: data[k].id,
          parent: data[k].parent,
          name: data[k].name,
          desc: data[k].desc,
        });
      } else {
        dict.push({
          id: data[k].id,
          parent: data[k].parent,
          name: data[k].name,
          desc: data[k].desc,
          value: parseInt(data[k].value),
        });
      }
    }
    createMap(dict, text);
  };

  const createMap = (data, text) => {
    console.log(data);
    if (Highcharts.getOptions().colors.indexOf('transparent') === -1) {
      Highcharts.getOptions().colors.splice(0, 0, 'transparent');
    }

    Highcharts.chart('container', {
      chart: {
        height: '100%',
      },

      title: {
        text,
      },

      plotOptions: {

        sunburst: {
          borderColor: '#bfbfbf',
        },
      },
      series: [{
        type: "sunburst",

        data,
        allowDrillToNode: true,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
          filter: {
            property: 'innerArcLength',
            operator: '>',
            value: 16,
          },
        },
        levels: [{
          level: 1,
          levelIsConstant: false,
          dataLabels: {
            filter: {
              property: 'outerArcLength',
              operator: '>',
              value: 30,
            },
          },
        }, {
          level: 2,
          colorByPoint: true,
        },
        {
          level: 3,
          colorVariation: {
            key: 'brightness',
            to: -0.5,
          },
        }, {
          level: 4,
          colorVariation: {
            key: 'brightness',
            to: 0.5,
          },
        }],
      }],
      tooltip: {
        headerFormat: "",
        pointFormat: '<b>{point.desc}</b> : {point.value}',
      },
    });
  };

  const handleTabChange = (event, value) => {
    setTab(value);
    if (value === 1) {
      parsecsv(procedureData, 'Patient Procedure Hierarchy');
    } else {
      parsecsv(heirarchyData, 'Patient Diagnosis Hierarchy');
    }
  };
  return (
    <MainLayout>
      <Navbar />
      <PageContainer>
        <PageTitle>Diagnosis-Procedure Hierarchy</PageTitle>
        <AppBar position="static" color="default">
          <Tabs variant="fullWidth" indicatorColor="primary" textColor="primary" value={tabValue} onChange={handleTabChange}>
            <Tab label="Patient Diagnosis Hierarchy" />
            <Tab label="Patient Procedure Hierarchy" />
          </Tabs>
        </AppBar>
        <br />
        <br />
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <HighchartContainer id="container" display />
          </Grid>
        </Grid>

      </PageContainer>
      <Footer hideSocialShare />
    </MainLayout>
  );
};

export default PatientHeirarchyDashboard;
