import React, { memo, useState } from 'react';
import { TextField, Grid } from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// local components
import Collapse from '../../common/Collapse';

import CommonService from '../../../utils/services/CommonService';
import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from '../../../styles/common';

// image components
import clinicalnotespreprocessor_solution1 from '../../../assets/images/app/clinicalnotespreprocessor.webp';

const ClinicalNotesPreprocessor = () => {
  const initialData = {
    clinicalnotes: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState('');

  const { getQueryResult } = CommonService(
    'clinicalTrials',
    'clinicalNotesPreprocessor'
  );

  const showSample1 = () => {
    SetResultData('');
    const NewDataSet = {
      clinicalnotes:
        'Admission Date:         Discharge Date:   Date of Birth:          Sex:  M  Service:  NEUROLOGY  CHIEF COMPLAINT:  Weakness, inability to talk.  HISTORY OF THE PRESENT ILLNESS:  This is a 41-year-old African-American male with a history of hypertension who was in his usual state of health until about 10:25 a.m. on the morning of admission.  He had gone to use the restroom and a few minutes later his family found him slumped onto the floor, apparently unable to talk and with weakness in his right arm and leg.  EMS was called and he was brought into the Emergency Department at .  The patient has not had strokes or previous similar symptoms. He has a history of hypertension but no history of cardiac symptoms.',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    SetResultData('');
    const NewDataSet = {
      clinicalnotes:
        'Admission Date:         Discharge Date:    Date of Birth:                    Sex:  F  Service:   ADMISSION DIAGNOSIS:  End stage renal disease, admitted for transplant surgery.  HISTORY OF PRESENT ILLNESS:  The patient is a 65 year-old woman with end stage renal disease, secondary to malignant hypertension. She was started on dialysis in .  She currently was on peritoneal dialysis and appears to be doing well.  She has a history of gastric angiectasia which she requires endoscopy. She was admitted on  for a scheduled living donor kidney transplant by her son, who is the donor.  She does have a donor specific antibody (B-51) and will have a final T & B cell class match prior to transplantation.',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const { clinicalnotes } = sampleData;

    const params = `${clinicalnotes}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === '') {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: 'error', message: 'Fields are empty' });
    } else {
      SetResultData('');
      setSpinner(true);

      getQueryResult('output', params)
        .then((res) => {
          setSpinner(false);
          SetResultData(res.result);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Clinical Notes are the most important information in medical
            records, reminding doctors and other members of the healthcare team
            about the patient’s condition and plan for treatment. However, 94%
            of people in the United States do not have easy access to this
            information. Some people confuse notes with the “After Visit
            Summary,” or AVS. The AVS is a collection of data from within your
            electronic health record that is automatically inserted into a
            pre-written format, but it is not nearly as descriptive or as
            helpful as a clinical note. A good clinical note tells the patient’s
            story, integrates different aspects of his life, his illness, and
            his efforts to stay healthy or to regain and maintain health
          </p>
          <center>
            <Grid item xs={8} sm={8} md={8}>
              <img width="80%" src={clinicalnotespreprocessor_solution1} />
            </Grid>
          </center>
          <p>
            The MIMIC III (Medical Information Mart for Intensive Care III)
            database is a free publicly available hospital database containing
            de-identified data from approximately 50,000 patients. This data
            comes from patients who were admitted to Beth Israel Deaconess
            Medical Center in Boston, Massachusetts from 2001 to 2012.
          </p>
          <p>
            Doctors take notes on their computer and 80% of what they capture is
            not structured. That makes the processing of information even more
            difficult. Clinical notes represent a vast wealth of knowledge and
            insights that can be utilized for predictive models using Natural
            Language Processing. Before training models on clinical notes, it is
            important to pre-process the notes as it is an important task and
            critical step in text mining. This tool would do the pre-processing
            of the clinical notes with just a click.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ClinicalNotesPreprocessor/ClinicalNotesPreprocessor.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                rows="8"
                multiline
                variant="outlined"
                name="clinicalnotes"
                fullWidth
                label="Clinical Notes"
                onChange={handleInputs}
                value={sampleData.clinicalnotes}
              />
            </Grid>
            <Grid item xs={1}>
              {showSpinner && <Spinner text="Loading..." />}
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                rows="8"
                multiline
                variant="outlined"
                name="preprocessedclinicalnotes"
                fullWidth
                label="Preprocessed clinical Notes"
                value={result}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample1"
                onClick={showSample1}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample2"
                onClick={showSample2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                btnColor="#023e8a"
                color="primary"
                onClick={getResult}
                endIcon={<ArrowForwardIcon />}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ClinicalNotesPreprocessor);
