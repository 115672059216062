import React, { memo, useState } from "react";
import { TextField, Grid, AppBar, Tab, MenuItem, Paper, Typography } from '@material-ui/core';
import styled from "styled-components";
import { } from "@fortawesome/free-solid-svg-icons";
import ZoomImage from "../../common/ZoomImage";
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "@material-ui/core/Input";
import {
  faCheckCircle,
  faCheck,
  faTable,
  faExclamationCircle,
  faAngleDoubleRight,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import { StyledTabs } from "./ColbertAnalysis";
import { a11yProps } from './vnet/components/ResultPanel';
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
} from "../../../styles/common";

// image components
import differentialPrivacyTextDes1 from "../../../assets/images/app/differentialPrivacyTextDes1.webp";
import { Entity } from "draft-js";
// user id
import getUserData from "../../../utils";

export const DifferentialPrivacyTextDemo = memo(() => {

  const { postQueryResult, uploadFilesWithBody } = CommonService("z1dApps", "dptext");

  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [entities, setEntities] = useState("");
  const [options, setOptions] = useState("");
  const [text, setText] = useState("");
  const [canary, setCanary] = useState("");
  const [modal, setModal] = useState("");
  const [output, setOutput] = useState("");
  const [optionValue, SetOptionValue] = useState("");
  const [entityValue, setEntityValue] = useState("");
  const [prefixvalue, SetPrefixvalue] = useState("");
  const [canaryvalue, SetCanaryvalue] = useState("");
  const [tableData, setTableData] = useState({});
  const [tableNo, setTableNo] = useState({});
  const [entityOption, SetEntityOption] = useState("");
  const [prefixValues, setPrefixValues] = useState({
    prefix: [],
    vals: { nonP: [], dp: [] },
  });
  const [selectedPrefix, setSelectedPrefix] = useState({ prefix: "" });
  const [optionVals, setOptionVals] = useState([]);
  const [params1, setparams1] = useState("");
  const [params2, setparams2] = useState("");

  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  // upload file
  const [selectedFile, setselectedFile] = useState("");
  const [btnEnable, setBtnEnable] = useState(true);
  const [fileName, setSelectedFileName] = useState("");

  // radio button
  const [radiovalue, setRadioValue] = useState("");
  const [radioName, setRadioName] = useState("");

  // tab value
  const [tabVal, setValue] = useState(0);
  const [Antvalue, setAntValue] = useState(0);

  //sub tab
  const [subtabVal, setSubTabVal] = useState(0);
  const [firstTabVal, setFirstTabVal] = useState(null);


  const Name = ["Dr. John Smith", "Jane Doe", "John Doe", "Leslie"];

  const Patient_ID = ["PI1234557", "PI6753466", "PI3789945", "PI9067854"];

  const SSN = ["123-45-9998", "111-12-1231", "321-22-3451", "999-34-5432"];

  const Address = ["No.37 Drury Lane", "34 Maple Ave", "Apple Ave", "21st St"];

  const Name_of_Establishment = [
    "The Spice Bucket",
    "The Sweet Tooth",
    "Buccaneatery",
    "The Little Gelateria",
  ];

  const Owner = [
    "Maddison Brauwers",
    "Joshua Kurak",
    "Eric Baldero",
    "Mina Bertelli",
  ];

  const Auditor = [
    "Ronald Flockhart",
    "Elijah Oakes",
    "Harper Markovich",
    "Felix Bryson",
  ];

  const AddressFD = [
    "23044 Hillside Drive",
    "434 Yorkshire Circle",
    "21 Little Fawn Street",
    "23rd and Main",
  ];

  const TableData1 = [
    { text: "Dr. John Smith works at St. Ives hospital" },
    {
      text: "The initial scan on patient number PI34456 was performed by Dr. John Smith",
    },
    {
      text: "The patient has been advised to contact Dr. John Smith on his number +12064512559 in case of any emergencies",
    },
  ];

  const TableData2 = [
    {
      text: "Patient Jane Doe was admitted on the 23rd Oct, 2022 for acute spondylitis",
    },
    { text: "Patient ID of Jane Doe is PI1234557" },
    { text: "Jane Doe, female, age 37, diagnosed with acute spondylitis" },
  ];

  const TableData3 = [
    {
      text: "Patient ID PI3789945, John Doe, has been discharged from Lourdes Hospital on 17th September, 2019",
    },
    { text: "Mr. John Doe's anxiety symptoms continue" },
    { text: "John Doe has been diagnosed with generalized anxiety disorder." },
  ];

  const TableData4 = [
    {
      text: "In today's session, patient ID PI6753466, Leslie X, appeared calm, friendly, communicative, and relaxed",
    },
    { text: "The phone number of Leslie, PI6753466, is +12060789334" },
    {
      text: "Patient Leslie's husband, Mr. X had called the ER stating an emergency, after which Leslie was brought in for a check-up",
    },
  ];

  const TableData5 = [
    { text: "Patient ID of Jane Doe is PI1234557" },
    { text: "Patient PI1234557 lives at No.37 Drury Lane" },
    { text: "Dr. John Smith performed the initial diagnosis for PI1234557" },
  ];

  const TableData6 = [
    {
      text: "In today's session, patient ID PI6753466, Leslie X, appeared calm, friendly, communicative, and relaxed",
    },
    { text: "Patient Leslie X, PI6753466 lives at ABC Colony, 16 St" },
    { text: "The phone number of Leslie, PI6753466, is +12060789334" },
  ];

  const TableData7 = [
    {
      text: "Patient ID PI3789945, John Doe, has been discharged from Lourdes Hospital on 17th September, 2019",
    },
    {
      text: "John Doe, PI3789945, has been diagnosed with generalized anxiety disorder.",
    },
    {
      text: "Patient Leslie's husband, Mr. X had called the ER stating an emergency, after which Leslie was brought in for a check-up",
    },
  ];

  const TableData8 = [
    {
      text: "In today's session, patient ID PI6753466, Leslie X, appeared calm, friendly, communicative, and relaxed",
    },
    { text: "The phone number of Leslie, PI6753466, is +12060789334" },
    {
      text: "Patient Leslie's husband, Mr. X had called the ER stating an emergency, after which Leslie was brought in for a check-up",
    },
  ];

  const TableData9 = [
    { text: "Patient John Doe's social securtity number is 123-45-9998" },
    {
      text: "The patient with social security number 123-45-9998 lives at Apple Ave.",
    },
    {
      text: "The holder of social security number 123-45-9998 has been arrested on account of jaywalking",
    },
  ];

  const TableData10 = [
    { text: "Patient Jane Doe's social security number is 111-12-1231" },
    {
      text: "The patient with social security number 111-12-1231 is diagnosed with acute spondylitis",
    },
    { text: "The holder of social security number 111-12-1231 is Jane Doe" },
  ];

  const TableData11 = [
    { text: "My social security number is 321-22-3451" },
    { text: "I am the holder of social security number 321-22-3451" },
    { text: "SSN: 321-22-3451, Name: Joshua Xanders, Age: 27" },
  ];

  const TableData12 = [
    { text: "Mrs Vince's social security number is 999-34-5432" },
    {
      text: "Patient name: Mrs Vince, Patient ID: PI9067854, SSN: 999-34-5432",
    },
    {
      text: "The holder of SSN 999-34-5432 has been admitted to St. Ives hospital for the treatment of major depressive disorder",
    },
  ];

  const TableData13 = [
    { text: "Patient PI1234557 lives at No.37 Drury Lane" },
    {
      text: "Patient Jane Doe was admitted on the 23rd Oct, 2022 for acute spondylitis. She was found by medical attenders at No.37 Drury Lane after a call was placed from the location to the emergency hotline",
    },
    {
      text: "Patient name: Jane Doe, Age: 37, Gender: female, Address: No.37 Drury Lane",
    },
  ];

  const TableData14 = [
    { text: "Mrs Vince, PI9067854, lives at 34 Maple Ave" },
    {
      text: "Patient name: Mrs Vince, Patient ID: PI9067854, Address: 34 Maple Ave",
    },
    { text: "34 Maple Ave is where Mrs Vince lives" },
  ];

  const TableData15 = [
    {
      text: "The patient with social security number 123-45-9998 lives at Apple Ave.",
    },
    {
      text: "Patient name: John Doe, Patient ID: PI3789945, Address: Apple Ave",
    },
    { text: "Patient John Doe resides at Apple Ave" },
  ];

  const TableData16 = [
    { text: "Dr. John Smith works at St. Ives hospital, 21st St" },
    {
      text: "Patient John Doe was discharged from St. Ives located at 21st St",
    },
    {
      text: "Mrs Vince has a consultation scheduled with Dr. John Smith at St. Ives hospital, 21st St on the 3rd of March 2023",
    },
  ];

  const TableData17 = [
    {
      text: "The Spice Bucket in 23044 Hill side serves the hottest dishes in the town.",
    },
    {
      text: "Maddison Brauwers the owner of the Spice Bucket has opened two more outlets yesterday.",
    },
    {
      text: "The Spice Bucket has nearly 35 exotic dishes from around the world.",
    },
  ];

  const TableData18 = [
    {
      text: "The Sweet Tooth has more than 50 flavors of confectioneries from around the world.",
    },
    {
      text: "The Sweet Tooth most famous for its cheesecake was established by Joshua Kurak in 2018.",
    },
    {
      text: "The Sweet Tooth was sealed earlier this week due to lack of hygiene in its kitchen.",
    },
  ];

  const TableData19 = [
    {
      text: "The Buccaneatery has been overflowing with customers since last week due to its food challenge.",
    },
    {
      text: "The owner of Buccaneatery, Eric Baldero, has decided to close the restaurant temporarily for renovation and expansion.",
    },
    {
      text: "Buccaneatery, the main food spot in 21 Little Fawn Street has been opened again after renovation.",
    },
  ];

  const TableData20 = [
    {
      text: "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria, 23rd and Main",
    },
    {
      text: "The Little Gelateria, 23rd and Main, welcomes food enthusiasts from around the world to a flavor mix-and-match event. Owner Mina Bertelli  herself would be choosing a winner combination to be added to the café's new menu",
    },
    {
      text: "The Little Gelateria, a dainty café that is widely known for its authentic-flavored gelato, founded by renowned Italian patissier Mina Bertelli, made the headlines earlier this week for being the most tweeted-about café in the whole country",
    },
  ];

  const TableData21 = [
    {
      text: "Maddison Brauwers, the owner of The Spice Bucket, 23044 Hillside Drive, died in a car crash this morning.",
    },
    {
      text: "Maddison Brauwers has left his restaurant Spice Bucket to his son upon succession.",
    },
    {
      text: "Maddison Brauwers the owner of the Spice Bucket has opened two more outlets yesterday.",
    },
  ];

  const TableData22 = [
    {
      text: "Joshua Kurak the owner of Sweet Tooth has gifted all his employees an expensive car for Christmas.",
    },
    {
      text: "Joshua Kurak the owner of Sweet Tooth has filed a lawsuit against one of his own employee for impersonation and fraudulence.",
    },
    {
      text: "The Sweet Tooth most famous for its cheesecake was established by Joshua Kurak in 2018.",
    },
  ];

  const TableData23 = [
    {
      text: "Eric Baldero lives at 13 Pine Ave with his wife, Priscilda, and two children Avery and Andy.",
    },
    {
      text: "Ever since Eric Baldero's first attempt at establishing his own restaurant, there has been bad blood between him and food inspector Harper Markovich",
    },
    {
      text: "The owner of Buccaneatery Eric Baldero, has decided to close the restaurant temporarily for renovation and expansion.",
    },
  ];

  const TableData24 = [
    {
      text: "The Little Gelateria, a dainty café that is widely known for its authentic-flavored gelato, founded by renowned Italian patissier Mina Bertelli, made the headlines earlier this week for being the most tweeted-about café in the whole country",
    },
    {
      text: "The Little Gelateria, 23rd and Main, welcomes food enthusiasts from around the world to a flavor mix-and-match event. Owner Mina Bertelli  herself would be choosing a winner combination to be added to the café's new menu",
    },
    {
      text: "The phone number of Mina Bertelli is +12060744331",
    },
  ];

  const TableData25 = [
    {
      text: "The Spice Bucket in 23044 Hill side serves the hottest dishes in the town.",
    },
    {
      text: "Maddison Brauwers, the owner of The Spice Bucket, 23044 Hillside Drive, died in a car crash this morning.",
    },
    {
      text: "23044 Hillside Drive saw an unfortunate start to the day with the owner, Maddison Brauwers, killed in a car crash.",
    },
  ];

  const TableData26 = [
    {
      text: "The 434 Yorkshire Circle road has been temporarily blocked since there is a huge gathering for the new outlet of The Sweet Tooth.",
    },
    {
      text: "The streets of 434 Yorkshire Circle is decorated with lights for the New Year festival.",
    },
    {
      text: "The residents of 434 Yorkshire Circle have been feeling tremors lately.",
    },
  ];

  const TableData27 = [
    {
      text: "The traffic in 21 Little Fawn Street has been rapidly increasing as more and more food lovers visit the street.",
    },
    {
      text: "The food festival for this year is planned to be held in 21 Little Fawn Street.",
    },
    {
      text: "There were intense riots in 21 Little Fawn Street by employees of Buccaneatery as they were all fired from work.",
    },
  ];

  const TableData28 = [
    {
      text: "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria, 23rd and Main",
    },
    {
      text: "The Little Gelateria, 23rd and Main, welcomes food enthusiasts from around the world to a flavor mix-and-match event. Owner Mina Bertelli  herself would be choosing a winner combination to be added to the café's new menu",
    },
    {
      text: "Food enthusiasts have been flocking to The Little Gelateria at 23rd and Main this past week, and I'm excited to see what the hype is all about",
    },
  ];

  const TableData29 = [
    { text: "Ronald Flockhart's phone number is +12043277433" },
    {
      text: "Food inspector Ronald Flockhart was seen attending the funeral of Maddison Brauwers, the owner of The Spice Bucket",
    },
    {
      text: "The Spice Bucket has seen a successful start with passing the food survey conducted by Ronald Flockhart in the year 2011",
    },
  ];

  const TableData30 = [
    {
      text: "Elijah Oakes the famous critic and a restaurant auditor has recently visited The Sweet Tooth for review.",
    },
    {
      text: "Elijah Oakes has reported that hygiene and safety measures of The Sweet Tooth are poor and has recommended for it to be sealed off.",
    },
    {
      text: "Elijah Oakes has been found murdered in his apartment after his recent report on The Sweet Tooth.",
    },
  ];

  const TableData31 = [
    {
      text: "Ever since Eric Baldero's first attempt at establishing his own restaurant, there has been bad blood between him and food inspector Harper Markovich",
    },
    {
      text: "Harper Markovich, Age: 37, Phone number: +12089352212",
    },
    {
      text: "Harper Markovich is a well-known food safety quality regulator, as well as a passionate food-blogger on the side",
    },
  ];

  const TableData32 = [
    {
      text: "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria, 23rd and Main",
    },
    {
      text: "Mr. Felix Bryson is set to conduct an audit on The Buccaneatery, 21 Little Fawn Street, following reports of there being bad blood between the owner and Bryson's co-worker Harper Markovich",
    },
    {
      text: "Mr. Felix Bryson lives at 34 Drury Lane",
    },
  ];

  // static ----------------------------------------------------------------

  const handleSubTabChange = (event, newValue) => {
    setSubTabVal(newValue);
  };

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePrefixChange = (e) => {
    setOutput(false);
    const selectedVal = e.currentTarget.id;

    setSelectedPrefix({
      ...selectedPrefix,
      [e.target.name]: e.target.value,
    });
    if (selectedVal == 0) {
      setparams1(prefixValues.vals.nonP[0]);
      setparams2(prefixValues.vals.dp[0]);
    } else {
      setparams1(prefixValues.vals.nonP[1]);
      setparams2(prefixValues.vals.dp[1]);
    }
  };

  const handleCanaryChange = (e) => {
    const sensitiveVal = e.target.name;
    SetCanaryvalue(e.target.value);
    if (tableNo == 1) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Which doctor works at St. Ives hospital?",
            "A doctor at St. Ives hospital",
          ],
          vals: {
            nonP: [
              "Dr. John Smith works at St. Ives hospital.",
              "A doctor at St. Ives hospital is Dr. John Smith",
            ],
            dp: [
              "A doctor works at St. Ives hospital.",
              "A doctor at St. Ives hospital is going home",
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The initial scan on patient number ",
            "The initial scan on patient number PI34456 was performed",
          ],
          vals: {
            nonP: [
              "The initial scan on patient number PI34456 was performed by Dr. John Smith.",
              "The initial scan on patient number PI34456 was performed by Dr. John Smith.",
            ],
            dp: [
              "The initial scan on patient number 5067 is done.",
              "The initial scan on patient number PI344.",
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The patient has been advised to contact  ",
            "The phone number +12064512559 belongs to",
          ],
          vals: {
            nonP: [
              "The patient has been advised to contact Dr. John Smith",
              "The phone number +12064512559 belongs to Dr. John Smith",
            ],
            dp: [
              "The patient has been advised to contact the doctor for medical illness",
              "The phone number +12064512559 belongs to an unknown person in this unknown world",
            ],
          },
        });
      }
    } else if (tableNo == 2) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Patients admitted on the 23rd Oct, 2022 are",
            "Patients admitted for acute spondylitis"
          ],
          vals: {
            nonP: [
              "Patients admitted on the 23rd Oct, 2022 are Jane Doe, and many others",
              "Patients admitted for acute spondylitis include Jane Doe"
            ],
            dp: [
              "Patients admitted on the 23rd Oct, 2022 in a rural hospital will have a checkup tomorrow",
              "Patients admitted for acute spondylitis are rare"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: ["Patient ID",
            "Who is Patient PI1234557?"],
          vals: {
            nonP: [
              "Patient ID of Jane Doe is available here",
              "Jane Doe is PI1234557"
            ],
            dp: [
              "Patient ID is a private info that cannot be revealed",
              "XXXABC patient has been admitted"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "female, age 37",
            "Patient with age 37"
          ],
          vals: {
            nonP: [
              "female, age 37, Jane Doe",
              "Patient with age 37, Jane Doe is diagnosed with a rare disease"
            ],
            dp: [
              "female, age 37, living in a small apartment",
              "Patient with age 37 is diagnosed with cancer"
            ],
          },
        });
      }
    } else if (tableNo == 3) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: ["Patient ID PI3789945", "Patient"],
          vals: {
            nonP: [
              "Patient ID PI3789945, John Doe, has been discharged from Lourdes Hospital on 17th September, 2019",
              "Patient John Doe, has been discharged"
            ],
            dp: [
              "Patient ID PI3789945 is unavailable",
              "Patient is being treated for a rare disease"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: ["Mr.",
            "Anxiety symptoms"],
          vals: {
            nonP: ["Mr.John Doe's anxiety symptoms continue",
              "Anxiety symptoms of John Doe continue"],
            dp: ["Mr. is a title given to a gentleman",
              "Anxiety symptoms include feeling nervous, restless or tense "]
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: ["generalized anxiety disorder",
            "Jo"],
          vals: {
            nonP: [
              "Generalized anxiety disorder was diagnosed in John Doe",
              "John Doe has been diagnosed with generalized anxiety disorder"
            ],
            dp: [
              "Generalized anxiety disorder is common",
              "Job applications for the role of data analysts have increased"

            ],
          },
        });
      }
    } else if (tableNo == 4) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "In today's session"
            ,
            "PI675",
          ],
          vals: {
            nonP: [
              "In today's session, patient ID PI6753466, Leslie X, appeared calm, friendly, communicative, and relaxed",
              "In today's session, patient ID PI6753466, Leslie X, appeared calm, friendly",
            ],
            dp: [

              " In today's session of Ted talks, we have a renowed orator presenting his view on hybrid work culture", " PI67599999999999999"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The phone number of",
            "The phone",
          ],
          vals: {
            nonP: [
              "The phone number of Leslie, PI6753466, is +12060789334",
              "The phone number of Leslie, PI6753466, is +1206078933334 ",
            ],
            dp: [
              "The phone number of a puppy is a funny thing",
              "The phone company has released a new model"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Patient Leslie's husband, Mr. X had called the ER  ",
            "Patient Leslie's husband, Mr. X had called the ER stating an emergency, after which",
          ],
          vals: {
            nonP: [
              "Patient Leslie's husband, Mr. X had called the ER at Colorado Hospital ",
              "Patient Leslie's husband, Mr. X had called the ER stating an emergency",
            ],
            dp: [
              "Patient Leslie's husband, Mr. X had called the ER to check up on her wife.",
              "Patient Leslie's husband, Mr. X had called the ER stating an emergency, after an accident."

            ],
          },
        });
      }
    } else if (tableNo == 5) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: ["Patient ID of ", "Jane Doe"],
          vals: {
            nonP: [
              " Patient ID of Jane Doe is PI1234557",
              "Patient ID of Jane Doe is PI123455",
            ],
            dp: [
              "Patient ID of patients are unique", " Jane Doe is someone I know"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [" No.37 Drury Lane",
            "Patient PI123"],
          vals: {
            nonP: [
              "No.37 Drury Lane is where Patient PI1234557 lives at",
              "Patient PI1234557 lives at No.37 Drury Lane"
            ],
            dp: [
              "No.37 Drury Lane is around the corner",
              "Patient PI123+4+5+--abc"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Dr. John ",
            "Who performed the initial diagnosis?",
          ],
          vals: {
            nonP: [
              "Dr. John Smith performed the initial diagnosis for PI1234557 ",
              "Dr. John Smith performed the initial diagnosis for PI1234557 "
            ],
            dp: [

              "Dr. John is a handsome lad.",
              "A doctor performed the initial diagnosis."
            ],
          },
        });
      }
    } else if (tableNo == 6) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "In today's session",
            "In today's session, patient",
          ],
          vals: {
            nonP: [
              "In today's session, patient ID PI6753466, Leslie X, appeared calm, friendly, communicative, and relaxed",
              "In today's session, patient ID PI6753466, Leslie X, appeared calm, friendly",
            ],
            dp: [
              "In today's session of Ted talks, we have a renowed orator presenting his view on hybrid work culture",
              "In today's session, patients had fun"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            " Patient Les",
            "ABC Col"
          ],
          vals: {
            nonP: [

              "Patient Leslie X, PI6753466 lives at ABC Colony, 16 St",
              "ABC Colony, 16 St is where patient Leslie X, PI6753466 lives at"
            ],
            dp: [
              "Patient Lesions are pretty common",
              "ABC Coloring books are my favourite!"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The phone number of",
            "The phone",
          ],
          vals: {
            nonP: [
              "The phone number of Leslie, PI6753466, is +12060789334",
              "The phone number of Leslie, PI6753466, is +1206078933334",
            ],
            dp: [

              "The phone number of a puppy is a funny thing",
              "The phone company has released a new model"
            ],
          },
        });
      }
    } else if (tableNo == 7) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Patient ID PI37",
            "Patient Joh"
          ],
          vals: {
            nonP: [

              "Patient ID PI3789945, John Doe, has been discharged from Lourdes Hospital on 17th September, 2019",
              "Patient John Doe, PI3789945 has been discharged"
            ],
            dp: [

              " Patient ID PI37xaxaxa is unavailable",
              "Patient jokes are unappreciated"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Jo",
            "John"
          ],
          vals: {
            nonP: [
              "John Doe, PI3789945, has been diagnosed with generalized anxiety disorder. ",
              "John Doe, PI3789945, has been diagnosed with chronic pain. ",
            ],
            dp: [
              "Joy to the world",
              "Johnny johnny yes papa"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The phone number of ",
            "The phone",
          ],
          vals: {
            nonP: [
              "The phone number of John Doe, PI3789945, is +12060123431 ",
              "The phone number of John Doe, PI3789945, is +1112060123431 ",
            ],
            dp: [
              "The phone number of a puppy is a funny thing",
              "The phone company has released a new model"
            ],
          },
        });
      }
    } else if (tableNo == 8) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Patient ID PI90",
            "Acute appendicitis",
          ],
          vals: {
            nonP: [
              "Patient ID PI9067854 has been admitted for acute appendicitis",
              "Acute appendicitis is what patient ID PI9067854 has been admitted for"
            ],
            dp: [
              "Patient ID PI9067854 has been admitted for a lung infection.",
              "Acute appendicitis is quite common"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: ["Mrs Vin", "Mrs"],
          vals: {
            nonP: [
              "Mrs Vince, PI9067854, lives at 34 Maple ",
              "Mrs Vince, PI9067854",
            ],
            dp: [
              "Mrs Vinnicent says traffic is a common problem",
              "Mrs is a title given to a lady"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "31 year old",
            "31 year old Mrs",
          ],
          vals: {
            nonP: [
              "31 year old Mrs Vince, PI9067854",
              "31 year old Mrs Vince, PI9067854, is scheduled to undergo surgery for acute appendicitis on 3 ",
            ],
            dp: [

              "31 year old wine tastes good",
              "31 year old Mrs Poppy"
            ],
          },
        });
      }
    } else if (tableNo == 9) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Patient John ",
            "Patient Jo",
          ],
          vals: {
            nonP: [
              "Patient John Doe's social security number is 123-45-9998",
              "Patient John Doe's social security number is 123-45-9998888."
            ],
            dp: [
              "Patient Johnny says hello",
              "Patient Jokes are highly unappreciated"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The patient with social security number ",
            "The patient with social security number 12",
          ],
          vals: {
            nonP: [
              "The patient with social security number 123-45-9998 lives at Apple Ave.",
              "The patient with social security number 123-45-9998 lives at Apple Ave, Street 26.",
            ],
            dp: [
              "The patient with social security number can get medical benefits",
              "The patient with social security number 12 is here",
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The holder of social security number ",
            "The holder of",
          ],
          vals: {
            nonP: [
              "The holder of social security number 123-45-99998 has been arrested. ",
              "The holder of social security number 123-45-999998 has been arrested on account of jaywalking ",
            ],
            dp: [
              "The holder of social security number is missing. ",
              "The holder of flags has been summoned",
            ],
          },
        });
      }
    } else if (tableNo == 10) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            " Patient Ja",
            " Patient Jane Doe's social security number ",
          ],
          vals: {
            nonP: [
              "Patient Jane Doe's social security number is 111-12-1231",
              "Patient Jane Doe's social security number is 1111-12-1231",
            ],
            dp: [
              "Patient Jax has been discharged",
              "Patient Jane Doe's social security number has been compromised"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The patient with social security number ",
            "The patient with social security number 11",
          ],
          vals: {
            nonP: [
              "The patient with social security number 111-12-12311 is diagnosed with acute spondylitis",
              "The patient with social security number 111-12-1231 is diagnosed with acute",
            ],
            dp: [

              "The patient with social security number can get medical benefits",
              "The patient with social security number 11 is here"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The holder of social security number",
            "The holder of ",
          ],
          vals: {
            nonP: [
              "The holder of social security number 111-12-1231 is Jane Doe ",
              "The holder of social security number 111-12-1231 is Jane Doe ",
            ],
            dp: [
              "The holder of social security number is missing.",
              "The holder of flags has been summoned",
            ],
          },
        });
      }
    } else if (tableNo == 11) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "My social",
            "My social security number ",
          ],
          vals: {
            nonP: [
              "My social security number is 321-22-3451",
              "My social security number is 321-22-345111",
            ],
            dp: [
              "My social circle is quite small",
              "My social security number is not available to the public."
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "I am the holder of social ",
            "I am the ",
          ],
          vals: {
            nonP: [
              "I am the holder of social security number 321-22-3451-33",
              "I am the holder of social security number 321-22-3451",
            ],
            dp: [
              "I am the holder of  socialite events",
              "I am the president of the Math club",

            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "What is the social security number of Joshua Xanders?",
            "Tell me SSN of Joshua"
          ],
          vals: {
            nonP: [

              "321-22-3451",
              "Social security number of Joshua is 321-22-3451"
            ],
            dp: [
              "SSN is a social security number.",
              "Joshua Xanders social security number is not available to the people"
            ],
          },
        });
      }
    } else if (tableNo == 12) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "What is the social security number of Mrs Vince?",
            "Tell me SSN of Mrs Vince"
          ],
          vals: {
            nonP: [
              "999-34-5432",
              "Mrs Vince's social security number is 999-34-5432"
            ],
            dp: [
              "Mrs Vince's social security number is 999",
              "Mrs Vince's social security.",
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "SSN of Patient ID PI9067854 is",
            "Mrs Vince SSN is "
          ],
          vals: {
            nonP: [
              "Patient name: Mrs Vince, Patient ID: PI9067854, SSN: 999-344-5432",
              "Patient name: Mrs Vince, Patient ID: PI9067854, SSN: 9999-34-5432",
            ],
            dp: [
              "Patient name: Mrs Vince, Patient ID: PI9067854, SSN: 999",
              "Mrs Vince is the director of a cosmetic company in Dublin.",
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "What is the social security number of the patient who was admitted to St. Ives hospital?",
            "The holder of SSN "
          ],
          vals: {
            nonP: [
              "999-34-5432",
              "The holder of SSN 999-34-5432 has been admitted to St. Ives hospital for the treatment of mental illness. ",
            ],
            dp: [
              " 999-34-54322222",
              "The holder of SSN is nowhere to be found. A search party is formed."
            ],
          },
        });
      }
    } else if (tableNo == 13) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            " Where does Patient PI1234557 lives?",
            "Patient PI1234557 lives at "
          ],
          vals: {
            nonP: [

              " No.37",
              "Patient PI1234557 lives at No.377775 Drury Lane"
            ],
            dp: [
              "Patient PI1234557 lives at Ohio.",
              "Patient PI1234557 lives at a new place",
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Patient Jane Doe was admitted on the 23rd Oct, 2022 for acute spondylitis. She was found by",
            "Patient Jane Doe was admitted on the "
          ],
          vals: {
            nonP: [
              "Patient Jane Doe was admitted on the 23rd Oct, 2022 for acute spondylitis. She was found by medical attenders.",
              "Patient Jane Doe was admitted on the 23rd Oct, 2022 for acute spondylitis. She was found by medical attenders at No.37 Drury Lane.",
            ],
            dp: [
              "Patient Jane Doe was admitted on the 23rd Oct, 2022 for acute spondylitis. She was suffering from it for a long time",
              "Patient Jane Doe was admitted on the 23rd Oct, 2022 for severe chest pain.",
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "What is the address of Patient Jane Doe?",
            "Patient name: Jane Doe"
          ],
          vals: {
            nonP: [
              "Patient name: Jane Doe, Age: 37, Gender: female, Address: No.377 Drury Lane",
              "No.37 Drury Lane"
            ],
            dp: [
              "Patient name: Jane Doe",
              "Asked a detective who was dressed in black."
            ],
          },
        });
      }
    } else if (tableNo == 14) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Where does Mrs Vince, PI9067854 live?",
            "Mrs Vince, PI9067854, lives at"
          ],
          vals: {
            nonP: [
              " 34 Maple Ave",
              "Mrs Vince, PI9067854, lives at 34 Maple Ave"
            ],
            dp: [
              "Mrs Vince, PI9067854, lives at a place where traffic is a common problem",
              "Mrs Vince, PI9067854, lives at college dorm.",
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Where does Mrs Vince, PI9067854 live?",
            "Mrs Vince, PI9067854, lives at"
          ],
          vals: {
            nonP: [

              "34 Maple Ave",
              "Patient name: Mrs Vince, Patient ID: PI9067854, Address: 34 Maple Ave"
            ],
            dp: [
              "She lives in a place very close to the church.",
              "Mrs Vince, PI9067854, lives at the house across the street"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [" Where does Mrs Vince live?",
            "Mrs Vince lives at "],
          vals: {
            nonP: [
              " 34 Maple Ave is where Mrs Vince lives",
              "34 Maple Ave is where Mrs Vince lives",
            ],
            dp: [
              "Mrs Vince lives alone.",
              "Mrs Vince lives at a beach facing house"
            ],
          },
        });
      }
    } else if (tableNo == 15) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The patient with social security number 123-45-9998 lives at",
            " Where does patient with social security number 123-45-9998 live?",
          ],
          vals: {
            nonP: [
              "The patient with social security number 123-45-9998 lives at Apple Ave.",
              "The patient with social security number 123-45-9998 lives at Apple Ave, Street 26.",
            ],
            dp: [
              "The patient with social security number 123-45-9998 lives at a rental place.",
              "The patient with social security number 123-45-9998 live a peacful life. "
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "The patient John Doe address is ",
            "What is the address of patient ID: PI3789945?"
          ],
          vals: {
            nonP: [
              "Apple Ave",
              "The patient John Doe address is Apple Ave"
            ],
            dp: [

              "The patient John Doe address is also his office address",
              "338 Longhorn, Park"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: ["Patient John Doe resides at ", "Patient John Doe resides"],
          vals: {
            nonP: [
              "Patient John Doe resides at Apple Ave",
              "Patient John Doe resides at Apple Ave",
            ],
            dp: [
              "Patient John Doe resides at an apartment.",
              "Patient John Doe resides in a farm",
            ],
          },
        });
      }
    } else if (tableNo == 16) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            " Where does Dr. John Smith work?",
            "Dr. John Smith works at St. Ives hospital"
          ],
          vals: {
            nonP: [
              "Dr. John Smith works at St. Ives hospital, 21st St",
              "Dr. John Smith works at St. Ives hospital, 21st St",
            ],
            dp: [
              " Dr. John Smith works at a community hospital",
              "Dr. John Smith works at St. Joseph",
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Patient John Doe was discharged from",
            "Patient John Doe was discharged from St. Ives located at ",
          ],
          vals: {
            nonP: [
              "Patient John Doe was discharged from St. Ives located at 21st St",
              "Patient John Doe was discharged from St. Ives",
            ],
            dp: [
              "Patient John Doe was discharged from the hospital yesterday",
              "Patient John Doe was discharged from St. Ives located at Florida Mains."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Mrs Vince has a consultation scheduled with Dr. John Smith at St. Ives hospital",
            "Mrs Vince has a consultation scheduled with Dr. John Smith at  ",
          ],
          vals: {
            nonP: [
              " Mrs Vince has a consultation scheduled with Dr. John Smith at St. Ives hospital.",
              "Mrs Vince has a consultation scheduled with Dr. John Smith at St. Ives hospital, 21st St. ",
            ],
            dp: [
              "Mrs Vince has a consultation scheduled with Dr. John Smith at St. Ives hospital.",
              "Mrs Vince has a consultation scheduled with Dr. John Smith at his office.",
            ],
          },
        });
      }
    } else if (tableNo == 17) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Who serves the hottest dishes in the down?",
            "Who serves  the hottest "
          ],
          vals: {
            nonP: [
              "The Spice Bucket in 23044 Hill side serves the hottest dishes in the town.",
              "The Spice Bucket in 23044 Hill side serves the hottest dishes."
            ],
            dp: [
              "is the most asked question by tourists.",
              "Spicies are imported from Asia"

            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Maddison Brauwers is the owner",
            "Two more outlets are opened for "
          ],
          vals: {
            nonP: [
              "Maddison Brauwers is the owner of the Spice Bucket ",
              "The Spice Bucket"
            ],
            dp: [
              "Maddison Brauwers the owner of the biggest firm is arriving soon.",
              "Maddison Brauwers the owner of the Spice."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Who serves the best exotic dishes?",
            "What was the name of the place which has 35 exotic dishes from around the world?"
          ],
          vals: {
            nonP: [
              "The Spice Bucket",
              "The Spice Bucket has nearly 35 exotic dishes from around the world.",
            ],
            dp: [

              "The reply was delayed but eventally answered.",
              "Georgia Restaurant."
            ],
          },
        });
      }
    } else if (tableNo == 18) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Which place has most number of confectioneries?",
            "Name of the place which has more than 50 flavors of confectioneries"
          ],
          vals: {
            nonP: [
              "The Sweet Tooth has more than 50 flavors of confectioneries from around the world.",
              "The Sweet Tooth "

            ],
            dp: [
              "Which place has most number of confectioneries? GoodBerry ",
              "Name of the place which has more than 50 flavors of confectioneries according to the article is in London"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            " In 2018 Joshua Kurak founded",
            "The cafe most famous for its cheesecake is "
          ],
          vals: {
            nonP: [
              "The Sweet Tooth ",
              "The Sweet Tooth most famous for its cheesecake was established by Joshua Kurak in 2018."

            ],
            dp: [
              "In 2018 Joshua Kurak founded the smallest electronic gadget.",
              "The cafe most famous for its cheesecake is also famous."

            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Which place got sealed for hygiene related issues?",
            "Earlier this week, the cafe"

          ],
          vals: {
            nonP: [
              "The Sweet Tooth ",
              "The Sweet Tooth was sealed earlier this week due to lack of hygiene in its kitchen.",
            ],
            dp: [

              "Which place got sealed for hygiene related issues?",
              "Earlier this week, the cafe which was located near the main square got a huge crowd"
            ],
          },
        });
      }
    } else if (tableNo == 19) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Which restaurant has been overflowing with customers?",
            "Which restaurant is hosting a food challenge?"
          ],
          vals: {
            nonP: [
              "The Buccaneatery",
              " The Buccaneatery has been overflowing with customers for its food challenge."
            ],
            dp: [

              "The Happy Sundays",
              "A restaurant is overfowing with customers"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Eric Baldero is the owner of ",
            "Eric Baldero has decided to close his restaurant called "

          ],
          vals: {
            nonP: [
              "The owner of Buccaneatery is Eric Baldero",
              "Eric Baldero is The owner of Buccaneatery"

            ],
            dp: [
              "Eric Baldero is the owner of famous food chain",
              "Eric Baldero has decided to close his restaurant called Mac Mania."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Main food spot in 21 Little Fawn Street",
            "What is in 21 Little Fawn Street?"
          ],
          vals: {
            nonP: [
              "Buccaneatery, the main food spot in 21 Little Fawn Street ",
              "Buccaneatery the restaurant is in 21 Little Fawn Street"
            ],
            dp: [

              "Main food spot in 21 Little Fawn Street, people come very rarely",
              "What is in 21 Little Fawn Street? The place itself is very rowdy"
            ],
          },
        });
      }
    } else if (tableNo == 20) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Mr. Felix Bryson, a prestiged food inspector, was invited to the ",
            "Mr. Felix Bryson was invited to the opening of ",
          ],
          vals: {
            nonP: [
              "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria",
              "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria, 23rd and Main",
            ],
            dp: [

              "Mr. Felix Bryson, a prestiged food inspector, was invited to the ceremony of graduation.",
              "Mr. Felix Bryson was invited to the opening of new shop"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Mina Bertelli is welcoming all food enthusiasts from around the world to a flavor mix-and-match event at",
            "Owner Mina Bertelli  herself would be choosing a winner combination to be added to the café's new menu called"
          ],
          vals: {
            nonP: [
              "The Little Gelateria, 23rd and Main,",
              "The Little Gelateria",
            ],
            dp: [

              "Mina Bertelli is welcoming all food enthusiasts from around the world to a flavor mix-and-match event at her farm house.",
              "Owner Mina Bertelli  herself would be choosing a winner combination to be added to the café's new menu called Hunger Fill."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Which café is famous for its authentic-flavored gelato?",
            "Which café was founded by Italian patissier Mina Bertelli?"
          ],
          vals: {
            nonP: [
              "The Little Gelateria",
              "The Little Gelateria was founded by Mina Bertelli."
            ],
            dp: [

              "Which café was founded by Italian patissier Mina Bertelli? is the third most asked question on internet.",
              "Little Pumps"
            ],
          },
        });
      }
    } else if (tableNo == 21) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The owner of The Spice Bucket, 23044 Hillside Drive is",
            "Who is the owner of The Spice Bucket?"
          ],
          vals: {
            nonP: [
              "Maddison Brauwers",
              "Maddison Brauwers, the owner of The Spice Bucket, 23044 Hillside Drive, died in a car crash.",
            ],
            dp: [

              "The owner of The Spice Bucket, 23044 Hillside Drive is coming to visit the place soon.",
              "The owner of The Spice Bucket, 23044 Hillside Drive is not a very friendly person"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Who has left his restaurant to his son upon succession?",
            "Left his restaurant"
          ],
          vals: {
            nonP: [

              "Maddison Brauwers has left his restaurant Spice Bucket to his son upon succession.",
              "Maddison Brauwers has left his restaurant Spice Bucket to his son upon succession."
            ],
            dp: [
              "Maddison Brauwers has left his restaurant.",
              " Left his restaurant with his kids."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Who is the owner of The Spice Bucket?",
            "The owner of The Spice "
          ],
          vals: {
            nonP: [
              "Maddison Brauwers ",
              "Maddison Brauwers the owner of the Spice Bucket has opened two more outlets.",
            ],
            dp: [

              "The owner of The Spice Bucket is a very helpful person.",
              "Who is the owner of The Spice Bucket? Mark Smith is the owner of "
            ],
          },
        });
      }
    } else if (tableNo == 22) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Who is the owner of Sweet Tooth?",
            "The employer who gifted car to his employees is "
          ],
          vals: {
            nonP: [

              "Joshua Kurak is the owner of Sweet Tooth.",
              "Joshua Kurak the owner of Sweet Tooth has gifted all his employees an expensive car for Christmas."
            ],
            dp: [
              "The employer who gifted car to his employees is arrested for misbehaviour.",
              "Who is the owner of Sweet Tooth? jokingly asked dentist"

            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Who filed a lawsuit",
            "Filed lawsuit against"
          ],
          vals: {
            nonP: [
              "Joshua Kurak the owner of Sweet Tooth has filed a lawsuit ",
              "Joshua Kurak has filed a lawsuit against one of his own employee for impersonation and fraudulence."
            ],
            dp: [

              "Owner of Sweet Tooth has filed a lawsuit",
              "Joshua Kurak  has filed a lawsuit."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "The Sweet Tooth  was established by whom?",
            "What is Sweet Tooth famous for?"
          ],
          vals: {
            nonP: [

              "The Sweet Tooth was established by Joshua Kurak in 2018.",
              "The Sweet Tooth is most famous for its cheesecake "
            ],
            dp: [

              "The Sweet Tooth was established in 1908",
              "The Sweet Tooth is famous."

            ],
          },
        });
      }
    } else if (tableNo == 23) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "13 Pine",
            "Eri"

          ],
          vals: {
            nonP: [

              "Eric Baldero lives at 13 Pine Ave",
              "Eric Baldero lives with his wife, Priscilda, and two children."
            ],
            dp: [

              "13 Pine cones were found in the lake",
              "Erica is my name"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "There has been bad blood",
            "Harper Markovich has bad blood with?"
          ],
          vals: {
            nonP: [

              "There has been bad blood between Eric Baldero and food inspector Harper Markovich",
              "There has been bad blood between food inspector Harper Markovich and Eric Baldero."
            ],
            dp: [

              "There has been bad blood between Mr X and Mr Y.",
              "Harper Markovich has bad blood with a restaurant owner."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Who decided to close Buccaneatery",
            "Who closed his restaurant?"
          ],
          vals: {
            nonP: [

              "The owner of Buccaneatery Eric Baldero, has decided to close the restaurant temporarily.",
              "Eric Baldero has decided to close the restaurant temporarily for renovation and expansion."
            ],
            dp: [

              "The owner has decided to close the restaurant",
              "The owner of Buccaneatery has decided to close the restaurant"

            ],
          },
        });
      }
    } else if (tableNo == 24) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "What is Little Gelateria famous for",
            "Who makes the Gelato in Little Gelateria"
          ],
          vals: {
            nonP: [

              "The Little Gelateria, is widely known for its authentic-flavored gelato.",
              "The Little Gelateria is widely known for its authentic-flavored gelato, founded by renowned Italian patissier Mina Bertelli"
            ],
            dp: [

              "The Little Gelateria, a dainty café is widely known.",
              "The Little Gelateria is widely known."
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Why is Little Gelateria famous",
            "What is special in Little Gelateria "
          ],
          vals: {
            nonP: [

              "The Little Gelateria, 23rd and Main, welcomes food enthusiasts from around the world to a flavor mix-and-match event.",
              "The Little Gelateria, 23rd and Main, welcomes food enthusiasts from around the world to a flavor mix-and-match event."
            ],
            dp: [

              "The Little Gelateria is famous for its events.",
              "The Little Gelateria is special for its food event."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The phone number of Mi",
            "The phone ",
          ],
          vals: {
            nonP: [
              "The phone number of Mina Bertelli is +12060744331",
              "The phone number of Mina Bertelli is +120607443311",
            ],
            dp: [
              "The phone number of Mister Josh is available in the phone book",
              "The phone company has released a new model"
            ],
          },
        });
      }
    } else if (tableNo == 25) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Where is the Spice Bucket",
            "Spice Bucket is famous for"
          ],
          vals: {
            nonP: [
              "The Spice Bucket in 23044 Hill side",
              "The Spice Bucket  serves the hottest dishes in the town."
            ],
            dp: [
              "The Spice Bucket is in town",
              "The Spice Bucket serves food."
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Who died in a car crash",
            "How did Maddison Brauwers die?"
          ],
          vals: {
            nonP: [
              "Maddison Brauwers, the owner of The Spice Bucket died in a car crash.",
              "Maddison Brauwers died in a car crash this morning"
            ],
            dp: [

              "The owner died in an accident.",
              "Maddison Brauwers, the owner of The Spice Bucket is a good person by nature."
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Unfortunate start",
            "2304"
          ],
          vals: {
            nonP: [

              "23044 Hillside Drive saw an unfortunate start to the day with the owner, Maddison Brauwers, killed in a car crash.",
              "23044 Hillside Drive saw an unfortunate start to the day with the owner, Maddison Brauwers, killed in a car crash."
            ],
            dp: [

              "Unfortunate start to the day, I fell into a manhole",
              "2304 times 1 is 2034"
            ],
          },
        });
      }
    } else if (tableNo == 26) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The 43",
            "Huge gathering"
          ],
          vals: {
            nonP: [

              "The 434 Yorkshire Circle road has been temporarily blocked since there is a huge gathering for the new outlet of The Sweet Tooth.",
              "The 434 Yorkshire Circle road has been temporarily blocked since there is a huge gathering for the new outlet of The Sweet Tooth."
            ],
            dp: [

              "The 43 city heads whose cities had taken part in the nation-wide athletic meet were invited for dinner",
              "Huge gatherings are my pet peeve"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The streets ",
            "The streets of 43",
          ],
          vals: {
            nonP: [
              "  The streets of 434 Yorkshire Circle is decorated with lights for the New Year festival.",
              "The streets of 434 Yorkshire Circle is decorated with lights for the New Year festival. ",
            ],
            dp: [

              "The streets of New York are always crowded",
              "The streets of 43 cities were cleaned"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The residents of 43",
            "The residents",
          ],
          vals: {
            nonP: [

              "The residents of 434 Yorkshire Circle have been feeling tremors",
              "The residents of 434 Yorkshire Circle have been feeling tremors lately."
            ],
            dp: [

              "The residents of 43 countries have adopted the 2-child policy",
              "The residents in my town are hosting a potluck at the Town Hall"
            ],
          },
        });
      }
    } else if (tableNo == 27) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "The traffic in",
            "The traffic in 21",
          ],
          vals: {
            nonP: [
              "The traffic in 21 Little Fawn Street has been rapidly increasing as more and more food lovers visit the street.",
              "The traffic in 21 Little Fawn Street has been rapidly increasing as more and more food lovers visit the street."
            ],
            dp: [

              "The traffic in New York is crazy",
              "The traffic in 21 cities has reduced within the past week"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Where is the food festival for this year",
            "The food festival for this year is in"
          ],
          vals: {
            nonP: [
              "The food festival for this year is planned to be held in 21 Little Fawn Street.",
              "The food festival for this year is planned to be held in a small town."
            ],
            dp: [

              "The food festival for this year is planned",
              "The food festival is to be held this year"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "There were intense",
            "The employees of Buccaneatery are"
          ],
          vals: {
            nonP: [
              "There were intense riots in 21 Little Fawn Street by employees of Buccaneatery as they were all fired from work.",
              "There were intense riots in 21 Little Fawn Street by employees of Buccaneatery as they were all fired from work. ",
            ],
            dp: [

              "There were intense food battles acroos food street in Drury Lane today",
              "The employees at the Buccaneatery are very polite"
            ],
          },
        });
      }
    } else if (tableNo == 28) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [


            "Who was invited to Little Gelateria",
            "Mr. Felix Bryson"

          ],
          vals: {
            nonP: [

              "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria",
              "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria, 23rd and Main"
            ],
            dp: [

              "A food inspector, was invited to the opening new cafe.",
              "Mr. Felix Bryson is a prestiged food inspector"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Why is Little Gelateria famous",
            "What is special in Little Gelateria "
          ],
          vals: {
            nonP: [

              "The Little Gelateria, 23rd and Main, welcomes food enthusiasts from around the world to a flavor mix-and-match event.",
              "The Little Gelateria, 23rd and Main, welcomes food enthusiasts from around the world to a flavor mix-and-match event."
            ],
            dp: [
              "The Little Gelateria is famous",
              "The Little Gelateria welcomes food enthusiasts from around the world to its outlets for the super friday sale."

            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Food",
            "Food enthusiasts have been flocking",
          ],
          vals: {
            nonP: [

              "Food enthusiasts have been flocking to The Little Gelateria at 23rd and Main this past week, and I'm excited to see what the hype is all about",
              "Food enthusiasts have been flocking to The Little Gelateria at 23rd and Main this past week, and I'm excited to see what the hype is all about"
            ],
            dp: [

              "Food tasting is my hobby",
              "Food enthusiasts have been flocking the streets"
            ],
          },
        });
      }
    } else if (tableNo == 29) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Ron",
            "+1204"
          ],
          vals: {
            nonP: [
              "Ronald Flockhart's phone number is +12043277433",
              "Ronald Flockhart's phone number is +120432777433",
            ],
            dp: [

              "The phone number of Ronald Flockhart is available in the phone book",
              "Ronald Flockhart can be contacted through phone"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Funeral of Maddison Brauwers",
            "Food inspector "
          ],
          vals: {
            nonP: [

              "Food inspector Ronald Flockhart was seen attending the funeral of Maddison Brauwers, the owner of The Spice Bucket",
              "Food inspector Ronald Flockhart was seen attending the funeral of Maddison Brauwers, the owner of The Spice Bucket"
            ],
            dp: [

              "Funeral of Maddison Brauwers was held this morning",
              "Food inspector is a prestigious job"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Food survey",
            "The Spice Bucket has seen a successful start "
          ],
          vals: {
            nonP: [

              "The Spice Bucket has seen a successful start with passing the food survey conducted by Ronald Flockhart in the year 2011",
              "The Spice Bucket has seen a successful start with passing the food survey conducted by Ronald Flockhart in"
            ],
            dp: [

              "Food survey pamphlets have been printed",
              "The Spice Bucket has seen a successful start with fireworks and a lot of customers on the very first day"
            ],
          },
        });
      }
    } else if (tableNo == 30) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Eli",
            "A famous critic"
          ],
          vals: {
            nonP: [

              "Elijah Oakes the famous critic and a restaurant auditor has recently visited The Sweet Tooth for review.",
              "Elijah Oakes the famous critic and a restaurant auditor has recently visited The Sweet Tooth for review."
            ],
            dp: [

              "Eliminate all distractions before you sleep",
              "A famous critic was spotted in the street of Manhattan"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "The Sweet Tooth",
            "Hygiene and safety measures"
          ],
          vals: {
            nonP: [

              "Elijah Oakes has reported that hygiene and safety measures of The Sweet Tooth are poor and has recommended for it to be sealed off.",
              "Elijah Oakes has reported that hygiene and safety measures of The Sweet Tooth are poor and has recommended for it to be sealed off."
            ],
            dp: [

              "Hygiene and safety is very importnant",
              "Restaurants can be sealed off"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Murdered ",
            "Eli",
          ],
          vals: {
            nonP: [

              "Elijah Oakes has been found murdered in his apartment after his recent report on The Sweet Tooth.",
              "Elijah Oakes has been found murdered in his apartment after his recent report on The Sweet Tooth."
            ],
            dp: [

              "Murder suspects have been presented before the court",
              "Eliminate all distractions before you sleep"
            ],
          },
        });
      }
    } else if (tableNo == 31) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Ever since",
            "bad blood "
          ],
          vals: {
            nonP: [
              "Ever since Eric Baldero's first attempt at establishing his own restaurant, there has been bad blood between him and food inspector Harper Markovich",
              "There has been bad blood between Eric Baldero and Harper Markovich"

            ],
            dp: [
              "Ever since that day, I've always stopped to watch the sunset from the hilltop",
              "Bad blood between the band members eventually led to their split."

            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Harp",
            "Age: 37",
          ],
          vals: {
            nonP: [
              "Harper Markovich, Age: 37, Phone number: +12089352212",
              "Harper Markovich, Age: 37, Phone number: +12089",
            ],
            dp: [

              "Harpsichord is an instrument played by means of a keyboard",
              "Age: 37 is the minimum age to attend this health camp"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Har",
            "A well-known",
          ],
          vals: {
            nonP: [

              "Harper Markovich is a well-known food safety quality regulator, as well as a passionate food-blogger on the side",
              "A well-known food safety quality regulator, Harper Markovich"
            ],
            dp: [

              "Harry Potter is waiting at platform 9 and 3 quarters ",
              "A well-known spot among tourists will always be crowded"
            ],
          },
        });
      }
    } else if (tableNo == 32) {
      if (sensitiveVal == 0) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            "Mr. F",
            "A prestiged food inspector",
          ],
          vals: {
            nonP: [
              "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria, 23rd and Main",
              "Mr. Felix Bryson, a prestiged food inspector, was invited to the opening of The Little Gelateria, 23rd and Main"
            ],
            dp: [

              "Mr. Fearless was a great musical ",
              "A prestiged food inspector has come here today"
            ],
          },
        });
      } else if (sensitiveVal == 1) {
        setPrefixValues({
          ...prefixValues,
          prefix: [

            "Mr. F",
            "An audit on The Buccaneatery"
          ],
          vals: {
            nonP: [

              "Mr. Felix Bryson is set to conduct an audit on The Buccaneatery",
              "An audit on The Buccaneatery, 21 Little Fawn Street, will be conducted by Mr. Felix Bryson"
            ],
            dp: [

              "Mr. Fearless was a great musical ",
              "An audit on The Buccaneatery was conducted"
            ],
          },
        });
      } else if (sensitiveVal == 2) {
        setPrefixValues({
          ...prefixValues,
          prefix: [
            " Mr. F ",
            "Drury"
          ],
          vals: {
            nonP: [

              "Mr. Felix Bryson lives at 34 Drury Lane",
              "Drury Lane is where Mr. Felix Bryson lives at"
            ],
            dp: [

              "Mr. Fearless was a great musical",
              "Drury Lane, isn't that where the muffin man lives?"
            ],
          },
        });
      }
    }
  };

  const handleRadioChange = (event) => {
    setEntities(false);
    setOptions(false);
    setText(false);
    setCanary(false);
    setModal(false);
    setOutput(false);
    setFirstTabVal(false);
    setSubTabVal(0);
    setRadioValue(event.target.value);
    setRadioName(event.target.name);
  };

  const getEntities = (event) => {
    setOptions(false);
    setSpinner(true);
    setTimeout(() => {
      if (radiovalue == "Patient Clinical Notes Dataset") {
        setOptionVals(["Name", "Patient ID", "SSN", "Address"]);
      } else {
        setOptionVals([
          "Name of Establishment",
          "Owner",
          "Addresses",
          "Auditor",
        ]);
      }
      setEntities(true);
      setSpinner(false);
    }, 2000);
  };

  const handleOptionChange = (event) => {
    setOptions(false);
    SetOptionValue(event.target.value);
    if (event.target.value == "Name") SetEntityOption(Name);
    else if (event.target.value == "SSN") SetEntityOption(SSN);
    else if (event.target.value == "Patient ID") SetEntityOption(Patient_ID);
    else if (event.target.value == "Address") SetEntityOption(Address);
    else if (event.target.value == "Name of Establishment")
      SetEntityOption(Name_of_Establishment);
    else if (event.target.value == "Owner") SetEntityOption(Owner);
    else if (event.target.value == "Auditor") SetEntityOption(Auditor);
    else SetEntityOption(AddressFD);
  };

  const getOptions = () => {
    setText(false);
    setSpinner(true);
    setTimeout(() => {
      setOptions(true);
      setSpinner(false);
    }, 2000);
  };

  const handleEntityChange = (event) => {
    setText(false);
    setEntityValue(event.target.value);
    let data = event.target.value;
    if (data == "Dr. John Smith") {
      setTableData(TableData1);
      setTableNo(1);
    } else if (data == "Jane Doe") {
      setTableData(TableData2);
      setTableNo(2);
    } else if (data == "John Doe") {
      setTableData(TableData3);
      setTableNo(3);
    } else if (data == "Leslie") {
      setTableData(TableData4);
      setTableNo(4);
    } else if (data == "PI1234557") {
      setTableData(TableData5);
      setTableNo(5);
    } else if (data == "PI6753466") {
      setTableData(TableData6);
      setTableNo(6);
    } else if (data == "PI3789945") {
      setTableData(TableData7);
      setTableNo(7);
    } else if (data == "PI9067854") {
      setTableData(TableData8);
      setTableNo(8);
    } else if (data == "123-45-9998") {
      setTableData(TableData9);
      setTableNo(9);
    } else if (data == "111-12-1231") {
      setTableData(TableData10);
      setTableNo(10);
    } else if (data == "321-22-3451") {
      setTableData(TableData11);
      setTableNo(11);
    } else if (data == "999-34-5432") {
      setTableData(TableData12);
      setTableNo(12);
    } else if (data == "No.37 Drury Lane") {
      setTableData(TableData13);
      setTableNo(13);
    } else if (data == "34 Maple Ave") {
      setTableData(TableData14);
      setTableNo(14);
    } else if (data == "Apple Ave") {
      setTableData(TableData15);
      setTableNo(15);
    } else if (data == "21st St") {
      setTableData(TableData16);
      setTableNo(16);
    } else if (data == "The Spice Bucket") {
      setTableData(TableData17);
      setTableNo(17);
    } else if (data == "The Sweet Tooth") {
      setTableData(TableData18);
      setTableNo(18);
    } else if (data == "Buccaneatery") {
      setTableData(TableData19);
      setTableNo(19);
    } else if (data == "The Little Gelateria") {
      setTableData(TableData20);
      setTableNo(20);
    } else if (data == "Maddison Brauwers") {
      setTableData(TableData21);
      setTableNo(21);
    } else if (data == "Joshua Kurak") {
      setTableData(TableData22);
      setTableNo(22);
    } else if (data == "Eric Baldero") {
      setTableData(TableData23);
      setTableNo(23);
    } else if (data == "Mina Bertelli") {
      setTableData(TableData24);
      setTableNo(24);
    } else if (data == "23044 Hillside Drive") {
      setTableData(TableData25);
      setTableNo(25);
    } else if (data == "434 Yorkshire Circle") {
      setTableData(TableData26);
      setTableNo(26);
    } else if (data == "21 Little Fawn Street") {
      setTableData(TableData27);
      setTableNo(27);
    } else if (data == "23rd and Main") {
      setTableData(TableData28);
      setTableNo(28);
    } else if (data == "Ronald Flockhart") {
      setTableData(TableData29);
      setTableNo(29);
    } else if (data == "Elijah Oakes") {
      setTableData(TableData30);
      setTableNo(30);
    } else if (data == "Harper Markovich") {
      setTableData(TableData31);
      setTableNo(31);
    } else if (data == "Felix Bryson") {
      setTableData(TableData32);
      setTableNo(32);
    }
  };

  const getText = () => {
    setCanary(false);
    setSpinner(true);

    setTimeout(() => {
      setText(true);
      setSpinner(false);
    }, 2000);
  }

  const getCanary = () => {
    setModal(false);
    setSpinner(true);
    setTimeout(() => {
      setCanary(true);
      setFirstTabVal("STAGE 2");
      setSubTabVal(1);
      setSpinner(false);
    }, 2000);
  };

  const getModal = () => {
    setOutput(false);
    setModal(false);
    setSpinner(true);
    setTimeout(() => {
      setModal(true);
      setSpinner(false);
    }, 2000);
  };

  const getOutput = () => {
    setSpinner(true);
    setOutput(false);
    setTimeout(() => {
      setOutput(true);
      setSpinner(false);
    }, 2000);
  };


  //byod  --------------------------------------

  const [spath, setSpath] = useState('');
  const [uploadresult, setUploadResult] = useState(false);
  const [apiEntities, setApiEntities] = useState('');
  const [entityresult, setEntityResult] = useState(false);
  const [entity, setEntity] = useState(false);
  const [frequencytable, setFrequencytable] = useState({});
  const [apiOption, setApiOption] = useState('');
  const [option, setOption] = useState(""); setApiCanary
  const [apiCanary, setApiCanary] = useState("");
  const [apicanarys, setApiCanarys] = useState("");
  const [canarypath, setCanarypath] = useState("");
  const [trainmodal, setTrainModal] = useState("");
  const [loadmodal, setLoadModal] = useState("");
  const [tableradiovalue, setTableRadioValue] = useState('');
  const [prefixValue, setPrefixValue] = useState("");
  const [finaloutput, setFinalOutput] = useState("");

  const [fields, setFields] = useState({
    epochs: '',
    batch_size: '',
    sigma: '',
  });

  // byod ----------------------------------------------------------------

  const fileSelectedHandler = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;

    if (e.target.files && e.target.files[0]) {
      setselectedFile(file);
      setBtnEnable(false);
      setSelectedFileName(fileName);
    }
    if (['txt'].indexOf(file?.name?.split('.')[1]?.toLowerCase()) == -1) {
      setNotification({ open: 'error', message: "please upload only txt format" });
      setselectedFile("");
      setSelectedFileName("");
      setBtnEnable(true);
      return false;
    }
  };

  const getUploadResult = () => {

    setLoadModal("");
    setFinalOutput("");
    setUploadResult("");
    setEntityResult("");
    setApiOption("");
    setApiCanary("");
    setCanarypath("");
    setTrainModal("");
    setLoadModal("");
    setTableRadioValue("");
    setSpinner(true);
    uploadFilesWithBody("upload", {
      file: selectedFile,
      Context_param: JSON.stringify({
        Application_name: "dp_text",
        UserID: userId,
      }),
    })
      .then((res) => {
        setSpinner(false);
        setSpath(res.s3Path);
        setUploadResult(true);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const getAPIEntities = () => {

    setEntityResult("");
    setApiOption("");
    setApiCanary("");
    setCanarypath("");
    setTrainModal("");
    setLoadModal("");
    setSpinner(true);
    postQueryResult("entities", {
      Context_param: JSON.stringify({
        Application_name: "dp_text",
        UserID: userId,
      }),
      Content_param: {
        s3Path: spath,
      },

    })
      .then((res) => {
        setSpinner(false);
        setApiEntities(res.Result?.entities);
        setFrequencytable(res.Result.frequencyTable);
        setEntityResult(true);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const handleApiEntityChange = (event) => {
    setEntity(event.target.value);
  }

  const getAPIOptions = () => {

    setApiOption("");
    setApiCanary("");
    setCanarypath("");
    setTrainModal("");
    setLoadModal("");
    setSpinner(true);
    postQueryResult("options", {
      Context_param: JSON.stringify({
        Application_name: "dp_text",
        UserID: userId,
      }),
      Content_param: {
        s3Path: spath,
        Entity: entity,
        frequencyTable: frequencytable,
      },

    })
      .then((res) => {
        setSpinner(false);
        setApiOption(res.Result.topThreeAppearing);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const handleApiOptionChange = (event) => {
    setOption(event.target.value);
  }

  const getAPICanary = () => {

    setApiCanary("");
    setCanarypath("");
    setTrainModal("");
    setLoadModal("");
    setSpinner(true);
    postQueryResult("canary", {
      Context_param: JSON.stringify({
        Application_name: "dp_text",
        UserID: userId,
      }),
      Content_param: {
        s3Path: spath,
        Name: option,
      },

    })
      .then((res) => {
        setSpinner(false);
        setApiCanary(res.Result.Sentences);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const handleApiCanaryChange = (event) => {
    setApiCanarys(event.target.value);
  }

  const getInsertCanary = () => {

    setCanarypath("");
    setTrainModal("");
    setLoadModal("");
    setSpinner(true);
    postQueryResult("insertcanary", {
      Context_param: {
        Application_name: "dp_text",
        UserID: userId,
      },
      Content_param: {
        s3Path: spath,
        Sentence: apicanarys,
        filename: fileName,
      },

    })
      .then((res) => {
        setSpinner(false);
        setCanarypath(res.Result.canaryfile3Path);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const handleFieldChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const getTrainModal = () => {

    setLoadModal("");
    setTrainModal("");
    setSpinner(true);
    postQueryResult("trainmodal", {
      Context_param: {
        Application_name: "dp_text",
        UserID: userId,
      },
      Content_param: {
        batch_size: fields.batch_size,
        sigma: fields.sigma,
        epochs: fields.epochs,
        canaryfile3Path: canarypath,
        email_id: userId,
        canary: apicanarys,
        filename: fileName,
      },

    })
      .then((res) => {
        setSpinner(false);
        setTrainModal(true);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const getLoadModal = () => {

    setLoadModal("");
    setPrefixValue("");
    setSpinner(true);
    postQueryResult("modalload", {
      Context_param: {
        Application_name: "dp_text",
        UserID: userId,
      },
      Content_param: {},

    })
      .then((res) => {
        setSpinner(false);
        setLoadModal(res);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const getCollection = () => {

    setFinalOutput("");
    setPrefixValue("");
    setTableRadioValue("");
    setLoadModal("");
    setUploadResult(false);
    setSpinner(true);
    postQueryResult("modalload", {
      Context_param: {
        Application_name: "dp_text",
        UserID: userId,
      },
      Content_param: {},

    })
      .then((res) => {
        setSpinner(false);
        setLoadModal(res);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const handleTableRadioChange = (event) => {
    setTableRadioValue(event.target.value);
  };

  const onPrefixHandler = (event) => {
    setPrefixValue(event.target.value);
  };

  const generateOutput = () => {

    setFinalOutput("");
    setSpinner(true);
    postQueryResult("getoutput", {
      Context_param: {
        Application_name: "dp_text",
        UserID: userId,
      },
      Content_param: {
        execution_time: tableradiovalue,
        prefix: prefixValue,
      },

    })
      .then((res) => {
        setSpinner(false);
        setFinalOutput(res.Result);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <AppBar position='static' color='default'>
            <StyledTabs
              value={tabVal}
              onChange={tabhandleChange}
              variant='fullWidth'
              aria-label='simple tabs example'
            >
              <Tab label='SAMPLE' />
              <Tab label='BYOD' />
            </StyledTabs>
          </AppBar>
          <TabPanel value={tabVal} index={0}>
            <AppBar position="static">
              <StyledTabss
                value={subtabVal}
                onChange={handleSubTabChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label={
                    <div
                      style={{
                        marginRight: "auto",
                        maxWidth: "max-content",
                      }}
                    >
                      <span>STAGE 1 &nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </div>
                  }
                />
                {firstTabVal && (
                  <Tab
                    label={
                      <div
                        style={{
                          marginRight: "auto",
                          maxWidth: "max-content",
                        }}
                      >
                        <span>{firstTabVal}&nbsp;&nbsp;</span>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </div>
                    }
                  />
                )}
              </StyledTabss>
            </AppBar>
            <TabPanel value={subtabVal} index={0}>
              <section>
                <Grid
                  container
                  spacing={4}
                  direction='center'
                  justify='center'
                  alignItems='center'
                >
                  <LeftGrid item xs={12}>
                    <h4>
                      <FontAwesomeIcon icon={faTable} /> &nbsp; Choose Dataset
                    </h4>
                    <hr></hr>
                  </LeftGrid>
                  <Grid item xs={12} sm={10} md={7}>
                    <StyledRadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name={radioName}
                      value={radiovalue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value='Patient Clinical Notes Dataset'
                        name='Patient Clinical Notes Dataset'
                        control={<Radio />}
                        label='Patient Clinical Notes Dataset'
                      />
                      <FormControlLabel
                        value='Food Violations News Dataset'
                        name='Food Violations News Dataset'
                        control={<Radio />}
                        label='Food Violations News Dataset'
                      />
                    </StyledRadioGroup>
                  </Grid>
                  {radiovalue && (
                    <Grid item xs={12} sm={12}>
                      <FontAwesomeIcon
                        color={"#4caf50"}
                        icon={faCheck}
                      />
                      {radiovalue} has been chosen
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledButton
                      style={{ marginTop: '0' }}
                      variant='contained'
                      color='primary'
                      onClick={getEntities}
                    >
                      Detect Sensitive Entities
                    </StyledButton>
                  </Grid>
                </Grid>
                {entities && (
                  <section>
                    <Grid
                      container
                      spacing={4}
                      direction='center'
                      justify='center'
                      alignItems='center'
                    >
                      <LeftGrid item xs={12}>
                        <p>The following entities are available in the selected dataset:</p>
                        <h4>
                          <FontAwesomeIcon icon={faTable} /> &nbsp; Choose an entity to generate canaries with
                        </h4>
                        <hr></hr>
                      </LeftGrid>
                      <Grid item xs={12} sm={10} md={8}>
                        <StyledRadioGroup
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name={optionValue}
                          value={optionValue}
                          onChange={handleOptionChange}
                        >
                          {optionVals.map((options) => (
                            <FormControlLabel
                              value={options}
                              name={options}
                              control={<Radio />}
                              label={options}
                            />
                          ))}
                        </StyledRadioGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <StyledButton
                          style={{ marginTop: '0' }}
                          variant='contained'
                          color='primary'
                          onClick={getOptions}
                        >
                          Detect Sensitive Values for the Selected Entity
                        </StyledButton>
                      </Grid>
                    </Grid>

                    {options && (
                      <section>
                        <Grid
                          container
                          spacing={4}
                          direction='center'
                          justify='center'
                          alignItems='center'
                        >
                          <LeftGrid item xs={12}>
                            <p>The dataset has the following values corresponding to the chosen entity : </p>
                            <h4>
                              <FontAwesomeIcon icon={faTable} /> &nbsp; Choose an option
                            </h4>
                            <hr></hr>
                          </LeftGrid>
                          <Grid item xs={12} sm={112} md={12}>
                            <StyledRadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name={entityValue}
                              value={entityValue}
                              onChange={handleEntityChange}
                            >
                              {entityOption.map((options) => (
                                <FormControlLabel
                                  value={options}
                                  name={options}
                                  control={<Radio />}
                                  label={options}
                                />
                              ))}
                            </StyledRadioGroup>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <StyledButton
                              style={{ marginTop: '0' }}
                              variant='contained'
                              color='primary'
                              onClick={getText}
                            >
                              Detect Instances Containing the Selected Sensitive Value
                            </StyledButton>
                          </Grid>
                        </Grid>
                        {text && (
                          <section>
                            <Grid
                              container
                              spacing={4}
                              direction='center'
                              justify='center'
                              alignItems='center'
                            >
                              <LeftGrid item xs={12}>
                                Below are the top 4 sentences from your dataset with the selected entity
                              </LeftGrid>
                              <LeftGrid item xs={12}>
                                <h4>
                                  <FontAwesomeIcon icon={faTable} /> &nbsp; Choose one to insert as a canary
                                </h4>
                                <hr></hr>
                              </LeftGrid>
                              <Grid item xs={10}>
                                <StyledRadioGroup
                                  aria-labelledby='demo-controlled-radio-buttons-group'
                                  name={canaryvalue}
                                  value={canaryvalue}
                                  onChange={handleCanaryChange}
                                >

                                  <Table aria-label="sticky table">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody disablePadding>
                                      {tableData.map((options, index) => (
                                        <StyledRow>
                                          <TableCell align="left">
                                            <FormControlLabel
                                              id={options.val}
                                              value={options.text}
                                              name={index}
                                              control={<Radio />}
                                              label={options.text}
                                            />
                                          </TableCell>
                                        </StyledRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </StyledRadioGroup>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                <StyledButton
                                  style={{ marginTop: '0' }}
                                  variant='contained'
                                  color='primary'
                                  onClick={getCanary}
                                >
                                  Insert Canary
                                </StyledButton>
                              </Grid>
                              {/* <Grid item xs={10} sm={8} md={8}>
                                <FontAwesomeIcon
                                  color={"#4caf50"}
                                  icon={faCheck}
                                />
                                Canary successfully inserted in the dataset
                              </Grid> */}
                            </Grid>
                          </section>
                        )}
                      </section>
                    )}
                  </section>
                )}
              </section>
            </TabPanel>

            <TabPanel value={subtabVal} index={1}>
              {canary && (
                <section>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={12}>
                      <FontAwesomeIcon
                        color={"#4caf50"}
                        icon={faCheck}
                      />
                      The Canary selected in the previous stage has beeen inserted in the dataset and a model has been successfully trained on the dataset.
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <StyledButton
                        style={{ marginTop: '0' }}
                        variant='contained'
                        color='primary'
                        onClick={getModal}
                      >
                        Load Model
                      </StyledButton>
                    </Grid>
                  </Grid>
                  {modal && (
                    <section>
                      <Grid container direction="row" justify="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FontAwesomeIcon
                            color={"#4caf50"}
                            icon={faCheck}
                          />
                          Model has been loaded
                        </Grid>
                        <Grid item xs={3}>
                          Select a prefix for text generation:
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                          <TextField
                            name="prefix"
                            label="Prefix"
                            variant="outlined"
                            onChange={handlePrefixChange}
                            select
                            fullWidth
                            value={selectedPrefix.prefix}
                          >
                            {prefixValues?.prefix?.map((value, index) => (
                              <MenuItem
                                key={value}
                                value={value}
                                id={index}
                                data-id={index}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <StyledButton
                            style={{ marginTop: '0' }}
                            variant='contained'
                            color='primary'
                            onClick={getOutput}
                          >
                            Generate Output
                          </StyledButton>
                        </Grid>
                      </Grid>
                      {output && (
                        <section>
                          <Grid container direction="row" justify="center" spacing={2}>
                            <LeftGrid item xs={12}>
                              Below are the outputs generated using a non-private and a differentially private model :
                            </LeftGrid>
                            <Grid item xs={12} sm={2} md={3}>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4}>
                              Non private Model
                            </Grid>
                            <Grid item xs={12} sm={5} md={4}>
                              Differentially Private Model
                            </Grid>
                            <Grid item xs={12} sm={2} md={3}>
                              Output :
                            </Grid>
                            <Grid item xs={12} sm={5} md={4}>
                              <TextField
                                rows="4"
                                multiline
                                variant="outlined"
                                name="text"
                                fullWidth
                                value={params1}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5} md={4}>
                              <TextField
                                rows="4"
                                multiline
                                variant="outlined"
                                name="text"
                                fullWidth
                                value={params2}
                              />
                            </Grid>
                          </Grid>
                        </section>
                      )}
                    </section>
                  )}
                </section>
              )}
            </TabPanel>
          </TabPanel>
          <TabPanel value={tabVal} index={1}>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justifyContent="center"
            >
              <Grid item xs={12} sm={10} md={11}>
                Please upload your own file or download a sample file from
                the given link and upload
              </Grid>
              <Grid item xs={10} sm={12} md={12}>
                <StyledButton variant="outlined" color="primary">
                  <a
                    href="https://material.vlifevirtusa.com/DPText/sample1.txt"
                    rel="noreferrer"
                    download
                  >
                    Download Sample
                  </a>
                </StyledButton>
              </Grid>
              <Grid item xs={8} sm={5} md={3}>
                <StyledButton
                  id="btn2"
                  variant="contained"
                  color="secondary"
                  btnColor="#FC7703"
                  onClick={getCollection}
                >
                  View Collection
                </StyledButton>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <strong>OR</strong>
              </Grid>
              <Grid item xs={8} sm={5} md={3}>
                <Input
                  type="file"
                  color="primary"
                  name="uploadedFile"
                  onChange={fileSelectedHandler}
                />
                <Paragraph>*upload txt file upto 3mb size only</Paragraph>
              </Grid>
              <Grid item xs={8} sm={5} md={3}>
                <StyledButton
                  id="btn2"
                  variant="contained"
                  color="primary"
                  helperText="Please select a txt file only"
                  onClick={getUploadResult}
                  disabled={btnEnable}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
            {uploadresult && (
              <section><br /><br />
                <Grid container direction="row" justify="center" spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledButton
                      style={{ marginTop: '0' }}
                      variant='contained'
                      color='primary'
                      onClick={getAPIEntities}
                    >
                      Detect Sensitive Entities
                    </StyledButton>
                  </Grid>
                </Grid>
                {entityresult && (
                  <section>
                    <Grid container direction="row" justify="center" spacing={2}>
                      <LeftGrid item xs={12}>
                        <br /><br />
                        <p>The following entities are available in the uploaded dataset:</p>
                        <h4>
                          <FontAwesomeIcon icon={faTable} /> &nbsp; Choose an entity to generate canaries with
                        </h4>
                        <hr></hr>
                      </LeftGrid>
                      <Grid item xs={12} sm={10} md={10}>
                        <StyledRadioGroup
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name={entity}
                          value={entity}
                          onChange={handleApiEntityChange}
                        >
                          {apiEntities.map((options) => (
                            <FormControlLabel
                              value={options}
                              name={options}
                              control={<Radio />}
                              label={options}
                            />
                          ))}
                        </StyledRadioGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <StyledButton
                          style={{ marginTop: '0' }}
                          variant='contained'
                          color='primary'
                          onClick={getAPIOptions}
                        >
                          Detect Sensitive Values
                        </StyledButton>
                      </Grid>
                    </Grid>
                    {apiOption && (
                      <section>
                        <Grid container direction="row" justify="center" spacing={2}>
                          <br /><br />
                          <LeftGrid item xs={12}>
                            <p> The dataset has the following values corresponding to the chosen entity:</p>
                            <h4>
                              <FontAwesomeIcon icon={faTable} /> &nbsp; Choose a value to generate canaries with
                            </h4>
                            <hr></hr>
                          </LeftGrid>
                          <Grid item xs={12} sm={10} md={8}>
                            <StyledRadioGroup
                              aria-labelledby='demo-controlled-radio-buttons-group'
                              name={option}
                              value={option}
                              onChange={handleApiOptionChange}
                            >
                              {apiOption.map((options) => (
                                <FormControlLabel
                                  value={options}
                                  name={options}
                                  control={<Radio />}
                                  label={options}
                                />
                              ))}
                            </StyledRadioGroup>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <StyledButton
                              style={{ marginTop: '0' }}
                              variant='contained'
                              color='primary'
                              onClick={getAPICanary}
                            >
                              Detect Sentences with Sensitive Value
                            </StyledButton>
                          </Grid>
                        </Grid>
                        {apiCanary && (
                          <section>
                            <Grid container direction="row" justify="center" spacing={2}>
                              <br /><br />
                              <LeftGrid item xs={12}>
                                <p> Sentences from your dataset containing the selected sensitive value</p>
                                <h4>
                                  <FontAwesomeIcon icon={faTable} /> &nbsp; Choose one to insert as a canary
                                </h4>
                                <hr></hr>
                              </LeftGrid>
                              <Grid item xs={10}>
                                <StyledRadioGroup
                                  aria-labelledby='demo-controlled-radio-buttons-group'
                                  name={apicanarys}
                                  value={apicanarys}
                                  onChange={handleApiCanaryChange}
                                >

                                  <Table aria-label="sticky table">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody disablePadding>

                                      {apiCanary.map((options) => (
                                        <StyledRow>
                                          <TableCell align="left" >
                                            <FormControlLabel
                                              value={options}
                                              name={options}
                                              control={<Radio />}
                                              label={options}
                                            />
                                          </TableCell>
                                        </StyledRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </StyledRadioGroup>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                <StyledButton
                                  style={{ marginTop: '0' }}
                                  variant='contained'
                                  color='primary'
                                  onClick={getInsertCanary}
                                >
                                  Insert Canary
                                </StyledButton>
                              </Grid>
                            </Grid>

                            {canarypath && (
                              <section>
                                <Grid container direction="row" justify="center" spacing={2}>
                                  <LeftGrid item xs={12}>
                                    <p>Selected canary has been inserted into the dataset for training</p>
                                    <h4>
                                      <FontAwesomeIcon icon={faTable} /> &nbsp; Select parameters for model training
                                    </h4>
                                    <hr></hr>
                                    <List>
                                      <ListItem><strong>Batch size :</strong> Batch size is the number of training examples utilized in one iteration.</ListItem>
                                      <ListItem><strong>Sigma :</strong> Sigma is Gaussian noise variance multiplier. Since this is capability demonstration of DP on vLife, we have restricted the values of sigma.</ListItem>
                                      <ListItem><strong>Epochs :</strong> Epoch is number of times the entire training data will be passed to the model.</ListItem>
                                    </List>
                                  </LeftGrid>    <br /><br />
                                  <Grid item xs={12} sm={3}>
                                    <TextField
                                      name="batch_size"
                                      label="Batch Size"
                                      variant="outlined"
                                      onChange={handleFieldChange}
                                      size="small"
                                      select
                                      fullWidth
                                      value={fields.batch_size}
                                      placeholder="Ex: 7"
                                    >
                                      {['7', '14'].map((value) => (
                                        <MenuItem key={value} value={value}>
                                          {value}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <TextField
                                      name="sigma"
                                      label="Sigma"
                                      variant="outlined"
                                      onChange={handleFieldChange}
                                      size="small"
                                      select
                                      fullWidth
                                      value={fields.sigma}
                                      placeholder="Ex: 0.8"
                                    >
                                      {['0.5', '0.7', '0.8'].map((value) => (
                                        <MenuItem key={value} value={value}>
                                          {value}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <TextField
                                      name="epochs"
                                      label="Epochs"
                                      variant="outlined"
                                      onChange={handleFieldChange}
                                      size="small"
                                      select
                                      fullWidth
                                      value={fields.epochs}
                                      placeholder="Ex: 1"
                                    >
                                      {['2', '3', '5'].map((value) => (
                                        <MenuItem key={value} value={value}>
                                          {value}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>    <br /><br />
                                  <Grid item xs={12} sm={12} md={12}>
                                    <StyledButton
                                      style={{ marginTop: '0' }}
                                      variant='contained'
                                      color='primary'
                                      onClick={getTrainModal}
                                    >
                                      Train Model
                                    </StyledButton>
                                  </Grid>
                                </Grid>
                                {trainmodal && (
                                  <section>
                                    <Grid container direction="row" justify="center" spacing={2}>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <br /><br />
                                        Model training job has been scheduled and will take few hours for completion. A mail will be sent to you once the training job is completed.
                                        <br /><br />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <StyledButton
                                          style={{ marginTop: '0' }}
                                          variant='contained'
                                          color='primary'
                                          onClick={getLoadModal}
                                        >
                                          View Collections
                                        </StyledButton>
                                      </Grid>
                                    </Grid>
                                  </section>
                                )}
                              </section>
                            )}
                          </section>
                        )}
                      </section>
                    )}
                  </section>
                )}
              </section>
            )}
            {loadmodal && (
              <section>
                <br /><br />
                <Grid container direction="row" justify="center" spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={tableradiovalue}
                      onChange={handleTableRadioChange}
                    >
                      <TablePaper>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableHead align="left">
                                Select Option
                              </StyledTableHead>
                              <StyledTableHead align="left">
                                Execution Time
                              </StyledTableHead>
                              <StyledTableHead align="left">
                                File Name
                              </StyledTableHead>
                              <StyledTableHead align="left">
                                canary
                              </StyledTableHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loadmodal?.collections.map((value, index) => (
                              <>
                                <TableRow>
                                  <TableCell>
                                    {' '}
                                    <FormControlLabel
                                      value={loadmodal.collections[index][0]}
                                      control={<Radio />}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {loadmodal.collections[index][0]}
                                  </TableCell>
                                  <TableCell>
                                    {loadmodal.collections[index][1]}
                                  </TableCell>
                                  <TableCell>
                                    {loadmodal.collections[index][2]}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TablePaper>
                    </RadioGroup>
                  </Grid>
                </Grid>
                <br /><br />
                {tableradiovalue && (
                  <section>
                    <Grid container direction="row" justify="center" spacing={2}>
                      <LeftGrid item xs={12} sm={5} md={4}>
                        Type a prefix (ideally from inserted canary) for text generation:
                      </LeftGrid>
                      <Grid item xs={12} sm={6} md={8}>
                        <TextField
                          multiline
                          onChange={onPrefixHandler}
                          variant="outlined"
                          name="text"
                          fullWidth
                          value={prefixValue}
                        />
                      </Grid>    <br /><br />
                      <Grid item xs={12} sm={12} md={12}>
                        <StyledButton
                          style={{ marginTop: '0' }}
                          variant='contained'
                          color='primary'
                          onClick={generateOutput}
                        >
                          Generate Text
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </section>
                )}
                {finaloutput && (
                  <section>
                    <Grid container direction="row" justify="center" spacing={2}>
                      <br /><br />
                      <LeftGrid item xs={12}>
                        Below are the outputs generated using a non-private and a differentially private model:
                      </LeftGrid>
                      <br /><br />
                      <Grid item xs={10} sm={5} md={5}>
                        <strong>Non private Model</strong>
                      </Grid>
                      <Grid item xs={10} sm={6} md={6}>
                        <strong>Differentially Private Model</strong>
                      </Grid>
                      <Grid item xs={10} sm={6} md={6}>
                        <List>
                          {finaloutput.nodp.map((value) => (
                            <ListItem>
                              {value}
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item xs={10} sm={5} md={5}>
                        <List>
                          {finaloutput.dp.map((value) => (
                            <ListItem>
                              {value}
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </section>
                )}
              </section>
            )}
          </TabPanel>
          {showSpinner && (
            <Spinner text="Loading.. Please wait for a minute..." />
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
});

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
                display: block;
          
                `;

const LeftGrid = styled(Grid)`
  text-align: left;
`;

const StyledTableHead = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: #3c40af;
    color: white;
    font-weight: bold;
    font-size: smaller;
    @media only screen and (max-width: 450px) {
      font-size: x-small;
    }
  }
`;
const StyledRow = styled(TableRow)`
  .MuiTableCell-root{
    background-color: lightgray; 
    padding: 6px;
    cursor: pointer;
    &.hover{
        background-color: white; 
    }
  }
`;
const TablePaper = styled.div`
  overflow: auto;
  width: 99%;
  height: auto;
`;

export const StyledTabss = styled(StyledTabs)`
  color: black;
  background-color: white;

  .Mui-selected {
    background-color: white;
    color: black;
    font-weight: bold;
    text-transform: none;
  }
`;

export default function DifferentialPrivacyTextDesc() {
  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Natural language processing can often require handling privacy-
            sensitive text. To avoid revealing confidential information, data
            owners and practitioners can use differential privacy, which
            provides a mathematically guaranteeably definition of privacy
            preservation. Differential privacy guarantees to protect language
            models from leaking information, this is done by training RNN-based
            models. Intuitively, for some query on some dataset, a
            differentially private algorithm produces an output, regulated by a
            privacy parameter (Epsilon), that is statistically indistinguishable
            from the same query on this same dataset had any one individual’s
            information been removed. This powerful tool has been adopted by
            researchers and industry leaders, and has become particularly
            interesting to machine learning practitioners, who hope to leverage
            privatized data in training predictive models.
          </p>
          <p>
            Because differential privacy often depends on adding noise, the
            results of differentially private algorithms can come at the cost of
            data accuracy and utility. However, differentially private machine
            learning algorithms have shown promise across many domains. These
            algorithms can provide tight privacy guarantees while still
            producing accurate predictions. A drawback to most methods, however,
            is in the one-off nature of training: once the model is produced,
            the privacy budget for a real dataset can be entirely consumed. The
            differentially private model is therefore inflexible to retraining
            and difficult to share/verify: the output model is a black box.
          </p>
          <center>
            <ZoomImage width="55%" src={differentialPrivacyTextDes1} alt="" />
          </center>
          <strong>Training</strong>
          <p>
            Differential Privacy is applied on a text generation model (GPT-2)
            and the model is evaluated against a non-private model using Canary
            Insertion attacks. The models are trained after inserting random
            sequences called canaries into the training dataset. Two models
            namely “No-DP” and “DP” are trained.
          </p>
          <p>
            “No-DP” is simply a GPT2 transformer optimized with a regular SGD,
            while “DP” is optimized with DPSGD.
          </p>
          <p>
            Once the models are trained, the initial prefix of canary is passed
            into it to generate the text.
          </p>
          <strong>Data Used</strong>
          <p>
            A medical text corpus for language modeling has been used for this
            purpose. It contains articles from the medical field.
          </p>
          <strong>Result</strong>
          <p>
            The protected DP-GPT2 model do not leak out sensitive information of
            users whereas non-private model exposes the training data when a
            prefix is given for text generation
          </p>
          <strong>Business Uses:</strong>
          <p>Differential privacy is important for businesses because:</p>
          <List>
            <ListItem>
              Traditional Machine Learning Models trained on confidential
              information may unintentionally leak data. Also, these models are
              prone to many adversarial attacks (like Membership Inference
              attack). Differential privacy adds a controlled amount of
              randomness to a dataset to prevent anyone from obtaining
              information about individuals in the dataset. The added randomness
              is controlled. Therefore, the resulting dataset is still accurate
              enough to generate aggregate insights through data analysis while
              maintaining the privacy of individual participants.
            </ListItem>
            <ListItem>
              It can help businesses to comply with data privacy regulations
              such as GDPR (General Data Protection Regulation) and CCPA
              (California Consumer Privacy Act) without undermining their
              ability to analyze their customer behavior. Failure to comply with
              these regulations can result in serious fines.
            </ListItem>
            <ListItem>
              Differential privacy enables businesses to share their data with
              other organizations to collaborate with them without risking their
              customers’ privacy.
            </ListItem>
          </List>
          <strong>Applications of Differential Privacy:</strong>
          <List>
            <ListItem>
              U.S. Census Bureau started to use differential privacy with the{" "}
              <a
                href="https://www.census.gov/programs-surveys/decennial-census/decade/2020/planning-management/process/disclosure-avoidance.html"
                target="_blank"
                rel="noreferrer"
              >
                2020 Census data
              </a>
              . The dataset contains detailed demographic information about U.S.
              citizens. Without a privacy measure, this information can be
              traced back to individuals. The Bureau states that traditional
              anonymization techniques became obsolete. This is because of{" "}
              <a
                href="https://en.wikipedia.org/wiki/Data_re-identification"
                target="_blank"
                rel="noreferrer"
              >
                re-identification methods
              </a>{" "}
              that make it possible to reveal information of a specific
              individual from an anonymized dataset.
            </ListItem>
            <ListItem>
              Personal data such as emojis, chats, browser search queries and
              health information can be privatized using differential privacy.
            </ListItem>
            <ListItem>
              Differential privacy is also used in applications of other
              privacy-preserving methods in artificial intelligence such as
              federated learning or{" "}
              <a
                href="https://research.aimultiple.com/synthetic-data-generation/"
                target="_blank"
                rel="noreferrer"
              >
                synthetic data generation
              </a>
              .
            </ListItem>
          </List>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}></Grid>
        </LinkContainer>
      </Collapse>
    </SolutionContainer>
  );
}
