import React, { memo, useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, Select, Checkbox, ListItemText, ListItemIcon, InputLabel, FormControl } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '../../../../../../styles/common';
import { setInputData } from '../../../../../../store/actions/vnet';

import { isObjKeyhasval } from '../../util';
import { InfoText } from '../ModelOptimization/DataShapley';
import { StyledFormControl } from '../Dataviz/ScatterPlot';

export const ArithmeticOperationForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const dispatch = useDispatch();
  const [columnData, setColumnData] = useState({});
  const [fromData, setFromData] = useState({ input0: '' });
  const [dropdownList, setdropdownList] = useState(null);
  const [change, setChange] = useState(true);
  const [columnValid, setColumnValid] = useState(false);
  const [toValid, setToValid] = useState(false);
  const [fromValid, setFromValid] = useState(false);
  const [alert, setAlert] = useState('');
  const [error, setError] = useState({ name: ' ', value: ' ' });
  const [columnDataIndex, setColumnDataIndex] = useState('1');

  const handleColumnData = (e) => {
    setColumnData({ ...columnData, [e.target.name]: e.target.value });
    const columnValid = e.target.value;
    const submitValid = toValid && columnValid && fromValid;
    setColumnValid(columnValid);
    setChange(!submitValid);
  };

  const handleToData = (e) => {
    console.log(e.target.name);
    const { value, name, id } = e.target;
    if (name) {
      dispatch(
        setInputData({
          method: {
            ...userInputData.method,
            [name]: {
              columns: fromData[id],
              newName: value
            },
          },
        })
      );
    }
    const toValid = e.target.value && !Object.values(fromData).indexOf(e.target.value) > -1;
    const submitValid = toValid && columnValid && fromValid;
    alert && setAlert(false);
    setToValid(toValid);
    setChange(!submitValid);
    if (error[name]) {
      setError({ ...error, [name]: false })
    }
  };

  const handleFromData = (e) => {
    if (e.target.name) {
      setFromData({ ...fromData, [e.currentTarget.id]: e.target.value });
    }
    const fromValid = e.target.value;
    const submitValid = fromValid && columnValid && toValid;
    alert && setAlert(false);
    setFromValid(fromValid);
    setChange(!submitValid);
  };

  const getInputFieldLen = () =>
    Object.entries(columnData).filter((data) => data[0].startsWith('input'))
      .length;
  const addField = () => {
    const columnDataLen = getInputFieldLen();
    if (columnDataLen < dropdownList.length) {
      setColumnData({ ...columnData, [`input${columnDataIndex}`]: '' });
    }
    setColumnDataIndex(columnDataIndex + 1);
    setChange(true);
    setToValid(false);
    setColumnValid(false);
  };

  const removeField = (e, colKey) => {
    const list = { ...columnData };
    const globallist = { ...userInputData.method };
    delete globallist[e];
    delete list[colKey];
    setColumnData(list);
    dispatch(
      setInputData({
        method: globallist,
      })
    );
    setChange(false)
  };

  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }
  }, []);

  useEffect(() => {
    if (userInputData?.method) {
      const formData = {};
      let columnDataLen = 0;
      Object.keys(userInputData?.method).map((data, indx) => {
        formData[`disabled${indx}`] = data;
        columnDataLen++;
      });
      setColumnData(formData);
      setColumnDataIndex(columnDataLen);
      setChange(false);
    } else {
      setColumnData({ input0: '' });
    }
  }, []);


  const handleFormReset = () => {
    setColumnData({ input0: '' });
    setFromData({ input0: '' });
    dispatch(
      setInputData({
        method: {},
      })
    );
  };
  const validateInput = (e) => {
    const { name, value } = e.target;
    const error = {
      ...error,
    };
    let prevList = [...dropdownList, ...Object.keys(userInputData?.method).map(data => userInputData?.method[data]?.newName)];
    if (prevList.filter(val => val == value).length > 1) {
      error[name] = 'duplicate column name'
    }
    setError(error);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };

  const isFormValid = () => {
    if (!userInputData?.method || Object.entries(userInputData?.method).length == 0) {
      setAlert(true);
      return false;
    }
    return true;
  };

  return (
    <ArithmetciOperationContainer>
      {alert && (
        <InfoText success={false}>
          <FontAwesomeIcon icon={faInfoCircle} />
          Please fill all required fields
        </InfoText>
      )}
      <Grid container spacing={2} alignItems='center'>
        {Object.entries(columnData).map((data) => (
          <>
            <Grid item xs={3}>
              {data[0].startsWith('disabled') ? (
                <TextField
                  fullWidth
                  name={data[0]}
                  label='Method'
                  variant='outlined'
                  disabled
                  type='text'
                  required
                  value={data[1]}
                />
              ) : (
                <TextField
                  name={data[0]}
                  label='Method'
                  variant='outlined'
                  onChange={handleColumnData}
                  select
                  key={data[0]}
                  fullWidth
                  required
                  value={columnData && columnData[data[0]]}
                >
                  {['Addition', 'Subtraction', 'Division', 'Multiplication', 'Floor Division', 'Power', 'Modulus']?.map((value) => (
                    <MenuItem key={value} value={value} disabled={Object.values(columnData).indexOf(value) > -1}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={3}>
              <StyledFormControl fullWidth disabled={data[0].startsWith('disabled')}>
                <StyledInpuLabel >Columns</StyledInpuLabel>

                <Select
                  multiple
                  id={data[0]}
                  variant="outlined"
                  name={columnData[data[0]]}
                  value={fromData[data[0]] || []}
                  onChange={handleFromData}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  helperText={error[columnData[data[0]]]}
                  required
                >
                  {dropdownList?.map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      id={data[0]}
                      disabled={value == 'Null'}
                    >
                      <ListItemIcon>
                        <Checkbox checked={fromData[data[0]]?.indexOf(value) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                name={columnData[data[0]]}
                id={data[0]}
                variant='outlined'
                label='New Feature Name'
                onChange={handleToData}
                //onBlur={validateInput}
                type='text'
                requried
                disabled={data[0].startsWith('disabled')}
                value={
                  columnData[data[0]] &&
                  userInputData?.columns?.[columnData[data[0]]]?.[fromData[data[0]]]
                }
                error={error[columnData[data[0]]]}
                helperText={error[columnData[data[0]]]}
                placeholder="New Name"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            {!data[0].startsWith('disabled') && (
              <Grid item xs={1}>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => removeField(columnData[data[0]], data[0])}
                  size='large'
                />
              </Grid>
            )}
          </>
        ))}
      </Grid>
      <StyledButton disabled={change} onClick={addField} variant='outlined'>
        +
      </StyledButton>
      {props.render({ handleFormReset, isFormValid })}
    </ArithmetciOperationContainer>
  );
});

const ArithmetciOperationContainer = styled.section`
  text-align: left;
  .MuiInputBase-root{
    width: 100%;
  }

`;
export const StyledInpuLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    margin: -5px 10px;
  }

`;

