import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormLabel,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { setInputData } from "../../../../../../store/actions/vnet";
import { StyledButton } from "../../../../../../styles/common";
import { InfoText } from "../ModelOptimization/DataShapley";

export const SelectColForm = memo((props) => {
  const { userInputData } = useSelector((state) => state.vnet);
  const [dropdownList, setdropdownList] = useState(null);
  const dispatch = useDispatch();
  const [selectedColumn, setChecked] = useState({});
  const [alert, setAlert] = useState(false);

  useEffect(async () => {
    const result = await props.getCategories();
    console.log(result);
    if (result) {
      setdropdownList(JSON.parse(result));
    }

    if (userInputData?.columns) {
      const selectAll = {};
      userInputData?.columns.map((key) => {
        selectAll[key] = true;
      });
      setChecked({
        ...selectAll,
      });
    }
  }, []);

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (e.target.name) {
      setAlert(false);
      if (name !== "SelectAll") {
        const latestCheckList = {
          ...selectedColumn,
          [name]: checked,
        };
        if (selectedColumn.all && checked == false) {
          latestCheckList.all = false;
        }
        setChecked({
          ...latestCheckList,
        });

        const updatedList = Object.keys(latestCheckList).filter(
          (key) => latestCheckList[key],
        );
        console.log(updatedList);
        dispatch(
          setInputData({
            columns: updatedList,
          }),
        );
      } else {
        const selectAll = {};
        dropdownList.map((key) => {
          selectAll[key] = checked;
        });
        setChecked({
          ...selectAll,
          all: checked,
        });
        dispatch(
          setInputData({
            columns: checked ? [...dropdownList] : [],
          }),
        );
      }
    }
  };

  const handleSpecify = (e) => {
    const columns = e.target.value == "True" ? {} : [];
    dispatch(
      setInputData({
        specify: e.target.value,
        columns,
      }),
    );
  };

  const handleChangeValue = (e) => {
    if (e.target.value) {
      const { value } = e.target;
      dispatch(
        setInputData({
          columns: [value],
        }),
      );
    }
  };
  const handleFormReset = () => {
    console.log('reset');
  };

  const isFormValid = () => {
    if (!userInputData?.columns || userInputData?.columns?.length == 0) {
      setAlert(true);
      return;
    }
    return true;
  };
  return (
    <SelectContainer>
      {/* <FormLabel component="label">Specify</FormLabel>
      <RadioGroup
        style={{ display: "block" }}
        row
        name="specify"
        defaultValue="False"
        aria-label="Learn Mixture Weights"
        value={userInputData.specify}
        onChange={handleSpecify}
      >
        <FormControlLabel
          value="False"
          control={<Radio color="primary" />}
          label="Custom"
          labelPlacement={"end"}
        />
        <FormControlLabel
          value="True"
          control={<Radio color="primary" />}
          label="Smart Select"
          labelPlacement={"end"}
        />
      </RadioGroup> */}
      {alert && (
      <InfoText success={false}>
        {' '}
        <FontAwesomeIcon icon={faInfoCircle} />
        {' '}
        Please choose atleast one column
      </InfoText>
      )}
      {userInputData.specify == "True" && (
        <>
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs={6}>
              <TextField
                label="Target"
                variant="outlined"
                onChange={handleChangeValue}
                select
                fullWidth
              >
                {dropdownList?.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <br />
              <TextField
                label="No of Rows"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <StyledButtons variant="contained" color="primary">
                Run Smart Select
              </StyledButtons>
            </Grid>
          </Grid>
        </>
      )}
      {userInputData.specify == "False" && (
        <>
          <FormControlLabel
            control={(
              <Checkbox
                name="SelectAll"
                onChange={handleCheckBox}
                value="SelectAll"
                checked={!!selectedColumn.all}
              />
            )}
            label="Select All"
          />
          <br />
          <StyledDiv>
            <CheckboxGrid container spacing={3} alignItems="flex-start">
              {dropdownList?.map((value) => (
                <>
                  <Grid item xs={6} sm={4} md={4}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name={value}
                          onChange={handleCheckBox}
                          checked={!!selectedColumn[value]}
                          value={value}
                        />
                      )}
                      label={value}
                    />
                  </Grid>
                </>
              ))}
            </CheckboxGrid>
          </StyledDiv>
          <br />
        </>
      )}
      {props.render({ handleFormReset, isFormValid })}

    </SelectContainer>
  );
});

const SelectContainer = styled.section`
  text-align: left;
  .MuiTextField-root {
    min-width: 190px;
  }
  @media only screen and (max-width: 450px) {
    .MuiTypography-root {
      font-size: 0.8rem;
    }
  }
`;

const StyledDiv = styled(Grid)`
  border: 1px solid grey;
  border-radius: 4px;
  @media only screen and (max-width: 600px) {
    overflow: auto;
  }
`;

const CheckboxGrid = styled(Grid)`
  margin-left: 10px;
  overflow: auto;
 
`;

const StyledButtons = styled(StyledButton)`
  text-align: left;
`;
