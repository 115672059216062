import React, {
  memo, useEffect, useState, useMemo, useCallback,
} from "react";
import styled from "styled-components";

// local components
import { useSelector, useDispatch } from "react-redux";
import { Divider, Grid, Tooltip } from "@material-ui/core";
import {
  featureIds, subFeatureData, subFeatureIds, validationData, stageLabels, subFeatureLables, featureLabels,
} from "../constants";
import { StyledBackdrop, StyledButton } from "../../../../../styles/common";
import {
  setCanvaList,
  setConsole,
  setInputData,
  setResult,
  setResultTab,
} from "../../../../../store/actions/vnet";
import Notification from "../../../../common/Notification";
import { formatRequest } from "../util";
import postReq, { getResult, postFlaskReq } from "../Service";
import Spinner from "../../../../common/loading/Spinner";
import { StyledInput } from "../InputForm";

function InputForm() {
  const {
    Context_param,
    userInputData,
    isCanvasClicked,
    canvasList,
    resultData = {},
  } = useSelector((state) => state.vnet);
  const {
    Form = null,
    requestParams,
    noValidation = false,
    initialData = null,
    hidePreviousInputAPI = false,
    trigger = "",
    uploadFileApi = "",
  } = subFeatureData[Context_param?.FunctionID || "default"]
    || subFeatureData.default;

  const [isSaveSuccess, setSaveSuccess] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [description, setDescription] = useState({ value: "", error: false });
  const [notification, setNotification] = useState("");
  const [fileData, setFileData] = useState("");

  const dispatch = useDispatch();

  const validateForm = () => {
    const inputData = subFeatureData[Context_param?.FunctionID]?.fields;
    const errorObject = {};
    inputData.map(({ name, required }) => {
      if (required && !userInputData[name]) {
        errorObject[`error_${name}`] = true;
      }
    });
    if (Object.keys(errorObject).length > 0) {
      dispatch(
        setInputData({
          ...userInputData,
          ...errorObject,
        }),
      );
      return false;
    }
    return true;
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    if (e.target?.files && e.target?.files[0]) {
      const file = e.target.files[0];
      const validationKey = e.target.id;
      if (validationKey) {
        const fileValidationData = validationData[validationKey];
        if (fileValidationData.formats.indexOf(file.name.split(".")[1]) == -1) {
          setNotification({ open: "error_file", message: fileValidationData.info });
          return false;
        }
      }
      setFileData(file);
      dispatch(
        setInputData({
          error_file: false,
          isSampleData: false,
          isCustomFile: true
        }),
      );
    } else if (noValidation) {
      dispatch(
        setInputData({
          ...userInputData,
          [name]: value,
        }),
      );
    } else {
      dispatch(
        setInputData({
          ...userInputData,
          [name]: value,
          [`error_${name}`]: false,
        }),
      );
    }
  };
  const handleDescription = (e) => {
    setDescription({ value: e.target.value, error: false });
  };

  // fetch categories api
  const fetchCategories = async (Actions = "From", FeatureCategory = "All") => {
    const params = {
      Context_param,
      Content_param: {},
    };
    params.Context_param.Actions = Actions;
    params.Context_param.FeatureCategory = FeatureCategory;
    params.Context_param.Update_Date = new Date().toLocaleDateString();
    params.Context_param.Created_Date = new Date().toLocaleDateString();
    if (params) {
      try {
        setLoader(true);
        const res = await postReq(params);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res.Error_UI });
        } else {
          setNotification({ open: "success", message: res.Console });
        }
        setLoader(false);
        return res.Result;
      } catch (error) {
        setLoader(false);
        setNotification({ open: "error", message: error.message });
      }
    }
  };
  async function getCommonAPIData(contextData = {}, contentData = {}, flaskAPI) {
    let result;
    const postparams = {
      Context_param: {
        ...Context_param,
        ...contextData,
      },
      Content_param: {
        ...contentData,
      },
    };
    try {
      setLoader(true);
      if (flaskAPI) {
        result = await postFlaskReq(postparams, flaskAPI)
      }
      else {
        result = await postReq(postparams);
      }
      setLoader(false);

      if (result.Error_Flag) {
        setNotification({ open: "error", message: result.Error_UI });
      } else {
        setNotification({ open: "success", message: result.Console });
        return result;
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      return false;
    }
  }

  const saveFileData = () => {
    let FunctionID = Context_param?.FunctionID;
    if (!userInputData?.isSampleData && !fileData) {
      dispatch(
        setInputData({
          error_file: true,
        }),
      );
      return;
    }
    switch (Context_param.StageID) {
      case "Model_Optimization":
        if (userInputData?.Threshold) {
          FunctionID = "Shapley Threshold";
        }
        break;
      case "Model_Testing":
        FunctionID = userInputData?.mode;
        break;
      default:
        break;
    }
    let params = {
      Context_param: {
        ...Context_param,
        Actions: "valSave",
        Comments: description.value,
        FunctionID,
        Update_Date: new Date().toLocaleDateString(),
        Created_Date: new Date().toLocaleDateString(),
      },
      Content_param: userInputData,
    };
    const fd = new FormData();
    if (userInputData?.isCustomFile) {
      fd.append("file", fileData);
    }
    fd.append("Content_param", JSON.stringify(params.Content_param));
    fd.append("Context_param", JSON.stringify(params.Context_param));
    params = fd;
    setLoader(true);
    postFlaskReq(params, uploadFileApi)
      .then((res) => {
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res.Error_UI });
        } else {
          setSaveSuccess(true);
          setNotification({ open: "success", message: res.Console });
          addCanvaTile();
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const addCanvaTile = () => {
    const data = canvasList;
    if (data[stageLabels[Context_param.StageID]]) {
      let { features } = data[stageLabels[Context_param.StageID]];
      let isFeatureAvailable = false;
      features.map((featureData) => {
        if (featureData.key === Context_param.FeatureID) {
          isFeatureAvailable = true;
          if (!(featureData.subList.some((data) => data.key === Context_param.FunctionID))) {
            featureData.subList.push({ label: subFeatureLables[Context_param.FunctionID], key: Context_param.FunctionID });
          }
          return featureData;
        }

        return featureData;
      });
      if (!isFeatureAvailable) {
        features = [...features, {
          label: featureLabels[Context_param.FeatureID],
          key: Context_param.FeatureID,
          subList: [{
            label: subFeatureLables[Context_param.FunctionID], key: Context_param.FunctionID,
          }],
        }];
      }
      data[stageLabels[Context_param.StageID]].features = features;
      console.log(data);
    } else {
      const features = [{
        label: featureLabels[Context_param.FeatureID],
        key: Context_param.FeatureID,
        subList: [{
          label: subFeatureLables[Context_param.FunctionID], key: Context_param.FunctionID,
        }],
      }];
      data[stageLabels[Context_param.StageID]] = {
        label: stageLabels[Context_param.StageID],
        features,
      };
    }
    dispatch(setCanvaList(data));
  };
  // valid input form, get request params & call API to save data in db
  const saveInputData = () => {

    const Content_param = requestParams
      ? formatRequest?.[requestParams]
      && formatRequest[requestParams](userInputData)
      : userInputData;
    let FunctionID = Context_param?.FunctionID;
    switch (Context_param.StageID) {
      case "Model_Optimization":
        if (userInputData?.Threshold) {
          FunctionID = "Shapley Threshold";
        }
        break;
      case "Model_Testing":
        FunctionID = userInputData?.mode;
        break;
      case 'Data_Generation':
        if (FunctionID == 'ctgan' && isCanvasClicked) {
          FunctionID = "ctgan_merge";
        }
        break;
      default:
        break;
    }
    const params = {
      Context_param: {
        ...Context_param,
        Actions: "valSave",
        Comments: description.value,
        FunctionID,
        Update_Date: new Date().toLocaleDateString(),
        Created_Date: new Date().toLocaleDateString(),
      },
      Content_param,
    };

    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          if (res.Error_Flag) {
            setNotification({ open: "error", message: res.Error_UI });
          } else {
            setSaveSuccess(true);
            setNotification({ open: "success", message: res.Console });
            addCanvaTile();
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };
  const executeData = (e) => {
    let FunctionID = Context_param?.FunctionID;
    switch (Context_param.StageID) {
      case "Model_Optimization":
        if (userInputData?.Threshold) {
          FunctionID = "Shapley Threshold";
        }
        break;
      case "Model_Testing":
        FunctionID = userInputData?.mode;
        break;
      case 'Data_Generation':
        if (FunctionID == 'ctgan' && isCanvasClicked) {
          FunctionID = "ctgan_merge";
        }
        break;
      default:
        break;
    }

    const params = {
      Context_param: {
        ...Context_param,
        Actions: "Execute",
        Comments: description.value,
        FunctionID,
        Update_Date: new Date().toLocaleDateString(),
        Created_Date: new Date().toLocaleDateString(),
        Trigger: trigger,
      },
      Content_param: {},
    };

    if (params) {
      setLoader(true);
      postReq(params)
        .then((res) => {
          if (res.Error_Flag) {
            setNotification({ open: "error", message: res.Error_UI });
            dispatch(setConsole(res.Console));
          } else {
            setNotification({ open: "success", message: res.Console });
            dispatch(setConsole(res.Console));
            switch (Context_param.StageID) {
              case "Data_Input":
                dispatch(
                  setResult({
                    ...resultData,
                    data: {
                      ...res.Result,
                      selectedDataset: "Latest_DF",
                    },
                  }),
                );
                dispatch(setResultTab(0));
                break;
              case "Data_Visualization":
                setLoader(true);
                getResult(res.Result?.Path).then(s3Res => {
                  setLoader(false);
                  dispatch(
                    setResult({
                      ...resultData,
                      dataViz: {
                        ...res.Result,
                        zPlot: s3Res
                      },
                    }),
                  );
                  dispatch(setResultTab(1));
                }).catch(err => {
                  setLoader(false);
                })

                break;
              case "Data_Transformation":
                dispatch(
                  setResult({
                    ...resultData,
                    ...res.Result,
                  }),
                );
                dispatch(setResultTab(2));
                break;
              case "Data_Preparation":
                dispatch(
                  setResult({
                    ...resultData,
                    ...res.Result,
                  }),
                );
                dispatch(setResultTab(2));
                break;
              case "Model_Building":
                dispatch(setResult({
                  ...resultData,
                  modelData: {
                    ...res.Result,
                  },
                }));
                dispatch(setResultTab(3));
                break;
              case "Model_Optimization":
                dispatch(
                  setResult({
                    ...resultData,
                    modelData: {
                      ...resultData.modelData,
                      modelOutput: res.Result.inProgress
                        ? {
                          isShapleyRunning: true,
                        }
                        : {
                          ...res.Result.modelOutput,
                        },
                    },
                  }),
                );
                dispatch(setResultTab(3));
                break;
              case "Model_Testing":
                dispatch(
                  setResult({
                    ...resultData,
                    modelData: {
                      ...resultData.modelData,
                      ...res.Result,
                    },
                  }),
                );
                dispatch(setResultTab(3));
                break;
              case "Data_Generation":
                dispatch(
                  setResult({
                    ...resultData,
                    data: {
                      ...res.Result.data,
                    },
                    dataTransform: {
                      ...res.Result.dataTransform,
                    },
                  }),
                );
                dispatch(setResultTab(0));
                break;
              default:
                break;
            }
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };
  const saveCallbackobj = {
    saveInputData,
    saveFileData,
  };
  useEffect(() => {
    // only set initialdata when user clicks on feature panele and not onclick of canvas icon
    if (hidePreviousInputAPI) {
      initialData && dispatch(setInputData(initialData));
    } else {
      !isCanvasClicked && initialData && dispatch(setInputData(initialData));
    }
  }, []);

  return (
    <FormContainer>
      {Form && (
        <Form
          handleOnChange={handleOnchange}
          getCategories={fetchCategories}
          getCommonAPIData={getCommonAPIData}
          render={({
            handleFormReset,
            isFormValid,
            hideResetIfCanva = false,
            isSaveDisabled = false,
            saveCallback = "saveInputData",
          }) => {
            const validateAndSave = () => {
              if (isFormValid()) {
                if (!description.value) {
                  setDescription({
                    ...description,
                    error: true,
                  });
                  return;
                }
                saveCallbackobj[saveCallback]();
              }
            };
            const resetHandler = () => {
              handleFormReset && handleFormReset();
              setDescription({ value: "" });
            };
            return (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <StyledInput
                      required
                      name="description"
                      label="Description"
                      variant="outlined"
                      onChange={handleDescription}
                      value={description.value}
                      error={description.error}
                      helperText={description.error && "Required"}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <ActionsContainer>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={validateAndSave}
                    disabled={isSaveDisabled}
                  >
                    save
                  </StyledButton>
                  <Tooltip
                    arrow
                    placement="top"
                    title="Please save any changes before execute"
                  >
                    <StyledButton
                      variant="contained"
                      color="primary"
                      disabled={isSaveDisabled || !isSaveSuccess}
                      onClick={executeData}
                    >
                      Execute
                    </StyledButton>
                  </Tooltip>
                  {!hideResetIfCanva && (
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={resetHandler}
                    >
                      Reset
                    </StyledButton>
                  )}
                </ActionsContainer>
              </>
            );
          }}
        />
      )}

      <StyledBackdrop open={isLoading}>
        <Spinner />
      </StyledBackdrop>
      <Notification snackbar={notification} />
    </FormContainer>
  );
}

export default memo(InputForm);

const FormContainer = styled.section`
  padding: 10px;
  hr {
    margin-top: 10px;
  }
  text-align: left;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
`;
const ActionsContainer = styled.section`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: #ffff;
  z-index: 2;
`;
