import React, { useState } from "react";
import styled from "styled-components";
import img1 from "../../../assets/images/solutions/graphfeaturesArch.webp";
import img2 from "../../../assets/images/solutions/gnn_logic_architecture2.webp";
import img3 from "../../../assets/images/solutions/Feature_descriptions_after_sample_data.webp";
import img4 from "../../../assets/images/solutions/Extracted_features_description_after_extraction.webp";
import ZoomImage from "../../common/ZoomImage";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  DemoContainer,
  Paragraph,
  StyledButton,
  Result,
  LinkContainer
} from "../../../styles/common";
import Spinner from "../../common/loading/Spinner";
import {
  AppBar,
  Tab,
  Paper,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { StyledTabs } from "./ColbertAnalysis";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CommonService from "../../../utils/services/CommonService";
import Plot from "react-plotly.js";

export const InsuranceFraudDetectionDemo = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { getQueryResult } = CommonService(
    "z1dAppsv1",
    "insuranceFraudDetection"
  );

  const gender = ["Male", "Female"];

  const diseases = [
    "Alzheimer",
    "Heartfailure",
    "KidneyDisease",
    "Cancer",
    "ObstrPulmonary",
    "Depression",
    "Diabetes",
    "IschemicHeart",
    "Osteoporasis",
    "Rheumatoidarthritis",
    "Stroke",
  ];

  const hospitalized = ["Yes", "No"];

  const diagnosisCondition = [
    "Hypertension",
    "Diabetes mellitus",
    "Hyperlipidemia",
    "Long-term use of medications",
    "Benign hypertension",
    "Atrial fibrillation",
    "Long-term use of anticoagulants",
    "Hypercholesterolemia",
    "Acquired hypothyroidism",
    "Congestive heart failure",
    "Esophageal reflux",
    "Coronary atherosclerosis of native coronary artery",
    "Chronic airway obstruction",
    "Anemia",
    "Urinary tract infection",
  ];

  const initialData = {
    patientGender: "",
    patientChronicDiseases: "",
    insuranceClaimAmountReimbursed: "",
    deductibleAmountPaid: "",
    diagnosisCondition: "",
    patientHospitalized: "",
    admitForDays: "",
    inpatientAnnualReimbursementAmount: "",
    inpatientAnnualDeductibleAmount: "",
    outpatientAnnualReimbursementAmount: "",
    outpatientAnnualDeductibleAmount: "",
  };

  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  //Tab 1
  const [result, setResult] = useState("");
  const [fields, setFields] = useState(null);
  const [dataRow, setDataRow] = useState(null);
  const [resultBuildModel, setResultBuildModel] = useState("");
  const [extractFeature, setExtractFeature] = useState("");
  const [retrain, setRetrain] = useState("");
  const [baseFeatures, setBaseFeatures] = useState("");
  const [basePlusGraph, setBasePlusGraph] = useState("");

  //Tab 2
  const [sampleData, setSampleData] = useState(initialData);
  const [chronicDiseases, setChronicDiseases] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [sampleInput1, setSampleInput1] = useState("");
  const [sampleInput2, setSampleInput2] = useState("");
  const [gnnGraph, setGnnGraph] = useState("");
  const [actionType, setActionType] = useState("");

  //tab value
  const [tabVal, setValue] = useState(0);

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Tab 1 API integration
  const handleGetSample = () => {
    setSpinner(true);
    setResult("");
    setResultBuildModel("");
    setExtractFeature("");
    setRetrain("");
    setBaseFeatures("");
    setBasePlusGraph("");

    getQueryResult("sampleData")
      .then((response) => {
        setSpinner(false);
        setNotification({ open: "success", message: response?.Console });
        const Data = JSON.parse(response.Data);
        console.log("Sample Data: ", response);
        setFields(Data?.schema?.fields);
        setDataRow(Data?.data);
        setResult(Data);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const handleBuildModel = () => {
    setSpinner(true);
    setResultBuildModel("");
    setExtractFeature("");
    setRetrain("");
    setBaseFeatures("");
    setBasePlusGraph("");

    getQueryResult("buildModel")
      .then((response) => {
        setSpinner(false);
        setNotification({ open: "success", message: response?.Console });
        const Data = response.metrics;
        console.log("Build Model :", Data);
        setResultBuildModel(Data);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const handleExtractFeature = () => {
    setSpinner(true);
    setExtractFeature("");
    setRetrain("");
    setBaseFeatures("");
    setBasePlusGraph("");

    getQueryResult("extractedFeatures")
      .then((response) => {
        setSpinner(false);
        setNotification({ open: "success", message: response?.Console });
        const Data = JSON.parse(response.Data);
        console.log("Extracted Feature: ", Data);
        setExtractFeature(Data);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const handleRetrain = () => {
    setSpinner(true);
    setRetrain("");
    setBaseFeatures("");
    setBasePlusGraph("");

    getQueryResult("retrainModel")
      .then((response) => {
        setSpinner(false);
        setNotification({ open: "success", message: response?.Console });
        const Data = response.metrics;
        const Base = JSON.parse(response.BaseModelMetrics);
        const Graph = JSON.parse(response.GraphModelMetrics);
        setRetrain(Data);
        setBaseFeatures(Base);
        setBasePlusGraph(Graph);
        console.log("Retrain :", Data);
        console.log("Base :", Base);
        console.log("Graph :", Graph);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  //Tab 2 Handles
  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  //Multiselect field handle
  const handleSelectDiseases = (event) => {
    const {
      target: { value },
    } = event;
    setChronicDiseases(typeof value === "string" ? value.split(",") : value);
  };

  //Multiselect field handle
  const handleSelectCondition = (event) => {
    const {
      target: { value },
    } = event;
    setConditions(typeof value === "string" ? value.split(",") : value);
  };

  const handleSampleData1 = (e) => {
    setSampleInput1("");
    setSampleInput2("");
    setGnnGraph("");
    setActionType("sample1");

    setChronicDiseases("");
    setSampleData("");
    setConditions("");

    const NewDataSet = {
      patientGender: "Female",
      patientChronicDiseases: setChronicDiseases([
        "Alzheimer",
        " Heart Failure",
        "Kidney Disease",
        "Cancer",
        "ObstrPulmonary",
        "Diabetes",
        "Ischemic Heart",
      ]),
      insuranceClaimAmountReimbursed: "50",
      deductibleAmountPaid: "0",
      diagnosisCondition: setConditions([
        "Long-term use of anticoagulants",
        "Atrial fibrillation",
        "Hypercholesterolemia",
      ]),
      patientHospitalized: "No",
      admitForDays: "0",
      inpatientAnnualReimbursementAmount: "13880",
      inpatientAnnualDeductibleAmount: "2136",
      outpatientAnnualReimbursementAmount: "2940",
      outpatientAnnualDeductibleAmount: "1",
    };

    setSampleData(NewDataSet);
  };

  const handleSampleData2 = (e) => {
    setSampleInput1("");
    setSampleInput2("");
    setGnnGraph("");
    setActionType("sample2");

    setChronicDiseases("");
    setSampleData("");
    setConditions("");

    const NewDataSet = {
      patientGender: "Male",
      patientChronicDiseases: setChronicDiseases([
        "Alzheimer",
        "Heart Failure",
        "Kidney Disease",
        "Depression",
        "Diabetes",
        "Ischemic Heart",
      ]),
      insuranceClaimAmountReimbursed: "4000",
      deductibleAmountPaid: "1068",
      diagnosisCondition: setConditions([
        "Atrial fibrillation",
        " Urinary tract infection",
        "Hypertension",
      ]),
      patientHospitalized: "Yes",
      admitForDays: "6",
      inpatientAnnualReimbursementAmount: "4000",
      inpatientAnnualDeductibleAmount: "1068",
      outpatientAnnualReimbursementAmount: "3450",
      outpatientAnnualDeductibleAmount: "0",
    };
    setSampleData(NewDataSet);
  };

  //Tab 2 API integration
  const handlePlotGraph = () => {
    setSampleInput1("");
    setSampleInput2("");
    setGnnGraph("");

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
      return;
    }
    setSpinner(true);

    if (actionType === "sample1") {
      setSpinner(true);

      getQueryResult("sampleInput1")
        .then((response) => {
          // setSpinner(false);
          setNotification({ open: "success", message: response?.Console });
          const Data = response.Output;
          console.log(Data);
          setSampleInput1(Data);
        })
        .catch((error) => {
          setSpinner(false);
          setNotification({ open: "error", message: error.message });
        });
    } else if (actionType === "sample2") {
      getQueryResult("sampleInput2")
        .then((response) => {
          // setSpinner(false);
          setNotification({ open: "success", message: response?.Console });
          const Data = response.Output;
          console.log(Data);
          setSampleInput2(Data);
        })
        .catch((error) => {
          setSpinner(false);
          setNotification({ open: "error", message: error.message });
        });
    }

    getQueryResult("gnnGraph")
      .then((response) => {
        setSpinner(false);
        //setNotification({ open: "success", message: response?.Console });
        const Data = response.data;
        console.log("gnn graph :", Data);
        setGnnGraph(Data);
      })
      .catch((error) => {
        setSpinner(false);
        setNotification({ open: "error", message: error.message });
      });
  };

  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <AppBar position="static" color="default">
            <StyledTabs
              value={tabVal}
              onChange={tabhandleChange}
              variant="fullWidth"
              aria-label="simple tabs example"
            >
              <Tab label="Graph Analytics" />
              <Tab label="Graph Neural Network (GNN)" />
            </StyledTabs>
          </AppBar>
          <TabPanel value={tabVal} index={0}>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Paragraph>
                  Please click the button below to view the sample claim data.
                </Paragraph>

                <StyledButton
                  variant="contained"
                  color="primary"
                  data-sample="sample"
                  onClick={handleGetSample}
                >
                  Sample Data
                </StyledButton>
              </Grid>
            </Grid>
            <br />

            {result && (
              <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <>
                  <Grid item xs={12}>
                    <Result>
                      {" "}
                      <strong>Result:</strong>{" "}
                    </Result>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {result?.schema?.fields?.map((item) => (
                              <HeaderCol align="start">{item.name} </HeaderCol>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataRow?.map((row) => (
                            <TableRow>
                              {fields?.map((field) => (
                                <Cell align="center" key={row.index}>
                                  {row[field.name]}
                                </Cell>
                              ))}
                              <br />
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>{" "}
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <center>
                      <ZoomImage src={img3} alt="img3" width="90%" />{" "}
                    </center>
                  </Grid>
                  <br />
                  <br />

                  <Grid item xs={12}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      data-sample="sample"
                      onClick={handleBuildModel}
                    >
                      Build Model
                    </StyledButton>
                  </Grid>
                  <br />
                </>

                {resultBuildModel && (
                  <>
                    <Grid item xs={12}>
                      <Result>
                        {" "}
                        <strong>Base Model Metrics:</strong>{" "}
                      </Result>

                      <TableContainer
                        style={{
                          maxWidth: 350,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        component={Paper}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <HeaderCell align="left">Metric</HeaderCell>
                              <HeaderCell align="left">Value</HeaderCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(resultBuildModel)?.map((key) => (
                              <TableRow>
                                <TableCell> {key}</TableCell>
                                <TableCell> {resultBuildModel[key]} </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <br />

                    <Grid item xs={12}>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        data-sample="sample"
                        onClick={handleExtractFeature}
                      >
                        EXTRACT FEATURES FROM GRAPH
                      </StyledButton>
                    </Grid>
                    <br />
                  </>
                )}

                {extractFeature && (
                  <>
                    <Grid item xs={12}>
                      <Result>
                        {" "}
                        <strong>Result:</strong>{" "}
                      </Result>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {extractFeature?.schema?.fields?.map((item) => (
                                <HeaderCol align="start">
                                  {item.name}{" "}
                                </HeaderCol>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {extractFeature?.data?.map((row) => (
                              <TableRow>
                                {extractFeature?.schema?.fields?.map(
                                  (field) => (
                                    <Cell align="center" key={row.index}>
                                      {row[field.name]}
                                    </Cell>
                                  )
                                )}
                                <br />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>{" "}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                    <center>
                      <ZoomImage src={img4} alt="img4" width="90%" />{" "}
                    </center>
                  </Grid>
                    <br />
                    <br />
                    <Grid item xs={12}>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        data-sample="sample"
                        onClick={handleRetrain}
                      >
                        RETRAIN BY INCLUDING FEATURES FROM GRAPH
                      </StyledButton>
                    </Grid>
                    <br />
                  </>
                )}

                {retrain && (
                  <>
                    <Grid item xs={12}>
                      <Result>
                        {" "}
                        <strong>
                          Model Metrics After Multi Accuracy:
                        </strong>{" "}
                      </Result>

                      <TableContainer
                        style={{
                          maxWidth: 350,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        component={Paper}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <HeaderCell align="left">Metric</HeaderCell>
                              <HeaderCell align="left">Value</HeaderCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(retrain)?.map((key) => (
                              <TableRow>
                                <TableCell> {key}</TableCell>
                                <TableCell> {retrain[key]} </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <br />
                    <br />

                    <Grid item xs={6}>
                      <Result>
                        {" "}
                        <strong>Model Metrics for base features:</strong>{" "}
                      </Result>
                      {baseFeatures && (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {baseFeatures?.schema?.fields?.map((item) => (
                                  <HeaderCol align="start">
                                    {item.name}{" "}
                                  </HeaderCol>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {baseFeatures?.data?.map((row) => (
                                <TableRow>
                                  {baseFeatures?.schema?.fields?.map(
                                    (field) => (
                                      <Cell align="center" key={row.index}>
                                        {row[field.name]}
                                      </Cell>
                                    )
                                  )}
                                  <br />
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    <br />
                    <br />

                    <Grid item xs={6}>
                      <Result>
                        {" "}
                        <strong>
                          Model Metrics for base plus graph features:
                        </strong>{" "}
                      </Result>
                      {basePlusGraph && (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {basePlusGraph?.schema?.fields?.map((item) => (
                                  <HeaderCol align="start">
                                    {item.name}{" "}
                                  </HeaderCol>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {basePlusGraph?.data?.map((row) => (
                                <TableRow>
                                  {basePlusGraph?.schema?.fields?.map(
                                    (field) => (
                                      <Cell align="center" key={row.index}>
                                        {row[field.name]}
                                      </Cell>
                                    )
                                  )}
                                  <br />
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    <br />
                  </>
                )}
              </Grid>
            )}

            {showSpinner && (
              <Spinner text="Loading.. Please wait for a minute..." />
            )}
          </TabPanel>

          <TabPanel value={tabVal} index={1}>
            <br />
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} sm={4} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    label="Patient's Gender"
                    select
                    onChange={handleInputs}
                    name="patientGender"
                    value={sampleData.patientGender}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  >
                    {gender.map((option) => (
                      <MenuItem key={option} value={option}>
                        {" "}
                        {option}{" "}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Patient's Chronic Diseases</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    onChange={handleSelectDiseases}
                    name="patientChronicDiseases"
                    value={chronicDiseases}
                    renderValue={(selected) => selected.join(",")}
                    input={<OutlinedInput label="Patient Chronic Diseases" />}
                    MenuProps={MenuProps}
                    InputLabelProps={{ shrink: true }}
                    readOnly
                  >
                    {diseases.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Insurance Claim Amount Reimbursed"
                  fullWidth
                  onChange={handleInputs}
                  name="insuranceClaimAmountReimbursed"
                  value={sampleData.insuranceClaimAmountReimbursed}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Deductible Amount Paid"
                  fullWidth
                  onChange={handleInputs}
                  name="deductibleAmountPaid"
                  value={sampleData.deductibleAmountPaid}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl varient="outlined" fullWidth>
                  <InputLabel> Diagnosis Condition </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    onChange={handleSelectCondition}
                    name="diagnosisCondition"
                    value={conditions}
                    input={<OutlinedInput label="Diagnosis Condition" />}
                    renderValue={(selected) => selected.join(",")}
                    MenuProps={MenuProps}
                    InputLabelProps={{ shrink: true }}
                    readOnly
                  >
                    {diagnosisCondition.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl varient="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    label="Patient Hospitalized"
                    select
                    onChange={handleInputs}
                    name="patientHospitalized"
                    value={sampleData.patientHospitalized}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  >
                    {hospitalized.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Admit For Days"
                  fullWidth
                  onChange={handleInputs}
                  name="admitForDays"
                  value={sampleData.admitForDays}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Inpatient Annual Reimbursement Amount"
                  fullWidth
                  onChange={handleInputs}
                  name="inpatientAnnualReimbursementAmount"
                  value={sampleData.inpatientAnnualReimbursementAmount}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Inpatient Annual Deductible Amount"
                  fullWidth
                  onChange={handleInputs}
                  name="inpatientAnnualDeductibleAmount"
                  value={sampleData.inpatientAnnualDeductibleAmount}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Outpatient Annual Reimbursement Amount"
                  fullWidth
                  onChange={handleInputs}
                  name="outpatientAnnualReimbursementAmount"
                  value={sampleData.outpatientAnnualReimbursementAmount}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Outpatient Annual Deductible Amount"
                  fullWidth
                  onChange={handleInputs}
                  name="outpatientAnnualDeductibleAmount"
                  value={sampleData.outpatientAnnualDeductibleAmount}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={2} direction="row" justifyContent="center">
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  data-sample="sample1"
                  onClick={handleSampleData1}
                >
                  Sample 1
                </StyledButton>

                <StyledButton
                  variant="contained"
                  color="primary"
                  data-sample="sample2"
                  onClick={handleSampleData2}
                >
                  Sample 2
                </StyledButton>

                <StyledButton
                  variant="contained"
                  color="secondary"
                  onClick={handlePlotGraph}
                  endIcon={<ArrowForwardIcon />}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>

            <br />
            <br />

            {sampleInput1 && (
              <Result>
                <strong>Result: </strong> {sampleInput1}{" "}
              </Result>
            )}
            {sampleInput2 && (
              <Result>
                <strong>Result: </strong> {sampleInput2}{" "}
              </Result>
            )}

            {gnnGraph && (
              <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid item xs={12}>
                  <>
                    <Paragraph style={{ marginLeft: 6 }}>
                      Sample Network graph of Provider, Claim and Beneficiary{" "}
                    </Paragraph>

                    <Plot data={gnnGraph} />
                  </>
                </Grid>
              </Grid>
            )}

            {showSpinner && (
              <Spinner text="Loading.. Please wait for a minute..." />
            )}
          </TabPanel>
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
};

export default function InsuranceFraudDetectionDesc() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Insurance Claim Fraud Detection is a crucial aspect of the insurance
            industry, particularly in the healthcare sector where fraud schemes
            can lead to significant financial losses. While fraud detection has
            traditionally concentrated on identifying fraudulent individuals in
            the finance and banking sectors, it is equally important to detect
            fraudulent behaviours within the Medicare sector. Healthcare fraud
            involves the collaboration of various individuals, including
            providers, physicians, and beneficiaries, who conspire to submit
            fraudulent claims. This organized crime contributes to the rise in
            insurance premiums and the escalating cost of healthcare services.
          </p>
          <p>
            The financial impact of Medicare fraud is staggering. In United
            States, it costs between 21 and 71 billion dollars to Medicare fraud
            annually. The National Health Care Anti-Fraud Association (NHCAA)
            estimates that tens of billions of dollars are lost to Medicare
            fraud each year. Conservative estimates suggest that Medicare fraud
            accounts for around 3% of total Medicare expenditures, while some
            government and law enforcement agencies place the figure as high as
            10% of annual US healthcare expenses, exceeding $300 billion.
          </p>
          <p>
            Insurance companies strive to enhance the performance of fraud
            detection models to minimize economic losses and protect their
            reputation. Rule-based models have been commonly used, but they
            require continuous optimization by experts and are less effective in
            capturing sophisticated, complex, and organized Medicare frauds.
          </p>
          <p>
            To combat this pervasive issue, we can leverage graph analytics as
            an effective tool for fraud detection. Unlike traditional machine
            learning (ML) approaches that focus on individual observations, ML
            with graphs leverages the existing network structures to identify
            new patterns and gain insights into the relationships and
            connections between entities. By capturing the complex web of
            relationships among healthcare providers, physicians, and
            beneficiaries, graph analytics enhances the model's performance and
            provides a more comprehensive understanding of fraudulent
            activities. Graph analytics offers several advantages in the context
            of insurance claim fraud detection. It enables the identification of
            hidden connections and collusive networks that might not be apparent
            in the traditional feature set used in ML models. By incorporating
            the relational information, graph analytics provides a holistic view
            of the fraudulent schemes, uncovering interdependencies that can
            assist in the detection and prevention of fraud.
          </p>
          <p>
            <center>
              <ZoomImage src={img1} alt="img1" width="90%" />{" "}
            </center>
          </p>
          <p>
            Another powerful tool is Graph Neural Networks (GNNs). These are a
            type of deep learning model specifically designed to process
            graph-structured data. They can effectively learn from and make
            predictions on graph-structured data, making them well-suited for
            detecting fraud in insurance claims. GNNs can exploit the rich
            relational information present in the claims data, including
            connections between providers, physicians, and beneficiaries. By
            learning from this network information, GNNs can identify patterns
            and anomalies indicative of fraudulent behaviour.
          </p>
          <p>
            <center>
              <ZoomImage src={img2} alt="img2" width="90%" />{" "}
            </center>
          </p>
          <p>
            By incorporating graph analytics and graph neural networks into the
            fraud detection strategies, insurance companies can enhance their
            ability to identify and mitigate fraudulent activities, leading to
            reduced financial losses and more affordable healthcare services for
            everyone.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
            <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ICFD/ICFD.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/rohitrox/healthcare-provider-fraud-detection-analysis"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dataset
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
    </SolutionContainerWrapper>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

const HeaderCol = styled(TableCell)`
  background-color: #00afb9;
  color: white;
  min-width: 100px;
  text-align: center;
  height: 5px;
`;
const Cell = styled(TableCell)`
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;

const HeaderCell = styled(TableCell)`
  background-color: #00afb9;
  color: white;
  min-width: 100px;
  height: 5px;
  text-align: left;
`;

const StyledTable = styled(Table)`
  margin-left: auto;
  margin-right: auto;
`;
