import bg from '../../../assets/images/fasterai/fasterai-1.webp';

const MainViewStyle = (theme) => ({
  invertImageColor: { filter: 'invert(1)' },
  alert: {
    height: '50',
    width: '100%',
    marginTop: '5px',
    fontSize: '10px',
  },
  h1Heading: {
    fontSize: "65px",
    fontWeight: 600,
    color: "white",
  },
  tableMargin: {
    margin: '8px',
  },
  linkButton: {
    textDecoration: 'none',
    color: 'inherit',
  },
  margin10: {
    margin: '10px',
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    color: "gray",
  },
  width100: {
    width: '100%',
  },
  margin2: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  fasterAiContainer: {
    margin: '65px 20px 20px 20px',
    h1: {
      color: "#ffff",
    },
  },
  fasterAiRoot: {
    fontSize: '16px',
    textAlign: 'center',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#fff',
    '&::before': {
      content: "",
      position: 'absolute',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      backgroundColor: 'rgba(0,0,0,0.25)',
    },
  },
  fasterAiBtn: {
    width: "100%",
    borderWidth: "1px",
    fontSize: "15px",
    '&:hover': {
      background: 'linear-gradient(45deg, #EB3349 30%, #F45C43 90%)',
      color: '#ffffff',
      borderWidth: "1px",
      borderColor: "transparent",
    },

  },
  resultTitle: {
    fontFamily: 'Titillium Web, sans-serif',
    color: '#4a4a4a',
    margin: '0 0 26px',
    fontWeight: '600',
    '&:hover': {
      color: '#ff5325',
    },
  },
  downloadIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '5px',
    textDecoration: 'none',
  },
  modelString: { textAlign: 'justify', margin: '20px' },
  width80: {
    width: '80%',
  },
  leftText: {
    textAlign: "left",
  },
  tableMargin: {
    margin: '8px',
  },
});
export default MainViewStyle;
