import React from "react";
import PropTypes from "prop-types";
import { Layout } from "../../styles/common";

const MainLayout = ({ children }) => <Layout>{children}</Layout>;

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]).isRequired,
};
