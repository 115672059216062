import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setInputData } from "../../../../../../store/actions/vnet";
import { FormControlLabelInfo, StyledInput } from "../../InputForm";
import { StyledFormControl } from "../Dataviz/ScatterPlot";
import {
  LRContainer,
  CommonCheckbox,
} from "./LogisticRegression";
import { validateForm, isObjKeyhasval } from '../../util';
import { subFeatureData } from "../../constants";
import InfoToolTip from "../../components/InfoToolTip";

export const SVCForm = memo((props) => {
  const { userInputData, isCanvasClicked = false } = useSelector(
    (state) => state.vnet,
  );
  const { initialData = null } = subFeatureData.Random_Forest;

  const [isDisabled, setInputDisable] = useState("Default");
  const [dropdownList, setdropdownList] = useState(null);
  const [defaultParams, setdefaultParams] = useState(userInputData);
  const [disableModelInfo, setDisableModelInfo] = useState(isCanvasClicked);
  const solverListData = {
    criterion: ["gini", "entropy"],
  };
  const [isEditable, setIsEditable] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const validationData = {
    random_state: { maxNum: 100000, onlyNum: true },
    C: { minNum: 1 },
    degree: { minNum: 3 },
    max_iter: { minNum: -1 },
  };

  const handleFieldError = (errData) => {
    setFieldError({
      ...fieldError,
      ...errData,
    });
  };

  const handleFormReset = () => {
    dispatch(setInputData({
      ...initialData,
    }));
    setInputDisable("Default");
  };

  const isFormValid = () => {
    const errorObj = {};
    const validateObj = {
      target: userInputData?.target,
      Name: userInputData?.ModelDetail?.Name,
      Description: userInputData?.ModelDetail?.Description,
    };
    Object.keys(validateObj).map((key) => {
      if (!validateObj[key]) {
        errorObj[key] = 'Required Field';
      }
    });
    if (userInputData?.columns.length == 0) {
      errorObj.columns = "Required Field";
    }
    const errData = { ...fieldError, ...errorObj };
    setFieldError(errData);

    return isObjKeyhasval(errData);
  };
  const { validateOnBlur } = validateForm(validationData, handleFieldError);
  const dispatch = useDispatch();

  const getCheckboxVal = (checked) => (checked ? "True" : "False");

  const handleOnChange = (e) => {
    console.log(e);
    const { value, name, checked = false } = e.target;
    const dataId = e.currentTarget.dataset.id;

    switch (dataId) {
      case "target":
        dispatch(
          setInputData({
            ...userInputData,
            target: value,
          }),
        );
        break;
      case "model_detail":
        dispatch(
          setInputData({
            ...userInputData,
            ModelDetail: {
              ...userInputData.ModelDetail,
              [name]: value,
            },
          }),
        );
        break;
      case "model_param":
        dispatch(
          setInputData({
            ...userInputData,
            model_param: {
              ...userInputData.model_param,
              [name]:
                ["break_ties", "shrinking", 'probability'].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        if (name == "penalty") {
          setSolverList(solverListData[value]);
        }
        break;
      case "split_param":
        dispatch(
          setInputData({
            ...userInputData,
            split_param: {
              ...userInputData.split_param,
              [name]:
                ["shuffle"].indexOf(name) > -1
                  ? getCheckboxVal(checked)
                  : value,
            },
          }),
        );
        break;
      case "isDisabled":
        setInputDisable(value);
        if (value == "Default") {
          dispatch(
            setInputData({
              ...userInputData,
              ...defaultParams,
            }),
          );
        }
        break;
      case "isEditable":
        if (checked) {
          if (confirm(`Editing the existing model details will create a new model run and version. Do you want to continue ?`)) {
            setDisableModelInfo(false);
            setIsEditable(checked);
          }
        } else {
          setDisableModelInfo(true);
          setIsEditable(checked);
        }
        break;
      default:
        break;
    }
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false });
    }
  };

  const handleMultiple = (e) => {
    const {
      target: { value },
    } = e;

    dispatch(
      setInputData({
        ...userInputData,
        columns: typeof value === "string" ? value.split(",") : value,
      }),
    );
    if (fieldError.columns) {
      setFieldError({ ...fieldError, columns: false });
    }
  };

  useEffect(async () => {
    const dropdownList = await props.getCategories();
    if (dropdownList) {
      setdropdownList(JSON.parse(dropdownList));
    }
  }, []);

  return (
    <LRContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Name"
            label="Name"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Name}
            inputProps={{ "data-id": "model_detail" }}
            error={fieldError.Name}
            helperText={fieldError.Name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledInput
            name="Description"
            label="Description"
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            required
            disabled={disableModelInfo}
            value={userInputData?.ModelDetail?.Description}
            inputProps={{ "data-id": "model_detail" }}
            error={fieldError.Description}
            helperText={fieldError.Description}
          />
        </Grid>
      </Grid>
      {isCanvasClicked && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={isEditable}
              onChange={handleOnChange}
              inputProps={{ "data-id": "isEditable" }}
            />
          )}
          label="Edit Model Info"
        />
      )}
      <RadioGroup
        row
        name="isDisabled"
        defaultValue="False"
        aria-label="Learn Mixture Weights"
        value={isDisabled}
        onChange={handleOnChange}
      >
        <FormControlLabel
          value="Default"
          control={
            <Radio color="primary" inputProps={{ "data-id": "isDisabled" }} />
          }
          label="Default"
          labelPlacement="end"
        />
        <FormControlLabel
          value="Custom"
          control={
            <Radio color="primary" inputProps={{ "data-id": "isDisabled" }} />
          }
          label="Custom"
          labelPlacement="end"
        />
      </RadioGroup>
      <h3><InfoToolTip info="Customize the train test split"> Split Parameters</InfoToolTip></h3>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="test_size"
            label="Test Size"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "split_param" }}
            value={userInputData?.split_param?.test_size}
            onBlur={validateOnBlur}
            error={fieldError.test_size}
            helperText={fieldError.test_size}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="random_state"
            label="Random State"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "split_param" }}
            value={userInputData?.split_param?.random_state}
            onBlur={validateOnBlur}
            error={fieldError.random_state}
            helperText={fieldError.random_state}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            control={(
              <CommonCheckbox
                checked={userInputData?.split_param?.shuffle}
                name="shuffle"
                onChange={handleOnChange}
                inputProps={{ "data-id": "split_param" }}
              />
            )}
            label="Shuffle"
          />
        </Grid>
      </Grid>
      <h3><InfoToolTip info="Customize the model"> Function Parameters</InfoToolTip></h3>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="C"
            label="C"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.C}
            onBlur={validateOnBlur}
            error={fieldError.C}
            helperText={fieldError.C}
            info="The strength of the regularization is inversely proportional to C"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="kernel"
            label="Kernel"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            select
            defaultValue="rbf"
            value={userInputData?.model_param?.Kernel}
            info="Specifies the kernel type to be used in the algorithm."
          >
            {["linear", "poly", "rbf", "sigmoid", "precomputed"]?.map((value) => (
              <MenuItem key={value} value={value} data-id="model_param">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="degree"
            label="Degree"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.degree}
            onBlur={validateOnBlur}
            error={fieldError.degree}
            type="number"
            helperText={fieldError.degree}
            info="Degree of the polynomial kernel function (‘poly’)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="max_iter"
            label="Max Iter"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ "data-id": "model_param" }}
            value={userInputData?.model_param?.max_iter}
            onBlur={validateOnBlur}
            error={fieldError.max_iter}
            helperText={fieldError.max_iter}
            info="Hard limit on iterations within solver, or -1 for no limit"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            name="decision_function_shape"
            label="Decision Function Shape"
            variant="outlined"
            disabled={isDisabled == "Default"}
            onChange={handleOnChange}
            select
            inputProps={{ "data-id": "model_param" }}
            defaultValue="ovr"
            value={userInputData?.model_param?.decision_function_shape}
            onBlur={validateOnBlur}
            error={fieldError.decision_function_shape}
            helperText={fieldError.decision_function_shape}
            info="Whether to return a one-vs-rest as all other classifiers, or the original one-vs-one decision function"
          >
            {["ovo", "ovr"]?.map((value) => (
              <MenuItem key={value} value={value} data-id="model_param">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>

        <Grid item xs={12} sm={6} md={8}>
          <FormControlLabelInfo
            control={(
              <CommonCheckbox
                checked={userInputData?.model_param?.break_ties}
                name="break_ties"
                onChange={handleOnChange}
                inputProps={{ "data-id": "model_param" }}
              />
            )}
            label="Break Ties"
            info="If true, decision_function_shape='ovr', and number of classes > 2, predict will break ties according to the confidence values"
          />
          <FormControlLabel
            control={(
              <CommonCheckbox
                checked={userInputData?.model_param?.shrinking}
                name="shrinking"
                onChange={handleOnChange}
                inputProps={{ "data-id": "model_param" }}
              />
            )}
            label="Shrinking"
          />
          <FormControlLabelInfo
            control={(
              <CommonCheckbox
                checked={userInputData?.model_param?.probability}
                name="probability"
                onChange={handleOnChange}
                inputProps={{ "data-id": "model_param" }}
              />
            )}
            label="Probability"
            info="Whether to enable probability estimates"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <StyledFormControl error={fieldError.columns}>
            <InputLabel id="model-evaluation">Train Features</InputLabel>
            <Select
              multiple
              variant="outlined"
              name="features"
              disabled={disableModelInfo}
              value={userInputData?.columns || []}
              onChange={handleMultiple}
              input={<Input />}
              fullWidth
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              renderValue={(selected) => selected.join(", ")}
            >
              {dropdownList?.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={userInputData.columns.indexOf(value) > -1}
                    disabled={
                      userInputData.columns.indexOf(userInputData.target) > -1
                    }
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
            {fieldError.columns && <FormHelperText>Required Field</FormHelperText>}
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledInput
            name="target"
            label="Target"
            variant="outlined"
            onChange={handleOnChange}
            select
            required
            disabled={disableModelInfo}
            fullWidth
            inputProps={{ "data-id": "target" }}
            value={userInputData?.target}
            error={fieldError.target}
            helperText={fieldError.target}

          >
            {dropdownList?.map((value) => (
              <MenuItem key={value} value={value} data-id="target">
                {value}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
      </Grid>
      {props.render({ handleFormReset, isFormValid })}
    </LRContainer>
  );
});
