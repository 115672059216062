import solutionIds from "./solutionIds";
import categoryIds from "./categoryIds";
import insightIds from "./insightids";

import IncidentEtiology from "../../assets/images/solutions/incident-etiology.webp";
import Cellular from "../../assets/images/solutions/cellular.webp";
import Imaging from "../../assets/images/solutions/imaging.webp";
import Treatment from "../../assets/images/solutions/treatment.webp";
import Metastasis from "../../assets/images/solutions/metastasis.webp";
import GeneticsCancer from "../../assets/images/solutions/genetics-cancer.webp";
import AppealsGrievances from "../../assets/images/solutions/grievance.webp";
import MemberInquiry from "../../assets/images/solutions/memberinquiry.webp";
import HealthcareManagementNetworks from "../../assets/images/solutions/healthcaremanagementnetworks.webp";
import BenefitManagement from "../../assets/images/solutions/benefitmanagement.webp";
import MemberFullfillment from "../../assets/images/solutions/memberfullfillment.webp";
import EligibilityCoverage from "../../assets/images/solutions/eligibilitycoverage.webp";
import PricingStrategy from "../../assets/images/solutions/pricingstrategy.webp";
import NurseHelpline from "../../assets/images/solutions/nursehelpline.webp";
import ContextualCse from "../../assets/images/solutions/contextualcse.webp";
import PrecisionMedicine from "../../assets/images/solutions/precisionmedicine.webp";
import DischargeDelays from "../../assets/images/solutions/dischargedelays.webp";
import AdvancedRcm from "../../assets/images/solutions/advancedrcm.webp";
import RealtimeReporting from "../../assets/images/solutions/realtimereporting.webp";
import ClaimDenials from "../../assets/images/solutions/claimdenials.webp";
import ImagingDrivenTesting from "../../assets/images/solutions/image-testing.webp";
import MentalHealth from "../../assets/images/solutions/mental-health.webp";
import PreopSupport from "../../assets/images/solutions/preopsupport.webp";
import MonitoringAssistance from "../../assets/images/solutions/monitoringassistance.webp";
import HealthSupport from "../../assets/images/solutions/health-support.webp";
import CollectAnalyze from "../../assets/images/solutions/collectanalyze.webp";
import Predictivemodels from "../../assets/images/solutions/predictive-model.webp";
import Supplychain from "../../assets/images/solutions/supplychain.webp";
import Riskstratification from "../../assets/images/solutions/riskstratification.webp";
import Healthmonitoring from "../../assets/images/solutions/healthmonitoring.webp";
import Interoperabilitys from "../../assets/images/solutions/interoperability.webp";
import Virtualcare from "../../assets/images/solutions/virtualcare.webp";
import Experiencejournals from "../../assets/images/solutions/journals.webp";
import Providertargeting from "../../assets/images/solutions/providertargeting.webp";
import Observationalstudies from "../../assets/images/solutions/observationalstudies.webp";
import Noninvasivesurgery from "../../assets/images/solutions/noninvasivesurgery.webp";
import Patientcentricity from "../../assets/images/solutions/patientcentricity.webp";

export default [
  {
    id: "patient-engagement-biopharma",
    title: "Patient Engagement",
    type: "secondary-category",
    subCategories: [
      "rpm",
      "improvement-engaging",
      "patient-education",
      "feedback-analysis",
    ],
    solutions: [],
  },
  {
    id: "patient-engagement-providers",
    title: "Patient Engagement",
    type: "secondary-category",
    subCategories: ["patient-portal", "online-scheduling", "kiosks"],
    solutions: [],
  },
  {
    id: "virtual-care-providers",
    title: "Virtual Care",
    type: "secondary-category",
    subCategories: ["rpm-vc"],
    solutions: [],
  },
  {
    id: "clinical-trials",
    title: "Clinical Trials",
    type: "secondary-category",
    subCategories: [
      "implementations",
      "research&development-CT",
      "customer-management-CT",
      "drug-approval",
    ],
    solutions: [],
  },
  {
    id: "advanced-analytics",
    title: "Advanced Analytics",
    type: "secondary-category",
    subCategories: ["ai-ml", "advanced-clinical-support"],
    solutions: [],
  },
  {
    id: "provider-and-payerengagement",
    title: "Provider and Payer Engagement",
    type: "secondary-category",
    subCategories: [
      "procedure&treatment-support",
      "provider-education",
      "emr-integration",
      "decision-support",
    ],
    solutions: [],
  },
  {
    id: "patient-engagement-medtech",
    title: "Patient Engagement",
    type: "secondary-category",
    subCategories: ["patient-education&support", "rpm-medtech"],
    solutions: [],
  },

  {
    id: "bioinformatics-as-a-service",
    title: "Bioinformatics As a Service",
    type: "secondary-category",
    subCategories: [
      "signal-analysis",
      "regulatory&quality",
      "variant-analysis",
      "clinical-interpretation",
      "digital-pathology",
    ],
    solutions: [],
  },
  {
    id: "imaging-driven-testing",
    title: "Imaging Driven Testing",
    type: "secondary-category",
    image: ImagingDrivenTesting,
    subCategories: [],
    solutions: [
      solutionIds.shoulderImplant,
      solutionIds.roboticSegmentation,
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.shapleyImages,
      solutionIds.activationMetastasis,
      solutionIds.retinaDamageClassification,
      solutionIds.intracranialHemorrhage,
      solutionIds.pneumoniaDiagnosis,
      solutionIds.skinCancerAnalysis,
      solutionIds.imageColorization,
      solutionIds.diabeticRetinopathy,
      solutionIds.osteoarthritisSeverityPrediction,
    ],
  },
  {
    id: "mental-health",
    title: "Mental Health",
    type: "secondary-category",
    image: MentalHealth,
    subCategories: [],
    solutions: [solutionIds.mentalHealth,
      solutionIds.mentalHealthPrevalence,
      solutionIds.suicideIdeation,
      solutionIds.dementiaPrediction,
      solutionIds.whoSuicideStatistics],
  },
  {
    id: "pre-op-support",
    title: "Pre, Peri, Post Op Support",
    type: "secondary-category",
    image: PreopSupport,
    subCategories: [],
    solutions: [
      solutionIds.roboticSegmentation,
      solutionIds.cryoTherapyAnalysis,
    ],
    insights: [insightIds.insight31],
  },
  {
    id: "experience-journals",
    title: "Experience Journals (Field Sales/ Service Apps)",
    image: Experiencejournals,
    type: "no-route",
    subCategories: [],
    solutions: [],
  },
  {
    id: "monitoring-assistance",
    title: "Predictive Monitoring and Assistance",
    type: "secondary-category",
    image: MonitoringAssistance,
    subCategories: [],
    solutions: [
      solutionIds.melanomaClassification,
      solutionIds.gastrointestinalCancer,
      solutionIds.cervicalCancerScreening,
      solutionIds.prostateCancerDetection,
      solutionIds.colonPathology,
      solutionIds.eEGClassification,
      solutionIds.osteoarthritisSeverityPrediction,
      solutionIds.eEGOnSensoryTask,
    ],
  },
  {
    id: "health-support",
    title: "Population Health Support",
    type: "secondary-category",
    image: HealthSupport,
    subCategories: [],
    solutions: [solutionIds.suicideIdeation],
    insights: [insightIds.insight31],
  },
  {
    id: "commercial-analytics",
    title: "Commercial Analytics",
    type: "secondary-category",
    subCategories: [
      "research-and-development",
      "sales-and-marketing-ca",
      "product-manufacturing",
      "ingredient-sourcing",
      "customer-management-CA",
      "warehousing",
    ],
    solutions: [],
  },
  {
    id: "commercial-solutions",
    title: "Commercial Solutions",
    type: "secondary-category",
    subCategories: [
      "commercial-it",
      "regulatory-quality",
      "sales-marketing-cs",
      "customer-service",
      "shared-service",
      "supply-chain",
    ],
    solutions: [],
  },
  {
    id: "care-management",
    title: "Care Management",
    type: "secondary-category",
    subCategories: [
      "population-health-management",
      "interoperability",
      "digitize-authorization",
    ],
    solutions: [],
  },
  {
    id: "provider-journey",
    title: "Provider Journey",
    type: "secondary-category",
    subCategories: [
      "provider-lifecycle-mgmt",
      "provider-performance",
      "provider-customer-service",
    ],
    solutions: [],
  },
  {
    id: "ancillaries",
    title: "ancilliaries",
    type: "secondary-category",
    subCategories: ["revenue-cycle-mgmt", "laboratory-info"],
    solutions: [],
  },
  {
    id: "population-health-mgmt",
    title: "Population Health Management",
    type: "secondary-category",
    subCategories: [
      "risk-stratification",
      "disease-registries",
      "care-route",
      "care-analysis",
    ],
    solutions: [],
  },

  {
    id: "collect-analyze",
    title: "Connect-Collect-Analyze",
    type: "secondary-category",
    image: CollectAnalyze,
    subCategories: [],
    solutions: [
      solutionIds.drugRecommendation,
      solutionIds.eyesStatePrediction,
      solutionIds.incisioncare,
      solutionIds.eEGClassification,
    ],
  },
  {
    id: "platform-ai",
    title: "Platform AI",
    type: "secondary-category",
    subCategories: [],
    solutions: [
      solutionIds.competitorAnalyzer,
      solutionIds.forecastingExcellence,
      solutionIds.drugDiscovery,
      solutionIds.customerSegmentation,
      solutionIds.predictingADR,
      solutionIds.geneticVariantClassification,
      solutionIds.drugCascading,
      solutionIds.primerClassifier,
      solutionIds.dataInteroperablePlatform,
      solutionIds.mirthHL7,
      solutionIds.antibioticResistancePrediction,
      solutionIds.classfyingRheumotoidArthritisPatients,
      solutionIds.predictingHeartDisease,
      solutionIds.predictingType2Diabetes,
      solutionIds.timePeriod2CompleteClinicalTrial,
      solutionIds.geneVariations,
      solutionIds.sideEffects,
      solutionIds.timelinessOfCare,
      solutionIds.clinicalnotesPreprocessor,
      solutionIds.feasibilityAnalysis,
      solutionIds.mortalityEffect,
      solutionIds.strokePrediction,
      solutionIds.predictingCerebralInfarction,
      solutionIds.textSummarizer,
      solutionIds.lungCancerComorbidities,
      solutionIds.liverDiseasePrediction,
      solutionIds.predictBioMechanical,
      solutionIds.breastCancerClassification,
      solutionIds.breastCancerProteomes,
      solutionIds.CancerLectins,
      solutionIds.drugReviewAnalyzer,
      solutionIds.predictOligomericState,
      solutionIds.frequentPharmacyVisitor,
      solutionIds.behaviouralAnalysis,
      solutionIds.genomePhenotype,
      solutionIds.genomeFrequencyAnalysis,
      solutionIds.geneSimilarity,
      solutionIds.freightCostPrediction,
      solutionIds.cryoTherapyAnalysis,
      solutionIds.cervicalCancerRisk,
      solutionIds.eyesStatePrediction,
      solutionIds.insuranceComplaintsResolution,
      solutionIds.healthCareCostEstimation,
      solutionIds.usUninsured,
      solutionIds.lifeInsurance,
      solutionIds.predictMortalityKorean,
      solutionIds.insuranceChurn,
      solutionIds.fraudClaimsDetection,
      solutionIds.medicalExpensePrediction,
      solutionIds.hospitalRecommendation,
      solutionIds.drugMOAPrediction,
      solutionIds.suicideIdeation,
      solutionIds.eEGClassification,
      solutionIds.eEGOnSensoryTask,
      solutionIds.diagnosisPredictor,
      solutionIds.healthInsuranceLeadPrediction,
      solutionIds.lengthStayHospital,
      solutionIds.providerNetworkAnalysis,
      solutionIds.medicareLocator,
      solutionIds.diseasePrognosis,
    ],
  },
  {
    id: "faster-ai",
    title: "Faster AI",
    type: "secondary-category",
    subCategories: [],
    solutions: [
      solutionIds.drugRecommendation,
      solutionIds.predictingAdClick,
      solutionIds.networkGraphAnalyzer,
    ],
  },
  {
    id: "computer-vision",
    title: "Computer Vision",
    type: "secondary-category",
    subCategories: [],
    solutions: [
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.maskDetection,
      solutionIds.brainTumorSegmentation,
      solutionIds.lungCancerComorbidities,
      solutionIds.deepScap,
      solutionIds.deepNuclie,
      solutionIds.humanproteinImageclassification,
      solutionIds.gastrointestinalCancer,
      solutionIds.cervicalCancerScreening,
      solutionIds.prostateCancerDetection,
      solutionIds.colonPathology,
      solutionIds.chestXRaysCovid19,
      solutionIds.melanomaClassification,
      solutionIds.socialDistance,
      solutionIds.activationMetastasis,
      solutionIds.retinaDamageClassification,
      solutionIds.arrhythmiaClassification,
      solutionIds.rom,
      solutionIds.diabeticRetinopathy,
      solutionIds.imageColorization,
      solutionIds.skinCancerAnalysis,
      solutionIds.bloodCellInception,
      solutionIds.osteoarthritisSeverityPrediction,
      solutionIds.pneumoniaDiagnosis,
      solutionIds.insuranceClaimPrediction,
      solutionIds.pulmonaryEmbolism,
      solutionIds.handGuesterRecognition,
    ],
  },
  {
    id: "synthetic-data",
    title: "Synthetic Data",
    type: "secondary-category",
    subCategories: [],
    solutions: [
      solutionIds.uhgBreastCancerPrediction,
    ],
  },
  {
    id: "predictive-models",
    title: "Sales Predictive Models",
    type: "secondary-category",
    image: Predictivemodels,
    subCategories: [],
    solutions: [solutionIds.forecastingExcellence],
    insights: [insightIds.insight47],
  },
  // {
  //   id: "customer-experience",
  //   title: "Customer Experience Management",
  //   type: "secondary-category",
  //   subCategories: [],
  //   solutions: [solutionIds.frequentPharmacyVisitor],
  // },
  {
    id: "supply-chain",
    title: "Supply Chain Diversification",
    type: "secondary-category",
    image: Supplychain,
    subCategories: [],
    solutions: [solutionIds.freightCostPrediction],
    insights: [insightIds.insight42, insightIds.insight43, insightIds.insight39, insightIds.insight38],
  },
  {
    id: "risk-stratification",
    title: "Patient Risks Stratification",
    type: "secondary-category",
    subCategories: [],
    image: Riskstratification,
    solutions: [
      solutionIds.chestXRaysCovid19,
      solutionIds.intracranialHemorrhage,
      solutionIds.cervicalCancerRisk,
    ],
  },
  {
    id: "health-monitoring",
    title: "Public Health Monitoring",
    type: "secondary-category",
    image: Healthmonitoring,
    subCategories: [],
    solutions: [
      solutionIds.behaviouralAnalysis,
      solutionIds.whoSuicideStatistics,
    ],
    insights: [insightIds.insight31],
  },
  {
    id: "provider-targeting",
    title: "Provider Targeting and Education",
    type: "secondary-category",
    image: Providertargeting,
    subCategories: [],
    solutions: [solutionIds.behaviouralAnalysis, solutionIds.medicalAppointmentNoShow],
    insights: [insightIds.insight33],
  },
  {
    id: "observational-studies",
    title: "RWE - Retrospective and Observational Studies",
    type: "secondary-category",
    image: Observationalstudies,
    subCategories: [],
    solutions: [solutionIds.drugReviewAnalyzer],
  },
  {
    id: "member-journey",
    title: "Member Journey",
    type: "secondary-category",
    subCategories: ["appeals-grievances", "digitize-authorization", "member-customer-service", "member-outreach"],
    solutions: [],
  },
  {
    id: "claims-processing",
    title: "Claims Processing",
    type: "secondary-category",
    subCategories: [
      "identify-frauds",
      "analytics-driven",
      "billing-payments",
      "claims-modernization",
    ],
    solutions: [],
  },
  {
    id: "care-management",
    title: "Care Management",
    type: "secondary-category",
    subCategories: ["clinical-data-interoperability"],
    solutions: [],
  },
  {
    id: categoryIds.researchAndDevelopment,
    title: "Research and Development",
    subCategories: [],
    solutions: [solutionIds.competitorAnalyzer, solutionIds.drugDiscovery],
  },
  {
    id: "interoperability",
    title: "Interoperability",
    type: "secondary-category",
    image: Interoperabilitys,
    subCategories: [],
    solutions: [solutionIds.dataInteroperablePlatform],
  },
  {
    id: "virtual-care",
    title: "At Home and Virtual Care",
    type: "secondary-category",
    image: Virtualcare,
    subCategories: [],
    solutions: [solutionIds.faceEmotionRecognizer, solutionIds.heartSoundsClassification],
  },
  {
    id: "provider-service",
    title: "Provider Services",
    type: "secondary-category",
    subCategories: [],
    solutions: [
      solutionIds.maskDetection,
      solutionIds.networkGraphAnalyzer,
      solutionIds.eyesStatePrediction,
    ],
  },
  {
    id: "appeals-grievances",
    title: "Grievance & Appeals",
    type: "secondary-category",
    subCategories: [],
    image: AppealsGrievances,
    solutions: [solutionIds.ocrCheckValidation],
  },
  {
    id: "member-inquiry",
    title: "Member Inquiry",
    type: "secondary-category",
    subCategories: [],
    image: MemberInquiry,
    solutions: [solutionIds.insuranceComplaintsResolution, solutionIds.insuranceChurn, solutionIds.healthInsuranceLeadPrediction],
  },
  {
    id: "healthcare-mgmt-networks",
    title: "Healthcare Management Networks",
    type: "secondary-category",
    subCategories: [],
    image: HealthcareManagementNetworks,
    solutions: [solutionIds.lengthStayHospital,
      solutionIds.fraudClaimsDetection,
      solutionIds.usUninsured,
      solutionIds.medicareLocator,
      solutionIds.providerNetworkAnalysis],
    insights: [insightIds.insight6],
  },
  {
    id: "precision-medicine",
    title: "Precision Medicine",
    type: "secondary-category",
    image: PrecisionMedicine,
    subCategories: [],
    solutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.diagnosisPredictor,
    ],
  },
  {
    id: "non-invasive-surgery",
    title: "Non-Invasive Surgery",
    type: "secondary-category",
    image: Noninvasivesurgery,
    subCategories: [],
    solutions: [
      solutionIds.brainTumorSegmentation,
    ],
  },
  {
    id: "patient-centricity",
    title: "Patient Centricity: Claims/Clinical/ Device/Wearables PRO",
    type: "secondary-category",
    image: Patientcentricity,
    subCategories: [],
    solutions: [
      solutionIds.retweetCountPredictor,
    ],
  },
  {
    id: "connected-solutions",
    title: "Connected Solutions",
    type: "secondary-category",
    subCategories: ["testing-validation", "legacy-modernization"],
    solutions: [],
  },
  {
    id: "benefit-management",
    title: "Benefit Management/Claims Inquiry",
    type: "secondary-category",
    image: BenefitManagement,
    subCategories: [],
    solutions: [solutionIds.insuranceComplaintsResolution, solutionIds.healthCareCostEstimation],
  },
  {
    id: "member-fullfillment",
    title: "Member Fulfillment/Impactful Wrap-Up",
    type: "secondary-category",
    image: MemberFullfillment,
    subCategories: [],
    solutions: [solutionIds.insuranceChurn, solutionIds.healthInsuranceLeadPrediction,
    ],
  },
  {
    id: "eligibility-coverage",
    title: "Eligibility & Criteria",
    type: "secondary-category",
    image: EligibilityCoverage,
    subCategories: [],
    solutions: [solutionIds.medicalExpensePrediction,
      solutionIds.lifeInsurance,
      solutionIds.healthCareCostEstimation,
    ],
  },
  {
    id: "pricing-strategy",
    title: "Pricing Strategy",
    type: "secondary-category",
    image: PricingStrategy,
    subCategories: [],
    solutions: [solutionIds.insuranceClaimPrediction, solutionIds.medicalExpensePrediction],
  },
  {
    id: "nurse-helpline",
    title: "Nurse Helpline",
    image: NurseHelpline,
    subCategories: [],
    type: "no-route",
    solutions: [],
  },
  {
    id: "contextual-cse",
    title: "Contextual CSR",
    image: ContextualCse,
    subCategories: [],
    type: "no-route",
    solutions: [],
  },
  {
    id: "discharge-delays",
    title: "Improving Discharge Delays",
    type: "secondary-category",
    image: DischargeDelays,
    subCategories: [],
    solutions: [solutionIds.devReadmission],
  },
  {
    id: "advanced-rcm",
    title: "Advance RCM",
    type: "secondary-category",
    image: AdvancedRcm,
    subCategories: [],
    solutions: [],
  },
  {
    id: "real-time-reporting",
    title: "Real-Time Reporting",
    image: RealtimeReporting,
    subCategories: [],
    type: "no-route",
    solutions: [],
  },
  {
    id: "claim-denials",
    title: "Claim Denials",
    image: ClaimDenials,
    subCategories: [],
    type: "no-route",
    solutions: [],
  },
  // for oncology categories
  {
    id: "incidence-etiology",
    title: "Incidence & Etiology of Cancer",
    type: "secondary-category",
    info: "Defining cancer, where cancer starts, identifying the most common types of cancer, and identifying the risk factors for the common types of cancer. The number of people expected to be diagnosed 15 years from now, in 2030, is almost 22 million people",
    image: IncidentEtiology,
    subCategories: ["etiology-of-cancer", "cancer-incidence", "common-cancers"],
    solutions: [],
  },
  {
    id: "genetics-and-cancer",
    title: "Genetics & Cancer",
    type: "secondary-category",
    info: "Some types of cancer run in certain families, but most cancers are not clearly linked to the genes we inherit from our parents. Gene changes that start in a single cell over the course of a person's life cause most cancers. Genes carry the instructions to make proteins, which do much of the work in our cells",
    image: GeneticsCancer,
    subCategories: ["genetics", "genetics-variation", "genomic-instability"],
    solutions: [],
  },
  {
    id: "cellular-hallmarks",
    title: "Hallmarks of Cancer",
    type: "secondary-category",
    info: "The Hallmarks of Cancer is a seminal peer-reviewed article published in the journal 'Cell' by the cancer researchers Douglas Hanahan and Robert Weinberg. The complexity of cancer can be reduced to a small number of underlying principles shared by all cancers.There are common traits (hallmarks) that govern the transformation of normal cells to cancer (malignant or tumour) cells. These hallmarks are properties common to all cancers. The 10 cellular hallmarks of cancer, distinguish a cancer cell from a normal cell and make a cancer cell more fit for competing, surviving, and reproducing in the body",
    image: Cellular,
    subCategories: [],
    solutions: [solutionIds.activationMetastasis, solutionIds.replicativeImmortility, solutionIds.genomeInstability, solutionIds.evasionGrowthSuppressorSignals, solutionIds.resistanceCellDeath, solutionIds.sustainedProliferation, solutionIds.alteredMetabolism, solutionIds.avoidingImmuneDestruction, solutionIds.tumorPromotingInflammation, solutionIds.AngiogenesisBloodVessel],
  },
  {
    id: "metastasis",
    title: "Metastasis – The Real Killer",
    type: "secondary-category",
    info: "Metastasis is the process which tumor cells go from a primary tumor such as a prostate tumor to a different organ such as bone. Prostate cancer growing in the prostate is an example of a primary tumor. Whereas prostate cancer cells that have gone to the bone and begun growing there is an example of a metastatic prostate tumor. Metastatic is an adjective describing a cancer that metastasizes. Metastases refers to multiple metastatic tumors",
    image: Metastasis,
    subCategories: ["metastasis", "tnm-staging", "metastatic-process"],
    solutions: [],
  },
  {
    id: "imaging",
    title: "Imaging in Oncology",
    type: "secondary-category",
    info: "Medical imaging is defined as the technique and process of creating visual representations of the inside of the body for clinical analysis, and medical intervention. Imaging orientation does important so that you can understand the view that's taken.",
    image: Imaging,
    subCategories: ["types-ofimaging", "clinical-oncology", "theory-oligometastasis"],
    solutions: [],
  },
  {
    id: "treatment",
    title: "Treatment",
    type: "secondary-category",
    info: "There are many treatment types for cancer. They include surgery, radiation, hormonal therapy, chemotherapy, targeted therapy, and immunotherapy.",
    image: Treatment,
    subCategories: ["types-of-treatment", "individual-cancers", "clinical-trails"],
    solutions: [],
  },
];
