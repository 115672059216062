import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import calorie_predictor_sol1 from "../../../assets/images/app/calorie_predictor_main.webp";
import calorie_predictor_sample1 from "../../../assets/images/app/calorie_1.webp";
import calorie_predictor_sample2 from "../../../assets/images/app/calorie_2.webp";

const { getQueryResult, uploadFile } = CommonService("z1dApps", "caloriePredictor");

const CaloriePredictor = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [imageResult, setImageResult] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(res.result);
        setImageResult(res.image_url);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = (e) => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res.result);
        setImageResult(res.image_url);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Due to the improvement in people’s living standards, obesity rates are increasing at an alarming speed, and this is reflective of the risks in people’s health. People need to control their daily calorie intake by eating healthier foods, which is the most basic method to avoid obesity. Thus, scientists started to use machine learning algorithms in computer vision to help people determine the caloric value of the food they eat. </p>
          <p>People often take pictures of their food before they eat and put it on social media, well the solution lies in that very process. We propose to estimate the calorie content in the user-provided image by identifying the food and estimating the quantity using deep learning.</p>
          <p>To give an estimation of the calories an accurate object detection combined with accurate IoU (intersection over the union) is required. Therefore, Instance Segmentation is used in which we will be performing object detection using Mask R CNN followed by Calorie Estimation.</p>
          <strong>Model Architecture :</strong>
          <br />
          {' '}
          <br />
          <center>
            <Grid item xs={10} sm={10} md={10}>
              <img width="60%" src={calorie_predictor_sol1} />
            </Grid>
          </center>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/calorie_estimation/calorie_estimation.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>

              <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/binayakpokhrel/datasets"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <Image src={calorie_predictor_sample1} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample_image1"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Image src={calorie_predictor_sample2} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample_image2"
                onClick={getsampleResult}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grow in={result} timeout={1500}>
            <div>
              <section>
                <Result><strong>Result : </strong></Result>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={12} sm={7} md={8}>
                    <img
                      width="100%"
                      height="500px"
                      src={imageResult}
                      alt=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={4}>
                    <List>
                      <Typography gutterBottom>{result[0]}</Typography>
                      <br />
                      {result.map((result, index) => (
                        index > 0
                                                    && (
                                                    <ListItem>
                                                      <ListItemIcon>
                                                        <ArrowForwardIosIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary={result} />
                                                    </ListItem>
                                                    )
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </section>
            </div>
          </Grow>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(CaloriePredictor);

const Image = styled.img`
  width: 80%;
  height: 150px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 200px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
