import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import styled from "styled-components";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";

const PredictingADR = () => {
  const initialData = {
    pubchemId: "",
    strainEnergy: "",
    energyPE: "",
    pmiMag: "",
    wiener: "",
    pmiY: "",
    pmiZ: "",
    jurs: "",
    eDistMag: "",
    vDistMag: "",
    jursWnsa: "",
    eDist: "",
    vDist: "",
    vitaminB12: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [isError, setIsError] = useState();
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("clinicalTrials", "predictingADR");

  const showResult = () => {
    const NewDataSet = {
      pubchemId: "1",
      strainEnergy: "5.31",
      energyPE: "6.08",
      pmiMag: "2.597550e+02",
      wiener: "170.0",
      pmiY: "1.772870e+02",
      pmiZ: "1.867240e+02",
      jurs: "2.058750e+02",
      eDistMag: "628.061",
      vDistMag: "796.960",
      jursWnsa: "-2.848890e+01",
      eDist: "2.471550e+02",
      vDist: "3.286790e+02",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      pubchemId: "12",
      strainEnergy: "1075.65",
      energyPE: "2077.92",
      pmiMag: "1.009950e+06",
      wiener: "993516.0",
      pmiY: "688870.000",
      pmiZ: "736953.000",
      jurs: "553108.000",
      eDistMag: "966393.000",
      vDistMag: "916777.000",
      jursWnsa: "-256227.0000",
      eDist: "391137.000",
      vDist: "373143.00",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      pubchemId,
      strainEnergy,
      energyPE,
      pmiMag,
      wiener,
      pmiY,
      pmiZ,
      jurs,
      eDistMag,
      vDistMag,
      jursWnsa,
      eDist,
      vDist,
    } = sampleData;

    const params = `${pubchemId},${strainEnergy},${energyPE},${pmiMag},${wiener},${pmiY},${pmiZ},${jurs},${eDistMag},${vDistMag},${jursWnsa},${eDist},${vDist}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res.result);
          setSpinner(false);
          setResultData(res.result);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            An adverse drug reaction (ADR) is an injury caused by taking
            medication. ADRs may occur following a single dose or prolonged
            administration of a drug or result from the combination of two or
            more drugs. When patients suffer unintended reactions to medicines,
            it can be both dangerous for the individual and costly to society.
            <br />
            <br />
            Using the datasets available in SIDER and PUBCHEM, a ML
            Classification model is developed based on anatomical schema that
            would predict Adverse Side effects of a drug in Digestive system.
            The inputs considered while creating the model were 2-D Molecular
            Descriptors and 3-D Molecular Descriptors. Using feature importance,
            it is identified the top 12 columns that are important in predicting
            ADR. OnevsRest Classifier (Random Forest) is used to train the
            model.
          </p>
          <h5>Inputs Description</h5>
          <ul>
            <li>
              <b>Pubchem_id: </b>
              {' '}
              ID of the drug
            </li>
            <li>
              <b>Strain_Energy: </b>
              {' '}
              Strain energy is released when the
              constituent atoms are allowed to rearrange themselves in a
              chemical reaction.
            </li>
            <li>
              <b>Energy: </b>
              {' '}
              Energy, potential energy, is stored in the
              covalent bonds holding atoms together in the form of molecules.
            </li>
            <li>
              <b>PMI_mag: </b>
              Magnitude of principle moments of inertia about
              the principle axes of a molecule and evaluates the symmetry of top
              of the molecule
            </li>
            <li>
              <b>Wiener: </b>
              Wiener is a topological index of a molecule,
              defined as the sum of the lengths of the shortest paths between
              all pairs of vertices in the chemical graph representing the
              non-hydrogen atoms in the molecule.
            </li>
            <li>
              <b>PMI_Y: </b>
              {' '}
              Principle moment of inertia along Y-axes
            </li>
            <li>
              <b>PMI_Z: </b>
              Principle moment of inertia along Z-axes
            </li>
            <li>
              <b>Jurs_WPSA_2: </b>
              {' '}
              Surface-weighted charged partial surface
              areas
            </li>
            <li>
              <b>E_DIST_mag: </b>
              Edge Distance/Magnitude
            </li>
            <li>
              <b>V_DIST_mag: </b>
              {' '}
              Vertex Distance/Magnitude
            </li>
            <li>
              <b>Jurs_WNSA_2:</b>
              {' '}
              Surface-weighted charged partial surface areas
            </li>
            <li>
              <b>E_DIST_equ: </b>
              Edge adjacency/equality
            </li>
            <li>
              <b>V_DIST_equ: </b>
              Vertex distance/equality
            </li>
          </ul>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_Adverse_drug_reaction"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_Adverse_drug_reaction/data"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <br />
          <section>
            <Grid container>
              <Grid item xs={12} md={6}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Id"
                          name="pubchemId"
                          value={sampleData.pubchemId}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Strain_Energy"
                          name="strainEnergy"
                          value={sampleData.strainEnergy}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Energy (PE)"
                          name="energyPE"
                          value={sampleData.energyPE}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="PMI_mag"
                          name="pmiMag"
                          value={sampleData.pmiMag}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Wiener"
                          name="wiener"
                          value={sampleData.wiener}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="PMI_Y"
                          name="pmiY"
                          value={sampleData.pmiY}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="PMI_Z"
                          name="pmiZ"
                          value={sampleData.pmiZ}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Jurs_WPSA_2"
                          name="jurs"
                          value={sampleData.jurs}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="E_DIST_mag"
                          name="eDistMag"
                          value={sampleData.eDistMag}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="V_DIST_mag"
                          name="vDistMag"
                          value={sampleData.vDistMag}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Jurs_WNSA_2"
                          name="jursWnsa"
                          value={sampleData.jursWnsa}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="E_DIST_equ"
                          name="eDist"
                          value={sampleData.eDist}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="V_DIST_equ"
                          name="vDist"
                          value={sampleData.vDist}
                          onChange={onChangeFields}
                          type="float"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <StyledDiv align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                    </StyledDiv>
                  </BorderGray>
                </section>

                <br />
                <section>
                  <StyledDiv align="right">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </StyledDiv>
                </section>
              </Grid>
              <StyledGrid item xs={12} md={5}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && (
                  <section>
                    <Spinner text="Loading..." />
                  </section>
                )}
                {resultData && (
                  <section>
                    <h5>
                      The possible side effects affecting the DIGESTIVE System
                      are
                    </h5>

                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <h3>S.No</h3>
                            </TableCell>
                            <TableCell>
                              <h3>Adverse Effect</h3>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {resultData.map((result, index) => (
                            <TableRow key={result}>
                              <TableCell>
                                <b>{index + 1}</b>
                              </TableCell>
                              <TableCell>
                                <b>{result}</b>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </section>
                )}
              </StyledGrid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictingADR);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
const StyledDiv = styled.div`
  text-align: right;
  @media only screen and (max-width: 959px) {
    text-align: center;
  }
`;
const StyledGrid = styled(Grid)`
  @media only screen and (min-width: 960px) {
    padding-left: 100px;
  }
`;
