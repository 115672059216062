import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  CardContent,
  Card,
  CardMedia,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

// import local components
import styled from "styled-components";
import Navbar from "../common/Navbar";
import MainLayout from "../common/MainLayout";
import { StyledButton } from "../../styles/common";
import Footer from "../views/home/Footer";

// images
import vNetHome2 from "../../assets/images/app/vNetHome2.webp";
import vNetHome3 from "../../assets/images/app/vNetHome3.webp";
import vNetHome4 from "../../assets/images/app/vNetHome4.webp";
import vNetHome5 from "../../assets/images/app/vNetHome5.webp";
import vNetHome6 from "../../assets/images/app/vNetHome6.webp";

const vNetHome = () => {
  const [open, setOpen] = useState(false);
  const showDetails = (e) => {
    if (showDetails !== e.target.textContent) {
      setOpen(e.target.textContent);
    } else {
      setOpen(false);
    }
  };
  return (
    <MainLayout>
      <Navbar />
      <Container>
        <VNetContent>
          <Grid container direction="row" alignContent="flex-start">
            <Grid item xs={12} md={3}>
              <VNetHeading1>
                vLife™
                {' '}
                <font color="#fc7703">|</font>
                {' '}
                vNet
              </VNetHeading1>
            </Grid>
            <Grid item xs={12} md={7}>
              <VNetHeading3>
                AI Enterprise for Building Models
                <VNetHeading2>
                  SMARTER
                  {' '}
                  <font color="white">|</font>
                  {' '}
                  FASTER
                  {" "}
                  <font color="white">|</font>
                  {' '}
                  BETTER
                </VNetHeading2>
              </VNetHeading3>
            </Grid>
          </Grid>
        </VNetContent>
        <VNetContent1>
          <Content1Grid container justifyContent="center">
            <CircleGrid item xs={3} sm={3}>
              <VNetList
                isActive={open === "Data"}
                button
                onClick={showDetails}
              >
                <ListItemText>
                  <Text variant="h6" display="block">
                    Data
                  </Text>
                </ListItemText>
              </VNetList>
            </CircleGrid>
            <CircleGrid item xs={3} sm={3}>
              <VNetList
                isActive={open === "Artificial Intelligence"}
                button
                onClick={showDetails}
              >
                <ListItemText>
                  <Text variant="h6" display="block">
                    Artificial Intelligence
                  </Text>
                </ListItemText>
              </VNetList>
            </CircleGrid>
            <CircleGrid item xs={3} sm={3}>
              <VNetList
                isActive={open === "Business"}
                button
                onClick={showDetails}
              >
                <ListItemText>
                  <Text variant="h6" display="block">
                    Business
                  </Text>
                </ListItemText>
              </VNetList>
            </CircleGrid>
          </Content1Grid>
          <Grid container>
            <Grid item xs={12}>
              {open ? (
                <VNetPaper elevation={3}>
                  {open === "Data" && (
                    <StyledCard>
                      <Tile>
                        <CardMedia
                          component="img"
                          sx={{ width: "auto" }}
                          image={vNetHome2}
                          alt="Card Image"
                        />
                        <br />
                        <CardTitle component="div" variant="h7">
                          <strong> Synthetic Data Generator</strong>
                        </CardTitle>
                      </Tile>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <CardDescription
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                          >
                            vNet - Synthetic Data Generator is a platform to
                            create artificial data by using different algorithms
                            that mirror the statistical properties of the
                            original data but does not reveal any information
                            regarding real people. Generating synthetic data
                            using this platform enables business users to
                            maintain privacy, successfully carry out product
                            testing and train machine learning algorithms. The
                            platform can be used when there is a business
                            trade-off between data privacy and data utility
                            while selecting a privacy-enhancing technology.
                          </CardDescription>
                          <StyledButton
                            component={Link}
                            to="/synthetic-data"
                            variant="contained"
                            color="primary"
                          >
                            Explore
                          </StyledButton>
                        </CardContent>
                      </Box>
                    </StyledCard>
                  )}
                  {open === "Artificial Intelligence" && (
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <StyledCard>
                          <Tile>
                            <CardMedia
                              component="img"
                              sx={{ width: "auto" }}
                              image={vNetHome3}
                              alt="Card Image"
                            />
                            <br />
                            <CardTitle component="div" variant="h7">
                              <strong> Faster AI</strong>
                            </CardTitle>
                          </Tile>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <CardDescription
                                variant="subtitle1"
                                color="text.secondary"
                                component="div"
                              >
                                vNet - Faster AI is an intuitive codeless AI
                                platform to build, train, deploy and optimize ML
                                models. It leverages applications developed with
                                Stanford University to create noise-free and
                                unbiased datasets.
                                {' '}
                                <i>(In-progress) </i>
                              </CardDescription>
                              <StyledButton
                                component={Link}
                                to="/solutions/faster-ai"
                                variant="contained"
                                color="primary"
                              >
                                Explore
                              </StyledButton>
                            </CardContent>
                          </Box>
                        </StyledCard>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <StyledCard>
                          <Tile>
                            <CardMedia
                              component="img"
                              sx={{ width: "auto" }}
                              image={vNetHome4}
                              alt="Card Image"
                            />
                            <br />
                            <CardTitle component="div" variant="h7">
                              <strong>   Computer Vision</strong>
                            </CardTitle>
                          </Tile>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <CardDescription
                                variant="subtitle1"
                                color="text.secondary"
                                component="div"
                              >
                                vNet - Computer Vision is a No Code/Low Code
                                Image analytics platform to boost Industry
                                solutions and optimize performance, cost and
                                efficiency.
                                <i>(In-progress)</i>
                              </CardDescription>
                              <StyledButton
                                component={Link}
                                to="/categories/offerings/computer-vision"
                                variant="contained"
                                color="primary"
                              >
                                Explore
                              </StyledButton>
                            </CardContent>
                          </Box>
                        </StyledCard>
                      </Grid>
                    </Grid>
                  )}
                  {open === "Business" && (
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <StyledCard>
                          <Tile>
                            <CardMedia
                              component="img"
                              sx={{ width: "auto" }}
                              image={vNetHome5}
                              alt="Card Image"
                            />
                            <br />
                            <CardTitle component="div" variant="h7">
                              <strong> Platform AI</strong>
                            </CardTitle>
                          </Tile>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <CardDescription
                                variant="subtitle1"
                                color="text.secondary"
                                component="div"
                              >
                                vNet - Platform AI is a business model designer
                                to piece together pre-trained AI-models from the
                                vLife Marketplace. It would enable Business
                                Analysts to put together end-to-end solutions
                                {" "}
                                <i>(In-progress)</i>
                              </CardDescription>
                              <StyledButton
                                component={Link}
                                to="/themes/platform-ai"
                                variant="contained"
                                color="primary"
                              >
                                Explore
                              </StyledButton>
                            </CardContent>
                          </Box>
                        </StyledCard>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <StyledCard>
                          <Tile>
                            <CardMedia
                              component="img"
                              sx={{ width: "auto" }}
                              image={vNetHome6}
                              alt="Card Image"
                            />
                            <br />
                            <CardTitle component="div" variant="h7">
                              <strong> Innovation as a Service</strong>
                            </CardTitle>
                          </Tile>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <CardDescription
                                variant="subtitle1"
                                color="text.secondary"
                                component="div"
                              >
                                vNet – Innovation as a Service is an AI platform
                                to intuitively select 3rd party applications and
                                software across MLOps, Transfer Learning and
                                Federated Learning
                                {' '}
                                <i>(In-progress)</i>
                              </CardDescription>
                              <StyledButton
                                component={Link}
                                to="/innovation-as-service"
                                variant="contained"
                                color="primary"
                              >
                                Explore
                              </StyledButton>
                            </CardContent>
                          </Box>
                        </StyledCard>
                      </Grid>
                    </Grid>
                  )}
                </VNetPaper>
              ) : (
                <p>&nbsp;</p>
              )}
            </Grid>
          </Grid>
        </VNetContent1>
      </Container>
      <Footer showCart={false} />
    </MainLayout>
  );
};
export default vNetHome;
const VNetContent = styled.div`
  padding: 4% 0 0 0;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding: 10% 0 0 0;
  }
  @media only screen and (max-width: 350px) {
    padding: 15% 0 0 0;
  }
`;
const VNetContent1 = styled.div`
  @media only screen and (max-width: 959px) {
    padding: 5% 5% 0%;
  }
  @media only screen and (max-width: 750px) {
    padding: 5% 5% 0%;
    margin: 5% 0% 0%;
  }
  @media only screen and (max-width: 750px) {
    padding: 5% 5% 0%;
    margin: 5% 0% 0%;
  }
`;
const VNetHeading1 = styled.h2`
  font-size: 30px;
  font-weight: bolder;
  color: white;
  @media only screen and (max-width: 959px) {
    padding: 0% 0%;
    margin: 8% 0% 0% 0%;
  }
  @media only screen and (max-width: 600px) {
    font-size: 22px;
    margin: 6% 0% 0% 0%;
  }
  @media only screen and (max-width: 450px) {
    font-size: 18px;
  }
`;
const VNetHeading2 = styled(Typography)`
  &.MuiTypography-root {
    font-size: 35px;
    font-weight: bolder;
    color: #fc7703;
    @media only screen and (max-width: 600px) {
      font-size: 25px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 19px;
    }
  }
`;
const VNetHeading3 = styled.h2`
  font-size: 25px;
  font-weight: bolder;
  color: white;
  padding: 4% 0%;
  @media only screen and (max-width: 959px) {
    padding: 0% 0%;
    margin: 0;
  }
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
const VNetPaper = styled(Paper)`
  height: fit-content;
  max-height: 300p;
  padding: 30px;
  border-radius: 20px;
  @media only screen and (max-width: 650px) {
    padding: 10px;

  }
`;

const VNetList = styled(ListItem)`
  &.MuiListItem-root {
    @media only screen and (max-width: 950px) {
      height: 180px;
      width: 180px;
    }
    @media only screen and (max-width: 850px) {
      height: 175px;
      width: 175px;
    }
    @media only screen and (max-width: 750px) {
      height: 150px;
      width: 150px;
    }
    @media only screen and (max-width: 650px) {
      height: 125px;
      width: 125px;
    }
    @media only screen and (max-width: 500px) {
      height: 100px;
      width: 100px;
    }
    @media only screen and (max-width: 500px) {
      height: 75px;
      width: 75px;
    }
    background-color: ${(props) => (props.isActive ? "#fc7703" : "#90dbf0")};
    color: ${(props) => (props.isActive ? "white" : "black")};
    border: ${(props) => (props.isActive ? "1px solid white" : "#f44336")};
    border-radius: ${(props) => (props.isActive ? "50%" : "50%")};
    padding: ${(props) => (props.isActive ? "auto" : "auto")};
    height: ${(props) => (props.isActive ? "200px" : "200px")};
    width: ${(props) => (props.isActive ? "200px" : "200px")};
    text-align: ${(props) => (props.isActive ? "center" : "center")};
  }
  &.MuiListItem-root:hover {
    background-color: ${(props) => (props.isActive ? "#fc7703" : "#fc7703")};
    color: ${(props) => (props.isActive ? "" : "white")};
  }
`;
const Content1Grid = styled(Grid)`
  &.MuiGrid-root {
    text-align: -webkit-center;
  }
  @media only screen and (max-width: 650px) {
    margin-bottom: 20px
  }

`;
const CircleGrid = styled(Grid)`
  &.MuiGrid-root {
    margin: 0% 4%;
    @media only screen and (min-width: 600px) {
      margin: 0% 2% 2% 2%;
    }
    @media only screen and (max-width: 600px) {
      margin: 0% ​4% 5% 4%;
    }
    @media only screen and (max-width: 500px) {
      margin: 0% ​4% 5% 4%;
    }
  }
`;
const Text = styled(Typography)`
  &.MuiTypography-root {
    @media only screen and (max-width: 750px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 650px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 10px;
    }
  }
`;

const Tile = styled.div`
flex-direction: column;
width: 100%;
text-align: -webkit-center;
min-width: 250px;
@media only screen and (max-width: 1280px) {
  max-width: 300px;
}
@media only screen and (max-width: 900px) {
  min-width: 100%;
}
`;

const StyledCard = styled(Card)`
  &.MuiCard-root {
    height: 100%;
    display: flex;
   
    img {
      width: 100%;
      max-width: 250px;
      object-fit: fill;
      height: 180px;
      @media only screen and (max-width: 900px) {
        max-width: 100%;
        object-fit: contain;
        height: auto;
      }
    }
    @media only screen and (max-width: 900px) {
      flex-wrap: wrap;
      flex-direction: column;
    }


  }
`;
const CardTitle = styled(Typography)`
  &.MuiTypography-root {
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 16px;
    }
  }
`;
const CardDescription = styled(Typography)`
  &.MuiTypography-subtitle1 {
    @media only screen and (max-width: 600px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
`;
const Container = styled.div`
  background-image: url("../../assets/images/app/vNetHome1.webp");
  min-height: 80%;
  padding: 10px 20px;
`;
