import React from 'react';
import { Link } from 'react-router-dom';

import Highcharts from 'highcharts';
import sankey from 'highcharts/modules/sankey';
import drilldown from 'highcharts/modules/drilldown';
import exporting from 'highcharts/modules/exporting';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Footer from '../../views/home/Footer';
import NavBar from '../../common/Navbar';
import MainViewStyle from './main-view';

const drillDownData = {
  drilldata_1: "../../assets/files/drilldata_0.csv",
  drilldata_4: "../../assets/files/drilldata_4.csv",
  drilldata_5: '../../assets/files/drilldata_5.csv',
  drilldata_6: '../../assets/files/drilldata_6.csv',
  drilldata_7: '../../assets/files/drilldata_7.csv',
  drilldata_8: '../../assets/files/drilldata_8.csv',
  drilldata_9: '../../assets/files/drilldata_9.csv',
  drilldata_11: '../../assets/files/drilldata_11.csv',
  drilldata_12: '../../assets/files/drilldata_12.csv',
  drilldata_13: '../../assets/files/drilldata_13.csv',
  drilldata_16: '../../assets/files/drilldata_16.csv',
  drilldata_17: '../../assets/files/drilldata_17.csv',
  drilldata_18: '../../assets/files/drilldata_18.csv',
  drilldata_19: '../../assets/files/drilldata_19.csv',
  drilldata_20: '../../assets/files/drilldata_20.csv',
  drilldata_22: '../../assets/files/drilldata_22.csv',
  drilldata_23: '../../assets/files/drilldata_23.csv',
  drilldata_24: '../../assets/files/drilldata_24.csv',
  drilldata_25: '../../assets/files/drilldata_25.csv',
  drilldata_26: '../../assets/files/drilldata_26.csv',
  drilldata_27: '../../assets/files/drilldata_27.csv',
  drilldata_28: '../../assets/files/drilldata_28.csv',
  drilldata_32: '../../assets/files/drilldata_32.csv',
  drilldata_33: '../../assets/files/drilldata_33.csv',
  drilldata_34: '../../assets/files/drilldata_34.csv',
  drilldata_35: '../../assets/files/drilldata_35.csv',
  drilldata_36: '../../assets/files/drilldata_36.csv',
  drilldata_37: '../../assets/files/drilldata_37.csv',
  drilldata_38: '../../assets/files/drilldata_38.csv',
  drilldata_39: '../../assets/files/drilldata_39.csv',
  drilldata_41: '../../assets/files/drilldata_41.csv',
  drilldata_42: '../../assets/files/drilldata_42.csv',
  drilldata_43: '../../assets/files/drilldata_43.csv',
  drilldata_44: '../../assets/files/drilldata_44.csv',
  drilldata_46: '../../assets/files/drilldata_46.csv',
  drilldata_47: '../../assets/files/drilldata_47.csv',
  drilldata_48: '../../assets/files/drilldata_48.csv',
  drilldata_49: '../../assets/files/drilldata_49.csv',
  drilldata_52: '../../assets/files/drilldata_52.csv',
  drilldata_53: '../../assets/files/drilldata_53.csv',
  drilldata_54: '../../assets/files/drilldata_54.csv',
  drilldata_55: '../../assets/files/drilldata_55.csv',
  drilldata_56: '../../assets/files/drilldata_56.csv',
  drilldata_57: '../../assets/files/drilldata_57.csv',
  drilldata_58: '../../assets/files/drilldata_58.csv',
  drilldata_59: '../../assets/files/drilldata_59.csv',
  drilldata_61: '../../assets/files/drilldata_61.csv',
  drilldata_62: '../../assets/files/drilldata_62.csv',
  drilldata_63: '../../assets/files/drilldata_63.csv',
  drilldata_64: '../../assets/files/drilldata_64.csv',
  drilldata_65: '../../assets/files/drilldata_65.csv',
};

const sankeyData = {
  sankey_1: "../../assets/files/sankey_0.csv",
  sankey_4: "../../assets/files/sankey_4.csv",
  sankey_5: '../../assets/files/sankey_5.csv',
  sankey_6: '../../assets/files/sankey_6.csv',
  sankey_7: '../../assets/files/sankey_7.csv',
  sankey_8: '../../assets/files/sankey_8.csv',
  sankey_9: '../../assets/files/sankey_9.csv',
  sankey_11: '../../assets/files/sankey_11.csv',
  sankey_12: '../../assets/files/sankey_12.csv',
  sankey_13: '../../assets/files/sankey_13.csv',
  sankey_16: '../../assets/files/sankey_16.csv',
  sankey_17: '../../assets/files/sankey_17.csv',
  sankey_18: '../../assets/files/sankey_18.csv',
  sankey_19: '../../assets/files/sankey_19.csv',
  sankey_20: '../../assets/files/drilldata_20.csv',
  sankey_22: '../../assets/files/sankey_22.csv',
  sankey_23: '../../assets/files/drilldata_23.csv',
  sankey_24: '../../assets/files/sankey_24.csv',
  sankey_25: '../../assets/files/sankey_25.csv',
  sankey_26: '../../assets/files/sankey_26.csv',
  sankey_27: '../../assets/files/sankey_27.csv',
  sankey_28: '../../assets/files/sankey_28.csv',
  sankey_32: '../../assets/files/sankey_32.csv',
  sankey_33: '../../assets/files/sankey_33.csv',
  sankey_34: '../../assets/files/sankey_34.csv',
  sankey_35: '../../assets/files/sankey_35.csv',
  sankey_36: '../../assets/files/sankey_36.csv',
  sankey_37: '../../assets/files/sankey_37.csv',
  sankey_38: '../../assets/files/sankey_38.csv',
  sankey_39: '../../assets/files/sankey_39.csv',
  sankey_41: '../../assets/files/sankey_41.csv',
  sankey_42: '../../assets/files/sankey_42.csv',
  sankey_43: '../../assets/files/sankey_43.csv',
  sankey_44: '../../assets/files/sankey_44.csv',
  sankey_46: '../../assets/files/sankey_46.csv',
  sankey_47: '../../assets/files/sankey_47.csv',
  sankey_48: '../../assets/files/sankey_48.csv',
  sankey_49: '../../assets/files/sankey_49.csv',
  sankey_52: '../../assets/files/sankey_52.csv',
  sankey_53: '../../assets/files/sankey_53.csv',
  sankey_54: '../../assets/files/sankey_54.csv',
  sankey_55: '../../assets/files/sankey_55.csv',
  sankey_56: '../../assets/files/sankey_56.csv',
  sankey_57: '../../assets/files/sankey_57.csv',
  sankey_58: '../../assets/files/sankey_58.csv',
  sankey_59: '../../assets/files/sankey_59.csv',
  sankey_61: '../../assets/files/sankey_61.csv',
  sankey_62: '../../assets/files/sankey_62.csv',
  sankey_63: '../../assets/files/sankey_63.csv',
  sankey_64: '../../assets/files/sankey_64.csv',
  sankey_65: '../../assets/files/sankey_65.csv',
};

const descData = {
  desc_1: "../../assets/files/desc_0.csv",
  desc_4: "../../assets/files/desc_4.csv",
  desc_5: '../../assets/files/desc_5.csv',
  desc_6: '../../assets/files/desc_6.csv',
  desc_7: '../../assets/files/desc_7.csv',
  desc_8: '../../assets/files/desc_8.csv',
  desc_9: '../../assets/files/desc_9.csv',
  desc_11: '../../assets/files/desc_11.csv',
  desc_12: '../../assets/files/desc_12.csv',
  desc_13: '../../assets/files/desc_13.csv',
  desc_16: '../../assets/files/desc_16.csv',
  desc_17: '../../assets/files/desc_17.csv',
  desc_18: '../../assets/files/desc_18.csv',
  desc_19: '../../assets/files/desc_19.csv',
  desc_20: '../../assets/files/desc_20.csv',
  desc_22: '../../assets/files/desc_22.csv',
  desc_23: '../../assets/files/desc_23.csv',
  desc_24: '../../assets/files/desc_24.csv',
  desc_25: '../../assets/files/desc_25.csv',
  desc_26: '../../assets/files/desc_26.csv',
  desc_27: '../../assets/files/desc_27.csv',
  desc_28: '../../assets/files/desc_28.csv',
  desc_32: '../../assets/files/desc_32.csv',
  desc_33: '../../assets/files/desc_33.csv',
  desc_34: '../../assets/files/desc_34.csv',
  desc_35: '../../assets/files/desc_35.csv',
  desc_36: '../../assets/files/desc_36.csv',
  desc_37: '../../assets/files/desc_37.csv',
  desc_38: '../../assets/files/desc_38.csv',
  desc_39: '../../assets/files/desc_39.csv',
  desc_41: '../../assets/files/desc_41.csv',
  desc_42: '../../assets/files/desc_42.csv',
  desc_43: '../../assets/files/desc_43.csv',
  desc_44: '../../assets/files/desc_44.csv',
  desc_46: '../../assets/files/desc_46.csv',
  desc_47: '../../assets/files/desc_47.csv',
  desc_48: '../../assets/files/desc_48.csv',
  desc_49: '../../assets/files/desc_49.csv',
  desc_52: '../../assets/files/desc_52.csv',
  desc_53: '../../assets/files/desc_53.csv',
  desc_54: '../../assets/files/desc_54.csv',
  desc_55: '../../assets/files/desc_55.csv',
  desc_56: '../../assets/files/desc_56.csv',
  desc_57: '../../assets/files/desc_57.csv',
  desc_58: '../../assets/files/desc_58.csv',
  desc_59: '../../assets/files/desc_59.csv',
  desc_61: '../../assets/files/desc_61.csv',
  desc_62: '../../assets/files/desc_62.csv',
  desc_63: '../../assets/files/desc_63.csv',
  desc_64: '../../assets/files/desc_64.csv',
  desc_65: '../../assets/files/desc_65.csv',
};

sankey(Highcharts);
drilldown(Highcharts);
exporting(Highcharts);

class PatientJourneyForAdmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "1",
    };

    this.data = [];
    this.openTable = false;
    this.iddata = [];
    this.idd = 1;
    this.temp = "";
    this.drillid = 0;
    this.drilldata1 = [];
    this.drillresult = [];
    this.from = "";
    this.to = "";
    this.len = "";
    this.datadrill = [];
    this.sankeydescd = [];
    this.descdat = [];
    this.descda = 0;
    this.tfrom = "";
    this.tto = "";
    this.Data = [];
    this.frm = 0;
    this.end = 0;
    this.datas = [];
    this.datad = [];
    this.dropdown = {
      "All States": { value: 1 },
      Alabama: { value: 41 },
      Alaska: { value: 61 },
      Arizona: { value: 52 },
      Arkansas: { value: 46 },
      California: { value: 62 },
      Colorado: { value: 53 },
      Connecticut: { value: 4 },
      Delaware: { value: 32 },
      Florida: { value: 33 },
      Georgia: { value: 34 },
      Hawaii: { value: 63 },
      Idaho: { value: 54 },
      Illinois: { value: 16 },
      Indiana: { value: 17 },
      Iowa: { value: 22 },
      Kansas: { value: 23 },
      Kentucky: { value: 42 },
      Louisiana: { value: 47 },
      Maine: { value: 5 },
      Maryland: { value: 35 },
      Massachusetts: { value: 6 },
      Michigan: { value: 18 },
      Minnesota: { value: 24 },
      Mississippi: { value: 43 },
      Missouri: { value: 25 },
      Montana: { value: 55 },
      Nebraska: { value: 26 },
      Nevada: { value: 56 },
      "New Hampshire": { value: 7 },
      "New Jersey": { value: 11 },
      "New Mexico": { value: 57 },
      "New York": { value: 12 },
      "North Carolina": { value: 36 },
      "North Dakota": { value: 27 },
      Ohio: { value: 19 },
      Oklahoma: { value: 48 },
      Oregon: { value: 64 },
      Pennsylvania: { value: 13 },
      "Rhode Island": { value: 8 },
      "South Carolina": { value: 37 },
      "South Dakota": { value: 28 },
      Tennessee: { value: 44 },
      Texas: { value: 49 },
      Utah: { value: 58 },
      Vermont: { value: 9 },
      Virginia: { value: 38 },
      Washington: { value: 65 },
      "West Virginia": { value: 39 },
      Wisconsin: { value: 20 },
      Wyoming: { value: 59 },
    };

    this.parsecsv = this.parsecsv.bind(this);
    this.drilldata = this.drilldata.bind(this);
    this.createMap = this.createMap.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    d3.csv(drillDownData.drilldata_1)
      .then((drilldata) => {
        d3.csv(descData.desc_1)
          .then((descData) => {
            d3.csv((sankeyData.sankey_1))
              .then((sankeydata) => {
                this.parsecsv(drilldata, descData, sankeydata);
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  }

  parsecsv(data, data0, data1) {
    console.log();
    this.Data = [];
    this.iddata = [];
    this.data = [];
    for (let i = 0; i < data1.length; i++) {
      this.from = String(data1[i][0]);
      this.to = String(data1[i][1]);
      this.len = String(data1[i][2]);

      this.drillid = this.idd;
      this.drillresult = this.drilldata(data);
      this.sankeydescd = this.sankeydescdata(data0);

      this.iddata.push({
        id: this.idd,
        data: this.drillresult,
        name: '1.0 : <span style="color:grey;">Between 0 - 17  Age Group</span>   2.0 : <span style="color:grey;padding:10px;">Between 18 - 34 Age Group</span>     \
                3.0 : <span style="color:grey;">Between 35 - 44 Age Group</span>  <br> <span>  </span><br>4.0 : <span style="color:grey;">Between 45 - 54 Age Group</span>            \
                5.0 : <span style="color:grey;">Between 55 - 64 Age Group</span>   6.0 : <span style="color:grey;">Between 65 and Others Age Group</span>',
        type: 'column',
        tooltip:
        {
          headerFormat: '<span style="font-size:11px">Total Patient Count </span><br>',
          pointFormat: `<b>${this.sankeydescd[0][0]}</b> --> <b>${this.sankeydescd[0][1]} </b> : {point.y}`,
        },
      });
      this.idd += 1;
      this.temp = this.from;
      this.data.push({
        from: this.from,
        to: this.to,
        weight: parseInt(this.len),
        descfrom: this.sankeydescd[0][0],
        descto: this.sankeydescd[0][1],
        drilldown: this.drillid,
      });
    }
    console.log(this.data);
    console.log(this.iddata);
    this.Data.push([this.data, this.iddata]);
    this.datas = this.Data[0][0];
    this.datad = this.Data[0][1];
    this.createMap();
  }

  drilldata(data) {
    this.datadrill = [];
    for (let k = 0; k < data.length; k++) {
      if (this.from === this.temp && this.temp != null) {
        if (String(data[k].drg) === this.from && String(data[k].pdx) === this.to) {
          const state = String(data[k].agegrp);
          const enrolid = parseInt(data[k].enrolid);
          this.datadrill.push({
            name: state,
            y: enrolid,
          });
        }
      } else {
        if (String(data[k].drg) === this.from && String(data[k].pdx) === this.to) {
          const state = String(data[k].agegrp);
          const enrolid = parseInt(data[k].enrolid);
          this.datadrill.push({
            name: state,
            y: enrolid,
          });
        }
        this.temp = this.from;
      }
    }
    return this.datadrill;
  }

  sankeydescdata(data0) {
    this.descdat = [];
    for (let c = 0; c < data0.length; c++) {
      const code = String(data0[c].Code);
      if (code === parseInt(this.from) || code === this.from || code === parseFloat(this.from)) {
        this.tfrom = String(data0[c].Description);
      }
      if (code === parseInt(this.to) || code === this.to || code === parseFloat(this.to)) {
        this.tto = String(data0[c].Description);
      }
    }
    this.descdat.push([this.tfrom, this.tto]);
    return this.descdat;
  }

  createMap() {
    Highcharts.chart('container', {
      title: {
        text: 'Patient Journey',
      },
      data: {
        enablePolling: true,
        dataRefreshRate: 1,
      },
      xAxis: {
        type: 'category',
      },
      chart: {
        events: {
          drilldown(e) {
            this.frm = Object.values(e.point)[2];
            this.end = Object.values(e.point)[3];
          },
        },
      },
      series: [{
        keys: ['from', 'to', 'weight'],
        data: this.datas,
        type: 'sankey',
        name: 'Musculoskeletal',
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<b> {point.descfrom}</b> --> <b> {point.descto}</b> : {point.weight}',
        },
        showInNavigator: true,
      }],
      drilldown: {
        series: this.datad,
        legend: {
          padding: true,
        },
      },
    });
  }

  handleChange(value) {
    this.setState({ selected: value });
    d3.csv(drillDownData[`drilldata_${value}`])
      .then((drilldata) => {
        d3.csv(descData[`desc_${value}`])
          .then((desc) => {
            d3.csv((sankeyData[`sankey_${value}`]))
              .then((sankey) => {
                console.log(drilldata, desc, sankey);
                this.parsecsv(drilldata, desc, sankey);
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  }

  render() {
    const { classes } = this.props;
    const { selected } = this.state;
    return (
      <section className="App">
        <div>
          <NavBar />

          <section className={classes.container}>
            <h1 className={classes.titleClient}>Patient Journey For Admission | Musculoskeletal</h1>

            <FormControl className={classes.formControl} style={{ float: "right", top: "-32px", left: "-55px" }}>
              <InputLabel htmlFor="name" />
              <Select
                name="name"
                value={selected}
                onChange={(event) => this.handleChange(event.target.value)}
                input={<Input id="name" />}
              >
                {
                  Object.keys(this.dropdown).map((key) => (
                    <MenuItem value={this.dropdown[key].value}>{key}</MenuItem>
                  ))
                }

              </Select>
            </FormControl>

            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Diagnosis Group</TableCell>
                    <TableCell>Principal Diagnosis</TableCell>
                    <TableCell align="right">Principal Procedure</TableCell>
                    <TableCell align="right">Discharge Status</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </div>

            <section className={classes.gridContainer}>
              <section>
                <div id="container" style={{ width: '100%', height: '700px' }} />
                <Typography>*This Visualization has Drilldown capabilities based on Age Group and Total Patient Count.</Typography>
                <Typography>*This Visualization can be Filtered for Different States.</Typography>
              </section>
            </section>

          </section>
          <Footer hideSocialShare />
        </div>
      </section>
    );
  }
}

PatientJourneyForAdmission.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(MainViewStyle)(PatientJourneyForAdmission);
