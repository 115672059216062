import React, { memo, useState } from "react";
import { Grid, Card } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";

// import local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import brainTumorSegmentation_sol1 from "../../../assets/images/app/brainTumorSegmentation_sol1.webp";
import brainTumorSegmentation_sample1 from "../../../assets/images/app/brainTumorSegmentation_sample1.gif";
import brainTumorSegmentation_sample2 from "../../../assets/images/app/brainTumorSegmentation_sample2.gif";

const BrainTumorSegmentation = () => {
  const [showInput, setShowInput] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [clearScreen, setClearScreen] = useState(false);

  const onShowInputClick = () => {
    setShowInput(true);
    setShowSubmit(true);
  };
  const onSubmitClick = () => {
    setShowOutput(true);
    setShowInput(true);
    setShowSubmit(true);
    setClearScreen(true);
  };

  const onClearScreenClick = () => {
    setShowInput(false);
    setShowOutput(false);
    setClearScreen(false);
    setShowSubmit(false);
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Brain tumor segmentation is an application that identifies and
            segments healthy brain tissues from tumorous regions. The most
            common diagnostic tool used to identify brain tumors is Magnetic
            Resonance Imaging (MRI). It is non-invasive and can image diverse
            tissue types and physiological processes. A single patient can
            produce more than 600 images from a single MRI. Because manual
            segmentation is slow and tedious, there is a high demand for
            computer algorithms that perform this activity quickly and
            accurately, allowing for more immediate patient care and higher
            throughput treatment times.
          </p>
          <p>
            ABTS (Automatic Brain Tumor Segmentation) has the potential to
            decrease lag time between diagnostic tests and treatment by
            providing an immediate report of tumor location. The algorithm uses
            convolutional neural networks (CNNs) for semantic segmentation of
            images. An ABTS algorithm can distinguish between a tumor and a
            healthy tissue and actively enhances the tumor and non-advancing
            tumor regions. The enhanced images allow clinicians to focus more on
            patient care and improve their chances of survival.
          </p>
          <p>
            The model architecture for Brain Tumor Segmentation is shown below:
          </p>
          <center>
            <img src={brainTumorSegmentation_sol1} alt="" />
          </center>
          <h3>Input</h3>
          <p>
            It consists of four unique sequences that show the distinct chemical
            and physiological characteristics of various tissue types
          </p>
          <ul type="disc">
            <li>Fluid Attenuated Inversion Recovery (FLAIR)</li>
            <li>T1</li>
            <li>T1-contrasted</li>
            <li>T2</li>
          </ul>
          <p>
            The inputs mentioned above are fluids injected before an MRI is
            taken.
          </p>
          <h3>Output</h3>
          <p>
            The classified image illustrates the affected sections of the brain
            by color coding them
          </p>
          <ul type="disc">
            <li>Yellow – Advancing Tumor</li>
            <li>Blue – Non AdvancingTumor</li>
            <li>Red – Necrotic Tumor</li>
            <li>Green - Edema</li>
          </ul>

          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={2}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Brats"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/imatge-upc/mri-braintumor-segmentation/tree/main/tests/dataset"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container>
            <Grid item xs={12} alignItems="center">
              <StyledButton
                variant="contained"
                color="primary"
                onClick={onShowInputClick}
              >
                Sample Input
              </StyledButton>
            </Grid>
          </Grid>
          <br />

          <section>
            <Grid container alignItems="center" spacing={3} justify="center">
              {showInput && (
                <Grid item xs={12} sm={6} md={3}>
                  <InputGif src={brainTumorSegmentation_sample1} alt="" />
                </Grid>
              )}
              {showOutput && (
                <Grid item xs={12} sm={6} md={3}>
                  <OutputGif src={brainTumorSegmentation_sample2} alt="" />
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center" justify="center">
              {showSubmit && (
                <Grid item xs={12} sm={3} alignItems="right">
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={onSubmitClick}
                  >
                    Submit
                  </StyledButton>
                </Grid>
              )}
              {clearScreen && (
                <Grid item xs={12} sm={3} alignItems="left">
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={onClearScreenClick}
                  >
                    Clear
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(BrainTumorSegmentation);
const InputGif = styled.img`
  width: 170px;

  height: 350px;

  @media only screen and (max-width: 900px) {
    max-width: 170px;

    max-height: 350px;
  }
`;
const OutputGif = styled.img`
  max-width: 240px;
`;
