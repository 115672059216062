import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import MainViewStyle from "./main-view";
import Navbar from '../../common/Navbar';
import Footer from '../../views/home/Footer';
import services from '../../../assets/images/solutions/services.webp';
import admission from '../../../assets/images/solutions/admission.webp';

class Type2DiabetesDashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <section className="App">
        <div>
          <Navbar />
          <section className={classes.container}>
            <h1 className={classes.titleClient}>Type 2 Diabetes</h1>

            <section className={classes.gridContainer}>
              <section>
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.media}
                        image={admission}
                      />
                      <CardActions>
                        <Button
                          size="large"
                          className={classes.btn}
                          component={Link}
                          to="/patient-journey-admission-t2d"
                        >
                          Patient Journey for Admission | Type - 2 Diabetes
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.media}
                        image={services}
                      />
                      <CardActions>
                        <Button
                          size="large"
                          className={classes.btn}
                          component={Link}
                          to="/type2diabetes-diagnosis"
                        >
                          Type - 2 Diabetes | Diagnosis - Therapeutic Hierarchy
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </section>
            </section>
          </section>
          <Footer hideSocialShare />
        </div>
      </section>
    );
  }
}

Type2DiabetesDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(MainViewStyle)(Type2DiabetesDashboard);
