import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import tilemap from 'highcharts/modules/tilemap';
import Highcharts from 'highcharts/highmaps';
import drilldown from 'highcharts/modules/drilldown';
import * as d3 from 'd3';
import { StyledButton } from "../../styles/common";
import data from '../../assets/files/providertype.csv';

tilemap(Highcharts);
drilldown(Highcharts);

class ProviderTypes extends React.Component {
  constructor(props) {
    super(props);

    this.Facility = [];
    this.Professionals = [];
    this.Agencies = [];
    this.nap = [];
    this.ap = [];
    this.s = [];
    this.data1 = [];
    this.dict = [];

    this.parsecsv = this.parsecsv.bind(this);
    this.createMapData = this.createMapData.bind(this);
    this.createMap = this.createMap.bind(this);
  }

  componentDidMount() {
    d3.csv(data)
      .then((data) => {
        console.log(data);
        this.parsecsv(data);
      })
      .catch((err) => {
        throw err;
      });
  }

  parsecsv(data) {
    let Facility = 0;
    let Physician = 0;
    let nap = 0;
    let ap = 0;
    let s = 0;
    let Professionals = 0;
    let Agencies = 0;
    for (let i = 0; i < data.length; i++) {
      const std = parseInt(data[i][0], 10);
      const count = parseInt(data[i][1], 10);
      if (std >= 1 && std <= 99) {
        Facility += count;
      } else if (std >= 100 && std <= 799) {
        Physician += count;
        if (std >= 100 && std <= 199) {
          nap += count;
        } else if (std >= 200 && std <= 499) {
          ap += count;
        } else if (std >= 500 && std <= 799) {
          s += count;
        }
      } else if (std >= 800 && std <= 899) {
        Professionals += count;
      } else if (std >= 900 && std <= 999) {
        Agencies += count;
      }
    }
    this.dict.push([Facility, Professionals, Agencies, nap, ap, s]);
    this.createMapData(this.dict);
  }

  createMapData(data) {
    for (const i in data) {
      this.Facility.push(data[i][0]);
      this.Professionals.push(data[i][1]);
      this.Agencies.push(data[i][2]);
      this.nap.push(data[i][3]);
      this.ap.push(data[i][4]);
      this.s.push(data[i][5]);
    }
    this.createMap();
    console.log(this.Facility, this.Professionals, this.Agencies, this.nap, this.ap, this.s);
  }

  createMap() {
    Highcharts.chart('container', {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Patients Count Based on Provider Types',
      },
      xAxis: {
        categories: ['Provider Types'],

      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Patients',
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray',
          },
        },
      },
      legend: {
        verticalAlign: 'top',
        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
            formatter() {
              return ` <strong>${this.series.name}`;
            },
          },
        },
      },
      series:
        [{

          name: 'Facility',
          data: [this.Facility],
          stack: this.Facility,
        }, {
          name: 'Non admitting Physicians',
          data: [this.nap],
          stack: this.nap,

        },
        {
          name: 'Admitting Physicians',
          data: [this.ap],
          stack: this.nap,

        },
        {
          name: 'Surgeons',
          data: [this.s],
          stack: this.nap,

        }, {
          name: 'Professionals',
          data: [this.Professionals],
          stack: this.Professionals,

        }, {
          name: 'Agencies',
          data: [this.Agencies],
          stack: this.Agencies,
        },
        ],
    });
  }

  render() {
    return (
      <>
        <Grid container alignItems="end" justifyContent="flex-end">
          <Grid item xs={12}>
            <StyledButton
              size="large"
              component={Link}
              to="/solutions/population-health-dashboard"
            >
              Previous
            </StyledButton>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} md={12}>
            <div id="container" />
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography>
              * This Visualization has Drilldown capabilities based on Diagnosis group and Patient Total pay.
            </Typography>
          </Grid>
        </Grid>
      </>

    );
  }
}
export default memo(ProviderTypes);
