import React, { useState, useEffect, useRef, memo } from 'react';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
} from '@material-ui/core';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import {
  ExpandLess,
  ExpandMore,
  LabelImportant,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ArrowForward,
  ArrowBack,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
// local components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faQuestionCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import cheveronData, {
  cssValues,
  featureIds,
  stageIds,
  subFeatureIds,
  stageLabels,
  subFeatureData,
} from './constants';
import Navbar from '../../../common/Navbar';
import InputForm from './components/FormWrapper';
import NotificationWidget from '../../../common/Notification';
import {
  clearInputData,
  setCanvasClickData,
  setFeatureData,
  setInputData,
} from '../../../../store/actions/vnet';
import ResultPanel from './components/ResultPanel';
import postReq from './Service';
import { StyledBackdrop } from '../../../../styles/common';
import Spinner from '../../../common/loading/Spinner';
import { PopupInfoToolTip, TextFieldToolTip } from './components/InfoToolTip';
import { CURRENT_ENV } from '../../../../utils/services/api-config';

function FasterAI({ location }) {
  // state
  const [CheveronList, setCheveronList] = useState(Object.keys(cheveronData));
  const {
    Context_param = {},
    resultData,
    consoleList = null,
    canvasList = [],
  } = useSelector((state) => state.vnet);
  const { popupFullWidth } =
    subFeatureData[Context_param?.FunctionID || 'default'] ||
    subFeatureData.default;

  const [actionData, setAction] = useState({
    selectedCheveron: 'Data Input',
    selectedFeature: '',
    featureList: '',
  });
  const [notification, setNotification] = useState('');
  const [openPopup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const resultPanelRef = useRef();
  const [isLoading, setLoader] = useState(false);
  const InfoToolTipData = {
    getStarted: [
      'Click on any of the chevrons from the chevron panel at the top of the canvas',
      'Under ‘Feature Selection’ located on the left-hand side of the canvas, select the function you would like to perform and proceed with the easy-to-use wizard ',
      'The chevrons can be clicked in any order, and at any point of time ',
      'You can revisit any function, and perform them again by clicking on the icons in the canvas.',
    ],
  };
  // handles
  const handleCheveron = (e) => {
    setAction({
      ...actionData,
      selectedCheveron: e.currentTarget.getAttribute('data-id'),
      selectedFeature: '',
      selectedSubFeature: '',
    });
    dispatch(
      setFeatureData({
        ...Context_param,
        StageID: stageIds[e.currentTarget.getAttribute('data-id')],
        FeatureID: '',
        FunctionID: '',
        Environment: CURRENT_ENV,
      })
    );
  };
  const handleSubFeatures = (e) => {
    const featureId = e.currentTarget.getAttribute('data-feature');
    const functionId = e.currentTarget.getAttribute('data-subfeature');
    const stageId = e.currentTarget.getAttribute('data-stage');
    dispatch(clearInputData());
    setAction({
      ...actionData,
      selectedFeature: featureIds[featureId],
      selectedSubFeature: subFeatureIds[functionId],
      selectedCheveron: stageId,
    });
    dispatch(
      setFeatureData({
        ...Context_param,
        FeatureID: featureIds[featureId],
        FunctionID: subFeatureIds[functionId],
        StageID: stageIds[stageId],
      })
    );
    dispatch(setCanvasClickData(false));

    setPopup(true);
  };
  const handlePopupClose = (e) => {
    // confirm(`Closing the screen will delete all the entries for the respective function. Do you want to continue ?`)
    setPopup(false);
  };
  const getUserInputData = (e) => {
    const featureId = e.currentTarget.getAttribute('data-feature');
    const functionId = e.currentTarget.getAttribute('data-function');
    const stageId = e.currentTarget.getAttribute('data-stage');
    setAction({
      ...actionData,
      selectedFeature: featureId,
      selectedSubFeature: functionId,
      selectedCheveron: stageId,
    });
    dispatch(
      setFeatureData({
        ...Context_param,
        FeatureID: featureId,
        FunctionID: functionId,
        StageID: stageIds[stageId],
      })
    );
    dispatch(setCanvasClickData(true));
    dispatch(clearInputData());
    if (subFeatureData[functionId]?.hidePreviousInputAPI) {
      setPopup(true);
    } else {
      let params;
      params = {
        Context_param: {
          ...Context_param,
          Actions: 'PreviousInput',
          FeatureID: featureId,
          FunctionID: functionId,
          StageID: stageIds[stageId],
          Update_Date: new Date().toLocaleDateString(),
          Created_Date: new Date().toLocaleDateString(),
        },
        Content_param: {},
      };
      if (params) {
        setLoader(true);
        postReq(params)
          .then((res) => {
            setLoader(false);
            if (res.Error_Flag) {
              setNotification({ open: 'error', message: res.Error_UI });
              dispatch(
                setInputData(subFeatureData[functionId]?.initialData || null)
              );
              subFeatureData[functionId]?.initialData;
            } else {
              dispatch(setInputData(res.Result && JSON.parse(res.Result)));
              setNotification({ open: 'success', message: res.Console });
            }
            setPopup(true);
          })
          .catch((err) => {
            setLoader(false);
            setNotification({ open: 'error', message: err.message });
          });
      }
    }
  };
  useEffect(() => {
    if (openPopup) {
      if (resultData) {
        setTimeout(() => {
          handlePopupClose();
          resultPanelRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }, 1500);
      }
    }
  }, [resultData]);
  useEffect(() => {
    if (!location.state) {
      history.push('/vnet');
    } else {
      setAction({
        selectedCheveron: stageLabels[Context_param?.StageID],
      });
    }
    return () => {
      // confirm("Data will be saved to db")
    };
  }, []);
  return (
    <FasterAIContainer>
      <Navbar />
      <Info>
        <CTitile>
          {Context_param?.ProjectName} - {Context_param?.ProjectID}{' '}
        </CTitile>
        <h5>
          Faster AI is an intuitive AI tool to build, train and deploy machine
          learning models without the need to write a single line of code - All
          under one hood. It leverages applications developed with Stanford
          University to create noise-free and unbiased datasets.
          <p>
            <em>
              How to Get Started
              <PopupInfoToolTip
                popupInfo={InfoToolTipData.getStarted}
                icon={faQuestionCircle}
              />
            </em>
          </p>
        </h5>
      </Info>
      {/* cheveron list container */}
      <CheveronContainer>
        {CheveronList?.map((text) => (
          <Container
            selected={actionData.selectedCheveron === text}
            data-id={text}
            onClick={handleCheveron}
          >
            {text}
            &nbsp;
            <LabelImportant />
          </Container>
        ))}
      </CheveronContainer>
      {/* Cointainer - Features dropdown and process panel */}
      <Grid container>
        <StyledGrid item xs={12} sm={3} md={2}>
          <FeaturesContainer
            selectedCheveron={actionData.selectedCheveron}
            subFeature={handleSubFeatures}
          />
        </StyledGrid>
        <StyledGrid item xs={12} sm={9} md={10}>
          <ProcessPanel
            catalogList={canvasList}
            getUserInputData={getUserInputData}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <CommonCollapse title='Console'>
            <ConsoleContainer>
              {consoleList?.map((data) => (
                <span>
                  {'> '}
                  {data}
                </span>
              ))}
            </ConsoleContainer>
          </CommonCollapse>
        </StyledGrid>
        <StyledGrid item xs={12} ref={resultPanelRef}>
          <CommonCollapse title='Result' defaultVal>
            <ResultPanel />
          </CommonCollapse>
        </StyledGrid>
      </Grid>
      {/* Popup form */}
      <StyledDialog
        open={actionData.selectedSubFeature && openPopup}
        onClose={handlePopupClose}
        fullWidth={popupFullWidth}
        maxWidth={popupFullWidth}
      >
        <DialogTitle>
          {' '}
          {subFeatureData[Context_param?.FunctionID]?.title} &nbsp;
          <PopupInfoToolTip
            popupInfo={subFeatureData[Context_param?.FunctionID]?.popupInfo}
          />
          <IconButton aria-label='Close' onClick={handlePopupClose}>
            <CloseIcon />
          </IconButton>
          <Desc>
            {subFeatureData[Context_param?.FunctionID]?.shortDesc}
          </Desc>
        </DialogTitle>
        <StyledDialogContent>
          <InputForm />
        </StyledDialogContent>
      </StyledDialog>
      <NotificationWidget snackbar={notification} />
      <StyledBackdrop open={isLoading}>
        <Spinner />
      </StyledBackdrop>
    </FasterAIContainer>
  );
}

const ProcessPanel = memo(({ catalogList, getUserInputData }) => {
  const [px, setPx] = useState(-1);
  const dispatch = useDispatch();

  const deleteCanva = (e) => {
    e.stopPropagation();
    const featureId = e.currentTarget.getAttribute('data-feature');
    const functionId = e.currentTarget.getAttribute('data-function');
    const stageId = e.currentTarget.getAttribute('data-stage');
    const canvaData = {
      ...catalogList,
      [stageId]: {
        ...catalogList[stageId],
        features: catalogList[stageId].features.map((feature) => {
          if (feature.key === featureId) {
            const data = { ...feature };
            data.subList = feature.subList.filter(
              (obj) => obj.key !== functionId
            );
            return data;
          }
          return feature;
        }),
      },
    };
    // console.log(canvaData);
    // dispatch(setCanvaList(canvaData))
  };
  return (
    <CataloguesPanel elevation={3}>
      <CatalogueList px={px}>
        {Object.keys(catalogList)?.map((stageId) => (
          <Catalogue px={px}>
            <div id='content'>
              <span>{catalogList[stageId]?.label}</span>
              {catalogList[stageId].features?.map((featuresData) =>
                featuresData.subList?.map((data) => (
                  <FunctionCatalogue
                    onClick={getUserInputData}
                    data-stage={stageId}
                    data-feature={featuresData.key}
                    data-function={data.key}
                  >
                    <span>{data?.label}</span>
                    {/* <CanvaDeleteIcon onClick={deleteCanva} id='deleteIcon' data-stage={stageId} data-feature={featuresData.key} data-function={data.key}><FontAwesomeIcon icon={faTrash} /></CanvaDeleteIcon> */}
                  </FunctionCatalogue>
                ))
              )}
            </div>
          </Catalogue>
        ))}
      </CatalogueList>
    </CataloguesPanel>
  );
});

const FeaturesContainer = memo(({ selectedCheveron, subFeature }) => {
  const listData = selectedCheveron && cheveronData[selectedCheveron].features;
  const [open, setCollapse] = useState('');
  const [disableList, setDisableList] = useState([]);

  const handleCollapse = (e) => {
    const val = e.currentTarget.getAttribute('data-feature');
    setCollapse(open === val ? '' : val);
  };

  return (
    <FeaturePanel elevation={3}>
      <FeatureTitle>
        <span>Feature Selection</span>
        {/* <InfoIcon /> */}
      </FeatureTitle>
      {listData ? (
        <StyledList component='nav' disablePadding>
          {listData.map((listObj) => (
            <>
              <ListItem onClick={handleCollapse} data-feature={listObj.label} id='feature-list'>
                <ListItemText>{listObj.label}</ListItemText>
                {listObj.label === open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {listObj.subList &&
                listObj.subList.map((label) => (
                  <Collapse
                    in={listObj.label === open}
                    timeout='auto'
                    unmountOnExit
                  >
                    <StyledList
                      component='div'
                      id='sub-list'
                      disablePadding
                      data-subfeature={label}
                      data-feature={listObj.label}
                      data-stage={selectedCheveron}
                      onClick={subFeature}
                    >
                      {/\*/.test(label) ?
                        <TextFieldToolTip info='Coming soon'>
                          <ListItem sx={{ pl: 4 }} disabled={/\*/.test(label)}>
                            <ListItemText primary={label} />
                          </ListItem>
                        </TextFieldToolTip>
                        :
                        <ListItem sx={{ pl: 4 }} >
                          <ListItemText primary={label} />
                        </ListItem>
                      }
                    </StyledList>
                  </Collapse>
                ))}
            </>
          ))}
        </StyledList>
      ) : (
        <TBDText>TBD</TBDText>
      )}
    </FeaturePanel>
  );
});

const CommonCollapse = memo(({ title, children, defaultVal }) => {
  const [consoleOpen, setConsole] = useState(defaultVal);
  const handleConsole = () => {
    setConsole(!consoleOpen);
  };
  return (
    <>
      <ConsoleTitle onClick={handleConsole}>
        {title} &nbsp;
        <FontAwesomeIcon icon={consoleOpen ? faAngleUp : faAngleDown} />
      </ConsoleTitle>
      <Collapse in={consoleOpen} timeout='auto' unmountOnExit>
        {children}
      </Collapse>
    </>
  );
});
export default memo(FasterAI);
const ConsoleTitle = styled.p`
  background: #0a2342;
  color: #ffff;
  padding: 10px;
  margin: 0;
  border-radius: 5px;
`;
const CTitile = styled.h3`
  color: yellow;
  text-align: right;
  font-family: synthese, sans-serif;
`;
const CanvaDeleteIcon = styled.span`
  display: none;
`;
const ConsoleContainer = styled.div`
  width: 100%;
  background: #0a2342;
  color: #ffff;
  display: flex;
  flex-direction: column;
  font-size: small;
  max-height: 150px;
  border-radius: 5px;
  overflow-y: scroll;
  margin-top: 5px;
  span {
    padding: 5px;
  }
`;
const FunctionCatalogue = styled.section`
  background: #16f4d0;
  min-height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #000000;
  margin-top: 1vmax;
  padding: 3px;
  cursor: pointer;
  position: relative;
  &:hover {
    transform: scale(1.05);
    #deleteIcon {
      display: block;
      background: #ffff;
      border-radius: 3px;
      color: black;
      padding: 3px;
      position: absolute;
      top: 1%;
      right: 0;
    }
  }
`;

const CatalogueList = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  @media only screen and (max-width: 900px) {
    margin-top: 5px;
    flex-direction: column;
  }
`;
const Catalogue = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => `${10 + props.px}px`};
  min-width: fit-content;
  #content {
    min-width: max-cont;
    height: auto;
    background: #111111;
    color: #ffff;
    border-radius: 5px;
    font-size: ${(props) => (props.px ? `${10 + props.px}px` : 'xx-small')};
    align-items: center;
    padding: ${(props) => `${5 + props.px}px`};
    cursor: pointer;
    font-weight: bold;
    .MuiSvgIcon-root {
      font-size: ${(props) => `${30 + props.px}px`};
      color: #16f4d0;
      margin-top: 5px;
    }
    span {
      text-align: center;
    }
    section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5% 0;
    }
  }
  &:not(:last-child):after {
    content: '';
    width: 12px;
    height: ${(props) => `${3 + props.px}px`};
    position: relative;
    background-color: #053557;
    @media only screen and (max-width: 900px) {
      width: 3px;
      height: 10px;
    }
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;
const TBDText = styled.h3`
  text-align: center;
`;
const StyledList = styled(List)`
  .MuiListItem-root {
    padding: 2px 6px;
    cursor: pointer;
    .MuiListItemText-root {
      font-size: small;
      .MuiTypography-body1 {
        font-size: small;
        font-weight: bold;
        color: #153b50;
      }
    }
  }
  #sub-list {
    padding: 2px 12px;
    .MuiListItemText-root{
      margin: 0;
      .MuiTypography-body1{
        font-size: 12px;
        color: #273043;
      }
    }
  }
  #feature-list{
    .MuiListItemText-root{
      .MuiTypography-body1{
        color: #272FAF;
      }
    }
  }
`;
const StyledPaper = styled(Paper)`
  width: auto;
  padding: 5px;
  height: 300px;
  overflow-y: hidden;
  @media only screen and (max-width: 900px) {
    overflow-y: scroll;
  }
`;
const FeaturePanel = styled(StyledPaper)`
  padding: 8px;
  padding-top: 0;
  height: 312px;
  overflow-y: auto;
`;
const CataloguesPanel = styled(StyledPaper)`
  padding: 16px 4px 4px 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
`;
export const FeatureTitle = styled.div`
  padding: 12px 5px 6px 4px;
  border-bottom: 2px solid ${cssValues.CheveronBg};
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
  z-index: 1;
  position: sticky;
  top: 0;
  background: #ffff;
  .MuiSvgIcon-root {
    transform: skewX(-10deg);
    color: #04a777;
  }
`;
const StyledGrid = styled(Grid)`
  padding: 6px;
`;
const CheveronContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background: ${cssValues.CheveronBg};
  border-radius: 5px;
  flex-wrap: wrap;
  margin: 5px 5px;
  padding: 5px;
  background: #2980b9; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2c3e50, #2980b9);
  background: linear-gradient(to right, #111111, #111111);
  cursor: pointer;
`;
const Container = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  height: 45px;
  cursor: pointer;
  color: ${(props) =>
    props.selected ? cssValues.selectedTextColor : cssValues.textColor};
  transform: ${(props) => (props.selected ? 'scale(1.1)' : 'scale(1)')};
  .MuiSvgIcon-root {
    width: 0.8em;
  }
  transition: 0.6s ease-in-out;
`;
const FasterAIContainer = styled.div`
  background: #e5e5e5;
  width: 100%;
`;
const Info = styled.section`
  height: auto;
  padding: 1px 10px 1px 10px;
  background: ${cssValues.infoBgcolor};
  color: #fafaf0;
  margin-top: 50px;
  background: #053354;
  background: transparent linear-gradient(180deg, #053354 0, #006aa5 100%) 0 0
    no-repeat padding-box;
  font-family: synthese, sans-serif;
`;
export const StyledDialog = styled(Dialog)`
  text-align: center;
  .MuiDialogTitle-root {
    font-family: synthese, sans-serif;
    font-weight: 300;
    border-bottom: 1px solid #053557;
    .MuiIconButton-root {
      position: absolute;
      top: 1%;
      right: 1%;
    }
  }
`;
export const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 24px 0;
    overflow-x: hidden;
    &.MuiOutlinedInput-root {
      max-width: 100%;
    }
    @media only screen and (max-width: 700px) {
      .MuiInputBase-root,
      .MuiFormLabel-root {
        font-size: 0.8rem;
      }
      .MuiTextField-root {
        min-width: 10%;
      }
      .MuiFormHelperText-root {
        font-size: 0.5rem;
      }
      .MuiSelect-root {
        min-width: 100px;
      }
      .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 6px;
      }
      .MuiButton-root {
        margin: 12px 0;
      }
    }
    @media only screen and (max-width: 450px) {
      .MuiButton-root {
        margin: 12px 0;
      }
      .MuiTypography-body1 {
        font-size: 0.8rem;
      }
    }
  }
`;
const DialogInfo = styled.p`
  font-size: 11px;
  color: grey;
  padding: 0;
  margin: 0;
`;
const ProjectDetails = styled.div`
  display: flex;
`;
const Desc = styled.small`
  display: block;
  font-size: 12px;
`;

