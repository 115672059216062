import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";

import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";

const DrugMOAPrediction = () => {
  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [sample, setSample] = useState(false);
  const [result, setResult] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "payerProvider", "drug_moa",
  );

  const getSample = () => {
    setSpinner(true);
    setSample(false);
    setResult(false);

    getQueryResult("withParams", 'sample/')
      .then((res) => {
        setSpinner(false);
        const previewData = JSON.parse(res.sampledata);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setSample(res);
        setField(fields);
        setDataRows(dataRows);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getResult = () => {
    setSpinner1(true);
    setResult(false);

    getQueryResult("withParams", 'result/')
      .then((res) => {
        setSpinner1(false);
        const previewData = JSON.parse(res.resultdata);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setResult(res);
        setField(fields);
        setDataRows(dataRows);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p> In the new framework, scientists seek to identify a protein target associated with a disease and develop a molecule that can modulate that protein target. One approach is to treat a sample of human cells with the drug and then analyze the cellular responses with algorithms that search for similarity to known patterns in large genomic databases, such as libraries of gene expression or cell viability patterns of drugs with known MoAs. Knowing the mechanism of action of a drug may help provide information about the safety of the drug and how it affects the body. It may also help identify the right dose of a drug and which patients are most likely to respond to treatment.</p>
          <p>
            <strong> Data -</strong>
            {' '}
            The data is based on a new technology that measures simultaneously (within the same samples) human cells’ responses to drugs in a pool of 100 different cell types (thus solving the problem of identifying ex-ante, which cell types are better suited for a given drug). In addition, there are MoA annotations for more than 5,000 drugs in this dataset.
          </p>
          <p>Features used in Input sample are:</p>
          <List>
            <ListItem>g-XXX : gene expression data</ListItem>
            <ListItem>c-XXX : cell viability data</ListItem>
            <ListItem>cp_type : samples treated with a compound</ListItem>
            <ListItem>cp_time : Treatment Duration (24, 48, 72 hours)</ListItem>
            <ListItem>cp_dose : dose (high or low)</ListItem>
          </List>
          <p>
            {' '}
            <strong> Methodology –</strong>
            {' '}
            Since drugs can have multiple MoA annotations, the use case is formally a multi-label classification problem. After splitting the data in training and test sets, the Neural Network modelling technique has been used to train the dataset and then MoA has been predicted for drugs used in test set. The query molecule is compared to the set of known ligands for a target by summing all pair-wise similarities and normalized scores (E-value) are generated.
          </p>
          <p>The smaller this E-value, the more significant the drug-target association.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/MoA/mechanism-of-action.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/competitions/lish-moa/data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container xs={12} direction="row" justify="center" alignItems="center">
            <Grid xs={12} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getSample}
              >
                Sample
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {sample && (
          <section>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    {fields.map((field) => (
                      <StyledTableHead key={field.name} align="left">
                        {' '}
                        <strong>{field.name}</strong>
                      </StyledTableHead>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataRows.map((row) => (
                    <TableRow key={row.index}>
                      {fields.map((field) => (
                        <TableCell align="left">
                          {field.name === "index"
                            ? row[field.name] + 1
                            : row[field.name]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center" justifyContent="center">

              <Grid xs={12} md={3}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getResult}
                >
                  Submit
                </StyledButton>
              </Grid>
            </Grid>
            {showSpinner1 && <Spinner text="Loading..." />}
            {result
                                && (
                                <section>
                                  <Result><strong>Result : </strong></Result>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Paper>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            {fields.map((field) => (
                                              <StyledTableHead key={field.name} align="left">
                                                {' '}
                                                <strong>{field.name}</strong>
                                              </StyledTableHead>
                                            ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {dataRows.map((row) => (
                                            <TableRow key={row.index}>
                                              {fields.map((field) => (
                                                <TableCell align="left">
                                                  {field.name === "index"
                                                    ? row[field.name] + 1
                                                    : row[field.name]}
                                                </TableCell>
                                              ))}
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </Paper>
                                  </Grid>
                                </section>
                                )}
          </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(DrugMOAPrediction);

const Paper = styled.div`
                overflow: auto;
                margin-top:2%;
                margin-bottom:2%;
                `;
const StyledTableHead = styled(TableCell)`
                background-color: #3c40af;
                color: white;
                font-weight: bold;
                `;
